import React from "react";
import { string } from "prop-types";

export default function Artwrk16(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      fill={primary}
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="graduates_1_">
        <g id="Shape_1_copy_14">
          <path
            d="M267.686,555.537c-0.711-0.35-1.328-0.268-1.923,0.268c-0.466,0.419-0.851,0.897-1.025,1.515
			c-0.163,0.594-0.338,1.189-0.443,1.795c-0.443,2.435-0.862,4.871-1.282,7.306c-0.478,2.82-0.932,5.64-1.387,8.367
			c0.151,0.478,0.245,0.862,0.385,1.212c0.082,0.21,0.221,0.42,0.396,0.559c1.41,1.084,3.309,0.117,3.729-0.979
			c0.466-1.235,0.711-2.529,0.944-3.822c0.291-1.596,0.548-3.193,0.816-4.801c0.35-2.051,0.734-4.113,1.014-6.176
			c0.151-1.072,0.175-2.156,0.128-3.239C269.014,556.656,268.525,555.957,267.686,555.537z M188.751,525.31
			c-0.28-0.128-0.571-0.245-0.862-0.338c-0.466-0.14-0.921-0.058-1.34,0.198c-0.198,0.117-0.396,0.245-0.559,0.408
			c-1.352,1.317-2.319,2.89-3.041,4.614c-0.245,0.583-0.291,1.212-0.163,1.83c0.058,0.291,0.175,0.606,0.373,0.827
			c1.002,1.189,3.1,1.235,4.148,0.082c1.317-1.457,2.377-3.053,2.738-5.302c-0.117-0.431-0.221-0.886-0.361-1.317
			C189.52,525.858,189.193,525.52,188.751,525.31z M355.442,564.556c-0.396-1.503-1.13-2.855-1.923-4.183
			c-0.058-0.105-0.291-0.21-0.396-0.175c-0.198,0.07-0.385,0.221-0.524,0.373c-0.093,0.105-0.128,0.28-0.14,0.431
			c-0.198,1.864,0.105,3.694,0.559,5.488c0.105,0.431,0.385,0.839,0.641,1.212c0.117,0.175,0.35,0.303,0.548,0.419
			c0.385,0.221,1.049,0.012,1.165-0.408c0.151-0.513,0.245-1.037,0.361-1.503C355.629,565.617,355.582,565.081,355.442,564.556z
			 M224.233,549.804c-0.221-0.128-0.478-0.105-0.641,0.058c-1.585,1.631-2.668,3.566-3.298,5.756
			c-0.058,0.221-0.093,0.466-0.058,0.688c0.093,0.629,0.466,1.177,1.154,1.27c0.874,0.117,1.573-0.21,1.899-0.979
			c0.781-1.783,1.224-3.647,1.433-5.593c-0.058-0.303-0.082-0.606-0.175-0.897C224.513,549.979,224.361,549.874,224.233,549.804z"
          />
        </g>
        <g id="Shape_1_copy_11">
          <path
            d="M577.939,435.293c-0.256-0.42-0.606-0.734-1.107-0.769c-0.769-0.058-1.55-0.07-2.331-0.07c-14.682,0-29.365,0-44.047,0
			c-2.797,0-5.593,0.047-8.39,0.047c-5.675,0-11.338-0.035-17.013-0.047c-0.618,0-1.247,0.012-1.864,0.035
			c-2.028,0.058-3.752,0.769-5.185,2.272c-1.981,2.086-4.032,4.09-6.036,6.164c-1.293,1.34-2.494,2.773-3.799,4.102
			c-3.985,4.032-7.994,8.04-12.002,12.061c-0.548,0.548-1.072,1.142-1.69,1.596c-1.13,0.839-2.086,1.864-3.088,2.832
			c-1.457,1.398-2.832,2.89-4.37,4.195c-1.713,1.468-3.344,3.018-4.976,4.556c-0.175,0.163-0.338,0.326-0.513,0.478
			c-2.109,1.713-4.172,3.484-6.176,5.302c-0.175,0.151-0.35,0.315-0.524,0.454c-1.387,1.119-2.773,2.237-4.16,3.368
			c-1.876,1.515-3.717,3.065-5.617,4.533c-3.752,2.89-7.609,5.64-11.513,8.32c-2.948,2.016-5.943,3.962-8.961,5.873
			c-4.067,2.575-8.238,4.976-12.492,7.225c-5.558,2.936-11.222,5.675-17.013,8.157c-5.43,2.331-10.942,4.416-16.535,6.292
			c-3.239,1.084-6.514,2.086-9.812,2.96c-7.201,1.923-14.461,3.612-21.814,4.824c-2.296,0.373-4.591,0.781-6.887,1.119
			c-2.226,0.326-4.44,0.757-6.7,0.746c-0.385,0-0.781,0.012-1.154,0.093c-2.063,0.489-4.16,0.443-6.257,0.454
			c-0.932,0-1.853,0.082-2.785,0.21c-2.377,0.338-4.778,0.583-7.19,0.559c-7.539-0.035-15.067-0.035-22.606-0.07
			c-0.769,0-1.55-0.117-2.319-0.186c-1.934-0.175-3.834-0.629-5.78-0.606c-1.864,0.023-3.729,0.035-5.558-0.431
			c-0.221-0.058-0.466-0.058-0.699-0.07c-3.03-0.117-6.013-0.734-9.019-1.06c-2.773-0.315-5.523-0.862-8.273-1.34
			c-1.911-0.326-3.822-0.664-5.721-1.084c-2.808-0.618-5.593-1.293-8.378-1.993c-3.309-0.827-6.595-1.783-9.87-2.762
			c-4.848-1.433-9.59-3.146-14.298-4.952c-2.028-0.769-4.032-1.643-6.024-2.482c-3.076-1.305-6.141-2.622-9.124-4.125
			c-5.279-2.634-10.569-5.255-15.65-8.273c-2.936-1.748-5.861-3.507-8.728-5.36c-2.61-1.678-5.162-3.438-7.714-5.22
			c-3.822-2.668-7.563-5.442-11.198-8.332c-3.333-2.657-6.712-5.267-9.812-8.215c-0.163-0.163-0.35-0.315-0.524-0.454
			c-2.342-1.911-4.626-3.892-6.794-6.013c-1.049-1.025-2.121-2.039-3.286-2.96c-1.573-1.259-3.088-2.599-4.521-4.02
			c-3.601-3.531-7.155-7.131-10.72-10.697c-0.385-0.385-0.804-0.746-1.13-1.177c-0.932-1.247-2.086-2.307-3.17-3.414
			c-2.226-2.272-4.475-4.533-6.689-6.828c-0.699-0.722-1.328-1.527-2.004-2.261c-0.734-0.792-1.492-1.585-2.272-2.342
			c-1.398-1.34-3.065-2.074-5.046-2.051c-0.781,0.012-1.55-0.012-2.331-0.012c-11.338,0-22.676,0-34.026,0
			c-11.653,0-23.305,0-34.958,0c-0.699,0-1.398-0.012-2.097,0.023c-0.303,0.012-0.618,0.07-0.909,0.151
			c-1.095,0.315-1.736,1.55-1.305,2.587c0.361,0.862,0.781,1.701,1.235,2.505c0.874,1.55,1.795,3.088,2.703,4.626
			c2.692,4.545,5.419,9.054,8.343,13.459c1.457,2.202,2.855,4.44,4.335,6.63c1.876,2.762,3.775,5.512,5.733,8.203
			c2.377,3.263,4.789,6.491,7.295,9.66c1.538,1.946,2.995,3.962,4.673,5.803c1.2,1.317,2.214,2.808,3.403,4.125
			c1.666,1.841,3.146,3.845,4.952,5.57c0.117,0.105,0.221,0.221,0.315,0.338c1.177,1.655,2.692,3.006,4.067,4.486
			c0.629,0.688,1.328,1.317,1.899,2.051c1.258,1.585,2.703,3.006,4.218,4.335c1.398,1.235,2.727,2.54,4.055,3.845
			c2.109,2.063,4.207,4.137,6.316,6.211c0.443,0.431,0.862,0.909,1.363,1.27c1.142,0.827,2.097,1.841,3.146,2.773
			c1.561,1.398,3.111,2.82,4.731,4.137c0.967,0.792,1.911,1.573,2.82,2.435c0.28,0.268,0.594,0.501,0.897,0.746
			c1.095,0.862,2.202,1.725,3.298,2.587c2.552,2.028,5.069,4.09,7.644,6.083c2.948,2.284,6.013,4.393,9.077,6.502
			c4.859,3.344,9.742,6.654,14.787,9.73c2.447,1.503,4.917,2.96,7.399,4.416c1.538,0.897,3.146,1.701,4.673,2.61
			c2.936,1.748,6.013,3.239,9.031,4.848c2.738,1.468,5.582,2.715,8.378,4.067c4.614,2.249,9.345,4.195,14.076,6.188
			c4.58,1.934,9.264,3.601,13.937,5.279c4.976,1.783,10.01,3.368,15.067,4.871c1.27,0.373,2.529,0.769,3.799,1.095
			c4.288,1.095,8.565,2.214,12.865,3.251c3.997,0.967,8.029,1.806,12.072,2.552c1.911,0.35,3.822,0.688,5.733,1.025
			c2.214,0.385,4.416,0.874,6.654,1.13c2.925,0.326,5.815,0.979,8.774,1.107c0.385,0.012,0.769,0.128,1.154,0.186
			c2.074,0.338,4.137,0.664,6.246,0.641c0.618-0.012,1.258,0,1.853,0.14c1.841,0.419,3.694,0.396,5.558,0.478
			c2.097,0.093,4.207,0.105,6.269,0.419c2.016,0.303,4.02,0.373,6.036,0.385c3.647,0.012,7.306,0.035,10.954,0.035
			c4.579-0.012,9.171-0.035,13.75-0.07c0.851-0.012,1.713-0.012,2.552-0.128c2.925-0.42,5.861-0.606,8.809-0.711
			c1.014-0.035,2.039,0.023,3.018-0.175c2.063-0.431,4.16-0.443,6.234-0.746c1.993-0.28,4.009-0.454,6.001-0.722
			c2.925-0.408,5.838-0.862,8.751-1.293c1.375-0.21,2.762-0.408,4.137-0.653c2.902-0.501,5.803-1.06,8.705-1.538
			c3.985-0.664,7.936-1.445,11.862-2.4c6.56-1.608,13.063-3.449,19.495-5.512c3.181-1.025,6.339-2.097,9.497-3.181
			c2.494-0.862,4.976-1.76,7.458-2.657c1.387-0.501,2.762-1.025,4.125-1.596c3.379-1.387,6.747-2.785,10.103-4.207
			c2.354-1.002,4.731-1.981,7.015-3.123c5.488-2.75,11.07-5.302,16.372-8.402c3.379-1.596,6.502-3.636,9.765-5.442
			c4.078-2.249,7.97-4.824,11.862-7.365c2.727-1.783,5.372-3.694,8.075-5.512c3.927-2.645,7.691-5.523,11.443-8.402
			c1.666-1.27,3.263-2.622,4.917-3.904c2.82-2.191,5.593-4.428,8.203-6.875c0.175-0.163,0.338-0.326,0.524-0.466
			c1.352-0.932,2.459-2.133,3.647-3.251c0.513-0.478,0.979-1.014,1.538-1.433c1.387-1.014,2.564-2.249,3.81-3.426
			c0.396-0.373,0.804-0.734,1.212-1.095c0.641-0.571,1.317-1.095,1.911-1.701c2.715-2.773,5.547-5.419,8.099-8.367
			c1.829-2.109,3.88-4.02,5.675-6.164c1.293-1.55,2.738-2.96,4.044-4.498c1.853-2.191,3.764-4.335,5.547-6.584
			c0.676-0.851,1.387-1.678,2.063-2.517c1.119-1.387,2.261-2.762,3.344-4.172c1.62-2.086,3.204-4.195,4.801-6.292
			c2.587-3.403,5.104-6.84,7.539-10.348c1.468-2.097,2.901-4.218,4.277-6.386c2.377-3.729,4.708-7.493,7.015-11.268
			c1.538-2.517,3.006-5.081,4.486-7.633c0.431-0.734,0.781-1.515,1.165-2.284C578.464,436.749,578.359,436.004,577.939,435.293z
			 M104.455,465.462c-1.095,0.862-2.202,1.713-3.298,2.575c-0.303,0.233-0.618,0.466-0.909,0.722
			c-1.212,1.072-1.62,2.435-1.293,3.997c0.175,0.827,0.443,1.643,0.676,2.459c0.151,0.524,0.338,1.037,0.478,1.561
			c0.186,0.676-0.163,1.328-0.874,1.725c-0.07,0.035-0.14,0.058-0.221,0.082c-0.151-0.012-0.326,0.012-0.454-0.058
			c-0.606-0.35-1.2-0.711-1.783-1.095c-0.653-0.419-1.27-0.897-1.934-1.282c-1.445-0.851-2.948-0.886-4.44-0.14
			c-0.618,0.315-1.2,0.711-1.771,1.095c-0.839,0.559-1.655,1.142-2.494,1.701c-0.361,0.233-0.769,0.198-1.084-0.058
			c-0.385-0.315-0.676-0.711-0.559-1.2c0.245-0.979,0.559-1.934,0.874-2.89c0.291-0.886,0.454-1.783,0.524-2.727
			c0.082-1.363-0.443-2.424-1.422-3.286c-0.629-0.559-1.34-1.06-2.028-1.561c-0.757-0.548-1.468-1.13-2.063-1.864
			c-0.175-0.21-0.058-0.653,0.175-0.816c0.466-0.315,0.979-0.478,1.527-0.489c1.084-0.023,2.167-0.047,3.251-0.035
			c0.862,0,1.69-0.14,2.505-0.431c0.536-0.198,0.956-0.524,1.212-1.025c0.338-0.688,0.664-1.398,0.944-2.121
			c0.35-0.944,0.641-1.911,0.967-2.867c0.245-0.734,0.524-1.457,1.014-2.074c0.163-0.198,0.688-0.245,0.839-0.047
			c0.186,0.245,0.396,0.489,0.501,0.769c0.361,0.944,0.664,1.899,1.002,2.843c0.315,0.874,0.618,1.76,0.99,2.599
			c0.221,0.489,0.536,0.932,0.874,1.363c0.443,0.583,1.049,0.909,1.806,0.921c1.084,0.023,2.167,0.058,3.251,0.07
			c0.618,0.012,1.247-0.023,1.864,0.035c0.373,0.035,0.757,0.163,1.107,0.303C104.828,464.425,104.944,465.066,104.455,465.462z
			 M161.786,501.434c-0.012,0.548-0.012,1.084-0.035,1.631c-0.047,1.049-0.431,1.969-1.119,2.75
			c-0.466,0.524-0.967,1.002-1.503,1.457c-0.606,0.513-1.771,0.559-2.412,0.093c-0.501-0.361-0.944-0.804-1.375-1.247
			c-0.454-0.478-0.583-1.084-0.454-1.736c0.117-0.606,0.28-1.212,0.373-1.818c0.117-0.769,0.14-1.55-0.047-2.319
			c-0.245-1.06-1.655-1.736-2.599-1.189c-0.664,0.385-1.328,0.816-1.899,1.34c-0.909,0.839-1.818,1.701-2.587,2.668
			c-0.781,0.967-1.643,1.864-2.505,2.75c-1.13,1.177-2.261,2.354-3.414,3.496c-0.827,0.827-1.515,1.76-2.237,2.668
			c-0.746,0.932-0.804,1.993-0.524,3.088c0.128,0.478,0.478,0.792,0.956,0.921c0.769,0.221,1.538,0.175,2.284-0.093
			c0.28-0.105,0.548-0.291,0.781-0.489c1.049-0.897,1.643-2.051,1.876-3.391c0.058-0.385,0.058-0.769,0.082-1.165
			c0.093-1.492,0.932-2.412,2.272-2.936c0.466-0.186,0.921-0.105,1.34,0.128c0.268,0.151,0.536,0.315,0.757,0.524
			c0.851,0.804,1.701,1.596,2.494,2.435c0.315,0.326,0.524,0.757,0.746,1.165c0.186,0.361,0.186,0.757,0.023,1.13
			c-0.151,0.35-0.315,0.722-0.548,1.025c-0.571,0.734-1.154,1.468-1.795,2.144c-1.445,1.515-2.948,2.971-4.393,4.498
			c-1.037,1.084-2.272,1.573-3.752,1.526c-0.151,0-0.315,0-0.676,0c-1.713,0.151-3.274-0.664-4.649-1.888
			c-0.99-0.874-1.934-1.806-2.808-2.785c-1.014-1.119-1.631-2.447-1.655-3.997c0-0.315-0.023-0.618-0.023-0.932
			c-0.023-1.387,0.419-2.587,1.398-3.577c0.548-0.548,1.095-1.095,1.631-1.666c0.629-0.676,1.235-1.387,1.864-2.074
			c0.419-0.454,0.839-0.921,1.27-1.363c1.841-1.888,3.694-3.764,5.535-5.663c0.921-0.944,1.841-1.876,2.668-2.925
			c0.676-0.851,1.457-1.608,2.412-2.167c0.618-0.361,1.258-0.571,1.981-0.583c1.025-0.023,2.016,0.151,2.983,0.396
			c0.851,0.221,1.561,0.711,2.202,1.27c1.34,1.165,2.668,2.365,3.857,3.706C161.413,499.161,161.821,500.21,161.786,501.434z
			 M195.8,530.519c-1.235,2.424-2.634,4.743-4.277,6.922c-0.524,0.699-1.177,1.224-1.969,1.585c-0.489,0.221-0.979,0.466-1.48,0.688
			c-1.119,0.501-1.899,1.328-2.342,2.47c-0.256,0.653-0.501,1.305-0.711,1.969c-0.664,2.063-1.317,4.137-1.981,6.211
			c-0.093,0.291-0.21,0.583-0.315,0.874c-0.361,1.06-0.99,1.387-2.202,1.107c-0.373-0.082-0.746-0.221-1.072-0.42
			c-1.678-1.014-2.156-2.039-1.678-3.892c0.291-1.119,0.699-2.214,1.06-3.321c0.501-1.55,1.06-3.088,1.387-4.684
			c0.058-0.303,0.07-0.618,0.07-0.921c-0.012-0.571-0.641-1.189-1.235-1.165c-0.291,0.012-0.629,0.093-0.862,0.256
			c-0.711,0.489-1.2,1.189-1.643,1.934c-0.956,1.596-1.946,3.17-2.936,4.754c-0.338,0.536-0.804,0.932-1.387,1.2
			c-0.676,0.315-1.34,0.338-2.028,0.058c-0.653-0.268-1.212-0.688-1.713-1.189c-0.478-0.489-0.688-1.06-0.419-1.69
			c0.396-0.921,0.827-1.841,1.328-2.715c0.816-1.41,1.725-2.762,2.587-4.148c2.366-3.822,4.719-7.656,7.085-11.478
			c1.025-1.643,2.063-3.286,3.111-4.917c0.291-0.454,0.629-0.886,0.979-1.293c0.699-0.781,1.596-0.921,2.552-0.606
			c0.513,0.175,1.014,0.408,1.468,0.688c2.109,1.317,4.16,2.703,6.292,3.974c1.958,1.165,2.587,2.983,2.797,5.069
			c0.012,0.151,0,0.315,0,0.513C196.325,529.062,196.162,529.82,195.8,530.519z M231.866,543.29
			c-0.478,2.272-0.921,4.556-1.398,6.84c-0.501,2.435-1.037,4.859-1.55,7.283c-0.641,3.03-1.293,6.071-1.934,9.101
			c-0.163,0.757-0.291,1.527-0.478,2.272c-0.186,0.757-0.361,1.515-0.629,2.237c-0.233,0.629-0.746,0.967-1.433,1.014
			c-0.711,0.047-1.398-0.012-2.051-0.28c-0.676-0.28-1.2-0.711-1.422-1.433c-0.105-0.373-0.21-0.746-0.303-1.107
			c0.221-1.247,0.35-2.47,0.373-3.717c0.012-1.585-1.41-2.832-2.797-2.867c-0.827-0.023-1.55,0.198-2.074,0.851
			c-0.431,0.548-0.827,1.13-1.165,1.736c-0.419,0.757-0.956,1.41-1.608,1.981c-0.501,0.443-1.095,0.583-1.736,0.466
			c-0.303-0.058-0.606-0.151-0.897-0.233c-1.375-0.408-1.655-0.921-1.468-2.447c0.047-0.373,0.198-0.746,0.361-1.095
			c0.291-0.629,0.618-1.258,0.956-1.864c1.69-2.971,3.391-5.931,5.069-8.903c1.643-2.901,3.263-5.826,4.894-8.728
			c0.757-1.352,1.526-2.703,2.284-4.055c0.501-0.897,1.293-1.305,2.272-1.422c0.618-0.07,1.247-0.023,1.841,0.21
			c1.154,0.443,2.331,0.862,3.473,1.352C231.621,540.96,232.145,541.985,231.866,543.29z M275.563,557.693
			c-0.07,1.561-0.454,3.065-0.688,4.591c-0.396,2.529-0.851,5.046-1.282,7.563c-0.431,2.447-0.862,4.882-1.293,7.318
			c-0.513,2.855-2.47,4.37-4.987,5.325c-0.804,0.303-1.69,0.291-2.529,0.14c-2.447-0.431-4.917-0.664-7.318-1.294
			c-0.524-0.14-1.037-0.315-1.527-0.524c-0.606-0.256-0.979-0.746-1.119-1.422c0.117-2.144,0.559-4.265,0.886-6.409
			c0.385-2.529,0.932-5.022,1.27-7.563c0.233-1.76,0.594-3.508,0.897-5.267c0.431-2.517,0.862-5.034,1.317-7.551
			c0.128-0.688,0.303-1.363,0.524-2.016c0.315-0.944,0.909-1.328,1.934-1.352c0.315,0,0.629-0.023,0.932,0.023
			c2.517,0.431,5.034,0.886,7.551,1.328c0.082,0.012,0.151,0.035,0.233,0.047c1.888,0.35,3.17,1.55,4.172,3.088
			C275.295,554.931,275.633,556.236,275.563,557.693z M317.746,582.886c-0.653,0.851-1.457,1.608-2.237,2.354
			c-0.268,0.256-0.676,0.385-1.037,0.489c-0.373,0.105-0.757,0.163-1.142,0.175c-1.713,0.023-3.414,0.023-5.127,0.035
			c-1.06,0.012-1.981-0.361-2.762-1.06c-0.583-0.513-1.13-1.06-1.701-1.585c-1.095-1.002-1.55-2.296-1.62-3.741
			c-0.023-0.466-0.023-0.932-0.035-1.398c-0.023-4.428-0.058-8.856-0.082-13.284c-0.012-1.398-0.047-2.785-0.221-4.183
			c-0.163-1.224-0.14-2.482-0.186-3.717c-0.012-0.315,0.012-0.618,0.058-0.932c0.233-1.62,0.757-2.296,2.505-2.564
			c0.676-0.105,1.398-0.058,2.086,0.058c0.734,0.128,1.247,0.618,1.41,1.375c0.245,1.142,0.373,2.284,0.373,3.461
			c0,5.908,0.035,11.804,0.047,17.712c0,0.618,0,1.247,0.023,1.864c0.023,0.757,0.396,1.328,1.014,1.736
			c0.256,0.175,0.536,0.303,0.827,0.431c0.373,0.163,0.757,0.14,1.13-0.035c0.21-0.105,0.42-0.198,0.618-0.326
			c0.559-0.35,0.932-0.839,0.99-1.503c0.07-0.769,0.117-1.55,0.117-2.319c0-3.111,0-6.211-0.023-9.322
			c-0.012-1.235-0.093-2.47-0.256-3.717c-0.28-2.156-0.163-4.346-0.093-6.514c0.012-0.385,0.186-0.746,0.245-1.13
			c0.198-1.084,0.967-1.538,1.923-1.771c0.769-0.186,1.538-0.175,2.319-0.035c0.571,0.093,0.99,0.443,1.293,0.921
			c0.303,0.466,0.478,0.979,0.513,1.526c0.035,0.548,0.047,1.084,0.047,1.631c0,3.496,0,6.992,0,11.525
			c0.035,2.459,0.093,5.955,0.151,9.439c0,0.233-0.012,0.466,0.012,0.699C319.016,580.579,318.562,581.814,317.746,582.886z
			 M365.872,577.654c-0.082,0.676-0.408,1.142-1.049,1.41c-0.804,0.35-1.643,0.431-2.505,0.431c-0.769,0-1.387-0.35-1.736-0.99
			c-0.454-0.816-0.827-1.666-1.165-2.529c-0.326-0.827-0.827-1.48-1.515-2.016c-0.594-0.466-1.258-0.571-1.981-0.385
			c-1.177,0.303-1.888,1.084-2.063,2.261c-0.105,0.757-0.07,1.55-0.105,2.319c0.023,0,0.047,0,0.058,0
			c-0.035,0.618-0.047,1.235-0.093,1.864c-0.07,0.874-0.559,1.41-1.375,1.69c-0.967,0.326-1.923,0.618-2.96,0.419
			c-0.583-0.117-0.979-0.443-1.189-0.99c-0.28-0.734-0.338-1.503-0.35-2.284c-0.035-1.631-0.058-3.263-0.128-4.894
			c-0.035-0.851-0.163-1.701-0.245-2.552c-0.058-0.699-0.14-1.387-0.163-2.086c-0.047-1.631-0.07-3.263-0.105-4.894
			c-0.035-1.864,0.023-3.729-0.303-5.57c-0.117-0.688-0.082-1.398-0.128-2.086c-0.082-1.084-0.163-2.167-0.268-3.239
			c-0.105-0.944-0.082-1.864,0.28-2.75c0.117-0.268,0.315-0.559,0.559-0.711c0.524-0.326,1.084-0.618,1.655-0.827
			c1.398-0.489,2.867-0.699,4.323-0.909c0.932-0.14,1.678,0.245,2.226,0.979c0.326,0.431,0.606,0.909,0.816,1.398
			c0.524,1.293,1.014,2.61,1.492,3.915c1.293,3.496,2.587,6.992,3.892,10.476c1.084,2.913,2.179,5.815,3.251,8.728
			c0.245,0.653,0.466,1.317,0.676,1.981C365.883,576.419,365.941,577.036,365.872,577.654z M401.459,566.933
			c-1.095,0.886-2.365,1.107-3.717,0.781c-0.385-0.093-0.734-0.303-0.921-0.653c-0.326-0.618-0.653-1.235-0.909-1.876
			c-1.934-4.813-3.857-9.637-5.791-14.449c-0.524-1.293-1.072-2.575-1.608-3.857c-0.058-0.14-0.14-0.28-0.21-0.419
			c-0.524-1.084-1.375-1.631-2.599-1.596c-0.385,0.012-0.769,0-1.165,0c0,0.023,0,0.047,0,0.082c-0.466,0-0.932,0.058-1.387-0.012
			c-0.373-0.058-0.781-0.175-1.06-0.408c-0.967-0.804-1.375-1.841-1.072-3.111c0.117-0.466,0.361-0.862,0.792-1.095
			c0.548-0.291,1.095-0.571,1.678-0.804c2.878-1.154,5.756-2.284,8.623-3.449c0.722-0.291,1.48-0.489,2.237-0.583
			c0.827-0.105,1.678,0.198,2.121,1.095c0.105,0.21,0.21,0.419,0.303,0.629c0.489,1.037,0.268,1.806-0.594,2.738
			c-0.21,0.221-0.489,0.373-0.746,0.559c-1.55,1.107-1.923,2.773-1.259,4.533c0.326,0.874,0.688,1.725,1.037,2.587
			c1.235,3.006,2.47,6.013,3.694,9.031c0.816,2.004,1.631,4.02,2.412,6.036c0.303,0.792,0.583,1.596,0.804,2.424
			C402.345,565.838,402.042,566.467,401.459,566.933z M444.737,544.094c-0.117,0.105-0.233,0.198-0.361,0.28
			c-2.447,1.631-4.871,3.309-7.423,4.789c-0.559,0.326-1.142,0.454-1.643,0.454c-0.956-0.012-1.643-0.315-2.121-1.002
			c-0.443-0.641-0.886-1.27-1.305-1.923c-1.41-2.226-2.785-4.475-4.207-6.689c-1.701-2.68-3.426-5.349-5.139-8.029
			c-1.212-1.888-2.424-3.776-3.647-5.675c-0.513-0.792-0.897-1.62-0.816-2.61c0.047-0.571,0.245-1.06,0.722-1.387
			c1.095-0.734,2.179-1.48,3.286-2.191c1.363-0.886,2.75-1.736,4.113-2.622c0.676-0.443,1.41-0.606,2.202-0.583
			c1.06,0.035,1.701,0.513,2.063,1.561c0.338,0.967,0.175,1.864-0.583,2.564c-0.629,0.571-1.352,1.037-2.028,1.55
			c-0.245,0.186-0.513,0.35-0.734,0.571c-0.897,0.932-1.34,2.109-0.769,3.391c0.454,1.002,1.002,1.911,1.818,2.68
			c0.862,0.816,2.132,1.072,3.228,0.571c0.42-0.186,0.804-0.466,1.224-0.664c0.489-0.233,1.002-0.419,1.503-0.618
			c0.909-0.35,1.69-0.082,2.342,0.548c0.734,0.711,0.909,1.608,0.629,2.564c-0.175,0.606-0.513,1.142-1.049,1.492
			c-0.781,0.513-1.492,1.119-2.109,1.818c-0.373,0.431-0.524,0.932-0.513,1.503c0.035,1.387,0.711,2.517,1.48,3.601
			c0.175,0.256,0.42,0.454,0.629,0.688c0.944,1.084,2.144,1.189,3.391,0.734c0.722-0.268,1.375-0.699,2.063-1.06
			c0.478-0.256,0.932-0.571,1.433-0.769c0.35-0.128,0.757-0.14,1.13-0.128c0.478,0.023,0.921,0.245,1.177,0.653
			c0.291,0.454,0.559,0.932,0.734,1.445C445.821,542.568,445.541,543.43,444.737,544.094z M479.532,513.844
			c-0.163,1.27-0.757,2.365-1.643,3.274c-1.142,1.165-2.319,2.296-3.496,3.414c-0.711,0.676-1.631,0.886-2.552,1.025
			c-0.688,0.105-1.387,0.093-2.086,0.093c-1.829,0-3.286-0.769-4.486-2.121c-0.373-0.431-0.571-0.921-0.618-1.468
			c-0.151-1.923,1.282-3.449,3.239-3.391c0.454,0.012,0.921,0.128,1.375,0.21c0.454,0.082,0.909,0.221,1.363,0.268
			c0.897,0.105,1.678-0.186,2.377-0.746c0.268-0.221,0.42-0.722,0.303-1.06c-0.338-1.049-0.827-2.004-1.631-2.785
			c-0.28-0.268-0.548-0.548-0.816-0.827c-0.722-0.746-1.561-1.282-2.855-1.352c-1.165,0.513-2.214,1.2-3.053,2.121
			c-1.445,1.585-3.216,2.063-5.267,1.771c-0.559-0.082-1.049-0.268-1.468-0.629c-0.291-0.256-0.594-0.501-0.862-0.781
			c-1.631-1.666-3.274-3.309-4.871-4.999c-1.154-1.224-1.853-2.657-1.911-4.381c-0.047-1.701,0.338-3.216,1.62-4.44
			c1.014-0.967,1.958-1.993,2.995-2.936c0.688-0.618,1.468-1.142,2.097-1.62c1.282-0.385,2.447-0.303,3.612-0.198
			c0.874,0.082,1.666,0.385,2.319,0.967c0.466,0.42,0.897,0.851,1.317,1.317c0.466,0.513,1.025,0.956,1.352,1.69
			c-0.082,0.233-0.14,0.548-0.315,0.792c-0.618,0.897-1.41,1.655-2.272,2.307c-0.326,0.245-0.722,0.291-1.107,0.175
			c-0.594-0.163-1.177-0.396-1.771-0.559c-0.443-0.117-0.909-0.221-1.363-0.245c-0.839-0.023-1.503,0.315-1.853,1.142
			c-0.093,0.21-0.14,0.443-0.21,0.653c0.093,1.934,1.363,3.088,2.738,4.113c1.025,0.769,2.167,0.804,3.274,0.117
			c0.653-0.408,1.27-0.886,1.899-1.352c0.245-0.186,0.466-0.408,0.699-0.618c1.445-1.363,3.181-1.492,5.011-1.119
			c0.291,0.058,0.583,0.221,0.839,0.373c0.804,0.478,1.55,1.025,2.202,1.701c1.352,1.398,2.715,2.773,4.09,4.148
			c0.664,0.664,1.212,1.41,1.666,2.237C479.777,511.373,479.695,512.62,479.532,513.844z M527.308,465.275
			c-0.105,0.117-0.198,0.245-0.315,0.35c-0.233,0.198-0.489,0.385-0.734,0.559c-0.757,0.548-1.526,1.072-2.249,1.643
			c-0.548,0.431-1.06,0.909-1.527,1.422c-0.653,0.711-0.956,1.573-0.944,2.552c0.012,0.711,0.117,1.387,0.326,2.063
			c0.35,1.107,0.676,2.226,0.967,3.356c0.07,0.28,0.035,0.629-0.058,0.897c-0.163,0.454-0.513,0.769-1.165,0.851
			c-0.303-0.163-0.734-0.35-1.119-0.594c-0.781-0.501-1.538-1.049-2.307-1.573c-0.385-0.256-0.769-0.536-1.177-0.757
			c-1.154-0.641-2.365-0.734-3.577-0.198c-0.629,0.28-1.235,0.653-1.83,1.014c-0.594,0.373-1.154,0.792-1.736,1.177
			c-0.326,0.21-0.676,0.385-1.025,0.536c-0.757,0.338-1.631-0.221-1.655-1.072c-0.012-0.385,0.012-0.781,0.105-1.154
			c0.151-0.606,0.373-1.189,0.559-1.771c0.163-0.513,0.326-1.037,0.478-1.561c0.513-1.853-0.012-3.368-1.515-4.545
			c-0.851-0.676-1.76-1.282-2.61-1.946c-0.489-0.385-0.944-0.804-1.387-1.235c-0.151-0.151-0.21-0.396-0.303-0.618
			c-0.105-0.256,0.14-0.699,0.408-0.722c0.385-0.047,0.769-0.093,1.154-0.093c1.48-0.012,2.948,0,4.428-0.012
			c0.466,0,0.932,0,1.398-0.023c0.583-0.023,1.06-0.303,1.34-0.804c0.385-0.676,0.734-1.363,1.014-2.086
			c0.361-0.944,0.629-1.923,0.979-2.867c0.385-1.014,0.804-2.016,1.235-3.006c0.151-0.35,0.816-0.385,0.99-0.07
			c0.186,0.338,0.385,0.676,0.513,1.037c0.454,1.317,0.862,2.657,1.328,3.974c0.291,0.804,0.641,1.573,1.002,2.354
			c0.315,0.676,0.897,1.037,1.608,1.212c0.757,0.186,1.527,0.268,2.307,0.268c1.084,0.012,2.167,0,3.263,0.035
			c0.629,0.012,1.224,0.175,1.736,0.571C527.425,464.611,527.495,465.066,527.308,465.275z"
          />
        </g>
      </g>
      <g id="below_1_">
        <g id="Shape_1_copy_9">
          <path
            fill="#FFFFFF"
            d="M434.343,644.459c0-19.262,0.012-38.524,0.012-57.786c0-0.781-0.012-1.55-0.047-2.331
			c-0.023-0.478-0.186-0.921-0.478-1.282c-0.361-0.443-0.862-0.757-1.422-0.618c-1.119,0.291-1.958,0.921-2.214,2.144
			c-0.175,0.839-0.338,1.678-0.315,2.54c0.012,0.699,0,1.398,0,2.097c0,21.045-0.012,42.09-0.012,63.146
			c0,1.165-0.012,2.331-0.023,3.496c-0.012,0.385-0.035,0.781-0.093,1.154c-0.128,0.839-0.909,1.561-1.748,1.631
			c-0.618,0.058-1.235,0.023-1.841-0.14c-1.876-0.501-3.764-0.956-5.628-1.468c-5.244-1.433-10.464-2.913-15.708-4.346
			c-4.568-1.247-9.147-2.459-13.715-3.694c-5.465-1.48-10.942-2.96-16.407-4.44c-1.946-0.524-3.892-1.084-5.826-1.62
			c-3.368-0.932-6.724-1.876-10.091-2.797c-6.141-1.678-12.282-3.344-18.423-4.999c-4.871-1.317-9.742-2.633-14.612-3.95
			c-3.671-0.99-7.341-1.993-11.012-2.995c-1.876-0.513-3.729-1.107-5.628-1.503c-3.298-0.676-2.61-0.862-6.164,0.058
			c-4.661,1.212-9.299,2.494-13.937,3.764c-6.292,1.713-12.573,3.449-18.866,5.174c-5.092,1.398-10.184,2.785-15.277,4.172
			c-3.368,0.921-6.735,1.83-10.115,2.75c-4.871,1.328-9.73,2.657-14.601,3.985c-4.346,1.177-8.693,2.342-13.028,3.542
			c-5.092,1.398-10.173,2.832-15.253,4.23c-5.011,1.375-10.033,2.738-15.055,4.09c-0.746,0.198-1.503,0.385-2.261,0.548
			c-0.722,0.151-1.387-0.07-1.981-0.478c-0.408-0.28-0.676-0.653-0.722-1.142c-0.047-0.536-0.035-1.084-0.035-1.631
			c0-9.555,0-19.11-0.012-28.654c0-13.517,0-27.023-0.012-40.54c0-0.769,0.093-1.55-0.186-2.459c-0.303-0.28-0.664-0.676-1.095-0.99
			c-0.524-0.396-1.142-0.443-1.783-0.326c-0.571,0.105-1.014,0.548-1.037,1.095c-0.035,0.699-0.047,1.398-0.047,2.098
			c0,24.389,0,48.778,0,73.156c0,0.699,0,1.398,0.012,2.097c0.012,0.385,0.035,0.781,0.105,1.154
			c0.198,1.13,1.165,1.911,2.331,1.853c0.466-0.023,0.921-0.105,1.375-0.221c2.109-0.536,4.207-1.095,6.304-1.666
			c3.974-1.072,7.935-2.167,11.909-3.239c2.692-0.734,5.395-1.468,8.087-2.202c4.265-1.165,8.541-2.331,12.806-3.496
			c2.843-0.781,5.687-1.55,8.53-2.342c3.146-0.862,6.281-1.76,9.427-2.61c4.416-1.2,8.844-2.377,13.261-3.589
			c6.292-1.713,12.573-3.438,18.866-5.162c5.092-1.387,10.184-2.785,15.277-4.172c2.4-0.653,4.789-1.305,7.19-1.958
			c3.228-0.874,6.444-1.725,9.672-2.599c2.144-0.583,4.288-0.583,6.444-0.012c1.422,0.385,2.843,0.769,4.265,1.165
			c3.065,0.851,6.141,1.69,9.206,2.552c2.319,0.653,4.626,1.328,6.945,1.958c6.141,1.666,12.282,3.321,18.435,4.987
			c4.941,1.34,9.882,2.692,14.822,4.044c3.671,1.002,7.341,2.004,11,3.006c4.113,1.13,8.227,2.272,12.352,3.391
			c6.071,1.655,12.142,3.286,18.202,4.941c4.568,1.247,9.124,2.505,13.692,3.764c3.741,1.025,7.493,2.039,11.222,3.1
			c1.142,0.326,2.272,0.524,3.449,0.373c0.501-0.058,0.862-0.35,1.095-0.769c0.268-0.478,0.419-1.002,0.443-1.55
			c0.023-0.699,0.035-1.398,0.035-2.097C434.355,654.713,434.343,649.586,434.343,644.459z"
          />
        </g>
      </g>
      <g id="_x32_019_1_">
        <g id="Shape_1_copy_7">
          <path
            d="M354.685,492.881c0.303,0.047,0.606,0.128,0.909,0.128c1.084,0.023,2.167,0.012,3.263,0.012
			c1.165,0,2.331-0.023,3.484-0.047 M265.181,499.767c0,0.221-0.07,0.454-0.105,0.722c-0.198,0.093-0.385,0.245-0.606,0.268
			c-0.385,0.058-0.769,0.047-1.165,0.047c-6.759,0-13.505,0-20.264,0c-0.536,0-1.072-0.058-1.62-0.082
			c-0.105-0.256-0.245-0.454-0.268-0.664c-0.047-0.466-0.047-0.932-0.047-1.398c0-5.663,0-11.338,0-17.001v-1.527
			c1.107-1.387,2.482-2.657,5.116-4.731c0.431-0.023,0.967-0.082,1.503-0.093c2.564-0.012,5.127,0,7.679-0.012
			c0.466,0,0.932-0.012,1.387-0.105c0.186-0.035,0.385-0.245,0.489-0.431c0.105-0.198,0.117-0.454,0.117-0.676
			c0.012-2.482,0.012-4.964,0-7.446c0-0.151-0.035-0.303-0.047-0.466c-0.035-0.256-0.361-0.618-0.594-0.641
			c-0.233-0.035-0.454-0.082-0.687-0.082c-1.864,0-3.729,0-5.593,0c-0.396,0-0.781,0.058-1.072,0.385
			c-0.489,0.967,0.151,2.156-0.571,3.158c-2.249,0.082-4.498,0.035-6.747,0.023c-0.221,0-0.443-0.082-0.641-0.117
			c-0.105-0.163-0.256-0.28-0.268-0.408c-0.14-2.086-0.117-4.183-0.012-6.316c1.55-1.643,3.274-3.076,4.952-4.463
			c1.806-0.163,11.734-0.186,14.03-0.035c1.596,1.165,3.612,2.936,4.894,4.3c0.035,0.198,0.117,0.419,0.117,0.653
			c0.012,5.127,0.012,10.243,0,15.37c0,0.221-0.07,0.454-0.128,0.769c-0.233,0.233-0.489,0.513-0.781,0.769
			c-1.34,1.165-2.692,2.331-4.078,3.507c-0.629,0.035-1.247,0.082-1.853,0.093c-2.412,0.012-4.813,0-7.225,0.012
			c-0.466,0-0.921,0.047-1.387,0.082c-0.256,0.023-0.571,0.35-0.629,0.618c-0.047,0.221-0.117,0.454-0.117,0.688
			c-0.012,2.412-0.012,4.813,0,7.225c0,0.396,0.07,0.769,0.42,1.095c0.175,0.035,0.408,0.128,0.629,0.128
			c2.097,0.012,4.195,0.012,6.281,0c0.221,0,0.454-0.058,0.664-0.151c0.128-0.058,0.268-0.198,0.303-0.326
			c0.07-0.291,0.128-0.606,0.128-0.921c-0.012-2.039,0.151-1.993,2.144-1.993c1.398,0,2.797,0,4.195,0.012
			c0.303,0,0.629,0.012,0.921,0.117c0.186,0.07,0.35,0.291,0.431,0.478c0.093,0.21,0.082,0.454,0.082,0.688
			C265.192,493.871,265.192,496.819,265.181,499.767z M319.377,491.774c0,0.396,0.07,0.769,0.419,1.095
			c0.175,0.035,0.408,0.128,0.629,0.128c2.097,0.012,4.195,0.012,6.281,0c0.221,0,0.454-0.058,0.664-0.151
			c0.128-0.058,0.268-0.198,0.303-0.326c0.082-0.291,0.128-0.606,0.128-0.921c-0.012-2.039,0.151-1.993,2.144-1.993
			c1.398,0,2.797,0,4.195,0.012c0.303,0,0.629,0.012,0.909,0.117c0.186,0.07,0.35,0.291,0.431,0.478
			c0.093,0.21,0.082,0.454,0.093,0.688c0,2.948,0,5.896-0.012,8.844c0,0.221-0.07,0.454-0.105,0.722
			c-0.198,0.093-0.385,0.245-0.594,0.268c-0.385,0.058-0.769,0.047-1.165,0.047c-6.759,0-13.505,0-20.264,0
			c-0.536,0-1.072-0.058-1.631-0.082c-0.105-0.256-0.245-0.454-0.268-0.664c-0.047-0.466-0.047-0.932-0.047-1.398
			c0-5.663,0-11.338,0-17.001v-1.527c1.107-1.387,2.482-2.657,5.116-4.731c0.431-0.023,0.967-0.082,1.503-0.093
			c2.564-0.012,5.127,0,7.679-0.012c0.466,0,0.932-0.012,1.387-0.105c0.186-0.035,0.396-0.245,0.489-0.431
			c0.105-0.198,0.117-0.454,0.117-0.676c0.012-2.482,0.012-4.964,0-7.446c0-0.151-0.035-0.303-0.047-0.466
			c-0.035-0.256-0.361-0.618-0.594-0.641c-0.233-0.035-0.466-0.082-0.688-0.082c-1.864,0-3.729,0-5.593,0
			c-0.396,0-0.781,0.058-1.072,0.385c-0.489,0.967,0.151,2.156-0.571,3.158c-2.249,0.082-4.498,0.035-6.747,0.023
			c-0.221,0-0.443-0.082-0.641-0.117c-0.105-0.163-0.256-0.28-0.268-0.408c-0.14-2.086-0.117-4.183-0.012-6.316
			c1.55-1.643,3.274-3.076,4.952-4.463c1.806-0.163,11.734-0.186,14.03-0.035c1.596,1.165,3.612,2.936,4.894,4.3
			c0.035,0.198,0.117,0.42,0.117,0.653c0.012,5.127,0.012,10.243,0,15.37c-0.012,0.221-0.082,0.454-0.128,0.769
			c-0.233,0.233-0.489,0.513-0.781,0.769c-1.34,1.165-2.703,2.331-4.078,3.507c-0.629,0.035-1.247,0.082-1.853,0.093
			c-2.412,0.012-4.813,0-7.225,0.012c-0.466,0-0.932,0.047-1.387,0.082c-0.256,0.023-0.571,0.35-0.629,0.618
			c-0.047,0.221-0.117,0.454-0.117,0.688C319.377,486.961,319.377,489.361,319.377,491.774z M354.685,492.881
			c0.303,0.047,0.606,0.128,0.909,0.128c1.084,0.023,2.167,0.012,3.263,0.012c1.165,0,2.331-0.023,3.484-0.047 M354.685,492.881
			c0.303,0.047,0.606,0.128,0.909,0.128c1.084,0.023,2.167,0.012,3.263,0.012c1.165,0,2.331-0.023,3.484-0.047 M354.685,492.881
			c0.303,0.047,0.606,0.128,0.909,0.128c1.084,0.023,2.167,0.012,3.263,0.012c1.165,0,2.331-0.023,3.484-0.047 M354.685,492.881
			c0.303,0.047,0.606,0.128,0.909,0.128c1.084,0.023,2.167,0.012,3.263,0.012c1.165,0,2.331-0.023,3.484-0.047"
          />
        </g>
      </g>
      <g id="seniors_1_">
        <g id="Shape_1_copy_3">
          <path
            d="M611.65,337.154c0.012-1.422-0.688-2.517-1.561-3.531c-0.303-0.35-0.641-0.676-0.979-1.002
			c-1.258-1.27-2.529-2.529-3.787-3.787c-0.326-0.326-0.664-0.653-0.944-1.037c-0.175-0.245-0.256-0.559-0.326-0.851
			c-0.082-0.361,0.256-0.851,0.583-0.874c0.385-0.035,0.769-0.07,1.154-0.082c1.084-0.012,2.179,0.023,3.263-0.012
			c0.536-0.012,1.084-0.082,1.608-0.21c0.478-0.117,0.827-0.466,0.874-0.979c0.07-0.699,0.093-1.398,0.093-2.086
			c0-12.189,0-24.389,0-36.578c0-0.781-0.047-1.55-0.035-2.331c0.023-1.643-0.524-3.041-1.76-4.148
			c-0.175-0.151-0.326-0.338-0.489-0.501c-6.805-6.817-13.622-13.622-20.427-20.439c-0.443-0.443-0.886-0.886-1.352-1.282
			c-0.862-0.711-1.841-1.154-2.983-1.142c-0.618,0.012-1.247,0-1.864,0c-7.772,0-15.533,0-23.305,0c-7.376,0-14.752,0-22.14,0.012
			c-1.165,0-2.331,0.058-3.496,0.117c-1.037,0.047-1.969,0.408-2.773,1.072c-0.956,0.792-1.946,1.538-2.808,2.435
			c-2.004,2.063-4.055,4.067-6.083,6.106c-0.548,0.548-1.025,1.154-1.561,1.725c-0.862,0.897-1.725,1.783-2.599,2.668
			c-0.722,0.734-2.459,0.653-3.053,0.14c-0.874-0.769-1.771-1.503-2.633-2.296c-1.142-1.049-2.098-2.284-3.17-3.403
			c-2.365-2.459-4.778-4.882-7.376-7.108c-0.921-0.781-1.934-1.293-3.146-1.34c-1.165-0.047-2.331-0.105-3.496-0.105
			c-24.774-0.012-49.547-0.012-74.333,0c-0.618,0-1.247,0-1.864,0.047c-0.303,0.023-0.618,0.117-0.897,0.233
			c-0.454,0.186-0.781,0.513-0.851,1.025c-0.047,0.385-0.07,0.769-0.07,1.154c-0.012,1.165,0,2.331-0.012,3.496
			c0,0.466-0.07,0.921-0.268,1.363c-0.291,0.653-1.165,0.967-1.76,0.571c-0.385-0.256-0.757-0.548-1.084-0.874
			c-1.27-1.247-2.529-2.529-3.799-3.787c-0.548-0.548-1.084-1.107-1.666-1.62c-1.2-1.06-2.54-1.795-4.218-1.608
			c-0.082,0.012-0.151,0-0.233,0c-16.232,0-32.464,0-48.697,0.012c-0.932,0-1.864,0.058-2.797,0.105
			c-1.212,0.058-2.261,0.524-3.123,1.375c-0.781,0.757-1.538,1.538-2.331,2.284c-0.338,0.315-0.699,0.606-1.072,0.886
			c-0.373,0.268-1.014-0.023-1.095-0.489c-0.035-0.233-0.082-0.454-0.093-0.688c-0.023-0.618-0.012-1.247-0.058-1.864
			c-0.058-0.594-0.396-1.025-0.967-1.2c-0.513-0.163-1.049-0.291-1.585-0.315c-1.468-0.07-2.948-0.093-4.428-0.093
			c-32.931,0-65.861,0-98.792-0.012c-4.428,0-8.856,0-13.284,0c-0.699,0-1.398,0-2.097,0.058c-1.002,0.082-1.585,0.664-1.655,1.631
			c-0.047,0.618-0.035,1.247-0.035,1.864c0,3.414,0,6.84,0,10.254c0,0.536-0.047,1.084-0.082,1.62
			c-0.012,0.245-0.361,0.419-0.711,0.385c-0.07-0.012-0.14-0.047-0.303-0.105c-0.583-0.932-1.025-2.004-1.41-3.1
			c-0.851-2.412-1.713-4.824-2.587-7.236c-0.373-1.025-0.746-2.039-1.177-3.041c-0.21-0.501-0.501-0.967-0.804-1.41
			c-0.373-0.536-0.909-0.851-1.573-0.874c-0.781-0.023-1.55-0.035-2.331-0.035c-38.99,0-77.98,0-116.97,0
			c-0.699,0-1.398-0.012-2.097,0.023c-0.385,0.012-0.769,0.07-1.154,0.163c-0.583,0.14-0.932,0.559-1.049,1.13
			c-0.082,0.373-0.105,0.769-0.105,1.154c-0.012,0.781,0.012,1.55-0.012,2.331c-0.012,0.385-0.058,0.769-0.117,1.154
			c-0.058,0.419-0.583,0.792-1.025,0.781c-0.571-0.012-1.06-0.198-1.468-0.594c-0.722-0.699-1.445-1.41-2.167-2.121
			c-0.886-0.874-1.771-1.748-2.668-2.61c-0.816-0.781-1.795-1.212-2.925-1.282c-0.851-0.047-1.701-0.105-2.564-0.117
			c-15.615-0.012-31.218-0.012-46.832,0c-0.781,0-1.55,0.047-2.331,0.07c-0.629,0.023-1.212,0.221-1.783,0.478
			c-1.084,0.501-1.923,1.293-2.75,2.121c-6.537,6.537-13.074,13.074-19.611,19.6c-0.28,0.28-0.559,0.548-0.816,0.827
			c-1.293,1.433-2.051,3.088-2.028,5.057c0,0.466-0.012,0.932-0.012,1.398c0.012,18.015,0.012,36.042,0.023,54.057
			c0,0.618-0.012,1.247,0.023,1.864c0.117,1.76,0.664,3.333,1.958,4.603c1.27,1.247,2.529,2.529,3.787,3.799
			c0.443,0.443,0.909,0.862,1.282,1.352c0.326,0.419,0.606,0.909,0.781,1.41c0.233,0.664-0.07,1.62-1.06,1.725
			c-0.688,0.07-1.398,0.07-2.086,0.093c-1.084,0.035-2.179,0.035-3.263,0.117c-0.454,0.035-0.897,0.221-1.328,0.396
			c-0.291,0.117-0.513,0.385-0.536,0.711c-0.058,0.618-0.093,1.235-0.093,1.853c0,12.27,0,24.541,0,36.811
			c0,0.699-0.012,1.398,0.047,2.097c0.093,1.259,0.466,2.424,1.189,3.484c0.536,0.781,1.177,1.445,1.841,2.097
			c5.605,5.593,11.21,11.198,16.85,16.768c1.375,1.363,2.855,2.622,4.288,3.927c0.117,0.105,0.233,0.198,0.361,0.303
			c0.699,0.536,1.48,0.851,2.365,0.862c0.851,0.012,1.713,0.035,2.564,0.035c11.023,0,22.059,0,33.082,0c4.58,0,9.171,0,13.75,0
			c0.851,0,1.713-0.023,2.564-0.035c1.119-0.023,2.132-0.408,3.03-1.06c0.443-0.315,0.839-0.688,1.224-1.072
			c0.944-0.921,1.864-1.864,2.82-2.785c0.443-0.431,0.932-0.816,1.433-1.177c0.245-0.175,0.559-0.256,0.851-0.338
			c0.536-0.14,1.154,0.245,1.224,0.781c0.082,0.688,0.105,1.387,0.14,2.086c0.035,0.699,0.035,1.398,0.117,2.086
			c0.082,0.676,0.513,1.119,1.177,1.293c0.373,0.093,0.757,0.186,1.142,0.198c0.699,0.035,1.398,0.023,2.097,0.023
			c30.6,0,61.2,0,91.8,0.012c9.94,0,19.88,0,29.819,0c0.699,0,1.398,0,2.097-0.047c0.769-0.058,1.492-0.746,1.561-1.492
			c0.058-0.618,0.058-1.235,0.058-1.864c0-3.729-0.012-7.458,0-11.187c0-0.385,0.07-0.757,0.14-1.13
			c0.035-0.163,0.221-0.245,0.373-0.151c0.256,0.163,0.559,0.338,0.699,0.583c0.419,0.746,0.816,1.503,1.13,2.296
			c0.513,1.293,0.967,2.622,1.445,3.939c0.769,2.121,1.538,4.23,2.296,6.351c0.291,0.816,0.746,1.527,1.363,2.109
			c0.886,0.431,1.795,0.583,2.738,0.583c0.851,0.012,1.713,0.023,2.564,0.023c35.96,0,71.921-0.012,107.881-0.023
			c0.781,0,1.55,0.012,2.331-0.047c0.454-0.035,0.921-0.14,1.363-0.291c0.466-0.151,0.781-0.524,0.886-1.002
			c0.082-0.373,0.128-0.769,0.151-1.154c0.023-0.618,0-1.247,0.023-1.864c0.012-0.221,0.058-0.466,0.151-0.664
			c0.047-0.093,0.315-0.186,0.361-0.14c0.816,0.711,1.631,1.422,2.424,2.167c0.676,0.629,1.352,1.27,2.086,1.853
			c1.095,0.874,2.284,1.293,3.671,1.154c0.151-0.012,0.315,0,0.466,0c15.766,0,31.532,0,47.298,0c0.851,0,1.713-0.012,2.564-0.023
			c2.039-0.035,3.787-0.699,5.174-2.261c0.676-0.746,1.422-1.433,2.132-2.144c0.932-0.932,1.876-1.864,2.808-2.797
			c0.28-0.268,0.559-0.536,0.862-0.781c0.746-0.606,1.806-0.291,2.039,0.618c0.105,0.443,0.175,0.921,0.186,1.375
			c0.023,1.084,0,2.179,0.012,3.263c0,0.466,0.012,0.932,0.058,1.398c0.07,0.722,0.653,1.293,1.398,1.328
			c0.851,0.035,1.713,0.035,2.564,0.035c39.374,0,78.761-0.012,118.135-0.012c14.216,0,28.433,0,42.637,0
			c0.781,0,1.55,0.023,2.331-0.023c1.69-0.093,3.181-0.653,4.37-1.911c0.21-0.221,0.443-0.443,0.664-0.653
			c6.642-6.642,13.296-13.296,19.938-19.938c0.443-0.443,0.874-0.886,1.293-1.34c0.769-0.839,1.165-1.829,1.212-2.96
			c0.023-0.618,0.012-1.247,0.012-1.864c0-13.354,0.012-26.72,0.012-40.074c0-0.699-0.023-1.398,0.023-2.098
			c0.023-0.291,0.175-0.583,0.268-0.874c0-4.661,0-9.322,0-13.983C611.674,338.086,611.65,337.608,611.65,337.154z M606.348,394.625
			c-0.012,0.641-0.21,1.224-0.513,1.771c-0.454,0.816-0.979,1.585-1.643,2.249c-0.443,0.443-0.874,0.886-1.317,1.317
			c-5.104,5.104-10.219,10.219-15.323,15.323c-0.385,0.385-0.781,0.757-1.154,1.154c-1.305,1.375-2.948,1.946-4.789,2.074
			c-0.536,0.035-1.084,0.023-1.631,0.023c-14.135,0-28.269,0-42.416,0c-1.014,0-2.016-0.012-2.995-0.303
			c-0.909-0.268-1.725-0.676-2.4-1.363c-0.489-0.501-0.99-0.979-1.492-1.48c-1.643-1.643-3.286-3.298-4.941-4.941
			c-0.443-0.443-0.897-0.862-1.375-1.258c-0.385-0.303-0.851-0.373-1.328-0.268c-0.408,0.093-0.688,0.361-0.816,0.746
			c-0.186,0.536-0.14,1.084,0.012,1.608c0.303,1.049,0.629,2.086,0.956,3.123c0.233,0.746,0.338,1.515,0.291,2.296
			c-0.047,0.769-0.443,1.305-1.224,1.503c-0.594,0.151-1.212,0.245-1.83,0.315c-0.536,0.058-1.084,0.023-1.631,0.023
			c-11.886,0-23.772-0.012-35.657,0c-1.492,0-2.948-0.07-4.3-0.664c-0.723-0.618-0.956-1.457-1.2-2.272
			c-0.42-1.41-0.816-2.832-1.189-4.265c-1.62-6.234-3.216-12.48-4.836-18.714c-0.315-1.2-0.653-2.4-0.979-3.589
			c-0.023-0.07-0.058-0.151-0.07-0.221c-0.151-0.944-0.816-1.666-1.095-2.552c-0.256-0.816-0.501-1.62-0.769-2.435
			c-0.023-0.07-0.105-0.117-0.163-0.151c-0.058-0.035-0.14-0.058-0.21-0.047c-0.058,0-0.151,0.035-0.186,0.082
			c-0.291,0.501-0.186,1.06-0.186,1.585c-0.012,10.021-0.012,20.043-0.012,30.064c0,0.781-0.023,1.55-0.058,2.331
			c-0.012,0.35-0.408,0.734-0.757,0.769c-0.233,0.023-0.466,0.058-0.688,0.07c-0.618,0.012-1.247,0.012-1.864,0.012
			c-12.9-0.012-25.787-0.023-38.687-0.035c-0.769,0-1.55-0.058-2.319-0.105c-0.268-0.012-0.594-0.35-0.618-0.618
			c-0.035-0.536-0.07-1.084-0.07-1.62c-0.012-3.496,0-6.992-0.012-10.487c0-0.699-0.012-1.398-0.082-2.097
			c-0.093-0.886-1.072-1.492-1.911-1.165c-0.501,0.198-0.979,0.466-1.41,0.792c-0.489,0.373-0.909,0.851-1.352,1.282
			c-3.845,3.834-7.702,7.679-11.548,11.525c-0.711,0.711-1.398,1.457-2.144,1.934c-1.457,0.571-2.855,0.571-4.253,0.571
			c-11.653,0-23.305,0-34.946,0.012c-2.645,0-5.279,0.012-7.924-0.012c-0.699,0-1.398-0.058-2.086-0.14
			c-1.596-0.186-2.901-0.874-4.043-2.063c-2.575-2.692-5.267-5.279-7.901-7.924c-0.711-0.722-1.468-1.387-2.331-1.911
			c-0.198-0.117-0.42-0.21-0.629-0.303c-0.699-0.291-1.468,0.175-1.503,0.944c-0.023,0.618-0.023,1.247-0.023,1.864
			c0,2.482,0.012,4.976,0,7.458c0,0.466-0.058,0.921-0.105,1.387c-0.023,0.256-0.361,0.571-0.641,0.606
			c-0.303,0.035-0.618,0.093-0.921,0.093c-0.781,0.012-1.55,0.012-2.331,0.012c-28.899-0.023-57.797-0.035-86.684-0.058
			c-4.661,0-9.322,0.012-13.983,0.012c-0.618,0-1.247-0.012-1.864-0.012c-0.862,0.012-1.492-0.35-1.829-1.165
			c-0.28-0.641-0.571-1.282-0.827-1.923c-0.396-1.014-0.781-2.028-1.142-3.053c-1.433-4.102-2.855-8.215-4.288-12.317
			c-1.527-4.405-3.053-8.809-4.603-13.203c-1.084-3.076-2.214-6.129-3.344-9.194c-0.163-0.431-0.385-0.851-0.618-1.247
			c-0.151-0.28-0.419-0.443-0.746-0.513c-0.268-0.058-0.722,0.128-0.781,0.361c-0.07,0.303-0.175,0.606-0.175,0.897
			c-0.023,1.317-0.012,2.645-0.012,3.962c0,11.268,0,22.525-0.012,33.793c0,0.769-0.023,1.55-0.023,2.284
			c-0.548,0.699-1.072,0.851-1.608,0.932c-1.457,0.256-2.913,0.396-4.405,0.385c-7.539-0.023-15.067-0.012-22.606-0.012
			c-27.803,0-55.618,0-83.422,0c-0.932,0-1.864,0.023-2.797-0.023c-0.536-0.023-1.072-0.128-1.608-0.245
			c-0.944-0.198-1.457-0.862-1.655-1.76c-0.117-0.524-0.14-1.072-0.14-1.62c-0.012-2.331,0-4.661-0.012-6.992
			c0-0.781,0-1.561-0.058-2.331c-0.14-1.736-1.282-2.296-2.738-1.352c-0.326,0.21-0.629,0.454-0.909,0.711
			c-0.454,0.419-0.886,0.862-1.328,1.305c-3.181,3.181-6.374,6.374-9.555,9.555c-0.443,0.443-0.874,0.886-1.328,1.305
			c-0.583,0.536-1.27,0.909-2.051,1.037c-1.072,0.186-2.132,0.431-3.228,0.385c-0.233-0.012-0.466,0-0.699,0
			c-13.75,0-27.5,0.012-41.251,0.012c-0.548,0-1.084,0-1.631-0.023c-1.853-0.082-3.473-0.746-4.789-2.086
			c-0.326-0.326-0.664-0.653-0.99-0.99c-5.314-5.337-10.639-10.674-15.953-16.011c-0.769-0.769-1.492-1.573-2.097-2.494
			c-0.524-0.792-0.851-1.655-0.897-2.61c-0.023-0.548-0.047-1.084-0.047-1.631c0-10.103,0-20.194,0-30.297
			c0-0.385,0.023-0.781,0.058-1.165c0.012-0.256,0.361-0.583,0.618-0.618c0.233-0.023,0.466-0.058,0.699-0.058
			c0.618-0.012,1.247-0.012,1.864-0.012c3.577,0,7.143,0,10.72,0c0.618,0,1.247-0.012,1.864-0.035
			c0.676-0.035,1.072-0.548,1.06-1.27c-0.012-0.653-0.21-1.235-0.594-1.736c-0.431-0.548-0.909-1.06-1.398-1.55
			c-3.729-3.741-7.469-7.481-11.198-11.21c-0.489-0.489-0.99-0.99-1.468-1.492c-0.722-0.746-1.154-1.62-1.305-2.668
			c-0.128-0.851-0.268-1.69-0.245-2.54c0.012-0.699,0-1.398,0-2.097c0-15.848,0-31.695,0-47.543c0-0.466,0.035-0.932,0-1.398
			c-0.105-1.305,0.35-2.447,1.072-3.496c0.524-0.769,1.084-1.503,1.748-2.167c5.721-5.698,11.431-11.42,17.141-17.129
			c0.769-0.781,1.643-1.41,2.587-2.004c0.816-0.303,1.678-0.198,2.529-0.198c7.143-0.012,14.298-0.023,21.441-0.035
			c6.759-0.012,13.517-0.012,20.276-0.012c0.851,0,1.713,0.023,2.564,0.058c0.874,0.035,1.655,0.338,2.389,0.827
			c0.979,0.653,1.841,1.422,2.668,2.249c3.286,3.298,6.584,6.595,9.881,9.893c0.489,0.489,0.979,1.002,1.515,1.445
			c0.35,0.291,0.769,0.513,1.177,0.734c0.198,0.105,0.443,0.128,0.676,0.151c0.513,0.058,1.154-0.396,1.2-0.897
			c0.058-0.536,0.082-1.084,0.093-1.62c0.012-2.948,0-5.908,0-8.856c0-0.618-0.012-1.247,0.058-1.864
			c0.047-0.373,0.198-0.746,0.373-1.084c0.256-0.513,0.699-0.851,1.27-0.909c0.699-0.07,1.398-0.093,2.097-0.093
			c2.412-0.012,4.813-0.012,7.225-0.012c32.931,0,65.873-0.012,98.803-0.012c0.851,0,1.713-0.012,2.564,0.047
			c0.536,0.035,1.084,0.128,1.608,0.256c0.548,0.14,0.99,0.466,1.247,0.979c0.431,0.909,0.886,1.818,1.235,2.762
			c1.573,4.3,3.1,8.623,4.661,12.923c1.305,3.577,2.645,7.131,3.962,10.697c0.105,0.291,0.198,0.583,0.303,0.886
			c1.212,3.438,2.412,6.887,3.659,10.313c0.396,1.095,0.921,2.132,1.422,3.181c0.128,0.268,0.373,0.501,0.618,0.664
			c0.163,0.117,0.431,0.128,0.653,0.105c0.256-0.023,0.431-0.256,0.454-0.466c0.07-0.618,0.093-1.235,0.093-1.853
			c0.012-9.171,0-18.33,0.012-27.5c0-3.03,0-6.059,0.012-9.089c0-0.548,0.012-1.084,0.012-1.631c0-0.839,0.35-1.492,1.072-1.969
			c0.722-0.291,1.503-0.28,2.284-0.303c0.781-0.023,1.55-0.012,2.331-0.012c33.094-0.012,66.187-0.012,99.269-0.023
			c0.932,0,1.864,0.012,2.797,0.047c0.629,0.023,1.224,0.163,1.783,0.489c0.42,0.245,0.734,0.583,0.839,1.06
			c0.245,1.142,0.466,2.284,0.454,3.461c-0.012,1.398-0.012,2.797,0,4.195c0,0.548,0,1.095,0.07,1.631
			c0.117,0.839,0.606,1.398,1.492,1.631c0.979-0.489,1.853-1.095,2.622-1.876c1.317-1.328,2.634-2.634,3.95-3.95
			c1.596-1.596,3.193-3.181,4.789-4.766c0.326-0.326,0.664-0.664,1.025-0.944c0.757-0.571,1.608-0.944,2.575-0.956
			c0.548-0.012,1.084-0.012,1.631-0.012c13.983,0,27.966,0,41.95,0c0.851,0,1.713,0.035,2.564,0.023
			c1.655-0.023,3.006,0.676,4.172,1.771c0.629,0.583,1.224,1.189,1.829,1.795c3.519,3.507,7.05,7.015,10.557,10.534
			c0.827,0.827,1.736,1.55,2.715,2.191c0.385,0.256,0.804,0.454,1.293,0.478c0.233,0.012,0.711-0.256,0.734-0.478
			c0.058-0.454,0.128-0.921,0.128-1.375c0.012-3.577,0.012-7.143,0.012-10.72c0-0.548-0.012-1.084,0.012-1.631
			c0.012-0.303,0.047-0.618,0.117-0.921c0.21-0.909,0.874-1.515,1.794-1.573c0.932-0.058,1.864-0.07,2.797-0.07
			c9.707-0.012,19.425-0.012,29.132-0.012c11.734,0,23.457,0,35.191,0c0.932,0,1.864,0,2.797,0.012
			c0.466,0.012,0.932,0.023,1.398,0.07c1.352,0.14,2.564,0.583,3.507,1.62c0.466,0.513,0.99,0.99,1.48,1.48
			c3.741,3.729,7.469,7.469,11.21,11.198c0.489,0.489,0.979,1.002,1.515,1.457c0.35,0.303,0.746,0.559,1.154,0.769
			c0.816,0.408,1.655,0.385,2.459-0.07c0.956-0.536,1.76-1.258,2.54-2.028c3.997-4.02,8.017-8.017,12.037-12.026
			c0.385-0.385,0.781-0.757,1.154-1.154c0.676-0.699,1.503-1.084,2.47-1.189c0.466-0.058,0.932-0.093,1.398-0.105
			c0.781-0.023,1.55-0.023,2.331-0.023c13.599,0,27.186,0,40.784,0c0.618,0,1.247,0,1.864,0.012c1.212,0.035,2.272,0.466,3.135,1.34
			c0.385,0.385,0.769,0.769,1.154,1.154c5.989,5.989,11.979,11.967,17.968,17.957c0.548,0.548,1.06,1.142,1.527,1.748
			c0.385,0.501,0.571,1.095,0.559,1.748c-0.012,0.548,0,1.084,0.012,1.631c0,9.87,0,19.728,0,29.598c0,0.618,0,1.247-0.035,1.864
			c-0.023,0.559-0.443,1.025-1.025,1.095c-0.688,0.082-1.387,0.14-2.086,0.14c-2.797,0.012-5.593,0-8.39,0.012
			c-0.699,0-1.398,0.023-2.098,0.082c-0.571,0.047-1.014,0.361-1.352,0.816c-0.303,0.396-0.361,0.897-0.117,1.305
			c0.478,0.804,0.967,1.596,1.655,2.237c0.396,0.373,0.769,0.769,1.154,1.154c3.181,3.193,6.362,6.374,9.555,9.567
			c0.443,0.443,0.886,0.874,1.293,1.34c0.932,1.095,1.492,2.354,1.468,3.822c-0.012,0.699,0.012,1.398,0.012,2.097
			c0,16.547,0.012,33.094,0.012,49.641C606.372,393.075,606.348,393.844,606.348,394.625z"
          />
        </g>
        <g id="Shape_1_copy">
          <path
            d="M514.7,406.231c-0.967-3.519-1.946-7.027-2.925-10.546c-0.909-3.286-1.829-6.584-2.75-9.87
			c-0.956-3.438-1.911-6.875-2.867-10.324c-1.037-3.741-2.063-7.481-3.111-11.222c-0.874-3.135-1.748-6.281-2.634-9.415
			c-0.233-0.839-0.326-1.666-0.105-2.703c0.944-1.317,1.958-2.482,3.065-3.577c2.599-2.564,5.162-5.162,7.737-7.737
			c0.489-0.489,1.014-0.967,1.457-1.515c0.956-1.165,1.631-2.459,1.526-4.032c-0.012-0.233,0-0.466,0-0.699
			c0-14.519,0-29.039,0-43.558c0-0.618,0.012-1.247-0.023-1.864c-0.093-1.841-0.641-3.507-2.051-4.789
			c-0.233-0.21-0.431-0.443-0.653-0.664c-4.498-4.51-8.996-9.008-13.505-13.517c-0.326-0.326-0.653-0.664-1.002-0.967
			c-0.711-0.629-1.527-1.025-2.47-1.235c-1.224-0.28-2.459-0.291-3.706-0.291c-11.723,0-23.445,0-35.18,0
			c-8.075,0-16.151,0.012-24.226-0.012c-2.424-0.012-2.459,0.338-2.459,2.54c0.012,6.828,0.012,13.669,0.012,20.497
			c0,38.827,0,77.654,0,116.48c0,0.932,0,1.864,0.047,2.797c0.023,0.454,0.14,0.921,0.268,1.363c0.14,0.466,0.489,0.792,0.967,0.932
			c0.373,0.117,0.757,0.21,1.13,0.233c0.769,0.047,1.55,0.058,2.331,0.058c7.994,0,15.999,0,23.993-0.012
			c0.548,0,1.095,0.023,1.631-0.035c0.618-0.07,1.224-0.198,1.818-0.35c0.501-0.128,0.804-0.489,0.839-1.002
			c0.047-0.769,0.058-1.55,0.058-2.331c0-16.069,0-32.15,0-48.219c0-0.699,0-1.398,0.023-2.097c0.023-0.618,0.117-1.235,0.373-1.818
			c0.21-0.466,0.548-0.792,1.049-0.816c1.165-0.047,2.331-0.035,3.496-0.023c0.501,0.012,0.851,0.315,1.107,0.746
			c0.431,0.746,0.734,1.527,0.956,2.366c0.559,2.097,1.107,4.207,1.631,6.304c0.897,3.612,1.946,7.19,2.89,10.79
			c1.62,6.153,3.204,12.317,4.813,18.47c1.235,4.731,2.47,9.462,3.729,14.181c0.175,0.676,0.443,1.317,0.688,1.969
			c0.431,1.165,1.34,1.666,2.494,1.795c0.466,0.047,0.932,0.023,1.398,0.023c8.227,0,16.465,0,24.692,0c0.618,0,1.247,0,1.864-0.047
			c0.385-0.035,0.769-0.117,1.13-0.233c0.827-0.268,1.317-0.897,1.305-1.736c-0.012-0.618-0.058-1.247-0.175-1.841
			C515.178,407.874,514.921,407.058,514.7,406.231z M479.905,326.946c-0.909,1.305-2.086,2.296-3.438,3.088
			c-1.025,0.606-2.202,0.734-3.356,0.874c-0.385,0.047-0.781,0.023-1.165,0.023c-2.564,0-5.127,0-7.691,0
			c-0.466,0-0.921-0.047-1.387-0.07c-0.653-0.524-0.827-1.049-0.862-1.596c-0.058-0.932-0.093-1.864-0.093-2.797
			c-0.012-6.595-0.012-13.203-0.012-19.798c0-3.181-0.012-6.362,0.012-9.555c0-0.699,0.035-1.398,0.14-2.086
			c0.14-0.839,0.688-1.387,1.503-1.608c0.443-0.117,0.921-0.186,1.375-0.186c2.948-0.012,5.896,0,8.856,0
			c1.888,0,3.484,0.653,4.766,2.051c0.256,0.291,0.548,0.548,0.816,0.827c0.606,0.629,1.014,1.363,1.2,2.226
			c0.21,0.909,0.385,1.818,0.385,2.762c-0.012,7.528-0.023,15.067,0,22.595C480.977,324.918,480.604,325.944,479.905,326.946z
			 M262.967,270.279c-0.012-0.385-0.058-0.781-0.128-1.154c-0.117-0.583-0.478-0.979-1.049-1.13
			c-0.443-0.128-0.909-0.221-1.363-0.256c-0.699-0.058-1.398-0.058-2.098-0.058c-6.607,0-13.203,0-19.81,0
			c-0.699,0-1.398,0.012-2.097,0.023c-0.466,0.012-0.932,0.035-1.398,0.082c-0.233,0.023-0.454,0.105-0.676,0.163
			c-0.338,0.093-0.676,0.513-0.664,0.874c0.058,1.317,0.163,2.634,0.175,3.95c0.012,20.346,0.023,40.703,0.023,61.049
			c0,0.699-0.012,1.398,0,2.097c0,0.792-0.186,1.538-0.478,2.261c-0.058,0.14-0.163,0.256-0.245,0.385
			c-0.163,0.245-0.676,0.28-0.816,0.023c-0.221-0.408-0.466-0.804-0.618-1.235c-1.841-5.116-3.659-10.231-5.488-15.358
			c-0.734-2.051-1.468-4.09-2.202-6.141c-1.701-4.673-3.403-9.334-5.092-14.007c-1.06-2.925-2.074-5.85-3.111-8.786
			c-0.827-2.342-1.666-4.684-2.505-7.015c-1.748-4.813-3.507-9.625-5.267-14.438c-0.128-0.361-0.245-0.734-0.385-1.095
			c-0.35-0.874-0.816-1.678-1.527-2.272c-1.025-0.536-2.109-0.571-3.204-0.571c-6.677,0.012-13.366,0-20.043,0.012
			c-0.548,0-1.095-0.012-1.631,0.058s-1.072,0.198-1.585,0.35c-0.396,0.117-0.676,0.408-0.781,0.804
			c-0.128,0.443-0.245,0.897-0.268,1.363c-0.047,0.851-0.047,1.713-0.047,2.564c0,7.376,0,14.764,0,22.14c0,37.44,0,74.88,0,112.32
			c0,0.699,0.012,1.398,0.012,2.097c0.012,0.711,0.151,1.387,0.385,2.051c0.175,0.478,0.524,0.769,0.99,0.897
			c0.373,0.105,0.757,0.186,1.142,0.221c0.769,0.047,1.55,0.082,2.331,0.082c6.607,0,13.203,0,19.81-0.012
			c0.781,0,1.55-0.023,2.331-0.058c0.385-0.023,0.781-0.07,1.154-0.151c0.722-0.175,1.107-0.466,1.154-1.259
			c0.058-1.084,0.093-2.167,0.093-3.263c0-20.975,0-41.95,0-62.913c0-0.699-0.012-1.398,0.023-2.098
			c0.012-0.303,0.058-0.618,0.163-0.909c0.105-0.303,0.338-0.501,0.676-0.571c0.268-0.058,0.699,0.082,0.816,0.315
			c0.221,0.408,0.454,0.816,0.606,1.247c1.503,4.16,2.995,8.332,4.463,12.503c1.165,3.298,2.296,6.595,3.438,9.905
			c1.701,4.917,3.391,9.835,5.104,14.752c1.27,3.671,2.552,7.33,3.834,11c1.596,4.545,3.216,9.077,4.824,13.61
			c0.385,1.095,0.722,2.214,1.13,3.309c0.326,0.874,0.699,1.725,1.084,2.575c0.559,1.259,1.538,1.923,2.913,1.969
			c0.781,0.035,1.55,0.058,2.331,0.058c6.292,0.012,12.585,0.012,18.877,0.012c0.932,0,1.864-0.047,2.797-0.082
			c0.315-0.012,0.618-0.058,0.921-0.105c0.454-0.07,0.886-0.478,0.921-0.932c0.07-1.084,0.151-2.167,0.151-3.251
			c0-45.049,0-90.099-0.012-135.16C263.013,272.143,262.99,271.211,262.967,270.279z M420.115,369.094
			c0.082-4.032-0.198-8.075,0.198-12.107c0.023-0.233,0.012-0.466,0.012-0.699c0.058-6.992,0.163-13.972,0.151-20.963
			c-0.012-12.969-0.105-25.927-0.128-38.897c0-2.645-0.128-5.279-0.268-7.912c-0.082-1.631-0.722-2.995-1.806-4.183
			c-0.256-0.291-0.536-0.559-0.816-0.839c-4.44-4.451-8.891-8.903-13.342-13.342c-0.221-0.221-0.443-0.431-0.664-0.653
			c-1.154-1.165-2.599-1.666-4.183-1.806c-0.536-0.047-1.084-0.023-1.631-0.023c-12.189,0-24.377-0.012-36.566-0.012
			c-0.618,0-1.247-0.023-1.864,0.012c-0.699,0.047-1.387,0.151-2.074,0.245c-1.282,0.163-2.342,0.769-3.228,1.701
			c-0.105,0.117-0.221,0.221-0.326,0.326c-4.661,4.673-9.311,9.345-13.995,14.007c-1.678,1.678-2.564,3.659-2.529,6.048
			c0.012,0.548,0,1.084,0,1.631c0,12.27,0.012,24.541,0.012,36.799c0,20.113,0,40.214,0,60.326c0,0.618,0,1.247,0.012,1.864
			c0.047,2.261,0.792,4.253,2.377,5.896c0.268,0.28,0.536,0.559,0.816,0.827c4.113,4.125,8.227,8.238,12.34,12.364
			c0.28,0.28,0.536,0.559,0.827,0.816c1.503,1.34,3.181,2.063,5.267,2.063c12.969-0.047,25.927-0.023,38.897-0.023
			c0.699,0,1.398,0,2.098-0.058c1.095-0.082,2.121-0.408,3.041-1.049c0.641-0.443,1.235-0.932,1.795-1.48
			c4.498-4.498,8.996-9.008,13.517-13.494c1.002-1.002,1.829-2.109,2.366-3.496c0.012-0.326,0.058-0.722,0.047-1.107
			c-0.035-3.88-0.023-7.761-0.117-11.641C420.29,376.54,420.488,372.811,420.115,369.094z M387.394,375.375
			c0,1.014-0.012,2.016-0.047,3.03c-0.058,1.457-0.618,2.703-1.62,3.764c-1.13,1.2-2.389,2.226-3.892,2.913
			c-0.35,0.163-0.734,0.338-1.095,0.35c-1.317,0.047-2.645,0.093-3.95,0c-1.014-0.07-1.946-0.501-2.692-1.247
			c-0.769-0.769-1.55-1.527-2.307-2.307c-0.664-0.676-1.072-1.527-1.398-2.4c-0.128-0.361-0.186-0.757-0.21-1.142
			c-0.035-0.618-0.023-1.247-0.023-1.864c0-24.552,0-49.093,0-73.645c0-0.769,0.023-1.55,0.035-2.284
			c1.328-2.249,3.228-3.822,5.29-5.29c1.701-0.175,3.403-0.163,5.104-0.012c1.095,0.093,2.086,0.489,2.971,1.2
			c1.095,0.874,2.086,1.864,2.995,2.913c0.571,0.653,0.886,1.457,0.862,2.354c-0.012,0.548,0,1.084,0,1.631
			C387.417,327.366,387.406,351.37,387.394,375.375z M93.07,338.937c-0.501-0.489-0.979-1.002-1.468-1.492
			c-2.587-2.575-5.162-5.162-7.749-7.737c-0.886-0.874-1.783-1.725-2.645-2.634c-1.002-1.06-2.226-1.62-3.659-1.864
			c-1.084-0.186-2.167-0.198-3.251-0.198c-7.225,0-14.449,0-21.674,0c-0.618,0-1.247,0.012-1.864-0.023
			c-0.303-0.012-0.641-0.035-0.909-0.175c-0.827-0.431-1.678-0.862-2.284-1.585c-1.119-1.328-2.016-2.762-1.853-4.626
			c0.035-0.385,0-0.781,0-1.165c0-4.661,0-9.322,0-13.983c0-0.466,0-0.932,0.012-1.398c0.023-0.792,0.268-1.515,0.629-2.214
			c0.536-1.06,1.41-1.841,2.214-2.68c0.944-1.002,2.121-1.573,3.438-1.841c1.072-0.221,2.167-0.198,3.239,0
			c0.932,0.175,1.771,0.548,2.447,1.247c0.921,0.956,1.864,1.864,2.773,2.82c0.315,0.326,0.571,0.722,0.851,1.072
			c0.023,0.583,0.058,1.13,0.047,1.666c-0.047,2.948,0.14,5.908-0.151,8.844c-0.035,0.385,0.023,0.769,0.058,1.165
			c0.07,0.688,0.454,1.154,1.107,1.352c0.513,0.151,1.049,0.291,1.585,0.326c1.002,0.07,2.016,0.082,3.03,0.082
			c5.512,0.012,11.035,0.012,16.547,0.012c2.482,0,4.976,0.012,7.458,0c0.548,0,1.084-0.012,1.631-0.058
			c0.653-0.047,1.142-0.466,1.317-1.13c0.093-0.373,0.186-0.757,0.21-1.142c0.047-0.699,0.047-1.398,0.047-2.097
			c0-6.292,0-12.585,0-18.877c0-0.699,0-1.398-0.047-2.098c-0.082-1.189-0.408-2.296-1.13-3.251
			c-0.373-0.489-0.746-0.99-1.165-1.445c-0.524-0.571-1.095-1.107-1.643-1.655c-3.904-3.892-7.807-7.796-11.711-11.688
			c-0.489-0.489-1.002-0.979-1.561-1.387c-1.293-0.921-2.762-1.398-4.37-1.398c-0.385,0-0.781,0-1.165-0.012
			c-12.119-0.012-24.238-0.023-36.356-0.035c-0.699,0-1.398-0.023-2.097,0.023c-1.352,0.082-2.587,0.501-3.554,1.503
			c-0.536,0.559-1.119,1.084-1.666,1.631c-4.125,4.113-8.25,8.227-12.364,12.352c-0.385,0.385-0.746,0.792-1.142,1.165
			c-1.398,1.293-1.934,2.96-2.051,4.801c-0.035,0.548-0.023,1.084-0.023,1.631c0,14.834,0.012,29.668,0.012,44.513
			c0,0.548-0.023,1.095,0.023,1.631c0.175,1.993,0.816,3.717,2.319,5.174c3.414,3.286,6.724,6.677,10.08,10.033
			c0.664,0.664,1.387,1.235,2.167,1.76c0.932,0.629,1.934,0.979,3.053,0.979c1.084,0.012,2.179,0.023,3.263,0.023
			c6.759-0.023,13.517-0.047,20.276-0.058c0.932,0,1.864,0.035,2.797,0.093c0.804,0.047,1.503,0.373,2.086,0.944
			c0.722,0.699,1.445,1.41,2.144,2.144c0.722,0.757,1.095,1.69,1.154,2.727c0.035,0.618,0.058,1.247,0.058,1.864
			c0,4.813,0,9.637,0,14.449c0,0.548-0.023,1.084-0.058,1.631c-0.07,0.956-0.396,1.806-0.944,2.587
			c-0.757,1.095-1.69,2.028-2.668,2.913c-0.897,0.804-1.958,1.27-3.17,1.328c-0.932,0.047-1.864,0.093-2.797,0.012
			c-1.596-0.117-3.03-0.653-4.137-1.876c-0.466-0.513-1.002-0.979-1.468-1.503c-1.025-1.13-1.643-2.435-1.62-3.997
			c0.012-0.699-0.012-1.398-0.012-2.098c0-1.946-0.012-3.88,0.012-5.826c0.012-1.025-0.105-2.016-0.664-3.041
			c-0.851-0.501-1.666-0.699-2.54-0.711c-0.466-0.012-0.932-0.012-1.398-0.012c-8.472-0.012-16.931-0.035-25.403-0.047
			c-0.466,0-0.932,0-1.398,0.012c-1.177,0.047-1.515,0.385-1.561,1.538c-0.012,0.315-0.012,0.618-0.012,0.932
			c0,7.306,0,14.601,0.012,21.907c0,0.699,0.047,1.398,0.14,2.086c0.14,1.107,0.629,2.074,1.387,2.902
			c0.21,0.233,0.431,0.454,0.641,0.676c4.568,4.545,9.147,9.089,13.704,13.645c0.781,0.781,1.666,1.398,2.587,1.981
			c0.536,0.338,1.119,0.536,1.76,0.524c0.548-0.012,1.084,0,1.631,0c3.962,0,7.924-0.012,11.886-0.012
			c8.39,0,16.78,0.012,25.17,0.023c1.014,0,2.016,0,3.03-0.047c1.27-0.047,2.447-0.408,3.461-1.2
			c0.548-0.431,1.107-0.851,1.596-1.34c4.568-4.545,9.124-9.112,13.68-13.669c0.28-0.28,0.548-0.548,0.792-0.851
			c0.897-1.119,1.492-2.366,1.445-3.834c-0.012-0.233,0-0.466,0-0.699c0-15.615,0-31.229,0-46.844c0-0.548,0.012-1.095-0.047-1.631
			C94.48,341.139,94.084,339.904,93.07,338.937z M597.702,337.841c-3.472-3.333-6.828-6.782-10.243-10.184
			c-0.221-0.221-0.443-0.431-0.664-0.653c-0.944-1.002-2.109-1.526-3.461-1.771c-1.084-0.198-2.156-0.221-3.251-0.221
			c-7.143,0-14.286,0-21.429,0c-0.699,0-1.398,0-2.097-0.023c-0.722-0.012-1.375-0.21-1.899-0.746
			c-0.653-0.664-1.328-1.305-1.981-1.969c-0.513-0.513-0.792-1.142-0.979-1.818c-0.256-0.909-0.291-1.841-0.291-2.773
			c0-4.894-0.012-9.788,0-14.682c0-0.851-0.023-1.713,0.198-2.517c0.967-1.958,2.54-3.344,4.16-4.754
			c1.899-0.711,3.682-0.664,5.465-0.524c0.478,0.035,0.897,0.221,1.282,0.513c0.361,0.291,0.722,0.583,1.107,0.851
			c1.212,0.886,2.132,2.028,2.937,3.274c0.385,0.594,0.618,1.258,0.653,1.969c0.035,0.618,0.047,1.247,0.047,1.864
			c0.012,2.249,0,4.51,0.012,6.759c0,0.548-0.012,1.084,0.023,1.631c0.07,1.084,0.711,1.748,1.76,1.795
			c0.699,0.035,1.398,0.035,2.097,0.035c7.609,0,15.218,0,22.828,0c0.851,0,1.713,0,2.564-0.023c0.548-0.023,1.095-0.035,1.62-0.163
			c0.443-0.105,0.874-0.315,1.27-0.536c0.175-0.105,0.338-0.35,0.373-0.559c0.082-0.454,0.105-0.921,0.105-1.387
			c0.012-7.062,0.012-14.135,0.012-21.196c0-0.548-0.012-1.084-0.058-1.631c-0.093-1.095-0.443-2.109-1.06-3.041
			c-0.524-0.781-1.142-1.468-1.806-2.121c-4.23-4.23-8.448-8.46-12.678-12.69c-0.326-0.326-0.641-0.676-1.002-0.979
			c-1.363-1.107-2.902-1.795-4.673-1.864c-0.466-0.023-0.932-0.012-1.398-0.012c-12.037,0-24.074,0-36.112,0
			c-0.548,0-1.084,0-1.631,0.023c-1.515,0.07-2.843,0.583-4.055,1.55c-1.829,1.468-3.484,3.1-5.034,4.836
			c-1.084,1.212-2.214,2.4-3.356,3.554c-2.028,2.039-4.055,4.067-6.083,6.106c-0.385,0.385-0.781,0.757-1.119,1.177
			c-0.921,1.107-1.48,2.366-1.503,3.822c-0.012,0.781-0.035,1.55-0.035,2.331c-0.012,14.519-0.012,29.039-0.012,43.569
			c0,0.699,0.012,1.398,0.023,2.097c0.012,1.527,0.489,2.902,1.422,4.102c0.28,0.373,0.571,0.734,0.897,1.072
			c3.508,3.519,7.027,7.027,10.534,10.546c0.722,0.722,1.573,1.259,2.435,1.783c0.606,0.361,1.282,0.548,1.993,0.548
			c0.618,0,1.247,0.012,1.864,0.012c6.992,0,13.983,0,20.963,0c0.781,0,1.55-0.012,2.331,0.035c1.864,0.105,3.472,0.769,4.731,2.179
			c1.177,1.317,1.958,2.785,1.806,4.626c-0.012,0.151,0,0.315,0,0.466c0,5.279-0.012,10.557-0.012,15.836
			c0,0.466,0.023,0.932-0.035,1.398c-0.047,0.373-0.14,0.792-0.35,1.084c-0.757,1.072-1.526,2.156-2.529,3.03
			c-1.13,0.979-2.342,1.841-3.88,1.981c-1.072,0.093-2.179,0.093-3.251-0.012c-1.177-0.117-2.237-0.594-3.065-1.503
			c-0.466-0.513-0.967-1.002-1.492-1.48c-1.468-1.34-2.167-2.995-2.109-4.976c0.012-0.466,0-0.932,0-1.398
			c0-2.098,0.012-4.195,0-6.292c0-0.548-0.023-1.084-0.093-1.62c-0.117-0.909-0.781-1.561-1.701-1.655
			c-0.688-0.07-1.398-0.082-2.097-0.082c-7.924,0-15.836,0-23.76,0c-0.781,0-1.55,0-2.331,0.047
			c-0.466,0.023-0.921,0.117-1.375,0.233c-0.734,0.186-1.247,0.653-1.34,1.433c-0.105,0.921-0.186,1.853-0.186,2.785
			c-0.012,6.444-0.012,12.888-0.012,19.332c0,0.781,0.035,1.55,0.093,2.331c0.07,1.037,0.478,1.958,1.177,2.727
			c0.466,0.513,0.956,1.025,1.445,1.515c3.566,3.566,7.143,7.131,10.72,10.697c0.932,0.932,1.899,1.841,2.832,2.773
			c1.445,1.445,3.193,2.156,5.22,2.237c0.466,0.023,0.932,0.023,1.398,0.023c12.585,0,25.158,0,37.743-0.012
			c0.618,0,1.247-0.023,1.864-0.047c0.711-0.035,1.375-0.245,1.969-0.653c0.897-0.606,1.76-1.259,2.529-2.039
			c4.381-4.405,8.786-8.786,13.179-13.179c0.105-0.105,0.21-0.221,0.326-0.326c1.457-1.34,2.086-3.041,2.144-4.987
			c0.023-0.699,0.023-1.398,0.023-2.097c0-13.436,0-26.871,0-40.307c0-1.55-0.082-3.111,0.012-4.661
			C600.545,341.652,599.567,339.636,597.702,337.841z M171.19,385.769c-0.012-0.303-0.058-0.618-0.117-0.921
			c-0.117-0.559-0.419-0.979-0.967-1.224c-0.501-0.221-1.025-0.361-1.573-0.373c-0.932-0.012-1.864-0.035-2.797-0.035
			c-9.625,0-19.262,0-28.887,0.012c-2.167,0-2.167-0.117-2.156-2.249c0.012-8.472,0.012-16.931,0.012-25.403
			c0-0.315,0-0.618,0.012-0.932c0.023-0.781,0.373-1.177,1.107-1.212c0.699-0.035,1.398-0.035,2.097-0.035
			c7.772,0,15.533,0,23.305-0.012c0.932,0,1.864-0.023,2.797-0.023c0.781-0.012,1.55-0.14,2.261-0.478
			c0.198-0.093,0.35-0.291,0.559-0.466c0.023-0.524,0.082-1.072,0.082-1.608c0.012-2.098,0.012-4.195,0.012-6.292
			c0-5.908,0.012-11.804,0-17.712c0-0.618-0.035-1.235-0.082-1.853c-0.035-0.396-0.536-0.897-0.944-0.932
			c-0.699-0.058-1.398-0.093-2.086-0.093c-7.924,0-15.848,0.012-23.772,0.012c-0.699,0-1.398,0-2.097-0.012
			c-0.944-0.012-1.853-0.198-2.727-0.513c-0.175-0.058-0.361-0.315-0.408-0.513c-0.082-0.373-0.105-0.769-0.105-1.154
			c0-7.458,0-14.915,0-22.373c0-0.385,0.023-0.781,0.082-1.154c0.047-0.326,0.221-0.594,0.524-0.722
			c0.35-0.152,0.722-0.315,1.095-0.35c0.699-0.058,1.398-0.047,2.097-0.047c9.392,0,18.796,0,28.188,0
			c0.618,0,1.247,0.012,1.864-0.012c0.303-0.012,0.618-0.058,0.921-0.105c0.583-0.105,1.119-0.699,1.177-1.293
			c0.035-0.303,0.047-0.618,0.047-0.932c0.012-0.699,0-1.398,0-2.097c0-7.225,0-14.449,0-21.674c0-0.618,0-1.247-0.023-1.864
			c-0.023-0.722-0.361-1.119-1.095-1.293c-0.303-0.07-0.606-0.14-0.909-0.151c-0.466-0.035-0.932-0.023-1.398-0.023
			c-20.276,0-40.54,0-60.815,0c-0.851,0-1.713,0.012-2.564,0.082c-0.454,0.035-0.909,0.175-1.352,0.338
			c-0.466,0.163-0.746,0.548-0.839,1.037c-0.07,0.385-0.105,0.769-0.128,1.154c-0.023,0.932-0.047,1.864-0.047,2.797
			c0,45.049,0.012,90.099,0.012,135.148c0,0.618,0.012,1.247,0.023,1.864c0.012,0.315,0.047,0.618,0.105,0.921
			c0.128,0.804,0.722,1.375,1.573,1.48c0.385,0.047,0.769,0.082,1.165,0.093c0.851,0.023,1.713,0.035,2.564,0.035
			c19.728,0,39.456,0,59.184,0c0.851,0,1.713-0.012,2.564-0.047c0.385-0.012,0.769-0.082,1.154-0.151
			c0.676-0.128,1.154-0.583,1.2-1.247c0.082-1.165,0.151-2.331,0.163-3.484c0.012-6.677,0-13.354,0-20.043
			C171.225,387.004,171.213,386.387,171.19,385.769z M317.501,294.901c0.082-0.594,0.431-0.99,0.979-1.2
			c0.431-0.163,0.886-0.303,1.34-0.373c0.536-0.07,1.084-0.058,1.631-0.07c2.412-0.012,4.813,0,7.225-0.012
			c0.781,0,1.55-0.023,2.331-0.07c0.548-0.035,1.072-0.163,1.561-0.548c0.07-0.175,0.198-0.385,0.221-0.606
			c0.047-0.536,0.058-1.084,0.058-1.631c0-5.978,0-11.967,0-17.945c0-0.618,0-1.247-0.035-1.864
			c-0.023-0.466-0.093-0.932-0.186-1.387c-0.221-1.049-0.722-1.468-1.76-1.515c-0.618-0.023-1.247-0.012-1.864-0.012
			c-19.11,0-38.209-0.012-57.32-0.012c-0.699,0-1.398,0-2.098,0.023c-0.233,0.012-0.466,0.058-0.676,0.128
			c-0.303,0.105-0.571,0.303-0.618,0.629c-0.082,0.536-0.128,1.072-0.128,1.62c-0.012,6.91-0.012,13.82-0.012,20.742
			c0,0.466,0.012,0.932,0.047,1.398c0.023,0.454,0.42,0.874,0.897,0.921c0.769,0.07,1.55,0.14,2.319,0.14
			c3.111,0.012,6.211,0,9.322,0.012c0.618,0,1.247,0.023,1.853,0.105c0.781,0.105,1.433,0.757,1.573,1.527
			c0.058,0.303,0.093,0.618,0.093,0.921c0.012,0.618,0.012,1.247,0.012,1.864c0,21.674,0.012,43.336,0.012,65.01
			c0,7.225-0.012,14.449-0.012,21.674c0,0.699-0.012,1.398-0.058,2.098c-0.035,0.629-0.594,1.2-1.2,1.247
			c-0.618,0.047-1.235,0.07-1.864,0.07c-2.797,0.012-5.593-0.012-8.39,0.012c-0.932,0.012-1.864,0.082-2.785,0.186
			c-0.548,0.058-1.049,0.291-1.398,0.909c-0.256,1.352-0.443,2.797-0.443,4.288c0.023,5.593,0,11.187,0,16.78
			c0,0.385,0,0.781,0.012,1.165c0.035,1.014,0.361,1.352,1.445,1.398c1.084,0.058,2.179,0.082,3.263,0.082
			c18.411-0.012,36.811-0.023,55.222-0.035c0.781,0,1.55-0.035,2.331-0.035c0.862-0.012,1.457-0.443,1.876-1.165
			c0.28-0.478,0.478-0.979,0.501-1.538c0.035-0.781,0.047-1.55,0.047-2.331c0-5.127,0-10.254,0.012-15.382
			c0-0.851,0.012-1.713-0.012-2.564c-0.012-0.303-0.058-0.618-0.128-0.909c-0.082-0.326-0.315-0.524-0.629-0.629
			c-0.676-0.245-1.375-0.186-2.074-0.186c-3.111-0.012-6.211-0.012-9.322-0.012c-0.618,0-1.247,0-1.864-0.023
			c-0.769-0.035-1.352-0.571-1.398-1.305c-0.058-0.769-0.07-1.55-0.07-2.331c0-28.666,0-57.32,0-85.985
			c0-0.548-0.012-1.084,0.012-1.631C317.361,295.973,317.431,295.437,317.501,294.901z"
          />
        </g>
      </g>
      <text
        transform="matrix(1.0048 0 0 1 340.001 499.5781)"
        font-family="'TwCenMT-CondensedExtraBold'"
        font-size="63.0844"
      >
        1
      </text>
      <text
        transform="matrix(1.0931 0 0 1 271.2256 500.8048)"
        font-family="'College'"
        font-size="62.755"
      >
        0
      </text>
    </svg>
  );
}

Artwrk16.props = {
  primary: string,
};

Artwrk16.defaultProps = {
  primary: "#000",
};
