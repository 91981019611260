import React from "react";
import { string } from "prop-types";

export default function Artwrk11(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="_x31__1_">
        <g id="Shape_1_copy_3">
          <path
            d="M285.21,599.211c0-2.484,0-5.207-0.948-7.421c-0.887-2.065-2.754-4.459-4.773-5.177c-3.121-1.137-6.7-1.287-10.096-1.317
			c-15.451-0.15-30.932-0.09-46.383-0.09c-22.182,0-23.895-0.479-23.773,21.575c0.031,3.322-0.551,6.703-1.407,9.905
			c-0.367,1.406-1.897,2.962-3.304,3.531c-2.295,0.928-4.895,1.586-7.373,1.586c-23.191,0.12-46.383,0.12-69.543,0
			c-2.478,0-4.987-0.987-6.945-1.436c-3.794-3.531-4.559-7.601-4.589-11.88c-0.031-26.034-0.061-52.067,0.061-78.13
			c0-2.484,0.795-4.937,1.285-7.391c0.581-2.903,4.13-6.464,6.945-6.703c5.109-0.419,10.249-0.898,15.359-0.928
			c26.618-0.09,53.236,0.03,79.885-0.09c6.823-0.03,13.615-0.628,20.56-0.958c15.237-12.807,30.198-25.345,45.067-38.003
			c3.182-2.723,6.027-5.835,8.597-8.319c0.581-3.351,1.346-5.835,1.346-8.289c0.061-55.448,0.061-110.897-0.031-166.345
			c0-2.454-0.857-4.907-1.254-7.062c-14.196-14.842-36.439-33.933-54.154-46.561c-25.425-1.616-135.232-1.406-155.241,0.419
			c-18.602,14.992-37.663,30.522-54.796,48.267c-1.163,23.101-1.407,45.693,0.122,68.315c0.092,1.406,1.744,2.723,2.907,4.429
			c2.172,0.389,4.62,1.257,7.098,1.287c24.874,0.15,49.748,0.628,74.653-0.269c7.985-10.892,0.857-23.789,6.303-34.233
			c3.274-3.561,7.465-4.129,11.84-4.159c20.621-0.06,41.212-0.09,61.833-0.03c2.539,0,5.109,0.539,7.649,0.898
			c2.631,0.359,6.241,4.249,6.578,6.972c0.214,1.676,0.581,3.322,0.581,4.997c0.031,26.871,0.061,53.773-0.031,80.644
			c0,2.484-0.153,5.267-1.285,7.361c-1.071,1.975-3.304,4.249-5.385,4.608c-5.018,0.928-10.219,1.077-15.359,1.077
			c-28.331,0.09-56.693,0-85.025,0.09c-5.936,0.03-11.871,0.658-16.613,0.958c-29.188,22.473-44.425,36.207-56.632,51.229
			c0,4.758,0,10.623,0,16.488c0,61.313,0,122.657,0,183.97c0,5.027-0.061,10.114,0.459,15.081c0.245,2.304,1.836,4.459,3.029,7.182
			c6.119,0.329,12.055,0.868,17.99,0.898c74.714,0.06,149.428,0.06,224.142,0.03c4.283,0,8.628,0.06,12.85-0.539
			c2.325-0.329,4.498-1.915,6.639-2.933c0.459-2.903,1.163-5.356,1.163-7.81C285.21,663.068,285.241,631.139,285.21,599.211z
			 M62.996,657.412c-0.031,0.12-0.306,0.389-0.459,0.389c-1.193,0.03-2.417,0.03-3.61,0.03c-0.367,0-0.612-0.299-0.643-0.688
			c-0.031-0.628-0.031-1.257-0.061-1.885c-0.031-1.197-0.031-2.364-0.031-3.561c0-0.209-0.031-0.419-0.061-0.628
			c-0.031-0.209-0.398-0.539-0.581-0.539c-1.285,0.03-2.539,0.03-3.824,0.06c-0.245,0-0.52,0.299-0.52,0.569
			c-0.031,0.569-0.031,1.107-0.031,1.676c0.031,4.04,0.061,8.079,0.092,12.119l0,0c0.031,4.309,0.061,8.648,0.092,12.957
			c0,0.419,0.061,0.838,0.122,1.227c0.031,0.12,0.153,0.239,0.275,0.299c0.184,0.09,0.398,0.12,0.612,0.12
			c0.918,0.03,1.836,0,2.754,0.03c0.52,0,0.826,0.239,0.826,0.718c0.031,0.987,0.031,1.945,0.031,2.933
			c0,0.628-0.245,0.868-0.887,0.868c-1.56,0.03-3.121,0.03-4.681,0.06c-2.692,0.03-5.385,0.03-8.108,0.06
			c-0.337,0-0.704-0.06-1.071-0.06c-0.122,0-0.428-0.299-0.428-0.419c-0.031-1.047-0.092-2.095-0.092-3.142
			c0-0.598,0.306-0.868,0.918-0.898c1.01-0.03,1.989-0.06,2.968-0.09c0.367,0,0.643-0.299,0.673-0.658
			c0-0.419,0.031-0.838,0.031-1.257c-0.061-8.349-0.122-16.727-0.184-25.076c0-2.065-0.092-2.005-1.989-2.005
			c-0.581,0-1.132,0-1.713,0.03c-1.316,0.03-1.377,0.09-1.377,1.466c0,1.466,0.031,2.932,0.031,4.399c0,0.269,0,0.569-0.031,0.838
			c-0.031,0.449-0.337,0.718-0.857,0.748c-0.581,0.03-1.132,0.03-1.713,0.03s-1.132-0.03-1.713-0.03
			c-0.275,0-0.52-0.269-0.581-0.539c-0.031-0.269-0.092-0.539-0.092-0.838c-0.031-3.202-0.061-6.404-0.092-9.605
			c0-0.209,0.031-0.419,0.092-0.628c0.031-0.12,0.184-0.269,0.275-0.269c0.337-0.06,0.704-0.09,1.071-0.09
			c7.465-0.06,14.931-0.12,22.365-0.15c0.49,0,0.979,0.03,1.499,0.06c0.275,0.03,0.52,0.299,0.551,0.539
			c0,0.06,0.031,0.15,0.031,0.209c0.031,3.411,0.061,6.823,0.061,10.234C63.027,657.113,62.996,657.262,62.996,657.412z
			 M115.1,649.303c-0.031,0.239-0.275,0.479-0.581,0.509c-1.713,0.06-1.744,0.09-2.019,1.975c-0.367,2.693-0.765,5.386-1.132,8.079
			c-0.337,2.424-0.673,4.818-1.01,7.241c-0.367,2.633-0.734,5.237-1.101,7.87c-0.184,1.376-0.398,2.753-0.581,4.129
			c-0.184,1.257-0.337,2.484-0.52,3.74c-0.061,0.419-0.398,0.748-0.887,0.748c-1.989,0.03-3.977,0.06-5.966,0.06
			c-0.49,0-0.704-0.209-0.857-0.748c-0.061-0.269-0.153-0.539-0.214-0.808c-0.49-2.753-0.948-5.506-1.407-8.229
			c-0.49-2.873-1.01-5.775-1.53-8.648c-0.49-2.813-0.979-5.626-1.469-8.438c-0.184-1.107-0.367-2.184-0.581-3.292
			c-0.031-0.209-0.153-0.389-0.245-0.569c-0.031-0.06-0.092-0.09-0.184-0.12c-0.031,0-0.122,0.03-0.122,0.06
			c-0.184,1.017-0.367,2.065-0.52,3.082c-0.551,3.591-1.132,7.152-1.683,10.743c-0.643,4.129-1.254,8.259-1.866,12.388
			c-0.214,1.376-0.49,2.753-0.734,4.129c-0.061,0.299-0.306,0.509-0.581,0.509c-1.285,0.03-2.57,0.03-3.824,0.06
			c-0.918,0-1.836,0-2.754,0c-0.367,0-0.673-0.269-0.734-0.628c-0.184-0.958-0.367-1.915-0.52-2.873
			c-0.49-3.082-0.948-6.194-1.438-9.306c-0.49-3.082-1.04-6.194-1.53-9.276c-0.49-3.082-0.948-6.194-1.469-9.306
			c-0.092-0.628-0.275-1.227-0.428-1.825c-0.031-0.12-0.184-0.239-0.306-0.239c-0.49-0.06-0.979-0.06-1.469-0.12
			c-0.275-0.03-0.551-0.299-0.551-0.539c-0.031-1.107-0.031-2.214-0.031-3.351c0-0.269,0.275-0.539,0.52-0.569
			c0.061,0,0.153-0.03,0.214-0.03c3.427-0.03,6.823-0.03,10.219-0.06c0.061,0,0.153,0.03,0.214,0.03
			c0.245,0.03,0.551,0.299,0.551,0.539c0,1.107,0.031,2.214,0.031,3.351c0,0.269-0.275,0.539-0.551,0.569
			c-0.765,0.06-1.56,0.09-2.325,0.12c-0.337,0.03-0.612,0.359-0.612,0.718c0,0.06,0,0.15,0,0.209
			c0.061,0.628,0.092,1.257,0.184,1.885c0.337,2.484,0.734,4.967,1.071,7.451c0.337,2.484,0.673,4.967,1.01,7.451
			c0.306,2.274,0.612,4.548,0.948,6.823c0.092,0.628,0.184,1.227,0.551,1.915c0.184-1.077,0.398-2.005,0.52-2.962
			c0.704-4.459,1.438-8.947,2.142-13.406c0.612-3.98,1.224-7.99,1.866-11.969c0.122-0.688,0.245-1.377,0.367-2.065
			c0.092-0.479,0.337-0.688,0.887-0.688c1.346-0.03,2.692-0.03,4.039-0.06c0.704,0,1.407,0.03,2.142,0.03
			c0.275,0,0.551,0.209,0.612,0.479c0.275,1.287,0.551,2.603,0.765,3.89c0.49,2.813,0.979,5.626,1.469,8.438
			c0.49,2.813,1.04,5.626,1.53,8.438c0.49,2.753,0.948,5.506,1.438,8.229c0.061,0.329,0.214,0.658,0.337,0.987
			c0.031,0.06,0.092,0.09,0.153,0.12c0.031,0,0.092-0.06,0.122-0.09c0.153-1.167,0.337-2.334,0.459-3.531
			c0.337-2.903,0.643-5.805,1.01-8.738c0.214-1.736,0.459-3.471,0.673-5.177c0.214-1.736,0.398-3.471,0.581-5.177
			c0.092-0.748,0.214-1.526,0.306-2.274c0.031-0.209,0.031-0.419,0.031-0.628c0-0.239-0.275-0.569-0.52-0.569
			c-0.765-0.03-1.56-0.06-2.325-0.09c-0.245,0-0.551-0.269-0.551-0.539c-0.031-1.107-0.031-2.214-0.031-3.351
			c0-0.209,0.367-0.539,0.551-0.569c0.275-0.03,0.551-0.06,0.857-0.06c2.784-0.03,5.538-0.06,8.322-0.06
			c0.214,0,0.428,0.03,0.643,0.06c0.122,0,0.428,0.299,0.428,0.419C115.192,646.969,115.192,648.136,115.1,649.303z
			 M149.887,682.787c-0.031,0.15-0.245,0.389-0.398,0.389c-0.428,0.09-0.857,0.09-1.285,0.09c-3.335,0-6.67,0-10.005,0
			c0,0.03,0,0.06,0,0.06c-3.182,0.03-6.394,0.06-9.576,0.06c-0.49,0-0.979-0.03-1.499-0.03c-0.275,0-0.581-0.269-0.581-0.509
			c0-1.107,0-2.214,0-3.351c0-0.239,0.306-0.479,0.581-0.509c0.704-0.06,1.407-0.09,2.111-0.15c0.275-0.03,0.551-0.299,0.551-0.539
			c0.031-0.419,0.061-0.838,0.031-1.257c-0.031-3.142-0.122-6.254-0.153-9.396c-0.061-4.878-0.092-9.755-0.122-14.633
			c0-0.269,0-0.569,0-0.838c-0.031-2.514,0.275-2.424-2.386-2.394c-0.214,0-0.428-0.03-0.612-0.09
			c-0.122-0.03-0.214-0.18-0.306-0.269c-0.031-0.06-0.061-0.12-0.061-0.209c0-1.107-0.031-2.214-0.031-3.351
			c0-0.359,0.306-0.598,0.704-0.628c0.214,0,0.428-0.03,0.643-0.03c6.67-0.06,13.34-0.09,20.009-0.15
			c1.989-0.03,1.989-0.06,1.989,1.915c0,2.214,0.031,4.459,0.031,6.673c0,0.209-0.031,0.419-0.031,0.628
			c0,0.239-0.275,0.539-0.551,0.539c-0.979,0.03-1.989,0.06-2.968,0.06c-1.438,0-1.56-0.03-1.56-1.496
			c0-1.047-0.031-2.095-0.061-3.142c0-0.239-0.306-0.539-0.551-0.539c-0.214-0.03-0.428-0.06-0.643-0.06
			c-2.203,0.03-4.406,0.03-6.609,0.06c-0.153,0-0.275,0.03-0.428,0.06c-0.275,0.03-0.551,0.299-0.581,0.539
			c-0.031,0.209-0.031,0.419-0.031,0.628c0.031,2.992,0.031,5.985,0.092,8.977c0,0.419,0.092,0.838,0.153,1.227
			c0.031,0.12,0.184,0.239,0.275,0.269c0.337,0.06,0.704,0.12,1.04,0.12c2.264,0,4.559-0.03,6.823-0.03
			c3.182,0,2.845-0.389,2.907,2.753c0,0.269,0,0.569,0,0.838c-0.031,1.167-0.153,1.317-1.346,1.347
			c-2.631,0.03-5.262,0.06-7.894,0.06c-0.367,0-0.704,0-1.071,0.03c-0.398,0.03-0.704,0.269-0.734,0.628
			c0,0.15-0.031,0.269-0.031,0.419c0.031,3.471,0.061,6.972,0.122,10.443c0,0.12,0.031,0.269,0.092,0.389
			c0.061,0.12,0.184,0.239,0.306,0.269c0.275,0.06,0.551,0.09,0.857,0.09c2.203-0.03,4.406-0.03,6.609-0.06
			c0.153,0,0.275-0.03,0.428-0.03c0.337-0.03,0.612-0.359,0.643-0.718c0-0.778,0-1.526,0.031-2.304c0-0.569,0-1.107,0.031-1.676
			c0-0.209,0.306-0.569,0.52-0.569c0.367-0.03,0.704-0.06,1.071-0.06c4.069,0.03,3.457-0.658,3.518,3.351
			c0.031,1.676,0.031,3.351,0.031,5.027C149.979,682.099,149.979,682.458,149.887,682.787z M189.63,649.273
			c-0.551,0.03-1.132,0.03-1.713,0.06c-0.337,0.03-0.642,0.359-0.642,0.718c0,0.778-0.031,1.526,0,2.304
			c0.061,4.608,0.153,9.216,0.214,13.795c0,0,0,0-0.031,0c0.031,4.728,0.061,9.486,0.092,14.214c0,0.628,0,1.257-0.031,1.885
			c0,0.389-0.245,0.628-0.673,0.628c-0.153,0-0.275,0.03-0.428,0.03c-1.407-0.09-2.845,0.239-4.222-0.18
			c-0.795-1.077-1.224-2.334-1.775-3.531c-3.029-6.523-6.058-13.077-9.056-19.6c-0.306-0.628-0.612-1.257-0.948-1.855
			c-0.031-0.03-0.122-0.06-0.214-0.06c-0.031,0-0.092,0.06-0.122,0.09c-0.031,0.269-0.092,0.539-0.092,0.838
			c0.031,6.134,0.061,12.269,0.122,18.403c0,1.436,0.153,1.556,1.56,1.586c0.49,0,1.01,0.03,1.499,0.03
			c0.245,0,0.581,0.299,0.581,0.509c0,1.107,0.031,2.214,0,3.351c0,0.15-0.245,0.329-0.398,0.419c-0.092,0.09-0.275,0.09-0.398,0.09
			c-3.488,0.06-6.976,0.12-10.433,0.18c-0.214,0-0.428-0.03-0.612-0.09c-0.122-0.03-0.214-0.18-0.275-0.269
			c-0.031-0.06-0.061-0.12-0.061-0.209c0-1.107,0-2.214,0-3.351c0-0.269,0.275-0.539,0.52-0.539c0.551-0.03,1.132-0.03,1.713-0.06
			c0.214,0,0.428-0.06,0.642-0.09c0.122-0.03,0.398-0.299,0.398-0.449c0.031-0.359,0.061-0.688,0.061-1.047
			c-0.061-5.925-0.092-11.85-0.153-17.775c-0.031-2.723-0.092-5.446-0.153-8.169c0-0.209,0-0.419,0-0.628
			c-0.031-0.778-0.245-1.017-0.979-1.047c-0.581-0.03-1.132-0.03-1.713-0.03c-0.367,0-0.673-0.329-0.673-0.688
			c0-1.047-0.031-2.095-0.031-3.142c0-0.329,0.337-0.628,0.704-0.658c0.061,0,0.153-0.03,0.214-0.03
			c2.356-0.03,4.681-0.06,7.037-0.06c0.459,0,0.826,0.18,1.04,0.598c0.245,0.509,0.49,0.987,0.734,1.496
			c3.396,7.271,6.823,14.573,10.219,21.844c0.184,0.359,0.275,0.778,0.612,1.047c0.031,0.03,0.122,0,0.153-0.03
			c0.061-0.06,0.092-0.12,0.092-0.18c0.031-0.479,0.061-0.987,0.061-1.466c-0.031-5.656-0.092-11.281-0.122-16.937
			c0-0.419-0.031-0.838-0.061-1.257c-0.031-0.329-0.337-0.628-0.704-0.628c-0.704-0.03-1.407-0.03-2.142-0.06
			c-0.245,0-0.581-0.269-0.612-0.509c-0.061-1.107-0.092-2.214-0.122-3.351c0-0.359,0.306-0.628,0.673-0.658
			c0.275-0.03,0.581-0.03,0.857-0.03c3.06-0.03,6.119-0.03,9.179-0.06c0.367,0,0.704,0.06,1.071,0.12
			c0.122,0.03,0.214,0.18,0.275,0.299c0.031,0.06,0.031,0.12,0.031,0.209c0,1.047,0.031,2.095,0.031,3.142
			C190.456,648.944,190.12,649.243,189.63,649.273z M227.569,655.706c0,0.239-0.306,0.569-0.52,0.569
			c-1.193,0.03-2.417,0.06-3.61,0.06c-0.245,0-0.551-0.299-0.581-0.539c-0.031-0.479-0.061-0.987-0.061-1.466
			c-0.031-1.406-0.031-2.783-0.061-4.189c0-0.269-0.061-0.539-0.122-0.808c-0.031-0.12-0.153-0.269-0.275-0.269
			c-1.407-0.209-2.815-0.15-4.222,0c-0.122,0-0.275,0.18-0.306,0.269c-0.061,0.419-0.122,0.838-0.092,1.257
			c0,2.992,0.031,5.985,0.061,8.977c0,1.317,0,2.633,0,3.98c0,0,0,0,0.031,0c0.031,4.1,0.061,8.229,0.092,12.328
			c0,0.479,0.031,0.987,0.061,1.466c0.031,0.329,0.367,0.628,0.704,0.628c0.857,0.03,1.713,0,2.539,0.03
			c0.275,0,0.551,0.03,0.857,0.06c0.122,0,0.398,0.299,0.398,0.449c0.031,0.838,0.092,1.676,0.092,2.514c0,1.496,0,1.526-1.53,1.526
			c-2.417,0.03-4.834,0.03-7.251,0.06c-1.622,0.03-3.274,0.09-4.895,0.09c-0.367,0-0.704-0.03-1.071-0.06
			c-0.245-0.03-0.398-0.209-0.428-0.419c-0.061-0.479-0.092-0.958-0.122-1.436c-0.031-0.569,0-1.107,0-1.676
			c0-0.748,0.398-0.868,0.918-0.898c0.979-0.03,1.989-0.06,2.968-0.09c0.367,0,0.673-0.329,0.673-0.658
			c0-0.419,0.031-0.838,0.031-1.257c-0.061-8.438-0.122-16.847-0.184-25.285c0-0.419-0.031-0.838-0.092-1.257
			c-0.031-0.359-0.337-0.628-0.734-0.628c-1.224,0-2.417,0.03-3.61,0.03c-0.337,0-0.612,0.329-0.643,0.718c0,0.569,0,1.107,0,1.676
			c0,1.257,0.031,2.514,0.031,3.77c0,0.269,0,0.569-0.092,0.838c-0.061,0.18-0.275,0.389-0.428,0.389
			c-1.285,0.03-2.539,0.06-3.824,0.06c-0.245,0-0.49-0.299-0.52-0.569c-0.031-0.269-0.061-0.539-0.061-0.838
			c-0.031-3.142-0.061-6.284-0.092-9.396c0-0.209,0.061-0.419,0.061-0.628c0-0.15,0.275-0.419,0.428-0.419
			c0.275-0.03,0.551-0.09,0.857-0.09c7.741-0.06,15.481-0.12,23.222-0.15c0.061,0,0.153,0,0.214,0
			c0.918,0.03,1.132,0.18,1.132,1.107c0.031,3.202,0.061,6.404,0.061,9.605C227.599,655.288,227.599,655.497,227.569,655.706z
			 M266.578,647.956c-0.031,0.239-0.306,0.509-0.581,0.539c-0.367,0.03-0.704,0.03-1.071,0.06c-0.49,0-0.765,0.299-0.948,0.718
			c-0.061,0.12-0.122,0.269-0.153,0.389c-2.478,6.224-4.926,12.448-7.404,18.672c-0.306,0.718-0.459,1.436-0.459,2.244
			c0.031,2.035,0.031,4.04,0.061,6.074c0,0.15,0.031,0.269,0.031,0.419c0.031,0.359,0.337,0.658,0.673,0.688
			c0.428,0.03,0.857,0.03,1.285,0.03c0.581,0,1.132,0,1.713,0c0.612,0.03,0.918,0.269,0.918,0.898c0.031,0.987,0,1.945,0,2.933
			c0,0.209-0.337,0.569-0.551,0.569c-0.979,0.06-1.989,0.12-2.968,0.15c-1.346,0.03-2.692,0-4.039,0c0,0.03,0,0.06,0,0.09
			c-1.928,0-3.824,0-5.752,0c-0.428,0-0.857-0.03-1.285-0.03c-0.245,0-0.52-0.299-0.52-0.569c0-1.107,0-2.214,0-3.351
			c0-0.06,0-0.15,0.031-0.209c0.092-0.12,0.153-0.239,0.275-0.299c0.184-0.06,0.398-0.09,0.612-0.09
			c0.918-0.03,1.836-0.03,2.754-0.06c0.428,0,0.765-0.359,0.765-0.838c-0.031-1.317-0.061-2.633-0.092-3.98
			c-0.031-1.167-0.092-2.364-0.122-3.531c-0.428-1.047-0.826-2.065-1.254-3.112c-2.142-5.147-4.283-10.294-6.425-15.47
			c-0.184-0.449-0.367-0.898-0.581-1.347c-0.214-0.479-0.52-0.808-1.101-0.808c-0.153,0-0.275,0-0.428,0
			c-1.04,0.03-1.193-0.359-1.193-1.287c0-0.778,0-1.526,0-2.304c0-0.509,0.275-0.838,0.734-0.838c3.549-0.06,7.098-0.12,10.647-0.18
			c0.153,0,0.275,0.03,0.428,0.06c0.122,0.03,0.398,0.329,0.398,0.449c0,1.167,0,2.364,0,3.531c0,0.12-0.306,0.389-0.428,0.389
			c-0.857,0.06-1.683,0.12-2.539,0.18c-0.153,0-0.367,0.359-0.337,0.479c0.184,0.539,0.337,1.047,0.551,1.586
			c0.979,2.544,1.958,5.087,2.937,7.601c0.551,1.436,1.132,2.873,1.683,4.279c0.061,0.18,0.184,0.209,0.337,0.12
			c0.061-0.03,0.122-0.06,0.153-0.12c0.092-0.12,0.153-0.239,0.214-0.359c1.53-4.129,3.06-8.229,4.589-12.358
			c0.061-0.209,0.122-0.389,0.153-0.598c0.092-0.389-0.122-0.748-0.459-0.778c-0.551-0.03-1.132-0.03-1.713-0.06
			c-0.214,0-0.398-0.06-0.612-0.12c-0.061-0.03-0.122-0.06-0.184-0.12c-0.061-0.06-0.092-0.09-0.122-0.18
			c-0.184-1.167-0.214-2.334-0.153-3.531c0.031-0.269,0.306-0.539,0.551-0.569c0.214-0.03,0.428-0.06,0.642-0.06
			c3.06-0.03,6.119-0.03,9.148-0.06c0.061,0,0.153,0,0.214,0c0.673,0.03,0.948,0.269,0.979,0.838c0.031,0.987,0,1.945,0,2.932
			C266.639,647.837,266.608,647.896,266.578,647.956z"
          />
        </g>
      </g>
      <g id="seniors_1_">
        <g id="Shape_1_copy">
          <path
            d="M274.349,77.915c0,6.517,0,13.034,0,19.55c0,0.581-0.061,1.163-0.092,1.713c0,0.153-0.459,0.52-0.612,0.551
			c-0.49,0.031-0.948,0.092-1.438,0.092c-1.254,0.031-2.478,0-3.733,0.031c-0.979,0.031-1.316,0.367-1.346,1.438
			c-0.031,0.948,0,1.928,0,2.876c0,33.441,0,66.882,0,100.322c0,1.04-0.031,2.111-0.061,3.151c0,0.367-0.367,0.734-0.704,0.765
			c-0.49,0.031-0.948,0.061-1.438,0.061c-7.373,0-14.747,0-22.12,0c-0.398,0-0.765,0-1.163-0.031
			c-0.49-0.031-0.918-0.214-1.132-0.704c-0.214-0.551-0.428-1.071-0.643-1.591c-8.047-20.897-16.093-41.824-24.17-62.721
			c-0.337-0.887-0.704-1.775-1.101-2.662c-0.061-0.153-0.275-0.214-0.428-0.337c-0.031,0.092-0.122,0.153-0.122,0.245
			c-0.061,0.49-0.122,0.948-0.122,1.407c0,13.217,0,26.434,0,39.682c0,2.019,0.092,2.08,2.08,2.142
			c1.438,0.031,2.876,0.061,4.314,0.092c0.153,0,0.551,0.428,0.551,0.612c0.031,0.184,0.092,0.367,0.092,0.551
			c0,0.49,0.031,0.948,0.031,1.438c0,6.425,0,12.85,0,19.275c0,0.49,0,0.948-0.031,1.438c-0.031,0.887-0.306,1.163-1.224,1.193
			c-0.49,0-0.948,0-1.438,0c-11.106,0-22.243,0-33.349,0c-2.784,0-2.692,0.122-2.692-2.631c0-6.425,0-12.85,0-19.275
			c0-0.673,0.061-1.346,0.092-1.989c0-0.184,0.398-0.581,0.551-0.612c0.49-0.031,0.948-0.092,1.438-0.092
			c1.254-0.031,2.478,0,3.733-0.031c0.857-0.031,1.193-0.337,1.193-1.224c0.031-0.857,0.031-1.713,0.031-2.601
			c0-25.486,0-50.972,0-76.458c0-0.857,0-1.713-0.031-2.601c-0.031-0.704-0.367-1.101-1.04-1.101
			c-1.438-0.031-2.876-0.031-4.314-0.031c-0.367,0-0.765-0.061-1.132-0.153c-0.153-0.031-0.337-0.245-0.398-0.398
			c-0.092-0.275-0.092-0.551-0.092-0.857c0-7.19,0-14.38,0-21.57c0-0.184,0-0.398,0.031-0.581c0.031-0.551,0.337-0.918,0.857-0.979
			c0.275-0.031,0.581-0.061,0.857-0.061c0.673,0,1.346,0,2.019,0c8.72,0,17.439,0,26.159,0c0.765,0,1.53,0.031,2.295,0
			c0.918-0.031,1.377,0.459,1.683,1.254c1.928,5.018,3.855,10.005,5.783,15.022c6.333,16.43,12.667,32.859,19.03,49.32
			c0.337,0.887,0.551,1.836,1.163,2.601c0.061,0.061,0.184,0.092,0.245,0.092c0.061,0,0.153-0.092,0.153-0.122
			c0.031-0.765,0.092-1.53,0.092-2.295c0-6.425,0-12.85,0-19.245c0-6.119,0-12.269,0-18.388c0-0.857,0-1.713-0.031-2.57
			c0-0.612-0.428-1.01-1.132-1.01c-0.857-0.031-1.713,0-2.601-0.031c-0.765,0-1.53,0-2.295-0.031
			c-0.643-0.031-1.01-0.398-1.04-1.101c-0.031-0.581-0.031-1.163-0.031-1.713c0-6.333,0-12.636,0-18.969
			c0-0.673,0-1.346,0.092-2.019c0.031-0.214,0.306-0.459,0.551-0.581c0.245-0.122,0.551-0.153,0.826-0.153
			c0.673-0.031,1.346-0.031,2.019-0.031c10.525,0,21.08,0,31.605,0c0.275,0,0.581,0,0.857,0
			C274.533,75.222,274.349,75.314,274.349,77.915L274.349,77.915z M522.661,206.813c0,0.367-0.061,0.765-0.153,1.101
			c-0.031,0.153-0.245,0.306-0.398,0.367c-0.153,0.092-0.367,0.122-0.551,0.122c-0.581,0.031-1.163,0.031-1.713,0.031
			c-7.006,0-13.982,0-20.988,0c-0.673,0-1.346,0-2.019-0.061c-0.398-0.031-0.704-0.275-0.887-0.643
			c-0.275-0.612-0.49-1.254-0.734-1.866c-2.478-6.425-4.987-12.85-7.435-19.306c-3.733-9.638-7.435-19.306-11.137-28.943
			c-0.337-0.887-0.704-1.775-1.071-2.662c-0.153-0.398-0.49-0.643-0.857-0.643c-2.019-0.031-4.008,0-6.027,0.031
			c-0.153,0-0.52,0.459-0.52,0.612c-0.031,0.581-0.092,1.132-0.092,1.713c0,8.23,0,16.491,0,24.721c0,0.581,0,1.163,0.092,1.713
			c0.031,0.214,0.306,0.551,0.52,0.581c0.551,0.122,1.132,0.122,1.713,0.122c1.254,0.031,2.478,0.031,3.733,0.092
			c0.49,0,0.887,0.428,0.887,0.948c0.031,0.49,0.031,0.948,0.031,1.438c0,6.609,0,13.217,0,19.826c0,0.49-0.031,0.948-0.031,1.438
			c-0.031,0.459-0.428,0.826-0.948,0.857c-0.398,0-0.765,0.031-1.163,0.031c-11.504,0-23.008,0-34.481,0
			c-0.398,0-0.765-0.031-1.163-0.031c-0.49-0.031-0.887-0.398-0.918-0.887c-0.031-0.581-0.061-1.163-0.061-1.713
			c0-6.425,0-12.85,0-19.275c0-0.49,0-0.948,0.031-1.438c0.031-0.795,0.428-1.193,1.254-1.224c1.04-0.031,2.111,0,3.151-0.031
			c0.581,0,1.163-0.031,1.713-0.061c0.306,0,0.765-0.459,0.795-0.734c0.031-0.275,0.061-0.581,0.061-0.857c0-0.765,0-1.53,0-2.295
			c0-25.394,0-50.788,0-76.183c0-4.345,0.49-3.886-3.763-3.916c-0.581,0-1.163,0-1.713,0c-1.285-0.031-1.499-0.245-1.53-1.499
			c-0.031-0.581,0-1.163,0-1.713c0-6.027,0-12.085,0-18.112c0-0.765,0.031-1.53,0.061-2.295c0.031-0.459,0.459-0.857,0.948-0.887
			c0.857-0.031,1.713-0.061,2.57-0.061c9.882,0,19.734,0,29.616,0c9.974,0,19.918,0,29.892,0c3.488,0,2.754-0.184,5.324,2.08
			c3.733,3.304,7.435,6.639,11.137,9.944c0.49,0.459,0.979,0.918,1.499,1.346c0.581,0.459,0.765,1.071,0.734,1.775
			c0,0.398,0,0.765,0,1.163c0,14.839,0,29.708,0,44.547c0,0.184-0.031,0.398,0,0.581c0.092,1.04-0.306,1.805-1.071,2.509
			c-4.712,4.375-9.393,8.75-14.074,13.156c-0.428,0.398-0.857,0.765-1.193,1.224c-0.153,0.184-0.275,0.551-0.214,0.795
			c0.214,0.734,0.49,1.469,0.765,2.172c2.876,7.404,5.783,14.808,8.659,22.212c0.49,1.254,1.01,2.478,1.438,3.763
			c0.337,0.918,0.857,1.407,1.897,1.407c1.927-0.031,3.824,0,5.752,0c0.581,0,1.132,0.031,1.713,0.092
			c0.367,0.031,0.734,0.398,0.765,0.734c0.031,0.275,0.061,0.581,0.061,0.857C522.692,192.648,522.692,199.746,522.661,206.813
			L522.661,206.813z M493.473,100.708c-0.031-0.49-0.428-0.826-0.979-0.857c-0.184,0-0.398-0.031-0.581-0.031
			c-7.465,0-14.931,0-22.396,0c-0.184,0-0.398,0.031-0.581,0.031c-0.459,0.031-0.857,0.459-0.857,0.979
			c-0.031,0.673-0.031,1.346-0.031,2.019c0,3.916,0,7.863,0,11.779s0,7.863,0,11.779c0,0.765,0.031,1.53,0.061,2.295
			c0.031,0.49,0.398,0.826,0.979,0.857c0.398,0.031,0.765,0.031,1.163,0.031c7.098,0,14.166,0,21.233,0c0.275,0,0.581,0,0.857-0.031
			c0.184,0,0.367-0.061,0.551-0.092c0.153-0.031,0.52-0.428,0.52-0.612c0.031-1.346,0.092-2.662,0.092-4.008
			c0-7.282,0-14.563,0-21.815C493.504,102.238,493.504,101.473,493.473,100.708L493.473,100.708z M421.023,191.118
			c0,0.857-0.031,1.713,0,2.601c0,0.612-0.245,1.101-0.704,1.499c-0.275,0.245-0.581,0.52-0.857,0.765
			c-4.253,3.733-8.506,7.465-12.758,11.167c-0.275,0.245-0.581,0.49-0.857,0.734c-0.367,0.337-0.795,0.52-1.285,0.551
			c-0.581,0.031-1.163,0.031-1.713,0.031c-13.707,0-27.413,0-41.09,0c-2.723,0-2.203,0.153-4.1-1.53
			c-4.1-3.61-8.2-7.221-12.269-10.861c-0.367-0.306-0.704-0.643-1.071-0.979s-0.581-0.765-0.581-1.254
			c-0.031-0.673-0.031-1.346-0.031-2.019c0-33.257,0-66.514,0-99.741c0-3.213-0.214-2.601,2.081-4.651
			c3.916-3.518,7.863-7.037,11.779-10.525c0.428-0.398,0.857-0.765,1.316-1.132c0.367-0.337,0.826-0.49,1.316-0.52
			c0.673-0.031,1.346-0.031,2.019-0.031c13.401,0,26.832,0,40.233,0c3.09,0,2.57-0.122,4.865,1.866
			c4.039,3.549,8.047,7.098,12.055,10.647c0.428,0.398,0.857,0.765,1.285,1.163c0.275,0.275,0.428,0.643,0.428,1.04
			c0.031,0.857,0.031,1.713,0.031,2.601c0,16.399,0,32.768,0,49.167C421.023,158.167,421.023,174.658,421.023,191.118
			L421.023,191.118z M396.333,103.462c0-0.857,0-1.713-0.031-2.57c0-0.184-0.031-0.398-0.122-0.551s-0.245-0.367-0.398-0.367
			c-0.551-0.092-1.132-0.122-1.713-0.122c-7.863,0-15.695,0-23.558,0c-0.49,0-0.948,0.031-1.438,0.061
			c-0.337,0.031-0.734,0.398-0.734,0.734c-0.031,0.948-0.061,1.928-0.061,2.876c0,25.578,0,51.125,0,76.703c0,0.673,0,1.346,0,2.019
			c0,0.275,0.031,0.581,0.061,0.857c0.031,0.275,0.49,0.704,0.795,0.734c0.581,0.031,1.132,0.061,1.713,0.061
			c7.557,0,15.114,0,22.702,0c0.398,0,0.765,0,1.163,0c0.275,0,0.581-0.031,0.857-0.061c0.367-0.031,0.734-0.398,0.734-0.734
			c0.031-0.948,0.061-1.928,0.061-2.876c0-12.819,0-25.67,0-38.489C396.333,128.948,396.333,116.19,396.333,103.462L396.333,103.462
			z M166.898,108.235c0,0.581,0,1.163-0.092,1.713c-0.031,0.214-0.306,0.49-0.551,0.551c-0.367,0.122-0.765,0.092-1.132,0.092
			c-7.006,0-13.982,0-20.988,0c-0.275,0-0.581,0-0.857-0.031c-0.52-0.031-0.948-0.398-0.948-0.857
			c-0.031-0.673-0.031-1.346-0.061-2.019c0-1.928,0-3.824,0-5.752c0-0.49-0.061-0.948-0.092-1.438
			c-0.031-0.337-0.428-0.673-0.795-0.704c-0.184,0-0.398-0.031-0.581-0.031c-6.119,0-12.269,0-18.388,0c-0.092,0-0.184,0-0.275,0
			c-0.918,0.031-1.254,0.337-1.285,1.163c-0.031,0.765-0.031,1.53-0.031,2.295c0,6.333,0,12.636,0,18.969c0,1.713,0,3.457,0,5.171
			c0,0.49,0.061,0.948,0.092,1.438c0,0.184,0.398,0.612,0.551,0.612c0.857,0.031,1.713,0.092,2.57,0.092c8.995,0,18.021,0,27.016,0
			c0.857,0,1.713,0.031,2.601,0.061c0.337,0,0.673,0.398,0.704,0.765c0.031,0.581,0.061,1.163,0.061,1.713c0,6.517,0,13.034,0,19.55
			c0,0.275,0,0.581,0,0.857c-0.031,1.499-0.214,1.713-1.622,1.713c-0.765,0-1.53,0-2.295,0c-8.72,0-17.439,0-26.159,0
			c-0.857,0-1.713,0.031-2.601,0.031c-0.52,0-0.857,0.398-0.887,0.918c-0.031,0.581-0.031,1.163-0.031,1.713
			c0,8.047,0,16.093,0,24.14c0,0.581,0,1.163,0.031,1.713s0.367,0.918,0.857,0.979c0.184,0,0.367,0.061,0.581,0.061
			c6.241,0,12.452,0,18.694,0c0.275,0,0.581-0.031,0.857-0.153c0.153-0.061,0.337-0.275,0.367-0.428
			c0.092-0.459,0.122-0.948,0.122-1.438c0-1.928,0-3.824,0-5.752c0-0.673,0.031-1.346,0.061-2.019c0-0.367,0.367-0.765,0.704-0.795
			c0.367-0.031,0.765-0.092,1.132-0.092c7.006,0,13.982,0,20.988,0c0.367,0,0.765,0,1.132,0.122
			c0.214,0.092,0.459,0.337,0.551,0.581c0.092,0.367,0.092,0.765,0.092,1.132c0,10.555,0,21.08,0,31.636
			c0,0.398,0.031,0.795-0.092,1.132c-0.061,0.214-0.367,0.52-0.551,0.551c-0.857,0.092-1.713,0.122-2.57,0.122
			c-10.647,0-21.264,0-31.911,0c-1.254,0-2.478,0-3.733,0c-11.687,0-23.375,0-35.062,0c-0.948,0-1.928-0.031-2.876-0.061
			c-0.49,0-0.826-0.398-0.857-0.948c0-0.275-0.031-0.581-0.031-0.857c0-7.006,0-13.982,0-20.988c0-0.367,0.092-0.765,0.122-1.132
			c0.031-0.184,0.398-0.551,0.612-0.551c1.438-0.031,2.876-0.031,4.314-0.092c0.459,0,0.948-0.061,1.407-0.184
			c0.153-0.031,0.306-0.245,0.398-0.398c0.092-0.153,0.122-0.367,0.122-0.551c0.031-0.857,0.031-1.713,0.031-2.601
			c0-25.578,0-51.186,0-76.764c0-3.947,0.428-3.549-3.488-3.58c-0.857,0-1.713-0.031-2.601-0.061
			c-0.52-0.031-0.857-0.367-0.857-0.918c-0.031-0.581-0.031-1.163-0.031-1.713c0-6.425,0-12.85,0-19.275
			c0-0.581,0.031-1.163,0.031-1.713c0.031-0.459,0.428-0.857,0.948-0.857c0.857-0.031,1.713-0.031,2.601-0.031
			c5.262,0,10.555,0,15.818,0c18.204,0,36.409,0,54.613,0c0.948,0,1.928,0.061,2.876,0.092c0.367,0.031,0.765,0.398,0.765,0.734
			c0.031,0.581,0.061,1.163,0.061,1.713C166.898,87.92,166.898,98.108,166.898,108.235L166.898,108.235z M611.939,109.795
			c-0.031,0.398-0.337,0.765-0.673,0.795c-0.398,0.031-0.765,0.061-1.132,0.061c-7.006,0-13.982,0-20.988,0
			c-0.367,0-0.765-0.061-1.132-0.092c-0.214-0.031-0.551-0.367-0.551-0.581c-0.031-0.49-0.092-0.948-0.092-1.438
			c0-2.203,0-4.406,0-6.609c0-0.49-0.092-0.948-0.122-1.438c0-0.184-0.398-0.551-0.581-0.581c-0.275-0.031-0.581-0.061-0.857-0.061
			c-7.19,0-14.38,0-21.539,0c-0.367,0-0.765,0.031-1.132,0.122c-0.153,0.031-0.306,0.214-0.398,0.398
			c-0.092,0.153-0.122,0.367-0.122,0.551c-0.031,0.398-0.031,0.765-0.031,1.163c0,8.444,0,16.858,0,25.302
			c0,0.581,0.061,1.163,0.153,1.713c0.031,0.153,0.245,0.337,0.398,0.367c0.551,0.092,1.132,0.122,1.713,0.122
			c4.039,0,8.047,0,12.055,0c5.446,0,10.923,0,16.369,0c0.765,0,1.53,0,2.295,0.031c0.49,0,0.948,0.184,1.316,0.49
			c0.306,0.245,0.581,0.49,0.887,0.734c4.222,3.763,8.444,7.526,12.636,11.29c0.214,0.184,0.398,0.398,0.643,0.581
			c0.765,0.581,1.01,1.346,0.948,2.264c-0.031,0.673,0,1.346,0,2.019c0,7.373,0,14.747,0,22.12c0,7.465,0,14.961,0,22.426
			c0,0.765,0,1.53-0.031,2.295c0,0.49-0.184,0.918-0.551,1.285c-0.275,0.245-0.551,0.52-0.857,0.765
			c-4.314,3.824-8.597,7.649-12.881,11.443c-0.275,0.245-0.551,0.551-0.887,0.734s-0.704,0.337-1.071,0.367
			c-0.581,0.061-1.163,0.031-1.713,0.031c-12.636,0-25.302,0-37.938,0c-0.092,0-0.184,0-0.275,0c-2.264,0-1.989,0.031-3.58-1.377
			c-4.039-3.518-8.077-7.068-12.116-10.617c-0.52-0.459-1.01-0.887-1.499-1.346c-0.459-0.398-0.673-0.918-0.673-1.53
			c0-0.49,0-0.948,0-1.438c0-5.477,0-10.923,0-16.369c0-0.49,0-0.948,0.031-1.438c0.031-0.795,0.398-1.132,1.285-1.163
			c0.184,0,0.398,0,0.581,0c6.976,0,13.982,0,20.988,0c0.367,0,0.795,0,1.132,0.122c0.214,0.092,0.49,0.337,0.52,0.581
			c0.092,0.459,0.092,0.948,0.092,1.438c0,2.111,0,4.222,0,6.333c0,0.49,0.031,0.948,0.061,1.438
			c0.031,0.306,0.459,0.704,0.795,0.704c0.765,0.031,1.53,0.061,2.295,0.061c4.895,0,9.76,0,14.655,0c1.622,0,3.243,0,4.895,0
			c0.49,0,0.979,0,1.438-0.122c0.214-0.061,0.459-0.337,0.551-0.551c0.122-0.245,0.092-0.551,0.092-0.857c0-8.903,0-17.807,0-26.74
			c0-0.275-0.031-0.581-0.122-0.857c-0.061-0.153-0.245-0.337-0.428-0.367c-0.673-0.061-1.346-0.122-2.019-0.122
			c-5.354,0-10.739,0-16.093,0c-4.039,0-8.047,0-12.055,0c-0.673,0-1.346,0-2.019-0.031c-0.49,0-0.918-0.214-1.316-0.52
			c-0.367-0.306-0.734-0.612-1.101-0.948c-4.314-3.794-8.628-7.618-12.911-11.412c-0.275-0.245-0.612-0.49-0.826-0.795
			c-0.214-0.306-0.398-0.643-0.459-1.01c-0.092-0.551-0.061-1.132-0.061-1.713c0-15.42,0-30.84,0-46.26c0-0.49,0-0.948,0.031-1.438
			c0-0.612,0.275-1.101,0.734-1.499c2.295-2.019,4.589-4.069,6.884-6.088c2.448-2.172,4.895-4.283,7.343-6.456
			c0.704-0.612,1.438-0.887,2.356-0.857c0.673,0.031,1.346,0,2.019,0c11.871,0,23.773,0,35.644,0c0.673,0,1.346,0.031,2.019,0
			c1.01-0.031,1.836,0.367,2.57,1.04c4.436,3.947,8.873,7.894,13.34,11.84c0.153,0.122,0.275,0.275,0.428,0.398
			c0.581,0.459,0.765,1.071,0.765,1.775c0,3.243,0,6.517,0,9.76c0,2.784,0,5.568,0,8.322
			C611.969,108.816,611.969,109.306,611.939,109.795L611.939,109.795z M25.333,129.499c0.857,0.031,1.713,0.061,2.57,0.061
			c8.995,0,18.021,0,27.016,0c0.765,0,1.53,0.031,2.295,0c0.826-0.031,1.469,0.275,2.05,0.826c4.559,4.1,9.117,8.2,13.707,12.269
			c0.612,0.551,0.948,1.163,0.918,1.989c-0.031,0.673,0,1.346,0,2.019c0,14.961,0,29.892,0,44.822c0,0.765,0,1.53-0.031,2.295
			c0,0.612-0.245,1.101-0.704,1.499c-1.071,0.948-2.142,1.897-3.243,2.845c-3.427,3.06-6.884,6.088-10.311,9.148
			c-0.275,0.245-0.551,0.551-0.887,0.734c-0.306,0.184-0.704,0.337-1.04,0.398c-0.581,0.061-1.163,0.031-1.713,0.031
			c-12.636,0-25.302,0-37.938,0c-2.57,0-2.111,0.184-4.1-1.56c-4.1-3.61-8.2-7.19-12.33-10.8c-0.367-0.306-0.704-0.643-1.071-0.948
			c-0.367-0.337-0.52-0.795-0.551-1.285c0-0.184-0.031-0.398-0.031-0.581c0-6.119,0-12.269,0-18.388
			c0-0.275,0.031-0.581,0.061-0.857c0.031-0.367,0.367-0.765,0.704-0.795c0.49-0.031,0.948-0.092,1.438-0.092
			c6.7,0,13.401,0,20.132,0c0.49,0,0.948,0.031,1.438,0.092c0.337,0.031,0.673,0.428,0.704,0.765
			c0.031,0.581,0.061,1.163,0.061,1.713c0,2.111,0,4.222,0,6.333c0,0.367,0.031,0.765,0.153,1.132
			c0.092,0.245,0.367,0.459,0.581,0.551c0.367,0.122,0.765,0.122,1.132,0.122c7.006,0,13.982,0,20.988,0
			c0.398,0,0.765-0.031,1.163-0.061c0.275-0.031,0.795-0.459,0.795-0.734c0.031-0.398,0.061-0.765,0.061-1.132
			c0-8.628,0-17.256,0-25.884c0-0.398-0.061-0.765-0.092-1.132c-0.031-0.153-0.428-0.551-0.612-0.551
			c-0.765-0.031-1.53-0.092-2.295-0.092c-8.995,0-18.021,0.031-27.016,0c-4.345,0-2.937,0.52-6.333-2.417
			c-3.763-3.243-7.465-6.578-11.198-9.882c-0.49-0.428-1.01-0.887-1.469-1.377C0.153,140.391,0,140.085,0,139.809
			c-0.061-0.765-0.061-1.53-0.061-2.295c0-7.588,0-15.145,0-22.702c0-7.465,0-14.931,0-22.426c0-0.765,0-1.53,0.031-2.295
			c0-0.612,0.245-1.101,0.704-1.499c1.866-1.652,3.733-3.304,5.599-4.956c2.845-2.478,5.66-4.926,8.444-7.435
			c0.765-0.673,1.591-1.01,2.601-0.979c0.673,0.031,1.346,0,2.019,0c11.871,0,23.773,0,35.644,0c3.947,0,2.876-0.337,5.844,2.264
			c3.733,3.274,7.435,6.609,11.167,9.913c0.428,0.398,0.826,0.795,1.285,1.163c0.49,0.398,0.643,0.918,0.643,1.53
			c0,0.275,0,0.581,0,0.857c0,5.844,0,11.687,0,17.531c0,0.398,0,0.765-0.031,1.163c-0.031,0.643-0.428,0.948-1.132,0.979
			c-0.184,0-0.398,0-0.581,0c-7.006,0-13.982,0-20.988,0c-0.398,0-0.765-0.031-1.132-0.061c-0.153,0-0.551-0.428-0.551-0.612
			c-0.031-0.673-0.092-1.346-0.092-2.019c0-2.019,0-4.039,0-6.027c0-0.49-0.031-0.948-0.153-1.407
			c-0.061-0.275-0.245-0.52-0.581-0.551c-0.367-0.031-0.765-0.092-1.132-0.092c-7.098,0-14.166,0-21.264,0
			c-0.275,0-0.581,0.031-0.857,0.031c-0.367,0.031-0.765,0.367-0.795,0.704c-0.061,0.367-0.092,0.765-0.092,1.132
			c0,8.628,0,17.256,0,25.853c0,0.398,0.061,0.765,0.092,1.132C24.66,129.132,24.966,129.499,25.333,129.499L25.333,129.499z
			 M328.35,98.659c-0.031,0.826-0.398,1.163-1.285,1.163c-1.163,0.031-2.295,0-3.457,0.031c-0.49,0-0.948,0.031-1.438,0.061
			c-0.306,0.031-0.734,0.459-0.795,0.734c-0.031,0.275-0.092,0.581-0.092,0.857c0,0.765,0,1.53,0,2.295c0,25.394,0,50.758,0,76.152
			c0,1.04,0.061,2.111,0.092,3.151c0,0.306,0.428,0.704,0.765,0.704c1.346,0.031,2.692,0.061,4.008,0.092
			c0.398,0,0.765,0.031,1.163,0.031c0.49,0.031,0.948,0.428,0.979,0.887c0.031,0.184,0.031,0.398,0.031,0.581
			c0,7.282,0,14.563,0,21.845c0,0.184-0.031,0.367-0.061,0.581c-0.031,0.184-0.367,0.581-0.551,0.581
			c-0.673,0.031-1.346,0.122-1.989,0.122c-5.66,0-11.29,0-16.95,0c-5.446,0-10.923,0-16.369,0c-0.673,0-1.346-0.031-2.019-0.031
			c-0.337,0-0.765-0.398-0.796-0.704c-0.031-0.398-0.061-0.765-0.061-1.132c0-6.884,0-13.799,0-20.682c0-0.275,0-0.581,0.031-0.857
			c0.031-0.673,0.428-1.101,1.04-1.132c0.948-0.031,1.928-0.031,2.876-0.031c0.765,0,1.53-0.031,2.295-0.061
			c0.306,0,0.795-0.459,0.795-0.734c0.031-0.275,0.061-0.581,0.061-0.857c0-0.765,0-1.53,0-2.295c0-25.394,0-50.758,0-76.152
			c0-1.04-0.061-2.111-0.092-3.151c0-0.367-0.398-0.734-0.765-0.734c-1.438-0.031-2.876-0.031-4.314-0.092
			c-0.459,0-0.948-0.061-1.407-0.153c-0.153-0.031-0.337-0.214-0.398-0.367c-0.092-0.367-0.153-0.734-0.153-1.132
			c-0.031-1.713,0-3.457,0-5.171c0-4.987,0-9.944,0-14.931c0-0.673,0.031-1.346,0.092-2.019c0.031-0.337,0.398-0.704,0.765-0.734
			c0.275-0.031,0.581-0.061,0.857-0.092c0.581-0.031,1.163,0,1.713,0c10.617,0,21.264,0,31.88,0c0.765,0,1.53,0,2.295,0.031
			c0.826,0.031,1.163,0.398,1.193,1.254c0.031,0.673,0.031,1.346,0.031,2.019c0,6.119,0,12.269,0,18.388
			C328.381,97.496,328.381,98.077,328.35,98.659L328.35,98.659z"
          />
        </g>
      </g>
      <text
        transform="matrix(1.192 0 0 1 244.0698 686.7705)"
        stroke={primary}
        stroke-width="34"
        stroke-miterlimit="10"
        font-family="'College'"
        font-size="633.6727"
      >
        1
      </text>
    </svg>
  );
}

Artwrk11.props = {
  primary: string,
};

Artwrk11.defaultProps = {
  primary: "#000",
};
