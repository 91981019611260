import React from "react";
import "./main.css";

import { connect } from "react-redux";

function Rightside(props) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="240 40 400 560"
        enableBackground="new 240 40 400 560"
        xmlSpace="preserve"
      >
        <path
          fill="url(#SVGID_1_)"
          d="M440.722,479.321l92.997-8.896c0,0-5.284,40.479-5.284,47.961
	c-7.725,18.494-49.659,23.67-87.713,23.67c-38.052,0-81.783-5.915-89.513-24.409c0-7.482,0-47.222,0-47.222L440.722,479.321z"
        />
        <path
          fill="url(#SVGID_2_)"
          d="M351.302,476.769c4.195,5.632,32.9,18.085,96.897,18.085c63.031,0,81.279-14.851,85.545-19.268
	c0-0.311,0.014-0.761,0.014-0.761l-92.89,9.775l-89.733-9.267C351.134,475.333,351.302,476.499,351.302,476.769z"
        />
        <path
          id="body"
          style={{ fill: props.body }}
          d="M348.484,474.067c-5.136-4.175-10.154-10.099-10.609-15.509
	c-0.923-10.889,3.427-17.108,6.341-40.048c0.232-1.833-1.841-6.059-1.077-9.158c1.45-5.923,4.036-7.094,6.227-11.794
	c0.877-1.892-2.741-4.731-2.741-10.423c0-3.484,4.064-10.519,6.345-20.837c0-36.812-2.818-48.081-3.859-86.288
	c-3.136-11.811-7.922-22.902-7.922-35.244c0-14.062,9.141-53.959,11.227-65.617c3.614-20.179,10.854-33.038,23.454-53.486
	c7.404-12.016,24.618-17.781,43.709-17.781c17.541,0,49.386,30.163,60.136,30.163c1.323,0.025,1.559,0.832,1.736,1.225
	c28.522,60.429,53.172,93.612,52.972,135.408c-0.264,55.409,9.877,137.23,10.727,165.468c0.511,17.005-6.58,29.936-11.464,35.032
	c-17.163,15.104-53.022,18.968-90.667,18.968C405.37,494.145,361.516,487.138,348.484,474.067z"
        />
        <g>
          <path
            fill="url(#SVGID_3_)"
            d="M426.697,112.321l-6.295,9.53l0.464,1.775l-2.055,1.801c0,0-2.941,4.782-5.091,6.959
		c-7.018,7.11-24.054,40.817-23.282,66.73c0.241,0.023,0.559,0.087,0.827,0.111c-0.891-25.94,15.904-59.129,23.041-66.49
		c2.305-2.377,5.364-7.26,5.364-7.26l1.968-1.505l-0.441-1.857l6.363-9.612L426.697,112.321z"
          />
          <path
            fill="url(#SVGID_4_)"
            d="M411.315,132.386c2.15-2.177,5.091-6.959,5.091-6.959l2.055-1.801l-0.464-1.775l6.295-9.53
		l0.514,0.108l-6.223,9.422l0.464,1.775l-2.054,1.801c0,0-2.941,4.782-5.091,6.959c-7.018,7.11-24.054,40.817-23.282,66.73
		c0.071,0.007,0.159,0.02,0.241,0.033c0,0.025,0,0.051,0,0.078c-0.268-0.024-0.586-0.089-0.827-0.111
		C387.261,173.203,404.297,139.496,411.315,132.386z"
          />
        </g>
        <path
          fill="#3A3A3A"
          d="M485.486,435.057l5.909,7.999c16.227-29.668,32.082-71.779,32.082-71.779l-5.759-8.211l-0.145-0.211
	C517.572,362.854,499.604,410.906,485.486,435.057z"
        />
        <path
          id="pockets"
          style={{ fill: props.pockets }}
          stroke="#0A0A0A"
          strokeMiterlimit="10"
          d="M520.149,367.316c0,0-17.054,45.627-31.704,70.98
	l-2.468-3.336c13.918-23.617,31.654-71.242,31.654-71.242L520.149,367.316z M491.332,442.201l-2.614-3.533
	c14.668-25.144,31.709-70.965,31.709-70.965l2.564,3.66C522.99,371.363,507.168,412.943,491.332,442.201z"
        />
        <path
          fill="#020202"
          d="M482.392,435.649l9.402,12.817c14.105-25.652,34.777-77.746,34.777-77.746l-9.279-13.109l-0.116-0.167
	C517.176,357.444,496.735,411.032,482.392,435.649z M491.744,447.783l-8.961-12.208c14.389-24.106,34.438-77.442,34.438-77.442
	l8.961,12.661C526.182,370.794,505.608,422.523,491.744,447.783z"
        />
        <path
          fill="url(#SVGID_5_)"
          d="M447.956,556.091c0,0,1.073,1.701,1.359,2.136c9.741,3.367,47.911-18.357,48.138-28.085
	c-0.173-0.324-0.705-1.294-0.705-1.294S461.951,548.101,447.956,556.091z"
        />
        <path
          id="base_1_"
          style={{ fill: props.rib2 }}
          d="M438.426,494.854c0,0,73.25,2.396,95.318-19.268c-0.777,11.248-4.523,29.857-5.309,41.38
	c-7.725,18.49-49.659,23.669-87.713,23.669c-38.052,0-81.783-5.918-89.513-24.408c0-7.484,0.093-39.459,0.093-39.459
	s4.073,2.376,12.44,6.056C385.901,492.57,438.426,494.854,438.426,494.854z M482.894,138c-5.639-13.717-23.498-41.293-51.256-55.988
	c-13.682-7.243-30.723-6.277-38.7-4.151c-3.607,0.961-3.768,4.016-4.023,6.565c-0.482,4.828-3.202,19.38-5.53,25.101
	c-1.298,3.19-5.236,9.075-3.877,11.64c6.011-3.354,25.986-5.13,40.92-0.198c14.934,4.933,58.399,26.375,63.636,24.008
	C484.242,144.232,484.108,140.952,482.894,138z"
        />
        <path
          id="hand_cufs"
          style={{ fill: props.rib2 }}
          d="M477.665,493.263c0,0,16.539,29.969,19.375,36.07c-0.368,8.884-40.079,31.838-48.724,27.26
	c-3.827-5.339-22.323-33.137-22.323-33.137s20.6-5.206,30.315-11.605C464.668,506.343,477.665,493.263,477.665,493.263z"
        />
        <path
          id="hand"
          style={{ fill: props.sleeves }}
          d="M347.934,229.701c-3.952,18.251-2.095,55.829-4.209,97.329
	c-0.691,13.583-0.205,14.851-0.246,17.433c-0.054,3.766-1.777,9.153-1.123,15.163c0.923,8.474,6.595,17.279,7.768,21.172
	c2.573,8.584,5.527,31.683,7.368,36.733c10.295,28.253,23.627,48.056,36.859,76.972c0.941,2.061,4.791,1.28,5.877,3.384
	c0.75,1.453-1.027,6.829,0.182,8.72c3.827,6.002,24.872,16.083,24.872,16.083s6.455,1.261,28.418-10.608
	c18.327-9.9,23.663-19.72,23.663-19.72s-2.554-22.708-7.413-33.504c-3.25-7.211-8.423-9.174-10.423-15.145
	c-2.836-8.488-16.3-48.27-17.064-50.831c-0.936-3.117-3.409-5.283-4.404-8.593c-0.382-1.28,0.618-4.705-0.255-7.625
	c-0.936-3.114-4.595-3.169-4.873-5.435c-0.359-2.934,3.282-2.89,2.936-5.059c-0.445-2.845-4.309-1.551-4.582-3.815
	c-0.2-1.618,2.482-3.19,2.886-5.9c1.896-12.659,7.8-41.889,9.177-66.245c0.45-8.01,1.345-28.575,0-41.89
	c-3.491-34.521-20.943-79.902-49.452-79.902C367.329,168.417,352.975,206.428,347.934,229.701z"
        />
        <path
          fill="url(#SVGID_6_)"
          d="M453.342,512.304c15.068-8.191,24-19.767,24-19.767l0.323,0.726c0,0-7.809,11.227-23.736,19.943
	c-15.827,8.658-27.936,10.25-27.936,10.25l-0.523-0.736C425.47,522.72,437.561,520.888,453.342,512.304z"
        />
        <g>
          <path
            fill="url(#SVGID_7_)"
            d="M480.603,140.443c-5.638-13.717-22.397-40.089-50.156-54.785
		c-13.682-7.243-30.584-6.235-38.561-4.109c-1.432,0.382-2.714,1.62-2.9,2.178c0.037-0.893,1.223-2.297,3.05-2.783
		c7.977-2.126,25.018-3.092,38.7,4.151c27.759,14.695,44.638,40.876,50.277,54.593c1.214,2.952,1.482,4.667,1.305,5.41
		c-0.15,0-0.262-0.037-0.373-0.111C481.976,143.702,481.596,142.858,480.603,140.443z"
          />
          <path
            fill="url(#SVGID_8_)"
            d="M379.509,120.944c6.011-3.354,25.986-5.129,40.92-0.198c14.934,4.932,58.399,26.375,63.636,24.007
		c0,0.111,0.037,0.184,0,0.369c-5.236,2.367-48.702-19.074-63.636-24.006c-14.934-4.933-35.261-3.059-41.272,0.296
		C379.229,121.153,379.484,120.968,379.509,120.944z"
          />
          <path
            fill="url(#SVGID_9_)"
            d="M377.341,123.682c6.22-3.354,26.757-5.106,42.211-0.174c15.457,4.933,60.438,26.387,65.859,24.02
		c0.073,0.073,0.109,0.198,0.073,0.332c-5.42,2.368-50.475-19.05-65.931-23.981c-15.454-4.933-36.356-3.084-42.577,0.27
		C377.05,123.891,377.316,123.705,377.341,123.682z"
          />
        </g>
        <path
          id="ribs"
          style={{ fill: props.rib1 }}
          stroke={props.rib1}
          strokeMiterlimit="10"
          d="M426.905,522.365c0.516,0.795,1.489,2.265,2.411,3.656
	c-33.795-1.021-68.786-7.21-76.636-22.566v-5.351c6.759,14.512,38.225,20.776,70.279,22.344c-7.625-3.505-14.245-8.198-14.243-8.195
	c-26.238-2.649-50.197-9.345-56.036-21.694v-5.396c4.936,11.408,25.127,17.79,49.1,20.833c0.039,0.084,0.054,0.195,0.102,0.269
	 M338.065,471.561 M542.861,264.539 M495.37,149.527 M455.3,89.423 M389.165,88.56c8.145-3.297,25.693-3.108,39.341,3.591
	c27.047,13.276,46.238,38.024,52.697,52.14c-1.389-0.351-3.473-1.004-5.986-1.877c-7.164-13.829-26.766-35.57-48.863-46.048
	c-13.214-6.266-30.127-5.883-38.272-2.974c-1.423,1.93-2.002,4.664-1.548,6.968c4.764-2.2,18.409-3.555,32.566,1.1
	c18.595,6.114,39.709,24.762,49.122,38.366c-2.423-0.946-4.961-1.985-7.482-3.08c-9.941-10.901-27.491-25.448-43.579-30.631
	c-14.448-4.655-25.184-2.718-31.584-0.973 M439.762,541.611c10.55,2.676,47.054-18.315,50.583-28.166
	c0.571,1.055,1.125,2.082,1.673,3.098c12.777-2.468,23.906-6.136,31.543-11.313l-0.743,5.683
	c-6.93,4.628-16.875,7.851-28.314,10.236c-0.663-1.246-1.416-2.617-2.154-3.99c-1.095,9.299-40.902,32.286-50.163,28.035
	C441.394,544.026,440.587,542.833,439.762,541.611z M433.775,532.714c11.191,2.271,48.02-19.016,51.336-28.921
	c0.441,0.812,0.607,1.112,0.789,1.452c15.884-2.377,30-6.318,39.27-12.342l-0.748,5.726c-8.541,5.543-21.375,9.013-35.945,11.371
	c-0.43-0.791-0.911-1.682-1.336-2.467c-0.652,9.274-40.806,32.609-50.875,28.893C435.416,535.16,434.582,533.917,433.775,532.714z"
        />
        <g className="right-design-parent">
          <rect
            className={`r-st12 design-place-outline out ${
              props.design && "active-path"
            } right-design`}
            height="130"
            id="XMLID_7_"
            width="80"
            x="353"
            y="220"
          ></rect>
          <rect
            className={`r-st13 design-place-outline in ${
              props.design && "active-path"
            } right-design`}
            height="124"
            id="XMLID_14_"
            width="74"
            x="356"
            y="223"
          ></rect>
          <svg height="139" width="73" x="356" y="220" viewBox="0 0 256 256">
            {props.rightImage}
          </svg>
          <text
            font="[object Object]"
            x="393"
            y="290"
            fill={props.rightTextColor}
            text="VFDS"
            maxLength="3"
            textAnchor="middle"
            fontSize={props.rightTextSize}
            strokeWidth={props.rightTextStroke}
            stroke={props.rightTextStrokeColor}
            nostroke="true"
            style={{ direction: "ltr" }}
            fontFamily={props.rightTextFamily}
            height="108"
            width="107.2"
          >
            {props.rightText}
          </text>
        </g>
      </svg>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    body: state.shirtColor.body,
    sleeves: state.shirtColor.sleeves,
    rib1: state.shirtColor.rib1,
    rib2: state.shirtColor.rib2,
    pockets: state.shirtColor.pockets,
    // text, image
    design: state.rightView.chooseRight,
    rightText: state.rightView.rightText,
    rightImage: state.rightView.rightImage,
    rightTextColor: state.rightView.rightTextColor,
    rightTextSize: state.rightView.rightTextSize,
    rightTextFamily: state.rightView.rightTextFamily,
    rightTextStroke: state.rightView.rightTextStroke,
    rightTextStrokeColor: state.rightView.rightTextStrokeColor,
  };
};

export default connect(mapStateToProps)(Rightside);
