import React from "react";
import { string } from "prop-types";

export default function Artwrk3(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      fill={primary}
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <path
        d="M512.634,740.864c-2.135,0.854-4.128,2.135-6.121,2.99c-6.406,2.705-12.955,5.41-19.503,7.972
	c-4.983,1.851-10.107,3.274-15.09,4.84c-4.271,1.424-8.684,2.705-12.955,3.986c-2.99,0.854-5.979,1.424-9.111,2.135
	c-2.99,0.712-6.121,1.424-9.111,1.851c-8.684,1.566-17.368,3.132-26.052,4.698c-6.406,1.139-12.955,2.278-19.503,3.132
	c-7.972,0.997-15.944,1.708-23.916,2.562c-3.559,0.285-7.118,0.427-10.677,0.712c-26.479,1.708-52.957,1.424-79.578,1.139
	c-11.531-0.142-23.204-1.139-34.736-1.851c-6.976-0.427-14.093-0.854-21.069-1.566c-5.552-0.569-10.962-1.708-16.514-2.42
	c-4.271-0.569-8.542-1.139-12.67-1.708c-4.128-0.57-8.399-0.854-12.385-1.708c-4.128-0.712-8.257-1.993-12.243-2.989
	c-2.705-0.712-5.552-1.139-8.257-1.851c-5.552-1.424-11.104-2.847-16.656-4.413c-5.837-1.566-11.673-3.274-17.368-5.125
	c-4.555-1.566-8.969-3.417-13.382-5.267c-5.837-2.42-11.531-4.983-17.368-7.403c-1.993-0.854-2.278-3.559-0.997-6.406
	c3.417-7.26,6.691-14.663,10.107-21.923c1.993-4.413,4.271-8.542,6.264-12.955c2.99-6.691,5.837-13.524,8.969-20.357
	c2.135-4.698,4.413-9.111,6.691-13.666c1.851-3.844,3.559-7.83,5.41-11.673c0.427-0.854,0.997-1.708,1.424-2.562
	c0.854-1.851,2.135-1.708,3.844-0.997c2.99,1.424,6.121,2.847,9.253,3.986c4.128,1.566,8.399,2.847,12.67,4.128
	c5.267,1.424,10.677,2.705,15.944,4.128c2.705,0.712,5.267,1.566,7.972,2.278c2.847,0.712,5.694,1.424,8.542,1.993
	c4.128,0.712,8.399,1.281,12.67,2.135c4.413,0.712,8.684,1.708,13.097,2.42c1.993,0.285,4.128,0.285,6.264,0.427
	c6.121,0.712,12.243,1.566,18.364,1.993c8.969,0.712,18.08,0.854,27.048,1.708c20.784,1.993,41.711,0.569,62.495,0.712
	c9.965,0.142,20.073-0.712,30.038-1.566c9.253-0.712,18.507-1.708,27.618-3.132c7.26-0.997,14.236-2.847,21.496-4.128
	c3.274-0.712,6.691-1.139,9.965-1.708c1.993-0.427,3.986-0.854,5.979-1.424c4.698-1.139,9.396-2.278,14.094-3.559
	c1.993-0.569,3.986-1.424,5.979-2.135l0,0c8.257-2.847,16.514-5.694,24.913-8.684c1.281-0.427,2.135-0.712,2.847,1.139
	c3.417,7.545,7.118,14.948,10.535,22.493c3.844,8.257,7.403,16.656,11.246,24.913c4.271,9.538,8.542,19.076,12.955,28.472
	c1.281,2.705,2.705,5.125,4.128,7.687C516.904,737.447,515.766,739.725,512.634,740.864L512.634,740.864z M138.23,621.852
	c-3.274,7.118-6.549,14.236-9.823,21.354c-3.701,7.687-7.545,15.09-11.104,22.777c-2.278,4.84-4.271,9.823-6.549,14.521
	c-3.559,7.545-7.26,15.09-10.819,22.635c-0.854,1.851-1.566,3.701-2.562,5.552c-1.424,2.562-2.562,2.705-5.41,1.708
	c-4.271-1.566-8.542-2.99-12.67-4.555c-3.844-1.566-7.545-3.274-11.246-5.125c-5.125-2.42-10.392-4.698-15.375-7.26
	c-6.406-3.274-12.812-6.691-19.076-10.107c-3.701-1.993-7.403-3.844-10.819-6.264c-4.84-3.274-9.538-6.833-14.236-10.392
	c-2.278-1.708-4.698-3.274-6.976-4.983c-0.569-0.427-0.997-1.281-1.424-1.993c0.569-0.427,1.139-0.854,1.708-0.997
	c2.42-0.854,4.983-1.708,7.403-2.42c0.142,0.285,0.142,0.569,0.142,0.712c3.701-0.712,7.26-1.424,10.962-2.135
	c2.99-0.569,5.837-1.424,8.826-1.851c4.271-0.712,8.684-1.139,12.955-1.993c5.837-1.139,11.531-2.705,17.368-3.986
	c1.993-0.427,4.128-0.569,6.121-0.854c2.847-0.285,3.844-1.708,3.559-4.413c-0.427-2.847-0.569-5.694-0.712-8.542
	c-0.712-9.396-1.424-18.934-2.135-28.329c-0.427-5.552-0.997-11.246-1.424-16.798c-0.142-1.139-0.712-2.278-0.712-3.417
	s0.285-2.135,0.427-3.274c1.139,0.142,2.42,0,3.132,0.569c3.132,2.135,5.979,4.84,9.111,6.833c4.983,3.132,10.25,5.979,15.517,8.684
	c5.837,2.99,11.816,5.837,17.795,8.542c3.132,1.424,6.264,2.705,9.538,3.986c5.267,2.135,10.392,4.128,15.659,6.121
	c0.285,0.142,0.427,0.142,0.712,0.285C139.512,617.439,139.939,618.435,138.23,621.852L138.23,621.852z M385.792,51.706
	c-2.562,1.139-5.125,1.993-7.545,3.132c-4.983,2.278-9.965,4.698-14.948,6.976c-1.993,0.854-3.986,1.424-5.979,2.135
	c-10.535,4.413-21.069,8.684-31.604,13.239c-5.41,2.278-10.535,4.983-15.802,7.26c-1.139,0.569-2.562,0.427-4.555,0.712
	c-1.281-0.427-2.99-0.997-4.698-1.708c-7.403-3.274-14.805-6.549-22.208-9.68c-6.548-2.847-13.097-5.552-19.645-8.399
	c-8.257-3.559-16.514-7.118-24.77-10.535c-2.562-1.139-5.267-2.135-7.83-3.274c-0.569-0.285-0.997-0.854-1.139-1.281
	c0-0.285,0.569-0.997,1.139-1.139c6.549-2.99,12.955-6.121,19.646-8.969c10.535-4.555,21.354-8.826,32.031-13.239
	c4.128-1.708,8.399-3.417,12.385-5.125c4.128-1.851,8.399-3.701,12.385-5.837c2.705-1.424,5.125-0.854,7.403,0.142
	c9.111,3.844,17.937,7.972,26.906,11.958c8.542,3.701,17.083,7.403,25.767,10.962c4.84,1.993,9.823,3.559,14.521,5.552
	c3.132,1.281,6.264,2.99,9.253,4.698C388.212,50.14,387.073,51.136,385.792,51.706L385.792,51.706z M359.74,104.663
	c0,3.844-0.712,4.84-4.698,5.552c-0.569,0.142-0.996,0.285-1.566,0.569c-5.125,2.42-10.392,4.84-15.517,7.26
	c-4.698,2.278-9.396,4.84-14.236,7.118c-3.844,1.851-7.83,3.559-11.816,5.41c-0.285,0.142-0.569,0.142-0.712,0.285
	c-3.274,2.847-6.406,1.708-9.823-0.142c-5.267-2.847-10.677-5.125-16.087-7.687c-8.826-4.128-17.652-8.257-26.479-12.385
	c-1.851-0.854-3.844-1.708-5.41-2.847c-0.712-0.427-0.997-1.851-0.854-2.847c0-3.274,0.427-6.549,0.569-9.823
	c0.142-4.128,0.142-8.257,0-12.385c-0.142-3.701-0.285-7.26-0.569-10.962c-0.142-2.135,1.139-1.993,2.42-1.424
	c3.417,1.424,6.976,2.847,10.392,4.271c6.406,2.847,12.67,5.979,18.934,8.826c6.691,2.99,13.382,6.121,20.073,8.969
	c1.281,0.569,3.417,0.285,4.84-0.285c9.823-4.413,19.788-8.826,29.611-13.382c3.701-1.708,7.26-3.844,10.819-5.552
	c2.42-1.139,4.983-2.278,7.545-3.274c1.993-0.712,2.278,0.569,2.278,2.135c0,6.121,0,12.385,0,18.507c0.142,0,0.285,0,0.427,0
	C359.74,95.125,359.598,99.965,359.74,104.663L359.74,104.663z M228.486,94.698c-3.844,1.566-7.83,0.142-9.111-2.278
	c-2.135-3.844-4.84-2.562-7.403-2.562c-41.711-0.142-83.422,0-124.991-0.142c-1.851,0-3.132,0.427-4.413,1.851
	c-2.42,2.99-5.552,4.271-9.538,2.847c-2.562-0.854-5.125-3.986-5.125-6.406c0-4.128,1.993-6.406,5.125-7.687
	c4.983-2.135,7.83,0.285,10.535,3.844c0.427,0.427,1.139,0.854,1.708,0.997c0.854,0.142,1.851,0,2.705,0
	c41.996,0,83.849,0,125.845,0c2.562,0,4.698,0,5.694-3.132c0.285-0.854,1.708-1.424,2.705-1.993
	c5.41-2.42,11.104,1.281,10.819,8.114C232.899,90.569,231.902,93.132,228.486,94.698L228.486,94.698z M543.241,89.573
	c-2.562,3.132-7.403,4.271-10.677,2.42c-0.854-0.427-1.993-0.854-2.42-1.708c-1.708-2.99-4.413-2.847-7.26-2.847
	c-20.642,0-41.284,0-61.926,0v0.142c-20.784,0-41.569,0-62.353,0c-2.42,0-4.413,0.427-5.979,2.562
	c-2.42,3.274-5.694,2.99-9.823,1.851c-2.562-0.712-3.559-3.274-3.417-7.26c0.285-4.555,1.424-6.121,5.267-7.26
	c3.274-0.997,6.264,0.285,8.684,3.132c0.997,1.139,2.99,2.278,4.413,2.278c37.725,0.142,75.45,0.285,113.175,0.285
	c3.986,0,8.114,0,12.1,0c2.99,0,5.125-1.139,7.26-3.274c3.701-3.701,7.83-3.844,11.673-0.285
	C544.807,82.17,545.234,87.153,543.241,89.573L543.241,89.573z M165.421,651.036c-5.837-1.281-11.673-2.278-17.368-3.701
	c-2.562-0.712-5.125-2.135-7.545-3.417c-0.569-0.285-0.854-1.139-1.281-1.851c1.424-3.274,2.562-6.549,3.986-9.538
	c0.997-2.278,2.278-4.413,3.417-6.691c0.854-1.851,1.708-1.851,2.847-0.285c4.983,6.833,9.965,13.666,14.948,20.5
	c0.712,0.854,1.708,1.566,2.278,2.562c0.427,0.569,0.285,1.424,0.427,2.278C166.56,651.036,165.99,651.178,165.421,651.036
	L165.421,651.036z M471.065,644.202c-5.694,3.417-18.222,6.976-25.482,7.118c-0.142-0.427-0.712-1.139-0.569-1.708
	s0.712-1.281,1.139-1.708c3.559-4.556,7.26-8.969,10.819-13.524c2.278-2.847,4.413-5.837,6.691-8.684
	c0.427-0.427,1.139-0.712,1.708-0.997c0.285,0.569,0.569,0.997,0.854,1.566c2.135,4.698,3.986,9.253,6.264,13.951
	C473.2,641.925,472.631,643.206,471.065,644.202L471.065,644.202z M185.92,768.624h-0.142c0-0.142,0.142-0.142,0.142-0.285
	c0,0,0.142,0,0.142,0.142C185.92,768.482,185.92,768.624,185.92,768.624L185.92,768.624z M448.572,658.438L448.572,658.438
	L448.572,658.438L448.572,658.438z M611.858,659.008c-1.424-0.569-2.847-1.139-4.271-1.424c-3.701-0.712-7.545-1.139-11.246-1.851
	c-4.84-0.854-9.68-1.993-14.663-2.99c-2.99-0.569-6.121-1.139-9.111-1.708c-4.698-0.854-9.396-1.851-14.094-2.705
	c-3.986-0.712-7.83-1.708-11.816-1.993c-2.278-0.285-2.99-1.139-2.847-3.132c0.569-5.979,1.139-11.816,1.566-17.795
	c0.712-9.253,1.139-18.364,1.851-27.618c0.427-5.552,0.997-11.246,1.566-16.798c-1.424,0.427-3.274,0.569-4.271,1.424
	c-5.41,5.267-12.101,8.399-18.507,11.958c-5.552,3.132-11.389,5.694-17.225,8.399c-5.552,2.562-11.246,5.125-16.941,7.545
	c-2.42,0.997-5.125,1.566-7.545,2.562c-2.705,1.139-5.267,2.562-7.972,3.559c-3.132,0.997-3.559,1.566-2.135,4.555
	c1.851,4.128,3.844,8.257,5.694,12.385c3.417,7.687,6.833,15.232,10.392,22.777c3.844,8.257,7.972,16.514,11.816,24.913
	c4.413,9.253,8.542,18.507,12.955,27.618c0.997,2.135,2.99,2.705,5.267,1.851c6.549-2.562,13.097-5.125,19.503-7.83
	c6.691-2.847,13.239-5.837,19.788-9.111c7.687-3.844,15.375-7.972,22.777-12.243c5.125-2.847,9.965-6.264,14.805-9.538
	c4.983-3.559,9.68-7.26,14.521-11.104C612,660.289,612,659.008,611.858,659.008z M315.609,349.805
	c-2.705,0.142-3.701,1.139-3.701,3.844c0.142,2.705,1.993,4.555,4.84,3.844c5.837-1.281,11.673-1.566,17.51,0
	c2.562,0.569,4.128-0.997,4.413-3.559c0.285-2.705-1.139-4.128-4.271-4.128c-4.413,0-4.698-0.142-5.979-4.413
	c-0.142-0.569-0.285-0.996-0.427-1.566c-2.847-11.389-5.694-22.777-8.684-34.166c-1.851-7.26-3.701-14.378-5.694-21.496
	c-0.569-2.135-1.993-3.417-4.413-3.417c-2.562,0-4.128,1.139-4.983,3.274c-0.427,1.139-0.854,2.42-1.139,3.559
	c-2.562,9.111-4.84,18.222-7.403,27.191c-2.562,8.969-4.983,17.937-7.83,26.763c-1.281,4.128-1.993,4.271-6.264,4.271
	c-2.99,0-4.271,1.281-3.986,4.271s2.278,4.271,5.979,3.274c4.84-1.281,9.68-1.424,14.521,0c0.569,0.142,0.996,0.142,1.566,0.142
	c2.99,0.142,4.271-1.281,4.128-4.271c-0.142-2.562-1.424-3.559-3.986-3.559c-3.417,0-4.128-0.854-3.417-4.271
	c0.285-1.851,0.854-3.701,1.281-5.552c2.278-9.68,1.993-8.114,10.677-8.257c1.851,0,3.701,0,5.694,0
	c1.851-0.142,2.99,0.569,3.417,2.562c0.712,3.417,1.708,6.833,2.278,10.25C320.592,348.666,319.595,349.662,315.609,349.805z
	 M312.62,324.607c-1.281,0-2.705,0-3.986,0c-7.687,0-6.976,0.285-5.267-6.833c1.566-6.121,3.132-12.1,4.698-18.222
	c0.142-0.569,0.569-0.997,0.854-1.566c0.285,0.569,0.712,0.997,0.712,1.566c1.708,7.403,3.274,14.805,4.983,22.208
	C315.182,323.753,314.613,324.749,312.62,324.607z M422.805,351.94c0.427,0.285,0.854,0.569,1.281,0.854
	c2.278,1.424,4.555,3.274,7.118,4.271c10.677,3.701,22.066-2.705,24.628-14.094c2.847-12.528,0-22.208-14.236-25.767
	c-3.559-0.854-7.118-1.993-10.535-3.132c-6.121-2.135-7.687-4.84-7.26-11.246c0.427-6.121,4.983-10.535,11.104-10.535
	c6.264,0,10.962,4.413,11.246,10.677c0.142,2.135,0,4.271,0,6.406c0,1.708,0.712,2.99,2.705,3.132
	c1.993,0.142,3.559-0.285,4.128-2.562c0.142-0.997,0.427-1.993,0.427-2.99c0-2.705,0-5.552,0-8.399c-0.142,0-0.142,0-0.285,0
	c0-1.139,0.285-2.278,0.142-3.417c0-2.562,0.142-5.267-0.427-7.687c-0.142-0.996-1.851-2.278-2.99-2.278
	c-0.997,0-2.705,0.997-3.132,1.993c-0.712,1.708-0.997,1.993-2.562,0.854c-6.833-5.267-15.802-4.555-22.066,1.281
	c-8.684,7.972-10.107,27.333,6.976,32.742c3.844,1.281,7.83,2.135,11.673,3.274c3.559,1.139,6.691,2.847,7.83,6.691
	c1.708,5.267,1.139,10.392-3.132,14.378c-4.128,3.986-9.111,4.271-14.236,2.42c-4.983-1.851-8.257-6.833-8.399-12.1
	c0-2.278,0.427-4.413,0.285-6.691c0-2.847-1.566-4.128-3.986-3.986c-2.562,0.142-3.274,1.851-3.274,4.128
	c0,4.84,0.285,9.68,0.285,14.378c0,2.99-0.285,6.121-0.285,9.111c0,1.993,0.569,3.844,3.132,3.844c2.562,0,3.132-1.566,3.274-3.701
	c0-0.142,0-0.427,0-0.569C422.378,353.079,422.663,352.509,422.805,351.94z M355.185,310.229c1.993,7.83,8.257,10.962,15.375,13.097
	c3.274,0.996,6.549,1.708,9.823,2.705c2.99,0.996,5.552,2.847,6.548,5.837c1.993,5.267,1.281,10.25-2.562,14.521
	c-3.559,3.844-9.538,4.84-14.663,2.705c-4.84-1.993-8.114-6.833-8.114-12.243c0-1.993,0.285-3.986,0.285-5.837
	c0-2.99-1.281-4.698-3.559-4.698c-2.562,0-3.701,1.281-3.701,4.413c0,4.84,0.142,9.68,0.142,14.521c0,2.847-0.142,5.694-0.142,8.542
	c0,1.993,0.285,3.844,2.847,3.986c2.705,0.142,3.274-1.566,3.417-3.844c0-0.712,0.285-1.281,0.427-1.993
	c0.569,0.285,1.281,0.569,1.708,0.996c10.962,9.253,26.479,5.125,30.892-8.399c3.559-10.962,2.278-23.774-14.094-27.618
	c-3.986-0.854-7.83-2.278-11.816-3.701c-3.986-1.424-5.694-4.271-5.837-8.399c-0.285-6.976,4.128-12.385,10.677-12.67
	c6.833-0.285,11.816,4.698,11.816,11.816c0,1.851-0.142,3.559,0,5.41c0.142,2.278,1.424,3.274,3.701,3.132
	c2.278-0.142,3.559-2.135,3.417-5.41c-0.142-2.705,0-5.41,0-8.114l0,0c0-2.705,0-5.552,0-8.257c0-1.139,0.142-2.278-0.427-3.132
	c-0.712-0.997-1.993-2.278-2.99-2.278s-2.705,1.139-3.132,2.135c-0.712,1.993-1.281,1.851-2.705,0.712
	c-6.548-4.84-15.232-4.555-21.211,0.854C354.9,294.569,353.192,301.972,355.185,310.229z M191.188,284.747
	c-3.132-0.285-3.417,1.993-3.701,4.128c0,0.427-0.142,0.854-0.285,1.281c-0.427-0.285-0.997-0.427-1.281-0.712
	c-2.847-2.99-6.264-4.555-10.535-4.84c-8.257-0.427-14.236,3.417-18.649,10.107c-5.267,8.114-6.549,17.083-6.549,26.906
	c0.142,8.969,1.281,18.222,6.549,26.479c6.406,10.107,16.941,12.243,25.909,8.399c9.538-4.128,14.378-14.948,13.809-24.77
	c-0.142-2.135-1.281-3.274-3.417-3.417c-2.278,0-3.417,1.281-3.844,3.417c-0.569,3.132-0.854,6.406-1.851,9.396
	c-1.566,4.983-4.983,8.257-10.392,8.969c-5.267,0.712-9.538-1.424-12.528-5.694c-1.424-1.993-2.562-4.271-3.417-6.691
	c-2.99-8.399-3.417-17.083-1.851-25.767c0.854-4.698,2.42-9.396,4.698-13.524c2.847-5.267,8.114-7.26,13.524-6.121
	c5.552,1.281,8.257,5.267,9.111,10.535c0.569,3.559,0.427,7.118,0.712,10.677c0.142,1.851,0.854,3.274,2.99,3.559
	c2.135,0.285,3.559-0.854,3.701-2.99c0.142-1.993-0.142-3.844,0-5.837c0.142-6.121,0.142-12.243,0.427-18.507
	C194.177,286.882,193.181,285.031,191.188,284.747z M254.68,329.59c-0.427,1.708-0.285,3.559-0.285,5.267
	c-0.142,3.417,0,6.976-0.142,10.392c-0.142,2.562-1.708,4.271-4.271,4.413c-4.128,0.427-8.399,0.569-12.528,0.569
	c-4.84,0-5.694-0.854-5.694-5.694c-0.142-7.687,0-15.375,0-23.062l0,0c0-7.687-0.142-15.375,0-23.062
	c0.142-5.125,0.569-5.41,5.552-5.267c2.278,0.142,3.844-0.569,4.128-3.132c0.142-2.42-1.566-4.413-3.986-4.413
	c-1.566,0-3.274,0.285-4.84,0.427c-2.99,0.142-5.837,0.285-8.826,0.142c-1.851,0-3.701-0.569-5.552-0.569
	c-2.99-0.142-4.413,1.281-4.413,4.128c0.142,2.562,1.281,3.559,4.271,3.417c4.413-0.142,5.267,0.569,5.267,5.125
	c0.142,8.114,0.427,16.229,0.427,24.486c0,7.687-0.142,15.375-0.427,23.062c-0.142,3.986-1.139,4.555-4.983,3.844
	c-2.705-0.569-4.271,0.427-4.555,2.705c-0.427,2.847,1.139,4.698,3.986,4.698c3.559-0.142,7.118-0.569,10.677-0.427
	c9.253,0.142,18.364,0.427,27.618,0.427c4.271,0,5.267-1.139,5.125-5.267c-0.142-4.84,0-9.823-0.142-14.805
	c0-2.278,0.285-4.698-0.142-6.976c-0.285-1.139-1.708-2.42-2.847-2.847C256.673,326.743,255.107,327.881,254.68,329.59z
	 M351.199,420.699c2.562-0.142,2.705-1.851,2.705-3.701c0-5.694,0-11.389,0-17.225c0-3.844-0.854-4.555-4.84-4.698
	c-10.535-0.285-21.069,1.424-31.604-0.569c-2.562-0.569-4.271,1.281-4.271,3.701c0,2.278,1.424,3.417,4.271,2.99
	s3.844,0.712,3.844,3.274c0,7.118,0,14.094,0,21.211h0.142c0,6.691,0.142,13.239-0.142,19.93c-0.142,4.413-0.569,4.271-4.84,4.271
	c-0.996,0-2.42,0.569-2.847,1.281c-0.427,0.997-0.427,2.562,0.142,3.559c1.139,2.278,3.417,1.993,5.41,1.708
	c4.413-0.712,8.684-0.997,13.097,0c0.854,0.142,1.851,0.142,2.705,0.142c1.851,0,2.705-1.139,3.132-2.847
	c0.569-2.99-0.996-4.271-4.84-3.844c-3.274,0.427-4.555-0.427-4.698-3.701c-0.142-5.267-0.142-10.535,0-15.802
	c0-0.712,0.569-2.135,0.997-2.135c1.993-0.142,4.128-0.427,5.979,0.142c0.854,0.285,1.424,2.42,1.708,3.844
	c0.285,1.708-0.285,3.701,0.285,5.41c0.285,1.139,1.566,2.42,2.705,2.705c1.708,0.427,2.42-1.139,2.562-2.705
	c0-1.139,0-2.278,0-3.559c-0.142-3.986-0.285-7.83-0.285-11.816c0-3.132,0.285-6.264,0.285-9.396c0-1.708-0.285-3.274-2.562-3.274
	c-2.278,0-2.847,1.566-2.847,3.417c0,1.566,0.142,2.99,0.142,4.556c-0.142,2.847-1.851,3.986-5.837,3.986
	c-2.705,0-3.274-1.566-3.274-3.844c0.142-3.986,0-8.114,0-12.1c0-2.278,1.139-3.701,3.274-3.844
	c3.559-0.285,7.118-0.427,10.677-0.142c3.986,0.285,4.983,1.566,5.125,5.694c0.142,3.274-0.142,6.406-0.142,9.68
	C347.64,419.56,348.921,420.842,351.199,420.699z M259.662,436.359c0.854,5.694,2.847,10.962,6.691,15.375
	c2.99,3.417,6.548,5.41,11.104,5.694c8.114,0.427,13.097-3.844,16.514-10.535c3.417-6.548,4.271-13.809,4.128-21.069
	c0-1.139,0-2.278,0-3.559c-0.427-8.542-1.993-16.798-7.972-23.347c-6.406-6.976-17.368-6.976-23.489,0.427
	c-2.278,2.847-4.271,6.264-5.41,9.823C258.096,417.994,258.239,427.248,259.662,436.359z M265.926,422.835
	c0.142-5.694,0.854-11.246,3.559-16.371c1.566-2.847,3.701-4.983,7.118-5.694c4.413-0.854,8.114,0.997,10.677,5.552
	c3.417,6.121,3.701,12.812,4.128,22.208c-0.569,2.847-1.566,8.257-2.705,13.666c-0.142,0.997-0.712,1.993-1.281,2.99
	c-1.993,3.559-4.84,5.837-8.969,5.837c-4.271,0-6.976-2.562-8.826-6.121C265.926,437.925,265.641,430.38,265.926,422.835z
	 M449.142,230.223c-0.997,1.424-0.142,2.847,1.424,3.701c4.128,2.278,8.257,4.555,12.528,6.549c0.997,0.427,2.99,0,3.701-0.712
	c1.281-1.281,0-2.42-1.281-3.132c-0.427-0.285-0.712-0.427-1.139-0.569c-1.993-0.712-2.278-2.135-1.424-3.844
	c4.128-7.972,8.399-15.944,12.67-24.059c0.142-0.285,0.569-0.569,0.854-0.854c0.142,0.427,0.427,0.997,0.427,1.424
	c-0.142,2.99-0.427,5.837-0.712,8.826c-0.997,8.114-1.993,16.371-2.99,24.486c-0.427,3.559,0.997,5.837,3.701,6.691
	c2.99,0.854,4.128-1.424,5.41-3.274c5.41-8.257,10.677-16.514,16.086-24.77c2.135-3.132,2.705-2.99,5.979-1.281
	c0.997,0.569,2.562,0,3.844-0.142c-0.427-1.281-0.427-2.99-1.281-3.701c-4.271-2.99-8.826-5.694-13.382-8.542
	c-1.424-0.854-2.99-1.281-4.128,0.285c-1.139,1.708,0.285,2.847,1.566,3.701c3.417,2.135,3.701,3.417,1.566,6.833
	c-4.128,6.691-8.257,13.382-12.385,20.073c-0.285,0.569-0.712,0.854-1.139,1.424c-0.142-0.569-0.285-1.281-0.285-1.993
	c0-0.569,0.142-1.281,0.285-1.851c1.281-10.25,2.562-20.357,3.844-31.604c-0.427-0.854-0.854-2.99-2.135-3.844
	c-3.274-2.278-6.833-3.986-10.25-5.837c-1.566-0.854-3.274-1.139-4.271,0.569c-0.997,1.993,0.712,2.847,2.135,3.559
	c3.559,1.708,3.844,2.278,2.135,5.694c-3.986,7.972-8.114,15.802-12.243,23.632c-1.566,2.847-2.705,4.128-5.837,1.851
	C451.704,229.369,449.569,229.654,449.142,230.223z M108.335,222.821c3.132-2.278,3.701-2.42,5.979,0.854
	c4.698,6.833,9.253,13.666,13.809,20.5c0.997,1.566,2.135,3.417,2.135,4.983c0,1.139-2.135,2.135-2.99,3.274
	c-0.569,0.712-0.997,1.993-0.569,2.562c0.427,0.712,1.708,1.281,2.562,1.281s1.708-0.854,2.562-1.281
	c3.274-2.135,6.548-4.271,9.823-6.406c0.569-0.427,1.566-0.569,1.708-1.139c0.427-1.139,0.569-2.42,0.712-3.559
	c-1.139-0.142-2.42-0.569-3.274-0.142c-3.986,1.993-3.986,2.135-6.548-1.566c-0.427-0.569-0.854-1.139-1.139-1.851
	c-4.983-7.972-6.691-6.121,3.417-11.958c4.128-2.42,5.979-1.566,8.542,2.562c1.708,2.705,3.417,5.267,5.267,7.687
	c1.281,1.708,3.132,2.135,4.983,1.424c1.708-0.712,3.274-1.424,4.84-2.278s2.705-1.993,1.708-3.844
	c-1.139-1.851-2.562-1.139-3.986-0.142c-1.993,1.566-3.417,0.712-4.698-1.139c-1.424-2.135-2.705-4.271-4.128-6.264
	c-1.281-1.993-2.705-3.986-5.41-3.844c-2.42,0.142-2.278-0.569-1.139-2.278c2.42-3.417,2.135-7.118,0.142-10.677
	c-1.993-3.417-4.84-5.41-10.25-5.41c-1.281,0.427-3.986,0.854-6.121,2.135c-6.976,4.128-13.951,8.542-20.784,12.812
	c-1.566,0.997-2.847,2.278-1.281,4.128C105.63,225.241,107.054,223.817,108.335,222.821z M120.151,215.418
	c2.705-1.851,5.41-3.844,8.399-5.125c4.983-1.993,9.396,1.566,9.68,6.976c-0.569,1.424-0.712,3.274-1.708,3.986
	c-3.132,2.562-6.548,4.698-9.823,6.833c-0.427,0.285-1.708-0.285-2.135-0.854c-1.851-2.562-3.417-5.267-5.125-7.83
	C118.442,217.98,118.727,216.415,120.151,215.418z M509.644,272.789c2.135,1.281,4.84,1.566,7.26,2.562
	c0.427,0.142,1.139,1.139,0.997,1.281c-1.424,1.851-0.854,3.274,0.854,4.271c1.851,1.139,2.847-0.142,3.986-1.424
	c2.42-2.99,4.983-5.979,7.403-8.826c1.566-1.851,3.417-2.278,5.125-0.142c1.139,1.281,2.562,2.42,4.128,0.712
	c1.424-1.566,0.427-2.99-0.854-4.128c-4.271-3.559-8.399-7.118-12.67-10.819c-0.142-0.142-0.427-0.427-0.712-0.427
	c-0.997,0-2.135,0.142-3.132,0.142c0,1.139-0.142,2.42,0.285,3.417c0.427,0.854,1.566,1.424,2.42,1.993
	c2.278,1.708,2.42,2.847,0.712,5.125c-2.705,3.559-6.691,4.983-10.677,3.701c-4.413-1.424-7.545-5.41-7.687-9.965
	c-0.142-8.826,7.403-19.503,15.802-22.066c5.125-1.566,10.677,0.712,14.236,5.694c2.42,3.559,1.993,6.406-1.708,11.389
	c-0.142,0.142-0.142,0.285-0.285,0.427c-1.139,1.424-0.712,2.705,0.427,3.701c1.281,1.139,2.705,1.566,3.986,0.142
	c2.99-3.274,6.121-6.548,8.969-9.965c0.569-0.569,0.427-2.42-0.142-2.99c-0.712-0.854-2.705-1.566-3.274-1.281
	c-2.135,1.424-2.278,0.142-2.847-1.281c-3.559-7.403-9.253-11.531-18.934-12.385c-1.993,0.712-5.552,1.424-8.257,3.132
	c-6.833,4.271-11.246,10.535-13.239,18.364C499.679,261.258,502.526,268.518,509.644,272.789z M82.568,281.472
	c7.972,3.417,16.371,1.424,21.496-5.267c1.424-1.851,1.993-4.128,3.132-6.264c0.142-0.427,1.281-0.712,1.424-0.569
	c1.424,1.566,2.847,1.424,4.128,0c1.424-1.566,0.712-2.847-0.427-4.271c-2.562-2.847-5.125-5.837-7.545-8.826
	c-1.566-1.993-1.851-3.844,0.854-5.41c1.424-0.854,2.705-2.135,1.139-3.986c-1.566-1.708-2.847-0.712-4.128,0.427
	c-4.128,3.417-8.257,6.976-12.243,10.392c-0.285,0.285-0.854,0.569-0.997,0.996c-0.142,0.997-0.569,2.42-0.142,2.847
	c0.712,0.569,2.135,0.854,2.99,0.569c0.997-0.285,1.708-1.424,2.562-2.278c1.993-1.851,3.274-1.851,5.267,0.142
	c3.132,3.274,3.844,7.403,1.851,11.104c-1.993,3.986-6.691,6.549-10.819,5.979c-8.826-1.281-17.51-9.396-19.361-18.08
	c-1.424-6.406,3.274-13.524,9.68-14.948c2.562-0.569,4.983,0,6.976,1.851c0.854,0.854,1.566,1.708,2.278,2.562
	c1.281,1.424,2.562,1.281,3.986,0.142s1.851-2.42,0.569-3.986c-2.705-3.417-5.267-6.833-8.114-10.107
	c-0.569-0.712-2.42-1.139-3.132-0.712c-0.854,0.569-1.993,2.42-1.708,3.132c1.139,2.278-0.427,2.278-1.851,2.562
	c-9.111,2.278-15.09,9.823-15.659,18.934c0.854,2.847,1.139,5.837,2.42,8.399C70.752,273.643,75.735,278.625,82.568,281.472z
	 M216.955,172.853c0.285-0.142,0.712-0.285,0.997-0.427c1.993-0.854,3.274,0,3.701,1.851c2.562,9.68,4.983,19.218,7.403,28.899
	c0.427,1.993-0.285,3.274-2.562,3.417c-1.708,0.142-3.417,0.569-2.99,2.847c0.569,2.135,2.278,1.993,4.128,1.566
	c4.983-1.281,9.823-2.705,14.805-3.417c11.816-1.993,18.08-9.823,17.225-21.496c-0.569-2.99-0.854-5.979-1.708-8.969
	c-2.562-9.823-9.538-14.948-19.503-13.524c-7.83,1.139-15.375,2.99-23.062,4.698c-1.566,0.427-3.274,1.281-2.562,3.274
	C213.538,173.849,215.389,173.422,216.955,172.853z M231.618,169.436c1.708-0.427,3.274-0.854,4.983-1.139
	c8.114-0.997,13.097,1.993,15.517,9.823c0.854,2.99,1.281,6.121,1.708,8.114c-0.427,6.833-1.281,12.528-7.403,15.232
	c-2.562,1.139-5.552,1.566-8.257,1.708c-0.997,0.142-2.705-1.281-2.99-2.278c-2.278-8.969-4.413-17.937-6.406-26.906
	C227.916,171.714,229.055,170.148,231.618,169.436z M272.332,162.745c4.128,0.285,4.555,0.712,4.983,4.983
	c0.569,7.26,1.281,14.378,2.135,21.639c0.996,8.257,4.128,11.531,12.385,12.1c7.83,0.569,17.652-1.993,17.652-14.236
	c0-7.26,0.285-14.521,0.427-21.781c0.142-3.986,0.142-3.986,4.128-4.555c1.566-0.142,2.562-0.712,2.562-2.562
	c-0.142-1.708-1.139-2.278-2.705-2.278c-5.125,0-10.107,0-15.232,0c-0.569,0-1.281,0-1.566,0.285
	c-0.712,0.712-1.708,1.708-1.708,2.42c0.142,0.712,1.281,1.424,2.135,1.851c0.285,0.285,0.854,0.142,1.281,0.142
	c2.99-0.427,4.271,1.139,4.413,3.986c0.142,4.128,0.427,8.257,0.569,12.528c-0.142,4.128-0.142,8.399-0.712,12.67
	c-0.569,4.271-3.844,6.833-8.542,6.976c-4.555,0.142-8.399-2.278-8.826-6.548c-0.996-8.257-1.708-16.514-2.135-24.77
	c-0.142-3.274,0.569-3.844,4.128-4.271c1.566-0.142,3.417-0.427,3.274-2.42c-0.142-2.278-1.993-2.42-3.844-2.42
	c-0.427,0-0.712,0.142-1.139,0.142c-3.986,0.285-7.972,0.712-11.958,0.997c-0.854,0.142-1.851-0.142-2.278,0.285
	c-0.854,0.712-1.851,1.851-1.851,2.705C269.77,161.464,271.336,162.603,272.332,162.745z M175.813,229.939
	c4.413-1.851,8.826-3.701,13.097-5.837c0.854-0.427,0.997-1.993,1.424-2.99c-0.997-0.427-2.135-1.424-3.132-1.281
	c-3.986,0.712-3.986,0.712-4.413-3.274c-0.142-1.281-0.285-2.562-0.427-3.701c-0.285-2.135,0.569-3.417,2.705-4.128
	c2.562-0.854,4.983-1.993,7.545-2.847c0.854-0.285,2.278,0,2.705,0.569c1.566,1.566,2.705,3.559,3.986,5.41
	c0.997,1.566,0.854,2.847-1.139,3.559c-1.566,0.569-3.274,1.281-2.278,3.417c0.997,1.851,2.705,1.424,4.271,0.854
	c3.986-1.424,7.972-2.847,12.1-4.128c1.566-0.569,2.562-1.424,2.135-3.132c-0.427-1.851-1.851-2.135-3.417-1.424
	c-2.847,1.424-4.555,0.142-6.121-1.993c-3.274-4.413-6.406-8.826-9.823-13.097c-3.844-4.983-7.83-9.823-11.816-14.663
	c-1.139-1.424-2.562-2.278-4.698-1.566c-2.99,1.139-3.844,2.99-3.559,7.403c0.712,10.819,1.424,21.781,1.993,32.6
	c0.142,2.705-0.285,4.983-3.274,5.979c-0.854,0.285-1.708,2.135-1.566,2.99C172.681,230.65,174.389,230.508,175.813,229.939z
	 M181.507,187.8c0.569,0.569,0.854,0.854,1.139,1.281c2.562,3.417,5.125,6.833,7.687,10.25c1.139,1.424,0.712,2.278-0.854,2.705
	c-1.851,0.712-3.701,1.424-5.552,2.135c-1.424,0.569-2.278,0.142-2.42-1.424c-0.285-4.413-0.427-8.969-0.569-13.382
	C181.08,189.082,181.365,188.512,181.507,187.8z M324.293,200.755c0.712,0.427,1.851,0.142,2.847,0.142
	c2.847,0.142,5.694,0.285,8.542,0.427c1.281,0.142,2.705,0.569,3.986,0.427c0.712-0.142,1.708-1.139,1.851-1.851
	s-0.569-1.708-1.139-2.278c-0.427-0.427-1.139-0.427-1.851-0.427c-1.993,0-2.562-1.139-1.993-2.847
	c0.569-1.566,1.281-3.132,1.851-4.84c0.712-2.42,2.278-3.559,4.84-3.132c1.281,0.142,2.42,0.285,3.701,0.569
	c6.406,0.997,6.406,1.139,6.833,7.687c0.142,3.417-0.142,3.701-3.559,3.844c-0.854,0-2.278,0.997-2.42,1.708
	c-0.142,0.854,0.854,1.851,1.566,2.705c0.285,0.285,0.997,0.285,1.566,0.285c4.128,0.569,8.257,1.281,12.385,1.993
	c1.708,0.285,2.99,0.142,3.417-1.566c0.569-1.993-0.712-2.99-2.42-2.99c-3.132,0-3.844-1.993-4.271-4.555
	c-0.854-4.983-1.424-10.107-2.278-15.09c-1.139-6.691-2.42-13.239-3.701-19.788c-0.427-2.42-2.135-3.274-4.413-3.417
	c-2.99-0.142-3.986,2.278-4.84,4.271c-4.555,9.965-9.111,20.073-13.666,30.18c-1.139,2.562-2.42,5.125-6.264,4.128
	c-0.569-0.142-2.278,1.139-2.278,1.851C322.442,199.047,323.439,200.186,324.293,200.755z M342.515,179.544
	c1.851-3.986,3.559-7.972,5.41-11.958c0.142-0.427,0.712-0.712,0.996-1.139c0.285,0.427,0.569,0.997,0.712,1.424
	c0.712,4.271,1.424,8.684,1.851,12.955c0.142,0.712-0.712,1.424-0.997,1.993c-2.847-0.285-4.698-0.569-6.691-0.712
	C342.088,181.964,341.946,180.967,342.515,179.544z M382.518,182.676c2.278,0.712,3.274-0.712,3.701-2.705
	c0.569-2.278,0.854-4.698,1.708-6.691c0.427-1.139,1.851-2.42,2.847-2.562c1.424-0.142,3.274,0.427,4.698,1.139
	c0.854,0.427,1.424,1.566,1.851,2.135c-0.142,1.139-0.142,1.708-0.285,2.278c-2.278,8.826-4.555,17.652-6.976,26.479
	c-0.569,2.42-1.851,3.559-4.556,2.42c-1.424-0.569-3.274-0.854-3.701,1.281c-0.569,2.135,0.997,2.99,2.847,3.417
	c4.128,0.997,8.257,2.135,12.385,3.274c1.851,0.569,3.559,0.569,4.128-1.424c0.712-1.993-0.997-2.705-2.562-2.99
	c-2.847-0.427-3.274-1.993-2.705-4.413c2.42-8.969,4.84-17.937,7.403-26.906c0.854-2.847,2.135-3.132,5.837-1.993
	c2.847,0.854,3.701,2.135,2.847,4.698c-0.569,2.135-1.566,4.128-2.278,6.264c-0.712,1.993-0.285,3.559,1.993,4.271
	c2.135,0.569,3.132-0.427,3.844-2.42c1.139-3.701,2.278-7.545,3.701-11.246c0.854-2.278,0-3.417-1.993-3.986
	c-2.135-0.569-4.128-0.997-6.264-1.566c-7.687-2.135-15.375-4.128-23.062-6.264c-3.417-0.854-4.271-0.285-4.84,3.274
	c-0.569,3.417-1.424,6.833-2.135,10.25C380.382,180.54,380.24,182.106,382.518,182.676z M420.955,220.97
	c0.142,0.142,0.285,0.142,0.569,0.142c4.128,1.708,8.257,3.274,12.385,4.983c1.566,0.712,3.132,0.854,3.986-0.854
	c0.854-1.851-0.427-2.847-1.993-3.417c-3.274-1.424-3.417-1.708-2.135-4.983c3.417-8.399,6.833-16.798,10.392-25.055
	c1.566-3.559,2.135-3.701,5.694-2.135c0.427,0.285,0.997,0.569,1.424,0.569c0.997-0.285,2.278-0.427,2.562-1.139
	c0.285-0.712-0.142-1.993-0.712-2.705c-0.427-0.569-1.281-0.854-2.135-1.139c-4.413-1.851-8.969-3.559-13.382-5.41
	c-0.854-0.285-1.708-0.569-2.99-0.997c-0.569,0.569-1.708,1.139-1.851,1.851c-0.142,0.854,0.712,1.851,1.281,2.562
	c0.285,0.427,0.997,0.427,1.424,0.569c3.417,1.281,3.844,1.708,2.562,4.983c-3.274,8.542-6.549,16.941-10.107,25.482
	c-1.424,3.274-1.708,3.274-5.267,1.993c-1.424-0.569-2.847-0.854-3.559,0.997C418.25,219.262,419.389,220.258,420.955,220.97z
	 M454.267,422.977c-12.955,0-26.052,0-39.006,0c-12.955,0-25.767,0-38.722,0c-1.424,0-2.705,0-2.705,1.993
	c0,1.851,0.854,2.278,2.42,2.278c26.052,0,52.103,0.142,78.155,0.142c0.712,0,1.708-0.569,1.993-1.139
	C457.826,424.258,456.971,422.977,454.267,422.977z M238.451,425.255c0-1.993-0.997-2.278-2.705-2.278c-2.42,0.142-4.84,0-7.26,0
	c-23.347,0-46.551,0-69.898,0c-1.566,0-2.847-0.142-2.847,2.135c0,2.135,1.139,2.135,2.705,2.135c12.812,0,25.625,0,38.437,0l0,0
	c12.955,0,26.052,0,39.006,0C237.454,427.248,238.451,427.248,238.451,425.255z M538.828,324.749c0-0.142,0.142-0.142,0.285-0.142
	h-0.142C538.828,324.607,538.828,324.749,538.828,324.749z M548.793,580.995L548.793,580.995L548.793,580.995L548.793,580.995
	L548.793,580.995L548.793,580.995z M501.957,560.068c4.983-5.552,9.396-11.816,13.382-18.08
	c6.406-10.107,12.528-20.357,17.083-31.461c5.267-12.955,10.392-25.909,13.097-39.576c2.278-11.389,4.271-22.777,5.694-34.308
	c1.139-9.538,1.139-19.076,1.851-30.607c-0.854-11.673-1.708-25.34-2.705-38.864c0-0.569-0.569-1.281-0.712-1.851
	c-0.142,0.712-0.427,1.281-0.427,1.993c-0.427,11.673-0.854,23.204-1.424,34.878c-0.142,4.271-0.569,8.542-1.139,12.812
	c-0.285,2.705-1.139,5.267-1.424,7.83c-0.427,4.84-0.285,9.68-1.139,14.521c-1.851,10.392-3.559,20.784-6.406,30.892
	c-3.274,11.958-7.118,23.916-11.816,35.305c-4.271,10.535-9.68,20.642-15.375,30.607c-4.698,8.257-10.107,15.944-15.517,23.632
	c-2.99,4.271-6.833,8.114-10.392,12.1c-1.424,1.708-3.274,2.99-4.698,4.84c-4.84,6.121-10.535,11.246-16.941,15.517
	c-1.281,0.854-2.135,2.278-3.132,3.417c1.281-0.427,2.562-0.854,3.844-1.281c0.285-0.142,0.569-0.142,0.712-0.285
	c6.121-4.413,12.528-8.542,18.222-13.382C489.429,572.738,495.978,566.617,501.957,560.068z M149.334,590.106
	c-4.84-4.271-10.107-8.114-14.663-12.67c-3.844-3.701-7.403-7.972-10.819-12.243c-4.983-6.121-9.965-12.243-14.521-18.649
	c-2.99-4.128-5.41-8.826-7.83-13.382c-3.844-6.976-7.687-13.809-11.104-20.927c-6.121-12.243-10.107-25.34-13.951-38.437
	c-2.562-8.826-4.698-17.795-6.264-26.763c-2.135-12.243-3.844-24.486-4.983-36.871c-1.139-12.528-1.139-25.055-1.708-37.583
	c-0.142-2.278,0-4.413-0.142-6.691c0-0.427-0.427-0.854-0.569-1.424c-0.285,0.427-0.569,0.854-0.569,1.424
	c-0.569,6.548-1.281,13.239-1.566,19.788c-0.712,17.225-1.424,34.593,0.997,51.819c1.424,10.25,2.847,20.5,5.125,30.607
	c1.281,5.694,1.566,11.816,4.413,17.225c1.424,2.705,1.851,5.979,2.847,8.826c2.562,6.833,5.267,13.666,7.83,20.5l0,0
	c1.851,3.417,3.559,6.833,5.41,10.107c6.121,11.531,13.097,22.635,21.781,32.6c4.271,4.84,8.542,9.823,13.239,14.236
	c4.413,4.271,9.396,8.114,14.236,11.958c2.99,2.42,6.264,4.271,9.538,6.406c1.851,1.139,3.701,2.278,5.552,3.417
	c0.285,0.142,0.569,0,0.997,0c0-0.285,0-0.569-0.142-0.712C151.185,591.957,150.331,590.818,149.334,590.106z M564.737,516.364
	c0.997-1.139,1.708-2.99,1.566-4.413c-0.142-0.712-2.278-1.424-3.701-1.708c-2.135-0.427-4.413-0.712-6.691-0.712
	c-6.976-0.285-13.951-0.712-19.788,4.128c-3.844,3.132-4.555,7.403-4.983,11.958c0,0.285,0.142,0.427,0.285,0.712
	c2.705,4.413,6.549,6.549,13.524,6.406c2.562-0.427,6.548-1.566,9.68-4.84C558.046,523.909,561.605,520.208,564.737,516.364z
	 M549.22,540.992c-5.267-0.854-10.535-1.566-15.802-2.42c-7.403,0.142-8.257,0.427-12.1,2.705c-3.274,1.851-4.271,5.125-5.41,8.257
	s0.569,5.125,2.42,7.403c3.274,3.844,9.823,5.552,14.378,3.417c7.26-3.417,13.666-7.83,18.222-14.805
	c0.569-0.854,1.566-2.278,1.281-2.562C551.64,541.989,550.359,541.135,549.22,540.992z M80.433,520.635
	c-0.142-0.712-0.285-1.424-0.285-2.135c-0.569-3.986-3.559-6.406-6.833-7.403c-4.271-1.424-8.826-1.708-13.524-2.42
	c-3.986,0.712-7.83,1.424-11.816,1.993c-2.562,0.285-2.42,1.708-1.424,3.132c2.847,3.986,5.694,7.972,8.969,11.673
	c3.701,4.128,8.257,7.26,14.236,7.26C76.874,532.736,81.429,527.753,80.433,520.635z M90.398,540.565
	c-3.844-1.993-7.687-0.854-12.385-1.993c-4.271,0.712-9.396,1.424-14.521,2.42c-0.997,0.142-1.851,0.712-2.562,1.281
	c-0.427,0.285-0.712,1.281-0.427,1.566c0.854,1.424,1.708,2.99,2.847,4.128c3.986,4.128,7.972,8.257,13.239,10.677
	c4.983,2.278,9.965,3.844,15.375,0.427C99.366,554.516,96.662,543.839,90.398,540.565z M571.001,491.309
	c2.278-3.417,3.701-7.403,5.267-11.246c0.569-1.424-0.285-2.135-1.708-2.135c-2.278,0-4.698,0-6.976,0
	c-5.41-0.285-10.392,0.569-15.375,2.705c-7.403,3.132-8.969,8.542-8.399,15.517c0.142,1.281,1.424,2.562,2.42,3.559
	c1.139,0.997,2.562,1.424,3.986,2.135c2.847,1.424,10.25,0.712,12.528-1.424C565.591,497.431,568.723,494.726,571.001,491.309z
	 M48.544,498.854c4.84,3.986,9.965,4.983,15.659,1.993c1.708-0.854,3.701-2.42,4.128-3.986c1.424-5.125,0.854-11.389-5.267-14.663
	c-5.837-3.132-11.958-4.84-18.507-4.271c-2.278,0-4.555,0-6.833,0s-2.847,0.997-1.851,3.132
	C39.149,487.75,42.708,494.014,48.544,498.854z M50.11,471.094c0.997,0,2.135,0.285,2.99,0c2.278-0.854,5.552-1.424,6.406-3.132
	c2.562-5.694,1.708-13.666-4.983-17.51c-6.833-3.986-14.663-4.271-22.066-5.694c-2.562-0.569-3.274,0.854-2.847,3.417
	c0.854,5.125,3.844,9.253,6.264,13.666C38.864,467.535,43.419,470.952,50.11,471.094z M579.969,445.043
	c-2.847,0.285-5.837,0.854-8.684,1.281c-6.549,0.712-12.67,2.562-17.368,7.545c-1.424,1.566-3.132,10.677-2.135,12.385
	c0.142,0.285,0.427,0.569,0.712,0.854c2.705,3.559,6.549,4.271,11.104,4.271c4.413,0.854,7.545-2.278,9.823-5.41
	c3.701-5.125,6.691-10.962,9.68-16.514C584.382,446.466,583.101,444.758,579.969,445.043z M49.398,439.348
	c1.851-0.427,4.271-1.139,5.125-2.42c3.559-5.41,0.854-14.948-4.84-17.937c-2.42-1.281-4.84-2.562-7.403-3.417
	c-3.986-1.281-7.972-2.278-11.958-3.417c0,0.142-0.142,0.427-0.142,0.569c-0.854,0-1.708-0.285-2.42-0.142
	c-0.569,0.142-1.281,0.712-1.281,1.139c0.142,1.993,0,4.271,0.854,5.979c1.993,4.128,2.99,8.542,5.694,12.385
	C37.156,438.352,42.992,441.057,49.398,439.348z M583.813,379.273c-3.844,1.281-7.687,2.42-11.246,3.986
	c-3.559,1.424-7.26,2.99-10.107,5.41c-3.132,2.562-4.84,6.406-3.986,10.392c0,1.566-0.142,2.705,0,3.844
	c0.142,1.851,0.854,2.99,2.847,3.986c3.986,1.993,7.83,1.281,11.816,0.997c0.569,0,1.424,0,1.851-0.427
	c2.42-2.705,5.125-4.983,6.833-8.542c2.562-5.694,4.128-11.531,5.125-17.652c0.142-0.569-0.142-1.566-0.569-1.851
	C585.521,379.131,584.525,378.988,583.813,379.273z M583.671,412.727c-7.118,0-13.666,2.562-19.93,5.552
	c-2.847,1.424-6.264,3.559-6.691,7.26c-0.427,3.559,0.142,7.26,0.427,10.819c0,0.569,0.569,1.281,0.997,1.424
	c2.562,0.854,5.267,1.566,7.26,2.278c4.555-0.427,8.114-1.281,10.962-4.555c4.983-5.837,8.257-12.385,9.396-20.073
	C586.518,413.154,585.806,412.727,583.671,412.727z M33.027,369.592c2.99,4.698,6.406,7.118,11.531,7.403
	c6.976,0.427,10.819-3.132,10.819-9.538c0-3.844-1.566-6.833-4.271-9.68c-5.694-5.979-13.524-7.687-20.642-10.535
	c-2.135-0.854-3.559,0.285-3.132,2.135C28.899,356.068,28.899,363.186,33.027,369.592z M36.159,406.036
	c2.99,2.705,12.385,3.132,15.375,0.569c0.854-0.712,1.708-1.993,1.993-2.99c0.427-1.424,0.569-3.132,0.712-4.698
	c0-5.694-2.705-9.253-7.403-12.1c-5.979-3.559-12.243-5.837-19.076-6.976c-2.562-0.427-2.847,0.854-2.42,2.847
	c0.427,2.278,0.854,4.556,1.566,6.833C29.184,395.502,31.177,401.481,36.159,406.036z M557.761,370.589
	c0.427,2.705,1.708,4.698,4.698,5.41c4.983,1.281,12.243,1.566,15.375-4.413c1.993-3.844,3.844-7.83,5.125-11.958
	c0.997-2.99,1.281-6.264,1.993-9.396c0.569-2.42,0.142-3.274-3.132-3.417c-0.712,0.285-1.851,0.854-3.132,1.281
	c-5.694,2.278-11.246,4.555-16.087,8.542C557.904,360.766,556.765,365.179,557.761,370.589z M58.082,350.943
	c3.844,2.847,8.114,3.986,12.1,1.281c1.566-1.139,3.417-2.278,4.128-3.844c0.997-2.278,1.139-4.983,1.708-7.545
	c0.285-7.26-2.847-13.382-5.837-19.645c-1.139-2.278-2.705-2.278-4.413-0.285c-2.847,3.559-5.267,7.26-8.257,10.677
	c-2.705,2.99-2.847,6.548-2.99,9.965C54.666,344.964,54.666,348.381,58.082,350.943z M545.518,320.194
	c-0.427-0.427-2.135-0.427-2.562,0c-1.139,1.281-2.135,2.705-2.705,4.271c-1.281,3.844-2.135,7.83-3.417,11.816
	c-1.708,5.552,0.142,12.528,4.84,15.517c2.42,1.424,5.267,3.132,8.542,1.851c5.267-2.278,7.687-6.264,7.687-11.958
	c-0.285-1.708-0.142-3.701-0.854-5.267C554.487,330.159,550.359,324.892,545.518,320.194z M72.888,373.151
	c2.42,0.854,4.84,1.708,7.26,2.562c0-0.142,0.142-0.427,0.285-0.712c7.545,1.281,13.097-4.983,15.659-10.107
	c1.566-3.132,2.99-6.406,4.555-9.538c1.281-2.562,0.569-4.128-2.278-3.844c-3.986,0.285-7.972,0.996-11.816,1.424
	c-5.694,0.712-10.535,2.847-14.094,7.545c-1.139,1.566-1.566,10.107-0.427,11.816C72.033,372.724,72.461,373.009,72.888,373.151z
	 M521.745,371.87c1.566,1.281,3.274,2.99,5.125,3.274c2.847,0.427,5.837-0.142,8.684-0.142c2.847-0.142,4.698-1.566,5.267-4.413
	c0.285-1.424,0.142-2.847,0.285-4.271h0.142c-0.285-1.993,0-4.698-1.139-5.837c-3.559-3.844-8.257-6.264-13.382-7.26
	c-4.413-0.854-9.111-0.997-13.524-1.708c-2.135-0.427-1.851,0.569-1.566,1.708C513.345,360.339,516.193,366.888,521.745,371.87z
	 M79.151,431.946c0.285,0,0.569,0,0.854,0c6.264,0,7.545-0.142,11.389-4.413c4.84-5.267,6.833-11.958,7.403-19.076
	c0.285-2.99-0.854-3.701-3.701-2.705c-5.267,1.851-10.535,3.844-15.659,5.837c-5.979,2.42-8.399,7.687-8.114,12.243
	C71.464,429.241,74.027,431.803,79.151,431.946z M69.756,394.505c0.854,8.399,1.566,8.826,10.677,9.111
	c5.125,0.712,8.969-2.42,11.958-6.833c1.993-3.132,3.417-6.833,4.983-10.25c0.712-1.851,1.139-3.844,1.566-5.837
	c0.285-1.851-0.712-2.705-2.562-2.278c-2.135,0.427-4.271,1.281-6.406,1.566c-6.549,0.712-12.1,3.417-17.083,7.545
	C70.468,389.238,69.613,391.943,69.756,394.505z M536.977,385.11c-6.406-3.701-13.382-5.694-20.642-6.691
	c-2.562-0.285-3.559,0.997-2.705,3.417c1.281,3.986,2.562,7.83,4.413,11.531c2.99,5.979,6.833,10.962,14.236,10.107
	c8.684-0.285,8.684-0.285,9.68-6.264C542.956,392.085,541.675,387.814,536.977,385.11z M78.297,459.563
	c4.128,0.854,8.257,1.851,12.243-0.427c4.128-2.42,7.118-5.837,8.114-10.677c0.854-4.556,1.851-9.111,2.705-13.666
	c0.285-1.566-0.285-3.132-2.278-2.562c-1.993,0.569-3.701,1.424-5.694,2.278c-3.274,1.281-6.406,2.42-9.68,3.701l0,0
	c-0.712,0.854-1.424,1.851-2.278,2.42c-7.687,4.84-7.118,10.819-4.128,17.795C77.443,458.851,77.87,459.421,78.297,459.563z
	 M539.966,428.529c2.42-7.403,0.285-13.666-7.118-16.798c-5.267-2.278-10.819-4.128-16.229-6.121
	c-1.993-0.712-3.559,0.285-3.274,2.135c1.424,8.826,3.417,17.368,11.104,23.204l0,0l0,0c3.559,0.285,7.26,0.569,10.819,0.854
	C537.973,432.088,539.255,430.522,539.966,428.529z M536.265,455.435c2.135-5.41-0.569-13.239-5.837-15.659
	c-5.552-2.562-11.104-4.983-16.798-7.403c-0.569-0.285-2.135,0.285-2.278,0.712c-0.427,1.851-0.854,3.986-0.427,5.837
	c0.854,3.701,2.278,7.26,3.417,10.819c1.566,5.267,8.399,11.389,14.948,10.392C533.133,461.271,534.842,458.994,536.265,455.435z
	 M530.713,480.205c-0.569-3.559-1.851-6.976-3.132-10.392c-0.427-0.997-1.566-1.993-2.562-2.705
	c-4.128-2.562-8.257-5.125-12.67-7.403c-1.566-0.854-3.559-0.997-5.41-0.854c-0.427,0-0.569,2.705-0.712,4.128
	c0,0.285,0,0.712,0.142,1.139c-0.142,0-0.142,0-0.285,0c0.427,3.274,1.139,6.549,1.281,9.823c0,3.417,2.135,5.694,3.701,8.257
	c0.854,1.281,2.278,2.562,3.701,3.132c2.562,0.997,5.267,1.424,7.972,1.993C526.158,488.035,531.425,483.622,530.713,480.205z
	 M97.089,513.517c3.986,0.997,8.114,0,11.104-2.42c3.132-2.705,5.552-6.549,5.694-11.104c0.142-2.42,0-4.84,0-7.26h0.142
	c0-2.562,0.142-4.983,0-7.545c-0.142-2.705-1.281-3.132-3.417-1.708c-2.562,1.566-4.84,3.559-7.545,4.84
	c-4.84,2.562-7.403,6.833-10.392,11.104c-1.993,2.847-0.997,5.267-0.285,7.83C92.96,509.958,94.099,512.805,97.089,513.517z
	 M89.544,487.181c8.399,0.285,12.385-2.99,14.521-10.535c1.281-4.698,1.708-9.68,2.42-14.378c0.142-1.139,0-3.132-0.569-3.417
	c-0.997-0.427-2.705-0.285-3.844,0.285c-4.555,2.42-9.111,4.84-13.382,7.687c-3.132,2.135-5.694,4.84-6.406,9.111
	C81.144,481.771,84.419,487.181,89.544,487.181z M113.745,538.288c1.851-0.142,3.559-1.424,5.41-2.42
	c3.274-1.566,5.41-4.128,6.121-7.83c0.427-2.562,0.569-5.125,0.997-8.114c-0.427-3.559-0.854-7.545-1.566-11.531
	c-0.427-2.562-1.424-2.99-3.559-1.566c-4.84,3.417-9.253,7.118-12.955,11.816c-2.278,2.847-2.99,5.979-3.559,9.253
	C103.779,533.447,108.335,538.572,113.745,538.288z M520.179,507.538c0.427-1.139,0.427-2.42,0.569-3.417
	c0-0.997,0.142-1.708,0-2.42c-1.566-5.41-5.552-9.111-9.538-12.528c-2.847-2.42-6.691-3.701-9.823-5.694
	c-1.993-1.281-2.705-0.427-2.847,1.424c-0.142,3.701-0.285,7.545-0.142,11.246c0.285,7.26,1.993,13.666,9.68,16.798
	C514.2,515.225,518.186,513.232,520.179,507.538z M491.992,506.969c-1.851-1.566-3.986-0.712-4.128,1.993
	c-0.285,6.264-0.569,12.67-0.285,18.934c0.285,5.125,7.118,10.677,12.812,10.819c0.285-0.142,1.139-0.285,1.993-0.712
	c5.267-3.132,7.118-8.399,4.413-14.663C503.665,516.08,497.401,511.951,491.992,506.969z M83.992,437.213c0,0-0.142,0-0.142,0.142
	c0,0.285-0.142,0.569-0.142,0.712l0,0C83.849,437.782,83.992,437.498,83.992,437.213z M524.449,430.949h0.142l0,0
	C524.449,430.949,524.449,430.949,524.449,430.949z"
      />
      <text
        transform="matrix(1.2388 0 0 1 193.4957 546.335)"
        font-family="'Typewriter-Serial-Bold'"
        font-size="93.4889"
      >
        2021
      </text>
    </svg>
  );
}

Artwrk3.props = {
  primary: string,
};

Artwrk3.defaultProps = {
  primary: "#000",
};
