import React from "react";
import { string } from "prop-types";

export default function Artwrk7(props) {
  const { primary, secondary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      fill={primary}
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="Shape_1_1_" enable-background="new    ">
        <g id="Shape_1">
          <path
            d="M611.989,423.249c0,0.362,0,0.735-0.044,1.096c-0.526,4.814-1.568,9.507-3.279,14.035
			c-2.434,6.448-5.548,12.555-9.189,18.411c-3.048,4.912-6.393,9.616-10.077,14.068c-3.597,4.342-7.412,8.476-11.546,12.292
			c-0.428,0.395-0.877,0.779-1.294,1.184c-3.915,3.86-8.224,7.248-12.566,10.592c-4.123,3.158-8.41,6.075-12.785,8.871
			c-4.309,2.763-8.772,5.263-13.279,7.676c-0.066,0.033-0.132,0.055-0.197,0.099c-6.294,3.586-12.939,6.448-19.54,9.408
			c-4.068,1.82-8.257,3.355-12.402,4.967c-8.18,3.158-16.557,5.735-24.968,8.169c-6.25,1.798-12.555,3.388-18.882,4.836
			c-3.191,0.735-6.393,1.447-9.627,2.018c-2.654,0.471-5.274,1.107-7.939,1.579c-3.015,0.537-6.042,0.998-9.079,1.469
			c-3.893,0.614-7.796,1.239-11.689,1.82c-2.171,0.318-4.342,0.603-6.513,0.844c-2.248,0.252-4.474,0.647-6.744,0.702
			c-1.349,0.329-2.741,0.296-4.112,0.406c-0.143,0.011-0.285,0.044-0.439,0.044c-0.8,0.022-1.491,0.526-2.347,0.384
			c-0.702-0.121-1.458,0.044-2.182,0.066c-0.735,0.033-1.447,0.077-2.138,0.406c-0.307,0.143-0.724,0.164-1.064,0.099
			c-1.458-0.263-2.917-0.044-4.364,0c-0.219,0.011-0.461,0.011-0.647,0.11c-0.822,0.461-1.678,0.285-2.566,0.252
			c-1.382-0.055-2.774,0.055-4.156,0.121c-0.274,0.011-0.592,0.121-0.8,0.296c-0.713,0.603-1.524,0.548-2.27,0.263
			c-0.57-0.219-1.108-0.307-1.7-0.296c-1.831,0.044-3.651,0.066-5.483,0.121c-0.943,0.033-1.897,0.033-2.84,0.23
			c-0.428,0.088-0.866,0.077-1.305,0.088c-0.515,0.011-1.02,0-1.535,0c-54.311,0-108.621,0-162.932,0
			c-1.239,0-2.489,0.088-3.695-0.307c-0.132-0.044-0.285-0.055-0.439-0.055c-2.029,0.011-3.849-0.768-5.702-1.436
			c-1.601-0.57-3.037-1.447-4.375-2.467c-2.555-1.93-4.496-4.375-5.855-7.27c-1.228-2.61-1.93-5.351-1.886-8.268
			c0.011-0.735,0-1.458,0-2.193c0.011-1.842,0.263-3.64,0.8-5.406c0.186-0.625,0.351-1.261,0.537-1.93
			c-0.186-0.252-0.318-0.515-0.526-0.702c-2.511-2.368-5.241-4.43-8.147-6.272c-6.437-4.079-13.246-7.423-20.209-10.505
			c-0.669-0.296-1.338-0.592-1.996-0.91c-4.485-2.171-8.684-7.149-9.891-12.5c-0.548-2.445-0.757-4.891-0.592-7.391
			c0.186-2.884,0.877-5.614,2.16-8.202c0.099-0.186,0.121-0.417,0.186-0.636c-0.132-0.175-0.23-0.384-0.395-0.515
			c-0.23-0.175-0.493-0.307-0.757-0.439c-1.722-0.822-3.311-1.853-4.923-2.862c-2.171-1.371-4.211-2.906-6.042-4.704
			c-0.57-0.559-1.173-1.097-1.776-1.623c-0.219-0.186-0.472-0.329-0.68-0.461c-0.472,0.088-0.647,0.417-0.855,0.724
			c-0.757,1.162-1.491,2.347-2.27,3.498c-2.336,3.465-4.978,6.656-7.917,9.638c-3.344,3.399-6.952,6.469-10.845,9.211
			c-3.29,2.325-6.776,4.32-10.417,6.042c-0.132,0.066-0.274,0.099-0.406,0.175c-4.101,2.127-8.454,3.608-12.851,4.978
			c-2.226,0.691-4.496,1.25-6.809,1.59c-1.009,0.154-2.018,0.318-3.015,0.548c-0.789,0.186-1.579,0.285-2.379,0.384
			c-0.504,0.066-1.02,0.143-1.491,0.329c-0.373,0.143-0.691,0.132-1.042,0.033c-0.208-0.055-0.439-0.077-0.647-0.066
			c-1.963,0.11-3.947,0.164-5.866,0.614c-0.581,0.132-1.14,0.23-1.722,0.23c-0.581,0-1.173,0.011-1.754-0.011
			c-0.285-0.011-0.603,0.011-0.866-0.099c-1.042-0.439-2.149-0.406-3.235-0.504c-0.877-0.077-1.743-0.154-2.621-0.241
			c-0.581-0.055-1.173-0.11-1.722,0.154c-0.219,0.11-0.439,0.11-0.614-0.011c-0.439-0.307-0.921-0.417-1.436-0.45
			c-1.02-0.066-2.04-0.088-3.015-0.428c-0.066-0.022-0.143-0.033-0.219-0.033c-2.423-0.099-4.726-0.8-7.051-1.382
			c-3.114-0.779-6.184-1.7-9.178-2.873c-3.684-1.447-7.226-3.18-10.559-5.307c-5.154-3.279-9.748-7.215-13.531-12.04
			c-4.364-5.57-7.16-11.853-8.432-18.816c-0.329-1.798-0.515-3.618-0.482-5.45c0.033-1.754,0.033-3.509,0-5.263
			c-0.077-3.838,0.559-7.566,1.557-11.25c1.875-6.886,5.132-13.049,9.836-18.4c4.386-4.989,9.496-9.079,15.494-12.018
			c2.5-1.228,5.066-2.281,7.665-3.246c2.061-0.768,4.2-1.25,6.327-1.754c2.05-0.482,4.112-0.965,6.25-1.009
			c0.219,0,0.45,0,0.647-0.077c1.129-0.417,2.303-0.329,3.465-0.351c3.509-0.055,7.018-0.175,10.527-0.033
			c0.559,0.022,1.151,0.132,1.7-0.263c-0.077-0.197-0.11-0.428-0.23-0.592c-0.537-0.691-1.097-1.371-1.645-2.05
			c-2.939-3.64-5.702-7.423-8.41-11.228c-3.311-4.649-6.48-9.375-9.222-14.386c-4.222-7.72-7.665-15.757-9.891-24.299
			c-0.724-2.763-1.272-5.548-1.765-8.366c-0.461-2.599-0.68-5.219-0.943-7.829c-0.154-1.524-0.154-3.07-0.154-4.605
			c0-2.489,0-4.967,0.11-7.456c0.077-1.678,0.274-3.344,0.515-5.011c0.921-6.469,2.665-12.72,5.274-18.718
			c3.004-6.886,6.974-13.158,11.919-18.816c2.314-2.643,4.66-5.241,7.314-7.566c5.625-4.923,11.656-9.277,18.246-12.829
			c6.327-3.41,12.917-6.173,19.737-8.377c3.816-1.228,7.686-2.259,11.634-2.983c2.007-0.362,4.013-0.735,6.009-1.129
			c0.362-0.066,0.724-0.121,1.086-0.132c1.031-0.044,2.007-0.318,3.015-0.526c0.987-0.208,2.029-0.099,3.048-0.175
			c0.351-0.033,0.735-0.132,1.02-0.318c0.603-0.406,1.228-0.493,1.864-0.197c0.559,0.252,1.129,0.274,1.711,0.252
			c1.097-0.044,2.193-0.11,3.29-0.143c3.07-0.077,6.141-0.066,9.134,0.164c0.34-0.132,0.614-0.252,0.888-0.34
			c0.504-0.154,1.031-0.208,1.469,0.121c0.493,0.373,1.064,0.471,1.645,0.537c6.722,0.757,13.191,2.5,19.485,4.912
			c2.116,0.811,4.167,1.776,6.162,2.851c3.279,1.776,6.469,3.684,9.441,5.965c3.553,2.73,6.82,5.757,9.726,9.156
			c5.954,6.963,9.967,14.902,11.744,23.926c0.855,4.32,1.173,8.684,0.998,13.081c-0.077,1.754-0.11,3.509-0.493,5.23
			c-0.351,4.254-1.469,8.334-2.785,12.369c-1.097,3.344-2.533,6.535-4.178,9.638c-2.643,5-5.812,9.638-9.573,13.86
			c-2.379,2.676-4.912,5.219-7.665,7.511c-1.678,1.404-3.421,2.741-5.143,4.101c-0.57,0.45-1.129,0.91-1.732,1.393
			c0.175,0.099,0.296,0.23,0.384,0.197c0.559-0.164,1.118-0.329,1.656-0.548c2.654-1.042,5.351-1.919,8.114-2.61
			c0.921-0.23,1.831-0.493,2.763-0.658c2.379-0.395,4.726-0.954,7.127-1.162c0.439-0.033,0.877-0.099,1.294-0.208
			c0.351-0.088,0.68-0.252,1.107-0.428c1.36,0.351,2.818,0.285,4.276,0.241c1.393-0.033,2.774-0.088,4.167-0.044
			c0.877,0.022,1.743-0.022,2.654-0.219c0.702,0.373,1.48,0.537,2.281,0.658c2.456,0.351,4.89,0.899,7.27,1.601
			c2.599,0.768,5.066,1.864,7.423,3.224c2.61,1.502,5.033,3.246,7.215,5.329c0.362,0.351,0.691,0.768,1.228,0.866
			c0.471-0.011,0.822-0.285,1.173-0.537c2.072-1.502,4.298-2.774,6.535-4.013c4.046-2.226,8.334-3.893,12.785-5.088
			c2.105-0.559,4.211-1.184,6.415-1.338c0.647-0.044,1.305-0.154,1.765-0.461c0.844,0.011,1.568,0.055,2.292,0.011
			c1.908-0.121,3.816-0.121,5.691,0.099c3.597,0.417,6.996,1.447,10.011,3.564c1.623,1.14,3.07,2.467,4.353,3.98
			c0.943,1.118,1.107,1.151,2.412,0.472c2.796-1.436,5.647-2.752,8.542-3.958c2.972-1.228,6.042-2.171,9.145-3.015
			c1.053-0.285,2.149-0.395,3.235-0.581c0.581-0.099,1.151-0.197,1.733-0.296c0.285-0.055,0.57-0.143,0.866-0.154
			c3.575-0.175,7.16-0.362,10.702,0.439c5.68,1.272,10.483,3.98,14.112,8.608c0.406,0.515,0.8,1.042,1.217,1.557
			c0.132,0.154,0.34,0.252,0.526,0.395c0.219-0.077,0.439-0.11,0.614-0.219c0.691-0.417,1.36-0.855,2.04-1.283
			c1.722-1.107,3.509-2.105,5.351-2.993c0.592-0.285,1.162-0.603,1.722-0.954c0.154-0.099,0.23-0.34,0.318-0.482
			c-0.132-0.581-0.504-0.943-0.8-1.36c-1.963-2.774-3.333-5.823-4.145-9.123c-0.439-1.776-0.779-3.564-0.789-5.406
			c0-0.515-0.022-1.02-0.044-1.535c-0.219-4.244,0.691-8.279,2.347-12.149c2.983-6.996,7.774-12.5,14.101-16.645
			c2.697-1.765,5.559-3.235,8.63-4.244c2.083-0.691,4.222-1.173,6.404-1.415c0.439-0.055,0.866-0.143,1.294-0.197
			c0.581-0.066,1.162-0.154,1.743-0.164c1.316-0.033,2.632,0.044,3.936-0.132c0.285-0.033,0.603-0.055,0.866,0.033
			c1.798,0.625,3.739,0.636,5.526,1.261c1.239,0.428,2.5,0.811,3.728,1.294c2.478,0.965,4.649,2.412,6.722,4.068
			c3.015,2.423,5.351,5.373,7.083,8.816c1.776,3.509,2.708,7.237,2.972,11.152c0.471,7.029-1.316,13.454-5.033,19.397
			c-2.237,3.575-5.154,6.524-8.301,9.277c-0.384,0.34-0.8,0.636-1.173,0.987c-0.154,0.143-0.23,0.362-0.34,0.548
			c0.055,0.23,0.066,0.461,0.175,0.636c0.691,1.206,1.261,2.478,1.754,3.772c0.055,0.132,0.143,0.252,0.208,0.384
			c0.121,0.208,0.559,0.373,0.768,0.263c0.318-0.175,0.658-0.34,0.932-0.559c2.007-1.612,4.211-2.895,6.48-4.09
			c2.083-1.097,4.287-1.908,6.623-2.215c2.248-0.285,4.518-0.318,6.776,0.033c0.219,0.033,0.428,0.055,0.603,0.088
			c0.57-0.077,0.998-0.373,1.404-0.68c2.116-1.59,4.441-2.818,6.831-3.926c2.73-1.261,5.603-2.072,8.542-2.708
			c3.969-0.855,7.983-0.888,11.996-0.658c1.524,0.088,3.048,0.395,4.551,0.691c4.123,0.833,7.961,2.412,11.579,4.518
			c1.327,0.779,2.61,1.623,3.805,2.577c0.285,0.23,0.57,0.461,0.866,0.68c0.504,0.373,0.899,0.263,1.228-0.34
			c0.143-0.252,0.285-0.515,0.406-0.779c1.25-2.741,2.993-5.187,4.77-7.599c3.279-4.463,7.281-8.202,11.799-11.36
			c4.463-3.125,9.331-5.428,14.584-6.897c1.239-0.132,2.401-0.614,3.662-0.636c0.077,0,0.154,0,0.219-0.022
			c2.072-0.592,4.2-0.384,6.316-0.439c0.515-0.011,1.02,0.022,1.535,0.022c2.577,0,5.066,0.515,7.511,1.305
			c2.368,0.768,4.638,1.776,6.776,3.059c1.634,0.987,3.136,2.138,4.507,3.465c2.171,2.094,4.068,4.397,5.537,7.04
			c1.875,3.355,3.125,6.919,3.608,10.735c0.274,2.248,0.669,4.485,0.537,6.766c-0.033,0.658-0.077,1.316-0.132,1.963
			c-0.175,1.82-0.362,3.629-0.537,5.45c-0.022,0.208,0.055,0.428,0.077,0.592c0.285,0.351,0.636,0.417,1.009,0.472
			c1.524,0.186,3.037,0.395,4.551,0.713c2.972,0.636,5.702,1.842,8.191,3.542c4.814,3.279,8.18,7.643,9.956,13.224
			c0.515,1.612,0.844,3.246,1.129,4.901c0.055,0.362,0.121,0.713,0.186,1.118c0.252,0.022,0.482,0.121,0.647,0.044
			c0.395-0.175,0.768-0.406,1.129-0.658c3.213-2.182,6.634-3.991,10.241-5.439c1.897-0.757,3.816-1.48,5.866-1.689
			c0.822-0.351,1.7-0.428,2.577-0.45c0.702-0.011,1.228-0.439,1.941-0.713c1.645,0,3.399,0,5.154,0c0.943,0,1.897-0.066,2.763,0.471
			c0.23,0.143,0.559,0.186,0.844,0.197c2.368,0.11,4.562,0.91,6.787,1.623c2.599,0.822,4.945,2.149,7.193,3.684
			c0.658,0.45,1.305,0.954,1.875,1.513c1.162,1.107,2.281,2.27,3.399,3.421c0.307,0.307,0.57,0.669,0.833,1.02
			c2.489,3.41,4.2,7.171,5.154,11.283c0.307,1.349,0.548,2.719,0.768,4.09c0.11,0.647,0.132,1.305,0.132,1.963
			C612,418.862,612,421.055,611.989,423.249L611.989,423.249z M168.93,331.843c3.301-3.936,6.108-8.191,8.136-12.939
			c0.899-2.083,1.711-4.2,2.336-6.371c0.647-2.248,1.261-4.496,1.338-6.864c0.011-0.285,0.088-0.581,0.154-0.866
			c0.241-0.998,0.307-2.029,0.318-3.048c0.033-1.974,0.033-3.947-0.406-5.888c-0.154-0.702-0.066-1.469-0.219-2.171
			c-0.515-2.358-1.14-4.693-2.072-6.919c-2.171-5.208-5.439-9.66-9.529-13.509c-3.871-3.64-8.246-6.513-13.071-8.717
			c-1.656-0.757-3.333-1.502-5.055-2.116c-1.919-0.691-3.882-1.239-5.844-1.809c-1.469-0.428-3.015-0.504-4.485-0.91
			c-0.625-0.175-1.327-0.077-1.952-0.252c-0.724-0.197-1.436-0.252-2.16-0.285c-0.581-0.022-1.173-0.033-1.743-0.121
			c-3.136-0.45-6.272-0.384-9.408-0.077c-1.382,0.143-2.763,0.241-4.156,0.23c-0.658,0-1.327,0-1.963,0.154
			c-0.724,0.175-1.436,0.263-2.16,0.285c-0.362,0.011-0.735,0.022-1.086,0.121c-0.636,0.175-1.272,0.307-1.93,0.362
			c-0.724,0.066-1.436,0.241-2.138,0.406c-1.206,0.285-2.434,0.428-3.64,0.702c-2.061,0.482-4.09,1.064-6.13,1.634
			c-4.013,1.118-7.95,2.5-11.766,4.167c-4.419,1.941-8.706,4.134-12.774,6.733c-2.467,1.579-4.88,3.213-7.116,5.11
			c-1.502,1.272-3.037,2.533-4.485,3.871c-1.129,1.042-2.149,2.193-3.202,3.301c-0.351,0.373-0.636,0.8-0.987,1.173
			c-2.007,2.149-3.706,4.518-5.296,6.974c-2.27,3.509-4.101,7.237-5.537,11.163c-0.724,1.996-1.349,4.024-1.798,6.086
			c-0.559,2.566-1.195,5.132-1.206,7.785c0,0.362-0.077,0.724-0.132,1.086c-0.154,0.943-0.175,1.875-0.088,2.84
			c0.11,1.239,0.11,2.478,0.175,3.717c0.121,2.325-0.011,4.682,0.581,6.974c0.033,0.143,0.022,0.296,0.044,0.439
			c0.208,1.294,0.362,2.61,0.647,3.882c0.482,2.138,1.02,4.254,1.612,6.371c1.009,3.52,2.259,6.952,3.772,10.274
			c2.906,6.393,6.338,12.511,10.307,18.312c1.732,2.533,3.498,5.044,5.34,7.5c2.511,3.322,5.055,6.612,7.709,9.825
			c1.02,1.239,2.04,2.489,3.07,3.717c2.061,2.467,4.123,4.934,6.217,7.38c3.805,4.441,7.555,8.926,10.867,13.75
			c1.864,2.719,3.619,5.494,4.945,8.52c0.647,1.469,1.261,2.961,1.601,4.54c0.373,1.711,0.855,3.399,0.735,5.187
			c-0.033,0.428,0.088,0.866,0.11,1.305c0.022,0.658,0.066,1.316,0.011,1.974c-0.088,1.096-0.197,2.182-0.164,3.279
			c0.011,0.362-0.099,0.724-0.164,1.075c-0.088,0.428-0.219,0.855-0.285,1.283c-0.373,2.632-1.261,5.099-2.314,7.511
			c-0.526,1.206-1.107,2.39-1.831,3.487c-2.796,4.287-6.36,7.818-10.68,10.548c-4.035,2.555-8.366,4.43-13.049,5.483
			c-1.711,0.384-3.388,0.833-5.132,1.031c-0.8,0.099-1.59,0.208-2.39,0.197c-0.515-0.011-1.02,0.011-1.535,0.022
			c-3.092,0.099-6.119-0.296-9.09-1.129c-2.972-0.833-5.79-1.985-8.388-3.662c-0.735-0.472-1.436-1.009-2.149-1.513
			c-2.292-1.634-4.101-3.739-5.636-6.064c-1.546-2.347-2.555-4.934-2.993-7.709c-0.329-2.094-0.504-4.189-0.066-6.316
			c0.581-2.851,1.7-5.428,3.586-7.643c1.678-1.974,3.695-3.52,5.965-4.748c1.097-0.592,2.259-1.02,3.443-1.393
			c1.053-0.329,2.171-0.285,3.202-0.669c1.535,0,3.07,0,4.605,0c0.143,0,0.296,0.033,0.428,0.066c1.261,0.34,2.533,0.658,3.75,1.184
			c1.711,0.735,3.202,1.798,4.463,3.114c1.623,1.689,2.961,3.608,3.958,5.757c0.68,1.469,1.118,3.004,1.623,4.529
			c0.23,0.691,0.362,1.415,0.592,2.105c0.373,1.107,0.811,2.193,1.513,3.147c1.239,1.656,3.64,2.61,5.855,1.568
			c1.228-0.581,2.248-1.371,3.048-2.434c1.02-1.349,1.809-2.829,2.292-4.452c0.482-1.612,0.625-3.29,0.8-4.956
			c0.055-0.57-0.011-1.173-0.143-1.733c-0.154-0.647-0.219-1.294-0.241-1.952c-0.011-0.296,0-0.592-0.088-0.866
			c-0.439-1.469-0.833-2.95-1.568-4.309c-1.722-3.213-4.09-5.855-7.062-7.961c-2.04-1.447-4.244-2.566-6.579-3.443
			c-2.259-0.855-4.562-1.568-6.963-1.864c-0.439-0.055-0.855-0.186-1.294-0.252c-0.789-0.121-1.59-0.241-2.39-0.34
			c-0.724-0.088-1.458-0.143-2.182-0.186c-2.04-0.121-4.09-0.307-6.13-0.154c-3.279,0.252-6.535,0.735-9.737,1.491
			c-1.349,0.318-2.697,0.68-4.002,1.129c-3.893,1.338-7.544,3.147-10.867,5.592c-1.765,1.305-3.421,2.752-4.89,4.386
			c-1.996,2.237-3.684,4.704-4.989,7.434c-2.05,4.298-3.103,8.794-3.015,13.553c0.011,0.515,0.055,1.02,0.033,1.535
			c-0.088,1.776,0.208,3.509,0.614,5.208c1.009,4.255,2.862,8.125,5.735,11.459c2.149,2.5,4.54,4.759,7.303,6.59
			c0.976,0.647,1.941,1.316,2.972,1.864c4.265,2.303,8.783,3.947,13.465,5.176c0.976,0.263,1.974,0.493,2.993,0.614
			c1.151,0.143,2.303,0.351,3.443,0.57c1.02,0.197,2.018,0.296,3.037,0.329c0.724,0.033,1.458,0.088,2.182,0.208
			c1.382,0.23,2.763,0.241,4.156,0.241c1.173,0,2.336,0,3.509,0c1.535-0.011,3.07,0.066,4.572-0.362
			c0.208-0.066,0.439-0.099,0.647-0.077c1.557,0.164,3.026-0.373,4.54-0.592c1.941-0.285,3.86-0.768,5.757-1.283
			c3.454-0.943,6.842-2.105,10.11-3.575c4.825-2.16,9.298-4.891,13.432-8.191c5.088-4.046,9.397-8.794,12.709-14.375
			c4.43-7.467,6.458-13.685,7.621-21.755c0.132-0.943,0.285-1.875,0.406-2.818c0.066-0.504,0.11-1.02,0.121-1.524
			c0.011-1.239,0-2.489,0-3.728c0-1.02-0.066-2.05,0.011-3.07c0.077-1.053-0.34-2.007-0.428-3.026
			c-0.164-1.82-0.592-3.608-1.053-5.373c-1.053-3.969-2.621-7.741-4.518-11.382c-1.393-2.654-2.928-5.23-4.562-7.741
			c-3.783-5.833-8.059-11.305-12.38-16.733c-1.645-2.061-3.224-4.156-4.89-6.195c-1.436-1.754-2.774-3.586-4.145-5.384
			c-0.965-1.283-1.919-2.577-2.862-3.882c-1.765-2.423-3.531-4.847-5.11-7.391c-2.654-4.287-5.274-8.597-7.38-13.191
			c-1.765-3.86-3.213-7.818-4.375-11.897c-0.658-2.314-1.129-4.682-1.48-7.062c-0.252-1.732-0.461-3.465-0.691-5.208
			c-0.022-0.143,0-0.296-0.011-0.439c-0.022-1.974-0.011-3.947-0.077-5.921c-0.066-1.985,0.154-3.947,0.548-5.877
			c0.746-3.597,1.952-7.029,3.717-10.252c1.557-2.829,3.476-5.417,5.757-7.698c3.377-3.377,7.226-6.162,11.481-8.355
			c4.759-2.445,9.748-4.276,14.967-5.461c1.634-0.373,3.257-0.8,4.923-1.042c1.447-0.219,2.895-0.417,4.331-0.669
			c0.866-0.154,1.733-0.241,2.61-0.241c2.116,0.011,4.233,0.011,6.349,0.033c0.658,0,1.327-0.055,1.974,0.044
			c3.037,0.417,6.064,0.91,8.97,1.952c5.066,1.831,9.32,4.803,12.774,8.937c0.329,0.395,0.636,0.811,0.921,1.228
			c1.985,2.851,3.311,6.009,4.068,9.386c0.318,1.415,0.526,2.851,0.537,4.331c0.022,1.754-0.077,3.509-0.011,5.252
			c0.055,1.634-0.362,3.191-0.493,4.792c-0.614,2.325-1.206,4.649-2.061,6.908c-1.217,3.169-2.917,6.031-5.022,8.684
			c-1.371,1.722-2.818,3.355-4.583,4.693c-0.23,0.175-0.417,0.406-0.647,0.592c-0.921,0.724-1.798,1.491-2.774,2.138
			c-2.368,1.601-4.923,2.851-7.555,3.958c-2.423,1.02-4.967,1.656-7.533,2.237c-0.998,0.23-2.007,0.406-3.015,0.526
			c-0.647,0.077-1.305,0.132-1.952,0.252c-0.647,0.121-1.305,0.197-1.963,0.197c-2.555,0.011-5.121,0-7.676-0.022
			c-0.504,0-1.031-0.022-1.513-0.164c-0.713-0.197-1.436-0.23-2.171-0.252c-0.504-0.011-1.042,0-1.513-0.132
			c-0.713-0.197-1.436-0.252-2.138-0.417c-1.129-0.274-2.303-0.329-3.41-0.746c-0.746-0.274-1.557-0.417-2.347-0.482
			c-1.02-0.077-2.05-0.055-3.07-0.011c-1.064,0.044-1.974,0.504-2.719,1.261c-0.428,0.428-0.658,0.954-0.713,1.568
			c-0.121,1.305,0.23,2.456,1.009,3.498c0.526,0.713,1.14,1.327,1.842,1.864c1.689,1.294,3.476,2.445,5.406,3.322
			c2.456,1.118,5,2.061,7.599,2.818c1.404,0.406,2.84,0.68,4.254,1.02c0.493,0.121,0.987,0.329,1.48,0.362
			c1.458,0.088,2.851,0.669,4.331,0.548c0.208-0.022,0.439,0.055,0.647,0.11c0.779,0.23,1.579,0.252,2.39,0.252
			c1.458,0,2.928,0,4.386,0c2.621-0.033,5.263,0.23,7.862-0.373c1.261,0.033,2.423-0.515,3.662-0.581
			c4.485-1.075,8.86-2.467,13.038-4.419c1.996-0.932,3.871-2.051,5.746-3.191C160.048,340.724,164.862,336.711,168.93,331.843
			L168.93,331.843z M380.602,327.972c-0.329-0.811-0.768-1.568-1.327-2.259c-2.171-2.632-5.066-3.838-8.345-4.309
			c-1.601,0.022-3.213,0.055-4.814,0.077c-1.118,0.329-2.259,0.603-3.355,0.987c-3.531,1.217-6.448,3.355-8.959,6.086
			c-1.14,1.25-2.007,2.686-2.763,4.189c-0.976,1.919-1.612,3.915-1.404,6.184c0,0.351,0.022,0.789,0,1.228
			c-0.066,1.283,0.307,2.445,0.855,3.586c2.061,4.276,5.691,6.283,10.187,7.04c2.193,0.066,4.353,0.055,6.502-0.581
			c3.739-1.118,6.93-3.059,9.573-5.899c1.908-2.051,3.355-4.408,4.211-7.105C381.939,334.058,381.83,330.987,380.602,327.972
			L380.602,327.972z M595.366,420.014c-0.011-0.296-0.011-0.581,0-0.877c0.033-2.138-0.34-4.233-1.009-6.239
			c-0.658-1.974-1.754-3.739-3.388-5.033c-2.577-2.04-5.494-3.311-8.86-3.311c-1.02,0-2.051-0.011-3.07,0
			c-0.439,0-0.888-0.022-1.316,0.066c-2.434,0.493-4.836,1.107-7.073,2.259c-4.452,2.292-8.432,5.252-12.051,8.695
			c-1.798,1.711-3.509,3.52-5.011,5.505c-0.219,0.296-0.471,0.559-0.713,0.833c-3.487,4.046-7.226,7.862-11.217,11.404
			c-1.798,1.601-3.695,3.103-5.603,4.572c-1.447,1.107-2.983,2.105-4.496,3.125c-2.741,1.842-5.68,3.29-8.816,4.342
			c-1.524,0.504-3.081,0.943-4.715,0.757c-1.776-0.208-3.191-0.976-3.947-2.708c-0.932-2.149-1.118-4.408-0.68-6.656
			c0.724-3.761,2.116-7.292,4.134-10.549c0.998-1.612,2.029-3.213,3.147-4.748c3.257-4.518,6.886-8.717,10.834-12.632
			c1.349-1.338,2.676-2.697,4.035-4.024c1.546-1.502,2.336-3.322,2.38-5.472c0.011-0.658,0-1.316-0.088-1.974
			c-0.384-3.037-1.864-5.351-4.616-6.755c-1.382-0.702-2.873-1.075-4.419-1.097c-1.535-0.022-3.07,0-4.605-0.011
			s-3.07,0.088-4.594-0.175c-1.228-0.208-2.467-0.252-3.717-0.252c-1.316,0-2.632,0.011-3.915-0.351
			c-0.417-0.121-0.866-0.143-1.305-0.132c-1.184,0.022-2.292-0.34-3.399-0.559c-0.077-0.197-0.197-0.351-0.164-0.45
			c0.175-0.482,0.362-0.954,0.592-1.414c1.25-2.566,2.325-5.208,3.169-7.939c0.899-2.939,1.722-5.877,2.149-8.926
			c0.175-1.305,0.23-2.621,0.274-3.926c0.022-0.877,0.439-1.711,0.318-2.599c-0.132-0.943-0.263-1.875-0.395-2.818
			c-0.011-0.077-0.011-0.143-0.022-0.219c-0.066-0.362-0.121-0.724-0.219-1.075c-0.888-3.399-2.829-6.053-5.757-7.994
			c-0.669-0.45-1.382-0.822-2.149-1.075c-1.393-0.45-2.774-0.833-4.265-0.855c-1.535-0.033-3.092-0.132-4.583,0.164
			c-4.057,0.8-7.785,2.445-11.195,4.814c-0.537,0.373-1.053,0.789-1.579,1.195c-2.138,1.667-4.09,3.542-5.779,5.669
			c-1.689,2.116-3.125,4.408-4.287,6.864c-0.757,1.59-1.349,3.235-1.864,4.912c-0.647,2.105-1.075,4.265-1.195,6.437
			c-0.143,2.467-0.318,4.967,0.373,7.401c0.066,1.107,0.45,2.138,0.79,3.169c1.118,3.311,2.928,6.184,5.384,8.684
			c1.645,1.678,3.432,3.169,5.362,4.507c0.537,0.362,1.162,0.636,1.535,1.184c0.044,0.439-0.241,0.691-0.493,0.943
			c-1.425,1.371-2.851,2.73-4.276,4.09c-2.785,2.621-5.921,4.77-9.167,6.733c-3.202,1.941-6.656,3.344-10.23,4.419
			c-1.25,0.373-2.5,0.833-3.838,0.789c-0.175-0.011-0.34-0.154-0.537-0.252c0.186-0.472,0.351-0.877,0.504-1.283
			c0.636-1.634,1.228-3.279,1.678-4.978c0.406-1.557,0.757-3.114,1.053-4.704c0.296-1.579,0.559-3.158,0.548-4.77
			c0-0.143,0.011-0.296,0.055-0.439c0.23-0.789,0.175-1.59,0.186-2.39c0.011-1.458-0.077-2.917-0.208-4.364
			c-0.132-1.524-0.307-3.037-0.592-4.551c-0.625-3.257-1.656-6.371-3.136-9.32c-1.985-3.969-4.66-7.423-8.114-10.22
			c-0.899-0.735-1.897-1.371-2.884-1.996c-2.752-1.754-5.757-2.785-9.013-3.059c-0.362-0.033-0.724-0.143-1.086-0.175
			c-0.581-0.055-1.173-0.154-1.743-0.099c-1.382,0.132-2.752,0.318-4.123,0.515c-0.724,0.099-1.436,0.23-2.138,0.428
			c-2.84,0.789-5.439,2.116-7.818,3.86c-3.29,2.412-5.844,5.494-7.895,9.002c-1.524,2.599-2.697,5.362-3.531,8.246
			c-0.779,2.73-1.59,5.461-1.634,8.355c-0.011,0.877-0.23,1.743-0.285,2.61c-0.186,3.531,0.197,6.996,1.096,10.406
			c0.395,1.48,0.811,2.961,1.436,4.364c2.665,6.053,6.766,10.888,12.325,14.474c2.829,1.82,5.801,3.377,9.024,4.419
			c0.482,0.154,0.976,0.318,1.436,0.526c0.164,0.077,0.263,0.296,0.406,0.471c-2.84,3.969-6.349,7.127-10.559,9.463
			c-2.577,1.436-5.351,2.325-8.345,2.314c-0.077,0-0.143,0.011-0.219,0.011c-1.765,0.066-3.476-0.164-5.143-0.833
			c-1.436-0.57-2.84-1.173-4.035-2.16c-1.25-1.02-2.368-2.171-3.355-3.454c-2.314-3.015-3.717-6.448-4.671-10.066
			c-0.844-3.18-1.294-6.437-1.261-9.737c0.011-1.239,0.033-2.489,0-3.728c-0.044-1.623,0.197-3.213,0.461-4.792
			c0.373-2.292,0.844-4.583,1.513-6.831c0.91-3.015,1.974-5.965,3.333-8.794c2.105-4.364,4.649-8.454,7.917-12.051
			c1.579-1.743,2.029-3.662,1.206-5.866c-0.055-0.132-0.077-0.285-0.143-0.417c-0.482-1.009-1.118-1.908-2.149-2.412
			c-1.173-0.57-2.39-0.647-3.597-0.044c-0.263,0.132-0.515,0.285-0.768,0.417c-2.336,1.25-4.43,2.84-6.393,4.605
			c-1.798,1.612-3.52,3.301-5.208,5.022c-3.542,3.619-6.656,7.588-9.364,11.853c-4.211,6.59-7.084,13.75-8.663,21.404
			c-0.296,1.415-0.526,2.862-0.68,4.309c-0.186,1.82-0.384,3.629-0.384,5.461c0,0.143-0.066,0.285-0.132,0.526
			c-0.329,0.362-0.713,0.8-1.129,1.206c-1.425,1.371-2.862,2.719-4.287,4.079c-3.279,3.147-6.853,5.932-10.614,8.465
			c-2.007,1.349-4.167,2.423-6.491,3.114c-0.504,0.143-0.998,0.186-1.513,0.164c-0.877-0.044-1.535-0.461-1.985-1.173
			c-0.23-0.362-0.417-0.789-0.493-1.206c-0.121-0.636-0.175-1.294-0.099-1.963c0.154-1.382,0.395-2.752,0.768-4.09
			c0.461-1.612,0.943-3.224,1.447-4.825c1.875-6.075,4.09-12.029,6.458-17.928c1.963-4.88,3.991-9.737,6.184-14.518
			c1.765-3.849,3.52-7.709,5.285-11.557c1.118-2.456,2.248-4.912,3.366-7.38c0.844-1.864,1.59-3.772,2.204-5.724
			c0.471-1.48,0.669-2.993,0.504-4.54c-0.132-1.162-0.691-2.072-1.634-2.741c-0.658-0.471-1.382-0.8-2.182-0.954
			c-1.743-0.351-3.476-0.417-5.219-0.088c-0.362,0.066-0.724,0.11-1.086,0.099c-1.48-0.044-2.862,0.57-4.331,0.57
			c-2.007,0.647-4.079,1.107-6.053,1.875c-2.73,1.053-5.362,2.314-7.895,3.783c-2.665,1.557-5.121,3.377-7.281,5.592
			c-1.031,1.053-1.897,2.215-2.544,3.553c-0.285,0.603-0.45,1.228-0.537,1.886c-0.143,1.053,0.241,1.864,0.998,2.533
			c0.614,0.548,1.316,0.91,2.16,0.943c0.724,0.033,1.447,0.11,2.182,0.033c0.724-0.077,1.458-0.033,2.193-0.011
			c0.746,0.033,1.217,0.658,1.031,1.36c-0.121,0.493-0.274,0.987-0.472,1.458c-0.548,1.272-1.097,2.555-1.711,3.794
			c-1.963,4.002-3.915,8.005-5.757,12.073c-1.963,4.32-3.871,8.673-5.505,13.136c-0.23,0.636-0.548,1.184-0.998,1.678
			c-2.04,2.292-4.079,4.583-6.184,6.82c-4.331,4.583-9.024,8.75-14.014,12.588c-2.215,1.7-4.605,3.092-7.193,4.145
			c-0.603,0.241-1.228,0.493-1.853,0.647c-0.482,0.121-1.02,0.164-1.513,0.11c-0.943-0.099-1.634-0.625-1.952-1.535
			c-0.164-0.472-0.274-0.998-0.296-1.491c-0.066-1.612-0.088-3.213,0.318-4.792c0.274-1.064,0.504-2.127,0.789-3.191
			c0.932-3.377,2.04-6.711,3.235-10c1.601-4.397,3.257-8.761,5.033-13.081c2.39-5.812,4.89-11.568,7.577-17.259
			c1.064-2.248,2.127-4.485,3.147-6.755c1.272-2.807,2.237-5.713,2.95-8.706c0.417-1.722,0.504-3.476,0.219-5.23
			c-0.384-2.434-1.557-4.419-3.816-5.559c-1.502-0.757-3.136-1.173-4.836-1.294c-1.173-0.088-2.325,0.132-3.476,0.318
			c-1.568,0.252-3.947,0.746-5.318,1.184c-5.614,1.754-10.845,4.32-15.845,7.38c-3.991,2.445-7.807,5.143-11.459,8.081
			c-1.425,1.14-2.873,2.259-4.244,3.476c-2.621,2.336-5.23,4.682-7.654,7.215c-0.45,0.471-0.888,0.965-1.415,1.535
			c-0.055-0.296-0.154-0.493-0.11-0.647c0.285-0.899,0.625-1.798,0.932-2.697c1.436-4.222,2.862-8.443,4.298-12.654
			c0.559-1.656,1.107-3.322,1.524-5.022c0.34-1.371,0.406-2.752,0.186-4.134c-0.285-1.733-1.217-2.983-2.906-3.52
			c-0.965-0.307-1.963-0.603-3.004-0.504c-1.458,0.132-2.928,0.154-4.353,0.428c-1.645,0.307-3.279,0.669-4.88,1.162
			c-2.445,0.757-4.814,1.722-7.084,2.895c-2.676,1.371-5.197,2.983-7.555,4.836c-1.733,1.36-3.202,2.983-4.518,4.748
			c-0.965,1.294-1.469,2.676-1.129,4.298c0.395,1.031,1.162,1.623,2.193,1.974c0.932,0.318,1.842,0.428,2.796,0.252
			c0.57-0.11,1.14-0.263,1.711-0.362c0.713-0.121,1.48,0.614,1.425,1.327c-0.022,0.219-0.033,0.439-0.088,0.647
			c-0.285,0.976-0.559,1.963-0.899,2.928c-1.393,4.079-2.807,8.147-4.211,12.226c-1.612,4.693-3.202,9.397-4.814,14.09
			c-0.702,2.061-0.702,2.061-2.182,3.728c-3.301,3.739-6.952,7.105-10.845,10.209c-0.746,0.592-1.469,1.206-2.248,1.743
			c-1.568,1.075-3.136,2.149-4.759,3.136c-3.936,2.401-8.07,4.43-12.489,5.779c-2.5,0.768-5.011,1.568-7.687,1.59
			c-0.362,0-0.713,0.121-1.075,0.186c-0.428,0.077-0.866,0.219-1.294,0.208c-2.105-0.066-4.244,0.263-6.327-0.318
			c-0.417-0.11-0.877-0.066-1.305-0.121c-0.362-0.044-0.724-0.099-1.075-0.197c-0.768-0.219-1.546-0.439-2.303-0.702
			c-3.081-1.075-5.823-2.676-8.048-5.121c-1.754-1.919-3.081-4.068-3.958-6.513c-1.327-3.706-1.568-7.566-1.557-11.448
			c0-0.143,0.022-0.296,0.044-0.439c0.439-3.849,0.998-7.676,2.094-11.415c0.757-2.599,1.656-5.143,2.73-7.621
			c1.864-4.309,4.178-8.366,7.04-12.095c2.654-3.443,5.669-6.491,9.463-8.706c0.822-0.482,1.667-0.921,2.555-1.25
			c1.809-0.669,3.695-0.91,5.614-0.702c1.579,0.164,2.928,0.833,3.937,2.127c0.504,0.647,0.91,1.338,1.151,2.105
			c0.307,0.976,0.537,1.963,0.559,3.004c0.033,1.458,0.121,2.939-0.143,4.375c-0.263,1.425-0.625,2.851-1.118,4.233
			c-1.118,3.114-2.643,6.02-4.364,8.827c-2.5,4.068-5.581,7.687-8.816,11.163c-3.301,3.531-6.963,6.667-10.702,9.726
			c-0.91,0.735-1.776,1.524-2.621,2.325c-0.811,0.757-1.415,1.656-1.93,2.643c-0.417,0.8-0.57,1.645-0.537,2.544
			c0.022,0.844,0.34,1.568,0.921,2.16c1.217,1.25,2.719,1.656,4.408,1.393c1.009-0.164,1.974-0.515,2.906-0.954
			c4.156-1.996,8.191-4.2,12.095-6.645c4.408-2.752,8.553-5.855,12.511-9.233c3.958-3.377,7.665-6.985,10.943-11.042
			c2.445-3.015,4.66-6.184,6.393-9.671c0.943-1.908,1.722-3.871,2.27-5.921c0.45-1.689,0.811-3.421,0.669-5.197
			c-0.011-0.143-0.011-0.296,0-0.439c0.11-2.314-0.428-4.507-1.305-6.612c-1.7-4.057-4.485-7.149-8.399-9.189
			c-1.491-0.779-3.037-1.414-4.66-1.875c-1.546-0.439-3.059-0.954-4.693-0.932c-0.219,0-0.439-0.022-0.647-0.077
			c-1.952-0.45-3.926-0.252-5.888-0.099c-0.515,0.044-1.02,0.208-1.524,0.175c-1.919-0.132-3.75,0.581-5.658,0.581
			c-1.491,0.34-2.993,0.636-4.463,1.02c-4.616,1.217-8.97,3.07-13.081,5.505c-1.129,0.669-2.281,1.327-3.344,2.094
			c-2.193,1.579-4.364,3.202-6.349,5.044c-2.303,2.149-4.551,4.342-6.513,6.798c-2.566,3.202-4.869,6.568-6.842,10.176
			c-3.586,6.535-6.031,13.443-7.062,20.834c-0.044,0.285-0.11,0.57-0.175,0.855c-0.318,1.513-0.45,3.037-0.461,4.572
			c-0.011,1.02,0.044,2.05-0.011,3.07c-0.143,2.73,0.362,5.395,0.976,8.027c1.097,4.616,3.136,8.783,6.206,12.435
			c2.763,3.29,5.976,5.998,9.847,7.895c1.305,0.647,2.621,1.305,3.98,1.842c1.36,0.526,2.752,0.987,4.167,1.338
			c1.557,0.384,3.147,0.603,4.726,0.877c0.362,0.066,0.724,0.121,1.086,0.121c2.193-0.044,4.375,0.164,6.579,0.044
			c1.897-0.099,3.794,0.022,5.658-0.461c1.469-0.044,2.873-0.493,4.298-0.757c1.864-0.351,3.673-0.954,5.494-1.48
			c5.428-1.579,10.559-3.86,15.505-6.579c4.485-2.478,8.695-5.373,12.698-8.586c0.143-0.11,0.329-0.154,0.515-0.241
			c0.033,0.208,0.11,0.373,0.077,0.482c-0.625,1.875-1.261,3.739-1.886,5.603c-0.482,1.447-0.987,2.895-1.404,4.375
			c-0.362,1.305-0.329,2.61-0.164,3.915c0.154,1.294,0.789,2.347,1.952,3.015c0.384,0.219,0.779,0.406,1.162,0.614
			c0.45,0.033,0.888,0.033,1.316,0.088c2.544,0.318,4.923-0.241,7.226-1.294c1.535-0.691,3.015-1.48,4.408-2.445
			c3.487-2.401,6.327-5.395,8.191-9.244c0.636-1.316,1.173-2.665,1.645-4.057c1.513-4.496,3.026-8.991,4.572-13.476
			c0.329-0.965,0.724-1.919,1.217-2.818c0.976-1.798,1.996-3.575,3.07-5.318c3.213-5.176,7.16-9.792,11.294-14.244
			c4.233-4.562,8.706-8.871,13.63-12.676c2.029-1.557,4.057-3.114,6.25-4.452c1.129-0.68,2.303-1.261,3.553-1.667
			c0.482-0.154,0.998-0.219,1.502-0.263c0.8-0.066,1.371,0.493,1.404,1.338c0.011,0.362,0.011,0.735-0.077,1.086
			c-0.164,0.636-0.362,1.272-0.614,1.875c-0.537,1.283-1.086,2.555-1.689,3.805c-1.491,3.092-3.015,6.173-4.507,9.266
			c-2.763,5.735-5.208,11.59-7.303,17.599c-1.491,4.276-2.785,8.608-3.64,13.06c-0.384,2.007-0.724,4.024-0.647,6.086
			c0.022,0.735,0.011,1.458,0.011,2.193c-0.011,2.368,0.504,4.649,1.382,6.831c1.469,3.629,3.98,6.272,7.676,7.676
			c1.776,0.669,3.597,1.184,5.526,1.217c2.489,0.044,4.956,0.077,7.391-0.592c2.116-0.581,4.222-1.162,6.184-2.16
			c1.568-0.789,3.147-1.535,4.704-2.358c1.097-0.581,2.182-1.184,3.213-1.875c4.397-2.917,8.619-6.042,12.5-9.616
			c0.702-0.647,1.393-1.294,2.105-1.919c0.154-0.132,0.373-0.186,0.603-0.296c0.066,0.252,0.154,0.461,0.164,0.658
			c0.022,0.515,0.011,1.02,0.011,1.535c0,2.818,0.614,5.515,1.689,8.103c0.888,2.127,2.259,3.915,4.134,5.296
			c1.601,1.184,3.388,2.051,5.296,2.555c0.976,0.263,1.963,0.57,3.004,0.57c1.02,0,2.05,0.11,3.07,0.088
			c1.239-0.033,2.5-0.033,3.706-0.252c2.73-0.515,5.351-1.458,7.873-2.61c4.342-1.974,8.421-4.397,12.215-7.27
			c3.608-2.741,7.127-5.603,10.428-8.706c0.121-0.121,0.362-0.11,0.57-0.154c0.296,0.735,0.57,1.414,0.844,2.094
			c1.14,2.807,2.719,5.351,4.715,7.61c4.101,4.638,9.2,7.588,15.318,8.75c0.855,0.164,1.711,0.373,2.577,0.461
			c2.621,0.263,5.252,0,7.884,0.022c1.031,0.011,2.018-0.285,3.037-0.406c1.678-0.197,3.322-0.526,4.923-0.987
			c3.651-1.053,7.226-2.325,10.636-4.035c7.533-3.783,14.134-8.794,19.748-15.066c1.075-1.195,2.094-2.423,3.191-3.695
			c0.461-0.088,0.954-0.208,1.458-0.252c2.478-0.252,4.912-0.746,7.314-1.36c1.338-0.34,2.686-0.735,3.969-1.239
			c4.649-1.798,9.101-3.98,13.268-6.722c4.222-2.774,8.235-5.812,11.996-9.189c3.432-3.081,6.689-6.327,9.704-9.814
			c0.241-0.274,0.461-0.57,0.943-0.636c0.68,0.23,1.436,0.504,2.204,0.724c1.678,0.482,3.355,1.02,5.055,1.36
			c1.787,0.351,3.542,0.822,5.362,1.02c0.614,0.066,1.261,0.23,1.82,0.537c-0.088,0.614-0.592,0.855-0.954,1.195
			c-3.081,2.928-5.965,6.02-8.542,9.397c-2.051,2.686-3.75,5.57-5.143,8.652c-1.184,2.61-1.919,5.373-2.27,8.202
			c-0.241,1.952-0.121,3.915,0.241,5.888c0.439,2.412,1.305,4.627,2.434,6.766c1.151,2.171,2.829,3.926,4.693,5.45
			c3.322,2.708,7.116,4.276,11.459,4.32c0.877,0.011,1.754,0.033,2.632,0c1.02-0.033,2.061-0.011,3.059-0.208
			c1.853-0.384,3.706-0.779,5.505-1.404c3.893-1.349,7.533-3.169,10.987-5.395c4.989-3.213,9.595-6.93,13.893-11.02
			c2.018-1.908,3.937-3.926,5.888-5.899c2.368-2.39,4.551-4.956,6.689-7.544c1.809-2.204,3.739-4.298,5.768-6.305
			c0.987-0.987,2.094-1.82,3.246-2.61c0.724-0.493,1.524-0.855,2.347-1.162c0.57-0.208,1.14-0.241,1.722-0.208
			c1.02,0.066,1.974,0.833,2.226,1.831c0.274,1.075,0.406,2.138,0.143,3.246c-0.219,0.921-0.417,1.842-0.702,2.752
			c-0.833,2.665-1.952,5.187-3.235,7.665c-2.621,5.022-5.812,9.627-9.649,13.794c-1.042,1.129-2.138,2.204-3.29,3.213
			c-2.314,2.018-4.66,3.991-7.259,5.647c-1.974,1.25-3.936,2.533-5.998,3.64c-2.774,1.48-5.603,2.84-8.498,4.068
			c-4.309,1.842-8.739,3.29-13.213,4.616c-4.901,1.458-9.902,2.555-14.869,3.717c-0.208,0.044-0.428,0.077-0.647,0.099
			c-2.029,0.208-3.991,0.888-6.053,0.943c-0.143,0-0.285,0.055-0.428,0.077c-1.711,0.351-3.41,0.8-5.165,0.822
			c-0.219,0-0.45,0.033-0.647,0.099c-0.702,0.263-1.447,0.197-2.16,0.296c-1.436,0.208-2.873,0.515-4.331,0.515
			c-0.208,0-0.428,0.11-0.636,0.154c-1.656,0.274-3.333,0.362-4.978,0.658c-0.428,0.077-0.877,0-1.316,0.011
			c-0.581,0.022-1.184-0.011-1.743,0.132c-1.086,0.274-2.171,0.263-3.268,0.296c-0.877,0.022-1.754,0.088-2.621,0.208
			c-1.239,0.164-2.467,0.285-3.717,0.263c-1.173-0.011-2.336,0.066-3.498,0.241c-0.932,0.143-1.897,0.154-2.84,0.186
			c-0.8,0.033-1.612,0.011-2.412,0.055c-1.097,0.055-2.193,0.143-3.279,0.208c-5.406,0.307-10.812,0.208-16.217,0.285
			c-2.851,0.044-5.702-0.022-8.553-0.044c-0.362,0-0.735,0-1.097,0.011c-2.04,0.022-4.09,0.044-6.141,0.044
			c-4.09,0.011-8.18,0-12.27,0c-2.632,0-5.263,0-7.895,0c-2.259-0.011-4.529-0.033-6.798-0.033c-2.917,0-5.844,0.033-8.772,0.044
			c-2.555,0-5.11-0.022-7.665-0.022c-4.167,0-8.334,0.011-12.489,0.011c-2.259,0-4.529-0.033-6.798-0.033
			c-4.167,0-8.334,0.033-12.489,0.033c-4.529-0.011-9.057-0.044-13.586-0.055c-1.316,0-2.632,0.066-3.947,0.066
			c-3.575,0-7.16-0.011-10.735-0.022c-1.754,0-3.509,0.011-5.263,0.011c-2.632,0-5.263-0.011-7.884-0.011
			c-1.974,0-3.947,0.011-5.921,0c-2.993-0.011-5.987-0.055-8.991-0.044c-5.121,0.011-10.231,0.044-15.351,0.066
			c-1.173,0-2.336-0.055-3.509-0.055c-2.774,0-5.548,0.033-8.323,0.033c-4.671,0-9.353-0.033-14.024-0.033
			c-5.548,0-11.108,0.022-16.656,0.033c-5.844,0-11.689,0-17.533,0c-5.187,0-10.373,0.011-15.56,0c-1.535,0-3.07-0.044-4.605-0.044
			c-4.682,0.011-9.353,0.044-14.024,0.044c-4.605,0-9.2-0.044-13.805-0.011c-1.678,0.011-3.355-0.022-5.022,0.307
			c-1.195,0.241-2.215,0.768-2.972,1.711c-1.217,1.502-1.261,3.224-0.252,4.89c0.143,0.241,0.384,0.45,0.614,0.614
			c0.241,0.164,0.526,0.252,0.789,0.373c1.667,0.735,3.366,1.414,5.011,2.215c4.66,2.281,9.32,4.551,13.805,7.171
			c3.158,1.842,6.206,3.86,9.156,6.009c0.768,0.559,1.502,1.151,2.259,1.743c1.447,1.118,2.719,2.423,4.057,3.684
			c1.447,1.36,2.741,2.84,3.882,4.463c1.393,1.985,2.511,4.123,2.741,6.601c0.033,0.285,0.11,0.57,0.143,0.735
			c-0.077,1.469-0.57,2.675-1.184,3.849c-0.34,0.647-0.691,1.294-1.02,1.941c-0.197,0.395-0.406,0.779-0.526,1.195
			c-0.406,1.36-0.493,2.73,0.055,4.079c0.197,0.493,0.526,0.866,0.987,1.14c0.833,0.482,1.722,0.779,2.697,0.779
			c2.774,0.011,5.559,0.055,8.334,0.055c3.805,0,7.599-0.044,11.393-0.044c13.158,0.011,26.305,0.033,39.464,0.044
			c2.116,0,4.244-0.055,6.36-0.044c7.16,0.011,14.321,0.044,21.492,0.044c4.901,0,9.792-0.044,14.693-0.044
			c9.792,0.011,19.584,0.033,29.387,0.044c1.754,0,3.509-0.055,5.263-0.055c5.187,0.011,10.373,0.066,15.571,0.044
			c5.406-0.033,10.812-0.055,16.217-0.186c0.954-0.022,1.897-0.077,2.851-0.11c1.535-0.055,3.07-0.099,4.594-0.164
			c1.524-0.066,3.07-0.077,4.594-0.252c1.02-0.11,2.04-0.164,3.059-0.197c1.382-0.044,2.785-0.044,4.156-0.23
			c1.458-0.197,2.906-0.252,4.364-0.241c0.581,0.011,1.173-0.033,1.733-0.164c0.789-0.197,1.579-0.263,2.39-0.296
			c0.724-0.022,1.469-0.011,2.182-0.154c1.162-0.23,2.325-0.219,3.487-0.263c0.581-0.022,1.184,0,1.743-0.143
			c0.789-0.197,1.59-0.219,2.379-0.318c1.733-0.208,3.454-0.581,5.208-0.592c0.702-0.23,1.436-0.23,2.16-0.296
			c0.647-0.066,1.294-0.208,1.941-0.307c0.362-0.055,0.724-0.154,1.086-0.154c1.469,0.022,2.873-0.548,4.342-0.526
			c1.612-0.472,3.311-0.493,4.945-0.822c1.217-0.252,2.445-0.406,3.662-0.592c1.656-0.252,3.301-0.603,4.945-0.888
			c3.662-0.647,7.314-1.382,10.965-2.094c1.075-0.208,2.149-0.428,3.213-0.68c2.27-0.537,4.54-1.107,6.809-1.656
			c1.491-0.362,2.983-0.691,4.463-1.086c3.18-0.833,6.349-1.656,9.507-2.533c2.599-0.724,5.165-1.524,7.741-2.336
			c3.618-1.129,7.259-2.215,10.823-3.542c6.02-2.237,12.084-4.386,17.939-7.04c3.454-1.568,6.974-3.015,10.351-4.748
			c2.599-1.338,5.252-2.577,7.807-3.98c5.384-2.95,10.658-6.097,15.757-9.518c2.061-1.382,4.156-2.719,6.162-4.2
			c5.472-4.035,10.702-8.377,15.582-13.125c2.303-2.237,4.638-4.463,6.7-6.941c1.535-1.853,3.103-3.695,4.627-5.559
			c2.643-3.235,4.989-6.678,7.138-10.241c2.039-3.377,3.925-6.842,5.548-10.45c1.414-3.136,2.664-6.305,3.311-9.715
			C595.059,424.509,595.443,422.295,595.366,420.014L595.366,420.014z M439.189,408.939c-0.044,0.943-0.011,1.897-0.285,2.818
			c-0.121,0.417-0.121,0.866-0.132,1.305c-0.022,0.877-0.11,1.733-0.329,2.599c-0.175,0.702-0.175,1.447-0.252,2.171
			c-0.033,0.252-0.066,0.307-0.581,0.921c-1.338-0.274-2.61-0.811-3.882-1.349c-3.75-1.557-7.062-3.794-9.967-6.612
			c-2.39-2.325-4.211-5.022-5.34-8.18c-0.943-2.643-1.393-5.373-1.129-8.158c0.23-2.5,1.02-4.858,2.478-6.93
			c1.272-1.798,2.993-2.961,5.165-3.41c0.362-0.077,0.713-0.154,1.075-0.23c1.897-0.384,3.629,0.044,5.23,1.053
			c1.305,0.822,2.423,1.842,3.268,3.169c1.031,1.612,1.886,3.279,2.467,5.099c0.603,1.875,1.173,3.75,1.393,5.724
			c0.077,0.647,0.329,1.283,0.362,1.93C438.882,403.555,439.32,406.23,439.189,408.939L439.189,408.939z M499.059,371.164
			c-0.175,1.886-0.461,3.772-0.79,5.636c-0.34,1.864-0.789,3.717-1.502,5.483c-0.11,0.263-0.197,0.548-0.735,0.735
			c-0.296-0.263-0.581-0.482-0.833-0.735c-1.009-1.053-1.919-2.204-2.774-3.388c-1.382-1.93-2.303-4.079-2.796-6.382
			c-0.691-3.213-0.219-6.25,1.7-8.991c0.888-1.283,1.963-2.358,3.465-2.917c0.669-0.252,1.305-0.143,1.864,0.274
			c0.713,0.537,1.25,1.206,1.568,2.061c0.493,1.316,0.691,2.697,0.844,4.068C499.212,368.368,499.179,369.782,499.059,371.164
			L499.059,371.164z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_4_1_" enable-background="new    ">
        <g id="Shape_1_copy_4">
          <path
            fill="#FFFFFF"
            d="M389.527,510.948c-0.274,0.307-0.493,0.614-0.768,0.833c-0.219,0.175-0.504,0.329-0.779,0.351
			c-0.8,0.066-1.601,0.088-2.412,0.066c-0.899-0.022-1.842-0.899-1.963-1.776c-0.033-0.219-0.044-0.439-0.066-0.658
			c-0.197-2.917-0.384-5.833-0.581-8.75c-0.066-0.943-0.132-1.897-0.197-2.895c0.154-0.208,0.307-0.45,0.482-0.68
			c0.285-0.362,0.669-0.57,1.129-0.581c0.8-0.011,1.612-0.011,2.412,0c0.384,0,0.713,0.154,0.987,0.406
			c0.263,0.241,0.504,0.515,0.757,0.789c0.329,3.958,0.658,7.884,0.976,11.81C389.549,510.224,389.527,510.597,389.527,510.948
			L389.527,510.948z M279.678,507.922c-1.93,0.132-2.566,0.088-3.465-0.23c0.559-2.862,1.415-5.658,2.051-8.498
			c0.11-0.493,0.285-0.976,0.526-1.776c0.417,0.888,0.472,1.546,0.559,2.193c0.351,2.675,0.658,5.351,0.976,8.048
			C280.062,507.768,279.875,507.911,279.678,507.922L279.678,507.922z M336.149,511.485c-0.263,0.625-0.625,0.965-1.173,1.294
			c-1.053,0.164-2.204,0.154-3.432,0.055c-0.34-0.318-0.713-0.647-1.053-0.954c-0.055-0.274-0.132-0.482-0.132-0.702
			c-0.022-1.743-0.022-3.498-0.033-5.241c-0.011-1.963-0.011-3.926-0.011-5.899c0-0.581,0.022-1.162,0.044-1.787
			c0.329-0.362,0.658-0.735,1.009-1.118c1.173-0.219,2.325-0.143,3.564-0.066c0.406,0.45,0.702,0.768,1.02,1.129
			c0.022,0.077,0.099,0.208,0.099,0.351c0.033,2.04,0.066,4.079,0.088,6.108c0.022,1.82,0.044,3.64,0.044,5.461
			C336.182,510.608,336.16,511.113,336.149,511.485L336.149,511.485z M421.019,510.948c-0.274,0.307-0.493,0.614-0.768,0.833
			c-0.219,0.175-0.504,0.329-0.779,0.351c-0.8,0.066-1.601,0.088-2.401,0.066c-0.899-0.022-1.842-0.899-1.963-1.776
			c-0.033-0.219-0.044-0.439-0.066-0.658c-0.197-2.917-0.384-5.833-0.581-8.75c-0.066-0.943-0.132-1.897-0.197-2.895
			c0.154-0.208,0.307-0.45,0.482-0.68c0.285-0.362,0.669-0.57,1.129-0.581c0.8-0.011,1.612-0.011,2.412,0
			c0.384,0,0.713,0.154,0.987,0.406c0.263,0.241,0.504,0.515,0.768,0.789c0.329,3.958,0.658,7.884,0.976,11.81
			C421.052,510.224,421.019,510.597,421.019,510.948L421.019,510.948z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_3_1_" enable-background="new    ">
        <g id="Shape_1_copy_3">
          <path
            d="M268.559,514.106c0.033,0.504,0.033,1.02,0.022,1.524c-0.011,0.285-0.033,0.581-0.45,0.921
			c-1.809,0.143-7.763,0.208-8.772,0.099c-0.066-0.011-0.121-0.066-0.175-0.11c-0.055-0.044-0.11-0.099-0.23-0.208
			c0.57-7.138,1.206-14.332,1.809-21.393c0.263-0.395,0.526-0.504,0.833-0.504c0.581-0.011,1.173-0.022,1.754,0.011
			c0.197,0.011,0.384,0.164,0.636,0.274c-0.186,3.136-0.472,6.261-0.724,9.386c-0.241,3.059-0.482,6.119-0.724,9.112
			c0.153,0.373,0.417,0.482,0.724,0.482c1.393,0,2.774-0.022,4.167-0.011c0.285,0,0.581,0.044,0.855,0.121
			C268.395,513.854,268.559,513.996,268.559,514.106z M254.052,494.566c-1.656-0.175-4.54-0.175-6.48-0.022
			c-1.064,0.8-2.018,1.798-2.983,2.829c-0.208,1.897-0.417,3.772-0.614,5.658c-0.186,1.82-0.318,3.64-0.548,5.45
			c-0.241,1.886-0.417,3.761-0.537,5.68c0.658,0.976,1.458,1.82,2.336,2.708c1.261,0.197,2.347,0.011,3.443-0.011
			c1.162-0.022,2.336,0.088,3.52-0.208c0.877-0.855,1.754-1.721,2.719-2.654c-0.022-1.283,0.318-2.566,0.307-3.794
			c-0.23-0.351-0.493-0.439-0.8-0.439c-2.533,0.011-2.467-0.263-2.621,2.237c-0.011,0.143-0.033,0.296-0.066,0.428
			c-0.033,0.143-0.088,0.274-0.186,0.537c-0.329,0.307-0.746,0.702-1.173,1.086c-1.064,0.044-2.072,0.143-3.136-0.055
			c-0.318-0.395-0.636-0.789-0.943-1.173c0.033-0.34,0.044-0.625,0.077-0.91c0.208-2.029,0.428-4.068,0.625-6.097
			s0.384-4.068,0.581-6.108c0.022-0.219,0.055-0.439,0.099-0.647c0.066-0.373,0.23-0.691,0.504-0.943
			c0.263-0.252,0.537-0.482,0.8-0.735c1.053-0.11,2.061-0.164,2.939,0c0.57,0.373,0.888,0.757,1.097,1.305
			c-0.066,0.735-0.121,1.535-0.186,2.303c0.779,0.461,0.921,0.471,3.059,0.23c0.493-1.294,0.351-2.676,0.428-4.068
			c-0.428-0.526-0.822-1.053-1.25-1.546C254.721,495.246,254.359,494.895,254.052,494.566z M284.645,515.378
			c0.033,0.208,0,0.428,0,0.614c-0.164,0.11-0.274,0.252-0.395,0.263c-0.866,0.088-1.733,0.197-2.643-0.077
			c-0.57-1.667-0.395-3.465-0.965-5.187c-0.154-0.066-0.351-0.23-0.548-0.23c-1.316-0.011-2.621,0-3.936,0.033
			c-0.274,0.011-0.537,0.164-0.844,0.263c-0.362,1.382-0.702,2.719-1.053,4.046c-0.077,0.285-0.121,0.57-0.23,0.844
			c-0.11,0.285-0.329,0.493-0.636,0.537c-0.724,0.099-1.447,0.175-2.16-0.033c-0.066-0.022-0.121-0.088-0.34-0.252
			c1.908-7.062,3.794-14.035,5.68-20.998c0.055-0.208,0.197-0.384,0.285-0.57c1.151-0.318,2.127-0.34,4.364-0.121
			c0.099,0.154,0.274,0.329,0.307,0.526c0.197,1.151,0.373,2.303,0.548,3.454c0.548,3.608,1.086,7.215,1.634,10.823
			C284.031,511.343,284.349,513.36,284.645,515.378z M280.314,507.659c-0.318-2.697-0.625-5.373-0.976-8.048
			c-0.088-0.647-0.132-1.305-0.559-2.193c-0.241,0.8-0.417,1.283-0.526,1.776c-0.636,2.851-1.491,5.636-2.05,8.498
			c0.899,0.318,1.535,0.362,3.465,0.23C279.875,507.911,280.062,507.768,280.314,507.659z M407.719,512.483
			c-0.384-0.285-0.833-0.197-1.25-0.197c-1.974,0-3.947,0.022-5.921,0.033c-0.285,0-0.581,0.011-0.899-0.362
			c-0.022-0.154-0.088-0.428-0.11-0.713c-0.066-0.877-0.077-1.754-0.186-2.621c-0.23-1.754,0.658-2.116,1.996-2.083
			c0.943,0.022,1.897,0,2.851-0.033c0.428-0.011,0.855-0.088,1.228-0.132c0.768-0.691,1.447-1.349,2.061-2.182
			c0-2.511-0.23-5.044-0.526-7.511c-0.921-0.866-1.711-1.733-2.752-2.401c-2.083,0.033-4.189-0.055-6.272,0.066
			c-0.417,0.406-0.8,0.757-1.151,1.129c-0.395,0.428-0.866,0.789-1.151,1.425c-0.077,0.789-0.022,1.656,0.066,2.533
			c0.022,0.197,0.175,0.373,0.263,0.559c0.833,0.154,1.645,0.121,2.445,0.055c0.132-0.011,0.263-0.11,0.395-0.175
			c0.252-0.482,0.033-0.998,0.066-1.491c0.055-0.779,0.625-1.414,1.371-1.447c0.581-0.033,1.173,0,1.754-0.033
			c0.822-0.044,1.404,0.318,1.93,1.206c0.066,1.393,0.175,2.994,0.164,4.594c0,0.45-0.504,0.976-0.976,1.053
			c-0.285,0.044-0.581,0.077-0.866,0.088c-0.735,0.022-1.458,0.011-2.193,0.033c-0.647,0.011-1.305,0.055-1.941,0.077
			c-0.779,0.636-1.436,1.305-1.996,1.996c-0.044,0.307-0.099,0.526-0.088,0.735c0.066,2.116,0.132,4.233,0.219,6.349
			c0.022,0.439,0.066,0.877,0.143,1.305c0.033,0.197,0.164,0.373,0.23,0.493c0.406,0.241,0.779,0.186,1.14,0.186
			c2.917-0.022,5.844-0.055,8.761-0.088c0.362,0,0.724-0.033,1.086-0.088c0.121-0.022,0.219-0.164,0.34-0.263
			c0.175-0.57,0.099-1.151-0.011-1.732C407.905,512.724,407.784,512.615,407.719,512.483z M376.216,512.483
			c-0.384-0.285-0.833-0.197-1.25-0.197c-1.974,0-3.947,0.022-5.921,0.033c-0.285,0-0.581,0.011-0.899-0.362
			c-0.022-0.154-0.088-0.428-0.11-0.713c-0.066-0.877-0.077-1.754-0.186-2.621c-0.23-1.754,0.658-2.116,1.996-2.083
			c0.943,0.022,1.897,0,2.851-0.033c0.428-0.011,0.855-0.088,1.228-0.132c0.768-0.691,1.447-1.349,2.061-2.182
			c0-2.511-0.23-5.044-0.526-7.511c-0.921-0.866-1.711-1.733-2.752-2.401c-2.083,0.033-4.189-0.055-6.272,0.066
			c-0.417,0.406-0.8,0.757-1.151,1.129c-0.395,0.428-0.866,0.789-1.151,1.425c-0.077,0.789-0.022,1.656,0.066,2.533
			c0.022,0.197,0.175,0.373,0.263,0.559c0.833,0.154,1.645,0.121,2.445,0.055c0.132-0.011,0.263-0.11,0.395-0.175
			c0.252-0.482,0.033-0.998,0.066-1.491c0.055-0.779,0.625-1.414,1.371-1.447c0.581-0.033,1.173,0,1.754-0.033
			c0.822-0.044,1.404,0.318,1.93,1.206c0.066,1.393,0.175,2.994,0.164,4.594c0,0.45-0.504,0.976-0.976,1.053
			c-0.285,0.044-0.581,0.077-0.866,0.088c-0.735,0.022-1.458,0.011-2.193,0.033c-0.647,0.011-1.305,0.055-1.941,0.077
			c-0.779,0.636-1.436,1.305-1.996,1.996c-0.044,0.307-0.099,0.526-0.088,0.735c0.066,2.116,0.132,4.233,0.219,6.349
			c0.022,0.439,0.066,0.877,0.154,1.305c0.033,0.197,0.164,0.373,0.23,0.493c0.406,0.241,0.779,0.186,1.14,0.186
			c2.917-0.022,5.844-0.055,8.761-0.088c0.362,0,0.724-0.033,1.086-0.088c0.121-0.022,0.219-0.164,0.34-0.263
			c0.175-0.57,0.099-1.151-0.011-1.732C376.402,512.724,376.292,512.615,376.216,512.483z M392.598,506.946
			c0.132,1.667,0.252,3.333,0.373,4.967c-0.154,0.23-0.252,0.428-0.395,0.581c-0.647,0.68-1.25,1.425-2.072,2.05
			c-0.526,0.033-1.107,0.11-1.678,0.099c-1.82-0.022-3.629,0.263-5.614,0.055c-0.943-0.844-1.82-1.623-2.697-2.401
			c-0.285-1.305-0.208-2.632-0.34-3.926c-0.143-1.305-0.175-2.621-0.274-3.926c-0.088-1.239-0.219-2.467-0.285-3.706
			c-0.077-1.305-0.208-2.61-0.121-3.947c0.724-0.844,1.469-1.634,2.292-2.379c1.14-0.23,2.314-0.099,3.476-0.121
			c1.162-0.022,2.325,0,3.487,0c1.064,0.724,1.93,1.601,2.884,2.478c0.241,1.623,0.362,3.301,0.526,4.956
			C392.323,503.459,392.466,505.202,392.598,506.946z M389.516,509.873c-0.318-3.926-0.647-7.862-0.976-11.81
			c-0.263-0.274-0.493-0.548-0.757-0.789c-0.274-0.252-0.614-0.406-0.987-0.406c-0.8-0.011-1.612-0.011-2.412,0
			c-0.461,0.011-0.844,0.219-1.129,0.581c-0.175,0.23-0.329,0.472-0.482,0.68c0.066,0.998,0.132,1.941,0.197,2.895
			c0.197,2.917,0.384,5.833,0.581,8.75c0.011,0.219,0.033,0.439,0.066,0.658c0.121,0.877,1.064,1.765,1.963,1.776
			c0.8,0.011,1.612-0.011,2.412-0.066c0.274-0.022,0.559-0.175,0.779-0.351c0.285-0.219,0.493-0.526,0.768-0.833
			C389.527,510.597,389.549,510.224,389.516,509.873z M424.101,506.946c0.132,1.667,0.252,3.333,0.373,4.967
			c-0.154,0.23-0.252,0.428-0.395,0.581c-0.647,0.68-1.25,1.425-2.072,2.05c-0.526,0.033-1.107,0.11-1.678,0.099
			c-1.82-0.022-3.629,0.263-5.614,0.055c-0.943-0.844-1.82-1.623-2.697-2.401c-0.285-1.305-0.208-2.632-0.34-3.926
			c-0.143-1.305-0.175-2.621-0.274-3.926c-0.088-1.239-0.219-2.467-0.285-3.706c-0.077-1.305-0.208-2.61-0.121-3.947
			c0.724-0.844,1.469-1.634,2.292-2.379c1.14-0.23,2.314-0.099,3.476-0.121c1.162-0.022,2.325,0,3.498,0
			c1.064,0.724,1.93,1.601,2.884,2.478c0.241,1.623,0.362,3.301,0.526,4.956C423.826,503.459,423.958,505.202,424.101,506.946z
			 M297.76,494.413c-1.82-0.066-3.64-0.033-5.461-0.022c-0.428,0-0.855,0.077-1.239,0.121c-0.921,0.932-1.897,1.7-2.643,2.796
			c-0.055,1.129-0.132,2.292-0.143,3.465c-0.022,1.239-0.208,2.467-0.022,3.717c0.592,0.757,1.217,1.458,1.919,2.072
			c0.833,0.175,1.634,0.077,2.434,0.088c0.943,0.011,1.897,0,2.84,0.022c0.603,0.011,1.14,0.526,1.151,1.107
			c0.033,1.524,0.044,3.059-0.143,4.452c-0.614,1.02-1.393,1.414-2.456,1.338c-0.647-0.044-1.305-0.033-1.952-0.044
			c-0.428-0.351-0.855-0.636-1.086-1.25c-0.022-0.548-0.055-1.195-0.088-1.798c-0.11-0.088-0.154-0.154-0.219-0.175
			c-0.625-0.285-1.283-0.121-1.93-0.143c-0.285-0.011-0.581,0.011-0.822,0.23c-0.23,0.296-0.208,0.669-0.219,1.031
			c-0.022,0.724-0.263,1.447-0.044,2.27c0.724,0.888,1.513,1.831,2.566,2.588c2.248-0.066,4.507,0.011,6.678-0.143
			c1.48-1.184,1.952-1.656,2.884-2.939c0.088-1.941,0.186-3.98,0.263-6.02c0.011-0.428-0.055-0.866-0.088-1.25
			c-0.395-0.428-0.724-0.822-1.086-1.184c-0.351-0.362-0.746-0.68-1.097-0.998c-1.382-0.088-2.686-0.011-4.002-0.022
			c-0.439,0-0.877-0.022-1.305-0.066c-0.439-0.044-0.943-0.603-0.987-1.053c-0.011-0.143-0.033-0.296-0.022-0.439
			c0.088-1.305,0.197-2.61,0.285-3.915c0.219-0.241,0.395-0.482,0.603-0.669c0.208-0.186,0.461-0.34,0.702-0.504
			c0.965,0.011,1.908-0.077,2.917,0.066c0.329,0.362,0.669,0.724,0.998,1.075c0.011,0.57,0.011,1.086,0.055,1.59
			c0.011,0.197,0.132,0.384,0.23,0.647c0.811,0.088,1.601,0.11,2.39,0.011c0.121-0.022,0.241-0.143,0.439-0.263
			c0.197-0.943,0.132-1.952,0.164-3.103C299.481,496.035,298.681,495.191,297.76,494.413z M308.484,497.066
			c1.075-0.055,2.083-0.143,3.147,0.044c0.285,0.307,0.592,0.614,0.899,0.943c0.088,0.724,0.164,1.447,0.241,2.105
			c0.208,0.11,0.329,0.23,0.461,0.23c0.658,0.022,1.316,0.033,1.963,0c0.186-0.011,0.373-0.175,0.57-0.274
			c0.175-1.042,0.121-2.061,0.055-3.125c-0.757-0.954-1.557-1.875-2.577-2.632c-2.204,0.033-4.386-0.088-6.623,0.077
			c-0.855,0.899-1.82,1.678-2.544,2.697c-0.022,0.384-0.055,0.746-0.055,1.107c-0.033,1.897-0.077,3.794-0.099,5.691
			c0,0.143,0.077,0.274,0.143,0.493c0.625,0.702,1.228,1.535,2.018,2.007c0.274,0.044,0.417,0.077,0.559,0.077
			c0.877,0,1.754-0.011,2.632-0.011c0.515,0,1.02,0,1.535-0.011c1.096-0.011,1.656,0.526,1.634,1.579
			c-0.033,1.239-0.044,2.478-0.077,3.717c0,0.143-0.088,0.274-0.164,0.515c-0.307,0.263-0.691,0.592-1.075,0.932
			c-0.91,0-1.787-0.011-2.665,0c-0.57,0.011-0.954-0.307-1.294-0.702c-0.099-0.11-0.154-0.241-0.296-0.461
			c-0.022-0.406-0.044-0.91-0.066-1.415c-0.011-0.318-0.164-0.548-0.493-0.735c-0.8,0.022-1.667-0.077-2.423,0.164
			c-0.34,0.395-0.34,0.768-0.329,1.129c0.011,0.789-0.186,1.601,0.143,2.412c0.746,0.768,1.436,1.623,2.237,2.204
			c1.985,0.132,5.461,0.077,7.193-0.11c0.866-0.789,1.776-1.623,2.5-2.555c0.186-1.886,0.23-5.548,0.088-7.325
			c-0.592-0.789-1.316-1.502-2.138-2.215c-0.537-0.022-1.118-0.044-1.7-0.055c-1.097-0.011-2.193,0-3.29,0
			c-0.658,0-1.097-0.318-1.404-0.998c-0.011-1.436-0.121-2.961,0.186-4.496C307.76,497.746,308.133,497.406,308.484,497.066z
			 M339.373,497.932c0.066,4.737,0.132,9.485,0.186,14.222c0,0.208-0.055,0.428-0.088,0.702c-0.691,0.899-1.579,1.645-2.379,2.5
			c-1.25,0.274-2.5,0.143-3.728,0.186c-1.162,0.033-2.325,0.011-3.509,0.011c-0.636-0.219-1.02-0.789-1.502-1.228
			c-0.428-0.395-0.822-0.833-1.261-1.283c-0.044-0.34-0.121-0.691-0.121-1.053c0-4.671,0-9.331,0.011-14.003
			c0-0.285,0.044-0.581,0.077-0.866c0.779-0.987,1.667-1.842,2.544-2.632c1.754-0.164,5.208-0.175,7.062-0.022
			c0.515,0.274,0.866,0.746,1.283,1.14c0.417,0.406,0.811,0.833,1.305,1.349C339.307,497.351,339.373,497.636,339.373,497.932z
			 M336.182,510.093c-0.011-1.82-0.022-3.64-0.044-5.461c-0.022-2.04-0.055-4.079-0.088-6.108c0-0.143-0.077-0.274-0.099-0.351
			c-0.329-0.362-0.614-0.68-1.02-1.129c-1.239-0.066-2.401-0.154-3.564,0.066c-0.34,0.384-0.68,0.757-1.009,1.118
			c-0.011,0.636-0.044,1.206-0.044,1.787c0,1.963,0.011,3.926,0.011,5.899c0.011,1.743,0.011,3.498,0.033,5.241
			c0,0.208,0.077,0.428,0.132,0.702c0.34,0.307,0.713,0.647,1.053,0.954c1.228,0.099,2.379,0.121,3.432-0.055
			c0.548-0.329,0.91-0.669,1.173-1.294C336.16,511.113,336.182,510.608,336.182,510.093z M353.561,494.456
			c-0.285-0.044-0.559-0.11-0.855-0.121c-3.355-0.099-6.941-0.033-8.597,0.154c-0.329,0.614-0.23,1.129-0.219,1.623
			c0.055,4.298,0.121,8.608,0.197,12.906c0.033,1.82,0.132,3.651,0.197,5.472c0.011,0.285,0.033,0.581,0.318,0.768
			c1.743,0.197,1.743,0.197,2.785-0.153c0.164-0.384,0.143-0.811,0.132-1.25c-0.033-1.897-0.066-3.794-0.088-5.691
			c-0.011-0.504,0.044-1.009,0.066-1.568c0.318-0.088,0.581-0.23,0.855-0.23c1.458-0.044,2.917-0.055,4.375-0.088
			c0.296-0.011,0.57-0.088,0.789-0.417c0-0.581,0-1.228,0-1.776c-0.252-0.351-0.515-0.45-0.811-0.45c-1.458,0-2.917,0-4.375,0
			c-0.362,0-0.724-0.044-0.954-0.504c-0.121-1.733-0.263-3.553-0.23-5.373c0.011-0.406,0.285-0.713,0.658-0.724
			c0.581-0.022,1.162-0.022,1.743-0.033c1.162-0.011,2.336-0.011,3.498-0.022c0.307,0,0.57-0.099,0.8-0.428
			c-0.022-0.439-0.033-0.943-0.088-1.447C353.759,494.906,353.649,494.709,353.561,494.456z"
          />
        </g>
      </g>
      <g id="senior_1_">
        <g id="Shape_1_copy_2">
          <path
            fill="#FFFFFF"
            d="M349.636,338.915c-0.208-2.27,0.428-4.265,1.404-6.184c0.757-1.502,1.623-2.939,2.763-4.189
			c2.5-2.73,5.417-4.879,8.959-6.086c1.096-0.373,2.237-0.658,3.355-0.987c1.601-0.022,3.213-0.044,4.814-0.077
			c3.279,0.471,6.184,1.678,8.345,4.309c0.559,0.68,0.998,1.436,1.327,2.248c1.228,3.026,1.327,6.097,0.34,9.211
			c-0.855,2.697-2.303,5.044-4.211,7.105c-2.643,2.84-5.833,4.792-9.573,5.899c-2.149,0.636-4.309,0.658-6.502,0.592
			c-4.496-0.757-8.114-2.763-10.187-7.04c-0.548-1.129-0.921-2.303-0.855-3.575C349.647,339.705,349.636,339.266,349.636,338.915z"
          />
        </g>
        <g id="Shape_1_copy">
          <path
            fill={secondary}
            d="M595.245,419.937c0-0.296,0-0.581,0-0.877c0.033-2.149-0.34-4.233-1.009-6.25
			c-0.658-1.974-1.754-3.739-3.388-5.033c-2.577-2.04-5.494-3.311-8.86-3.311c-1.02,0-2.051-0.011-3.07,0
			c-0.439,0.011-0.888-0.022-1.316,0.066c-2.434,0.493-4.836,1.107-7.073,2.259c-4.452,2.292-8.432,5.252-12.051,8.695
			c-1.798,1.711-3.509,3.52-5.011,5.505c-0.23,0.285-0.471,0.559-0.713,0.833c-3.487,4.046-7.215,7.862-11.217,11.404
			c-1.798,1.601-3.695,3.103-5.603,4.572c-1.447,1.107-2.983,2.105-4.496,3.125c-2.741,1.853-5.68,3.301-8.816,4.342
			c-1.524,0.504-3.081,0.943-4.715,0.757c-1.776-0.208-3.191-0.976-3.947-2.708c-0.932-2.149-1.118-4.408-0.68-6.656
			c0.735-3.75,2.116-7.281,4.134-10.548c0.998-1.612,2.039-3.213,3.147-4.748c3.257-4.507,6.875-8.717,10.834-12.632
			c1.349-1.338,2.675-2.697,4.035-4.024c1.535-1.502,2.336-3.322,2.379-5.472c0.011-0.658,0-1.327-0.088-1.974
			c-0.384-3.037-1.875-5.351-4.616-6.755c-1.371-0.713-2.862-1.075-4.419-1.097c-1.535-0.022-3.07,0-4.605-0.011
			s-3.07,0.088-4.594-0.164c-1.239-0.208-2.478-0.252-3.717-0.252c-1.316,0-2.632,0.011-3.915-0.351
			c-0.417-0.11-0.866-0.143-1.305-0.132c-1.195,0.022-2.303-0.351-3.399-0.559c-0.077-0.197-0.197-0.351-0.164-0.45
			c0.175-0.482,0.373-0.954,0.592-1.415c1.25-2.577,2.336-5.208,3.169-7.939c0.899-2.939,1.732-5.888,2.149-8.937
			c0.186-1.305,0.241-2.61,0.274-3.926c0.022-0.877,0.45-1.7,0.318-2.599c-0.132-0.943-0.263-1.875-0.395-2.818
			c-0.011-0.077-0.011-0.143-0.022-0.219c-0.077-0.362-0.132-0.724-0.219-1.075c-0.899-3.399-2.84-6.053-5.757-7.994
			c-0.669-0.45-1.371-0.822-2.149-1.075c-1.393-0.45-2.774-0.833-4.265-0.866c-1.535-0.033-3.092-0.132-4.583,0.164
			c-4.057,0.8-7.785,2.445-11.184,4.814c-0.548,0.384-1.064,0.79-1.579,1.195c-2.138,1.678-4.09,3.553-5.779,5.669
			c-1.678,2.116-3.125,4.408-4.287,6.864c-0.746,1.59-1.349,3.235-1.864,4.912c-0.647,2.094-1.075,4.254-1.195,6.437
			c-0.143,2.478-0.318,4.978,0.373,7.412c0.066,1.107,0.439,2.138,0.79,3.169c1.118,3.322,2.939,6.195,5.384,8.684
			c1.645,1.678,3.432,3.169,5.362,4.507c0.537,0.373,1.162,0.636,1.535,1.184c0.044,0.439-0.241,0.702-0.493,0.943
			c-1.415,1.371-2.84,2.741-4.276,4.09c-2.774,2.621-5.921,4.759-9.167,6.733c-3.191,1.93-6.656,3.333-10.23,4.419
			c-1.25,0.384-2.5,0.833-3.838,0.789c-0.175-0.011-0.34-0.154-0.537-0.252c0.186-0.472,0.34-0.877,0.504-1.283
			c0.636-1.634,1.228-3.279,1.678-4.978c0.406-1.557,0.757-3.125,1.053-4.704c0.307-1.579,0.57-3.158,0.548-4.77
			c0-0.154,0.011-0.296,0.055-0.439c0.23-0.779,0.175-1.59,0.186-2.39c0.011-1.458-0.077-2.906-0.208-4.364
			c-0.132-1.535-0.296-3.037-0.592-4.551c-0.636-3.257-1.656-6.371-3.136-9.32c-1.985-3.958-4.66-7.412-8.114-10.22
			c-0.91-0.735-1.897-1.371-2.884-1.996c-2.752-1.754-5.757-2.785-9.013-3.059c-0.362-0.033-0.724-0.143-1.086-0.175
			c-0.581-0.055-1.173-0.154-1.743-0.099c-1.371,0.132-2.752,0.318-4.123,0.515c-0.724,0.11-1.436,0.23-2.138,0.428
			c-2.851,0.8-5.45,2.116-7.818,3.86c-3.29,2.412-5.844,5.483-7.895,9.002c-1.524,2.61-2.708,5.362-3.531,8.246
			c-0.779,2.73-1.59,5.472-1.634,8.355c-0.022,0.866-0.241,1.733-0.285,2.61c-0.186,3.52,0.197,6.996,1.096,10.406
			c0.395,1.48,0.822,2.961,1.436,4.364c2.665,6.064,6.766,10.888,12.325,14.474c2.829,1.82,5.801,3.377,9.024,4.419
			c0.482,0.164,0.976,0.318,1.436,0.526c0.164,0.077,0.263,0.296,0.406,0.471c-2.84,3.969-6.349,7.127-10.559,9.463
			c-2.577,1.425-5.34,2.325-8.345,2.314c-0.077,0-0.143,0.011-0.219,0.011c-1.765,0.055-3.476-0.164-5.143-0.833
			c-1.436-0.57-2.829-1.184-4.035-2.16c-1.25-1.02-2.358-2.171-3.344-3.454c-2.314-3.004-3.717-6.448-4.682-10.066
			c-0.844-3.18-1.294-6.426-1.261-9.737c0.011-1.239,0.033-2.489,0-3.728c-0.044-1.623,0.197-3.224,0.461-4.792
			c0.373-2.303,0.844-4.583,1.513-6.831c0.899-3.015,1.974-5.965,3.333-8.794c2.105-4.364,4.649-8.454,7.917-12.051
			c1.579-1.743,2.029-3.662,1.206-5.866c-0.055-0.143-0.077-0.285-0.143-0.417c-0.493-1.009-1.118-1.908-2.149-2.412
			c-1.184-0.57-2.39-0.658-3.597-0.044c-0.252,0.132-0.504,0.274-0.768,0.417c-2.347,1.25-4.43,2.84-6.393,4.605
			c-1.798,1.612-3.52,3.301-5.208,5.022c-3.531,3.629-6.645,7.588-9.364,11.853c-4.2,6.59-7.073,13.75-8.663,21.404
			c-0.296,1.415-0.526,2.851-0.68,4.309c-0.186,1.82-0.384,3.629-0.384,5.461c0,0.143-0.066,0.285-0.132,0.526
			c-0.329,0.351-0.713,0.8-1.129,1.206c-1.425,1.371-2.862,2.719-4.287,4.079c-3.279,3.147-6.853,5.932-10.614,8.465
			c-2.007,1.349-4.156,2.423-6.491,3.114c-0.493,0.154-0.998,0.186-1.513,0.164c-0.866-0.044-1.535-0.45-1.985-1.173
			c-0.23-0.362-0.417-0.789-0.493-1.206c-0.11-0.647-0.175-1.294-0.099-1.963c0.143-1.393,0.384-2.752,0.768-4.09
			c0.461-1.612,0.954-3.224,1.447-4.825c1.875-6.075,4.09-12.029,6.458-17.928c1.963-4.88,3.991-9.737,6.184-14.518
			c1.765-3.849,3.52-7.698,5.285-11.557c1.129-2.456,2.259-4.912,3.366-7.38c0.833-1.864,1.579-3.772,2.204-5.724
			c0.471-1.48,0.68-2.993,0.504-4.54c-0.132-1.162-0.702-2.072-1.634-2.741c-0.647-0.461-1.371-0.789-2.182-0.954
			c-1.733-0.34-3.476-0.417-5.219-0.088c-0.351,0.066-0.724,0.11-1.086,0.099c-1.469-0.044-2.862,0.57-4.331,0.57
			c-2.007,0.647-4.079,1.107-6.053,1.875c-2.73,1.053-5.362,2.303-7.895,3.783c-2.665,1.557-5.121,3.388-7.281,5.592
			c-1.031,1.053-1.897,2.226-2.544,3.553c-0.285,0.603-0.45,1.228-0.537,1.886c-0.143,1.042,0.241,1.864,0.998,2.533
			c0.614,0.548,1.305,0.91,2.16,0.943c0.724,0.033,1.447,0.11,2.182,0.033c0.724-0.077,1.469-0.044,2.193-0.011
			c0.735,0.033,1.206,0.658,1.031,1.36c-0.132,0.493-0.285,0.987-0.482,1.458c-0.548,1.272-1.097,2.544-1.711,3.794
			c-1.952,4.013-3.915,8.016-5.757,12.073c-1.963,4.331-3.871,8.673-5.505,13.136c-0.23,0.636-0.559,1.184-0.998,1.678
			c-2.04,2.292-4.068,4.594-6.184,6.82c-4.331,4.583-9.013,8.75-14.014,12.588c-2.215,1.7-4.605,3.092-7.193,4.145
			c-0.603,0.252-1.217,0.493-1.853,0.647c-0.482,0.121-1.02,0.164-1.513,0.11c-0.943-0.099-1.634-0.625-1.952-1.535
			c-0.164-0.472-0.274-0.987-0.296-1.491c-0.077-1.601-0.099-3.213,0.318-4.792c0.274-1.064,0.504-2.138,0.789-3.191
			c0.932-3.377,2.04-6.711,3.235-10c1.59-4.386,3.257-8.761,5.033-13.081c2.39-5.823,4.891-11.579,7.577-17.259
			c1.064-2.248,2.127-4.496,3.147-6.755c1.261-2.807,2.237-5.713,2.95-8.706c0.406-1.733,0.493-3.487,0.219-5.23
			c-0.384-2.434-1.557-4.419-3.816-5.559c-1.502-0.768-3.136-1.173-4.836-1.294c-1.173-0.077-2.325,0.132-3.476,0.318
			c-1.568,0.263-3.947,0.757-5.318,1.184c-5.614,1.754-10.845,4.32-15.845,7.38c-3.991,2.445-7.807,5.154-11.459,8.081
			c-1.425,1.14-2.884,2.259-4.244,3.476c-2.621,2.325-5.23,4.671-7.654,7.215c-0.45,0.472-0.888,0.954-1.415,1.535
			c-0.055-0.296-0.154-0.493-0.11-0.647c0.296-0.91,0.625-1.798,0.932-2.697c1.436-4.211,2.862-8.432,4.298-12.654
			c0.559-1.656,1.107-3.322,1.524-5.022c0.34-1.371,0.417-2.752,0.186-4.134c-0.285-1.732-1.217-2.983-2.906-3.52
			c-0.965-0.307-1.963-0.592-3.004-0.504c-1.447,0.132-2.928,0.164-4.353,0.428c-1.634,0.307-3.279,0.669-4.88,1.162
			c-2.445,0.757-4.803,1.722-7.084,2.895c-2.665,1.371-5.197,2.983-7.555,4.836c-1.733,1.36-3.191,2.983-4.518,4.748
			c-0.965,1.294-1.458,2.676-1.129,4.298c0.395,1.031,1.162,1.623,2.193,1.974c0.921,0.318,1.842,0.428,2.796,0.252
			c0.57-0.11,1.14-0.263,1.711-0.362c0.702-0.11,1.48,0.625,1.425,1.327c-0.011,0.219-0.022,0.439-0.088,0.647
			c-0.296,0.987-0.57,1.974-0.899,2.939c-1.393,4.079-2.818,8.147-4.211,12.226c-1.612,4.693-3.213,9.397-4.814,14.09
			c-0.713,2.061-0.713,2.061-2.182,3.728c-3.301,3.739-6.952,7.105-10.845,10.209c-0.735,0.592-1.469,1.206-2.248,1.743
			c-1.568,1.075-3.136,2.149-4.759,3.136c-3.936,2.412-8.07,4.441-12.489,5.79c-2.511,0.768-5.022,1.568-7.687,1.59
			c-0.362,0-0.713,0.121-1.075,0.186c-0.439,0.077-0.866,0.219-1.294,0.208c-2.105-0.066-4.233,0.263-6.327-0.307
			c-0.417-0.11-0.866-0.066-1.305-0.121c-0.362-0.044-0.724-0.099-1.075-0.197c-0.779-0.208-1.546-0.439-2.303-0.702
			c-3.081-1.075-5.823-2.676-8.048-5.121c-1.743-1.93-3.081-4.068-3.947-6.513c-1.327-3.706-1.568-7.566-1.557-11.448
			c0-0.143,0.022-0.296,0.044-0.439c0.439-3.86,0.998-7.676,2.094-11.415c0.768-2.599,1.656-5.143,2.73-7.621
			c1.864-4.309,4.167-8.366,7.04-12.095c2.654-3.443,5.68-6.491,9.463-8.706c0.822-0.482,1.667-0.921,2.555-1.25
			c1.82-0.658,3.695-0.91,5.614-0.702c1.59,0.164,2.939,0.833,3.937,2.127c0.504,0.647,0.91,1.327,1.151,2.105
			c0.307,0.976,0.537,1.963,0.559,3.004c0.033,1.469,0.121,2.939-0.143,4.375s-0.625,2.851-1.118,4.233
			c-1.107,3.114-2.632,6.02-4.364,8.827c-2.5,4.068-5.57,7.687-8.816,11.163c-3.301,3.531-6.963,6.678-10.702,9.726
			c-0.899,0.735-1.765,1.524-2.621,2.325c-0.811,0.757-1.415,1.656-1.93,2.643c-0.417,0.811-0.559,1.645-0.537,2.544
			c0.022,0.844,0.34,1.557,0.921,2.16c1.217,1.25,2.719,1.656,4.408,1.393c1.009-0.154,1.974-0.504,2.906-0.954
			c4.156-1.985,8.202-4.2,12.106-6.634c4.408-2.752,8.553-5.866,12.511-9.233c3.958-3.377,7.665-6.996,10.943-11.042
			c2.445-3.015,4.66-6.173,6.393-9.671c0.954-1.908,1.722-3.871,2.27-5.921c0.45-1.7,0.811-3.421,0.669-5.198
			c-0.011-0.143-0.011-0.296,0-0.439c0.121-2.314-0.417-4.507-1.305-6.612c-1.7-4.057-4.485-7.138-8.399-9.189
			c-1.491-0.779-3.037-1.415-4.66-1.875c-1.546-0.439-3.059-0.954-4.693-0.932c-0.208,0-0.439-0.033-0.647-0.077
			c-1.952-0.45-3.926-0.252-5.888-0.099c-0.515,0.044-1.02,0.208-1.524,0.175c-1.93-0.121-3.75,0.592-5.658,0.581
			c-1.48,0.34-2.983,0.647-4.463,1.031c-4.616,1.217-8.97,3.07-13.081,5.505c-1.129,0.68-2.281,1.327-3.344,2.094
			c-2.193,1.579-4.364,3.202-6.349,5.044c-2.303,2.138-4.54,4.342-6.513,6.798c-2.555,3.202-4.869,6.579-6.842,10.176
			c-3.586,6.535-6.031,13.443-7.062,20.834c-0.044,0.285-0.11,0.57-0.175,0.855c-0.318,1.513-0.45,3.037-0.461,4.572
			c-0.011,1.02,0.044,2.05-0.011,3.07c-0.154,2.741,0.351,5.395,0.976,8.026c1.097,4.616,3.136,8.783,6.206,12.435
			c2.763,3.29,5.987,5.998,9.847,7.895c1.316,0.647,2.621,1.316,3.98,1.842c1.36,0.537,2.752,0.987,4.167,1.338
			c1.557,0.373,3.147,0.592,4.726,0.877c0.362,0.066,0.724,0.132,1.086,0.121c2.193-0.044,4.386,0.164,6.579,0.044
			c1.897-0.099,3.794,0.022,5.658-0.461c1.469-0.044,2.873-0.493,4.298-0.757c1.864-0.34,3.673-0.954,5.494-1.48
			c5.428-1.579,10.57-3.86,15.505-6.579c4.496-2.467,8.706-5.373,12.698-8.586c0.132-0.11,0.329-0.154,0.515-0.241
			c0.044,0.208,0.121,0.362,0.077,0.482c-0.625,1.875-1.261,3.739-1.886,5.603c-0.493,1.447-0.987,2.895-1.404,4.375
			c-0.362,1.305-0.318,2.61-0.164,3.915c0.154,1.294,0.779,2.347,1.952,3.015c0.384,0.219,0.779,0.417,1.162,0.614
			c0.439,0.033,0.877,0.033,1.316,0.088c2.544,0.318,4.923-0.252,7.226-1.294c1.524-0.702,3.015-1.491,4.397-2.445
			c3.487-2.401,6.316-5.384,8.191-9.244c0.647-1.316,1.184-2.665,1.645-4.057c1.513-4.496,3.026-8.991,4.572-13.476
			c0.329-0.965,0.735-1.919,1.217-2.818c0.976-1.798,1.996-3.586,3.07-5.318c3.213-5.187,7.171-9.792,11.294-14.244
			c4.233-4.551,8.706-8.871,13.641-12.676c2.029-1.568,4.057-3.125,6.25-4.452c1.129-0.68,2.303-1.261,3.553-1.667
			c0.482-0.154,0.998-0.23,1.502-0.263c0.8-0.066,1.371,0.493,1.404,1.338c0.011,0.362,0.011,0.735-0.077,1.086
			c-0.164,0.636-0.362,1.272-0.614,1.875c-0.537,1.283-1.086,2.555-1.689,3.805c-1.491,3.103-3.015,6.173-4.507,9.266
			c-2.763,5.724-5.208,11.59-7.303,17.599c-1.502,4.276-2.796,8.608-3.651,13.06c-0.384,2.007-0.724,4.024-0.647,6.086
			c0.022,0.735,0.011,1.458,0.011,2.193c0,2.368,0.504,4.649,1.382,6.831c1.469,3.64,3.98,6.272,7.676,7.676
			c1.776,0.669,3.597,1.184,5.526,1.217c2.489,0.044,4.967,0.077,7.391-0.592c2.105-0.581,4.222-1.162,6.184-2.16
			c1.568-0.789,3.158-1.546,4.704-2.358c1.107-0.581,2.193-1.195,3.224-1.875c4.397-2.906,8.619-6.031,12.5-9.616
			c0.691-0.647,1.393-1.294,2.105-1.919c0.154-0.132,0.373-0.186,0.603-0.296c0.066,0.252,0.154,0.45,0.164,0.658
			c0.022,0.515,0.011,1.02,0.011,1.535c0,2.818,0.614,5.515,1.689,8.103c0.888,2.127,2.27,3.915,4.134,5.296
			c1.601,1.184,3.377,2.051,5.296,2.555c0.976,0.263,1.963,0.57,3.004,0.57c1.031,0,2.051,0.11,3.07,0.088
			c1.239-0.022,2.5-0.022,3.706-0.252c2.73-0.515,5.351-1.458,7.873-2.61c4.331-1.963,8.421-4.386,12.215-7.27
			c3.608-2.741,7.127-5.592,10.428-8.706c0.121-0.11,0.362-0.099,0.57-0.154c0.296,0.735,0.57,1.404,0.844,2.083
			c1.14,2.807,2.719,5.351,4.715,7.61c4.101,4.649,9.211,7.599,15.318,8.75c0.855,0.154,1.711,0.373,2.577,0.461
			c2.621,0.274,5.252,0,7.884,0.022c1.042,0,2.029-0.285,3.037-0.406c1.678-0.186,3.322-0.526,4.923-0.987
			c3.651-1.053,7.226-2.325,10.636-4.035c7.533-3.783,14.123-8.783,19.748-15.066c1.064-1.195,2.094-2.423,3.191-3.695
			c0.461-0.077,0.954-0.197,1.458-0.252c2.489-0.252,4.912-0.746,7.325-1.36c1.338-0.351,2.675-0.735,3.969-1.239
			c4.638-1.809,9.101-3.98,13.268-6.722c4.222-2.774,8.235-5.812,11.996-9.189c3.421-3.081,6.689-6.327,9.704-9.814
			c0.23-0.274,0.461-0.57,0.943-0.636c0.68,0.23,1.436,0.504,2.204,0.724c1.689,0.471,3.355,1.02,5.066,1.36
			c1.776,0.362,3.542,0.833,5.362,1.02c0.614,0.066,1.261,0.219,1.82,0.537c-0.088,0.614-0.592,0.855-0.954,1.195
			c-3.081,2.917-5.965,6.02-8.542,9.397c-2.051,2.697-3.75,5.581-5.143,8.652c-1.184,2.621-1.919,5.373-2.27,8.202
			c-0.241,1.952-0.121,3.915,0.241,5.888c0.439,2.412,1.305,4.627,2.434,6.766c1.151,2.171,2.818,3.926,4.693,5.45
			c3.322,2.708,7.116,4.265,11.459,4.32c0.877,0.011,1.754,0.033,2.632,0c1.02-0.033,2.072,0,3.059-0.208
			c1.853-0.395,3.706-0.779,5.505-1.404c3.893-1.349,7.533-3.169,10.987-5.395c4.989-3.224,9.595-6.93,13.893-11.02
			c2.018-1.919,3.937-3.926,5.888-5.899c2.379-2.39,4.551-4.956,6.689-7.555c1.809-2.204,3.739-4.298,5.768-6.305
			c0.998-0.987,2.094-1.831,3.246-2.61c0.724-0.493,1.524-0.855,2.347-1.162c0.559-0.208,1.14-0.23,1.722-0.197
			c1.009,0.066,1.963,0.833,2.226,1.831c0.285,1.064,0.406,2.138,0.143,3.246c-0.219,0.921-0.417,1.842-0.702,2.752
			c-0.833,2.665-1.941,5.198-3.235,7.665c-2.621,5.022-5.812,9.638-9.649,13.794c-1.031,1.129-2.138,2.204-3.29,3.213
			c-2.303,2.018-4.66,3.991-7.259,5.647c-1.974,1.25-3.936,2.533-5.998,3.629c-2.774,1.48-5.603,2.84-8.498,4.079
			c-4.298,1.831-8.739,3.29-13.213,4.616c-4.89,1.458-9.891,2.555-14.869,3.717c-0.208,0.044-0.428,0.077-0.647,0.099
			c-2.04,0.219-3.991,0.888-6.053,0.943c-0.143,0-0.285,0.044-0.428,0.077c-1.711,0.351-3.399,0.8-5.165,0.822
			c-0.219,0-0.45,0.022-0.647,0.099c-0.702,0.263-1.436,0.197-2.16,0.296c-1.436,0.208-2.862,0.504-4.331,0.515
			c-0.208,0.011-0.417,0.121-0.636,0.154c-1.645,0.274-3.322,0.362-4.978,0.658c-0.428,0.066-0.877-0.011-1.316,0.011
			c-0.581,0.022-1.184-0.011-1.743,0.132c-1.086,0.285-2.171,0.263-3.268,0.296c-0.877,0.033-1.754,0.088-2.621,0.208
			c-1.239,0.154-2.467,0.274-3.717,0.263c-1.173-0.011-2.336,0.066-3.498,0.241c-0.932,0.143-1.897,0.143-2.84,0.186
			c-0.8,0.033-1.612,0.022-2.412,0.055c-1.086,0.055-2.182,0.143-3.279,0.208c-5.395,0.307-10.812,0.208-16.217,0.285
			c-2.851,0.044-5.702-0.022-8.553-0.044c-0.362,0-0.735,0.011-1.097,0.011c-2.05,0.011-4.101,0.044-6.141,0.044
			c-4.09,0.011-8.18,0-12.27,0c-2.632,0-5.263,0.011-7.895,0c-2.27-0.011-4.54-0.033-6.798-0.033
			c-2.928,0.011-5.855,0.044-8.772,0.044c-2.555,0-5.11-0.022-7.665-0.022c-4.156,0-8.323,0.011-12.489,0.011
			c-2.27,0-4.54-0.033-6.798-0.033c-4.156,0.011-8.323,0.033-12.489,0.033c-4.529-0.011-9.057-0.044-13.586-0.055
			c-1.316,0-2.632,0.066-3.947,0.066c-3.575,0-7.16-0.022-10.735-0.022c-1.754,0-3.509,0.011-5.263,0.011
			c-2.621,0-5.252-0.011-7.884-0.011c-1.974-0.011-3.947,0.011-5.921,0c-3.004-0.011-5.998-0.055-8.991-0.044
			c-5.121,0.011-10.231,0.055-15.351,0.066c-1.173,0-2.336-0.055-3.509-0.055c-2.774,0-5.548,0.033-8.323,0.033
			c-4.671,0-9.353-0.033-14.024-0.033c-5.548,0-11.108,0.033-16.656,0.033c-5.844,0.011-11.689,0-17.533,0
			c-5.187,0-10.373,0.011-15.56,0c-1.535,0-3.07-0.044-4.605-0.044c-4.671,0.011-9.342,0.044-14.024,0.044
			c-4.605,0-9.2-0.044-13.805-0.011c-1.678,0.011-3.355-0.022-5.022,0.307c-1.195,0.241-2.215,0.768-2.972,1.711
			c-1.217,1.502-1.261,3.224-0.252,4.89c0.143,0.241,0.373,0.45,0.614,0.614c0.23,0.164,0.526,0.252,0.789,0.373
			c1.678,0.735,3.377,1.414,5.011,2.215c4.66,2.281,9.32,4.551,13.805,7.171c3.169,1.853,6.206,3.86,9.167,6.009
			c0.768,0.548,1.513,1.151,2.259,1.733c1.447,1.118,2.73,2.434,4.057,3.684c1.447,1.371,2.752,2.851,3.882,4.463
			c1.393,1.985,2.5,4.123,2.741,6.601c0.022,0.285,0.11,0.57,0.143,0.735c-0.066,1.469-0.57,2.675-1.184,3.849
			c-0.34,0.647-0.691,1.294-1.02,1.941c-0.197,0.384-0.417,0.779-0.537,1.195c-0.417,1.36-0.493,2.73,0.055,4.079
			c0.197,0.493,0.526,0.877,0.987,1.14c0.833,0.482,1.711,0.779,2.697,0.779c2.774,0,5.559,0.055,8.334,0.055
			c3.794,0,7.588-0.044,11.393-0.044c13.158,0.011,26.305,0.033,39.464,0.044c2.116,0,4.244-0.044,6.36-0.044
			c7.171,0.011,14.331,0.044,21.492,0.044c4.901,0,9.792-0.044,14.693-0.044c9.803,0,19.595,0.033,29.387,0.044
			c1.754,0,3.509-0.055,5.263-0.055c5.197,0.011,10.384,0.077,15.571,0.044c5.406-0.033,10.812-0.066,16.217-0.186
			c0.954-0.022,1.897-0.077,2.851-0.11c1.524-0.055,3.059-0.099,4.594-0.164s3.07-0.077,4.594-0.252
			c1.02-0.11,2.04-0.164,3.059-0.197c1.382-0.044,2.785-0.044,4.156-0.23c1.447-0.197,2.906-0.252,4.364-0.241
			c0.581,0.011,1.184-0.022,1.743-0.164c0.789-0.197,1.579-0.274,2.39-0.296c0.724-0.022,1.469-0.011,2.182-0.154
			c1.151-0.23,2.325-0.23,3.487-0.263c0.581-0.022,1.184,0,1.743-0.143c0.789-0.197,1.579-0.219,2.379-0.318
			c1.733-0.208,3.454-0.581,5.208-0.592c0.702-0.23,1.436-0.23,2.16-0.296c0.647-0.055,1.294-0.208,1.941-0.307
			c0.362-0.055,0.724-0.164,1.086-0.154c1.48,0.022,2.873-0.548,4.342-0.526c1.612-0.471,3.311-0.493,4.945-0.822
			c1.206-0.252,2.434-0.406,3.662-0.592c1.656-0.241,3.301-0.592,4.945-0.888c3.662-0.658,7.314-1.382,10.965-2.094
			c1.075-0.208,2.149-0.428,3.213-0.68c2.27-0.537,4.54-1.107,6.809-1.656c1.491-0.362,2.983-0.702,4.463-1.086
			c3.169-0.822,6.349-1.645,9.507-2.533c2.588-0.735,5.165-1.535,7.741-2.336c3.629-1.14,7.259-2.215,10.823-3.542
			c6.02-2.237,12.084-4.386,17.939-7.04c3.454-1.568,6.974-3.004,10.351-4.748c2.599-1.338,5.241-2.577,7.807-3.98
			c5.384-2.95,10.658-6.097,15.757-9.518c2.061-1.393,4.167-2.73,6.162-4.2c5.472-4.046,10.702-8.388,15.582-13.136
			c2.314-2.237,4.638-4.452,6.7-6.941c1.546-1.853,3.103-3.695,4.627-5.559c2.632-3.235,4.978-6.667,7.138-10.241
			c2.039-3.388,3.925-6.853,5.548-10.45c1.414-3.136,2.654-6.305,3.311-9.715C595.048,424.509,595.443,422.295,595.245,419.937z
			 M439.178,408.939c-0.044,0.943-0.011,1.886-0.285,2.818c-0.121,0.417-0.121,0.866-0.132,1.305
			c-0.022,0.877-0.11,1.743-0.329,2.599c-0.175,0.702-0.164,1.447-0.252,2.171c-0.022,0.252-0.066,0.307-0.581,0.921
			c-1.338-0.285-2.61-0.822-3.882-1.349c-3.739-1.557-7.051-3.783-9.967-6.612c-2.39-2.336-4.211-5.033-5.34-8.18
			c-0.943-2.643-1.393-5.373-1.129-8.158c0.23-2.5,1.009-4.858,2.478-6.93c1.272-1.798,2.993-2.961,5.165-3.41
			c0.362-0.077,0.713-0.154,1.075-0.23c1.897-0.395,3.629,0.044,5.23,1.053c1.305,0.822,2.412,1.842,3.268,3.169
			c1.031,1.601,1.886,3.279,2.467,5.099c0.592,1.875,1.173,3.761,1.393,5.724c0.077,0.647,0.329,1.283,0.373,1.93
			C438.882,403.555,439.32,406.23,439.178,408.939z M499.048,371.164c-0.164,1.886-0.45,3.772-0.789,5.636
			c-0.34,1.864-0.79,3.717-1.502,5.483c-0.099,0.263-0.186,0.548-0.735,0.735c-0.296-0.252-0.581-0.471-0.833-0.735
			c-1.009-1.053-1.919-2.204-2.774-3.388c-1.382-1.93-2.303-4.079-2.796-6.382c-0.691-3.213-0.219-6.25,1.7-8.991
			c0.888-1.283,1.963-2.358,3.465-2.917c0.669-0.252,1.305-0.143,1.864,0.274c0.713,0.537,1.25,1.206,1.568,2.061
			c0.493,1.316,0.691,2.697,0.844,4.068C499.212,368.368,499.179,369.782,499.048,371.164z M255.884,501.222
			c-2.138,0.241-2.281,0.23-3.059-0.23c0.066-0.768,0.121-1.568,0.186-2.303c-0.208-0.548-0.526-0.932-1.097-1.305
			c-0.877-0.164-1.886-0.11-2.939,0c-0.263,0.252-0.537,0.482-0.8,0.735c-0.274,0.252-0.439,0.57-0.504,0.943
			c-0.044,0.208-0.077,0.428-0.099,0.647c-0.197,2.04-0.384,4.079-0.581,6.108c-0.197,2.029-0.417,4.068-0.625,6.097
			c-0.033,0.285-0.044,0.57-0.077,0.91c0.307,0.384,0.625,0.779,0.943,1.173c1.064,0.197,2.072,0.099,3.136,0.055
			c0.428-0.384,0.844-0.779,1.173-1.086c0.099-0.263,0.153-0.395,0.186-0.537c0.033-0.132,0.055-0.285,0.066-0.428
			c0.154-2.5,0.088-2.226,2.621-2.237c0.307,0,0.57,0.088,0.8,0.439c0.011,1.228-0.329,2.511-0.307,3.794
			c-0.965,0.932-1.842,1.798-2.719,2.654c-1.184,0.296-2.358,0.186-3.52,0.208c-1.097,0.022-2.182,0.208-3.443,0.011
			c-0.877-0.888-1.678-1.733-2.336-2.708c0.121-1.919,0.296-3.794,0.537-5.68c0.23-1.809,0.362-3.63,0.548-5.45
			c0.197-1.886,0.406-3.761,0.614-5.658c0.965-1.031,1.919-2.029,2.983-2.829c1.941-0.154,4.825-0.154,6.48,0.022
			c0.307,0.329,0.669,0.68,0.998,1.064c0.428,0.493,0.822,1.02,1.25,1.546C256.235,498.568,256.366,499.95,255.884,501.222z
			 M268.581,515.641c-0.011,0.285-0.044,0.581-0.45,0.921c-1.82,0.132-7.763,0.208-8.772,0.099c-0.055-0.011-0.121-0.066-0.175-0.11
			c-0.055-0.033-0.11-0.088-0.23-0.208c0.57-7.138,1.206-14.331,1.809-21.393c0.263-0.395,0.526-0.504,0.833-0.504
			c0.581-0.011,1.173-0.022,1.754,0.011c0.197,0.011,0.384,0.164,0.636,0.274c-0.186,3.136-0.472,6.261-0.724,9.386
			c-0.252,3.059-0.482,6.119-0.724,9.112c0.153,0.362,0.417,0.482,0.724,0.482c1.393,0,2.774-0.011,4.167-0.011
			c0.285,0,0.581,0.044,0.855,0.121c0.11,0.033,0.263,0.175,0.274,0.285C268.592,514.61,268.603,515.126,268.581,515.641z
			 M284.645,516.003c-0.154,0.11-0.274,0.252-0.395,0.263c-0.866,0.088-1.733,0.197-2.643-0.077
			c-0.581-1.667-0.395-3.476-0.965-5.187c-0.164-0.066-0.351-0.23-0.548-0.23c-1.316-0.011-2.621,0-3.936,0.033
			c-0.274,0.011-0.537,0.164-0.844,0.263c-0.351,1.371-0.702,2.708-1.053,4.046c-0.077,0.285-0.132,0.57-0.23,0.844
			c-0.099,0.285-0.329,0.493-0.636,0.537c-0.724,0.099-1.447,0.175-2.16-0.033c-0.066-0.022-0.11-0.088-0.34-0.252
			c1.908-7.073,3.794-14.035,5.68-20.998c0.055-0.208,0.186-0.395,0.285-0.57c1.151-0.318,2.127-0.351,4.364-0.121
			c0.099,0.164,0.274,0.329,0.307,0.526c0.197,1.14,0.373,2.303,0.548,3.454c0.548,3.608,1.086,7.215,1.634,10.823
			c0.307,2.018,0.625,4.035,0.932,6.053C284.678,515.586,284.656,515.806,284.645,516.003z M300.062,500.191
			c-0.197,0.121-0.318,0.241-0.439,0.263c-0.789,0.099-1.579,0.077-2.39-0.011c-0.099-0.263-0.219-0.45-0.23-0.647
			c-0.044-0.504-0.044-1.02-0.055-1.59c-0.329-0.351-0.669-0.713-0.998-1.075c-1.009-0.143-1.952-0.055-2.917-0.066
			c-0.241,0.164-0.493,0.318-0.702,0.504c-0.208,0.186-0.384,0.428-0.603,0.669c-0.088,1.305-0.197,2.61-0.285,3.915
			c-0.011,0.143,0.011,0.296,0.022,0.439c0.044,0.45,0.548,1.009,0.987,1.053c0.428,0.044,0.866,0.066,1.305,0.066
			c1.316,0.011,2.621-0.066,4.002,0.022c0.351,0.318,0.746,0.636,1.097,0.998c0.362,0.362,0.691,0.757,1.086,1.184
			c0.033,0.384,0.099,0.822,0.088,1.25c-0.077,2.04-0.175,4.079-0.263,6.02c-0.932,1.283-1.404,1.754-2.884,2.939
			c-2.171,0.154-4.43,0.077-6.678,0.143c-1.053-0.757-1.842-1.7-2.566-2.588c-0.219-0.822,0.022-1.546,0.044-2.27
			c0.011-0.362-0.011-0.735,0.219-1.031c0.241-0.219,0.537-0.241,0.822-0.23c0.647,0.022,1.305-0.143,1.93,0.143
			c0.066,0.022,0.11,0.088,0.219,0.175c0.033,0.603,0.066,1.25,0.088,1.798c0.23,0.614,0.658,0.899,1.086,1.25
			c0.647,0.011,1.305,0,1.952,0.044c1.064,0.077,1.842-0.318,2.456-1.338c0.186-1.393,0.175-2.928,0.143-4.452
			c-0.011-0.581-0.548-1.097-1.151-1.108c-0.943-0.022-1.897-0.011-2.84-0.022c-0.8-0.011-1.601,0.088-2.434-0.088
			c-0.702-0.614-1.327-1.316-1.919-2.072c-0.186-1.25,0-2.478,0.022-3.717c0.011-1.173,0.088-2.336,0.143-3.465
			c0.746-1.096,1.722-1.864,2.643-2.796c0.384-0.044,0.811-0.121,1.239-0.121c1.82-0.011,3.64-0.044,5.461,0.022
			c0.91,0.789,1.711,1.623,2.478,2.665C300.205,498.217,300.271,499.237,300.062,500.191z M307.19,502.571
			c0.307,0.68,0.746,0.998,1.404,0.998c1.097,0,2.193-0.011,3.29,0c0.581,0.011,1.162,0.033,1.7,0.055
			c0.822,0.713,1.546,1.425,2.138,2.215c0.143,1.776,0.099,5.439-0.088,7.325c-0.724,0.932-1.634,1.765-2.5,2.555
			c-1.733,0.186-5.208,0.241-7.193,0.11c-0.8-0.581-1.491-1.436-2.237-2.204c-0.329-0.811-0.132-1.623-0.143-2.412
			c-0.011-0.362-0.011-0.735,0.329-1.129c0.757-0.241,1.623-0.143,2.423-0.164c0.329,0.186,0.482,0.417,0.493,0.735
			c0.022,0.504,0.044,1.009,0.066,1.415c0.143,0.219,0.197,0.351,0.296,0.461c0.34,0.395,0.724,0.713,1.294,0.702
			c0.877-0.011,1.754,0,2.665,0c0.384-0.34,0.768-0.669,1.075-0.932c0.077-0.241,0.164-0.373,0.164-0.515
			c0.033-1.239,0.044-2.478,0.077-3.717c0.022-1.053-0.537-1.59-1.634-1.579c-0.515,0.011-1.02,0.011-1.535,0.011
			c-0.877,0-1.754,0.011-2.632,0.011c-0.143,0-0.285-0.033-0.559-0.077c-0.789-0.471-1.393-1.305-2.018-2.007
			c-0.066-0.219-0.143-0.351-0.143-0.493c0.022-1.897,0.066-3.794,0.099-5.691c0-0.362,0.033-0.724,0.055-1.107
			c0.724-1.02,1.689-1.798,2.544-2.697c2.237-0.164,4.419-0.044,6.623-0.077c1.02,0.757,1.82,1.678,2.577,2.632
			c0.066,1.064,0.121,2.083-0.055,3.125c-0.197,0.099-0.384,0.263-0.57,0.274c-0.647,0.033-1.305,0.022-1.963,0
			c-0.132,0-0.252-0.121-0.461-0.23c-0.077-0.658-0.154-1.382-0.241-2.105c-0.307-0.329-0.614-0.636-0.899-0.943
			c-1.064-0.186-2.072-0.099-3.147-0.044c-0.351,0.329-0.713,0.669-1.097,1.031C307.08,499.632,307.19,501.167,307.19,502.571z
			 M339.471,512.845c-0.68,0.899-1.579,1.645-2.379,2.5c-1.25,0.274-2.5,0.143-3.728,0.186c-1.162,0.044-2.325,0.011-3.509,0.011
			c-0.647-0.23-1.031-0.789-1.502-1.228c-0.428-0.395-0.811-0.833-1.261-1.283c-0.044-0.34-0.121-0.691-0.121-1.053
			c0-4.671,0-9.331,0.011-14.003c0-0.285,0.044-0.57,0.077-0.866c0.779-0.987,1.667-1.831,2.544-2.632
			c1.754-0.175,5.208-0.175,7.062-0.022c0.504,0.263,0.866,0.735,1.283,1.14c0.417,0.406,0.811,0.833,1.305,1.349
			c0.055,0.417,0.121,0.702,0.121,0.987c0.066,4.737,0.132,9.485,0.186,14.222C339.57,512.363,339.504,512.582,339.471,512.845z
			 M353.057,496.978c-1.162,0.011-2.336,0.011-3.498,0.022c-0.581,0.011-1.162,0.011-1.743,0.033
			c-0.373,0.011-0.647,0.318-0.658,0.724c-0.033,1.82,0.11,3.64,0.23,5.373c0.23,0.461,0.592,0.504,0.954,0.504
			c1.458,0,2.917,0,4.375,0c0.296,0,0.559,0.099,0.811,0.45c0,0.548,0,1.195,0,1.776c-0.219,0.329-0.493,0.406-0.789,0.417
			c-1.458,0.033-2.917,0.044-4.375,0.088c-0.274,0-0.537,0.143-0.855,0.23c-0.022,0.559-0.077,1.064-0.066,1.568
			c0.022,1.897,0.055,3.794,0.088,5.691c0.011,0.439,0.033,0.866-0.132,1.25c-1.042,0.351-1.042,0.351-2.785,0.154
			c-0.285-0.186-0.307-0.482-0.318-0.768c-0.066-1.82-0.164-3.651-0.197-5.472c-0.077-4.298-0.143-8.608-0.197-12.906
			c-0.011-0.493-0.11-1.009,0.219-1.623c1.656-0.186,5.241-0.252,8.597-0.154c0.296,0.011,0.57,0.077,0.855,0.121
			c0.077,0.252,0.186,0.45,0.208,0.658c0.055,0.504,0.066,1.009,0.088,1.447C353.638,496.891,353.364,496.978,353.057,496.978z
			 M148.863,347.238c-4.178,1.952-8.553,3.333-13.038,4.419c-1.239,0.066-2.401,0.614-3.662,0.581
			c-2.599,0.603-5.241,0.34-7.862,0.373c-1.458,0-2.928-0.011-4.386,0c-0.8,0-1.612-0.022-2.39-0.252
			c-0.208-0.055-0.439-0.132-0.647-0.11c-1.48,0.121-2.873-0.461-4.331-0.548c-0.493-0.022-0.987-0.23-1.48-0.351
			c-1.415-0.34-2.851-0.614-4.254-1.02c-2.599-0.757-5.143-1.689-7.599-2.818c-1.93-0.877-3.717-2.029-5.406-3.322
			c-0.702-0.537-1.316-1.151-1.842-1.864c-0.779-1.042-1.129-2.193-1.009-3.498c0.066-0.614,0.285-1.14,0.713-1.568
			c0.746-0.757,1.656-1.217,2.719-1.261c1.02-0.044,2.05-0.066,3.07,0.011c0.789,0.066,1.601,0.208,2.347,0.482
			c1.107,0.406,2.281,0.472,3.41,0.746c0.702,0.164,1.425,0.219,2.138,0.417c0.482,0.132,1.02,0.121,1.524,0.132
			c0.735,0.022,1.458,0.044,2.171,0.252c0.482,0.132,1.009,0.154,1.513,0.154c2.555,0.022,5.121,0.033,7.676,0.022
			c0.658,0,1.316-0.077,1.963-0.197s1.294-0.175,1.952-0.252c1.009-0.121,2.018-0.296,3.015-0.526
			c2.566-0.581,5.099-1.217,7.533-2.237c2.632-1.107,5.187-2.368,7.555-3.958c0.965-0.658,1.853-1.425,2.774-2.149
			c0.23-0.186,0.417-0.417,0.647-0.592c1.765-1.327,3.213-2.961,4.583-4.682c2.105-2.654,3.805-5.516,5.022-8.684
			c0.855-2.259,1.447-4.583,2.061-6.908c0.132-1.601,0.548-3.158,0.493-4.792c-0.066-1.743,0.033-3.498,0.011-5.252
			c-0.011-1.48-0.219-2.917-0.537-4.331c-0.757-3.377-2.083-6.535-4.068-9.386c-0.285-0.428-0.592-0.833-0.921-1.228
			c-3.443-4.134-7.698-7.105-12.763-8.937c-2.906-1.042-5.932-1.535-8.97-1.952c-0.647-0.088-1.316-0.044-1.974-0.044
			c-2.116-0.011-4.233-0.022-6.349-0.033c-0.877,0-1.743,0.088-2.61,0.241c-1.436,0.252-2.884,0.45-4.331,0.669
			c-1.667,0.252-3.29,0.68-4.923,1.053c-5.219,1.184-10.209,3.015-14.967,5.461c-4.254,2.193-8.103,4.978-11.481,8.355
			c-2.281,2.281-4.211,4.869-5.768,7.698c-1.765,3.213-2.972,6.645-3.717,10.241c-0.395,1.93-0.614,3.893-0.548,5.877
			c0.066,1.974,0.055,3.947,0.077,5.921c0.011,0.143-0.011,0.296,0.011,0.439c0.23,1.743,0.439,3.476,0.691,5.208
			c0.351,2.379,0.822,4.748,1.48,7.062c1.151,4.079,2.599,8.037,4.375,11.897c2.116,4.594,4.726,8.904,7.38,13.191
			c1.579,2.555,3.344,4.967,5.11,7.391c0.943,1.305,1.897,2.599,2.862,3.882c1.371,1.798,2.708,3.629,4.145,5.384
			c1.667,2.04,3.257,4.134,4.89,6.195c4.32,5.428,8.597,10.899,12.38,16.733c1.634,2.511,3.169,5.088,4.562,7.741
			c1.897,3.64,3.465,7.412,4.518,11.382c0.461,1.765,0.888,3.542,1.053,5.373c0.088,1.02,0.504,1.974,0.428,3.026
			c-0.077,1.02-0.011,2.05-0.011,3.07c0,1.239,0.011,2.489,0,3.728c-0.011,0.504-0.055,1.02-0.121,1.524
			c-0.121,0.943-0.274,1.875-0.406,2.818c-1.151,8.07-3.191,14.288-7.621,21.755c-3.311,5.581-7.621,10.329-12.709,14.375
			c-4.134,3.301-8.608,6.031-13.432,8.191c-3.268,1.469-6.656,2.632-10.11,3.575c-1.897,0.515-3.816,0.998-5.757,1.283
			c-1.513,0.219-2.983,0.757-4.54,0.592c-0.208-0.022-0.439,0.022-0.647,0.077c-1.502,0.428-3.037,0.362-4.572,0.362
			c-1.173,0-2.336,0-3.509,0c-1.393,0-2.774-0.011-4.156-0.241c-0.724-0.121-1.447-0.175-2.182-0.208
			c-1.02-0.033-2.018-0.132-3.037-0.329c-1.14-0.219-2.292-0.428-3.443-0.57c-1.02-0.121-2.018-0.351-2.993-0.614
			c-4.682-1.228-9.2-2.873-13.465-5.176c-1.031-0.548-1.996-1.217-2.972-1.864c-2.763-1.831-5.154-4.09-7.303-6.59
			c-2.862-3.322-4.726-7.193-5.735-11.448c-0.406-1.7-0.702-3.432-0.614-5.208c0.022-0.515-0.022-1.02-0.033-1.535
			c-0.088-4.759,0.965-9.255,3.015-13.553c1.305-2.73,2.993-5.198,4.989-7.434c1.469-1.634,3.125-3.081,4.89-4.386
			c3.322-2.434,6.974-4.255,10.867-5.592c1.305-0.45,2.654-0.811,4.002-1.129c3.202-0.757,6.458-1.239,9.737-1.491
			c2.04-0.154,4.09,0.033,6.13,0.154c0.724,0.044,1.458,0.099,2.182,0.186c0.8,0.099,1.601,0.219,2.39,0.34
			c0.439,0.066,0.855,0.197,1.294,0.252c2.401,0.296,4.704,1.009,6.963,1.864c2.336,0.877,4.54,1.996,6.579,3.443
			c2.972,2.105,5.329,4.748,7.062,7.961c0.735,1.36,1.129,2.84,1.568,4.309c0.088,0.274,0.077,0.57,0.088,0.866
			c0.022,0.658,0.099,1.305,0.241,1.952c0.121,0.559,0.197,1.162,0.143,1.732c-0.175,1.667-0.318,3.344-0.8,4.956
			c-0.482,1.623-1.272,3.103-2.292,4.452c-0.8,1.064-1.82,1.853-3.048,2.434c-2.215,1.042-4.616,0.088-5.855-1.568
			c-0.702-0.954-1.14-2.04-1.513-3.147c-0.23-0.691-0.362-1.415-0.592-2.105c-0.504-1.524-0.943-3.059-1.623-4.529
			c-0.998-2.149-2.336-4.068-3.958-5.757c-1.261-1.316-2.752-2.379-4.463-3.114c-1.217-0.526-2.489-0.844-3.75-1.184
			c-0.143-0.033-0.285-0.066-0.428-0.066c-1.535,0-3.07,0-4.605,0c-1.031,0.384-2.149,0.34-3.202,0.669
			c-1.184,0.373-2.347,0.8-3.443,1.393c-2.27,1.228-4.287,2.774-5.965,4.748c-1.886,2.215-3.004,4.792-3.586,7.643
			c-0.439,2.127-0.263,4.222,0.066,6.316c0.439,2.774,1.447,5.362,2.993,7.709c1.535,2.325,3.344,4.43,5.636,6.064
			c0.713,0.504,1.415,1.042,2.149,1.513c2.599,1.667,5.428,2.829,8.388,3.662c2.972,0.833,5.998,1.228,9.09,1.129
			c0.515-0.011,1.02-0.033,1.535-0.022c0.8,0.011,1.59-0.099,2.39-0.197c1.743-0.197,3.421-0.647,5.132-1.031
			c4.682-1.053,9.013-2.928,13.049-5.483c4.32-2.73,7.884-6.261,10.68-10.549c0.724-1.097,1.305-2.281,1.831-3.487
			c1.053-2.423,1.941-4.879,2.314-7.511c0.066-0.428,0.197-0.855,0.285-1.283c0.066-0.351,0.175-0.713,0.164-1.075
			c-0.033-1.097,0.077-2.182,0.164-3.279c0.055-0.658,0.011-1.316-0.011-1.974c-0.022-0.439-0.143-0.877-0.11-1.305
			c0.132-1.787-0.362-3.476-0.735-5.187c-0.34-1.579-0.954-3.07-1.601-4.54c-1.316-3.026-3.07-5.801-4.934-8.52
			c-3.311-4.825-7.062-9.309-10.867-13.75c-2.094-2.445-4.156-4.912-6.217-7.38c-1.031-1.228-2.05-2.478-3.07-3.717
			c-2.654-3.213-5.198-6.502-7.709-9.825c-1.842-2.456-3.608-4.967-5.34-7.5c-3.969-5.801-7.402-11.919-10.307-18.312
			c-1.502-3.322-2.752-6.755-3.761-10.274c-0.592-2.116-1.129-4.233-1.612-6.371c-0.285-1.272-0.439-2.588-0.647-3.882
			c-0.022-0.143-0.011-0.296-0.044-0.439c-0.592-2.292-0.45-4.649-0.581-6.974c-0.066-1.239-0.066-2.478-0.175-3.717
			c-0.088-0.954-0.066-1.897,0.088-2.84c0.055-0.362,0.132-0.724,0.132-1.086c0.011-2.643,0.647-5.208,1.206-7.774
			c0.45-2.061,1.075-4.09,1.798-6.086c1.436-3.926,3.268-7.654,5.537-11.163c1.59-2.456,3.29-4.825,5.296-6.974
			c0.34-0.373,0.636-0.8,0.987-1.173c1.053-1.107,2.072-2.259,3.202-3.301c1.447-1.349,2.983-2.599,4.485-3.871
			c2.237-1.897,4.649-3.542,7.116-5.11c4.068-2.599,8.355-4.803,12.774-6.744c3.816-1.667,7.752-3.048,11.766-4.167
			c2.04-0.57,4.068-1.151,6.13-1.634c1.206-0.274,2.434-0.417,3.64-0.702c0.702-0.164,1.415-0.34,2.138-0.406
			c0.658-0.055,1.294-0.186,1.93-0.362c0.351-0.099,0.724-0.11,1.086-0.121c0.724-0.033,1.436-0.11,2.16-0.285
			c0.636-0.154,1.305-0.154,1.963-0.154c1.393,0.011,2.774-0.088,4.156-0.23c3.136-0.307,6.272-0.373,9.408,0.077
			c0.57,0.088,1.162,0.099,1.743,0.121c0.735,0.022,1.447,0.077,2.16,0.274c0.625,0.164,1.327,0.077,1.952,0.252
			c1.469,0.406,3.015,0.482,4.485,0.91c1.963,0.57,3.926,1.118,5.844,1.809c1.722,0.614,3.399,1.36,5.055,2.116
			c4.825,2.204,9.2,5.077,13.07,8.717c4.09,3.849,7.358,8.301,9.529,13.509c0.932,2.226,1.557,4.562,2.072,6.919
			c0.154,0.702,0.066,1.469,0.219,2.171c0.439,1.941,0.45,3.915,0.406,5.888c-0.022,1.02-0.077,2.05-0.318,3.048
			c-0.066,0.285-0.143,0.581-0.154,0.866c-0.088,2.358-0.691,4.627-1.338,6.864c-0.625,2.171-1.436,4.287-2.336,6.371
			c-2.029,4.748-4.836,9.002-8.136,12.939c-4.068,4.858-8.882,8.871-14.288,12.171C152.767,345.154,150.892,346.284,148.863,347.238
			z"
          />
        </g>
      </g>
      <text
        transform="matrix(1.0042 0 0 1 362.1631 513.9883)"
        font-family="'College'"
        font-size="28.6134"
      >
        2021
      </text>
    </svg>
  );
}

Artwrk7.props = {
  primary: string,
  secondary: string,
};

Artwrk7.defaultProps = {
  primary: "#000",
  secondary: "#fff",
};
