import React from "react";
import { string } from "prop-types";

export default function Artwrk22(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      enable-background="new 0 0 400 400"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M90.541,165.361c0.358,0.316,0.726,0.685,1.1,1.105c0.375,0.421,0.763,0.823,1.164,1.205
		c0.401,0.382,0.821,0.705,1.259,0.967c0.438,0.264,0.905,0.395,1.401,0.395c0.58,0,1.166-0.171,1.757-0.513
		c0.591-0.342,1.168-0.849,1.733-1.521c0.564-0.671,1.105-1.487,1.623-2.448c0.517-0.961,0.992-2.073,1.425-3.337
		c0.475-1.343,0.871-2.56,1.187-3.653c0.317-1.092,0.573-2.066,0.768-2.922c0.195-0.855,0.335-1.592,0.419-2.211
		c0.084-0.618,0.126-1.151,0.126-1.599c0-0.658-0.089-1.23-0.269-1.718c-0.18-0.487-0.419-0.889-0.72-1.205
		s-0.641-0.546-1.021-0.691c-0.38-0.145-0.776-0.217-1.188-0.217c-0.243,0-0.528,0.02-0.855,0.059s-0.66,0.079-0.998,0.119
		s-0.665,0.073-0.981,0.099c-0.316,0.026-0.591,0.04-0.823,0.04c-0.348,0-0.71-0.099-1.084-0.296c-0.375-0.197-0.742-0.52-1.1-0.967
		c-0.359-0.447-0.691-1.053-0.998-1.816c-0.306-0.763-0.56-1.711-0.76-2.843c-0.063-0.369-0.122-0.803-0.174-1.303
		c-0.053-0.5-0.079-1.04-0.079-1.619c0-0.632,0.018-1.23,0.055-1.797c0.037-0.566,0.082-1.112,0.135-1.639
		c0.147-1.316,0.393-2.652,0.736-4.008c0.343-1.355,0.754-2.679,1.235-3.969c0.48-1.29,1.002-2.541,1.567-3.751
		c0.564-1.21,1.145-2.35,1.741-3.416c0.596-1.066,1.182-2.027,1.757-2.883c0.575-0.855,1.111-1.585,1.607-2.191
		c0.697-0.842,1.406-1.455,2.129-1.836c0.723-0.381,1.438-0.573,2.146-0.573c0.422,0,0.823,0.138,1.203,0.415
		c0.38,0.276,0.715,0.678,1.005,1.204c0.29,0.527,0.52,1.179,0.688,1.955c0.169,0.777,0.253,1.665,0.253,2.666
		c0,0.579-0.026,1.066-0.079,1.461c-0.053,0.395-0.122,0.718-0.206,0.967c-0.084,0.251-0.177,0.428-0.277,0.533
		c-0.101,0.105-0.204,0.158-0.309,0.158c-0.285,0-0.546-0.079-0.784-0.237c-0.238-0.158-0.467-0.336-0.689-0.533
		c-0.222-0.198-0.443-0.381-0.665-0.553c-0.222-0.171-0.464-0.256-0.728-0.256c-0.781,0-1.575,0.389-2.383,1.165
		c-0.807,0.777-1.594,1.764-2.359,2.961c-0.765,1.198-1.488,2.534-2.169,4.008c-0.681,1.475-1.291,2.909-1.829,4.304
		c-0.285,0.738-0.494,1.475-0.625,2.211c-0.132,0.738-0.198,1.436-0.198,2.093c0,0.974,0.169,1.737,0.507,2.291
		c0.337,0.553,0.749,0.829,1.235,0.829c0.285,0,0.606-0.013,0.965-0.04c0.359-0.026,0.726-0.059,1.101-0.099
		c0.375-0.04,0.739-0.079,1.092-0.119s0.667-0.059,0.942-0.059c0.654,0,1.283,0.231,1.884,0.691
		c0.602,0.461,1.134,1.139,1.599,2.034c0.464,0.896,0.836,2.008,1.116,3.337c0.28,1.33,0.42,2.85,0.42,4.561
		c0,1.053-0.063,2.12-0.19,3.199c-0.127,1.08-0.301,2.166-0.522,3.258c-0.222,1.093-0.475,2.172-0.76,3.238
		c-0.285,1.066-0.585,2.113-0.902,3.14c-0.57,1.843-1.206,3.581-1.908,5.212c-0.702,1.633-1.449,3.054-2.24,4.265
		s-1.628,2.172-2.509,2.883c-0.881,0.71-1.787,1.066-2.715,1.066c-0.401,0-0.813-0.092-1.235-0.276
		c-0.422-0.185-0.839-0.454-1.25-0.81s-0.8-0.803-1.164-1.343c-0.364-0.539-0.684-1.151-0.958-1.836s-0.494-1.441-0.657-2.271
		c-0.164-0.829-0.245-1.73-0.245-2.705c0-0.474,0.029-0.928,0.087-1.362C90.164,165.579,90.309,165.361,90.541,165.361z"
        />
        <path
          d="M110.014,159.24c0-1.131,0.121-2.093,0.364-2.883c0.243-0.79,0.564-1.316,0.966-1.58c0.116-0.579,0.295-1.362,0.538-2.35
		s0.543-2.06,0.902-3.218c0.359-1.158,0.768-2.323,1.227-3.495s0.966-2.238,1.52-3.199s1.15-1.744,1.789-2.35
		c0.638-0.605,1.316-0.909,2.034-0.909c0.612,0,1.145,0.185,1.599,0.553c0.454,0.369,0.828,0.882,1.124,1.54
		c0.295,0.658,0.517,1.441,0.665,2.35c0.147,0.908,0.222,1.889,0.222,2.942c0,0.896-0.048,1.751-0.143,2.567
		c-0.148,1.475-0.422,2.764-0.823,3.87c-0.401,1.105-0.874,2.073-1.417,2.902c-0.544,0.829-1.135,1.521-1.773,2.073
		s-1.277,1.014-1.916,1.382s-1.253,0.651-1.844,0.849c-0.591,0.198-1.103,0.35-1.536,0.454c-0.095,0.474-0.177,1.073-0.246,1.797
		c-0.069,0.725-0.103,1.429-0.103,2.113c0,1.527,0.243,2.712,0.729,3.554c0.485,0.843,1.156,1.264,2.011,1.264
		c0.496,0,0.971-0.138,1.425-0.415c0.454-0.276,0.884-0.632,1.29-1.066s0.789-0.908,1.148-1.421c0.359-0.514,0.686-1.02,0.981-1.521
		c0.38-0.632,0.742-1.165,1.084-1.6c0.343-0.434,0.646-0.651,0.911-0.651c0.264,0,0.433,0.165,0.506,0.494
		c0.074,0.33,0.111,0.678,0.111,1.046c0,0.211-0.002,0.415-0.008,0.612c-0.005,0.197-0.013,0.362-0.024,0.494
		c-0.053,0.685-0.158,1.369-0.316,2.053c-0.158,0.685-0.359,1.277-0.602,1.777c-1.098,2.159-2.225,3.699-3.38,4.621
		s-2.388,1.382-3.697,1.382c-0.253,0-0.551-0.04-0.894-0.119c-0.343-0.079-0.7-0.237-1.069-0.474
		c-0.37-0.237-0.734-0.579-1.092-1.026c-0.359-0.448-0.678-1.04-0.958-1.777c-0.28-0.737-0.507-1.645-0.681-2.725
		c-0.174-1.079-0.261-2.382-0.261-3.91c0-1.237,0.068-2.619,0.206-4.146c-0.137-0.052-0.267-0.23-0.388-0.533
		C110.075,160.261,110.014,159.82,110.014,159.24z M119.925,148.302c0.021-0.131,0.032-0.25,0.032-0.355c0-0.105,0-0.224,0-0.355
		c0-0.711-0.105-1.244-0.316-1.599c-0.211-0.355-0.465-0.533-0.76-0.533c-0.464,0-0.921,0.303-1.369,0.908
		c-0.449,0.606-0.871,1.362-1.267,2.271c-0.396,0.908-0.747,1.883-1.053,2.922c-0.307,1.041-0.544,1.981-0.712,2.824
		c0.76-0.158,1.457-0.415,2.089-0.77c0.633-0.355,1.185-0.803,1.655-1.343c0.469-0.54,0.849-1.146,1.14-1.816
		C119.653,149.783,119.84,149.065,119.925,148.302z"
        />
        <path
          d="M137.499,142.063c-0.37,0-0.766,0.217-1.188,0.651c-0.422,0.435-0.86,1.021-1.314,1.757
		c-0.454,0.737-0.916,1.573-1.385,2.508c-0.47,0.935-0.932,1.902-1.385,2.902c-0.232,0.5-0.454,0.955-0.665,1.362
		c-0.211,0.409-0.422,0.718-0.633,0.928c-0.148,0.606-0.304,1.402-0.467,2.389c-0.164,0.987-0.325,2.067-0.483,3.238
		c-0.159,1.172-0.309,2.37-0.451,3.594c-0.143,1.224-0.261,2.363-0.356,3.416c-0.053,0.553-0.095,1.093-0.126,1.619
		c-0.032,0.527-0.069,1.086-0.111,1.679c-0.042,0.592-0.093,1.231-0.15,1.915c-0.058,0.685-0.129,1.448-0.214,2.291
		c-0.053,0.5-0.137,0.902-0.253,1.205s-0.237,0.533-0.364,0.691c-0.126,0.158-0.248,0.257-0.364,0.296
		c-0.116,0.04-0.206,0.06-0.27,0.06c-0.401,0-0.728-0.237-0.981-0.711c-0.253-0.474-0.38-1.395-0.38-2.764
		c0-0.369,0.005-0.757,0.016-1.165c0.011-0.408,0.042-0.862,0.095-1.362c0.232-2.501,0.457-4.752,0.673-6.753
		c0.216-2,0.436-3.856,0.657-5.568c0.221-1.711,0.445-3.323,0.673-4.837c0.227-1.514,0.459-3.041,0.696-4.581
		c0.238-1.54,0.485-3.133,0.744-4.778c0.259-1.645,0.536-3.468,0.831-5.469c0.063-0.447,0.229-0.823,0.499-1.125
		c0.269-0.302,0.557-0.454,0.863-0.454c0.401,0,0.73,0.224,0.989,0.671c0.259,0.448,0.388,1.093,0.388,1.935
		c0,0.079-0.011,0.224-0.032,0.434c-0.021,0.211-0.053,0.435-0.095,0.671l-0.696,4.107c0.443-0.869,0.905-1.737,1.385-2.606
		c0.48-0.869,0.974-1.651,1.48-2.35c0.507-0.697,1.016-1.264,1.528-1.698c0.512-0.435,1.016-0.652,1.512-0.652
		c1.013,0,1.805,0.678,2.375,2.034c0.57,1.356,0.855,3.456,0.855,6.299c0,1.633-0.114,3.489-0.34,5.568
		c-0.227,2.08-0.512,4.312-0.855,6.694c-0.343,2.383-0.712,4.884-1.108,7.503c-0.396,2.62-0.763,5.286-1.1,7.997
		c-0.095,0.737-0.193,1.356-0.293,1.856c-0.101,0.5-0.219,0.902-0.356,1.205c-0.138,0.302-0.298,0.52-0.483,0.651
		c-0.185,0.132-0.409,0.198-0.673,0.198c-0.412,0-0.697-0.191-0.855-0.573c-0.158-0.382-0.238-0.993-0.238-1.836
		c0-0.736,0.045-1.639,0.135-2.705c0.089-1.066,0.216-2.237,0.38-3.514s0.354-2.639,0.57-4.087c0.216-1.448,0.452-2.922,0.705-4.423
		c0.201-1.21,0.398-2.422,0.594-3.633c0.195-1.21,0.37-2.382,0.522-3.514s0.277-2.211,0.372-3.238
		c0.095-1.027,0.143-1.975,0.143-2.843c0-0.816-0.071-1.533-0.214-2.152C138.155,142.372,137.889,142.063,137.499,142.063z"
        />
        <path
          d="M144.132,167.257c0.158-1.526,0.349-3.304,0.57-5.331c0.222-2.027,0.457-4.172,0.705-6.437
		c0.248-2.264,0.499-4.581,0.752-6.95c0.253-2.369,0.491-4.673,0.712-6.911c0.011-0.105,0.048-0.283,0.111-0.533
		c0.063-0.25,0.153-0.5,0.27-0.75c0.116-0.25,0.264-0.467,0.443-0.652c0.179-0.184,0.39-0.276,0.633-0.276
		c0.18,0,0.354,0.046,0.522,0.138c0.169,0.093,0.319,0.231,0.451,0.415c0.132,0.185,0.238,0.428,0.317,0.73
		c0.079,0.303,0.119,0.652,0.119,1.047c0,0.079,0,0.165,0,0.256c0,0.093-0.005,0.178-0.016,0.257l-0.443,4.186
		c-0.496,4.686-1,9.385-1.512,14.098c-0.512,4.713-1.011,9.438-1.496,14.177c-0.021,0.158-0.053,0.355-0.095,0.592
		c-0.042,0.237-0.101,0.474-0.174,0.711s-0.164,0.435-0.269,0.592c-0.105,0.158-0.232,0.237-0.38,0.237
		c-0.275,0-0.518-0.139-0.729-0.415s-0.391-0.625-0.538-1.046c-0.148-0.421-0.259-0.882-0.333-1.382
		c-0.074-0.5-0.111-0.974-0.111-1.421c0-0.131,0-0.25,0-0.355c0-0.105,0.01-0.223,0.032-0.355L144.132,167.257z M148.264,126.385
		c0.053-0.553,0.147-1.224,0.285-2.014c0.137-0.79,0.38-1.501,0.729-2.133c0.168-0.237,0.375-0.454,0.617-0.651
		c0.243-0.198,0.417-0.296,0.522-0.296s0.214,0.093,0.325,0.276c0.111,0.185,0.208,0.382,0.293,0.593
		c0.105,0.263,0.206,0.553,0.301,0.869c0.264,0.053,0.436,0.29,0.515,0.711c0.079,0.421,0.119,0.921,0.119,1.5
		c0,0.421-0.016,0.803-0.047,1.145c-0.032,0.342-0.058,0.619-0.08,0.83c-0.063,0.553-0.185,1.06-0.364,1.52
		c-0.18,0.461-0.386,0.856-0.618,1.185c-0.232,0.33-0.475,0.586-0.728,0.771s-0.475,0.276-0.665,0.276
		c-0.243,0-0.43-0.105-0.562-0.316c-0.132-0.21-0.298-0.658-0.499-1.343c-0.117-0.395-0.182-0.704-0.198-0.928
		c-0.016-0.223-0.023-0.415-0.023-0.572c0-0.29,0.008-0.546,0.023-0.77C148.225,126.813,148.243,126.596,148.264,126.385z"
        />
        <path
          d="M152.522,159.359c0.159-1.605,0.404-3.311,0.736-5.114s0.768-3.626,1.306-5.47c0.538-1.842,1.185-3.646,1.939-5.41
		c0.754-1.763,1.628-3.409,2.62-4.936c0.158-0.237,0.401-0.494,0.728-0.77s0.665-0.44,1.014-0.494
		c0.412-1.105,0.965-1.659,1.662-1.659c0.823,0,1.517,0.521,2.082,1.56c0.564,1.04,1.031,2.455,1.401,4.245
		c0.116,0.58,0.224,1.145,0.325,1.698c0.1,0.553,0.185,1.139,0.253,1.757c0.068,0.619,0.124,1.283,0.166,1.994
		c0.042,0.71,0.063,1.514,0.063,2.409c0,2.08-0.132,4.2-0.396,6.358c-0.264,2.159-0.639,4.265-1.124,6.318
		c-0.485,2.053-1.074,4.022-1.765,5.904c-0.691,1.882-1.459,3.574-2.304,5.074c-0.57,1.027-1.201,1.823-1.892,2.389
		c-0.691,0.566-1.465,0.849-2.319,0.849c-0.813,0-1.518-0.27-2.114-0.81c-0.596-0.54-1.092-1.309-1.488-2.31
		c-0.396-1-0.691-2.198-0.887-3.594c-0.195-1.395-0.292-2.948-0.292-4.66C152.238,163.031,152.333,161.254,152.522,159.359z
		 M155.151,159.872c-0.063,0.606-0.108,1.179-0.135,1.718c-0.026,0.54-0.04,1.06-0.04,1.56c0,0.869,0.045,1.678,0.135,2.429
		c0.089,0.75,0.234,1.396,0.435,1.935s0.451,0.961,0.752,1.264s0.667,0.454,1.101,0.454c0.802,0,1.549-0.507,2.24-1.521
		c0.691-1.013,1.306-2.284,1.844-3.811c0.538-1.526,0.99-3.165,1.354-4.916c0.364-1.751,0.62-3.363,0.768-4.838
		c0.095-0.921,0.177-1.79,0.245-2.606c0.069-0.816,0.103-1.566,0.103-2.251s-0.05-1.323-0.15-1.916
		c-0.101-0.592-0.229-1.257-0.388-1.994c-0.095-0.447-0.19-0.915-0.285-1.402c-0.095-0.487-0.18-0.967-0.253-1.441
		c-0.084,0.369-0.174,0.691-0.269,0.968c-0.095,0.276-0.237,0.527-0.427,0.75c-0.19,0.224-0.449,0.428-0.776,0.612
		c-0.327,0.185-0.755,0.342-1.282,0.474c-0.349,0.29-0.702,0.737-1.061,1.343c-0.359,0.606-0.71,1.317-1.053,2.132
		c-0.343,0.816-0.67,1.698-0.981,2.646c-0.312,0.948-0.591,1.915-0.839,2.902s-0.464,1.955-0.649,2.902
		S155.225,159.083,155.151,159.872z"
        />
        <path
          d="M169.827,170.416c0.137-1.842,0.295-3.87,0.475-6.082s0.364-4.449,0.554-6.713c0.19-2.264,0.375-4.488,0.554-6.674
		c0.179-2.185,0.333-4.186,0.459-6.002c-0.011-0.263-0.016-0.493-0.016-0.691c0-0.197,0-0.415,0-0.651
		c0-0.816,0.021-1.632,0.063-2.449c0.042-0.815,0.101-1.566,0.174-2.25c0.116-1,0.246-1.679,0.388-2.034
		c0.143-0.355,0.372-0.533,0.689-0.533c0.306,0,0.551,0.152,0.736,0.454c0.185,0.303,0.33,0.705,0.435,1.204
		c0.105,0.5,0.174,1.073,0.206,1.718c0.032,0.646,0.047,1.31,0.047,1.995c0,0.369-0.005,0.777-0.016,1.224
		c-0.011,0.448-0.016,0.803-0.016,1.066c0.295-0.763,0.609-1.546,0.942-2.35c0.333-0.803,0.686-1.526,1.061-2.172
		c0.375-0.645,0.77-1.178,1.188-1.599c0.417-0.421,0.852-0.632,1.306-0.632c0.18,0,0.356,0.04,0.531,0.118
		c0.174,0.079,0.351,0.185,0.53,0.316c0.211,0.132,0.43,0.31,0.657,0.533c0.227,0.224,0.446,0.533,0.657,0.928
		c0.348,0.658,0.641,1.481,0.879,2.468c0.237,0.987,0.356,1.994,0.356,3.021c0,0.395-0.032,0.823-0.095,1.284
		c-0.063,0.461-0.151,0.882-0.261,1.264c-0.111,0.382-0.24,0.698-0.388,0.948c-0.148,0.25-0.312,0.375-0.491,0.375
		c-0.18,0-0.398-0.112-0.657-0.335c-0.258-0.224-0.472-0.52-0.641-0.889c-0.074-0.158-0.15-0.381-0.229-0.671
		c-0.079-0.289-0.153-0.579-0.222-0.869c-0.069-0.29-0.132-0.559-0.19-0.81c-0.058-0.25-0.103-0.415-0.134-0.494
		c-0.053-0.026-0.111-0.045-0.174-0.059c-0.063-0.013-0.122-0.02-0.174-0.02c-0.538,0-1.05,0.343-1.536,1.027
		c-0.485,0.685-0.939,1.521-1.361,2.508c-0.422,0.987-0.81,2.041-1.164,3.159c-0.354,1.119-0.663,2.126-0.926,3.021
		c-0.211,2.132-0.446,4.403-0.705,6.812c-0.259,2.409-0.515,4.93-0.768,7.562v0.987c0,0.79-0.01,1.593-0.031,2.409
		c-0.021,0.816-0.085,1.54-0.19,2.172c-0.116,0.395-0.282,0.731-0.499,1.007s-0.467,0.415-0.752,0.415
		c-0.443,0-0.776-0.362-0.998-1.086s-0.333-1.573-0.333-2.547C169.748,171.351,169.774,170.89,169.827,170.416z"
        />
      </g>
      <g>
        <path
          stroke="#000000"
          stroke-width="1.7084"
          stroke-miterlimit="10"
          d="M217.671,132.08v1.239
		c-1.092,3.257-2.309,4.885-3.653,4.885c-0.137-0.048-0.273-0.097-0.409-0.146c-0.137-0.048-0.292-0.097-0.468-0.146
		c-3.118,8.068-5.816,12.102-8.096,12.102c-0.429,0-0.823-0.279-1.185-0.838c-0.36-0.559-0.633-1.3-0.818-2.224
		c-0.185-0.923-0.277-1.993-0.277-3.208c0-3.255,0.575-6.439,1.725-9.55c1.149-3.11,2.538-5.65,4.165-7.619s3.055-2.953,4.282-2.953
		c1.324,0,1.987,1.531,1.987,4.593c0,2.479-0.478,5.371-1.433,8.675c0.194,0.195,0.409,0.292,0.644,0.292
		C215.362,137.183,216.541,135.482,217.671,132.08z M213.023,124.643c-1.091,0-2.343,1.544-3.756,4.63
		c-1.413,3.087-2.679,6.477-3.799,10.17c-1.121,3.694-1.681,5.978-1.681,6.853c0,0.778,0.142,1.421,0.424,1.932
		c0.282,0.51,0.638,0.765,1.066,0.765c1.032,0,2.274-1.276,3.727-3.827c1.451-2.552,2.732-5.565,3.844-9.041
		c1.11-3.474,1.666-6.208,1.666-8.202c0-0.972-0.142-1.761-0.424-2.369C213.808,124.947,213.452,124.643,213.023,124.643z"
        />
        <path
          stroke="#000000"
          stroke-width="1.7084"
          stroke-miterlimit="10"
          d="M216.97,134.485v-1.312
		c0.039-0.097,0.346-0.62,0.921-1.568c0.574-0.948,0.881-1.469,0.92-1.567c0.02-1.506,0.19-2.903,0.512-4.192
		c0.321-1.287,0.647-1.932,0.979-1.932c0.526,0,0.711,0.948,0.555,2.843c2.592-3.255,4.023-5.054,4.297-5.395l7.015-20.34
		c5.105-14.92,8.856-22.382,11.253-22.382c0.448,0,0.828,0.341,1.14,1.021c0.312,0.681,0.468,1.58,0.468,2.697
		c0,2.965-0.774,6.586-2.323,10.863c-1.549,4.277-3.702,8.821-6.46,13.633c-2.757,4.812-5.792,9.259-9.104,13.342l-6.284,18.809
		c0.761,0.973,1.462,1.458,2.104,1.458c1.403,0,2.523-0.644,3.361-1.932c0.838-1.288,1.91-3.438,3.216-6.452v1.239
		c-1.248,3.111-2.319,5.225-3.216,6.343c-0.896,1.119-1.997,1.677-3.303,1.677c-0.857,0-1.676-0.485-2.455-1.458l-14.088,42.065
		h-2.338l5.407-15.528c0.935-2.723,2.036-6.197,3.303-10.425s2.362-8.056,3.288-11.482c0.926-3.427,1.798-6.914,2.616-10.462
		l-0.059-2.406c-0.293,0.438-0.58,0.887-0.862,1.349C217.549,133.453,217.262,133.951,216.97,134.485z M219.483,137.766
		l-0.556-2.406c-0.059,0.244-0.404,1.58-1.037,4.01c-0.634,2.431-1.159,4.459-1.579,6.087c-0.418,1.629-0.881,3.439-1.388,5.431
		L219.483,137.766z M224.335,123.477c-0.838,0.973-2.017,2.503-3.536,4.593c-0.02,0.438-0.088,0.924-0.205,1.458
		c-0.117,0.536-0.238,1.021-0.365,1.458s-0.277,0.973-0.453,1.604c-0.312,0.924-0.468,1.604-0.468,2.042
		c0.059,0.681,0.234,1.385,0.526,2.114c0.194-0.68,0.945-2.891,2.251-6.634C223.39,126.369,224.14,124.158,224.335,123.477z
		 M230.678,109.552l-2.835,8.53c2.201-2.771,4.291-5.747,6.269-8.931c1.978-3.183,3.766-6.452,5.364-9.806
		c1.597-3.354,2.854-6.5,3.77-9.441c0.916-2.94,1.374-5.406,1.374-7.399c0-0.875-0.107-1.555-0.321-2.042
		c-0.215-0.485-0.507-0.729-0.877-0.729c-1.286,0-2.957,2.625-5.013,7.874S233.775,100.172,230.678,109.552z"
        />
      </g>
      <path
        d="M239.191,224.676c0,28.972-17.5,52.508-39.094,52.508c-21.59,0-39.095-23.536-39.095-52.508
	c0-29.011,17.505-52.494,39.095-52.494C221.691,172.182,239.191,195.665,239.191,224.676z"
      />
      <g>
        <path
          d="M171.867,315.805c0.168,1.738-0.308,3.066-1.428,3.987c-0.842,0.511-1.949,0.141-3.322-1.106
		c-0.444-0.611-0.913-1.207-1.405-1.787c-0.859-0.601-2.497-0.687-4.914-0.26c-15.212,2.429-28.507,7.096-39.888,14
		c-1.781,1.08-3.48,2.232-5.095,3.451c-0.439,1.706-1.165,4.506-2.178,8.401c-0.435,1.542-1.876,2.657-4.326,3.345
		c-2.536,0.258-4.143-0.889-4.821-3.436c-1.362-4.935-2.181-9.356-2.455-13.271c-0.275-3.913,1.047-6.755,3.968-8.526
		c0.544-0.33,3.575-1.769,9.093-4.318c6.824-18.139,12.127-36.998,15.908-56.572c1.949-10.141,1.493-19.465-1.369-27.969
		c-0.895-2.656-1.721-4.315-2.479-4.977c-1.233-1.01-3.039-0.797-5.414,0.645c-0.891,0.54-1.848,1.241-2.869,2.1
		c-5.724,4.594-11.04,13.14-15.95,25.637c-1.155,2.942-1.397,5.409-0.727,7.401c-0.044-0.132,0.806,1.513,2.553,4.932
		c0.963,1.978,1.023,3.22,0.183,3.729c-0.792,0.48-1.896,0.39-3.309-0.272c-1.724-0.874-4.017-2.282-6.875-4.229
		c-2.396-1.984-3.885-3.842-4.466-5.57c-0.604-1.794-0.396-3.919,0.624-6.379c10.016-23.355,19.161-37.344,27.437-41.965
		c1.975-1.038,4.781,0.22,8.419,3.771c3.606-3.628,5.136-5.276,4.592-4.946c2.177-1.32,4.641,2.104,7.392,10.276
		c2.392,7.109,3.705,12.074,3.938,14.891c0.468,5.639-0.675,13.49-3.423,23.557c-5.395,19.914-11.055,37.71-16.981,53.384
		c12.553-6.255,26.193-9.811,40.92-10.665c3.627-0.199,6.077,1.593,7.352,5.38C170.999,309.771,171.437,312.226,171.867,315.805z"
        />
      </g>
      <g>
        <path
          d="M346.399,270.183c0.168,1.738-0.309,3.067-1.43,3.987c-0.841,0.511-1.949,0.141-3.321-1.105
		c-0.444-0.612-0.913-1.207-1.405-1.788c-0.858-0.6-2.496-0.686-4.914-0.259c-15.212,2.428-28.507,7.095-39.888,13.999
		c-1.781,1.081-3.48,2.232-5.095,3.451c-0.439,1.707-1.165,4.506-2.178,8.402c-0.435,1.542-1.876,2.656-4.326,3.345
		c-2.535,0.258-4.142-0.89-4.82-3.437c-1.361-4.934-2.182-9.356-2.455-13.271c-0.275-3.913,1.047-6.756,3.967-8.527
		c0.545-0.33,3.576-1.767,9.094-4.317c6.825-18.139,12.128-36.997,15.908-56.571c1.949-10.141,1.493-19.465-1.369-27.97
		c-0.894-2.656-1.721-4.315-2.479-4.977c-1.234-1.01-3.039-0.796-5.414,0.645c-0.891,0.541-1.848,1.242-2.868,2.1
		c-5.725,4.594-11.04,13.139-15.951,25.637c-1.155,2.941-1.397,5.409-0.727,7.401c-0.044-0.132,0.806,1.511,2.554,4.931
		c0.963,1.977,1.022,3.221,0.182,3.73c-0.792,0.48-1.895,0.389-3.309-0.273c-1.725-0.874-4.017-2.281-6.875-4.229
		c-2.397-1.984-3.886-3.842-4.467-5.57c-0.604-1.794-0.396-3.919,0.624-6.379c10.015-23.356,19.16-37.345,27.437-41.966
		c1.975-1.038,4.78,0.22,8.42,3.772c3.605-3.628,5.136-5.276,4.592-4.946c2.176-1.321,4.641,2.105,7.391,10.277
		c2.393,7.109,3.705,12.074,3.939,14.89c0.467,5.639-0.676,13.489-3.424,23.558c-5.395,19.914-11.054,37.709-16.98,53.383
		c12.553-6.254,26.193-9.81,40.92-10.664c3.627-0.199,6.078,1.593,7.353,5.38C345.53,264.149,345.969,266.604,346.399,270.183z"
        />
      </g>
      <g>
        <path
          d="M398.788,165.855c1.1,0.798,1.69,1.703,1.771,2.718c0.154,1.957-1.162,3.282-3.946,3.97
		c-4.271,9.552-7.824,22.299-10.661,38.244c-2.991,16.773-4.503,33.264-4.536,49.47c-0.018,4.622,0.581,8.056,1.796,10.302
		c0.446,0.815,0.707,1.697,0.781,2.639c0.171,2.175-0.581,3.381-2.254,3.618c-0.81,0.115-1.838-0.105-3.083-0.661
		c-3.67-1.529-5.827-2.581-6.473-3.148c-1.398-1.12-2.206-3.058-2.424-5.813c-0.16-2.029-0.106-5.153,0.162-9.37
		c1.836-25.186,4.928-45.529,9.275-61.03c-4.67,8.655-10.251,18.539-16.746,29.651c-0.639,0.898-1.418,1.411-2.335,1.541
		c-1.836,0.262-2.837-0.657-3.003-2.762c-0.085-1.087,0.29-2.202,1.129-3.35c8.896-11.821,16.707-28.398,23.434-49.736
		c0.655-2.073,1.16-3.281,1.516-3.625c0.463-0.359,1.368-0.305,2.716,0.163c0.999-3.953,2.47-6.069,4.414-6.345
		C392.209,162.062,395.032,163.238,398.788,165.855z"
        />
      </g>
      <g></g>
      <path
        d="M87.296,54.204l95.524-52.88l86.495,46.146l-13.181,9.332c0,0,3.03,13.998,0,31.627c0,0-5.462,18.137,0,25.401h-8.061
	c0,0-0.39-15.033,3.478-24.365c0,0,2.702-19.706-1.934-29.559L224.906,70.8l8.108,24.366c0,0-15.057-18.666-44.017-7.777
	c0,0,5.018,24.365-1.545,29.553c-6.563,5.176,3.379-12.83-2.413-15.17l-8.108-6.606l7.82-4.28c0,0-22.01-0.392-40.253,20.615
	c0,0-13.032-37.33,13.899-54.439c26.933-17.105,49.81-8.165,51.258-7.001c1.45,1.167-1.448-1.164-1.448-1.164
	s-26.351-17.889-69.212,14.379c0,0-1.158,22.942-0.579,26.057c0,0-48.942-31.885-52.706-31.491
	C81.946,58.22,87.296,54.204,87.296,54.204z"
      />
      <path
        d="M162.742,269.323c0,0,43.635,38.99,67.958,7.86c0,0,42.863-36.335,8.492-108.396c0,0,2.319-7.267,8.492,5.701
	c0,0,34.371,69.989-8.492,165.375c0,0-14.673,41.477-79.924,60.137c0,0-2.979-3.994,13.732-12.986
	c0.307-0.162,0.62-0.324,0.94-0.495c17.76-9.325,43.243-30.581,22.394-76.201c0,0-12.354-15.054-33.978-33.171
	C162.355,277.146,156.563,268.826,162.742,269.323z"
      />
      <path
        d="M269.314,300.961c0,0-2.325,31.123,16.986,22.815c0,0,6.948-23.319,16.991-22.815l-3.09,6.74c0,0-10.812,8.533-6.176,17.507
	l-6.18,8.443h-1.591c0,0-24.279,10.875-23.511-16.34C262.745,317.312,264.679,305.118,269.314,300.961z"
      />
      <path
        d="M303.292,300.961l-3.09,6.74c0,0,6.658,4.128-6.176,17.507l-6.18,8.443c0,0-3.375,13.474,7.726,10.737
	c0,0,19.495,2.09,27.384-1.705c0.513-0.236,0.973-0.499,1.382-0.809c6.652-4.856,27.218-28.212,44.015-25.465
	c0,0,17.083,1.425,6.659,19.392c0,0-5.215,8.587-13.035-10.456l-13.896,7.003c0,0,9.844,17.078,25.486,12.627
	c0,0,13.318-12.168,8.105-26.587c0,0-6.085-14.813-15.062-13.271c0,0-8.396-3.971-45.462,30.684c0,0-32.432,6.65-22.3-9.667
	C298.85,326.135,311.209,310.465,303.292,300.961z"
      />
      <path
        d="M230.31,128.606c0,0,11.782-9.072,11.972,4.666c0,0-2.313,20.474,10.426,11.924l5.604-5.963v12.184
	c0,0-15.641,8.296-19.12-9.854c0,0,2.702-19.18-8.882-8.813V128.606z"
      />
      <path
        d="M106.271,119.854c0,0,8.898,29.742-21.903,19.634l-31.96-3.497l-30.118,1.167c0,0-28.379,15.168-18.824,37.33
	c0,0,15.35,30.721,52.416,13.98c0,0,22.299-16.394,5.214-31.317c0,0-34.172-4.825-41.412,22.789c0,0-3.765,5.429-6.371,0.376
	c0,0-17.375-15.941,5.211-34.22c0,0-0.433,12.441,9.702,6.619c0,0,5.067,3.294,4.778,7.964c0,0,3.475-2.72-2.171-10.492
	c0,0,1.013-7.973-6.372-8.172c0,0,32.725-3.885,49.376,2.917c0,0,15.203,3.889,21.285,0.974c0,0,7.818-1.554,10.425-4.082
	c0,0,10.859-11.707,3.041-27.994C108.588,113.831,109.891,123.158,106.271,119.854z"
      />
      <path
        d="M366.229,121.604c0,0-45.178,5.702-80.699-18.146c0,0-23.208-5.702-22.803,31.624c0,0,0.392,35.583,20.286,18.563
	c0,0,13.809-13.381,5.987-39.813c0,0-15.641-17.371-23.166,10.103c0,0-1.008,36.555,13.543,22.164c0,0,5.907-7.404-4.567-3.109
	c0,0-13.315-15.168,1.735-29.157c0,0,14.424,1.825,9.772,25.658c-0.145,0.755-0.312,1.531-0.501,2.336
	c-6.077,26.05-19.982,10.107-19.982,5.836c0,0-11.492-47.674,21.528-38.502c0,0,16.405,17.502,44.5,19.058
	c0,0,36.777,3.802,38.515,2.489C372.12,129.378,369.026,124.718,366.229,121.604z"
      />
      <path
        d="M370.378,130.704c0,0,17.376,21.711-8.684,36.043c0,0-19.405-11.895-36.198-8.494c0,0,21.389-12.895,14.453-29.238
	L370.378,130.704z"
      />
      <path
        d="M326.843,158.033c0,0-41.603-2.788-54.92,0.22c0,0,23.833,0.419,17.078-44.422c0,0,15.473,15.184,33.211,15.184
	c17.737,0-5.408,1.689-5.408,1.689S335.344,134.842,326.843,158.033z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

Artwrk22.props = {
  primary: string,
};

Artwrk22.defaultProps = {
  primary: "#000",
};
