import React from "react";
import { string } from "prop-types";

export default function Artwrk22(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      enable-background="new 0 0 400 400"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M91.821,138.643c-5.329,1.024-9.2,0.511-11.615-1.539c-2.415-2.049-3.764-4.858-4.049-8.427
		c-0.154-1.934,0.17-4.146,0.972-6.64c0.802-2.493,2.331-4.985,4.588-7.477c2.256-2.49,5.494-4.719,9.716-6.686
		c4.221-1.965,9.569-3.321,16.047-4.064l0.063,0.78c-6.756,0.775-12.287,2.208-16.592,4.296c-4.306,2.089-7.455,4.363-9.447,6.823
		c-1.993,2.462-3.305,4.835-3.936,7.122c-0.631,2.287-0.877,4.284-0.741,5.994c0.275,3.458,1.648,6.077,4.12,7.856
		c2.471,1.78,6.264,2.19,11.379,1.228c2.308-3.603,5.125-7.5,8.449-11.69c3.326-4.188,6.803-8.124,10.435-11.805
		c3.63-3.681,7.179-6.741,10.643-9.185c3.464-2.441,6.544-3.817,9.24-4.127c2.354-0.27,3.638,0.915,3.848,3.554
		c0.226,2.826-1.405,6.306-4.891,10.438c-3.486,4.134-8.167,8.169-14.045,12.108c-5.878,3.937-12.184,7.213-18.918,9.823
		c-2.689,4.323-4.816,8.047-6.38,11.171c-1.565,3.125-2.69,5.879-3.375,8.266c-0.686,2.387-0.953,4.527-0.801,6.423
		c0.148,1.859,0.998,3.402,2.55,4.631c1.552,1.228,3.49,1.71,5.814,1.444c3.161-0.363,6.273-1.724,9.337-4.082
		c3.064-2.358,5.541-5.128,7.43-8.309c1.888-3.18,2.733-6.017,2.535-8.508c-0.16-2.007-0.761-3.458-1.801-4.352
		c-1.041-0.894-2.444-1.239-4.21-1.037c-2.355,0.271-5.051,1.866-8.085,4.782c-3.036,2.919-5.828,8.014-8.375,15.284l-0.695-0.483
		c2.31-6.717,4.992-11.715,8.046-14.993c3.054-3.276,6.099-5.089,9.136-5.438c2.014-0.231,3.582,0.208,4.702,1.317
		c1.12,1.11,1.762,2.688,1.925,4.733c0.168,2.118-0.284,4.385-1.358,6.795c-1.075,2.413-2.618,4.728-4.629,6.948
		c-2.012,2.219-4.227,4.058-6.644,5.517c-2.417,1.458-4.771,2.321-7.064,2.584c-3.068,0.352-5.518-0.277-7.351-1.888
		c-1.833-1.609-2.889-4.179-3.171-7.712c-0.251-3.158,0.152-6.338,1.21-9.537S88.885,143.406,91.821,138.643z M97.674,136.171
		c4.96-2.108,9.766-4.591,14.417-7.451c4.65-2.859,8.67-5.769,12.063-8.728c3.392-2.959,5.968-5.75,7.729-8.372
		c1.761-2.622,2.561-4.955,2.397-7c-0.163-2.045-1.205-2.958-3.126-2.738c-3.13,0.359-6.705,2.309-10.722,5.846
		c-4.018,3.538-7.978,7.808-11.878,12.815C104.654,125.549,101.027,130.759,97.674,136.171z"
        />
        <path
          d="M118.327,151.642c1.288-1.499,2.566-3.015,3.836-4.549c1.269-1.533,2.536-2.992,3.803-4.375
		c-0.34-1.124-0.298-2.169,0.125-3.139c0.422-0.967,0.99-1.491,1.704-1.573c0.402-0.046,0.77,0.051,1.102,0.294
		c0.332,0.244,0.515,0.569,0.547,0.979c0.039,0.483-0.063,0.889-0.302,1.216c-0.239,0.328-0.545,0.513-0.917,0.556
		c-0.372,0.042-0.913-0.27-1.623-0.939c-0.163,0.319-0.223,0.739-0.182,1.259c0.004,0.45,0.185,0.851,0.542,1.204
		c0.356,0.354,0.736,0.507,1.139,0.46c0.279-0.032,0.69-0.155,1.237-0.367c0.608-0.22,0.974-0.336,1.098-0.351
		c0.248-0.028,0.479,0.227,0.694,0.765c0.214,0.537,0.353,1.197,0.416,1.978c0.053,0.669-0.256,1.596-0.928,2.78
		c-0.672,1.184-1.838,3.128-3.499,5.831c-1.661,2.705-2.806,4.62-3.436,5.742c-0.63,1.123-0.923,1.962-0.878,2.52
		c0.044,0.559,0.376,0.801,0.997,0.73c0.867-0.1,2.622-1.529,5.264-4.29c2.642-2.761,5.688-6.233,9.138-10.418l0.075,0.947
		c-7.657,9.471-12.586,14.331-14.786,14.583c-0.837,0.096-1.304-0.469-1.401-1.696c-0.139-1.747,0.015-3.436,0.464-5.063
		c0.449-1.627,1.295-3.469,2.539-5.524l4.865-7.819c-0.628,0.374-1.354,0.587-2.178,0.644c-0.824,0.058-1.349-0.164-1.577-0.663
		c-3.795,4.563-6.398,7.62-7.806,9.168L118.327,151.642z"
        />
        <path
          d="M158.005,145.684l0.075,0.947c-1.537,2.69-3.375,4.158-5.513,4.403c-0.22-0.011-0.44-0.023-0.659-0.037
		c-0.22-0.011-0.471-0.021-0.752-0.026c-4.467,6.741-8.513,10.318-12.139,10.734c-0.681,0.078-1.326-0.063-1.933-0.425
		c-0.607-0.361-1.086-0.88-1.438-1.552c-0.35-0.672-0.563-1.473-0.637-2.403c-0.199-2.491,0.522-5.031,2.16-7.621
		c1.639-2.588,3.692-4.785,6.16-6.588c2.468-1.802,4.678-2.815,6.63-3.039c2.107-0.242,3.254,0.809,3.441,3.15
		c0.151,1.896-0.432,4.196-1.749,6.899c0.321,0.113,0.668,0.148,1.041,0.105C154.644,150.008,156.414,148.492,158.005,145.684z
		 M150.161,140.842c-1.736,0.199-3.632,1.608-5.691,4.228s-3.866,5.444-5.423,8.472c-1.556,3.031-2.308,4.88-2.255,5.549
		c0.047,0.595,0.312,1.062,0.792,1.401c0.481,0.338,1.063,0.468,1.743,0.39c1.643-0.188,3.541-1.391,5.693-3.607
		c2.153-2.216,4.007-4.755,5.562-7.616c1.554-2.861,2.271-5.053,2.149-6.578c-0.059-0.743-0.333-1.322-0.818-1.735
		C151.426,140.932,150.842,140.764,150.161,140.842z"
        />
        <path
          d="M183.323,136.701l-10.868,17.848c-0.331,0.564-0.478,1.069-0.442,1.514c0.056,0.707,0.394,1.024,1.014,0.953
		c2.231-0.256,4.841-1.85,7.828-4.782c2.986-2.932,5.14-5.542,6.458-7.832c0.857-1.411,1.412-3.275,1.666-5.594
		c0.203-2.162,0.599-3.276,1.188-3.344c0.434-0.05,0.674,0.223,0.722,0.818c0.216,2.714-1.079,6.089-3.884,10.125
		c1.328,0.186,2.52-0.064,3.576-0.747c1.055-0.684,2.097-2.042,3.124-4.073l0.075,0.947c-0.828,1.783-1.813,3.05-2.957,3.8
		c-1.145,0.75-2.632,1.024-4.461,0.821c-4.912,6.642-9.429,10.198-13.55,10.671c-1.271,0.146-1.963-0.506-2.079-1.956
		c-0.216-2.713,1.766-7.273,5.946-13.682c-4.273,5.63-7.784,9.85-10.531,12.66c-2.747,2.811-4.973,4.313-6.677,4.508
		c-0.991,0.114-1.545-0.554-1.661-2.004c-0.222-2.788,1.673-7.451,5.688-13.99c0.784-1.141,1.404-2.186,1.86-3.139
		c-2.875,1.98-5.637,4.605-8.285,7.871L157,147.205c1.574-1.831,3.048-3.445,4.422-4.84c1.374-1.396,3.034-2.636,4.98-3.723
		l3.904-0.448l-9.929,16.671c-0.845,1.561-1.256,2.49-1.232,2.787c0.015,0.187,0.108,0.381,0.281,0.587
		c0.173,0.205,0.336,0.298,0.492,0.281c1.177-0.135,3.107-1.623,5.79-4.463c2.682-2.84,5.084-5.656,7.207-8.452
		c0.984-1.387,2.101-2.866,3.352-4.437c1.251-1.568,2.302-2.908,3.151-4.019L183.323,136.701z"
        />
        <path
          d="M192.677,144.013l-0.071-0.892c4.292-5.782,7.384-8.782,9.273-8.999c1.054-0.121,1.645,0.619,1.772,2.217
		c0.101,1.264-0.126,2.547-0.679,3.848s-1.7,3.591-3.44,6.866c3.537-4.27,6.626-7.701,9.265-10.292
		c2.639-2.591,4.966-4.003,6.98-4.234c1.054-0.121,1.65,0.693,1.79,2.44c0.136,1.711-0.225,3.468-1.081,5.274
		c-0.857,1.805-2.338,3.823-4.443,6.052c-2.242,2.472-3.326,4.172-3.252,5.102c0.029,0.371,0.203,0.698,0.523,0.979
		c0.319,0.283,0.634,0.406,0.944,0.371c1.084-0.125,2.46-1.004,4.129-2.64c1.667-1.636,3.466-3.681,5.397-6.135
		c1.931-2.453,3.494-4.425,4.688-5.912l0.076,0.947c-1.722,2.336-3.408,4.527-5.059,6.574c-1.651,2.046-3.313,3.851-4.986,5.412
		c-1.675,1.562-3.1,2.409-4.276,2.544c-0.744,0.085-1.303-0.15-1.676-0.708c-0.372-0.558-0.584-1.152-0.634-1.785
		c-0.113-1.412,0.226-3.054,1.015-4.928c0.789-1.872,1.937-3.739,3.446-5.601c1.729-2.224,2.911-3.982,3.544-5.273
		c0.631-1.292,0.374-1.872-0.772-1.741c-1.457,0.167-3.563,1.6-6.318,4.298c-2.756,2.699-5.315,5.506-7.678,8.421
		c-2.364,2.917-4.591,5.771-6.683,8.561l-3.626,0.416l5.516-9.074c4.042-6.578,6.04-10.146,5.996-10.705
		c-0.03-0.37-0.23-0.536-0.603-0.494C200.392,135.08,197.366,138.11,192.677,144.013z"
        />
      </g>
      <g>
        <path
          d="M237.542,142.285c-3.006,0.229-5.134-0.302-6.385-1.595s-1.877-2.939-1.877-4.94c0-1.084,0.278-2.293,0.834-3.627
		c0.557-1.333,1.516-2.621,2.88-3.861s3.262-2.272,5.694-3.096c2.433-0.823,5.464-1.235,9.096-1.235v0.438
		c-3.788,0-6.924,0.443-9.408,1.329c-2.484,0.886-4.335,1.949-5.551,3.189c-1.217,1.241-2.051,2.476-2.502,3.706
		c-0.452,1.23-0.678,2.325-0.678,3.283c0,1.938,0.647,3.481,1.941,4.627c1.294,1.147,3.384,1.616,6.268,1.407
		c1.442-1.855,3.18-3.841,5.213-5.957c2.032-2.116,4.139-4.081,6.319-5.894s4.287-3.288,6.32-4.424s3.805-1.705,5.316-1.705
		c1.32,0,1.98,0.74,1.98,2.22c0,1.584-1.06,3.414-3.179,5.488c-2.12,2.074-4.9,4.018-8.34,5.832
		c-3.44,1.813-7.089,3.231-10.946,4.252c-1.686,2.231-3.032,4.164-4.039,5.8c-1.008,1.637-1.756,3.096-2.241,4.377
		c-0.487,1.282-0.73,2.455-0.73,3.518c0,1.043,0.404,1.955,1.212,2.736c0.809,0.782,1.863,1.172,3.167,1.172
		c1.771,0,3.562-0.558,5.368-1.673c1.807-1.115,3.306-2.496,4.496-4.143c1.189-1.646,1.785-3.168,1.785-4.565
		c0-1.126-0.27-1.97-0.808-2.533c-0.539-0.563-1.304-0.844-2.294-0.844c-1.32,0-2.889,0.714-4.704,2.142
		c-1.815,1.428-3.593,4.081-5.329,7.958l-0.365-0.313c1.581-3.585,3.292-6.191,5.134-7.817s3.614-2.439,5.316-2.439
		c1.13,0,1.981,0.344,2.555,1.032s0.859,1.605,0.859,2.752c0,1.188-0.352,2.418-1.055,3.689c-0.704,1.271-1.664,2.46-2.88,3.564
		c-1.217,1.105-2.528,1.986-3.936,2.642c-1.407,0.657-2.754,0.985-4.039,0.985c-1.721,0-3.054-0.505-4.001-1.517
		c-0.947-1.011-1.42-2.506-1.42-4.487c0-1.771,0.364-3.512,1.094-5.222C234.415,146.829,235.7,144.745,237.542,142.285z
		 M240.904,141.284c2.849-0.854,5.629-1.928,8.34-3.221c2.71-1.292,5.072-2.652,7.088-4.08s3.571-2.814,4.665-4.159
		c1.095-1.345,1.643-2.59,1.643-3.737c0-1.146-0.539-1.72-1.616-1.72c-1.755,0-3.827,0.855-6.216,2.564
		c-2.389,1.709-4.778,3.831-7.167,6.363S243.006,138.491,240.904,141.284z"
        />
        <path
          d="M279.433,113.004l2.226-2.885h3.429l-28.049,37.659c-3.354,4.416-5.031,6.998-5.031,7.745c0,0.677,0.296,1.015,0.891,1.015
		c1.038,0,2.226-0.525,3.562-1.576c1.336-1.05,3.139-2.804,5.409-5.261s3.94-4.237,5.009-5.342v0.908
		c-4.037,4.452-6.99,7.567-8.859,9.348c-1.87,1.781-3.577,2.671-5.12,2.671c-0.534,0-0.986-0.24-1.358-0.721
		c-0.37-0.48-0.557-1.042-0.557-1.683c0-1.816,0.742-3.934,2.227-6.356c1.483-2.421,3.487-5.252,6.011-8.493
		c0.178-0.285,0.712-1.042,1.603-2.271c0.891-1.229,1.751-2.448,2.582-3.659c-2.048,1.852-5.996,5.894-11.843,12.125v-0.854
		l12.645-13.141C265.749,130.667,270.824,124.258,279.433,113.004z"
        />
        <path
          d="M290.474,145.268l-2.271,2.297c-3.563,3.562-6.196,6.072-7.903,7.532c-1.707,1.46-3.213,2.19-4.519,2.19
		c-0.861,0-1.291-0.587-1.291-1.763c0-1.246,0.4-2.991,1.202-5.235c-4.245,4.666-7.376,6.998-9.395,6.998
		c-0.891,0-1.617-0.418-2.182-1.255c-0.563-0.836-0.846-2.056-0.846-3.659c0-1.816,0.445-3.606,1.336-5.369s2.085-3.32,3.584-4.674
		c1.498-1.353,3.176-2.421,5.03-3.205c1.855-0.783,3.658-1.175,5.41-1.175c0.86,0,1.491,0.187,1.892,0.561
		c0.401,0.374,0.616,0.988,0.646,1.843c-0.029,0.179-0.052,0.347-0.066,0.508c-0.016,0.16-0.045,0.383-0.089,0.667
		c-0.045,0.286-0.082,0.517-0.111,0.694l2.805-3.953l3.74-0.321l-1.158,1.709c-0.95,1.354-1.722,2.466-2.314,3.338
		c-0.595,0.873-1.099,1.594-1.515,2.164c-4.63,6.019-6.945,9.544-6.945,10.577c0,0.534,0.208,0.801,0.624,0.801
		c0.563,0,1.409-0.401,2.537-1.202s2.396-1.86,3.807-3.178c1.409-1.317,3.021-2.875,4.831-4.674c1.81-1.798,2.864-2.839,3.161-3.125
		V145.268z M278.72,138.698c-1.781,0-3.762,1.051-5.943,3.151c-2.182,2.102-4.082,4.487-5.699,7.158s-2.427,4.559-2.427,5.662
		c0,0.534,0.149,0.98,0.445,1.335c0.297,0.356,0.668,0.534,1.113,0.534c1.217,0,2.968-1.015,5.254-3.045
		c2.285-2.03,4.348-4.327,6.188-6.891c1.84-2.564,2.76-4.576,2.76-6.036C280.411,139.321,279.848,138.698,278.72,138.698z"
        />
        <path
          d="M289.36,145.481l9.305-9.615c0.713-1.033,1.365-1.985,1.959-2.858c0.594-0.872,1.024-1.309,1.291-1.309
		c0.237,0,0.452,0.08,0.646,0.24s0.29,0.401,0.29,0.721c0,0.677-0.817,1.514-2.449,2.511c0.95,1.709,1.632,3.205,2.048,4.487
		c0.416,1.282,0.668,2.903,0.758,4.861c-0.09,1.211-0.208,2.226-0.356,3.045c1.068,0.32,2.04,0.294,2.916-0.08
		c0.875-0.374,2.085-1.486,3.629-3.339v0.908c-1.188,1.781-2.286,2.885-3.295,3.312c-1.01,0.427-2.182,0.41-3.518-0.054
		c-0.712,1.923-1.87,3.571-3.473,4.941c-1.603,1.371-3.25,2.386-4.941,3.044c-1.692,0.66-3.146,0.988-4.363,0.988
		c-1.158,0-2.1-0.275-2.827-0.828c-0.728-0.552-1.091-1.327-1.091-2.324c0-0.89,0.216-1.611,0.646-2.164
		c0.431-0.552,1.062-0.828,1.893-0.828c0.534,0,0.972,0.169,1.313,0.507c0.341,0.339,0.513,0.793,0.513,1.362
		c0,0.463-0.186,0.873-0.557,1.229c-0.371,0.356-0.764,0.534-1.18,0.534c-0.563,0-0.979-0.552-1.247-1.656
		c-0.385,0.321-0.579,0.801-0.579,1.442c0,0.392,0.306,0.82,0.913,1.282c0.608,0.463,1.313,0.694,2.115,0.694
		c1.572,0,3.234-0.81,4.986-2.431c1.751-1.62,3.212-3.659,4.385-6.116s1.759-4.825,1.759-7.104c0-1.637-0.431-3.312-1.291-5.021
		l-10.195,10.47V145.481z"
        />
        <path
          d="M308.326,145.481l9.305-9.615c0.713-1.033,1.365-1.985,1.959-2.858c0.594-0.872,1.024-1.309,1.291-1.309
		c0.237,0,0.452,0.08,0.646,0.24s0.29,0.401,0.29,0.721c0,0.677-0.817,1.514-2.449,2.511c0.95,1.709,1.632,3.205,2.048,4.487
		c0.416,1.282,0.668,2.903,0.758,4.861c-0.09,1.211-0.208,2.226-0.356,3.045c1.068,0.32,2.04,0.294,2.916-0.08
		c0.875-0.374,2.085-1.486,3.629-3.339v0.908c-1.188,1.781-2.286,2.885-3.295,3.312c-1.01,0.427-2.182,0.41-3.518-0.054
		c-0.712,1.923-1.87,3.571-3.473,4.941c-1.603,1.371-3.25,2.386-4.941,3.044c-1.692,0.66-3.146,0.988-4.363,0.988
		c-1.158,0-2.1-0.275-2.827-0.828c-0.728-0.552-1.091-1.327-1.091-2.324c0-0.89,0.216-1.611,0.646-2.164
		c0.431-0.552,1.062-0.828,1.893-0.828c0.534,0,0.972,0.169,1.313,0.507c0.341,0.339,0.513,0.793,0.513,1.362
		c0,0.463-0.186,0.873-0.557,1.229c-0.371,0.356-0.764,0.534-1.18,0.534c-0.563,0-0.979-0.552-1.247-1.656
		c-0.385,0.321-0.579,0.801-0.579,1.442c0,0.392,0.306,0.82,0.913,1.282c0.608,0.463,1.313,0.694,2.115,0.694
		c1.572,0,3.234-0.81,4.986-2.431c1.751-1.62,3.212-3.659,4.385-6.116s1.759-4.825,1.759-7.104c0-1.637-0.431-3.312-1.291-5.021
		l-10.195,10.47V145.481z"
        />
      </g>
      <g>
        <path
          d="M160.571,209.813h0.777c-2.247,8.958-5.13,15.979-8.651,21.067c-3.521,5.089-6.665,7.631-9.429,7.631
		c-0.865,0-1.631-0.483-2.3-1.451c-0.67-0.967-1.004-2.203-1.004-3.708c0-5.015,1.199-9.959,3.597-14.833
		c2.397-4.872,5.335-8.849,8.813-11.931c3.477-3.08,6.729-4.622,9.753-4.622c1.9,0,3.347,0.932,4.341,2.795
		c0.994,1.864,1.491,4.514,1.491,7.954c0,6.448-1.081,12.057-3.24,16.821c-2.161,4.766-4.957,8.797-8.392,12.092
		c-3.435,3.297-7.744,6.879-12.928,10.749c-4.364,3.153-7.496,5.554-9.396,7.201c-1.901,1.649-3.931,3.941-6.091,6.879
		c2.419-1.218,4.536-1.897,6.351-2.042c2.635,0.215,5.162,1.255,7.582,3.117c1.079,0.789,1.998,1.381,2.754,1.773
		c0.755,0.395,1.523,0.591,2.3,0.591c1.08,0,2.225-0.733,3.435-2.203c1.209-1.468,2.613-3.958,4.212-7.471l0.843,0.968
		c-2.074,4.587-4.083,7.919-6.027,9.996c-1.944,2.078-4.018,3.117-6.221,3.117c-1.124,0-3.024-0.932-5.703-2.795
		c-3.068-2.22-5.638-3.332-7.711-3.332c-2.375,0-5.076,2.043-8.1,6.127h-1.166c2.98-5.087,8.769-11.679,17.366-19.777
		c7.689-7.165,13.176-12.969,16.459-17.413c3.023-4.154,5.724-8.902,8.101-14.241c2.375-5.338,3.564-9.978,3.564-13.92
		c0-3.797-1.275-5.696-3.823-5.696c-2.55,0-5.498,1.811-8.846,5.428c-3.348,3.619-6.232,7.865-8.651,12.737
		c-2.42,4.874-3.629,8.851-3.629,11.931c0,2.365,0.885,3.547,2.657,3.547c1.986,0,4.633-2.149,7.938-6.449
		C154.9,226.15,157.892,219.271,160.571,209.813z"
        />
        <path
          d="M207.486,201.966c1.426,0,2.537,0.698,3.338,2.096c0.799,1.397,1.199,3.315,1.199,5.751c0,5.876-2.27,14.026-6.805,24.453
		c-4.536,10.426-9.829,19.706-15.876,27.839c-6.049,8.133-11.146,12.199-15.293,12.199c-3.413,0-5.119-3.797-5.119-11.394
		c0-6.807,1.286-13.793,3.855-20.96c2.57-7.164,5.951-13.812,10.142-19.938c4.189-6.127,8.51-10.999,12.96-14.618
		C200.336,203.776,204.203,201.966,207.486,201.966z M207.486,203.471c-3.154,0-6.836,2.383-11.048,7.147
		c-4.212,4.767-8.294,10.714-12.247,17.843c-3.953,7.131-7.226,14.296-9.817,21.497c-2.592,7.202-3.888,12.845-3.888,16.93
		c0,1.648,0.345,3.046,1.037,4.191c0.69,1.147,1.533,1.72,2.527,1.72c2.894,0,6.425-2.364,10.595-7.094
		c4.168-4.729,8.24-10.588,12.215-17.574c3.974-6.986,7.301-14.063,9.979-21.229c2.68-7.164,4.018-12.934,4.018-17.305
		c0-2.006-0.291-3.529-0.873-4.568C209.398,203.991,208.566,203.471,207.486,203.471z"
        />
        <path
          d="M243.646,209.813h0.778c-2.247,8.958-5.131,15.979-8.651,21.067c-3.521,5.089-6.664,7.631-9.429,7.631
		c-0.864,0-1.631-0.483-2.3-1.451c-0.671-0.967-1.005-2.203-1.005-3.708c0-5.015,1.199-9.959,3.597-14.833
		c2.397-4.872,5.335-8.849,8.813-11.931c3.478-3.08,6.729-4.622,9.753-4.622c1.9,0,3.348,0.932,4.342,2.795
		c0.993,1.864,1.49,4.514,1.49,7.954c0,6.448-1.08,12.057-3.24,16.821c-2.16,4.766-4.957,8.797-8.392,12.092
		c-3.435,3.297-7.744,6.879-12.928,10.749c-4.364,3.153-7.496,5.554-9.396,7.201c-1.901,1.649-3.932,3.941-6.091,6.879
		c2.419-1.218,4.536-1.897,6.351-2.042c2.634,0.215,5.161,1.255,7.581,3.117c1.08,0.789,1.998,1.381,2.754,1.773
		c0.756,0.395,1.523,0.591,2.301,0.591c1.079,0,2.225-0.733,3.435-2.203c1.209-1.468,2.613-3.958,4.212-7.471l0.843,0.968
		c-2.074,4.587-4.083,7.919-6.026,9.996c-1.944,2.078-4.018,3.117-6.222,3.117c-1.123,0-3.024-0.932-5.702-2.795
		c-3.067-2.22-5.638-3.332-7.711-3.332c-2.376,0-5.076,2.043-8.101,6.127h-1.166c2.98-5.087,8.77-11.679,17.366-19.777
		c7.689-7.165,13.176-12.969,16.46-17.413c3.023-4.154,5.724-8.902,8.1-14.241c2.376-5.338,3.564-9.978,3.564-13.92
		c0-3.797-1.274-5.696-3.823-5.696c-2.55,0-5.498,1.811-8.846,5.428c-3.349,3.619-6.231,7.865-8.65,12.737
		c-2.42,4.874-3.629,8.851-3.629,11.931c0,2.365,0.885,3.547,2.656,3.547c1.987,0,4.634-2.149,7.938-6.449
		C237.976,226.15,240.967,219.271,243.646,209.813z"
        />
        <path
          d="M259.521,240.445l-0.389-1.72c2.894-2.149,6.48-4.944,10.757-8.384c4.277-3.439,8.305-6.807,12.086-10.104
		c3.779-3.295,7.344-6.573,10.691-9.835c3.348-3.26,6.06-6.073,8.133-8.438h0.972l-38.232,71.263h-6.415l39.205-64.062
		C283.239,221.708,270.969,232.134,259.521,240.445z"
        />
      </g>
      <path
        d="M53.134,53.935c0,0-11.69-15.912,26.649-48.417c0,0,2.503,34.87-15.875,49.352l-6.912,9.213c0,0,7.282-10.083,24.078-5.079
	c0,0-4.106,35.301-32.049,28.458c0,0-8.304,12.674-7.817,17.174c0,0,11.028-10.63,26.775-12.882c0,0-0.527,32.068-28.189,35.955
	c0,0-4.601,13.88-1.25,20.825c0,0,4.601-17.871,19.081-21.982c0,0,3.106,41.837-22.467,50.943c0,0-4.76,21.597,1.369,31.027
	c0,0-1.143-25.013,17.917-35.896c0,0,14.719,24.448-10.841,54.334c0,0,2.63,24.384,6.04,26.43c0,0-1.5-15.281,8.962-41.136
	c0,0,21.385,27.619,0.915,59.831c0,0,3.796,23.516,12.988,31.415c0,0-5.235-12.063-0.96-46.487c0,0,24.193,5.693,15.804,59.362
	c0,0,5.979,12.091,14.473,19.003c0,0-8.867-13.199-8.614-42.655c0,0,24.405,14.646,26.043,49.547c0,0,6.604,13.881,18.444,14.933
	c0,0-15.634-12.063-18.546-38.114c0,0,30.591,10.634,37.039,39.689c0,0,4.121,5.735,14.032,1.195c0,0,14.525-5.89,24.395-3.234
	l5.624,1.588c0,0-12.83,0.045-23.011,11.933c0,0,11.833-7.559,16.969-6.437c0,0-8.377,11.967-37.852,10.774
	c0,0-9.513,37.974-46.619,20.138c0,0,10.35-28.58,28.494-24.324c0,0-6.116-0.569-16.097-10.776c0,0-17.139,35.161-53.024,1.438
	c0,0,18.837-17.075,34.771-12.971c0,0-16.654-10.83-18.632-15.042c0,0-18.051,22.577-49.545-19.606c0,0,12.656-16.244,33.933,0.963
	c0,0-14.461-19.363-14.066-26.017c0,0-31.089,16.762-44.405-40.517c0,0,13.833-3.333,32.016,14.637c0,0-4.25-12.967-5.961-21.752
	c0,0-30.264,2.76-36.207-56.64c0,0,18.109,4.892,25.395,22.949c0,0-0.326-13.435,0.465-17.413c0,0-28.521,1.573-22.506-62.101
	c0,0,9.276-1.832,19.624,26.423c0,0,1.778-11.203,3.954-10.366c2.176,0.887-2.176-0.837-2.176-0.837s-26.438-24.315-7.024-69.488
	c0,0,13.903,19.232,13.079,33.234c0,0-1.188-8.151,0.824-14.002c0,0-16.333-34.831,7.902-67.533c0,0,6.908,15.315,6.317,34.642
	l-0.287,4.666C46.566,64.301,52.205,57,53.134,53.935z"
      />
      <path
        d="M351.743,56.424c0,0,12.042-15.578-25.605-49.276c0,0-3.25,34.798,14.797,49.858l6.71,9.424c0,0-7.062-10.319-23.952-5.844
	c0,0,3.349,35.434,31.438,29.495c0,0,7.994,12.923,7.438,17.401c0,0-10.813-11.021-26.492-13.719c0,0-0.203,32.044,27.392,36.819
	c0,0,4.288,13.997,0.78,20.859c0,0-4.206-18.023-18.601-22.575c0,0-4.009,41.705,21.356,51.659c0,0,4.284,21.705-2.042,30.986
	c0,0,1.679-25.029-17.122-36.459c0,0-15.257,23.947,9.646,54.619c0,0-3.187,24.227-6.618,26.189c0,0,1.804-15.243-8.066-41.34
	c0,0-21.984,26.91-2.232,59.711c0,0-4.317,23.41-13.682,30.988c0,0,5.49-11.839,1.975-46.354c0,0-24.298,4.826-17.067,58.778
	c0,0-6.237,11.869-14.916,18.572c0,0,9.177-12.965,9.563-42.357c0,0-24.705,13.859-27.145,48.683c0,0-6.881,13.753-18.756,14.333
	c0,0,15.905-11.492,19.39-37.556c0,0-30.811,9.7-37.89,38.598c0,0-4.251,5.548-14.07,0.763c0,0-14.385-6.417-24.28-4.104
	l-5.699,1.454c0,0,12.828,0.446,22.77,12.672c0,0-11.661-7.938-16.794-6.961c0,0,8.058,12.25,37.565,11.965
	c0,0,8.682,38.288,46.169,21.55c0,0-9.726-28.909-27.967-25.183c0,0,6.125-0.331,16.323-10.202c0,0,16.365,35.656,53,3.071
	c0,0-18.457-17.606-34.465-14.072c0,0,16.862-10.333,18.923-14.399c0,0,17.561,23.097,49.974-18.037c0,0-12.328-16.734-33.957-0.23
	c0,0,14.876-18.821,14.613-25.484c0,0,30.729,17.768,45.29-39.159c0,0-13.744-3.66-32.296,13.679c0,0,4.505-12.863,6.408-21.604
	c0,0,30.182,3.67,37.45-55.426c0,0-18.22,4.314-25.88,22.099c0,0,0.593-13.367-0.083-17.372c0,0,28.443,2.491,23.863-61.356
	c0,0-9.236-2.148-20.197,25.756c0,0-1.538-11.242-3.748-10.457c-2.175,0.786,2.21-0.785,2.21-0.785s26.95-23.487,8.551-69.255
	c0,0-14.338,18.798-13.791,32.819c0,0,1.349-8.121-0.547-14.021c0,0,17.094-34.295-6.41-67.759c0,0-7.246,15.078-7.06,34.441
	l0.158,4.673C358.066,66.992,352.594,59.478,351.743,56.424z"
      />
      <path
        fill="#E0AC18"
        d="M164.73,47.21l24.384-12.031c0,0,13.431-3.104-2.142,6.228l-22.069,10.934c0,0-0.394,1.084,5.55-0.239
	L194.637,39.2c4.909-0.865,8.613,0.329-1.178,5.078l-22.32,11.806c0,0-4.079,12.007-1.118,20.005c0,0-3.262-14.696-2.535-4.001
	c0,0,1.809,32.923-3.948,41.798c0,0-13.523,7.577-11.307-14.2c0,0,1.49-9.785,5.942-16.453
	C158.174,83.233,155.287,60.545,164.73,47.21z"
      />
      <path
        fill="#C5B38D"
        d="M167.245,69.205c0,0-5.703,5.905-6.821-3.1c0,0-3.604-2.563-3.045,5.408
	C157.379,71.514,162.523,82.888,167.245,69.205z"
      />
      <path
        d="M141.949,26.672C140.563,25.581,182.26,11.344,204.084,0c31.268,37.371,30.934,37.463,45.722,46.693
	c0,0,3.893,0.674-25.597,20.675c0,0,6.13,24.032-34.461,37.366c0,0-33.343-22.026-18.888-48.71c0,0,38.507-16.005,25.093-14.676
	l-21.2,11.01c0,0-23.919,7.688,2.775-6.984l14.883-6.451c0,0,9.016-9.568-27.682,9.786C164.73,48.71,148.618,32.024,141.949,26.672z
	"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

Artwrk22.props = {
  primary: string,
};

Artwrk22.defaultProps = {
  primary: "#000",
};
