import React from "react";
import { string } from "prop-types";

export default function Artwrk30(props) {
  const { primary, secondary } = props;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      enable-background="new 0 0 400 400"
      xmlSpace="preserve"
    >
      <g>
        <rect
          x="208.865"
          y="192.795"
          fill="none"
          width="20.939"
          height="6.714"
        />
      </g>
      <g>
        <path
          d="M64.499,22.758l-15.867,5.494c-2.134-6.52-6.654-9.78-13.56-9.78c-7.372,0-11.057,2.475-11.057,7.426
		c0,1.972,0.689,3.643,2.068,5.011c1.378,1.369,4.494,2.557,9.349,3.565c8.116,1.695,14.058,3.408,17.826,5.138
		c3.767,1.73,6.941,4.517,9.525,8.359c2.583,3.843,3.875,8.138,3.875,12.887c0,7.444-2.769,13.882-8.305,19.315
		c-5.537,5.433-13.745,8.148-24.624,8.148c-8.16,0-15.105-1.921-20.836-5.765c-5.731-3.843-9.509-9.548-11.335-17.115l17.267-4.045
		c1.94,7.688,7.335,11.531,16.184,11.531c4.27,0,7.451-0.854,9.548-2.565c2.095-1.71,3.144-3.752,3.144-6.128
		c0-2.415-0.952-4.286-2.854-5.614c-1.903-1.329-5.573-2.576-11.009-3.745c-10.136-2.175-17.36-5.145-21.671-8.908
		c-4.312-3.763-6.466-9.266-6.466-16.509c0-7.324,2.573-13.501,7.721-18.532c5.148-5.03,12.132-7.545,20.951-7.545
		C49.987,3.379,60.031,9.839,64.499,22.758z"
        />
        <path d="M135.724,20.344H97.807v16.904h29.809v15.515H97.807v17.991h37.917v16.3H78.849V4.647h56.875V20.344z" />
        <path
          d="M208.174,4.647v82.407H192.26l-31.161-55.662c0.038,1.409,0.077,2.334,0.116,2.777c0.155,1.41,0.233,2.596,0.233,3.562
		v49.323h-14.525V4.647h20.104l27.088,48.599c-0.233-2.415-0.35-4.246-0.35-5.494V4.647H208.174z"
        />
        <path d="M242.767,4.647v82.407h-18.609V4.647H242.767z" />
        <path
          d="M288.646,88.321c-10.725,0-19.021-4.166-24.888-12.497s-8.8-18.413-8.8-30.246c0-12.034,2.972-22.075,8.917-30.125
		c5.944-8.049,14.415-12.074,25.412-12.074c11.112,0,19.495,4.297,25.149,12.889c5.652,8.594,8.479,18.424,8.479,29.491
		c0,11.954-2.914,22.036-8.742,30.246C308.345,84.216,299.836,88.321,288.646,88.321z M289.17,19.136
		c-9.78,0-14.67,8.896-14.67,26.684c0,17.83,4.89,26.744,14.67,26.744c9.47,0,14.205-8.894,14.205-26.684
		C303.375,28.051,298.64,19.136,289.17,19.136z"
        />
        <path
          d="M353.892,54.393v32.661h-18.784V4.647h35.001c6.455,0,11.637,0.775,15.546,2.324c3.908,1.549,7.146,4.394,9.712,8.537
		c2.567,4.143,3.851,8.748,3.851,13.815c0,9.895-4.608,16.994-13.825,21.297l14.7,36.434h-20.61l-12.229-32.661H353.892z
		 M353.892,19.317v21.009h11.433c3.733,0,6.446-0.272,8.138-0.815c1.692-0.543,3.198-1.751,4.521-3.623
		c1.321-1.872,1.983-4.015,1.983-6.43c0-2.294-0.662-4.347-1.983-6.158c-1.323-1.811-2.762-2.928-4.317-3.351
		c-1.556-0.422-4.473-0.634-8.75-0.634H353.892z"
        />
      </g>
      <g>
        <path
          d="M149.451,380.609l-1.61,19.158H76.125v-18.664c2.334-1.783,6.186-4.578,11.556-8.381
		c13.119-9.391,22.667-17.127,28.644-23.209c5.976-6.08,8.964-11.656,8.964-16.727c0-3.709-1.19-6.773-3.572-9.191
		s-5.58-3.629-9.595-3.629c-9.338,0-14.754,6.129-16.248,18.383l-19.75-4.436c4.435-21.178,16.738-31.766,36.908-31.766
		c11.298,0,19.971,2.982,26.018,8.945c6.046,5.963,9.069,13.076,9.069,21.34c0,8.547-3.164,16.178-9.49,22.891
		c-6.327,6.717-17.963,15.145-34.912,25.285H149.451z"
        />
        <path
          d="M197.565,401.248c-11.72,0-20.835-4.672-27.348-14.018c-6.513-9.344-9.77-21.152-9.77-35.428
		c0-13.992,3.175-25.766,9.525-35.322c6.349-9.555,15.593-14.332,27.733-14.332c12.046,0,21.29,4.777,27.733,14.332
		c6.443,9.557,9.665,21.236,9.665,35.041c0,14.135-3.233,25.955-9.7,35.463S209.657,401.248,197.565,401.248z M197.845,318.277
		c-5.277,0-8.954,2.453-11.03,7.359c-2.078,4.908-3.117,13.514-3.117,25.814c0,12.396,1.085,21.014,3.257,25.85
		c2.171,4.836,5.778,7.254,10.82,7.254c4.995,0,8.614-2.371,10.855-7.113s3.361-13.102,3.361-25.074
		c0-13.146-1.098-22.117-3.292-26.906S202.888,318.277,197.845,318.277z"
        />
        <path
          d="M317.674,380.609l-1.61,19.158h-71.716v-18.664c2.335-1.783,6.187-4.578,11.556-8.381
		c13.12-9.391,22.668-17.127,28.645-23.209c5.976-6.08,8.964-11.656,8.964-16.727c0-3.709-1.19-6.773-3.571-9.191
		s-5.58-3.629-9.595-3.629c-9.339,0-14.754,6.129-16.248,18.383l-19.75-4.436c4.436-21.178,16.738-31.766,36.908-31.766
		c11.299,0,19.971,2.982,26.019,8.945c6.046,5.963,9.069,13.076,9.069,21.34c0,8.547-3.164,16.178-9.49,22.891
		c-6.327,6.717-17.964,15.145-34.912,25.285H317.674z"
        />
      </g>
      <path
        fill={primary}
        d="M147.259,219.646v-33.378c0,0,7.175-14.824,68.197-17.9c0,0,69.752-1.118,82.518,17.621v34.96
	c0,0-18.263-14.262-78.604-14.543c0,0-38.843,3.951-69.442,17.063c-2.669,1.121-2.669,1.121-2.669,1.121V219.646z"
      />
      <g fill={secondary}>
        <path
          d="M171.759,198.991c-1.082,0.376-3.245,0.752-6.016,0.752c-6.422,0-11.255-2.82-11.255-8.013
		c0-4.958,4.833-8.319,11.897-8.319c2.839,0,4.63,0.423,5.408,0.705l-0.71,1.668c-1.115-0.376-2.704-0.658-4.596-0.658
		c-5.34,0-8.889,2.373-8.889,6.532c0,3.877,3.211,6.368,8.754,6.368c1.791,0,3.616-0.258,4.799-0.658L171.759,198.991z"
        />
        <path d="M175.443,183.67h2.94v14.123h9.734v1.715h-12.674V183.67z" />
        <path
          d="M195.079,194.527l-2.366,4.981h-3.042l7.74-15.838h3.549l7.773,15.838h-3.143l-2.434-4.981H195.079z M202.549,192.929
		l-2.231-4.559c-0.507-1.034-0.845-1.974-1.183-2.891h-0.068c-0.338,0.94-0.709,1.904-1.149,2.867l-2.231,4.582H202.549z"
        />
        <path
          d="M211.675,197.018c1.318,0.564,3.245,1.034,5.272,1.034c3.008,0,4.766-1.104,4.766-2.702c0-1.48-1.217-2.326-4.292-3.149
		c-3.718-0.917-6.017-2.256-6.017-4.488c0-2.467,2.94-4.3,7.368-4.3c2.332,0,4.021,0.376,5.036,0.775l-0.812,1.668
		c-0.743-0.282-2.265-0.752-4.326-0.752c-3.109,0-4.292,1.292-4.292,2.374c0,1.48,1.386,2.209,4.528,3.055
		c3.854,1.034,5.813,2.327,5.813,4.653c0,2.444-2.603,4.559-7.977,4.559c-2.196,0-4.596-0.446-5.813-1.01L211.675,197.018z"
        />
        <path
          d="M228.338,197.018c1.318,0.564,3.244,1.034,5.272,1.034c3.008,0,4.766-1.104,4.766-2.702c0-1.48-1.217-2.326-4.292-3.149
		c-3.718-0.917-6.017-2.256-6.017-4.488c0-2.467,2.94-4.3,7.368-4.3c2.332,0,4.021,0.376,5.036,0.775l-0.812,1.668
		c-0.743-0.282-2.265-0.752-4.326-0.752c-3.109,0-4.292,1.292-4.292,2.374c0,1.48,1.386,2.209,4.528,3.055
		c3.854,1.034,5.813,2.327,5.813,4.653c0,2.444-2.603,4.559-7.977,4.559c-2.196,0-4.596-0.446-5.813-1.01L228.338,197.018z"
        />
      </g>
      <g fill={secondary}>
        <path
          d="M271.571,191.425c0,5.452-4.884,8.342-10.842,8.342c-6.166,0-10.495-3.243-10.495-8.036c0-5.029,4.606-8.319,10.842-8.319
		C267.449,183.412,271.571,186.725,271.571,191.425z M253.455,191.683c0,3.384,2.702,6.415,7.447,6.415
		c4.78,0,7.482-2.984,7.482-6.579c0-3.149-2.425-6.439-7.447-6.439C255.949,185.08,253.455,188.206,253.455,191.683z"
        />
        <path d="M275.487,183.67h12.573v1.715H278.5v5.264h8.833v1.692H278.5v7.167h-3.013V183.67z" />
      </g>
      <path
        fill={primary}
        d="M356.222,168.355l-130.854-46.043l-8.24-2.891L74.413,172.284l-5.756,3.077l14.841,2.237l0.835,12.586
	c0,0-9.085,6.059,1.249,10.441c0,0-12.359,28.997-5.35,31.513h18.121c0,0,7.845-5.408-6.592-31.513c0,0,8.661-4.251,0.417-10.441
	l-0.417-9.509l52.749,15.795l-0.765-13.373c0,0,16.791-17.247,75.624-20.044c0,0,62.912,2.238,83.451,18.46l-1.505,11.28
	l61.328-21.908L356.222,168.355z"
      />
      <path
        fill={primary}
        stroke="#000000"
        stroke-width="0.3396"
        stroke-miterlimit="10"
        d="M161.443,239.971
	c0.418,15.383,7.011,27.131,7.011,27.131c6.592,13.707,44.147,22.375,44.147,22.375c47.489,3.357,67.592-18.74,67.592-18.74
	c8.605-12.305,7.104-32.725,7.104-32.725c-19.869,3.357-42.076-8.947-42.076-8.947c-21.105-16.227-44.641,1.676-44.641,1.676
	C179.142,243.049,161.443,239.971,161.443,239.971"
      />
      <path
        fill="none"
        stroke={secondary}
        stroke-width="2.717"
        stroke-miterlimit="10"
        d="M174.628,245.285c0,0,14.189,0.467,25.207,0
	c0,0,20.698-4.943,21.579-5.314c0,0,2.862-1.492,8.363,0l19.298,4.66h24.697"
      />
      <path
        fill="none"
        stroke={secondary}
        stroke-width="2.717"
        stroke-miterlimit="10"
        d="M178.388,255.635c0,0,38.47,14.916,95.385-1.68"
      />
      <path
        fill="none"
        stroke={secondary}
        stroke-width="2.717"
        stroke-miterlimit="10"
        d="M183.557,265.516
	c0,0,32.465,25.359,85.047-1.305"
      />
      <path
        fill={primary}
        stroke="#000000"
        stroke-width="0.3396"
        stroke-miterlimit="10"
        d="M161.443,239.551v-2.191
	c0,0-21.865-5.18-26.538,8.135c0,0-4.673,11.258,15.69,19.65c0,0,7.172,2.609,17.858,1.957l3.036,4.174
	c0,0-28.073,1.516-38.752-12.1c0,0-15.945-23.494,14.521-26.107c0,0,11.599-0.742,19.523,0.936c0,0,15.692,1.303,36.056-10.816
	c0,0,18.295-16.467,43.223-0.926c0,0,16.199,11.369,39.227,9.877c0,0,30.546-2.98,34.878,5.221c0,0,10.184,14.359-11.015,26.852
	c0,0-13.566,6.244-27.65,4.426l2.125-4.426c0,0,25.525,1.678,32.209-13.799c0,0,10.676-18.645-26.212-15.85l-2.254,0.375
	l-0.071,3.074c0,0-18.676,3.586-42.946-9.605c0,0-16.379-15.449-42.737,1.623c0,0-12.342,7.646-22.324,8.938
	C179.289,238.967,166.573,241.51,161.443,239.551z"
      />
      <path
        d="M167.031,237.82c0,0.822-0.934,1.484-2.171,1.484c-1.229,0-2.163-0.662-2.163-1.484s0.934-1.484,2.163-1.484
	C166.098,236.336,167.031,236.998,167.031,237.82z"
      />
      <path
        d="M172.914,237.176c0,0.818-1.015,1.484-2.162,1.484c-1.238,0-2.255-0.666-2.255-1.484c0-0.822,1.017-1.488,2.255-1.488
	C171.899,235.688,172.914,236.354,172.914,237.176z"
      />
      <path
        d="M179.045,236.336c0,0.822-1.016,1.484-2.246,1.484c-1.154,0-2.171-0.662-2.171-1.484s1.017-1.488,2.171-1.488
	C178.029,234.848,179.045,235.514,179.045,236.336z"
      />
      <path
        d="M185.806,234.848c0,0.822-1.011,1.488-2.249,1.488c-1.153,0-2.162-0.666-2.162-1.488c0-0.818,1.009-1.484,2.162-1.484
	C184.795,233.363,185.806,234.029,185.806,234.848z"
      />
      <path
        d="M191.776,232.752c0,0.82-1.016,1.484-2.254,1.484c-1.146,0-2.162-0.664-2.162-1.484c0-0.822,1.016-1.488,2.162-1.488
	C190.761,231.264,191.776,231.93,191.776,232.752z"
      />
      <path
        d="M196.659,230.217c0,0.82-1.016,1.484-2.254,1.484c-1.146,0-2.162-0.664-2.162-1.484c0-0.822,1.016-1.484,2.162-1.484
	C195.644,228.732,196.659,229.395,196.659,230.217z"
      />
      <path
        d="M202.652,227.172c0,0.822-1.014,1.488-2.157,1.488c-1.24,0-2.257-0.666-2.257-1.488c0-0.82,1.017-1.484,2.257-1.484
	C201.639,225.688,202.652,226.352,202.652,227.172z"
      />
      <path
        d="M206.931,224.828c0.375,0.789-0.284,1.611-1.495,1.838c-1.118,0.227-2.361-0.23-2.734-1.02
	c-0.279-0.793,0.377-1.615,1.594-1.84C205.412,223.578,206.651,224.037,206.931,224.828z"
      />
      <path
        d="M213.242,222.262c0,0.822-0.994,1.488-2.162,1.488c-1.232,0-2.231-0.666-2.231-1.488c0-0.818,0.999-1.484,2.231-1.484
	C212.248,220.777,213.242,221.443,213.242,222.262z"
      />
      <path
        d="M219.37,220.777c0,0.822-1.038,1.484-2.231,1.484c-1.169,0-2.208-0.662-2.208-1.484s1.039-1.486,2.208-1.486
	C218.332,219.291,219.37,219.955,219.37,220.777z"
      />
      <path
        d="M226.471,219.291c0,0.822-1.039,1.486-2.207,1.486c-1.193,0-2.232-0.664-2.232-1.486s1.039-1.486,2.232-1.486
	C225.432,217.805,226.471,218.469,226.471,219.291z"
      />
      <path
        d="M232.961,219.973c0,0.822-1.038,1.486-2.232,1.486c-1.173,0-2.207-0.664-2.207-1.486s1.034-1.486,2.207-1.486
	C231.923,218.486,232.961,219.15,232.961,219.973z"
      />
      <path
        d="M239.927,221.459c0,0.82-0.972,1.486-2.21,1.486c-1.175,0-2.162-0.666-2.162-1.486c0-0.822,0.987-1.486,2.162-1.486
	C238.955,219.973,239.927,220.637,239.927,221.459z"
      />
      <path
        d="M246.272,224.59c0,0.82-0.935,1.484-2.172,1.484c-1.21,0-2.162-0.664-2.162-1.484c0-0.822,0.952-1.488,2.162-1.488
	C245.338,223.102,246.272,223.768,246.272,224.59z"
      />
      <path
        d="M251.238,228.291c0,0.822-1.1,1.486-2.162,1.486c-1.239,0-2.338-0.664-2.338-1.486c0-0.82,1.099-1.484,2.338-1.484
	C250.139,226.807,251.238,227.471,251.238,228.291z"
      />
      <path
        d="M257.241,231.264c0,0.824-0.925,1.488-2.162,1.488c-1.238,0-2.162-0.664-2.162-1.488c0-0.818,0.924-1.484,2.162-1.484
	C256.316,229.779,257.241,230.445,257.241,231.264z"
      />
      <path
        d="M264.756,233.363c0,0.822-1.104,1.484-2.342,1.484c-1.223,0-2.161-0.662-2.161-1.484s0.938-1.484,2.161-1.484
	C263.652,231.879,264.756,232.541,264.756,233.363z"
      />
      <path
        d="M270.767,234.848c0,0.822-1.103,1.488-2.162,1.488c-1.238,0-2.345-0.666-2.345-1.488c0-0.818,1.106-1.484,2.345-1.484
	C269.664,233.363,270.767,234.029,270.767,234.848z"
      />
      <path
        d="M275.936,235.688c0,0.822-0.924,1.488-2.162,1.488c-1.239,0-2.163-0.666-2.163-1.488s0.924-1.484,2.163-1.484
	C275.012,234.203,275.936,234.865,275.936,235.688z"
      />
      <path
        d="M282.617,236.336c0,0.822-1.106,1.484-2.342,1.484c-1.057,0-2.165-0.662-2.165-1.484s1.108-1.488,2.165-1.488
	C281.511,234.848,282.617,235.514,282.617,236.336z"
      />
      <g></g>
      <g>
        <path
          d="M343.162,402.027c0.946-7.607,1.725-16.256,2.333-25.939l1.929-29.646c0.676-10.078,1.115-18.75,1.318-26.014
		c-3.062,1.385-5.391,2.459-6.989,3.225c-1.598,0.766-5.56,2.73-11.884,5.891c0.34-3.123,0.629-6.619,0.867-10.488
		s0.358-6.846,0.358-8.93c2.382-0.979,5.479-2.303,9.291-3.969s6.194-2.732,7.146-3.199c0.952-0.465,2.247-1.164,3.881-2.096
		c4.027,0.1,8.906,0.148,14.64,0.148c5.596,0,10.884-0.049,15.866-0.148l-1.119,10.08c-0.271,2.322-0.575,5.658-0.914,10.006
		l-4.064,58.92l-0.305,8.82c-0.069,1.828-0.103,3.682-0.103,5.559l0.103,7.781c-4.642-0.098-9.692-0.148-15.154-0.148
		C354.015,401.879,348.282,401.93,343.162,402.027z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

Artwrk30.props = {
  primary: string,
  secondary: string,
};

Artwrk30.defaultProps = {
  primary: "#000",
  secondary: "#fff",
};
