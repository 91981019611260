import React from "react";
import { Card, Nav, Col, Row } from "react-bootstrap";
import insta from "./icon/instagram.png";
import whats from "./icon/whatsapp.png";
import face from "./icon/facebook.png";
import email from "./icon/email.png";
import location from "./icon/location.png";
import phone from "./icon/phone.png";

function Footer() {
  return (
    <>
      <div className="p-5 d-flex justify-content-center footer">
        <Row>
          <Col className={"d-flex justify-content-center"} xs={12} md={6}>
            <Nav style={{ color: "white" }}>
              <Nav.Item style={{ textAlign: "left" }}>
                <Row>
                  <Col xs={2}>
                    <img src={email} width="25px" alt="icon" />
                  </Col>
                  <Col>seniorjacketuae@gmail.com</Col>
                </Row>
                <br />
                <Row>
                  <Col xs={2}>
                    <img src={phone} width="25px" alt="icon" />
                  </Col>
                  <Col>+(971)52 526 7588</Col>
                </Row>
                <br />
                <Row>
                  <Col xs={2}>
                    <br />
                    <img src={location} width="25px" alt="icon" />
                  </Col>
                  <Col>
                    Lucky Round About,
                    <br /> New Industrial Area Ajman,
                    <br /> UAE
                  </Col>
                </Row>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={2}></Col>
          <Col className={"d-flex justify-content-center"} xs={12} md={4}>
            <div className="pt-5" style={{ color: "white" }}>
              <Nav
                style={{
                  color: "white",
                }}
              >
                <Nav.Item>
                  <Nav.Link
                    href="https://seniorjacket.com/"
                    className="footer-link"
                  >
                    Home
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    href="http://seniorjacket.com/about.html"
                    className="footer-link"
                  >
                    About Us
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="http://seniorjacket.com/contact.aspx"
                    className="footer-link"
                  >
                    Contact Us
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Card
                style={{ width: "18rem" }}
                className="footer-line align-left my-3"
              ></Card>
              <Nav className="justify-content-center">
                <Nav.Item>
                  <Nav.Link
                    href="https://www.instagram.com/senior.jackets.emirates/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <img src={insta} width="25px" alt="icon" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="https://api.whatsapp.com/send?phone=971525267588"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <img src={whats} width="25px" alt="icon" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="https://www.facebook.com/senior.jackets.7"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <img src={face} width="25px" alt="icon" />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Footer;
