import React from "react";
import { string } from "prop-types";

export default function Artwrk22(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      enable-background="new 0 0 400 400"
      xmlSpace="preserve"
    >
      {/* <line
        fill="none"
        stroke={primary}
        stroke-width="3.8941"
        stroke-miterlimit="10"
        x1="90.063"
        y1="315.662"
        x2="90.063"
        y2="278.594"
      /> */}
      <path
        fill="transparent"
        stroke={primary}
        stroke-width="2.9206"
        stroke-miterlimit="10"
        d="M215.191,270.383l-11.401,12.154
	l-64.758,1.457v52h40.435l1.259-20.332h56.557v83H80.582V282.537C80.582,282.537,123.615,268.436,215.191,270.383"
      />
      <path
        fill="none"
        stroke={primary}
        stroke-width="2.9206"
        stroke-miterlimit="10"
        d="M53.815,240.412
	c0,0-40.835,8.582-50.315,13.582l47.689,20l-32.212,43c0,0,12.957-10.998,61.605-16.666l1.571-20.791
	c0,0,61.454-19.225,187.028-6.209c125.427,13-0.94-0.08-0.94-0.08l0.94,64.746h-14.206l0.926,54h77.726l-0.113-53.457l-14.415,0.123
	l-0.585-39.332c0,0,26.489,0,63.146,15.334c0,0-19.273-29.002-29.074-39.668c0,0,14.544-10.332,43.915-21.332
	c0,0-47.021-12.629-52.948-13.25l2.485-14.016c-6.089-9.234-238.681-22.57-293.223,1.332"
      />
      <path
        fill="transparent"
        stroke={primary}
        stroke-width="2.9206"
        stroke-miterlimit="10"
        d="M286.999,108.912l-33.616,14.749l-0.624,52
	h13.884v37.167l54.616,7.918l-0.651-112.584h-12.801c0,0-9.825,15.063-14.279,14.25c-5.934-1.087,0,0,0,0s-5.343-1.334-6.528-14.084
	"
      />
      <path
        fill="transparent"
        d="M268.259,275.332l0.923,63.662h-14.206v58.002h78.651l-0.113-57.459l-14.415,0.123l-1.155-61.066
	C317.943,278.594,271.758,270.338,268.259,275.332z"
      />
      <path
        fill="none"
        stroke={primary}
        stroke-width="3.8941"
        stroke-miterlimit="10"
        d="M52.566,227.578
	c0,0,166.049-34.588,293.471-1.182l-10.051,56.141c0,0-103.438-32.377-271.707,0L52.566,227.578z"
      />
      <path
        fill="none"
        stroke={primary}
        stroke-width="3.8941"
        stroke-miterlimit="10"
        d="M78.998,139.661l32.223-31.333h93.764
	l30.706,31.333v66.667v3.334v3.166c0,0,16.422-1.498,30.952,0v-34.5v-2.667l-9.625-0.165l-4.259,0.165l0.624-52l34.439-15.333
	c0,0-0.624,26.668,17.069,2.668l2.52-2.668h13.582v104.5v4.756l0.266,3.162c0,0,22.163,2.916,25.954,6.25
	c-11.264,50.686-10.705,49.07-11.369,54l0.143,1.541l-18.043-3.943l0.512,18.4l0.644,36.666v6l14.415-0.123l0.113,58.563
	l-78.651,0.063l0.146-58.002l14.061,0.168v-66c0,0-112.467-13.58-187.028,6.209L63.2,285.66l-13.417-58.998
	c0,0,17.84-10.332,130.308-17v-41v-3.667h-45.493l-0.634,20.666l-52.445,0.668l-3.154-0.668L78.998,139.661z"
      />
      <g>
        <path
          d="M115.958,362.25v-1.146h-1.085v10.475h1.085v4.293h-7.133v-4.293h1.085v-10.475h-1.085v1.146h-4.068v-5.438h15.27v5.438
		H115.958z"
        />
        <path
          d="M134.672,371.607v4.293h-9.276v-4.293h0.597l-3.309-10.504h-1.438v-4.291h7.54v4.291h-0.705l1.98,6.297l1.98-6.297h-0.57
		v-4.291h8.083v4.291h-0.597l2.007,6.297l1.98-6.297h-0.597v-4.291h7.432v4.291h-1.464l-3.282,10.504h0.57v4.293h-9.222v-4.293
		h0.569l-1.438-4.58l-1.438,4.58H134.672z"
        />
        <path
          d="M160.222,371.607v-0.773h4.068v5.066H151v-4.293h1.085v-10.504H151v-4.291h13.29v5.064h-4.068v-0.773h-3.173v3.121h0.732
		v-0.773h4.068v5.809h-4.068v-0.744h-0.732v3.092H160.222z"
        />
        <path
          d="M171.966,367.371v4.236h1.085v4.293h-7.133v-4.293h1.085v-10.504h-1.085v-4.291h5.886l4.963,8.729v-4.438h-1.085v-4.291
		h7.133v4.291h-1.085v10.504h1.085v4.293h-5.967L171.966,367.371z"
        />
        <path
          d="M195.644,362.25v-1.146h-1.085v10.475h1.085v4.293h-7.133v-4.293h1.085v-10.475h-1.085v1.146h-4.068v-5.438h15.27v5.438
		H195.644z"
        />
        <path
          d="M213.437,371.578h1.085v4.293h-7.134v-4.293h1.085v-4.293l-5.831-6.182h-1.709v-4.291h8.598v4.291h-0.732l2.17,2.291
		l2.143-2.291h-0.705v-4.291h8.327v4.291h-1.438l-5.858,6.182V371.578z"
        />
      </g>
      <g>
        <g>
          <path
            stroke={primary}
            stroke-width="2"
            stroke-miterlimit="10"
            d="M153.214,22.587c0,3.048-1.715,6.212-5.145,9.492
			c3.125,5.672,4.688,10.898,4.688,15.68c0,1.172-0.286,1.758-0.857,1.758c-0.457,0-0.686-0.773-0.686-2.32
			c0-4.687-1.334-9.445-4.001-14.273c-5.526,5.063-13.044,9.434-22.552,13.113c-9.509,3.681-17.941,5.52-25.296,5.52
			c-1.029,0-1.925-0.046-2.687-0.141C90.578,61.869,87.53,71.947,87.53,81.65c0,6.609,1.667,11.837,5.002,15.68
			c3.334,3.843,7.879,5.766,13.634,5.766c10.862,0,20.542-6.398,29.041-19.195c5.145-7.734,7.717-15.07,7.717-22.008
			c0-3.656-0.894-6.562-2.682-8.719c-1.789-2.155-4.166-3.234-7.134-3.234c-4.755,0-9.864,3.083-15.323,9.246
			c-5.459,6.165-8.189,11.918-8.189,17.262c0,0.469,0.113,0.703,0.34,0.703c0.188-0.046,0.339-0.141,0.453-0.281
			c1.02-1.125,1.964-1.688,2.833-1.688c1.927,0,2.891,1.29,2.891,3.867c0,3.47-1.372,5.203-4.116,5.203
			c-2.515,0-3.773-1.874-3.773-5.625c0-6.703,2.763-13.347,8.289-19.934c5.525-6.585,11.128-9.879,16.807-9.879
			c7.317,0,10.976,4.946,10.976,14.836c0,10.22-3.973,19.536-11.919,27.949c-7.946,8.415-16.76,12.621-26.439,12.621
			c-6.746,0-12.415-2.79-17.007-8.367c-4.593-5.578-6.889-12.468-6.889-20.672c0-7.968,2.267-16.147,6.803-24.539
			c-16.503-3.75-24.753-11.765-24.753-24.047c0-7.313,3.067-13.43,9.204-18.352c6.136-4.922,13.777-7.383,22.924-7.383
			c12.767,0,25.667,5.228,38.702,15.68c2.249-0.563,4.554-0.844,6.917-0.844C149.422,15.697,153.214,17.994,153.214,22.587z
			 M133.32,16.822c-11.586-8.25-23.916-12.375-36.987-12.375c-8.081,0-15.141,2.345-21.181,7.031
			c-6.041,4.688-9.061,10.172-9.061,16.453c0,11.204,7.736,18.446,23.209,21.727C98.906,33.205,113.578,22.26,133.32,16.822z
			 M146.754,31.939c-2.935-5.203-6.879-9.819-11.833-13.852c-7.127,2.391-14.083,6.504-20.866,12.34
			c-6.784,5.836-12.348,12.48-16.693,19.934c7.965,0,16.778-1.793,26.44-5.379C133.463,41.396,141.114,37.049,146.754,31.939z
			 M152.3,22.587c0-3.843-3.05-5.766-9.147-5.766c-1.906,0-4.173,0.329-6.803,0.984c4.535,3.844,8.289,8.321,11.262,13.43
			C150.736,27.956,152.3,25.073,152.3,22.587z"
          />
          <path
            stroke={primary}
            stroke-width="2"
            stroke-miterlimit="10"
            d="M176.743,30.232c-15.682,32.101-24.821,50.976-27.416,56.625
			c-2.596,5.649-3.893,9.392-3.893,11.229c0,2.066,0.709,3.1,2.131,3.1c3.141,0,8.899-7.291,17.275-21.872
			c0.186-0.458,0.41-0.689,0.673-0.689c0.149,0.046,0.224,0.184,0.224,0.414c0,0.276-0.188,0.645-0.56,1.104l-2.412,4.07
			c-7.065,12.004-12.28,18.006-15.645,18.006c-1.607,0-2.925-0.747-3.953-2.239c-1.028-1.492-1.542-3.386-1.542-5.683
			c0-2.525,0.476-4.971,1.428-7.336c0.952-2.365,4.191-9.196,9.717-20.494c6.758-13.823,12.452-25.809,17.082-35.959
			c1.12,0.368,2.109,0.551,2.969,0.551C174.241,31.059,175.548,30.784,176.743,30.232z"
          />
          <path
            stroke={primary}
            stroke-width="2"
            stroke-miterlimit="10"
            d="M205.11,79.039c0,0.966-2.729,5.427-8.186,13.383
			c-4.412,6.531-7.888,9.797-10.43,9.797c-1.721,0-3.123-0.792-4.206-2.376c-1.084-1.584-1.626-3.57-1.626-5.959
			c0-2.48,0.597-5.167,1.792-8.06c-3.734,6.43-6.59,10.77-8.569,13.02c-1.979,2.25-3.902,3.375-5.769,3.375
			c-1.979,0-3.641-1.045-4.985-3.134c-1.344-2.089-2.016-4.719-2.016-7.888c0-8.817,5.059-16.624,15.178-23.421
			c4.443-3.031,8.027-4.546,10.753-4.546c2.314,0,3.472,1.378,3.472,4.133c0,0.781-0.149,1.814-0.448,3.1l2.856-6.682
			c1.12,0.322,2.109,0.482,2.969,0.482c1.008,0,2.314-0.16,3.92-0.482l-9.914,20.184c-3.436,7.073-5.152,11.734-5.152,13.984
			c0,2.159,0.729,3.238,2.187,3.238c1.495,0,3.889-2.104,7.179-6.313s6.637-9.395,10.04-15.559c0.223-0.458,0.43-0.689,0.616-0.689
			C204.998,78.625,205.11,78.763,205.11,79.039z M189.681,67.638c0-2.25-0.84-3.375-2.521-3.375c-3.436,0-7.944,4.133-13.526,12.399
			c-5.582,8.267-8.373,14.949-8.373,20.046c0,2.986,1.083,4.478,3.249,4.478c2.576,0,7.038-5.258,13.386-15.775
			C187.085,76.777,189.681,70.853,189.681,67.638z"
          />
          <path
            stroke={primary}
            stroke-width="2"
            stroke-miterlimit="10"
            d="M224.125,52.311c0,1.832-2.334,5.471-7.001,10.918
			c-0.224,1.424-0.336,2.664-0.336,3.72c0,2.664,0.989,6.889,2.969,12.675c1.008,2.893,1.512,5.328,1.512,7.302
			c0,4.133-1.634,7.715-4.9,10.747c-3.268,3.031-7.141,4.546-11.622,4.546c-2.539,0-4.564-0.885-6.076-2.652
			c-1.512-1.767-2.269-4.144-2.269-7.129c0-3.444,0.999-6.796,2.997-10.058c1.997-3.26,4.061-4.891,6.189-4.891
			c0.373,0,0.709,0.093,1.008,0.276l4.48-6.751c1.345-1.974,3.137-4.707,5.377-8.197c0.635-3.214,1.604-5.97,2.912-8.267
			c1.307-2.296,2.595-3.444,3.865-3.444C223.826,51.105,224.125,51.508,224.125,52.311z M216.34,79.486
			c0-1.378-0.037-2.744-0.111-4.099c-0.075-1.354-0.112-3.295-0.112-5.821c0-2.066,0.074-3.789,0.224-5.167
			c-2.091,3.262-5.115,7.853-9.073,13.777c0.709,0.735,1.064,1.631,1.064,2.687c0,2.71-1.812,4.547-5.433,5.511
			c-1.456,0.413-2.716,1.389-3.781,2.927c-1.064,1.54-1.596,3.158-1.596,4.857c0,1.837,0.541,3.375,1.625,4.615
			c1.083,1.24,2.426,1.86,4.032,1.86c3.137,0,6.114-2.239,8.934-6.716S216.34,84.63,216.34,79.486z"
          />
          <path
            stroke={primary}
            stroke-width="2"
            stroke-miterlimit="10"
            d="M246.472,52.311c0,1.832-2.334,5.471-7.001,10.918
			c-0.224,1.424-0.336,2.664-0.336,3.72c0,2.664,0.989,6.889,2.969,12.675c1.008,2.893,1.512,5.328,1.512,7.302
			c0,4.133-1.634,7.715-4.9,10.747c-3.268,3.031-7.141,4.546-11.621,4.546c-2.54,0-4.565-0.885-6.077-2.652
			c-1.513-1.767-2.269-4.144-2.269-7.129c0-3.444,0.999-6.796,2.996-10.058c1.997-3.26,4.061-4.891,6.189-4.891
			c0.373,0,0.709,0.093,1.008,0.276l4.48-6.751c1.345-1.974,3.137-4.707,5.377-8.197c0.635-3.214,1.604-5.97,2.912-8.267
			c1.307-2.296,2.595-3.444,3.865-3.444C246.173,51.105,246.472,51.508,246.472,52.311z M238.687,79.486
			c0-1.378-0.037-2.744-0.111-4.099c-0.075-1.354-0.112-3.295-0.112-5.821c0-2.066,0.074-3.789,0.224-5.167
			c-2.091,3.262-5.115,7.853-9.073,13.777c0.709,0.735,1.064,1.631,1.064,2.687c0,2.71-1.812,4.547-5.433,5.511
			c-1.456,0.413-2.717,1.389-3.78,2.927c-1.064,1.54-1.597,3.158-1.597,4.857c0,1.837,0.541,3.375,1.624,4.615
			c1.083,1.24,2.427,1.86,4.033,1.86c3.136,0,6.113-2.239,8.933-6.716S238.687,84.63,238.687,79.486z"
          />
        </g>
      </g>
      <g>
        <path
          stroke={primary}
          stroke-width="3"
          stroke-miterlimit="10"
          d="M308.639,71.02c0,0.732-1.102,2.447-3.304,5.145
		c-3.285,4.025-6.309,6.037-9.07,6.037c-1.157,0-2.185-0.275-3.079-0.826c-4.928,8.579-9.557,12.868-13.887,12.868
		c-2.239,0-4.153-1.135-5.738-3.406c-1.587-2.271-2.38-4.989-2.38-8.154c0-7.064,3.767-13.877,11.302-20.438
		c5.296-4.633,9.66-6.95,13.092-6.95c3.208,0,4.812,2.11,4.812,6.331c0,5.276-2.257,11.63-6.77,19.062
		c0.745,0.55,1.585,0.826,2.518,0.826c1.679,0,3.64-0.96,5.884-2.88c2.243-1.92,4.076-4.229,5.498-6.928
		c0.412-0.641,0.712-0.961,0.899-0.961C308.563,70.745,308.639,70.837,308.639,71.02z M299.547,61.764
		c0-3.716-1.362-5.574-4.084-5.574c-3.395,0-7.535,3.991-12.421,11.974c-4.887,7.982-7.33,14.704-7.33,20.163
		c0,3.166,1.231,4.749,3.693,4.749c4.028,0,8.429-4.083,13.204-12.249c-1.083-1.468-1.623-2.982-1.623-4.542
		c0-2.247,0.728-4.564,2.183-6.95c1.454-2.385,2.871-3.579,4.252-3.579c0.596,0,1.007,0.459,1.23,1.376
		C299.248,65.526,299.547,63.737,299.547,61.764z"
        />
        <path
          stroke={primary}
          stroke-width="3"
          stroke-miterlimit="10"
          d="M304.302,54.814c5.707-15.139,11.283-27.457,16.729-36.954
		c3.692-6.422,6.75-11.102,9.175-14.038c1.679-2.064,3.114-3.097,4.309-3.097c0.447,0,0.839,0.184,1.175,0.55
		c0.521,0.459,0.783,1.079,0.783,1.858c0,1.009-0.653,2.455-1.958,4.335c-8.244,11.561-15.703,25.417-22.38,41.564
		c5.968-2.798,10.127-4.198,12.477-4.198c1.716,0,3.134,0.528,4.252,1.583c1.156,1.101,1.734,2.363,1.734,3.785
		c0,0.734-0.205,1.4-0.615,1.996c-0.56,0.872-1.286,1.307-2.182,1.307c-0.635,0-1.884-0.642-3.749-1.927
		c-0.522-0.32-1.194-0.481-2.014-0.481c-3.73,0-8.206,2.088-13.428,6.262c2.237,1.285,3.822,2.982,4.756,5.092
		c1.268,2.982,1.901,6.652,1.901,11.01c0,11.24-2.723,23.098-8.168,35.577c-2.35,5.321-4.626,9.359-6.826,12.111
		c-2.35,2.981-4.756,4.473-7.217,4.473c-1.865,0-3.376-1.101-4.532-3.303c-0.932-1.835-1.398-4.335-1.398-7.501
		c0-6.79,2.089-16.997,6.266-30.623c0.523-1.651,2.015-5.894,4.477-12.73c0.634-1.697,1.249-3.51,1.846-5.437
		c-0.299,0.092-0.578,0.138-0.839,0.138c-1.007,0-1.511-0.688-1.511-2.064C297.364,61.673,299.677,58.576,304.302,54.814z
		 M306.204,63.829c-2.089,4.863-4.849,12.708-8.28,23.535c-3.543,11.056-5.315,19.635-5.315,25.737c0,1.192,0.243,2.247,0.728,3.166
		c0.522,0.917,1.139,1.376,1.847,1.376c2.313,0,5.352-4.772,9.119-14.313c4.252-10.644,6.379-20.392,6.379-29.247
		c0-3.44-0.542-6.032-1.623-7.776C308.646,65.618,307.695,64.792,306.204,63.829z"
        />
      </g>
      <g>
        <g>
          <path
            stroke={primary}
            stroke-width="2.2988"
            stroke-miterlimit="10"
            d="M106.979,247.986c-1.91-1.47-2.869-2.202-4.796-3.661
			c-0.338-3.002-0.509-4.501-0.847-7.503c1.573-1.828,2.365-2.741,3.957-4.563c5.176-0.52,7.766-0.762,12.947-1.205
			c1.92,1.495,2.876,2.245,4.779,3.751c0.124,1.438,0.185,2.154,0.309,3.592c-2.201,0.177-3.3,0.271-5.5,0.461
			c-0.081-0.868-0.12-1.302-0.2-2.17c-0.903-0.706-1.356-1.06-2.263-1.764c-2.611,0.233-3.916,0.354-6.525,0.606
			c-0.752,0.857-1.127,1.287-1.875,2.146c0.199,1.884,0.298,2.828,0.498,4.712c0.906,0.681,1.358,1.021,2.26,1.704
			c3.85-0.372,5.775-0.551,9.627-0.88c1.894,1.497,2.837,2.25,4.714,3.758c0.253,2.95,0.38,4.424,0.633,7.374
			c-1.578,1.785-2.363,2.68-3.923,4.472c-5.007,0.428-7.508,0.661-12.509,1.163c-1.884-1.473-2.831-2.205-4.732-3.667
			c-0.155-1.374-0.232-2.061-0.387-3.435c2.147-0.227,3.221-0.339,5.37-0.552c0.087,0.822,0.129,1.236,0.216,2.059
			c0.894,0.682,1.341,1.021,2.231,1.704c2.544-0.246,3.816-0.363,6.361-0.591c0.739-0.835,1.108-1.253,1.852-2.087
			c-0.169-1.826-0.253-2.739-0.422-4.565c-0.891-0.707-1.338-1.059-2.233-1.764C112.703,247.424,110.795,247.604,106.979,247.986z"
          />
          <path
            stroke={primary}
            stroke-width="2.2988"
            stroke-miterlimit="10"
            d="M151.204,256.763c-8.117,0.401-12.172,0.653-20.279,1.249
			c-0.113-1.438-0.17-2.157-0.283-3.596c0.782-0.058,1.173-0.087,1.955-0.143c-0.626-8.241-0.94-12.362-1.566-20.604
			c-0.802,0.057-1.202,0.086-2.004,0.145c-0.113-1.438-0.169-2.156-0.282-3.595c8.39-0.616,12.587-0.875,20.987-1.291
			c0.103,1.95,0.155,2.93,0.258,4.88c-1.539,0.076-2.307,0.116-3.845,0.199c-0.029-0.51-0.044-0.769-0.073-1.278
			c-3.819,0.206-5.729,0.318-9.547,0.566c0.237,3.418,0.354,5.129,0.591,8.547c1.984-0.129,2.976-0.19,4.961-0.308
			c-0.032-0.51-0.047-0.766-0.079-1.275c1.526-0.09,2.291-0.132,3.817-0.216c0.142,2.43,0.212,3.644,0.354,6.073
			c-1.516,0.083-2.273,0.126-3.789,0.216c-0.031-0.495-0.046-0.74-0.078-1.235c-1.976,0.117-2.965,0.178-4.941,0.306
			c0.236,3.403,0.354,5.104,0.589,8.506c3.722-0.241,5.584-0.351,9.308-0.552c-0.028-0.495-0.042-0.744-0.071-1.239
			c1.504-0.081,2.256-0.12,3.761-0.194C151.051,253.859,151.102,254.827,151.204,256.763z"
          />
          <path
            stroke={primary}
            stroke-width="2.2988"
            stroke-miterlimit="10"
            d="M174.086,231.667c-0.034-1.442-0.051-2.163-0.084-3.605
			c3.823-0.084,5.735-0.115,9.558-0.158c0.017,1.442,0.026,2.165,0.043,3.607c-0.803,0.009-1.205,0.013-2.008,0.023
			c0.119,8.262,0.179,12.395,0.296,20.656c0.783-0.011,1.174-0.015,1.957-0.023c0.017,1.442,0.027,2.163,0.043,3.605
			c-3.086,0.034-4.63,0.059-7.716,0.121c-4.868-7.445-7.359-11.153-12.45-18.526c0.222,6.096,0.331,9.146,0.552,15.241
			c0.783-0.026,1.175-0.038,1.958-0.063c0.049,1.441,0.073,2.162,0.122,3.604c-3.693,0.117-5.542,0.185-9.235,0.342
			c-0.065-1.441-0.097-2.161-0.162-3.603c0.783-0.033,1.174-0.049,1.957-0.08c-0.355-8.256-0.533-12.387-0.889-20.643
			c-0.803,0.032-1.204,0.05-2.007,0.084c-0.065-1.441-0.098-2.163-0.164-3.604c3.096-0.132,4.644-0.188,7.74-0.293
			c5.245,7.534,7.81,11.321,12.824,18.932c-0.131-6.264-0.196-9.396-0.328-15.66C175.292,231.64,174.89,231.649,174.086,231.667z"
          />
          <path
            stroke={primary}
            stroke-width="2.2988"
            stroke-miterlimit="10"
            d="M189.814,255.728c-0.006-1.442-0.01-2.165-0.016-3.607
			c0.783-0.003,1.174-0.002,1.958-0.004c-0.016-8.263-0.023-12.397-0.041-20.66c-0.803,0.002-1.206,0.005-2.009,0.008
			c-0.006-1.442-0.009-2.165-0.016-3.607c3.824-0.016,5.735-0.012,9.559,0.014c-0.01,1.442-0.014,2.163-0.024,3.605
			c-0.803-0.006-1.206-0.008-2.009-0.012c-0.039,8.263-0.057,12.397-0.097,20.66c0.783,0.003,1.174,0.003,1.957,0.008
			c-0.011,1.442-0.016,2.165-0.026,3.607C195.355,255.715,193.508,255.712,189.814,255.728z"
          />
          <path
            stroke={primary}
            stroke-width="2.2988"
            stroke-miterlimit="10"
            d="M209.816,228.005c5.195,0.098,7.793,0.162,12.987,0.336
			c1.715,1.711,2.565,2.569,4.263,4.289c-0.322,7.837-0.481,11.756-0.807,19.592c-1.791,1.585-2.68,2.381-4.453,3.975
			c-5.018-0.168-7.528-0.232-12.547-0.326c-1.681-1.685-2.525-2.526-4.225-4.202c0.116-7.842,0.171-11.764,0.285-19.606
			C207.11,230.433,208.009,229.624,209.816,228.005z M219.461,231.843c-2.618-0.078-3.927-0.109-6.545-0.168
			c-0.854,0.763-1.279,1.143-2.129,1.907c-0.143,6.715-0.21,10.073-0.354,16.788c0.8,0.782,1.196,1.173,1.992,1.957
			c2.552,0.057,3.828,0.09,6.38,0.166c0.839-0.741,1.259-1.11,2.102-1.85c0.232-6.713,0.345-10.071,0.576-16.784
			C220.676,233.052,220.271,232.648,219.461,231.843z"
          />
          <path
            stroke={primary}
            stroke-width="2.2988"
            stroke-miterlimit="10"
            d="M232.166,256.604c0.07-1.44,0.107-2.16,0.178-3.601
			c0.782,0.036,1.172,0.055,1.954,0.092c0.425-8.253,0.637-12.382,1.06-20.635c-0.803-0.038-1.205-0.058-2.008-0.094
			c0.07-1.44,0.104-2.162,0.175-3.603c7.206,0.328,10.809,0.529,18.01,1.005c1.655,1.766,2.48,2.651,4.118,4.425
			c-0.194,2.563-0.292,3.845-0.486,6.407c-1.277,1.039-1.918,1.56-3.188,2.604c1.107,1.205,1.66,1.806,2.759,3.014
			c-0.245,3.222-0.365,4.833-0.61,8.055c0.781,0.056,1.171,0.084,1.952,0.142c-0.113,1.438-0.168,2.158-0.281,3.597
			c-2.91-0.214-4.366-0.315-7.277-0.504c0.298-4.287,0.446-6.431,0.743-10.719c-0.768-0.817-1.151-1.225-1.923-2.041
			c-2.862-0.179-4.295-0.263-7.158-0.418c-0.209,3.615-0.313,5.422-0.523,9.037c0.782,0.042,1.174,0.063,1.956,0.107
			c-0.087,1.439-0.131,2.162-0.218,3.602C237.706,256.868,235.858,256.772,232.166,256.604z M249.779,239.328
			c0.113-1.634,0.169-2.45,0.282-4.084c-0.778-0.834-1.167-1.25-1.949-2.082c-2.903-0.182-4.354-0.265-7.259-0.422
			c-0.185,3.195-0.277,4.794-0.462,7.989c2.876,0.155,4.316,0.239,7.191,0.419C248.461,240.42,248.897,240.054,249.779,239.328z"
          />
          <path
            stroke={primary}
            stroke-width="2.2988"
            stroke-miterlimit="10"
            d="M267.043,246.806c-1.586-1.795-2.383-2.69-3.987-4.478
			c0.258-3.01,0.39-4.513,0.647-7.522c1.903-1.506,2.855-2.256,4.775-3.751c5.181,0.443,7.771,0.685,12.947,1.204
			c1.593,1.821,2.386,2.735,3.96,4.563c-0.161,1.434-0.245,2.148-0.406,3.582c-2.196-0.231-3.294-0.344-5.492-0.562
			c0.092-0.867,0.138-1.301,0.229-2.168c-0.748-0.859-1.125-1.286-1.877-2.145c-2.609-0.252-3.914-0.376-6.525-0.608
			c-0.907,0.704-1.358,1.056-2.262,1.762c-0.175,1.886-0.262,2.829-0.437,4.715c0.756,0.835,1.133,1.253,1.885,2.09
			c3.852,0.344,5.777,0.527,9.626,0.914c1.566,1.818,2.345,2.731,3.893,4.557c-0.331,2.942-0.496,4.412-0.827,7.354
			c-1.901,1.462-2.847,2.194-4.731,3.667c-5.002-0.502-7.504-0.735-12.512-1.163c-1.561-1.792-2.348-2.687-3.926-4.472
			c0.118-1.378,0.179-2.064,0.297-3.442c2.151,0.173,3.226,0.261,5.376,0.447c-0.076,0.823-0.116,1.235-0.192,2.059
			c0.743,0.834,1.116,1.251,1.855,2.086c2.546,0.227,3.819,0.346,6.363,0.592c0.891-0.684,1.335-1.024,2.229-1.706
			c0.192-1.823,0.291-2.736,0.483-4.56c-0.736-0.858-1.106-1.289-1.847-2.146C272.771,247.307,270.862,247.132,267.043,246.806z"
          />
        </g>
      </g>
      <polygon
        fill="transparent"
        stroke={primary}
        stroke-width="2.9206"
        stroke-miterlimit="10"
        points="111.221,108.328 79.007,139.662 
	78.365,185.661 133.964,185.661 134.598,164.995 180.091,164.995 180.091,209.662 180.091,212.693 235.69,211.662 235.388,138.996 
	205.372,108.328 "
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

Artwrk22.props = {
  primary: string,
};

Artwrk22.defaultProps = {
  primary: "#000",
};
