import React from "react";
import { string } from "prop-types";

export default function Artwrk10(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="class_1_">
        <g id="Shape_1_copy_49">
          <path
            d="M584.701,656.072c-0.136-0.718-0.458-1.362-0.991-1.883c-0.508-0.508-1.003-1.028-1.523-1.523
			c-1.152-1.078-2.527-1.635-4.1-1.685c-0.867-0.025-1.722-0.037-2.589-0.037c-1.288-0.012-2.589,0.012-3.877-0.025
			c-0.347-0.012-0.681-0.149-0.979-0.223c-0.087-0.223-0.173-0.347-0.173-0.483c-0.012-1.722-0.025-3.456-0.025-5.177
			c0-0.582,0.124-1.14,0.322-1.685c0.186-0.508,0.533-0.818,1.053-0.991c1.189-0.409,2.415-0.297,3.629-0.273
			c0.842,0.012,1.523,0.396,2.007,1.09c0.285,0.409,0.533,0.855,0.83,1.263c0.211,0.297,0.52,0.495,0.879,0.594
			c1.499,0.372,2.985,0.372,4.471-0.037c0.694-0.186,1.115-0.644,1.239-1.35c0.173-1.003,0.173-2.007,0.037-3.01
			c-0.186-1.226-0.644-2.353-1.573-3.233c-0.31-0.297-0.619-0.594-0.929-0.904c-0.954-0.954-2.118-1.474-3.443-1.623
			c-0.495-0.062-1.003-0.099-1.499-0.099c-2.155-0.012-4.31-0.012-6.466,0.012c-0.644,0.012-1.288,0.062-1.932,0.149
			c-1.239,0.173-2.341,0.656-3.233,1.585c-0.248,0.26-0.508,0.508-0.78,0.743c-1.14,1.016-1.647,2.329-1.796,3.803
			c-0.05,0.434-0.062,0.867-0.062,1.288c0,2.948-0.012,5.896,0,8.844c0,0.644,0.062,1.288,0.136,1.932
			c0.074,0.656,0.334,1.239,0.718,1.784c0.582,0.842,1.338,1.474,2.13,2.118c1.053,0.867,2.23,1.276,3.555,1.4
			c0.495,0.049,1.003,0.062,1.511,0.062c1.152,0.012,2.304,0,3.456,0c0.434,0,0.867,0,1.288,0.037
			c0.545,0.05,0.941,0.359,1.189,0.83c0.161,0.31,0.31,0.656,0.372,1.003c0.099,0.632,0.161,1.288,0.186,1.932
			c0.025,1.226,0.012,2.44,0.012,3.666c0,0.211-0.025,0.434-0.049,0.644c-0.05,0.421-0.421,0.842-0.83,0.879
			c-1.437,0.124-2.874,0.136-4.298-0.037c-0.595-0.074-1.078-0.396-1.449-0.855c-0.359-0.446-0.681-0.917-1.028-1.375
			c-0.458-0.619-1.102-0.954-1.846-0.966c-1.288-0.025-2.589,0.012-3.877,0.062c-0.619,0.025-1.09,0.508-1.115,1.102
			c-0.025,1.152-0.037,2.304-0.012,3.443c0.012,0.347,0.112,0.706,0.211,1.053c0.359,1.214,1.09,2.18,2.068,2.96
			c0.78,0.619,1.61,1.201,2.391,1.784c0.396,0.037,0.756,0.099,1.115,0.099c3.518,0.012,7.048,0.025,10.565,0.012
			c0.421,0,0.842-0.112,1.177-0.161c0.396-0.372,0.706-0.669,1.028-0.954c0.434-0.384,0.879-0.743,1.301-1.14
			c0.979-0.904,1.635-2.007,1.771-3.357c0.062-0.57,0.149-1.14,0.149-1.722c0.012-3.097,0.025-6.181,0-9.277
			C584.949,657.484,584.837,656.766,584.701,656.072z"
          />
        </g>
        <g id="Shape_1_copy_48">
          <path
            d="M549.227,658.376c0-0.285,0-0.582-0.012-0.867c-0.149-1.796-0.78-3.344-2.267-4.459c-0.347-0.26-0.669-0.545-0.991-0.83
			c-0.83-0.768-1.808-1.152-2.923-1.214c-0.57-0.025-1.152-0.049-1.722-0.049c-1.078-0.012-2.155,0.012-3.233-0.012
			c-0.57-0.012-1.14-0.124-1.709-0.211c-0.793-0.124-1.276-0.632-1.486-1.375c-0.099-0.347-0.211-0.694-0.211-1.053
			c-0.025-1.585-0.025-3.159,0.012-4.744c0.012-0.743,0.644-1.4,1.362-1.424c1.362-0.05,2.737-0.062,4.1-0.012
			c0.706,0.025,1.424,0.198,1.982,0.793c0.124,0.471,0.223,0.954,0.372,1.437c0.161,0.533,0.533,0.855,1.09,0.892
			c1.585,0.124,3.158-0.025,4.719-0.322c0.074-0.012,0.136-0.05,0.198-0.074c0.359-0.149,0.644-0.421,0.644-0.793
			c0.037-1.362,0.062-2.725,0.012-4.087c-0.025-0.644-0.347-1.251-0.805-1.734c-0.644-0.669-1.325-1.325-1.982-1.982
			c-0.694-0.681-1.536-1.065-2.465-1.239c-0.632-0.111-1.276-0.235-1.92-0.235c-2.663-0.025-5.326,0-7.977-0.025
			c-0.978,0-1.846,0.285-2.638,0.818c-0.83,0.57-1.598,1.214-2.316,1.92c-0.966,0.954-1.462,2.118-1.598,3.456
			c-0.05,0.495-0.062,1.003-0.062,1.511c0,2.663,0,5.326,0,7.977c0,0.434,0.012,0.867,0.05,1.288
			c0.124,1.796,0.731,3.357,2.192,4.509c0.173,0.136,0.31,0.297,0.471,0.446c1.201,1.152,2.663,1.685,4.298,1.759
			c0.57,0.025,1.152,0.037,1.722,0.037c1.362,0,2.737,0,4.1,0c0.434,0,0.867,0.037,1.288,0.087c0.396,0.05,0.793,0.483,0.793,0.904
			c0.012,1.945,0.025,3.877,0,5.821c-0.012,0.644-0.124,1.288-0.421,1.932c-0.161,0.087-0.347,0.26-0.533,0.26
			c-1.573,0.025-3.158,0.037-4.732,0c-0.607-0.012-1.102-0.347-1.474-0.805c-0.359-0.446-0.644-0.954-0.966-1.424
			c-0.297-0.434-0.706-0.718-1.214-0.818c-1.573-0.297-3.134-0.384-4.707,0c-0.434,0.099-0.818,0.471-0.818,0.892
			c0,1.226-0.05,2.452,0.074,3.654c0.111,1.164,0.644,2.192,1.499,3.035c1.028,1.016,2.143,1.907,3.282,2.651
			c1.87,0.173,6.887,0.235,10.429,0.124c0.644-0.025,1.288-0.087,1.87-0.136c0.694-0.52,1.338-0.978,1.957-1.462
			c0.396-0.31,0.768-0.656,1.127-0.991c0.706-0.669,1.152-1.474,1.301-2.428c0.124-0.78,0.235-1.573,0.248-2.353
			C549.239,664.408,549.227,661.386,549.227,658.376z"
          />
        </g>
        <g id="Shape_1_copy_47">
          <path
            d="M512.7,667.554c-0.049-0.049-0.074-0.124-0.124-0.173c-0.954-1.214-1.437-2.626-1.709-4.137
			c-0.508-2.824-1.09-5.648-1.635-8.472c-0.446-2.254-0.892-4.509-1.325-6.775c-0.557-2.898-1.102-5.784-1.672-8.683
			c-0.149-0.78-0.359-1.536-0.557-2.304c-0.074-0.273-0.173-0.545-0.297-0.805c-0.297-0.607-0.756-1.053-1.437-1.189
			c-0.557-0.111-1.127-0.223-1.697-0.248c-1.437-0.037-2.874-0.025-4.31-0.025c-0.211,0-0.434,0.012-0.644,0.05
			c-1.139,0.186-2.031,0.718-2.391,1.895c-0.124,0.409-0.26,0.818-0.347,1.239c-0.619,3.022-1.263,6.057-1.821,9.091
			c-1.003,5.438-2.229,10.838-3.134,16.3c-0.074,0.421-0.173,0.842-0.285,1.263c-0.198,0.768-0.483,1.511-0.941,2.168
			c-0.211,0.297-0.396,0.607-0.595,0.892c-0.458,0.669-0.743,1.412-0.805,2.205c-0.074,0.929-0.099,1.87-0.062,2.799
			c0.037,0.718,0.235,1.412,0.805,1.994c0.248,0.037,0.533,0.136,0.818,0.136c2.737,0.012,5.462,0.025,8.2,0.012
			c0.211,0,0.421-0.087,0.83-0.173c0.731-2.267,0.78-4.447,0.012-6.639c-0.186-0.545-0.409-1.078-0.619-1.61
			c-0.359-0.917,0.272-1.672,0.917-1.907c0.334-0.124,0.694-0.198,1.053-0.235c1.152-0.124,2.304-0.025,3.431,0.211
			c0.694,0.149,1.115,0.619,1.424,1.214c0.272,0.533,0,0.966-0.26,1.412c-0.111,0.186-0.223,0.372-0.347,0.545
			c-0.533,0.805-0.768,1.697-0.855,2.638c-0.111,1.301-0.087,2.589,0.372,3.827c0.074,0.198,0.198,0.359,0.31,0.545
			c0.235,0.062,0.446,0.149,0.644,0.149c2.737,0.012,5.462,0.012,8.2,0c0.273,0,0.545-0.112,0.756-0.161
			c1.04-1.61,1.115-1.821,1.214-2.812C513.939,670.267,513.765,668.793,512.7,667.554z M501.466,655.279
			c-0.012,0.211-0.074,0.421-0.161,0.619c-0.186,0.421-0.533,0.681-0.991,0.718c-0.471,0.037-0.867-0.136-1.078-0.557
			c-0.161-0.31-0.31-0.669-0.322-1.003c-0.05-1.387,0.297-2.688,1.04-3.877c0.074-0.111,0.223-0.161,0.384-0.26
			c0.149,0.111,0.396,0.211,0.458,0.372C501.305,652.567,501.54,653.892,501.466,655.279z"
          />
        </g>
        <g id="Shape_1_copy_46">
          <path
            d="M472.916,666.439c0-0.285-0.025-0.57-0.062-0.855c-0.062-0.508-0.446-0.954-0.941-1.053
			c-1.561-0.297-3.134-0.446-4.719-0.31c-0.347,0.025-0.694,0.173-1.016,0.31c-0.272,0.111-0.471,0.347-0.508,0.656
			c-0.074,0.632-0.124,1.276-0.186,1.92c-0.198,0.136-0.359,0.334-0.545,0.359c-1.362,0.186-2.725,0.161-4.075,0.025
			c-0.409-0.037-0.818-0.471-0.855-0.867c-0.037-0.495-0.062-1.003-0.062-1.511c0-6.329,0.025-12.659-0.025-18.976
			c-0.012-1.573,0.272-2.997,1.301-4.211c0.05-0.049,0.087-0.111,0.124-0.173c0.396-0.533,0.607-1.14,0.681-1.796
			c0.124-1.152,0.136-2.291,0-3.443c-0.124-1.003-0.669-1.523-1.685-1.672c-0.136-0.025-0.285-0.049-0.434-0.049
			c-2.155,0-4.31-0.012-6.466,0.025c-0.57,0.012-1.14,0.173-1.685,0.31c-0.446,0.111-0.805,0.421-0.817,0.892
			c-0.05,1.288-0.074,2.589-0.012,3.877c0.025,0.483,0.272,0.966,0.483,1.412c0.149,0.322,0.384,0.607,0.607,0.892
			c0.756,0.941,1.09,2.007,1.053,3.208c-0.012,0.285,0,0.57,0,0.867c-0.012,5.611-0.025,11.209-0.037,16.82
			c0,0.508-0.012,1.003-0.025,1.511c0,0.656-0.173,1.276-0.483,1.858c-0.211,0.372-0.396,0.768-0.656,1.115
			c-0.495,0.656-0.756,1.412-0.867,2.18c-0.211,1.424-0.235,2.861,0.297,4.261c0.099,0.273,0.235,0.52,0.681,0.731
			c0.694,0.025,1.548,0.074,2.415,0.074c5.103,0.012,10.206,0,15.309,0c0.644,0,1.288-0.025,1.945-0.062
			c0.272-0.012,0.545-0.112,0.855-0.186c0.111-0.285,0.272-0.545,0.31-0.818c0.074-0.495,0.124-1.003,0.124-1.499
			C472.928,670.316,472.928,668.372,472.916,666.439z"
          />
        </g>
        <g id="Shape_1_copy_45">
          <path
            d="M436.86,664.965c-0.025-0.273-0.483-0.644-0.768-0.681c-0.136-0.012-0.285-0.05-0.421-0.05c-1.362,0-2.725-0.012-4.087,0
			c-0.347,0-0.706,0.087-1.053,0.186c-0.359,0.111-0.632,0.359-0.694,0.756c-0.087,0.632-0.124,1.276-0.186,1.821
			c-0.285,0.421-0.607,0.545-0.978,0.557c-1.87,0.012-3.741,0-5.599,0c-0.136,0-0.285-0.049-0.421-0.062
			c-0.322-0.049-0.706-0.396-0.743-0.706c-0.05-0.495-0.099-1.003-0.099-1.499c-0.012-7.048,0-14.083,0-21.131
			c0-0.434,0.025-0.867,0.05-1.288c0.025-0.322,0.384-0.694,0.706-0.718c1.796-0.149,3.592-0.124,5.376-0.012
			c0.359,0.025,0.694,0.235,0.917,0.52c0.446,0.557,0.867,1.14,1.313,1.709c0.421,0.533,0.954,0.879,1.647,0.892
			c1.288,0.025,2.589,0.025,3.877,0.012c0.768-0.012,1.239-0.471,1.263-1.189c0.025-1.003,0.062-2.019,0-3.022
			c-0.087-1.325-0.545-2.527-1.499-3.481c-0.458-0.458-0.941-0.892-1.4-1.338c-0.867-0.83-1.945-1.201-3.097-1.387
			c-0.347-0.062-0.718-0.062-1.078-0.062c-2.514-0.012-5.029-0.012-7.543,0c-0.434,0-0.867,0.025-1.288,0.074
			c-1.474,0.173-2.787,0.694-3.827,1.771c-0.347,0.359-0.731,0.694-1.053,1.078c-0.619,0.718-1.04,1.548-1.115,2.514
			c-0.062,0.793-0.099,1.573-0.099,2.366c0,8.336,0,16.672,0,25.008c0,0.285,0.012,0.57,0,0.867c-0.05,1.536,0.57,2.774,1.635,3.84
			c0.917,0.917,1.945,1.685,3.01,2.353c2.031,0.211,6.689,0.285,11.247,0.173c0.57-0.012,1.14-0.087,1.623-0.136
			c0.756-0.607,1.462-1.115,2.106-1.672c0.979-0.855,1.846-1.821,2.192-3.134C437.169,668.248,437.033,666.6,436.86,664.965z"
          />
        </g>
      </g>
      <g id="graduating_1_">
        <g id="Shape_1_copy_44">
          <path
            d="M203.443,637.134c-0.012-0.359-0.062-0.718-0.161-1.065c-0.186-0.694-0.669-1.102-1.362-1.226
			c-0.347-0.062-0.718-0.087-1.078-0.087c-2.378-0.012-4.744,0-7.122,0c-0.285,0-0.57,0.05-0.855,0.087
			c-0.396,0.062-0.805,0.495-0.817,0.892c-0.025,0.867,0,1.722-0.05,2.589c-0.087,1.573,0.334,2.985,1.226,4.273
			c0.087,0.124,0.161,0.235,0.248,0.359c0.372,0.557,0.545,1.164,0.52,1.833c-0.012,0.359,0,0.718,0,1.078c0,5.537,0,11.073,0,16.61
			c0,0.718,0,1.437-0.025,2.155c-0.037,0.855-0.099,1.722-0.359,2.502c-0.161,0.111-0.285,0.248-0.409,0.248
			c-1.078,0.074-2.143,0.149-3.22,0.173c-1.14,0.012-2.291,0.025-3.604-0.26c-0.124-0.57-0.272-0.978-0.31-1.4
			c-0.074-0.855-0.136-1.722-0.136-2.576c-0.012-3.233-0.012-6.466-0.012-9.711c0-2.44,0.05-4.893-0.012-7.333
			c-0.05-1.647,0.26-3.134,1.263-4.459c0.211-0.285,0.384-0.607,0.52-0.941c0.136-0.322,0.272-0.681,0.285-1.028
			c0.025-1.226,0.012-2.44-0.012-3.666c-0.012-0.52-0.372-0.954-0.904-1.065c-0.632-0.136-1.263-0.297-1.907-0.31
			c-2.007-0.037-4.025-0.025-6.032-0.012c-0.359,0-0.718,0.074-1.065,0.161c-0.669,0.173-1.127,0.557-1.35,1.251
			c-0.359,1.127-0.31,2.279-0.211,3.419c0.087,0.941,0.347,1.833,1.065,2.539c0.149,0.149,0.272,0.334,0.409,0.508
			c0.446,0.595,0.694,1.251,0.669,2.007c-0.012,0.644,0,1.288,0,1.945c0,6.54,0,13.08,0.012,19.632c0,0.793,0.025,1.585,0.037,2.366
			c0.025,1.362,0.57,2.49,1.523,3.456c0.966,0.979,2.019,1.821,3.097,2.651c0.458,0.037,0.879,0.099,1.313,0.099
			c3.666,0,7.333,0,10.999-0.012c0.359,0,0.706-0.062,1.065-0.099c0.533-0.396,1.065-0.768,1.548-1.189
			c0.595-0.52,1.152-1.078,1.722-1.635c0.694-0.681,1.065-1.523,1.115-2.502c0.05-1.003,0.111-2.007,0.111-3.01
			c0.012-6.181,0.012-12.361,0.012-18.554c0-0.644,0-1.288,0.012-1.945c0.025-1.325,0.272-2.576,1.177-3.617
			c0.136-0.161,0.235-0.359,0.359-0.533c0.508-0.731,0.731-1.548,0.718-2.44C203.443,638.57,203.467,637.852,203.443,637.134z"
          />
        </g>
        <g id="Shape_1_copy_39">
          <path
            d="M378.248,663.107c0-3.307,0-6.614,0-9.921c0-0.495-0.037-1.003-0.111-1.499c-0.05-0.297-0.285-0.52-0.57-0.594
			c-0.347-0.087-0.706-0.111-1.065-0.111c-2.514-0.012-5.029-0.012-7.543-0.025c-0.359,0-0.718,0-1.078,0.037
			c-0.619,0.074-1.065,0.421-1.313,0.979c-0.136,0.322-0.26,0.681-0.272,1.028c-0.037,1.288-0.012,2.589-0.012,3.877
			c0,0.074,0.012,0.149,0.025,0.211c0.074,0.471,0.347,0.793,0.768,0.954c0.334,0.124,0.681,0.223,1.028,0.31
			c0.557,0.149,1.115,0.285,1.672,0.446c0.533,0.149,0.842,0.533,1.003,1.04c0.111,0.334,0.198,0.694,0.211,1.053
			c0.025,1.437,0.012,2.874,0,4.31c0,0.285-0.05,0.57-0.136,0.842c-0.211,0.756-0.706,1.239-1.474,1.412
			c-0.211,0.05-0.421,0.074-0.644,0.074c-1.511,0.012-3.022,0.012-4.521,0c-0.632-0.012-0.929-0.285-1.016-0.879
			c-0.173-1.14-0.235-2.279-0.235-3.443c0.012-5.822,0-11.643,0-17.464c0-0.434-0.025-0.867,0.012-1.288
			c0.025-0.359,0.099-0.706,0.211-1.053c0.211-0.669,0.706-1.065,1.362-1.263c0.198-0.062,0.421-0.099,0.632-0.099
			c1.226-0.012,2.44-0.012,3.666,0c0.694,0.012,1.239,0.347,1.647,0.892c0.31,0.396,0.595,0.818,0.892,1.214
			c0.31,0.421,0.731,0.681,1.251,0.793c1.424,0.285,2.861,0.285,4.286,0c0.533-0.111,0.904-0.434,1.053-0.966
			c0.495-1.796,0.446-3.555-0.396-5.239c-0.124-0.26-0.285-0.508-0.458-0.718c-1.672-2.044-3.728-3.344-6.478-3.282
			c-0.285,0.012-0.57-0.012-0.867,0c-1.945,0-3.877,0-5.822,0.012c-0.508,0-1.003-0.012-1.511,0.037
			c-1.387,0.136-2.675,0.545-3.703,1.548c-0.57,0.545-1.14,1.09-1.685,1.66c-0.718,0.756-1.14,1.647-1.189,2.7
			c-0.037,0.718-0.074,1.437-0.074,2.155c0,8.187,0,16.387,0,24.574c0,0.433,0,0.867,0.012,1.288
			c0.025,1.127,0.434,2.118,1.201,2.923c0.595,0.619,1.239,1.202,1.895,1.759c0.545,0.458,1.152,0.842,1.746,1.263
			c0.508,0.037,1.003,0.112,1.511,0.112c3.233,0,6.466,0,9.698-0.012c0.495,0,1.003-0.074,1.486-0.124
			c0.718-0.508,1.276-0.855,1.771-1.263c0.607-0.495,1.189-1.04,1.759-1.585c0.533-0.508,0.855-1.152,1.053-1.858
			c0.235-0.83,0.359-1.685,0.347-2.552C378.236,665.981,378.248,664.544,378.248,663.107z"
          />
        </g>
        <g id="Shape_1_copy_38">
          <path
            d="M341.808,636.031c0-0.471-0.31-0.855-0.756-0.929c-0.706-0.124-1.412-0.285-2.13-0.297c-1.87-0.05-3.741-0.05-5.611-0.025
			c-0.495,0-0.991,0.136-1.486,0.235c-0.533,0.124-0.892,0.471-1.003,1.016c-0.434,2.229-0.607,4.422,0.979,6.354
			c0.458,0.557,0.756,1.226,0.817,1.969c0.149,1.932,0.211,3.877-0.037,5.809c-0.025,0.211-0.124,0.409-0.198,0.607
			c-0.087,0.223-0.508,0.347-0.743,0.248c-0.471-0.223-0.83-0.57-1.053-1.04c-0.272-0.582-0.508-1.189-0.743-1.796
			c-1.251-3.295-2.49-6.589-3.765-9.872c-0.31-0.805-0.718-1.573-1.102-2.341c-0.235-0.471-0.656-0.768-1.152-0.917
			c-0.409-0.124-0.842-0.248-1.263-0.26c-2.007-0.025-4.025-0.012-6.032,0.012c-0.619,0.012-1.028,0.396-1.226,1.028
			c-0.26,0.83-0.297,1.697-0.334,2.552c-0.05,1.412,0.26,2.725,1.189,3.827c0.743,0.879,0.991,1.883,0.991,2.997
			c0,0.285,0,0.57,0,0.867c0,5.611,0,11.222,0,16.82c0,0.508,0,1.003-0.012,1.511c-0.025,0.879-0.198,1.709-0.718,2.44
			c-0.285,0.409-0.533,0.855-0.78,1.288c-0.396,0.694-0.644,1.449-0.644,2.254c-0.012,1.152,0,2.304,0.012,3.443
			c0,0.297,0.124,0.557,0.372,0.768c0.52,0.248,1.102,0.211,1.672,0.223c5.289,0.099,7.097,0.062,8.608-0.173
			c0.52-0.793,0.582-0.892,0.595-1.338c0.012-1.152,0.025-2.304,0-3.456c-0.012-0.656-0.235-1.263-0.595-1.821
			c-0.235-0.359-0.458-0.731-0.694-1.09c-0.57-0.867-0.867-1.796-0.855-2.849c0.012-1.226,0-2.44-0.012-3.666
			c0-0.941,0.235-1.846,0.557-2.725c0.037-0.111,0.211-0.173,0.359-0.273c0.731,0.706,1.177,1.585,1.523,2.527
			c0.458,1.214,0.904,2.428,1.362,3.629c0.991,2.626,1.994,5.239,2.973,7.865c0.458,1.226,1.09,2.329,2.106,3.245
			c1.846,0.198,3.716,0.124,5.574,0.037c0.272-0.012,0.545-0.124,0.743-0.161c0.434-0.446,0.632-0.904,0.656-1.424
			c0.025-0.644,0.037-1.288,0.037-1.945c0-8.336,0-16.684,0-25.02c0-0.793,0.012-1.573,0.012-2.378
			c0.434-0.768,0.743-1.325,1.078-1.895c0.446-0.768,0.694-1.573,0.706-2.465C341.808,638.335,341.821,637.183,341.808,636.031z"
          />
        </g>
        <g id="Shape_1_copy_37">
          <path
            d="M300.104,667.492c-0.879-1.14-1.214-2.415-1.14-3.84c0.025-0.359,0-0.718,0-1.078c0-5.388-0.012-10.776-0.012-16.164
			c0-1.846-0.248-2.49,1.362-4.608c0.396-0.52,0.632-1.14,0.718-1.784c0.161-1.214,0.198-2.428,0.025-3.654
			c-0.111-0.818-0.557-1.288-1.35-1.474c-0.272-0.062-0.57-0.124-0.855-0.124c-2.007,0-4.025,0-6.032,0.012
			c-0.582,0-1.14,0.136-1.672,0.384c-0.409,0.186-0.731,0.471-0.879,0.892c-0.124,0.334-0.272,0.681-0.31,1.028
			c-0.198,1.883-0.223,3.741,1.09,5.326c0.087,0.111,0.149,0.248,0.235,0.359c0.396,0.619,0.607,1.301,0.669,2.031
			c0.037,0.433,0.05,0.855,0.05,1.288c0,6.181,0.012,12.349,0,18.53c0,0.421-0.062,0.855-0.099,1.325
			c-0.396,0.582-0.793,1.189-1.189,1.771c-0.458,0.669-0.743,1.4-0.793,2.205c-0.087,1.499-0.149,3.01,0.025,4.471
			c0.149,0.124,0.248,0.285,0.359,0.297c0.347,0.062,0.706,0.111,1.065,0.111c2.799,0.012,5.599,0,8.398,0
			c0.211,0,0.434-0.012,0.644-0.025c0.384-0.025,0.694-0.297,0.694-0.669c0.025-1.362,0.025-2.725,0.025-4.087
			c0-0.582-0.173-1.14-0.458-1.635C300.513,668.074,300.315,667.777,300.104,667.492z"
          />
        </g>
        <g id="Shape_1_copy_36">
          <path
            d="M275.939,638.149c-0.037-0.78-0.211-1.561-0.384-2.329c-0.161-0.731-0.607-1.028-1.412-1.065
			c-0.149,0-0.285-0.012-0.434-0.012c-7.258,0-14.517,0-21.775,0c-0.285,0-0.57,0.025-0.855,0.062
			c-0.396,0.049-0.78,0.471-0.83,0.879c-0.012,0.149-0.037,0.285-0.037,0.434c0,2.378-0.012,4.744-0.012,7.122
			c0,0.149,0.012,0.285,0.025,0.433c0.074,0.83,0.706,1.511,1.523,1.573c0.718,0.05,1.437,0.05,2.155,0.05
			c1.969-0.012,2.366,0.211,3.728-1.895c0.235-0.372,0.557-0.656,1.053-0.768c0.149,0.087,0.372,0.149,0.508,0.297
			c0.136,0.149,0.26,0.372,0.272,0.57c0.05,0.644,0.074,1.288,0.074,1.932c-0.012,4.893-0.05,9.773-0.062,14.665
			c0,1.078,0.05,2.155,0.062,3.233c0.012,1.61-0.235,3.146-1.313,4.447c-0.087,0.111-0.161,0.235-0.248,0.359
			c-0.396,0.545-0.582,1.164-0.632,1.808c-0.099,1.226,0,2.44,0.297,3.642c0.111,0.421,0.285,0.818,0.793,1.102
			c0.173,0.025,0.458,0.099,0.731,0.099c2.44,0.012,4.893,0.025,7.333,0.012c0.359,0,0.706-0.074,0.879-0.087
			c1.276-0.855,1.424-1.833,1.449-2.874c0.037-1.387-0.111-2.725-0.867-3.939c-0.149-0.248-0.26-0.508-0.409-0.756
			c-0.495-0.83-0.681-1.734-0.756-2.675c-0.037-0.495-0.037-1.003-0.037-1.511c0-5.537,0-11.073,0-16.61
			c0-0.433-0.012-0.867,0.025-1.288c0.05-0.644,0.198-1.276,0.495-1.858c0.111-0.211,0.458-0.421,0.718-0.359
			c0.198,0.05,0.434,0.136,0.545,0.285c0.173,0.223,0.248,0.508,0.359,0.768c0.26,0.656,0.743,1.04,1.424,1.177
			c1.424,0.297,2.861,0.31,4.286,0.012c0.607-0.124,1.016-0.533,1.189-1.127c0.099-0.347,0.186-0.706,0.186-1.053
			C275.976,641.32,276.001,639.735,275.939,638.149z"
          />
        </g>
        <g id="Shape_1_copy_35">
          <path
            d="M241.258,667.443c-0.879-0.904-1.375-2.007-1.623-3.233c-0.037-0.211-0.111-0.421-0.161-0.632
			c-0.52-2.539-1.04-5.066-1.536-7.605c-0.718-3.666-1.412-7.345-2.13-11.011c-0.483-2.465-1.003-4.93-1.536-7.395
			c-0.124-0.557-0.322-1.102-0.533-1.635c-0.334-0.805-0.917-1.226-1.833-1.214c-1.945,0.037-3.889,0.012-5.822,0.012
			c-0.211,0-0.434,0.025-0.644,0.037c-0.694,0.062-1.214,0.409-1.585,0.991c-0.471,0.743-0.669,1.573-0.842,2.428
			c-0.508,2.539-1.04,5.066-1.548,7.605c-0.458,2.254-0.892,4.509-1.338,6.775c-0.718,3.666-1.449,7.333-2.155,10.999
			c-0.285,1.511-0.756,2.911-1.771,4.1c-0.582,0.681-0.855,1.511-0.867,2.391c-0.025,1.362,0,2.725,0.037,4.087
			c0,0.186,0.186,0.359,0.297,0.557c0.31,0.037,0.595,0.087,0.879,0.087c3.097,0,6.181,0,9.277-0.012
			c0.211,0,0.421-0.074,0.619-0.099c0.495-0.409,0.681-0.966,0.694-1.548c0.025-0.929,0.012-1.87-0.05-2.799
			c-0.05-0.867-0.322-1.685-0.867-2.403c-0.26-0.347-0.495-0.706-0.718-1.078c-0.347-0.57-0.458-1.201-0.372-1.87
			c0.037-0.248,0.285-0.582,0.508-0.644c0.211-0.049,0.421-0.149,0.619-0.149c1.437-0.012,2.874-0.025,4.31,0
			c0.285,0,0.57,0.099,0.842,0.186c0.545,0.161,0.941,0.78,0.879,1.338c-0.074,0.718-0.26,1.412-0.619,2.056
			c-0.384,0.706-0.545,1.486-0.669,2.267c-0.198,1.301-0.05,2.589,0.235,3.852c0.062,0.26,0.223,0.508,0.359,0.793
			c0.545,0.049,1.04,0.111,1.536,0.124c5.363,0.087,6.416,0.062,8.447-0.186c0.557-0.533,0.768-1.065,0.78-1.66
			c0.025-0.867,0.025-1.722,0.025-2.589C242.397,669.263,242.05,668.273,241.258,667.443z M229.813,655.552
			c-0.334,0.954-0.855,1.276-2.019,1.003c-0.223-0.049-0.52-0.409-0.533-0.619c-0.012-0.136-0.05-0.285-0.037-0.421
			c0.111-1.276,0.334-2.539,0.669-3.79c0.074-0.26,0.272-0.495,0.421-0.731c0.099-0.161,0.421-0.198,0.582-0.074
			c0.111,0.087,0.26,0.161,0.322,0.273c0.483,1.04,0.681,2.168,0.805,3.332C229.949,654.858,229.924,655.217,229.813,655.552z"
          />
        </g>
        <g id="Shape_1_copy_34">
          <path
            d="M162.89,643.215c0-0.508-0.012-1.003-0.062-1.511c-0.235-2.477-0.223-3.109-2.725-5.363
			c-0.545-0.495-1.152-0.842-1.846-1.09c-0.743-0.273-1.523-0.446-2.316-0.483c-0.508-0.025-1.003-0.037-1.511-0.037
			c-4.533-0.012-9.067-0.012-13.588-0.012c-0.434,0-0.867,0.012-1.288,0.049c-0.533,0.037-0.954,0.409-1.053,0.929
			c-0.248,1.202-0.272,2.428-0.223,3.654c0.037,1.102,0.26,2.143,0.991,3.01c0.087,0.111,0.149,0.248,0.235,0.359
			c0.52,0.731,0.718,1.561,0.731,2.44c0,0.508,0.012,1.003,0.012,1.511c0,3.158,0.012,6.329,0.012,9.488
			c0,2.737-0.012,5.462,0.012,8.2c0.012,0.966-0.211,1.846-0.718,2.663c-0.149,0.248-0.297,0.495-0.471,0.731
			c-0.458,0.595-0.681,1.276-0.706,2.007c-0.05,1.003-0.074,2.007-0.05,3.022c0.012,0.644,0.161,1.288,0.607,1.808
			c0.136,0.049,0.26,0.124,0.396,0.149c0.211,0.037,0.421,0.074,0.644,0.074c5.388,0.012,10.788,0.012,16.176,0.012
			c0.644,0,1.288-0.062,1.846-0.099c1.127-0.681,2.093-1.424,2.973-2.291c1.251-1.214,1.907-2.675,1.982-4.409
			c0.037-0.793,0.062-1.585,0.062-2.366C162.965,658.178,162.928,650.697,162.89,643.215z M155.298,654.536L155.298,654.536
			c0,3.369,0,6.738,0,10.107c0,0.495-0.025,1.003-0.087,1.499c-0.037,0.285-0.248,0.508-0.52,0.632
			c-0.198,0.087-0.396,0.173-0.607,0.223c-1.759,0.409-3.555,0.409-5.351,0.285c-0.446-0.025-0.83-0.409-0.867-0.818
			c-0.037-0.359-0.05-0.718-0.05-1.065c0-6.812,0-13.625,0-20.437c0-0.495,0.025-1.003,0.074-1.499
			c0.062-0.57,0.557-1.09,1.102-1.189c0.347-0.062,0.706-0.124,1.065-0.124c1.362-0.025,2.725-0.025,4.087-0.025
			c0.136,0,0.285,0.037,0.421,0.062c0.31,0.037,0.656,0.396,0.681,0.731c0.025,0.434,0.05,0.855,0.05,1.288
			C155.298,647.65,155.298,651.093,155.298,654.536z"
          />
        </g>
        <g id="Shape_1_copy_33">
          <path
            d="M122.957,666.898c-0.756-1.003-1.09-2.168-1.313-3.381c-0.644-3.394-1.325-6.775-1.982-10.157
			c-0.595-3.035-1.164-6.069-1.759-9.104c-0.396-2.044-0.805-4.087-1.226-6.131c-0.074-0.347-0.211-0.694-0.272-1.04
			c-0.26-1.548-1.301-2.13-2.737-2.279c-0.149-0.012-0.285-0.037-0.434-0.037c-1.511,0-3.022-0.012-4.533,0.012
			c-0.879,0.012-1.697,0.26-2.403,0.768c-0.867,1.412-0.892,1.449-1.152,2.774c-0.52,2.601-1.04,5.215-1.561,7.828
			c-0.421,2.118-0.879,4.224-1.301,6.342c-0.768,3.877-1.511,7.766-2.254,11.643c-0.161,0.867-0.471,1.647-0.941,2.391
			c-0.347,0.545-0.669,1.102-0.991,1.66c-0.334,0.57-0.508,1.189-0.557,1.846c-0.099,1.301-0.111,2.589,0.272,3.852
			c0.074,0.26,0.223,0.508,0.297,0.669c0.495,0.322,0.941,0.285,1.362,0.285c2.514,0.012,5.029,0,7.556,0
			c0.285,0,0.57-0.037,0.855-0.062c0.26-0.025,0.545-0.273,0.607-0.545c0.483-2.291,0.706-4.558-0.495-6.713
			c-0.248-0.446-0.334-0.941-0.347-1.449c-0.012-0.842,0.533-1.561,1.35-1.709c1.35-0.248,2.725-0.198,4.075-0.062
			c0.545,0.062,0.892,0.409,1.078,0.929c0.198,0.57,0.173,1.14,0,1.697c-0.087,0.273-0.223,0.533-0.347,0.78
			c-1.04,2.031-1.003,4.149-0.434,6.292c0.05,0.198,0.198,0.372,0.347,0.644c0.285,0.074,0.434,0.136,0.57,0.136
			c2.948,0.012,5.896,0.025,8.844,0.012c0.198,0,0.384-0.149,0.533-0.211c0.619-1.028,0.582-2.106,0.607-3.183
			C124.295,669.734,123.973,668.223,122.957,666.898z M112.169,655.49c-0.074,0.594-0.545,1.078-1.102,1.115
			c-0.656,0.05-1.263-0.396-1.412-1.04c-0.26-1.09-0.05-2.118,0.434-3.097c0.161-0.31,0.384-0.595,0.595-0.879
			c0.074-0.099,0.235-0.149,0.359-0.223c0.124,0.074,0.285,0.124,0.347,0.223C112.045,652.815,112.33,654.103,112.169,655.49z"
          />
        </g>
        <g id="Shape_1_copy_32">
          <path
            d="M83.557,669.561c-0.074-0.595-0.334-1.09-0.78-1.499c-0.421-0.384-0.842-0.78-1.313-1.115
			c-0.731-0.52-1.201-1.239-1.536-2.044c-0.756-1.858-1.474-3.741-2.279-5.784c0.272-0.446,0.446-0.855,0.718-1.177
			c0.706-0.818,1.437-1.61,2.192-2.366c0.904-0.904,1.424-1.982,1.598-3.22c0.087-0.57,0.099-1.14,0.099-1.722
			c0.012-2.948,0-5.896,0-8.844c0-0.211,0-0.434,0-0.644c-0.025-1.35-0.458-2.539-1.412-3.518c-0.446-0.458-0.917-0.904-1.387-1.35
			c-0.694-0.656-1.523-1.09-2.465-1.263c-0.706-0.124-1.424-0.211-2.13-0.248c-1.003-0.037-2.007-0.012-3.022-0.012
			c-3.233,0-6.466-0.012-9.698,0c-0.718,0-1.437,0.062-2.143,0.149c-0.979,0.124-1.548,0.731-1.66,1.697
			c-0.099,0.855-0.149,1.709-0.173,2.576c-0.025,1.04,0.248,1.994,0.929,2.812c0.966,1.164,1.325,2.527,1.263,4.013
			c-0.012,0.285,0,0.57,0,0.867c0,5.388,0,10.776,0,16.176c0,0.57-0.025,1.152-0.05,1.722c-0.037,0.731-0.235,1.412-0.656,2.019
			c-0.322,0.471-0.632,0.954-0.941,1.449c-0.384,0.632-0.582,1.301-0.57,2.044c0.012,0.793-0.025,1.585,0.025,2.366
			c0.025,0.421,0.124,0.855,0.26,1.251c0.161,0.508,0.495,0.867,1.09,0.867c2.155,0.012,4.31,0.037,6.466,0.025
			c0.867,0,1.722-0.037,2.589-0.087c0.198-0.012,0.396-0.136,0.595-0.211c0.074-0.223,0.211-0.409,0.211-0.607
			c0.012-1.511,0.087-3.022-0.062-4.521c-0.099-1.065-0.805-1.982-1.251-2.96c-0.173-0.384-0.322-0.805-0.359-1.226
			c-0.149-1.647-0.186-3.307,0.037-4.942c0.136-0.978,0.904-1.325,1.722-0.756c0.285,0.198,0.57,0.458,0.756,0.743
			c0.31,0.483,0.57,1.003,0.78,1.536c0.681,1.734,1.338,3.481,2.007,5.239c0.582,1.548,1.14,3.097,1.734,4.645
			c0.434,1.152,0.991,2.229,1.994,3.059c1.833,0.211,3.703,0.087,5.561,0.087c0.285,0,0.557-0.099,0.879-0.173
			c0.161-0.409,0.409-0.793,0.446-1.201C83.718,672.137,83.73,670.849,83.557,669.561z M75.097,648.554
			c0,0.211-0.025,0.434-0.062,0.644c-0.111,0.706-0.694,1.338-1.375,1.499c-1.338,0.31-2.7,0.31-4.063,0.173
			c-1.102-0.112-1.87-0.917-1.907-1.994c-0.037-1.288-0.037-2.576,0.012-3.865c0.025-0.557,0.211-1.127,0.384-1.66
			c0.285-0.904,0.644-1.201,1.536-1.214c1.362-0.025,2.725-0.012,4.087,0c0.211,0,0.421,0.062,0.632,0.111
			c0.322,0.062,0.656,0.421,0.681,0.743c0.037,1.003,0.062,2.007,0.087,3.01c-0.012,0-0.012,0-0.025,0
			C75.097,646.832,75.097,647.699,75.097,648.554z"
          />
        </g>
        <g id="Shape_1_copy_31">
          <path
            d="M44.677,652.195c-0.062-0.533-0.595-1.102-1.102-1.201c-0.211-0.037-0.421-0.087-0.644-0.087
			c-2.874,0-5.747-0.012-8.633,0c-0.285,0-0.582,0.05-0.855,0.136c-0.595,0.173-1.003,0.557-1.14,1.177
			c-0.025,0.136-0.062,0.285-0.062,0.421c-0.012,1.288-0.025,2.589-0.025,3.877c0,0.211,0.037,0.434,0.087,0.644
			c0.31,1.189,0.756,1.486,1.784,1.499c0.285,0,0.582-0.012,0.867,0.012c0.359,0.025,0.718,0.037,1.065,0.112
			c0.372,0.074,0.632,0.322,0.78,0.669c0.062,0.136,0.111,0.26,0.149,0.409c0.384,1.771,0.409,3.567,0.31,5.363
			c-0.025,0.421-0.161,0.842-0.272,1.263c-0.099,0.359-0.347,0.632-0.706,0.756c-1.276,0.421-4.335,0.384-5.537-0.062
			c-0.582-0.223-0.966-0.644-1.078-1.251c-0.099-0.557-0.149-1.14-0.149-1.709c-0.012-6.329-0.012-12.659,0-18.988
			c0-0.495,0.025-1.003,0.099-1.499c0.099-0.595,0.421-1.065,0.979-1.338c0.26-0.124,0.533-0.285,0.805-0.285
			c1.647-0.025,3.307-0.025,4.954,0.012c0.248,0,0.495,0.235,0.632,0.31c0.458,0.768,0.099,1.561,0.533,2.217
			c0.495,0.434,1.102,0.644,1.771,0.656c0.867,0.025,1.734,0.05,2.589-0.012c0.644-0.05,1.276-0.186,1.907-0.347
			c0.52-0.136,0.855-0.545,0.904-1.09c0.111-1.078,0.062-2.155-0.099-3.22c-0.186-1.251-0.706-2.329-1.623-3.208
			c-0.31-0.297-0.619-0.594-0.929-0.892c-0.855-0.83-1.87-1.338-3.022-1.573c-0.557-0.111-1.14-0.211-1.709-0.211
			c-2.663-0.025-5.326-0.025-7.977,0c-0.57,0-1.152,0.087-1.709,0.198c-2.304,0.434-3.765,1.982-4.979,3.827
			c-0.186,0.285-0.26,0.669-0.347,1.016c-0.248,0.979-0.322,1.982-0.322,2.997c0.012,2.663,0,5.326,0,7.977
			c0,5.326,0,10.64,0.012,15.966c0,0.855,0.087,1.722,0.173,2.576c0.124,1.276,0.768,2.291,1.647,3.196
			c0.198,0.211,0.421,0.384,0.656,0.557c0.743,0.57,1.486,1.115,2.143,1.623c2.49,0.235,11.259,0.211,13.253-0.025
			c1.016-0.681,1.969-1.424,2.849-2.279c1.14-1.102,1.759-2.44,1.907-4.001c0.074-0.78,0.111-1.573,0.111-2.366
			c0.012-4.174,0.012-8.336,0.012-12.51C44.739,653.062,44.726,652.629,44.677,652.195z"
          />
        </g>
      </g>
      <g id="twelve_1_">
        <g id="Shape_1_copy_30">
          <path
            d="M579.14,561.231c-0.508-0.025-1.003-0.037-1.511-0.037c-2.663,0-5.326,0-7.977,0c-0.57,0-1.14-0.037-1.647-0.062
			c-0.149-0.173-0.297-0.26-0.322-0.384c-0.074-0.347-0.124-0.706-0.124-1.065c-0.012-4.174,0-8.336,0-12.51
			c0-0.285,0.025-0.57,0.05-0.855c0.025-0.235,0.322-0.52,0.594-0.533c0.57-0.012,1.152-0.025,1.722-0.037
			c2.304-0.012,4.608-0.012,6.899-0.012c0.297,0,0.57-0.062,0.83-0.384c0-0.409,0-0.904,0-1.387
			c-0.111-0.111-0.211-0.272-0.322-0.285c-0.421-0.062-0.855-0.112-1.276-0.112c-2.229-0.012-4.459,0-6.689-0.012
			c-0.495,0-0.991-0.062-1.412-0.087c-0.434-0.409-0.396-0.855-0.396-1.276c0-4.1,0-8.2,0-12.299c0-0.285,0.037-0.57,0.099-0.855
			c0.025-0.124,0.149-0.235,0.285-0.421c0.52-0.025,1.09-0.062,1.66-0.062c2.799-0.012,5.611,0,8.41-0.012
			c0.495,0,0.991-0.049,1.412-0.074c0.421-0.421,0.347-0.867,0.334-1.288c0-0.297-0.087-0.557-0.372-0.731
			c-1.511-0.112-12.399-0.136-13.786-0.025c-0.062,0-0.111,0.087-0.322,0.26c-0.161,0.731-0.099,1.449-0.099,2.168
			c0,10.714,0,21.428,0,32.142c0,0.508,0,1.003,0.012,1.511c0,0.136,0.012,0.31,0.074,0.421c0.112,0.161,0.273,0.31,0.434,0.421
			c0.111,0.062,0.285,0.05,0.421,0.05c4.248,0,8.484,0,12.733-0.012c0.198,0,0.409-0.087,0.595-0.136
			c0.322-0.83,0.334-0.929,0.26-1.474C579.685,561.529,579.4,561.244,579.14,561.231z"
          />
        </g>
        <g id="Shape_1_copy_29">
          <path
            d="M547.53,526.773c-0.012-0.112-0.149-0.297-0.235-0.31c-0.495-0.062-0.991-0.074-1.412-0.111
			c-0.186,0.149-0.347,0.211-0.384,0.322c-0.149,0.396-0.285,0.817-0.384,1.239c-1.201,5.475-2.391,10.937-3.58,16.412
			c-1.04,4.769-2.081,9.537-3.121,14.306c-0.049,0.211-0.124,0.421-0.211,0.607c-0.074,0.161-0.124,0.384-0.434,0.285
			c-0.248-1.102-0.495-2.217-0.743-3.332c-1.226-5.611-2.465-11.222-3.691-16.833c-0.892-4.063-1.784-8.138-2.675-12.2
			c-0.062-0.272-0.173-0.545-0.582-0.768c-0.359,0-0.855-0.037-1.35,0c-0.186,0.012-0.359,0.161-0.557,0.26
			c0.025,0.322,0.025,0.619,0.074,0.892c0.173,0.842,0.372,1.685,0.545,2.527c1.672,7.568,3.332,15.148,5.004,22.716
			c0.731,3.295,1.449,6.589,2.192,9.884c0.049,0.198,0.186,0.372,0.26,0.533c1.598,0.297,2.341,0.285,3.468-0.087
			c0.136-0.508,0.31-1.053,0.434-1.61c0.495-2.242,0.954-4.496,1.449-6.738c1.201-5.549,2.403-11.086,3.617-16.622
			c0.768-3.505,1.536-7.011,2.304-10.516C547.53,527.355,547.542,527.058,547.53,526.773z"
          />
        </g>
        <g id="Shape_1_copy_28">
          <path
            d="M512.7,561.479c-0.421-0.347-0.867-0.273-1.288-0.273c-2.514-0.012-5.029,0-7.543-0.012c-0.718,0-1.424-0.025-2.291-0.037
			c-0.136-0.359-0.26-0.545-0.272-0.743c-0.037-0.718-0.037-1.437-0.037-2.155c0-9.698,0-19.397,0-29.095
			c0-0.718,0-1.437-0.05-2.155c-0.012-0.186-0.161-0.372-0.272-0.607c-0.52-0.012-1.016-0.037-1.511-0.012
			c-0.186,0.012-0.372,0.173-0.595,0.285c-0.012,0.731-0.037,1.437-0.037,2.155c0,10.702,0,21.403,0,32.117
			c0,0.57,0,1.152,0.025,1.722c0.012,0.198,0.149,0.384,0.211,0.545c1.313,0.211,4.1,0.26,12.077,0.186
			c0.495,0,1.003,0.099,1.499-0.198C513.01,562.643,512.973,562.036,512.7,561.479z"
          />
        </g>
        <g id="Shape_1_copy_27">
          <path
            d="M478.799,561.207c-2.799-0.012-5.611,0-8.41-0.012c-0.347,0-0.706-0.049-1.053-0.124c-0.111-0.025-0.26-0.173-0.285-0.285
			c-0.074-0.347-0.136-0.706-0.136-1.053c-0.012-2.44-0.012-4.893-0.012-7.333c0-1.796,0-3.592,0.012-5.388
			c0-0.347-0.05-0.718,0.26-1.078c0.149-0.05,0.334-0.149,0.533-0.161c0.793-0.025,1.585-0.025,2.378-0.025
			c2.081-0.012,4.174-0.025,6.255-0.05c0.124,0,0.26-0.099,0.396-0.161c0.037-0.05,0.136-0.087,0.136-0.149
			c0.037-0.495,0.05-0.991,0.074-1.437c-0.186-0.149-0.285-0.297-0.396-0.31c-0.421-0.05-0.855-0.087-1.288-0.087
			c-2.155-0.012-4.31,0-6.466-0.012c-0.434,0-0.867-0.025-1.288-0.049c-0.235-0.012-0.52-0.334-0.533-0.595
			c-0.025-0.285-0.05-0.57-0.05-0.855c0-4.026,0-8.051,0-12.076c0-0.211,0.012-0.434,0.025-0.644
			c0.025-0.297,0.149-0.545,0.545-0.743c0.607-0.012,1.313-0.025,2.031-0.025c2.589,0,5.177,0,7.766,0
			c0.434,0,0.855-0.037,1.288-0.087c0.124-0.012,0.297-0.124,0.334-0.235c0.198-0.619,0.31-1.239-0.149-1.784
			c-1.449-0.111-10.826-0.173-13.402-0.087c-0.211,0.012-0.409,0.074-0.756,0.149c-0.223,0.929-0.136,1.722-0.136,2.502
			c-0.012,10.565,0,21.143,0,31.709c0,0.644,0,1.301,0.025,1.945c0.012,0.198,0.149,0.384,0.235,0.607
			c0.495,0.161,1.003,0.124,1.499,0.124c3.381,0,6.763,0,10.144,0c0.644,0,1.301,0,1.945-0.025c0.198-0.012,0.396-0.136,0.557-0.198
			c0.285-1.152,0.285-1.152-0.136-1.858C480.1,561.132,479.443,561.207,478.799,561.207z"
          />
        </g>
        <g id="Shape_1_copy_26">
          <path
            d="M448.738,526.426c-0.57-0.012-1.189-0.235-1.771,0.173c-0.05,0.074-0.111,0.136-0.111,0.198
			c-1.722,10.627-3.456,21.255-5.177,31.894c-0.074-0.037-0.161-0.037-0.173-0.087c-0.136-0.334-0.31-0.656-0.372-0.991
			c-0.149-0.78-0.235-1.561-0.359-2.341c-0.359-2.341-0.731-4.682-1.102-7.023c-0.347-2.267-0.681-4.546-1.028-6.812
			c-0.384-2.49-0.78-4.967-1.164-7.456c-0.334-2.13-0.644-4.261-0.991-6.391c-0.05-0.347-0.149-0.706-0.57-1.016
			c-0.656-0.087-1.424-0.223-2.18,0.223c-0.458,1.907-0.681,3.827-0.991,5.747c-0.495,3.047-0.979,6.094-1.462,9.153
			c-0.52,3.258-1.053,6.528-1.573,9.785c-0.322,1.982-0.619,3.976-0.954,5.958c-0.087,0.483-0.124,1.003-0.57,1.387
			c-0.062-0.211-0.136-0.396-0.173-0.595c-0.347-2.13-0.681-4.261-1.028-6.379c-0.508-3.047-1.028-6.094-1.536-9.141
			c-0.644-3.902-1.251-7.803-1.895-11.693c-0.235-1.412-0.52-2.824-0.793-4.236c-0.025-0.111-0.173-0.211-0.334-0.384
			c-0.557,0.087-1.201-0.223-1.784,0.149c-0.05,0.099-0.099,0.161-0.099,0.211c0.087,0.78,0.149,1.573,0.272,2.353
			c0.372,2.415,0.78,4.818,1.164,7.234c0.483,2.973,0.966,5.958,1.449,8.93c0.52,3.196,1.028,6.379,1.548,9.575
			c0.434,2.626,0.855,5.239,1.301,7.865c0.037,0.198,0.186,0.372,0.297,0.607c0.991,0.136,1.982,0.173,2.923-0.012
			c0.421-0.632,0.495-1.276,0.607-1.895c0.347-2.056,0.669-4.112,1.028-6.168c0.991-5.809,1.994-11.606,2.985-17.415
			c0.173-0.991,0.334-1.982,0.545-2.96c0.037-0.161,0.272-0.285,0.471-0.495c0.074,0.372,0.136,0.656,0.173,0.941
			c0.495,3.047,0.991,6.094,1.486,9.141c0.52,3.196,1.053,6.379,1.573,9.562c0.471,2.836,0.917,5.673,1.4,8.509
			c0.05,0.272,0.223,0.508,0.31,0.731c0.768,0.26,1.499,0.149,2.205,0.149c0.198,0,0.409-0.099,0.582-0.136
			c0.099-0.161,0.211-0.272,0.248-0.396c0.149-0.706,0.297-1.412,0.409-2.118c0.495-3.121,0.991-6.243,1.486-9.364
			c0.508-3.196,1.028-6.379,1.548-9.575c0.359-2.267,0.731-4.533,1.09-6.812c0.421-2.7,0.842-5.388,1.239-8.088
			C448.924,526.785,448.825,526.662,448.738,526.426z"
          />
        </g>
        <g id="Shape_1_copy_25">
          <path
            d="M403.516,526.414c-0.297-0.012-0.582-0.049-0.867-0.049c-4.954,0-9.921,0-14.876,0c-0.285,0-0.57,0.012-0.855,0.074
			c-0.124,0.025-0.223,0.161-0.396,0.31c0,0.372-0.012,0.805,0.012,1.226c0.012,0.124,0.111,0.248,0.173,0.372
			c0.297,0.273,0.669,0.211,1.016,0.211c1.722,0.012,3.456,0,5.177,0.012c0.272,0,0.582-0.037,0.892,0.285
			c0.037,0.099,0.149,0.285,0.161,0.483c0.025,0.718,0.025,1.437,0.025,2.155c0,9.711,0,19.409,0,29.12c0,0.644,0,1.288,0.025,1.945
			c0.012,0.198,0.099,0.409,0.198,0.793c0.557,0.025,0.991,0.049,1.412,0.049c0.297,0,0.557-0.099,0.756-0.409
			c0.173-0.731,0.087-1.523,0.087-2.316c0.012-9.773,0-19.558,0-29.33c0-0.644,0.012-1.288,0.025-1.945
			c0.012-0.297,0.087-0.557,0.483-0.805c0.446-0.012,1.016-0.037,1.585-0.049c1.511-0.012,3.022-0.012,4.533-0.012
			c0.297,0,0.557-0.087,0.768-0.347C403.851,527.591,404.086,526.934,403.516,526.414z"
          />
        </g>
      </g>
      <g id="year_1_">
        <g id="Shape_1_copy_23">
          <path
            d="M185.842,557.354c0-1.647-0.012-3.307,0-4.954c0.012-1.016-0.111-2.007-0.334-2.985c-0.434-1.846-1.387-3.282-3.097-4.187
			c-0.359-0.186-0.842-0.198-1.04-0.632c0.025-0.074,0.025-0.173,0.074-0.198c0.297-0.186,0.607-0.359,0.929-0.533
			c1.784-0.929,2.774-2.44,3.171-4.372c0.186-0.917,0.322-1.833,0.297-2.775c-0.012-1.003-0.025-2.007,0-3.022
			c0.025-1.09-0.149-2.155-0.396-3.196c-0.545-2.316-2.044-4.484-5.524-5.004c-0.78-0.111-1.573-0.136-2.366-0.149
			c-1.87-0.025-3.741-0.012-5.611,0c-0.434,0-0.855,0.037-1.251,0.062c-0.136,0.124-0.248,0.223-0.372,0.334
			c-0.037,0.186-0.099,0.396-0.099,0.607c-0.012,11.569-0.012,23.137-0.012,34.718c0,0.285,0.05,0.57,0.074,0.83
			c0.136,0.124,0.235,0.211,0.396,0.359c0.372,0,0.805,0.012,1.239,0c0.396-0.012,0.619-0.285,0.644-0.706
			c0.012-0.434,0.025-0.867,0.025-1.288c0-3.951,0-7.902,0-11.854c0-0.644,0-1.288,0.05-1.932c0.012-0.186,0.161-0.372,0.26-0.582
			c1.746-0.136,3.468-0.124,5.177,0c0.632,0.037,1.276,0.211,1.883,0.421c1.524,0.52,2.552,1.536,3.047,3.097
			c0.223,0.694,0.334,1.4,0.347,2.118c0.012,1.152,0.012,2.304,0.012,3.443c0,1.288,0.012,2.589,0.012,3.877
			c0,0.941,0.198,1.858,0.483,2.75c0.136,0.421,0.483,0.669,0.979,0.694c0.285,0.012,0.57,0.012,0.867,0
			c0.285-0.025,0.557-0.062,0.681-0.57C185.792,560.377,185.842,558.866,185.842,557.354z M179.636,543.135
			c-0.83,0.198-1.697,0.322-2.552,0.359c-1.214,0.062-2.44,0.012-3.654,0.012c-0.297,0-0.557-0.087-0.805-0.409
			c-0.025-0.272-0.062-0.632-0.074-0.978c-0.099-6.007-0.05-12.287,0.099-14.157c0.161-0.285,0.421-0.396,0.718-0.396
			c1.722,0,3.443-0.012,5.153,0.012c0.495,0.012,1.003,0.099,1.474,0.235c1.4,0.421,2.329,1.362,2.836,2.725
			c0.297,0.805,0.495,1.647,0.495,2.514c0,1.647,0.012,3.295,0,4.942c0,0.644-0.111,1.276-0.285,1.907
			C182.522,541.661,181.37,542.726,179.636,543.135z"
          />
        </g>
        <g id="Shape_1_copy_21">
          <path
            d="M146.169,561.046c-2.601-11.693-5.215-23.385-7.828-35.078c-0.025-0.124-0.136-0.248-0.235-0.409
			c-0.149-0.062-0.347-0.198-0.545-0.198c-0.718-0.025-1.437-0.025-2.155-0.012c-0.297,0-0.557,0.124-0.78,0.396
			c-0.136,0.508-0.297,1.053-0.421,1.61c-0.446,2.031-0.892,4.063-1.338,6.094c-1.548,7.085-3.097,14.157-4.645,21.242
			c-0.458,2.106-0.929,4.199-1.375,6.305c-0.074,0.334-0.186,0.694,0.136,1.164c0.508,0.087,1.115,0.223,1.685-0.074
			c0.111-0.186,0.211-0.297,0.235-0.433c0.149-0.557,0.297-1.115,0.421-1.672c0.334-1.474,0.644-2.948,0.979-4.409
			c0.111-0.471,0.161-1.003,0.57-1.338c0.334-0.211,0.706-0.173,1.065-0.173c3.295,0.062,6.602-0.136,9.897,0.111
			c0.062,0,0.124,0.074,0.248,0.161c0.347,0.57,0.434,1.288,0.595,1.969c0.359,1.536,0.669,3.084,1.028,4.62
			c0.099,0.409,0.173,0.842,0.508,1.152c1.375,0.248,1.375,0.248,2.031-0.186C146.219,561.615,146.231,561.33,146.169,561.046z
			 M141.289,551.867c-0.198,0.062-0.421,0.099-0.632,0.099c-2.799,0-5.611,0-8.41,0c-0.359,0-0.718,0-1.053-0.384
			c0.025-0.211,0.037-0.483,0.099-0.756c1.14-5.115,2.279-10.231,3.419-15.346c0.409-1.821,0.805-3.641,1.214-5.462
			c0.074-0.347,0.173-0.706,0.619-0.867c0.161,0.619,0.359,1.226,0.495,1.858c1.511,6.874,3.171,13.711,4.632,20.549
			C141.499,551.682,141.413,551.818,141.289,551.867z"
          />
        </g>
        <g id="Shape_1_copy_20">
          <path
            d="M101.876,560.055c-2.799,0-5.599,0-8.41-0.012c-0.644,0-1.288,0-1.932-0.037c-0.198-0.012-0.384-0.149-0.607-0.235
			c-0.037-0.297-0.087-0.57-0.099-0.855c0-4.385,0-8.769,0-13.142c0-0.211,0-0.446,0.087-0.632c0.087-0.173,0.26-0.396,0.421-0.433
			c0.421-0.087,0.855-0.087,1.288-0.087c2.155-0.012,4.31,0,6.466-0.012c0.434,0,0.867-0.012,1.276-0.099
			c0.161-0.037,0.297-0.26,0.434-0.409c0.037-0.05,0.037-0.136,0.037-0.211c0.012-0.211,0.025-0.434,0.012-0.644
			c-0.025-0.545-0.26-0.83-0.768-0.83c-2.081-0.012-4.162-0.012-6.255-0.025c-0.793,0-1.585-0.012-2.366-0.049
			c-0.186-0.012-0.372-0.173-0.533-0.248c-0.161-1.796-0.223-8.039-0.111-13.179c0.012-0.347,0.05-0.706,0.074-0.991
			c0.186-0.136,0.285-0.285,0.409-0.297c0.495-0.049,1.003-0.074,1.499-0.074c2.589-0.012,5.177,0,7.754,0
			c0.57,0,1.152,0,1.722-0.05c0.198-0.012,0.372-0.173,0.607-0.285c0-0.508,0-0.991,0-1.511c-0.198-0.124-0.372-0.273-0.557-0.322
			c-0.272-0.062-0.57-0.062-0.855-0.062c-3.877,0-7.754,0-11.643,0c-0.285,0-0.57,0.025-0.855,0.05
			c-0.26,0.025-0.57,0.297-0.582,0.545c-0.025,0.644-0.05,1.288-0.05,1.932c0,10.627,0,21.267,0,31.894c0,0.57,0,1.152,0.025,1.722
			c0.012,0.198,0.124,0.396,0.198,0.607c0.421,0.186,0.855,0.173,1.276,0.173c3.877,0,7.754,0,11.643,0
			c0.359,0,0.718-0.049,1.065-0.111c0.124-0.025,0.211-0.173,0.347-0.297c0-0.471,0-0.966,0-1.338
			C102.607,560.042,102.235,560.055,101.876,560.055z"
          />
        </g>
        <g id="Shape_1_copy_19">
          <path
            d="M64.197,525.423c-0.421-0.049-0.855-0.062-1.288-0.049c-0.31,0-0.57,0.186-0.681,0.446
			c-0.235,0.52-0.421,1.065-0.619,1.61c-1.87,5.128-3.728,10.256-5.599,15.384c-0.173,0.471-0.31,0.954-0.681,1.4
			c-0.26-0.248-0.421-0.545-0.533-0.867c-0.756-2.093-1.511-4.187-2.279-6.28c-1.301-3.58-2.601-7.147-3.902-10.726
			c-0.124-0.334-0.248-0.681-0.718-0.941c-0.57-0.062-1.263-0.235-1.945,0.173c0.012,0.57,0.211,1.016,0.384,1.486
			c2.291,6.28,4.57,12.547,6.85,18.827c0.756,2.081,0.756,2.068,0.768,4.187c0,3.233,0,6.466,0,9.698c0,0.57,0,1.152,0.037,1.722
			c0.012,0.198,0.136,0.396,0.198,0.57c0.656,0.248,1.288,0.223,1.895,0.049c0.334-0.285,0.322-0.656,0.322-1.016
			c0-3.741,0.037-7.469-0.012-11.209c-0.012-1.251,0.211-2.403,0.644-3.567c2.329-6.255,4.608-12.535,6.911-18.802
			c0.223-0.607,0.409-1.214,0.607-1.796C64.408,525.596,64.309,525.435,64.197,525.423z"
          />
        </g>
      </g>
      <g id="star_1_">
        <g id="Shape_1_copy_18">
          <path
            d="M342.849,530.254c-0.644-0.037-1.288-0.05-1.945-0.05c-3.171,0-6.329,0.012-9.5,0.012c-1.437,0-2.886,0.012-4.31-0.099
			c-2.229-0.173-4.459-0.161-6.689-0.161c-1.796,0-3.592,0-5.4,0c-0.434,0-0.867,0-1.288-0.025c-0.842-0.05-1.511-0.409-1.945-1.164
			c-0.285-0.495-0.545-1.003-0.718-1.561c-0.384-1.313-0.818-2.601-1.251-3.902c-1.325-3.951-2.663-7.902-3.964-11.854
			c-0.929-2.799-1.92-5.574-2.935-8.348c-0.198-0.545-0.434-1.053-0.867-1.462c-0.421-0.396-1.201-0.359-1.511,0.161
			c-0.26,0.434-0.495,0.879-0.656,1.35c-1.14,3.258-2.267,6.515-3.369,9.785c-0.917,2.725-1.784,5.462-2.688,8.187
			c-0.656,1.982-1.325,3.951-1.994,5.921c-0.136,0.409-0.334,0.793-0.52,1.189c-0.458,1.003-1.226,1.573-2.341,1.647
			c-0.57,0.037-1.152,0.062-1.722,0.062c-2.663,0.012-5.326,0-7.989,0c-0.793,0-1.585-0.025-2.378,0.049
			c-2.366,0.223-4.744,0.211-7.11,0.211c-2.44,0-4.893-0.012-7.333-0.012c-0.508,0-1.003-0.012-1.511,0.012
			c-0.285,0.012-0.57,0.099-0.842,0.161c-0.595,0.149-0.855,0.842-0.483,1.375c0.211,0.285,0.446,0.557,0.731,0.78
			c2.329,1.796,4.657,3.617,7.011,5.376c4.385,3.258,8.794,6.478,13.179,9.736c0.632,0.471,1.214,1.016,1.808,1.523
			c1.04,0.879,1.313,2.019,1.102,3.307c-0.074,0.421-0.186,0.842-0.31,1.251c-1.4,4.459-2.799,8.918-4.199,13.377
			c-1.102,3.493-2.217,6.998-3.319,10.491c-0.111,0.347-0.211,0.694-0.272,1.04c-0.037,0.211-0.025,0.434,0,0.644
			c0.037,0.582,0.706,1.09,1.226,0.904c0.471-0.173,0.929-0.384,1.35-0.644c2.279-1.387,4.348-3.059,6.527-4.583
			c4.013-2.787,7.94-5.698,11.928-8.534c0.991-0.706,2.056-1.325,3.072-1.994c0.557-0.372,1.164-0.52,1.833-0.533
			c0.817-0.012,1.573,0.161,2.254,0.619c1.375,0.917,2.762,1.821,4.112,2.774c3.171,2.242,6.329,4.496,9.488,6.75
			c1.053,0.756,2.106,1.486,3.146,2.254c1.796,1.325,3.604,2.614,5.648,3.629c0.384-0.074,0.805,0.173,1.239-0.012
			c0.272-0.297,0.235-0.681,0.173-1.016c-0.173-0.842-0.359-1.685-0.607-2.514c-0.793-2.614-1.61-5.227-2.44-7.828
			c-0.892-2.812-1.833-5.599-2.725-8.41c-0.731-2.329-1.424-4.67-2.13-7.011c-0.025-0.074-0.037-0.136-0.062-0.211
			c-0.384-1.499-0.223-2.861,1.078-3.902c0.285-0.223,0.533-0.471,0.83-0.694c1.375-1.04,2.762-2.068,4.149-3.096
			c2.13-1.598,4.248-3.196,6.391-4.769c2.428-1.796,4.88-3.555,7.308-5.351c1.152-0.855,2.291-1.734,3.431-2.613
			c0.396-0.31,0.718-0.694,0.929-1.164C343.716,530.799,343.443,530.278,342.849,530.254z"
          />
        </g>
        <g id="Shape_1_copy_16">
          <path
            d="M585.494,591.429c-0.694-0.173-1.412-0.285-2.13-0.334c-1.003-0.062-2.007-0.062-3.022-0.062
			c-18.629,0-37.258,0.012-55.886,0.012c-29.64,0-59.268-0.012-88.908-0.012c-20.499,0-40.998,0-61.497,0c-1.437,0-2.874,0-4.31,0
			c-0.434,0-0.867-0.025-1.288-0.062c-0.533-0.05-1.003-0.731-0.917-1.276c0.037-0.211,0.062-0.433,0.161-0.619
			c0.334-0.632,0.681-1.276,1.053-1.883c2.316-3.815,4.422-7.729,6.131-11.866c1.957-4.732,3.542-9.575,4.447-14.628
			c0.124-0.706,0.248-1.412,0.409-2.118c0.533-2.329,0.756-4.682,0.855-7.06c0.05-1.152,0.173-2.291,0.31-3.431
			c0.235-2.081,0.235-4.162-0.05-6.243c-0.111-0.855-0.223-1.709-0.248-2.576c-0.062-2.663-0.396-5.289-0.842-7.927
			c-1.251-7.432-3.604-14.492-6.887-21.267c-0.941-1.945-1.907-3.864-3.047-5.71c-0.26-0.421-0.495-0.879-0.694-1.338
			c-0.421-0.991-0.087-1.647,1.016-1.92c0.409-0.111,0.842-0.173,1.276-0.198c0.57-0.037,1.152-0.012,1.722-0.012
			c20.289,0,40.565,0,60.853,0c25.899,0,51.787-0.012,77.686-0.012c22.729,0,45.457,0.012,68.186,0.025
			c1.078,0,2.155,0.012,3.233-0.025c0.793-0.037,1.585-0.087,2.353-0.235c1.387-0.272,2.527-1.016,3.381-2.143
			c1.523-1.994,1.722-4.211,0.979-6.54c-0.384-1.201-1.14-2.13-2.192-2.849c-0.979-0.669-2.019-1.078-3.183-1.226
			c-1.499-0.198-3.01-0.223-4.521-0.223c-19.706,0-39.413-0.012-59.132,0c-39.921,0.025-79.841-0.074-119.762,0.087
			c-5.177,0.025-10.355-0.074-15.532-0.074c-7.481,0-14.962,0.05-22.444,0.062c-1.362,0-2.737-0.074-4.1-0.05
			c-1.821,0.025-3.369-0.644-4.732-1.796c-0.223-0.186-0.434-0.372-0.644-0.57c-1.697-1.561-3.542-2.948-5.413-4.273
			c-4.645-3.295-9.55-6.144-14.789-8.398c-3.307-1.424-6.689-2.638-10.181-3.542c-3.691-0.954-7.419-1.734-11.222-2.155
			c-1.784-0.198-3.58-0.471-5.363-0.52c-4.459-0.111-8.906-0.223-13.352,0.347c-4.434,0.57-8.807,1.387-13.117,2.576
			c-5.921,1.647-11.507,4.087-16.808,7.196c-1.672,0.979-3.307,2.031-4.967,3.047c-1.846,1.127-3.53,2.477-5.19,3.852
			c-1.003,0.818-1.969,1.672-2.96,2.514c-1.313,1.102-2.799,1.746-4.546,1.697c-0.644-0.012-1.301,0-1.945,0
			c-29.417,0-58.834,0-88.264,0c-42.732,0-85.452,0-128.184,0c-1.301,0-2.589-0.012-3.889,0.012
			c-0.929,0.025-1.87,0.124-2.799,0.186c-0.793,0.062-1.561,0.285-2.291,0.595c-1.523,0.632-2.638,1.697-3.307,3.233
			c-0.756,1.746-0.718,3.493-0.025,5.252c0.768,1.932,2.13,3.233,4.187,3.691c0.694,0.161,1.424,0.223,2.13,0.272
			c0.57,0.05,1.152,0.012,1.722,0.012c37.047,0,74.094,0,111.129,0c32.724,0,65.461,0,98.185,0c0.644,0,1.301,0,1.945,0.012
			c0.211,0,0.434,0.05,0.632,0.099c0.632,0.149,1.078,0.929,0.83,1.523c-0.223,0.533-0.458,1.065-0.768,1.548
			c-1.313,2.068-2.391,4.261-3.468,6.466c-1.573,3.233-2.936,6.565-4.038,9.996c-1.362,4.248-2.415,8.584-3.01,13.018
			c-0.26,1.92-0.632,3.827-0.681,5.784c-0.124,5.252-0.248,10.491,0.52,15.718c0.471,3.208,0.991,6.391,1.796,9.537
			c2.007,7.94,5.264,15.359,9.599,22.295c0.421,0.669,0.842,1.338,1.226,2.031c0.31,0.545,0.012,1.362-0.557,1.685
			c-0.384,0.223-0.793,0.359-1.226,0.359c-0.793,0.012-1.585,0.025-2.378,0.025c-16.251,0-32.514,0.012-48.764,0
			c-15.396-0.012-30.792-0.049-46.176-0.037c-9.141,0.012-18.27,0.136-27.411,0.136c-14.38,0-28.773-0.05-43.153-0.087
			c-14.095-0.025-28.191-0.062-42.299-0.087c-1.288,0-2.589,0.062-3.877,0.099c-0.867,0.025-1.709,0.248-2.539,0.495
			c-0.991,0.297-1.808,0.842-2.539,1.585c-0.793,0.793-1.177,1.784-1.511,2.824c-0.334,1.065-0.347,2.118,0.012,3.183
			c0.111,0.334,0.235,0.681,0.372,1.016c0.817,1.92,2.23,3.121,4.298,3.542c1.14,0.223,2.279,0.273,3.431,0.273
			c5.103,0,10.219,0,15.322,0c69.771,0,139.542,0,209.326,0c0.434,0,0.867,0.012,1.301,0c0.743-0.037,1.424,0.173,2.044,0.582
			c0.718,0.483,1.424,0.966,2.106,1.499c3.072,2.378,6.119,4.781,9.475,6.75c3.171,1.846,6.391,3.592,9.797,4.967
			c2.601,1.053,5.289,1.858,7.964,2.7c1.102,0.347,2.217,0.619,3.32,0.941c2.007,0.607,4.075,0.892,6.131,1.251
			c1.065,0.186,2.143,0.26,3.208,0.372c0.78,0.087,1.585,0.099,2.366,0.223c4.149,0.644,8.311,0.718,12.473,0.26
			c1.932-0.211,3.852-0.495,5.797-0.582c0.359-0.012,0.718-0.087,1.065-0.149c3.555-0.557,7.073-1.288,10.503-2.378
			c0.817-0.26,1.635-0.545,2.465-0.768c1.734-0.483,3.431-1.065,5.091-1.746c2.192-0.917,4.335-1.92,6.428-3.047
			c2.923-1.561,5.772-3.233,8.435-5.202c1.734-1.288,3.431-2.601,5.153-3.902c0.458-0.347,0.929-0.681,1.412-0.991
			c0.867-0.545,1.808-0.867,2.861-0.78c0.495,0.037,1.003,0.012,1.511,0.012c37.183,0,74.379,0,111.562,0
			c36.539,0,73.078,0,109.617,0c1.152,0,2.304,0.025,3.456-0.037c0.929-0.05,1.87-0.124,2.774-0.322
			c1.784-0.396,3.121-1.424,3.902-3.109c0.372-0.793,0.594-1.623,0.656-2.49C590.436,594.798,588.603,592.221,585.494,591.429z
			 M360.772,575.166c-1.313,2.403-2.663,4.769-4.211,7.035c-1.214,1.784-2.527,3.493-3.939,5.128
			c-2.44,2.849-5.054,5.499-7.902,7.94c-2.96,2.539-6.156,4.719-9.55,6.627c-0.941,0.533-1.87,1.078-2.824,1.573
			c-2.874,1.486-5.809,2.849-8.93,3.765c-1.796,0.52-3.567,1.127-5.363,1.623c-1.945,0.533-3.902,0.954-5.908,1.226
			c-2.923,0.409-5.871,0.495-8.807,0.731c-1.932,0.161-3.877,0.149-5.809-0.136c-1.499-0.211-2.997-0.359-4.496-0.483
			c-2.019-0.173-3.976-0.632-5.933-1.09c-2.514-0.594-5.004-1.325-7.432-2.229c-5.004-1.87-9.723-4.323-14.145-7.308
			c-2.44-1.647-4.781-3.443-6.961-5.438c-0.904-0.83-1.821-1.623-2.725-2.452c-1.635-1.523-3.059-3.245-4.459-4.979
			c-3.245-4.05-6.044-8.398-8.373-13.043c-1.672-3.357-2.973-6.862-4.013-10.466c-1.164-4.013-1.895-8.113-2.279-12.275
			c-0.211-2.291-0.272-4.595-0.334-6.887c-0.099-3.902,0.421-7.741,1.14-11.569c0.78-4.199,2.081-8.224,3.679-12.163
			c1.115-2.737,2.452-5.363,3.951-7.902c2.329-3.988,5.041-7.692,8.076-11.172c0.991-1.14,2.056-2.217,3.158-3.245
			c3.22-2.985,6.589-5.797,10.305-8.15c4.57-2.886,9.364-5.351,14.541-6.986c1.647-0.52,3.282-1.065,4.942-1.523
			c2.502-0.681,5.041-1.201,7.605-1.523c1.784-0.223,3.58-0.384,5.376-0.458c1.647-0.074,3.307-0.012,4.954-0.012
			c0,0.025,0,0.037,0,0.062c1.796,0.099,3.592,0.161,5.388,0.297c0.929,0.062,1.858,0.198,2.774,0.347
			c2.415,0.384,4.806,0.917,7.147,1.598c0.892,0.26,1.808,0.458,2.7,0.743c4.682,1.486,9.141,3.468,13.365,5.97
			c2.973,1.759,5.772,3.753,8.472,5.921c3.035,2.44,5.784,5.19,8.361,8.088c2.675,3.01,5.054,6.267,7.023,9.797
			c1.573,2.824,3.022,5.722,4.211,8.732c1.189,3.01,2.118,6.106,2.874,9.253c0.694,2.874,1.065,5.797,1.486,8.707
			c0.136,0.917,0.173,1.858,0.173,2.799c0.012,2.589,0,5.177-0.012,7.766c0,1.449-0.297,2.861-0.471,4.286
			c-0.458,3.654-1.276,7.221-2.366,10.726C364.116,568.155,362.642,571.735,360.772,575.166z"
          />
        </g>
      </g>
      <g id="seniors_1_">
        <g id="Shape_1_copy_15">
          <path
            d="M588.838,399.679c0-2.229,0-4.459,0-6.689c0-1.078-0.087-2.155-0.26-3.22c-0.111-0.656-0.334-1.263-0.743-1.771
			c-0.495-0.619-1.003-1.226-1.548-1.784c-4.248-4.286-8.522-8.571-12.783-12.844c-0.409-0.409-0.842-0.78-1.263-1.177
			c-1.164-1.065-2.564-1.561-4.125-1.647c-0.793-0.037-1.585-0.05-2.378-0.05c-7.333-0.012-14.678-0.012-22.01-0.025
			c-0.57,0-1.152,0-1.722-0.025c-0.211-0.012-0.421-0.062-0.632-0.124c-0.297-0.087-0.508-0.31-0.557-0.607
			c-0.062-0.421-0.074-0.855-0.074-1.288c0-9.711,0-19.422,0-29.132c0-0.434-0.012-0.867,0.025-1.288
			c0.025-0.285,0.099-0.57,0.186-0.83c0.149-0.446,0.471-0.756,0.929-0.805c0.718-0.074,1.437-0.099,2.155-0.099
			c6.181-0.012,12.374,0,18.554,0c0.508,0,1.003,0,1.511,0.025c0.285,0.012,0.57,0.074,0.842,0.136
			c0.434,0.111,0.78,0.384,0.966,0.793c0.149,0.322,0.297,0.669,0.334,1.016c0.062,0.57,0.05,1.152,0.05,1.722
			c0,3.456,0.012,6.911,0,10.355c0,1.152,0.074,2.291,0.446,3.332c0.285,0.359,0.607,0.508,0.979,0.545
			c0.434,0.037,0.867,0.037,1.288,0.037c5.326-0.012,10.64-0.025,15.966-0.037c0.57,0,1.152-0.037,1.722-0.074
			c0.285-0.025,0.57-0.087,0.842-0.173c0.359-0.111,0.619-0.359,0.768-0.706c0.111-0.26,0.223-0.545,0.26-0.817
			c0.111-0.855,0.248-1.709,0.248-2.576c0.025-4.818,0.025-9.636,0-14.455c0-0.855-0.124-1.722-0.186-2.576
			c-0.074-0.966-0.483-1.796-1.053-2.564c-0.26-0.347-0.545-0.669-0.842-0.979c-2.056-2.106-4.137-4.199-6.193-6.317
			c-1.709-1.746-3.481-3.431-5.091-5.277c-0.941-1.078-1.982-2.081-3.01-3.097c-0.966-0.941-2.13-1.462-3.48-1.499
			c-0.867-0.025-1.722-0.05-2.589-0.05c-8.769,0-17.551,0-26.321,0c-0.57,0-1.152,0-1.722,0.025
			c-1.424,0.05-2.651,0.582-3.679,1.585c-0.619,0.607-1.239,1.201-1.846,1.821c-3.332,3.381-6.676,6.75-9.996,10.144
			c-1.053,1.078-2.044,2.23-3.072,3.332c-0.706,0.756-1.102,1.66-1.362,2.663c-0.198,0.78-0.161,1.573-0.161,2.366
			c0,12.374,0,24.748,0,37.121c0,0.508-0.012,1.003,0.012,1.511c0.037,0.879,0.31,1.697,0.855,2.403
			c0.57,0.731,1.164,1.462,1.808,2.143c1.338,1.412,2.713,2.774,4.075,4.162c2.118,2.155,4.236,4.31,6.354,6.453
			c1.016,1.016,2.044,2.019,3.047,3.047c1.065,1.09,2.391,1.573,3.865,1.685c0.718,0.05,1.437,0.037,2.155,0.037
			c7.122,0,14.244,0,21.366,0c0.929,0,1.87,0.025,2.799,0.087c0.421,0.025,0.83,0.161,1.288,0.26c0.099,0.31,0.248,0.57,0.26,0.842
			c0.074,1.288,0.173,2.589,0.173,3.877c0.012,8.484,0.012,16.981,0.012,25.466c0,0.434,0.037,0.867-0.012,1.288
			c-0.074,0.57-0.173,1.14-0.334,1.685c-0.074,0.26-0.297,0.483-0.495,0.681c-0.433,0.421-0.991,0.557-1.585,0.557
			c-2.378,0-4.744,0-7.122,0c-4.818,0-9.636,0-14.455,0c-1.301,0-1.573-0.26-1.548-1.462c0.012-0.434,0-0.867,0-1.301
			c0-3.951,0-7.915,0-11.866c0-0.57,0.025-1.164-0.074-1.722c-0.136-0.78-0.607-1.325-1.437-1.362
			c-0.285-0.012-0.57-0.025-0.867-0.025c-5.76,0-11.507,0-17.266,0c-0.57,0-1.152,0.05-1.722,0.111
			c-0.656,0.074-1.065,0.495-1.115,1.115c-0.037,0.434-0.05,0.855-0.05,1.288c0,5.537,0,11.073,0,16.61
			c0,0.57,0.025,1.152,0.074,1.722c0.087,0.954,0.396,1.833,0.991,2.589c0.632,0.78,1.276,1.561,1.957,2.304
			c0.78,0.842,1.61,1.635,2.428,2.452c2.824,2.861,5.735,5.648,8.447,8.633c0.818,0.904,1.759,1.697,2.638,2.552
			c0.979,0.929,2.155,1.412,3.505,1.474c1.078,0.05,2.155,0.087,3.233,0.087c5.896,0.012,11.792,0,17.7,0
			c2.304,0,4.608,0.025,6.899-0.012c1.226-0.025,2.44-0.124,3.654-0.235c1.028-0.099,1.932-0.533,2.675-1.276
			c0.458-0.458,0.929-0.892,1.387-1.35c1.92-1.945,3.79-3.939,5.76-5.834c2.638-2.552,5.066-5.301,7.605-7.94
			c0.198-0.211,0.384-0.421,0.57-0.656c0.545-0.694,0.917-1.462,0.954-2.353c0.05-1.078,0.149-2.155,0.149-3.233
			C588.85,418.952,588.838,409.315,588.838,399.679z"
          />
        </g>
        <g id="Shape_1_copy_13">
          <path
            d="M504.946,430.954c0-0.508,0-1.003-0.012-1.511c-0.025-0.867-0.31-1.152-1.164-1.177c-0.644-0.025-1.301-0.012-1.945-0.012
			c-1.437,0-2.874,0-4.31-0.012c-0.359,0-0.718-0.025-1.102-0.334c-0.087-0.161-0.248-0.409-0.359-0.669
			c-0.805-1.759-1.623-3.53-2.403-5.301c-1.994-4.546-3.964-9.091-5.945-13.637c-0.458-1.053-0.929-2.106-1.35-3.171
			c-0.756-1.895-0.681-2.502,1.003-3.642c1.201-0.805,2.279-1.746,3.282-2.787c0.743-0.78,1.523-1.523,2.242-2.329
			c1.957-2.217,4.087-4.248,6.168-6.342c1.065-1.065,2.155-2.118,3.208-3.196c0.595-0.619,1.164-1.276,1.697-1.945
			c0.545-0.694,0.867-1.486,0.892-2.391c0.037-0.867,0.087-1.722,0.087-2.589c0.012-14.962,0.012-29.925,0.012-44.887
			c0-0.718-0.05-1.437-0.074-2.155c-0.037-1.288-0.545-2.366-1.424-3.282c-1.04-1.09-2.093-2.168-3.146-3.258
			c-1.647-1.709-3.381-3.32-4.992-5.066c-1.945-2.118-4.025-4.112-6.044-6.156c-0.198-0.198-0.409-0.396-0.632-0.582
			c-0.954-0.817-2.044-1.35-3.319-1.387c-1.003-0.037-2.019-0.074-3.022-0.074c-9.921,0-19.855,0-29.776,0
			c-7.407,0-14.814-0.012-22.221,0c-0.867,0-1.722,0.037-2.589,0.124c-0.929,0.099-1.598,0.768-1.722,1.672
			c-0.087,0.644-0.111,1.288-0.111,1.932c-0.012,5.326-0.012,10.64,0,15.966c0,0.434,0,0.867,0.037,1.288
			c0.025,0.285,0.087,0.57,0.161,0.842c0.136,0.52,0.495,0.867,0.991,1.053c0.607,0.235,1.251,0.31,1.907,0.31
			c0.929,0,1.87-0.012,2.799,0.012c0.495,0.012,1.003,0.05,1.499,0.149c0.533,0.099,0.904,0.446,1.078,0.954
			c0.235,0.681,0.31,1.4,0.31,2.13c0,1.301,0,2.589,0,3.889c0,23.881,0.012,47.761,0.012,71.642c0,3.666,0,7.333,0,11.011
			c0,0.421,0.062,0.867-0.359,1.4c-0.78,0.483-1.796,0.768-2.898,0.818c-0.867,0.025-1.722,0-2.589,0.037
			c-0.57,0.025-1.14,0.087-1.709,0.173c-0.458,0.074-0.78,0.384-0.941,0.805c-0.124,0.334-0.211,0.681-0.26,1.04
			c-0.05,0.421-0.037,0.867-0.037,1.288c0,5.326-0.012,10.64,0,15.966c0,0.793,0.062,1.585,0.149,2.366
			c0.111,0.954,0.632,1.412,1.561,1.474c0.793,0.05,1.585,0.074,2.366,0.074c10.355,0,20.71,0,31.077,0
			c0.867,0,1.722-0.025,2.589-0.087c0.421-0.025,0.842-0.161,1.251-0.285c0.359-0.111,0.607-0.372,0.756-0.718
			c0.297-0.669,0.347-1.387,0.334-2.118c0-5.4,0-10.788-0.012-16.189c0-0.793-0.087-1.573-0.173-2.366
			c-0.05-0.434-0.211-0.842-0.644-1.127c-0.619-0.285-1.35-0.297-2.056-0.31c-1.003-0.025-2.019-0.037-3.022-0.037
			c-0.582,0-1.14-0.124-1.685-0.334c-0.731-0.297-1.177-0.83-1.325-1.585c-0.099-0.495-0.149-0.991-0.149-1.499
			c0-7.048,0-14.095,0.012-21.143c0-0.285,0.025-0.57,0.05-0.855c0.037-0.322,0.372-0.718,0.669-0.768
			c0.855-0.161,1.697-0.223,2.552,0.111c0.57,0.768,0.929,1.635,1.313,2.477c1.115,2.49,2.192,5.004,3.295,7.506
			c2.155,4.942,4.31,9.884,6.466,14.826c1.61,3.691,3.196,7.395,4.806,11.086c1.437,3.295,2.861,6.589,4.323,9.884
			c0.322,0.718,0.718,1.412,1.102,2.106c0.297,0.533,0.768,0.855,1.325,1.04c0.409,0.136,0.83,0.235,1.251,0.272
			c0.929,0.062,1.87,0.099,2.799,0.111c4.608,0.012,9.203,0,13.811,0c0.508,0,1.016,0.025,1.511-0.025
			c0.57-0.062,1.139-0.161,1.697-0.297c0.656-0.161,1.003-0.582,1.04-1.214c0.025-0.57,0.037-1.152,0.037-1.722
			C504.946,442.473,504.946,436.713,504.946,430.954z M482.478,378.04c-0.012,0.446-0.359,0.818-0.805,0.867
			c-0.644,0.062-1.288,0.111-1.932,0.111c-2.948,0.012-5.896,0-8.831,0c-3.307,0-6.614,0-9.909,0c-0.508,0-1.003,0-1.511,0
			c-1.536,0.012-1.883-0.297-2.205-1.759c-0.074-0.347-0.124-0.706-0.149-1.065c-0.025-0.434-0.012-0.867-0.012-1.288
			c0-12.002,0-23.992,0-35.994c0-0.508-0.012-1.003,0.037-1.511c0.025-0.272,0.161-0.533,0.248-0.817
			c0.31-0.087,0.582-0.211,0.855-0.223c0.495-0.037,1.003-0.037,1.511-0.037c6.676,0,13.365-0.012,20.041,0
			c0.644,0,1.288,0.037,1.932,0.074c0.334,0.025,0.694,0.384,0.706,0.706c0.037,0.718,0.062,1.437,0.062,2.155
			c0,12.287,0,24.574,0,36.861C482.503,376.752,482.503,377.396,482.478,378.04z"
          />
        </g>
        <g id="Shape_1_copy_11">
          <path
            d="M415.469,379.848c-0.012-2.081-0.025-4.174-0.025-6.255c0-0.644,0.025-1.301,0.012-1.945
			c-0.025-3.307-0.05-6.614-0.087-9.921c-0.012-1.078-0.062-2.155-0.124-3.233c-0.087-1.437-0.111-2.874,0.012-4.31
			c0.149-1.647,0.149-3.307,0-4.954c-0.124-1.362-0.124-2.725,0-4.087c0.05-0.495,0.099-1.003,0.099-1.511
			c0.012-3.381,0.012-6.763,0.037-10.144c0-0.979-0.272-1.846-0.879-2.601c-0.632-0.78-1.276-1.561-1.969-2.279
			c-4.273-4.372-8.571-8.72-12.857-13.08c-0.359-0.359-0.706-0.718-1.078-1.065c-0.867-0.805-1.895-1.301-3.097-1.35
			c-0.718-0.025-1.437-0.074-2.155-0.074c-8.992,0-17.985,0-26.977,0.012c-0.57,0-1.152,0.025-1.722,0.05
			c-1.276,0.05-2.366,0.57-3.32,1.387c-0.322,0.285-0.644,0.582-0.941,0.892c-1.573,1.585-3.134,3.171-4.694,4.756
			c-1.462,1.486-2.911,2.985-4.372,4.471c-1.499,1.548-2.985,3.121-4.509,4.632c-1.362,1.35-1.957,2.985-2.019,4.855
			c-0.025,0.793-0.025,1.585-0.025,2.378c0,9.203,0,18.418,0,27.621c0,21.292,0.012,42.584,0.025,63.875
			c0,0.718,0.012,1.437,0.012,2.155c-0.012,2.118,0.718,3.939,2.217,5.438c1.016,1.016,2.019,2.056,3.022,3.072
			c2.217,2.254,4.434,4.509,6.664,6.763c1.462,1.486,2.948,2.948,4.409,4.434c0.743,0.756,1.635,1.164,2.638,1.362
			c0.557,0.111,1.14,0.173,1.709,0.186c1.288,0.05,2.589,0.099,3.877,0.099c7.766,0.012,15.532,0,23.298,0
			c0.718,0,1.437-0.05,2.155-0.025c1.685,0.05,3.072-0.619,4.248-1.771c0.26-0.248,0.508-0.508,0.756-0.768
			c2.663-2.725,5.326-5.45,7.989-8.175c1.808-1.846,3.617-3.703,5.438-5.549c1.226-1.239,1.907-2.675,1.858-4.447
			c-0.099-3.307,0.322-6.614,0.099-9.921c-0.025-0.359-0.025-0.718,0.012-1.078c0.26-3.084,0.136-6.181,0.149-9.277
			c0-1.078-0.087-2.155-0.124-3.233c-0.025-0.929-0.111-1.87-0.037-2.799c0.223-2.589,0.186-5.177,0.186-7.766
			c0-4.744,0-9.488,0.012-14.244c0.025,0,0.037,0,0.062,0C415.469,381.583,415.481,380.715,415.469,379.848z M392.604,425.306
			c-0.012,0.359-0.05,0.718-0.124,1.065c-0.186,0.941-0.768,1.511-1.709,1.722c-0.78,0.186-1.573,0.161-2.366,0.161
			c-4.533,0-9.054,0-13.588,0c-1.288,0-2.589,0-3.877-0.012c-1.239-0.012-2.403-0.31-3.468-0.954
			c-0.074-0.235-0.186-0.434-0.211-0.644c-0.037-0.285-0.025-0.57-0.025-0.855c0-26.308,0-52.604,0-78.912c0-2.725,0-5.462,0-8.187
			c0-0.495,0.025-1.003,0.074-1.499c0.037-0.409,0.446-0.78,0.879-0.817c0.434-0.037,0.855-0.062,1.288-0.062
			c7.184,0,14.368,0,21.564,0.012c0.211,0,0.434,0.012,0.644,0.025c0.471,0.025,0.842,0.347,0.867,0.78
			c0.025,0.495,0.037,1.003,0.037,1.511c0,12.076,0,24.153,0,36.217c0,16.238,0,32.489,0,48.727
			C392.616,424.154,392.629,424.723,392.604,425.306z"
          />
        </g>
        <g id="Shape_1_copy_10">
          <path
            d="M333.695,429.579c-0.099-0.347-0.186-0.694-0.644-1.065c-0.855-0.211-1.709-0.223-2.576-0.235
			c-0.57-0.012-1.152,0.025-1.722-0.012s-1.152-0.099-1.697-0.235c-1.102-0.272-1.746-1.016-1.833-2.13
			c-0.05-0.793-0.037-1.585-0.025-2.366c0.037-2.081,0.124-4.162,0.136-6.255c0.025-2.514-0.012-5.029-0.012-7.556
			c-0.012-3.307,0.025-6.614-0.149-9.921c-0.05-0.855-0.05-1.722-0.012-2.589c0.223-4.31,0.136-8.621,0.173-12.944
			c0.012-1.647,0-3.307-0.074-4.954c-0.149-3.456-0.124-6.899-0.025-10.355c0.037-1.362,0.012-2.725,0-4.1
			c-0.012-0.867-0.087-1.722-0.062-2.589c0.111-3.666,0.111-7.333,0.074-10.999c-0.037-4.026,0.05-8.051,0.087-12.077
			c0-0.644,0-1.288,0.025-1.945c0.025-0.644,0.396-0.941,0.929-0.966c0.508-0.025,1.003-0.037,1.511-0.037
			c1.078,0,2.155,0.012,3.233-0.012c0.495-0.012,1.003-0.074,1.486-0.173c0.706-0.136,1.177-0.545,1.251-1.288
			c0.087-0.78,0.161-1.573,0.173-2.366c0.012-5.252,0.012-10.503,0-15.743c0-0.57-0.037-1.152-0.087-1.722
			c-0.025-0.285-0.087-0.57-0.149-0.842c-0.149-0.619-0.582-1.016-1.214-1.04c-0.644-0.025-1.288-0.037-1.945-0.037
			c-11.148,0-22.295,0-33.43,0c-0.57,0-1.152,0-1.722,0.012c-0.211,0.012-0.434,0.05-0.632,0.099
			c-0.372,0.111-0.656,0.347-0.718,0.731c-0.087,0.495-0.124,0.991-0.124,1.499c-0.012,6.044-0.012,12.077-0.012,18.121
			c0,0.434,0.012,0.867,0.05,1.288c0.05,0.557,0.396,0.904,0.867,1.152c0.458,0.235,0.954,0.31,1.462,0.322
			c0.867,0.012,1.722-0.012,2.589,0.012c0.718,0.025,1.437,0.074,2.143,0.149c0.817,0.087,1.263,0.582,1.499,1.35
			c0.161,0.557,0.285,1.115,0.285,1.697c0,0.718,0.012,1.437,0.012,2.155c0.012,27.398,0.025,54.784,0.037,82.182
			c0,0.57-0.012,1.152-0.05,1.722c-0.012,0.285-0.062,0.57-0.111,0.855c-0.099,0.52-0.384,0.917-0.842,1.189
			c-0.756,0.471-1.598,0.644-2.465,0.669c-0.793,0.025-1.585,0-2.378,0.012c-0.57,0.012-1.152,0.037-1.722,0.099
			c-0.471,0.05-0.818,0.322-0.954,0.768c-0.173,0.545-0.297,1.102-0.372,1.672c-0.062,0.495-0.037,1.003-0.037,1.511
			c0,5.462,0,10.925,0,16.399c0,0.434,0,0.867,0.05,1.288c0.062,0.545,0.384,0.929,0.892,1.127c0.334,0.124,0.681,0.248,1.04,0.272
			c0.855,0.062,1.722,0.099,2.589,0.099c10.429,0,20.846,0,31.275,0c0.867,0,1.722-0.037,2.589-0.087
			c0.731-0.037,1.251-0.458,1.362-1.152c0.124-0.78,0.235-1.573,0.235-2.353c0.012-5.326,0.012-10.64,0-15.966
			C333.931,431.14,333.918,430.347,333.695,429.579z"
          />
        </g>
        <g id="Shape_1_copy_9">
          <path
            d="M285.91,314.462c-0.025-1.127-0.285-1.387-1.412-1.412c-0.285-0.012-0.57,0-0.867,0c-11.792,0-23.596,0.012-35.387,0
			c-2.452,0-2.49,0.124-2.49,2.564c0.012,5.896,0,11.792,0,17.687c0,0.434,0,0.867,0.037,1.288c0.074,0.929,0.731,1.573,1.685,1.647
			c0.434,0.037,0.867,0.037,1.288,0.037c1.226,0,2.44,0,3.666,0c0.434,0,0.867,0,1.288,0.074c0.26,0.05,0.483,0.26,0.731,0.396
			c0.025,0.557,0.074,1.053,0.074,1.561c0.012,15.966,0.012,31.932,0.025,47.897c0,0.495-0.012,1.016-0.099,1.499
			c-0.05,0.26-0.248,0.508-0.434,0.718c-0.285,0.334-0.929,0.285-1.127-0.074c-0.347-0.632-0.669-1.263-0.954-1.932
			c-0.793-1.846-1.561-3.716-2.329-5.574c-2.415-5.846-4.831-11.693-7.234-17.539c-1.536-3.716-3.072-7.444-4.62-11.16
			c-1.709-4.112-3.456-8.224-5.165-12.337c-2.068-4.979-4.112-9.971-6.168-14.95c-1.177-2.861-2.353-5.722-3.555-8.559
			c-0.471-1.127-0.966-2.254-2.019-3.109c-0.917-0.223-1.709-0.149-2.502-0.149c-8.348-0.012-16.684,0-25.032,0
			c-0.359,0-0.718,0-1.078,0c-1.87,0.025-2.068,0.223-2.081,2.13c-0.012,6.255,0,12.51,0,18.777c0,0.434,0.025,0.867,0.062,1.288
			c0.037,0.396,0.434,0.83,0.83,0.892c0.347,0.062,0.706,0.124,1.065,0.124c1.226,0.012,2.44,0,3.666,0.012
			c0.495,0.012,1.003,0.037,1.499,0.124c0.793,0.136,1.239,0.681,1.449,1.424c0.074,0.272,0.099,0.57,0.099,0.855
			c0.012,0.867,0.012,1.722,0.012,2.589c0,20.35,0.012,40.713,0.012,61.064c0,7.122,0,14.244-0.012,21.366
			c0,0.929-0.062,1.87-0.124,2.799c-0.025,0.347-0.149,0.706-0.272,1.04c-0.136,0.347-0.421,0.582-0.805,0.644
			c-0.359,0.05-0.718,0.074-1.078,0.074c-1.362,0.012-2.737,0-4.1,0.012c-2.168,0.012-2.291,0.136-2.291,2.341
			c-0.012,5.462,0,10.937,0,16.399c0,0.718,0.037,1.437,0.074,2.155c0.025,0.359,0.099,0.718,0.173,1.065
			c0.111,0.458,0.421,0.768,0.855,0.879c0.557,0.136,1.127,0.26,1.697,0.285c1.226,0.062,2.44,0.099,3.666,0.099
			c9.996,0.012,19.991,0,29.987,0c0.508,0,1.003,0,1.511-0.012c0.211,0,0.434-0.037,0.644-0.074
			c0.768-0.136,1.412-0.867,1.462-1.647c0.025-0.359,0.025-0.718,0.025-1.078c0-6.181,0-12.374,0-18.554
			c0-0.359-0.012-0.718-0.037-1.078c-0.025-0.347-0.347-0.706-0.681-0.731c-0.644-0.037-1.288-0.062-1.932-0.062
			c-1.66-0.012-3.307-0.012-4.967-0.037c-0.483,0-0.842-0.347-0.855-0.793c-0.012-0.57-0.025-1.152-0.025-1.722
			c0-16.04,0-32.08,0-48.12c0-0.508-0.012-1.003,0.012-1.511c0.012-0.211,0.099-0.409,0.136-0.595
			c0.421-0.223,0.768-0.062,0.941,0.248c0.396,0.681,0.756,1.387,1.065,2.118c1.883,4.509,3.753,9.029,5.636,13.55
			c1.647,3.988,3.295,7.977,4.954,11.953c1.796,4.31,3.604,8.621,5.413,12.931c0.743,1.796,1.462,3.592,2.217,5.388
			c1.338,3.183,2.7,6.354,4.025,9.537c2.242,5.376,4.447,10.764,6.676,16.139c0.297,0.731,0.656,1.437,0.991,2.155
			c0.656,1.387,1.746,2.056,3.282,1.969c0.074,0,0.149,0,0.211,0c5.611,0,11.222,0,16.833,0c0.359,0,0.718-0.025,1.078-0.05
			c0.731-0.074,1.177-0.483,1.35-1.189c0.26-1.127,0.322-2.279,0.322-3.431c0-15.891,0-31.795,0.012-47.687
			c0-19.496,0.012-38.979,0.012-58.475c0-0.867,0.012-1.722,0.05-2.589c0.062-1.412,0.223-1.598,1.635-1.685
			c1.152-0.074,2.304-0.087,3.443-0.099c0.941,0,1.87-0.099,2.774-0.322c0.619-0.149,1.016-0.595,1.053-1.201
			c0.037-0.644,0.05-1.288,0.05-1.945c0-5.685,0-11.358,0-17.043C285.91,315.329,285.922,314.895,285.91,314.462z"
          />
        </g>
        <g id="Shape_1_copy_8">
          <path
            d="M179.797,405.438c0-0.57,0-1.152-0.025-1.722c-0.025-0.619-0.396-1.09-1.003-1.251c-0.557-0.149-1.127-0.248-1.685-0.322
			c-0.495-0.062-1.003-0.05-1.511-0.05c-5.029,0-10.07-0.012-15.099-0.012c-0.434,0-0.867,0-1.288,0.05
			c-0.359,0.037-0.706,0.124-1.053,0.223c-0.446,0.136-0.743,0.458-0.842,0.904c-0.124,0.557-0.223,1.127-0.272,1.697
			c-0.05,0.644-0.025,1.288-0.025,1.945c0.012,6.255,0.025,12.51,0.025,18.765c0,0.57,0,1.152-0.025,1.722
			c-0.012,0.434-0.396,0.793-0.842,0.818c-0.359,0.025-0.718,0.037-1.078,0.037c-7.258,0-14.529,0-21.787,0
			c-0.285,0-0.57-0.012-0.867-0.037c-0.359-0.025-0.669-0.347-0.669-0.718c0-0.867,0.025-1.722,0.025-2.589
			c0-11.507,0-23.013,0-34.52c0-0.793-0.025-1.585-0.012-2.378c0-0.471,0.334-0.867,0.768-0.892
			c0.644-0.037,1.288-0.062,1.945-0.062c5.462,0,10.937,0.012,16.399,0c0.793,0,1.585-0.025,2.366-0.074
			c1.226-0.074,1.945-0.83,2.068-2.081c0.062-0.718,0.099-1.437,0.099-2.155c0.012-3.022,0.012-6.044,0.012-9.067
			c0-2.155,0.012-4.31-0.012-6.478c-0.012-0.644-0.074-1.288-0.173-1.92c-0.124-0.731-0.619-1.152-1.325-1.189
			c-0.644-0.025-1.288-0.025-1.945-0.025c-5.821,0-11.655,0-17.477,0c-0.644,0-1.288-0.012-1.945-0.037
			c-0.359-0.012-0.706-0.334-0.731-0.669c-0.025-0.434-0.05-0.867-0.05-1.288c0-7.915,0-15.817,0-23.732
			c0-0.359,0-0.718,0.012-1.078c0.025-0.644,0.297-0.954,0.892-0.979c0.508-0.025,1.003-0.025,1.511-0.025
			c6.763,0,13.526,0,20.276,0c0.434,0,0.867,0,1.301,0.012c0.842,0.025,1.201,0.372,1.226,1.164
			c0.025,0.793,0.012,1.585,0.012,2.378c0,3.456-0.012,6.899,0,10.355c0,0.57,0.062,1.14,0.136,1.709
			c0.062,0.409,0.495,0.793,0.892,0.818c0.495,0.037,1.003,0.062,1.511,0.062c5.177,0,10.355,0,15.532,0
			c0.644,0,1.301-0.012,1.945-0.05c0.359-0.012,0.718-0.062,1.065-0.136c0.619-0.124,1.04-0.495,1.226-1.078
			c0.272-0.818,0.434-1.672,0.446-2.539c0.012-0.793,0.025-1.585,0.025-2.378c0-8.41,0-16.833,0-25.243c0-1.945,0-3.889,0-5.822
			c0-0.434-0.025-0.867-0.074-1.288c-0.074-0.545-0.632-1.09-1.164-1.115c-0.718-0.05-1.437-0.062-2.155-0.062
			c-12.151,0-24.314,0-36.465,0c-9.996,0-19.991,0-29.987,0c-2.514,0-5.029,0.012-7.556,0c-2.007-0.012-2.304,0.285-2.304,2.391
			c0.025,5.822,0,11.655,0.012,17.477c0,0.434-0.025,0.867,0.05,1.288c0.198,1.115,0.694,1.573,1.61,1.784
			c0.483,0.111,0.991,0.198,1.486,0.223c0.793,0.05,1.585,0.05,2.366,0.062c0.793,0.012,1.585,0,2.366,0.05
			c0.458,0.025,0.83,0.248,0.867,0.793c0.062,0.855,0.111,1.722,0.111,2.576c0.012,12.374,0.025,24.735,0.012,37.109
			c-0.012,16.61-0.037,33.232-0.037,49.842c0,1.35-0.248,1.623-1.623,1.61c-1.796-0.012-3.592-0.012-5.388,0
			c-1.647,0.012-1.846,0.211-1.846,1.895c-0.012,6.181,0,12.374,0,18.554c0,0.434,0.025,0.867,0.074,1.288
			c0.05,0.458,0.322,0.793,0.694,1.04c0.359,0.248,0.768,0.396,1.214,0.409c0.57,0.025,1.152,0.037,1.722,0.037
			c23.881,0,47.749,0,71.629-0.012c0.867,0,1.722-0.037,2.589-0.074c0.211-0.012,0.434-0.05,0.632-0.099
			c0.508-0.111,1.04-0.718,1.09-1.226c0.037-0.434,0.05-0.855,0.05-1.288c0-1.511,0-3.022,0-4.533
			C179.797,431.325,179.797,418.382,179.797,405.438z"
          />
        </g>
        <g id="Shape_1_copy_7">
          <path
            d="M88.487,387.825c-0.223-0.285-0.483-0.533-0.731-0.793c-2.415-2.465-4.893-4.868-7.258-7.382
			c-2.068-2.192-4.248-4.298-6.391-6.428c-0.409-0.409-0.83-0.793-1.263-1.177c-0.718-0.632-1.548-1.016-2.49-1.226
			c-1.065-0.235-2.13-0.272-3.22-0.272c-7.048,0.012-14.095,0.012-21.143,0.012c-0.867,0-1.722-0.012-2.589-0.025
			c-0.669,0-1.276-0.211-1.895-0.632c-0.372-0.743-0.248-1.4-0.26-2.031c-0.037-4.385,0.186-8.769,0.025-13.154
			c-0.012-0.434,0.025-0.867,0.025-1.288c0-1.722,0.05-3.456,0-5.177c-0.074-2.948,0.074-5.896,0.149-8.844
			c0.025-0.805,0.223-1.561,0.607-2.267c0.211-0.409,0.545-0.694,0.991-0.756c0.644-0.087,1.288-0.124,1.932-0.124
			c6.614-0.012,13.228-0.012,19.855,0c0.508,0,1.003,0.012,1.511,0.037c0.471,0.025,0.817,0.359,0.842,0.817
			c0.037,0.718,0.062,1.437,0.062,2.155c0.012,3.889,0,7.766,0.012,11.655c0,0.57,0.012,1.152,0.074,1.722
			c0.074,0.718,0.694,1.301,1.437,1.412c0.211,0.037,0.434,0.05,0.644,0.062c0.434,0.012,0.867,0.025,1.288,0.025
			c4.967,0.012,9.921,0.025,14.888,0.037c0.867,0,1.722,0,2.576-0.198c0.706-0.161,1.127-0.619,1.325-1.276
			c0.111-0.334,0.161-0.706,0.186-1.053c0.037-0.644,0.05-1.288,0.05-1.945c0-4.967,0-9.921,0-14.888c0-0.359,0.012-0.718,0-1.078
			c-0.05-1.412-0.495-2.675-1.499-3.703c-0.396-0.409-0.793-0.83-1.201-1.239c-4.57-4.57-9.153-9.153-13.612-13.835
			c-0.198-0.211-0.396-0.421-0.619-0.595c-0.867-0.669-1.808-1.177-2.936-1.214c-0.718-0.025-1.437-0.062-2.155-0.062
			c-8.918-0.025-17.836-0.037-26.754-0.05c-0.508,0-1.003,0-1.511,0.012c-1.66,0.037-3.072,0.595-4.248,1.796
			c-2.564,2.613-5.165,5.202-7.741,7.816c-1.263,1.276-2.49,2.589-3.741,3.877c-1.003,1.028-2.019,2.044-3.035,3.072
			c-1.065,1.065-1.585,2.391-1.722,3.864c-0.062,0.718-0.062,1.437-0.062,2.155c0,3.307,0,6.614,0.012,9.921
			c0.012,8.485,0.025,16.969,0.05,25.466c0,0.718-0.012,1.437,0.05,2.155c0.111,1.387,0.52,2.675,1.536,3.703
			c0.607,0.619,1.164,1.276,1.771,1.883c2.329,2.341,4.682,4.657,6.973,7.048c1.734,1.821,3.555,3.555,5.351,5.314
			c0.458,0.446,0.954,0.879,1.474,1.263c0.817,0.619,1.746,0.991,2.787,1.016c0.793,0.025,1.585,0.05,2.366,0.062
			c7.122,0.012,14.244,0.012,21.366,0.012c0.57,0,1.152-0.037,1.722,0.012c0.644,0.05,1.276,0.173,1.907,0.285
			c0.396,0.074,0.595,0.372,0.694,0.743c0.322,1.127,0.285,2.279,0.285,3.431c0.012,8.633,0,17.266,0,25.887
			c0,0.644-0.05,1.288-0.087,1.932c-0.05,0.582-0.272,1.115-0.582,1.61c-0.285,0.446-0.694,0.743-1.226,0.793
			c-0.57,0.062-1.152,0.062-1.722,0.062c-5.611,0.012-11.222,0.012-16.833,0.012c-0.508,0-1.003,0.025-1.511-0.012
			c-0.495-0.037-1.003-0.099-1.486-0.211c-1.14-0.285-1.846-1.102-1.969-2.291c-0.074-0.718-0.087-1.437-0.087-2.155
			c-0.012-3.381,0-6.763-0.012-10.144c0-0.57-0.025-1.152-0.099-1.722c-0.074-0.595-0.582-1.078-1.152-1.115
			c-0.644-0.037-1.288-0.05-1.945-0.05c-5.388,0-10.788,0-16.176,0c-0.434,0-0.867-0.012-1.288,0.012
			c-0.285,0.012-0.57,0.05-0.855,0.111c-0.545,0.111-1.065,0.656-1.102,1.189c-0.05,0.718-0.074,1.437-0.074,2.155
			c0,5.177,0.012,10.355,0.012,15.532c0,0.508-0.012,1.003,0.012,1.511c0.05,1.189,0.458,2.254,1.201,3.171
			c0.359,0.446,0.768,0.867,1.164,1.263c1.065,1.078,2.143,2.13,3.208,3.196c1.065,1.078,2.155,2.118,3.158,3.245
			c2.341,2.638,4.893,5.078,7.37,7.58c1.177,1.189,2.601,1.759,4.248,1.833c0.359,0.012,0.718,0.037,1.078,0.05
			c1.362,0.025,2.737,0.074,4.1,0.074c7.407,0.012,14.814,0,22.221,0.012c1.226,0,2.44-0.025,3.654-0.223
			c1.016-0.161,1.932-0.533,2.675-1.288c0.347-0.359,0.731-0.694,1.078-1.053c4.447-4.509,8.881-9.017,13.327-13.513
			c0.149-0.149,0.297-0.322,0.446-0.471c1.14-1.115,1.697-2.477,1.734-4.05c0.025-0.793,0.037-1.585,0.037-2.378
			c0-12.151,0-24.314,0-36.465c0-0.285-0.012-0.57,0-0.867C89.787,389.894,89.255,388.804,88.487,387.825z"
          />
        </g>
      </g>
      <g id="_x32_019_1_">
        <g id="Shape_1_copy_4">
          <path
            d="M440.328,136.138c-0.012-0.384-0.062-0.78-0.111-1.177c-0.867-0.842-1.734-1.635-2.589-2.465
			c-4.62-4.385-9.228-8.794-13.848-13.179c-0.52-0.495-0.978-1.065-1.672-1.412c-0.223-0.012-0.495-0.05-0.756-0.05
			c-23.385,0-46.795,0-70.18,0c-0.198,0-0.384,0.05-0.595,0.062c-0.161,0.111-0.334,0.211-0.471,0.347
			c-5.883,5.735-11.754,11.47-17.626,17.217c-0.136,0.136-0.235,0.31-0.409,0.52c-0.012,0.533-0.05,1.115-0.05,1.709
			c0,10.392,0,20.759,0,31.151c0,0.458,0.012,0.917,0.012,1.375c0,0.273,0.099,0.508,0.434,0.731
			c0.483,0.012,1.078,0.037,1.66,0.037c9.265,0,18.53,0,27.795,0c0.458,0,0.917-0.012,1.375-0.025
			c0.347-0.012,0.644-0.273,0.644-0.595c0.025-0.595,0.025-1.177,0.025-1.771c0-4.001,0-8.014,0-12.027c0-0.595,0-1.189,0.025-1.771
			c0.012-0.186,0.099-0.359,0.161-0.57c0.198-0.062,0.372-0.173,0.557-0.186c0.52-0.037,1.053-0.037,1.573-0.037
			c12.621,0,25.231,0,37.852,0c0.458,0,0.917,0,1.375,0.012c0.582,0.025,0.83,0.272,0.842,0.855
			c0.012,0.198,0.012,0.396,0.012,0.595c0,8.683,0,17.341,0,26.023c0,0.322-0.025,0.656-0.05,0.979
			c-0.012,0.186-0.334,0.533-0.52,0.533c-0.656,0.025-1.313,0.05-1.969,0.05c-15.904,0-31.808,0-47.711,0
			c-0.595,0-1.189-0.012-1.771,0c-0.384,0.012-0.78,0.062-1.09,0.074c-1.623,1.362-10.999,10.479-20.276,19.719
			c-0.322,0.322-0.619,0.669-0.917,0.979c-0.111,0.756-0.062,1.486-0.062,2.205c0,29.169,0,58.351,0,87.521
			c0,0.52,0,1.053,0.012,1.573c0.012,0.607,0.223,0.83,0.83,0.842c0.656,0.012,1.313,0.012,1.969,0.012c22.667,0,45.346,0,68,0
			c11.569,0,23.125,0,34.694,0c0.718,0,1.437,0.062,2.304-0.099c0.087-0.285,0.186-0.458,0.198-0.632
			c0.025-0.52,0.025-1.053,0.025-1.573c0-14.727,0-29.43,0-44.157c0-0.52-0.012-1.053-0.025-1.573
			c-0.012-0.272-0.111-0.508-0.446-0.694c-0.446-0.012-0.966-0.037-1.486-0.037c-9.203,0-18.393,0-27.596,0
			c-0.458,0-0.917,0-1.375,0.037c-0.186,0.012-0.334,0.161-0.483,0.223c-0.211,0.619-0.136,1.214-0.136,1.796
			c-0.012,4.137,0,8.286,0,12.423c0,0.595,0,1.189-0.025,1.771c-0.012,0.186-0.111,0.359-0.186,0.595
			c-0.582,0.173-1.164,0.111-1.746,0.111c-13.538,0-27.076,0-40.614,0c-2.118,0-1.982,0.087-1.982-2.081c0-14.195,0-28.389,0-42.584
			c0-0.458,0-0.917,0.05-1.375c0.012-0.186,0.149-0.347,0.211-0.508c0.743-0.223,1.474-0.124,2.192-0.124
			c10.912-0.012,21.812,0,32.724,0c7.159,0,14.331,0,21.49,0c0.718,0,1.449,0.062,2.217-0.087c0.31-0.297,0.656-0.595,0.966-0.917
			c1.09-1.14,2.168-2.291,3.258-3.431c3.988-4.187,7.977-8.373,11.965-12.56c0.458-0.471,0.978-0.879,1.288-1.672
			c0-0.632,0-1.412,0-2.205c0-19.706,0-39.425,0-59.132C440.328,137.055,440.34,136.597,440.328,136.138z"
          />
        </g>
        <g id="Shape_1_copy_2">
          <path
            d="M289.787,276.734c0-46.659,0-93.305,0-139.964c0-0.52,0-1.053,0-1.573c0-0.396,0.037-0.793-0.186-1.14
			c-1.808-1.957-15.272-15.21-16.585-16.313c-0.706-0.149-1.424-0.074-2.155-0.074c-22.865,0-45.742,0-68.607,0
			c-0.396,0-0.793-0.012-1.189,0c-0.384,0.025-0.78,0.062-1.09,0.099c-0.173,0.149-0.285,0.223-0.372,0.322
			c-5.4,5.388-10.788,10.776-16.164,16.164c-0.223,0.223-0.409,0.495-0.607,0.756c0,0.892,0,1.746,0,2.589
			c0,47.055,0,94.098,0,141.14c0,0.52-0.012,1.053,0,1.573c0.012,0.384,0.05,0.78,0.062,1.102
			c1.313,1.474,13.204,13.167,14.678,14.43c0.817,0,1.61,0,2.391,0c23.658,0,47.315,0,70.973,0c0.458,0,0.917,0.012,1.375,0
			c0.384-0.012,0.78-0.062,1.09-0.074c1.635-1.437,7.221-7.06,15.507-15.569c0.272-0.285,0.52-0.582,0.768-0.855
			C289.861,278.443,289.787,277.588,289.787,276.734z M255.403,152.079c0,36.787,0,73.561,0,110.348c0,0.595,0,1.189-0.025,1.771
			c-0.012,0.186-0.099,0.359-0.173,0.595c-0.248,0.062-0.508,0.136-0.756,0.149c-0.595,0.025-1.177,0.025-1.771,0.025
			c-10.838,0-21.676,0-32.514,0c-0.595,0-1.189,0.012-1.771-0.025c-0.248-0.012-0.495-0.124-0.731-0.186
			c-0.062-0.235-0.124-0.434-0.124-0.619c-0.025-0.595-0.025-1.177-0.025-1.771c0-36.713,0-73.438,0-110.15
			c0-0.78-0.062-1.561,0.111-2.502c0.434-0.05,0.681-0.087,0.941-0.087c12.151,0.012,24.302,0.037,36.452,0.062
			c0.062,0,0.099,0.062,0.223,0.161c0.037,0.111,0.149,0.285,0.149,0.458C255.403,150.903,255.403,151.485,255.403,152.079z"
          />
        </g>
        <g id="Shape_1_copy">
          <path
            d="M131.095,135.94c-0.012-0.384-0.062-0.78-0.111-1.177c-0.867-0.842-1.734-1.635-2.589-2.465
			c-4.62-4.385-9.228-8.794-13.848-13.179c-0.52-0.495-0.979-1.065-1.672-1.412c-0.223-0.012-0.495-0.05-0.756-0.05
			c-23.385,0-46.795,0-70.18,0c-0.198,0-0.384,0.05-0.595,0.062c-0.161,0.111-0.334,0.211-0.471,0.347
			c-5.883,5.735-11.754,11.47-17.626,17.217c-0.136,0.136-0.235,0.31-0.409,0.52c-0.012,0.533-0.05,1.115-0.05,1.709
			c0,10.392,0,20.759,0,31.151c0,0.458,0.012,0.917,0.012,1.375c0,0.272,0.099,0.508,0.434,0.731
			c0.483,0.012,1.078,0.037,1.66,0.037c9.265,0,18.53,0,27.795,0c0.458,0,0.917-0.012,1.375-0.025
			c0.347-0.012,0.644-0.272,0.644-0.595c0.025-0.595,0.025-1.177,0.025-1.771c0-4.001,0-8.014,0-12.027c0-0.595,0-1.189,0.025-1.771
			c0.012-0.186,0.099-0.359,0.161-0.57c0.198-0.062,0.372-0.173,0.557-0.186c0.52-0.037,1.053-0.037,1.573-0.037
			c12.622,0,25.231,0,37.852,0c0.458,0,0.917,0,1.375,0.012c0.582,0.025,0.83,0.273,0.842,0.855
			c0.012,0.198,0.012,0.396,0.012,0.595c0,8.683,0,17.341,0,26.023c0,0.322-0.025,0.656-0.05,0.979
			c-0.012,0.186-0.334,0.533-0.52,0.533c-0.656,0.025-1.313,0.05-1.969,0.05c-15.904,0-31.808,0-47.711,0
			c-0.595,0-1.189-0.012-1.771,0c-0.384,0.012-0.78,0.062-1.09,0.074c-1.623,1.362-10.999,10.479-20.276,19.719
			c-0.322,0.322-0.619,0.669-0.917,0.979c-0.111,0.756-0.062,1.486-0.062,2.205c0,29.169,0,58.351,0,87.521
			c0,0.52,0,1.053,0.012,1.573c0.012,0.607,0.223,0.83,0.83,0.842c0.656,0.012,1.313,0.012,1.969,0.012c22.667,0,45.346,0,68,0
			c11.569,0,23.125,0,34.694,0c0.718,0,1.437,0.062,2.304-0.099c0.087-0.285,0.186-0.458,0.198-0.632
			c0.025-0.52,0.025-1.053,0.025-1.573c0-14.727,0-29.43,0-44.157c0-0.52-0.012-1.053-0.025-1.573
			c-0.012-0.272-0.111-0.508-0.446-0.694c-0.446-0.012-0.966-0.037-1.486-0.037c-9.203,0-18.393,0-27.596,0
			c-0.458,0-0.917,0-1.375,0.037c-0.186,0.012-0.334,0.161-0.483,0.223c-0.211,0.619-0.136,1.214-0.136,1.796
			c-0.012,4.137,0,8.286,0,12.423c0,0.595,0,1.189-0.025,1.771c-0.012,0.186-0.111,0.359-0.186,0.595
			c-0.582,0.173-1.164,0.111-1.746,0.111c-13.538,0-27.076,0-40.614,0c-2.118,0-1.982,0.087-1.982-2.081c0-14.195,0-28.389,0-42.584
			c0-0.458,0-0.917,0.05-1.375c0.012-0.186,0.149-0.347,0.211-0.508c0.743-0.223,1.474-0.124,2.192-0.124
			c10.912-0.012,21.812,0,32.724,0c7.159,0,14.331,0,21.49,0c0.718,0,1.449,0.062,2.217-0.087c0.31-0.297,0.656-0.595,0.966-0.917
			c1.09-1.14,2.168-2.291,3.258-3.431c3.988-4.187,7.977-8.373,11.965-12.56c0.458-0.471,0.979-0.879,1.288-1.672
			c0-0.632,0-1.412,0-2.205c0-19.706,0-39.425,0-59.132C131.095,136.857,131.107,136.411,131.095,135.94z"
          />
        </g>
      </g>
      <text
        transform="matrix(1.0986 0 0 1 441.3262 289.5039)"
        stroke={primary}
        stroke-width="9"
        stroke-miterlimit="10"
        font-family="'College'"
        font-size="247.547"
      >
        1
      </text>
    </svg>
  );
}

Artwrk10.props = {
  primary: string,
};

Artwrk10.defaultProps = {
  primary: "#000",
};
