import React from "react";
import { string } from "prop-types";

export default function Artwrk5(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      fill={primary}
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="graduating_class_1_">
        <g id="Shape_1_copy_5">
          <path
            d="M348.268,87.535c-0.119,0.848-0.489,1.6-1.373,1.767c-1.385,0.263-1.755,1.182-1.827,2.388
			c-0.167,2.937-0.358,5.874-0.525,8.811c-0.107,1.91-0.167,3.82-0.251,5.719c-0.012,0.394-0.012,0.8-0.072,1.194
			c-0.501,3.164-0.406,6.351-0.489,9.527c-0.036,1.433-0.084,2.865-0.334,4.286c-0.119,0.704-0.155,1.421-0.143,2.137
			c0.012,1.003,0.358,1.862,1.277,2.412c0.334,0.203,0.657,0.442,0.967,0.704c0.49,0.418,0.597,1.11,0.346,1.755
			c-0.322,0.824-0.931,1.254-1.791,1.361c-0.155,0.024-0.31,0.036-0.478,0.036c-1.91-0.06-3.82-0.119-5.719-0.203
			c-0.466-0.024-0.943-0.143-1.397-0.275c-0.931-0.287-1.397-0.943-1.385-1.982c0-0.31,0.119-0.609,0.191-0.943
			c0.525-0.382,1.063-0.728,1.564-1.11c0.394-0.298,0.609-0.728,0.716-1.206c0.012-0.072,0.036-0.155,0.048-0.239
			c0.167-2.065,0.418-4.119,0.489-6.184c0.119-3.343,0.119-6.686,0.167-10.028c0.012-0.478,0-0.955-0.024-1.433
			c-0.06-1.218-0.382-2.352-1.063-3.379c-0.669-0.991-1.325-1.994-1.982-2.973c-1.063-1.588-2.137-3.164-3.223-4.74
			c-0.358-0.525-0.788-0.991-1.373-1.301c-0.31-0.167-0.907-0.024-1.039,0.298c-0.06,0.143-0.155,0.287-0.179,0.442
			c-0.072,0.31-0.131,0.621-0.155,0.943c-0.048,0.716-0.096,1.433-0.096,2.149c-0.036,4.62-0.227,9.228-0.442,13.837
			c-0.155,3.498-0.131,6.996-0.191,10.506c0,0.322,0,0.633,0.012,0.955c0.036,0.824,0.31,1.552,0.907,2.149
			c0.287,0.287,0.585,0.537,0.86,0.824c0.621,0.657,0.657,1.421,0.346,2.221c-0.155,0.394-0.442,0.68-0.836,0.8
			c-0.454,0.131-0.931,0.239-1.397,0.239c-1.827,0.012-3.665-0.024-5.492-0.048c-0.728-0.012-1.421-0.143-2.077-0.454
			c-1.254-0.597-1.17-2.244-0.525-2.841c0.286-0.275,0.585-0.537,0.907-0.764c0.943-0.645,1.301-1.564,1.266-2.674
			c-0.012-0.322,0.012-0.633,0-0.955c-0.012-1.277,0.036-2.543,0.227-3.808c0.119-0.788,0.155-1.588,0.167-2.376
			c0.084-7.402,0.155-14.804,0.227-22.205c0.012-0.716-0.012-1.433-0.024-2.149c-0.024-0.943-0.382-1.695-1.194-2.209
			c-0.406-0.251-0.776-0.537-1.063-0.728c-0.382-0.645-0.334-1.206-0.287-1.755c0.06-0.788,0.609-1.373,1.337-1.445
			c0.478-0.036,0.955-0.06,1.433-0.036c1.433,0.072,2.853,0.155,4.286,0.239c0.991,0.06,1.874,0.43,2.579,1.11
			c0.573,0.549,1.11,1.146,1.564,1.791c1.158,1.612,2.268,3.271,3.402,4.895c0.501,0.716,1.015,1.421,1.564,2.113
			c0.191,0.239,0.466,0.418,0.728,0.609c0.287,0.215,0.895,0.084,1.051-0.239c0.167-0.358,0.358-0.728,0.406-1.11
			c0.084-0.704,0.072-1.433,0.096-2.149c0.048-1.194-0.298-2.197-1.325-2.901c-0.263-0.179-0.478-0.418-0.704-0.645
			c-0.466-0.501-0.597-1.11-0.489-1.767c0.084-0.561,0.525-1.051,1.063-1.158c0.394-0.084,0.788-0.155,1.182-0.167
			c2.555-0.119,5.086,0.155,7.617,0.454c0.454,0.048,0.919,0.263,1.325,0.501C348.125,86.412,348.352,86.938,348.268,87.535
			L348.268,87.535z M112.484,155.572c-0.358,0.179-0.716,0.358-1.098,0.466c-2.053,0.609-4.119,1.218-6.184,1.803
			c-0.382,0.107-0.788,0.119-1.182,0.131c-0.43,0.012-0.776-0.191-0.979-0.561c-0.191-0.346-0.334-0.716-0.442-1.098
			c-0.131-0.478-0.036-0.955,0.251-1.361c0.275-0.394,0.585-0.752,0.872-1.134c0.418-0.537,0.537-1.146,0.394-1.803
			c-0.119-0.537-0.263-1.086-0.418-1.612c-0.824-2.829-1.648-5.647-2.483-8.464c-0.227-0.764-0.466-1.528-0.764-2.256
			c-0.466-1.122-1.349-1.624-2.471-1.349c-1.313,0.322-2.603,0.728-3.892,1.11c-0.155,0.048-0.287,0.119-0.43,0.191
			c-0.776,0.394-1.242,1.015-1.194,1.898c0.036,0.633,0.131,1.265,0.287,1.874c0.227,0.919,0.525,1.839,0.812,2.746
			c0.764,2.424,1.528,4.859,2.292,7.282c0.072,0.227,0.167,0.454,0.263,0.669c0.334,0.764,0.848,1.337,1.659,1.6
			c0.382,0.119,0.764,0.239,1.122,0.406c0.537,0.239,0.872,0.68,1.063,1.23c0.37,1.051,0.024,1.934-0.991,2.34
			c-0.585,0.239-1.206,0.418-1.803,0.609c-1.361,0.442-2.71,0.907-4.083,1.313c-0.609,0.179-1.23,0.322-1.862,0.382
			c-1.361,0.131-2.376-1.337-1.815-2.603c0.155-0.358,0.382-0.704,0.621-1.003c0.704-0.895,0.812-1.874,0.478-2.925
			c-0.621-1.97-1.218-3.952-1.85-5.921c-1.254-3.94-2.519-7.879-3.761-11.819c-0.239-0.764-0.454-1.528-0.68-2.28
			c-0.692-2.328-0.764-4.692-0.406-7.091c0.155-1.098,0.263-2.209,0.346-3.319c0.024-0.394-0.036-0.788-0.119-1.182
			c-0.084-0.394-0.322-0.74-0.692-0.919c-0.501-0.239-1.027-0.43-1.528-0.645c-0.549-0.239-0.872-0.669-1.039-1.23
			c-0.239-0.8,0.06-1.647,0.8-2.065c0.549-0.31,1.134-0.573,1.743-0.752c3.343-1.027,6.686-2.03,10.04-3.044
			c0.68-0.203,1.361-0.442,2.053-0.609c0.609-0.155,1.242-0.251,1.456-0.287c1.122-0.024,1.636,0.31,1.91,0.955
			c0.346,0.8,0.31,1.54-0.275,2.232c-0.31,0.358-0.645,0.704-0.907,1.098c-0.275,0.406-0.346,0.907-0.167,1.373
			c0.167,0.442,0.382,0.883,0.657,1.254c0.752,1.027,1.552,2.018,2.328,3.02c1.719,2.221,2.985,4.668,3.749,7.39
			c1.659,5.898,3.391,11.759,5.086,17.645c0.084,0.298,0.167,0.609,0.287,0.907c0.31,0.776,0.8,1.373,1.636,1.6
			c0.31,0.084,0.609,0.179,0.919,0.275c0.764,0.239,1.158,0.8,1.325,1.54C113.582,154.426,113.236,155.19,112.484,155.572
			L112.484,155.572z M97.453,131.552c-0.31-0.716-0.68-1.433-1.086-2.101c-1.098-1.839-2.388-3.534-3.689-5.229
			c-0.143-0.179-0.322-0.346-0.501-0.501c-0.561-0.513-1.516-0.287-1.755,0.43c-0.119,0.37-0.227,0.764-0.263,1.146
			c-0.107,1.098-0.179,2.209-0.263,3.307c-0.107,1.433-0.012,2.841,0.203,4.262c0.072,0.466,0.203,0.931,0.358,1.373
			c0.203,0.585,0.848,1.015,1.48,0.991c0.394-0.012,0.8-0.036,1.17-0.131c0.991-0.275,1.97-0.573,2.949-0.895
			c0.37-0.119,0.728-0.298,1.074-0.501c0.37-0.215,0.573-0.573,0.549-0.991C97.644,132.316,97.609,131.898,97.453,131.552
			L97.453,131.552z M74.472,166.913c-0.107,0.394-0.334,0.74-0.704,0.919c-0.489,0.251-1.003,0.489-1.516,0.68
			c-1.564,0.573-3.14,1.134-4.716,1.695c-0.68,0.239-1.361,0.442-2.089,0.442c-0.513,0-0.919-0.203-1.194-0.645
			c-0.537-0.895-0.657-1.815,0.036-2.662c0.991-1.194,0.919-2.424,0.454-3.796c-0.669-1.958-1.791-3.546-3.45-4.74
			c-1.934-1.397-3.904-2.746-5.85-4.119c-0.525-0.37-1.074-0.68-1.719-0.788c-0.358-0.06-0.824,0.191-0.955,0.549
			c-0.143,0.382-0.215,0.776-0.119,1.17c0.119,0.466,0.239,0.931,0.406,1.373c1.134,3.056,2.28,6.101,3.426,9.157
			c0.024,0.072,0.06,0.143,0.084,0.227c0.501,1.433,1.337,2.495,2.949,2.794c0.764,0.143,1.194,0.74,1.456,1.445
			c0.215,0.585,0.036,1.373-0.43,1.743c-0.37,0.298-0.764,0.609-1.194,0.776c-2.149,0.824-4.322,1.612-6.483,2.4
			c-0.072,0.024-0.155,0.048-0.227,0.06c-1.11,0.263-2.268-0.788-2.101-1.946c0.06-0.382,0.191-0.764,0.358-1.122
			c0.191-0.43,0.466-0.836,0.692-1.254c0.227-0.442,0.31-0.907,0.155-1.385c-0.346-1.051-0.68-2.125-1.074-3.164
			c-2.889-7.581-5.802-15.15-8.703-22.719c-0.478-1.265-0.967-2.519-1.421-3.796c-0.406-1.134-1.134-1.886-2.352-2.137
			c-0.31-0.06-0.645-0.107-0.919-0.239c-0.346-0.179-0.68-0.418-0.955-0.692c-0.561-0.573-0.501-1.648,0.107-2.149
			c0.298-0.251,0.633-0.489,0.991-0.657c0.788-0.37,1.6-0.716,2.412-1.027c3.796-1.421,7.593-2.853,11.401-4.238
			c0.967-0.358,1.982-0.573,2.985-0.824c0.298-0.072,0.633-0.06,0.788-0.072c1.827,0.036,3.008,0.895,3.868,2.244
			c0.298,0.466,0.525,0.991,0.704,1.504c1.265,3.51,2.519,7.032,3.773,10.554c0.179,0.525,0.31,1.074,0.466,1.6
			c0.454,1.576,0.072,2.997-0.895,4.262c-0.382,0.501-0.872,0.931-1.277,1.409c-0.657,0.776-0.609,1.91,0.119,2.591
			c0.466,0.442,0.967,0.824,1.468,1.218c0.943,0.728,1.886,1.456,2.841,2.173c1.767,1.325,3.044,2.997,3.749,5.11
			c0.227,0.68,0.513,1.337,0.812,1.982c0.239,0.525,0.669,0.895,1.206,1.098c0.513,0.191,1.063,0.334,1.576,0.537
			C74.245,164.86,74.723,165.958,74.472,166.913L74.472,166.913z M57.878,145.388c-0.06-0.239-0.143-0.871-0.358-1.456
			c-1.027-2.913-2.089-5.826-3.14-8.727c-0.107-0.298-0.263-0.585-0.406-0.86c-0.406-0.812-1.086-1.122-1.946-1.086
			c-1.182,0.048-4.596,1.265-5.551,1.982c-0.406,0.298-0.597,0.728-0.609,1.242c-0.012,0.728,0.203,1.409,0.454,2.077
			c1.027,2.746,2.041,5.504,3.08,8.249c0.227,0.585,0.537,1.146,0.872,1.683c0.406,0.657,1.134,0.943,1.85,0.74
			c1.612-0.442,3.176-1.003,4.668-1.755c0.143-0.072,0.275-0.167,0.394-0.263C57.651,146.857,57.901,146.391,57.878,145.388
			L57.878,145.388z M536.298,123.326c-0.227,0.346-0.537,0.585-0.931,0.704c-0.525,0.167-1.074,0.263-1.6,0.454
			c-0.478,0.179-0.764,0.573-0.812,1.086c-0.024,0.31-0.024,0.633,0.024,0.955c0.334,2.376,0.191,4.763,0.203,7.151
			c0,1.122-0.263,2.209-0.597,3.271c-0.955,3.116-1.91,6.232-2.913,9.336c-1.051,3.259-2.149,6.495-3.223,9.742
			c-0.119,0.382-0.251,0.752-0.358,1.134c-0.275,0.967-0.215,1.886,0.454,2.71c0.251,0.31,0.478,0.633,0.669,0.991
			c0.286,0.513,0.286,1.074,0.06,1.612c-0.227,0.561-0.633,0.919-1.242,0.979c-0.31,0.036-0.645,0.072-0.943,0
			c-2.471-0.633-4.895-1.373-7.271-2.304c-0.955-0.37-1.337-1.122-0.919-2.364c0.119-0.358,0.418-0.657,0.645-0.979
			c0.573-0.167,1.11-0.298,1.636-0.466c0.633-0.215,1.122-0.609,1.385-1.23c0.227-0.513,0.442-1.027,0.609-1.552
			c1.051-3.259,2.089-6.506,3.116-9.778c0.167-0.525,0.298-1.074,0.346-1.624c0.072-0.776-0.239-1.409-0.967-1.767
			c-1.516-0.728-3.128-1.17-4.752-1.528c-0.227-0.048-0.477-0.036-0.716-0.024c-0.692,0.036-1.242,0.346-1.552,0.943
			c-0.322,0.633-0.585,1.301-0.812,1.982c-0.358,1.051-0.68,2.125-1.003,3.188c-0.585,1.898-1.146,3.808-1.719,5.707
			c-0.095,0.298-0.191,0.609-0.286,0.907c-0.454,1.313-0.322,2.507,0.788,3.474c0.119,0.107,0.215,0.239,0.31,0.358
			c0.048,0.06,0.072,0.143,0.179,0.358c0.012,0.251,0.084,0.645,0.036,1.027c-0.107,0.872-1.015,1.612-1.85,1.516
			c-0.478-0.048-0.943-0.131-1.409-0.263c-1.839-0.537-3.665-1.074-5.48-1.648c-0.37-0.119-0.74-0.298-1.074-0.489
			c-1.122-0.633-1.206-2.268-0.155-3.02c0.454-0.322,0.967-0.525,1.48-0.752c0.848-0.382,1.421-1.003,1.707-1.874
			c0.227-0.68,0.454-1.361,0.657-2.041c1.528-5.193,3.044-10.375,4.572-15.568c0.251-0.836,0.525-1.671,0.836-2.495
			c0.657-1.803,1.552-3.486,2.782-4.966c0.967-1.17,1.898-2.352,2.865-3.51c0.943-1.134,1.266-2.268-0.119-3.546
			c-0.167-0.155-0.275-0.382-0.454-0.657c0.024-0.406,0-0.895,0.096-1.349c0.167-0.836,0.907-1.349,1.779-1.218
			c0.633,0.096,1.254,0.215,1.862,0.394c3.725,1.146,7.45,2.304,11.162,3.486c0.824,0.263,1.647,0.609,2.412,1.015
			C536.68,121.32,536.871,122.478,536.298,123.326L536.298,123.326z M526.807,130.453c-0.036-1.027-0.048-2.065-0.131-3.092
			c-0.048-0.549-0.167-1.098-0.31-1.624c-0.179-0.669-0.931-0.943-1.54-0.573c-0.334,0.203-0.692,0.418-0.943,0.704
			c-1.659,1.934-3.164,3.987-4.31,6.268c-0.239,0.489-0.358,1.051-0.454,1.588c-0.084,0.489,0.119,0.955,0.454,1.301
			c0.215,0.227,0.478,0.442,0.764,0.537c1.122,0.394,2.244,0.8,3.402,1.086c1.659,0.418,2.626-0.215,2.853-1.922
			c0.179-1.409,0.203-2.841,0.298-4.274C526.855,130.465,526.831,130.465,526.807,130.453L526.807,130.453z M231.391,132.161
			c-0.418,0.215-0.86,0.466-1.313,0.525c-2.125,0.287-4.262,0.513-6.399,0.74c-0.394,0.048-0.8,0-1.182-0.06
			c-0.501-0.072-0.86-0.382-1.063-0.86c-0.406-0.943-0.251-1.683,0.489-2.376c0.346-0.322,0.74-0.597,1.074-0.943
			c0.346-0.346,0.513-0.8,0.513-1.301c0-0.31-0.012-0.633-0.06-0.955c-0.513-3.462-0.931-6.936-1.313-10.422
			c-0.084-0.704-0.263-1.409-0.478-2.089c-0.239-0.74-0.788-1.206-1.588-1.265c-1.6-0.119-3.176,0.012-4.74,0.382
			c-0.298,0.072-0.597,0.215-0.872,0.382c-0.585,0.346-0.895,0.907-0.943,1.576c-0.024,0.31,0.012,0.633,0.06,0.955
			c0.31,2.531,0.621,5.05,0.955,7.581c0.191,1.421,0.43,2.829,0.633,4.25c0.131,0.931,0.537,1.659,1.445,2.03
			c0.143,0.06,0.287,0.131,0.43,0.203c0.848,0.406,1.337,1.11,1.122,2.376c-0.107,0.609-0.454,1.039-1.027,1.194
			c-0.68,0.191-1.385,0.346-2.089,0.454c-1.337,0.203-2.686,0.358-4.023,0.525c-0.788,0.107-1.576,0.155-2.376-0.036
			c-1.098-0.263-1.695-1.779-1.015-2.686c0.287-0.382,0.621-0.716,0.979-1.039c0.86-0.776,1.051-1.743,0.895-2.829
			c-0.239-1.659-0.466-3.307-0.704-4.966c-0.334-2.364-0.68-4.728-1.015-7.091c-0.37-2.603-0.692-5.205-1.11-7.796
			c-0.287-1.767-0.239-3.486,0.167-5.217c0.263-1.086,0.561-2.161,0.848-3.235c0.263-1.003,0.537-1.994,0.8-2.997
			c0.06-0.227,0.084-0.466,0.084-0.704c0-0.501-0.167-0.955-0.585-1.242c-0.394-0.275-0.836-0.454-1.254-0.692
			c-0.836-0.478-1.218-1.23-1.254-2.161c-0.012-0.418,0.167-0.776,0.549-0.991c0.275-0.155,0.573-0.298,0.872-0.37
			c0.621-0.155,1.242-0.287,1.874-0.37c3.558-0.43,7.115-0.848,10.661-1.277c0.788-0.096,1.576-0.203,2.364-0.287
			c0.394-0.036,0.8-0.06,1.194-0.024c0.406,0.036,0.764,0.263,0.991,0.609c0.501,0.788,0.657,1.612,0.107,2.555
			c-0.418,0.251-0.895,0.525-1.361,0.812c-0.68,0.43-0.943,1.086-0.692,1.839c0.179,0.525,0.406,1.039,0.657,1.528
			c0.561,1.146,1.134,2.28,1.743,3.402c1.707,3.14,2.4,6.566,2.71,10.076c0.119,1.349,0.215,2.698,0.454,4.035
			c0.107,0.621,0.131,1.265,0.191,1.898c0.143,1.504,0.275,3.008,0.418,4.513c0.119,1.265,0.239,2.531,0.37,3.796
			c0.072,0.716,0.167,1.421,0.287,2.125c0.179,1.158,0.657,2.125,1.815,2.615c0.215,0.096,0.406,0.251,0.585,0.406
			c0.513,0.442,0.764,1.003,0.764,1.683C232.012,131.468,231.844,131.922,231.391,132.161L231.391,132.161z M220.324,106.851
			c-0.072-0.621-0.179-1.265-0.406-1.85c-0.967-2.615-2.185-5.11-3.546-7.533c-0.107-0.203-0.298-0.37-0.454-0.549
			c-0.179-0.215-0.645-0.203-0.872-0.024c-0.382,0.322-0.609,0.74-0.74,1.194c-0.263,0.991-0.549,1.994-0.728,3.008
			c-0.298,1.636-0.549,3.283-0.752,4.931c-0.072,0.537,0,1.11,0.072,1.659c0.084,0.609,0.68,1.17,1.277,1.242
			c0.31,0.048,0.633,0.06,1.146,0.107c0.907-0.119,2.018-0.239,3.104-0.418c0.382-0.06,0.764-0.227,1.11-0.406
			C220.073,107.937,220.384,107.448,220.324,106.851L220.324,106.851z M150.388,143.621c-0.633,1.456-1.719,2.483-3.2,3.032
			c-0.967,0.358-1.97,0.609-2.973,0.86c-3.629,0.895-7.259,1.767-10.9,2.65c-0.776,0.191-1.54,0.406-2.352,0.334
			c-0.609-0.048-1.039-0.358-1.277-0.919c-0.334-0.788-0.334-1.552,0.203-2.256c0.191-0.251,0.406-0.489,0.597-0.752
			c0.513-0.669,0.669-1.433,0.525-2.256c-0.084-0.466-0.167-0.943-0.287-1.409c-1.039-4.083-2.089-8.166-3.128-12.249
			c-0.43-1.695-0.872-3.391-1.289-5.086c-0.8-3.164-1.588-6.327-2.388-9.491c-0.215-0.848-0.454-1.695-0.716-2.519
			c-0.275-0.883-0.812-1.528-1.719-1.85c-0.37-0.131-0.752-0.263-1.098-0.454c-0.669-0.37-1.015-0.979-1.11-1.743
			c-0.072-0.68,0.227-1.313,0.848-1.564c0.74-0.298,1.48-0.585,2.244-0.764c4.25-1.051,8.5-2.077,12.75-3.092
			c0.848-0.203,1.707-0.358,2.579-0.501c0.8-0.131,1.588,0.012,2.352,0.251c1.206,0.394,2.089,1.158,2.555,2.328
			c0.37,0.967,0.716,1.946,0.943,2.949c1.564,6.9,3.092,13.813,4.632,20.725c0.728,3.259,1.456,6.518,2.173,9.79
			c0.191,0.848,0.31,1.719,0.454,2.555C150.639,142.714,150.568,143.192,150.388,143.621L150.388,143.621z M144.658,140.971
			c-0.036-0.31-0.072-0.633-0.143-0.943c-0.382-1.707-0.776-3.414-1.17-5.122c-1.373-5.886-2.758-11.771-4.131-17.657
			c-0.43-1.862-0.848-3.725-1.301-5.575c-0.167-0.692-0.382-1.385-0.645-2.041c-0.37-0.931-1.122-1.397-2.077-1.23
			c-1.48,0.275-2.949,0.621-4.429,0.955c-0.298,0.072-0.597,0.239-0.86,0.406c-0.585,0.37-0.907,0.931-0.788,1.612
			c0.155,0.943,0.382,1.862,0.609,2.794c1.086,4.477,2.185,8.954,3.283,13.443c1.17,4.787,2.352,9.575,3.534,14.362
			c0.096,0.382,0.227,0.764,0.37,1.134c0.334,0.824,0.967,1.242,2.221,1.23c-0.048,0,0.275,0.024,0.585-0.06
			c1.158-0.287,2.316-0.549,3.45-0.907C144.264,143.048,144.765,142.153,144.658,140.971L144.658,140.971z M186.705,138.619
			c-0.37,0.143-0.752,0.263-1.134,0.346c-3.02,0.68-6.077,1.23-9.145,1.695c-0.812,0.119-1.576,0.036-2.352-0.227
			c-1.433-0.489-2.483-1.433-3.056-2.829c-0.298-0.728-0.501-1.504-0.657-2.28c-1.146-5.766-2.28-11.544-3.414-17.323
			c-0.776-3.976-1.528-7.963-2.316-11.938c-0.143-0.704-0.31-1.397-0.513-2.077c-0.191-0.633-0.597-1.11-1.218-1.397
			c-0.43-0.203-0.872-0.406-1.277-0.645c-0.776-0.454-1.158-1.325-1.003-2.244c0.084-0.489,0.334-0.895,0.824-1.098
			c0.37-0.155,0.752-0.275,1.134-0.358c2.34-0.478,4.68-0.955,7.02-1.385c0.454-0.084,0.943-0.048,1.409,0.012
			c0.501,0.06,0.86,0.394,1.051,0.872c0.334,0.872,0.322,1.719-0.346,2.447c-0.155,0.179-0.334,0.346-0.501,0.501
			c-0.776,0.704-1.003,1.6-0.907,2.603c0.048,0.549,0.131,1.11,0.227,1.648c1.313,7.032,2.638,14.063,3.952,21.107
			c0.394,2.113,0.752,4.226,1.146,6.339c0.143,0.776,0.334,1.552,0.585,2.316c0.275,0.848,1.134,1.504,1.982,1.468
			c1.839-0.072,3.617-0.442,5.348-1.086c0.489-0.179,0.716-0.609,0.752-1.122c0.012-0.155,0-0.322,0-0.812
			c-0.024-0.227-0.072-0.776-0.167-1.325c-0.991-5.563-1.994-11.115-2.973-16.678c-0.442-2.507-0.848-5.014-1.265-7.533
			c-0.31-1.803-0.621-3.605-0.931-5.408c-0.06-0.31-0.155-0.621-0.263-0.907c-0.239-0.609-0.609-1.11-1.242-1.373
			c-0.442-0.191-0.895-0.346-1.301-0.585c-0.657-0.394-1.003-1.003-1.039-1.767s0.37-1.445,1.063-1.695
			c0.37-0.143,0.752-0.263,1.146-0.334c2.185-0.418,4.369-0.824,6.566-1.206c0.466-0.084,0.955-0.084,1.421-0.012
			c0.764,0.107,1.301,0.573,1.54,1.325c0.215,0.645,0.131,1.265-0.31,1.791c-0.203,0.239-0.442,0.466-0.68,0.669
			c-0.931,0.812-1.182,1.85-0.991,3.02c0.227,1.409,0.442,2.829,0.669,4.238c0.633,3.844,1.265,7.688,1.886,11.533
			c0.764,4.704,1.54,9.419,2.304,14.123c0.131,0.788,0.263,1.576,0.31,2.364C190.179,135.861,188.997,137.712,186.705,138.619
			L186.705,138.619z M387.295,96.453c-0.072,0.31-0.179,0.621-0.322,0.895c-0.263,0.525-0.68,0.883-1.266,0.991
			c-0.955,0.179-1.898,0.096-2.829-0.167c-0.704-0.191-1.23-0.8-1.277-1.564c-0.036-0.478,0-0.955,0.012-1.421
			c0.012-0.394,0.072-0.788,0.072-1.194c-0.012-0.788-0.621-1.564-1.385-1.755c-1.707-0.418-3.45-0.609-5.205-0.561
			c-0.239,0-0.478,0.06-0.704,0.131c-0.657,0.179-1.134,0.585-1.301,1.265c-0.107,0.454-0.191,0.943-0.203,1.409
			c-0.048,1.433-0.131,2.865-0.43,4.274c-0.048,0.227-0.072,0.478-0.084,0.716c-0.298,4.369-0.895,8.703-1.254,13.073
			c-0.155,1.827-0.239,3.653-0.561,5.456c-0.096,0.549-0.084,1.11-0.107,1.671c-0.096,1.671-0.191,3.331-0.286,5.002
			c-0.036,0.645-0.048,1.265,0.155,1.886c0.155,0.478,0.43,0.872,0.931,1.003c1.624,0.442,3.283,0.597,4.966,0.549
			c1.027-0.036,1.779-0.657,2.077-1.671c0.179-0.609,0.298-1.242,0.37-1.862c0.275-2.77,0.525-5.539,0.788-8.321
			c-0.012,0-0.024,0-0.036,0c0.072-0.716,0.155-1.421,0.215-2.137c0.036-0.394,0.036-0.8,0.012-1.194
			c-0.06-0.692-0.406-1.206-1.074-1.433c-0.525-0.179-1.074-0.298-1.588-0.501c-1.242-0.478-1.695-2.03-0.907-3.176
			c0.167-0.251,0.454-0.454,0.728-0.585c0.286-0.131,0.621-0.167,0.931-0.167c1.994,0.012,3.976,0.143,5.933,0.537
			c0.155,0.036,0.31,0.072,0.466,0.119c1.254,0.346,1.695,0.895,1.648,2.256c-0.048,1.194-0.155,2.376-0.275,3.57
			c-0.167,1.743-0.382,3.486-0.549,5.229c-0.227,2.376-0.645,4.728-0.752,7.115c-0.036,0.86-0.287,1.731-0.513,2.567
			c-0.609,2.256-2.244,3.57-4.644,3.832c-0.633,0.072-1.277,0.036-1.91-0.012c-1.982-0.155-3.964-0.322-5.945-0.537
			c-0.871-0.096-1.731-0.263-2.579-0.466c-0.86-0.203-1.659-0.561-2.352-1.122c-1.098-0.895-1.815-2.018-1.898-3.45
			c-0.048-0.788,0.036-1.588,0.096-2.376c0.06-0.716,0.275-1.421,0.275-2.125c0.012-3.355,0.621-6.65,0.764-9.992
			c0.048-1.027,0.131-2.065,0.251-3.092c0.227-1.982,0.406-3.964,0.454-5.945c0.012-0.633,0.048-1.277,0.167-1.898
			c0.322-1.886,0.418-3.796,0.442-5.707c0.024-1.683,0.286-3.319,0.8-4.931c0.251-0.8,0.728-1.409,1.373-1.898
			c0.907-0.692,1.946-1.098,3.08-1.17c0.788-0.048,1.588-0.06,2.388,0c2.221,0.167,4.441,0.358,6.662,0.585
			c1.349,0.143,2.674,0.43,3.892,1.051c1.731,0.872,2.615,2.268,2.65,4.19C387.641,94.411,387.546,95.438,387.295,96.453
			L387.295,96.453z M32.652,181.908c-0.418,0.967-1.074,1.767-1.994,2.316c-0.824,0.489-1.659,0.943-2.519,1.337
			c-1.516,0.692-3.044,1.361-4.596,1.982c-1.182,0.478-2.376,0.895-3.593,1.254c-1.791,0.525-3.271-0.072-4.513-1.409
			c-0.609-0.657-0.991-1.433-1.361-2.232c-1.445-3.188-2.913-6.363-4.346-9.551c-2.889-6.351-5.766-12.738-8.643-19.101
			c-0.298-0.657-0.585-1.313-0.824-1.982c-0.454-1.277-0.31-2.495,0.394-3.665c0.501-0.836,1.146-1.528,1.97-2.03
			c0.68-0.418,1.373-0.812,2.101-1.134c2.4-1.063,4.799-2.113,7.223-3.128c0.728-0.31,1.504-0.525,2.28-0.704
			c1.827-0.406,3.749,0.107,4.811,2.173c0.478,0.919,0.883,1.862,1.301,2.806c0.119,0.287,0.191,0.609,0.215,0.931
			c0.048,0.669-0.131,1.265-0.657,1.707c-0.824,0.692-1.779,1.039-2.853,1.051c-0.669,0.012-1.254-0.215-1.6-0.836
			c-0.346-0.621-0.669-1.265-1.027-1.886c-0.525-0.883-1.433-1.289-2.447-1.015c-1.707,0.454-3.319,1.122-4.811,2.053
			c-0.752,0.466-1.003,1.182-0.955,2.041c0.036,0.728,0.263,1.409,0.549,2.065c0.621,1.468,1.254,2.925,1.898,4.381
			c2.304,5.241,4.608,10.482,6.912,15.723c0.86,1.97,1.719,3.928,2.603,5.886c0.298,0.657,0.609,1.289,0.979,1.91
			c0.669,1.146,1.504,1.468,2.829,1.17c0.227-0.048,0.478-0.096,0.692-0.191c1.098-0.466,2.185-0.943,3.271-1.445
			c0.215-0.096,0.406-0.251,0.573-0.418c0.489-0.454,0.74-1.015,0.728-1.695c-0.012-0.645-0.227-1.254-0.466-1.839
			c-1.409-3.295-2.794-6.59-4.226-9.861c-0.752-1.707-1.409-2.316-3.558-1.803c-0.31,0.072-0.621,0.119-0.943,0.167
			c-0.716,0.096-1.242-0.239-1.588-0.824c-0.191-0.334-0.31-0.74-0.358-1.122c-0.084-0.68,0.215-1.242,0.74-1.671
			c0.179-0.143,0.394-0.275,0.609-0.37c1.898-0.824,3.784-1.648,5.695-2.447c0.287-0.119,0.609-0.179,0.931-0.203
			c0.406-0.036,0.788,0.107,1.051,0.442c0.513,0.621,0.8,1.361,1.11,2.101c1.23,2.937,2.471,5.862,3.701,8.799
			c0.704,1.683,1.409,3.367,2.101,5.062c0.454,1.098,0.895,2.209,1.158,3.641C33.01,180.857,32.867,181.395,32.652,181.908
			L32.652,181.908z M611.881,154.247c-0.298,1.242-0.788,2.424-1.504,3.486c-0.525,0.788-1.349,1.122-2.268,0.943
			c-0.788-0.155-1.516-0.478-2.221-0.848c-0.788-0.43-1.11-1.098-0.895-1.994c0.131-0.537,0.358-1.051,0.525-1.576
			c0.119-0.382,0.275-0.752,0.322-1.134c0.084-0.657-0.084-1.277-0.609-1.731c-0.298-0.263-0.609-0.537-0.955-0.692
			c-1.23-0.549-2.483-1.074-3.737-1.576c-0.287-0.119-0.621-0.155-0.931-0.167c-0.764-0.036-1.433,0.227-1.85,0.872
			c-1.051,1.612-1.755,3.379-2.173,5.265c-0.143,0.645-0.06,1.277,0.131,1.886c0.406,1.289,0.836,2.567,1.289,3.844
			c1.433,3.964,2.889,7.915,4.334,11.879c0.131,0.37,0.239,0.764,0.358,1.134c0.346,1.11,0.31,2.197-0.107,3.271
			c-0.43,1.11-0.848,2.232-1.325,3.319c-0.704,1.6-1.445,3.188-2.209,4.763c-0.346,0.716-0.728,1.421-1.194,2.053
			c-1.242,1.695-3.032,2.304-5.05,1.767c-0.537-0.143-1.063-0.322-1.576-0.537c-2.34-0.991-4.68-1.982-7.02-2.997
			c-1.098-0.478-2.101-1.134-2.937-2.006c-0.561-0.585-0.967-1.254-1.194-2.03c-0.179-0.633-0.239-1.254-0.048-1.874
			c0.203-0.68,0.406-1.373,0.657-2.041c0.49-1.254,1.027-2.495,1.564-3.737c0.119-0.287,0.286-0.573,0.442-0.848
			c0.478-0.836,1.11-1.158,1.982-0.907c0.681,0.191,1.361,0.43,2.006,0.74c0.955,0.466,1.337,1.409,1.015,2.4
			c-0.191,0.597-0.466,1.182-0.704,1.767c-0.298,0.74-0.609,1.468-0.883,2.221c-0.131,0.37-0.227,0.764-0.286,1.158
			c-0.143,0.872,0.167,1.588,0.919,2.041c1.23,0.74,2.543,1.313,3.928,1.707c0.382,0.107,0.776,0.155,1.182,0.167
			c0.764,0.012,1.409-0.263,1.803-0.955c0.31-0.549,0.609-1.122,0.871-1.695c0.657-1.445,1.301-2.901,1.91-4.369
			c0.215-0.513,0.346-1.051,0.49-1.588c0.072-0.31,0.084-0.633,0.095-0.716c0.048-1.051-0.179-1.815-0.454-2.555
			c-0.776-2.173-1.552-4.346-2.352-6.506c-0.848-2.316-1.743-4.608-2.591-6.924c-0.298-0.824-0.561-1.659-0.8-2.495
			c-0.287-1.015-0.275-2.041,0.012-3.068c0.06-0.227,0.119-0.466,0.203-0.68c0.836-1.97,1.671-3.952,2.531-5.921
			c0.155-0.358,0.382-0.704,0.633-1.003c1.134-1.409,2.615-2.03,4.417-1.779c0.466,0.072,0.943,0.179,1.385,0.346
			c3.211,1.182,6.327,2.603,9.407,4.059c0.501,0.239,0.943,0.585,1.409,0.895c0.74,0.501,1.265,1.182,1.683,1.982
			C612.012,152.205,612.131,153.22,611.881,154.247L611.881,154.247z M574.991,138.321c-0.119,1.54-0.633,2.949-1.48,4.238
			c-0.43,0.657-1.074,0.919-1.839,0.872c-0.812-0.06-1.552-0.346-2.256-0.704c-0.907-0.466-1.397-0.895-1.039-2.328
			c0.131-0.537,0.334-1.063,0.466-1.6c0.084-0.31,0.155-0.621,0.167-0.943c0.012-0.669-0.215-1.254-0.788-1.648
			c-0.263-0.179-0.537-0.358-0.824-0.478c-1.11-0.43-2.221-0.86-3.355-1.242c-0.37-0.131-0.776-0.179-1.17-0.203
			c-1.063-0.084-1.886,0.346-2.352,1.289c-0.776,1.576-1.421,3.199-1.647,4.954c-0.06,0.466-0.048,0.967,0.06,1.409
			c0.191,0.764,0.466,1.516,0.764,2.256c1.659,3.964,3.343,7.915,5.002,11.867c0.406,0.955,0.752,1.922,1.11,2.889
			c0.43,1.158,0.442,2.328,0.048,3.51c-0.155,0.454-0.287,0.907-0.454,1.361c-0.943,2.447-1.91,4.883-2.865,7.33
			c-0.358,0.907-0.86,1.707-1.588,2.352c-1.206,1.074-2.579,1.528-4.19,1.134c-0.155-0.036-0.31-0.084-0.466-0.131
			c-3.008-1.015-6.017-2.041-8.93-3.343c-0.43-0.191-0.883-0.394-1.254-0.68c-0.513-0.37-1.027-0.764-1.433-1.254
			c-0.955-1.158-1.373-2.459-0.955-3.999c0.573-2.077,1.349-4.071,2.149-6.065c0.298-0.752,1.11-1.182,1.946-1.086
			c0.883,0.096,1.719,0.346,2.471,0.824c0.513,0.322,0.8,0.812,0.752,1.397c-0.036,0.549-0.167,1.098-0.322,1.624
			c-0.31,0.991-0.692,1.946-0.991,2.937c-0.155,0.525-0.251,1.086-0.286,1.636c-0.036,0.68,0.31,1.218,0.836,1.636
			c0.179,0.143,0.394,0.287,0.609,0.37c1.349,0.478,2.698,0.955,4.047,1.409c0.286,0.096,0.633,0.072,0.943,0.048
			c0.406-0.024,0.788-0.179,1.039-0.513c0.322-0.454,0.669-0.907,0.871-1.409c0.752-1.922,1.457-3.868,2.149-5.814
			c0.525-1.492,0.406-2.949-0.227-4.405c-1.803-4.155-3.558-8.333-5.325-12.511c-0.346-0.8-0.68-1.612-1.015-2.424
			c-0.621-1.54-0.645-3.104-0.119-4.668c0.585-1.731,1.182-3.462,1.839-5.169c0.251-0.657,0.645-1.289,1.074-1.839
			c1.182-1.48,2.758-2.125,4.644-1.779c0.86,0.155,1.707,0.406,2.519,0.692c2.173,0.776,4.322,1.612,6.483,2.423
			c0.895,0.334,1.755,0.752,2.555,1.265c0.609,0.394,1.146,0.86,1.6,1.421C574.728,136.124,575.074,137.151,574.991,138.321
			L574.991,138.321z M268.83,95.366c-0.716,0.072-1.039-0.06-2.185-0.764c-0.406-0.621-0.501-1.325-0.585-2.041
			c-0.072-0.549-0.155-1.098-0.287-1.636c-0.119-0.489-0.478-0.812-0.955-0.979c-0.931-0.322-1.874-0.322-2.829-0.179
			c-0.597,0.096-1.17,0.692-1.23,1.301c-0.048,0.549-0.096,1.11-0.072,1.659c0.096,2.304,0.191,4.608,0.322,6.912
			c0.131,2.221,0.31,4.441,0.43,6.674c0.107,1.827,0.155,3.653,0.406,5.468c0.131,0.943,0.107,1.898,0.131,2.853
			c0.06,2.232,0.107,4.453,0.442,6.662c0.131,0.86,0.131,1.743,0.203,2.615c0.072,0.836,0.418,1.516,1.17,1.97
			c0.346,0.203,0.669,0.418,0.991,0.669c0.68,0.525,0.919,1.492,0.585,2.376c-0.143,0.394-0.418,0.692-0.812,0.824
			c-0.37,0.119-0.764,0.239-1.158,0.263c-2.137,0.143-4.286,0.263-6.435,0.37c-0.394,0.012-0.8-0.048-1.182-0.131
			c-0.68-0.155-1.098-0.609-1.289-1.277c-0.179-0.657-0.107-1.289,0.382-1.779c0.382-0.394,0.86-0.704,1.265-1.074
			c0.37-0.334,0.537-0.776,0.573-1.277c0.012-0.239,0.012-0.478,0-0.716c-0.072-1.504-0.167-3.02-0.239-4.525
			c-0.06-1.433-0.131-2.853-0.346-4.274c-0.155-1.015-0.203-2.065-0.227-3.092c-0.048-2.149-0.119-4.298-0.406-6.423
			c-0.131-0.943-0.167-1.898-0.203-2.853c-0.107-2.388-0.203-4.763-0.537-7.139c-0.107-0.788-0.107-1.588-0.155-2.376
			c-0.036-0.561-0.048-1.11-0.096-1.671c-0.06-0.609-0.597-1.158-1.242-1.301c-0.955-0.215-1.898-0.179-2.829,0.072
			c-0.489,0.131-0.836,0.489-0.955,0.979c-0.119,0.454-0.131,0.943-0.179,1.409c-0.06,0.716-0.072,1.433-0.179,2.137
			c-0.119,0.752-0.704,1.325-1.433,1.409c-0.788,0.096-1.588,0.143-2.376,0.143c-0.716,0-1.349-0.561-1.552-1.277
			c-0.072-0.227-0.143-0.454-0.167-0.692c-0.143-1.421-0.298-2.841-0.394-4.274c-0.036-0.549,0.024-1.11,0.096-1.659
			c0.155-1.015,0.669-1.504,1.707-1.707c0.239-0.048,0.466-0.096,0.704-0.119c2.853-0.239,5.683-0.68,8.56-0.74
			c1.671-0.036,3.331-0.119,5.002-0.346c0.86-0.119,1.743-0.119,2.615-0.167c2.065-0.096,4.131-0.179,6.196-0.287
			c0.883-0.048,1.743,0,2.555,0.406c0.537,0.263,0.931,0.633,0.943,1.254c0.036,1.588,0.275,3.164,0.406,4.752
			C272.244,94.483,271.54,95.08,268.83,95.366L268.83,95.366z M454.735,104.117c-0.191,1.086-0.466,2.173-0.776,3.235
			c-0.31,1.039-1.146,1.504-2.221,1.349c-0.788-0.119-1.564-0.298-2.28-0.657c-0.454-0.227-0.764-0.597-0.848-1.086
			c-0.084-0.466-0.107-0.943-0.107-1.421c0.012-0.549,0.131-1.11,0.131-1.659c0-0.943-0.561-1.731-1.468-1.946
			c-1.468-0.346-2.949-0.633-4.429-0.895c-0.37-0.072-0.788,0.024-1.182,0.107c-0.573,0.119-1.039,0.478-1.254,1.015
			c-0.239,0.585-0.442,1.194-0.561,1.815c-0.525,2.817-1.015,5.635-1.516,8.452c-0.525,2.889-1.063,5.79-1.6,8.679
			c-0.68,3.677-1.385,7.342-2.042,11.019c-0.167,0.931-0.215,1.898-0.251,2.841c-0.012,0.37,0.155,0.764,0.239,1.182
			c0.537,0.227,1.027,0.525,1.564,0.645c1.242,0.263,2.495,0.442,3.749,0.657c0.155,0.024,0.322,0.012,0.478,0
			c1.051-0.024,1.839-0.585,2.125-1.6c0.263-0.919,0.43-1.85,0.645-2.782c0.167-0.692,0.31-1.397,0.501-2.089
			c0.251-0.907,1.003-1.445,1.958-1.421c0.979,0.024,1.886,0.287,2.674,0.872c0.239,0.179,0.418,0.489,0.501,0.776
			c0.107,0.37,0.143,0.788,0.107,1.182c-0.203,1.982-0.645,3.928-1.218,5.826c-0.346,1.17-1.086,2.125-2.089,2.829
			c-0.8,0.573-1.707,0.848-2.686,0.836c-0.549,0-1.11-0.06-1.659-0.155c-2.352-0.418-4.692-0.848-7.032-1.289
			c-1.254-0.239-2.471-0.597-3.57-1.265c-1.074-0.657-1.779-1.588-2.018-2.817c-0.107-0.537-0.107-1.11-0.06-1.659
			c0.107-1.027,0.263-2.053,0.43-3.068c0.776-4.549,1.564-9.085,2.364-13.634c0.31-1.803,0.657-3.593,0.967-5.396
			c0.597-3.45,1.182-6.888,1.791-10.339c0.107-0.621,0.275-1.242,0.442-1.85c0.633-2.304,2.973-4.071,5.659-3.796
			c0.478,0.048,0.955,0.084,1.421,0.167c2.579,0.513,5.145,1.027,7.712,1.564c0.621,0.131,1.23,0.322,1.839,0.513
			c0.848,0.275,1.564,0.764,2.209,1.385C454.472,101.312,455.01,102.589,454.735,104.117L454.735,104.117z M488.664,141.114
			c-0.024,0.155-0.048,0.31-0.084,0.466c-0.669,2.543-1.349,5.074-2.03,7.605c-0.107,0.382-0.251,0.752-0.406,1.122
			c-0.358,0.86-0.907,1.182-2.292,1.11c-0.382-0.072-1.17-0.203-1.934-0.394c-3.784-0.943-7.557-1.91-11.342-2.853
			c-0.848-0.215-1.707-0.394-2.555-0.609c-0.621-0.155-1.242-0.31-1.839-0.489c-1.325-0.406-1.862-0.824-1.445-2.71
			c0.096-0.406,0.334-0.728,0.728-0.883c0.442-0.179,0.907-0.298,1.349-0.466c0.728-0.275,1.158-0.824,1.397-1.528
			c0.131-0.37,0.215-0.764,0.298-1.158c1.134-4.883,2.256-9.766,3.402-14.648c0.418-1.779,0.848-3.558,1.266-5.336
			c0.68-2.865,1.361-5.73,2.03-8.596c0.131-0.537,0.239-1.086,0.298-1.636c0.096-0.824-0.107-1.576-0.704-2.197
			c-0.275-0.287-0.573-0.561-0.824-0.86c-0.275-0.31-0.346-0.704-0.346-1.11c-0.012-0.967,0.633-1.707,1.564-1.779
			c0.394-0.024,0.8-0.048,1.182,0.024c2.268,0.418,4.525,0.931,6.733,1.6c0.382,0.119,0.74,0.287,1.086,0.478
			c0.943,0.513,1.122,1.934,0.346,2.674c-0.227,0.215-0.478,0.418-0.764,0.573c-0.418,0.227-0.883,0.37-1.301,0.585
			c-0.525,0.251-0.895,0.68-1.086,1.218c-0.215,0.597-0.394,1.206-0.549,1.827c-0.513,2.089-1.003,4.178-1.504,6.268
			c-0.537,2.244-1.063,4.489-1.624,6.733c-1.23,4.943-2.471,9.885-3.689,14.828c-0.167,0.692-0.298,1.409-0.37,2.113
			c-0.084,0.895,0.454,1.779,1.301,2.077c1.504,0.525,3.032,0.991,4.608,1.182c0.31,0.036,0.633,0.012,0.943-0.036
			c0.49-0.084,0.907-0.346,1.134-0.788c0.251-0.489,0.454-1.015,0.609-1.552c0.322-1.063,0.585-2.149,0.895-3.223
			c0.107-0.382,0.227-0.764,0.418-1.11c0.334-0.597,0.871-0.931,1.564-0.907c0.967,0.024,1.874,0.322,2.722,0.8
			C488.413,139.789,488.76,140.517,488.664,141.114L488.664,141.114z M300.992,87.917c-1.433,0.979-1.707,2.328-1.695,3.94
			c0.048,9.551,0.036,19.09,0.048,28.64c0,0.716,0.012,1.433,0.072,2.149c0.048,0.466,0.191,0.919,0.298,1.385
			c0.585,0.466,1.17,0.895,1.707,1.361c0.621,0.537,0.776,1.397,0.406,2.185c-0.203,0.454-0.537,0.8-1.027,0.931
			c-0.454,0.131-0.931,0.251-1.397,0.275c-1.827,0.072-3.653,0.119-5.48,0.155c-0.645,0.012-1.265-0.084-1.874-0.322
			c-0.561-0.227-0.907-0.633-1.027-1.218c-0.215-0.943,0.036-1.731,0.86-2.292c0.072-0.048,0.131-0.096,0.203-0.131
			c1.218-0.597,1.659-1.6,1.624-2.925c-0.06-2.388,0.06-4.775-0.286-7.151c-0.107-0.788-0.096-1.588-0.096-2.376
			c-0.036-6.602-0.06-13.204-0.084-19.806c0-0.716-0.012-1.433-0.048-2.149c-0.036-0.955-0.442-1.671-1.337-2.089
			c-0.358-0.167-0.716-0.346-1.039-0.573c-0.191-0.131-0.334-0.346-0.442-0.549c-0.37-0.704-0.084-1.958,0.573-2.376
			c0.322-0.215,0.716-0.394,1.098-0.418c2.376-0.191,4.763-0.394,7.151-0.251c0.549,0.036,1.086,0.203,1.612,0.382
			c0.478,0.167,0.74,0.573,0.848,1.074C301.839,86.603,301.708,87.427,300.992,87.917L300.992,87.917z"
          />
        </g>
      </g>
      <g id="wings_1_" enable-background="new    ">
        <g id="wings">
          <path
            d="M554.564,337.967c0.227-0.048,0.466-0.084,0.681-0.179c1.385-0.597,2.758-1.242,4.155-1.815
			c0.537-0.227,0.883-0.68,1.361-0.931c0.907-0.49,1.827-0.991,2.782-1.373c2.232-0.883,4.274-2.101,6.387-3.211
			c2.185-1.146,4.393-2.232,6.709-3.08c1.122-0.406,2.268-0.764,3.319-1.313c0.955-0.501,1.946-0.692,2.985-0.692
			c0.633,0,1.266,0.084,1.922,0.143c0.549,0.478,1.122,0.919,1.624,1.409c0.466,0.454,0.728,1.039,0.788,1.695
			c0.191,2.018-0.084,3.975-0.907,5.826c-0.227,0.513-0.37,1.039-0.573,1.564c-0.31,0.812-0.513,1.671-1.074,2.388
			c-0.191,0.239-0.286,0.573-0.406,0.86c-0.752,1.755-1.647,3.438-2.471,5.157c-1.134,2.364-2.471,4.632-3.653,6.972
			c-0.609,1.206-1.325,2.352-2.077,3.474c-1.051,1.588-2.173,3.14-3.247,4.716c-0.8,1.182-1.576,2.376-2.376,3.57
			c-1.325,1.982-2.591,4.011-4.322,5.683c-0.227,0.215-0.406,0.49-0.585,0.74c-0.525,0.704-1.039,1.409-1.564,2.101
			c-0.621,0.824-1.218,1.659-1.874,2.471c-1.397,1.731-2.818,3.45-4.25,5.157c-0.657,0.788-1.337,1.576-2.006,2.364
			c-1.6,1.874-3.188,3.761-4.787,5.635c-0.513,0.609-1.027,1.206-1.516,1.839c-0.776,1.003-1.528,2.03-2.543,2.817
			c-0.239,0.179-0.394,0.501-0.501,0.788c-0.06,0.167,0.072,0.394,0.131,0.669c2.412-0.824,4.728-1.636,6.924-2.758
			c1.91-0.967,3.832-1.898,5.85-2.65c2.28-0.86,4.596-1.254,7.008-1.086c0.239,0.012,0.466,0.107,0.764,0.167
			c0.095,0.263,0.263,0.549,0.31,0.848c0.131,0.955,0.06,1.934-0.227,2.841c-0.358,1.122-0.669,2.268-1.194,3.355
			c-0.788,1.647-1.468,3.355-2.03,5.086c-0.442,1.373-1.074,2.65-1.564,3.987c-0.501,1.337-1.098,2.638-1.636,3.964
			c-0.728,1.767-1.492,3.51-2.388,5.193c-0.334,0.633-0.68,1.242-1.11,1.827c-1.003,1.337-1.898,2.746-2.65,4.25
			c-0.143,0.286-0.287,0.573-0.466,0.824c-1.528,2.232-3.068,4.453-4.608,6.674c-0.095,0.131-0.191,0.251-0.286,0.382
			c-0.561,0.764-1.098,1.552-1.695,2.292c-0.549,0.669-1.218,1.254-1.767,1.934c-1.671,2.03-3.522,3.88-5.444,5.659
			c-0.286,0.275-0.525,0.597-0.812,0.872c-0.334,0.334-0.68,0.657-1.051,0.943c-0.955,0.716-1.934,1.397-2.889,2.113
			c-0.251,0.191-0.442,0.454-0.657,0.681c-0.131,0.143-0.06,0.525,0.107,0.621c0.764,0.454,1.6,0.716,2.459,0.86
			c1.504,0.239,2.925,0.728,4.298,1.385c0.943,0.442,1.91,0.776,2.913,1.063c0.836,0.239,1.659,0.549,2.495,0.788
			c1.051,0.298,1.779,1.003,2.447,1.815c0.645,0.788,0.883,1.683,0.788,2.674c-0.107,1.146-0.549,2.149-1.277,3.032
			c-0.251,0.31-0.549,0.585-0.824,0.86c-0.167,0.167-0.334,0.37-0.537,0.466c-1.516,0.752-2.495,2.185-3.999,2.973
			c-2.328,1.206-4.572,2.567-6.865,3.844c-1.528,0.848-3.068,1.671-4.608,2.519c-0.346,0.191-0.728,0.358-1.027,0.609
			c-1.91,1.612-4.31,2.34-6.435,3.582c-1.23,0.716-2.555,1.277-3.856,1.886c-3.391,1.576-6.805,3.092-10.398,4.155
			c-0.764,0.227-1.409,0.704-2.173,0.955c-2.734,0.907-5.551,1.397-8.393,1.755c-2.209,0.275-4.417,0.537-6.626,0.812
			c-4.035,0.49-8.094,0.633-12.153,0.501c-3.2-0.107-6.327-0.68-9.419-1.445c-1.015-0.251-1.898-0.8-2.829-1.242
			c-0.131-0.06-0.31-0.036-0.466-0.024c-0.143,0.012-0.31,0.394-0.275,0.621c0.74,3.784,0.609,7.605,0.31,11.413
			c-0.096,1.182-0.322,2.364-0.585,3.522c-0.466,2.089-0.943,4.19-1.779,6.172c-0.31,0.728-0.549,1.492-0.788,2.244
			c-0.275,0.895-0.907,1.277-1.803,1.254c-0.871-0.024-1.755-0.012-2.615-0.167c-1.015-0.167-2.018-0.442-3.008-0.752
			c-4.322-1.337-8.667-2.626-12.977-4.023c-2.042-0.669-4.095-1.277-6.112-2.006c-0.669-0.239-1.349-0.43-1.97-0.824
			c-0.454-0.286-1.039-0.358-1.576-0.525c-1.445-0.442-2.889-0.86-4.274-1.492c-0.43-0.191-0.907-0.263-1.373-0.37
			c-0.215-0.06-0.513,0.215-0.466,0.418c0.024,0.155,0.012,0.322,0.072,0.466c1.337,3.152,2.495,6.363,3.617,9.598
			c0.179,0.525,0.286,1.074,0.442,1.612c0.382,1.301,0.49,2.662,1.074,3.904c0.251,1.648,0.525,3.295,0.752,4.954
			c0.072,0.549,0.024,1.122,0.084,1.671c0.537,4.453,0.167,8.906-0.096,13.347c-0.107,1.827-0.49,3.617-0.812,5.42
			c-0.06,0.346-0.525,0.501-0.752,0.286c-0.06-0.06-0.131-0.096-0.179-0.155c-1.361-1.552-2.722-3.104-4.095-4.644
			c-0.478-0.537-0.991-1.039-1.516-1.516c-1.588-1.445-2.961-3.104-4.381-4.704c-0.955-1.063-1.791-2.232-2.758-3.295
			c-0.848-0.943-1.6-1.982-2.638-2.758c-0.251-0.191-0.49-0.406-0.692-0.657c-2.316-2.829-4.907-5.432-6.912-8.512
			c-0.346-0.525-0.657-1.086-1.254-1.492c-0.155,0.131-0.43,0.251-0.466,0.418c-0.107,0.621-0.143,1.266-0.191,1.886
			c-0.06,0.8-0.155,1.588-0.501,2.316c-0.179,0.382-0.573,0.573-0.979,0.418c-0.298-0.119-0.597-0.227-0.872-0.37
			c-1.743-0.955-3.414-2.042-4.859-3.438c-0.8-0.764-1.636-1.516-2.447-2.268c-0.119-0.107-0.227-0.227-0.31-0.358
			c-1.445-2.077-3.235-3.94-4.262-6.315c-0.096-0.215-0.263-0.394-0.406-0.585c-0.096-0.131-0.537-0.155-0.669-0.048
			c-0.119,0.107-0.263,0.191-0.358,0.31c-0.203,0.251-0.37,0.513-0.573,0.764c-2.065,2.543-2.388,2.865-5.301,4.31
			c-3.761,1.874-7.629,2.077-11.521,0.406c-0.788-0.346-1.564-0.824-2.221-1.385c-1.862-1.636-3.558-3.414-4.095-5.981
			c-0.406-1.97-0.489-3.94,0.084-5.898c0.394-1.349,1.122-2.447,2.34-3.188c0.406-0.251,0.8-0.537,1.23-0.728
			c0.155-0.072,0.406,0.06,0.597,0.096c0.036,0.513-0.251,0.812-0.43,1.17c-0.776,1.564-0.931,3.164-0.442,4.871
			c0.334,1.17,0.824,2.244,1.516,3.223c0.848,1.206,2.006,2.042,3.45,2.412c3.164,0.812,5.981,0.084,8.44-2.053
			c0.346-0.31,0.621-0.728,0.872-1.122c0.991-1.564,1.636-3.283,1.874-5.122c0.418-3.164,0.645-6.327,0.394-9.527
			c-0.37-4.775-1.146-9.479-2.34-14.099c-0.466-1.85-1.11-3.653-1.659-5.48c-0.334-1.146-0.633-2.292-0.955-3.438
			c-0.084-0.31-0.155-0.621-0.263-0.919c-0.848-2.4-1.671-4.811-2.555-7.199c-0.358-0.955-0.573-1.958-1.182-2.853
			c-0.382-0.573-0.525-1.325-0.692-2.018c-0.406-1.707-0.883-3.402-1.134-5.122c-0.275-1.803-0.358-3.641-0.466-5.468
			c-0.131-2.232,0.012-4.465,0.275-6.674c0.203-1.647,0.621-3.283,0.955-4.919c0.107-0.549,0.251-1.086,0.37-1.624
			c0.227-1.003,0.478-2.006,1.158-2.841c0.191-0.239,0.298-0.561,0.43-0.848c1.039-2.244,2.34-4.334,3.641-6.423
			c0.131-0.203,0.215-0.43,0.382-0.597c1.636-1.612,2.961-3.534,4.787-4.978c1.182-0.931,2.197-2.077,3.247-3.152
			c0.943-0.967,1.898-1.91,2.961-2.758c1.242-0.979,2.447-2.03,3.689-3.02c1.17-0.943,2.221-2.042,3.295-3.104
			c1.982-1.958,4.047-3.82,6.256-5.516c1.636-1.265,3.044-2.794,4.56-4.202c1.564-1.468,3.104-2.949,4.835-4.238
			c1.146-0.848,2.137-1.898,3.152-2.901s1.982-2.042,3.02-3.032c1.492-1.421,3.02-2.818,4.501-4.262
			c1.146-1.11,2.173-2.316,3.247-3.498c0.322-0.346,0.585-0.776,0.943-1.074c1.337-1.134,2.424-2.519,3.725-3.677
			c1.671-1.48,3.235-3.056,4.584-4.835c0.43-0.573,0.967-1.051,1.468-1.564c1.874-1.946,3.749-3.88,5.623-5.838
			c0.334-0.346,0.645-0.704,0.919-1.086c1.349-1.874,2.877-3.593,4.381-5.348c1.086-1.265,2.185-2.519,3.271-3.796
			c0.203-0.239,0.37-0.513,0.537-0.788c0.215-0.334,0.382-0.704,0.621-1.015c1.301-1.719,2.316-3.617,3.629-5.325
			c1.898-2.459,3.414-5.169,5.098-7.76c1.671-2.555,2.758-5.372,3.582-8.285c0.215-0.764,0.334-1.564,0.179-2.352
			c-0.024-0.119-0.203-0.227-0.322-0.31c-0.048-0.036-0.167-0.012-0.203,0.024c-0.645,0.597-1.218,1.242-1.683,1.994
			c-0.37,0.609-0.764,1.218-1.206,1.767c-0.895,1.122-1.791,2.232-2.459,3.51c-0.251,0.489-0.621,0.919-0.967,1.349
			c-1.85,2.292-3.498,4.728-5.026,7.235c-0.74,1.23-1.576,2.388-2.507,3.486c-1.289,1.516-2.447,3.128-3.94,4.465
			c-0.179,0.155-0.322,0.358-0.454,0.549c-1.385,2.065-3.199,3.737-4.835,5.563c-1.325,1.48-2.782,2.829-4.083,4.334
			c-2.4,2.758-4.907,5.42-7.569,7.927c-0.227,0.215-0.442,0.454-0.657,0.692c-2.244,2.579-4.644,5.026-7.079,7.438
			c-2.722,2.674-5.277,5.504-8.011,8.166c-0.967,0.943-1.827,1.982-2.913,2.794c-0.191,0.143-0.358,0.322-0.501,0.501
			c-1.301,1.624-2.865,2.973-4.334,4.429c-1.409,1.397-2.782,2.841-4.262,4.155c-1.54,1.373-2.925,2.913-4.429,4.346
			c-1.719,1.648-3.498,3.247-5.205,4.895c-1.6,1.54-3.259,3.032-4.895,4.537c-1.409,1.289-2.782,2.615-4.417,3.629
			c-0.394,0.251-0.704,0.645-1.027,0.991c-0.442,0.454-0.824,0.979-1.301,1.397c-1.982,1.743-3.653,3.749-5.42,5.683
			c-0.43,0.466-0.74,1.027-1.301,1.385c-0.191,0.119-0.346,0.334-0.466,0.525c-0.836,1.349-1.683,2.698-2.483,4.071
			c-0.645,1.098-1.254,2.209-1.839,3.331c-0.263,0.489-0.513,1.003-0.657,1.528c-0.872,3.152-1.636,6.315-1.588,9.622
			c0.024,2.149,0.203,4.286,0.669,6.399c0.334,1.552,0.561,3.128,0.824,4.692c0.036,0.239,0.072,0.478,0.119,0.704
			c0.788,3.653,1.588,7.306,2.376,10.96c0.263,1.242,0.49,2.495,0.704,3.749c0.37,2.28,0.728,4.549,1.289,6.793
			c0.31,1.23,0.466,2.495,0.657,3.761c0.155,1.015,0.143,2.065,0.764,2.973c0.131,0.191,0.143,0.454,0.179,0.68
			c0.191,1.182,0.43,2.352,0.549,3.534c0.37,3.725,0.525,7.462,0.072,11.198c-0.167,1.337-0.43,2.662-0.633,3.892
			c-0.298,0.43-0.537,0.74-0.74,1.086c-0.191,0.322-0.131,0.788-0.525,1.015c-0.179,0.012-0.251-0.084-0.215-0.287
			c0.155-0.776,0.31-1.564,0.466-2.34c0.084-0.466,0.215-0.943,0.227-1.409c0.107-3.02,0.227-6.041-0.251-9.049
			c-0.263-1.647-0.37-3.319-0.788-4.942c-0.286-1.158-0.597-2.304-0.871-3.474c-0.406-1.695-0.692-3.438-1.242-5.098
			c-0.454-1.361-0.8-2.746-1.146-4.131c-0.716-2.853-1.361-5.719-2.041-8.584c-0.621-2.626-1.254-5.253-1.886-7.891
			c-0.06-0.227-0.06-0.501-0.179-0.692c-0.764-1.182-0.848-2.555-1.194-3.856c-0.454-1.683-0.848-3.391-1.098-5.122
			c-0.537-3.725-0.597-7.438,0.048-11.162c0.287-1.648,0.585-3.283,1.242-4.835c0.251-0.585,0.394-1.206,0.883-1.671
			c0.107-0.107,0.191-0.263,0.239-0.406c0.501-1.433,1.289-2.734,1.934-4.095c0.788-1.671,1.839-3.152,2.997-4.572
			c0.454-0.549,0.895-1.11,1.349-1.671c0.549-0.68,1.063-1.397,1.659-2.03c1.313-1.385,2.662-2.734,4.023-4.071
			c0.74-0.728,1.54-1.373,2.304-2.065c0.585-0.537,1.146-1.098,1.743-1.636c0.466-0.43,0.919-0.883,1.445-1.242
			c1.051-0.728,1.982-1.6,2.853-2.519c1.313-1.385,2.698-2.698,4.155-3.928c0.37-0.31,0.716-0.633,1.027-0.991
			c0.74-0.836,1.504-1.636,2.447-2.256c0.382-0.263,0.68-0.657,1.003-1.003c1.54-1.612,3.032-3.271,4.787-4.656
			c0.251-0.191,0.466-0.43,0.692-0.657c2.244-2.244,4.632-4.346,6.757-6.721c0.43-0.478,0.895-0.907,1.373-1.325
			c2.149-1.886,4.167-3.916,6.22-5.898c0.167-0.167,0.37-0.31,0.525-0.478c1.206-1.385,2.543-2.615,3.904-3.844
			c2.423-2.185,4.716-4.501,6.96-6.865c1.206-1.265,2.507-2.435,3.82-3.582c1.922-1.671,3.653-3.534,5.42-5.36
			c1.325-1.373,2.734-2.662,4.143-3.94c0.943-0.86,1.85-1.731,2.734-2.662c2.352-2.483,4.74-4.931,7.115-7.39
			c0.275-0.286,0.597-0.549,0.812-0.871c1.313-2.006,3.068-3.641,4.405-5.623c0.621-0.919,1.397-1.743,2.101-2.591
			c2.077-2.495,4.238-4.931,6.435-7.342c0.692-0.764,1.48-1.445,2.232-2.149c0.227-0.215,0.692-0.06,0.776,0.227
			c0.227,0.776,0.251,1.576,0.179,2.364c-0.096,1.194-0.251,2.376-0.573,3.534c-0.382,1.373-0.716,2.77-1.051,4.155
			c-0.024,0.096,0.119,0.263,0.215,0.37c0.036,0.036,0.155,0.012,0.227-0.012s0.143-0.06,0.215-0.107
			c0.06-0.048,0.131-0.096,0.179-0.155c0.979-1.146,2.137-2.125,3.068-3.319c0.692-0.872,1.445-1.683,2.268-2.447
			c0.931-0.86,1.719-1.874,2.591-2.806c0.824-0.871,1.612-1.767,2.519-2.543c0.907-0.788,1.612-1.743,2.483-2.567
			c0.86-0.824,1.755-1.624,2.591-2.459c1.015-1.015,1.994-2.053,2.961-3.104c1.015-1.11,2.209-2.03,3.211-3.176
			c0.991-1.134,2.173-2.101,3.247-3.164c0.513-0.501,0.967-1.051,1.468-1.564c1.373-1.445,2.817-2.794,4.202-4.214
			c1.385-1.421,2.841-2.782,4.238-4.19c0.621-0.621,1.182-1.289,1.803-1.898c0.907-0.895,1.91-1.695,2.746-2.638
			c0.836-0.967,1.755-1.839,2.686-2.698c1.516-1.397,2.997-2.841,4.513-4.238c0.764-0.692,1.683-1.218,2.388-1.97
			c0.657-0.692,1.266-1.421,1.982-2.041c0.776-0.68,1.516-1.409,2.328-2.053c1.122-0.883,2.221-1.815,3.211-2.841
			c0.227-0.227,0.43-0.489,0.704-0.645c1.397-0.812,2.495-1.946,3.57-3.128c0.263-0.287,0.597-0.525,0.895-0.776
			c2.101-1.707,4.202-3.402,6.292-5.11c0.86-0.704,1.791-1.361,2.519-2.185c1.552-1.743,3.57-2.913,5.241-4.501
			c2.555-1.516,4.513-3.749,6.805-5.587c1.361-1.098,2.686-2.232,4.143-3.199c1.254-0.824,2.412-1.815,3.605-2.746
			c0.501-0.394,0.943-0.86,1.48-1.194c1.289-0.788,2.376-1.85,3.653-2.674c1.266-0.824,2.423-1.803,3.689-2.626
			c2.328-1.516,4.572-3.164,6.96-4.608c1.015-0.621,1.958-1.361,2.901-2.089c0.895-0.692,1.91-1.134,2.985-1.445
			c0.227-0.06,0.466-0.048,0.752-0.072c0.346,0.298,0.692,0.621,1.063,0.943c0.871,2.185,1.182,4.441,0.931,6.781
			c-0.191,1.815-0.251,3.653-0.704,5.444c-0.179,0.692-0.334,1.373-0.609,2.041c-0.334,0.8-0.537,1.659-0.764,2.507
			c-0.561,2.077-1.254,4.107-2.077,6.089c-1.098,2.638-2.161,5.301-3.235,7.951c-0.657,1.624-1.373,3.211-2.244,4.74
			c-0.752,1.313-1.409,2.662-1.994,4.071c-0.621,1.468-1.385,2.853-2.113,4.274c-1.277,2.471-2.507,4.966-3.677,7.497
			c-0.669,1.456-1.492,2.794-2.352,4.143c-0.251,0.394-0.573,0.776-0.74,1.218c-0.585,1.492-1.421,2.829-2.292,4.167
			c-0.251,0.394-0.406,0.848-0.585,1.289c-0.024,0.06,0,0.179,0.048,0.215c0.119,0.096,0.275,0.227,0.394,0.215
			c0.812-0.096,1.456-0.597,2.149-0.991c0.275-0.155,0.489-0.43,0.776-0.537c2.376-0.919,4.584-2.232,7.044-2.925
			c0.609-0.167,1.206-0.37,1.755-0.716c0.322-0.203,0.728-0.298,1.11-0.406c0.454-0.131,0.931-0.215,1.385-0.346
			c2.065-0.585,4.095-0.561,6.089,0.478c1.337,1.051,2.567,2.268,3.211,4.059c0.227,1.17,0.179,2.435,0.119,3.713
			c-0.048,0.955-0.107,1.91-0.609,2.77c-0.036,0.072-0.072,0.143-0.084,0.227c-0.716,3.044-2.232,5.778-3.379,8.643
			c-0.179,0.442-0.466,0.836-0.692,1.254c-0.489,0.907-0.955,1.839-1.445,2.746c-0.537,0.979-1.146,1.91-1.636,2.901
			c-1.051,2.161-2.412,4.107-3.844,6.017c-0.286,0.382-0.525,0.8-0.776,1.206c-0.215,0.334-0.406,0.692-0.645,1.003
			c-2.053,2.722-3.868,5.635-6.017,8.297c-0.346,0.43-0.728,0.848-1.098,1.254c-0.525,0.597-1.122,1.146-1.576,1.779
			c-1.958,2.722-4.393,5.026-6.399,7.688c-0.191,0.251-0.406,0.501-0.657,0.692c-1.433,1.063-2.316,2.615-3.474,3.904
			c-0.095,0.107-0.107,0.286-0.143,0.442c-0.012,0.06,0.036,0.155,0.084,0.203C554.421,337.931,554.505,337.979,554.564,337.967
			L554.564,337.967z M537.182,432.854c0.537-0.585,1.17-1.074,1.695-1.659c0.895-1.003,1.862-1.982,2.603-3.092
			c0.836-1.265,1.85-2.388,2.71-3.629c0.454-0.645,0.991-1.242,1.456-1.874c0.848-1.146,1.457-2.459,2.543-3.438
			c0.167-0.155,0.251-0.406,0.382-0.597c1.839-2.877,3.582-5.826,5.539-8.62c0.275-0.394,0.501-0.812,0.692-1.254
			c0.645-1.468,1.516-2.794,2.435-4.095c0.967-1.373,1.815-2.818,2.507-4.322c1.051-2.244,2.304-4.358,3.761-6.351
			c1.218-1.683,2.209-3.474,2.973-5.313c-0.048-0.358-0.251-0.478-0.501-0.478c-0.155,0-0.322,0.048-0.454,0.131
			c-1.086,0.657-2.185,1.313-3.247,2.006c-1.468,0.955-2.997,1.791-4.656,2.388c-0.227,0.084-0.466,0.143-0.657,0.263
			c-1.779,1.098-3.796,1.624-5.742,2.28c-1.122,0.382-2.304,0.621-3.486,0.764c-1.504,0.179-2.949,0.645-4.429,0.943
			c-0.776,0.155-1.516,0.49-2.089,1.098c-0.704,0.752-1.325,1.6-2.101,2.268c-0.836,0.728-1.791,1.325-2.543,2.161
			c-0.107,0.119-0.203,0.251-0.322,0.358c-0.346,0.322-0.692,0.657-1.063,0.955c-1.528,1.266-3.056,2.531-4.608,3.784
			c-2.543,2.041-5.074,4.071-7.641,6.077c-1.003,0.788-2.006,1.588-3.116,2.185c-1.134,0.609-2.101,1.421-3.152,2.137
			c-0.382,0.263-0.645,0.716-1.039,0.967c-1.612,1.015-3.223,2.041-4.704,3.235c-1.552,0.657-2.841,1.755-4.286,2.579
			c-1.457,0.824-2.889,1.683-4.25,2.65c-0.645,0.466-1.313,0.883-1.994,1.301c-0.669,0.418-1.349,0.836-2.006,1.277
			c-0.167,0.107-0.239,0.346-0.382,0.585c0.239,0,0.406,0.048,0.525-0.012c1.552-0.597,3.116-1.194,4.668-1.803
			c0.37-0.143,0.764-0.275,1.086-0.489c1.731-1.158,3.701-1.803,5.611-2.603c0.513-0.215,1.039-0.406,1.552-0.609
			c3.629-1.421,7.103-3.188,10.721-4.62c1.182-0.466,2.34-1.003,3.498-1.504c0.143-0.06,0.31-0.131,0.406-0.239
			c0.442-0.501,1.051-0.728,1.648-0.931c1.659-0.573,3.223-1.361,4.811-2.089c4.298-1.982,7.318-3.88,10.458-6.542
			c0.155-0.131,0.31-0.048,0.382,0.131c0.024,0.06,0,0.155-0.024,0.227c-0.49,0.943-1.349,1.564-2.018,2.34
			c-0.991,1.146-2.03,2.244-3.45,2.901c-0.215,0.095-0.382,0.275-0.585,0.406c-1.122,0.752-2.244,1.504-3.367,2.244
			c-0.394,0.263-0.871,0.454-1.182,0.788c-0.788,0.836-1.803,1.301-2.782,1.827c-1.397,0.752-2.806,1.468-4.178,2.256
			c-2.065,1.17-4.274,2.113-6.411,3.164c-1.421,0.704-2.913,1.313-4.25,2.161c-1.421,0.907-2.925,1.588-4.453,2.256
			c-1.313,0.573-2.674,1.039-3.916,1.743c-1.671,0.943-3.51,1.492-5.181,2.412c-2.531,0.704-4.811,2.089-7.39,2.686
			c-0.227,0.048-0.466,0.155-0.645,0.298c-0.525,0.418-1.146,0.501-1.779,0.621c-0.621,0.119-1.242,0.298-1.827,0.513
			c-0.167,0.06-0.239,0.346-0.37,0.561c0.716,0.525,1.516,0.633,2.28,0.812c1.719,0.406,3.307,1.122,4.907,1.827
			c1.528,0.669,2.997,1.456,4.501,2.185c1.647,0.812,3.199,1.779,4.751,2.734c0.943,0.979,2.161,1.612,3.235,2.412
			c0.382,0.286,0.788,0.537,1.218,0.74c0.525,0.251,1.11,0.346,1.6-0.012c1.54-1.122,3.367-1.767,4.871-2.961
			c0.8-0.633,1.755-1.098,2.71-1.48c1.039-0.43,2.006-0.955,2.997-1.456c1.994-1.003,3.94-2.077,5.778-3.331
			c0.322-0.227,0.704-0.358,1.051-0.549c1.266-0.692,2.4-1.54,3.355-2.626c0.418-0.478,0.907-0.883,1.397-1.289
			C535.272,434.764,536.275,433.857,537.182,432.854L537.182,432.854z M473.61,386.449c-0.824,1.456-1.588,2.937-2.412,4.393
			c-1.063,1.874-2.173,3.713-3.605,5.325c-1.051,1.182-2.113,2.376-3.176,3.546c-0.37,0.406-0.776,0.788-1.182,1.17
			c-0.466,0.43-0.967,0.836-1.409,1.289c-1.564,1.6-3.307,2.961-5.181,4.178c-1.6,1.039-3.259,1.982-4.763,3.164
			c-0.251,0.191-0.525,0.37-0.8,0.525c-2.459,1.301-4.895,2.65-7.485,3.665c-2.519,0.979-5.026,1.982-7.617,2.758
			c-1.516,0.454-2.973,1.11-4.465,1.671c-1.027,0.812-2.28,1.086-3.522,1.361c-1.003,0.227-2.006,0.489-2.997,0.776
			c-0.203,0.06-0.37,0.251-0.537,0.37c0.06,0.203,0.06,0.382,0.143,0.49c0.692,0.872,1.361,1.767,2.089,2.603
			c1.206,1.373,2.34,2.806,3.211,4.465c0.669,0.346,1.158,0.298,1.564,0.012c0.991-0.704,2.161-1.003,3.271-1.409
			c0.74-0.275,1.528-0.454,2.28-0.68c0.836-0.251,1.683-0.489,2.495-0.8c3.713-1.433,7.282-3.176,10.768-5.062
			c1.039-0.561,2.137-1.051,3.008-1.91c0.215-0.215,0.537-0.334,0.812-0.489c1.182-0.657,2.388-1.266,3.534-1.982
			c1.421-0.883,2.818-1.803,4.155-2.794c1.015-0.764,2.03-1.516,3.056-2.256c0.704-0.513,1.373-1.086,2.03-1.659
			c1.982-1.719,3.892-3.522,5.921-5.169c0.931-0.764,1.791-1.576,2.615-2.424c0.824-0.86,1.683-1.671,2.412-2.615
			c1.361-1.755,2.829-3.438,4.322-5.086c0.645-0.704,1.266-1.433,1.803-2.209c1.182-1.695,2.364-3.379,3.617-5.026
			c1.146-1.516,2.221-3.104,3.259-4.704c0.609-0.931,1.373-1.731,1.958-2.674c0.967-1.552,2.041-3.032,3.02-4.572
			c1.325-2.077,2.435-4.286,3.51-6.506c0.095-0.203,0.072-0.466,0.095-0.692c0.012-0.155-0.286-0.501-0.442-0.501
			c-0.549,0.012-1.11,0-1.659,0.048c-0.955,0.072-1.898,0.239-2.853,0.263c-3.188,0.084-6.363,0.179-9.503,0.8
			c-0.072,0.012-0.155,0.012-0.239,0.024c-2.782,0.227-5.504,0.836-8.249,1.254c-0.31,0.048-0.609,0.167-0.907,0.263
			c-0.525,0.179-1.039,0.382-1.564,0.549c-0.633,0.191-1.17,0.478-1.564,1.027c-0.418,0.573-0.86,1.146-1.277,1.719
			c-0.478,0.645-0.907,1.313-1.421,1.922c-0.872,1.039-1.719,2.101-2.686,3.032c-0.979,0.943-1.707,2.077-2.698,3.02
			c-1.373,1.313-2.615,2.77-3.916,4.155c-0.167,0.179-0.346,0.334-0.513,0.501c-1.397,1.409-2.817,2.806-4.202,4.226
			c-1.445,1.48-2.949,2.877-4.381,4.381c-1.755,1.839-3.593,3.593-5.42,5.36c-1.6,1.54-3.223,3.056-4.859,4.572
			c-2.328,2.161-4.966,3.952-7.39,6.005c-0.788,0.669-1.683,1.206-2.495,1.839c-1.325,1.027-2.71,1.934-4.19,2.734
			c-1.254,0.669-2.471,1.433-3.761,2.053c-0.501,0.239-0.967,0.549-1.421,0.871c-0.191,0.131-0.322,0.346-0.478,0.525
			c-0.143,0.179-0.143,0.513,0.012,0.657c0.239,0.215,0.466,0.43,0.728,0.597c0.49,0.334,1.063,0.406,1.588,0.167
			c0.704-0.334,1.504-0.49,2.077-1.122c0.31-0.334,0.8-0.513,1.206-0.74c1.659-0.943,3.331-1.862,4.99-2.806
			c0.621-0.346,1.23-0.74,1.862-1.074c1.827-0.967,3.677-1.886,5.539-2.758c1.373-0.633,2.698-1.337,3.975-2.149
			c0.943-0.597,1.898-1.134,2.913-1.624c1.289-0.621,2.543-1.313,3.749-2.077c2.412-1.54,4.775-3.152,7.151-4.74
			c0.263-0.179,0.537-0.346,0.764-0.561c1.85-1.755,3.832-3.331,5.826-4.919c0.561-0.442,1.098-0.919,1.612-1.421
			c1.922-1.898,3.749-3.88,5.432-6.005c0.489-0.621,1.027-1.206,1.552-1.815c0.561-0.657,1.17-1.289,1.862-1.815
			c0.119,0.155,0.286,0.275,0.263,0.37C474.35,385.04,474.004,385.757,473.61,386.449L473.61,386.449z M483.125,403.796
			c-0.454-0.119-0.943-0.167-1.409-0.239c-0.597-0.096-1.086,0.167-1.528,0.537c-0.119,0.107-0.227,0.215-0.346,0.334
			c-1.612,1.636-3.223,3.283-4.859,4.907c-0.454,0.442-0.931,0.883-1.456,1.23c-0.872,0.573-1.636,1.254-2.412,1.946
			c-0.716,0.633-1.445,1.242-2.221,1.803c-1.361,0.967-2.71,1.958-4.143,2.806c-1.504,0.883-2.829,2.041-4.381,2.841
			c-1.48,0.764-2.925,1.588-4.429,2.316c-1.146,0.549-2.328,1.015-3.51,1.492c-2.435,0.979-4.871,1.922-7.402,2.638
			c-1.074,0.298-2.185,0.478-3.223,0.872c-1.874,0.716-3.832,1.003-5.802,1.289c-0.788,0.119-1.576,0.191-2.364,0.275
			c-0.633,0.06-1.265,0.084-1.898,0.155c-0.788,0.084-1.146,0.669-0.86,1.337c0.645,1.456,1.254,2.925,1.958,4.346
			c0.454,0.919,0.752,1.922,1.504,2.853c0.931,0.155,1.958,0.179,2.913-0.286c0.585-0.286,1.182-0.454,1.791-0.621
			c0.991-0.287,1.97-0.621,2.949-0.955c1.349-0.466,2.698-0.931,4.035-1.445c1.707-0.657,3.426-1.289,5.062-2.101
			c0.991-0.489,1.994-0.967,2.997-1.433c1.373-0.621,2.698-1.349,4.047-2.041c0.215-0.107,0.43-0.239,0.597-0.406
			c1.194-1.194,2.674-1.982,4.119-2.818c1.922-1.11,3.916-2.125,5.778-3.343c1.612-1.063,3.331-1.827,5.11-2.531
			c0.74-0.298,1.528-0.501,2.209-0.883c0.561-0.322,1.134-0.525,1.755-0.692c2.256-0.645,4.549-0.669,6.865-0.478
			c0.119,0.012,0.215,0.143,0.346,0.227c-1.086,0.49-2.089,1.086-3.295,1.277c-0.692,0.107-1.373,0.418-2.006,0.74
			c-1.552,0.788-3.2,1.409-4.584,2.495c-0.31,0.239-0.681,0.406-1.027,0.597c-2.364,1.325-4.477,3.02-6.578,4.728
			c-0.919,0.752-1.862,1.48-2.806,2.209c-1.074,0.812-2.232,1.516-3.295,2.352c-1.182,0.931-2.435,1.779-3.796,2.459
			c-0.919,0.466-1.839,0.931-2.794,1.349c-1.015,0.442-2.053,0.824-2.913,1.564c-1.97,0.848-3.904,1.791-6.029,2.232
			c-2.889,0.597-5.778,1.158-8.727,0.346c-0.298-0.084-0.633-0.036-0.943-0.048c-0.287,0-0.585,0.406-0.466,0.68
			c0.203,0.513,0.346,1.086,0.657,1.528c0.43,0.609,0.645,1.277,0.848,1.958c0.095,0.298,0.119,0.621,0.227,0.931
			c0.131,0.406,0.43,0.657,0.836,0.764c0.227,0.06,0.466,0.119,0.704,0.143c2.221,0.167,4.429,0.406,6.65,0.597
			c2.292,0.203,4.608,0.203,6.912,0.095c2.053-0.095,4.107-0.454,6.16-0.692c0.239-0.024,0.466-0.096,0.704-0.155
			c1.767-0.49,3.534-0.919,5.277-1.48c2.424-0.788,4.775-1.743,6.888-3.211c0.585-0.406,1.23-0.728,1.839-1.11
			c1.003-0.633,1.982-1.325,3.02-1.91c0.86-0.489,1.397-1.289,2.113-1.91c2.161-1.862,4.441-3.593,6.495-5.575
			c1.254-1.218,2.686-2.209,3.844-3.546c0.812-0.955,1.862-1.731,2.901-2.459c0.776-0.549,1.54-1.122,2.256-1.755
			c0.955-0.848,1.886-1.695,2.913-2.459c0.764-0.561,1.468-1.23,2.089-1.934c0.537-0.609,1.134-1.122,1.791-1.552
			c1.015-0.657,1.898-1.445,2.77-2.256c2.041-1.898,4.095-3.773,6.387-5.36c0.191-0.131,0.406-0.251,0.597-0.382
			c0.072-0.048,0.143-0.072,0.203-0.119c0.215-0.155,0.346-0.501,0.215-0.609c-0.179-0.131-0.394-0.298-0.597-0.31
			c-0.549-0.036-1.11,0.012-1.671,0.012c-2.626,0.048-5.253,0.096-7.867-0.179c-3.725-0.394-7.366-1.194-10.983-2.137
			C487.578,405.014,485.357,404.381,483.125,403.796L483.125,403.796z M484.689,439.325c-0.537-0.131-1.074-0.298-1.612-0.43
			c-0.573-0.131-1.122-0.036-1.588,0.334c-0.561,0.442-1.134,0.872-1.647,1.385c-1.648,1.648-3.629,2.841-5.623,3.987
			c-1.242,0.704-2.579,1.23-3.689,2.173c-0.298,0.251-0.68,0.418-1.051,0.561c-1.791,0.669-3.546,1.445-5.384,1.922
			c-3.773,1.003-7.629,1.552-11.556,1.385c-0.394-0.012-0.8-0.012-1.194-0.024c-3.271-0.06-6.506-0.418-9.742-0.788
			c-1.576-0.179-3.128-0.549-4.692-0.848c-1.636-0.322-3.283-0.609-4.907-1.015c-2.304-0.585-4.68-0.907-6.877-1.898
			c-0.346-0.155-0.776-0.131-1.17-0.131c-0.131,0-0.275,0.131-0.394,0.227c-0.131,0.107-0.096,0.525,0.024,0.669
			c0.096,0.119,0.179,0.275,0.31,0.358c1.23,0.871,2.4,1.815,3.844,2.364c0.883,0.346,1.731,0.788,2.603,1.182
			c1.6,0.716,3.188,1.421,4.895,1.862c2.471,0.633,4.978,1.039,7.485,1.48c0.31,0.06,0.633,0.06,0.955,0.072
			c2.149,0.084,4.286,0.215,6.435,0.215c3.737,0,7.462-0.084,11.151-0.872c2.734-0.573,5.432-1.182,7.999-2.28
			c1.898-0.812,3.832-1.564,5.874-1.934c1.648-0.298,3.283-0.633,4.943-0.824c2.555-0.286,5.05,0.107,7.76,0.824
			c-0.286,0.203-0.346,0.298-0.418,0.298c-2.077-0.155-4.131,0.084-6.184,0.334c-0.788,0.096-1.576,0.251-2.328,0.49
			c-1.433,0.466-2.829,1.039-4.25,1.552c-2.626,0.943-5.026,2.388-7.653,3.331c-0.215,0.072-0.43,0.215-0.609,0.37
			c-0.31,0.263-0.669,0.43-1.051,0.525c-1.612,0.442-3.199,0.943-4.823,1.325c-2.794,0.657-5.659,0.895-8.512,1.039
			c-4.369,0.215-8.751,0.119-13.085-0.549c-2.209-0.334-4.37-0.812-6.459-1.648c-0.513-0.203-0.979-0.478-1.445-0.8
			c-0.716-0.49-1.528-0.824-2.316-1.206c-0.286-0.143-0.68,0.155-0.669,0.466c0.036,0.728,0.203,1.409,0.454,2.089
			c0.549,1.504,0.812,3.068,0.788,4.68c-0.012,0.561-0.012,1.11,0.036,1.671c0.036,0.418,0.275,0.764,0.633,0.955
			c0.346,0.191,0.716,0.37,1.098,0.442c1.086,0.203,2.209,0.275,3.2,0.848c0.263,0.155,0.621,0.131,0.931,0.203
			c0.621,0.131,1.242,0.251,1.862,0.382c1.862,0.394,3.689,1.003,5.539,1.409c3.653,0.8,7.33,1.456,11.055,1.791
			c1.516,0.143,3.02,0.119,4.525-0.143c1.564-0.263,3.116-0.609,4.692-0.872c2.925-0.478,5.683-1.492,8.44-2.519
			c0.227-0.084,0.49-0.143,0.645-0.298c0.525-0.549,1.254-0.669,1.898-0.955c2.256-1.003,4.525-1.946,6.674-3.164
			c1.098-0.633,2.209-1.254,3.331-1.827c1.349-0.692,2.65-1.445,3.964-2.185c0.824-0.466,1.659-0.931,2.4-1.552
			c0.358-0.298,0.776-0.573,1.218-0.728c2.244-0.812,4.322-2.006,6.578-2.794c3.092-1.086,6.172-2.089,9.479-2.185
			c0.072,0,0.167-0.012,0.227-0.06c0.107-0.096,0.263-0.191,0.287-0.31c0.012-0.131-0.06-0.334-0.167-0.418
			c-0.322-0.227-0.657-0.43-1.015-0.597c-1.719-0.812-3.57-1.373-5.074-2.591c-0.119-0.096-0.286-0.131-0.43-0.191
			c-2.758-1.23-5.492-2.519-8.309-3.617c-0.967-0.37-1.97-0.669-2.901-1.098C488.354,440.315,486.539,439.766,484.689,439.325
			L484.689,439.325z M426.393,444.255c-0.275-0.024-0.561-0.024-0.836-0.036c0.227,0.191,0.478,0.322,0.776,0.322
			c0.072,0,0.179,0.012,0.227-0.036C426.692,444.386,426.62,444.279,426.393,444.255L426.393,444.255z M425.319,444.148
			c-0.191-0.107-0.37-0.263-0.716-0.072C424.889,444.112,425.116,444.124,425.319,444.148L425.319,444.148z M468.034,473.063
			c0.418,0.37,0.848,0.704,1.265,1.074c0.991,0.872,2.185,1.325,3.45,1.504c2.125,0.298,4.25,0.549,6.411,0.561
			c2.555,0.012,5.086-0.203,7.617-0.454c3.164-0.322,6.339-0.549,9.384-1.6c0.227-0.072,0.466-0.096,0.704-0.131
			c1.409-0.239,2.829-0.454,4.238-0.728c1.086-0.203,2.185-0.442,3.259-0.728c3.307-0.907,6.471-2.185,9.598-3.57
			c2.185-0.967,4.358-1.934,6.53-2.913c0.43-0.191,0.872-0.418,1.23-0.716c0.991-0.812,2.125-1.385,3.247-1.982
			c1.624-0.848,3.247-1.683,4.871-2.519c0.143-0.072,0.263-0.179,0.394-0.263c1.492-1.063,3.116-1.898,4.716-2.782
			c1.039-0.585,2.065-1.182,3.092-1.791c0.549-0.322,1.074-0.68,1.612-1.003c1.564-0.955,3.152-1.862,4.68-2.853
			c0.931-0.609,1.779-1.337,2.292-2.459c-0.191-0.239-0.346-0.549-0.597-0.692c-0.609-0.358-1.254-0.68-1.922-0.943
			c-1.194-0.478-2.328-0.991-3.271-1.91c-0.442-0.43-1.027-0.764-1.612-1.015c-1.242-0.537-2.447-1.146-3.665-1.731
			c-0.848-0.418-1.767-0.704-2.71-1.074c-0.501,0.191-0.824,0.286-1.098,0.43c-1.982,1.027-4.059,1.815-6.148,2.603
			c-0.895,0.334-1.767,0.68-2.603,1.17c-0.74,0.43-1.6,0.728-2.435,0.955c-3.462,0.955-6.781,2.256-10.148,3.796
			c-1.361-0.072-2.698,0.43-4.035,0.955c-0.513,0.203-1.039,0.394-1.552,0.609c-0.143,0.06-0.298,0.143-0.382,0.263
			c-0.072,0.107-0.084,0.263-0.095,0.406c0,0.036,0.119,0.119,0.179,0.107c1.958-0.298,3.928-0.585,5.886-0.907
			c3.999-0.681,8.011-1.242,12.082-1.349c0.633-0.012,1.266-0.072,1.91-0.131c2.149-0.203,4.298-0.084,6.435,0.155
			c0.155,0.012,0.298,0.084,0.442,0.155c0.048,0.024,0.107,0.107,0.107,0.167c0.012,0.072-0.012,0.191-0.06,0.215
			c-0.49,0.251-0.967,0.573-1.492,0.692c-3.02,0.692-5.993,1.6-9.025,2.244c-1.385,0.298-2.865,0.251-4.107,1.11
			c-0.179,0.119-0.466,0.107-0.692,0.143c-0.549,0.096-1.098,0.167-1.647,0.263c-2.113,0.394-4.214,0.8-6.327,1.194
			c-1.254,0.227-2.507,0.406-3.761,0.657c-2.101,0.442-4.19,0.943-6.28,1.409c-0.848,0.191-1.767,0.227-2.555,0.549
			c-1.277,0.513-2.603,0.704-3.928,0.991c-0.788,0.167-1.492,0.49-2.173,0.931c-0.931,0.609-1.898,1.158-2.829,1.755
			c-0.597,0.382-1.218,0.752-1.767,1.218c-0.621,0.525-1.301,0.907-2.03,1.23c-0.513,0.215-1.039,0.406-1.528,0.669
			c-3.904,1.994-7.963,3.617-12.153,4.883c-0.298,0.096-0.585,0.227-0.872,0.358C467.951,472.31,467.855,472.895,468.034,473.063
			L468.034,473.063z M433.855,488.666c0.907,0.298,1.827,0.525,2.734,0.836c2.483,0.836,4.966,1.683,7.45,2.543
			c0.824,0.286,1.683,0.525,2.435,0.943c1.051,0.597,2.149,1.015,3.259,1.445c2.364,0.931,4.751,1.827,7.115,2.77
			c1.54,0.621,3.08,1.277,4.572,2.03c0.872,0.442,1.803,0.597,2.77,0.585c0.215,0,0.43-0.179,0.669-0.298
			c0.084-0.298,0.203-0.597,0.263-0.907c0.692-3.593,1.337-7.187,1.946-10.792c0.43-2.531,0.537-5.05,0.585-7.605
			c0.036-2.256-0.501-4.417-0.883-6.602c-0.107-0.573-0.68-0.979-1.23-0.907c-0.31,0.036-0.633,0.072-0.943,0.155
			c-2.185,0.525-4.417,0.537-6.626,0.764c-0.633,0.06-1.277,0-1.91-0.048c-1.11-0.084-2.221-0.191-3.331-0.31
			c-0.549-0.06-1.11-0.131-1.647-0.275c-1.779-0.442-3.546-0.895-5.313-1.361c-1.54-0.406-2.997-1.039-4.453-1.671
			c-3.283-1.421-6.614-2.698-9.992-3.892c-0.454-0.155-0.883-0.346-1.337-0.501c-0.358-0.131-0.895,0.131-0.979,0.466
			c-0.239,1.003-0.466,2.006-0.692,3.02c-0.072,0.322,0.036,0.645,0.275,0.883c0.633,0.621,1.337,1.11,2.149,1.48
			c1.516,0.692,3.032,1.409,4.537,2.125c0.43,0.203,0.895,0.382,1.254,0.68c0.86,0.728,1.886,1.17,2.877,1.671
			c1.839,0.943,3.701,1.85,5.539,2.782c0.645,0.322,1.265,0.68,1.898,1.003c1.134,0.573,2.256,1.17,3.402,1.707
			c1.23,0.561,2.483,1.063,3.737,1.576c1.54,0.633,2.853,1.683,4.334,2.435c0.49,0.251,0.907,0.645,1.361,0.967
			c1.003,0.692,1.743,1.624,2.376,2.638c0.107,0.167,0.072,0.418,0.107,0.692c-0.227-0.119-0.37-0.167-0.489-0.263
			c-1.624-1.325-3.438-2.28-5.48-2.806c-0.609-0.155-1.218-0.358-1.827-0.537c-1.982-0.597-3.964-1.182-5.933-1.779
			c-0.382-0.119-0.788-0.203-1.11-0.418c-0.991-0.692-2.125-1.086-3.211-1.54c-3.522-1.48-6.877-3.307-10.231-5.11
			c-1.743-0.931-3.51-1.874-4.931-3.307c-1.552-0.991-2.925-2.185-4.238-3.474c-0.394-0.394-0.836-0.74-1.301-1.039
			c-0.466-0.298-0.967-0.585-1.492-0.716c-3.462-0.895-6.888-1.934-10.41-2.603c-0.549-0.107-1.074-0.275-1.624-0.394
			c-0.227-0.048-0.478-0.048-0.704-0.072c-0.203-0.012-0.585,0.203-0.561,0.334c0.024,0.155,0.036,0.346,0.131,0.442
			c1.803,1.85,2.925,4.143,4.107,6.387c0.251,0.489,0.454,1.015,0.609,1.552c0.513,1.755,0.991,3.522,1.456,5.205
			c1.671,1.803,1.683,1.791,3.617,2.782c0.848,0.43,1.719,0.848,2.483,1.421c0.525,0.394,1.063,0.68,1.648,0.943
			C426.262,486.326,430.082,487.436,433.855,488.666L433.855,488.666z M405.799,478.351c-0.072,0.107-0.263,0.227-0.37,0.203
			c-0.215-0.048-0.454-0.143-0.621-0.298c-1.218-1.146-2.268-2.447-3.199-3.832c-0.716-1.063-1.492-2.041-2.519-2.829
			c-0.37-0.286-0.669-0.669-0.979-1.027c-0.501-0.609-0.979-1.254-1.48-1.862c-0.143-0.179-0.334-0.346-0.537-0.454
			c-0.119-0.06-0.322-0.06-0.43,0c-0.119,0.06-0.263,0.227-0.275,0.358c-0.084,0.955-0.048,1.922,0.286,2.817
			c0.418,1.146,0.513,2.316,0.549,3.51c0.024,0.633,0.107,1.266,0.096,1.898c-0.048,1.516,0.692,2.567,1.91,3.331
			c0.406,0.251,0.812,0.49,1.23,0.716c0.49,0.263,1.003,0.478,1.48,0.764c1.373,0.788,2.841,1.421,3.987,2.567
			c1.433,0.752,2.424,2.042,3.653,3.032c1.182,0.967,2.137,2.113,2.973,3.391c0.251,0.394,0.549,0.776,0.86,1.134
			c0.072,0.084,0.31,0.096,0.43,0.048s0.203-0.215,0.275-0.334c0.036-0.06,0.036-0.155,0.036-0.239
			c-0.155-3.247-0.251-6.506-0.621-9.742c-0.227-2.053-0.609-4.083-1.194-6.065c-0.275-0.907-0.478-1.839-0.872-2.71
			c-1.242-2.758-2.782-5.348-4.429-7.891c-3.402-1.051-6.65-1.098-9.909-0.955c-0.239,0.012-0.466,0.072-0.704,0.107
			c-0.251,0.048-0.525,0.406-0.537,0.704s0.191,0.728,0.406,0.8c0.227,0.072,0.454,0.155,0.68,0.191
			c1.23,0.203,2.125,1.027,3.152,1.624c1.409,0.824,2.459,1.982,3.391,3.295c1.576,2.232,2.615,4.704,3.355,7.318
			C405.883,478.041,405.871,478.232,405.799,478.351L405.799,478.351z M408.115,498.348c0.645,0.872,1.277,1.683,1.886,2.519
			c1.695,2.304,3.486,4.537,5.599,6.483c0.525,0.478,0.955,1.063,1.421,1.612c0.203,0.239,0.358,0.537,0.597,0.74
			c1.122,0.907,2.041,1.994,2.77,3.247c0.155,0.275,0.406,0.478,0.609,0.728c1.266,1.528,2.615,2.997,3.784,4.608
			c2.519,3.462,5.313,6.745,7.497,10.446c0.155,0.263,0.43,0.454,0.657,0.669c0.048,0.048,0.167,0.06,0.215,0.036
			c0.131-0.06,0.322-0.143,0.346-0.251c0.072-0.298,0.107-0.621,0.107-0.931c-0.036-2.065-0.334-4.119-0.501-6.184
			c-0.31-3.808-1.11-7.545-1.564-11.341c-0.883-3.617-1.516-7.318-3.044-10.757c-0.096-0.215-0.131-0.454-0.191-0.692
			c-0.418-1.54-0.836-3.068-1.23-4.608c-0.096-0.382-0.107-0.788-0.167-1.182c-0.191-1.17-0.418-2.352-0.597-3.522
			c-0.131-0.86-0.645-1.433-1.373-1.839c-0.275-0.155-0.573-0.275-0.86-0.406c-2.018-0.955-3.868-2.197-5.766-3.355
			c-0.334-0.203-0.621-0.49-0.907-0.752c-0.358-0.322-0.68-0.669-1.027-0.991c-0.119-0.107-0.239-0.215-0.382-0.275
			s-0.298-0.072-0.454-0.096c-0.179-0.012-0.442,0.263-0.442,0.49c-0.06,1.743-0.119,3.498-0.143,5.241
			c0,0.215,0.143,0.43,0.251,0.716c0.322,0,0.633,0.012,0.943,0c1.242-0.072,2.125,0.549,2.794,1.528
			c0.31,0.454,0.549,0.967,0.812,1.456c0.442,0.848,0.86,1.707,1.301,2.555c0.657,1.266,1.385,2.507,1.994,3.796
			c0.645,1.361,1.289,2.734,1.719,4.178c0.513,1.743,0.895,3.546,0.752,5.408c-0.012,0.143-0.119,0.287-0.179,0.43
			c-0.119-0.084-0.286-0.131-0.358-0.251c-0.406-0.597-0.812-1.182-1.158-1.803c-0.824-1.456-1.528-2.973-2.423-4.381
			c-1.098-1.743-2.101-3.558-3.546-5.062c-0.537-0.573-0.931-1.289-1.361-1.946c-0.346-0.537-0.645-1.098-0.979-1.636
			c-0.072-0.119-0.239-0.167-0.37-0.251c-0.107,0.107-0.287,0.191-0.322,0.322c-0.119,0.37-0.191,0.764-0.287,1.146
			c-0.072,0.31-0.119,0.621-0.191,0.931c-0.096,0.442-0.513,0.645-0.895,0.454c-1.23-0.609-2.316-1.445-3.223-2.435
			c-0.824-0.895-1.898-1.421-2.829-2.149c-0.048-0.036-0.179-0.036-0.227,0.012c-0.107,0.096-0.239,0.227-0.263,0.346
			c-0.036,0.227-0.048,0.478,0,0.704C407.268,494.158,407.698,496.271,408.115,498.348L408.115,498.348z M394.565,494.11
			c0.06,0.167,0.107,0.418,0.239,0.597c0.322,0.454,0.657,0.907,1.051,1.289c1.54,1.48,2.806,3.223,4.334,4.728
			c0.501,0.501,0.907,1.098,1.349,1.671c0.68,0.872,1.337,1.779,2.042,2.626c0.967,1.158,2.089,2.149,3.259,2.985
			c0.466-0.072,0.645-0.31,0.669-0.645c0.012-0.239-0.012-0.478-0.06-0.704c-0.406-2.018-0.8-4.047-1.23-6.065
			c-0.215-1.015-0.37-2.042-0.728-2.997c-0.37-0.979-0.525-1.994-0.752-2.997c-0.287-1.242-0.358-2.507-0.49-3.773
			c-0.131-1.266-0.275-2.531-0.883-3.677c-1.707-1.54-3.749-2.662-5.289-4.286c-0.406,0.024-0.549,0.227-0.49,0.466
			c0.263,1.17,0.764,2.256,1.361,3.283c1.146,1.994,2.161,4.441,2.507,6.363c0.048,0.298,0.107,0.621-0.203,0.919
			c-0.167-0.143-0.382-0.275-0.501-0.454c-0.346-0.537-0.561-1.158-0.979-1.624c-0.8-0.907-1.409-1.898-1.922-2.985
			c-0.203-0.43-0.466-0.836-0.74-1.218c-0.131-0.179-0.346-0.43-0.525-0.43c-0.37,0-0.406,0.382-0.513,0.669
			c-0.669,1.874-1.027,3.82-1.504,5.742C394.529,493.728,394.565,493.895,394.565,494.11L394.565,494.11z M389.36,447.431
			c0.095,0.382,0.131,0.812,0.334,1.134c0.585,0.871,0.967,1.839,1.194,2.841c0.37,1.671,1.027,1.504,2.173,0.883
			c2.77-1.516,5.73-2.34,8.906-2.244c1.277,0.036,2.543,0.012,3.82,0.048c1.922,0.048,3.808,0.442,5.611,1.027
			c2.877,0.943,5.647,2.149,8.046,4.178c0.454,0.669,0.943,1.385,1.421,2.113c0.107,0.167,0.048,0.322-0.131,0.406
			c-0.06,0.036-0.167,0.048-0.215,0.024c-0.728-0.478-1.445-0.967-2.185-1.433c-0.466-0.298-0.991-0.478-1.385-0.907
			c-0.203-0.227-0.525-0.346-0.824-0.454c-2.543-0.955-5.098-1.767-7.855-1.839c-2.304-0.06-4.608-0.072-6.912,0.036
			c-1.6,0.072-3.152,0.37-4.656,0.955c-1.11,0.43-2.244,0.788-3.414,1.027c-0.227,0.048-0.418,0.191-0.561,0.251
			c-0.382,0.49-0.37,0.955-0.251,1.421c0.358,1.468,0.728,2.937,1.11,4.393c0.119,0.454,0.322,0.895,0.501,1.337
			c0.131,0.31,0.358,0.513,0.752,0.597c0.179-0.024,0.406-0.072,0.645-0.107c2.364-0.382,4.728-0.669,7.115-0.537
			c1.349,0.072,2.698,0.155,4.047,0.31c1.659,0.191,3.319,0.43,4.954,0.728c1.803,0.334,3.57,0.764,5.36,1.17
			c0.31,0.072,0.657,0.107,0.907,0.287c0.68,0.466,1.468,0.573,2.221,0.812c1.206,0.382,2.423,0.776,3.57,1.325
			c0.788,0.37,1.6,0.704,2.412,1.027c0.286,0.107,0.597,0.143,0.943,0.215c0.119-0.275,0.322-0.513,0.286-0.68
			c-0.382-1.564-0.334-3.152-0.489-4.74c-0.191-1.982-0.466-3.94-1.074-5.85c-0.513-1.588-0.895-3.211-1.779-4.656
			c-0.167-0.275-0.275-0.573-0.394-0.871c-0.37-0.967-0.872-1.874-1.468-2.722c-0.728-1.039-1.409-2.125-2.232-3.092
			c-1.015-1.206-2.328-1.898-3.964-1.815c-0.716,0.036-1.433,0.06-2.137,0.155c-2.435,0.346-4.883,0.704-7.306,1.098
			c-1.648,0.275-3.307,0.513-4.919,0.943c-1.874,0.49-3.761,0.657-5.671,0.633c-1.671-0.024-3.331-0.131-5.002-0.191
			c-0.31-0.012-0.633,0.036-0.943,0.072C389.647,446.774,389.3,447.204,389.36,447.431L389.36,447.431z M386.34,438.74
			c0.513,2.006,1.194,3.952,1.922,5.898c0.239,0.657,0.764,0.991,1.457,1.063c0.239,0.024,0.478,0.036,0.716,0.036
			c5.444,0.191,10.78-0.609,16.069-1.815c1.313-0.298,2.626-0.669,3.952-0.883c1.648-0.263,3.283-0.645,4.954-0.728
			c1.349-0.072,2.698-0.227,4.035-0.37c1.743-0.191,3.486-0.298,5.241-0.251c2.173,0.06,4.262,0.513,6.22,1.468
			c0.573,0.275,1.146,0.549,1.743,0.788c0.215,0.096,0.466,0.107,0.692,0.155c0.37,0.072,0.812-0.406,0.68-0.764
			c-0.334-0.895-0.669-1.779-1.039-2.662c-0.37-0.872-0.872-1.707-1.194-2.603c-1.003-2.77-2.244-5.432-3.653-8.011
			c-0.692-1.254-1.325-2.531-2.03-3.773c-0.943-1.659-2.232-3.092-3.414-4.572c-0.848-1.063-1.922-1.91-3.128-2.543
			c-0.454-0.239-0.919-0.251-1.361,0.036c-0.334,0.215-0.68,0.406-0.991,0.657c-0.931,0.752-1.815,1.552-2.758,2.268
			c-2.149,1.624-4.202,3.414-6.686,4.56c-0.358,0.167-0.704,0.394-0.991,0.657c-0.776,0.716-1.683,1.182-2.65,1.576
			c-0.442,0.179-0.883,0.37-1.313,0.573c-0.131,0.06-0.275,0.167-0.358,0.286s-0.143,0.298-0.119,0.43
			c0.024,0.131,0.179,0.322,0.287,0.334c1.659,0.107,3.331,0.203,4.99,0.287c2.077,0.107,4.107,0.382,6.041,1.23
			c0.215,0.096,0.454,0.143,0.692,0.191c2.268,0.478,4.405,1.349,6.554,2.185c0.669,0.263,1.289,0.621,1.91,0.967
			c0.119,0.06,0.167,0.251,0.251,0.382c-0.466,0.298-0.919,0.107-1.349-0.06c-1.409-0.561-2.877-0.907-4.369-1.17
			c-1.254-0.215-2.495-0.478-3.737-0.764c-2.806-0.657-5.659-0.86-8.524-1.003c-2.161-0.107-4.286,0.155-6.423,0.406
			c-1.11,0.131-2.209,0.287-3.283,0.585c-1.922,0.513-3.856,0.979-5.766,1.492c-0.919,0.251-1.815,0.585-2.71,0.895
			c-0.442,0.155-0.776,0.669-0.728,1.122C386.196,437.796,386.232,438.274,386.34,438.74L386.34,438.74z M386.053,435.325
			c0.143,0.036,0.334,0.072,0.454,0.012c0.549-0.286,1.086-0.597,1.6-0.931c0.096-0.06,0.084-0.275,0.131-0.43
			c-0.119-0.072-0.251-0.215-0.37-0.227c-0.716-0.036-1.397,0.155-2.006,0.489c-0.143,0.084-0.227,0.382-0.239,0.597
			C385.6,435.11,385.814,435.266,386.053,435.325L386.053,435.325z M393.3,409.538c-0.86,0.871-1.683,1.719-2.316,2.734
			c-0.298,0.466-0.621,0.931-1.003,1.325c-0.692,0.692-1.158,1.504-1.6,2.352c-0.919,1.779-1.504,3.665-2.018,5.587
			c-0.155,0.609-0.275,1.242-0.131,1.886c0.012,0.072,0.084,0.167,0.143,0.179c0.143,0.036,0.322,0.072,0.43,0.012
			c0.203-0.119,0.382-0.286,0.537-0.454c1.015-1.122,2.137-2.089,3.474-2.853c0.955-0.549,1.922-1.122,2.734-1.886
			c0.286-0.263,0.645-0.466,1.003-0.621c1.23-0.549,2.447-1.17,3.737-1.552c2.352-0.704,4.752-1.277,7.139-1.886
			c0.215-0.06,0.466,0.012,0.692,0.036c0.036,0,0.084,0.119,0.084,0.167c-0.012,0.072-0.048,0.167-0.096,0.203
			c-0.334,0.203-0.669,0.43-1.015,0.585c-1.946,0.895-3.892,1.779-5.85,2.662c-1.636,1.265-3.582,2.041-5.277,3.211
			c-0.191,0.131-0.394,0.275-0.573,0.418c-1.301,1.039-2.507,2.185-3.916,3.092c-0.191,0.131-0.37,0.31-0.525,0.478
			c-1.218,1.349-2.435,2.71-3.558,3.964c-0.203,0.86-0.167,1.576-0.107,2.28c0.012,0.084,0.024,0.155,0.036,0.239
			c0.072,0.537,0.501,0.883,0.991,0.752c0.525-0.155,1.086-0.275,1.564-0.537c0.501-0.275,1.015-0.442,1.552-0.585
			c1.54-0.406,2.997-1.027,4.441-1.695c1.504-0.692,3.176-1.039,4.465-2.173c0.119-0.107,0.275-0.167,0.418-0.215
			c2.149-0.86,4.107-2.077,6.041-3.319c0.728-0.466,1.48-0.943,2.137-1.504c0.907-0.776,1.827-1.516,2.841-2.161
			c0.597-0.382,1.182-0.848,1.612-1.397c0.8-1.015,1.827-1.755,2.758-2.603c0.824-0.74,1.695-1.445,2.567-2.125
			c2.734-2.161,5.229-4.62,8.238-6.423c0.036-0.024,0.048-0.155,0.024-0.215c-0.024-0.072-0.084-0.143-0.143-0.167
			c-0.143-0.048-0.31-0.084-0.454-0.084c-6.196,0.239-12.38,0.466-18.576,0.716c-0.788,0.036-1.6,0.072-2.364,0.227
			c-1.803,0.382-3.605,0.537-5.444,0.621C396.404,408.882,394.84,409.12,393.3,409.538L393.3,409.538z M445.674,356.83
			c-1.063,1.074-2.113,2.161-3.092,3.307c-1.242,1.445-2.423,2.949-3.641,4.405c-0.358,0.43-0.74,0.824-1.146,1.218
			c-2.674,2.603-5.169,5.384-7.593,8.226c-0.466,0.537-0.979,1.039-1.504,1.528c-1.695,1.552-3.414,3.092-5.014,4.752
			c-1.385,1.433-2.758,2.865-4.143,4.286c-0.394,0.394-0.86,0.704-1.277,1.063c-1.588,1.325-3.164,2.662-4.596,4.155
			c-1.874,1.946-3.749,3.88-5.85,5.599c-1.17,0.955-2.256,2.006-3.367,3.02c-0.286,0.275-0.549,0.573-0.824,0.871
			c-1.325,1.492-3.211,3.259-4.99,4.74c-0.49,0.406-0.895,0.895-1.325,1.361c-0.084,0.096-0.119,0.322-0.06,0.418
			c0.072,0.107,0.287,0.203,0.406,0.179c1.086-0.239,2.161-0.489,3.247-0.752c1.934-0.454,3.844-1.015,5.826-1.254
			c1.265-0.155,2.531-0.251,3.784-0.442c1.815-0.275,3.641-0.382,5.468-0.442c0.633-0.024,1.265-0.084,1.803-0.119
			c1.468-0.907,2.543-2.065,3.498-3.343c0.573-0.764,1.194-1.48,1.767-2.244c0.919-1.206,2.065-2.197,3.092-3.307
			c1.946-2.089,4.023-4.071,5.874-6.256c0.406-0.478,0.895-0.907,1.373-1.325c1.636-1.385,3.068-2.997,4.752-4.334
			c0.37-0.298,0.704-0.645,1.027-0.991c1.707-1.779,3.534-3.426,5.527-4.883c1.791-1.313,3.498-2.746,5.265-4.083
			c0.824-0.621,1.695-1.17,2.555-1.743c0.048-0.036,0.155-0.012,0.215,0.012c0.179,0.084,0.227,0.239,0.131,0.406
			c-1.254,2.328-2.877,4.405-4.68,6.303c-1.922,2.018-3.534,4.286-5.551,6.22c-1.385,1.85-3.032,3.474-4.632,5.134
			c-1.647,1.719-3.319,3.414-5.014,5.086c-1.528,1.504-3.08,2.997-4.823,4.262c-0.645,0.466-1.254,0.979-1.839,1.528
			c-0.645,0.597-1.289,1.17-2.03,1.647c-0.597,0.37-1.182,0.8-1.516,1.445c0.06,0.382,0.287,0.597,0.621,0.657
			c1.015,0.203,2.03,0.37,3.044,0.573c1.48,0.298,2.949,0.669,4.286,1.421c0.286,0.167,0.478,0.394,0.513,0.764
			c-0.287,1.086-1.015,1.97-1.683,2.841c-0.525,0.68-1.23,1.23-1.886,1.803c-0.537,0.466-1.182,0.836-1.659,1.361
			c-0.86,0.943-1.839,1.755-2.734,2.65c-0.334,0.334-0.609,0.728-0.895,1.098c-0.024,0.036,0.012,0.143,0.048,0.191
			c0.048,0.06,0.143,0.119,0.191,0.107c0.298-0.096,0.621-0.167,0.872-0.334c2.507-1.695,4.883-3.558,7.223-5.468
			c0.37-0.298,0.704-0.645,1.015-1.015c0.776-0.907,1.695-1.671,2.615-2.435c1.456-1.23,2.973-2.4,4.322-3.749
			c1.289-1.277,2.615-2.555,3.701-4.035c0.37-0.513,0.86-0.943,1.325-1.373c2.615-2.447,5.134-4.978,7.414-7.748
			c0.907-1.11,1.898-2.137,2.949-3.116c2.376-2.221,4.68-4.525,6.697-7.103c0.776-0.991,1.659-1.922,2.615-2.77
			c0.824-0.74,1.647-1.516,2.316-2.388c1.456-1.886,3.056-3.653,4.465-5.587c1.254-1.743,2.674-3.355,3.964-5.074
			c1.767-2.352,3.426-4.775,5.193-7.127c1.337-1.779,2.244-3.856,3.904-5.396c0.107-0.107,0.167-0.275,0.251-0.406
			c0.597-0.848,1.182-1.695,1.779-2.543c0.692-0.967,1.48-1.874,2.077-2.901c1.122-1.922,2.4-3.749,3.558-5.647
			c0.203-0.346,0.37-0.74,0.657-0.991c0.871-0.764,1.289-1.803,1.874-2.734c0.119-0.191,0.179-0.442,0.227-0.669
			c0.048-0.227,0.024-0.466-0.167-0.657c-0.155-0.155-0.442-0.191-0.657-0.06c-0.203,0.119-0.418,0.239-0.609,0.37
			c-1.683,1.206-3.379,2.388-5.181,3.391c-3.391,1.91-6.841,3.725-10.195,5.695c-1.779,1.051-3.582,2.03-5.42,2.985
			c-2.543,1.325-5.169,2.447-7.855,3.45c-0.298,0.107-0.645,0.143-0.895,0.31c-0.943,0.621-2.018,0.919-3.068,1.277
			c-1.958,0.669-3.916,1.325-5.85,2.053c-1.492,0.573-3.044,0.907-4.584,1.289c-0.466,0.107-0.931,0.191-1.409,0.275
			C446.939,355.875,446.235,356.257,445.674,356.83L445.674,356.83z M562.873,272.27c0.752-1.397,1.48-2.817,2.173-4.238
			c0.454-0.931,0.931-1.839,1.468-2.722c0.704-1.146,1.265-2.376,1.839-3.605c0.645-1.361,1.23-2.758,1.85-4.143
			c0.454-1.015,0.931-2.018,1.409-3.032c0.907-1.946,1.815-3.892,2.734-5.826c0.442-0.931,0.907-1.85,1.397-2.77
			c0.919-1.755,1.934-3.474,2.615-5.348c0.239-0.669,0.609-1.289,0.919-1.934s0.633-1.289,0.907-1.946
			c0.955-2.28,1.874-4.56,2.817-6.829c0.764-1.862,1.098-3.808,1.313-5.802c0.072-0.645-0.036-1.265-0.239-1.874
			c-0.024-0.072-0.06-0.143-0.095-0.215c-0.06-0.167-0.442-0.287-0.621-0.203c-0.955,0.442-1.719,1.134-2.447,1.886
			c-1.874,1.946-3.725,3.904-5.91,5.528c-1.074,0.8-2.077,1.719-3.02,2.686c-2.567,2.615-5.372,4.954-8.261,7.187
			c-0.943,0.728-1.85,1.504-2.662,2.388c-0.812,0.872-1.695,1.671-2.626,2.424c-1.934,1.528-3.832,3.104-5.742,4.668
			c-0.609,0.501-1.254,0.991-1.803,1.564c-1.218,1.265-2.531,2.412-3.999,3.379c-0.525,0.346-0.967,0.812-1.445,1.23
			c-0.298,0.263-0.585,0.537-0.895,0.788c-1.17,0.943-2.376,1.862-3.522,2.841c-2.674,2.256-5.325,4.537-8.011,6.793
			c-1.218,1.027-2.435,2.053-3.713,2.997c-0.836,0.621-1.647,1.23-2.304,2.041c-0.346,0.43-0.776,0.812-1.23,1.134
			c-1.433,1.027-2.722,2.197-3.952,3.438c-0.907,0.907-2.006,1.552-2.997,2.352c-1.182,0.955-2.077,2.197-3.211,3.188
			s-2.34,1.898-3.391,3.008c-0.549,0.573-1.146,1.11-1.779,1.588c-1.337,1.015-2.507,2.209-3.701,3.367
			c-0.346,0.322-0.585,0.764-0.943,1.063c-2.137,1.779-4.023,3.832-6.22,5.551c-1.946,1.528-3.534,3.426-5.42,5.014
			c-1.027,0.872-2.125,1.683-3.104,2.603c-2.03,1.898-4.202,3.629-6.16,5.599c-0.776,0.788-1.695,1.445-2.543,2.161
			s-1.719,1.409-2.543,2.161c-1.051,0.967-2.065,1.982-3.104,2.973c-0.286,0.275-0.645,0.501-0.848,0.824
			c-0.752,1.158-1.791,2.018-2.817,2.901c-1.648,1.397-2.877,3.188-4.346,4.751c-0.776,0.824-1.17,1.874-1.695,2.853
			c-1.707,3.223-3.713,6.28-5.563,9.431c-1.528,2.603-3.152,5.157-5.098,7.485c-0.203,0.239-0.394,0.501-0.549,0.788
			c-0.454,0.848-1.122,1.54-1.731,2.28c-1.85,2.28-3.701,4.56-5.539,6.853c-0.143,0.179-0.239,0.418-0.263,0.645
			c-0.024,0.191,0.096,0.406,0.167,0.669c0.525-0.179,1.003-0.275,1.409-0.513c1.242-0.728,2.615-1.146,3.928-1.731
			c1.015-0.454,2.065-0.824,3.068-1.301c2.507-1.206,5.014-2.4,7.569-3.51c1.886-0.824,3.773-1.695,5.432-2.925
			c1.086-0.812,2.244-1.492,3.474-2.065c0.788-0.37,1.54-0.836,2.292-1.277c0.895-0.525,1.779-1.051,2.65-1.6
			c2.221-1.397,4.608-2.507,6.888-3.796c0.991-0.549,1.815-1.242,2.519-2.125c1.146-1.421,2.209-2.937,3.629-4.107
			c0.812-0.669,1.528-1.397,2.173-2.197c1.254-1.54,2.519-3.068,3.868-4.525c0.06-0.06,0.107-0.119,0.155-0.191
			c1.074-1.385,2.041-2.865,3.379-3.999c1.361-1.158,2.137-2.758,3.367-3.999c0.227-0.227,0.43-0.466,0.645-0.704
			c0.752-0.824,1.492-1.647,2.256-2.459c1.086-1.158,2.113-2.376,3.438-3.295c0.382-0.275,0.704-0.645,1.015-1.003
			c0.31-0.358,0.573-0.764,0.907-1.098c1.086-1.051,2.209-2.065,3.283-3.116s2.125-2.149,3.199-3.211
			c0.334-0.334,0.74-0.597,1.11-0.907c0.298-0.251,0.657-0.489,0.872-0.812c0.776-1.122,1.803-2.006,2.782-2.937
			c0.692-0.657,1.445-1.254,2.089-1.946c1.134-1.218,2.256-2.447,3.641-3.414c0.454-0.31,0.871-0.728,1.182-1.182
			c0.955-1.397,2.232-2.483,3.426-3.641c0.8-0.776,1.612-1.516,2.34-2.376c0.657-0.788,1.409-1.504,2.244-2.125
			c0.442-0.322,0.812-0.764,1.17-1.182c0.418-0.478,0.764-1.027,1.194-1.48c2.34-2.483,4.513-5.134,6.984-7.509
			c0.167-0.167,0.31-0.37,0.454-0.549c1.791-2.232,3.582-4.465,5.36-6.697c0.549-0.68,1.158-1.337,1.588-2.077
			c1.086-1.874,2.65-3.367,3.975-5.05c0.06-0.084,0.251-0.072,0.382-0.107c0.072,0.454-0.215,0.752-0.406,1.074
			c-1.528,2.507-3.068,5.014-4.608,7.509c-0.334,0.537-0.728,1.039-1.11,1.552c-0.895,1.218-1.839,2.4-2.543,3.749
			c-0.107,0.215-0.251,0.406-0.394,0.597c-1.122,1.54-2.221,3.08-3.486,4.525c-1.361,1.552-2.662,3.152-3.88,4.823
			c-1.218,1.659-2.615,3.199-3.94,4.787c-0.251,0.298-0.513,0.621-0.836,0.848c-1.063,0.752-1.791,1.803-2.591,2.782
			c-0.501,0.621-0.955,1.277-1.528,1.815c-1.158,1.086-2.209,2.28-3.247,3.486c-0.716,0.836-1.457,1.671-2.364,2.352
			c-0.501,0.382-1.003,0.824-1.349,1.337c-0.86,1.277-1.982,2.268-3.068,3.319c-0.573,0.549-1.098,1.146-1.636,1.731
			c-1.074,1.17-2.101,2.388-3.391,3.343c-0.442,0.322-0.871,0.728-1.17,1.17c-0.716,1.074-1.612,1.958-2.543,2.841
			c-1.218,1.146-2.364,2.352-3.462,3.605c-0.633,0.716-1.194,1.48-2.018,2.018c-0.322,0.203-0.549,0.573-0.8,0.872
			c-0.501,0.609-0.979,1.254-1.492,1.85c-2.185,2.519-4.393,5.026-6.411,7.688c-0.143,0.191-0.298,0.358-0.466,0.537
			c-2.065,2.316-4.131,4.62-6.136,6.865c-0.095,0.322-0.191,0.549-0.227,0.788c-0.358,2.591-1.612,4.823-2.853,7.032
			c-0.812,1.456-1.707,2.853-2.412,4.369c-0.263,0.573-0.597,1.122-0.907,1.671c-1.516,2.698-3.343,5.205-4.763,7.975
			c-0.358,0.692-0.907,1.301-1.385,1.934c-0.191,0.251-0.442,0.466-0.609,0.728c-1.373,2.149-2.734,4.298-4.083,6.447
			c-0.119,0.191-0.155,0.442-0.203,0.669c-0.012,0.06,0.036,0.155,0.084,0.203s0.155,0.096,0.191,0.072
			c0.263-0.155,0.561-0.298,0.776-0.513c0.907-0.895,1.755-1.85,2.686-2.71c1.349-1.242,2.746-2.412,4.131-3.605
			c0.967-0.836,1.97-1.624,2.901-2.483c0.812-0.752,1.516-1.624,2.34-2.376s1.743-1.385,2.579-2.101
			c0.967-0.824,1.898-1.695,2.841-2.543c0.657-0.585,1.313-1.146,1.982-1.719c1.636-1.385,3.116-2.949,4.835-4.238
			c0.633-0.478,1.182-1.063,1.767-1.6c0.298-0.275,0.573-0.549,0.883-0.8c0.848-0.716,1.707-1.421,2.579-2.125
			c0.8-0.645,1.588-1.337,2.447-1.886c0.955-0.609,1.552-1.588,2.471-2.221c0.322-0.227,0.609-0.513,0.943-0.728
			c1.397-0.919,2.638-2.041,3.94-3.092c0.43-0.346,0.872-0.704,1.325-1.015c1.564-1.086,3.116-2.209,4.298-3.749
			c0.239-0.31,0.561-0.561,0.871-0.812c1.242-1.003,2.423-2.065,3.462-3.271c0.31-0.358,0.645-0.728,1.027-0.991
			c1.206-0.812,2.149-1.898,3.104-2.949c0.585-0.645,1.063-1.385,1.648-2.041c0.633-0.716,1.325-1.373,1.982-2.065
			c0.275-0.287,0.525-0.597,0.752-0.931c0.585-0.86,1.266-1.648,2.053-2.316c0.919-0.788,1.659-1.731,2.304-2.722
			c0.657-1.003,1.385-1.946,2.101-2.889c2.101-2.794,4.238-5.563,6.232-8.429c1.588-2.28,2.973-4.704,4.453-7.056
			c1.564-2.483,2.877-5.122,4.584-7.533c0.501-0.704,0.86-1.528,1.254-2.304C560.486,276.938,561.632,274.586,562.873,272.27
			L562.873,272.27z M554.755,326.745c0.931-1.504,2.077-2.817,3.367-3.999c0.227-0.215,0.418-0.489,0.597-0.74
			c1.492-2.065,3.295-3.88,4.608-6.077c0.119-0.203,0.286-0.382,0.442-0.561c1.445-1.6,2.471-3.51,3.987-5.05
			c0.167-0.167,0.263-0.394,0.406-0.585c0.836-1.158,1.492-2.435,2.256-3.641c1.815-2.889,3.773-5.695,5.456-8.667
			c1.134-2.006,2.412-3.928,3.259-6.089c0.036-0.072,0.072-0.143,0.107-0.215c0.919-1.791,1.361-3.725,1.695-5.695
			c0.024-0.155,0.024-0.322,0.036-0.478c0.024-0.681-0.466-1.277-1.134-1.289c-0.872-0.024-1.767-0.06-2.615,0.06
			c-2.125,0.31-4.238,0.716-6.292,1.349c-0.907,0.286-1.791,0.657-2.71,0.895c-1.385,0.382-2.71,0.895-4.023,1.48
			c-0.872,0.394-1.707,0.776-2.388,1.492c-0.537,0.573-1.206,1.039-1.874,1.468c-1.839,1.206-3.176,2.865-4.322,4.704
			c-0.084,0.131-0.167,0.275-0.251,0.406c-0.752,1.122-1.48,2.268-2.244,3.379c-1.003,1.433-2.03,2.853-3.044,4.274
			c-0.836,1.158-1.647,2.34-2.77,3.247c-0.836,1.265-1.707,2.483-2.734,3.605c-0.107,0.119-0.251,0.203-0.358,0.322
			c-0.585,0.657-1.146,1.313-1.719,1.982c-0.728,0.848-1.468,1.671-2.161,2.543c-1.051,1.301-2.209,2.495-3.426,3.641
			c-1.098,1.039-2.209,2.065-3.247,3.152c-2.197,2.292-4.501,4.477-6.948,6.495c-1.779,1.468-3.677,2.782-5.372,4.358
			c-0.406,0.37-0.895,0.669-1.385,0.907c-0.871,0.43-1.636,1.003-2.292,1.695c-0.501,0.525-1.086,0.919-1.671,1.325
			c-0.919,0.633-1.85,1.242-2.746,1.898c-0.836,0.609-1.588,1.349-2.483,1.85c-0.991,0.561-1.719,1.397-2.626,2.03
			c-0.525,0.37-1.003,0.776-1.492,1.182c-0.681,0.549-1.373,1.086-2.006,1.683c-1.182,1.074-2.412,2.077-3.773,2.913
			c-0.275,0.167-0.549,0.334-0.788,0.525c-1.922,1.54-3.88,3.032-5.742,4.656c-1.266,1.098-2.519,2.173-3.892,3.116
			c-0.322,0.227-0.645,0.466-0.943,0.728c-1.803,1.564-3.844,2.829-5.575,4.489c-0.394,0.382-0.895,0.657-1.349,0.979
			c-0.263,0.179-0.537,0.334-0.776,0.549c-1.576,1.456-3.223,2.841-4.895,4.178c-0.179,0.143-0.298,0.358-0.418,0.549
			c-0.024,0.048,0.012,0.155,0.048,0.203c0.048,0.06,0.107,0.119,0.179,0.143c0.322,0.096,0.621-0.012,0.919-0.143
			c1.624-0.669,3.319-1.134,5.038-1.456c2.268-0.418,4.549-0.764,6.829-1.098c3.164-0.466,6.327-0.645,9.467,0.191
			c0.31,0.084,0.633,0,0.943-0.275c0.095-0.31,0.179-0.704,0.334-1.063c0.239-0.597,0.501-1.206,1.015-1.6
			c1.457-1.122,2.71-2.459,4.083-3.665c1.015-0.895,2.041-1.767,3.08-2.638c1.027-0.872,2.065-1.731,3.092-2.603
			c2.125-1.803,4.262-3.57,6.447-5.301c0.68-0.537,1.397-1.051,2.125-1.54c0.931-0.621,1.767-1.349,2.579-2.113
			c1.97-1.862,4.214-3.379,6.268-5.122c0.478-0.406,1.027-0.728,1.54-1.11c2.495-1.85,4.692-4.047,7.032-6.089
			c1.015-0.883,2.042-1.755,3.009-2.698c1.301-1.277,2.638-2.519,3.689-4.047c0.669-0.979,1.528-1.827,2.316-2.722
			c0.084-0.096,0.286-0.191,0.394-0.155c0.155,0.048,0.167,0.239,0.072,0.406c-0.657,1.182-1.313,2.364-1.982,3.534
			c-0.155,0.275-0.358,0.525-0.549,0.776c-0.943,1.182-1.898,2.352-2.841,3.534c-0.501,0.621-0.967,1.265-1.504,1.862
			c-0.525,0.597-1.122,1.122-1.671,1.707c-0.549,0.573-1.063,1.182-1.612,1.755s-1.134,1.122-1.695,1.671
			c-0.167,0.167-0.358,0.322-0.537,0.478c-0.704,0.633-1.456,1.242-2.113,1.922c-0.943,0.979-1.839,2.03-2.961,2.758
			c-1.23,0.812-2.042,1.994-3.116,2.925c-0.358,0.31-0.752,0.585-1.122,0.895c-1.504,1.301-2.997,2.615-4.501,3.916
			c-0.239,0.203-0.442,0.478-0.704,0.633c-1.277,0.704-2.232,1.779-3.235,2.794c-0.501,0.501-1.039,0.991-1.588,1.445
			c-2.889,2.364-5.754,4.763-8.429,7.378c-1.074,1.051-2.149,2.137-3.737,2.901c-0.573,0.048-1.134,0.072-1.683,0.131
			c-0.334,0.036-0.621,0.251-0.728,0.549c-0.263,0.752-0.513,1.504-0.681,2.28c-0.573,2.734-1.54,5.36-2.662,7.903
			c-1.385,3.116-2.865,6.196-4.549,9.181c-0.358,0.621-0.776,1.182-1.206,1.755c-0.657,0.883-1.11,1.922-1.695,2.877
			c-1.158,1.898-2.089,3.94-3.617,5.599c-0.107,0.107-0.155,0.275-0.239,0.418c-0.143,0.239-0.143,0.633,0.012,0.633
			c0.227-0.012,0.466-0.036,0.669-0.119c0.215-0.096,0.394-0.263,0.561-0.43c1.254-1.218,2.495-2.447,3.749-3.677
			c0.286-0.275,0.549-0.585,0.871-0.8c0.812-0.525,1.457-1.218,2.089-1.946c0.525-0.597,1.063-1.182,1.636-1.731
			c1.194-1.17,2.423-2.304,3.629-3.462c0.513-0.501,0.955-1.086,1.516-1.516c1.54-1.158,2.794-2.591,4.047-4.023
			c0.215-0.239,0.43-0.466,0.669-0.68c1.039-0.979,2.089-1.958,3.14-2.937c0.525-0.49,0.979-1.074,1.564-1.468
			c0.681-0.454,1.194-1.063,1.719-1.648c1.504-1.636,3.056-3.235,4.668-4.763c1.206-1.146,2.268-2.459,3.641-3.426
			c0.191-0.131,0.322-0.346,0.49-0.525c1.182-1.289,2.34-2.603,3.558-3.856c1.492-1.54,2.997-3.08,4.572-4.537
			c0.704-0.657,1.349-1.337,1.946-2.089c1.039-1.301,2.077-2.615,3.188-3.856c1.85-2.077,3.749-4.107,5.623-6.16
			c0.645-0.704,1.301-1.397,1.886-2.149c1.027-1.325,2.149-2.555,3.343-3.713c0.907-0.883,1.683-1.898,2.471-2.901
			c0.776-0.979,1.839-1.743,2.638-2.746c1.337-1.671,2.734-3.319,4.262-4.823C554.349,327.235,554.588,327.008,554.755,326.745
			L554.755,326.745z M552.654,340.45c-0.537,0.131-1.074,0.287-1.516,0.406c-0.263,0.227-0.454,0.37-0.633,0.525
			c-1.074,0.955-1.91,2.137-3.08,2.985c-0.191,0.131-0.31,0.358-0.454,0.549c-0.812,1.086-1.803,2.006-2.758,2.961
			s-1.922,1.898-2.794,2.937c-2.053,2.423-4.322,4.668-6.506,6.972c-0.704,0.752-1.516,1.409-2.244,2.125
			c-1.027,1.003-2.018,2.03-3.044,3.02c-0.513,0.49-1.098,0.907-1.6,1.409c-2.328,2.4-5.086,4.322-7.378,6.769
			c-0.06,0.06-0.119,0.119-0.179,0.155c-1.242,0.752-2.221,1.791-3.223,2.806c-0.394,0.394-0.812,0.764-1.242,1.122
			c-1.588,1.325-3.176,2.638-4.775,3.952c-0.74,0.609-1.504,1.17-2.221,1.791c-0.836,0.728-1.624,1.528-2.471,2.244
			c-1.457,1.242-2.853,2.567-4.429,3.617c-1.803,1.206-3.355,2.662-4.954,4.095c-0.418,0.37-0.824,0.776-1.301,1.051
			c-0.907,0.525-1.612,1.289-2.388,1.958c-0.119,0.107-0.239,0.215-0.31,0.358c-0.06,0.119-0.084,0.322-0.012,0.418
			c0.072,0.107,0.298,0.203,0.406,0.167c1.098-0.466,2.221-0.883,3.247-1.468c1.385-0.8,2.829-1.445,4.25-2.173
			c0.418-0.215,0.836-0.466,1.242-0.704c1.647-0.967,3.235-2.018,4.883-2.973c2.328-1.349,4.847-2.4,7.02-4.011
			c2.077-1.003,3.82-2.579,5.91-3.57c2.973-1.994,6.065-3.796,9.193-5.504c0.275-0.155,0.525-0.358,0.776-0.549
			c2.316-1.683,4.835-3.044,7.151-4.728c0.633-0.466,1.433-0.704,2.101-1.122c1.349-0.848,2.638-1.767,3.987-2.626
			c1.946-1.242,3.641-2.829,5.742-3.82c0.358-0.167,0.657-0.442,0.967-0.692c2.173-1.755,4.584-3.14,6.912-4.668
			c1.074-0.704,2.125-1.397,3.008-2.328c0.215-0.227,0.478-0.406,0.728-0.609c0.167-0.131,0.298-0.095,0.418,0.119
			c-0.752,1.409-1.91,2.483-3.164,3.474c-3.128,2.459-6.196,4.99-9.145,7.653c-0.239,0.215-0.466,0.454-0.74,0.597
			c-1.206,0.633-2.173,1.6-3.283,2.364c-1.182,0.8-2.28,1.719-3.402,2.603c-2.053,1.624-4.298,2.997-6.315,4.692
			c-1.337,0.704-2.531,1.648-3.785,2.495c-1.051,0.716-2.232,1.254-3.223,2.018c-1.707,1.301-3.486,2.495-5.253,3.701
			c-1.313,0.895-2.531,1.934-4.011,2.543c-0.286,0.119-0.549,0.334-0.812,0.513c-2.161,1.48-4.501,2.698-6.59,4.298
			c-0.119,0.096-0.251,0.203-0.394,0.275c-2.507,1.23-4.751,2.877-7.199,4.214c-0.704,0.382-1.397,0.764-2.113,1.11
			c-2.292,1.11-4.62,2.137-6.865,3.319c-1.421,0.752-2.829,1.421-4.441,1.6c-0.143,0.012-0.275,0.143-0.406,0.227
			c-0.155,0.084-0.203,0.537-0.072,0.645c0.119,0.096,0.239,0.251,0.37,0.275c1.504,0.179,2.937,0.621,4.381,1.063
			c0.072,0.024,0.155,0.024,0.239,0.048c3.116,0.621,6.232,1.265,9.324,2.006c2.006,0.478,4.035,0.895,6.041,1.361
			c0.692,0.167,1.385,0.358,2.053,0.609c0.179,0.06,0.275,0.346,0.418,0.537c-0.549,1.146-1.373,1.97-2.364,2.674
			c-0.645,0.454-1.277,0.967-1.767,1.588c-1.349,1.671-2.853,3.211-4.298,4.799c-1.325,1.468-2.412,3.152-4.119,4.25
			c-0.191,0.131-0.346,0.334-0.501,0.513c-1.242,1.456-2.638,2.758-4.059,4.035c-0.418,0.37-0.883,0.704-1.218,1.146
			c-1.122,1.456-2.531,2.615-3.856,3.856c-1.385,1.301-2.662,2.722-3.987,4.095c-0.048,0.048-0.072,0.143-0.096,0.215
			c0.107,0.084,0.227,0.239,0.31,0.227c0.8-0.155,1.445-0.657,2.089-1.11c2.531-1.779,5.098-3.534,7.7-5.205
			c0.263-0.167,0.549-0.346,0.752-0.585c0.943-1.122,2.161-1.874,3.391-2.615c1.301-0.788,2.543-1.636,3.725-2.579
			c1.982-1.6,4.178-2.901,5.969-4.74c1.695-0.907,3.044-2.292,4.62-3.367c1.051-0.716,2.042-1.504,2.961-2.4
			c0.513-0.49,1.051-1.003,1.671-1.349c1.063-0.597,1.958-1.385,2.758-2.268c0.872-0.943,1.874-1.719,2.841-2.543
			c2.865-2.4,5.826-4.668,8.214-7.593c0.096-0.119,0.203-0.251,0.322-0.346c1.552-1.254,2.853-2.77,4.31-4.119
			c1.289-1.182,2.471-2.459,3.546-3.844c0.824-1.063,1.647-2.149,2.567-3.14c0.919-0.991,1.791-2.018,2.615-3.092
			c0.286-0.37,0.585-0.752,0.931-1.074c0.776-0.692,1.409-1.504,1.994-2.364c0.49-0.716,1.063-1.385,1.588-2.089
			c1.624-2.161,3.629-4.011,5.038-6.339c0.119-0.203,0.287-0.394,0.466-0.549c0.955-0.86,1.659-1.922,2.435-2.925
			c1.218-1.576,2.447-3.128,3.546-4.787c1.003-1.528,2.244-2.889,3.188-4.465c0.167-0.275,0.406-0.49,0.609-0.728
			c1.325-1.492,2.412-3.152,3.402-4.883c0.895-1.588,1.862-3.152,2.818-4.704c0.37-0.609,0.812-1.182,1.194-1.779
			c0.633-1.003,1.122-2.101,1.755-3.116c1.218-1.958,2.292-3.987,3.391-6.017c1.11-2.041,1.791-4.25,2.71-6.363
			c0.096-0.215,0.131-0.466,0.167-0.692c0.024-0.155-0.012-0.31-0.024-0.478c-0.012-0.263-0.418-0.633-0.657-0.573
			c-0.764,0.191-1.552,0.346-2.292,0.621c-2.531,0.931-5.062,1.898-7.569,2.889c-0.883,0.346-1.815,0.669-2.579,1.218
			c-0.943,0.669-1.958,1.015-3.032,1.301c-0.836,0.227-1.671,0.501-2.495,0.812C559.829,338.373,556.283,339.543,552.654,340.45
			L552.654,340.45z M217.256,494.958c-0.155,0.442-0.358,0.883-0.585,1.301c-0.298,0.561-0.657,1.086-0.979,1.636
			c-0.907,1.564-2.209,2.734-3.761,3.582c-1.11,0.609-2.328,1.015-3.593,1.206c-2.424,0.382-4.763,0.143-6.996-0.907
			c-0.573-0.275-1.122-0.585-1.707-0.86c-1.468-0.704-2.674-1.695-3.641-3.008c-0.466-0.645-1.003-1.23-1.516-1.839
			c-0.251-0.298-0.812-0.215-1.015,0.155c-0.31,0.561-0.633,1.11-0.872,1.695c-0.883,2.089-2.352,3.773-3.82,5.444
			c-0.573,0.657-1.265,1.206-1.85,1.862c-1.397,1.564-3.211,2.579-4.919,3.737c-0.251,0.167-0.585,0.251-0.883,0.334
			c-0.143,0.036-0.298-0.024-0.549-0.048c-0.478-0.692-0.633-1.54-0.788-2.4c-0.131-0.776,0.06-1.6-0.334-2.34
			c-0.167,0-0.346-0.06-0.442,0.012c-0.513,0.37-1.003,0.776-1.301,1.373c-0.836,1.648-2.077,3.008-3.2,4.441
			c-1.027,1.313-2.077,2.615-3.2,3.856c-1.277,1.421-2.447,2.925-3.737,4.334c-0.812,0.883-1.707,1.671-2.244,2.758
			c-1.325,1.254-2.304,2.841-3.725,4.011c-0.37,0.298-0.728,0.645-0.991,1.027c-1.277,1.839-2.901,3.379-4.358,5.062
			c-0.203,0.239-0.406,0.501-0.657,0.657c-0.155,0.096-0.418,0.012-0.597,0c-0.084-0.143-0.143-0.203-0.155-0.275
			c-0.215-1.576-0.513-3.152-0.621-4.728c-0.275-4.286-0.525-8.584-0.203-12.882c0.06-0.788,0.084-1.588,0.096-2.388
			c0.036-1.839,0.525-3.617,0.704-5.444c0.967-2.03,1.027-4.322,1.85-6.399c0.788-1.994,1.504-4.023,2.256-6.029
			c0.334-0.895,0.728-1.767,1.063-2.662c0.107-0.286,0.143-0.621-0.191-0.86c-0.645-0.036-1.23,0.227-1.815,0.466
			c-1.11,0.454-2.232,0.836-3.379,1.182c-1.051,0.31-2.137,0.609-3.068,1.265c-1.874,0.657-3.749,1.337-5.635,1.958
			c-2.949,0.979-5.91,1.874-8.834,2.901c-0.907,0.322-1.862,0.382-2.758,0.74c-0.812,0.334-1.624,0.645-2.459,0.895
			c-0.764,0.227-1.54,0.394-2.316,0.549c-0.943,0.191-1.898,0.227-2.853,0.131c-0.633-0.06-1.015-0.394-1.218-0.979
			c-0.107-0.298-0.239-0.597-0.287-0.907c-0.131-0.8-0.489-1.516-0.74-2.268c-0.358-1.051-0.836-2.053-1.015-3.176
			c-0.143-0.86-0.597-1.624-0.752-2.495c-0.358-2.041-0.573-4.095-0.597-6.172c-0.036-2.937-0.107-5.886,0.418-8.799
			c0.024-0.119-0.131-0.263-0.203-0.406c-0.155,0.024-0.334,0-0.454,0.072c-1.468,0.836-3.056,1.313-4.704,1.659
			c-2.734,0.573-5.468,1.003-8.273,0.991c-2.865-0.012-5.73-0.012-8.584-0.358c-2.292-0.287-4.596-0.442-6.865-0.836
			c-1.409-0.239-2.829-0.358-4.25-0.609c-1.815-0.322-3.57-0.764-5.289-1.409c-1.039-0.382-1.982-0.979-3.056-1.277
			c-1.003-0.287-1.946-0.681-2.877-1.146c-0.358-0.179-0.74-0.286-1.11-0.43c-1.397-0.573-2.794-1.134-4.107-1.898
			c-1.17-0.669-2.412-1.23-3.617-1.827c-0.919-0.466-1.85-0.895-2.722-1.468c-1.11-0.74-2.555-0.919-3.462-2.03
			c-1.158-0.692-2.316-1.397-3.486-2.077s-2.364-1.313-3.534-1.982c-1.242-0.704-2.483-1.409-3.713-2.161
			c-1.361-0.824-2.734-1.636-3.88-2.77c-0.287-0.275-0.585-0.561-0.931-0.74c-1.039-0.525-1.671-1.456-2.388-2.292
			c-0.406-0.466-0.704-1.074-0.883-1.671c-0.43-1.456-0.287-2.794,0.883-3.952c0.812-0.812,1.648-1.445,2.758-1.779
			c1.6-0.466,3.176-0.991,4.692-1.719c1.206-0.573,2.507-1.027,3.844-1.242c0.872-0.143,1.695-0.442,2.459-0.872
			c0.107-0.06,0.155-0.251,0.263-0.442c-0.191-0.215-0.37-0.478-0.609-0.68c-0.669-0.573-1.337-1.134-2.03-1.659
			c-0.764-0.573-1.588-1.063-2.077-1.922c-0.107-0.191-0.334-0.346-0.525-0.489c-1.361-1.003-2.364-2.352-3.522-3.546
			c-0.167-0.167-0.358-0.334-0.466-0.537c-0.716-1.373-1.934-2.304-2.937-3.426c-0.155-0.179-0.406-0.31-0.478-0.525
			c-0.382-1.277-1.433-2.089-2.137-3.14c-0.657-0.991-1.122-2.113-2.018-2.949c-0.669-1.265-1.528-2.4-2.173-3.689
			c-0.812-1.636-1.85-3.14-2.782-4.716c-0.728-1.23-1.433-2.471-2.125-3.725c-0.191-0.346-0.275-0.74-0.37-1.134
			c-0.275-1.17-0.74-2.268-1.325-3.307c-0.513-0.907-0.883-1.862-1.23-2.841c-1.003-2.853-1.958-5.719-3.128-8.512
			c-0.752-1.779-1.134-3.641-1.134-5.575c0-0.31,0.048-0.633,0.084-0.943c0.048-0.382,0.406-0.704,0.812-0.752
			c1.194-0.131,2.388-0.096,3.558,0.155c0.931,0.203,1.839,0.466,2.746,0.8c1.791,0.669,3.546,1.421,5.181,2.388
			c1.373,0.812,2.841,1.421,4.322,1.994c0.955,0.37,1.922,0.74,2.817,1.086c0.334-0.143,0.394-0.346,0.346-0.597
			c-0.096-0.418-0.358-0.716-0.669-0.967c-0.489-0.406-0.86-0.907-1.254-1.409c-1.265-1.624-2.424-3.355-3.904-4.811
			c-0.454-0.442-0.836-0.955-1.23-1.468c-1.015-1.325-1.994-2.674-3.008-3.999c-0.43-0.561-0.931-1.074-1.409-1.612
			c-0.68-0.776-1.504-1.433-1.886-2.435c-0.06-0.143-0.155-0.275-0.263-0.394c-2.065-2.221-3.605-4.847-5.468-7.211
			c-0.394-0.501-0.74-1.039-1.134-1.528c-0.907-1.11-1.719-2.28-2.471-3.498c-0.418-0.669-0.895-1.301-1.349-1.958
			c-0.131-0.191-0.298-0.394-0.358-0.609c-0.287-0.943-0.872-1.683-1.456-2.447c-0.633-0.824-1.23-1.671-1.815-2.519
			c-0.275-0.394-0.561-0.788-0.752-1.218c-0.669-1.528-1.612-2.913-2.268-4.465c-0.525-1.242-1.289-2.376-1.946-3.558
			c-0.227-0.418-0.525-0.812-0.669-1.254c-0.597-1.827-1.648-3.438-2.292-5.241c-0.86-1.17-1.289-2.555-1.803-3.88
			c-0.525-1.325-0.931-2.698-1.373-4.071c-0.287-0.919-0.358-1.862-0.358-2.829c0-1.015,0.382-1.874,1.039-2.603
			c0.418-0.466,0.943-0.848,1.361-1.218c1.098-0.31,2.125-0.298,3.152-0.131c0.466,0.072,0.931,0.215,1.361,0.418
			c1.588,0.74,3.211,1.385,4.823,2.065c1.683,0.704,3.319,1.54,4.919,2.423c1.325,0.716,2.65,1.433,3.94,2.221
			c1.289,0.776,2.71,1.325,4.083,1.97c0.501,0.239,1.039,0.418,1.361,0.919c0.048,0.06,0.107,0.131,0.179,0.155
			c1.671,0.716,3.271,1.648,5.014,2.185c0.107,0.036,0.334-0.06,0.394-0.167c0.072-0.107,0.024-0.298,0.012-0.442
			c0-0.072-0.06-0.143-0.096-0.215s-0.084-0.131-0.131-0.203c-0.537-0.68-1.086-1.373-1.636-2.053
			c-0.251-0.31-0.489-0.645-0.776-0.907c-1.313-1.17-2.483-2.459-3.402-3.964c-0.167-0.263-0.394-0.501-0.633-0.716
			c-1.194-1.17-2.292-2.423-3.152-3.88c-0.084-0.131-0.191-0.251-0.287-0.382c-1.277-1.624-2.591-3.223-3.82-4.883
			c-1.134-1.528-2.376-2.985-3.176-4.74c-0.263-0.573-0.68-1.063-1.063-1.588c-1.254-1.743-2.555-3.45-3.617-5.325
			c-0.31-0.549-0.573-1.134-0.86-1.695c-0.633-1.194-1.254-2.4-1.922-3.57c-1.11-1.934-2.125-3.916-2.889-6.005
			c-0.167-0.454-0.334-0.895-0.573-1.313c-1.218-2.089-1.827-4.405-2.507-6.686c-0.573-1.922-0.513-3.928-0.263-5.921
			c0.084-0.645,0.43-1.206,0.776-1.731c0.872-1.373,2.065-2.4,3.617-2.961c1.17-0.43,2.364-0.43,3.534-0.155
			c1.301,0.31,2.662,0.489,3.844,1.242c0.191,0.131,0.442,0.179,0.669,0.263c1.516,0.478,2.961,1.122,4.405,1.791
			c1.445,0.669,2.913,1.265,4.202,2.232c0.382,0.287,0.788,0.561,1.289,0.573c0.131,0.012,0.275-0.096,0.394-0.179
			c0.048-0.036,0.072-0.155,0.048-0.215c-0.155-0.442-0.263-0.931-0.501-1.325c-0.824-1.361-1.54-2.782-2.209-4.226
			c-0.669-1.445-1.48-2.817-2.221-4.214c-0.86-1.612-1.719-3.223-2.4-4.919c-0.298-0.728-0.716-1.409-1.074-2.125
			c-0.179-0.346-0.394-0.692-0.478-1.074c-0.251-1.015-0.716-1.946-1.23-2.841c-0.919-1.588-1.636-3.271-2.459-4.907
			c-1.182-2.34-2.161-4.775-3.032-7.259c-0.179-0.525-0.334-1.074-0.585-1.564c-1.063-2.053-1.767-4.25-2.543-6.423
			c-0.239-0.669-0.43-1.361-0.633-2.053c-0.334-1.063-0.573-2.149-1.086-3.152c-0.107-0.215-0.203-0.43-0.263-0.669
			c-0.776-3.092-1.516-6.184-1.612-9.396c-0.06-2.006,0.119-3.976,0.991-5.826c0.072-0.143,0.131-0.287,0.215-0.43
			c0.37-0.704,1.051-0.943,1.827-0.645c1.421,0.549,2.746,1.313,3.88,2.304c1.385,1.218,2.961,2.137,4.429,3.223
			c1.277,0.943,2.579,1.862,3.904,2.734c2.4,1.576,4.525,3.498,6.805,5.217c1.206,0.907,2.304,1.958,3.498,2.877
			c1.194,0.907,2.507,1.671,3.641,2.686c0.119,0.107,0.263,0.179,0.382,0.287c0.728,0.728,1.433,1.48,2.197,2.185
			c0.704,0.645,1.504,1.182,2.197,1.827c1.039,0.979,2.101,1.922,3.259,2.77c1.731,1.254,3.402,2.626,4.763,4.31
			c0.155,0.179,0.322,0.346,0.513,0.501c1.994,1.588,3.904,3.247,5.826,4.931c1.015,0.883,2.101,1.695,3.068,2.638
			c0.346,0.334,0.716,0.645,1.015,1.015c0.752,0.943,1.648,1.707,2.662,2.364c0.609,0.382,1.134,0.848,1.552,1.456
			c0.263,0.382,0.633,0.704,0.991,1.015c1.433,1.265,2.865,2.519,4.322,3.761c0.669,0.573,0.955,1.456,1.719,1.958
			c0.657,0.442,1.254,0.979,1.827,1.528c1.086,1.051,2.125,2.149,3.188,3.223c0.06,0.06,0.096,0.131,0.155,0.179
			c1.707,1.313,3.02,3.032,4.549,4.525c1.254,1.218,2.388,2.555,3.582,3.82c0.704,0.752,1.445,1.48,2.161,2.221
			c0.107,0.107,0.251,0.203,0.358,0.322c0.86,1.027,1.719,2.041,2.77,2.913c1.039,0.86,1.97,1.874,2.782,2.937
			c0.931,1.218,2.053,2.209,3.128,3.259c0.227,0.227,0.442,0.454,0.657,0.692c0.645,0.692,1.146,1.504,1.922,2.101
			c0.752,0.573,1.361,1.325,2.041,1.994c0.74,0.728,1.445,1.468,2.101,2.28c0.848,1.051,1.767,2.053,2.746,2.973
			c0.585,0.549,1.027,1.218,1.647,1.695c0.645,0.501,1.027,1.218,1.6,1.755c1.516,1.421,2.817,3.02,4.214,4.549
			c0.585,0.645,1.17,1.301,1.683,2.006c0.561,0.776,1.17,1.528,1.898,2.125c0.633,0.513,1.015,1.122,1.23,1.862
			c0.203-0.084,0.406-0.167,0.645-0.275c0.179-0.919-0.358-1.671-0.525-2.507c-0.155-0.776-0.251-1.576-0.37-2.352
			c-0.131-0.872-0.167-1.731-0.096-2.782c0.263,0.669,0.525,1.158,0.645,1.683c0.442,1.874,1.456,3.522,2.018,5.348
			c0.179,0.597,0.597,1.11,0.931,1.659c0.704,1.146,1.445,2.28,2.149,3.438c1.039,1.695,2.053,3.402,3.068,5.11
			c0.084,0.131,0.155,0.286,0.263,0.406c1.433,1.719,2.483,3.689,3.749,5.516c0.68,0.979,1.397,1.922,2.006,2.949
			c0.239,0.406,0.585,0.728,1.003,1.003c0.609,0.406,1.027,0.979,1.433,1.576c0.537,0.788,1.146,1.528,1.731,2.28
			c0.191,0.251,0.478,0.454,0.621,0.728c0.824,1.576,2.221,2.698,3.259,4.095c0.561,0.752,1.17,1.504,2.018,2.006
			c0.131,0.084,0.263,0.191,0.358,0.31c1.039,1.504,2.555,2.615,3.522,4.178c0.251,0.406,0.561,0.776,0.895,1.11
			c1.277,1.301,2.435,2.722,3.832,3.904c0.298,0.251,0.609,0.525,0.824,0.836c0.454,0.669,1.027,1.194,1.6,1.755
			c0.86,0.824,1.612,1.755,2.412,2.638c1.015,1.122,1.946,2.34,3.056,3.343c1.182,1.063,2.221,2.256,3.391,3.331
			c0.645,0.597,1.289,1.158,1.767,1.898c0.215,0.322,0.537,0.585,0.824,0.848c1.277,1.182,2.579,2.364,3.856,3.546
			c1.397,1.289,2.841,2.531,4.107,3.964c0.37,0.418,0.752,0.836,1.206,1.146c0.812,0.549,1.242,1.445,1.994,2.041
			c0.68,0.549,1.445,0.979,2.089,1.564c1.063,0.955,2.053,1.994,3.08,2.985c0.454,0.442,0.943,0.848,1.397,1.289
			c0.848,0.836,1.671,1.707,2.519,2.543c0.513,0.501,1.003,1.039,1.588,1.445c2.053,1.409,3.737,3.211,5.48,4.954
			c1.516,1.516,2.997,3.068,4.56,4.537c1.743,1.647,3.259,3.462,4.417,5.587c0.919,1.671,1.898,3.307,2.782,5.002
			c0.728,1.409,1.361,2.877,1.659,4.453c0.203,1.098,0.442,2.185,0.669,3.271c0.048,0.239,0.072,0.478,0.179,0.692
			c0.573,1.182,0.609,2.459,0.609,3.737c0.012,2.782,0,5.575-0.024,8.357c-0.012,1.122-0.084,2.221-0.561,3.271
			c-0.191,0.43-0.227,0.919-0.322,1.385c-0.298,1.48-0.669,2.949-1.194,4.369c-0.585,1.564-1.11,3.14-1.731,4.692
			c-0.86,2.137-1.588,4.322-2.089,6.59c-0.43,1.934-1.039,3.832-1.552,5.754c-0.251,0.919-0.489,1.85-0.669,2.782
			c-0.155,0.788-0.346,1.54-0.609,2.304c-0.275,0.824-0.442,1.695-0.597,2.555c-0.43,2.34-1.063,4.656-1.122,7.056
			c-0.012,0.633-0.096,1.265-0.191,1.898c-0.275,1.827-0.287,3.653-0.155,5.48c0.107,1.588,0.275,3.176,0.478,4.751
			c0.203,1.516,0.716,2.937,1.492,4.25c0.203,0.346,0.478,0.645,0.633,1.003c0.382,0.836,1.051,1.385,1.743,1.922
			c2.221,1.683,6.22,2.065,8.966,0.06c0.454-0.334,0.86-0.704,1.17-1.182c0.872-1.361,1.456-2.829,1.755-4.405
			c0.191-0.955,0.107-1.91-0.263-2.818c-0.275-0.657-0.573-1.313-0.824-1.982c-0.036-0.096,0.084-0.275,0.155-0.394
			c0.024-0.036,0.155-0.036,0.227-0.024c0.155,0.036,0.31,0.084,0.442,0.155c1.85,1.134,3.116,2.722,3.45,4.895
			C217.912,491.352,217.876,493.191,217.256,494.958L217.256,494.958z M180.963,507.612c0,0.179,0.382,0.418,0.537,0.346
			c0.215-0.107,0.466-0.179,0.621-0.334c1.194-1.265,2.388-2.543,3.558-3.832c0.215-0.227,0.394-0.501,0.549-0.776
			c0.597-1.134,1.433-2.089,2.304-3.02c0.919-0.991,1.815-1.994,2.734-2.973c0.812-0.883,1.492-1.85,2.006-2.973
			c-0.072-0.382-0.096-0.776-0.215-1.146c-0.525-1.671-0.824-3.391-1.063-5.122c-0.024-0.155-0.107-0.298-0.179-0.43
			c-0.036-0.06-0.119-0.155-0.167-0.143c-0.155,0.012-0.334,0.036-0.43,0.119c-0.167,0.155-0.31,0.358-0.43,0.561
			c-0.322,0.549-0.585,1.134-0.931,1.659c-0.43,0.669-0.931,1.277-1.361,1.934c-0.478,0.728-0.895,1.48-1.349,2.221
			c-0.036,0.048-0.179,0.107-0.203,0.084c-0.107-0.084-0.251-0.203-0.251-0.322c-0.012-0.31-0.012-0.645,0.072-0.943
			c0.609-2.4,1.433-4.704,2.758-6.817c0.43-0.68,0.8-1.373,0.943-2.161c0.036-0.203-0.084-0.43-0.131-0.645
			c-0.549,0.096-0.931,0.346-1.265,0.704c-0.275,0.286-0.549,0.573-0.848,0.812c-1.11,0.907-2.221,1.791-3.319,2.674
			c-0.537,1.277-1.003,2.292-1.027,3.438c-0.036,1.839-0.382,3.629-0.704,5.42c-0.215,1.254-0.394,2.519-0.716,3.737
			c-0.609,2.304-1.015,4.656-1.468,6.996C180.903,506.968,180.951,507.302,180.963,507.612L180.963,507.612z M183.41,474.638
			c0.107-0.382,0.215-0.776,0.43-1.11c0.943-1.468,1.91-2.925,2.901-4.358c0.215-0.322,0.501-0.633,0.824-0.86
			c1.397-1.039,2.746-2.173,4.477-2.65c0.215-0.06,0.466-0.179,0.609-0.346c0.143-0.167,0.203-0.418,0.215-0.645
			c0.024-0.239-0.096-0.466-0.334-0.573c-0.215-0.095-0.442-0.203-0.669-0.203c-1.743-0.012-3.498-0.06-5.241,0.048
			c-1.421,0.084-2.817,0.394-4.214,0.848c-0.478,0.752-0.991,1.456-1.397,2.232c-0.704,1.325-1.325,2.698-1.994,4.047
			c-0.107,0.215-0.191,0.442-0.346,0.621c-0.967,1.146-1.277,2.531-1.516,3.952c-0.155,0.943-0.215,1.886-0.657,2.77
			c-0.167,0.346-0.215,0.764-0.263,1.158c-0.489,4.023-0.621,8.082-0.812,12.129c0,0.095,0.191,0.203,0.322,0.275
			c0.048,0.036,0.179,0,0.215-0.036c0.382-0.406,0.895-0.752,1.086-1.23c0.501-1.23,1.409-2.077,2.376-2.913
			c0.418-0.37,0.824-0.752,1.194-1.17c0.74-0.836,1.683-1.468,2.435-2.268c0.788-0.836,1.791-1.289,2.734-1.862
			c0.943-0.585,1.946-1.086,2.901-1.648c0.621-0.358,1.23-0.728,1.803-1.158c0.8-0.597,1.277-1.397,1.289-2.435
			c0.012-0.549,0.084-1.11,0.107-1.659c0.06-1.671,0.155-3.331,0.418-4.99c0.096-0.621,0.084-1.266,0.084-1.898
			c0-0.096-0.179-0.215-0.298-0.287c-0.048-0.036-0.179-0.012-0.227,0.024c-0.334,0.322-0.68,0.657-1.003,1.003
			c-0.537,0.585-0.895,1.301-1.54,1.803c-1.325,1.051-2.34,2.352-3.2,3.808c-0.776,1.313-1.815,2.412-3.032,3.319
			c-0.203,0.155-0.418-0.024-0.549-0.358C182.813,476.943,183.1,475.785,183.41,474.638L183.41,474.638z M158.805,506.908
			c-0.143,0.454-0.227,0.931-0.31,1.397c-0.251,1.492-0.501,2.973-0.776,4.465c-0.573,3.128-1.122,6.268-1.277,9.455
			c-0.024,0.478-0.143,0.943-0.179,1.421c-0.119,1.827-0.239,3.653-0.334,5.48c-0.012,0.131,0.119,0.275,0.215,0.394
			c0.036,0.048,0.155,0.036,0.239,0.06c0.537-0.358,0.883-0.895,1.218-1.433c1.158-1.803,2.316-3.605,3.486-5.408
			c0.084-0.131,0.143-0.298,0.251-0.406c1.134-1.134,1.874-2.567,2.877-3.796c0.454-0.549,1.003-1.051,1.349-1.659
			c0.669-1.206,1.636-2.137,2.543-3.14c0.43-0.478,0.836-0.955,1.122-1.528c0.394-0.788,0.943-1.468,1.576-2.077
			c0.334-0.322,0.609-0.728,0.919-1.086c0.621-0.728,1.23-1.456,1.874-2.161c1.445-1.588,2.997-3.056,4.059-4.954
			c0.155-0.275,0.334-0.561,0.573-0.764c0.943-0.776,1.504-1.827,2.221-2.853c0.346-2.065,0.716-4.19,1.063-6.303
			c0.036-0.227,0.012-0.478-0.06-0.704c-0.036-0.107-0.215-0.179-0.394-0.31c-1.254,0.919-2.495,1.91-3.629,3.008
			c-0.645,0.621-1.361,1.086-2.125,1.504c-0.382,0.215-0.8,0.012-0.895-0.442c-0.131-0.621-0.215-1.254-0.37-1.862
			c-0.048-0.191-0.287-0.334-0.43-0.489c-0.155,0.131-0.31,0.215-0.382,0.346c-0.991,1.624-1.886,3.319-3.104,4.799
			c-0.346,0.43-0.573,0.955-0.86,1.421c-0.406,0.68-0.812,1.361-1.242,2.03c-0.895,1.409-1.862,2.77-2.424,4.358
			c-0.322,0.907-0.883,1.683-1.528,2.388c-0.024,0.036-0.131,0-0.203-0.012c-0.048-0.072-0.119-0.131-0.119-0.191
			c0-0.633-0.012-1.266,0.024-1.898c0.072-1.445,0.394-2.841,0.931-4.166c0.442-1.11,0.907-2.197,1.23-3.355
			c0.215-0.776,0.573-1.456,0.991-2.149c0.979-1.636,2.077-3.2,2.71-5.026c0.179-0.513,0.501-1.003,0.836-1.445
			c0.501-0.669,1.17-1.063,2.03-1.063c0.382,0,0.788,0.155,1.122-0.155c0.287-0.346,0.227-0.752,0.227-1.146
			c-0.036-1.349-0.072-2.698-0.119-4.059c-0.012-0.239-0.048-0.478-0.072-0.704c-0.036-0.251-0.561-0.478-0.776-0.334
			c-0.191,0.131-0.418,0.251-0.561,0.418c-0.836,0.967-1.803,1.767-2.865,2.471c-0.8,0.537-1.588,1.039-2.447,1.48
			c-0.991,0.513-1.97,1.039-2.925,1.588c-0.573,0.334-0.979,0.824-1.086,1.492c-0.155,1.027-0.31,2.041-0.466,3.068
			c-0.096,0.633-0.107,1.289-0.334,1.874c-0.466,1.194-0.68,2.447-1.051,3.665C160.25,501.213,159.689,504.103,158.805,506.908
			L158.805,506.908z M124.506,490.779c0.155,0.943,0.322,1.886,0.442,2.829c0.251,1.898,0.585,3.773,1.063,5.623
			c0.084,0.346,0.561,0.645,0.931,0.597c0.872-0.084,1.695-0.334,2.507-0.704c2.244-0.991,4.405-2.209,6.793-2.877
			c0.155-0.048,0.298-0.107,0.442-0.191c1.337-0.716,2.758-1.23,4.178-1.755c0.597-0.215,1.218-0.442,1.731-0.8
			c0.991-0.704,2.149-1.003,3.259-1.433c0.967-0.37,1.946-0.704,2.913-1.039c2.638-0.883,5.205-1.946,7.891-2.698
			c1.23-0.346,2.424-0.764,3.605-1.242c0.513-0.215,1.039-0.406,1.576-0.561c1.313-0.37,2.543-0.907,3.773-1.468
			c1.23-0.561,2.424-1.17,3.534-1.934c0.394-0.275,0.788-0.561,1.218-0.752c1.421-0.633,2.495-1.659,3.438-2.746
			c0.107-0.322,0.215-0.537,0.263-0.764c0.334-1.313,0.669-2.615,0.967-3.94c0.263-1.182,0.68-2.292,1.349-3.295
			c0.442-0.669,0.812-1.361,1.098-2.101c0.263-0.669,0.657-1.242,1.218-1.731c0.466-0.418,0.836-0.955,1.218-1.456
			c0.084-0.107,0.084-0.286,0.119-0.442c-0.752-0.263-0.872-0.287-1.361-0.179c-4.202,0.872-8.321,2.101-12.44,3.271
			c-1.934,1.552-3.57,3.414-5.623,4.799c-1.051,1.23-2.435,2.018-3.808,2.806c-2.471,1.433-5.014,2.746-7.521,4.119
			c-2.304,1.265-4.811,2.077-7.056,3.438c-0.466,0.287-1.039,0.382-1.576,0.549c-0.68,0.203-1.373,0.382-2.065,0.585
			c-0.991,0.298-1.958,0.633-2.961,0.907c-1.922,0.537-3.773,1.23-5.42,2.4c-0.454,0.322-0.931,0.609-1.552,1.015
			c-0.131-0.609,0.06-0.883,0.263-1.134c1.051-1.313,2.34-2.376,3.737-3.295c0.334-0.215,0.752-0.334,1.027-0.597
			c0.824-0.788,1.839-1.194,2.853-1.624c0.943-0.406,1.85-0.931,2.806-1.313c1.337-0.537,2.638-1.11,3.808-1.958
			c0.191-0.131,0.406-0.251,0.621-0.358c1.946-0.907,3.832-1.922,5.719-2.949c1.182-0.657,2.376-1.265,3.486-2.041
			c0.585-0.406,1.23-0.728,1.862-1.051c1.349-0.669,2.722-1.313,4.083-1.958c0.812-0.382,1.48-0.907,1.91-1.719
			c0.024-0.155,0.084-0.322,0.06-0.466c-0.179-1.015-0.37-2.03-0.573-3.044c-0.048-0.239-0.513-0.537-0.752-0.478
			c-0.227,0.06-0.466,0.084-0.692,0.167c-2.006,0.764-4.011,1.528-5.993,2.328c-1.624,0.657-3.259,1.289-4.811,2.065
			c-1.218,0.609-2.483,1.051-3.784,1.445c-0.991,0.298-1.994,0.561-2.973,0.848c-2.77,0.812-5.623,1.027-8.488,1.146
			c-0.394,0.012-0.8,0-1.194-0.06c-1.886-0.31-3.82-0.239-5.671-0.752c-0.155-0.048-0.31-0.036-0.478-0.048
			c-0.43-0.036-0.991,0.334-1.098,0.764c-0.179,0.776-0.275,1.564-0.489,2.328c-0.346,1.325-0.394,2.662-0.454,4.023
			C123.276,483.568,123.885,487.174,124.506,490.779L124.506,490.779z M163.783,446.153c-0.8,0.37-1.636,0.609-2.471,0.848
			c-1.839,0.525-3.713,0.895-5.563,1.325c-3.486,0.812-6.996,1.504-10.566,1.862c-3.331,0.334-6.662,0.501-10.004,0.43
			c-2.483-0.048-4.883-0.549-7.306-1.063c-1.803-0.394-3.45-1.206-5.205-1.743c-0.824-0.251-1.659-0.669-2.304-1.218
			c-0.931-0.788-1.994-1.301-3.056-1.839c-0.287-0.143-0.573-0.287-0.824-0.478c-2.006-1.433-4.059-2.806-5.802-4.56
			c-0.275-0.286-0.597-0.525-0.943-0.824c-0.454,0.012-0.943-0.048-1.385,0.06c-1.54,0.382-3.08,0.788-4.56,1.385
			c-3.534,1.445-7.103,2.794-10.506,4.525c-1.337,0.68-2.806,1.11-3.976,2.125c-0.287,0.251-0.704,0.358-1.063,0.525
			c-0.931,0.442-1.874,0.872-2.806,1.325c-0.203,0.096-0.394,0.263-0.549,0.442c-0.084,0.107-0.096,0.287-0.096,0.442
			c0,0.048,0.084,0.131,0.143,0.155c0.298,0.072,0.609,0.179,0.919,0.191c0.872,0.048,1.743,0.119,2.603,0.334
			c2.328,0.561,4.584,1.289,6.805,2.185c1.839,0.752,3.558,1.767,5.42,2.471c0.287,0.107,0.597,0.287,0.8,0.513
			c0.43,0.513,1.039,0.728,1.576,1.063c1.146,0.716,2.304,1.421,3.462,2.101c0.68,0.406,1.373,0.8,2.089,1.146
			c0.573,0.275,1.134,0.537,1.636,0.955c0.358,0.31,0.824,0.466,1.23,0.716c1.695,1.063,3.558,1.743,5.336,2.65
			c1.051,0.537,2.209,0.883,3.188,1.576c0.191,0.131,0.43,0.215,0.657,0.298c4.107,1.576,8.321,2.662,12.702,3.152
			c1.277,0.143,2.543,0.167,3.808-0.012c1.97-0.263,3.928-0.549,5.898-0.883c2.364-0.406,4.656-0.991,6.96-1.588
			c1.456-0.382,2.937-0.704,4.405-1.063c0.31-0.072,0.669-0.06,0.919-0.215c0.919-0.561,1.958-0.633,2.973-0.8
			c0.382-0.06,0.764-0.215,1.122-0.37c0.37-0.179,0.633-0.49,0.669-0.919c0.024-0.239,0.024-0.478,0.012-0.716
			c-0.084-2.161,0.119-4.286,0.764-6.363c0.143-0.454,0.167-0.943,0.215-1.409c0.012-0.131-0.072-0.322-0.167-0.406
			c-0.096-0.084-0.322-0.119-0.43-0.06c-0.752,0.43-1.588,0.716-2.256,1.301c-0.239,0.203-0.525,0.358-0.812,0.466
			c-1.194,0.43-2.376,0.943-3.617,1.218c-2.794,0.621-5.623,1.063-8.5,1.17c-4.059,0.143-8.106,0.048-12.153-0.501
			c-1.504-0.203-3.008-0.43-4.441-0.883c-1.504-0.466-3.128-0.68-4.393-1.767c-0.167-0.143-0.442-0.167-0.669-0.251
			c-0.442-0.167-0.931-0.275-1.325-0.513c-2.053-1.23-4.322-2.006-6.483-3.02c-1.504-0.704-3.056-1.373-4.728-1.6
			c-2.053-0.275-4.095-0.621-6.172-0.442c-0.155,0.012-0.31-0.024-0.454-0.072c-0.048-0.012-0.119-0.107-0.119-0.155
			c0-0.06,0.048-0.179,0.096-0.191c2.161-0.585,4.358-0.967,6.602-0.728c1.576,0.167,3.164,0.394,4.704,0.752
			c1.612,0.382,3.223,0.824,4.751,1.528c2.316,1.051,4.716,1.91,7.211,2.447c0.931,0.191,1.886,0.298,2.806,0.537
			c1.325,0.334,2.662,0.454,4.011,0.549c3.892,0.251,7.796,0.167,11.688-0.084c0.788-0.048,1.576-0.191,2.364-0.31
			c0.704-0.119,1.409-0.275,2.113-0.406c3.307-0.609,6.483-1.6,9.419-3.247c0.418-0.227,0.836-0.478,1.289-0.621
			c1.468-0.49,2.591-1.516,3.832-2.376c0.119-0.084,0.251-0.227,0.287-0.37c0.048-0.215,0.06-0.466-0.012-0.669
			c-0.036-0.107-0.251-0.191-0.394-0.227C164.691,445.843,164.213,445.938,163.783,446.153L163.783,446.153z M164.607,444.076
			c-0.394-0.179-0.585-0.048-0.776,0.072C164.034,444.136,164.273,444.112,164.607,444.076L164.607,444.076z M163.604,444.219
			c-0.275,0.012-0.549,0.012-0.836,0.036c-0.227,0.012-0.298,0.119-0.167,0.251c0.048,0.048,0.155,0.036,0.227,0.036
			C163.127,444.542,163.377,444.41,163.604,444.219L163.604,444.219z M116.746,383.954c0.036-0.048,0.131-0.084,0.179-0.072
			c0.143,0.06,0.31,0.107,0.406,0.227c0.895,1.003,1.922,1.874,2.674,3.008c0.478,0.728,1.23,1.277,1.671,2.006
			c0.633,1.051,1.445,1.886,2.364,2.662c0.239,0.203,0.525,0.394,0.692,0.657c0.883,1.361,2.28,2.209,3.355,3.379
			c0.37,0.406,0.836,0.728,1.265,1.086c0.609,0.513,1.206,1.039,1.827,1.528c1.624,1.265,2.925,2.925,4.883,3.761
			c0.275,0.119,0.478,0.418,0.728,0.609c0.37,0.298,0.74,0.609,1.146,0.848c2.316,1.385,4.453,3.056,6.972,4.083
			c0.358,0.143,0.669,0.43,1.003,0.645c1.003,0.633,1.994,1.301,3.032,1.886c0.967,0.549,1.958,1.063,2.985,1.492
			c1.098,0.466,2.065,1.182,3.128,1.695c1.158,0.549,2.209,1.254,3.319,1.862c1.456,0.8,2.722,1.946,4.31,2.519
			c0.287,0.107,0.585,0.298,0.776,0.537c0.537,0.669,1.361,0.776,2.077,1.122c0.37,0.179,0.788,0.143,1.158-0.048
			c0.346-0.179,0.68-0.406,1.003-0.633c0.239-0.155,0.227-0.645,0.012-0.86c-0.119-0.107-0.215-0.251-0.346-0.334
			c-0.74-0.466-1.468-0.943-2.232-1.361c-3.784-2.089-3.784-2.077-6.793-4.369c-0.501-0.382-1.039-0.74-1.54-1.122
			c-0.764-0.573-1.612-1.074-2.244-1.767c-0.657-0.716-1.433-1.242-2.173-1.827c-0.43-0.346-0.848-0.716-1.277-1.063
			c-2.722-2.209-4.99-4.895-7.629-7.199c-2.268-2.555-4.835-4.835-6.996-7.497c-0.298-0.37-0.645-0.716-1.039-0.967
			c-0.621-0.406-1.086-0.919-1.468-1.54c-0.203-0.334-0.43-0.704-0.74-0.931c-1.361-1.003-2.304-2.412-3.51-3.57
			c-1.445-1.361-2.555-3.044-3.987-4.417c-0.692-1.182-1.659-2.125-2.662-3.032c-0.346-0.31-0.609-0.728-0.895-1.098
			c-0.239-0.31-0.418-0.68-0.692-0.967c-0.776-0.812-1.385-1.731-1.886-2.734c-0.394-0.8-1.098-1.409-1.85-1.803
			c-0.895-0.478-1.898-0.824-2.889-1.039c-3.737-0.8-7.533-1.17-11.306-1.791c-0.549-0.096-1.11-0.107-1.659-0.131
			c-2.626-0.119-5.253-0.06-7.796-0.537c-0.287,0.012-0.525,0.036-0.764,0.048c-0.179,0.012-0.442,0.322-0.418,0.501
			c0.024,0.239,0.012,0.489,0.107,0.704c0.442,0.931,0.907,1.862,1.373,2.782c0.43,0.848,0.907,1.683,1.289,2.543
			c0.358,0.8,0.8,1.54,1.337,2.244c0.716,0.955,1.289,1.994,1.934,3.008c0.334,0.537,0.573,1.17,1.015,1.612
			c0.633,0.633,1.051,1.397,1.528,2.125c0.263,0.394,0.573,0.764,0.836,1.146c0.931,1.385,1.874,2.758,2.782,4.155
			c0.74,1.122,1.456,2.28,2.185,3.414c0.131,0.203,0.287,0.382,0.43,0.573c1.039,1.289,2.316,2.4,3.104,3.904
			c0.072,0.131,0.203,0.239,0.31,0.358c1.23,1.361,2.34,2.806,3.546,4.167c1.313,1.468,2.471,3.128,4.202,4.178
			c0.203,0.119,0.346,0.322,0.513,0.489c1.898,2.042,4.155,3.689,6.16,5.599c1.898,1.301,3.486,3.008,5.48,4.19
			c0.824,0.49,1.636,0.967,2.412,1.528s1.516,1.146,2.459,1.421c0.37,0.107,0.728,0.358,1.003,0.633
			c1.039,1.015,2.328,1.624,3.582,2.304c0.764,0.418,1.516,0.86,2.28,1.301c0.275,0.155,0.525,0.358,0.812,0.489
			c2.101,0.955,4.19,1.898,6.303,2.829c0.728,0.322,1.468,0.621,2.232,0.836c2.137,0.621,4.262,1.266,6.208,2.376
			c0.072,0.036,0.155,0.048,0.227,0.072c0.418,0.143,1.074-0.072,1.23-0.478c0.513-1.266,1.098-2.507,2.077-3.45
			c1.051-1.003,1.803-2.221,2.71-3.319c0.298-0.358,0.119-0.764-0.346-0.895c-1.074-0.287-2.161-0.537-3.235-0.836
			c-0.991-0.275-1.982-0.549-2.829-1.194c-0.251-0.191-0.561-0.287-0.86-0.394c-1.862-0.669-3.653-1.564-5.611-2.006
			c-0.537-0.119-1.039-0.394-1.552-0.597c-0.883-0.358-1.755-0.74-2.638-1.098c-1.91-0.776-3.844-1.504-5.575-2.674
			c-0.322-0.227-0.692-0.394-1.063-0.549c-1.468-0.645-2.77-1.54-3.987-2.579c-0.418-0.358-0.907-0.633-1.385-0.907
			c-0.275-0.167-0.633-0.215-0.848-0.418c-0.86-0.836-1.922-1.409-2.829-2.173c-2.447-2.041-4.763-4.214-6.65-6.805
			c-0.191-0.251-0.394-0.513-0.621-0.728c-1.289-1.194-2.328-2.567-3.223-4.095c-0.68-1.17-1.48-2.256-1.862-3.582
			c-0.084-0.298-0.287-0.561-0.466-0.824c-0.907-1.313-1.576-2.746-2.244-4.19c-0.06-0.143-0.084-0.31-0.096-0.466
			C116.65,384.097,116.698,384.014,116.746,383.954L116.746,383.954z M158.16,429.046c-0.37-0.048-0.848-0.155-1.313-0.167
			c-2.006-0.036-3.94-0.501-5.91-0.764c-0.466-0.06-0.943-0.179-1.385-0.358c-1.779-0.704-3.653-1.122-5.468-1.671
			c-0.454-0.143-0.931-0.227-1.373-0.394c-1.337-0.513-2.638-1.098-3.987-1.576s-2.507-1.385-3.94-1.659
			c-0.227-0.048-0.454-0.179-0.621-0.334c-0.597-0.549-1.349-0.824-2.041-1.194c-1.194-0.633-2.364-1.301-3.534-1.982
			c-0.263-0.155-0.478-0.406-0.716-0.621c-0.239-0.203-0.466-0.43-0.728-0.609c-1.254-0.848-2.519-1.671-3.773-2.507
			c-1.254-0.848-2.447-1.767-3.498-2.853c-0.442-0.466-0.907-0.872-1.433-1.23c-1.039-0.728-2.041-1.504-2.794-2.567
			c-0.358-0.513-0.872-0.919-1.313-1.373c-0.716-0.74-1.445-1.48-2.161-2.221c-0.215-0.227-0.418-0.478-0.657-0.692
			c-0.621-0.573-1.337-0.824-2.185-0.645c-1.636,0.346-3.259,0.752-4.835,1.289c-0.597,0.203-1.206,0.406-1.827,0.549
			c-2.006,0.49-4.023,0.955-6.029,1.421c-2.638,0.621-5.313,0.979-8.023,1.015c-1.671,0.024-3.343-0.012-5.014-0.012
			c-0.394,0-0.8,0.012-1.182,0.048c-0.131,0.012-0.298,0.143-0.346,0.263c-0.06,0.119-0.036,0.346,0.036,0.442
			c0.143,0.179,0.358,0.298,0.549,0.442c1.719,1.409,3.438,2.806,5.05,4.358c1.361,1.313,2.638,2.746,4.322,3.701
			c0.466,0.275,0.895,0.692,1.206,1.134c0.382,0.525,0.86,0.931,1.337,1.349c1.015,0.895,2.006,1.815,3.032,2.686
			c1.086,0.919,2.041,2.006,3.319,2.698c0.072,0.036,0.119,0.107,0.179,0.167c1.218,1.134,2.638,2.053,3.57,3.486
			c0.131,0.191,0.322,0.346,0.513,0.501c1.528,1.265,2.77,2.853,4.465,3.928c0.191,0.131,0.358,0.31,0.513,0.501
			c0.394,0.513,0.883,0.907,1.385,1.301c0.8,0.645,1.624,1.289,2.328,2.03c1.218,1.289,2.626,2.292,4.143,3.176
			c0.549,0.322,1.134,0.597,1.612,1.003c0.8,0.68,1.731,1.146,2.65,1.588c2.232,1.063,4.537,1.91,6.912,2.567
			c0.991,0.275,1.994,0.585,3.008,0.728c1.731,0.251,3.486,0.31,5.205,0.68c2.626,0.096,5.253,0.084,7.867-0.143
			c1.504-0.131,3.008-0.31,4.501-0.466c0.478-0.048,0.955-0.084,1.421-0.155c0.406-0.06,0.764-0.227,1.003-0.597
			c0.024-0.06,0.072-0.131,0.096-0.203c0.275-1.074,0.478-2.161,1.277-3.032c0.37-0.406,0.406-1.015,0.394-1.588
			c0-0.179-0.275-0.489-0.454-0.489c-0.31,0-0.645-0.048-0.943,0.024c-2.053,0.513-4.095,0.525-6.136,0.024
			c-0.31-0.072-0.633-0.072-0.943-0.155c-1.074-0.275-2.197-0.442-3.211-0.871c-1.6-0.669-3.295-1.158-4.692-2.28
			c-0.489-0.382-1.098-0.657-1.695-0.848c-1.456-0.478-2.626-1.48-4.011-2.077c-2.304-1.564-4.477-3.295-6.662-5.002
			c-1.063-0.824-2.101-1.695-3.104-2.603c-2.137-1.922-4.704-3.223-6.972-4.954c-1.934-0.919-3.773-2.041-5.886-2.531
			c-0.752-0.179-1.433-0.633-2.137-0.967c-0.036-0.012-0.036-0.119-0.048-0.191c0.597-0.31,1.242-0.215,1.874-0.215
			c2.483-0.012,4.919,0.179,7.103,1.54c0.394,0.251,0.883,0.358,1.313,0.549c1.015,0.454,2.018,0.931,3.044,1.349
			c0.824,0.346,1.433,1.015,2.209,1.385c1.301,0.621,2.459,1.456,3.713,2.149c1.385,0.776,2.615,1.803,4.035,2.519
			c0.346,0.179,0.669,0.454,0.919,0.752c0.537,0.621,1.23,0.991,1.946,1.349c0.776,0.382,1.421,0.991,2.268,1.289
			c1.039,0.37,2.065,0.848,3.02,1.409c0.764,0.442,1.552,0.788,2.34,1.158c1.648,0.788,3.414,1.289,5.086,2.006
			c2.197,0.943,4.513,1.528,6.709,2.435c0.872,0.358,1.839,0.489,2.817,0.012c0.883-1.946,1.767-3.904,2.638-5.862
			c0.191-0.43,0.382-0.871,0.549-1.325C158.757,429.726,158.614,429.368,158.16,429.046L158.16,429.046z M57.161,444.064
			c-0.406,0.251-0.836,0.454-1.277,0.633c-0.967,0.382-1.839,0.931-2.662,1.576c-0.943,0.74-1.946,1.361-3.068,1.803
			c-0.657,0.263-1.254,0.68-1.85,1.063c-0.191,0.119-0.298,0.346-0.549,0.645c1.015,1.003,1.803,2.185,3.068,2.949
			c1.146,0.704,2.221,1.528,3.45,2.113c0.43,0.203,0.836,0.466,1.218,0.74c1.289,0.931,2.626,1.791,4.059,2.483
			c0.43,0.203,0.824,0.478,1.218,0.752c1.11,0.764,2.376,1.277,3.391,2.197c0.979,0.358,1.85,0.872,2.71,1.468
			c0.836,0.585,1.827,0.955,2.722,1.468c0.764,0.43,1.54,0.824,2.197,1.421c0.346,0.31,0.788,0.549,1.218,0.752
			c2.794,1.325,5.563,2.71,8.429,3.892c0.442,0.179,0.872,0.394,1.301,0.573c2.794,1.17,5.647,2.161,8.631,2.686
			c2.03,0.358,4.083,0.645,6.041,1.349c0.442,0.167,0.931,0.203,1.397,0.275c2.746,0.43,5.516,0.788,8.285,1.039
			c2.388,0.227,4.775,0.334,7.151,0.167c2.364-0.179,4.787-0.251,7.151-1.576c0.466-0.43,1.098-1.015,1.743-1.612
			c0.048-0.048,0.048-0.143,0.084-0.298c-0.096-0.143-0.179-0.418-0.358-0.501c-0.501-0.227-1.039-0.418-1.576-0.549
			c-1.23-0.322-2.4-0.824-3.582-1.289c-3.259-1.277-6.363-2.877-9.515-4.393c-0.358-0.167-0.728-0.358-1.015-0.621
			c-1.397-1.337-3.128-2.173-4.692-3.271c-0.645-0.454-1.385-0.74-2.185-0.907c-2.65-0.549-5.217-1.385-7.855-1.958
			c-1.552-0.334-3.092-0.728-4.656-1.027c-3.665-0.716-7.342-1.385-11.007-2.077c-0.239-0.048-0.489-0.072-0.68-0.203
			c-0.752-0.537-1.612-0.609-2.471-0.776c-1.098-0.203-2.173-0.466-3.247-0.74c-2.077-0.537-4.155-1.086-6.22-1.659
			c-0.525-0.143-1.027-0.406-1.516-0.657c-0.096-0.048-0.107-0.263-0.167-0.406c0.131-0.072,0.263-0.191,0.406-0.203
			c1.898-0.263,3.796-0.358,5.707-0.227c1.91,0.131,3.808,0.239,5.719,0.358c0.633,0.048,1.277,0.072,1.898,0.179
			c3.283,0.573,6.626,0.86,9.873,1.648c0.609,0.143,1.254,0.179,1.886,0.251c0.06,0.012,0.179-0.084,0.179-0.119
			c-0.012-0.143-0.024-0.346-0.119-0.406c-1.301-0.8-2.734-1.242-4.178-1.707c-1.254,0.084-2.304-0.549-3.379-1.039
			c-2.173-0.991-4.405-1.815-6.674-2.543c-1.361-0.442-2.734-0.836-3.952-1.636c-0.525-0.346-1.146-0.537-1.743-0.776
			c-1.994-0.788-3.999-1.552-5.898-2.567c-0.406-0.215-0.883-0.322-1.254-0.442C59.585,442.87,58.319,443.348,57.161,444.064
			L57.161,444.064z M47.109,396.024c-1.277-0.107-2.495-0.49-3.725-0.824c-2.853-0.764-5.372-2.34-8.106-3.402
			c-0.143-0.06-0.275-0.167-0.406-0.251c-1.612-1.027-3.211-2.041-4.835-3.056c-0.203-0.131-0.418-0.275-0.645-0.286
			c-0.191-0.012-0.382,0.143-0.621,0.251c0.107,0.37,0.155,0.68,0.275,0.967c0.215,0.513,0.454,1.015,0.728,1.504
			c1.098,1.934,2.244,3.844,3.57,5.635c0.525,0.716,0.919,1.48,1.23,2.304c0.215,0.597,0.346,1.266,0.716,1.755
			c0.776,1.015,1.301,2.173,1.97,3.247c1.134,1.815,2.352,3.593,3.128,5.611c0.227,0.585,0.597,1.122,0.943,1.647
			c0.86,1.337,1.791,2.626,2.519,4.047c0.251,0.49,0.585,0.955,0.907,1.409c0.513,0.704,1.086,1.361,1.301,2.244
			c0.072,0.287,0.346,0.525,0.549,0.764c1.015,1.23,2.006,2.459,2.782,3.856c0.227,0.418,0.513,0.8,0.788,1.194
			c0.8,1.086,1.648,2.149,2.292,3.343c0.179,0.346,0.513,0.597,0.764,0.907c1.23,1.564,2.734,2.889,4.011,4.405
			c0.466,0.549,0.967,1.051,1.516,1.504c0.728,0.609,1.48,1.23,2.065,1.97c1.027,1.266,2.292,2.173,3.689,2.949
			c0.764,0.43,1.54,0.824,2.209,1.409c0.358,0.31,0.776,0.561,1.194,0.788c2.113,1.11,4.226,2.209,6.339,3.319
			c0.561,0.298,1.158,0.549,1.647,0.943c0.68,0.549,1.421,1.003,2.185,1.421c0.764,0.43,1.48,0.931,2.232,1.385
			c0.406,0.239,0.836,0.43,1.158,0.585c0.812-0.024,1.349-0.37,1.862-0.752c1.074-0.812,2.125-1.659,3.2-2.483
			c1.588-1.206,3.259-2.244,5.086-3.056c1.086-0.489,2.149-1.039,3.188-1.612c1.886-1.051,3.868-1.839,5.957-2.364
			c0.609-0.155,1.194-0.37,1.898-0.585c-0.179-0.251-0.263-0.537-0.418-0.585c-0.68-0.227-1.361-0.43-2.065-0.573
			c-0.549-0.107-1.086-0.203-1.528-0.597c-0.179-0.155-0.406-0.275-0.633-0.31c-1.671-0.298-3.116-1.194-4.668-1.779
			c-0.513-0.203-1.015-0.49-1.54-0.645c-1.707-0.466-3.223-1.337-4.787-2.101c-1.85-0.907-3.689-1.85-5.575-2.698
			c-1.23-0.561-2.519-0.991-3.51-1.982c-0.215-0.215-0.549-0.31-0.836-0.454c-0.501-0.251-0.991-0.501-1.492-0.752
			c-1.636-0.812-3.307-1.564-4.907-2.447c-1.588-0.883-3.307-1.54-4.728-2.722c-0.179-0.143-0.418-0.215-0.633-0.31
			c-1.659-0.764-3.283-1.576-4.608-2.889c-0.227-0.227-0.513-0.382-0.776-0.549c-1.206-0.764-2.435-1.504-3.51-2.459
			c-1.54-0.752-2.603-2.077-3.82-3.211c-0.692-0.645-1.325-1.373-1.671-2.28c-0.06-0.143,0.119-0.263,0.346-0.239
			c0.478,0.585,1.003,1.158,1.731,1.528c0.489,0.251,0.907,0.633,1.373,0.943c1.194,0.788,2.34,1.671,3.593,2.316
			c2.543,1.301,4.978,2.829,7.736,3.689c0.298,0.096,0.609,0.239,0.836,0.442c0.788,0.704,1.767,1.039,2.698,1.468
			c2.089,0.955,4.19,1.922,6.292,2.865c2.603,1.182,5.205,2.364,7.855,3.45c1.325,0.537,2.662,1.086,3.892,1.803
			c0.824,0.49,1.648,0.955,2.531,1.313c1.313,0.537,2.65,1.051,3.975,1.552c0.048,0.012,0.131-0.072,0.227-0.131
			c-0.119-0.203-0.203-0.43-0.358-0.585c-0.215-0.227-0.478-0.418-0.752-0.585c-0.609-0.37-1.265-0.669-1.85-1.063
			s-1.086-0.919-1.671-1.325s-1.242-0.704-1.839-1.098c-1.456-0.955-2.901-1.946-4.358-2.913c-0.525-0.346-1.194-0.549-1.624-0.979
			c-0.633-0.645-1.409-1.027-2.113-1.54c-0.824-0.609-1.719-1.146-2.483-1.839c-1.003-0.919-2.077-1.707-3.223-2.423
			c-0.275-0.167-0.549-0.322-0.8-0.513c-1.504-1.182-2.961-2.4-4.501-3.546c-1.027-0.764-1.91-1.671-2.925-2.435
			c-1.767-1.349-3.367-2.901-5.05-4.334c-1.456-1.242-2.877-2.483-4.178-3.892c-0.943-0.49-1.516-1.385-2.209-2.137
			c-0.275-0.286-0.525-0.609-0.824-0.86c-0.669-0.573-1.361-1.146-2.221-1.361C49.747,396.621,48.47,396.131,47.109,396.024
			L47.109,396.024z M34.801,338.182c-1.886-0.633-3.749-1.301-5.623-1.982c-0.669-0.239-1.385-0.466-1.946-0.872
			c-0.657-0.478-1.385-0.788-2.101-1.098c-2.77-1.194-5.575-2.316-8.381-3.426c-0.657-0.263-1.373-0.382-2.065-0.549
			c-0.275-0.072-0.657,0.275-0.669,0.549c-0.036,0.812,0.155,1.588,0.454,2.316c0.418,1.027,0.824,2.065,1.337,3.056
			c1.289,2.471,2.28,5.074,3.677,7.497c0.824,1.445,1.6,2.925,2.579,4.286c0.322,0.454,0.645,0.919,0.848,1.433
			c0.394,0.967,0.967,1.815,1.516,2.698c0.418,0.669,0.776,1.385,1.17,2.077c0.239,0.406,0.466,0.824,0.728,1.218
			c1.767,2.555,3.546,5.086,5.289,7.653c0.8,1.182,1.552,2.4,2.531,3.462c0.68,1.564,1.922,2.722,2.949,4.011
			c0.943,1.182,1.886,2.364,2.722,3.617c0.752,1.134,1.588,2.185,2.495,3.199c0.537,0.585,1.051,1.206,1.48,1.862
			c1.349,2.065,2.961,3.916,4.572,5.766c1.086,1.265,2.149,2.567,3.092,3.94c0.68,0.991,1.421,1.91,2.232,2.782
			c0.489,0.525,0.895,1.11,1.385,1.624c0.549,0.573,1.158,1.086,1.719,1.648c0.334,0.334,0.633,0.716,0.955,1.074
			c0.275,0.286,0.525,0.609,0.836,0.848c1.074,0.848,1.994,1.827,2.782,2.937c0.131,0.191,0.275,0.406,0.454,0.549
			c1.349,1.098,2.292,2.65,3.88,3.486c0.143,0.072,0.275,0.179,0.358,0.31c0.669,1.027,1.743,1.624,2.615,2.412
			c0.585,0.537,1.254,0.991,1.791,1.576c1.349,1.468,2.865,2.758,4.441,3.964c0.191,0.143,0.394,0.275,0.549,0.454
			c0.537,0.597,1.158,1.086,1.791,1.564c0.692,0.525,1.349,1.11,2.03,1.648c0.812,0.645,1.6,1.313,2.459,1.874
			c0.883,0.573,1.504,1.409,2.34,2.018c0.513,0.37,0.991,0.788,1.492,1.182c0.489,0.394,0.979,0.812,1.468,1.206
			c1.361,1.098,2.698,2.244,4.202,3.116c1.325,0.764,2.519,1.671,3.617,2.71c0.645,0.609,1.301,1.158,2.041,1.624
			c0.537,0.346,1.051,0.716,1.564,1.086c1.564,1.098,3.116,2.197,4.692,3.295c0.454,0.322,0.907,0.645,1.48,0.716
			c0.072-0.119,0.227-0.251,0.215-0.358c-0.119-0.585-0.549-0.955-0.943-1.337c-0.621-0.609-1.254-1.218-1.862-1.839
			c-0.669-0.68-1.254-1.421-2.101-1.922c-0.776-0.454-1.051-1.385-1.636-2.03c-0.215-0.239-0.454-0.442-0.692-0.657
			c-1.755-1.612-3.426-3.307-4.954-5.145c-0.346-0.418-0.8-0.776-1.265-1.063c-0.478-0.31-0.943-0.645-1.194-1.134
			c-0.406-0.8-1.051-1.385-1.624-2.042c-1.206-1.361-2.412-2.746-3.558-4.167c-0.907-1.122-1.815-2.197-2.997-3.044
			c-0.788-0.561-1.194-1.445-1.647-2.28c-0.024-0.048,0.036-0.155,0.048-0.227c0.263-0.334,0.657-0.442,1.039-0.537
			c3.474-0.848,6.948-1.707,10.422-2.519c2.006-0.466,4.035-0.872,6.065-1.301c1.158-0.561,2.412-0.764,3.665-0.931
			c0.143-0.024,0.298-0.119,0.406-0.227c0.107-0.107,0.203-0.263,0.203-0.394s-0.131-0.275-0.227-0.394
			c-0.048-0.06-0.131-0.107-0.203-0.119c-1.695-0.191-3.152-1.003-4.62-1.791c-0.418-0.227-0.824-0.478-1.254-0.704
			c-1.409-0.728-2.782-1.564-4.25-2.149c-1.194-0.478-2.28-1.11-3.379-1.743c-1.301-0.764-2.543-1.624-3.82-2.424
			c-1.54-0.967-3.128-1.886-4.596-2.985c-0.633-0.478-1.265-0.967-1.934-1.397c-1.409-0.895-2.841-1.743-4.262-2.626
			c-0.406-0.251-0.788-0.525-1.182-0.8c-1.11-0.764-2.221-1.54-3.331-2.316c-0.979-0.68-1.982-1.325-2.829-2.185
			c-0.275-0.275-0.597-0.537-0.943-0.704c-1.528-0.704-2.817-1.779-4.167-2.758c-1.731-1.254-3.534-2.423-5.11-3.892
			c-0.167-0.155-0.406-0.239-0.597-0.382c-1.086-0.788-2.244-1.516-3.259-2.412c-1.731-1.516-3.51-2.973-5.42-4.286
			c-0.322-0.227-0.609-0.501-0.907-0.752c-1.289-1.063-2.364-2.352-3.761-3.271c-0.263-0.167-0.501-0.394-0.728-0.621
			c-1.671-1.707-3.534-3.199-5.372-4.716c-0.37-0.298-0.728-0.621-1.039-0.979c-0.239-0.286-0.478-0.633-0.358-1.063
			c0.072,0,0.167-0.048,0.215-0.012c1.803,1.445,3.784,2.626,5.635,4.011c1.528,1.134,3.164,2.125,4.644,3.343
			c0.37,0.298,0.776,0.549,1.194,0.788c1.254,0.704,2.4,1.54,3.51,2.459c0.907,0.764,1.874,1.492,2.901,2.089
			c1.242,0.716,2.34,1.648,3.677,2.209c0.287,0.119,0.561,0.322,0.776,0.549c0.561,0.585,1.265,0.943,1.946,1.361
			c0.74,0.466,1.48,0.931,2.185,1.457c1.397,1.027,2.734,2.149,4.369,2.841c0.287,0.119,0.549,0.322,0.776,0.537
			c0.478,0.442,1.051,0.704,1.6,1.027c1.433,0.848,2.925,1.648,4.262,2.626c1.277,0.943,2.65,1.719,3.952,2.626
			c1.23,0.86,2.555,1.6,3.784,2.483c0.454,0.322,0.931,0.621,1.421,0.872c2.543,1.325,4.966,2.829,7.366,4.405
			c1.122,0.74,2.244,1.468,3.486,2.03c1.146,0.525,2.268,1.122,3.355,1.803c0.872,0.549,1.862,0.883,2.817,1.289
			c0.096,0.048,0.322-0.06,0.394-0.167c0.06-0.095,0.06-0.334-0.012-0.418c-0.489-0.513-0.943-1.11-1.528-1.48
			c-1.624-1.051-2.877-2.507-4.274-3.784c-0.645-0.597-1.206-1.254-2.065-1.564c-0.215-0.084-0.43-0.227-0.585-0.406
			c-1.098-1.277-2.698-1.994-3.725-3.331c-1.289-0.943-2.352-2.137-3.522-3.2c-2.698-2.447-5.539-4.728-8.166-7.271
			c-1.421-1.373-2.937-2.662-4.334-4.071c-0.669-0.669-1.361-1.361-2.125-1.922c-0.979-0.716-1.791-1.564-2.555-2.483
			c-0.203-0.239-0.406-0.513-0.669-0.669c-0.836-0.49-1.456-1.218-2.101-1.91c-1.027-1.11-1.994-2.28-3.104-3.307
			c-1.349-1.254-2.579-2.591-3.796-3.964c-0.68-0.776-1.373-1.54-2.053-2.316c-1.158-1.313-2.328-2.615-3.498-3.916
			c-1.23-1.361-2.435-2.722-3.689-4.047c-1.254-1.313-2.435-2.71-3.808-3.784C40.364,340.104,37.594,339.113,34.801,338.182
			L34.801,338.182z M31.935,287.957c-1.385-0.776-2.829-1.445-4.369-1.898c-0.764-0.227-1.54-0.418-2.28-0.716
			c-2.077-0.848-4.286-1.17-6.471-1.588c-1.015-0.191-2.053-0.275-3.08-0.024c-0.31,0.072-0.561,0.31-0.633,0.645
			c-0.06,0.31-0.119,0.633-0.084,0.931c0.191,1.671,0.645,3.271,1.313,4.811c0.346,0.8,0.728,1.6,1.003,2.424
			c0.322,0.991,0.812,1.898,1.313,2.806c0.394,0.692,0.764,1.397,1.122,2.101c0.287,0.561,0.573,1.122,0.967,1.636
			c0.68,0.883,1.277,1.815,1.85,2.77c0.406,0.68,0.645,1.445,1.051,2.125s0.919,1.289,1.349,1.958
			c0.263,0.394,0.466,0.836,0.68,1.254c0.155,0.275,0.275,0.573,0.454,0.836c1.421,2.018,2.806,4.059,4.286,6.017
			c1.194,1.576,2.125,3.355,3.522,4.775c0.215,0.227,0.394,0.501,0.537,0.776c0.692,1.265,1.707,2.28,2.662,3.343
			c1.015,1.122,1.994,2.256,2.817,3.534c0.43,0.669,0.931,1.289,1.468,1.874c1.122,1.23,2.244,2.459,3.223,3.82
			c0.549,0.764,1.182,1.492,1.862,2.149c0.907,0.883,1.731,1.839,2.519,2.818c1.146,1.421,2.507,2.674,3.474,4.25
			c0.084,0.131,0.215,0.227,0.322,0.346c0.848,0.836,1.588,1.779,2.364,2.674c0.931,1.086,1.946,2.089,2.734,3.283
			c1.468,1.361,2.698,2.901,3.832,4.549c0.096,0.131,0.191,0.251,0.31,0.37c0.609,0.633,1.146,1.325,1.612,2.065
			c0.215,0.322,0.501,0.621,0.8,0.872c0.848,0.716,1.743,1.373,2.316,2.376c0.191,0.334,0.549,0.573,0.836,0.848
			c0.346,0.334,0.704,0.645,1.027,0.991c1.146,1.218,2.352,2.388,3.379,3.689c1.098,1.385,2.424,2.507,3.629,3.761
			c0.549,0.573,1.146,1.098,1.707,1.647c0.287,0.275,0.597,0.537,0.8,0.872c0.454,0.776,1.122,1.325,1.755,1.934
			c0.346,0.322,0.752,0.633,0.979,1.027c0.406,0.716,1.074,1.158,1.6,1.743c0.37,0.406,0.86,0.716,1.218,1.146
			c0.812,0.991,1.767,1.827,2.674,2.722c0.513,0.501,0.943,1.074,1.456,1.564c1.313,1.254,2.244,2.889,3.88,3.832
			c0.263,0.155,0.442,0.454,0.657,0.692c0.382,0.406,0.74,0.824,1.134,1.23c1.17,1.182,2.352,2.364,3.534,3.546
			c1.23,1.242,2.197,2.722,3.701,3.689c0.131,0.084,0.203,0.239,0.31,0.358c1.325,1.48,2.889,2.71,4.238,4.178
			c0.096,0.107,0.287,0.131,0.43,0.167c0.048,0.012,0.119-0.072,0.179-0.107c-0.036-0.155-0.024-0.322-0.096-0.442
			c-1.051-1.779-2.101-3.57-3.176-5.325c-0.418-0.68-0.836-1.349-1.17-2.065c-0.298-0.645-0.645-1.301-1.11-1.827
			c-0.478-0.549-0.824-1.158-1.146-1.791c-0.752-1.492-1.528-2.961-2.28-4.465c-0.394-0.776-0.776-1.564-1.051-2.4
			c-0.406-1.206-0.895-2.388-1.409-3.546c-0.872-1.982-1.48-4.023-1.874-6.148c-0.072-0.394-0.167-0.776-0.275-1.158
			c-0.525-1.827-0.394-2.113-2.507-2.125c-0.155,0-0.31-0.06-0.478-0.084c-0.728-0.322-1.385-0.776-1.97-1.325
			c-0.633-0.597-1.23-1.242-1.839-1.862c-2.006-2.041-4.083-3.999-6.28-5.826c-1.039-0.86-2.077-1.731-2.985-2.722
			c-0.752-0.836-1.588-1.552-2.435-2.268c-0.239-0.203-0.549-0.346-0.752-0.573c-1.289-1.528-2.877-2.734-4.381-4.023
			c-0.955-0.824-1.994-1.552-2.698-2.662c-0.239-0.382-0.645-0.704-1.039-0.955c-0.68-0.43-1.242-0.991-1.827-1.528
			c-1.707-1.552-3.068-3.45-4.943-4.835c-0.191-0.143-0.31-0.358-0.478-0.537c-1.683-1.898-3.414-3.749-5.038-5.695
			c-1.074-1.277-2.113-2.615-2.937-4.047c-0.752-1.301-1.456-2.638-2.256-3.904c-0.036-0.06-0.06-0.155-0.06-0.227
			s0.024-0.155,0.072-0.203c0.048-0.036,0.155-0.06,0.191-0.036c0.263,0.167,0.573,0.322,0.752,0.549
			c0.489,0.621,0.943,1.277,1.397,1.934c1.074,1.588,2.292,3.032,3.737,4.31c1.313,1.146,2.531,2.4,3.82,3.582
			c1.349,1.23,2.65,2.507,3.976,3.761c0.872,0.812,1.803,1.564,2.71,2.328c0.179,0.155,0.406,0.239,0.621,0.358
			c0.776,0.43,1.456,0.943,1.982,1.671c0.275,0.37,0.692,0.645,1.074,0.931c1.277,0.943,2.519,1.922,3.641,3.068
			c0.657,0.68,1.445,1.242,2.185,1.85c0.8,0.645,1.671,1.23,2.412,1.934c0.752,0.716,1.767,1.098,2.388,1.958
			c0.621,0.848,1.588,1.313,2.34,2.006c2.865,2.638,5.73,5.277,8.596,7.915c0.525,0.49,0.991,1.051,1.576,1.456
			c0.704,0.478,1.086,1.146,1.397,1.886c0.155,0.358,0.227,0.74,0.346,1.146c0.657,0.227,1.039,0.096,1.433,0.024
			c0.704-0.143,1.397-0.334,2.101-0.37c1.994-0.107,3.975-0.06,5.945,0.239c1.886,0.287,3.761,0.645,5.647,0.967
			c1.815,0.31,3.57,0.764,5.301,1.349c0.454,0.155,0.895,0.298,1.361,0.406c0.096,0.024,0.251-0.131,0.358-0.239
			c0.048-0.048,0.06-0.167,0.024-0.227c-0.155-0.382-0.454-0.645-0.764-0.895c-0.979-0.812-1.994-1.588-2.901-2.459
			c-0.979-0.931-2.006-1.791-3.128-2.543c-0.454-0.31-0.86-0.692-1.277-1.063c-1.468-1.337-2.997-2.615-4.549-3.856
			c-1.289-1.039-2.806-1.791-3.82-3.188c-0.358-0.489-0.991-0.8-1.492-1.182c-0.382-0.287-0.812-0.537-1.11-0.895
			c-0.979-1.17-2.34-1.898-3.379-2.997c-0.155-0.167-0.37-0.31-0.585-0.406c-1.468-0.68-2.567-1.85-3.761-2.889
			c-0.955-0.836-1.85-1.731-2.889-2.471c-0.322-0.227-0.609-0.513-0.895-0.788c-1.373-1.325-2.949-2.4-4.465-3.534
			c-1.146-0.848-2.149-1.886-3.45-2.519c-0.143-0.072-0.275-0.179-0.37-0.298c-0.692-0.895-1.659-1.456-2.567-2.101
			c-0.394-0.275-0.812-0.525-1.146-0.848c-2.018-1.922-4.417-3.391-6.351-5.42c-0.275-0.287-0.609-0.513-0.943-0.728
			c-0.466-0.31-0.848-0.68-1.194-1.134c-0.382-0.501-0.872-0.919-1.337-1.361c-0.752-0.716-1.528-1.397-2.113-2.256
			c-0.179-0.263-0.418-0.478-0.657-0.692c-0.943-0.86-1.862-1.719-2.686-2.71c-0.8-0.967-1.898-1.695-2.507-2.829
			c-1.492-1.337-2.662-2.937-3.856-4.513c-0.907-1.206-1.898-2.352-2.841-3.522c-0.346-1.015-1.468-1.456-1.839-2.447
			c-1.098-1.265-2.041-2.638-2.877-4.083c-0.191-0.334-0.513-0.609-0.752-0.931c-0.43-0.573-0.931-1.11-1.242-1.743
			c-0.812-1.648-1.898-3.116-2.841-4.692c-0.585-0.991-1.313-1.791-2.232-2.423c-0.525-0.37-1.003-0.764-1.48-1.194
			C33.738,289.235,32.878,288.494,31.935,287.957L31.935,287.957z M141.9,353.678c-0.561-0.669-1.17-1.301-1.707-1.982
			c-0.585-0.752-1.098-1.564-1.683-2.316c-0.68-0.883-1.409-1.719-1.994-2.674c-0.287-0.466-0.692-0.872-1.11-1.242
			c-0.489-0.43-0.836-0.943-1.146-1.504c-0.382-0.692-0.728-1.433-1.23-2.041c-0.764-0.931-1.349-1.958-1.994-2.961
			c-0.394-0.597-0.728-1.242-1.146-1.815c-0.764-1.027-1.361-2.137-1.922-3.283c-0.418-0.86-0.812-1.731-1.337-2.531
			c-0.573-0.872-1.015-1.791-1.48-2.71c-1.158-2.256-2.328-4.513-4.107-6.363c-0.382-0.394-0.728-0.848-1.015-1.325
			c-0.143-0.239-0.155-0.561-0.227-0.86c-0.275-0.119-0.573-0.203-0.812-0.37c-1.098-0.788-2.161-1.648-2.901-2.794
			c-0.442-0.68-0.943-1.277-1.516-1.827c-0.967-0.943-1.934-1.886-2.913-2.817c-1.098-1.039-2.149-2.125-3.438-2.937
			c-0.752-0.478-1.373-1.11-1.91-1.779c-1.659-2.065-3.856-3.558-5.659-5.456c-1.051-0.597-1.779-1.576-2.71-2.328
			c-0.991-0.812-1.934-1.636-2.674-2.698c-0.227-0.322-0.573-0.549-0.86-0.812c-0.525-0.489-1.039-0.991-1.564-1.468
			c-0.346-0.322-0.669-0.728-1.074-0.931c-0.74-0.382-1.254-0.955-1.767-1.576c-0.298-0.37-0.669-0.68-1.015-1.003
			c-0.8-0.776-1.612-1.54-2.4-2.328c-0.454-0.442-0.895-0.907-1.301-1.385c-1.445-1.695-3.319-2.937-4.787-4.62
			c-0.931-1.063-2.113-1.922-3.176-2.877c-0.239-0.215-0.525-0.394-0.692-0.657c-0.728-1.194-1.874-1.934-2.877-2.829
			c-0.525-0.478-1.086-0.931-1.612-1.409c-1.528-1.397-2.973-2.877-4.656-4.095c-0.31-0.227-0.645-0.513-0.824-0.848
			c-0.382-0.752-1.015-1.194-1.671-1.659c-0.704-0.513-1.409-1.039-2.065-1.612c-2.388-2.101-4.787-4.19-7.127-6.339
			c-1.003-0.907-2.03-1.779-3.08-2.626c-1.182-0.943-2.304-1.958-3.402-2.997c-0.513-0.501-1.027-0.979-1.624-1.385
			c-1.433-1.003-2.746-2.149-3.928-3.45c-0.37-0.406-0.8-0.776-1.218-1.134c-0.669-0.573-1.361-1.098-2.018-1.671
			c-1.325-1.146-2.591-2.352-3.952-3.45c-0.872-0.704-1.743-1.373-2.388-2.304c-0.227-0.322-0.525-0.597-0.824-0.86
			c-1.504-1.289-3.056-2.543-4.537-3.868c-1.719-1.54-3.462-3.056-5.026-4.74c-1.098-1.17-2.292-2.173-3.51-3.2
			c-0.907-0.764-1.767-1.6-2.65-2.4c-1.015-1.552-2.543-2.615-3.761-3.976c-0.37-0.406-0.812-0.764-1.397-0.848
			c-0.322,0.131-0.501,0.382-0.609,0.692c-0.215,0.621-0.251,1.254-0.155,1.886c0.143,0.943,0.251,1.898,0.525,2.817
			c1.027,3.51,2.185,6.972,3.808,10.267c0.489,0.991,0.979,2.006,1.337,3.056c0.657,1.97,1.695,3.773,2.471,5.683
			c0.143,0.37,0.406,0.68,0.585,1.039c0.788,1.552,1.779,3.02,2.316,4.692c0.394,1.218,0.883,2.376,1.468,3.498
			c0.812,1.552,1.254,3.271,2.197,4.751c1.122,2.722,2.364,5.384,3.749,7.987c0.227,0.418,0.406,0.872,0.597,1.301
			c0.334,0.716,0.633,1.456,1.003,2.161c0.669,1.265,1.23,2.579,2.018,3.784c0.263,0.394,0.43,0.848,0.597,1.289
			c0.418,1.122,0.991,2.161,1.552,3.211c0.573,1.051,1.337,1.982,1.862,3.044c0.466,0.931,0.967,1.827,1.492,2.71
			c0.358,0.621,0.704,1.254,1.003,1.898c0.597,1.301,1.254,2.567,2.161,3.701c0.346,0.43,0.609,0.931,0.883,1.409
			c0.525,0.895,0.919,1.886,1.576,2.65c0.692,0.8,1.003,1.803,1.683,2.591c0.883,1.015,1.707,2.101,2.412,3.247
			c1.564,2.591,3.486,4.919,5.205,7.402c0.501,0.716,1.063,1.373,1.731,1.958c0.525,0.466,1.015,1.039,1.373,1.636
			c0.501,0.836,1.158,1.516,1.827,2.185c0.68,0.68,1.301,1.385,1.815,2.197c0.895,1.421,2.113,2.579,3.355,3.677
			c0.824,0.74,1.755,1.337,2.316,2.352c0.215,0.394,0.669,0.669,1.015,0.991c0.812,0.752,1.695,1.456,2.424,2.28
			c1.158,1.325,2.519,2.412,3.868,3.522c0.43,0.358,0.919,0.633,1.337,0.991c1.612,1.409,3.271,2.782,4.966,4.095
			c0.322,0.239,0.597,0.501,0.824,0.848c0.155,0.263,0.406,0.478,0.657,0.657c1.839,1.266,3.402,2.865,5.098,4.31
			c1.636,1.385,3.032,3.02,4.835,4.214c0.322,0.215,0.549,0.573,0.824,0.86c0.275,0.286,0.513,0.621,0.812,0.871
			c1.54,1.266,2.901,2.71,4.429,3.987c1.098,0.907,2.03,1.994,3.2,2.829c0.322,0.227,0.537,0.585,0.824,0.86
			c2.065,1.982,4.095,3.999,6.22,5.91c1.779,1.6,3.534,3.188,5.098,5.002c0.143,0.167,0.394,0.239,0.609,0.334
			c0.06,0.024,0.155-0.012,0.215-0.06s0.119-0.131,0.107-0.191c-0.012-0.155-0.012-0.334-0.084-0.466
			c-1.313-2.173-2.746-4.274-3.94-6.506c-0.155-0.275-0.322-0.549-0.537-0.788c-1.039-1.11-1.898-2.328-2.495-3.737
			c-0.155-0.37-0.382-0.704-0.573-1.051c-1.313-2.364-2.603-4.728-3.94-7.079c-0.895-1.588-1.528-3.319-2.662-4.775
			c-0.191-0.251-0.334-0.549-0.43-0.848c-0.716-2.101-1.839-4.071-2.113-6.22c-0.466-0.573-0.872-1.063-1.265-1.552
			c-1.301-1.612-2.591-3.223-3.88-4.823c-0.096-0.119-0.191-0.275-0.334-0.346c-0.8-0.454-1.23-1.218-1.695-1.946
			c-0.298-0.466-0.657-0.895-1.015-1.325c-0.298-0.37-0.68-0.68-0.943-1.074c-0.955-1.48-2.149-2.758-3.283-4.071
			c-0.466-0.537-0.871-1.134-1.289-1.707c-0.239-0.322-0.418-0.692-0.716-0.943c-0.86-0.716-1.504-1.624-2.28-2.412
			c-1.385-1.421-2.519-3.068-4.083-4.322c-0.179-0.143-0.334-0.334-0.478-0.537c-0.752-1.134-1.719-2.053-2.698-2.985
			c-0.979-0.931-1.815-1.994-2.746-2.985c-0.919-0.979-1.85-1.97-2.829-2.889c-0.466-0.442-0.907-0.871-1.17-1.48
			c-0.322-0.752-0.883-1.313-1.552-1.779c-0.597-0.406-1.086-0.919-1.54-1.48c-1.063-1.289-2.101-2.603-3.259-3.808
			c-1.146-1.206-2.292-2.424-3.211-3.82c-0.346-0.525-0.776-1.003-1.313-1.361c-0.191-0.131-0.37-0.298-0.513-0.489
			c-1.23-1.659-2.746-3.08-3.904-4.799c-0.489-0.728-1.003-1.433-1.492-2.149c-0.585-0.848-1.17-1.695-1.922-2.412
			c-0.573-0.537-1.182-1.122-1.54-1.803c-0.466-0.86-1.122-1.54-1.671-2.316c-0.406-0.585-0.824-1.182-1.11-1.827
			c-0.263-0.597-0.597-1.122-1.015-1.6c-0.8-0.895-1.433-1.898-2.041-2.925c-0.692-1.158-1.421-2.304-2.053-3.498
			c-0.68-1.254-1.468-2.447-2.185-3.677c-0.358-0.621-0.776-1.194-1.158-1.791c-0.107-0.167-0.072-0.31,0.119-0.442
			c0.239,0.179,0.549,0.31,0.728,0.549c1.027,1.409,2.209,2.698,3.152,4.178c1.11,1.731,2.221,3.474,3.641,5.002
			c1.027,1.098,1.779,2.435,2.853,3.51c0.107,0.107,0.191,0.263,0.239,0.406c0.191,0.549,0.561,0.931,0.967,1.325
			c1.146,1.098,2.34,2.161,3.152,3.558c0.084,0.131,0.191,0.251,0.298,0.382c1.516,1.839,3.307,3.426,4.692,5.384
			c0.179,0.251,0.466,0.43,0.704,0.633c0.298,0.263,0.633,0.501,0.872,0.8c1.182,1.516,2.638,2.746,3.916,4.155
			c1.063,1.17,2.268,2.232,3.128,3.593c0.263,0.406,0.633,0.692,1.039,0.967c0.525,0.346,1.086,0.728,1.456,1.218
			c1.146,1.54,2.626,2.77,3.976,4.107c0.68,0.68,1.456,1.289,1.982,2.053c0.645,0.943,1.492,1.647,2.292,2.412
			c0.406,0.382,0.836,0.728,1.23,1.122c1.659,1.707,3.211,3.522,5.014,5.086c0.119,0.107,0.263,0.215,0.31,0.346
			c0.346,0.955,1.194,1.456,1.886,2.101c0.525,0.49,1.027,1.003,1.492,1.528c1.421,1.6,2.817,3.235,4.25,4.835
			c0.573,0.657,1.158,1.277,1.552,2.089c0.227,0.478,0.657,0.895,1.086,1.242c0.501,0.394,0.967,0.812,1.373,1.313
			c1.815,2.316,3.617,4.632,5.444,6.924c0.692,0.872,1.433,1.695,2.185,2.519c0.43,0.466,0.919,0.872,1.361,1.325
			c1.158,1.194,2.256,2.447,3.2,3.832c0.501,0.716,1.17,1.265,1.982,1.659c1.218,0.597,2.447,1.17,3.57,1.91
			c2.065,1.349,4.298,2.4,6.28,3.904c0.501,0.382,1.074,0.704,1.659,0.919c0.836,0.31,1.54,0.836,2.244,1.337
			c0.716,0.501,1.349,1.146,2.101,1.54c1.206,0.609,2.4,1.254,3.605,1.85c2.209,1.086,4.453,2.089,6.697,3.14
			c0.215,0.096,0.454,0.179,0.633,0.334c0.621,0.537,1.361,0.8,2.125,1.027c0.382,0.119,0.74,0.275,1.098,0.466
			c1.194,0.621,2.507,1.003,3.665,1.707c0.394,0.239,0.895,0.298,1.325,0.43C142.055,354.287,142.127,353.953,141.9,353.678
			L141.9,353.678z M189.773,404.297c-1.158-0.74-2.053-1.743-3.008-2.71c-2.794-2.817-5.336-5.886-8.524-8.297
			c-0.06-0.048-0.107-0.119-0.155-0.179c-1.122-1.23-2.244-2.459-3.379-3.677c-0.322-0.346-0.669-0.68-1.015-1.003
			c-1.17-1.074-2.352-2.137-3.51-3.211c-0.239-0.215-0.489-0.43-0.657-0.68c-0.68-1.003-1.624-1.743-2.519-2.531
			c-0.358-0.31-0.716-0.609-0.991-1.015c-1.015-1.54-2.471-2.674-3.784-3.928c-0.752-0.716-1.516-1.397-2.197-2.185
			c-1.456-1.683-2.913-3.367-4.369-5.05c-0.513-0.597-1.158-1.122-1.564-1.779c-0.478-0.788-1.242-1.206-1.839-1.85
			c-1.504-1.647-2.889-3.379-4.19-5.169c-1.039-1.421-2.352-2.591-3.414-3.975c-0.143-0.179-0.394-0.263-0.573-0.418
			c-0.692-0.585-1.516-0.86-2.376-1.039c-1.946-0.394-3.868-0.883-5.695-1.719c-0.143-0.072-0.298-0.131-0.454-0.167
			c-1.397-0.334-2.71-0.919-4.047-1.421c-1.409-0.525-2.865-0.931-4.167-1.731c-0.203-0.119-0.442-0.191-0.657-0.286
			c-2.412-1.015-4.895-1.886-7.115-3.319c-0.334-0.215-0.716-0.346-1.074-0.501c-1.027-0.442-1.994-0.979-2.865-1.671
			c-0.501-0.394-1.039-0.716-1.588-1.051c-0.752-0.454-1.516-0.86-2.268-1.313c-0.883-0.525-1.707-1.17-2.638-1.612
			c-2.232-1.063-4.286-2.424-6.339-3.784c-1.063-0.704-2.089-1.445-3.14-2.161c-0.131-0.096-0.275-0.179-0.418-0.227
			s-0.334-0.084-0.442-0.012s-0.203,0.263-0.203,0.394c0.036,1.254,0.645,2.292,1.421,3.199c0.418,0.49,0.824,0.967,1.122,1.528
			c0.657,1.277,1.54,2.412,2.316,3.605c0.298,0.466,0.669,0.907,0.872,1.409c0.812,1.934,2.256,3.45,3.283,5.241
			c0.728,0.633,1.039,1.588,1.803,2.209s0.955,1.624,1.409,2.459c0.155,0.275,0.394,0.501,0.573,0.764
			c0.585,0.848,1.086,1.767,1.743,2.555c0.704,0.86,1.385,1.731,1.97,2.674c0.549,0.883,1.086,1.767,1.683,2.603
			c0.645,0.895,1.385,1.731,2.053,2.615c0.716,0.943,1.397,1.922,2.089,2.889c0.191,0.251,0.478,0.454,0.597,0.728
			c0.466,1.039,1.182,1.862,1.994,2.638c0.513,0.489,0.907,1.098,1.337,1.671c0.191,0.251,0.298,0.585,0.525,0.788
			c1.373,1.23,2.388,2.77,3.665,4.071c0.334,0.346,0.704,0.645,0.991,1.027c0.967,1.265,1.886,2.555,3.128,3.582
			c0.704,1.17,1.6,2.149,2.782,2.853c1.098,1.266,2.221,2.495,3.271,3.784c1.301,1.6,2.758,3.068,3.999,4.716
			c0.096,0.131,0.203,0.263,0.334,0.346c1.218,0.669,1.886,1.91,2.937,2.77c0.489,0.394,0.931,0.883,1.265,1.421
			c0.967,1.588,2.543,2.626,3.558,4.155c1.421,1.027,2.603,2.316,3.916,3.474c0.836,0.728,1.659,1.48,2.435,2.268
			c1.277,1.313,2.662,2.507,4.083,3.653c1.289,1.051,2.65,2.018,3.987,3.02c0.251,0.191,0.537,0.346,0.812,0.478
			c0.119,0.06,0.287,0.012,0.442,0.012c-0.012-0.131,0.024-0.322-0.048-0.406c-1.134-1.206-2.292-2.412-3.438-3.605
			c-0.549-0.573-1.074-1.146-1.791-1.552c-0.704-0.394-1.23-0.991-1.671-1.671c-0.394-0.597-0.824-1.17-1.218-1.767
			c-0.215-0.334-0.37-0.704-0.537-1.063c-0.179-0.382-0.024-0.776,0.382-1.003c1.265-0.68,2.579-1.218,4.011-1.468
			c0.943-0.155,1.874-0.37,2.794-0.585c0.334-0.072,0.537-0.31,0.597-0.716c-0.203-0.597-0.68-1.027-1.218-1.373
			c-0.752-0.478-1.433-1.003-1.994-1.683c-0.203-0.239-0.442-0.454-0.704-0.633c-1.707-1.182-3.104-2.734-4.632-4.107
			c-0.704-0.633-1.265-1.445-1.994-2.041c-0.812-0.669-1.468-1.433-2.065-2.292c-1.504-1.194-2.626-2.746-3.94-4.131
			c-0.597-0.633-1.11-1.349-1.659-2.018c-0.298-0.37-0.573-0.764-0.907-1.098c-1.182-1.182-2.101-2.579-3.271-3.761
			c-0.943-0.955-1.707-2.089-2.567-3.128c-0.406-0.49-0.883-0.919-1.242-1.433c-0.824-1.17-1.612-2.364-2.4-3.558
			c-0.119-0.191-0.239-0.394-0.131-0.645c0.06-0.155,0.37-0.143,0.633,0.036c1.492,1.051,2.973,2.125,4.298,3.379
			c0.513,0.49,1.098,0.907,1.659,1.337c0.251,0.191,0.561,0.31,0.8,0.513c1.528,1.266,3.08,2.507,4.441,3.964
			c0.752,0.812,1.48,1.659,2.292,2.412c0.931,0.86,2.006,1.552,2.722,2.626c0.167,0.251,0.466,0.43,0.704,0.645
			c1.946,1.659,3.677,3.498,5.229,5.539c0.334,0.442,0.68,0.895,1.11,1.242c0.752,0.597,1.349,1.337,1.97,2.065
			c0.406,0.489,0.872,0.931,1.301,1.385c0.275,0.286,0.609,0.537,0.812,0.86c1.158,1.815,2.722,3.319,4.047,4.99
			c0.442,0.561,0.967,1.051,1.695,1.445c0.382,0.036,0.86,0.119,1.325,0.119c2.232,0.024,4.441,0.334,6.65,0.561
			c2.065,0.203,4.095,0.573,6.101,1.086c1.695,0.43,3.391,0.848,5.086,1.254c0.191,0.048,0.43-0.072,0.657-0.131
			C190.788,405.085,190.299,404.632,189.773,404.297L189.773,404.297z M202.046,429.237c-0.669-1.063-1.337-1.946-2.173-2.698
			c-1.063-0.967-2.077-1.958-3.14-2.913c-2.603-2.352-5.563-4.19-8.632-5.874c-1.468-0.8-2.925-1.6-4.501-2.185
			c-0.442-0.167-0.883-0.37-1.182-0.764c-0.048-0.06-0.096-0.179-0.072-0.215c0.084-0.096,0.215-0.251,0.31-0.239
			c1.719,0.298,3.391,0.824,5.074,1.289c1.695,0.466,3.307,1.086,4.895,1.839c0.955,0.454,1.707,1.146,2.567,1.695
			c1.815,1.146,3.593,2.364,5.074,3.928c0.215,0.227,0.513,0.37,0.788,0.501c0.072,0.036,0.322-0.119,0.334-0.215
			c0.048-0.31,0.036-0.633,0.012-0.943c-0.143-1.683-0.776-3.223-1.385-4.775c-0.382-0.967-0.836-1.886-1.433-2.746
			c-0.633-0.919-1.289-1.815-1.898-2.746c-0.657-1.003-1.433-1.898-2.364-2.662c-1.624-0.442-3.271-0.692-4.943-0.74
			c-1.361-0.036-2.686-0.227-4.023-0.466c-1.098-0.191-2.209-0.322-3.319-0.37c-5.002-0.215-10.016-0.394-15.019-0.585
			c-0.872-0.036-1.743-0.072-2.615-0.084c-0.06,0-0.143,0.072-0.191,0.131c-0.107,0.143-0.096,0.322,0.072,0.394
			c0.573,0.275,1.098,0.609,1.564,1.063c0.513,0.49,1.086,0.931,1.695,1.313c0.752,0.478,1.373,1.086,2.006,1.671
			c1.456,1.349,2.913,2.698,4.429,3.987c1.194,1.015,2.459,1.994,3.402,3.295c0.31,0.43,0.824,0.728,1.277,1.051
			c0.848,0.597,1.755,1.098,2.388,1.946c0.143,0.191,0.358,0.31,0.549,0.454c2.065,1.624,4.274,3.032,6.638,4.19
			c0.645,0.31,1.349,0.549,1.898,0.979c0.895,0.704,1.922,1.11,2.937,1.564c1.158,0.525,2.316,1.051,3.51,1.492
			c1.265,0.466,2.567,0.824,3.784,1.421c0.275,0.131,0.621,0.143,0.931,0.203s0.645-0.179,0.716-0.478
			C202.201,431.087,202.142,430.216,202.046,429.237L202.046,429.237z M201.807,434.907c0.096-0.203,0.072-0.466-0.155-0.621
			c-0.669-0.442-1.397-0.645-2.185-0.549c-0.072,0.012-0.179,0.072-0.179,0.119c-0.024,0.131-0.048,0.31,0.024,0.394
			c0.525,0.621,1.313,0.764,2.03,1.122C201.521,435.218,201.724,435.086,201.807,434.907L201.807,434.907z M199.396,444.53
			c0.346-0.883,0.561-1.839,0.991-2.686c0.704-1.385,0.86-2.877,1.098-4.37c0.107-0.669-0.287-1.182-1.015-1.409
			c-1.445-0.442-2.877-0.907-4.334-1.313c-1.6-0.454-3.2-0.979-4.835-1.254c-2.03-0.346-4.095-0.525-6.16-0.669
			c-2.877-0.191-5.707,0.286-8.56,0.609c-0.239,0.024-0.466,0.096-0.704,0.143c-0.86,0.191-1.707,0.358-2.555,0.561
			c-2.006,0.49-4.047,0.86-5.981,1.612c-0.143,0.06-0.298,0.107-0.454,0.131c-0.155,0.024-0.322,0.036-0.478,0.024
			c-0.072,0-0.203-0.06-0.203-0.096c0-0.131,0-0.322,0.072-0.394c0.883-0.716,2.03-0.883,3.02-1.385
			c2.209-1.122,4.68-1.516,6.936-2.507c1.254-0.179,2.483-0.585,3.761-0.633c1.671-0.06,3.331-0.155,5.002-0.239
			c0.227-0.012,0.454-0.084,0.692-0.143c0.191-0.048,0.322-0.454,0.179-0.609c-0.155-0.167-0.31-0.394-0.513-0.454
			c-1.468-0.478-2.734-1.254-3.904-2.244c-0.418-0.358-0.907-0.657-1.397-0.907c-1.85-0.943-3.462-2.232-5.05-3.558
			c-1.218-1.015-2.4-2.077-3.617-3.092c-0.43-0.358-0.883-0.68-1.54-0.633c-0.179,0.084-0.406,0.155-0.597,0.286
			c-0.967,0.704-1.91,1.421-2.746,2.042c-0.704,1.051-1.134,1.827-1.683,2.495c-0.931,1.11-1.707,2.28-2.244,3.629
			c-0.143,0.37-0.334,0.728-0.573,1.039c-0.728,0.872-1.182,1.898-1.564,2.937c-0.489,1.349-1.063,2.65-1.671,3.952
			c-0.263,0.573-0.454,1.182-0.692,1.779c-0.203,0.513-0.418,1.027-0.645,1.54c-0.633,1.456-1.289,2.901-1.922,4.358
			c-0.06,0.143-0.048,0.31-0.072,0.466c-0.024,0.179,0.239,0.513,0.418,0.513c0.239,0,0.489,0.036,0.692-0.048
			c0.74-0.298,1.468-0.597,2.185-0.955c2.041-1.027,4.202-1.445,6.459-1.385c1.504,0.036,3.02,0.167,4.525,0.286
			c0.872,0.072,1.731,0.239,2.603,0.287c2.949,0.143,5.826,0.776,8.691,1.433c3.259,0.752,6.495,1.504,9.837,1.839
			c2.221,0.215,4.453,0.203,6.674,0.203c0.084,0,0.155-0.012,0.239-0.012C198.524,445.64,199.109,445.27,199.396,444.53
			L199.396,444.53z M197.533,446.774c-0.394-0.036-0.788-0.06-1.182-0.048c-1.349,0.048-2.698,0.119-4.047,0.155
			c-1.433,0.036-2.877,0.012-4.274-0.31s-2.806-0.549-4.202-0.872c-3.02-0.704-6.112-1.003-9.169-1.516
			c-0.931-0.155-1.898-0.107-2.698-0.155c-1.612,0.728-2.161,0.979-2.531,1.576c-0.931,1.48-2.185,2.734-3.104,4.214
			c-0.251,0.406-0.442,0.848-0.561,1.301c-0.263,0.931-0.621,1.803-1.134,2.615c-0.167,0.263-0.322,0.549-0.406,0.86
			c-0.669,2.364-1.48,4.692-1.6,7.199c-0.096,1.982-0.143,3.975-0.704,5.91c-0.024,0.072,0.012,0.155,0,0.239
			c-0.036,0.287,0.37,0.609,0.657,0.513c0.227-0.072,0.466-0.131,0.68-0.227c2.089-0.967,4.19-1.922,6.423-2.519
			c0.298-0.084,0.621-0.191,0.872-0.358c0.549-0.358,1.146-0.525,1.779-0.633c0.549-0.096,1.086-0.239,1.624-0.37
			c3.402-0.848,6.841-1.397,10.339-1.659c2.877-0.215,5.707-0.024,8.536,0.537c0.143,0.024,0.298-0.06,0.466-0.096
			c0.609-0.466,0.716-1.194,0.907-1.874c0.37-1.385,0.704-2.77,1.051-4.155c0.036-0.155,0.072-0.31,0.096-0.466
			c0.06-0.501-0.048-0.943-0.251-1.098c-1.373-0.489-2.495-0.871-3.617-1.277c-1.349-0.501-2.734-0.848-4.167-0.955
			c-2.865-0.215-5.719-0.179-8.584,0.036c-0.394,0.036-0.788,0.072-1.182,0.155c-1.719,0.358-3.367,0.943-4.99,1.588
			c-0.215,0.084-0.489,0.155-0.633,0.322c-0.86,0.955-2.161,1.337-2.997,2.328c-0.203,0.227-0.501,0.37-0.776,0.537
			c-0.155,0.096-0.298,0.036-0.43-0.251c0.227-0.346,0.585-0.669,0.848-1.051c0.31-0.466,0.561-0.967,0.895-1.397
			c0.287-0.37,0.657-0.692,1.015-1.003c1.659-1.409,3.629-2.268,5.635-3.032c2.388-0.907,4.871-1.433,7.45-1.445
			c1.349-0.012,2.698,0.024,4.059,0.036c1.707,0.012,3.307,0.466,4.847,1.134c0.872,0.382,1.719,0.824,2.579,1.23
			c0.287,0.131,0.573,0.239,0.776,0.334c0.442-0.095,0.692-0.275,0.812-0.597c0.454-1.277,0.895-2.555,1.325-3.832
			c0.072-0.227,0.119-0.466,0.167-0.692C198.202,447.276,197.903,446.81,197.533,446.774L197.533,446.774z M217.435,430.932
			c-0.096,0.549-0.167,1.098-0.263,1.648c-0.072,0.394-0.096,0.812-0.263,1.158c-0.501,1.015-0.692,2.125-0.907,3.2
			c-0.454,2.185-0.967,4.346-1.528,6.494c-0.442,1.683-0.728,3.414-1.182,5.11c-0.716,2.698-1.194,5.432-1.815,8.142
			c-0.072,0.31-0.096,0.633-0.227,0.931c-0.68,1.624-1.015,3.343-1.516,5.014c-0.346,1.158-0.585,2.304-0.68,3.498
			c-0.06,0.8-0.263,1.54-0.561,2.292c-0.525,1.337-0.645,2.782-0.907,4.178c-0.155,0.86-0.239,1.731-0.37,2.603
			c-0.382,2.531-0.442,5.074-0.31,7.617c0.06,1.11,0.167,2.221,0.501,3.295c0.107,0.37,0.119,0.788,0.167,1.182
			c0.012,0.072-0.036,0.143-0.155,0.513c-1.122-2.077-1.468-4.107-1.695-6.16c-0.37-3.331-0.263-6.674,0.06-10.004
			c0.155-1.576,0.525-3.128,0.812-4.692c0.358-1.958,0.764-3.904,1.086-5.862c0.31-1.886,0.657-3.749,1.146-5.599
			c0.179-0.692,0.298-1.409,0.346-2.113c0.155-2.388,0.728-4.716,1.074-7.079c0.119-0.86,0.478-1.671,0.68-2.519
			c0.406-1.695,0.788-3.402,1.182-5.11c0.072-0.31,0.155-0.621,0.179-0.931c0.215-1.982,0.633-3.928,0.955-5.886
			c0.358-2.125,0.37-4.274,0.298-6.423c-0.06-1.827-0.167-3.653-0.692-5.432c-0.096-0.31-0.167-0.621-0.203-0.931
			c-0.215-1.862-1.027-3.498-1.994-5.062c-0.215-0.334-0.454-0.657-0.621-1.015c-0.346-0.716-0.597-1.48-0.991-2.173
			c-0.394-0.692-0.955-1.277-1.337-1.958c-0.466-0.848-1.074-1.576-1.719-2.28c-0.8-0.883-1.6-1.767-2.364-2.686
			c-2.232-2.674-3.737-4.214-6.53-6.59c-0.907-0.776-1.779-1.588-2.698-2.34c-1.421-1.158-2.531-2.626-3.892-3.856
			c-1.767-1.6-3.498-3.247-5.122-4.99c-0.322-0.346-0.716-0.621-1.074-0.943c-0.239-0.203-0.537-0.382-0.704-0.633
			c-0.86-1.361-2.232-2.268-3.14-3.57c-0.263-0.382-0.752-0.597-1.039-0.967c-0.633-0.824-1.6-1.266-2.209-2.137
			c-0.525-0.764-1.325-1.349-1.994-2.03c-0.728-0.74-1.456-1.468-2.137-2.244c-1.301-1.504-2.817-2.794-3.987-4.405
			c-0.191-0.251-0.418-0.478-0.657-0.692c-2.03-1.791-3.832-3.784-5.528-5.886c-0.096-0.119-0.191-0.275-0.322-0.346
			c-1.17-0.752-1.934-1.862-2.794-2.901c-0.812-0.979-1.767-1.827-2.65-2.746c-1.158-1.206-2.364-2.352-3.295-3.761
			c-0.167-0.251-0.43-0.454-0.657-0.68c-0.394-0.394-0.8-0.764-1.182-1.158c-2.077-2.197-4.107-4.417-6.005-6.769
			c-0.597-0.74-1.206-1.468-1.982-2.053c-0.37-0.287-0.692-0.657-0.979-1.039c-0.872-1.134-1.803-2.209-2.829-3.211
			c-0.788-0.776-1.433-1.707-2.113-2.579c-0.489-0.633-0.931-1.289-1.421-1.922c-0.346-0.43-0.68-0.907-1.122-1.23
			c-0.74-0.537-1.206-1.23-1.648-2.006c-0.346-0.621-0.8-1.182-1.254-1.731c-0.716-0.86-1.337-1.767-1.803-2.794
			c-0.263-0.573-0.645-1.086-1.039-1.588c-0.812-1.074-1.636-2.149-2.221-3.379c-0.454-0.955-1.265-1.636-1.803-2.507
			c-0.501-0.812-0.919-1.659-1.421-2.471c-0.251-0.406-0.573-0.764-0.907-1.098c-0.645-0.621-1.158-1.313-1.492-2.137
			c-0.394-0.991-1.23-1.636-1.97-2.328c-0.107-0.096-0.298-0.084-0.442-0.155c-0.191-0.096-0.215-0.466-0.024-0.609
			c0.107-0.084,0.346-0.155,0.418-0.096c0.764,0.573,1.516,1.146,2.053,1.97c0.251,0.394,0.585,0.764,0.931,1.086
			c1.85,1.767,3.414,3.761,4.859,5.862c0.31,0.454,0.657,0.895,1.039,1.313c0.8,0.883,1.612,1.743,2.197,2.806
			c0.298,0.549,0.728,1.063,1.182,1.492c0.824,0.776,1.468,1.648,1.886,2.71c0.179,0.442,0.525,0.788,0.931,1.063
			c0.394,0.263,0.8,0.537,1.11,0.883c1.158,1.313,2.268,2.662,3.402,3.987c0.263,0.298,0.561,0.561,0.848,0.824
			c0.692,0.657,1.337,1.337,1.886,2.137c0.442,0.645,1.039,1.194,1.648,1.707c1.659,1.373,3.223,2.817,4.584,4.489
			c0.346,0.43,0.74,0.836,1.146,1.206c0.597,0.537,1.122,1.11,1.624,1.743c0.489,0.609,1.051,1.206,1.683,1.671
			c0.895,0.669,1.468,1.647,2.376,2.316c0.883,0.645,1.648,1.504,2.4,2.316c1.086,1.158,2.113,2.376,3.176,3.558
			c0.263,0.298,0.561,0.561,0.848,0.836c1.492,1.421,3.104,2.722,4.429,4.31c0.203,0.239,0.466,0.43,0.704,0.645
			c1.731,1.636,3.331,3.402,4.931,5.169c0.322,0.346,0.633,0.716,1.003,1.015c1.624,1.289,2.937,2.889,4.334,4.417
			c0.43,0.466,0.86,0.919,1.373,1.313c0.621,0.478,1.242,1.003,1.719,1.624c0.633,0.824,1.421,1.504,2.113,2.256
			c0.812,0.883,1.695,1.671,2.567,2.483c0.86,0.812,1.612,1.743,2.424,2.626c0.322,0.346,0.621,0.74,0.991,1.015
			c1.086,0.812,2.006,1.803,2.949,2.77c1.934,1.994,3.916,3.952,5.886,5.921c0.275,0.286,0.573,0.561,0.883,0.788
			c1.886,1.349,3.45,3.056,5.086,4.668c1.755,1.719,3.45,3.51,5.002,5.432c0.394,0.489,0.776,0.967,1.098,1.528
			c0.382,0.68,0.931,1.289,1.48,1.862c0.669,0.692,1.206,1.48,1.648,2.328c1.528,2.973,3.092,5.921,4.011,9.157
			c0.8,1.516,0.967,3.199,1.17,4.835C217.96,424.581,217.96,427.768,217.435,430.932L217.435,430.932z M388.107,434.418
			c-0.513,0.334-1.051,0.645-1.6,0.931c-0.119,0.06-0.31,0.024-0.454-0.012c-0.239-0.06-0.454-0.215-0.442-0.466
			c0.012-0.203,0.095-0.513,0.239-0.597c0.621-0.346,1.301-0.525,2.006-0.49c0.131,0,0.251,0.143,0.37,0.227
			C388.19,434.131,388.19,434.358,388.107,434.418L388.107,434.418z M426.56,444.506c-0.048,0.048-0.155,0.036-0.227,0.036
			c-0.31,0-0.561-0.131-0.776-0.322c0.286,0.012,0.561,0.012,0.836,0.036C426.62,444.279,426.692,444.386,426.56,444.506
			L426.56,444.506z M425.498,444.172c0.024,0.012,0.036,0.036,0.06,0.048c-0.036,0-0.072,0-0.107,0L425.498,444.172L425.498,444.172
			z M425.45,444.219c-0.048-0.012-0.084-0.048-0.131-0.072c0.06,0.012,0.119,0.012,0.179,0.012L425.45,444.219L425.45,444.219z
			 M424.602,444.076c0.346-0.191,0.537-0.048,0.716,0.072C425.116,444.124,424.889,444.112,424.602,444.076L424.602,444.076z
			 M201.807,434.907c-0.084,0.179-0.287,0.298-0.466,0.478c-0.728-0.37-1.516-0.501-2.03-1.122
			c-0.072-0.084-0.036-0.263-0.024-0.394c0.012-0.048,0.119-0.107,0.179-0.119c0.8-0.096,1.528,0.107,2.185,0.549
			C201.879,434.442,201.915,434.704,201.807,434.907L201.807,434.907z M163.712,444.219c-0.036,0-0.072,0-0.107,0
			c0.024-0.012,0.036-0.036,0.06-0.06L163.712,444.219L163.712,444.219z M162.601,444.506c-0.131-0.119-0.06-0.227,0.167-0.251
			c0.275-0.024,0.549-0.024,0.836-0.036c-0.215,0.191-0.466,0.31-0.776,0.31C162.745,444.542,162.637,444.554,162.601,444.506
			L162.601,444.506z M164.607,444.076c-0.334,0.036-0.561,0.06-0.776,0.072C164.01,444.028,164.213,443.897,164.607,444.076
			L164.607,444.076z M163.664,444.172c0.048,0,0.107-0.012,0.155-0.012c-0.036,0.024-0.072,0.048-0.119,0.072L163.664,444.172
			L163.664,444.172z"
          />
        </g>
      </g>
      <g id="text_1_">
        <g id="Shape_1_copy">
          <path
            d="M209.842,640.475c-1.074,2.483-2.268,4.919-3.784,7.163c-0.895,1.325-1.886,2.579-3.116,3.605
			c-0.669,0.561-1.373,1.098-2.125,1.528c-1.564,0.907-3.283,1.194-5.062,0.955c-2.519-0.346-3.773-2.113-3.88-4.143
			c-0.084-1.433,0.012-2.853,0.394-4.262c0.466-1.695,1.086-3.331,1.779-4.943c0.501-1.17,1.015-2.34,1.504-3.51
			c0.251-0.597,0.334-1.218,0.215-1.862c-0.072-0.346-0.466-0.728-0.812-0.776c-0.489-0.06-0.943,0.036-1.361,0.31
			c-0.609,0.406-1.051,0.955-1.445,1.576c-0.561,0.883-0.931,1.85-1.265,2.829c-0.645,1.886-1.265,3.773-1.898,5.659
			c-0.764,2.256-1.528,4.525-2.304,6.781c-0.155,0.442-0.37,0.871-0.573,1.349c-2.077,0.358-4.143,0.107-6.196,0.167
			c-0.394,0.012-0.8,0.012-1.134-0.286c-0.143-0.286-0.096-0.597,0.012-0.895c0.478-1.433,0.967-2.865,1.456-4.298
			c1.086-3.235,2.185-6.483,3.259-9.73c0.275-0.824,0.478-1.683,0.657-2.531c0.143-0.692-0.68-1.421-1.361-1.277
			c-0.31,0.072-0.645,0.143-0.883,0.322c-0.442,0.322-0.872,0.692-1.23,1.11c-0.573,0.669-0.967,1.456-1.254,2.292
			c-0.489,1.433-0.979,2.865-1.468,4.286c-1.074,3.164-2.149,6.327-3.235,9.479c-0.155,0.442-0.358,0.871-0.561,1.361
			c-0.406,0.06-0.8,0.167-1.182,0.155c-1.982-0.048-3.964,0.203-5.933-0.167c-0.072-0.012-0.119-0.084-0.191-0.131
			c-0.131-0.501,0.131-0.931,0.275-1.385c0.442-1.361,0.919-2.71,1.349-4.071c0.096-0.298,0.107-0.621,0.131-0.931
			c0.012-0.06-0.072-0.143-0.143-0.191c-0.06-0.036-0.167-0.072-0.215-0.048c-0.346,0.191-0.692,0.382-1.015,0.597
			c-1.027,0.657-2.125,1.074-3.343,1.182c-0.549,0.048-1.098,0.131-1.504,0.179c-0.692,0.31-0.967,0.812-1.313,1.242
			c-0.812,0.979-1.683,1.91-2.698,2.686c-1.707,1.289-3.605,1.982-5.766,1.922c-2.471-0.06-4.226-1.23-5.289-3.438
			c-0.239-0.501-0.394-1.039-0.609-1.552c-0.084-0.215-0.251-0.382-0.418-0.657c-0.275,0.215-0.549,0.382-0.752,0.609
			c-0.442,0.466-0.824,0.967-1.265,1.433c-0.931,0.991-1.958,1.874-3.14,2.555c-1.492,0.872-3.104,1.23-4.823,0.991
			c-2.376-0.322-3.82-1.874-4.059-4.023c-0.084-0.704-0.119-1.421-0.179-2.197c-0.287,0.06-0.573,0.024-0.692,0.155
			c-0.478,0.525-0.907,1.11-1.361,1.659c-1.074,1.289-2.292,2.435-3.737,3.307c-1.397,0.848-2.901,1.266-4.549,1.158
			c-0.728-0.048-1.421-0.227-2.065-0.537c-1.063-0.49-1.791-1.313-2.173-2.412c-0.43-1.23-0.525-2.483-0.31-3.761
			c0.06-0.382,0.203-0.776-0.119-1.134c-0.143,0.012-0.322-0.012-0.442,0.06c-0.418,0.227-0.812,0.501-1.218,0.752
			c-0.895,0.549-1.862,0.895-2.913,1.003c-0.633,0.06-1.254,0.155-1.922,0.251c-0.418,0.478-0.836,0.955-1.254,1.433
			c-0.776,0.919-1.6,1.779-2.555,2.495c-1.791,1.337-3.784,1.994-6.029,1.862c-1.862-0.107-3.343-0.931-4.417-2.459
			c-0.692-1.003-1.074-2.125-1.254-3.319c-0.096-0.633-0.096-1.266-0.179-1.898c-0.024-0.215-0.167-0.43-0.251-0.621
			c-0.704-0.179-1.265,0.239-1.898,0.286c-0.633,0.06-1.265,0.036-1.898-0.024s-1.134-0.609-1.815-0.489
			c-0.597,0.31-0.836,0.931-1.194,1.445c-1.027,1.528-2.137,2.973-3.474,4.238c-0.872,0.824-1.815,1.54-2.901,2.065
			c-1.015,0.49-2.065,0.788-3.211,0.788c-2.937,0.012-4.405-1.97-4.656-4.178c-0.155-1.421-0.143-2.865,0.191-4.274
			c0.275-1.158,0.573-2.316,0.943-3.45c2.806-8.452,5.635-16.905,8.452-25.345c0.179-0.525,0.382-1.039,0.597-1.612
			c0.298-0.107,0.585-0.31,0.872-0.31c1.994-0.036,3.975-0.024,5.969-0.012c0.215,0,0.418,0.191,0.621,0.286
			c0.024,0.764-0.263,1.421-0.489,2.101c-2.686,7.999-5.36,15.986-8.035,23.984c-0.454,1.361-0.919,2.71-1.349,4.071
			c-0.215,0.681-0.394,1.373-0.513,2.077c-0.048,0.298,0.048,0.633,0.143,0.931c0.096,0.31,0.358,0.525,0.68,0.609
			c0.573,0.155,1.122,0.096,1.6-0.239c0.454-0.322,0.895-0.669,1.265-1.074c1.361-1.48,2.34-3.211,3.152-5.038
			c0.155-0.346,0.203-0.776,0.215-1.158c0.024-0.633-0.036-1.266-0.06-1.91c-0.048-2.173,0.334-4.274,1.003-6.339
			c0.346-1.063,0.812-2.077,1.492-2.973c0.394-0.525,0.895-0.895,1.528-1.098c0.597-0.191,1.349,0.155,1.588,0.716
			c0.382,0.907,0.454,1.85,0.287,2.805c-0.215,1.254-0.489,2.495-0.955,3.689c-0.489,1.254-1.015,2.507-1.516,3.761
			c-0.215,0.513-0.454,1.015-0.633,1.54c-0.322,0.919,0.394,1.922,1.433,2.03c0.573,0.06,1.098-0.084,1.6-0.382
			c0.848-0.501,1.421-1.266,1.874-2.113c0.609-1.122,1.218-2.232,1.827-3.355c0.848-1.54,1.886-2.949,3.104-4.214
			c1.051-1.098,2.209-2.053,3.498-2.865c1.373-0.872,2.853-1.397,4.441-1.648c1.039-0.167,2.053-0.084,3.056,0.251
			c0.872,0.286,1.54,0.812,2.03,1.576c0.609,0.955,0.955,2.006,1.027,3.14c0.143,2.161-0.06,4.298-0.74,6.363
			c-0.322,0.979-0.704,1.946-1.051,2.913c-0.155,0.442-0.322,0.895-0.466,1.289c0.155,0.191,0.215,0.37,0.346,0.406
			c0.382,0.143,0.788,0.096,1.17-0.036c1.492-0.537,2.579-1.552,3.379-2.889c0.454-0.752,0.836-1.528,1.254-2.304
			c0.346-0.191,0.68-0.382,1.015-0.573c0.096-0.131,0.215-0.251,0.263-0.406c0.991-2.937,1.982-5.874,2.961-8.823
			c0.143-0.454,0.227-0.919,0.334-1.409c-0.239-0.131-0.43-0.322-0.645-0.346c-0.633-0.072-1.265-0.084-1.898-0.131
			c-0.322-0.024-0.549-0.382-0.466-0.704c0.143-0.537,0.251-1.086,0.728-1.564c0.919-0.394,1.982-0.036,2.949-0.298
			c0.442-0.167,0.633-0.525,0.764-0.895c0.406-1.122,0.764-2.256,1.158-3.391c0.227-0.669,0.489-1.337,0.669-1.839
			c0.382-0.49,0.776-0.573,1.158-0.669c2.149-0.573,4.298-1.146,6.447-1.707c0.31-0.084,0.669,0.251,0.609,0.537
			c-0.072,0.31-0.131,0.621-0.239,0.919c-0.573,1.743-1.158,3.474-1.719,5.205c-0.119,0.37-0.227,0.764-0.322,1.146
			c-0.06,0.251,0.239,0.669,0.489,0.704c0.084,0.012,0.155,0.048,0.239,0.048c1.827,0,3.653,0.012,5.492,0
			c0.418,0,0.764-0.239,0.919-0.657c0.513-1.421,0.991-2.853,1.492-4.274c0.191-0.525,0.406-1.027,0.621-1.552
			c0.334-0.119,0.621-0.251,0.919-0.334c2.149-0.585,4.298-1.17,6.447-1.743c0.143-0.036,0.334-0.048,0.466,0.012
			c0.119,0.048,0.275,0.239,0.251,0.334c-0.107,0.537-0.251,1.086-0.418,1.612c-0.466,1.433-0.967,2.865-1.445,4.298
			c-0.203,0.597-0.358,1.206-0.501,1.695c0.131,0.513,0.442,0.597,0.752,0.633c0.633,0.06,1.265,0.084,1.898,0.131
			c0.251,0.012,0.513,0.454,0.442,0.74c-0.048,0.227-0.096,0.466-0.191,0.68c-0.119,0.286-0.298,0.549-0.442,0.812
			c-1.11,0.43-2.268-0.012-3.307,0.358c-0.525,0.358-0.669,0.895-0.848,1.421c-1.098,3.235-2.185,6.483-3.283,9.718
			c-0.585,1.731-1.17,3.462-1.743,5.205c-0.227,0.68-0.43,1.373-0.597,2.065c-0.107,0.466-0.084,0.943,0.06,1.409
			c0.143,0.418,0.597,0.8,1.063,0.836c0.489,0.036,0.967-0.024,1.373-0.31c0.394-0.275,0.8-0.549,1.11-0.895
			c1.636-1.767,2.997-3.701,3.725-6.041c0.872-2.77,2.268-5.253,4.178-7.438c1.206-1.385,2.579-2.591,4.143-3.581
			c1.361-0.872,2.853-1.397,4.441-1.659c1.039-0.167,2.053-0.084,3.056,0.251c0.955,0.31,1.671,0.907,2.161,1.767
			c0.513,0.907,0.836,1.886,0.907,2.937c0.143,2.173-0.06,4.298-0.74,6.363c-0.298,0.907-0.621,1.803-0.967,2.698
			c-0.203,0.525-0.394,1.039-0.525,1.456c0.131,0.513,0.454,0.573,0.764,0.549c0.31-0.024,0.633-0.084,0.931-0.191
			c1.385-0.549,2.412-1.528,3.164-2.794c0.454-0.752,0.848-1.528,1.289-2.34c0.322-0.167,0.669-0.334,1.027-0.513
			c0.096-0.119,0.239-0.239,0.287-0.37c0.728-2.101,1.433-4.214,2.149-6.327c0.287-0.824,0.597-1.648,0.883-2.447
			c0.275-0.107,0.489-0.263,0.704-0.263c1.827-0.024,3.653-0.024,5.492-0.012c0.215,0,0.43,0.155,0.657,0.239
			c-0.084,0.657-0.167,1.277-0.239,1.898c-0.012,0.06,0.084,0.131,0.143,0.179c0.06,0.036,0.167,0.06,0.203,0.024
			c0.501-0.382,1.003-0.764,1.504-1.146c1.015-0.776,2.089-1.433,3.331-1.815c0.86-0.263,1.719-0.358,2.591-0.263
			c1.433,0.167,2.555,0.824,3.164,2.197c0.191,0.43,0.394,0.86,0.597,1.289c0.024,0.06,0.119,0.095,0.179,0.131
			c0.418-0.072,0.692-0.37,0.991-0.621c0.68-0.549,1.325-1.146,2.053-1.624c1.504-0.979,3.152-1.504,4.966-1.397
			c0.322,0.024,0.633,0.048,0.943,0.119c1.158,0.275,2.018,0.943,2.459,2.065c0.382,0.967,0.609,1.982,0.43,3.032
			c-0.167,1.015-0.322,2.042-0.728,3.009c-0.561,1.313-1.098,2.638-1.683,3.952c-0.549,1.242-1.158,2.447-1.683,3.689
			c-0.31,0.728-0.537,1.492-0.752,2.256c-0.084,0.298-0.048,0.633,0,0.943c0.084,0.537,0.645,0.979,1.182,1.003
			c0.406,0.012,0.788-0.048,1.146-0.251c0.633-0.358,1.146-0.848,1.624-1.397c0.883-1.027,1.648-2.149,2.28-3.355
			c0.657-1.266,1.277-2.567,1.91-3.844c0.143-0.298,0.346-0.525,0.669-0.633c0.537,0.107,1.098,0.203,1.409,0.752
			C209.866,640.165,209.902,640.344,209.842,640.475L209.842,640.475z M111.171,633.897c-0.012-0.072-0.048-0.155-0.06-0.227
			c-0.227-0.907-1.17-1.385-2.041-1.074c-0.621,0.227-1.11,0.585-1.552,1.051c-0.824,0.871-1.445,1.886-1.97,2.961
			c-1.051,2.137-1.827,4.381-2.424,6.686c-0.203,0.764-0.322,1.552-0.43,2.34c-0.096,0.716-0.06,1.421,0.215,2.101
			c0.251,0.633,0.776,1.051,1.445,1.015c0.382-0.024,0.812-0.072,1.134-0.251c0.776-0.43,1.409-1.015,1.636-1.874
			c-1.409-4.274,0.251-7.282,3.534-8.894C111.338,636.428,111.35,635.175,111.171,633.897L111.171,633.897z M137.865,628.274
			c-0.131-0.084-0.263-0.239-0.394-0.239c-1.827-0.048-3.641-0.072-5.468-0.072c-0.298,0-0.609,0.119-0.991,0.215
			c-0.179,0.43-0.287,0.645-0.358,0.871c-1.803,5.336-3.605,10.673-5.396,16.009c-0.203,0.597-0.322,1.23-0.418,1.85
			c-0.048,0.298,0.024,0.633,0.072,0.943c0.072,0.478,0.501,0.86,0.979,0.907c0.573,0.06,1.134-0.012,1.576-0.382
			c0.549-0.442,1.098-0.919,1.564-1.445c1.122-1.254,1.982-2.674,2.734-4.167c0.501-0.991,0.943-2.006,1.433-3.009
			c0.072-0.131,0.191-0.239,0.287-0.358c0.358-0.143,0.716-0.286,1.098-0.454c1.17-3.402,2.352-6.769,3.343-10.195
			C137.972,628.597,137.889,628.429,137.865,628.274L137.865,628.274z M162.733,634.005c-0.024-0.155-0.084-0.298-0.131-0.454
			c-0.239-0.788-1.134-1.23-1.898-0.967c-0.621,0.203-1.134,0.549-1.588,1.015c-0.836,0.86-1.48,1.862-1.994,2.937
			c-1.313,2.722-2.316,5.551-2.817,8.548c-0.107,0.621-0.119,1.265-0.072,1.886c0.024,0.382,0.203,0.764,0.37,1.11
			c0.179,0.37,0.525,0.585,0.931,0.657c0.478,0.084,0.967,0.06,1.397-0.179c0.776-0.418,1.421-1.003,1.695-1.85
			c-1.337-4.74,0.275-7.235,3.593-9.049C162.876,636.524,162.912,635.282,162.733,634.005L162.733,634.005z M286.51,555.641
			c-2.495,7.462-5.002,14.923-7.497,22.397c-0.633,1.886-1.265,3.773-1.898,5.659c-0.227,0.681-0.466,1.361-0.633,2.053
			c-0.095,0.37-0.084,0.788-0.06,1.182c0.048,0.74,0.836,1.301,1.54,1.086c0.37-0.107,0.776-0.251,1.063-0.501
			c0.549-0.454,1.063-0.967,1.528-1.504c0.836-0.967,1.504-2.053,2.101-3.176c0.549-1.063,1.051-2.137,1.576-3.211
			c0.167-0.358,0.322-0.728,0.657-0.991c0.692-0.155,1.218,0.131,1.647,0.633c-0.024,0.167-0.012,0.346-0.072,0.478
			c-1.182,2.782-2.519,5.492-4.334,7.939c-1.051,1.409-2.256,2.674-3.665,3.725c-1.039,0.776-2.185,1.301-3.45,1.552
			c-0.872,0.179-1.731,0.179-2.603-0.036c-1.242-0.298-2.161-1.003-2.65-2.197c-0.239-0.585-0.382-1.206-0.621-1.791
			c-0.072-0.167-0.334-0.251-0.561-0.406c-0.501,0.597-0.943,1.146-1.421,1.671c-1.206,1.325-2.662,2.256-4.417,2.686
			c-2.435,0.597-4.441-0.251-5.671-2.4c-0.358-0.621-0.585-1.301-0.776-1.994c-0.143-0.525-0.096-1.122-0.585-1.576
			c-0.179,0.107-0.406,0.179-0.549,0.322c-0.609,0.633-1.158,1.313-1.779,1.922c-1.803,1.743-3.856,3.044-6.315,3.677
			c-1.564,0.406-3.152,0.478-4.74,0.239c-2.077-0.31-3.701-1.385-4.811-3.2c-0.334-0.537-0.549-1.146-0.86-1.695
			c-0.107-0.191-0.334-0.31-0.501-0.454c-0.645,0.31-0.955,0.895-1.385,1.361c-0.812,0.883-1.683,1.695-2.662,2.388
			c-0.991,0.692-2.065,1.194-3.247,1.456c-1.277,0.275-2.531,0.191-3.749-0.286c-1.349-0.537-2.221-1.516-2.567-2.925
			c-0.227-0.931-0.334-1.886-0.215-2.841c0.084-0.633,0.179-1.266,0.239-1.898c0.024-0.215-0.084-0.442-0.131-0.645
			c-0.573-0.131-0.943,0.239-1.373,0.406c-1.289,0.513-2.579,0.716-3.964,0.418c-2.173-0.478-3.486-1.827-4.095-3.88
			c-0.322-1.074-0.382-2.173-0.227-3.307c0.275-1.994,0.776-3.892,1.743-5.659c0.657-1.194,1.48-2.268,2.567-3.104
			c0.967-0.752,2.065-1.194,3.307-1.146c0.31,0.012,0.633,0.072,0.931,0.167c0.466,0.155,0.824,0.454,1.063,0.907
			c0.382,0.74,0.478,1.504,0.298,2.316c-0.31,1.349-0.907,2.543-1.886,3.534c-1.015,1.039-2.173,1.862-3.51,2.423
			c-0.513,0.215-1.039,0.394-1.397,0.537c-0.466,0.394-0.525,0.776-0.549,1.182c-0.036,0.74,0.167,1.421,0.501,2.065
			c0.943,1.827,3.164,2.388,4.907,1.254c0.68-0.442,1.206-1.027,1.648-1.707c0.382-0.597,0.728-1.23,1.17-1.958
			c0.263-0.119,0.609-0.286,0.967-0.442c0.096-0.119,0.227-0.239,0.275-0.382c1.194-3.367,2.304-6.769,3.391-10.207
			c-0.203-0.179-0.346-0.43-0.537-0.466c-0.621-0.119-1.265-0.131-1.886-0.227c-0.215-0.036-0.406-0.203-0.585-0.31
			c-0.024-0.704,0.227-1.277,0.513-1.743c0.251-0.107,0.382-0.227,0.525-0.227c0.633-0.048,1.265-0.048,1.898-0.084
			c0.31-0.024,0.621-0.096,0.979-0.155c0.191-0.358,0.418-0.68,0.549-1.051c0.501-1.421,0.967-2.865,1.468-4.286
			c0.131-0.37,0.322-0.716,0.489-1.074c0.406-0.155,0.776-0.31,1.158-0.418c1.922-0.525,3.844-1.039,5.754-1.54
			c0.155-0.036,0.31-0.036,0.478-0.048c0.31-0.048,0.657,0.286,0.585,0.573c-0.084,0.382-0.167,0.776-0.287,1.146
			c-0.549,1.659-1.11,3.319-1.648,4.978c-0.119,0.37-0.215,0.764-0.31,1.146c-0.072,0.275,0.215,0.633,0.525,0.657
			c0.549,0.048,1.11,0.06,1.659,0.107c0.227,0.024,0.478,0.072,0.68,0.167c0.119,0.048,0.203,0.227,0.239,0.37s0,0.322-0.048,0.466
			c-0.143,0.37-0.31,0.716-0.406,0.943c-0.442,0.394-0.848,0.43-1.242,0.454c-0.716,0.048-1.421,0.107-2.137,0.167
			c-0.072,0.012-0.143,0.06-0.215,0.096c-0.358,0.227-0.501,0.597-0.621,0.979c-1.265,3.773-2.543,7.533-3.808,11.306
			c-0.561,1.659-1.098,3.319-1.659,4.978c-0.251,0.764-0.394,1.54-0.275,2.34c0.096,0.645,0.68,1.134,1.349,1.074
			c0.239-0.024,0.478-0.048,0.692-0.131c0.215-0.084,0.442-0.191,0.633-0.334c1.421-1.074,2.483-2.459,3.283-4.035
			c0.215-0.418,0.263-0.907,0.346-1.373c0.537-3.056,1.898-5.718,3.844-8.106c1.421-1.743,3.116-3.176,5.002-4.393
			c1.791-1.146,3.749-1.731,5.886-1.636c0.549,0.024,1.098,0.131,1.648,0.251c1.6,0.37,2.4,1.492,2.638,3.032
			c0.239,1.54-0.024,3.008-0.764,4.393c-0.645,1.206-1.456,2.268-2.424,3.235c-1.779,1.755-3.856,3.056-6.148,4.023
			c-0.657,0.275-1.325,0.513-1.839,0.704c-0.442,0.43-0.525,0.824-0.489,1.218c0.048,0.633,0.107,1.277,0.251,1.886
			c0.31,1.325,1.409,2.173,2.746,2.232c1.313,0.06,2.519-0.298,3.629-0.967c1.182-0.692,2.185-1.588,3.08-2.615
			c1.373-1.576,2.459-3.319,3.355-5.193c0.275-0.573,0.501-1.182,0.872-1.695c1.397-1.934,2.507-4.059,4.178-5.802
			c1.445-1.504,2.985-2.865,4.859-3.796c1.23-0.609,2.495-1.015,3.892-1.003c1.063,0.012,2.053,0.179,2.889,0.907
			c0.119,0.107,0.275,0.155,0.442,0.251c0.155-0.155,0.394-0.287,0.478-0.478c0.275-0.657,0.501-1.337,0.728-2.018
			c1.242-3.689,2.471-7.39,3.725-11.079c0.179-0.525,0.406-1.027,0.609-1.516c0.286-0.096,0.513-0.227,0.728-0.227
			c1.982-0.012,3.975-0.012,5.969-0.012c0.155,0,0.322,0.048,0.442,0.131c0.119,0.084,0.239,0.215,0.275,0.358
			C286.904,554.757,286.654,555.199,286.51,555.641L286.51,555.641z M244.571,579.792c0.06,0.131,0.155,0.263,0.275,0.454
			c0.275-0.072,0.585-0.119,0.86-0.251c1.958-0.931,3.617-2.244,4.931-3.964c0.466-0.621,0.86-1.325,1.206-2.03
			c0.203-0.418,0.287-0.895,0.346-1.361c0.131-1.277-0.752-1.934-1.946-1.48c-0.287,0.107-0.585,0.251-0.824,0.442
			c-0.43,0.346-0.848,0.716-1.206,1.122c-1.719,2.006-2.925,4.286-3.641,6.841C244.535,579.637,244.535,579.732,244.571,579.792
			L244.571,579.792z M272.638,572.975c-0.024-0.872-0.907-1.409-1.707-1.098c-0.609,0.239-1.098,0.621-1.528,1.11
			c-0.68,0.776-1.23,1.659-1.707,2.567c-0.955,1.827-1.85,3.689-2.567,5.623c-0.358,0.967-0.669,1.958-0.943,2.937
			c-0.167,0.609-0.239,1.242-0.322,1.648c0.024,0.466,0.012,0.704,0.048,0.943c0.06,0.478,0.215,0.919,0.669,1.194
			c0.442,0.263,1.086,0.203,1.564-0.143c0.597-0.418,1.051-0.955,1.48-1.54c0.752-1.027,1.313-2.161,1.767-3.355
			c0.871-2.304,1.755-4.596,2.615-6.9c0.191-0.525,0.382-1.051,0.489-1.588C272.626,573.919,272.662,573.441,272.638,572.975
			L272.638,572.975z M304.478,640.654c-0.478,1.194-1.17,2.256-2.006,3.223c-0.8,0.919-1.743,1.624-2.985,1.85
			c-1.218,0.215-2.364,0.131-3.438-0.573c-0.346-0.227-0.752-0.06-0.991,0.346c-0.525,0.883-1.039,1.791-1.588,2.662
			c-0.764,1.218-1.636,2.352-2.71,3.319c-1.146,1.039-2.435,1.803-3.94,2.137c-1.337,0.298-2.674,0.298-3.987-0.251
			c-1.063-0.454-1.886-1.17-2.424-2.197c-0.251-0.478-0.31-1.086-0.907-1.445c-0.215,0.191-0.454,0.382-0.657,0.597
			c-0.549,0.573-1.063,1.194-1.636,1.731c-1.564,1.445-3.438,1.946-5.516,1.683c-1.349-0.167-2.376-0.871-2.961-2.137
			c-0.478-1.027-0.776-2.089-0.752-3.223c0.012-0.788,0.084-1.588,0.096-2.376c0-0.107-0.155-0.239-0.263-0.334
			c-0.048-0.036-0.167-0.036-0.227,0c-0.275,0.167-0.537,0.334-0.788,0.525c-1.242,0.931-2.615,1.492-4.167,1.612
			c-0.478,0.036-0.943,0.107-1.277,0.155c-0.657,0.322-0.931,0.824-1.289,1.254c-0.824,0.979-1.683,1.91-2.698,2.686
			c-1.636,1.242-3.462,1.886-5.539,1.922c-2.173,0.048-4.656-0.979-5.802-4.119c-0.191-0.525-0.298-1.074-0.466-1.6
			c-0.072-0.215-0.227-0.406-0.334-0.597c-0.633,0.179-0.86,0.728-1.194,1.158c-0.991,1.266-2.065,2.412-3.355,3.379
			c-0.967,0.728-2.018,1.277-3.188,1.564c-1.433,0.346-2.841,0.322-4.202-0.286c-1.671-0.74-2.662-1.982-2.722-3.856
			c-0.048-1.433,0.036-2.853,0.43-4.25c0.478-1.695,1.086-3.319,1.779-4.931c0.466-1.098,0.943-2.197,1.409-3.283
			c0.227-0.513,0.334-1.051,0.322-1.624c-0.012-0.657-0.609-1.17-1.289-1.074c-0.573,0.072-1.063,0.334-1.492,0.704
			c-0.74,0.621-1.289,1.397-1.791,2.221c-0.74,1.23-1.301,2.555-1.779,3.904c-0.478,1.349-0.955,2.698-1.421,4.047
			c-0.657,1.874-1.301,3.761-1.958,5.635c-0.215,0.597-0.454,1.182-0.68,1.779c-0.239,0.119-0.442,0.298-0.657,0.298
			c-1.994,0.024-3.976,0.012-5.957,0c-0.454,0-0.704-0.382-0.609-0.812c0.06-0.227,0.107-0.466,0.179-0.692
			c0.119-0.382,0.239-0.764,0.37-1.134c2.041-6.113,4.083-12.213,6.124-18.326c0.251-0.74,0.382-1.552,1.015-2.209
			c2.018-0.191,4.083-0.096,6.148-0.06c0.227,0,0.454,0.131,0.621,0.179c0.454,0.812-0.513,1.552-0.024,2.412
			c0.287-0.167,0.561-0.31,0.812-0.478c0.716-0.501,1.397-1.051,2.125-1.528c1.218-0.788,2.603-1.194,4.023-1.409
			c0.883-0.131,1.743-0.024,2.591,0.263c0.8,0.263,1.385,0.764,1.755,1.504c0.549,1.098,0.788,2.256,0.609,3.474
			c-0.167,1.098-0.418,2.197-0.86,3.223c-0.621,1.468-1.265,2.913-1.922,4.37c-0.489,1.086-1.027,2.161-1.492,3.259
			c-0.275,0.657-0.478,1.349-0.657,2.042c-0.107,0.394-0.036,0.788,0.167,1.158s0.513,0.597,0.931,0.657
			c0.406,0.06,0.812,0,1.158-0.215c0.406-0.251,0.812-0.525,1.134-0.872c0.597-0.645,1.17-1.313,1.683-2.018
			c0.895-1.23,1.659-2.531,2.28-3.916c0.764-1.671,1.707-3.223,2.65-4.787c1.552-2.555,3.534-4.692,6.041-6.339
			c1.421-0.931,2.973-1.504,4.644-1.779c1.039-0.167,2.053-0.072,3.056,0.263c0.872,0.286,1.54,0.812,2.03,1.576
			c0.478,0.752,0.776,1.552,0.907,2.435c0.298,1.994,0.155,3.975-0.334,5.922c-0.334,1.301-0.824,2.567-1.265,3.856
			c-0.179,0.525-0.37,1.039-0.585,1.6c0.239,0.155,0.418,0.358,0.609,0.358c0.394,0,0.8-0.048,1.158-0.191
			c1.397-0.537,2.4-1.54,3.164-2.794c0.454-0.752,0.836-1.54,1.265-2.34c0.322-0.155,0.669-0.322,1.098-0.525
			c0.728-1.445,1.11-3.08,1.671-4.644c0.549-1.492,0.955-3.032,1.612-4.549c0.298-0.072,0.597-0.215,0.895-0.215
			c1.994-0.012,3.975-0.012,5.969,0c0.143,0,0.287,0.096,0.442,0.155c0.048,0.143,0.167,0.298,0.131,0.418
			c-0.131,0.537-0.286,1.074-0.466,1.6c-1.397,4.143-2.806,8.285-4.202,12.428c-0.179,0.525-0.394,1.051-0.525,1.588
			c-0.131,0.537-0.215,1.086-0.239,1.648c-0.036,1.003,0.824,1.612,1.791,1.242c0.358-0.131,0.716-0.358,1.003-0.609
			c0.609-0.525,1.051-1.206,1.373-1.934c0.454-1.015,0.907-2.03,1.277-3.08c1.289-3.677,2.543-7.366,3.808-11.055
			c0.251-0.752,0.478-1.504,0.848-2.125c0.239-0.107,0.382-0.227,0.525-0.227c1.91-0.036,3.82-0.048,5.73-0.06
			c0.31,0,0.621,0.072,0.883,0.107c0.084,0.215,0.215,0.37,0.179,0.49c-0.155,0.621-0.334,1.23-0.537,1.827
			c-1.397,4.226-2.806,8.452-4.202,12.691c-0.251,0.752-0.478,1.516-0.645,2.292c-0.119,0.561,0.012,1.122,0.478,1.516
			c0.418,0.358,1.289,0.37,1.803,0.048c0.263-0.167,0.537-0.358,0.764-0.573c1.683-1.647,2.77-3.677,3.582-5.862
			c0.191-0.501,0.167-1.098,0.155-1.647c-0.06-1.767,0.143-3.498,0.501-5.217c0.298-1.48,0.74-2.937,1.468-4.274
			c0.227-0.418,0.501-0.812,0.788-1.194c0.406-0.525,0.967-0.824,1.612-0.943c0.31-0.06,0.883,0.155,1.063,0.418
			c0.382,0.537,0.549,1.134,0.585,1.791c0.096,1.779-0.203,3.486-0.788,5.157c-0.454,1.277-0.955,2.531-1.433,3.796
			c-0.167,0.442-0.358,0.883-0.489,1.349c-0.167,0.573-0.036,1.098,0.406,1.528c0.442,0.418,0.955,0.537,1.54,0.37
			c0.716-0.191,1.313-0.597,1.743-1.194c0.418-0.573,0.764-1.206,1.122-1.827c0.203-0.346,0.37-0.704,0.74-0.919
			c0.585-0.024,1.027,0.298,1.48,0.609C304.514,640.189,304.561,640.439,304.478,640.654L304.478,640.654z M261.607,633.587
			c-0.179-0.776-1.098-1.254-1.862-1.027c-0.704,0.215-1.265,0.633-1.755,1.17c-0.919,1.003-1.576,2.173-2.149,3.402
			c-0.943,2.018-1.659,4.107-2.197,6.268c-0.215,0.836-0.31,1.719-0.406,2.579c-0.072,0.633,0.024,1.265,0.251,1.862
			c0.179,0.466,0.537,0.776,1.039,0.872c0.645,0.119,1.265,0.012,1.803-0.37c0.585-0.418,1.086-0.919,1.385-1.839
			c-0.561-1.48-0.74-2.937-0.31-4.453c0.406-1.421,1.122-2.615,2.364-3.438c0.394-0.263,0.836-0.454,1.206-0.752
			c0.239-0.191,0.454-0.466,0.537-0.752C261.834,635.951,261.87,634.769,261.607,633.587L261.607,633.587z M470.195,580.222
			c-0.907,2.125-1.958,4.178-3.295,6.077c-1.242,1.767-2.71,3.331-4.489,4.572c-2.435,1.695-5.122,2.507-8.094,2.316
			c-2.973-0.191-5.086-1.647-6.28-4.381c-0.286-0.645-0.501-1.325-0.728-1.97c-0.215,0-0.418-0.06-0.49,0.012
			c-0.382,0.394-0.752,0.812-1.086,1.254c-0.931,1.194-1.982,2.28-3.2,3.2c-1.098,0.836-2.316,1.385-3.665,1.647
			c-1.194,0.239-2.364,0.179-3.534-0.179c-1.325-0.406-2.173-1.277-2.662-2.531c-0.358-0.907-0.501-1.85-0.37-2.806
			c0.155-1.182,0.334-2.364,0.68-3.51c0.609-1.982,1.421-3.88,2.244-5.778c0.382-0.872,0.728-1.767,1.051-2.662
			c0.107-0.287,0.096-0.621,0.096-0.943c0-0.537-0.549-1.027-1.11-1.039c-0.585-0.012-1.086,0.167-1.54,0.525
			c-0.824,0.657-1.433,1.48-1.97,2.376c-0.824,1.373-1.433,2.841-1.934,4.358c-0.86,2.567-1.707,5.134-2.555,7.7
			c-0.298,0.907-0.609,1.815-0.931,2.71c-0.107,0.286-0.263,0.561-0.382,0.8c-1.385,0.287-4.071,0.382-6.721,0.227
			c-0.227-0.012-0.442-0.143-0.633-0.215c-0.155-0.537,0.119-0.967,0.239-1.421c0.203-0.764,0.466-1.516,0.669-2.28
			c0.024-0.095-0.119-0.239-0.203-0.346c-0.024-0.024-0.167,0.024-0.215,0.072c-0.489,0.513-0.979,1.039-1.468,1.552
			c-0.931,0.991-1.97,1.862-3.164,2.519c-1.206,0.657-2.483,1.051-3.868,1.027c-0.728-0.012-1.421-0.155-2.101-0.406
			c-1.266-0.466-2.137-1.349-2.567-2.638c-0.406-1.23-0.501-2.483-0.263-3.761c0.251-1.325,0.597-2.638,1.027-3.916
			c1.409-4.226,2.782-8.452,4.19-12.679c0.227-0.669,0.49-1.337,0.513-2.101c-0.191-0.119-0.37-0.298-0.573-0.334
			c-0.621-0.107-1.265-0.155-1.886-0.227c-0.31-0.036-0.549-0.394-0.454-0.704c0.143-0.454,0.322-0.895,0.501-1.385
			c0.322-0.072,0.621-0.167,0.919-0.203c0.788-0.072,1.588,0.048,2.185-0.107c0.561-0.286,0.704-0.669,0.836-1.039
			c0.406-1.122,0.764-2.256,1.146-3.391c0.227-0.669,0.49-1.337,0.74-2.006c2.185-0.907,4.477-1.265,6.674-1.934
			c0.37-0.107,0.776-0.119,1.206-0.191c0.095,0.263,0.263,0.513,0.215,0.68c-0.358,1.218-0.776,2.423-1.158,3.629
			c-0.37,1.134-0.728,2.268-1.074,3.414c-0.131,0.418,0.155,0.812,0.573,0.848c0.239,0.024,0.478,0.048,0.716,0.06
			c0.478,0.024,0.955,0.036,1.421,0.084c0.131,0.012,0.227,0.179,0.346,0.286c0.119,0.597-0.239,1.063-0.466,1.659
			c-0.227,0.096-0.501,0.287-0.8,0.322c-0.549,0.072-1.11,0.072-1.659,0.096c-0.394,0.024-0.8,0.036-1.146,0.239
			c-0.561,0.43-0.645,1.098-0.848,1.683c-1.182,3.462-2.34,6.924-3.51,10.398c-0.561,1.659-1.11,3.319-1.659,4.978
			c-0.227,0.68-0.382,1.373-0.382,2.101c0,1.397,1.039,1.803,2.113,1.361c0.501-0.203,0.991-0.549,1.361-0.943
			c0.704-0.752,1.361-1.552,1.97-2.388c0.836-1.158,1.492-2.447,2.089-3.749c0.334-0.716,0.68-1.433,1.003-2.113
			c0.346-0.155,0.645-0.286,0.943-0.418c0.501-0.537,0.621-1.266,0.848-1.934c1.289-3.844,2.567-7.688,3.856-11.533
			c1.086-3.247,2.173-6.483,3.259-9.73c0.155-0.454,0.287-0.907,0.86-1.266c2.221-0.155,4.584-0.107,6.841-0.012
			c0.251,0.513,0.179,0.812,0.072,1.122c-0.943,2.794-1.874,5.575-2.806,8.369c-0.633,1.886-1.265,3.773-1.898,5.659
			c-0.203,0.597-0.37,1.206-0.549,1.791c0.394,0.334,0.513,0.346,0.8,0.131c0.633-0.478,1.242-1.003,1.898-1.445
			c1.707-1.146,3.605-1.612,5.659-1.445c2.077,0.155,3.116,1.648,3.355,3.176c0.203,1.349,0.167,2.71-0.287,4.011
			c-0.454,1.277-0.931,2.543-1.468,3.773c-0.597,1.385-1.277,2.734-1.898,4.119c-0.358,0.8-0.692,1.612-0.955,2.447
			c-0.131,0.442-0.131,0.943-0.119,1.409c0.024,0.633,0.657,1.158,1.301,1.134c0.406-0.012,0.776-0.119,1.134-0.334
			c0.836-0.501,1.48-1.206,2.077-1.946c1.313-1.624,2.28-3.438,3.104-5.348c0.131-0.286,0.215-0.597,0.322-0.895
			c1.552-4.19,4.346-7.354,8.046-9.778c1.85-1.218,3.88-1.815,6.101-1.695c0.549,0.036,1.098,0.155,1.636,0.286
			c1.492,0.37,2.256,1.433,2.495,2.877c0.263,1.528,0.048,3.008-0.68,4.405c-0.633,1.206-1.433,2.28-2.4,3.247
			c-1.827,1.827-3.975,3.164-6.339,4.167c-0.657,0.275-1.325,0.525-1.994,0.788c-0.394,1.325-0.275,2.567,0.334,3.737
			c0.478,0.919,1.265,1.385,2.304,1.468c1.313,0.107,2.519-0.239,3.653-0.895c1.182-0.68,2.197-1.564,3.104-2.579
			c1.385-1.564,2.471-3.307,3.379-5.181c0.275-0.573,0.466-1.182,0.848-1.659c0.752-0.191,1.266,0.143,1.791,0.621
			C470.35,579.613,470.315,579.935,470.195,580.222L470.195,580.222z M456.406,580.15c2.34-0.979,4.214-2.531,5.671-4.596
			c0.322-0.454,0.573-0.955,0.824-1.445c0.298-0.585,0.382-1.206,0.358-1.85c-0.024-0.824-0.609-1.325-1.409-1.206
			c-0.478,0.072-0.943,0.203-1.313,0.525c-0.418,0.358-0.848,0.728-1.218,1.134c-0.931,0.991-1.659,2.137-2.268,3.355
			c-0.609,1.206-1.218,2.412-1.409,3.725C455.809,580.198,456.06,580.294,456.406,580.15L456.406,580.15z M396.475,579.625
			c-1.17,2.71-2.459,5.348-4.167,7.76c-1.063,1.504-2.292,2.841-3.773,3.952c-1.11,0.824-2.328,1.373-3.677,1.624
			c-1.11,0.203-2.209,0.167-3.307-0.143c-1.516-0.43-2.435-1.421-2.913-2.889c-0.298-0.919-0.37-1.862-0.239-2.829
			c0.191-1.337,0.406-2.674,0.883-3.952c0.501-1.337,1.039-2.662,1.576-3.987c0.334-0.812,0.716-1.6,1.051-2.4
			c0.203-0.513,0.37-1.051,0.501-1.588c0.155-0.609-0.072-1.254-0.489-1.48c-0.537-0.298-1.086-0.227-1.588,0.06
			c-0.334,0.191-0.645,0.466-0.919,0.752c-0.669,0.704-1.11,1.552-1.456,2.447c-0.442,1.194-0.883,2.388-1.289,3.593
			c-1.122,3.319-2.221,6.638-3.331,9.945c-0.179,0.525-0.418,1.015-0.609,1.445c-0.239,0.095-0.382,0.215-0.537,0.215
			c-2.065,0.012-4.131,0.024-6.196,0.012c-0.525,0-0.812-0.466-0.645-1.015c0.203-0.692,0.418-1.361,0.657-2.041
			c1.265-3.761,2.543-7.533,3.82-11.294c0.251-0.752,0.513-1.504,0.716-2.268c0.119-0.454,0.131-0.943,0.131-1.409
			c0.012-0.537-0.573-0.991-1.158-0.967c-0.418,0.012-0.776,0.155-1.11,0.37c-0.824,0.525-1.421,1.277-1.839,2.149
			c-0.346,0.716-0.633,1.457-0.895,2.209c-1.134,3.307-2.256,6.626-3.379,9.933c-0.334,0.979-0.657,1.958-1.003,2.937
			c-0.155,0.442-0.286,0.907-0.692,1.289c-0.179,0.048-0.406,0.155-0.633,0.155c-1.982,0-3.976-0.012-5.957-0.036
			c-0.143,0-0.286-0.119-0.43-0.179c-0.203-0.286-0.167-0.597-0.072-0.907c0.489-1.516,1.003-3.02,1.492-4.537
			c0.095-0.298,0.155-0.609,0.167-0.931c0-0.095-0.179-0.191-0.275-0.298c-0.991,0.49-1.958,1.086-2.997,1.433
			c-0.967,0.31-2.03,0.334-2.997,0.478c-0.633,0.322-0.943,0.907-1.349,1.385c-0.776,0.907-1.6,1.767-2.543,2.507
			c-1.349,1.074-2.889,1.671-4.572,1.898c-3.498,0.478-6.292-1.48-7.056-4.931c-0.131-0.621-0.179-1.254-0.275-1.886
			c-0.072-0.466-0.155-0.931-0.239-1.433c-0.263-0.036-0.501-0.131-0.716-0.084c-0.466,0.107-0.907,0.275-1.361,0.406
			c-0.943,0.275-1.874,0.263-2.829,0.06c-2.197-0.478-3.808-2.101-4.226-4.334c-0.239-1.277-0.155-2.543,0.036-3.796
			c0.298-1.994,0.991-3.844,2.089-5.539c0.561-0.872,1.254-1.648,2.077-2.292c1.039-0.812,2.221-1.23,3.546-1.146
			c1.003,0.06,1.803,0.74,2.03,1.719c0.143,0.633,0.131,1.266-0.024,1.886c-0.31,1.17-0.824,2.232-1.659,3.128
			c-1.051,1.122-2.256,2.006-3.677,2.591c-0.513,0.215-1.039,0.394-1.421,0.549c-0.501,0.466-0.573,0.931-0.525,1.409
			c0.048,0.466,0.143,0.955,0.298,1.397c0.788,2.221,3.379,2.973,5.289,1.564c0.788-0.585,1.349-1.349,1.827-2.173
			c0.681-1.17,1.349-2.34,2.018-3.51c0.919-1.6,2.018-3.056,3.319-4.346c1.194-1.182,2.495-2.221,3.999-2.997
			c1.504-0.788,3.092-1.266,4.799-1.23c0.478,0.012,0.955,0.072,1.409,0.179c1.337,0.298,2.268,1.098,2.829,2.34
			c0.43,0.955,0.704,1.934,0.74,2.985c0.084,1.922-0.167,3.808-0.752,5.647c-0.406,1.289-0.907,2.543-1.361,3.82
			c-0.072,0.215-0.119,0.466-0.119,0.692c0,0.119,0.143,0.334,0.251,0.346c0.298,0.06,0.633,0.107,0.919,0.024
			c1.528-0.43,2.65-1.421,3.486-2.734c0.513-0.8,0.931-1.659,1.445-2.591c0.215-0.084,0.525-0.155,0.788-0.31
			c0.191-0.119,0.37-0.322,0.466-0.525c0.275-0.657,0.501-1.337,0.728-2.018c0.669-1.958,1.313-3.916,1.982-5.874
			c0.131-0.37,0.275-0.74,0.74-1.074c1.922-0.191,3.987-0.155,6.041-0.012c0.131,0.012,0.251,0.167,0.37,0.251
			c-0.036,0.549-0.084,1.098-0.096,1.648c0,0.072,0.263,0.239,0.322,0.203c0.286-0.131,0.549-0.298,0.8-0.49
			c0.716-0.501,1.385-1.074,2.137-1.528c1.325-0.8,2.77-1.194,4.334-1.063c1.564,0.131,2.698,0.86,3.319,2.328
			c0.179,0.43,0.227,0.931,0.657,1.23c0.143-0.012,0.334,0.012,0.43-0.06c0.43-0.346,0.824-0.74,1.265-1.086
			c0.561-0.442,1.134-0.883,1.743-1.254c1.54-0.931,3.223-1.313,5.026-1.146c1.839,0.167,2.949,1.337,3.283,2.961
			c0.286,1.421,0.215,2.865-0.251,4.238c-0.43,1.277-0.931,2.543-1.468,3.773c-0.597,1.385-1.277,2.734-1.898,4.119
			c-0.358,0.8-0.692,1.612-0.943,2.447c-0.131,0.442-0.131,0.943-0.119,1.409c0.036,0.633,0.68,1.134,1.325,1.11
			c0.406-0.012,0.8-0.107,1.122-0.358c0.501-0.394,1.015-0.776,1.421-1.254c0.657-0.788,1.289-1.624,1.827-2.507
			c0.692-1.158,1.289-2.376,1.91-3.57c0.287-0.561,0.549-1.134,0.788-1.636c0.8-0.31,1.313,0.024,1.803,0.454
			C396.464,579.327,396.535,579.506,396.475,579.625L396.475,579.625z M349.163,572.784c-0.215-0.8-1.11-1.218-1.898-0.943
			c-0.692,0.239-1.254,0.657-1.743,1.206c-0.8,0.895-1.409,1.91-1.922,2.985c-1.17,2.435-2.053,4.978-2.591,7.629
			c-0.155,0.764-0.155,1.576-0.191,2.364c-0.012,0.31,0.072,0.633,0.155,0.931c0.191,0.68,0.907,1.182,1.648,1.074
			c0.382-0.048,0.788-0.155,1.11-0.346c0.704-0.406,1.23-0.979,1.504-1.815c-0.131-0.513-0.334-1.039-0.406-1.576
			c-0.084-0.621-0.107-1.265-0.048-1.898c0.179-1.91,0.991-3.486,2.615-4.584c0.454-0.31,0.943-0.573,1.421-0.86
			c0.406-0.812,0.573-1.671,0.585-2.543C349.414,573.883,349.295,573.322,349.163,572.784L349.163,572.784z M494.645,630.495
			c-0.191,0.692-0.406,1.373-0.633,2.053c-2.03,6.029-4.059,12.058-6.101,18.087c-0.227,0.669-0.49,1.337-0.8,2.209
			c-0.8,0.048-1.433,0.096-2.065,0.119c-0.633,0.012-1.277,0-1.91,0c-0.561,0-1.122,0.024-1.671-0.012
			c-0.609-0.036-1.265,0.167-1.779-0.358c-0.012-0.669,0.286-1.242,0.454-1.85c0.179-0.609,0.346-1.218,0.513-1.827
			c0.012-0.048-0.06-0.143-0.119-0.179c-0.048-0.036-0.167-0.048-0.203-0.012c-0.358,0.298-0.728,0.597-1.063,0.931
			c-0.967,0.955-2.018,1.791-3.176,2.507c-2.555,1.564-5.336,2.041-8.261,1.612c-2.268-0.334-3.94-1.636-4.978-3.689
			c-0.358-0.704-0.561-1.48-0.872-2.209c-0.084-0.179-0.322-0.298-0.501-0.454c-0.645,0.454-0.967,1.11-1.409,1.659
			c-1.003,1.242-2.137,2.364-3.462,3.259c-1.648,1.11-3.45,1.588-5.432,1.409c-0.31-0.024-0.633-0.072-0.943-0.155
			c-1.922-0.489-3.14-1.922-3.295-3.904c-0.107-1.349-0.036-2.698,0.31-4.023c0.478-1.85,1.146-3.641,1.91-5.384
			c0.478-1.098,0.955-2.185,1.421-3.283c0.251-0.597,0.334-1.218,0.263-1.874c-0.048-0.454-0.49-0.871-0.955-0.907
			c-0.501-0.036-0.943,0.084-1.361,0.346c-0.752,0.478-1.301,1.158-1.803,1.886c-0.955,1.385-1.636,2.913-2.185,4.489
			c-0.525,1.504-1.003,3.02-1.504,4.525c-0.657,1.958-1.301,3.928-1.958,5.886c-0.155,0.442-0.37,0.872-0.549,1.289
			c-0.275,0.084-0.501,0.215-0.728,0.215c-1.91,0.024-3.82,0.024-5.73,0.012c-0.31,0-0.633-0.036-0.872-0.418
			c-0.119-0.406,0.072-0.848,0.227-1.301c1.039-3.092,2.065-6.184,3.092-9.276c2.746-8.226,5.48-16.439,8.249-24.665
			c0.275-0.824,0.442-1.695,0.991-2.447c0.203-0.072,0.418-0.227,0.633-0.239c1.982-0.024,3.976-0.012,5.957-0.012
			c0.155,0,0.322,0.072,0.442,0.155c0.119,0.084,0.263,0.251,0.251,0.37c-0.036,0.394-0.096,0.788-0.215,1.158
			c-1.433,4.298-2.889,8.596-4.334,12.894c-0.227,0.68-0.442,1.361-0.609,2.053c-0.036,0.167,0.131,0.37,0.203,0.561
			c0.728-0.179,1.122-0.728,1.636-1.086c1.051-0.74,2.149-1.349,3.391-1.695c1.015-0.286,2.041-0.346,3.068-0.239
			c1.468,0.155,2.507,0.919,3.044,2.328c0.418,1.074,0.537,2.173,0.298,3.283c-0.191,0.931-0.43,1.862-0.764,2.758
			c-0.501,1.337-1.086,2.65-1.671,3.952c-0.513,1.17-1.074,2.304-1.6,3.462c-0.37,0.8-0.645,1.624-0.776,2.495
			c-0.036,0.227-0.012,0.478,0.024,0.704c0.072,0.561,0.585,1.015,1.134,1.051c0.585,0.048,1.098-0.131,1.564-0.466
			c0.86-0.609,1.54-1.385,2.161-2.209c1.146-1.54,2.065-3.211,2.782-4.99c0.203-0.513,0.394-1.039,0.609-1.552
			c1.683-4.047,4.549-7.044,8.214-9.348c1.373-0.86,2.913-1.254,4.513-1.421c1.051-0.107,2.065,0.072,3.056,0.394
			c0.8,0.251,1.385,0.776,1.767,1.516c0.525,1.015,0.728,2.101,0.537,3.235c-0.179,1.027-0.501,2.006-1.027,2.913
			c-0.692,1.17-1.54,2.221-2.531,3.152c-1.707,1.6-3.653,2.806-5.802,3.713c-0.657,0.275-1.337,0.513-1.886,0.728
			c-0.418,0.37-0.501,0.752-0.525,1.158c-0.048,0.979,0.119,1.91,0.597,2.758c0.454,0.812,1.158,1.289,2.101,1.385
			c1.301,0.119,2.531-0.119,3.677-0.764c1.194-0.669,2.221-1.528,3.14-2.543c1.349-1.492,2.424-3.152,3.319-4.943
			c0.251-0.501,0.466-1.015,0.692-1.516c0.203-0.478,0.633-0.692,1.158-0.943c0.203-0.513,0.466-1.086,0.669-1.683
			c0.645-1.874,1.265-3.773,1.91-5.647c0.203-0.597,0.442-1.182,0.68-1.827c0.322-0.072,0.621-0.203,0.931-0.203
			c2.065-0.012,4.131-0.012,6.101-0.012C494.729,629.874,494.741,630.184,494.645,630.495L494.645,630.495z M470.267,640.296
			c0.048,0.215,0.155,0.406,0.239,0.597c0.215-0.024,0.382,0,0.525-0.048c2.555-1.086,4.537-2.841,6.017-5.193
			c0.382-0.609,0.633-1.277,0.788-1.982c0.155-0.669-0.072-1.421-0.537-1.707c-0.525-0.322-1.074-0.239-1.588,0
			c-0.358,0.155-0.704,0.37-0.991,0.633c-0.466,0.43-0.907,0.883-1.301,1.373c-1.397,1.755-2.388,3.725-3.08,5.85
			C470.267,639.986,470.243,640.153,470.267,640.296L470.267,640.296z M214.02,569.585c-0.143,0.537-0.298,1.074-0.478,1.6
			c-2.101,6.256-4.202,12.511-6.315,18.767c-0.227,0.669-0.478,1.337-0.74,2.053c-0.322,0.084-0.633,0.215-0.931,0.215
			c-1.994,0.012-3.975,0-5.969-0.012c-0.143,0-0.287-0.107-0.442-0.155c-0.048-0.143-0.167-0.298-0.131-0.418
			c0.263-1.003,0.573-1.982,0.836-2.985c0.024-0.107-0.107-0.263-0.167-0.394c-0.442,0.06-0.669,0.406-0.931,0.704
			c-0.848,0.955-1.779,1.815-2.829,2.555c-0.991,0.704-2.077,1.182-3.259,1.421c-0.943,0.191-1.886,0.203-2.829-0.072
			c-1.063-0.298-1.827-0.943-2.316-1.91c-0.215-0.43-0.358-0.883-0.537-1.325c-0.143-0.358-0.191-0.776-0.537-1.039
			c-0.334,0.06-0.549,0.275-0.764,0.513c-0.466,0.537-0.907,1.11-1.421,1.612c-1.039,1.015-2.256,1.731-3.677,2.137
			c-2.471,0.716-4.763-0.215-6.029-2.603c-0.334-0.621-0.489-1.337-0.728-2.006c-0.084-0.227-0.119-0.466-0.239-0.657
			c-0.06-0.095-0.31-0.179-0.394-0.119c-0.263,0.179-0.501,0.382-0.692,0.621c-0.812,0.979-1.659,1.922-2.65,2.734
			c-1.182,0.967-2.471,1.731-3.975,2.089c-1.361,0.322-2.698,0.239-3.976-0.298c-1.158-0.478-1.958-1.337-2.364-2.543
			c-0.382-1.146-0.525-2.328-0.31-3.534c0.251-1.409,0.597-2.794,1.051-4.155c1.397-4.143,2.746-8.309,4.107-12.464
			c0.096-0.298,0.215-0.597,0.31-0.907c0.454-1.492,0.561-1.815-1.23-1.898c-0.394-0.024-0.788-0.107-1.158-0.203
			c-0.107-0.036-0.263-0.251-0.239-0.346c0.119-0.537,0.287-1.051,0.501-1.755c1.23-0.072,2.256-0.131,3.104-0.191
			c0.561-0.286,0.704-0.657,0.836-1.027c0.394-1.122,0.752-2.268,1.146-3.391c0.227-0.669,0.501-1.325,0.752-1.994
			c0.334-0.143,0.609-0.31,0.907-0.394c2.065-0.573,4.143-1.122,6.22-1.683c0.072-0.024,0.155-0.012,0.239-0.036
			c0.43-0.084,0.824,0.275,0.716,0.669c-0.191,0.692-0.394,1.373-0.621,2.053c-0.478,1.433-0.967,2.865-1.433,4.298
			c-0.096,0.298-0.131,0.609-0.203,0.919c-0.036,0.155,0.227,0.525,0.382,0.549c0.788,0.072,1.576,0.131,2.364,0.191
			c0.179,0.012,0.418,0.346,0.382,0.537c-0.036,0.155-0.06,0.31-0.119,0.466c-0.107,0.298-0.251,0.585-0.346,0.836
			c-0.322,0.346-0.692,0.454-1.098,0.49c-0.716,0.048-1.433,0.072-2.137,0.131c-0.334,0.024-0.609,0.227-0.752,0.525
			c-0.203,0.43-0.37,0.883-0.513,1.325c-1.624,4.823-3.259,9.646-4.871,14.469c-0.179,0.525-0.37,1.051-0.478,1.6
			c-0.107,0.537-0.143,1.098-0.131,1.647c0.024,0.86,0.824,1.409,1.683,1.218c0.561-0.131,1.039-0.406,1.456-0.776
			c0.848-0.74,1.54-1.612,2.173-2.531c1.134-1.647,2.018-3.426,2.794-5.265c1.23-2.901,3.032-5.396,5.336-7.545
			c1.11-1.039,2.316-1.934,3.665-2.638c0.919-0.49,1.898-0.824,2.901-1.074c0.704-0.179,1.421-0.215,2.137-0.084
			c0.955,0.179,1.85,0.49,2.412,1.373c0.167,0.263,0.358,0.513,0.728,0.549c0.239-0.298,0.489-0.597,0.812-0.991
			c2.125-0.239,4.31-0.119,6.471-0.06c0.107,0.812-0.119,1.313-0.298,1.85c-1.421,4.131-2.829,8.273-4.25,12.416
			c-0.203,0.597-0.466,1.194-0.609,1.815c-0.143,0.609-0.215,1.253-0.227,1.886c-0.024,0.764,0.764,1.313,1.492,1.074
			c0.37-0.119,0.74-0.298,1.051-0.525c0.824-0.633,1.48-1.433,2.089-2.28c1.039-1.421,1.886-2.949,2.662-4.513
			c0.322-0.645,0.609-1.289,0.907-1.922c0.322-0.167,0.597-0.322,0.872-0.466c0.406-0.406,0.549-0.943,0.728-1.468
			c0.633-1.886,1.277-3.773,1.898-5.659c0.227-0.68,0.478-1.337,0.74-1.898c0.239-0.119,0.382-0.251,0.513-0.251
			c2.065-0.036,4.131-0.06,6.196-0.06c0.227,0,0.454,0.107,0.657,0.167C213.961,569.298,214.044,569.453,214.02,569.585
			L214.02,569.585z M190.239,572.94c-0.036-0.883-0.895-1.409-1.707-1.051c-0.513,0.227-0.967,0.537-1.349,0.943
			c-0.776,0.8-1.409,1.719-1.958,2.674c-1.516,2.626-2.591,5.432-3.355,8.357c-0.179,0.692-0.287,1.397-0.394,2.101
			c-0.072,0.478,0.024,0.943,0.239,1.385c0.287,0.573,1.074,0.883,1.624,0.561c0.406-0.239,0.8-0.513,1.134-0.836
			c0.86-0.836,1.492-1.85,1.982-2.937c0.43-0.931,0.812-1.886,1.182-2.853c0.704-1.85,1.385-3.713,2.077-5.563
			c0.31-0.824,0.501-1.671,0.537-2.495C190.251,573.095,190.239,573.011,190.239,572.94L190.239,572.94z M370.294,640.392
			c-1.218,3.14-2.794,6.065-5.05,8.596c-1.17,1.313-2.519,2.423-4.035,3.319c-2.507,1.468-5.229,1.886-8.058,1.468
			c-1.898-0.275-3.402-1.289-4.477-2.889c-0.812-1.206-1.254-2.567-1.456-3.999c-0.072-0.466,0-0.967-0.358-1.349
			c-0.143-0.036-0.298-0.131-0.442-0.107c-0.394,0.072-0.764,0.203-1.158,0.286c-1.206,0.227-2.376,0.191-3.45-0.513
			c-0.119-0.084-0.298-0.084-0.525-0.131c-0.143,0.119-0.37,0.215-0.49,0.394c-0.549,0.872-1.051,1.779-1.6,2.662
			c-0.764,1.218-1.636,2.352-2.698,3.319c-1.074,0.979-2.28,1.707-3.689,2.113c-1.015,0.286-2.053,0.358-3.068,0.179
			c-1.648-0.286-2.961-1.122-3.689-2.71c-0.191-0.43-0.406-0.848-0.621-1.313c-0.263,0.143-0.501,0.215-0.657,0.382
			c-0.549,0.573-1.063,1.194-1.624,1.743c-1.504,1.492-3.331,2.101-5.432,1.958c-1.564-0.119-2.71-0.86-3.355-2.304
			c-0.298-0.657-0.549-1.325-0.585-2.053c-0.084-1.349-0.119-2.71,0.191-4.035c0.275-1.158,0.525-2.328,0.907-3.462
			c1.194-3.629,2.447-7.235,3.677-10.84c0.155-0.442,0.37-0.872,0.549-1.289c0.275-0.084,0.501-0.215,0.728-0.215
			c1.91-0.024,3.82-0.012,5.73-0.012c0.322,0,0.621,0.06,0.848,0.346c0.155,0.478-0.048,0.919-0.203,1.373
			c-1.254,3.689-2.495,7.378-3.749,11.067c-0.334,0.979-0.692,1.946-0.991,2.937c-0.179,0.609-0.31,1.23-0.406,1.862
			c-0.036,0.298,0.06,0.633,0.143,0.931c0.095,0.346,0.525,0.645,0.907,0.669c0.49,0.024,0.943-0.084,1.349-0.37
			c0.597-0.43,1.039-0.979,1.409-1.6c0.585-0.955,1.027-1.982,1.385-3.032c0.907-2.626,1.827-5.253,2.734-7.891
			c0.525-1.504,1.015-3.02,1.54-4.513c0.179-0.525,0.31-1.074,0.692-1.504c0.143-0.072,0.275-0.203,0.418-0.203
			c2.065-0.024,4.131-0.036,6.196-0.024c0.43,0,0.68,0.394,0.573,0.836c-0.06,0.227-0.107,0.466-0.179,0.692
			c-0.167,0.525-0.346,1.063-0.525,1.588c-1.277,3.844-2.555,7.7-3.832,11.544c-0.203,0.609-0.418,1.206-0.573,1.827
			c-0.107,0.454-0.143,0.943-0.155,1.421c-0.012,0.764,0.657,1.301,1.433,1.23c0.406-0.036,0.8-0.131,1.098-0.418
			c0.525-0.49,1.051-0.979,1.492-1.528c1.098-1.373,1.886-2.937,2.531-4.572c0.179-0.454,0.239-0.919,0.239-1.397
			c0-0.8,0.012-1.588,0.024-2.388c0.048-2.006,0.501-3.94,1.146-5.826c0.334-0.979,0.788-1.91,1.421-2.746
			c0.406-0.525,0.931-0.86,1.564-1.039c0.478-0.131,1.218,0.215,1.409,0.669c0.37,0.895,0.525,1.827,0.406,2.806
			c-0.203,1.576-0.454,3.152-1.051,4.632c-0.478,1.182-0.931,2.376-1.361,3.57c-0.131,0.37-0.251,0.752-0.31,1.146
			c-0.155,0.991,0.872,1.85,1.85,1.588c0.633-0.167,1.218-0.442,1.624-0.955c0.442-0.561,0.848-1.158,1.194-1.767
			c0.346-0.621,0.609-1.301,0.919-1.946c1.719-3.534,4.369-6.22,7.664-8.321c1.373-0.871,2.901-1.289,4.489-1.492
			c1.122-0.143,2.221,0.036,3.295,0.406c0.704,0.239,1.242,0.704,1.612,1.349c0.561,1.003,0.824,2.077,0.633,3.211
			c-0.191,1.182-0.573,2.304-1.206,3.343c-0.895,1.433-2.018,2.662-3.331,3.713c-1.552,1.266-3.271,2.256-5.134,2.985
			c-0.585,0.239-1.182,0.466-1.898,0.752c-0.382,1.385-0.251,2.543,0.298,3.641c0.454,0.919,1.218,1.445,2.256,1.528
			c1.301,0.107,2.531-0.155,3.665-0.812c1.182-0.68,2.221-1.54,3.128-2.567c1.325-1.492,2.412-3.164,3.295-4.954
			c0.286-0.573,0.525-1.158,0.812-1.731c0.06-0.119,0.251-0.191,0.358-0.263c0.167,0,0.346-0.048,0.478,0.012
			c0.37,0.131,0.776,0.191,1.051,0.525C370.247,640.093,370.342,640.272,370.294,640.392L370.294,640.392z M355.622,640.642
			c0.024,0.107,0.215,0.191,0.334,0.286c0.155-0.012,0.322,0,0.466-0.06c2.304-1.039,4.178-2.591,5.623-4.656
			c0.549-0.788,0.967-1.648,1.134-2.603c0.036-0.227,0.036-0.478,0.024-0.704c-0.048-0.669-0.621-1.158-1.289-1.134
			c-0.489,0.024-0.931,0.167-1.337,0.442c-0.68,0.442-1.218,1.015-1.719,1.636c-1.456,1.827-2.507,3.856-3.199,6.089
			C355.586,640.153,355.574,640.416,355.622,640.642L355.622,640.642z M324.558,570.671c-2.388,7.079-4.728,14.183-7.139,21.179
			c-0.633,0.406-1.039,0.394-1.433,0.394c-1.91-0.012-3.82-0.036-5.73-0.048c-0.072,0-0.143-0.048-0.215-0.096
			c-0.072-0.048-0.119-0.107-0.215-0.203c0.191-1.791,1.277-3.379,1.445-5.217c0-0.06-0.084-0.119-0.143-0.203
			c-0.633-0.191-0.967,0.394-1.468,0.561c-0.525,0.191-1.027,0.43-1.552,0.621c-0.525,0.179-1.051,0.31-1.504,0.442
			c-0.442,0.549-0.442,1.122-0.466,1.671c-0.179,3.283-1.051,6.423-2.101,9.527c-0.848,2.507-2.089,4.811-3.498,7.032
			c-0.728,1.146-1.624,2.161-2.794,2.901c-1.6,1.015-3.331,1.063-5.038,0.418c-1.719-0.657-2.543-2.101-2.817-3.856
			c-0.251-1.659-0.203-3.343,0.131-4.99c0.537-2.567,1.158-5.122,2.006-7.605c3.044-8.954,6.089-17.92,9.121-26.885
			c0.609-1.815,1.313-3.582,2.161-5.301c0.752-1.492,1.612-2.925,2.674-4.226c0.764-0.931,1.6-1.767,2.591-2.459
			c1.576-1.11,3.331-1.468,5.217-1.17c1.086,0.167,1.91,0.764,2.471,1.695c0.322,0.549,0.549,1.146,0.621,1.791
			c0.131,1.277,0.107,2.543-0.215,3.796c-0.454,1.779-1.11,3.486-1.922,5.122c-1.325,2.638-2.877,5.146-4.751,7.414
			c-0.86,1.039-1.827,1.994-2.77,2.961c-0.501,0.513-1.063,0.967-1.588,1.445c-1.54,1.433-1.827,2.018-2.077,4.322
			c0.704,1.17,1.385,2.328,2.101,3.474c0.167,0.275,0.501,0.394,0.836,0.382c0.31-0.012,0.645-0.024,0.943-0.107
			c0.931-0.263,1.731-0.776,2.435-1.457c0.991-0.943,1.743-2.065,2.352-3.283c0.322-0.633,0.621-1.289,0.907-1.886
			c0.382-0.239,0.704-0.454,1.086-0.704c0.286-0.776,0.585-1.6,0.871-2.423c0.645-1.886,1.266-3.773,1.922-5.647
			c0.131-0.358,0.334-0.692,0.489-0.991c2.161-0.275,6.053-0.263,7.199,0.036c0.06,0.012,0.084,0.107,0.131,0.179
			C324.94,569.8,324.702,570.229,324.558,570.671L324.558,570.671z M306.77,572.808c0.131,0.036,0.322,0.072,0.394,0.012
			c1.504-1.433,2.555-3.211,3.701-4.919c1.421-2.125,2.388-4.465,3.116-6.9c0.251-0.848,0.394-1.695,0.406-2.567
			c0-0.31-0.072-0.621-0.155-0.931c-0.107-0.418-0.764-0.68-1.182-0.49c-0.442,0.203-0.788,0.513-1.086,0.895
			c-0.633,0.824-1.074,1.755-1.397,2.734c-1.289,3.832-2.567,7.653-3.749,11.198c-0.084,0.454-0.131,0.609-0.131,0.764
			C306.675,572.677,306.722,572.796,306.77,572.808L306.77,572.808z M303.475,588.233c-0.287-0.418-0.776-0.525-1.194-0.74
			c-0.203-0.107-0.454-0.107-0.872-0.191c-0.227,0.525-0.406,0.883-0.525,1.254c-1.456,4.369-2.913,8.739-4.358,13.108
			c-0.203,0.597-0.382,1.218-0.466,1.839c-0.072,0.454-0.012,0.943,0.06,1.409c0.107,0.633,0.967,1.015,1.504,0.669
			c0.394-0.251,0.776-0.561,1.074-0.919c0.716-0.86,1.206-1.862,1.648-2.889c1.695-3.88,2.722-7.951,3.271-12.153
			C303.678,589.176,303.726,588.686,303.475,588.233L303.475,588.233z M160.035,563.46c-1.194,0.716-2.483,1.063-3.88,0.919
			c-0.322-0.036-0.609-0.167-0.8-0.549c0.322-0.501,0.692-1.003,0.991-1.552c0.585-1.063,0.895-2.197,0.776-3.438
			c-0.06-0.657-0.275-1.254-0.716-1.731c-0.263-0.286-0.597-0.561-0.955-0.669c-1.23-0.346-2.364-0.131-3.283,0.836
			c-0.74,0.776-1.182,1.707-1.373,2.734c-0.418,2.232-0.334,4.441,0.549,6.566c0.454,1.098,1.003,2.161,1.492,3.247
			c0.585,1.301,1.182,2.603,1.707,3.94c0.645,1.648,0.955,3.355,0.979,5.122c0.036,1.839-0.024,3.653-0.454,5.444
			c-1.074,4.429-3.57,7.736-7.653,9.825c-1.719,0.883-3.546,1.421-5.444,1.683c-2.531,0.346-5.074,0.358-7.569-0.322
			c-1.313-0.358-2.567-0.848-3.725-1.552c-0.955-0.585-1.803-1.301-2.519-2.173c-1.039-1.265-1.6-2.698-1.731-4.357
			c-0.227-2.997,0.812-5.468,3.02-7.45c2.507-2.256,5.504-3.092,8.834-2.722c1.098,0.119,2.221,0.298,3.176,0.931
			c0.179,0.119,0.31,0.322,0.501,0.537c-0.239,0.143-0.418,0.322-0.633,0.382c-0.764,0.191-1.552,0.286-2.304,0.585
			c-0.979,0.382-1.874,0.86-2.686,1.516c-2.543,2.065-3.379,4.763-2.674,7.891c0.585,2.579,2.412,4.095,5.086,4.202
			c2.806,0.107,4.907-1.074,6.423-3.379c0.573-0.872,0.979-1.827,1.242-2.829c0.788-2.985,0.883-5.969-0.107-8.93
			c-0.501-1.504-1.086-2.985-1.648-4.477c-0.561-1.48-1.206-2.949-1.719-4.441c-0.943-2.71-0.991-5.468-0.143-8.214
			c0.824-2.698,2.471-4.787,4.907-6.208c2.865-1.683,5.981-2.089,9.216-1.576c1.265,0.203,2.447,0.704,3.462,1.516
			c0.955,0.764,1.624,1.695,1.97,2.901C162.936,559.652,162.1,562.231,160.035,563.46L160.035,563.46z M427.026,640.523
			c-0.68,1.791-1.564,3.474-2.543,5.11c-0.991,1.648-2.161,3.14-3.558,4.465c-1.098,1.051-2.34,1.91-3.689,2.603
			c-2.352,1.194-4.871,1.468-7.426,1.063c-1.886-0.298-3.379-1.337-4.429-2.961c-0.788-1.218-1.242-2.567-1.397-4.011
			c-0.048-0.466-0.012-0.967-0.406-1.325c-0.155-0.024-0.322-0.084-0.454-0.048c-0.537,0.143-1.063,0.322-1.6,0.478
			c-2.818,0.812-6.697-0.764-7.079-5.11c-0.179-2.018,0.191-3.952,0.895-5.838c0.645-1.731,1.564-3.295,2.925-4.584
			c0.895-0.848,1.958-1.349,3.164-1.552c0.31-0.048,0.633-0.036,0.943-0.012c1.122,0.119,1.874,0.907,1.982,2.03
			c0.167,1.779-0.478,3.283-1.612,4.584c-1.003,1.158-2.221,2.042-3.629,2.65c-0.573,0.251-1.17,0.478-1.779,0.716
			c-0.394,0.86-0.334,1.731-0.072,2.567c0.239,0.776,0.716,1.433,1.409,1.874c1.242,0.8,2.471,0.8,3.999-0.239
			c0.597-0.406,1.063-0.967,1.421-1.588c0.394-0.692,0.776-1.385,1.086-2.113c1.564-3.617,4.119-6.375,7.318-8.596
			c1.397-0.967,2.925-1.576,4.608-1.839c1.289-0.203,2.531-0.095,3.761,0.31c0.8,0.263,1.385,0.776,1.755,1.516
			c0.442,0.86,0.669,1.791,0.561,2.758c-0.131,1.277-0.501,2.483-1.182,3.594c-0.931,1.504-2.113,2.782-3.486,3.88
			c-1.504,1.206-3.152,2.137-4.931,2.865c-0.585,0.239-1.182,0.466-1.946,0.752c-0.43,1.421-0.275,2.65,0.346,3.808
			c0.442,0.824,1.158,1.289,2.101,1.385c1.385,0.131,2.674-0.155,3.88-0.872c1.11-0.657,2.065-1.48,2.925-2.423
			c1.456-1.6,2.591-3.414,3.534-5.36c0.275-0.561,0.43-1.194,0.943-1.647c0.645-0.048,1.218,0.107,1.636,0.621
			C427.038,640.213,427.086,640.392,427.026,640.523L427.026,640.523z M412.389,640.547c0.012,0.131,0.167,0.239,0.251,0.358
			c0.155,0,0.334,0.048,0.466-0.012c2.543-1.11,4.572-2.841,6.041-5.193c0.43-0.68,0.728-1.421,0.8-2.221
			c0.06-0.573,0-1.134-0.513-1.492c-0.501-0.358-1.074-0.251-1.6-0.024c-0.358,0.155-0.704,0.37-1.003,0.633
			c-0.418,0.37-0.8,0.776-1.158,1.194c-1.48,1.803-2.531,3.844-3.235,6.053C412.377,640.057,412.366,640.308,412.389,640.547
			L412.389,640.547z M525.96,640.678c-1.074,2.579-2.34,5.038-4.071,7.247c-1.086,1.385-2.328,2.603-3.761,3.629
			c-2.65,1.874-5.575,2.626-8.787,2.28c-2.006-0.215-3.605-1.17-4.787-2.794c-0.657-0.907-1.063-1.934-1.349-3.032
			c-0.215-0.836-0.239-1.719-0.478-2.483c-0.263-0.072-0.43-0.167-0.549-0.131c-0.609,0.167-1.218,0.382-1.827,0.537
			c-2.28,0.573-5.575-0.43-6.602-3.713c-0.31-1.003-0.466-2.018-0.346-3.056c0.239-2.149,0.764-4.214,1.815-6.124
			c0.585-1.051,1.313-1.994,2.197-2.806c0.848-0.776,1.85-1.218,2.973-1.421c0.549-0.107,1.11-0.06,1.636,0.155
			c0.633,0.251,1.051,0.681,1.23,1.349c0.31,1.134,0.107,2.209-0.322,3.259c-0.334,0.824-0.836,1.528-1.457,2.161
			c-0.955,0.979-2.042,1.767-3.295,2.316c-0.585,0.251-1.182,0.478-1.707,0.68c-0.346,0.358-0.406,0.74-0.418,1.146
			c-0.024,0.645,0.107,1.266,0.382,1.85c1.003,2.089,3.319,2.794,5.372,1.17c0.764-0.597,1.301-1.385,1.755-2.232
			c0.263-0.49,0.478-1.003,0.704-1.516c1.659-3.761,4.381-6.578,7.796-8.799c1.361-0.883,2.877-1.337,4.465-1.552
			c1.122-0.155,2.221,0,3.295,0.334c0.883,0.275,1.504,0.86,1.91,1.683c0.549,1.11,0.645,2.292,0.382,3.486
			c-0.203,0.931-0.549,1.827-1.039,2.65c-0.776,1.313-1.767,2.447-2.913,3.45c-1.636,1.421-3.462,2.519-5.468,3.343
			c-0.513,0.215-1.051,0.37-1.564,0.585c-0.49,0.203-0.692,0.621-0.716,1.134c-0.06,0.895,0.095,1.743,0.454,2.555
			c0.454,1.027,1.242,1.624,2.388,1.707c1.313,0.095,2.519-0.179,3.665-0.824c1.182-0.68,2.209-1.552,3.116-2.567
			c1.325-1.492,2.4-3.164,3.283-4.954c0.251-0.501,0.454-1.015,0.704-1.516c0.107-0.203,0.31-0.346,0.442-0.489
			c0.621,0.036,1.134,0.179,1.552,0.657C525.996,640.225,526.043,640.475,525.96,640.678L525.96,640.678z M511.407,640.63
			c0.06,0.119,0.227,0.191,0.346,0.275c0.155-0.024,0.322-0.012,0.466-0.072c2.376-1.051,4.286-2.674,5.73-4.835
			c0.442-0.669,0.776-1.385,0.955-2.161c0.06-0.227,0.084-0.478,0.072-0.704c-0.06-0.824-0.537-1.492-1.576-1.313
			c-0.31,0.048-0.633,0.143-0.883,0.31c-0.454,0.31-0.919,0.633-1.289,1.039c-1.85,2.018-3.116,4.381-3.868,7.02
			C511.323,640.308,511.347,640.499,511.407,640.63L511.407,640.63z M395.664,630.483c-0.036,0.155-0.06,0.31-0.107,0.466
			c-0.167,0.525-0.358,1.051-0.525,1.588c-2.006,5.957-3.999,11.903-6.017,17.848c-0.275,0.812-0.406,1.707-1.027,2.388
			c-0.251,0.06-0.478,0.167-0.704,0.167c-1.91,0.012-3.82,0.012-5.718,0c-0.227,0-0.454-0.096-0.824-0.179
			c0-0.322-0.048-0.49,0.012-0.633c2.423-7.235,4.859-14.469,7.294-21.692c0.072-0.215,0.251-0.394,0.358-0.573
			c0.179-0.072,0.322-0.179,0.478-0.179c2.065-0.012,4.131-0.012,6.196-0.012C395.508,629.671,395.771,630.053,395.664,630.483
			L395.664,630.483z M383.152,618.903c-0.227,1.504-0.716,2.925-1.516,4.226c-1.433,2.328-3.235,4.31-5.289,6.101
			c-0.716,0.621-1.433,1.254-2.244,1.707c-0.812-0.251-0.824-0.251-1.086-1.158c0.478-0.478,0.991-0.967,1.492-1.48
			c1.015-1.015,1.922-2.113,2.615-3.391c0.501-0.931,0.752-1.91,0.68-2.973c-0.096-1.421-0.704-2.149-2.125-2.447
			c-0.227-0.048-0.466-0.06-0.704-0.107c-0.275-0.06-0.489-0.43-0.442-0.74c0.203-1.552,0.872-2.865,2.053-3.916
			c1.027-0.895,2.197-1.23,3.534-1.027c1.015,0.155,1.779,0.657,2.292,1.528C383.092,616.372,383.343,617.601,383.152,618.903
			L383.152,618.903z"
          />
        </g>
      </g>
      <g id="star_1_" enable-background="new    ">
        <g id="star">
          <path
            d="M24.366,697.326c-0.191,0.251-0.418,0.478-0.669,0.669c-0.752,0.585-1.528,1.134-2.28,1.719
			c-0.37,0.286-0.752,0.585-1.086,0.931c-0.525,0.537-0.8,1.218-0.633,1.958c0.239,1.086,0.561,2.149,0.848,3.223
			c0.119,0.454,0.31,0.895,0.251,1.397c-0.036,0.263-0.43,0.597-0.68,0.513c-0.227-0.072-0.466-0.119-0.657-0.251
			c-0.669-0.43-1.313-0.895-1.982-1.325c-0.466-0.31-0.931-0.621-1.421-0.871c-0.346-0.167-0.752-0.203-1.17-0.31
			c-0.442,0.167-0.907,0.275-1.313,0.513c-0.68,0.406-1.325,0.883-1.982,1.313c-0.466,0.298-0.919,0.621-1.421,0.872
			c-0.179,0.095-0.501,0.072-0.669-0.036s-0.322-0.418-0.287-0.597c0.167-0.776,0.394-1.54,0.609-2.304
			c0.191-0.692,0.418-1.373,0.561-2.065c0.155-0.752-0.048-1.445-0.597-1.97c-0.513-0.501-1.086-0.931-1.648-1.373
			c-0.621-0.49-1.265-0.955-1.874-1.468c-0.155-0.131-0.239-0.406-0.263-0.621c-0.036-0.227,0.358-0.549,0.657-0.597
			c0.31-0.048,0.621-0.107,0.943-0.131c0.955-0.072,1.898-0.119,2.853-0.191c0.322-0.024,0.633-0.036,0.943-0.107
			c0.657-0.143,1.182-0.501,1.445-1.134c0.31-0.728,0.561-1.492,0.824-2.232c0.298-0.824,0.585-1.648,0.907-2.447
			c0.048-0.119,0.239-0.239,0.37-0.251c0.131-0.012,0.322,0.072,0.394,0.179c0.167,0.263,0.322,0.549,0.418,0.836
			c0.322,0.895,0.597,1.815,0.919,2.71c0.167,0.442,0.358,0.883,0.585,1.301c0.239,0.43,0.609,0.752,1.098,0.86
			c0.537,0.119,1.086,0.203,1.648,0.251c1.349,0.119,2.698,0.191,4.035,0.298c0.119,0.012,0.287,0.167,0.334,0.286
			C24.45,697.004,24.438,697.219,24.366,697.326L24.366,697.326z M440.552,697.565c-0.131,0.191-0.251,0.418-0.43,0.561
			c-0.549,0.454-1.122,0.883-1.683,1.325c-0.37,0.298-0.752,0.585-1.11,0.907c-0.692,0.633-0.991,1.433-0.872,2.364
			c0.072,0.549,0.215,1.086,0.358,1.624c0.191,0.692,0.418,1.373,0.609,2.053c0.06,0.227,0.036,0.466,0.036,0.704
			c0,0.275-0.43,0.597-0.692,0.525c-0.227-0.072-0.466-0.119-0.657-0.239c-0.478-0.286-0.931-0.609-1.385-0.919
			c-0.466-0.31-0.919-0.621-1.385-0.919c-1.051-0.657-2.101-0.669-3.128,0.012c-0.86,0.573-1.707,1.17-2.567,1.731
			c-0.263,0.167-0.573,0.263-0.86,0.382c-0.251,0.096-0.692-0.203-0.716-0.478c-0.012-0.239-0.072-0.478-0.012-0.704
			c0.179-0.692,0.418-1.373,0.609-2.053c0.143-0.537,0.298-1.074,0.382-1.624c0.155-1.015-0.155-1.898-0.967-2.543
			c-0.872-0.692-1.779-1.325-2.662-2.018c-0.31-0.239-0.573-0.537-0.848-0.824c-0.179-0.191,0.024-0.74,0.287-0.776
			c0.31-0.048,0.621-0.107,0.943-0.131c1.265-0.084,2.543-0.143,3.808-0.251c1.051-0.084,1.791-0.633,2.173-1.612
			c0.346-0.883,0.645-1.791,0.991-2.686c0.167-0.442,0.37-0.871,0.597-1.289c0.06-0.119,0.251-0.167,0.382-0.251
			c0.143,0.048,0.322,0.06,0.406,0.167c0.155,0.179,0.275,0.394,0.358,0.609c0.251,0.669,0.466,1.349,0.704,2.03
			c0.155,0.454,0.322,0.895,0.466,1.349c0.346,1.146,1.146,1.648,2.304,1.707c0.549,0.036,1.11,0.06,1.659,0.107
			c0.716,0.06,1.421,0.119,2.137,0.227c0.227,0.036,0.43,0.191,0.633,0.298C440.54,697.016,440.648,697.434,440.552,697.565
			L440.552,697.565z M506.106,697.135c-0.466,0.418-0.943,0.836-1.433,1.242c-0.609,0.501-1.265,0.967-1.85,1.492
			c-0.704,0.621-0.979,1.456-0.824,2.376c0.131,0.776,0.37,1.54,0.573,2.304c0.119,0.466,0.275,0.907,0.394,1.373
			c0.119,0.454,0,0.895-0.287,1.074c-0.31,0.179-0.621,0.119-0.895-0.06c-0.609-0.37-1.194-0.776-1.791-1.17
			c-0.466-0.298-0.919-0.621-1.409-0.895c-0.824-0.466-1.671-0.501-2.495-0.036c-0.692,0.394-1.337,0.86-2.006,1.289
			c-0.537,0.346-1.063,0.704-1.624,0.991c-0.167,0.084-0.501,0.048-0.657-0.072c-0.167-0.119-0.298-0.406-0.287-0.597
			c0.024-0.394,0.131-0.776,0.239-1.158c0.215-0.764,0.466-1.516,0.669-2.28c0.107-0.382,0.143-0.776,0.191-1.015
			c-0.024-0.812-0.215-1.433-0.704-1.874c-0.585-0.537-1.218-1.015-1.839-1.516c-0.609-0.501-1.23-1.003-1.839-1.504
			c-0.179-0.143-0.203-0.501-0.072-0.657c0.107-0.119,0.215-0.251,0.346-0.298c0.227-0.072,0.466-0.107,0.704-0.119
			c1.027-0.072,2.065-0.143,3.092-0.215c0.394-0.024,0.788-0.048,1.182-0.119c0.752-0.143,1.337-0.549,1.647-1.254
			c0.358-0.8,0.633-1.624,0.943-2.447c0.227-0.585,0.454-1.182,0.74-1.743c0.084-0.155,0.37-0.251,0.585-0.287
			c0.107-0.012,0.31,0.131,0.358,0.263c0.346,0.883,0.669,1.779,1.015,2.674c0.251,0.669,0.513,1.337,0.812,1.982
			c0.167,0.37,0.501,0.621,0.907,0.704c0.537,0.107,1.098,0.191,1.647,0.251c0.955,0.095,1.898,0.131,2.853,0.239
			c0.31,0.036,0.609,0.167,0.895,0.275C506.178,696.443,506.309,696.944,506.106,697.135L506.106,697.135z M58.51,697.625
			c-0.143,0.191-0.251,0.406-0.43,0.549c-0.489,0.406-1.003,0.776-1.492,1.182c-0.489,0.394-0.991,0.788-1.468,1.218
			c-0.633,0.585-0.872,1.337-0.716,2.161c0.131,0.704,0.37,1.373,0.561,2.065c0.179,0.692,0.382,1.373,0.525,2.077
			c0.036,0.191-0.096,0.466-0.215,0.645c-0.143,0.215-0.621,0.227-0.883,0.06c-0.537-0.334-1.074-0.68-1.6-1.027
			c-0.597-0.394-1.194-0.788-1.791-1.17c-0.812-0.513-1.659-0.525-2.483-0.036c-0.609,0.358-1.194,0.788-1.791,1.17
			c-0.597,0.382-1.194,0.788-1.815,1.122c-0.167,0.084-0.489,0.036-0.645-0.084c-0.155-0.119-0.298-0.43-0.263-0.609
			c0.155-0.776,0.358-1.54,0.573-2.304c0.203-0.764,0.442-1.528,0.585-2.029c0.06-0.848-0.084-1.385-0.525-1.791
			c-0.466-0.43-0.955-0.836-1.456-1.218c-0.633-0.49-1.277-0.943-1.91-1.421c-0.179-0.143-0.322-0.346-0.478-0.525
			c-0.239-0.275-0.012-0.872,0.358-0.967c0.227-0.06,0.466-0.119,0.692-0.143c0.872-0.072,1.743-0.131,2.615-0.203
			c0.478-0.036,0.955-0.072,1.421-0.143c0.848-0.119,1.468-0.573,1.803-1.385c0.215-0.513,0.394-1.039,0.573-1.564
			c0.239-0.669,0.454-1.361,0.704-2.03c0.084-0.215,0.239-0.406,0.37-0.597c0.167-0.251,0.669-0.251,0.824,0.012
			c0.119,0.203,0.263,0.406,0.346,0.621c0.298,0.824,0.573,1.648,0.872,2.471c0.167,0.442,0.334,0.895,0.537,1.313
			c0.298,0.609,0.776,1.003,1.445,1.122c0.466,0.084,0.943,0.131,1.421,0.167c0.955,0.084,1.898,0.143,2.853,0.239
			c0.227,0.024,0.454,0.143,0.669,0.227C58.522,696.896,58.677,697.41,58.51,697.625L58.51,697.625z M570.836,697.183
			c-0.275,0.286-0.549,0.573-0.848,0.824c-0.74,0.597-1.516,1.158-2.244,1.779c-0.788,0.669-1.086,1.564-0.907,2.579
			c0.107,0.621,0.298,1.242,0.466,1.85c0.119,0.466,0.275,0.907,0.394,1.373c0.06,0.227,0.084,0.466,0.084,0.704
			s-0.036,0.513-0.275,0.621c-0.191,0.084-0.466,0.155-0.657,0.096c-0.298-0.084-0.573-0.275-0.836-0.442
			c-0.597-0.394-1.182-0.812-1.779-1.206c-0.334-0.215-0.681-0.418-1.027-0.597c-0.68-0.334-1.397-0.346-2.077-0.012
			c-0.573,0.275-1.098,0.645-1.636,0.979c-0.669,0.43-1.313,0.883-1.982,1.313c-0.286,0.179-0.657,0.143-0.883-0.048
			c-0.286-0.239-0.31-0.573-0.239-0.871c0.131-0.621,0.334-1.23,0.513-1.839c0.131-0.454,0.298-0.907,0.394-1.373
			c0.084-0.382,0.119-0.788,0.131-1.182c0.012-0.669-0.215-1.265-0.716-1.707c-0.597-0.525-1.242-0.991-1.862-1.492
			c-0.621-0.501-1.242-0.991-1.839-1.504c-0.107-0.084-0.191-0.334-0.143-0.43c0.095-0.191,0.275-0.466,0.454-0.478
			c1.11-0.131,2.209-0.203,3.319-0.298c0.478-0.036,0.955-0.048,1.433-0.096c0.943-0.107,1.648-0.597,2.006-1.48
			c0.334-0.812,0.597-1.647,0.907-2.459c0.191-0.513,0.394-1.039,0.645-1.528c0.095-0.179,0.358-0.263,0.525-0.37
			c0.37,0.143,0.561,0.406,0.669,0.704c0.298,0.824,0.585,1.647,0.86,2.483c0.86,2.507,0.955,2.579,3.498,2.77
			c0.955,0.072,1.898,0.143,2.853,0.251c0.227,0.024,0.442,0.167,0.657,0.263C570.92,696.467,571.027,697.004,570.836,697.183
			L570.836,697.183z M197.354,697.672c-0.179,0.251-0.394,0.501-0.633,0.692c-0.68,0.537-1.397,1.051-2.077,1.588
			c-0.31,0.239-0.609,0.513-0.883,0.788c-0.346,0.358-0.537,0.8-0.513,1.289c0.036,0.549,0.096,1.11,0.239,1.636
			c0.215,0.848,0.513,1.671,0.74,2.507c0.084,0.298,0.048,0.621,0.048,0.943c0,0.275-0.454,0.573-0.704,0.454
			c-0.358-0.167-0.728-0.31-1.051-0.525c-0.8-0.513-1.576-1.074-2.388-1.576c-0.955-0.597-1.958-0.633-2.925-0.024
			c-0.74,0.466-1.445,0.979-2.185,1.445c-0.394,0.251-0.824,0.478-1.242,0.692c-0.346,0.179-0.872-0.191-0.836-0.573
			c0.048-0.394,0.084-0.788,0.191-1.17c0.275-0.991,0.585-1.982,0.895-2.961c0.275-0.848,0.024-1.576-0.585-2.173
			c-0.454-0.442-0.955-0.836-1.456-1.23c-0.752-0.585-1.528-1.146-2.268-1.731c-0.179-0.143-0.298-0.37-0.382-0.573
			c-0.036-0.107,0.036-0.334,0.131-0.394c0.191-0.119,0.43-0.191,0.657-0.215c1.265-0.084,2.531-0.143,3.796-0.227
			c0.394-0.024,0.8-0.036,1.182-0.119c0.561-0.119,1.051-0.418,1.289-0.967c0.31-0.728,0.573-1.48,0.848-2.221
			c0.31-0.812,0.621-1.624,0.955-2.435c0.048-0.119,0.239-0.191,0.37-0.263c0.155-0.084,0.478,0.095,0.573,0.31
			c0.131,0.286,0.263,0.573,0.37,0.871c0.287,0.824,0.561,1.659,0.848,2.483c0.131,0.37,0.275,0.74,0.454,1.098
			c0.287,0.609,0.8,1.003,1.456,1.098c0.704,0.107,1.421,0.119,2.125,0.191c0.86,0.096,1.731,0.179,2.591,0.322
			c0.179,0.036,0.322,0.31,0.454,0.454C197.426,697.374,197.438,697.565,197.354,697.672L197.354,697.672z M336.174,697.828
			c-0.478,0.418-0.991,0.8-1.48,1.206c-0.621,0.501-1.265,0.979-1.839,1.516c-0.561,0.513-0.788,1.206-0.68,1.958
			c0.096,0.621,0.275,1.242,0.43,1.85c0.179,0.692,0.394,1.373,0.549,2.077c0.048,0.215-0.024,0.466-0.06,0.704
			c-0.024,0.179-0.394,0.43-0.549,0.382c-0.227-0.06-0.478-0.084-0.681-0.203c-0.537-0.334-1.051-0.716-1.588-1.063
			c-0.537-0.346-1.063-0.692-1.636-0.979c-0.752-0.37-1.564-0.37-2.292,0.048c-0.692,0.382-1.337,0.86-2.006,1.277
			c-0.537,0.334-1.074,0.669-1.624,0.979c-0.346,0.191-0.895-0.155-0.86-0.537c0.036-0.394,0.072-0.788,0.179-1.17
			c0.191-0.692,0.454-1.361,0.633-2.053c0.143-0.537,0.203-1.086,0.263-1.409c-0.048-0.907-0.275-1.504-0.788-1.922
			c-0.8-0.657-1.636-1.266-2.447-1.898c-0.501-0.382-1.051-0.716-1.397-1.289c-0.107-0.167,0-0.549,0.191-0.597
			c0.227-0.048,0.454-0.131,0.692-0.143c1.504-0.084,3.02-0.155,4.525-0.215c0.8-0.036,1.373-0.442,1.671-1.146
			c0.37-0.871,0.657-1.791,1.003-2.674c0.203-0.513,0.43-1.027,0.669-1.516c0.155-0.322,0.848-0.287,1.015,0.036
			c0.143,0.275,0.298,0.561,0.406,0.86c0.334,0.895,0.645,1.791,0.955,2.698c0.418,1.206,1.277,1.755,2.531,1.791
			c1.027,0.036,2.065,0.107,3.092,0.191c0.31,0.024,0.609,0.143,0.907,0.239C336.33,696.932,336.461,697.577,336.174,697.828
			L336.174,697.828z M232.071,697.792c-0.418,0.358-0.848,0.716-1.289,1.051c-0.561,0.442-1.146,0.86-1.707,1.289
			c-0.907,0.704-1.206,1.648-1.015,2.746c0.084,0.466,0.215,0.931,0.346,1.385c0.191,0.692,0.406,1.373,0.573,2.065
			c0.06,0.227,0.024,0.466,0.012,0.704c0,0.287-0.418,0.597-0.68,0.513c-0.227-0.072-0.466-0.119-0.657-0.251
			c-0.669-0.43-1.301-0.907-1.97-1.337c-0.466-0.298-0.943-0.585-1.445-0.824c-0.525-0.251-1.098-0.263-1.624-0.012
			c-0.573,0.275-1.122,0.597-1.648,0.931c-0.74,0.466-1.445,0.979-2.185,1.445c-0.179,0.119-0.442,0.107-0.716,0.155
			c-0.131-0.203-0.334-0.382-0.358-0.573c-0.036-0.298-0.012-0.633,0.072-0.931c0.227-0.836,0.525-1.671,0.752-2.507
			c0.119-0.454,0.203-0.931,0.239-1.397c0.048-0.585-0.143-1.11-0.561-1.516c-0.334-0.334-0.716-0.633-1.086-0.919
			c-0.752-0.585-1.528-1.146-2.268-1.743c-0.179-0.143-0.346-0.37-0.382-0.585c-0.036-0.191,0.072-0.478,0.215-0.621
			c0.155-0.155,0.418-0.251,0.645-0.275c1.027-0.095,2.053-0.167,3.08-0.239c0.394-0.024,0.8-0.012,1.194-0.06
			c0.752-0.095,1.361-0.466,1.648-1.206c0.406-1.039,0.752-2.089,1.146-3.128c0.143-0.37,0.334-0.704,0.525-1.051
			c0.119-0.215,0.704-0.203,0.86,0c0.143,0.191,0.31,0.37,0.394,0.585c0.215,0.513,0.382,1.051,0.561,1.564
			c0.179,0.525,0.358,1.051,0.549,1.576c0.394,1.074,1.146,1.683,2.316,1.743c1.11,0.06,2.221,0.119,3.331,0.215
			c0.31,0.024,0.609,0.143,0.919,0.227C232.226,696.932,232.37,697.541,232.071,697.792L232.071,697.792z M405.775,697.768
			c-0.478,0.418-0.979,0.812-1.48,1.194c-0.561,0.442-1.146,0.86-1.707,1.301c-0.883,0.716-1.254,1.624-0.931,2.746
			c0.287,0.991,0.597,1.982,0.872,2.973c0.084,0.298,0.131,0.621,0.179,0.931c0.048,0.358-0.49,0.8-0.812,0.669
			c-0.286-0.119-0.585-0.239-0.848-0.406c-0.657-0.442-1.289-0.943-1.958-1.361c-0.466-0.298-0.967-0.537-1.492-0.728
			c-0.537-0.203-1.11-0.179-1.624,0.107c-0.752,0.442-1.48,0.931-2.221,1.397c-0.537,0.334-1.074,0.68-1.624,0.979
			c-0.107,0.06-0.346,0.024-0.442-0.06c-0.167-0.143-0.382-0.358-0.382-0.537c0-0.394,0.107-0.776,0.215-1.158
			c0.286-0.991,0.621-1.97,0.872-2.973c0.239-0.931-0.06-1.731-0.764-2.364c-0.358-0.322-0.728-0.609-1.11-0.907
			c-0.442-0.346-0.895-0.657-1.337-1.003c-0.251-0.191-0.501-0.406-0.692-0.645c-0.131-0.179-0.191-0.43-0.203-0.657
			c-0.012-0.251,0.346-0.561,0.645-0.597c0.394-0.06,0.788-0.119,1.182-0.155c0.788-0.06,1.588-0.107,2.376-0.119
			c1.48-0.012,2.364-0.752,2.782-2.137c0.251-0.836,0.549-1.659,0.848-2.483c0.107-0.287,0.287-0.561,0.454-0.824
			c0.143-0.227,0.68-0.227,0.836,0.012c0.131,0.191,0.298,0.382,0.382,0.597c0.251,0.669,0.478,1.349,0.716,2.018
			c0.167,0.454,0.322,0.895,0.513,1.337c0.382,0.871,1.063,1.385,2.018,1.456c1.11,0.084,2.221,0.107,3.331,0.191
			c0.394,0.036,0.788,0.119,1.146,0.263c0.179,0.072,0.298,0.322,0.418,0.466C405.895,697.481,405.883,697.672,405.775,697.768
			L405.775,697.768z M300.646,698.532c-0.561,0.442-1.158,0.848-1.719,1.289c-0.37,0.298-0.716,0.621-1.051,0.967
			c-0.406,0.418-0.609,0.955-0.513,1.528c0.119,0.704,0.298,1.397,0.478,2.089c0.191,0.764,0.43,1.528,0.621,2.304
			c0.084,0.334-0.084,0.609-0.346,0.824c-0.334,0.095-0.633,0-0.907-0.179c-0.597-0.394-1.17-0.824-1.767-1.218
			c-0.466-0.298-0.931-0.609-1.433-0.86c-0.836-0.418-1.719-0.418-2.519,0.072c-0.883,0.525-1.719,1.158-2.579,1.719
			c-0.334,0.215-0.692,0.37-1.051,0.537c-0.251,0.119-0.704-0.215-0.68-0.501c0.024-0.31,0.036-0.633,0.107-0.931
			c0.275-1.003,0.585-1.982,0.86-2.985c0.322-1.206-0.048-2.209-0.979-2.985c-0.669-0.561-1.397-1.063-2.077-1.612
			c-0.37-0.298-0.704-0.633-1.051-0.955c-0.215-0.203-0.048-0.692,0.251-0.776c0.298-0.095,0.609-0.227,0.907-0.239
			c1.027-0.084,2.065-0.215,3.092-0.167c1.433,0.06,2.232-0.609,2.662-1.898c0.275-0.824,0.585-1.647,0.907-2.459
			c0.143-0.358,0.346-0.704,0.537-1.051c0.191-0.334,0.848-0.286,1.003,0.072c0.251,0.585,0.489,1.17,0.716,1.767
			c0.286,0.74,0.525,1.504,0.836,2.233c0.31,0.704,0.907,1.098,1.648,1.23c0.394,0.072,0.788,0.072,1.182,0.095
			c0.716,0.036,1.433,0.048,2.149,0.119c0.609,0.06,1.385-0.06,1.588,0.728C301.696,697.899,301.075,698.198,300.646,698.532
			L300.646,698.532z M162.948,697.434c-0.191,0.251-0.418,0.478-0.657,0.68c-0.621,0.49-1.277,0.943-1.898,1.433
			c-0.442,0.346-0.872,0.692-1.265,1.086c-0.537,0.537-0.752,1.218-0.633,1.97c0.096,0.549,0.263,1.074,0.406,1.612
			c0.167,0.609,0.37,1.218,0.525,1.827c0.155,0.585,0.024,1.194-0.275,1.313c-0.406,0.167-0.788,0.012-1.122-0.203
			c-0.537-0.346-1.051-0.716-1.576-1.074c-2.232-1.528-2.817-1.516-5.134,0.048c-0.597,0.406-1.17,0.824-1.767,1.218
			c-0.191,0.119-0.43,0.179-0.657,0.263c-0.275,0.095-0.692-0.227-0.657-0.513c0.036-0.31,0.072-0.633,0.155-0.931
			c0.251-0.919,0.537-1.827,0.788-2.746c0.107-0.382,0.167-0.776,0.203-1.17c0.048-0.585-0.143-1.11-0.573-1.504
			c-0.525-0.478-1.086-0.919-1.648-1.373c-0.621-0.501-1.254-0.967-1.85-1.492c-0.155-0.131-0.215-0.418-0.239-0.633
			c-0.024-0.251,0.334-0.537,0.669-0.573c0.239-0.036,0.466-0.072,0.704-0.084c0.872-0.048,1.743-0.131,2.615-0.131
			c2.328-0.012,2.579-0.621,3.223-2.459c0.215-0.597,0.382-1.218,0.621-1.803c0.227-0.561,0.263-1.397,1.074-1.373
			c0.645,0.024,0.728,0.764,0.907,1.265c0.275,0.74,0.501,1.504,0.764,2.256c0.131,0.37,0.298,0.74,0.489,1.074
			c0.239,0.43,0.633,0.74,1.11,0.836c0.537,0.107,1.098,0.143,1.648,0.179c1.265,0.096,2.531,0.179,3.796,0.287
			c0.119,0.012,0.227,0.191,0.334,0.298C163.007,697.147,163.031,697.326,162.948,697.434L162.948,697.434z M93.406,697.362
			c-0.131,0.191-0.239,0.418-0.418,0.561c-0.609,0.501-1.254,0.967-1.886,1.468c-0.442,0.346-0.871,0.692-1.289,1.063
			c-0.561,0.513-0.824,1.182-0.74,1.922c0.072,0.633,0.203,1.253,0.358,1.862c0.155,0.621,0.394,1.206,0.549,1.827
			c0.084,0.298,0.084,0.621,0.096,0.943c0.012,0.263-0.43,0.609-0.669,0.525c-0.227-0.072-0.466-0.131-0.657-0.263
			c-0.537-0.346-1.039-0.728-1.564-1.086c-0.322-0.227-0.657-0.442-1.003-0.645c-1.206-0.752-2.412-0.692-3.582,0.084
			c-0.525,0.346-1.051,0.728-1.576,1.074c-0.394,0.263-0.8,0.525-1.206,0.764c-0.131,0.072-0.298,0.072-0.525,0.107
			c-0.131-0.131-0.346-0.263-0.418-0.454c-0.084-0.203-0.084-0.478-0.024-0.692c0.179-0.692,0.406-1.373,0.609-2.053
			c0.848-2.758,0.716-3.152-1.504-4.847c-0.692-0.525-1.373-1.074-2.041-1.636c-0.179-0.143-0.31-0.418-0.191-0.645
			c0.096-0.191,0.287-0.382,0.478-0.49c0.203-0.107,0.454-0.143,0.692-0.167c0.716-0.06,1.433-0.107,2.137-0.143
			c0.561-0.036,1.11-0.036,1.671-0.072c0.776-0.06,1.361-0.454,1.659-1.182c0.31-0.728,0.561-1.492,0.836-2.232
			c0.131-0.37,0.251-0.752,0.394-1.122c0.167-0.43,0.275-1.015,0.836-1.027c0.704-0.024,0.812,0.645,1.003,1.146
			c0.203,0.525,0.358,1.051,0.537,1.576c0.155,0.454,0.31,0.895,0.501,1.337c0.406,0.967,1.134,1.504,2.197,1.552
			c1.194,0.06,2.388,0.107,3.57,0.179c0.31,0.012,0.621,0.107,0.931,0.179C93.335,696.801,93.502,697.219,93.406,697.362
			L93.406,697.362z M128.004,697.696c-0.346,0.334-0.704,0.633-1.086,0.919c-0.633,0.478-1.277,0.955-1.922,1.421
			c-1.194,0.86-1.516,1.97-1.074,3.355c0.287,0.907,0.549,1.827,0.8,2.746c0.084,0.298,0.107,0.621,0.143,0.931
			c0.036,0.286-0.394,0.633-0.645,0.537c-0.287-0.119-0.597-0.227-0.86-0.394c-0.669-0.43-1.301-0.907-1.97-1.337
			c-0.394-0.251-0.812-0.501-1.254-0.681c-0.692-0.286-1.421-0.298-2.077,0.048c-0.704,0.37-1.349,0.836-2.03,1.254
			c-0.537,0.334-1.063,0.681-1.612,1.003c-0.322,0.191-0.872-0.203-0.824-0.585c0.036-0.394,0.084-0.788,0.179-1.17
			c0.215-0.848,0.501-1.671,0.704-2.519c0.287-1.182-0.024-2.209-0.967-2.997c-0.669-0.561-1.385-1.074-2.065-1.612
			c-0.31-0.251-0.609-0.513-0.895-0.776c-0.275-0.251-0.119-0.872,0.251-0.991c0.298-0.095,0.609-0.215,0.919-0.239
			c0.955-0.084,1.91-0.107,2.853-0.179c0.478-0.036,0.943-0.107,1.409-0.203c0.394-0.095,0.74-0.346,0.907-0.716
			c0.275-0.573,0.513-1.158,0.74-1.755c0.251-0.669,0.466-1.349,0.716-2.029c0.084-0.215,0.215-0.418,0.334-0.621
			c0.191-0.334,0.848-0.346,1.015-0.012c0.215,0.418,0.418,0.848,0.585,1.301c0.31,0.824,0.561,1.659,0.883,2.471
			c0.394,0.979,1.158,1.48,2.209,1.552c1.194,0.072,2.376,0.119,3.57,0.215c0.31,0.024,0.609,0.167,0.895,0.298
			c0.119,0.06,0.191,0.239,0.275,0.358C128.087,697.41,128.099,697.601,128.004,697.696L128.004,697.696z M370.975,697.804
			c-0.478,0.418-0.979,0.8-1.48,1.194c-0.561,0.442-1.146,0.86-1.695,1.301c-0.895,0.728-1.17,1.671-0.86,2.77
			c0.251,0.919,0.561,1.815,0.824,2.734c0.107,0.37,0.143,0.776,0.191,1.158c0.036,0.287-0.382,0.633-0.633,0.549
			c-0.298-0.107-0.609-0.203-0.871-0.358c-0.537-0.334-1.051-0.704-1.588-1.051c-0.394-0.263-0.8-0.525-1.206-0.764
			c-0.895-0.525-1.815-0.513-2.71,0c-0.478,0.275-0.943,0.585-1.409,0.883s-0.907,0.633-1.397,0.907
			c-0.191,0.119-0.454,0.131-0.68,0.179c-0.251,0.048-0.669-0.298-0.657-0.573c0.024-0.394,0.048-0.788,0.143-1.17
			c0.239-0.919,0.561-1.827,0.776-2.746c0.227-1.003-0.06-1.898-0.895-2.555c-0.752-0.585-1.528-1.134-2.28-1.719
			c-0.31-0.239-0.597-0.513-0.872-0.788c-0.263-0.263-0.084-0.883,0.275-0.991c0.227-0.06,0.454-0.155,0.68-0.167
			c1.027-0.072,2.065-0.107,3.092-0.167c0.394-0.024,0.788-0.06,1.182-0.143c0.478-0.107,0.871-0.394,1.122-0.824
			c0.191-0.346,0.358-0.704,0.501-1.074c0.346-0.883,0.657-1.791,1.003-2.674c0.084-0.215,0.227-0.418,0.346-0.609
			c0.143-0.239,0.68-0.286,0.836-0.06c0.179,0.251,0.394,0.501,0.501,0.788c0.298,0.74,0.537,1.492,0.812,2.232
			c0.167,0.442,0.334,0.895,0.549,1.313c0.275,0.513,0.728,0.836,1.313,0.943c0.466,0.072,0.943,0.096,1.421,0.119
			c1.027,0.072,2.053,0.119,3.08,0.215c0.215,0.024,0.442,0.179,0.609,0.322c0.107,0.084,0.119,0.275,0.179,0.418
			C371.106,697.529,371.082,697.708,370.975,697.804L370.975,697.804z M266.645,697.911c-0.74,0.597-1.504,1.17-2.256,1.755
			c-1.839,1.445-2.018,1.994-1.385,4.298c0.203,0.764,0.478,1.516,0.692,2.268c0.06,0.227,0.096,0.466,0.084,0.704
			c-0.012,0.143-0.095,0.346-0.203,0.406c-0.191,0.107-0.478,0.215-0.645,0.143c-0.43-0.179-0.836-0.454-1.23-0.704
			c-0.669-0.43-1.301-0.907-1.982-1.313c-1.122-0.681-2.268-0.633-3.367,0.06c-0.609,0.382-1.17,0.824-1.767,1.218
			c-0.394,0.263-0.812,0.501-1.218,0.728c-0.322,0.179-0.883-0.179-0.848-0.561c0.036-0.394,0.084-0.788,0.191-1.17
			c0.227-0.836,0.525-1.659,0.752-2.507c0.334-1.289-0.06-2.352-1.098-3.164c-0.812-0.633-1.648-1.254-2.459-1.886
			c-0.37-0.286-0.764-0.573-0.883-1.146c0.167-0.119,0.334-0.346,0.525-0.37c1.182-0.107,2.376-0.215,3.558-0.227
			c2.209-0.024,2.698-0.466,3.391-2.567c0.227-0.68,0.466-1.349,0.704-2.018c0.084-0.227,0.215-0.418,0.322-0.633
			c0.179-0.334,0.848-0.358,1.015-0.024c0.227,0.418,0.43,0.848,0.609,1.277c0.322,0.812,0.585,1.647,0.943,2.435
			c0.43,0.943,1.206,1.445,2.256,1.492c1.349,0.072,2.698,0.131,4.035,0.215c0.334,0.024,0.597,0.167,0.728,0.49
			C267.111,697.469,266.896,697.708,266.645,697.911L266.645,697.911z M540.62,697.255c-0.227,0.227-0.442,0.454-0.692,0.645
			c-0.561,0.442-1.146,0.848-1.707,1.289c-1.827,1.409-2.006,2.018-1.325,4.298c0.203,0.68,0.442,1.361,0.633,2.042
			c0.084,0.298,0.107,0.621,0.107,0.621c0.036,0.704-0.478,1.074-0.812,0.883c-0.478-0.275-0.943-0.561-1.409-0.86
			c-0.74-0.466-1.445-0.979-2.197-1.409c-0.812-0.466-1.695-0.478-2.507,0c-0.883,0.513-1.707,1.146-2.579,1.695
			c-0.334,0.215-0.692,0.358-1.051,0.513c-0.251,0.107-0.704-0.239-0.669-0.501c0.048-0.394,0.084-0.788,0.191-1.158
			c0.263-1.003,0.573-1.982,0.836-2.973c0.239-0.931-0.012-1.755-0.716-2.376c-0.836-0.728-1.743-1.373-2.603-2.077
			c-0.298-0.251-0.561-0.537-0.836-0.824c-0.119-0.131,0-0.549,0.167-0.621c0.215-0.095,0.43-0.239,0.657-0.263
			c0.704-0.084,1.421-0.119,2.137-0.167c0.549-0.036,1.11-0.036,1.659-0.072c1.051-0.06,1.803-0.561,2.197-1.54
			c0.215-0.513,0.382-1.039,0.573-1.564c0.215-0.597,0.418-1.194,0.657-1.779c0.084-0.203,0.286-0.37,0.442-0.549
			c0.107-0.119,0.561-0.072,0.657,0.06c0.131,0.191,0.298,0.37,0.394,0.585c0.286,0.74,0.537,1.492,0.812,2.233
			c0.167,0.442,0.334,0.895,0.537,1.313c0.298,0.609,0.812,0.979,1.468,1.098c0.466,0.084,0.943,0.107,1.421,0.143
			c0.871,0.06,1.743,0.084,2.615,0.155c0.227,0.012,0.454,0.107,0.68,0.179C540.704,696.371,540.871,696.992,540.62,697.255
			L540.62,697.255z M475.162,697.696c-0.215,0.227-0.418,0.478-0.669,0.669c-0.621,0.49-1.277,0.943-1.898,1.433
			c-1.719,1.337-1.91,1.946-1.265,4.107c0.227,0.764,0.501,1.504,0.716,2.268c0.084,0.298,0.072,0.621,0.084,0.931
			c0.012,0.286-0.418,0.609-0.669,0.49c-0.358-0.167-0.716-0.334-1.051-0.537c-0.872-0.561-1.695-1.17-2.579-1.695
			c-0.394-0.239-0.883-0.31-1.349-0.478c-0.466,0.143-0.955,0.203-1.361,0.43c-0.752,0.43-1.456,0.967-2.185,1.433
			c-0.394,0.251-0.812,0.49-1.23,0.704c-0.334,0.167-0.848-0.227-0.812-0.621c0.036-0.31,0.06-0.633,0.143-0.931
			c0.191-0.692,0.454-1.361,0.633-2.041c0.143-0.537,0.227-1.086,0.286-1.636c0.048-0.573-0.131-1.122-0.549-1.516
			c-0.573-0.549-1.206-1.027-1.815-1.54s-1.325-0.872-1.815-1.528c-0.119-0.167-0.084-0.537,0.107-0.633
			c0.203-0.107,0.406-0.251,0.633-0.298c0.382-0.084,0.788-0.119,1.182-0.143c0.716-0.048,1.433-0.048,2.137-0.095
			c0.394-0.024,0.788-0.06,1.182-0.143c0.573-0.119,1.027-0.442,1.301-0.955c0.263-0.489,0.466-1.003,0.669-1.528
			c0.287-0.74,0.513-1.504,0.8-2.244c0.084-0.215,0.275-0.382,0.418-0.561c0.131-0.167,0.525-0.179,0.645-0.024
			c0.143,0.179,0.334,0.346,0.418,0.561c0.263,0.669,0.478,1.349,0.716,2.018c0.191,0.525,0.382,1.051,0.609,1.552
			c0.322,0.704,0.919,1.086,1.671,1.194c0.466,0.072,0.943,0.072,1.421,0.107c0.872,0.06,1.743,0.096,2.615,0.179
			c0.227,0.024,0.442,0.155,0.657,0.251C475.221,696.98,475.341,697.505,475.162,697.696L475.162,697.696z M605.279,697.481
			c-0.549,0.466-1.122,0.883-1.683,1.313c-0.382,0.286-0.764,0.573-1.134,0.871c-0.979,0.764-1.361,1.731-0.991,2.937
			c0.275,0.907,0.573,1.815,0.836,2.734c0.107,0.382,0.191,0.776,0.191,1.158c0,0.191-0.203,0.406-0.358,0.549
			c-0.096,0.072-0.322,0.072-0.454,0.012c-0.418-0.215-0.824-0.466-1.218-0.728c-0.728-0.478-1.433-0.991-2.173-1.456
			c-0.955-0.597-1.97-0.609-2.925-0.024c-0.812,0.49-1.576,1.074-2.376,1.588c-0.334,0.215-0.692,0.37-1.051,0.525
			c-0.239,0.107-0.692-0.251-0.669-0.513c0.036-0.394,0.072-0.788,0.167-1.17c0.179-0.692,0.454-1.361,0.621-2.053
			c0.131-0.537,0.203-1.086,0.251-1.647c0.036-0.49-0.119-0.967-0.466-1.313c-0.501-0.501-1.051-0.967-1.6-1.421
			c-0.669-0.561-1.361-1.086-2.03-1.647c-0.239-0.191-0.143-0.692,0.155-0.812c0.286-0.119,0.585-0.251,0.895-0.286
			c0.788-0.072,1.588-0.095,2.376-0.143c0.478-0.024,0.955-0.024,1.421-0.095c0.848-0.107,1.492-0.573,1.815-1.373
			c0.394-0.955,0.704-1.946,1.074-2.901c0.143-0.358,0.358-0.692,0.561-1.027c0.143-0.227,0.692-0.203,0.836,0.036
			c0.155,0.275,0.334,0.537,0.442,0.836c0.334,0.895,0.621,1.803,0.943,2.698c0.382,1.086,1.146,1.683,2.304,1.743
			c1.11,0.06,2.221,0.095,3.331,0.179c0.31,0.024,0.633,0.095,0.907,0.239c0.179,0.095,0.263,0.346,0.406,0.537
			C605.517,697.087,605.446,697.338,605.279,697.481L605.279,697.481z"
          />
        </g>
      </g>
      <text
        transform="matrix(1.4348 0 0 1 135.2139 305.8584)"
        font-family="'College'"
        font-size="117.4282"
      >
        2021
      </text>
    </svg>
  );
}

Artwrk5.props = {
  primary: string,
};

Artwrk5.defaultProps = {
  primary: "#000",
};
