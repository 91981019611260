import React from "react";
import "./main.css";

import { connect } from "react-redux";

function Front(props) {
  return (
    <div>
      <svg
        version="1.1"
        id="backview"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="55 70 740 585"
        enableBackground="new 55 70 740 585"
        xmlSpace="preserve"
      >
        <path
          id="paths"
          fill="url(#paths_1_)"
          d="M602.545,532.418c0,0-0.271,1.446-0.555,1.947c-1.256,2.042-14.029,1.298-25.837-0.67
	c-12.293-2.393-25.092-6.656-25.333-8.978c0.068-0.311,0.25-0.952,0.4-1.49C560.014,518.585,595.235,525.194,602.545,532.418z
	 M239.127,534.261c1.235,2.008,13.788,1.276,25.403-0.661c12.094-2.354,24.683-6.547,24.918-8.831
	c-0.065-0.307-5.941-2.897-27.15,1.033C241.084,529.733,238.849,533.766,239.127,534.261z"
        />
        <linearGradient
          id="paths_1_"
          gradientUnits="userSpaceOnUse"
          x1="249.7758"
          y1="-97.6214"
          x2="597.7755"
          y2="-165.4366"
          gradientTransform="matrix(1 0 0 -1 0 400.552)"
        >
          <stop offset="0" style={{ stopColor: props.rib2 }} />
          <stop offset="0.0304" style={{ stopColor: props.rib2 }} />
          <stop offset="0.4945" style={{ stopColor: props.rib2 }} />
        </linearGradient>
        <path
          id="body"
          style={{ fill: props.body }}
          stroke=""
          strokeWidth="0.25"
          strokeMiterlimit="10"
          d="M541.46,143.18
	c-3.78-1.42-5.49-2.21-6.51-2.5c-1.56-0.44-2.69-0.67-4.09-1.1c-1.28-0.38-2.4-1.31-3.56-1.68c-1.26-0.42-4.01-1.05-5.19-1.28
	c-11.26-2.21-22.71-4.87-38.61-9.09c-1.02,0-2.69-0.27-3.22,0.34c-4.91,5.58-20.13,23.24-58.03,23.24c-0.74,0.32-1.37,0.7-1.9,1.12
	c-0.53-0.42-1.16-0.8-1.9-1.12c-37.9,0-53.12-17.66-58.03-23.24c-0.54-0.61-2.2-0.34-3.22-0.34c-15.9,4.22-27.35,6.88-38.61,9.09
	c-1.18,0.23-3.93,0.86-5.2,1.28c-1.15,0.37-2.27,1.3-3.55,1.68c-1.4,0.43-2.53,0.66-4.09,1.1c-1.02,0.29-2.73,1.08-6.51,2.5
	c-7.2,2.69-31.334,7.486-39.264,27.276c-0.14,0.34-0.41,1.02-0.54,1.53c0.17,0.17,0.37,0.3,0.6,0.45
	c12.21,10.13,42.924,89.614,43.524,136.964c0,13.1-5.06,28.57-5.06,40.19c0,8.94,2.68,8.64,2.08,15.79
	c-0.89,10.71-3.87,16.08-3.87,25.01v0.29c0.25-1.25,0.48-2.48,0.66-3.66c0.42-4.12,0.85-9.06,0.53-4.51
	c-0.1,1.42-0.28,2.93-0.53,4.51c-0.32,3.03-0.63,5.61-0.66,3.66c-2.42,12.08-7.44,26.84-7.44,36.19c0,13.13,6.55,21.74,10.42,28.89
	c12.6,8.91,50.29,18.69,117.8,18.84c0.95,0.01,1.9,0.01,2.86,0.01s1.91,0,2.86-0.01c67.5-0.16,105.2-9.94,117.8-18.84
	c3.87-7.15,10.42-15.76,10.42-28.89c0-9.36-5.04-24.15-7.45-36.24c-0.02,2.03-0.33-0.51-0.64-3.53c0.18,1.14,0.39,2.32,0.64,3.53
	v-0.24c0-8.93-2.97-14.3-3.86-25.01c-0.6-7.15,2.08-6.85,2.08-15.79c0-11.62-5.06-27.09-5.06-40.19
	c0.59-47.35,15.702-138.054,27.912-148.184C565.292,161.066,548.65,145.87,541.46,143.18z M543.33,387.02
	c-0.26-1.58-0.44-3.09-0.54-4.51C542.48,377.96,542.9,382.9,543.33,387.02z"
        />
        <path
          id="base"
          style={{ fill: props.rib2 }}
          stroke="#070707"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M420.35,474.61
	c-94.905,0.159-120.272-17.972-120.272-17.972s-0.163,32.5-0.163,39.355c10.412,16.94,69.363,22.362,120.669,22.362
	c51.301,0,110.257-5.422,120.669-22.362c0-6.855-0.243-40.233-0.243-40.233S518.195,474.446,420.35,474.61z M482.488,127.78
	c-3.684,11.494-27.644,24.241-60.873,24.241h-0.043c-0.659,0.022-1.296,0.022-1.89,0.022c-0.67-0.011-1.307-0.022-1.89-0.022h-0.043
	c-33.229,0-57.189-12.747-60.873-24.241c0.713-3.727,2.701-6.266,3.586-9.204c1.437-4.732,2.377-8.037,4.786-20.039
	c2.387-11.937,37.485-14.443,54.132-14.443c0.119,0.238,0.227,0.519,0.302,0.821c0.076-0.303,0.184-0.583,0.303-0.821
	c16.647,0,51.745,2.506,54.132,14.443c2.409,12.002,3.349,15.307,4.786,20.039C479.788,121.515,481.776,124.053,482.488,127.78z
	 M588.1,484.293c9.578,1.265,26.606,8.077,25.316,12.373c-2.789,9.298-8.569,29.186-11.145,37.065
	c-4.645-5.514-41.374-13.08-51.342-9.476c0.898-5.924,6.475-29.415,8.063-37.742C560.213,480.126,579.037,483.095,588.1,484.293z
	 M252.601,484.293c-9.577,1.265-26.606,8.077-25.316,12.373c2.79,9.298,8.569,29.186,11.145,37.065
	c4.645-5.514,41.375-13.08,51.342-9.476c-0.898-5.924-6.475-29.415-8.063-37.742C280.489,480.126,261.664,483.095,252.601,484.293z"
        />
        <path
          id="hands"
          style={{ fill: props.sleeves }}
          d="M563.166,159.343c0.136,0.407,1.715,1.481,1.886,1.873
	c9.143,9.422,55.675,114.525,68.612,148.786c4.232,11.212,4.134,12.415,4.859,14.573c1.046,3.148,4.016,7.186,5.036,12.414
	c1.436,7.379-1.258,16.375-1.258,19.975c0,7.935,3.528,28.165,3.236,32.927c-1.618,26.628-8.184,47.017-12.233,75.03
	c-0.288,1.998-3.914,2.425-4.316,4.498c-0.28,1.434,2.728,5.448,2.16,7.378c-1.8,6.119-17.812,20.511-17.812,20.511
	s-8.459-5.397-26.682-8.677c-18.875-3.398-27.661-2.118-27.661-2.118s-3.778-19.792-2.338-30.229
	c0.966-6.97,5.037-10.076,5.219-15.653c0.258-7.932,1.622-45.135,1.622-47.502c0-2.881,1.618-5.399,1.618-8.457
	c0-1.186-1.8-3.78-1.8-6.478c0-2.879,3.236-3.958,2.881-5.937c-0.466-2.565-3.69-1.501-3.961-3.419
	c-0.36-2.52,3.418-2.52,3.058-4.498c-0.254-1.417-3.058-1.978-4.138-4.138c-5.053-10.103-17.356-55.686-24.125-76.088
	C535.969,209.33,558.945,152.513,563.166,159.343z M267.561,159.082c-8.533,1.056-47.586,116.659-60.523,150.92
	c-4.232,11.212-4.134,12.415-4.859,14.573c-1.046,3.148-4.016,7.186-5.037,12.414c-1.436,7.379,1.258,16.375,1.258,19.975
	c0,7.935-3.529,28.165-3.236,32.927c1.618,26.628,8.184,47.017,12.233,75.03c0.288,1.998,3.914,2.425,4.316,4.498
	c0.28,1.434-2.728,5.448-2.16,7.378c1.8,6.119,17.812,20.511,17.812,20.511s8.459-5.397,26.682-8.677
	c18.875-3.398,27.661-2.118,27.661-2.118s3.778-19.792,2.338-30.229c-0.966-6.97-5.037-10.076-5.219-15.653
	c-0.258-7.932-1.622-45.135-1.622-47.502c0-2.881-1.618-5.399-1.618-8.457c0-1.186,1.8-3.78,1.8-6.478
	c0-2.879-3.236-3.958-2.88-5.937c0.466-2.565,3.689-1.501,3.96-3.419c0.36-2.52-3.418-2.52-3.058-4.498
	c0.254-1.417,3.058-1.978,4.138-4.138c5.053-10.103,17.88-30.878,24.649-51.28C302.112,217.471,271.915,156.358,267.561,159.082z"
        />
        <path
          id="pockets"
          style={{ fill: props.pockets }}
          d="M525.063,419.87l-8.48,7.098l-47.991-66.449l8.48-7.492L525.063,419.87z M315.634,419.87
	l8.48,7.098l47.991-66.449l-8.48-7.492L315.634,419.87z"
        />
        <g>
          <path
            fill="#191818"
            d="M477.112,352.625l-0.22,0.197l-8.669,7.652l48.309,66.892l8.9-7.454L477.112,352.625z M477.023,353.429
		l47.669,66.392l-3.715,3.109l-47.754-66.147L477.023,353.429z M468.952,360.555l3.863-3.411l47.747,66.134l-3.933,3.292
		L468.952,360.555z"
          />
          <path
            fill="#AFACAA"
            d="M463.619,359.973l13.976-12.216l0.176-0.156l52.38,72.88l-14.159,11.944L463.619,359.973L463.619,359.973z
		 M464.201,360.042l51.867,71.747l13.493-11.377l-51.861-72.17L464.201,360.042L464.201,360.042z M477.563,348.05l0.176-0.128
		L477.563,348.05L477.563,348.05z"
          />

          <path
            display="none"
            fill="url(#SVGID_1_)"
            d="M472.885,356.786c0,0,10.2,13.521,23.766,32.441
		c13.735,19.158,24.399,34.249,24.399,34.249s-11.024-14.82-24.85-33.887C482.596,370.828,472.885,356.786,472.885,356.786z"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1146CC"
              d="M1418.036,166.589c-5.013,11.251-23.399,26.466-64.621,26.466c-41.171,0-58.799-15.521-63.948-26.636
			c-0.237,0.068-0.072,0.041-0.339,0.102c4.99,11.22,23.245,27.008,64.287,27.008s59.934-15.585,64.926-26.805
			C1418.104,166.597,1418.25,166.645,1418.036,166.589z"
            />
            <path
              fill="#1146CC"
              d="M1413.936,166.148c-1.804,8.012-20.845,23.687-60.513,23.687c-39.619,0-57.757-16.13-60.161-23.586
			c-0.229,0.068-0.119,0.075-0.373,0.136c2.484,7.557,21.04,23.958,60.534,23.958s59.057-16.164,60.851-24.094
			C1414.046,166.122,1414.144,166.205,1413.936,166.148z"
            />
          </g>
          <g>
            <image
              overflow="visible"
              enableBackground="new    "
              width="131"
              height="30"
              xlinkHref="C9F9AAE5.png"
              transform="matrix(0.9924 0 0 0.9667 1289 165.2754)"
            ></image>

            <path
              fill="url(#SVGID_2_)"
              d="M1418.036,166.589c-5.013,11.251-23.399,26.466-64.621,26.466
			c-41.171,0-58.799-15.521-63.948-26.636c-0.237,0.068-0.072,0.041-0.339,0.102c4.99,11.22,23.245,27.008,64.287,27.008
			s59.934-15.585,64.926-26.805C1418.104,166.597,1418.25,166.645,1418.036,166.589z"
            />
            <path
              fill="#A06635"
              d="M1413.936,166.148c-1.804,8.012-20.845,23.687-60.513,23.687c-39.619,0-57.757-16.13-60.161-23.586
			c-0.229,0.068-0.119,0.075-0.373,0.136c2.484,7.557,21.04,23.958,60.534,23.958s59.057-16.164,60.851-24.094
			C1414.046,166.122,1414.144,166.205,1413.936,166.148z"
            />
          </g>
        </g>
        <path
          fill="none"
          stroke="#020202"
          strokeWidth="0.25"
          strokeMiterlimit="10"
          d="M420.35,144.655c0,0,4.939,5.237,4.942,8.98"
        />
        <g>
          <g>
            <path
              id="SVGID_3_"
              fill="#1C1B1B"
              d="M363.585,352.625l0.22,0.197l8.669,7.652l-48.309,66.892l-8.9-7.454L363.585,352.625z
			 M363.674,353.429l-47.669,66.392l3.715,3.109l47.754-66.147L363.674,353.429z M371.745,360.555l-3.863-3.411l-47.747,66.134
			l3.933,3.292L371.745,360.555z"
            />
          </g>
          <g>
            <path
              id="SVGID_4_"
              fill="#0F0F0E"
              d="M377.078,359.973l-13.976-12.216l-0.176-0.156l-52.379,72.88l14.159,11.944L377.078,359.973
			L377.078,359.973z M376.496,360.042l-51.867,71.747l-13.494-11.377l51.861-72.17L376.496,360.042L376.496,360.042z
			 M363.134,348.05l-0.176-0.128L363.134,348.05L363.134,348.05z"
            />
          </g>
          <g>
            <g>
              <g>
                <defs>
                  <polygon
                    id="SVGID_5_"
                    points="373.211,360.404 363.595,351.974 314.194,420.328 324.074,428.89 					"
                  />
                </defs>
              </g>
            </g>
            <g>
              <g>
                <path
                  id="SVGID_15_"
                  fill="#EAE1E1"
                  d="M377.078,359.973l-13.976-12.216l-0.176-0.156l-52.379,72.88l14.159,11.944
					L377.078,359.973L377.078,359.973z M376.496,360.042l-51.867,71.747l-13.494-11.377l51.861-72.17L376.496,360.042
					L376.496,360.042z M363.134,348.05l-0.176-0.128L363.134,348.05L363.134,348.05z"
                />
              </g>
              <g>
                <defs>
                  <polygon
                    id="SVGID_7_"
                    points="373.211,360.404 363.595,351.974 314.194,420.328 324.074,428.89 					"
                  />
                </defs>
              </g>
              <g>
                <defs>
                  <polygon
                    id="SVGID_9_"
                    points="373.211,360.404 363.595,351.974 314.194,420.328 324.074,428.89 					"
                  />
                </defs>
                <defs>
                  <path
                    id="SVGID_10_"
                    d="M363.585,352.625l0.22,0.197l8.669,7.652l-48.309,66.892l-8.9-7.454L363.585,352.625z M363.674,353.429
						l-47.669,66.392l3.715,3.109l47.754-66.147L363.674,353.429z M371.745,360.555l-3.863-3.411l-47.747,66.134l3.933,3.292
						L371.745,360.555z"
                  />
                </defs>
              </g>
              <g>
                <defs>
                  <polygon
                    id="SVGID_13_"
                    points="373.211,360.404 363.595,351.974 314.194,420.328 324.074,428.89 					"
                  />
                </defs>
                <defs>
                  <path
                    id="SVGID_14_"
                    d="M363.585,352.625l0.22,0.197l8.669,7.652l-48.309,66.892l-8.9-7.454L363.585,352.625z M363.674,353.429
						l-47.669,66.392l3.715,3.109l47.754-66.147L363.674,353.429z M371.745,360.555l-3.863-3.411l-47.747,66.134l3.933,3.292
						L371.745,360.555z"
                  />
                </defs>
                <defs>
                  <path
                    id="SVGID_16_"
                    d="M377.078,359.973l-13.976-12.216l-0.176-0.156l-52.379,72.88l14.159,11.944L377.078,359.973
						L377.078,359.973z M376.496,360.042l-51.867,71.747l-13.494-11.377l51.861-72.17L376.496,360.042L376.496,360.042z
						 M363.134,348.05l-0.176-0.128L363.134,348.05L363.134,348.05z"
                  />
                </defs>

                <path
                  clipPath="url(#SVGID_19_)"
                  fill="url(#SVGID_20_)"
                  d="M367.812,356.786c0,0-10.2,13.521-23.766,32.441
					c-13.735,19.158-24.399,34.249-24.399,34.249s11.024-14.82,24.85-33.887C358.101,370.828,367.812,356.786,367.812,356.786z"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          id="buttons"
          style={{ fill: props.buttons }}
          d="M430.591,487.948c0,3.441-2.637,6.234-5.888,6.234s-5.888-2.794-5.888-6.234
	c0-3.442,2.637-6.235,5.888-6.235S430.591,484.507,430.591,487.948z M424.703,481.713c-3.139,0-5.682,2.546-5.682,5.686
	s2.544,5.686,5.682,5.686c3.139,0,5.682-2.546,5.682-5.686C430.386,484.259,427.842,481.713,424.703,481.713z M424.703,481.829
	c-3.031,0-5.49,2.458-5.49,5.488c0,3.033,2.459,5.49,5.49,5.49c3.029,0,5.49-2.457,5.49-5.49
	C430.193,484.287,427.732,481.829,424.703,481.829z M424.703,481.933c-2.961,0-5.358,2.401-5.358,5.361
	c0,2.962,2.398,5.361,5.358,5.361s5.358-2.398,5.358-5.361C430.062,484.334,427.664,481.933,424.703,481.933z M424.703,492.308
	c-2.175,0-4.037-1.213-4.95-2.961c0.803,1.941,2.715,3.308,4.95,3.308c2.235,0,4.147-1.367,4.947-3.308
	C428.738,491.096,426.876,492.308,424.703,492.308z M424.703,481.933c-2.239,0-4.151,1.374-4.952,3.323
	c0.248,2.334,2.355,4.164,4.952,4.164s4.706-1.831,4.954-4.164C428.852,483.308,426.942,481.933,424.703,481.933z M424.701,454.79
	c-3.251,0-5.886,2.791-5.886,6.233c0,3.441,2.635,6.232,5.886,6.232s5.886-2.791,5.886-6.232
	C430.587,457.582,427.952,454.79,424.701,454.79z M424.701,454.79c-3.139,0-5.682,2.545-5.682,5.685
	c0,3.138,2.544,5.684,5.682,5.684c3.139,0,5.682-2.546,5.682-5.684C430.383,457.335,427.84,454.79,424.701,454.79z M424.701,454.905
	c-3.031,0-5.488,2.458-5.488,5.489s2.457,5.488,5.488,5.488s5.488-2.457,5.488-5.488S427.732,454.905,424.701,454.905z
	 M424.701,455.011c-2.959,0-5.361,2.4-5.361,5.358c0,2.962,2.402,5.362,5.361,5.362c2.959,0,5.361-2.4,5.361-5.362
	C430.062,457.41,427.66,455.011,424.701,455.011z M424.701,465.383c-2.175,0-4.035-1.212-4.95-2.962
	c0.805,1.942,2.717,3.309,4.95,3.309c2.232,0,4.145-1.367,4.948-3.309C428.736,464.172,426.874,465.383,424.701,465.383z
	 M424.701,455.011c-2.236,0-4.151,1.372-4.954,3.322c0.25,2.333,2.357,4.164,4.954,4.164c2.597,0,4.704-1.832,4.954-4.164
	C428.852,456.383,426.94,455.011,424.701,455.011z M424.701,381.395c-3.251,0-5.886,2.793-5.886,6.235s2.635,6.233,5.886,6.233
	s5.886-2.791,5.886-6.233C430.587,384.188,427.952,381.395,424.701,381.395z M424.701,381.395c-3.139,0-5.682,2.546-5.682,5.686
	c0,3.139,2.544,5.685,5.682,5.685c3.139,0,5.682-2.546,5.682-5.685C430.383,383.941,427.84,381.395,424.701,381.395z
	 M424.701,381.511c-3.031,0-5.488,2.458-5.488,5.49c0,3.031,2.457,5.489,5.488,5.489s5.488-2.458,5.488-5.489
	C430.189,383.969,427.732,381.511,424.701,381.511z M424.701,381.617c-2.959,0-5.361,2.402-5.361,5.36
	c0,2.96,2.402,5.361,5.361,5.361c2.959,0,5.361-2.401,5.361-5.361C430.062,384.019,427.66,381.617,424.701,381.617z
	 M424.701,391.991c-2.175,0-4.035-1.213-4.95-2.963c0.805,1.943,2.717,3.31,4.95,3.31c2.232,0,4.145-1.367,4.948-3.31
	C428.736,390.778,426.874,391.991,424.701,391.991z M424.701,381.617c-2.236,0-4.151,1.374-4.954,3.322
	c0.25,2.333,2.357,4.164,4.954,4.164c2.597,0,4.704-1.831,4.954-4.164C428.852,382.99,426.94,381.617,424.701,381.617z
	 M424.701,308.004c-3.251,0-5.886,2.791-5.886,6.233c0,3.442,2.635,6.233,5.886,6.233s5.886-2.791,5.886-6.233
	C430.587,310.795,427.952,308.004,424.701,308.004z M424.701,308.004c-3.139,0-5.682,2.545-5.682,5.685
	c0,3.138,2.544,5.684,5.682,5.684c3.139,0,5.682-2.546,5.682-5.684C430.383,310.548,427.84,308.004,424.701,308.004z
	 M424.701,308.118c-3.031,0-5.488,2.458-5.488,5.489s2.457,5.489,5.488,5.489s5.488-2.458,5.488-5.489
	C430.189,310.576,427.732,308.118,424.701,308.118z M424.701,308.224c-2.959,0-5.361,2.401-5.361,5.358
	c0,2.961,2.402,5.362,5.361,5.362c2.959,0,5.361-2.401,5.361-5.362C430.062,310.625,427.66,308.224,424.701,308.224z
	 M424.701,318.597c-2.175,0-4.035-1.211-4.95-2.962c0.805,1.941,2.717,3.309,4.95,3.309c2.232,0,4.145-1.368,4.948-3.309
	C428.736,317.385,426.874,318.597,424.701,318.597z M424.701,308.224c-2.236,0-4.151,1.372-4.954,3.321
	c0.25,2.334,2.357,4.164,4.954,4.164c2.597,0,4.704-1.831,4.954-4.164C428.852,309.596,426.94,308.224,424.701,308.224z
	 M424.701,234.61c-3.251,0-5.886,2.791-5.886,6.233c0,3.441,2.635,6.232,5.886,6.232s5.886-2.791,5.886-6.232
	C430.587,237.401,427.952,234.61,424.701,234.61z M424.701,234.61c-3.139,0-5.682,2.546-5.682,5.686
	c0,3.137,2.544,5.682,5.682,5.682c3.139,0,5.682-2.545,5.682-5.682C430.383,237.156,427.84,234.61,424.701,234.61z M424.701,234.725
	c-3.031,0-5.488,2.458-5.488,5.488c0,3.031,2.457,5.489,5.488,5.489s5.488-2.458,5.488-5.489
	C430.189,237.183,427.732,234.725,424.701,234.725z M424.701,234.831c-2.959,0-5.361,2.4-5.361,5.358
	c0,2.962,2.402,5.361,5.361,5.361c2.959,0,5.361-2.399,5.361-5.361C430.062,237.231,427.66,234.831,424.701,234.831z
	 M424.701,245.203c-2.175,0-4.035-1.21-4.95-2.961c0.805,1.94,2.717,3.308,4.95,3.308c2.232,0,4.145-1.368,4.948-3.308
	C428.736,243.992,426.874,245.203,424.701,245.203z M424.701,234.831c-2.236,0-4.151,1.371-4.954,3.322
	c0.25,2.333,2.357,4.164,4.954,4.164c2.597,0,4.704-1.833,4.954-4.164C428.852,236.202,426.94,234.831,424.701,234.831z
	 M424.701,161.216c-3.251,0-5.886,2.791-5.886,6.234c0,3.441,2.635,6.232,5.886,6.232c3.249,0,5.886-2.791,5.886-6.232
	C430.587,164.007,427.95,161.216,424.701,161.216z M424.701,161.216c-3.139,0-5.68,2.545-5.68,5.686c0,3.138,2.542,5.681,5.68,5.681
	c3.137,0,5.681-2.544,5.681-5.681C430.381,163.761,427.838,161.216,424.701,161.216z M424.701,161.33
	c-3.031,0-5.486,2.459-5.486,5.49s2.455,5.489,5.486,5.489s5.486-2.458,5.486-5.489C430.186,163.789,427.732,161.33,424.701,161.33z
	 M424.701,161.438c-2.957,0-5.361,2.4-5.361,5.358c0,2.96,2.404,5.361,5.361,5.361c2.959,0,5.361-2.401,5.361-5.361
	C430.062,163.838,427.66,161.438,424.701,161.438z M424.701,171.809c-2.175,0-4.037-1.21-4.952-2.961
	c0.803,1.942,2.719,3.309,4.952,3.309c2.232,0,4.143-1.367,4.948-3.309C428.734,170.598,426.872,171.809,424.701,171.809z
	 M424.701,161.438c-2.236,0-4.153,1.372-4.954,3.321c0.252,2.333,2.357,4.163,4.954,4.163c2.597,0,4.704-1.831,4.952-4.163
	C428.852,162.811,426.938,161.438,424.701,161.438z M424.701,502.673c-3.251,0-5.886,2.792-5.886,6.233
	c0,3.441,2.635,6.232,5.886,6.232s5.886-2.791,5.886-6.232C430.587,505.465,427.952,502.673,424.701,502.673z M424.701,502.788
	c-3.031,0-5.488,2.458-5.488,5.489s2.457,5.488,5.488,5.488s5.488-2.457,5.488-5.488S427.732,502.788,424.701,502.788z
	 M424.701,502.894c-2.959,0-5.361,2.4-5.361,5.358c0,2.962,2.402,5.362,5.361,5.362c2.959,0,5.361-2.4,5.361-5.362
	C430.062,505.294,427.66,502.894,424.701,502.894z M424.701,513.266c-2.175,0-4.035-1.211-4.95-2.962
	c0.805,1.942,2.717,3.309,4.95,3.309c2.232,0,4.145-1.367,4.948-3.309C428.736,512.055,426.874,513.266,424.701,513.266z
	 M424.701,502.894c-2.236,0-4.151,1.374-4.954,3.322c0.25,2.333,2.357,4.164,4.954,4.164c2.597,0,4.704-1.832,4.954-4.164
	C428.852,504.267,426.94,502.894,424.701,502.894z M424.701,502.673c-3.139,0-5.682,2.545-5.682,5.685
	c0,3.138,2.544,5.685,5.682,5.685c3.139,0,5.682-2.547,5.682-5.685C430.383,505.218,427.84,502.673,424.701,502.673z"
        />
        <g>
          <path
            fill="url(#SVGID_21_)"
            d="M414.026,516.69c0,0,0.106,1.355,0.885,1.626c-1.355,0-2.618,0-2.618,0v-2.304V154.635l1.732,0.136
		V516.69H414.026z"
          />

          <rect
            x="412.294"
            y="474.398"
            fill="url(#SVGID_22_)"
            width="1.732"
            height="4.168"
          />

          <path
            fill="url(#SVGID_23_)"
            d="M414.196,516.316c0,1.084,0.309,1.83,1.19,2.033c-0.169,0-0.576,0-0.807,0
		c-0.576-0.237-0.851-0.847-0.851-2.033V151.043h0.468L414.196,516.316L414.196,516.316z"
          />

          <rect
            x="413.728"
            y="474.398"
            fill="url(#SVGID_24_)"
            width="0.468"
            height="4.168"
          />

          <rect
            x="412.268"
            y="474.229"
            fill="url(#SVGID_25_)"
            width="1.932"
            height="0.54"
          />
        </g>
        <path
          fill="#9E9D9D"
          stroke="#0C0C0C"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          d="M420.691,89.046
	c-33.172,0-49.041,6.988-49.041,12.577c0,6.404,34.381,28.295,49.041,43.596c14.661-15.3,49.041-37.191,49.041-43.596
	C469.732,96.035,453.864,89.046,420.691,89.046z"
        />
        <path
          fill="none"
          stroke="#222624"
          strokeMiterlimit="10"
          d="M781.174,555.276"
        />
        <path
          id="ribs"
          style={{ fill: props.rib1 }}
          stroke={props.rib1}
          strokeMiterlimit="10"
          d="M284.995,501.953c-1.151-6.06-1.368-7.462-1.368-7.462s-8.261-2.309-28.515,1.647
	c-20.866,4.075-25.394,8.668-25.394,8.668s0.971,3.336,2.207,7.514c0.148-0.748,4.292-4.98,25.158-9.055
	C277.338,499.309,284.495,501.422,284.995,501.953z M286.876,510.232c-0.233-1.052-0.876-3.982-1.063-4.785
	c-0.654-0.632-6.458-2.652-26.712,1.303c-20.866,4.075-26.032,8.734-26.026,9.441c0.413,1.218,1.175,4.371,1.644,5.309
	c0.112-0.891,5.007-5.634,25.873-9.709C280.846,507.836,286.308,509.656,286.876,510.232z M584.445,497.638
	c-20.254-3.955-27.767-1.654-27.767-1.654s-0.263,0.801-1.21,6.867c0.5-0.53,6.896-2.361,27.15,1.594
	c20.866,4.075,25.614,8.461,25.763,9.208c1.62-4.653,2.086-6.852,2.086-6.852S605.311,501.713,584.445,497.638z M581.604,506.668
	c-20.254-3.955-26.137-1.613-26.792-0.981c-0.271,1.063-1.111,4.228-1.121,5.153c0.568-0.576,6.168-3.087,26.422,0.868
	c20.866,4.075,25.761,8.818,25.873,9.709c0.642-0.944,1.306-3.668,1.694-5.155C607.687,515.556,602.47,510.743,581.604,506.668z
	 M364.705,103.879c0.087,12.11,44.956,36.453,48.699,47.679c-2.774-0.184-4.9-0.231-7.536-0.508
	c-5.03-11.151-41.293-31.305-42.565-42.025c-1.422,0.093-2.308,4.529-1.485,6.733c0.195,10.814,25.896,22.286,31.15,33.304
	c-2.128-0.555-4.857-1.221-6.788-1.865c-6.242-8.92-25.018-16.967-25.993-26.599 M479.248,120.597
	c-0.975,9.632-19.751,17.679-25.993,26.599c-1.931,0.644-4.66,1.31-6.788,1.865c5.253-11.018,30.954-22.49,31.149-33.304
	c0.824-2.204-0.063-6.64-1.485-6.733c-1.272,10.72-37.534,30.874-42.565,42.025c-2.635,0.277-4.762,0.324-7.536,0.508
	c3.743-11.226,48.612-35.569,48.699-47.679 M540.502,485.46c-8.3,13.508-47.41,19.7-88.936,21.665v-5.002
	c41.526-1.965,80.729-8.305,89.029-21.813c0,0,0.713-4.514-0.034-6.333c-5.447,8.276-33.304,18.644-88.995,21.565v-5.21
	c54.989-2.858,82.057-12.872,88.953-20.245 M300.431,470.354c6.799,7.269,33.517,17.145,87.814,19.941v5.128
	c-54.988-2.857-82.486-13.081-87.856-21.239c-0.848,3.093,0.305,6.379,0.305,6.379c8.181,13.314,46.657,19.554,87.551,21.415v4.924
	c-40.894-1.86-79.37-8.101-87.551-21.415"
        />
        <g className="front-top-right-parent">
          <path
            className="f-st10"
            d="M1178.7,472.7v-80c0,0,2.3-95.3,69.6-95.3"
            id="XMLID_95_"
          ></path>
          <rect
            className={`f-st11 design-place-outline out ${
              props.leftActive && "active-path"
            } front-top-right`}
            height="90"
            width="90"
            x="313"
            y="200"
          ></rect>
          <rect
            className={`f-st13 design-place-outline in ${
              props.leftActive && "active-path"
            } front-top-right`}
            height="84"
            width="84"
            x="316"
            y="203"
          ></rect>
          {/* ..... */}
          <svg height="100" width="81" x="318" y="194" viewBox="0 0 256 256">
            {props.frontTopRightImage}
          </svg>
          {/* ..... */}
          <text
            font=""
            fill={props.frontTopRightTextColor}
            text=""
            x="358"
            y="260"
            maxLength="30"
            textAnchor="middle"
            strokeWidth={props.frontTopRightTextStroke}
            stroke={props.frontTopRightTextStrokeColor}
            nostroke="true"
            style={{
              direction: "ltr",
            }}
            fontFamily={props.frontTopRightTextFamily}
            fontSize={props.frontTopRightTextSize}
            height="108"
            width="107.2"
          >
            {props.frontTopRightText}
          </text>
        </g>

        <g className="front-top-left-parent">
          <rect
            className={`front-top-left f-st11 design-place-outline out ${
              props.rightActive && "active-path"
            }`}
            height="90"
            width="90"
            x="440"
            y="200"
          ></rect>
          <rect
            className={`f-st13 design-place-outline in ${
              props.rightActive && "active-path"
            } front-top-left`}
            height="84"
            width="84"
            x="443"
            y="203"
          ></rect>
          {/* ..... */}
          <svg height="100" width="81" x="445" y="194" viewBox="0 0 256 256">
            {props.frontTopLeftImage}
          </svg>
          {/* ..... */}
          <text
            font=""
            fill={props.frontTopLeftTextColor}
            text=""
            x="485"
            y="260"
            maxLength="30"
            textAnchor="middle"
            strokeWidth={props.frontTopLeftTextStroke}
            stroke={props.frontTopLeftTextStrokeColor}
            nostroke="true"
            style={{
              direction: "ltr",
            }}
            fontFamily={props.frontTopLeftTextFamily}
            fontSize={props.frontTopLeftTextSize}
            height="108"
            width="107.2"
          >
            {props.frontTopLeftText}
          </text>
        </g>
      </svg>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    body: state.shirtColor.body,
    sleeves: state.shirtColor.sleeves,
    rib1: state.shirtColor.rib1,
    rib2: state.shirtColor.rib2,
    pockets: state.shirtColor.pockets,
    buttons: state.shirtColor.buttons,
    // text, image
    leftActive: state.frontView.chooseLeft,
    rightActive: state.frontView.chooseRight,
    //...
    frontTopLeftText: state.frontView.frontTopLeftText,
    frontTopLeftTextColor: state.frontView.frontTopLeftTextColor,
    frontTopLeftTextSize: state.frontView.frontTopLeftTextSize,
    frontTopLeftTextFamily: state.frontView.frontTopLeftTextFamily,
    frontTopLeftTextStroke: state.frontView.frontTopLeftTextStroke,
    frontTopLeftTextStrokeColor: state.frontView.frontTopLeftTextStrokeColor,
    //...
    frontTopRightText: state.frontView.frontTopRightText,
    frontTopRightTextColor: state.frontView.frontTopRightTextColor,
    frontTopRightTextSize: state.frontView.frontTopRightTextSize,
    frontTopRightTextFamily: state.frontView.frontTopRightTextFamily,
    frontTopRightTextStroke: state.frontView.frontTopRightTextStroke,
    frontTopRightTextStrokeColor: state.frontView.frontTopRightTextStrokeColor,
    //...
    frontTopLeftImage: state.frontView.frontTopLeftImage,
    frontTopRightImage: state.frontView.frontTopRightImage,
  };
};

export default connect(mapStateToProps)(Front);
