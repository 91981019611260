import React from "react";
import { string } from "prop-types";

export default function Artwrk21(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="Shape_1_1_" enable-background="new    ">
        <g id="Shape_1">
          <path
            d="M357.563,637.572c-0.01,0.062-0.031,0.135-0.062,0.198c-1.653,3.202-4.013,5.79-6.955,7.859
			c-0.852,0.603-1.798,1.04-2.765,1.435c-1.934,0.78-3.93,0.748-5.936,0.426c-0.468-0.073-0.925-0.301-1.351-0.52
			c-0.769-0.395-1.289-1.029-1.601-1.84c-0.437-1.144-0.426-2.308-0.239-3.483c0.114-0.748,0.333-1.487,0.478-2.235
			c0.021-0.114-0.042-0.249-0.062-0.374c-0.343,0.135-0.52,0.437-0.717,0.717c-1.029,1.476-2.048,2.963-3.098,4.418
			c-0.489,0.676-0.998,1.331-1.549,1.954c-1.549,1.757-4.117,2.287-6.269,1.31c-0.509-0.229-0.956-0.551-1.299-1.008
			c-0.343-0.457-0.551-0.946-0.561-1.528c-0.01-0.769,0.073-1.518,0.239-2.266c0.416-1.83,0.988-3.607,1.695-5.343
			c0.603-1.476,1.247-2.932,1.913-4.377c0.198-0.447,0.426-0.873,0.457-1.362c0.01-0.27-0.052-0.551-0.135-0.8
			c-0.083-0.291-0.572-0.572-0.832-0.489c-0.395,0.114-0.8,0.218-1.164,0.416c-0.738,0.395-1.383,0.936-1.934,1.559
			c-0.676,0.78-1.362,1.57-1.986,2.401c-2.038,2.724-3.805,5.614-5.375,8.628c-0.655,1.247-1.133,2.526-1.175,3.94
			c0,0.062-0.073,0.114-0.114,0.177c-0.707,0.104-1.019,0.01-1.861-0.572c-1.206-0.832-1.663-1.632-2.079-3.69
			c0.198-1.216,0.748-2.339,1.206-3.503c0.25-0.645,0.541-1.279,0.769-1.923c0.052-0.135-0.094-0.343-0.166-0.572
			c-0.593,0.156-1.112,0.312-1.653,0.416c-0.468,0.083-0.946,0.104-1.403,0.146c-0.114,0.249-0.229,0.426-0.281,0.613
			c-0.478,1.767-1.403,3.316-2.401,4.813c-0.416,0.634-0.925,1.216-1.424,1.778c-1.601,1.788-3.639,2.745-5.988,3.015
			c-1.372,0.156-2.755,0.135-4.127-0.208c-1.934-0.499-3.129-1.726-3.67-3.597c-0.405-1.403-0.499-2.848-0.291-4.314
			c0.322-2.225,1.008-4.304,2.173-6.227c0.759-1.258,1.695-2.36,2.848-3.275c1.507-1.185,3.233-1.882,5.125-2.121
			c2.225-0.281,4.418-0.052,6.56,0.676c0.333,0.114,0.655,0.25,0.852,0.551c0.073,0.104,0.125,0.27,0.094,0.374
			c-0.031,0.104-0.187,0.229-0.291,0.229c-0.198,0-0.405-0.073-0.603-0.125c-1.019-0.249-2.058-0.281-3.098-0.208
			c-1.362,0.094-2.547,0.593-3.576,1.487c-0.894,0.78-1.663,1.663-2.318,2.651c-1.674,2.516-2.744,5.281-3.285,8.244
			c-0.198,1.092-0.239,2.204-0.125,3.316c0.104,1.04,0.967,1.736,2.027,1.643c0.852-0.073,1.611-0.364,2.246-0.956
			c0.717-0.665,1.279-1.455,1.757-2.297c0.873-1.507,1.466-3.129,1.861-4.824c0.146-0.603,0.229-1.216,0.353-1.882
			c-0.229-0.26-0.447-0.52-0.665-0.78c-0.53-0.572-0.593-1.247-0.333-1.934c0.291-0.78,0.863-1.227,1.705-1.341
			c1.757-0.239,3.14,0.291,4.044,1.871c0.166,0.302,0.322,0.603,0.509,0.946c0.998,0.457,2.006,0.312,2.973-0.125
			c0.509-0.229,0.956-0.561,1.611-0.53c1.237-2.266,1.611-4.803,2.1-7.35c1.008-0.748,2.09-1.029,3.285-0.52
			c0.239,0.104,0.437,0.343,0.603,0.551c0.333,0.457,0.489,0.988,0.541,1.549c0.083,1.04-0.01,2.079-0.239,3.098
			c-0.094,0.405-0.177,0.811-0.239,1.216c-0.021,0.114,0.083,0.25,0.125,0.374c0.301-0.062,0.447-0.301,0.624-0.52
			c0.603-0.759,1.185-1.539,1.819-2.266c0.998-1.154,2.142-2.162,3.441-2.984c1.268-0.801,2.63-1.196,4.138-1.133
			c0.717,0.031,1.362,0.239,1.965,0.582c1.175,0.676,1.809,2.058,1.518,3.368c-0.146,0.676-0.374,1.341-0.624,1.975
			c-0.509,1.289-1.06,2.557-1.611,3.826c-0.863,1.965-1.746,3.919-2.609,5.884c-0.281,0.634-0.551,1.279-0.582,1.986
			c-0.01,0.198,0.052,0.405,0.104,0.603c0.052,0.218,0.447,0.447,0.655,0.416c0.707-0.125,1.31-0.468,1.809-0.956
			c0.447-0.437,0.863-0.904,1.237-1.403c1.164-1.549,2.308-3.119,3.451-4.678c0.364-0.499,0.717-1.019,1.071-1.528
			c0.322-0.478,0.728-0.832,1.341-0.832c0.229-0.437,0.426-0.8,0.613-1.164c0.53-1.05,1.123-2.069,1.83-3.015
			c1.081-1.466,2.391-2.693,3.992-3.576c1.726-0.967,3.576-1.455,5.572-1.403c0.842,0.021,1.643,0.239,2.412,0.561
			c0.769,0.312,1.206,0.904,1.31,1.736c0.083,0.624,0.01,1.237-0.187,1.84c-0.655,2.079-1.705,3.94-3.243,5.499
			c-2.079,2.11-4.481,3.69-7.371,4.481c-0.198,0.052-0.395,0.114-0.582,0.198c-0.27,0.114-0.478,0.333-0.499,0.613
			c-0.052,0.624-0.104,1.247-0.062,1.861c0.083,1.123,0.956,1.902,2.058,1.944c0.769,0.031,1.507-0.094,2.225-0.405
			c1.351-0.582,2.537-1.414,3.628-2.391c1.507-1.351,2.776-2.9,3.971-4.522c0.405-0.551,0.832-1.102,1.268-1.632
			c0.062-0.073,0.281-0.094,0.353-0.042c0.094,0.073,0.114,0.239,0.135,0.364C357.605,637.437,357.584,637.51,357.563,637.572
			L357.563,637.572z M344.652,640.254c0.083,0.083,0.249,0.146,0.364,0.114c1.102-0.218,2.09-0.717,2.994-1.362
			c1.986-1.445,3.597-3.223,4.73-5.406c0.218-0.426,0.426-0.873,0.572-1.331c0.156-0.52,0.229-1.071,0.291-1.362
			c-0.021-0.322-0.021-0.395-0.021-0.468c-0.01-0.541-0.52-0.894-1.008-0.697c-0.322,0.125-0.634,0.27-0.925,0.457
			c-1.279,0.842-2.412,1.84-3.368,3.046c-1.559,1.965-2.817,4.096-3.67,6.456c-0.021,0.062-0.073,0.135-0.062,0.198
			C344.558,640.015,344.568,640.171,344.652,640.254L344.652,640.254z M403.513,561.558c-3.763,6.019-6.56,12.496-9.086,19.097
			c-0.697,1.809-1.268,3.659-1.684,5.562c-0.135,0.613-0.218,1.227-0.114,1.85c0.031,0.208,0.353,0.509,0.582,0.509
			c0.426,0.01,0.842-0.01,1.195-0.26c0.447-0.312,0.904-0.645,1.299-1.029c1.154-1.123,2.017-2.464,2.755-3.878
			c0.322-0.613,0.645-1.227,0.988-1.819c0.094-0.166,0.291-0.281,0.457-0.405c0.042-0.031,0.146-0.021,0.198,0.01
			c0.104,0.073,0.229,0.156,0.281,0.27s0.052,0.281,0.01,0.405c-0.894,2.36-2.162,4.491-3.878,6.341
			c-0.374,0.405-0.79,0.78-1.227,1.123c-1.175,0.946-2.537,1.393-4.044,1.414c-1.528,0.021-2.651-0.697-3.399-1.996
			c-0.385-0.665-0.634-1.393-0.686-2.162c-0.052-0.894-0.062-1.798-0.083-2.703c-0.01-0.416,0-0.821,0-1.247
			c-0.146,0.052-0.312,0.052-0.374,0.135c-0.301,0.374-0.551,0.79-0.852,1.175c-0.769,0.988-1.507,1.986-2.329,2.921
			c-1.102,1.258-2.391,2.308-3.898,3.067c-1.268,0.634-2.589,0.946-4.013,0.769c-1.643-0.198-2.817-1.06-3.628-2.474
			c-0.457-0.8-0.634-1.674-0.686-2.526c-0.26-0.218-0.447-0.083-0.551,0.052c-0.405,0.561-0.759,1.164-1.175,1.715
			c-0.416,0.551-0.863,1.092-1.351,1.57c-0.749,0.759-1.684,1.195-2.744,1.372c-1.071,0.177-2.069-0.083-2.984-0.624
			c-0.551-0.333-0.915-0.842-1.112-1.455c-0.239-0.738-0.353-1.476-0.146-2.245c0.281-1.071,0.551-2.142,0.821-3.223
			c0.062-0.26,0.187-0.53,0-0.842c-0.146,0.104-0.343,0.187-0.437,0.322c-0.582,0.863-1.133,1.746-1.695,2.609
			c-0.832,1.289-1.809,2.453-2.848,3.566c-0.281,0.302-0.593,0.582-0.894,0.863c-1.695,1.518-3.607,1.601-5.614,0.759
			c-0.956-0.405-1.518-1.175-1.674-2.214c-0.187-1.185-0.052-2.349,0.25-3.493c0.146-0.53,0.353-1.05,0.489-1.591
			c0.083-0.312,0.114-0.634,0.156-0.956c0.374-0.156,0.374-0.499,0.405-0.832c0.364-0.166,0.385-0.509,0.416-0.842
			c0.353-0.104,0.457-0.447,0.624-0.717c0.821-1.289,1.757-2.495,2.817-3.597c1.601-1.674,3.441-2.984,5.645-3.774
			c1.58-0.572,3.223-0.8,4.876-0.925c1.04-0.083,2.069,0.156,3.077,0.395c0.385,0.094,0.749,0.353,1.081,0.593
			c0.229,0.156,0.333,0.426,0.281,0.728c-0.26,0.364-0.655,0.478-1.06,0.582c-1.071,0.291-2.142,0.572-3.202,0.884
			c-1.757,0.52-3.275,1.476-4.626,2.693c-2.297,2.069-4.013,4.574-5.219,7.423c-0.187,0.447-0.343,0.904-0.468,1.372
			c-0.156,0.541-0.187,1.092-0.114,1.643c0.062,0.395,0.52,0.728,0.915,0.603c0.26-0.083,0.53-0.187,0.738-0.343
			c0.676-0.499,1.206-1.144,1.715-1.798c1.196-1.528,2.287-3.129,3.275-4.803c0.489-0.832,0.956-1.695,1.497-2.495
			c0.541-0.801,1.154-1.559,1.767-2.297c0.499-0.603,1.185-0.946,1.944-1.133c0.541-0.125,1.081-0.229,1.632-0.302
			c0.27-0.042,0.551,0.01,0.821,0.042c0.426,0.042,0.8,0.447,0.738,0.842c-0.083,0.541-0.166,1.092-0.322,1.622
			c-0.229,0.728-0.52,1.435-0.821,2.131c-0.8,1.913-1.632,3.826-2.422,5.739c-0.291,0.707-0.53,1.424-0.738,2.152
			c-0.073,0.25-0.042,0.551-0.01,0.821c0.031,0.291,0.416,0.624,0.717,0.655c0.353,0.031,0.697-0.01,0.998-0.208
			c0.281-0.198,0.561-0.405,0.79-0.665c0.457-0.52,0.915-1.04,1.31-1.611c1.341-1.934,2.599-3.909,3.639-6.04
			c2.173-4.501,5.77-7.35,10.562-8.701c1.414-0.395,2.869-0.447,4.325-0.25c0.707,0.094,1.32,0.405,1.85,0.873
			c0.27,0.239,0.447,0.52,0.551,0.863c0.052,0.177-0.073,0.457-0.249,0.499c-0.198,0.042-0.405,0.104-0.603,0.135
			c-2.339,0.25-4.366,1.247-6.321,2.485c-2.246,1.424-3.784,3.431-4.772,5.863c-0.208,0.509-0.374,1.04-0.509,1.58
			c-0.083,0.333-0.083,0.686-0.062,1.029c0.073,0.863,0.769,1.539,1.622,1.622c0.634,0.062,1.258,0.052,1.83-0.239
			c0.551-0.281,1.112-0.561,1.622-0.915c1.04-0.707,1.913-1.601,2.724-2.547c1.809-2.121,3.389-4.377,4.585-6.903
			c0.977-2.069,1.944-4.137,2.942-6.185c0.665-1.372,1.341-2.744,2.09-4.065c0.509-0.894,1.144-1.726,1.757-2.568
			c0.364-0.509,0.832-0.925,1.372-1.258c0.53-0.333,1.092-0.582,1.736-0.655c1.31-0.135,2.62-0.135,3.909,0.062
			C403.502,561.329,403.564,561.485,403.513,561.558L403.513,561.558z M349.777,581.851c0,0.021-0.01,0.042-0.01,0.062
			c-0.021,0.01-0.042,0.021-0.062,0.021L349.777,581.851L349.777,581.851z M350.182,581.009c0,0.021,0,0.042-0.01,0.062
			c-0.021,0.01-0.031,0.01-0.052,0.021L350.182,581.009L350.182,581.009z M350.608,580.177c0,0.021,0,0.031-0.01,0.052
			c-0.021,0-0.042,0.01-0.052,0.021L350.608,580.177L350.608,580.177z M342.604,522.948c-0.083,0.187-0.187,0.374-0.301,0.541
			c-1.591,2.433-3.191,4.865-4.99,7.163c-0.343,0.437-0.697,0.863-1.06,1.279c-0.416,0.478-0.925,0.832-1.507,1.081
			c-1.393,0.572-2.807,0.738-4.252,0.198c-0.322-0.125-0.634-0.281-0.915-0.478c-0.998-0.686-1.476-1.653-1.31-2.859
			c0.239-1.788,0.707-3.524,1.31-5.229c0.728-2.017,1.528-4.013,2.474-5.946c0.187-0.374,0.374-0.748,0.509-1.133
			c0.083-0.25,0.083-0.541,0.073-0.821c-0.021-0.561-0.676-1.05-1.175-0.863c-0.447,0.166-0.904,0.353-1.31,0.613
			c-0.717,0.437-1.341,0.998-1.861,1.643c-0.873,1.071-1.767,2.142-2.568,3.264c-1.445,2.038-2.744,4.158-3.94,6.352
			c-1.341,2.453-1.424,2.724-1.809,5.291c-0.166,0.021-0.374,0.094-0.541,0.052c-0.811-0.229-1.518-0.665-2.11-1.268
			c-0.863-0.884-1.331-1.923-1.299-3.181c0.01-0.405,0.021-0.8,0.031-1.196c-0.125,0.01-0.27-0.01-0.333,0.052
			c-0.603,0.572-1.175,1.175-1.778,1.747c-1.258,1.195-2.651,2.214-4.221,2.994c-2.069,1.029-4.231,1.227-6.477,0.956
			c-0.135-0.021-0.27-0.042-0.405-0.073c-2.09-0.489-3.191-1.799-3.337-3.95c-0.021-0.343,0-0.696-0.01-1.04
			c-0.01-0.27-0.031-0.541-0.062-0.894c-0.249,0.125-0.457,0.166-0.582,0.281c-0.697,0.676-1.362,1.383-2.058,2.048
			c-1.06,1.008-2.246,1.83-3.545,2.516c-2.1,1.102-4.325,1.403-6.643,1.112c-0.478-0.062-0.946-0.208-1.403-0.374
			c-1.435-0.541-2.204-1.622-2.381-3.119c-0.125-1.04-0.104-2.09,0.125-3.108c0.634-2.859,1.695-5.562,3.306-8.026
			c0.956-1.466,2.121-2.734,3.576-3.722c2.006-1.372,4.2-2.079,6.653-1.965c0.842,0.042,1.643,0.25,2.401,0.603
			c0.593,0.281,0.977,0.728,1.092,1.414c0.166,0.925,0.031,1.798-0.281,2.651c-0.728,1.975-1.798,3.742-3.316,5.208
			c-2.027,1.944-4.335,3.441-7.069,4.2c-0.135,0.042-0.27,0.052-0.405,0.094c-0.125,0.042-0.249,0.114-0.468,0.218
			c-0.073,0.177-0.208,0.426-0.26,0.676c-0.125,0.613-0.125,1.237-0.021,1.861c0.177,1.04,0.967,1.746,1.996,1.788
			c0.697,0.031,1.372-0.062,2.038-0.333c0.977-0.385,1.871-0.904,2.703-1.528c1.507-1.133,2.786-2.485,3.961-3.95
			c0.998-1.247,1.923-2.526,2.485-4.054c0.509-1.362,1.268-2.62,2.131-3.794c1.206-1.643,2.672-2.984,4.512-3.909
			c1.643-0.821,3.368-1.237,5.208-1.154c0.842,0.031,1.643,0.249,2.412,0.572c0.686,0.291,1.081,0.832,1.206,1.58
			c0.104,0.624,0.042,1.237-0.135,1.85c-0.645,2.162-1.736,4.065-3.327,5.676c-2.038,2.048-4.397,3.566-7.184,4.397
			c-0.26,0.083-0.551,0.125-0.78,0.26c-0.166,0.094-0.322,0.281-0.374,0.457c-0.239,0.738-0.229,1.518-0.062,2.256
			c0.198,0.873,0.904,1.611,1.892,1.663c0.697,0.042,1.372-0.031,2.038-0.281c0.842-0.333,1.632-0.748,2.381-1.268
			c1.497-1.029,2.765-2.308,3.919-3.69c0.665-0.8,1.268-1.643,1.902-2.474c0.125-0.166,0.218-0.364,0.364-0.509
			c1.622-1.653,2.225-3.794,2.817-5.926c0.374-1.331,0.613-2.693,0.894-4.002c0.426-0.624,1.092-0.634,1.684-0.8
			c0.405-0.114,0.821-0.042,1.227,0.094c0.499,0.156,0.852,0.468,1.081,0.915c0.457,0.894,0.593,1.85,0.426,2.828
			c-0.166,0.956-0.405,1.892-0.593,2.838c-0.042,0.198-0.031,0.395-0.042,0.634c0.146-0.042,0.281-0.052,0.333-0.114
			c0.385-0.489,0.759-0.988,1.133-1.487c0.842-1.102,1.767-2.142,2.828-3.036c0.686-0.582,1.414-1.123,2.173-1.591
			c1.279-0.78,2.693-1.05,4.19-0.894c0.634,0.062,1.216,0.281,1.736,0.645c0.977,0.686,1.487,1.643,1.362,2.848
			c-0.052,0.551-0.166,1.102-0.353,1.611c-0.395,1.102-0.832,2.204-1.31,3.275c-0.8,1.84-1.643,3.659-2.453,5.499
			c-0.395,0.884-0.748,1.788-1.102,2.693c-0.125,0.322-0.208,0.665-0.26,0.998c-0.052,0.353,0.052,0.686,0.374,0.904
			c0.249,0.166,0.52,0.135,0.79,0.042c0.676-0.229,1.237-0.624,1.705-1.154c0.509-0.561,1.008-1.143,1.466-1.757
			c1.154-1.559,2.277-3.14,3.42-4.709c0.322-0.447,0.645-0.894,1.008-1.32c0.114-0.146,0.353-0.187,0.52-0.27
			C342.676,522.408,342.718,522.678,342.604,522.948L342.604,522.948z M289.055,525.973c0.988-0.104,1.85-0.53,2.661-1.06
			c2.308-1.497,4.075-3.493,5.291-5.957c0.239-0.489,0.447-1.008,0.603-1.539c0.114-0.395,0.135-0.811,0.166-1.071
			c-0.021-0.218-0.031-0.291-0.031-0.364c-0.021-0.416-0.478-0.759-0.884-0.634c-0.322,0.094-0.655,0.218-0.946,0.395
			c-1.539,0.956-2.828,2.204-3.919,3.649c-1.341,1.767-2.422,3.69-3.202,5.77c-0.052,0.125-0.062,0.27-0.104,0.395
			C288.66,525.703,288.889,525.984,289.055,525.973L289.055,525.973z M305.969,525.953c1.393-0.27,2.568-0.988,3.649-1.861
			c1.414-1.133,2.609-2.464,3.545-4.013c0.395-0.645,0.717-1.341,0.998-2.038c0.198-0.509,0.27-1.06,0.343-1.611
			c0.052-0.343,0-0.686-0.27-0.956c-0.218-0.208-0.769-0.198-1.164,0.031c-0.177,0.104-0.364,0.198-0.53,0.322
			c-1.268,0.852-2.401,1.861-3.358,3.056c-1.123,1.403-2.1,2.911-2.859,4.543c-0.343,0.748-0.728,1.476-0.894,2.36
			C305.615,525.849,305.802,525.984,305.969,525.953L305.969,525.953z M316.271,527.034c-0.218,0.052-0.218,0.198-0.187,0.364
			c0.083-0.062,0.177-0.135,0.249-0.208C316.344,527.169,316.271,527.034,316.271,527.034L316.271,527.034z M287.329,644.277
			c-1.196,1.268-2.568,2.318-4.065,3.212c-3.358,1.996-6.955,3.431-10.812,4.096c-1.289,0.218-2.62,0.229-3.93,0.301
			c-0.759,0.042-1.518,0.01-2.391,0.01c-0.53,0.551-0.956,0.998-1.393,1.445c-0.541,0.541-1.144,0.998-1.85,1.31
			c-0.198,0.083-0.281,0.073-0.78-0.177c0.166-1.019,0.998-1.798,1.133-2.859c-0.198-0.104-0.364-0.229-0.551-0.291
			c-0.79-0.26-1.591-0.478-2.37-0.749c-1.445-0.499-2.786-1.227-4.054-2.079c-1.944-1.31-2.838-3.181-2.963-5.437
			c-0.125-2.381,0.634-4.501,2.194-6.31c1.351-1.57,3.036-2.609,5.021-3.171c0.603-0.166,1.206-0.322,1.83-0.395
			c2.256-0.239,4.252,0.343,5.853,2.017c0.114,0.114,0.094,0.353,0.146,0.582c-0.541,0.031-1.019,0.062-1.507,0.083
			c-0.915,0.031-1.767,0.312-2.609,0.655c-3.909,1.611-5.011,5.229-4.709,8.202c0.146,1.445,0.894,2.609,1.996,3.524
			c0.749,0.624,1.591,1.092,2.537,1.227c0.52-0.177,0.593-0.634,0.749-0.988c1.632-3.67,3.191-7.36,4.533-11.155
			c1.019-2.869,2.006-5.749,2.744-8.701c0.27-1.071,0.509-2.152,0.759-3.233c0.094-0.405,0.208-0.801,0.322-1.227
			c1.653-1.164,3.42-2.069,5.343-2.693c0.312-0.104,0.603,0,0.904,0.312c-0.426,1.58-1.019,3.129-1.549,4.699
			c-1.414,4.127-2.973,8.202-4.678,12.205c-1.518,3.566-3.181,7.069-5.208,10.385c-0.146,0.229-0.229,0.499-0.343,0.728
			c0.249,0.26,0.52,0.26,0.78,0.218c2.277-0.343,4.439-1.05,6.435-2.214c5.603-3.264,9.283-7.984,10.843-14.315
			c0.707-2.88,0.717-5.759-0.021-8.628c-0.509-1.975-1.466-3.722-2.932-5.177c-1.081-1.081-2.36-1.632-3.909-1.643
			c-1.341-0.01-2.609,0.239-3.857,0.686c-1.507,0.551-2.9,1.299-4.2,2.235c-0.676,0.489-1.362,0.956-2.152,1.247
			c-1.258,0.457-2.568,0.582-3.888,0.405c-1.237-0.166-2.443-0.478-3.566-1.04c-0.166-0.083-0.281-0.26-0.478-0.447
			c0.229-0.218,0.405-0.426,0.624-0.582c3.41-2.381,7.184-3.846,11.29-4.429c0.478-0.073,0.967-0.094,1.445-0.083
			c1.726,0,3.462-0.01,5.187,0.052c1.528,0.052,3.025,0.312,4.491,0.748c1.071,0.322,2.069,0.801,2.984,1.424
			c1.029,0.707,1.965,1.528,2.828,2.443c0.863,0.925,1.466,2.006,1.954,3.171c0.655,1.549,1.019,3.171,1.154,4.834
			C293.151,634.63,291.477,639.88,287.329,644.277L287.329,644.277z M418.67,522.751c-0.052,0.125-0.094,0.27-0.166,0.374
			c-1.788,2.807-3.649,5.551-5.697,8.171c-0.956,1.216-2.152,1.902-3.649,2.162c-1.071,0.187-2.069-0.031-3.036-0.426
			c-0.457-0.187-0.842-0.478-1.154-0.863c-0.468-0.551-0.759-1.164-0.707-1.892c0.031-0.478,0.073-0.967,0.166-1.445
			c0.281-1.497,0.707-2.963,1.227-4.397c0.697-1.954,1.497-3.867,2.391-5.749c0.239-0.499,0.457-1.008,0.603-1.539
			c0.062-0.239-0.042-0.551-0.146-0.79c-0.156-0.374-0.686-0.624-1.029-0.499c-0.385,0.146-0.78,0.281-1.133,0.489
			c-0.842,0.499-1.57,1.144-2.173,1.913c-0.863,1.081-1.736,2.162-2.537,3.285c-1.403,1.975-2.661,4.054-3.826,6.175
			c-1.372,2.505-1.383,2.568-1.871,5.291c-0.166,0.021-0.374,0.114-0.541,0.062c-0.593-0.187-1.154-0.468-1.643-0.873
			c-1.133-0.946-1.757-2.121-1.705-3.618c0.01-0.27,0.052-0.551,0.073-0.79c-0.229-0.218-0.395-0.094-0.509,0.042
			c-0.541,0.634-1.029,1.299-1.591,1.913c-0.738,0.821-1.507,1.622-2.297,2.401c-0.343,0.343-0.728,0.645-1.144,0.894
			c-1.455,0.894-2.89,0.665-4.19-0.489c-0.156-0.135-0.281-0.301-0.416-0.457c-0.468-0.551-0.707-1.175-0.707-1.902
			c0.01-1.175,0.073-2.349,0.291-3.514c0.042-0.198,0.01-0.395,0.01-0.603c-0.457,0.146-0.603,0.561-0.811,0.894
			c-0.894,1.403-1.861,2.755-2.921,4.044c-0.717,0.873-1.591,1.528-2.63,1.975c-1.123,0.478-2.256,0.613-3.441,0.281
			c-0.946-0.26-1.58-0.852-1.913-1.746c-0.27-0.717-0.416-1.487-0.353-2.245c0.083-1.029,0.177-2.069,0.457-3.077
			c0.561-2.006,1.258-3.961,2.173-5.822c0.364-0.749,0.717-1.497,1.112-2.235c0.665-1.247,1.029-2.557,1.071-3.971
			c0.01-0.27,0.031-0.551,0.062-0.821c0.042-0.312,0.437-0.613,0.759-0.541c0.333,0.073,0.686,0.146,0.977,0.312
			c1.04,0.582,1.882,1.414,2.578,2.37c0.426,0.582,0.561,1.237,0.395,1.965c-0.281,1.227-0.749,2.37-1.299,3.493
			c-0.759,1.549-1.518,3.108-2.266,4.668c-0.489,0.998-0.884,2.038-1.133,3.119c-0.062,0.26-0.042,0.551-0.01,0.821
			c0.052,0.457,0.603,0.873,1.05,0.811c0.27-0.042,0.561-0.094,0.79-0.229c0.353-0.208,0.676-0.478,0.977-0.759
			c0.915-0.852,1.705-1.809,2.433-2.838c1.237-1.757,2.339-3.597,3.379-5.479c1.32-2.36,2.526-4.772,3.67-7.225
			c0.114-0.25,0.229-0.52,0.405-0.707c0.114-0.125,0.374-0.229,0.551-0.198c1.611,0.333,2.62,1.403,2.817,3.16
			c0.146,1.331-0.094,2.63-0.603,3.857c-0.312,0.769-0.655,1.528-1.008,2.277c-0.728,1.57-1.487,3.129-2.183,4.709
			c-0.395,0.884-0.686,1.809-1.019,2.713c-0.042,0.125-0.083,0.27-0.073,0.405c0.01,0.198,0.021,0.437,0.125,0.593
			c0.104,0.146,0.322,0.218,0.53,0.353c0.312-0.156,0.634-0.281,0.925-0.468c0.988-0.645,1.85-1.445,2.641-2.329
			c1.247-1.403,2.381-2.89,3.275-4.543c0.374-0.686,0.821-1.268,1.435-1.622c1.144-2.422,1.934-4.844,2.339-7.391
			c0.062-0.405,0.166-0.821,0.301-1.206c0.062-0.177,0.25-0.374,0.416-0.437c0.52-0.187,1.05-0.395,1.591-0.426
			c0.936-0.052,1.726,0.333,2.1,1.362c0.27,0.717,0.416,1.466,0.281,2.246c-0.156,0.884-0.322,1.767-0.478,2.651
			c-0.062,0.322-0.094,0.645-0.146,0.977c0.114-0.01,0.26,0.01,0.312-0.052c0.364-0.416,0.697-0.863,1.029-1.299
			c1.019-1.32,2.11-2.578,3.431-3.597c0.603-0.468,1.227-0.904,1.882-1.289c1.237-0.717,2.589-0.925,4.013-0.78
			c0.634,0.062,1.216,0.291,1.726,0.655c0.904,0.645,1.414,1.518,1.362,2.651c-0.021,0.478-0.094,0.977-0.249,1.424
			c-0.385,1.112-0.79,2.214-1.258,3.295c-0.936,2.162-1.913,4.304-2.869,6.456c-0.312,0.697-0.593,1.403-0.863,2.121
			c-0.094,0.26-0.146,0.53-0.198,0.8c-0.094,0.478,0.062,0.946,0.364,1.112c0.343,0.187,0.665,0.073,0.988-0.062
			c0.593-0.239,1.092-0.613,1.507-1.081c0.457-0.52,0.915-1.05,1.32-1.601c1.268-1.726,2.516-3.472,3.784-5.208
			c0.281-0.385,0.603-0.748,0.936-1.102c0.073-0.083,0.25-0.083,0.385-0.073C418.607,522.22,418.732,522.605,418.67,522.751
			L418.67,522.751z M400.789,520.245c0.01-0.083,0.021-0.166,0.031-0.25C400.737,520.069,400.654,520.131,400.789,520.245
			L400.789,520.245z M228.998,511.263c-0.083,1.57-0.509,2.464-2.339,2.973c-0.603,0.166-1.216,0.27-1.84,0.322
			c-1.102,0.083-2.204,0.177-3.181,0.26c-0.457,0.208-0.655,0.489-0.8,0.8c-2.048,4.314-3.961,8.691-5.676,13.151
			c-0.177,0.447-0.353,0.904-0.457,1.383c-0.094,0.395-0.094,0.821-0.114,1.227c-0.01,0.302,0.353,0.655,0.665,0.686
			c0.769,0.083,1.518-0.031,2.235-0.301c0.852-0.322,1.611-0.8,2.287-1.403c0.894-0.78,1.726-1.601,2.422-2.568
			c0.811-1.123,1.601-2.266,2.36-3.42c0.769-1.154,1.487-2.339,2.246-3.493c0.187-0.281,0.426-0.541,0.676-0.769
			c0.052-0.052,0.239,0.01,0.353,0.052c0.052,0.021,0.114,0.104,0.125,0.156c0.021,0.135,0.042,0.291-0.01,0.405
			c-0.218,0.509-0.416,1.04-0.707,1.497c-1.081,1.695-2.183,3.368-3.316,5.021c-0.509,0.738-1.081,1.445-1.695,2.1
			c-0.665,0.707-1.372,1.362-2.11,1.996c-2.017,1.736-4.377,2.277-6.976,1.913c-0.405-0.062-0.8-0.198-1.196-0.333
			c-0.478-0.156-0.832-0.489-1.102-0.894c-0.645-0.967-0.904-2.006-0.645-3.16c0.229-1.019,0.478-2.017,0.863-2.984
			c0.457-1.154,0.915-2.318,1.424-3.451c1.06-2.401,2.152-4.792,3.233-7.194c0.114-0.26,0.156-0.52-0.052-0.873
			c-1.362-0.426-2.796-0.925-4.075-1.653c-0.489,0.301-0.53,0.748-0.613,1.133c-0.353,1.695-0.832,3.358-1.362,5
			c-1.539,4.772-3.618,9.283-6.31,13.504c-0.821,1.289-1.705,2.516-2.724,3.659c-0.603,0.676-1.258,1.289-2.006,1.798
			c-0.468,0.322-0.988,0.478-1.549,0.52c-1.559,0.094-2.848-0.468-3.919-1.559c-0.645-0.645-1.102-1.414-1.445-2.266
			c-0.832-2.09-0.821-4.19-0.146-6.3c0.187-0.593,0.405-1.195,0.717-1.726c0.894-1.507,2.183-2.485,3.961-2.703
			c0.218-0.031,0.27-0.01,0.707,0.291c-0.021,0.572-0.364,1.029-0.561,1.539c-0.977,2.485-1.424,5.052-1.31,7.724
			c0.031,0.707,0.166,1.372,0.572,1.965c0.281,0.405,0.904,0.572,1.279,0.281c0.322-0.26,0.665-0.509,0.904-0.842
			c0.52-0.728,1.019-1.487,1.466-2.266c1.216-2.1,2.173-4.325,3.119-6.56c1.861-4.408,3.431-8.909,4.959-13.442
			c0.686-2.038,1.05-4.158,1.372-6.279c0.146-0.956,0.187-1.934,0.031-2.9c-0.052-0.312-0.489-0.551-0.811-0.478
			c-0.343,0.083-0.645,0.249-0.894,0.489c-1.206,1.175-2.173,2.526-2.713,4.127c-0.374,1.112-0.645,2.256-0.988,3.389
			c-0.156,0.52-0.198,1.092-0.561,1.539c-0.385,0-0.665-0.208-0.894-0.457c-1.154-1.258-1.601-2.765-1.299-4.418
			c0.551-3.067,2.256-5.312,5.115-6.622c1.299-0.593,2.672-0.686,4.065-0.447c0.499,0.094,0.894,0.374,1.185,0.769
			c0.416,0.572,0.665,1.206,0.79,1.902c0.26,1.58,0.374,3.16,0.177,4.761c-0.125,0.967-0.187,1.934-0.27,2.9
			c-0.021,0.301,0.125,0.53,0.405,0.738c1.622,0.457,3.306,0.759,5.084,0.468c1.092-1.507,1.528-3.243,1.83-4.855
			c0.27-0.395,0.561-0.405,0.832-0.416c0.79-0.042,1.507,0.208,2.173,0.593c0.561,0.333,0.915,0.821,1.019,1.487
			c0.104,0.697,0.062,1.383-0.198,2.038c-0.104,0.26-0.218,0.499-0.104,0.79c0.114,0.052,0.239,0.166,0.353,0.156
			c1.112-0.052,2.173-0.374,3.223-0.738c0.593-0.198,1.175-0.426,1.767-0.613C228.624,510.193,229.03,510.556,228.998,511.263
			L228.998,511.263z M226.607,571.486c-1.008,0.229-2.038,0.416-3.077,0.426c-0.686,0.01-1.383-0.083-2.142,0.218
			c-0.166,0.302-0.385,0.645-0.561,1.008c-1.934,4.065-3.722,8.192-5.354,12.381c-0.27,0.707-0.509,1.435-0.728,2.162
			c-0.073,0.26-0.062,0.551-0.052,0.821c0.01,0.405,0.395,0.821,0.79,0.821c0.416,0,0.842,0.01,1.237-0.083
			c1.164-0.27,2.204-0.811,3.119-1.591c0.956-0.811,1.83-1.694,2.568-2.703c0.769-1.06,1.518-2.152,2.245-3.244
			c0.686-1.04,1.331-2.11,2.006-3.15c0.218-0.353,0.478-0.676,0.717-1.019c0.198-0.26,0.468-0.239,0.852,0.062
			c-0.125,0.697-0.468,1.299-0.832,1.882c-1.081,1.694-2.173,3.379-3.337,5.021c-1.081,1.528-2.329,2.932-3.815,4.086
			c-1.102,0.863-2.297,1.539-3.691,1.83c-1.31,0.27-2.599,0.26-3.909-0.094c-1.299-0.353-1.944-1.237-2.245-2.464
			c-0.135-0.551-0.094-1.102-0.031-1.653c0.042-0.405,0.187-0.811-0.021-1.206c-0.125,0.042-0.291,0.042-0.374,0.125
			c-0.281,0.301-0.53,0.634-0.811,0.946c-0.925,1.029-1.85,2.058-2.796,3.067c-0.333,0.353-0.717,0.655-1.102,0.946
			c-1.362,1.019-3.431,0.925-4.699-0.509c-0.624-0.697-0.988-1.476-0.946-2.443c0.042-0.904,0.083-1.798,0.281-2.682
			c0.073-0.333,0.156-0.676,0.01-1.019c-0.301,0.042-0.447,0.27-0.593,0.499c-0.499,0.749-0.977,1.518-1.497,2.246
			c-0.52,0.728-1.081,1.435-1.653,2.142c-0.613,0.769-1.393,1.341-2.277,1.778c-0.894,0.437-1.819,0.634-2.817,0.572
			c-1.736-0.104-2.828-1.102-3.067-2.817c-0.177-1.247-0.104-2.485,0.177-3.711c0.478-2.1,1.227-4.106,2.121-6.061
			c0.374-0.821,0.749-1.643,1.185-2.422c0.759-1.362,1.133-2.807,1.206-4.356c0.062-1.403,0.645-1.663,1.861-0.925
			c1.019,0.624,1.861,1.424,2.557,2.391c0.385,0.541,0.509,1.143,0.374,1.778c-0.146,0.676-0.333,1.352-0.582,1.986
			c-0.333,0.832-0.738,1.643-1.123,2.453c-0.686,1.435-1.372,2.869-2.069,4.304c-0.457,0.936-0.769,1.923-0.977,2.952
			c-0.073,0.353,0.042,0.686,0.239,0.977c0.239,0.364,0.717,0.53,1.081,0.405c0.53-0.187,0.998-0.478,1.403-0.873
			c0.852-0.811,1.663-1.674,2.318-2.651c0.884-1.32,1.778-2.641,2.589-4.013c1.643-2.734,3.088-5.572,4.439-8.452
			c0.198-0.437,0.426-0.863,0.593-1.196c0.343-0.229,0.613-0.26,0.894-0.187c1.372,0.374,2.308,1.393,2.464,2.755
			c0.135,1.175,0.125,2.36-0.301,3.483c-0.333,0.904-0.697,1.809-1.102,2.693c-0.832,1.83-1.695,3.639-2.537,5.458
			c-0.405,0.884-0.728,1.798-0.884,2.755c-0.031,0.208,0.01,0.405,0.177,0.572c0.187,0.177,0.53,0.26,0.769,0.146
			c0.249-0.125,0.499-0.239,0.728-0.395c0.915-0.634,1.747-1.362,2.485-2.194c1.206-1.351,2.297-2.776,3.202-4.345
			c0.343-0.603,0.655-1.216,1.196-1.684c0.198-0.177,0.353-0.426,0.468-0.676c1.029-2.266,2.038-4.543,3.046-6.82
			c0.031-0.062,0.021-0.135,0.042-0.25c-0.146-0.094-0.301-0.218-0.478-0.281c-1.05-0.353-2.121-0.655-3.098-1.195
			c-0.665-0.374-1.299-0.79-1.934-1.216c-0.229-0.156-0.416-0.364-0.468-0.665c0.104-0.073,0.208-0.218,0.301-0.208
			c0.27,0.031,0.541,0.114,0.8,0.208c1.445,0.53,2.952,0.738,4.47,0.884c0.478,0.042,0.956-0.021,1.289-0.031
			c0.489-0.229,0.645-0.541,0.79-0.852c0.489-0.998,0.894-2.027,1.133-3.119c0.083-0.395,0.104-0.821,0.457-1.237
			c1.071-0.281,2.121-0.094,3.036,0.645c0.717,0.572,0.894,1.403,0.79,2.256c-0.073,0.613-0.312,1.206-0.468,1.798
			c0.187,0.25,0.468,0.281,0.728,0.218c0.936-0.229,1.882-0.457,2.807-0.728c0.79-0.229,1.57-0.53,2.308-0.78
			c0.374,0.073,0.53,0.27,0.572,0.572c0.01,0.073,0.021,0.135,0.021,0.208C229.217,570.249,228.25,571.112,226.607,571.486
			L226.607,571.486z M343.539,599.222c-4.668-3.69-10.032-5.094-15.729-4.865c-0.593,0.229-0.79,0.613-1.04,0.936
			c-1.695,2.256-3.867,3.982-6.279,5.354c-3.015,1.715-6.279,2.048-9.637,1.175c-0.811-0.208-1.559-0.572-2.194-1.133
			c-1.071-0.915-1.247-2.162-0.489-3.347c0.457-0.707,1.05-1.289,1.705-1.798c1.882-1.455,3.94-2.568,6.248-3.181
			c1.809-0.478,3.639-0.852,5.51-0.956c0.832-0.042,1.653-0.156,2.516-0.239c0.218-0.27,0.468-0.52,0.624-0.811
			c0.426-0.79,0.821-1.601,1.196-2.412c0.083-0.166,0.031-0.374-0.25-0.457c-0.353,0.301-0.717,0.613-1.092,0.915
			c-0.759,0.603-1.58,1.102-2.464,1.528c-1.622,0.78-3.327,0.915-5.073,0.645c-1.331-0.208-2.256-0.977-2.734-2.266
			c-0.395-1.071-0.457-2.173-0.249-3.275c0.218-1.154,0.509-2.287,1.008-3.368c2.287-4.959,6.071-8.161,11.321-9.606
			c0.863-0.239,1.767-0.353,2.661-0.457c1.331-0.166,2.63,0.042,3.898,0.416c0.457,0.135,0.873,0.395,1.31,0.613
			c0.218,0.114,0.353,0.353,0.27,0.478c-0.073,0.114-0.156,0.239-0.27,0.291c-0.187,0.094-0.395,0.125-0.593,0.187
			c-0.852,0.27-1.726,0.509-2.568,0.821c-4.585,1.705-8.14,4.647-10.531,8.94c-0.405,0.717-0.717,1.497-1.019,2.266
			c-0.291,0.717-0.343,1.476-0.239,2.245c0.083,0.645,0.624,1.102,1.289,1.123c0.499,0.01,0.956-0.083,1.403-0.291
			c1.674-0.79,2.994-1.975,4.034-3.483c0.665-0.977,1.227-2.006,1.674-3.098c0.405-0.956,0.811-1.913,1.164-2.89
			c0.229-0.624,0.613-1.071,1.185-1.383c1.102-0.593,2.277-1.008,3.493-1.299c0.53-0.125,1.092-0.187,1.643-0.042
			c0.135,0.042,0.353,0.333,0.322,0.478c-0.042,0.198-0.062,0.416-0.135,0.603c-1.206,3.316-2.433,6.633-3.971,9.814
			c-0.541,1.123-1.112,2.225-1.684,3.368c0.208,0.468,0.499,0.509,0.769,0.541c1.102,0.135,2.194,0.281,3.275,0.541
			c1.695,0.405,3.316,0.977,4.855,1.778c1.175,0.613,2.256,1.351,3.181,2.318c0.821,0.852,1.518,1.798,1.861,2.952
			C343.758,598.973,343.622,599.087,343.539,599.222L343.539,599.222z M321.531,594.918c-0.759,0.083-1.507,0.177-2.256,0.322
			c-1.83,0.343-3.639,0.78-5.354,1.539c-0.884,0.395-1.726,0.863-2.443,1.528c-0.312,0.281-0.561,0.613-0.686,1.019
			c-0.114,0.343-0.104,0.707,0.125,0.988c0.218,0.26,0.468,0.509,0.759,0.676c0.749,0.416,1.58,0.489,2.412,0.364
			c1.164-0.166,2.287-0.52,3.295-1.154c1.965-1.237,3.514-2.88,4.647-4.896c0.031-0.052,0.01-0.135,0.021-0.26
			C321.885,594.991,321.708,594.897,321.531,594.918L321.531,594.918z M303.619,580.645c-0.031,0.208-0.062,0.416-0.156,0.593
			c-1.112,2.079-2.256,4.138-3.67,6.019c-0.416,0.551-0.873,1.071-1.331,1.591c-1.393,1.58-3.212,2.1-5.229,2.038
			c-0.613-0.021-1.237-0.156-1.84-0.312c-0.8-0.208-1.393-0.998-1.466-1.84c-0.042-0.561,0.021-1.102,0.146-1.643
			c0.405-1.757,0.967-3.462,1.695-5.115c0.53-1.206,1.06-2.412,1.549-3.628c0.177-0.447,0.301-0.915,0.374-1.393
			c0.031-0.166-0.094-0.395-0.198-0.551c-0.135-0.198-0.374-0.187-0.561-0.094c-0.364,0.187-0.748,0.364-1.071,0.613
			c-0.811,0.665-1.424,1.507-2.006,2.37c-1.05,1.549-1.934,3.202-2.765,4.876c-0.249,0.499-0.468,1.008-0.749,1.487
			c-0.146,0.239-0.416,0.364-0.717,0.333c-0.79-0.073-1.466-0.405-2.017-0.967c-0.52-0.52-0.748-1.133-0.582-1.882
			c0.218-0.956,0.561-1.85,0.946-2.745c0.395-0.884,0.79-1.767,1.144-2.672c0.208-0.52,0.312-1.06,0.239-1.622
			c-0.052-0.416-0.541-0.738-0.925-0.561c-0.312,0.146-0.655,0.281-0.894,0.499c-0.655,0.613-1.331,1.227-1.892,1.913
			c-1.892,2.318-3.462,4.844-4.678,7.579c-0.686,1.528-1.154,3.108-1.32,4.99c-0.343,0-0.551,0.042-0.728-0.01
			c-1.507-0.405-2.765-1.247-3.836-2.36c-0.353-0.374-0.53-0.821-0.468-1.331c0.083-0.613,0.166-1.237,0.353-1.83
			c0.385-1.185,0.821-2.349,1.268-3.514c0.634-1.611,1.299-3.202,1.954-4.803c0.187-0.447,0.364-0.894,0.551-1.341
			c0.613-1.507,0.811-3.067,0.52-4.678c-0.042-0.198-0.062-0.405-0.083-0.561c0.125-0.343,0.395-0.447,0.655-0.509
			c1.227-0.27,2.464-0.343,3.711-0.239c0.208,0.021,0.416,0.052,0.613,0.125c0.852,0.291,1.414,0.832,1.258,1.871
			c-0.125,0.821-0.312,1.632-0.572,2.422c-0.364,1.112-0.811,2.204-1.237,3.295c-0.281,0.707-0.603,1.393-0.884,2.1
			c-0.042,0.104,0.052,0.249,0.083,0.385c0.457-0.208,0.676-0.655,0.977-0.998c1.736-1.986,3.597-3.846,5.686-5.447
			c0.717-0.551,1.455-1.06,2.225-1.528c0.53-0.322,1.112-0.551,1.695-0.78c0.249-0.094,0.541-0.094,0.821-0.104
			c0.655-0.021,1.216,0.499,1.258,1.133c0.042,0.561-0.031,1.102-0.177,1.642c-0.312,1.144-0.873,2.183-1.362,3.244
			c-0.042,0.094,0.062,0.239,0.114,0.426c0.509-0.603,0.988-1.175,1.476-1.747c1.216-1.435,2.547-2.734,4.096-3.805
			c0.811-0.551,1.663-0.998,2.609-1.237c0.769-0.198,1.518-0.135,2.194,0.322c0.364,0.239,0.624,0.572,0.613,1.019
			c-0.01,0.478-0.021,0.977-0.146,1.435c-0.249,0.936-0.561,1.85-0.884,2.765c-0.749,2.09-1.663,4.106-2.589,6.113
			c-0.551,1.195-1.029,2.422-1.528,3.639c-0.239,0.582-0.322,1.195-0.27,1.83c0.031,0.416,0.437,0.738,0.863,0.697
			s0.8-0.198,1.154-0.426c0.593-0.385,1.04-0.904,1.455-1.455c1.092-1.435,1.986-2.994,2.828-4.595
			c0.291-0.551,0.603-1.081,0.925-1.611c0.052-0.083,0.249-0.135,0.364-0.104c0.114,0.021,0.239,0.135,0.291,0.249
			C303.609,580.354,303.63,580.51,303.619,580.645L303.619,580.645z M371.701,507.23c-0.156,0.884-0.374,1.757-0.613,2.63
			c-0.291,1.071-0.769,2.079-1.268,3.067c-2.63,5.188-6.102,9.73-10.437,13.608c-0.208,0.187-0.416,0.364-0.686,0.603
			c-0.135,0.322-0.333,0.686-0.468,1.081c-1.279,3.587-2.433,7.215-3.275,10.936c-0.353,1.549-0.707,3.108-0.686,4.72
			c0,0.343,0.042,0.686,0.094,1.029c0.062,0.447,0.697,0.769,1.144,0.613c0.405-0.135,0.769-0.343,1.019-0.686
			c0.374-0.499,0.749-0.998,1.05-1.549c0.333-0.603,0.613-1.247,0.863-1.882c0.915-2.329,1.601-4.73,1.996-7.194
			c0.322-2.048,0.457-4.138,0.353-6.217c-0.01-0.343-0.052-0.686-0.062-1.04c-0.031-0.967,0.301-1.341,1.331-1.487
			c0.135-0.021,0.27-0.031,0.416-0.042c3.399-0.291,6.03-1.882,7.932-4.689c0.114-0.166,0.27-0.312,0.416-0.447
			c0.042-0.042,0.156-0.062,0.198-0.031c0.094,0.073,0.239,0.177,0.239,0.26c-0.021,0.634-0.27,1.206-0.624,1.726
			c-0.478,0.686-1.05,1.289-1.705,1.809c-1.206,0.946-2.537,1.684-3.992,2.173c-0.53,0.177-1.06,0.312-1.58,0.499
			c-0.249,0.094-0.478,0.26-0.79,0.437c0,0.53-0.01,0.884,0,1.227c0.229,5.874-1.019,11.415-3.618,16.675
			c-0.613,1.247-1.279,2.464-2.142,3.555c-0.509,0.655-1.092,1.258-1.663,1.85c-0.239,0.25-0.541,0.437-0.832,0.613
			c-1.112,0.686-2.277,0.53-3.212-0.405c-0.499-0.499-0.863-1.092-1.102-1.747c-0.312-0.842-0.572-1.715-0.624-2.62
			c-0.104-1.736-0.083-3.462,0.198-5.177c0.676-4.2,1.954-8.223,3.639-12.101c2.537-5.853,5.416-11.539,8.764-16.976
			c0.873-1.414,1.819-2.786,2.817-4.117c0.613-0.832,1.362-1.559,2.09-2.308c0.489-0.499,1.06-0.884,1.695-1.185
			c0.385-0.177,0.78-0.281,1.206-0.27c0.769,0.01,1.31,0.385,1.663,1.05C371.753,505.837,371.826,506.544,371.701,507.23
			L371.701,507.23z M368.739,508.446c-0.281,0.25-0.509,0.405-0.676,0.613c-0.468,0.593-0.936,1.196-1.362,1.83
			c-2.755,4.086-4.959,8.452-6.83,13.005c-0.042,0.104,0.031,0.26,0.042,0.395c0.301,0.021,0.457-0.198,0.603-0.405
			c0.769-1.06,1.559-2.11,2.277-3.212c2.162-3.306,3.919-6.82,5.593-10.396c0.177-0.374,0.333-0.759,0.447-1.154
			C368.884,508.945,368.78,508.737,368.739,508.446L368.739,508.446z M442.819,569.345c-0.042,0.416-0.239,0.79-0.509,1.123
			c-0.468,0.561-1.102,0.852-1.788,0.988c-1.019,0.218-2.027,0.447-3.077,0.447c-0.686,0-1.383,0.062-1.923,0.083
			c-0.499,0.208-0.624,0.541-0.78,0.842c-2.017,4.252-3.93,8.556-5.593,12.964c-0.25,0.645-0.447,1.31-0.655,1.975
			c-0.062,0.198-0.052,0.405-0.062,0.613c-0.021,0.489,0.405,0.967,0.884,0.956c0.343,0,0.697,0,1.029-0.062
			c1.247-0.27,2.339-0.832,3.306-1.663c0.998-0.863,1.923-1.809,2.682-2.89c0.842-1.185,1.653-2.391,2.453-3.607
			c0.686-1.04,1.341-2.11,2.017-3.15c0.146-0.229,0.364-0.416,0.572-0.582c0.083-0.062,0.281-0.073,0.374-0.01
			c0.094,0.052,0.218,0.249,0.187,0.322c-0.229,0.572-0.416,1.185-0.749,1.695c-1.154,1.809-2.339,3.597-3.566,5.354
			c-0.593,0.842-1.299,1.622-2.006,2.381c-1.05,1.112-2.235,2.069-3.639,2.713c-1.83,0.842-3.722,1.019-5.666,0.437
			c-1.237-0.364-2.079-1.414-2.194-2.724c-0.073-0.832,0-1.663,0.218-2.474c0.094-0.333,0.177-0.665,0.229-1.008
			c0.021-0.104-0.083-0.239-0.125-0.364c-0.437,0.135-0.624,0.53-0.873,0.852c-0.977,1.258-2.038,2.453-3.223,3.524
			c-0.717,0.645-1.497,1.237-2.266,1.809c-0.385,0.281-0.832,0.499-1.268,0.707c-1.081,0.52-2.162,0.395-3.202-0.125
			c-0.509-0.25-0.884-0.655-1.112-1.185c-0.281-0.645-0.405-1.32-0.322-2.027c0.031-0.27,0.083-0.551,0.146-0.821
			c0.894-3.42,1.965-6.788,3.545-9.959c0.343-0.676,0.707-1.351,1.112-1.996c0.759-1.227,1.85-2.058,3.254-2.422
			c1.071-0.281,2.152-0.551,3.275-0.52c0.322,0.01,0.728-0.114,0.915,0.27c0.135,0.281-0.114,0.52-0.26,0.749
			c-1.663,2.641-3.119,5.395-4.366,8.254c-0.697,1.591-1.31,3.202-1.632,4.917c-0.062,0.333-0.094,0.686-0.083,1.029
			c0.01,0.353,0.166,0.665,0.593,0.884c0.187-0.083,0.478-0.146,0.676-0.312c0.582-0.489,1.185-0.967,1.684-1.528
			c1.185-1.351,2.318-2.755,3.451-4.158c0.478-0.582,0.873-1.237,1.549-1.653c0.27-0.166,0.447-0.53,0.582-0.832
			c0.863-1.882,1.715-3.784,2.568-5.676c0.249-0.561,0.478-1.144,0.749-1.767c-1.102-0.551-2.266-0.769-3.316-1.279
			c-1.008-0.478-1.934-1.081-2.848-1.84c-1.133,0-2.1,0.01-3.067-0.01c-0.094,0-0.198-0.146-0.27-0.239
			c-0.031-0.042-0.01-0.146,0.031-0.198c0.125-0.166,0.27-0.312,0.395-0.478c0.904-1.237,2.173-1.902,3.618-2.277
			c0.738-0.187,1.476-0.374,2.225-0.509c0.468-0.083,0.956-0.166,1.424,0.062c-0.218,0.998-0.925,1.965-2.505,3.399
			c0.083,0.083,0.156,0.198,0.26,0.239c0.395,0.135,0.79,0.291,1.196,0.353c1.029,0.146,2.058,0.25,3.098,0.343
			c0.26,0.021,0.541-0.062,0.852-0.104c1.144-1.476,1.476-3.254,1.954-4.98c0.114-0.073,0.218-0.187,0.353-0.208
			c0.697-0.135,1.383-0.062,2.038,0.187c1.435,0.551,1.85,1.923,1.58,3.015c-0.125,0.53-0.312,1.05-0.468,1.549
			c0.26,0.239,0.541,0.26,0.811,0.198c0.873-0.208,1.746-0.426,2.609-0.676c0.728-0.218,1.445-0.499,2.162-0.728
			c0.187-0.062,0.395-0.031,0.582-0.042C443.048,568.107,442.871,568.731,442.819,569.345L442.819,569.345z M284.782,518.883
			c-0.114,0.166-0.27,0.322-0.437,0.437c-0.697,0.457-1.466,0.78-2.256,1.04c-0.395,0.125-0.78,0.27-1.227,0.426
			c-0.052,0.26-0.146,0.52-0.166,0.79c-0.042,0.832-0.135,1.653-0.281,2.474c-0.53,2.921-2.006,5.281-4.221,7.204
			c-0.842,0.738-1.788,1.289-2.828,1.705c-1.684,0.676-3.368,0.665-5.073,0.062c-1.591-0.561-2.713-1.622-3.389-3.16
			c-0.738-1.695-0.946-3.462-0.665-5.291c0.27-1.705,0.645-3.399,1.092-5.073c1.289-4.907,2.994-9.647,5.343-14.149
			c0.8-1.539,1.715-3.004,2.817-4.345c0.613-0.759,1.299-1.445,2.121-1.975c0.405-0.27,0.832-0.499,1.268-0.697
			c1.092-0.478,2.162-0.333,3.202,0.198c0.863,0.447,1.341,1.185,1.435,2.152c0.073,0.759,0,1.518-0.218,2.256
			c-0.374,1.268-0.946,2.453-1.601,3.597c-1.289,2.225-2.807,4.283-4.481,6.237c-1.258,1.466-2.589,2.89-4.138,4.616
			c-0.208,0.769-0.478,1.695-0.707,2.63c-0.385,1.611-0.738,3.243-1.112,4.855c-0.374,1.57-0.353,3.16-0.198,4.751
			c0.062,0.634,0.281,1.206,0.603,1.757c0.426,0.707,1.393,1.185,2.339,0.613c0.353-0.208,0.686-0.489,0.936-0.8
			c0.385-0.489,0.749-0.998,1.029-1.549c1.268-2.495,2.079-5.125,2.266-7.932c0.021-0.27-0.042-0.541-0.073-0.852
			c-0.509-0.27-0.988-0.52-1.466-0.8c-0.665-0.395-1.196-0.936-1.539-1.643c-0.478-0.977-0.353-1.871,0.281-2.724
			c1.455-1.975,4.938-2.069,6.248,0.541c0.312,0.624,0.551,1.268,0.655,1.965c0.042,0.27,0.135,0.541,0.208,0.8
			c0.094,0.322,0.468,0.509,0.832,0.437c0.749-0.166,1.476-0.353,2.173-0.686c0.312-0.146,0.624-0.291,0.956-0.385
			c0.094-0.031,0.281,0.083,0.322,0.177C284.886,518.613,284.855,518.79,284.782,518.883L284.782,518.883z M272.089,514.455
			c0.208-0.156,0.416-0.239,0.53-0.385c1.778-2.297,3.493-4.647,4.917-7.194c0.478-0.842,0.904-1.715,1.279-2.609
			c0.27-0.634,0.447-1.31,0.572-1.986c0.073-0.395,0.042-0.842-0.083-1.227c-0.229-0.728-1.04-1.247-1.788-0.561
			c-0.301,0.281-0.603,0.582-0.821,0.925c-0.405,0.645-0.79,1.31-1.102,1.996c-1.539,3.347-2.63,6.84-3.587,10.385
			C271.964,513.987,272.058,514.195,272.089,514.455L272.089,514.455z M274.979,517.74c-0.114,0.01-0.26,0.094-0.312,0.187
			c-0.052,0.094-0.021,0.249,0.031,0.353c0.166,0.322,0.457,0.447,0.811,0.468c0.073-0.094,0.218-0.187,0.218-0.281
			C275.717,518.041,275.374,517.709,274.979,517.74L274.979,517.74z M189.515,576.227c-0.499,0.499-1.154,0.769-1.788,1.029
			s-1.299,0.447-1.944,0.665c-0.229,0.218-0.281,0.478-0.291,0.759c-0.021,0.977-0.125,1.934-0.333,2.88
			c-0.728,3.264-2.485,5.832-5.25,7.693c-1.403,0.946-2.952,1.507-4.689,1.393c-2.796-0.177-4.969-1.643-5.842-4.491
			c-0.426-1.403-0.551-2.838-0.322-4.314c0.27-1.715,0.634-3.399,1.071-5.073c1.258-4.834,2.932-9.523,5.229-13.972
			c0.8-1.539,1.695-3.025,2.776-4.377c0.821-1.04,1.767-1.944,2.921-2.609c0.749-0.426,1.539-0.613,2.391-0.52
			c0.343,0.042,0.686,0.114,0.998,0.239c1.237,0.457,1.892,1.341,1.902,2.682c0.01,0.697-0.073,1.372-0.27,2.048
			c-0.385,1.268-0.967,2.443-1.632,3.586c-1.476,2.516-3.243,4.824-5.177,7.007c-1.05,1.195-2.162,2.339-3.368,3.649
			c-0.78,2.838-1.466,5.655-2.017,8.514c-0.239,1.227-0.166,2.485-0.042,3.732c0.062,0.634,0.27,1.216,0.582,1.767
			c0.676,1.196,1.996,1.071,2.672,0.509c0.27-0.218,0.541-0.447,0.738-0.728c0.364-0.499,0.728-1.019,0.998-1.57
			c1.258-2.505,2.048-5.135,2.214-7.942c0.01-0.208-0.042-0.405-0.052-0.561c-0.156-0.322-0.416-0.426-0.665-0.53
			c-0.645-0.27-1.237-0.624-1.715-1.143c-1.341-1.476-1.258-3.036,0.291-4.325c0.551-0.457,1.185-0.717,1.902-0.717
			c0.208,0,0.416-0.01,0.624-0.01c1.299-0.01,2.266,0.603,2.9,1.694c0.312,0.53,0.499,1.133,0.707,1.715
			c0.135,0.395,0.198,0.8,0.312,1.206c0.073,0.26,0.364,0.457,0.613,0.457c0.634,0,1.227-0.166,1.819-0.374
			c0.572-0.208,1.06-0.634,1.695-0.686c0.073,0.135,0.177,0.25,0.177,0.374C189.661,575.977,189.598,576.133,189.515,576.227
			L189.515,576.227z M176.853,571.6c0.114,0,0.281,0,0.333-0.062c2.038-2.537,3.93-5.177,5.489-8.036
			c0.333-0.603,0.634-1.227,0.904-1.861c0.218-0.509,0.395-1.04,0.53-1.57c0.104-0.395,0.114-0.821,0.166-1.164
			c-0.052-0.27-0.062-0.478-0.135-0.665c-0.333-0.811-1.102-1.133-1.819-0.509c-0.53,0.457-0.946,1.019-1.258,1.632
			c-0.478,0.925-0.925,1.861-1.32,2.817c-1.154,2.817-2.048,5.718-2.89,8.639c-0.052,0.198-0.062,0.405-0.073,0.613
			C176.77,571.476,176.822,571.6,176.853,571.6L176.853,571.6z M180.024,574.938c-0.187-0.104-0.437-0.052-0.541,0.125
			c-0.104,0.187-0.094,0.416,0.083,0.541c0.218,0.156,0.447,0.302,0.697,0.395c0.094,0.042,0.239-0.073,0.426-0.146
			C180.596,575.468,180.409,575.145,180.024,574.938L180.024,574.938z M260.633,565.945c-0.707,6.477-2.693,12.568-5.562,18.401
			c-1.164,2.37-2.464,4.657-4.013,6.788c-0.655,0.894-1.383,1.736-2.121,2.557c-0.416,0.457-0.904,0.852-1.403,1.237
			c-0.946,0.728-2.017,0.842-3.15,0.509c-1.247-0.353-2.235-1.071-3.004-2.11c-0.665-0.904-1.123-1.923-1.32-3.025
			c-0.395-2.245-0.135-4.429,0.915-6.466c0.624-1.216,1.611-2.069,2.88-2.578c0.582-0.239,1.196-0.343,1.944,0.021
			c-0.572,1.414-1.123,2.693-1.435,4.044c-0.374,1.622-0.603,3.264-0.572,4.938c0.01,0.707,0.166,1.383,0.478,2.006
			c0.187,0.385,0.509,0.655,0.977,0.686c0.499-0.094,0.884-0.395,1.185-0.79c0.426-0.551,0.842-1.102,1.185-1.705
			c0.676-1.206,1.331-2.433,1.934-3.67c1.383-2.869,2.578-5.832,3.68-8.816c0.956-2.599,1.871-5.208,2.765-7.838
			c0.852-2.505,1.393-5.073,1.57-7.714c0.042-0.551,0.042-1.112,0-1.663c-0.021-0.26-0.166-0.509-0.26-0.759
			c-0.26,0-0.499-0.062-0.665,0.01c-0.312,0.146-0.624,0.322-0.863,0.561c-1.123,1.144-2.069,2.412-2.609,3.961
			c-0.499,1.445-0.925,2.9-1.247,4.397c-0.052,0.27-0.166,0.53-0.52,0.686c-0.187-0.125-0.437-0.239-0.613-0.416
			c-1.247-1.289-1.674-2.828-1.372-4.574c0.478-2.776,1.975-4.834,4.408-6.227c0.915-0.52,1.913-0.8,2.952-0.936
			c0.27-0.042,0.551-0.042,0.832-0.021c1.476,0.073,2.297,0.686,2.724,2.121c0.198,0.655,0.343,1.352,0.395,2.038
			C260.82,563.034,260.789,564.49,260.633,565.945L260.633,565.945z M354.507,560.321c-0.395,1.403-0.956,2.745-1.622,4.034
			c-1.497,2.9-3.368,5.562-5.499,8.036c-1.715,2.006-3.587,3.846-5.697,5.479c-1.081,2.734-1.591,5.572-1.601,8.504
			c0,0.27,0.052,0.551,0.114,0.821c0.104,0.53,0.665,0.967,1.227,0.904c0.333-0.031,0.686-0.104,0.988-0.239
			c1.029-0.457,1.882-1.154,2.693-1.913c1.175-1.092,2.131-2.36,2.973-3.722c0.509-0.821,0.956-1.684,1.445-2.516
			c0.177-0.291,0.374-0.572,0.593-0.842c0.052-0.062,0.229-0.042,0.343-0.031c0.042,0.01,0.114,0.104,0.114,0.166
			c-0.01,0.395-0.031,0.8-0.052,1.195c-0.343,0.166-0.364,0.509-0.405,0.832c-0.353,0.177-0.405,0.499-0.426,0.842
			c-0.104,0.042-0.218,0.083-0.27,0.166c-0.353,0.52-0.665,1.05-1.008,1.57c-0.873,1.341-1.819,2.609-2.952,3.742
			c-0.946,0.925-1.965,1.746-3.14,2.36c-1.112,0.572-2.277,0.998-3.576,0.915c-1.227-0.083-2.09-0.676-2.599-1.767
			c-0.322-0.686-0.53-1.424-0.561-2.193c-0.062-1.32-0.021-2.63,0.198-3.93c0.582-3.493,1.653-6.861,2.921-10.157
			c1.497-3.888,3.295-7.62,5.603-11.092c0.925-1.393,1.934-2.703,3.129-3.857c0.8-0.769,1.663-1.445,2.682-1.934
			c0.707-0.343,1.445-0.468,2.214-0.405c1.393,0.104,2.318,0.998,2.422,2.37C354.819,558.554,354.756,559.448,354.507,560.321
			L354.507,560.321z M352.552,557.743c-0.156-0.177-0.572-0.291-0.759-0.166c-0.281,0.187-0.613,0.353-0.821,0.613
			c-0.468,0.593-0.925,1.206-1.31,1.861c-0.728,1.247-1.435,2.516-2.09,3.805c-1.778,3.514-3.264,7.152-4.73,10.801
			c-0.052,0.125-0.104,0.27-0.094,0.395c0.01,0.104,0.104,0.198,0.198,0.353c0.26-0.239,0.53-0.457,0.769-0.697
			c1.445-1.476,2.807-3.046,4.106-4.657c2.121-2.609,3.514-5.603,4.616-8.743c0.27-0.769,0.374-1.601,0.499-2.422
			C353.01,558.46,352.854,558.065,352.552,557.743L352.552,557.743z M350.546,580.25c0-0.01,0-0.031,0-0.042
			c0.021-0.01,0.042-0.021,0.062-0.031L350.546,580.25L350.546,580.25z M350.13,581.102c0-0.021,0.01-0.042,0.01-0.062
			c0.021-0.01,0.031-0.01,0.052-0.021L350.13,581.102L350.13,581.102z M349.714,581.934c0-0.021,0-0.031,0-0.052
			c0.021-0.01,0.042-0.021,0.073-0.031L349.714,581.934L349.714,581.934z M251.526,522.168c-0.198,0.281-0.405,0.561-0.655,0.8
			c-0.863,0.811-1.84,1.466-2.848,2.069c-0.468,0.281-0.925,0.582-1.32,0.832c-0.177,0.478,0.052,0.759,0.198,1.06
			c0.416,0.894,0.593,1.83,0.561,2.828c-0.042,0.998-0.312,1.913-0.863,2.734c-1.081,1.622-2.557,2.713-4.501,3.098
			c-0.894,0.177-1.788,0.146-2.661-0.146c-1.196-0.395-1.954-1.362-2.038-2.609c-0.062-1.008,0.187-1.913,0.811-2.724
			c0.728-0.936,1.487-1.83,2.495-2.474c0.343-0.218,0.749-0.385,0.904-0.842c-0.26-0.655-0.884-1.029-1.299-1.57
			c-0.509-0.665-0.956-1.341-1.216-2.152c-0.967-2.973,0.312-7.464,4.876-8.826c1.279-0.385,2.609-0.353,3.909-0.062
			c0.676,0.146,1.31,0.457,1.715,1.081c0.114,0.166,0.187,0.364,0.26,0.561c0.094,0.239-0.135,0.624-0.385,0.624
			c-0.27,0-0.551-0.01-0.821-0.01c-0.343-0.01-0.697-0.062-1.029-0.031c-1.85,0.198-3.181,1.435-3.566,3.254
			c-0.208,0.977-0.083,1.934,0.239,2.859c0.291,0.852,0.697,1.663,1.279,2.36c0.083,0.104,0.229,0.156,0.447,0.301
			c1.216-0.593,2.225-1.32,3.16-2.152c0.457-0.416,0.936-0.821,1.424-1.206c0.146-0.114,0.374-0.177,0.572-0.198
			c0.114-0.01,0.281,0.073,0.343,0.166C251.578,521.888,251.588,522.085,251.526,522.168L251.526,522.168z M244.301,530.038
			c-0.312-0.686-0.738-1.31-1.247-1.871c-0.042-0.052-0.104-0.094-0.146-0.146c-0.166-0.177-0.541-0.198-0.759-0.052
			c-0.884,0.582-1.539,1.352-1.986,2.308c-0.239,0.509-0.343,1.04-0.343,1.591c0.01,0.863,0.603,1.601,1.424,1.778
			c0.198,0.042,0.405,0.062,0.551,0.083c0.707,0.031,1.175-0.094,1.611-0.416C244.509,532.523,244.862,531.244,244.301,530.038
			L244.301,530.038z M268.762,569.105c-1.081,1.549-2.474,2.765-4.075,3.742c-0.281,0.177-0.634,0.25-0.967,0.322
			c-0.27,0.062-0.541,0-0.759-0.187c0-0.135-0.052-0.301,0.01-0.385c0.198-0.281,0.447-0.52,0.665-0.78
			c0.769-0.925,1.206-1.986,1.331-3.181c0.052-0.551,0.114-1.092,0.218-1.632c0.114-0.561,0.582-0.946,1.154-1.019
			c0.707-0.083,1.362,0.031,1.986,0.385c0.499,0.281,0.884,0.645,1.029,1.237C269.355,568.18,269.074,568.658,268.762,569.105
			L268.762,569.105z M234.269,511.222c-0.229,0.426-0.468,0.852-0.78,1.216c-0.967,1.185-2.09,2.204-3.379,3.036
			c-0.395,0.249-0.873,0.416-1.331,0.551c-0.374,0.114-0.665-0.062-0.811-0.499c0.26-0.291,0.541-0.582,0.8-0.904
			c0.707-0.884,1.154-1.871,1.289-3.004c0.073-0.613,0.156-1.237,0.26-1.85c0.094-0.53,0.613-0.956,1.164-1.029
			c0.873-0.114,1.632,0.187,2.329,0.676C234.467,509.87,234.643,510.536,234.269,511.222L234.269,511.222z M350.13,581.102
			c0.062-0.364,0.052-0.749,0.478-0.925C350.567,580.551,350.556,580.936,350.13,581.102L350.13,581.102z M349.714,581.934
			c0.021-0.374,0.062-0.749,0.478-0.925C350.141,581.383,350.172,581.788,349.714,581.934L349.714,581.934z M316.323,527.179
			c-0.073,0.083-0.156,0.146-0.249,0.208c-0.031-0.166-0.031-0.312,0.187-0.364C316.271,527.034,316.344,527.169,316.323,527.179
			L316.323,527.179z M316.053,527.418c0.01-0.01,0.021-0.01,0.031-0.021c0,0.021,0.01,0.042,0.01,0.062L316.053,527.418
			L316.053,527.418z M400.872,519.902c0,0.042-0.031,0.073-0.062,0.104c0.01-0.052,0.01-0.094,0.01-0.146L400.872,519.902
			L400.872,519.902z M400.81,520.006c-0.01,0.083-0.021,0.166-0.031,0.249C400.654,520.131,400.737,520.069,400.81,520.006
			L400.81,520.006z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_2_1_" enable-background="new    ">
        <g id="Shape_1_copy_2">
          <path
            d="M307.591,348.663c-0.166,2.349-0.333,4.699-0.676,7.028c-0.447,3.077-1.05,6.144-1.798,9.169
			c-2.256,9.169-5.468,18.016-9.315,26.624c-6.092,13.598-13.754,26.249-23.006,37.913c-5.697,7.184-11.997,13.795-19.035,19.7
			c-6.019,5.052-12.517,9.387-19.554,12.901c-4.356,2.173-8.888,3.846-13.65,4.886c-2.641,0.582-5.302,0.956-8.005,1.154
			c-2.848,0.208-5.686,0.146-8.514-0.114c-3.451-0.322-6.882-0.78-10.167-1.965c-0.78-0.281-1.559-0.572-2.318-0.904
			c-5.302-2.256-9.263-5.988-12.07-10.978c-1.341-2.381-2.11-4.959-2.609-7.63c-0.395-2.121-0.489-4.262-0.53-6.414
			c-0.114-5.437,0.645-10.77,1.975-16.03c1.643-6.456,3.961-12.683,6.674-18.764c4.044-9.076,8.982-17.652,14.513-25.886
			s11.622-16.041,18.224-23.442c0.832-0.925,1.695-1.83,2.589-2.703c3.94-3.909,8.119-7.547,12.517-10.926
			c4.252-3.264,8.868-5.894,13.847-7.901c9.564-3.857,18.972-8.078,28.453-12.153c1.206-0.52,2.422-1.04,3.618-1.57
			c3.805-1.674,7.62-3.306,11.269-5.26c0.873-0.083,1.455,0.156,2.058,0.322c3.576,1.019,6.518,2.984,8.816,5.926
			c0.894,1.154,1.715,2.349,2.401,3.639c1.902,3.576,3.233,7.339,3.784,11.363C307.632,340.637,307.871,344.629,307.591,348.663
			L307.591,348.663z M292.049,343.091c-0.52-2.942-1.279-5.811-2.391-8.587c-0.489-1.227-1.092-2.391-1.882-3.451
			c-1.923-2.578-4.543-3.992-7.693-4.47c-1.934-0.291-3.878-0.229-5.801,0.104c-3.763,0.634-7.433,1.601-10.978,3.056
			c-4.044,1.663-7.901,3.68-11.633,5.967c-7.204,4.397-13.66,9.699-19.211,16.072c-1.279,1.466-2.578,2.9-3.836,4.377
			c-6.373,7.433-12.142,15.292-17.07,23.765c-4.501,7.734-8.525,15.698-11.633,24.097c-1.684,4.553-3.129,9.169-4.127,13.92
			c-0.613,2.921-1.029,5.863-1.435,8.816c-0.187,1.372-0.333,2.755-0.416,4.138c-0.166,2.557-0.187,5.125-0.083,7.682
			c0.114,2.713,0.561,5.375,1.289,7.974c0.946,3.379,2.474,6.466,4.751,9.148c3.493,4.096,7.911,6.404,13.265,6.944
			c1.944,0.198,3.867,0.166,5.811,0.031c3.618-0.239,7.121-0.967,10.562-2.069c4.647-1.487,8.972-3.649,13.078-6.258
			c5.167-3.285,9.876-7.142,14.221-11.435c5.094-5.032,9.585-10.562,13.608-16.488c5.312-7.828,9.585-16.197,13.119-24.96
			c4.657-11.571,7.641-23.557,8.774-35.98c0.322-3.587,0.624-7.184,0.509-10.77C293.005,350.794,292.725,346.927,292.049,343.091
			L292.049,343.091z M150.375,347.821c-1.206,3.181-2.703,6.217-4.418,9.148c-2.173,3.711-4.605,7.256-7.121,10.728
			c-4.512,6.237-9.398,12.173-14.564,17.881c-10.053,11.103-20.719,21.602-31.842,31.624c-5.614,5.052-11.29,10.032-17.101,14.856
			c-1.383,1.154-2.786,2.277-4.179,3.42c-0.208,0.177-0.447,0.343-0.405,0.676c0.104,0.052,0.218,0.146,0.333,0.156
			c2.142,0.177,4.283,0.333,6.425,0.52c2.204,0.198,4.408,0.416,6.622,0.634c1.861,0.177,3.722,0.343,5.583,0.52
			c2.547,0.25,5.094,0.509,7.651,0.759c1.861,0.187,3.722,0.343,5.583,0.551c1.788,0.198,3.576,0.405,5.364,0.645
			c2.329,0.322,4.668,0.645,6.986,1.019c3.826,0.624,7.62,1.445,11.331,2.557c0.53,0.156,1.06,0.333,1.559,0.561
			c1.341,0.603,2.214,1.622,2.589,3.056c0.229,0.884,0.291,1.736,0.094,2.661c-0.832,3.857-1.57,7.734-2.641,11.539
			c-0.229,0.8-0.499,1.591-0.78,2.37c-0.229,0.634-0.759,0.769-1.32,0.322c-2.9-2.308-6.185-3.93-9.595-5.323
			c-3.919-1.601-7.932-2.89-11.997-4.034c-5.395-1.518-10.864-2.755-16.363-3.836c-4.481-0.884-8.992-1.643-13.504-2.308
			c-2.734-0.405-5.499-0.676-8.244-0.988c-1.923-0.218-3.857-0.426-5.78-0.634c-0.624-0.062-1.247-0.104-1.861-0.135
			c-0.343-0.021-0.686,0-1.05,0c-0.457,0.333-0.915,0.645-1.362,0.977c-3.108,2.329-6.289,4.533-9.575,6.612
			c-6.581,4.169-13.525,7.568-20.906,10.063c-2.297,0.78-4.647,1.372-7.038,1.757c-0.956,0.156-1.934,0.208-2.9,0.239
			c-3.68,0.135-7.339-0.021-10.936-0.925c-0.603-0.156-1.216-0.301-1.778-0.561c-1.861-0.821-2.932-2.245-3.16-4.293
			c-0.208-1.975,0.062-3.867,0.884-5.676c0.665-1.466,1.549-2.776,2.609-3.982c1.746-1.986,3.742-3.691,5.926-5.188
			c3.389-2.329,7.048-4.117,10.895-5.541c4.169-1.549,8.452-2.672,12.828-3.368c3.555-0.561,7.142-0.936,10.749-0.936
			c5.468,0.01,10.947-0.01,16.425,0.021c1.871,0.01,3.732,0.135,5.697,0.208c0.738-0.353,1.383-0.998,2.069-1.57
			c3.306-2.744,6.622-5.479,9.886-8.265c5.219-4.449,10.417-8.92,15.5-13.535c7.693-6.996,15.053-14.336,21.904-22.164
			c5.073-5.79,9.73-11.903,13.847-18.421c2.079-3.285,3.95-6.674,5.406-10.281c1.247-3.098,2.131-6.3,2.516-9.616
			c0.27-2.349,0.239-4.709-0.021-7.048c-0.208-1.861-0.52-3.701-1.092-5.489c-0.593-1.85-1.383-3.618-2.453-5.25
			c-1.934-2.932-4.543-5.011-7.807-6.269c-1.746-0.676-3.566-1.071-5.437-1.31c-2.838-0.364-5.666-0.26-8.504-0.021
			c-2.911,0.239-5.759,0.749-8.556,1.591c-4.886,1.476-9.377,3.732-13.504,6.736c-3.649,2.661-7.007,5.655-10.074,8.972
			c-2.256,2.443-4.47,4.928-6.56,7.516c-0.343,0.426-0.707,0.852-1.102,1.237c-0.312,0.301-0.728,0.405-1.154,0.27
			c-0.509-0.146-0.821-0.499-0.946-1.008c-0.125-0.489-0.052-0.967,0.239-1.393c0.198-0.291,0.416-0.551,0.645-0.811
			c4.034-4.761,8.244-9.356,12.704-13.722c4.761-4.668,9.907-8.878,15.563-12.433c4.293-2.693,8.816-4.938,13.598-6.633
			c3.535-1.247,7.173-2.079,10.926-2.381c0.686-0.052,1.372-0.166,2.069-0.218c4.044-0.27,8.026,0.104,11.945,1.112
			c2.568,0.665,4.948,1.798,7.1,3.347c3.264,2.349,5.416,5.52,6.612,9.325c0.541,1.715,0.863,3.493,0.946,5.302
			c0.021,0.343,0.031,0.697,0.052,1.04C152.745,339.296,151.966,343.61,150.375,347.821L150.375,347.821z M52.28,446.3
			c-0.821-0.125-1.643-0.301-2.464-0.322c-4.637-0.146-9.273-0.447-13.92-0.333c-1.934,0.052-3.878,0.146-5.811,0.187
			c-4.023,0.083-8.026,0.353-12.018,0.863c-1.788,0.229-3.566,0.509-5.323,0.915c-1.216,0.281-2.391,0.686-3.503,1.258
			c-0.676,0.353-1.32,0.769-1.882,1.289c-1.175,1.081-1.83,2.401-1.954,4.002c-0.114,1.487,0.353,2.713,1.455,3.722
			c0.946,0.852,2.027,1.445,3.243,1.798c1.736,0.499,3.514,0.748,5.323,0.79c4.522,0.083,8.992-0.301,13.421-1.237
			c3.535-0.748,6.934-1.913,10.115-3.649c3.16-1.726,6.258-3.555,9.242-5.593c1.31-0.894,2.578-1.871,3.846-2.828
			c0.218-0.156,0.468-0.312,0.53-0.613C52.468,446.456,52.384,446.321,52.28,446.3L52.28,446.3z M454.66,347.821
			c-1.206,3.181-2.703,6.217-4.418,9.148c-2.173,3.711-4.605,7.256-7.121,10.728c-4.512,6.237-9.398,12.173-14.565,17.881
			c-10.053,11.103-20.719,21.602-31.842,31.624c-5.614,5.052-11.29,10.032-17.101,14.856c-1.383,1.154-2.786,2.277-4.179,3.42
			c-0.208,0.177-0.457,0.343-0.405,0.676c0.114,0.052,0.218,0.146,0.343,0.156c2.142,0.177,4.283,0.333,6.425,0.52
			c2.204,0.198,4.408,0.416,6.622,0.634c1.861,0.177,3.722,0.343,5.583,0.52c2.547,0.25,5.094,0.509,7.651,0.759
			c1.861,0.187,3.722,0.343,5.583,0.551c1.788,0.198,3.576,0.405,5.364,0.645c2.329,0.322,4.668,0.645,6.986,1.019
			c3.826,0.624,7.61,1.445,11.331,2.557c0.53,0.156,1.06,0.333,1.559,0.561c1.341,0.603,2.214,1.622,2.589,3.056
			c0.229,0.884,0.301,1.736,0.094,2.661c-0.832,3.857-1.559,7.734-2.641,11.539c-0.229,0.8-0.499,1.591-0.78,2.37
			c-0.229,0.634-0.759,0.769-1.32,0.322c-2.9-2.308-6.185-3.93-9.595-5.323c-3.909-1.601-7.932-2.89-11.997-4.034
			c-5.395-1.518-10.864-2.755-16.363-3.836c-4.481-0.884-8.992-1.643-13.504-2.308c-2.734-0.405-5.499-0.676-8.254-0.988
			c-1.923-0.218-3.857-0.426-5.78-0.634c-0.624-0.062-1.247-0.104-1.861-0.135c-0.343-0.021-0.686,0-1.05,0
			c-0.457,0.333-0.915,0.645-1.362,0.977c-3.108,2.329-6.3,4.533-9.575,6.612c-6.581,4.169-13.525,7.568-20.906,10.063
			c-2.297,0.78-4.647,1.372-7.038,1.757c-0.956,0.156-1.934,0.208-2.9,0.239c-3.68,0.135-7.339-0.021-10.936-0.925
			c-0.603-0.156-1.216-0.301-1.778-0.561c-1.861-0.821-2.932-2.245-3.16-4.293c-0.208-1.975,0.062-3.867,0.884-5.676
			c0.665-1.466,1.549-2.776,2.609-3.982c1.746-1.986,3.742-3.691,5.926-5.188c3.389-2.329,7.048-4.117,10.895-5.541
			c4.169-1.549,8.452-2.672,12.828-3.368c3.555-0.561,7.142-0.936,10.749-0.936c5.479,0.01,10.947-0.01,16.425,0.021
			c1.861,0.01,3.732,0.135,5.697,0.208c0.738-0.353,1.383-0.998,2.069-1.57c3.306-2.744,6.622-5.479,9.886-8.265
			c5.219-4.449,10.417-8.92,15.5-13.535c7.693-6.996,15.053-14.336,21.904-22.164c5.084-5.79,9.73-11.903,13.847-18.421
			c2.079-3.285,3.95-6.674,5.406-10.281c1.247-3.098,2.131-6.3,2.516-9.616c0.27-2.349,0.239-4.709-0.021-7.048
			c-0.208-1.861-0.52-3.701-1.092-5.489c-0.593-1.85-1.383-3.618-2.453-5.25c-1.934-2.932-4.543-5.011-7.807-6.269
			c-1.757-0.676-3.566-1.071-5.437-1.31c-2.838-0.364-5.666-0.26-8.504-0.021c-2.911,0.239-5.759,0.749-8.556,1.591
			c-4.886,1.476-9.377,3.732-13.504,6.736c-3.649,2.661-7.007,5.655-10.074,8.972c-2.256,2.443-4.47,4.928-6.56,7.516
			c-0.343,0.426-0.707,0.852-1.102,1.237c-0.312,0.301-0.717,0.405-1.154,0.27c-0.509-0.146-0.821-0.499-0.946-1.008
			c-0.125-0.489-0.052-0.967,0.239-1.393c0.198-0.291,0.416-0.551,0.645-0.811c4.034-4.761,8.254-9.356,12.704-13.722
			c4.761-4.668,9.907-8.878,15.562-12.433c4.293-2.693,8.816-4.938,13.598-6.633c3.535-1.247,7.173-2.079,10.926-2.381
			c0.686-0.052,1.372-0.166,2.069-0.218c4.044-0.27,8.026,0.104,11.945,1.112c2.568,0.665,4.948,1.798,7.1,3.347
			c3.264,2.349,5.416,5.52,6.612,9.325c0.541,1.715,0.863,3.493,0.946,5.302c0.01,0.343,0.031,0.697,0.052,1.04
			C457.04,339.296,456.25,343.61,454.66,347.821L454.66,347.821z M356.565,446.3c-0.821-0.125-1.643-0.301-2.464-0.322
			c-4.637-0.146-9.273-0.447-13.92-0.333c-1.934,0.052-3.878,0.146-5.811,0.187c-4.023,0.083-8.026,0.353-12.018,0.863
			c-1.788,0.229-3.566,0.509-5.312,0.915c-1.216,0.281-2.401,0.686-3.503,1.258c-0.676,0.353-1.32,0.769-1.882,1.289
			c-1.175,1.081-1.83,2.401-1.954,4.002c-0.114,1.487,0.353,2.713,1.455,3.722c0.946,0.852,2.027,1.445,3.243,1.798
			c1.736,0.499,3.514,0.748,5.323,0.79c4.522,0.083,8.992-0.301,13.421-1.237c3.535-0.748,6.934-1.913,10.115-3.649
			c3.16-1.726,6.258-3.555,9.231-5.593c1.31-0.894,2.578-1.871,3.846-2.828c0.218-0.156,0.468-0.312,0.53-0.613
			C356.763,446.456,356.669,446.321,356.565,446.3L356.565,446.3z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_1_" enable-background="new    ">
        <g id="Shape_1_copy">
          <path
            d="M519.758,227.531c-0.208,0.593-0.426,1.175-0.697,1.736c-0.967,2.09-2.225,3.982-3.701,5.749
			c-2.194,2.609-4.657,4.938-7.298,7.09c-3.119,2.547-6.445,4.792-9.845,6.944c-0.582,0.374-1.175,0.738-1.694,1.06
			c-0.291,0.353-0.291,0.697-0.218,1.04c0.998,4.387,0.416,8.691-0.884,12.912c-1.102,3.587-3.119,6.622-5.894,9.159
			c-4.065,3.722-8.784,6.237-14.107,7.631c-2.63,0.686-5.312,1.029-8.015,1.081c-2.641,0.052-5.26-0.187-7.786-1.06
			c-0.655-0.229-1.289-0.499-1.923-0.78c-2.952-1.31-4.293-4.013-3.992-7.298c0.135-1.528,0.603-2.984,1.31-4.345
			c0.509-0.988,1.102-1.923,1.778-2.807c1.643-2.173,3.607-4.023,5.822-5.593c1.528-1.092,3.129-2.038,4.792-2.9
			c2.339-1.206,4.709-2.349,7.132-3.389c1.975-0.852,3.971-1.611,6.082-2.09c0.447-0.104,0.447-0.114,1.06-0.655
			c-0.146-1.227-0.499-2.412-1.06-3.524c-0.561-1.112-1.154-2.214-1.798-3.275c-0.613-1.008-1.289-1.975-1.986-2.921
			c-1.518-2.069-2.9-4.231-4.096-6.497c-0.218-0.416-0.343-0.894-0.801-1.144c-0.322,0.198-0.447,0.52-0.572,0.842
			c-1.102,2.62-2.173,5.25-3.316,7.849c-2.817,6.414-5.988,12.641-9.647,18.619c-2.287,3.732-4.844,7.256-7.901,10.406
			c-2.433,2.505-5.136,4.647-8.254,6.237c-1.611,0.821-3.285,1.476-5.073,1.788c-2.641,0.457-5.239,0.301-7.786-0.551
			c-2.287-0.769-3.94-2.256-5.011-4.397c-0.811-1.622-1.31-3.347-1.632-5.125c-0.613-3.441-0.541-6.903-0.281-10.365
			c0.125-1.653,0.374-3.306,0.593-4.948c0.655-4.813,1.674-9.543,2.828-14.253c1.414-5.79,3.192-11.467,4.907-17.174
			c0.582-1.923,1.164-3.846,1.684-5.78c0.395-1.466,0.728-2.963,0.707-4.501c-0.01-0.832-0.021-1.663-0.114-2.485
			c-0.125-1.05-0.551-1.996-1.268-2.796c-0.904-0.988-2.038-1.445-3.368-1.258c-1.299,0.177-2.599,0.437-3.857,1.133
			c-0.187,0.364-0.447,0.769-0.634,1.206c-1.663,3.805-3.306,7.62-4.969,11.435c-1.279,2.921-2.578,5.822-3.867,8.743
			c-0.333,0.759-0.645,1.528-0.946,2.308c-2.318,6.102-5.104,11.976-8.431,17.59c-1.705,2.869-3.597,5.593-5.801,8.098
			c-1.986,2.256-4.231,4.19-6.861,5.655c-1.216,0.676-2.474,1.227-3.836,1.549c-1.507,0.353-3.015,0.426-4.533,0.218
			c-0.27-0.042-0.541-0.073-0.717-0.104c-0.447,0.146-0.634,0.447-0.852,0.717c-1.497,1.809-2.984,3.649-4.522,5.427
			c-1.965,2.256-4.169,4.252-6.622,5.967c-2.287,1.601-4.74,2.838-7.423,3.649c-2.703,0.811-5.447,0.956-8.213,0.426
			c-0.748-0.146-1.497-0.301-2.225-0.509c-3.119-0.884-5.583-2.703-7.443-5.343c-1.736-2.464-2.973-5.156-3.774-8.046
			c-0.645-2.349-1.029-4.74-1.258-7.152c-0.042-0.405-0.083-0.811-0.125-1.237c-0.187,0.073-0.364,0.094-0.416,0.177
			c-0.26,0.405-0.499,0.821-0.728,1.258c-1.175,2.204-2.381,4.377-3.742,6.477c-1.58,2.453-3.285,4.803-5.26,6.955
			c-1.736,1.902-3.659,3.597-5.832,4.969c-1.539,0.967-3.16,1.726-4.948,2.079c-2.568,0.509-5.084,0.416-7.579-0.457
			c-1.819-0.645-3.306-1.726-4.501-3.223c-1.133-1.424-1.986-3.004-2.62-4.699c-0.603-1.632-1.008-3.316-1.237-5.032
			s-0.478-3.431-0.447-5.177c0-0.198-0.042-0.405-0.062-0.572c-0.104-0.156-0.249-0.177-0.343-0.042
			c-0.312,0.457-0.582,0.936-0.873,1.414c-1.123,1.83-2.225,3.68-3.379,5.479c-1.466,2.277-3.14,4.397-4.98,6.383
			c-1.32,1.435-2.776,2.713-4.418,3.763c-0.697,0.447-1.424,0.863-2.162,1.237c-2.297,1.196-4.751,1.424-7.277,1.06
			c-0.686-0.094-1.362-0.26-2.027-0.468c-2.121-0.634-3.857-1.84-5.229-3.576c-1.268-1.611-2.069-3.441-2.485-5.427
			c-0.457-2.183-0.551-4.397-0.26-6.622c0.218-1.715,0.457-3.431,0.759-5.136c0.956-5.312,1.996-10.614,3.129-15.895
			s2.339-10.541,3.628-15.791c0.936-3.836,1.902-7.662,2.859-11.487c0.374-1.476,0.707-2.963,0.8-4.491
			c0.042-0.624,0.062-1.247-0.166-1.85c-0.135-0.374-0.645-0.686-0.988-0.541c-0.447,0.187-0.904,0.364-1.299,0.634
			c-0.634,0.426-1.237,0.884-1.819,1.383c-2.433,2.069-4.626,4.387-6.612,6.892c-2.287,2.88-4.397,5.884-6.341,9.003
			c-3.327,5.354-6.591,10.749-9.886,16.124c-0.655,1.06-1.32,2.11-2.006,3.16c-0.572,0.873-1.019,1.809-1.393,2.776
			c-1.601,4.148-2.807,8.4-3.732,12.745c-0.218,1.019-0.374,2.048-0.582,3.056c-0.301,1.435-0.426,2.88-0.395,4.335
			c0.021,0.832-0.01,1.653-0.094,2.485c-0.073,0.759-0.052,1.528-0.01,2.277c0.052,0.832,0.156,1.653,0.27,2.474
			c0.062,0.478,0.229,0.936,0.301,1.414c0.062,0.405,0.114,0.821,0.104,1.237c0,0.447-0.26,0.78-0.665,0.977
			c-0.447,0.208-0.915,0.322-1.403,0.333c-1.185,0.031-2.349-0.177-3.483-0.52c-1.008-0.301-1.882-0.863-2.641-1.591
			c-1.019-0.988-1.757-2.142-2.204-3.483c-0.468-1.383-0.8-2.796-0.873-4.262c-0.208-4.023-0.229-8.036,0.322-12.028
			c0.042-0.343,0.114-0.686-0.052-1.008c-0.312,0.052-0.457,0.291-0.613,0.52c-0.551,0.8-1.071,1.611-1.622,2.412
			c-2.256,3.254-4.782,6.279-7.61,9.055c-2.786,2.734-5.863,5.073-9.252,6.996c-2.495,1.414-5.125,2.464-7.942,2.952
			c-2.318,0.405-4.657,0.665-7.028,0.353c-1.372-0.187-2.744-0.333-4.075-0.748c-3.763-1.175-6.788-3.389-9.128-6.549
			c-2.006-2.713-3.295-5.759-4.075-9.024c-0.8-3.316-1.081-6.695-0.904-10.105c0.01-0.208,0.052-0.416,0.031-0.624
			c-0.156-2.734,0.457-5.385,0.988-8.015c1.549-7.641,4.148-14.939,7.776-21.852c2.225-4.241,4.813-8.254,7.942-11.903
			c2.308-2.693,4.876-5.115,7.766-7.183c2.505-1.798,5.229-3.119,8.265-3.753c2.391-0.509,4.803-0.624,7.225-0.166
			c2.256,0.426,4.148,1.518,5.697,3.202c1.144,1.247,1.954,2.682,2.537,4.262c0.707,1.902,1.05,3.888,1.154,5.894
			c0.198,3.618-0.146,7.204-1.081,10.708c-0.769,2.859-2.152,5.385-4.086,7.631c-2.1,2.433-4.501,4.501-7.246,6.175
			c-3.639,2.214-7.537,3.794-11.643,4.886c-2.204,0.593-4.439,1.092-6.716,1.362c-0.613,0.073-1.227,0.166-1.892,0.405
			c-0.104,0.198-0.281,0.426-0.343,0.686c-0.645,2.557-1.164,5.125-1.445,7.755c-0.291,2.63-0.364,5.25-0.333,7.88
			c0.031,1.746,0.333,3.451,0.842,5.115c0.426,1.403,1.081,2.693,1.954,3.878c1.268,1.726,2.973,2.651,5.073,2.911
			c2.973,0.374,5.79-0.125,8.452-1.528c2.401-1.268,4.605-2.828,6.633-4.626c3.015-2.682,5.645-5.718,8.005-8.982
			c3.451-4.782,6.508-9.814,9.325-14.991c0.437-0.8,0.655-1.663,0.863-2.547c0.665-2.828,1.32-5.666,2.006-8.493
			c1.331-5.52,2.682-11.03,4.002-16.56c0.468-1.954,0.873-3.919,1.299-5.884c0.166-0.748,0.301-1.487,0.125-2.381
			c-0.499-0.624-1.06-1.32-1.632-2.017c-0.447-0.541-0.8-1.123-1.029-1.788c-0.198-0.572-0.083-1.092,0.27-1.57
			c0.343-0.468,0.8-0.79,1.341-0.936c0.728-0.208,1.476-0.374,2.225-0.489c0.821-0.125,1.653-0.156,2.485-0.229
			c1.466-0.125,2.911,0.052,4.345,0.301c0.676,0.114,1.341,0.374,1.954,0.665c2.516,1.206,3.898,3.243,4.252,5.988
			c0.177,1.383,0.146,2.776-0.094,4.148c-0.322,1.84-0.655,3.691-1.195,5.479c-0.915,2.984-1.601,6.019-2.329,9.055
			c-0.27,1.144-0.561,2.287-0.832,3.431c-0.062,0.26-0.146,0.541,0.021,0.811c0.104-0.042,0.239-0.062,0.291-0.135
			c0.291-0.385,0.561-0.79,0.842-1.185c1.466-2.11,2.921-4.21,4.481-6.248c1.601-2.09,3.16-4.21,4.72-6.331
			c1.975-2.682,4.086-5.25,6.383-7.662c2.11-2.214,4.418-4.189,7.017-5.832c1.414-0.894,2.911-1.591,4.543-2.006
			c2.682-0.676,5.364-0.676,7.994,0.177c3.233,1.04,5.188,3.306,6.03,6.581c0.561,2.214,0.395,4.408-0.208,6.57
			c-0.665,2.401-1.372,4.792-2.09,7.183c-2.246,7.568-4.543,15.115-6.622,22.736c-1.32,4.813-2.516,9.647-3.41,14.554
			c-0.551,2.994-1.05,6.009-1.06,9.076c0,0.759,0.042,1.528,0.083,2.287c0.031,0.499,0.218,0.946,0.447,1.372
			c0.343,0.603,1.112,0.925,1.788,0.707c0.322-0.104,0.645-0.25,0.946-0.416c0.665-0.374,1.227-0.894,1.767-1.435
			c1.133-1.133,2.11-2.381,3.067-3.659c1.746-2.329,3.275-4.803,4.792-7.298c2.162-3.555,4.169-7.194,6.175-10.832
			c0.333-0.613,0.572-1.247,0.697-1.944c0.301-1.778,0.645-3.545,0.977-5.312c0.78-4.221,1.83-8.389,2.869-12.558
			c1.507-6.05,3.243-12.028,4.886-18.047c0.343-1.268,0.697-2.537,0.998-3.815c0.218-0.946,0.78-1.591,1.57-2.1
			c0.769-0.489,1.591-0.852,2.443-1.123c1.247-0.405,2.526-0.717,3.836-0.904c1.653-0.239,3.295-0.405,4.969-0.364
			c0.904,0.031,1.788,0.187,2.661,0.405c0.478,0.114,0.894,0.353,1.258,0.697c0.353,0.333,0.457,0.936,0.177,1.331
			c-0.239,0.333-0.489,0.676-0.8,0.946c-0.863,0.748-1.424,1.695-1.85,2.734c-0.468,1.154-0.904,2.318-1.299,3.503
			c-1.507,4.47-2.672,9.024-3.711,13.618c-1.31,5.738-2.433,11.519-3.41,17.319c-0.634,3.753-1.279,7.506-1.861,11.269
			c-0.405,2.599-0.665,5.229-0.842,7.849c-0.166,2.568,0.031,5.125,0.676,7.63c0.156,0.603,0.353,1.196,0.634,1.746
			c0.509,1.008,1.383,1.518,2.505,1.601c0.561,0.042,1.102-0.031,1.643-0.208c1.071-0.353,1.996-0.956,2.828-1.695
			c1.144-1.019,2.173-2.142,3.129-3.327c1.258-1.57,2.422-3.212,3.493-4.907c1.923-3.046,3.69-6.185,5.312-9.408
			c0.936-1.85,1.819-3.742,2.755-5.593c0.509-1.008,0.811-2.048,0.977-3.16c0.603-4.044,1.476-8.036,2.589-11.976
			c1.539-5.427,3.68-10.593,6.612-15.417c1.882-3.088,3.992-5.998,6.581-8.545c1.185-1.164,2.443-2.256,3.774-3.254
			c3.212-2.412,6.778-4.002,10.697-4.855c1.559-0.343,3.14-0.541,4.73-0.676c2.37-0.208,4.709,0.073,6.996,0.697
			c2.765,0.759,5.021,2.266,6.788,4.533c1.643,2.11,2.796,4.46,3.524,7.007c0.499,1.736,0.811,3.503,1.029,5.291
			c0.405,3.389,0.385,6.768,0.062,10.167c-0.187,2.006-0.374,4.002-0.665,5.988c-0.312,2.121-0.728,4.231-1.185,6.331
			c-1.195,5.416-2.817,10.708-4.938,15.843c-0.343,0.832-0.728,1.643-1.081,2.474c-0.166,0.374-0.405,0.728-0.405,1.164
			c0.094,0.083,0.187,0.229,0.291,0.25c0.832,0.146,1.653,0.062,2.443-0.26c0.78-0.312,1.487-0.738,2.152-1.237
			c1.227-0.915,2.318-1.975,3.306-3.15c1.975-2.339,3.659-4.876,5.198-7.516c3.472-5.926,6.508-12.07,9.231-18.369
			c0.634-1.466,1.227-2.942,1.85-4.408c0.374-0.894,0.728-1.798,1.31-2.589c0.239-0.333,0.416-0.717,0.561-1.102
			c1.507-3.95,2.994-7.901,4.481-11.851c0.052-0.125,0.073-0.27,0.104-0.395c-0.062-0.322-0.281-0.468-0.572-0.52
			c-1.164-0.187-2.329-0.301-3.472-0.593c-1.414-0.364-2.765-0.873-4.034-1.622c-2.09-1.237-3.368-3.046-3.836-5.416
			c-0.114-0.541-0.208-1.092-0.301-1.632c-0.135-0.832-0.125-1.653,0.021-2.485c0.301-1.705,0.686-3.389,1.216-5.042
			c0.759-2.381,1.695-4.688,2.838-6.913c1.206-2.36,2.713-4.512,4.605-6.373c0.79-0.78,1.653-1.476,2.568-2.11
			c2.776-1.923,5.842-2.422,9.117-1.892c0.821,0.135,1.632,0.312,2.391,0.676c1.164,0.551,2.11,1.331,2.755,2.443
			c0.707,1.206,1.195,2.485,1.424,3.878c0.229,1.445,0.249,2.88,0.135,4.345c-0.322,3.919-1.507,7.589-2.911,11.217
			c-0.27,0.707-0.603,1.403-0.884,2.11c-0.125,0.312-0.312,0.624-0.26,0.988c0.094,0.094,0.177,0.239,0.281,0.26
			c1.965,0.426,3.971,0.624,5.957,0.863c1.861,0.218,3.701,0.541,5.52,0.956c1.414,0.333,2.776,0.842,4.096,1.476
			c3.431,1.663,5.198,4.429,5.531,8.181c0.27,3.088-0.166,6.092-1.092,9.024c-0.769,2.443-1.632,4.855-2.485,7.277
			c-1.819,5.156-3.732,10.281-5.385,15.5c-1.237,3.888-2.453,7.797-3.337,11.789c-0.603,2.703-1.05,5.437-1.351,8.192
			c-0.218,2.069-0.27,4.158,0.26,6.196c0.156,0.603,0.343,1.196,0.603,1.767c0.395,0.873,1.268,1.289,2.235,1.123
			c1.175-0.187,2.287-0.603,3.254-1.31c0.894-0.655,1.757-1.351,2.568-2.1c1.58-1.466,2.952-3.119,4.262-4.824
			c1.986-2.589,3.784-5.302,5.416-8.119c4.751-8.223,9.034-16.685,12.6-25.501c0.26-0.645,0.499-1.289,0.759-1.944
			c-0.218-0.603-0.426-1.196-0.655-1.767c-0.852-2.204-1.549-4.439-2.069-6.747c-0.759-3.347-0.915-6.726-0.603-10.125
			c0.239-2.651,1.05-5.136,2.433-7.443c3.098-5.146,7.651-8.285,13.411-9.751c0.873-0.218,1.767-0.333,2.661-0.426
			c1.175-0.125,2.349-0.021,3.503,0.291c1.996,0.541,3.223,1.83,3.68,3.826c0.229,1.008,0.301,2.048,0.198,3.098
			c-0.25,2.495-0.894,4.896-1.705,7.267c-1.289,3.742-2.859,7.36-4.553,10.926c-0.624,1.31-1.268,2.62-1.902,3.93
			c-0.146,0.312-0.27,0.634-0.353,0.832c0.01,0.208,0.01,0.281,0.021,0.343c0.01,0.073,0.021,0.146,0.052,0.198
			c4.377,7.485,7.859,15.417,11.56,23.245c0.031,0.062,0.083,0.104,0.135,0.156c0.052,0.042,0.104,0.083,0.166,0.114
			c0.135-0.042,0.27-0.062,0.395-0.125c3.056-1.643,6.019-3.431,8.743-5.583c4.626-3.649,8.4-8.036,11.363-13.119
			c0.416-0.717,0.811-1.455,1.237-2.162c0.177-0.291,0.395-0.561,0.634-0.811c0.333-0.353,0.925-0.416,1.341-0.125
			c0.27,0.198,0.561,0.437,0.717,0.717C520.018,224.932,520.216,226.2,519.758,227.531L519.758,227.531z M480.244,258.344
			c-0.541,0.062-1.092,0.156-1.622,0.312c-5.146,1.601-9.834,4.086-14.138,7.287c-0.665,0.499-1.289,1.05-1.882,1.632
			c-0.582,0.582-1.123,1.227-1.643,1.871c-0.572,0.717-0.915,1.549-1.123,2.433c-0.208,0.904-0.114,1.788,0.364,2.609
			c1.133,1.934,3.316,2.932,5.499,2.485c1.227-0.25,2.381-0.717,3.472-1.331c1.279-0.717,2.443-1.58,3.514-2.568
			c2.474-2.266,4.46-4.928,5.978-7.911c1.04-2.048,1.778-4.2,2.058-6.549C480.566,258.521,480.389,258.323,480.244,258.344
			L480.244,258.344z M203.768,237.501c1.861-0.146,3.722-0.395,5.551-0.769c3.14-0.624,6.144-1.632,8.972-3.171
			c2.339-1.279,4.397-2.89,6.134-4.896c2.266-2.62,3.441-5.645,3.399-8.878c-0.031-0.593-0.052-0.936-0.062-1.289
			c-0.042-1.466-0.322-2.89-0.842-4.262c-0.343-0.925-0.821-1.757-1.518-2.453c-0.842-0.852-1.882-1.133-3.046-0.967
			c-1.123,0.156-2.121,0.613-3.046,1.258c-0.967,0.676-1.809,1.507-2.599,2.381c-2.277,2.537-4.242,5.291-6.04,8.181
			c-2.828,4.543-5.052,9.387-7.36,14.19C203.144,237.199,203.362,237.532,203.768,237.501L203.768,237.501z M365.142,216.408
			c-0.062,0.083-0.135,0.156-0.177,0.239c-0.021,0.031-0.021,0.073-0.021,0.104c0.083-0.052,0.166-0.104,0.239-0.166
			C365.204,216.563,365.152,216.47,365.142,216.408L365.142,216.408z M363.738,236.388c-0.447-4.834-0.416-9.678,0.343-14.502
			c0.198-1.227,0.468-2.443,0.717-3.659c0.094-0.457,0.198-0.904,0.094-1.362c-0.457,0.239-0.551,0.749-0.738,1.154
			c-1.331,2.973-2.422,6.03-3.368,9.148c-1.539,5.032-2.734,10.157-3.67,15.334c-0.52,2.859-0.977,5.728-1.237,8.629
			c-0.333,3.794-0.52,7.599-0.114,11.404c0.208,2.006,0.624,3.971,1.331,5.853c0.27,0.707,0.613,1.393,0.998,2.048
			c1.466,2.443,4.439,3.16,6.477,2.682c1.019-0.239,1.996-0.582,2.921-1.05c2.88-1.466,5.343-3.441,7.402-5.936
			c0.572-0.697,1.081-1.445,1.403-2.297c0.073-0.187,0.073-0.395,0.104-0.561c-0.291-0.385-0.717-0.478-1.081-0.665
			c-0.686-0.333-1.331-0.749-1.934-1.216c-0.769-0.603-1.435-1.299-2.038-2.058c-1.694-2.131-2.963-4.501-3.971-7.028
			C365.339,247.2,364.237,241.856,363.738,236.388L363.738,236.388z M390.393,227.011c0.353-3.192,0.374-6.352-0.073-9.533
			c-0.229-1.591-0.634-3.119-1.258-4.595c-0.353-0.832-0.8-1.611-1.31-2.36c-1.019-1.476-2.37-2.516-4.086-3.046
			c-1.133-0.353-2.297-0.572-3.472-0.551c-2.256,0.031-4.19,0.842-5.666,2.599c-0.541,0.634-0.977,1.341-1.372,2.079
			c-0.655,1.227-1.175,2.505-1.611,3.826c-1.289,3.826-1.975,7.786-2.391,11.789c-0.353,3.441-0.457,6.913-0.114,10.375
			c0.291,2.973,0.8,5.905,1.757,8.743c0.749,2.245,1.767,4.356,3.108,6.321c1.57,2.318,3.701,3.909,6.341,4.813
			c0.187,0.062,0.405,0.01,0.582,0.01c0.333-0.198,0.499-0.509,0.655-0.811c0.572-1.102,1.175-2.204,1.684-3.337
			C386.962,244.944,389.364,236.17,390.393,227.011L390.393,227.011z M427.267,180.989c-0.509-1.06-1.403-1.455-2.578-1.123
			c-0.198,0.052-0.395,0.146-0.561,0.27c-0.499,0.364-1.019,0.717-1.476,1.133c-0.821,0.748-1.466,1.653-2.058,2.589
			c-1.362,2.173-2.443,4.481-3.316,6.892c-0.468,1.299-0.79,2.641-1.071,3.992c-0.094,0.468-0.114,0.956-0.135,1.435
			c-0.021,0.582,0.208,1.081,0.613,1.497c0.447,0.457,0.988,0.759,1.622,0.842c0.769,0.104,1.507,0.021,2.235-0.281
			c0.717-0.291,1.351-0.686,2.017-1.403c1.112-2.755,2.235-5.499,3.337-8.254c0.312-0.769,0.572-1.559,0.842-2.339
			c0.374-1.112,0.603-2.266,0.79-3.431C427.641,182.164,427.537,181.55,427.267,180.989L427.267,180.989z M484.995,205.773
			c0.437-1.237,0.78-2.505,1.144-3.774c0.25-0.873,0.322-1.767,0.177-2.672c-0.052-0.343-0.166-0.665-0.416-0.936
			c-0.114-0.125-0.437-0.239-0.561-0.156c-0.229,0.146-0.478,0.281-0.676,0.468c-0.665,0.624-1.144,1.383-1.601,2.162
			c-1.497,2.599-2.568,5.364-3.316,8.265c-0.385,1.476-0.551,2.994-0.499,4.522c0.031,0.977,0.198,1.923,0.53,2.838
			c0.052,0.146,0.281,0.229,0.426,0.343c0.561-0.53,0.811-1.185,1.081-1.819C482.635,211.979,483.893,208.902,484.995,205.773
			L484.995,205.773z M197.094,168.868c-0.426,4.429-1.299,8.764-2.703,12.984c-2.266,6.84-6.03,12.745-11.227,17.725
			c-4.439,4.252-9.616,7.204-15.604,8.701c-2.09,0.53-4.21,0.832-6.352,0.967c-3.171,0.208-6.196-0.499-9.107-1.726
			c-3.025-1.289-5.749-3.036-8.015-5.458c-0.146-0.156-0.291-0.291-0.447-0.437c-0.052-0.052-0.114-0.073-0.198-0.125
			c-0.146,0.125-0.353,0.229-0.437,0.395c-1.632,3.056-4.034,5.437-6.716,7.558c-1.539,1.216-3.243,2.11-5.073,2.786
			c-0.52,0.187-1.029,0.395-1.528,0.624c-0.094,0.042-0.187,0.239-0.156,0.343c0.062,0.26,0.166,0.53,0.301,0.759
			c1.008,1.674,2.225,3.171,3.576,4.574c1.778,1.85,3.659,3.597,5.624,5.239c3.566,2.984,7.277,5.759,11.02,8.504
			c2.121,1.559,4.2,3.171,6.165,4.928c2.703,2.401,5.156,5.032,7.277,7.963c1.632,2.245,3.025,4.637,4.096,7.204
			c1.528,3.639,2.162,7.423,2.027,11.363c-0.146,4.221-1.206,8.202-2.921,12.038c-2.911,6.529-7.287,11.903-12.922,16.259
			c-4.896,3.784-10.375,6.352-16.342,7.901c-2.817,0.728-5.676,1.175-8.597,1.393c-2.994,0.229-5.957,0.052-8.92-0.187
			c-2.069-0.166-4.127-0.499-6.113-1.144c-0.655-0.218-1.32-0.437-1.954-0.707c-5.198-2.173-8.691-6.892-9.408-12.506
			c-0.374-2.942-0.104-5.811,0.655-8.639c1.528-5.707,4.501-10.531,9.003-14.398c1.476-1.268,2.994-2.474,4.678-3.441
			c3.15-1.819,6.539-2.599,10.167-2.36c1.196,0.083,2.318,0.416,3.389,0.915c0.697,0.322,1.31,0.78,1.788,1.393
			c0.759,0.967,0.738,1.892-0.052,2.828c-0.603,0.717-1.341,1.196-2.318,1.216c-0.624,0.021-1.247,0.042-1.871,0.083
			c-1.102,0.073-2.214,0.146-3.316,0.25c-1.622,0.156-3.088,0.728-4.429,1.653c-0.114,0.083-0.239,0.146-0.343,0.239
			c-3.046,2.318-5.822,4.917-8.057,8.046c-1.507,2.1-2.641,4.397-3.171,6.944c-0.821,3.971,0.135,7.412,3.171,10.188
			c1.185,1.081,2.537,1.923,4.013,2.557c2.256,0.956,4.605,1.476,7.048,1.57c6.362,0.26,12.007-1.715,17.028-5.52
			c4.262-3.233,7.599-7.267,9.97-12.07c1.663-3.368,2.89-6.892,3.659-10.573c0.749-3.576,0.759-7.142-0.062-10.718
			c-1.112-4.865-3.68-8.774-7.589-11.82c-1.144-0.894-2.37-1.684-3.607-2.453c-3.483-2.142-6.913-4.356-10.229-6.757
			c-3.878-2.807-7.579-5.832-10.936-9.252c-2.578-2.62-4.917-5.447-7.142-8.379c-0.333-0.437-0.686-0.863-1.008-1.279
			c-0.811-0.146-1.559-0.26-2.297-0.426c-3.68-0.863-7.059-2.433-10.177-4.564c-1.497-1.019-2.796-2.256-3.878-3.722
			c-1.112-1.507-2.214-3.025-3.077-4.678c-0.645-1.227-1.247-2.474-1.455-3.867c-0.042-0.27-0.031-0.551-0.021-0.821
			c0.021-0.385,0.541-0.717,0.915-0.624c0.613,0.156,1.164,0.447,1.684,0.8c0.925,0.624,1.767,1.341,2.516,2.162
			c1.164,1.279,2.287,2.599,3.441,3.888c0.645,0.717,1.289,1.455,2.006,2.1c2.162,1.965,4.772,3.015,7.558,3.722
			c0.26,0.062,0.541,0.031,0.852,0.042c0.073-0.208,0.208-0.405,0.187-0.572c-0.125-0.821-0.291-1.632-0.426-2.453
			c-0.78-4.46-0.894-8.94-0.447-13.452c0.437-4.429,1.289-8.764,2.599-13.016c3.212-10.448,8.618-19.617,16.228-27.466
			c5.998-6.144,12.964-10.822,20.999-13.878c3.711-1.414,7.547-2.349,11.487-2.672c3.451-0.291,6.913-0.27,10.375,0.187
			c2.62,0.353,5.188,0.884,7.672,1.757c3.098,1.081,5.967,2.589,8.514,4.678c3.982,3.275,6.612,7.423,7.963,12.371
			C197.187,160.561,197.499,164.678,197.094,168.868L197.094,168.868z M183.6,164.73c-0.094-2.776-0.541-5.52-1.299-8.192
			c-0.884-3.098-2.329-5.915-4.449-8.379c-1.289-1.497-2.817-2.661-4.637-3.431c-1.476-0.624-3.015-0.998-4.616-1.123
			c-3.233-0.25-6.352,0.281-9.356,1.466c-2.859,1.123-5.489,2.641-7.953,4.449c-3.649,2.682-6.84,5.853-9.626,9.419
			c-8.504,10.895-13.629,23.214-15.542,36.895c-0.343,2.422-0.374,4.834-0.229,7.256c0.104,1.726,0.343,3.451,0.79,5.125
			c0.052,0.187,0.187,0.343,0.27,0.499c0.114,0.021,0.198,0.062,0.25,0.042c2.412-0.78,4.553-2.017,6.383-3.763
			c0.8-0.759,1.57-1.57,2.266-2.433c0.873-1.071,1.663-2.214,2.464-3.347c0.759-1.071,1.476-2.183,2.214-3.264
			c0.52-0.748,1.185-1.341,2.017-1.746c0.728-0.353,1.466-0.437,2.194-0.052c0.676,0.353,1.331,0.728,1.965,1.154
			c1.268,0.852,2.505,1.736,3.753,2.609c1.539,1.071,3.14,2.027,4.803,2.89c1.632,0.842,3.379,1.279,5.198,1.268
			c1.798-0.01,3.587-0.27,5.302-0.873c2.401-0.832,4.46-2.193,6.289-3.93c3.212-3.046,5.624-6.664,7.412-10.687
			C182.571,179.627,183.87,172.329,183.6,164.73L183.6,164.73z M341.034,177.839c-0.27,1.008-0.645,1.975-1.133,2.9
			c-1.684,3.212-3.805,6.113-6.456,8.597c-0.759,0.707-1.591,1.341-2.412,1.965c-0.717,0.541-1.539,0.915-2.401,1.196
			c-1.362,0.447-2.547-0.26-3.15-1.008c-0.395-0.489-0.676-1.04-0.884-1.643c-0.447-1.32-0.697-2.672-0.78-4.065
			c-0.021-0.343,0-0.697,0-1.04c0.062-3.077,0.697-6.04,1.996-8.847c0.437-0.946,0.998-1.819,1.726-2.578
			c0.946-0.977,2.1-1.57,3.431-1.819c0.541-0.104,1.092-0.198,1.643-0.218c2.183-0.083,4.283,0.26,6.196,1.372
			c0.478,0.27,0.946,0.593,1.351,0.956c0.697,0.624,1.081,1.424,1.092,2.381C341.252,176.623,341.19,177.236,341.034,177.839
			L341.034,177.839z M365.183,216.584c-0.073,0.062-0.156,0.114-0.239,0.166c0-0.042,0.01-0.073,0.021-0.104
			c0.042-0.083,0.114-0.156,0.177-0.239C365.152,216.47,365.204,216.563,365.183,216.584L365.183,216.584z M364.882,216.792
			c0.021-0.01,0.042-0.031,0.062-0.042c0,0.031,0,0.062-0.01,0.094L364.882,216.792L364.882,216.792z"
          />
        </g>
      </g>
      <text
        transform="matrix(0.7644 0.21 -0.2649 0.9643 422.4429 448.1714)"
        font-family="'TypoUprightBT-Regular'"
        font-size="420.508"
      >
        1
      </text>
    </svg>
  );
}

Artwrk21.props = {
  primary: string,
};

Artwrk21.defaultProps = {
  primary: "#000",
};
