import React from "react";
import { string } from "prop-types";

export default function Artwrk12(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      fill={primary}
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="Shape_1_copy_7_1_" enable-background="new    ">
        <g id="Shape_1_copy_7">
          <path
            d="M423.74,659.183c-0.014,0.257-0.149,0.5-0.284,0.945c-0.527,0.04-0.891,0.095-1.243,0.108c-4.592,0-9.185,0-13.777,0
			c-0.176,0-0.365,0-0.54-0.014c-0.675-0.027-1.04-0.338-1.054-0.945c-0.027-0.81-0.027-1.621-0.027-2.431
			c0-8.01,0-16.019-0.014-24.029c0-0.986,0.108-1.972-0.149-2.999c-0.216-0.095-0.446-0.297-0.689-0.297
			c-3.242-0.108-6.47-0.054-9.63-0.04c-0.216,0.189-0.351,0.311-0.5,0.432c-0.216,0.973-0.122,1.972-0.122,2.958
			c-0.014,8.01,0,16.019,0,24.029c0,0.81,0,1.621-0.041,2.431c-0.014,0.243-0.189,0.486-0.284,0.743
			c-0.284,0.068-0.54,0.149-0.797,0.162c-3.498,0.122-13.588,0.04-15.438-0.122c-0.108-0.203-0.311-0.419-0.324-0.662
			c-0.068-0.716-0.068-1.432-0.068-2.161c0-24.218,0-48.422,0-72.64c0-0.716,0.027-1.432,0.054-2.161
			c0.014-0.324,0.365-0.702,0.675-0.743c0.176-0.027,0.351-0.081,0.527-0.081c4.862,0,9.725,0,14.574,0.013
			c0.365,0,0.702,0.135,0.959,0.459c0.284,0.851,0.149,1.756,0.149,2.647c0.014,8.185,0,16.384,0.014,24.569
			c0,0.986-0.081,1.972,0.135,2.999c0.243,0.108,0.473,0.311,0.702,0.324c3.242,0.108,6.47,0.054,9.63,0.04
			c0.216-0.189,0.351-0.311,0.5-0.432c0.243-0.959,0.135-1.958,0.135-2.944c0.014-8.185,0-16.384,0.014-24.569
			c0-0.891-0.135-1.796,0.176-2.715c0.216-0.108,0.446-0.284,0.689-0.311c0.446-0.068,0.891-0.068,1.337-0.068
			c4.228,0,8.455,0,12.697,0c0.446,0,0.905,0.014,1.337,0.122c0.216,0.054,0.419,0.297,0.567,0.486
			c0.095,0.135,0.108,0.338,0.122,0.513c0.014,0.716,0.027,1.445,0.027,2.161c0,23.948,0,47.882,0,71.83
			C423.781,657.562,423.781,658.373,423.74,659.183L423.74,659.183z M73.626,659.831c-0.23,0.122-0.446,0.243-0.675,0.351
			c-0.081,0.04-0.176,0.027-0.27,0.027c-4.863,0-9.725,0.013-14.588,0c-0.338,0-0.675-0.162-0.959-0.243
			c-0.392-0.959-0.257-1.877-0.257-2.769c-0.014-8.01,0-16.019,0-24.042c0-0.81,0.014-1.621-0.027-2.431
			c-0.014-0.351-0.135-0.689-0.189-0.986c-0.243-0.122-0.405-0.243-0.567-0.257c-1.661-0.135-8.428-0.054-10.022,0.108
			c-0.095,0.203-0.297,0.419-0.311,0.662c-0.054,0.891-0.054,1.796-0.054,2.701c0,8.01,0,16.019,0,24.042
			c0,0.81-0.014,1.621-0.068,2.431c-0.014,0.243-0.23,0.459-0.351,0.716c-0.473,0.027-0.918,0.081-1.364,0.081
			c-4.417,0-8.82,0-13.237,0c-0.446,0-0.891-0.068-1.324-0.149c-0.149-0.027-0.311-0.203-0.365-0.351
			c-0.095-0.243-0.149-0.527-0.149-0.783c-0.027-0.716-0.014-1.445-0.014-2.161c0-23.948,0-47.896,0-71.843
			c0-0.81,0.027-1.621,0.054-2.431c0.014-0.432,0.432-0.783,0.918-0.824c0.27-0.014,0.54-0.041,0.81-0.041c4.417,0,8.82,0,13.237,0
			c0.527,0,1.094-0.068,1.81,0.5c0.014,0.891,0.054,1.783,0.054,2.674c0,8.28,0,16.56,0,24.853c0,0.891-0.081,1.796,0.135,2.701
			c0.27,0.108,0.5,0.297,0.743,0.297c3.066,0.027,6.119,0.027,9.185,0.027c0.257,0,0.513-0.108,0.851-0.176
			c0.081-0.338,0.216-0.675,0.243-1.027c0.041-0.81,0.027-1.621,0.027-2.431c0-8.28,0-16.56,0-24.853c0-2.512,0-2.593,2.499-2.58
			c4.147,0.013,8.28,0,12.426,0.013c0.621,0,1.27-0.081,1.783,0.419c0.054,0.176,0.149,0.338,0.149,0.513
			c0.014,25.474,0.014,50.948,0.027,76.436C73.748,659.264,73.68,659.521,73.626,659.831L73.626,659.831z M217.34,659.845
			c-0.23,0.122-0.446,0.257-0.689,0.338c-0.162,0.054-0.351,0.04-0.54,0.04c-4.687,0-9.36,0-14.047,0c-0.446,0-0.905,0-1.27-0.405
			c-0.311-0.837-0.189-1.742-0.189-2.634c-0.014-8.104,0-16.208-0.014-24.313c0-0.81,0-1.621-0.041-2.431
			c-0.014-0.257-0.149-0.5-0.216-0.729c-0.203-0.095-0.365-0.216-0.527-0.23c-1.756-0.122-8.428-0.041-10.036,0.108
			c-0.095,0.203-0.297,0.419-0.311,0.662c-0.054,0.891-0.054,1.796-0.054,2.701c0,8.01,0,16.019,0,24.042
			c0,0.81-0.014,1.621-0.068,2.431c-0.014,0.243-0.23,0.459-0.351,0.716c-0.473,0.027-0.918,0.081-1.364,0.081
			c-4.417,0-8.82,0.014-13.237,0c-0.446,0-0.891-0.068-1.324-0.149c-0.149-0.027-0.311-0.203-0.365-0.351
			c-0.095-0.243-0.135-0.527-0.149-0.783c-0.027-0.716-0.027-1.445-0.027-2.161c0-23.948,0-47.896,0-71.843
			c0-0.81,0.027-1.621,0.054-2.431c0.014-0.432,0.432-0.783,0.918-0.81c0.365-0.027,0.716-0.041,1.081-0.041
			c4.228,0,8.469,0,12.697,0c0.621,0,1.27-0.095,2.067,0.486c0.027,0.919,0.054,1.81,0.054,2.701c0,8.28,0,16.56,0.014,24.853
			c0,0.891-0.135,1.796,0.189,2.715c0.216,0.095,0.459,0.27,0.702,0.27c3.066,0.027,6.119,0.027,9.185,0.027
			c0.257,0,0.513-0.108,0.837-0.189c0.081-0.338,0.216-0.675,0.243-1.027c0.041-0.81,0.027-1.621,0.027-2.431
			c0-8.28,0-16.56,0-24.853c0-2.512-0.014-2.58,2.512-2.58c4.147,0.013,8.28,0,12.426,0.013c0.621,0,1.27-0.081,1.783,0.419
			c0.054,0.176,0.149,0.351,0.149,0.513c0.014,25.474,0.014,50.962,0.014,76.436C217.475,659.278,217.394,659.534,217.34,659.845
			L217.34,659.845z M481.077,595.309c0.014,7.294,0,14.574,0,21.868c0,9.536,0,19.085,0,28.621c0,0.986-0.014,1.972-0.027,2.931
			c-1.121,1.526-2.512,2.674-3.755,3.971c-1.189,1.229-2.418,2.431-3.62,3.633c-1.27,1.27-2.553,2.526-3.836,3.809
			c-3.525,0.176-20.409,0.135-22.489-0.04c-0.23-0.189-0.527-0.405-0.783-0.648c-3.363-3.377-6.74-6.753-10.09-10.144
			c-0.122-0.122-0.189-0.311-0.297-0.513c-0.027-0.216-0.108-0.486-0.108-0.743c0-18.086-0.014-36.172,0-54.271
			c0-0.27,0.081-0.527,0.122-0.77c1.229-1.499,8.955-9.279,11.17-11.224c0.311-0.041,0.743-0.135,1.189-0.135
			c6.753-0.014,13.493,0,20.247,0c0.27,0,0.54,0.068,0.891,0.108c0.23,0.162,0.554,0.338,0.797,0.581
			c3.323,3.296,6.632,6.605,9.928,9.928c0.189,0.189,0.338,0.419,0.486,0.621C481.185,593.688,481.077,594.512,481.077,595.309
			L481.077,595.309z M464.004,599.131c-0.419-0.473-0.797-0.473-1.148-0.473c-2.877,0-5.754,0-8.631,0
			c-0.176,0-0.392-0.014-0.527,0.068c-0.216,0.122-0.513,0.297-0.554,0.486c-0.108,0.527-0.122,1.067-0.122,1.607
			c0,13.48,0,26.946,0,40.426c0,0.621,0.068,1.243,0.095,1.891c0.284,0.122,0.513,0.324,0.756,0.324
			c3.147,0.014,6.281,0.014,9.428-0.013c0.23,0,0.459-0.216,0.716-0.351c0.041-0.554,0.095-1.094,0.095-1.634
			C464.274,628.576,464.207,601.981,464.004,599.131L464.004,599.131z M538.374,648.04c0,0.27-0.068,0.527-0.108,0.756
			c-0.122,0.203-0.189,0.378-0.311,0.5c-3.363,3.377-6.726,6.767-10.09,10.144c-0.257,0.257-0.54,0.459-0.783,0.662
			c-2.566,0.216-10.238,0.27-20.841,0.135c-0.54,0-1.067-0.068-1.526-0.095c-1.769-1.567-9.698-9.495-11.13-11.13
			c-0.257-0.5-0.216-1.04-0.216-1.58c-0.014-0.905,0-1.796,0-2.701c0-16.195,0-32.403,0-48.598c0-1.067-0.108-2.161,0.149-3.161
			c1.418-1.688,9.374-9.644,11.184-11.184c0.338-0.041,0.783-0.122,1.229-0.122c6.564-0.013,13.142-0.013,19.707,0
			c0.446,0,0.891,0.068,1.364,0.108c1.445,1.081,2.607,2.472,3.89,3.714c1.229,1.189,2.418,2.418,3.633,3.62
			c1.27,1.27,2.553,2.526,3.687,3.714c0.068,0.459,0.149,0.729,0.149,0.999C538.374,611.868,538.374,629.954,538.374,648.04
			L538.374,648.04z M521.422,601.211c0-0.716-0.054-1.432-0.081-2.08c-0.216-0.176-0.351-0.284-0.54-0.432
			c-3.188,0-6.416-0.013-9.644,0.014c-0.23,0-0.473,0.203-0.77,0.338c-0.027,0.81-0.068,1.621-0.068,2.418
			c0,13.115,0,26.231,0,39.346c0,0.621,0,1.256,0.068,1.877c0.027,0.243,0.216,0.459,0.311,0.621
			c0.162,0.068,0.243,0.122,0.324,0.122c3.147,0.014,6.281,0.027,9.428,0.041c0.095,0,0.176-0.027,0.27-0.027
			c0.297-0.027,0.635-0.405,0.662-0.743c0.027-0.54,0.054-1.081,0.054-1.607C521.422,627.793,521.422,614.502,521.422,601.211
			L521.422,601.211z M160.044,609.288c-0.041,0.149-0.203,0.338-0.351,0.378c-0.338,0.108-0.702,0.176-1.054,0.176
			c-2.337,0.013-4.687,0.013-7.024,0.013c-2.337,0-4.687,0-7.024-0.013c-0.459,0-0.905-0.068-1.256-0.581
			c-0.243-1.594-0.068-3.309-0.108-5.011c-0.054-1.702,0.095-3.417-0.108-5.146c-0.23-0.135-0.446-0.378-0.662-0.392
			c-3.242-0.014-6.47,0-9.712,0.013c-0.081,0-0.149,0.068-0.5,0.243c-0.041,0.392-0.122,0.743-0.122,1.094
			c0.041,14.304-0.149,28.608,0.108,42.912c0,0.068,0.095,0.135,0.135,0.216c0.149,0.081,0.297,0.243,0.446,0.257
			c3.35,0.203,8.658,0.135,9.941-0.135c0.081-0.014,0.149-0.108,0.216-0.149c0.041-0.081,0.095-0.162,0.108-0.243
			c0.257-2.337,0.122-4.673,0.081-7.01c-0.014-0.432-0.446-0.824-0.891-0.837c-0.986-0.027-1.986-0.027-2.972-0.054
			c-0.351-0.013-0.729-0.013-1.054-0.135c-0.216-0.081-0.419-0.324-0.513-0.54c-0.095-0.243-0.081-0.527-0.081-0.797
			c0-4.592,0-9.185,0-13.764c0-0.351,0.041-0.716,0.122-1.067c0.041-0.149,0.23-0.257,0.351-0.392
			c2.296-0.176,19.518-0.162,21.638,0.013c0.108,0.203,0.311,0.419,0.338,0.662c0.068,0.527,0.068,1.081,0.068,1.621
			c0,8.82,0,17.64,0,26.46c0,0.621-0.054,1.256-0.081,1.877c-0.851,1.108-1.877,1.972-2.823,2.944
			c-0.945,0.959-1.918,1.905-2.877,2.85c-0.891,0.891-1.783,1.783-2.688,2.661c-0.959,0.945-1.864,1.945-2.809,2.701
			c-2.35,0.162-19.869,0.176-22.435,0.014c-1-0.729-1.864-1.756-2.836-2.701s-1.918-1.904-2.863-2.863
			c-0.891-0.891-1.783-1.783-2.674-2.674c-0.945-0.959-1.931-1.877-2.782-2.85c-0.041-0.554-0.095-1-0.108-1.445
			c-0.162-9.576-0.068-51.921,0.122-54.649c0.149-0.203,0.324-0.513,0.581-0.77c3.242-3.255,6.483-6.497,9.739-9.739
			c0.257-0.257,0.554-0.446,0.783-0.635c2.337-0.23,13.25-0.324,20.841-0.162c0.527,0.013,1.067,0.081,1.486,0.122
			c1.607,1.337,9.225,8.928,11.251,11.197c0.041,0.257,0.135,0.621,0.135,0.972c0.014,4.768,0.014,9.536,0,14.304
			C160.192,608.586,160.138,608.951,160.044,609.288L160.044,609.288z M308.958,604.007c-1.877,0.203-12.818,0.243-15.898,0.068
			c-0.135-0.122-0.27-0.243-0.459-0.419c-0.041-1.472-0.081-2.985-0.135-4.525c-0.257-0.162-0.473-0.392-0.702-0.419
			c-1.378-0.135-8.253-0.081-10.009,0.041c-0.108,0.122-0.23,0.243-0.351,0.378c-0.203,3.296-0.149,11.346,0.095,13.25
			c0.203,0.095,0.446,0.27,0.689,0.284c0.81,0.054,1.621,0.054,2.431,0.054c3.782,0,7.564,0,11.346,0
			c0.716,0,1.432,0.04,2.053,0.068c1.675,1.378,9.59,9.225,11.278,11.157c0.189,2.674,0.054,5.47,0.081,8.253
			c0.027,2.796,0.014,5.578,0,8.374c-0.014,2.782,0.054,5.578-0.054,8.293c-0.216,0.297-0.351,0.54-0.54,0.716
			c-3.377,3.363-6.767,6.726-10.157,10.09c-0.189,0.189-0.432,0.311-0.621,0.459c-2.175,0.149-19.774,0.149-22.165,0.013
			c-0.527-0.176-0.878-0.594-1.256-0.973c-2.999-2.985-5.997-5.97-8.996-8.955c-0.311-0.311-0.621-0.648-0.959-1
			c-0.054-0.351-0.149-0.702-0.162-1.053c-0.013-2.972-0.013-5.943,0-8.915c0-0.351,0.081-0.702,0.122-1
			c0.216-0.162,0.338-0.324,0.5-0.351c0.351-0.068,0.716-0.095,1.067-0.095c4.417,0,8.82,0,13.237,0c0.54,0,1.067,0,1.607,0.216
			c0.108,0.284,0.257,0.513,0.27,0.756c0.041,0.81,0.027,1.621,0.041,2.431c0.014,0.621,0.054,1.256,0.081,1.783
			c0.297,0.405,0.621,0.513,0.999,0.513c2.526,0,5.038,0.013,7.564,0.013c0.716,0,1.432-0.054,2.08-0.068
			c0.176-0.203,0.351-0.338,0.392-0.486c0.081-0.351,0.108-0.716,0.108-1.067c0-3.687,0-7.375,0-11.076
			c0-0.27-0.041-0.54-0.068-0.756c-0.203-0.392-0.5-0.567-0.878-0.581c-0.716-0.014-1.445-0.027-2.161-0.027
			c-3.606,0-7.199,0-10.806,0c-0.891,0-1.796,0-2.701,0c-0.324-0.243-0.635-0.419-0.878-0.675c-3.323-3.309-6.618-6.618-9.928-9.928
			c-0.189-0.189-0.338-0.419-0.5-0.635c-0.041-0.27-0.135-0.54-0.135-0.81c-0.014-7.915-0.014-15.844,0-23.759
			c0-0.27,0.081-0.527,0.122-0.756c1.499-1.756,9.495-9.752,11.143-11.116c0.297-0.041,0.648-0.122,1.013-0.122
			c6.835,0,13.683,0,20.517,0.014c0.257,0,0.527,0.081,0.756,0.122c1.54,1.256,8.726,8.401,11.224,11.143
			c0.041,0.419,0.122,0.959,0.122,1.486c0.014,2.877,0,5.754-0.014,8.644C309.377,603.426,309.282,603.764,308.958,604.007
			L308.958,604.007z M365.984,649.728c-3.188,3.174-6.375,6.362-9.576,9.522c-0.311,0.311-0.675,0.581-0.959,0.837
			c-0.351,0.054-0.621,0.122-0.878,0.122c-7.105,0-14.223,0-21.355,0c-0.297-0.216-0.621-0.392-0.864-0.648
			c-3.323-3.309-6.632-6.618-9.928-9.928c-0.189-0.189-0.338-0.419-0.5-0.621c-0.041-0.27-0.135-0.54-0.135-0.81
			c0-18.18,0-36.374,0-54.555c0-0.27,0.081-0.527,0.122-0.756c1.553-1.796,9.509-9.725,11.116-11.089
			c0.243-0.041,0.5-0.135,0.77-0.135c6.929-0.013,13.858-0.013,20.801,0c0.257,0,0.527,0.095,0.756,0.135
			c1.54,1.27,8.78,8.469,11.197,11.13c0.041,0.27,0.135,0.621,0.135,0.972c0.014,4.768,0.014,9.536,0.014,14.317
			c0,0.446,0.041,0.905-0.311,1.337c-0.311,0.284-0.756,0.297-1.216,0.297c-1.445,0.013-2.877,0.013-4.322,0.013
			c-3.066,0-6.119,0-9.185-0.013c-0.446,0-0.891-0.081-1.324-0.176c-0.135-0.027-0.311-0.216-0.351-0.365
			c-0.081-0.432-0.149-0.878-0.149-1.324c-0.014-2.431-0.014-4.862-0.014-7.294c0-0.365-0.014-0.716-0.027-1.081
			c-0.027-0.527-0.338-0.891-0.797-0.918c-0.095-0.014-0.176-0.027-0.27-0.027c-3.066,0-6.119,0-9.185,0.014
			c-0.176,0-0.351,0.081-0.581,0.135c-0.095,0.189-0.284,0.419-0.311,0.662c-0.054,0.716-0.054,1.432-0.054,2.161
			c0,12.967,0,25.933,0,38.886c0,0.891-0.081,1.783,0.122,2.58c0.257,0.162,0.392,0.338,0.54,0.338
			c3.242,0.027,6.483,0.04,9.711,0.04c0.446,0,0.797-0.392,0.81-0.905c0.027-1.081,0.027-2.161,0.027-3.242
			c0-1.796,0-3.606,0.014-5.403c0-0.446,0.081-0.878,0.122-1.337c0.297-0.122,0.527-0.297,0.77-0.311
			c0.891-0.041,1.796-0.054,2.701-0.054c3.782,0,7.564,0,11.346,0c0.527,0,1.081-0.095,1.634,0.297
			c0.054,0.162,0.216,0.405,0.23,0.648c0.041,0.891,0.041,1.796,0.041,2.701c0,3.512,0,7.024,0,10.535c0,0.81,0,1.621,0,2.404
			C366.43,649.161,366.241,649.471,365.984,649.728L365.984,649.728z M589.673,659.723c-0.216,0.338-0.527,0.486-0.905,0.486
			c-0.716,0.013-1.445,0.013-2.161,0.013c-10.981,0-21.962,0-32.943,0c-0.81,0-1.621-0.013-2.431-0.068
			c-0.243-0.014-0.459-0.23-0.702-0.351c-0.027-0.473-0.081-0.932-0.081-1.364c0-25.285,0.014-50.57,0.027-75.855
			c0-0.176,0.095-0.338,0.149-0.513c0.149-0.108,0.27-0.243,0.432-0.297c0.257-0.081,0.527-0.122,0.797-0.122
			c4.673-0.014,9.36-0.014,14.034,0c0.27,0,0.554,0,0.783,0.122c0.216,0.108,0.473,0.338,0.513,0.554
			c0.095,0.527,0.095,1.067,0.108,1.607c0,3.147,0,6.294,0,9.455c0,15.573,0,31.133,0,46.707c0,0.891,0.04,1.796,0.068,2.701
			c0.014,0.338,0.365,0.675,0.716,0.689c0.81,0.027,1.621,0.054,2.431,0.054c5.484,0,10.981,0,16.465,0
			c0.797,0,1.621-0.081,2.499,0.162c0.135,0.365,0.243,0.527,0.257,0.689C589.849,646.446,589.795,658.062,589.673,659.723
			L589.673,659.723z M102.977,659.007c0,0.27-0.081,0.527-0.122,0.837c-0.23,0.108-0.446,0.243-0.689,0.338
			c-0.081,0.04-0.176,0.027-0.27,0.027c-4.863,0-9.712,0-14.574,0c-0.365,0-0.716-0.068-1.027-0.405
			c-0.041-0.203-0.135-0.459-0.149-0.716c-0.027-0.716-0.027-1.432-0.027-2.161c0-24.015,0-48.017,0-72.033
			c0-0.716,0-1.432,0.027-2.161c0.027-0.743,0.392-1.067,1.216-1.094c0.176-0.014,0.365-0.014,0.54-0.014c4.403,0,8.82,0,13.223,0
			c0.54,0,1.081-0.013,1.715,0.351c0.068,0.419,0.135,0.675,0.135,0.945C102.977,608.275,102.977,633.641,102.977,659.007
			L102.977,659.007z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_6_1_" enable-background="new    ">
        <g id="Shape_1_copy_6">
          <path
            d="M100.411,456.849c0,1.621-0.041,3.242-0.068,4.863c-0.041,2.269-0.486,4.471-1.135,6.632
			c-0.405,1.378-1,2.701-1.783,3.917c-1.445,2.269-3.417,3.917-5.93,4.889c-1.607,0.621-3.255,1.054-4.984,1.094
			c-1.351,0.041-2.701,0.014-4.052,0c-1.459-0.027-2.85-0.378-4.214-0.837c-3.215-1.081-5.605-3.161-7.199-6.159
			c-1.108-2.094-1.756-4.336-2.053-6.672c-0.324-2.499-0.473-5.011-0.473-7.537c0.014-33.916,0.014-67.845,0-101.761
			c0-2.526,0.149-5.038,0.446-7.537c0.23-1.891,0.702-3.701,1.418-5.457c2.04-4.957,5.821-7.618,11.062-8.293
			c2.053-0.27,4.133-0.23,6.2,0c1.621,0.176,3.174,0.648,4.646,1.337c2.62,1.243,4.511,3.215,5.835,5.781
			c0.702,1.364,1.202,2.823,1.526,4.309c0.405,1.85,0.743,3.701,0.716,5.605c-0.014,1.432,0.027,2.877,0.027,4.322
			c0,17.005,0,34.01,0,51.016C100.424,423.19,100.424,440.019,100.411,456.849L100.411,456.849z M521.787,441.91
			c-0.54,0.068-1.081,0.095-1.607,0.095c-8.469,0-16.924,0.014-25.393,0c-0.608,0-1.27,0.189-1.864-0.419
			c0.311-3.512,1.013-7.037,1.486-10.589c0.486-3.66,1.094-7.294,1.634-10.941c0.527-3.566,1.054-7.118,1.58-10.684
			c0.527-3.566,1.067-7.118,1.594-10.684c0.554-3.647,1.094-7.294,1.648-10.941c0.527-3.566,1.067-7.118,1.594-10.684
			s1.067-7.118,1.594-10.684c0.54-3.647,1.081-7.294,1.634-10.941c0.54-3.552,1.108-7.105,1.675-10.698
			c0.149,0.108,0.324,0.162,0.365,0.257c0.162,0.513,0.351,1.027,0.432,1.553c0.594,3.998,1.175,8.01,1.769,12.021
			c0.635,4.268,1.297,8.536,1.945,12.805c0.432,2.85,0.851,5.7,1.27,8.55c0.459,3.12,0.945,6.227,1.405,9.347
			c0.446,3.026,0.878,6.051,1.337,9.077c0.419,2.85,0.864,5.686,1.283,8.536c0.473,3.12,0.932,6.227,1.405,9.347
			c0.432,2.931,0.864,5.876,1.31,8.807c0.648,4.363,1.297,8.725,1.958,13.075c0.108,0.716,0.257,1.418,0.351,2.134
			c0.054,0.446,0.054,0.891,0.081,1.297C522.057,441.694,521.922,441.897,521.787,441.91L521.787,441.91z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_5_1_" enable-background="new    ">
        <g id="Shape_1_copy_5">
          <path
            d="M611.878,50.317c0.041,0.365,0.122,0.729,0.122,1.081c-0.014,229.956-0.027,459.912-0.054,689.868
			c0,0.081-0.027,0.176-0.068,0.257c-0.04,0.081-0.108,0.135-0.216,0.284c-0.176,0.054-0.419,0.203-0.662,0.203
			c-1.175,0.04-2.337,0.04-3.512,0.04c-142.269,0-284.524,0-426.793,0c-58.971,0-117.956,0-176.928,0
			c-0.986,0-1.986-0.027-2.972-0.054c-0.257,0-0.702-0.459-0.716-0.716c-0.027-0.27-0.054-0.54-0.054-0.81
			c-0.014-0.905-0.014-1.796-0.014-2.701C0,509.877,0,282.001,0,54.112c0-1.081,0.014-2.161,0.041-3.242
			c0.014-0.419,0.446-0.837,0.878-0.878c0.27-0.027,0.54-0.041,0.81-0.041c0.81-0.014,1.621,0,2.431,0c201.24,0,402.48,0,603.72,0
			c1.081,0,2.161,0.014,3.242,0.054C611.365,50.019,611.595,50.209,611.878,50.317L611.878,50.317z M1.918,52.275
			c-0.041,0.311-0.108,0.662-0.108,1.013c0,73.924,0,147.847,0.014,221.757c0,0.257,0.081,0.527,0.135,0.824
			c0.23,0.108,0.459,0.297,0.702,0.311c1.162,0.054,2.337,0.068,3.512,0.068c199.984,0,399.954,0,599.938,0c0.716,0,1.445,0,2.161,0
			c0.365,0,0.716-0.013,1.081-0.027c0.378-0.027,0.675-0.189,0.959-0.716c0-0.81,0-1.796,0-2.782c0-72.478,0-144.957,0-217.435
			c0-0.81,0-1.621-0.027-2.431c-0.014-0.257-0.135-0.5-0.216-0.783c-0.27-0.095-0.5-0.257-0.756-0.257
			c-1.081-0.041-2.161-0.041-3.242-0.041c-199.984,0-399.968,0-599.938,0c-0.905,0-1.796-0.014-2.701,0.014
			c-0.351,0.014-0.729,0.041-1.067,0.122C2.215,51.937,2.094,52.113,1.918,52.275L1.918,52.275z M1.904,739.901
			c0.243,0.122,0.473,0.338,0.702,0.351c1.162,0.054,2.337,0.068,3.512,0.068c108.771,0,217.543,0,326.301,0
			c91.293,0,182.6,0,273.907,0c0.986,0,1.986-0.014,2.971-0.027c0.378,0,0.689-0.149,0.918-0.54
			c0.176-0.932,0.095-1.918,0.095-2.904c0-66.36,0-132.719,0-199.079c0-0.81,0-1.621-0.027-2.431
			c-0.014-0.257-0.122-0.513-0.189-0.797c-0.257-0.108-0.486-0.297-0.729-0.311c-1.162-0.041-2.337-0.054-3.512-0.054
			c-199.795,0-399.603,0-599.398,0c-0.905,0-1.796,0-2.701,0c-0.365,0-0.716,0.027-1.081,0.054
			c-0.473,0.041-0.824,0.378-0.837,0.878c-0.027,1.081-0.054,2.161-0.054,3.242c0,65.914,0,131.814,0,197.715
			c0,0.81-0.014,1.621,0.014,2.431C1.823,738.969,1.877,739.414,1.904,739.901L1.904,739.901z M131.342,406.076L131.342,406.076
			c0-8.374,0-16.749,0-25.123c0-9.09,0-18.18,0-27.271c0-5.241-0.581-10.414-1.823-15.52c-1-4.133-2.472-8.091-4.525-11.805
			c-4.012-7.24-9.766-12.616-17.262-16.127c-5.281-2.472-10.873-3.822-16.641-4.282c-5.389-0.432-10.779-0.432-16.168,0.365
			c-3.849,0.567-7.604,1.459-11.211,2.891c-9.077,3.606-15.884,9.711-20.314,18.451c-1.472,2.904-2.62,5.93-3.471,9.063
			c-1.324,4.876-2.013,9.874-2.188,14.925c-0.054,1.702-0.068,3.417-0.068,5.133c0,32.862,0,65.725,0,98.574
			c0,0.905,0,1.796,0,2.701c0.041,4.971,0.54,9.874,1.607,14.736c1,4.511,2.512,8.82,4.741,12.872
			c4.079,7.429,9.982,12.886,17.694,16.411c5.214,2.391,10.725,3.687,16.411,4.133c5.295,0.405,10.603,0.432,15.898-0.351
			c3.849-0.567,7.604-1.445,11.211-2.877c9.279-3.647,16.181-9.901,20.625-18.856c1.04-2.107,1.918-4.268,2.634-6.497
			c1.877-5.876,2.742-11.913,2.836-18.072c0.027-1.445,0.014-2.877,0.014-4.322C131.342,438.844,131.342,422.46,131.342,406.076
			L131.342,406.076z M152.98,307.975c-0.122,0.365-0.27,0.608-0.284,0.864c-0.041,0.81-0.041,1.621-0.041,2.431
			c0,48.72,0.014,97.426-0.014,146.132c0,4.876,0.284,9.711,1.189,14.506c0.878,4.619,2.256,9.077,4.363,13.291
			c4.376,8.766,11.157,14.898,20.301,18.423c4.741,1.823,9.671,2.85,14.723,3.147c4.754,0.284,9.536,0.324,14.29-0.365
			c3.404-0.486,6.74-1.189,9.968-2.323c9.914-3.485,17.208-9.914,21.787-19.396c1.337-2.769,2.377-5.632,3.161-8.604
			c1.31-4.984,1.999-10.063,2.148-15.209c0.054-1.986,0.068-3.958,0.068-5.943c0-47.626,0-95.265,0-142.89c0-0.716,0-1.445,0-2.161
			c0-0.365-0.014-0.716-0.041-1.081c-0.027-0.513-0.351-0.878-0.797-0.905c-0.635-0.027-1.256-0.054-1.891-0.054
			c-7.929,0-15.844,0-23.772,0c-2.877,0-2.715-0.108-2.715,2.863c0,0.716,0,1.445,0,2.161c0,47.72,0,95.44,0,143.16
			c0,1.081,0,2.161,0,3.242c0,2.161-0.189,4.322-0.554,6.456c-0.284,1.688-0.756,3.323-1.459,4.903
			c-1.918,4.309-5.281,6.699-9.833,7.564c-2.782,0.527-5.551,0.513-8.32-0.014c-4.565-0.864-7.902-3.323-9.806-7.618
			c-0.324-0.743-0.594-1.513-0.824-2.283c-0.608-2.08-0.972-4.214-1.04-6.375c-0.054-1.621-0.054-3.242-0.054-4.863
			c0-48.355,0-96.71,0-145.051c0-1.081-0.014-2.161-0.054-3.242c-0.014-0.243-0.189-0.486-0.311-0.743
			c-0.378-0.054-0.729-0.135-1.081-0.149C178.251,307.719,155.06,307.827,152.98,307.975L152.98,307.975z M532.039,503.975
			c0.311,0.095,0.554,0.23,0.81,0.243c0.621,0.041,1.256,0.041,1.891,0.041c8.55,0,17.113,0,25.663,0
			c0.635,0,1.27,0.014,1.891-0.041c0.243-0.027,0.473-0.216,0.716-0.324c-0.135-2.296-0.635-4.484-0.973-6.713
			c-0.608-3.998-1.27-7.996-1.904-11.994c-0.608-3.822-1.216-7.645-1.823-11.467c-0.621-3.903-1.27-7.82-1.891-11.724
			c-0.621-3.904-1.229-7.821-1.85-11.738c-0.662-4.174-1.337-8.347-2.013-12.521c-0.608-3.822-1.229-7.645-1.837-11.454
			c-0.635-3.998-1.283-7.996-1.918-11.994c-0.608-3.822-1.202-7.645-1.823-11.467c-0.648-4.093-1.324-8.172-1.972-12.264
			c-0.608-3.822-1.216-7.645-1.823-11.467c-0.648-4.093-1.31-8.172-1.972-12.264c-0.608-3.822-1.216-7.645-1.837-11.467
			c-0.621-3.822-1.256-7.631-1.864-11.454c-0.81-5.065-1.58-10.13-2.377-15.195c-1.027-6.483-2.08-12.967-3.12-19.45
			c-0.189-1.135-0.446-1.391-1.553-1.418c-0.635-0.014-1.256-0.014-1.891-0.014c-12.967,0-25.933,0-38.9,0
			c-0.716,0-1.445,0-2.161,0.041c-0.257,0.014-0.513,0.135-0.851,0.23c-0.135,0.392-0.324,0.797-0.405,1.229
			c-0.23,1.324-0.419,2.661-0.635,3.998c-0.648,4.093-1.31,8.172-1.959,12.264c-0.608,3.822-1.229,7.645-1.823,11.454
			c-0.621,3.904-1.229,7.82-1.85,11.738c-0.648,4.093-1.31,8.172-1.972,12.264c-0.648,3.998-1.297,7.996-1.931,11.994
			c-0.594,3.728-1.202,7.469-1.796,11.197c-0.635,3.998-1.27,7.996-1.904,11.994c-0.621,3.904-1.256,7.821-1.877,11.724
			c-0.621,3.904-1.229,7.82-1.864,11.738c-0.635,3.998-1.297,7.996-1.932,11.994c-0.608,3.822-1.189,7.645-1.796,11.467
			c-0.635,3.998-1.31,7.996-1.958,11.994c-0.675,4.174-1.337,8.347-2.013,12.521c-0.621,3.904-1.229,7.821-1.85,11.738
			c-0.608,3.822-1.216,7.645-1.837,11.467c-0.621,3.822-1.256,7.631-1.85,11.454c-0.081,0.513-0.216,1.054,0,1.58
			c0.149,0.108,0.284,0.27,0.446,0.284c0.716,0.054,1.432,0.081,2.161,0.081c7.564,0,15.128,0,22.692,0c0.635,0,1.256,0,1.891-0.041
			c0.257-0.014,0.513-0.149,0.837-0.243c0.149-0.594,0.338-1.189,0.446-1.796c0.149-0.891,0.243-1.783,0.378-2.674
			c0.621-4.093,1.243-8.185,1.864-12.278c0.621-4.187,1.229-8.361,1.85-12.548c0.257-1.688,0.54-3.377,0.837-5.065
			c0.041-0.243,0.203-0.473,0.284-0.675c0.824-0.419,1.648-0.257,2.445-0.257c10.711-0.014,21.436-0.014,32.147-0.014
			c0.635,0,1.256,0,1.891,0.027c0.351,0.014,0.716,0.068,1.081,0.54c0.054,0.257,0.162,0.689,0.243,1.135
			c0.108,0.621,0.216,1.243,0.311,1.864c0.297,1.958,0.581,3.917,0.878,5.876c0.608,3.998,1.216,8.01,1.81,12.008
			c0.608,4.093,1.189,8.185,1.81,12.278C531.715,502.948,531.904,503.448,532.039,503.975L532.039,503.975z M457.048,335.948
			c0.675-0.878,0.486-1.621,0.486-2.323c0.014-7.74,0.014-15.479,0.014-23.232c0-0.54-0.014-1.081-0.041-1.621
			c-0.014-0.486-0.365-0.851-0.837-0.864c-0.986-0.027-1.972-0.054-2.972-0.054c-29.256,0-58.526,0-87.782,0
			c-0.81,0-1.621,0-2.431,0.013c-0.27,0-0.527,0.068-0.797,0.108c-0.162,0.027-0.527,0.392-0.54,0.554
			c-0.027,0.365-0.081,0.716-0.081,1.081c0,8.199,0,16.384,0,24.583c0,0.351,0.054,0.716,0.095,1.067
			c0.041,0.338,0.392,0.662,0.743,0.675c0.81,0.027,1.621,0.054,2.431,0.054c8.469,0,16.924,0,25.393,0c0.905,0,1.796,0,2.701,0.054
			c0.243,0.014,0.473,0.189,0.729,0.284c0.068,0.297,0.162,0.554,0.162,0.797c0.027,0.986,0.027,1.986,0.027,2.972
			c0,53.393,0,106.772,0,160.165c0,0.986,0,1.986,0.027,2.972c0.014,0.257,0.149,0.5,0.203,0.689
			c0.621,0.473,1.27,0.338,1.877,0.338c8.915,0.014,17.829,0.014,26.744,0c0.365,0,0.716-0.014,1.081-0.027
			c0.378-0.014,0.675-0.176,0.959-0.689c0-0.986,0.014-2.161,0.014-3.323c0-53.204,0-106.408,0-159.625c0-0.905,0-1.796,0-2.701
			c0-0.365,0.027-0.716,0.054-1.081c0.027-0.351,0.351-0.729,0.662-0.743c0.81-0.041,1.621-0.081,2.418-0.081
			c8.644,0,17.289,0,25.933,0C455.211,335.989,456.103,335.962,457.048,335.948L457.048,335.948z M354.395,307.975
			c-0.945-0.189-1.945-0.108-2.931-0.108c-29.621,0-59.242,0-88.862,0c-0.716,0-1.445,0.013-2.161,0.027
			c-0.176,0-0.365,0.054-0.513,0.135c-0.149,0.081-0.338,0.216-0.351,0.365c-0.095,0.621-0.176,1.243-0.176,1.864
			c-0.013,7.74-0.013,15.492,0,23.232c0,0.54,0,1.081,0.068,1.621c0.027,0.243,0.216,0.473,0.324,0.702
			c0.905,0.324,1.823,0.189,2.715,0.189c8.55,0.014,17.1,0,25.663,0.014c0.905,0,1.796,0.014,2.701,0.068
			c0.23,0.014,0.459,0.216,0.716,0.351c0.189,1.189,0.108,2.35,0.108,3.512c0,53.474,0,106.962,0,160.436
			c0,0.986,0.014,1.986,0.041,2.972c0.014,0.486,0.378,0.864,0.824,0.891c0.635,0.027,1.256,0.041,1.891,0.041
			c8.469,0,16.924,0,25.393,0c0.716,0,1.432-0.041,2.148-0.095c0.149-0.014,0.284-0.203,0.486-0.365
			c0.216-1.094,0.122-2.256,0.122-3.417c0-53.393,0-106.772,0-160.165c0-0.905,0-1.796,0.014-2.701c0-0.27,0.041-0.54,0.054-0.81
			c0.027-0.243,0.473-0.662,0.756-0.675c0.81-0.027,1.621-0.041,2.431-0.041c8.55,0,17.1,0,25.663,0c0.81,0,1.621-0.014,2.431-0.041
			c0.5-0.014,0.891-0.365,0.918-0.81c0.027-0.54,0.041-1.081,0.041-1.621c0-7.74,0-15.492,0-23.232c0-0.635,0-1.256,0-1.783
			C354.652,308.272,354.544,308.137,354.395,307.975L354.395,307.975z"
          />
        </g>
      </g>
      <g id="straight_1_">
        <g id="Shape_1_copy">
          <path
            d="M243.679,182.482c0.635,0.635,1.256,1.283,1.918,1.959c0.041,0.635,0.108,1.256,0.108,1.877c0,12.332,0,24.677,0,37.009
			c0,0.54-0.027,1.081-0.041,1.621c-0.014,0.324-0.365,0.702-0.675,0.729c-0.365,0.027-0.716,0.068-1.081,0.068
			c-6.483,0-12.967,0-19.45,0c-0.446,0-0.891-0.068-1.351-0.108c-0.149-0.27-0.351-0.5-0.378-0.729
			c-0.068-0.81-0.081-1.621-0.081-2.418c0-10.981,0-21.976,0-32.957c0-0.905-0.041-1.796-0.068-2.701
			c-0.014-0.27-0.446-0.716-0.702-0.729c-0.27-0.027-0.54-0.054-0.81-0.054c-3.242,0-6.483,0-9.725,0c-0.27,0-0.554,0-0.797,0.068
			s-0.513,0.203-0.581,0.5c-0.054,0.257-0.135,0.527-0.135,0.783c-0.027,0.81-0.014,1.621-0.014,2.431c0,10.806,0,21.611,0,32.417
			c0,0.905-0.027,1.796-0.095,2.688c-0.014,0.23-0.243,0.446-0.405,0.716c-0.554,0.027-1.081,0.081-1.621,0.081
			c-6.308,0-12.602,0-18.91,0c-0.446,0-0.905-0.014-1.337-0.081c-0.257-0.041-0.486-0.216-0.567-0.5
			c-0.054-0.176-0.095-0.351-0.095-0.527c-0.014-0.716-0.027-1.445-0.027-2.161c0-31.782,0-63.577,0-95.359
			c0-0.986-0.108-1.972,0.135-2.985c0.243-0.108,0.473-0.297,0.716-0.311c0.716-0.054,1.445-0.054,2.161-0.054
			c12.872,0,25.758,0,38.63,0c0.81,0,1.607,0.041,2.445,0.054c0.351,0.311,0.689,0.594,1.013,0.905
			c5.538,5.362,12.075,11.981,13.696,13.845c0.041,0.23,0.122,0.5,0.122,0.77c0.014,8.28,0.014,16.573,0,24.853
			c0,0.27-0.068,0.527-0.108,0.729c-0.311,0.648-0.81,1.04-1.256,1.486c-2.35,2.364-4.714,4.714-7.064,7.078
			c-0.378,0.378-0.716,0.797-1.013,1.121c0.041,0.513,0.297,0.756,0.554,1C239.1,177.903,241.396,180.186,243.679,182.482
			L243.679,182.482z M222.649,148.607c0-0.365-0.027-0.716-0.054-1.081c-0.027-0.432-0.446-0.837-0.905-0.837
			c-3.593-0.014-7.186-0.014-10.779-0.027c-0.095,0-0.176,0.027-0.27,0.027c-0.311,0.027-0.729,0.405-0.756,0.675
			c-0.041,0.351-0.081,0.716-0.081,1.067c0,4.309,0,8.617,0,12.94c0,0.446,0.068,0.891,0.108,1.499
			c0.459,0.135,0.716,0.257,0.959,0.27c3.498,0.014,7.01,0.014,10.508,0.014c0.176,0,0.378,0,0.527-0.054
			c0.243-0.095,0.513-0.216,0.567-0.513c0.068-0.446,0.135-0.891,0.135-1.324C222.649,157.049,222.649,152.821,222.649,148.607
			L222.649,148.607z M497.083,222.26c0,0.905-0.027,1.796-0.054,2.701c-0.014,0.284-0.432,0.716-0.702,0.729
			c-0.27,0.027-0.54,0.054-0.81,0.054c-6.659,0-13.318,0-19.99,0c-0.27,0-0.54-0.041-0.756-0.068
			c-0.392-0.189-0.594-0.486-0.608-0.864c-0.027-0.81-0.041-1.621-0.041-2.431c0-10.981,0-21.962-0.014-32.943
			c0-0.986,0.135-1.986-0.189-3.066c-0.338-0.149-0.5-0.27-0.662-0.27c-3.687-0.027-7.375-0.027-11.062-0.027
			c-0.176,0-0.365,0.054-0.5,0.149c-0.149,0.081-0.324,0.243-0.338,0.378c-0.068,0.797-0.135,1.607-0.135,2.418
			c-0.014,11.17,0,22.327-0.014,33.484c0,0.81-0.014,1.621-0.041,2.431c-0.014,0.351-0.351,0.716-0.662,0.743
			c-0.446,0.041-0.891,0.081-1.337,0.081c-6.308,0-12.602,0.014-18.91,0c-0.621,0-1.27,0.122-1.877-0.365
			c-0.054-0.176-0.203-0.419-0.216-0.662c-0.041-0.81-0.041-1.621-0.041-2.431c0-31.687,0-63.375,0-95.075
			c0-0.986-0.095-1.972,0.095-2.904c0.243-0.392,0.567-0.5,0.932-0.513c0.446-0.014,0.905-0.027,1.351-0.027
			c6.119,0,12.237,0,18.369,0c0.621,0,1.256,0.041,1.837,0.068c0.162,0.176,0.284,0.311,0.486,0.527
			c0,0.905,0.014,1.891,0.014,2.877c0,10.981,0,21.962,0.014,32.957c0,0.878-0.149,1.783,0.176,2.593
			c0.216,0.135,0.365,0.297,0.513,0.297c3.782,0.027,7.564,0.041,11.332,0.041c0.459,0,0.824-0.392,0.837-0.878
			c0.027-0.81,0.041-1.621,0.041-2.431c0-10.9,0-21.787,0-32.687c0-0.986-0.068-1.972,0.095-2.917
			c0.176-0.149,0.311-0.338,0.459-0.351c0.527-0.068,1.081-0.095,1.607-0.095c6.213,0,12.426-0.014,18.64,0
			c0.608,0,1.256-0.149,1.905,0.311c0.054,0.176,0.189,0.405,0.203,0.662c0.041,0.905,0.041,1.796,0.041,2.701
			C497.083,159.061,497.083,190.654,497.083,222.26L497.083,222.26z M423.038,211.738c-4.457,4.457-8.928,8.888-13.412,13.331
			c-0.189,0.189-0.419,0.324-0.743,0.567c-0.432,0.027-0.973,0.108-1.499,0.108c-8.644,0.014-17.289,0.014-25.933,0
			c-0.54,0-1.081-0.041-1.634-0.081c-0.297-0.23-0.594-0.432-0.851-0.675c-3.552-3.35-12.507-12.386-13.899-14.007
			c-0.284-0.756-0.176-1.58-0.176-2.377c-0.014-6.578,0-13.142,0-19.72c0-15.763,0-31.512,0-47.274c0-0.986,0.027-1.972,0.041-2.999
			c1.972-2.215,13.142-13.345,14.75-14.709c0.23-0.041,0.486-0.108,0.756-0.108c9.455,0,18.91,0,28.405,0
			c0.351,0.284,0.729,0.527,1.04,0.837c4.349,4.309,8.685,8.631,13.021,12.967c0.311,0.311,0.594,0.675,0.891,1.027
			c0.027,0.554,0.068,1.094,0.068,1.621c0,6.213,0,12.426,0,18.64c0,0.54-0.041,1.081-0.108,1.607
			c-0.014,0.149-0.203,0.284-0.392,0.513c-0.729,0.027-1.54,0.068-2.337,0.054c-6.564-0.054-13.142,0.135-19.707-0.108
			c-0.081,0-0.149-0.095-0.23-0.149c-0.378-0.338-0.324-0.81-0.324-1.243c-0.014-3.147,0-6.308-0.014-9.455
			c0-0.905-0.027-1.796-0.054-2.701c-0.014-0.338-0.365-0.675-0.702-0.702c-0.351-0.027-0.716-0.068-1.081-0.068
			c-3.066,0-6.119-0.014-9.185,0c-0.446,0-0.891,0.068-1.337,0.149c-0.149,0.027-0.257,0.23-0.459,0.405
			c-0.027,0.378-0.081,0.824-0.095,1.27c-0.149,9.657-0.068,51.137,0.108,53.731c0.203,0.351,0.513,0.513,0.891,0.513
			c3.687,0,7.375-0.014,11.076-0.027c0.162,0,0.324-0.108,0.486-0.176c0.095-0.149,0.27-0.284,0.27-0.432
			c0.027-2.607,0.027-5.214,0.027-7.821c0-0.162-0.122-0.311-0.216-0.554c-0.189-0.068-0.432-0.23-0.675-0.23
			c-1.175-0.041-2.337-0.027-3.512-0.041c-0.716-0.014-1.432-0.041-2.026-0.054c-0.446-0.27-0.581-0.594-0.594-0.972
			c-0.014-0.54-0.027-1.081-0.027-1.621c0-5.943,0-11.886,0-17.829c0-0.635,0.041-1.256,0.095-1.877
			c0.014-0.149,0.176-0.297,0.27-0.432c0.162-0.068,0.324-0.203,0.486-0.203c9.09-0.027,18.18-0.041,27.284-0.054
			c0.54,0,1.067,0.068,1.499,0.095c0.432,0.284,0.554,0.621,0.567,0.986c0.014,0.635,0.027,1.256,0.027,1.891
			c0,11.616,0,23.232,0,34.848c0,0.81,0,1.621,0,2.431C423.578,211.036,423.348,211.428,423.038,211.738L423.038,211.738z
			 M319.642,225.691c-0.446,0.027-0.891,0.054-1.351,0.054c-1.351,0.014-2.701,0-4.052,0c-4.862,0-9.725,0-14.574,0
			c-0.621,0-1.256,0.081-1.864-0.23c-0.486-0.797-0.554-1.715-0.702-2.58c-0.594-3.363-1.148-6.74-1.715-10.103
			c-0.041-0.27-0.122-0.527-0.189-0.783c-0.081-0.324-0.432-0.608-0.797-0.635c-0.27-0.027-0.54-0.041-0.81-0.041
			c-4.673,0-9.36,0-14.034,0c-0.446,0-0.905-0.027-1.364,0.459c-0.23,0.891-0.459,1.932-0.635,2.999
			c-0.54,3.282-1.121,6.564-1.688,9.833c-0.027,0.176-0.068,0.378-0.176,0.5c-0.162,0.189-0.378,0.405-0.608,0.459
			c-0.432,0.095-0.891,0.108-1.337,0.108c-6.389,0-12.778,0-19.166,0c-0.365,0-0.716-0.027-1.067-0.081
			c-0.149-0.027-0.284-0.203-0.621-0.459c6.186-33.362,12.345-66.603,18.518-99.843c0.081-0.432,0.216-0.851,0.324-1.283
			c0.311-0.108,0.554-0.243,0.81-0.257c0.635-0.041,1.256-0.041,1.891-0.041c8.185,0,16.384,0,24.569,0
			c0.797,0,1.607-0.108,2.404,0.176c0.135,0.257,0.324,0.486,0.365,0.729c1.135,6.105,2.256,12.197,3.39,18.302
			c1.351,7.253,2.728,14.493,4.079,21.746c1.189,6.362,2.323,12.737,3.512,19.099c1.148,6.186,2.323,12.372,3.485,18.559
			c1.31,6.983,2.607,13.966,3.904,20.949c0.095,0.527,0.122,1.067,0.176,1.607C320.344,225.218,319.926,225.677,319.642,225.691
			L319.642,225.691z M290.859,186.656c-0.459-2.742-0.945-5.497-1.418-8.239c-0.77-4.525-1.54-9.05-2.31-13.561
			c-0.041-0.257-0.135-0.527-0.27-0.756c-0.081-0.135-0.257-0.216-0.473-0.378c-0.311,1.891-0.608,3.741-0.932,5.592
			c-0.81,4.795-1.634,9.576-2.458,14.358c-0.149,0.891-0.311,1.769-0.459,2.661c-0.054,0.351-0.081,0.716-0.122,1.067
			c-0.054,0.513,0.257,0.918,0.729,0.918c2.337,0.014,4.673,0,7.01,0c0.081,0,0.176-0.041,0.257-0.054
			c0.203-0.027,0.527-0.378,0.513-0.554C290.926,187.372,290.913,187.007,290.859,186.656L290.859,186.656z M99.006,153.442
			c-0.176,0.122-0.311,0.284-0.473,0.311c-0.446,0.068-0.891,0.122-1.337,0.122c-6.389,0.014-12.791,0-19.18,0
			c-0.446,0-0.905-0.041-1.337-0.108c-0.162-0.027-0.284-0.203-0.486-0.338c-0.338-2.094,0.095-4.268-0.243-6.348
			c-0.203-0.149-0.338-0.311-0.5-0.338c-0.446-0.068-0.891-0.095-1.337-0.095c-2.972,0-5.943-0.014-8.915,0
			c-0.446,0-0.905,0.014-1.337,0.122c-0.216,0.054-0.446,0.284-0.554,0.5c-0.122,0.23-0.135,0.527-0.135,0.783
			c0,4.592,0,9.185,0,13.777c0,0.459,0.027,0.905,0.54,1.256c2.161,0.176,4.403,0.054,6.645,0.081c2.337,0.027,4.687,0,7.024,0
			c2.256,0,4.498,0,6.943,0c4.727,4.673,9.293,9.279,13.858,13.858c0.311,0.311,0.594,0.662,0.905,1.027
			c0.027,0.459,0.068,0.905,0.081,1.351c0.122,8.158,0.054,28.837-0.108,31.458c-0.216,0.257-0.473,0.621-0.783,0.932
			c-4.268,4.268-8.536,8.523-12.818,12.778c-0.311,0.311-0.662,0.608-0.905,0.837c-0.864,0.446-1.688,0.324-2.499,0.324
			c-6.578,0.014-13.142,0-19.72,0c-1.891,0-3.782,0.014-5.673,0c-0.54,0-1.067-0.068-1.526-0.095
			c-1.634-1.324-11.359-10.941-14.75-14.574c-0.054-0.338-0.176-0.77-0.176-1.216c-0.014-4.322,0-8.644,0-12.967
			c0-0.27,0.054-0.54,0.081-0.756c0.216-0.378,0.527-0.513,0.905-0.527c0.446-0.014,0.905-0.027,1.351-0.027
			c6.119,0,12.251,0,18.369,0c0.621,0,1.256-0.108,1.918,0.243c0.068,0.176,0.257,0.405,0.257,0.648
			c0.041,1.715,0.054,3.417,0.068,5.133c0,0.378,0.095,0.702,0.432,0.986c0.216,0.041,0.473,0.122,0.743,0.122
			c3.512,0.014,7.024,0.014,10.535,0c0.257,0,0.527-0.081,0.824-0.135c0.108-0.23,0.27-0.459,0.311-0.702
			c0.068-0.446,0.054-0.891,0.054-1.351c0-4.228,0-8.469,0-12.697c0-0.527,0.095-1.081-0.378-1.594
			c-0.176-0.054-0.419-0.176-0.675-0.189c-1.445-0.027-2.877-0.027-4.322-0.027c-4.322,0-8.644,0-12.967,0
			c-0.891,0-1.796-0.027-2.701-0.041c-1.783-1.445-3.269-3.134-4.876-4.7c-1.675-1.634-3.323-3.296-4.984-4.957
			c-1.594-1.594-3.174-3.174-4.808-4.808c-0.054-0.446-0.149-0.878-0.149-1.324c-0.014-10.171-0.014-20.341,0-30.526
			c0-0.351,0.054-0.716,0.081-1.148c0.257-0.311,0.527-0.675,0.837-0.986c4.336-4.322,8.671-8.644,13.007-12.967
			c0.257-0.257,0.54-0.473,0.824-0.702c0.365-0.027,0.729-0.095,1.081-0.095c9.36,0,18.721,0,28.135,0
			c0.419,0.351,0.851,0.675,1.216,1.054c4.16,4.12,8.307,8.253,12.44,12.386c0.446,0.446,0.837,0.918,1.202,1.324
			c0.135,1.837,0.216,11.481,0.108,14.155C99.128,152.942,99.046,153.199,99.006,153.442L99.006,153.442z M574.045,126.064
			c0,6.119,0,12.251,0,18.369c0,0.54-0.041,1.081-0.054,1.499c-0.23,0.459-0.513,0.662-0.891,0.675
			c-0.81,0.027-1.621,0.041-2.431,0.041c-4.957,0-9.901,0-14.858,0c-0.81,0-1.621,0-2.431,0.054
			c-0.243,0.014-0.473,0.189-0.756,0.311c-0.054,0.378-0.122,0.729-0.122,1.081c-0.014,0.81-0.014,1.621-0.014,2.431
			c0,23.948,0,47.909,0,71.857c0,0.986,0.081,1.972-0.122,2.89c-0.216,0.149-0.351,0.311-0.513,0.338
			c-0.351,0.081-0.716,0.122-1.067,0.122c-6.578,0.014-13.142,0.014-19.72,0c-0.351,0-0.716-0.041-1.067-0.108
			c-0.162-0.027-0.284-0.189-0.5-0.338c-0.041-0.297-0.108-0.648-0.108-1c-0.013-0.81-0.013-1.621-0.013-2.431
			c0-23.948,0-47.909-0.014-71.857c0-0.986,0.135-1.986-0.176-2.999c-0.216-0.095-0.446-0.284-0.689-0.297
			c-0.81-0.054-1.621-0.054-2.431-0.068c-4.957,0-9.901,0-14.858,0c-0.81,0-1.621,0-2.431-0.041
			c-0.243-0.014-0.486-0.176-0.675-0.243c-0.108-0.23-0.243-0.378-0.243-0.54c-0.027-6.578-0.041-13.142-0.054-19.72
			c0-0.621,0.054-1.243,0.081-1.877c0.284-0.149,0.5-0.351,0.743-0.378c0.716-0.054,1.432-0.054,2.161-0.054
			c20.085,0,40.156,0,60.241,0c0.891,0,1.796-0.135,2.701,0.203c0.095,0.243,0.243,0.473,0.27,0.729
			C574.059,125.159,574.045,125.604,574.045,126.064L574.045,126.064z M175.996,144.338c0,0.446-0.014,0.905-0.027,1.351
			c-0.027,0.513-0.338,0.878-0.797,0.905c-0.81,0.027-1.621,0.054-2.431,0.054c-5.133,0-10.265,0-15.398,0
			c-0.716,0-1.432,0.054-2.148,0.095c-0.338,0.027-0.675,0.378-0.689,0.716c-0.027,0.905-0.054,1.796-0.054,2.701
			c0,12.791,0,25.569,0,38.36c0,11.346,0,22.692,0,34.038c0,0.81-0.027,1.621-0.054,2.431c-0.014,0.324-0.378,0.689-0.702,0.716
			c-0.365,0.027-0.716,0.054-1.081,0.068c-1.175,0.014-2.337,0-3.512,0c-4.863,0-9.725,0-14.588,0c-0.716,0-1.445-0.027-2.161-0.054
			c-0.432-0.014-0.837-0.432-0.851-0.878c-0.027-0.905-0.054-1.796-0.054-2.701c0-23.853,0-47.72,0-71.573
			c0-0.905-0.014-1.796-0.027-2.701c0-0.365-0.108-0.702-0.405-0.986c-0.662-0.338-1.391-0.203-2.107-0.203
			c-5.038-0.014-10.09,0-15.128,0c-0.716,0-1.445,0-2.161,0c-1.54-0.027-1.702-0.162-1.702-1.702c-0.014-6.483,0-12.967,0-19.45
			c0-0.446,0.068-0.891,0.108-1.351c0.284-0.122,0.513-0.297,0.756-0.311c0.716-0.054,1.445-0.054,2.161-0.054
			c19.99,0,39.981,0,59.957,0c0.891,0,1.796-0.108,2.728,0.162c0.095,0.203,0.27,0.432,0.297,0.675
			c0.054,0.446,0.054,0.891,0.054,1.351C175.996,132.088,175.996,138.206,175.996,144.338L175.996,144.338z M350.6,224.502
			c-0.027,0.918-0.338,1.229-1.364,1.229c-2.701,0.014-5.403,0.014-8.104,0.014c-3.863,0-7.74,0-11.602-0.014
			c-0.446,0-0.891-0.068-1.324-0.149c-0.149-0.027-0.284-0.203-0.365-0.351c-0.081-0.149-0.135-0.338-0.135-0.513
			c-0.027-0.81-0.041-1.621-0.041-2.431c0-31.674,0-63.348,0-95.021c0-0.81,0-1.621,0.027-2.431c0.014-0.257,0.122-0.5,0.216-0.891
			c2.755-0.257,8.617-0.297,20.706-0.162c0.527,0,1.081-0.068,1.661,0.216c0.081,0.162,0.27,0.392,0.284,0.635
			c0.054,0.81,0.054,1.621,0.054,2.431c0,31.849,0,63.712,0,95.562C350.613,223.246,350.613,223.881,350.6,224.502L350.6,224.502z"
          />
        </g>
      </g>
    </svg>
  );
}

Artwrk12.props = {
  primary: string,
};

Artwrk12.defaultProps = {
  primary: "#000",
};
