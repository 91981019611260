import React from "react";
import { string } from "prop-types";

export default function Artwrk22(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      enable-background="new 0 0 400 400"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            stroke={primary}
            stroke-width="1.7055"
            stroke-miterlimit="10"
            d="M27.109,147.54c-5.903-0.308-8.895-0.332-14.944-0.097
			c-4.397-8.253-6.596-12.379-10.994-20.632c1.511-9.432,2.347-14.155,4.189-23.593c9.261-10.509,18.706-20.726,28.988-30.256
			c6.282,0.951,9.382,1.557,15.483,3.016c2.228,6.282,3.34,9.423,5.567,15.705c-7.115,6.017-10.625,9.224-17.524,16.032
			c-1.267-3.063-1.9-4.597-3.167-7.66c-1.219-0.214-1.83-0.315-3.056-0.509c-3.717,3.731-5.56,5.651-9.209,9.607
			c-0.383,1.874-0.571,2.813-0.94,4.688c1.368,2.958,2.052,4.437,3.42,7.395c1.232,0.042,1.845,0.067,3.071,0.13
			c7.107-7.705,10.731-11.337,18.097-18.159c5.975,1.235,8.919,1.98,14.708,3.703c3.256,9.184,4.884,13.777,8.141,22.962
			c-1.946,8.264-2.838,12.404-4.466,20.681c-8.29,8.006-16.075,16.157-23.733,24.771c-5.637-0.688-8.499-0.906-14.291-1.067
			c-2.857-5.363-4.285-8.043-7.142-13.406c6.062-7.694,9.158-11.355,15.465-18.311c1.286,2.782,1.931,4.171,3.217,6.953
			c1.192,0.151,1.785,0.232,2.971,0.406c3.27-3.545,4.922-5.266,8.252-8.609c0.3-1.725,0.452-2.588,0.765-4.313
			c-1.191-2.881-1.787-4.32-2.979-7.201c-1.159-0.272-1.74-0.404-2.907-0.657C37.17,136.065,33.769,139.749,27.109,147.54z
			 M50.774,136.836c-0.362,1.962-0.539,2.943-0.885,4.904c-3.6,3.602-5.383,5.461-8.912,9.298c-1.312-0.232-1.971-0.341-3.292-0.546
			c-1.229-2.645-1.844-3.967-3.072-6.611c-5.838,6.47-8.709,9.863-14.337,16.969c2.625,4.954,3.938,7.431,6.563,12.385
			c5.577,0.176,8.333,0.392,13.766,1.056c7.523-8.448,15.171-16.456,23.301-24.32c1.57-7.979,2.427-11.97,4.292-19.938
			c-3.169-8.886-4.754-13.328-7.924-22.214c-5.573-1.638-8.406-2.349-14.151-3.535c-7.385,6.857-11.019,10.508-18.142,18.255
			c-1.357-0.116-2.038-0.167-3.403-0.256c-1.536-3.306-2.304-4.96-3.84-8.266c0.402-2.047,0.605-3.071,1.024-5.119
			c3.954-4.3,5.951-6.383,9.985-10.418c1.38,0.17,2.07,0.261,3.441,0.458c1.206,2.931,1.808,4.397,3.013,7.328
			c6.396-6.275,9.644-9.245,16.224-14.843c-2.068-5.797-3.103-8.695-5.171-14.493c-5.874-1.382-8.855-1.961-14.896-2.872
			c-10.037,9.331-19.278,19.314-28.339,29.581c-1.771,9.072-2.58,13.611-4.044,22.678c4.228,7.978,6.342,11.966,10.57,19.943
			c5.87-0.124,8.771-0.052,14.501,0.333c6.71-7.833,10.139-11.535,17.115-18.513c1.313,0.238,1.966,0.363,3.27,0.627
			C48.769,131.958,49.437,133.584,50.774,136.836z M26.756,141.84c-4.921-0.349-7.409-0.434-12.435-0.413
			c-3.511-6.779-5.268-10.167-8.779-16.946c1.287-7.7,1.986-11.553,3.497-19.256c7.943-9.172,16.335-17.957,25.171-26.273
			c5.138,0.76,7.678,1.23,12.694,2.334c1.331,3.637,1.998,5.455,3.329,9.091c-4.274,3.732-6.393,5.669-10.589,9.687
			c-0.87-2.167-1.305-3.25-2.175-5.418c-2.383-0.336-3.582-0.485-5.99-0.742c-5.047,5-7.542,7.604-12.465,13.014
			c-0.718,3.519-1.065,5.277-1.734,8.796c2.08,4.373,3.12,6.56,5.2,10.932c2.353,0.159,3.523,0.257,5.851,0.495
			c6.971-7.658,10.526-11.271,17.752-18.066c4.885,0.997,7.297,1.581,12.059,2.909c2.714,7.415,4.072,11.122,6.786,18.537
			c-1.564,6.85-2.292,10.28-3.641,17.137c-7.396,6.964-14.425,14.315-21.074,21.996c-4.664-0.578-7.024-0.782-11.795-1.005
			c-1.602-3.093-2.402-4.639-4.004-7.732c3.632-4.483,5.472-6.66,9.192-10.88c0.894,1.88,1.342,2.821,2.236,4.701
			c2.241,0.285,3.355,0.447,5.571,0.808c4.316-4.742,6.503-7.023,10.928-11.407c0.633-3.334,0.961-5.003,1.644-8.337
			c-1.795-4.474-2.693-6.709-4.488-11.183c-2.263-0.45-3.401-0.656-5.69-1.031C36.861,130.473,33.446,134.122,26.756,141.84z"
          />
          <path
            stroke={primary}
            stroke-width="1.7055"
            stroke-miterlimit="10"
            d="M103.664,98.987c-0.331-1.373-0.495-2.059-0.826-3.432
			c5.662-3.249,8.516-4.749,14.26-7.51c1.852,9.167,2.779,13.749,4.63,22.916c-15.539,7.48-29.51,16.995-43.174,27.436
			c-2.429-7.222-3.644-10.832-6.073-18.054c1.435-1.151,2.154-1.718,3.597-2.834c-4.812-14.834-9.629-29.667-14.441-44.501
			c-1.61,1.246-2.413,1.879-4.014,3.164c-2.429-7.222-3.645-10.833-6.074-18.055c16.625-12.802,33.78-24.387,52.73-33.513
			c1.852,9.167,2.78,13.75,4.632,22.916c-6.308,3.032-9.442,4.683-15.66,8.25c-0.331-1.373-0.498-2.059-0.829-3.432
			c-4.887,2.804-7.315,4.289-12.139,7.421c1.485,5.451,2.228,8.175,3.713,13.625c1.079-0.7,1.619-1.047,2.701-1.73
			c-0.359-1.355-0.54-2.034-0.899-3.39c6.06-3.828,9.118-5.608,15.275-8.909c2.343,10.402,3.515,15.602,5.857,26.003
			c-5.795,3.106-8.674,4.785-14.377,8.387c-0.346-1.305-0.519-1.959-0.865-3.265c-1.035,0.653-1.551,0.986-2.582,1.655
			c1.472,5.4,2.208,8.1,3.68,13.5C97.108,102.83,99.285,101.499,103.664,98.987z M92.129,89.179
			c0.357,1.357,0.535,2.036,0.893,3.393c5.184-3.25,7.798-4.775,13.058-7.617c-2.208-9.727-3.313-14.588-5.521-24.315
			c-5.567,3.009-8.333,4.62-13.82,8.061c0.344,1.307,0.517,1.96,0.86,3.267c-1.602,1.004-2.401,1.515-3.995,2.557
			c-1.657-6.045-2.486-9.067-4.144-15.112c5.365-3.504,8.069-5.155,13.513-8.254c0.316,1.323,0.475,1.985,0.792,3.309
			c5.653-3.218,8.5-4.717,14.227-7.494c-1.719-8.435-2.578-12.653-4.297-21.088c-18.336,8.905-34.987,20.125-51.132,32.493
			c2.208,6.605,3.312,9.908,5.52,16.513c1.605-1.28,2.411-1.911,4.025-3.152c4.96,15.378,9.92,30.757,14.878,46.136
			c-1.441,1.108-2.16,1.67-3.593,2.813c2.192,6.557,3.287,9.836,5.479,16.393c13.325-10.131,26.945-19.382,42.041-26.717
			c-1.719-8.436-2.579-12.653-4.298-21.088c-5.194,2.519-7.776,3.877-12.902,6.796c0.329,1.374,0.493,2.063,0.821,3.437
			c-4.858,2.766-7.272,4.24-12.06,7.368c-1.671-6.095-2.506-9.142-4.177-15.237C89.824,90.634,90.591,90.143,92.129,89.179z
			 M117.377,107.833c-13.096,7.044-25.144,14.757-37.113,23.58c-1.199-3.685-1.798-5.526-2.997-9.211
			c1.426-1.106,2.141-1.65,3.574-2.723c-5.618-17.902-11.232-35.804-16.849-53.706c-1.635,1.223-2.451,1.844-4.078,3.105
			c-1.215-3.733-1.822-5.6-3.037-9.333C71.09,48.97,85.563,39.694,101.209,31.381c1.124,5.312,1.684,7.967,2.808,13.279
			c-3.219,1.62-4.823,2.465-8.019,4.224c-0.307-1.329-0.46-1.993-0.768-3.321c-8.048,4.43-12.035,6.864-19.914,12.167
			c2.542,9,3.813,13.5,6.355,22.5c3.937-2.649,5.917-3.917,9.899-6.337c-0.335-1.313-0.501-1.969-0.836-3.281
			c3.05-1.853,4.582-2.745,7.656-4.464c1.557,6.63,2.335,9.945,3.893,16.575c-2.958,1.654-4.431,2.511-7.364,4.293
			c-0.348-1.363-0.521-2.044-0.869-3.407c-3.892,2.365-5.827,3.604-9.674,6.193c2.557,9.05,3.834,13.577,6.391,22.627
			c6.907-4.649,10.402-6.782,17.457-10.666c-0.319-1.379-0.479-2.07-0.797-3.45c2.847-1.567,4.276-2.318,7.144-3.762
			C115.693,99.865,116.254,102.521,117.377,107.833z"
          />
          <path
            stroke={primary}
            stroke-width="1.7055"
            stroke-miterlimit="10"
            d="M142.362,61.11c1.04,7.866,1.559,11.799,2.599,19.665
			c1.55-0.487,2.326-0.721,3.879-1.174c0.98,7.995,1.472,11.991,2.452,19.985c-9.729,2.835-14.565,4.607-24.126,8.857
			c-1.461-7.818-2.193-11.728-3.654-19.545c1.527-0.679,2.292-1.009,3.823-1.654c-2.829-16.005-5.661-32.009-8.494-48.014
			c-1.709,0.719-2.563,1.089-4.266,1.846c-1.461-7.818-2.191-11.728-3.653-19.546c9.636-4.284,14.5-6.132,24.279-9.238
			c8.605,11.557,16.683,23.304,24.414,35.462c-0.751-8.327-1.127-12.492-1.879-20.819c-1.745,0.374-2.617,0.57-4.358,0.982
			c-0.802-8.042-1.203-12.063-2.005-20.104c9.862-2.338,19.888-3.981,29.981-4.907c0.313,8.122,0.469,12.185,0.782,20.306
			c-1.755,0.16-2.632,0.249-4.386,0.448c0.787,16.544,1.579,33.088,2.368,49.633c1.571-0.178,2.358-0.259,3.931-0.402
			c0.313,8.122,0.469,12.183,0.782,20.305c-8.241,0.753-12.358,1.377-20.548,3.119C157.382,84.304,150.259,72.501,142.362,61.11z
			 M164.57,95.297c7.838-1.649,11.777-2.249,19.661-2.995c-0.293-7.363-0.44-11.045-0.734-18.408
			c-1.569,0.148-2.353,0.231-3.921,0.414c-0.839-17.127-1.681-34.253-2.523-51.38c1.757-0.205,2.635-0.298,4.394-0.465
			c-0.296-7.417-0.443-11.125-0.74-18.542c-9.573,0.907-19.082,2.464-28.446,4.646c0.721,7.348,1.08,11.021,1.801,18.369
			c1.745-0.406,2.618-0.599,4.366-0.967c0.886,9.996,1.33,14.994,2.216,24.989c-8.134-12.911-16.65-25.354-25.766-37.592
			c-9.264,2.963-13.873,4.71-23.008,8.733c1.323,7.146,1.983,10.719,3.307,17.865c1.708-0.752,2.563-1.12,4.276-1.833
			c2.903,16.583,5.811,33.165,8.714,49.747c-1.529,0.637-2.292,0.964-3.816,1.636c1.313,7.094,1.97,10.64,3.283,17.734
			c9.115-4.014,13.722-5.699,22.988-8.431c-0.899-7.246-1.348-10.87-2.247-18.115c-1.549,0.457-2.322,0.693-3.868,1.185
			c-1.278-9.563-1.918-14.343-3.196-23.906C149.565,70.167,157.257,82.523,164.57,95.297z M156.969,22.173
			c-0.376-4.137-0.565-6.204-0.942-10.34c8.551-1.847,12.845-2.548,21.445-3.504c0.195,4.167,0.293,6.249,0.489,10.415
			c-1.772,0.197-2.659,0.306-4.43,0.541c1.111,19.814,2.22,39.628,3.332,59.442c1.552-0.207,2.327-0.301,3.881-0.474
			c0.192,4.112,0.289,6.169,0.481,10.281c-6.025,0.669-9.034,1.134-15.032,2.326c-9.273-16.286-19.199-31.869-30.092-47.119
			c2.023,14.355,4.044,28.711,6.068,43.066c1.529-0.512,2.294-0.758,3.826-1.236c0.53,4.041,0.794,6.061,1.325,10.102
			c-7.101,2.215-10.635,3.509-17.648,6.472c-0.706-3.977-1.059-5.966-1.765-9.942c1.513-0.639,2.271-0.949,3.789-1.555
			c-3.23-19.255-6.458-38.51-9.69-57.765c-1.731,0.69-2.595,1.046-4.321,1.775c-0.716-4.029-1.074-6.043-1.79-10.072
			c6.79-2.869,10.207-4.157,17.067-6.438c11.593,15.474,22.168,31.318,32.073,47.923c-1.22-14.934-2.439-29.868-3.657-44.802
			C159.613,21.613,158.731,21.792,156.969,22.173z"
          />
          <path
            stroke={primary}
            stroke-width="1.7055"
            stroke-miterlimit="10"
            d="M190.453,92.773c-0.2-8.13-0.302-12.194-0.502-20.324
			c1.574-0.092,2.362-0.129,3.937-0.188c-0.255-16.584-0.509-33.168-0.771-49.752c-1.758,0.064-2.636,0.106-4.393,0.209
			c-0.201-8.13-0.303-12.196-0.504-20.326c10.031-0.588,20.101-0.456,30.113,0.394c-0.289,8.124-0.434,12.187-0.724,20.31
			c-1.756-0.149-2.633-0.214-4.39-0.325c-0.44,16.575-0.875,33.15-1.32,49.725c1.574,0.099,2.36,0.158,3.934,0.291
			c-0.291,8.124-0.433,12.185-0.724,20.309C205.255,92.261,200.312,92.196,190.453,92.773z M211.287,73.409
			c0.433-17.163,0.861-34.326,1.295-51.488c1.76,0.105,2.638,0.166,4.396,0.31c0.255-7.421,0.383-11.13,0.637-18.552
			c-9.501-0.774-19.054-0.905-28.572-0.383c0.172,7.427,0.256,11.141,0.428,18.567c1.76-0.096,2.643-0.135,4.403-0.193
			c0.238,17.172,0.477,34.344,0.715,51.516c-1.571,0.052-2.359,0.087-3.93,0.173c0.17,7.372,0.256,11.058,0.426,18.431
			c9.395-0.515,14.102-0.451,23.491,0.314c0.254-7.367,0.383-11.05,0.636-18.417C213.642,73.559,212.857,73.503,211.287,73.409z
			 M193.838,87.591c-0.066-4.121-0.101-6.182-0.167-10.303c1.556-0.06,2.335-0.081,3.892-0.106
			c-0.135-19.887-0.274-39.773-0.416-59.66c-1.776,0.029-2.665,0.054-4.44,0.122c-0.068-4.176-0.101-6.264-0.169-10.439
			c8.615-0.331,12.93-0.274,21.541,0.287c-0.114,4.173-0.172,6.26-0.286,10.433c-1.774-0.116-2.662-0.164-4.438-0.241
			c-0.358,19.88-0.726,39.761-1.091,59.641c1.557,0.067,2.335,0.109,3.891,0.211c-0.113,4.119-0.169,6.179-0.282,10.297
			C204.662,87.363,201.051,87.314,193.838,87.591z"
          />
          <path
            stroke={primary}
            stroke-width="1.7055"
            stroke-miterlimit="10"
            d="M237.403,5.277c11.345,2.324,22.128,5.121,33.182,8.574
			c3.995,7.31,5.918,11.016,9.602,18.504c-3.366,19.808-6.73,39.617-10.103,59.424c-5.004,3.978-7.443,6.057-12.196,10.377
			c-8.859-3.029-17.946-5.387-27.166-7.021c-3.563-6.471-5.423-9.662-9.282-15.937c1.016-20.417,2.026-40.834,3.037-61.251
			C229.512,12.72,232.099,10.182,237.403,5.277z M231.071,94.244c10.733,1.914,16.083,3.292,26.672,6.893
			c4.582-4.164,6.93-6.17,11.744-10.017c3.292-19.558,6.581-39.117,9.874-58.675c-3.554-7.193-5.404-10.754-9.247-17.782
			c-10.804-3.364-21.356-6.099-32.438-8.382c-5.102,4.715-7.593,7.152-12.444,12.167c-1.028,20.143-2.063,40.285-3.093,60.427
			C225.849,84.93,227.636,88.007,231.071,94.244z M239.064,10.631c9.76,1.824,19.391,4.321,28.814,7.445
			c3.271,5.966,4.853,8.983,7.912,15.071c-2.943,18.295-5.885,36.59-8.83,54.884c-4.104,3.349-6.115,5.084-10.049,8.663
			c-9.585-3.177-14.422-4.423-24.124-6.233c-2.949-5.358-4.474-8.009-7.624-13.236c1.096-18.773,2.184-37.547,3.275-56.321
			C232.599,16.686,234.725,14.629,239.064,10.631z M259.464,26.097c-5.689-1.677-8.544-2.414-14.269-3.683
			c-2.011,1.801-3.008,2.714-4.98,4.564c-1.339,16.004-2.68,32.008-4.019,48.013c1.417,2.579,2.112,3.876,3.484,6.481
			c5.019,1.112,7.522,1.757,12.51,3.228c1.889-1.762,2.846-2.631,4.774-4.343c2.115-15.803,4.238-31.605,6.359-47.408
			C261.804,30.194,261.031,28.823,259.464,26.097z M251.525,80.365c-4.069-1.172-6.112-1.7-10.202-2.636
			c-0.806-1.555-1.211-2.33-2.032-3.876c1.343-14.817,2.689-29.635,4.035-44.452c1.158-1.064,1.74-1.592,2.912-2.64
			c4.582,1.049,6.866,1.639,11.426,2.952c0.888,1.58,1.329,2.372,2.201,3.961c-1.863,14.684-3.729,29.366-5.595,44.049
			C253.165,78.772,252.617,79.3,251.525,80.365z M257.135,30.389c-4.206-1.202-6.313-1.747-10.538-2.724
			c-1.073,0.958-1.606,1.442-2.669,2.415c-1.332,14.455-2.664,28.91-3.998,43.364c0.757,1.371,1.132,2.058,1.876,3.437
			c3.786,0.875,5.675,1.364,9.443,2.44c0.957-0.94,1.438-1.406,2.406-2.333c1.797-14.335,3.594-28.67,5.389-43.006
			C258.286,32.542,257.904,31.823,257.135,30.389z"
          />
          <path
            stroke={primary}
            stroke-width="1.7055"
            stroke-miterlimit="10"
            d="M301.6,101.458c-1.918,7.58-2.874,11.369-4.792,18.948
			c-9.331-5.622-14.071-8.079-23.639-12.286c1.447-7.824,2.169-11.736,3.616-19.561c1.527,0.672,2.29,1.017,3.813,1.723
			c3.093-15.888,6.202-31.772,9.291-47.661c-1.699-0.788-2.55-1.174-4.255-1.923c1.446-7.825,2.169-11.738,3.614-19.563
			c15.909,7.013,30.574,15.832,44.982,25.499c2.958,8.45,4.356,12.704,6.99,21.237c-2.693,8.293-4.039,12.437-6.732,20.729
			c-2.4,0.812-3.593,1.239-5.959,2.128c1.039,3.362,1.546,5.046,2.532,8.419c-2.395,7.371-3.592,11.057-5.986,18.428
			c1.442,1.118,2.162,1.685,3.597,2.837c-2.431,7.22-3.646,10.831-6.078,18.051c-7.618-6.117-11.493-8.922-19.34-14.021
			c2.819-10.333,5.641-20.664,8.458-30.997c-0.5-1.62-0.754-2.429-1.267-4.046c-3.535-2.249-5.311-3.326-8.883-5.386
			c-1.478,6.098-2.218,9.145-3.695,15.243C299.363,100.121,300.11,100.561,301.6,101.458z M310.97,88.708
			c0.64,1.817,0.958,2.727,1.581,4.548c-2.818,10.237-5.628,20.477-8.439,30.716c7.399,4.844,11.057,7.491,18.253,13.239
			c2.195-6.554,3.291-9.832,5.486-16.386c-1.433-1.144-2.151-1.706-3.592-2.815c2.431-7.524,3.646-11.289,6.077-18.813
			c-1.077-3.679-1.632-5.515-2.772-9.18c2.578-0.986,3.879-1.457,6.498-2.35c2.572-7.962,3.858-11.941,6.43-19.903
			c-2.622-8.264-4.012-12.379-6.941-20.559c-14.007-9.375-28.265-17.953-43.695-24.812c-1.332,7.142-1.996,10.713-3.329,17.854
			c1.707,0.758,2.559,1.146,4.26,1.942c-3.23,16.438-6.468,32.874-9.699,49.311c-1.519-0.71-2.28-1.058-3.804-1.734
			c-1.323,7.089-1.985,10.634-3.31,17.723c9.111,4.044,13.628,6.389,22.525,11.714c1.729-6.878,2.591-10.318,4.32-17.197
			c-1.487-0.891-2.231-1.328-3.726-2.185c1.641-6.814,2.46-10.22,4.101-17.034C305.126,85.043,307.081,86.227,310.97,88.708z
			 M277.461,104.83c0.768-3.949,1.148-5.923,1.916-9.873c1.505,0.695,2.256,1.051,3.756,1.781
			c3.883-18.968,7.755-37.939,11.631-56.909c-1.711-0.832-2.568-1.238-4.285-2.031c0.777-4.001,1.165-6.002,1.941-10.004
			c13.449,6.606,26.045,13.864,38.568,22.092c2.457,6.921,3.629,10.401,5.864,17.383c-2.102,6.687-3.149,10.029-5.251,16.716
			c-3.271,1.167-4.892,1.787-8.099,3.098c1.449,4.607,2.148,6.917,3.496,11.545c-2.639,8.395-3.961,12.593-6.6,20.987
			c1.433,1.074,2.148,1.619,3.573,2.726c-1.201,3.683-1.8,5.524-3.001,9.207c-5.214-4.053-7.852-5.962-13.17-9.549
			c2.675-9.445,5.344-18.892,8.023-28.336c-0.972-3.142-1.471-4.711-2.49-7.841c-5.624-3.641-8.461-5.342-14.176-8.509
			c-2.306,9.904-3.461,14.856-5.767,24.76c1.484,0.823,2.226,1.243,3.704,2.099c-0.939,3.86-1.41,5.79-2.35,9.649
			C287.896,109.852,284.437,108.052,277.461,104.83z M321.454,73.778c1.224-4.325,1.836-6.487,3.06-10.812
			c-1.104-3.171-1.67-4.754-2.821-7.911c-6.041-3.91-9.086-5.739-15.224-9.14c-2.02,8.679-3.03,13.018-5.051,21.696
			c5.845,3.239,8.747,4.98,14.5,8.705C318.115,75.268,319.225,74.76,321.454,73.778z M319.228,57.878
			c0.712,1.863,1.065,2.795,1.761,4.665c-0.837,3.046-1.256,4.569-2.093,7.616c-1.35,0.59-2.022,0.892-3.36,1.506
			c-4.05-2.584-6.085-3.817-10.18-6.164c1.346-5.593,2.02-8.392,3.365-13.985C312.947,53.937,315.048,55.211,319.228,57.878z
			 M315.469,70.593c1.139-0.566,1.709-0.846,2.856-1.395c0.723-2.65,1.085-3.974,1.808-6.624c-0.561-1.659-0.845-2.487-1.418-4.143
			c-3.802-2.418-5.712-3.578-9.553-5.793c-1.194,4.929-1.79,7.393-2.984,12.322C309.913,67.115,311.771,68.241,315.469,70.593z"
          />
          <path
            stroke={primary}
            stroke-width="1.7055"
            stroke-miterlimit="10"
            d="M348.196,120.655c-1.949-8.511-3.004-12.762-5.274-21.239
			c3.324-9.375,4.985-14.062,8.309-23.437c6.102-1.458,9.202-2.065,15.483-3.016c10.28,9.532,19.725,19.746,28.984,30.256
			c1.843,9.438,2.679,14.16,4.189,23.591c-2.946,5.53-4.418,8.296-7.364,13.827c-6.456-8.195-9.755-12.096-16.474-19.5
			c1.367-2.959,2.051-4.439,3.418-7.398c-0.333-1.833-0.504-2.751-0.851-4.584c-3.648-3.956-5.49-5.877-9.207-9.607
			c-1.264,0.155-1.894,0.238-3.15,0.415c-1.266,3.064-1.9,4.596-3.167,7.66c0.429,1.774,0.64,2.661,1.055,4.436
			c7.239,7.266,10.797,11.119,17.762,19.268c1.552,9.098,2.246,13.646,3.473,22.717c-4.308,8.085-6.462,12.127-10.77,20.213
			c-5.792,0.162-8.654,0.38-14.291,1.068c-7.659-8.613-15.443-16.765-23.732-24.772c-1.627-8.276-2.521-12.416-4.467-20.68
			c2.16-6.091,3.24-9.137,5.4-15.229c6.68,5.649,9.974,8.657,16.452,15.044c-1.19,2.881-1.786,4.323-2.977,7.205
			c0.348,1.759,0.519,2.638,0.853,4.397c3.33,3.342,4.979,5.063,8.249,8.608c1.152-0.209,1.73-0.309,2.89-0.498
			c1.286-2.782,1.929-4.173,3.215-6.956c-0.275-1.77-0.417-2.656-0.705-4.426C358.704,130.651,355.238,127.178,348.196,120.655z
			 M363.376,150.492c-1.321,0.205-1.98,0.316-3.293,0.547c-3.528-3.837-5.31-5.697-8.909-9.298
			c-0.346-1.962-0.523-2.943-0.886-4.905c1.13-2.748,1.695-4.122,2.825-6.869c-6.012-5.898-9.064-8.688-15.249-13.95
			c-2,5.607-3.001,8.41-5.001,14.017c1.865,7.967,2.723,11.959,4.292,19.938c8.129,7.865,15.777,15.872,23.301,24.319
			c5.433-0.664,8.187-0.878,13.765-1.055c4.161-7.851,6.241-11.775,10.401-19.626c-1.19-8.722-1.859-13.095-3.352-21.841
			c-6.99-8.159-10.563-12.016-17.829-19.286c-0.432-1.983-0.652-2.974-1.101-4.956c1.412-3.435,2.12-5.153,3.532-8.588
			c1.372-0.196,2.062-0.287,3.441-0.457c4.033,4.035,6.027,6.119,9.98,10.418c0.419,2.047,0.624,3.07,1.026,5.118
			c-1.311,2.821-1.965,4.23-3.275,7.051c6.212,6.883,9.266,10.496,15.253,18.055c2.714-5.122,4.072-7.683,6.786-12.805
			c-1.465-9.067-2.273-13.607-4.046-22.68c-9.061-10.266-18.302-20.249-28.338-29.579c-6.04,0.912-9.022,1.489-14.896,2.872
			c-3.221,9.029-4.833,13.543-8.054,22.572c2.146,8.267,3.144,12.413,4.986,20.708c7.088,6.582,10.575,10.085,17.412,17.521
			c0.352,1.975,0.526,2.961,0.862,4.936C365.559,145.798,364.83,147.362,363.376,150.492z M351.463,118
			c-1.578-7.137-2.423-10.703-4.223-17.821c2.766-7.557,4.149-11.335,6.915-18.892c5.017-1.104,7.557-1.574,12.693-2.333
			c8.836,8.316,17.225,17.104,25.172,26.272c1.511,7.703,2.208,11.557,3.495,19.256c-1.689,3.262-2.531,4.893-4.221,8.155
			c-3.935-4.856-5.928-7.212-9.957-11.783c0.978-2.055,1.464-3.083,2.441-5.138c-0.707-3.561-1.07-5.34-1.827-8.9
			c-4.925-5.406-7.421-8.009-12.469-13.006c-2.369,0.296-3.547,0.464-5.891,0.837c-1.851,4.612-2.775,6.918-4.626,11.53
			c0.764,3.412,1.132,5.118,1.847,8.531c7.146,7.075,10.661,10.828,17.542,18.767c1.271,7.426,1.85,11.137,2.9,18.546
			c-3.445,6.652-5.167,9.978-8.612,16.629c-4.771,0.223-7.131,0.428-11.795,1.006c-6.65-7.679-13.677-15.034-21.075-21.996
			c-1.348-6.857-2.075-10.287-3.639-17.137c1.262-3.448,1.892-5.171,3.153-8.619c3.946,3.446,5.904,5.234,9.778,8.944
			c-0.796,1.983-1.192,2.975-1.988,4.958c0.647,3.301,0.959,4.952,1.559,8.252c4.425,4.38,6.612,6.661,10.929,11.399
			c2.249-0.326,3.382-0.47,5.656-0.717c2.018-4.241,3.025-6.362,5.043-10.603c-0.592-3.431-0.901-5.147-1.542-8.58
			C361.944,128.117,358.488,124.605,351.463,118z"
          />
        </g>
      </g>
      <text
        transform="matrix(0.6479 0 0 1 145.252 400.0801)"
        font-family="'CollegiateBlackFLF'"
        font-size="106.8506"
      >
        2021
      </text>
      <text
        transform="matrix(0.565 -0.2264 0.1659 0.9861 130.2676 303.0117)"
        font-family="'CollegiateInsideFLF'"
        font-size="46.9086"
      >
        of
      </text>
      <text
        transform="matrix(0.7155 -0.3012 0.174 0.9847 97.3325 243.9355)"
        font-family="'BrushScriptMT'"
        font-size="124.5481"
      >
        Class
      </text>
      <path
        fill="none"
        stroke={primary}
        stroke-width="3.4109"
        stroke-miterlimit="10"
        d="M107.271,264.381
	c0,0,60.859-30.371,111.651-33.9c0,0,38.437-9.184,43.471-61.448c0,0,28.371,55.09-19.676,95.348c0,0-11.43,11.524-17.39,10.593
	c0,0-78.705,20.48-91.059,59.325C134.269,334.299,131.066,306.051,107.271,264.381z"
      />
    </svg>
  );
}

Artwrk22.props = {
  primary: string,
};

Artwrk22.defaultProps = {
  primary: "#000",
};
