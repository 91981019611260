import React from "react";
import { string } from "prop-types";

export default function Artwrk22(props) {
  const { primary, secondary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      enable-background="new 0 0 400 400"
      xmlSpace="preserve"
    >
      <polygon
        points="246.095,111.737 266.332,111.737 266.332,189.867 274.142,196.259 267.398,201.23 266.686,280.78 235.799,280.78 
	235.799,202.297 229.761,194.839 237.219,188.09 236.51,149.736 226.924,149.381 226.924,118.485 "
      />
      <polyline
        fill="none"
        stroke="#000000"
        stroke-width="2.0975"
        stroke-miterlimit="10"
        points="141.857,86.135 35.251,189.973 
	145.528,305.175 "
      />
      <polyline
        fill="none"
        stroke="#000000"
        stroke-width="2.0975"
        stroke-miterlimit="10"
        points="261.745,303.428 367.802,194.723 
	258.229,86.47 "
      />
      <polygon
        fill={primary}
        points="115.869,82.813 0,190.848 111.671,307.861 130.738,305.66 22.545,190.848 135.267,82.813 "
      />
      <polygon
        fill={primary}
        points="288.621,305.009 400,192.348 286.386,82.552 264.91,83.427 377.476,193.266 269.237,305.798 "
      />
      <path
        fill="#5A6360"
        d="M69.048,18.307c0,0,169.444,23.6,256.783,0l16.734,51.919c0,0-145.911,41.431-285.023,0
	C57.542,70.227,66.956,41.907,69.048,18.307z"
      />
      <path
        d="M79.468,20.929c0,0,115.579,18.355,237.957,0c0,0,3.989,41.431,13.761,47.723c0,0-104.215,41.431-258.505,4.197
	C72.681,72.849,75.808,48.724,79.468,20.929z"
      />
      <path
        d="M89.667,284.514L17.234,282.1l17.433,30.068l-18.131,31.99l38.004-0.99c0,0-1.78-24.749,6.344-27.34l51.011-7.967
	L89.667,284.514z"
      />
      <path
        d="M312.775,280.845l73.97-1.979l-16.73,30.506l16.73,33.038l-38.952-1.34c0,0,1.326-24.281-6.821-26.873l-52.351-9.188
	L312.775,280.845z"
      />
      <path
        fill="#5A6360"
        d="M53.883,317.237c0,0,170.395-36.711,297.797,0l-9.436,60.311c0,0-166.265-26.531-274.269,7.558
	L53.883,317.237z"
      />
      <path
        d="M68.631,318.811c0,0,156.239-34.614,278.922,0c0,0-16.95,10.314-15.039,54.017c0,0-134.033-22.55-251.824,4.721
	l-16.777-57.164L68.631,318.811z"
      />
      <path
        fill={primary}
        d="M201.583,398.787l41.984-30.68c0,0-57.078-3.9-69.705,0.672L201.583,398.787"
      />
      <path
        fill={primary}
        d="M170.568,27.525l28.398-25.738l31.467,26.831C230.434,28.618,175.199,29.14,170.568,27.525z"
      />
      <path
        d="M285.174,82.552l44.44-6.64l0.536-0.091c0,0,1.627,4.106-9.865,13.59c0,0-12.94,9.097-17.574,10.427l-17.476-16.929
	L285.174,82.552z"
      />
      <path
        d="M112.747,82.847l-43.928-6.56l-0.951-0.16c0,0-1.451,4.609,10.039,14.095c0,0,12.941,9.097,17.577,10.427l18.972-17.615
	L112.747,82.847z"
      />
      <path
        d="M62.106,54.653l-35.458-1.18l17.187,26.718l-19.628,27.271H90.49l2.113-2.707l21.952-20.519l1.871-0.899l-1.971-0.303
	L96.292,99.866l-3.557,2.773c0,0-24.289-6.979-36.137-31.629l0.944-0.784C57.542,70.227,61.767,55.938,62.106,54.653z"
      />
      <path
        fill="#5a6360"
        d="M122.185,83.214c0,0-58.57-10.038-64.643-12.988c-5.489-2.666,10.549,26.585,35.193,32.413l2.574-2.495
	c0,0-26.744-11.956-27.441-24.018C67.868,76.127,120.716,85.059,122.185,83.214z"
      />
      <path
        d="M337.168,53.473l34.638-1.049l-17.187,26.718l19.629,27.269h-66.284l-2.934-2.152l-21.953-20.52l0.876-0.832l1.221-0.357
	l16.988,16.266l3.557,2.773c0,0,24.999-6.716,36.847-31.364l-0.648-0.742C341.917,69.484,337.505,54.759,337.168,53.473z"
      />
      <path
        fill="#5A6360"
        d="M275.834,82.909c0,0,60.627-11.055,65.648-12.682c5.803-1.88-14.321,27.867-38.962,33.695l-2.353-2.491
	c0,0,29.287-13.548,29.982-25.61C330.15,75.82,277.303,84.752,275.834,82.909z"
      />
      <polygon
        fill="#5A6360"
        points="336.034,49.958 376.349,49.958 359.439,79.418 381.404,109.909 313.944,110.426 309.804,106.412 
	374.248,106.412 354.619,79.143 371.806,52.424 337.168,53.473 "
      />
      <polygon
        fill="#5A6360"
        points="64.497,49.304 20.343,50.298 40.216,81.415 19.472,109.734 87.586,110.395 91.507,107.462 
	25.225,107.462 44.852,80.191 27.665,53.473 63.124,54.653 "
      />
      <path
        fill="#5A6360"
        d="M83.253,277.777H12.179l17.782,34.391l-18.653,35.486l43.408,1.049l9.588,36.711l3.596-0.309l-14.056-67.868
	l6.773-1.358c0,0,11.969-18.649,33.453-26.737c0,0-15.299-7.684-39.354,25.648c0,0-7.323,9.787-0.872,26.396l0.697,1.982
	l-38.004,0.99l18.131-31.99L17.234,282.1l72.432,2.414L83.253,277.777z"
      />
      <path
        fill="#5A6360"
        d="M318.036,275.256l72.225,0.425l-17.825,34.389l18.7,35.487l-43.517,1.049l-8.717,28.059l-3.669-1.313
	l12.319-57.437l-6.581-1.718c0,0-11.269-19.063-32.808-27.155c0,0,17.372-10.131,41.486,23.202c0,0,8.215,14.51-1.157,28.844
	l-0.7,1.982l38.099,0.989l-18.176-31.99l17.479-30.068l-73.872,2.045L318.036,275.256z"
      />
      <polygon
        points="65.613,324.96 55.594,331.596 67.555,331.596 68.253,333.978 57.481,340.487 70.159,340.487 70.722,342.41 
	61.254,348.111 72.391,348.111 62.305,353.185 73.876,353.185 64.274,360.591 75.645,359.229 65.233,367.321 77.104,364.21 
	66.095,371.486 79.234,371.486 64.274,381.409 81.009,377.548 "
      />
      <polygon
        points="80.485,20.929 72.482,20.163 84.619,27.124 84.066,30.153 71.525,29.186 82.93,36.647 81.145,38.451 70.135,37.585 
	80.335,44.154 69.186,42.417 78.608,49.149 66.502,49.594 76.552,55.103 65.32,55.556 74.856,60.008 65.564,59.668 72.381,67.173 
	61.939,66.808 73.698,72.849 "
      />
      <polygon
        points="340.057,316.792 352.796,324.85 340.805,324.85 340.104,327.23 350.905,333.742 338.194,333.742 337.632,335.665 
	347.122,341.364 335.958,341.364 346.069,346.438 334.47,346.438 344.097,353.845 332.694,352.482 343.134,360.576 331.232,357.465 
	342.271,364.74 329.098,364.74 344.097,374.664 327.318,372.027 "
      />
      <polygon
        points="316.534,22.412 327.368,22.747 316.674,28.12 316.942,30.218 329.014,30.262 317.675,35.956 317.896,37.65 
	328.494,37.674 318.538,42.675 329.456,41.95 319.11,47.146 330.469,48.388 319.794,52.474 332.132,53.865 320.354,56.864 
	332.923,57.375 321.176,63.276 338.27,63.998 320.825,68.915 "
      />
      <g>
        <path
          fill={secondary}
          d="M122.042,329.002c0-1.921-0.498-3.095-1.495-3.521c-0.996-0.426-2.238-0.639-3.726-0.639
		c-3.062,0-5.838,0.604-8.329,1.809c-2.491,1.207-3.737,2.654-3.737,4.345c0,1.214,0.552,2.271,1.657,3.174
		c1.104,0.903,3.512,1.896,7.224,2.979c3.596,1.04,6.155,2.113,7.679,3.218c1.523,1.105,2.285,2.438,2.285,3.998
		c0,1.574-1.061,3.275-3.184,5.103c-2.123,1.827-4.683,3.218-7.679,4.171c-2.997,0.953-5.542,1.43-7.636,1.43
		c-1.748,0-3.073-0.205-3.975-0.617s-1.354-0.929-1.354-1.549c0-0.434,0.133-0.965,0.4-1.593c0.267-0.629,0.538-0.942,0.813-0.942
		c0.289,0,0.585,0.051,0.888,0.151c1.545,0.578,3.076,0.866,4.592,0.866c2.729,0,5.625-0.798,8.686-2.394
		c3.062-1.597,4.592-3.088,4.592-4.475c0-0.592-0.466-1.159-1.397-1.701c-0.932-0.541-3.29-1.411-7.073-2.61
		c-4.693-1.488-7.578-2.937-8.654-4.345s-1.614-2.893-1.614-4.452c0-3.005,1.726-5.428,5.177-7.27
		c3.451-1.842,7.062-2.763,10.831-2.763c2.498,0,4.419,0.422,5.762,1.268c1.343,0.845,2.014,1.78,2.014,2.806
		c0,0.491-0.151,1.206-0.455,2.146C124.035,328.532,123.27,329.002,122.042,329.002z"
        />
        <path
          fill={secondary}
          d="M154.166,325.231c-0.043,0.015-0.437,0.008-1.181-0.021c-0.744-0.029-1.505,0.043-2.285,0.217
		c-0.78,0.188-1.473,0.303-2.08,0.347c-0.419,0.029-0.874,0.087-1.365,0.173c-0.621,0.116-1.849,0.181-3.683,0.195
		c-2.758,0.043-4.495,0.231-5.209,0.563s-1.115,1.019-1.202,2.059c-0.072,0.794-0.224,1.668-0.455,2.621
		c-0.708,2.847-1.062,4.738-1.062,5.678l0.325,0.021c0.679,0.043,2.05-0.051,4.116-0.282c2.065-0.23,3.61-0.462,4.636-0.693
		c2.108-0.505,3.718-0.758,4.831-0.758c1.805,0,2.708,0.483,2.708,1.451c0,1.185-0.603,1.911-1.809,2.178
		c-1.206,0.268-3.723,0.61-7.549,1.029c-4.679,0.521-7.173,0.986-7.484,1.397c-0.311,0.412-0.639,1.737-0.986,3.977
		c-0.346,2.238-0.52,3.669-0.52,4.29v0.238c0.072,0.723,0.534,1.134,1.386,1.234l0.39,0.022c0.794,0.043,1.74-0.022,2.837-0.195
		l4.159-0.606c1.545-0.217,3.112-0.47,4.7-0.759c1.979-0.419,3.235-0.628,3.769-0.628c0.404,0,0.856,0.17,1.354,0.509
		c0.498,0.34,0.748,0.878,0.748,1.614c0,0.534-0.13,0.903-0.39,1.105c-1.473,0.649-3.372,1.011-5.697,1.083
		c-2.007,0.072-5.142,0.411-9.401,1.019c-2.152,0.317-3.567,0.477-4.246,0.477c-1.689,0-2.748-0.134-3.173-0.401
		c-0.426-0.267-0.682-0.921-0.769-1.961l-0.086-0.736v-0.087c0-0.23,0.13-0.7,0.39-1.408c0.361-1.025,0.783-2.976,1.268-5.85
		c0.483-2.875,0.797-5.149,0.942-6.826c0.072-0.577,0.188-1.278,0.347-2.102c0.635-3.452,0.953-6.182,0.953-8.19
		c0-0.101-0.145-0.368-0.433-0.802c-0.072-0.086-0.123-0.158-0.152-0.216c-0.101-0.333-0.195-0.607-0.281-0.824
		c-0.102-0.202-0.152-0.44-0.152-0.715v-0.217c0-0.317,0.195-0.599,0.585-0.845c0.39-0.245,0.765-0.368,1.126-0.368h0.065
		c0.606,0,1.3,0.043,2.08,0.13h0.086c0.332,0,1.935-0.137,4.809-0.412c2.873-0.273,6.303-0.75,10.289-1.43
		c0.418-0.072,0.671-0.108,0.758-0.108l0.325-0.021c1.126-0.072,1.902,0.04,2.329,0.336c0.426,0.296,0.689,0.87,0.791,1.723
		c0,0.404-0.188,0.794-0.563,1.17C154.678,325.001,154.382,325.203,154.166,325.231z"
        />
        <path
          fill={secondary}
          d="M181.512,329.552c-1.065,0-2.228,0.697-3.487,2.09c-1.26,1.394-3.18,4.518-5.76,9.372
		c-3.4,6.515-5.596,10.223-6.589,11.124c-0.994,0.901-1.818,1.353-2.473,1.353c-2.417,0-3.625-1.168-3.625-3.503
		c0-0.983,0.164-2.377,0.492-4.18c1.126-6.187,1.689-12.711,1.689-19.574c0-0.778,0.333-1.644,0.999-2.597
		c0.666-0.952,1.295-1.429,1.889-1.429c1.065,0,1.823,0.328,2.273,0.983c0.45,0.655,0.676,1.978,0.676,3.964
		c0,2.069-0.614,6.33-1.843,12.783c-0.41,2.131-0.614,3.483-0.614,4.057c0,0.327,0.082,0.491,0.246,0.491
		c0.308,0,1.73-2.447,4.271-7.344c2.56-4.979,4.802-8.481,6.728-10.51c1.925-2.028,4.044-3.042,6.359-3.042
		c1.843,0,3.343,0.84,4.5,2.52c1.157,1.681,2.002,4.743,2.535,9.188c0.573,5.06,1.09,8.21,1.551,9.448
		c0.461,1.24,1.193,1.859,2.197,1.859c0.634,0,1.433-0.225,2.396-0.676c0.164-0.082,0.287-0.123,0.369-0.123
		c0.88,0,1.321,0.513,1.321,1.536c0,0.902-0.604,1.859-1.813,2.874c-1.208,1.014-2.663,1.521-4.362,1.521
		c-1.741,0-3.087-0.691-4.04-2.074s-1.705-5.075-2.258-11.077C184.563,332.563,183.355,329.552,181.512,329.552z"
        />
        <path
          fill={secondary}
          d="M209.877,324.474c-0.086,0.563-0.57,2.578-1.451,6.045c-0.42,1.633-0.693,3.005-0.824,4.117
		c-0.086,0.751-0.193,1.567-0.324,2.448c-0.578,3.697-0.865,6.226-0.865,7.583c0,2.037,0.121,4.038,0.367,6.002
		c0.145,1.142-0.059,1.965-0.605,2.471c-0.551,0.505-1.098,0.758-1.646,0.758c-0.781,0-1.322-0.437-1.625-1.311
		s-0.455-2.286-0.455-4.236c0-2.036,0.289-4.854,0.867-8.45c0.201-1.357,0.316-2.174,0.346-2.448
		c0.029-0.491,0.086-0.997,0.174-1.517c0.072-0.506,0.129-1.011,0.172-1.517c0.088-1.344,0.348-3.099,0.781-5.266
		c0.316-1.632,0.549-3.069,0.693-4.312c0.1-0.867,0.217-1.452,0.346-1.755c0.131-0.304,0.467-0.636,1.008-0.997
		s0.949-0.542,1.223-0.542c0.607,0,1.063,0.293,1.365,0.878S209.877,323.693,209.877,324.474z"
        />
        <path
          fill={secondary}
          d="M223.566,354.742c-2.311,0-4.364-1.147-6.162-3.444s-2.697-4.681-2.697-7.15
		c0-2.918,0.889-6.247,2.665-9.989c1.775-3.74,4.094-6.792,6.953-9.154c2.859-2.361,5.834-3.542,8.924-3.542
		c2.369,0,4.34,0.292,5.914,0.877s2.989,1.427,4.245,2.524c1.257,1.098,2.094,2.225,2.514,3.38c0.418,1.156,0.628,2.773,0.628,4.854
		c0,1.965-0.593,4.262-1.776,6.891c-1.185,2.629-3.029,5.193-5.534,7.691c-2.506,2.5-4.878,4.302-7.116,5.406
		C229.885,354.19,227.032,354.742,223.566,354.742z M218.671,343.952c0,2.008,0.636,3.698,1.906,5.07s2.744,2.059,4.419,2.059
		c4.361,0,8.369-2.008,12.022-6.023s5.48-8.277,5.48-12.784c0-1.719-0.336-3.073-1.008-4.063c-0.671-0.989-1.751-1.834-3.238-2.535
		c-1.487-0.7-3.155-1.051-5.004-1.051c-3.234,0-6.473,2.195-9.715,6.587S218.671,339.85,218.671,343.952z"
        />
        <path
          fill={secondary}
          d="M257.467,322.566c4.245-0.693,7.343-1.04,9.293-1.04c3.046,0,5.479,0.723,7.3,2.167
		c1.819,1.444,2.729,3.258,2.729,5.438c0,2.182-0.964,4.236-2.892,6.164c-1.928,1.929-4.351,3.442-7.268,4.54
		c-0.36,0.145-0.541,0.296-0.541,0.454c0,0.246,1.491,1.521,4.473,3.825c2.982,2.304,5.723,4.229,8.221,5.774
		c0.707,0.433,1.062,1.004,1.062,1.711c0,0.463-0.242,0.921-0.726,1.376c-0.484,0.455-0.936,0.683-1.354,0.683
		c-0.853,0-2.214-0.707-4.084-2.123s-3.809-2.976-5.815-4.681c-4.347-3.654-6.773-5.496-7.278-5.525
		c-0.982-0.028-1.676-0.165-2.08-0.411c-0.404-0.245-0.606-0.686-0.606-1.322c0-0.549,0.261-1.028,0.78-1.44
		s1.134-0.617,1.841-0.617c0.246,0,0.636,0.036,1.17,0.108c0.534,0.086,0.932,0.13,1.191,0.13c1.155,0,3.13-0.859,5.925-2.579
		c2.794-1.719,4.191-3.74,4.191-6.066c0-1.228-0.625-2.282-1.874-3.163c-1.249-0.882-2.842-1.322-4.776-1.322
		c-1.444,0-2.889,0.102-4.332,0.304c-1.444,0.202-2.365,0.376-2.762,0.52c-0.397,0.145-0.719,0.517-0.964,1.116
		c-0.246,0.6-0.657,2.51-1.235,5.73c-0.577,3.222-1.028,6.263-1.354,9.122c-0.325,2.86-0.487,4.962-0.487,6.306
		c0,1.04,0.072,1.943,0.217,2.708c0.13,0.767,0.194,1.38,0.194,1.842c0,0.434-0.228,0.882-0.682,1.344
		c-0.455,0.462-0.95,0.693-1.484,0.693c-1.516,0-2.274-1.228-2.274-3.684c0-5.792,1.019-13.499,3.055-23.119
		c0.101-0.506,0.151-0.802,0.151-0.889c0-0.086-0.051-0.217-0.151-0.39c-0.578-0.91-0.867-1.517-0.867-1.82
		c0-0.549,0.268-1.025,0.802-1.43s1.019-0.606,1.451-0.606L257.467,322.566z"
        />
        <path
          fill={secondary}
          d="M304.494,329.002c0-1.921-0.499-3.095-1.495-3.521c-0.996-0.426-2.238-0.639-3.726-0.639
		c-3.062,0-5.838,0.604-8.329,1.809c-2.491,1.207-3.736,2.654-3.736,4.345c0,1.214,0.553,2.271,1.657,3.174
		c1.104,0.903,3.513,1.896,7.224,2.979c3.596,1.04,6.155,2.113,7.68,3.218c1.523,1.105,2.285,2.438,2.285,3.998
		c0,1.574-1.062,3.275-3.185,5.103s-4.683,3.218-7.679,4.171c-2.997,0.953-5.542,1.43-7.636,1.43c-1.747,0-3.072-0.205-3.975-0.617
		c-0.903-0.412-1.354-0.929-1.354-1.549c0-0.434,0.134-0.965,0.401-1.593c0.267-0.629,0.537-0.942,0.812-0.942
		c0.289,0,0.585,0.051,0.889,0.151c1.545,0.578,3.076,0.866,4.592,0.866c2.729,0,5.625-0.798,8.687-2.394
		c3.062-1.597,4.592-3.088,4.592-4.475c0-0.592-0.465-1.159-1.396-1.701c-0.932-0.541-3.289-1.411-7.072-2.61
		c-4.693-1.488-7.578-2.937-8.654-4.345s-1.613-2.893-1.613-4.452c0-3.005,1.726-5.428,5.177-7.27s7.062-2.763,10.831-2.763
		c2.498,0,4.419,0.422,5.762,1.268c1.343,0.845,2.015,1.78,2.015,2.806c0,0.491-0.152,1.206-0.455,2.146
		C306.486,328.532,305.721,329.002,304.494,329.002z"
        />
      </g>
      <g>
        <path
          fill={secondary}
          d="M90.452,42.565c-0.159-0.289-0.238-0.65-0.238-1.083c0-0.65,0.234-1.09,0.702-1.322
		c0.468-0.23,1.869-0.361,4.203-0.39c2.867-0.014,5.441-0.119,7.725-0.314c2.283-0.195,5.802-0.617,10.555-1.268
		c1.801-0.245,3.515-0.368,5.143-0.368c0.821,0,1.603,0.051,2.344,0.152c0.742,0.101,1.268,0.199,1.578,0.292
		c0.31,0.094,0.558,0.354,0.746,0.78c0.187,0.426,0.281,0.755,0.281,0.986c0,1.026-0.36,1.539-1.081,1.539
		c-0.475,0-1.124-0.043-1.944-0.13c-0.836-0.072-1.966-0.108-3.393-0.108c-1.469,0-3.252,0.105-5.348,0.314
		c-2.096,0.209-3.259,0.553-3.49,1.029s-0.609,3.293-1.134,8.45c-0.526,5.157-0.789,9-0.789,11.527c0,1.17,0.18,2.521,0.54,4.052
		c0.058,0.289,0.086,0.462,0.086,0.52c0,0.636-0.173,1.21-0.519,1.723c-0.346,0.512-0.764,0.769-1.253,0.769
		c-0.749,0-1.372-0.361-1.869-1.083c-0.497-0.722-0.746-2.744-0.746-6.067c0-3.149,0.598-9.295,1.793-18.439
		c0.057-0.491,0.086-0.744,0.086-0.758c0-0.491-0.267-0.737-0.799-0.737c-0.865,0-2.514,0.108-4.948,0.325
		c-2.435,0.231-4.17,0.347-5.208,0.347C91.921,43.302,90.913,43.056,90.452,42.565z"
        />
        <path
          fill={secondary}
          d="M145.528,51.773c-0.49,0.665-1.39,2.16-2.701,4.485c-5.46,9.852-8.514,14.777-9.162,14.777
		c-0.98,0-1.772-0.574-2.377-1.723s-1.437-4.193-2.496-9.133c-1.059-4.94-1.956-9.664-2.69-14.17
		c-0.274-1.271-0.54-2.311-0.799-3.12c-0.36-1.083-0.541-1.893-0.541-2.427c0-0.563,0.212-1.073,0.638-1.528
		c0.424-0.455,0.896-0.683,1.415-0.683c0.634,0,1.185,0.488,1.653,1.463s0.99,3.34,1.567,7.096
		c1.238,8.161,2.506,14.33,3.803,18.504c3.601-5.764,5.841-9.429,6.72-10.997c0.878-1.567,2.118-4.098,3.716-7.594
		c0.418-0.766,0.994-1.148,1.729-1.148c0.316,0,0.677,0.314,1.08,0.942c0.403,0.628,1.109,2.358,2.118,5.189
		c2.506,7.006,4.213,11.209,5.121,12.611c5.07-8.407,8.017-17.125,8.837-26.153c0-0.202,0.191-0.48,0.573-0.834
		c0.381-0.354,0.746-0.531,1.091-0.531c1.152,0,1.729,0.737,1.729,2.21c0,1.531-0.386,3.824-1.156,6.879
		c-0.771,3.055-1.692,5.872-2.766,8.45c-1.073,2.578-2.877,6.504-5.413,11.776c-0.418,0.853-0.771,1.611-1.059,2.275
		c-0.144,0.274-0.421,0.506-0.832,0.693s-0.738,0.282-0.983,0.282c-0.62,0-1.246-0.34-1.88-1.019
		c-0.634-0.679-1.599-2.668-2.896-5.969s-2.233-5.782-2.809-7.443C146.356,53.796,145.946,52.742,145.528,51.773z"
        />
        <path
          fill={secondary}
          d="M194.663,40.961c-0.043,0.015-0.436,0.008-1.177-0.021c-0.742-0.029-1.502,0.043-2.28,0.217
		c-0.778,0.188-1.469,0.303-2.074,0.347c-0.418,0.029-0.872,0.086-1.361,0.173c-0.62,0.116-1.844,0.181-3.673,0.195
		c-2.751,0.043-4.483,0.231-5.196,0.563c-0.713,0.333-1.113,1.018-1.199,2.058c-0.072,0.795-0.224,1.668-0.454,2.622
		c-0.706,2.846-1.059,4.738-1.059,5.677l0.324,0.021c0.677,0.043,2.045-0.05,4.105-0.281c2.06-0.231,3.601-0.462,4.624-0.693
		c2.103-0.506,3.709-0.759,4.818-0.759c1.8,0,2.701,0.484,2.701,1.452c0,1.185-0.602,1.91-1.804,2.177
		c-1.203,0.268-3.713,0.61-7.53,1.029c-4.667,0.52-7.155,0.986-7.465,1.397c-0.31,0.412-0.638,1.737-0.983,3.976
		c-0.346,2.239-0.519,3.669-0.519,4.29v0.238c0.072,0.723,0.533,1.134,1.383,1.235l0.389,0.021c0.792,0.043,1.735-0.021,2.831-0.195
		l4.148-0.607c1.541-0.216,3.104-0.469,4.689-0.758c1.973-0.419,3.227-0.628,3.759-0.628c0.403,0,0.854,0.17,1.351,0.509
		c0.497,0.34,0.746,0.877,0.746,1.614c0,0.535-0.13,0.903-0.389,1.105c-1.469,0.65-3.363,1.011-5.683,1.083
		c-2.002,0.072-5.128,0.412-9.377,1.018c-2.146,0.318-3.558,0.477-4.235,0.477c-1.686,0-2.741-0.134-3.166-0.401
		c-0.425-0.268-0.681-0.921-0.767-1.961l-0.086-0.737v-0.086c0-0.231,0.129-0.701,0.389-1.409c0.36-1.025,0.781-2.976,1.264-5.85
		c0.482-2.875,0.796-5.149,0.94-6.825c0.072-0.578,0.187-1.278,0.346-2.102c0.634-3.453,0.951-6.183,0.951-8.19
		c0-0.101-0.144-0.369-0.432-0.802c-0.072-0.087-0.123-0.159-0.151-0.217c-0.101-0.332-0.194-0.606-0.281-0.823
		c-0.101-0.202-0.151-0.44-0.151-0.715v-0.217c0-0.318,0.194-0.599,0.583-0.845c0.389-0.246,0.763-0.368,1.124-0.368h0.065
		c0.605,0,1.296,0.043,2.074,0.13h0.086c0.331,0,1.93-0.137,4.797-0.412c2.866-0.274,6.288-0.751,10.263-1.43
		c0.418-0.072,0.67-0.108,0.756-0.108L193,37.126c1.124-0.072,1.897,0.04,2.323,0.336c0.425,0.296,0.688,0.87,0.789,1.723
		c0,0.404-0.188,0.794-0.562,1.17C195.174,40.73,194.879,40.933,194.663,40.961z"
        />
        <path
          fill={secondary}
          d="M221.94,45.282c-1.063,0-2.222,0.697-3.478,2.089c-1.257,1.394-3.172,4.517-5.746,9.373
		c-3.391,6.514-5.582,10.223-6.572,11.124c-0.991,0.902-1.813,1.352-2.467,1.352c-2.411,0-3.616-1.167-3.616-3.503
		c0-0.983,0.163-2.376,0.49-4.179c1.123-6.187,1.686-12.711,1.686-19.574c0-0.778,0.332-1.644,0.996-2.597
		c0.663-0.953,1.291-1.429,1.884-1.429c1.063,0,1.818,0.328,2.268,0.983c0.449,0.656,0.675,1.977,0.675,3.964
		c0,2.069-0.613,6.33-1.839,12.783c-0.409,2.131-0.613,3.483-0.613,4.056c0,0.328,0.082,0.492,0.245,0.492
		c0.307,0,1.727-2.448,4.26-7.344c2.554-4.978,4.791-8.481,6.711-10.509c1.92-2.028,4.034-3.042,6.344-3.042
		c1.838,0,3.334,0.84,4.489,2.52c1.153,1.68,1.996,4.743,2.527,9.188c0.572,5.06,1.088,8.21,1.548,9.449
		c0.46,1.24,1.189,1.859,2.19,1.859c0.634,0,1.431-0.225,2.391-0.676c0.163-0.082,0.286-0.123,0.368-0.123
		c0.878,0,1.317,0.512,1.317,1.537c0,0.901-0.603,1.859-1.808,2.873c-1.206,1.014-2.656,1.521-4.352,1.521
		c-1.737,0-3.08-0.691-4.03-2.074c-0.949-1.383-1.7-5.075-2.252-11.078C224.984,48.293,223.779,45.282,221.94,45.282z"
        />
        <path
          fill={secondary}
          d="M239.475,42.565c-0.158-0.289-0.237-0.65-0.237-1.083c0-0.65,0.233-1.09,0.702-1.322
		c0.468-0.23,1.869-0.361,4.202-0.39c2.866-0.014,5.441-0.119,7.725-0.314c2.283-0.195,5.802-0.617,10.556-1.268
		c1.8-0.245,3.515-0.368,5.143-0.368c0.82,0,1.602,0.051,2.344,0.152s1.268,0.199,1.577,0.292s0.559,0.354,0.746,0.78
		c0.187,0.426,0.28,0.755,0.28,0.986c0,1.026-0.359,1.539-1.08,1.539c-0.476,0-1.123-0.043-1.944-0.13
		c-0.836-0.072-1.967-0.108-3.393-0.108c-1.47,0-3.252,0.105-5.348,0.314c-2.096,0.209-3.26,0.553-3.489,1.029
		c-0.231,0.477-0.609,3.293-1.135,8.45c-0.526,5.157-0.789,9-0.789,11.527c0,1.17,0.181,2.521,0.541,4.052
		c0.057,0.289,0.086,0.462,0.086,0.52c0,0.636-0.173,1.21-0.519,1.723c-0.346,0.512-0.764,0.769-1.253,0.769
		c-0.749,0-1.372-0.361-1.869-1.083s-0.746-2.744-0.746-6.067c0-3.149,0.598-9.295,1.794-18.439
		c0.058-0.491,0.086-0.744,0.086-0.758c0-0.491-0.267-0.737-0.799-0.737c-0.864,0-2.514,0.108-4.948,0.325
		c-2.435,0.231-4.17,0.347-5.207,0.347C240.944,43.302,239.936,43.056,239.475,42.565z"
        />
        <path
          fill={secondary}
          d="M285.779,53.811c-1.152-1.199-2.723-3.134-4.711-5.807c-1.396-1.877-2.384-3.124-2.96-3.738
		c-0.576-0.614-1.368-1.325-2.377-2.134c-1.339-1.04-2.164-1.784-2.474-2.232s-0.465-0.917-0.465-1.408
		c0-0.231,0.224-0.538,0.67-0.921s0.857-0.574,1.231-0.574c0.879,0,2.038,0.665,3.479,1.993c1.439,1.329,2.744,2.73,3.91,4.204
		c3.559,4.521,5.762,7.049,6.612,7.583c0.85-0.939,1.742-1.899,2.679-2.882c4.754-4.94,7.649-8.147,8.687-9.62
		c1.138-1.589,2.269-2.383,3.393-2.383c0.432,0,0.771,0.206,1.016,0.617c0.244,0.412,0.367,0.827,0.367,1.246
		c0,0.39-0.544,1.228-1.632,2.513c-1.088,1.286-3.965,4.363-8.632,9.23c-2.247,2.34-3.565,3.85-3.954,4.528
		c-0.389,0.679-0.764,2.651-1.123,5.916c-0.36,3.265-0.613,5.821-0.757,7.67c-0.086,1.126-0.202,1.762-0.346,1.906
		c-0.145,0.145-0.446,0.293-0.907,0.444c-0.461,0.152-0.829,0.228-1.103,0.228c-1.08,0-1.62-0.91-1.62-2.73
		c0-1.127,0.23-3.127,0.691-6.002c0.433-2.759,0.648-4.608,0.648-5.547C286.104,55.089,285.995,54.388,285.779,53.811z"
        />
      </g>
      <g>
        <path
          d="M215.277,246.355l-1.887,32.597H129.37v-31.758c2.734-3.036,7.247-7.79,13.538-14.262
		c15.371-15.979,26.557-29.141,33.559-39.488c7.001-10.346,10.502-19.834,10.502-28.463c0-6.311-1.395-11.523-4.185-15.639
		c-2.79-4.114-6.537-6.172-11.241-6.172c-10.941,0-17.286,10.426-19.036,31.279l-23.138-7.55
		c5.196-36.031,19.61-54.049,43.241-54.049c13.237,0,23.397,5.075,30.483,15.22c7.082,10.147,10.625,22.251,10.625,36.312
		c0,14.542-3.707,27.524-11.117,38.949c-7.414,11.426-21.047,25.766-40.903,43.023H215.277z"
        />
      </g>
      <polygon points="180.698,187.526 168.377,194.78 177.7,203.831 " />
      <polygon points="204.892,188.94 212.609,195.384 201.276,203.608 " />
      <polygon points="265.239,187.526 270.484,195.917 261.745,200.113 " />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

Artwrk22.props = {
  primary: string,
  secondary: string,
};

Artwrk22.defaultProps = {
  primary: "#000",
  secondary: "#fff",
};
