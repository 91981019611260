import React from "react";
import { string } from "prop-types";

export default function Artwrk15(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="Shape_1_copy_2_1_" enable-background="new    ">
        <g id="Shape_1_copy_2">
          <path
            d="M205.783,478.918c-0.058,0.932-0.437,1.748-1.116,2.392c-0.851,0.805-1.737,1.599-2.622,2.369
			c-6.717,5.809-13.423,11.606-20.152,17.414c-1.15,0.989-2.277,2.024-3.508,2.829c-1.403,0.449-2.76,0.564-4.152,0.564
			c-14.217-0.023-28.445-0.012-42.661-0.012c-0.483,0-0.966,0-1.461,0c-1.668,0.012-1.668,0.012-1.679,1.783
			c0,0.414,0,0.828,0,1.254c0,7.005,0,14.009,0,21.014c0,3.543,0,7.074,0,10.605c0,0.69,0.023,1.38,0.035,1.944
			c0.207,0.391,0.46,0.483,0.748,0.483c0.414,0.012,0.828,0.012,1.242,0.012c8.742,0,17.483,0,26.225,0
			c0.61,0,1.231,0.046,1.691-0.058c0.449-0.391,0.357-0.759,0.357-1.081c0.012-2.979,0.046-5.97-0.011-8.949
			c-0.023-1.196,0.138-2.358,0.472-3.485c0.391-1.334,1.242-2.289,2.634-2.691c1.07-0.311,2.162-0.46,3.278-0.449
			c11.583,0.012,23.165,0.012,34.759,0c1.058,0,2.07,0.115,3.071,0.449c1.576,0.541,2.553,1.61,2.829,3.267
			c0.069,0.414,0.081,0.828,0.092,1.242c0.011,0.483,0.011,0.966,0.011,1.461c0,17.126,0,34.253,0,51.391
			c0,0.828,0,1.668-0.115,2.484c-0.23,1.783-1.357,2.853-3.014,3.324c-0.794,0.23-1.633,0.276-2.461,0.357
			c-0.483,0.046-0.966,0.012-1.461,0.012c-36.829,0-73.671,0-110.5,0c-1.185,0-2.358,0.011-3.508-0.311
			c-1.668-0.46-2.76-1.484-3.163-3.221c-0.23-0.955-0.334-1.898-0.334-2.876c0.011-32.39,0.011-64.768,0-97.158
			c0-1.127,0.161-2.197,0.391-3.29c0.161-0.771,0.529-1.426,1.093-1.967c0.196-0.196,0.414-0.368,0.621-0.552
			c7.603-6.683,15.206-13.365,22.82-20.037c0.725-0.644,1.507-1.219,2.128-1.714c1.702-0.541,3.278-0.667,4.888-0.656
			c14.286,0.023,28.583,0.012,42.868,0.012c0.69,0,1.392-0.012,2.082-0.046c0.127,0,0.242-0.115,0.357-0.173
			c0.046-0.046,0.127-0.103,0.127-0.15c0.035-0.414,0.069-0.828,0.069-1.242c0.115-15.976,0.069-33.195-0.104-35.196
			c-0.15-0.081-0.322-0.23-0.506-0.242c-0.552-0.046-1.104-0.046-1.656-0.046c-8.465,0-16.931,0-25.385,0
			c-0.483,0-0.966,0-1.461,0.023c-0.196,0.012-0.38,0.115-0.506,0.161c-0.299,0.345-0.253,0.702-0.253,1.047
			c-0.011,2.979,0,5.958-0.011,8.949c0,0.552,0.035,1.116-0.012,1.668c-0.058,0.621-0.115,1.254-0.299,1.84
			c-0.414,1.346-1.346,2.231-2.691,2.657c-0.863,0.265-1.748,0.38-2.657,0.38c-11.997-0.012-24.005-0.012-36.001,0
			c-0.909,0-1.806-0.069-2.668-0.345c-1.507-0.472-2.473-1.472-2.841-3.014c-0.23-0.943-0.368-1.886-0.368-2.876
			c0.023-9.915,0.011-19.829,0.011-29.756c0-0.909,0.127-1.783,0.299-2.668c0.219-1.162,0.771-2.047,1.645-2.783
			c0.794-0.667,1.564-1.38,2.335-2.059c3.911-3.428,7.81-6.867,11.721-10.294c2.922-2.565,5.843-5.118,8.765-7.672
			c0.725-0.633,1.484-1.242,2.082-1.737c1.507-0.598,2.887-0.656,4.279-0.644c19.496,0.012,38.98,0.012,58.476,0.012
			c1.599,0,3.186,0,4.785,0c0.483,0,0.966,0.023,1.449,0.069c1.127,0.127,2.139,0.472,3.014,1.265
			c1.587,1.438,3.244,2.818,4.865,4.233c2.289,2.013,4.578,4.037,6.867,6.05c2.968,2.611,5.924,5.233,8.891,7.844
			c0.932,0.828,1.875,1.645,2.807,2.473c0.311,0.276,0.633,0.541,0.92,0.84c0.736,0.782,1.242,1.668,1.288,2.772
			c0.035,0.759,0.058,1.53,0.058,2.289c0,11.514,0,23.027,0,34.541c0,13.733,0,27.455,0,41.189
			C205.863,477.25,205.829,478.09,205.783,478.918L205.783,478.918z M201.562,399.819c-0.012-0.276-0.046-0.552-0.069-0.759
			c-0.127-0.173-0.196-0.299-0.288-0.391c-0.242-0.253-0.495-0.495-0.748-0.725c-7.752-6.832-15.505-13.653-23.269-20.485
			c-0.046-0.046-0.115-0.081-0.184-0.104c-0.058-0.035-0.127-0.058-0.345-0.15c-0.667,0-1.507-0.012-2.335-0.012
			c-20.393,0-40.786,0-61.179,0c-0.483,0-0.978-0.012-1.461,0c-0.414,0.012-0.817,0.069-1.265,0.104
			c-7.614,6.683-15.229,13.354-22.843,20.025c-0.575,0.506-1.127,1.024-1.61,1.472c-0.253,0.794-0.161,1.495-0.161,2.185
			c-0.011,9.639,0,19.277,0,28.916c0,0.552,0,1.116,0.046,1.656c0.011,0.196,0.15,0.368,0.23,0.564
			c0.702,0.196,1.403,0.127,2.082,0.127c11.306,0,22.613,0,33.919,0c0.552,0,1.104-0.012,1.668-0.035
			c0.288-0.012,0.529-0.15,0.725-0.541c0.011-0.564,0.035-1.254,0.035-1.944c0-4.026,0-8.04,0.011-12.066
			c0-0.621-0.092-1.242,0.138-1.84c0.115-0.081,0.219-0.219,0.345-0.23c0.414-0.046,0.828-0.069,1.242-0.069
			c11.79,0,23.591,0,35.38,0c0.414,0,0.828,0.035,1.265,0.058c0.104,0.184,0.276,0.345,0.299,0.529
			c0.046,0.621,0.058,1.242,0.058,1.863c0,13.733,0,27.467,0,41.189c0,0.552,0,1.104-0.023,1.668
			c-0.012,0.196-0.104,0.38-0.161,0.598c-0.219,0.069-0.403,0.161-0.598,0.173c-0.69,0.023-1.392,0.023-2.082,0.023
			c-15.815,0-31.631,0-47.446,0c-0.828,0-1.668-0.081-2.599,0.115c-7.925,6.913-15.884,13.929-23.867,20.922
			c-0.207,0.184-0.38,0.391-0.541,0.564c-0.207,0.817-0.115,1.576-0.115,2.335c0,32.102,0,64.216,0,96.318
			c0,0.621,0,1.254,0.046,1.875c0.011,0.184,0.173,0.357,0.265,0.518c0.242,0.035,0.437,0.092,0.644,0.092
			c37.945,0,75.89,0,113.836,0c0.207,0,0.403-0.057,0.644-0.092c0.092-0.172,0.253-0.345,0.265-0.529
			c0.046-0.621,0.058-1.242,0.058-1.863c0-16.643,0-33.287,0-49.93c0-0.621,0-1.254-0.046-1.875
			c-0.011-0.184-0.15-0.368-0.242-0.564c-0.288-0.034-0.564-0.081-0.84-0.092c-4.819-0.115-35.15-0.046-36.818,0.092
			c-0.104,0.104-0.207,0.196-0.357,0.334c0,0.644-0.011,1.346-0.011,2.036c0,3.888,0,7.764,0,11.652c0,0.621,0,1.254-0.035,1.875
			c-0.012,0.184-0.138,0.368-0.207,0.518c-0.173,0.069-0.299,0.15-0.426,0.15c-12.698,0.069-25.385,0.012-38.003,0.035
			c-0.184-0.161-0.288-0.242-0.403-0.345c-0.196-0.748-0.104-1.507-0.104-2.266c-0.011-13.595,0-27.179,0-40.775
			c0-0.414,0-0.828,0-1.254c0-0.207,0.012-0.414,0.023-0.621c0.023-0.518,0.265-0.794,0.736-0.817
			c0.552-0.023,1.104-0.023,1.668-0.023c3.75,0,7.488,0,11.237,0c12.135,0,24.281,0,36.415,0c0.759,0,1.53,0.104,2.369-0.161
			c1.932-1.668,3.922-3.393,5.912-5.107c3.681-3.175,7.35-6.338,11.03-9.512c2.415-2.082,4.819-4.175,7.223-6.269
			c0.311-0.276,0.656-0.506,0.805-0.932C201.596,476.192,201.665,404.96,201.562,399.819L201.562,399.819z M341.484,401.026
			c0,28.433,0,56.855,0,85.288c0,24.821,0,49.643,0,74.476c0,0.552,0,1.104,0,1.668c0,1.541-0.552,2.841-1.714,3.865
			c-1.254,1.093-2.507,2.185-3.761,3.278c-3.451,3.014-6.89,6.027-10.329,9.041c-2.979,2.599-5.958,5.187-8.949,7.787
			c-0.575,0.495-1.185,0.955-1.771,1.449c-0.437,0.368-0.943,0.587-1.495,0.679c-0.886,0.15-1.771,0.368-2.68,0.311
			c-0.207-0.011-0.414,0-0.621,0c-21.221,0-42.454,0-63.675,0c-1.047,0-2.07-0.046-3.106-0.23c-0.851-0.149-1.587-0.472-2.231-1.024
			c-0.679-0.587-1.392-1.15-2.059-1.748c-5.026-4.474-10.041-8.949-15.056-13.423c-2.116-1.898-4.221-3.807-6.349-5.682
			c-0.679-0.598-1.277-1.265-1.76-2.013c-0.483-1.472-0.575-2.772-0.575-4.095c0.011-53.116,0.011-106.233,0-159.349
			c0-1.392,0.023-2.772,0.633-4.359c0.23-0.426,0.725-1.001,1.3-1.507c1.357-1.196,2.691-2.404,4.049-3.589
			c3.083-2.691,6.165-5.371,9.248-8.074c3.336-2.922,6.66-5.866,9.995-8.788c0.161-0.138,0.322-0.265,0.472-0.414
			c1.127-1.196,2.576-1.576,4.141-1.668c0.414-0.023,0.828-0.012,1.254-0.012c21.428,0,42.868,0,64.296,0
			c0.345,0,0.69,0,1.047,0.012c1.576,0.035,2.968,0.495,4.164,1.599c1.829,1.691,3.738,3.313,5.613,4.957
			c5.371,4.704,10.731,9.42,16.103,14.125c0.725,0.644,1.449,1.3,2.174,1.932c1.058,0.92,1.541,2.116,1.656,3.462
			C341.53,399.635,341.484,400.336,341.484,401.026L341.484,401.026z M337.217,402.142c0-0.621,0.012-1.254-0.012-1.875
			c-0.012-0.414-0.069-0.817-0.115-1.254c-7.982-7.005-15.965-14.009-23.936-21.003c-0.311-0.276-0.656-0.506-1.012-0.782
			c-0.759-0.011-1.518-0.023-2.277-0.023c-9.846,0-19.703,0-29.549,0c-11.099,0-22.199,0-33.298,0c-0.759,0-1.53-0.081-2.266,0.092
			c-0.242,0.173-0.483,0.322-0.69,0.495c-1.932,1.691-3.865,3.382-5.786,5.072c-3.129,2.749-6.246,5.498-9.374,8.247
			c-2.657,2.335-5.325,4.658-7.971,6.993c-0.357,0.322-0.782,0.587-0.966,1.047c-0.115,1.771-0.196,157.52-0.081,162.708
			c0.012,0.276,0.046,0.552,0.092,0.989c0.242,0.196,0.564,0.46,0.874,0.736c2.07,1.852,4.129,3.692,6.211,5.544
			c3.163,2.807,6.326,5.613,9.478,8.431c2.231,1.978,4.44,3.98,6.671,5.958c0.357,0.322,0.702,0.667,1.369,0.851
			c22.164,0,44.501,0,66.838,0c0.276,0,0.552-0.035,0.84-0.058c0.334-0.253,0.69-0.483,1.001-0.748
			c7.89-6.89,15.769-13.791,23.648-20.681c0.103-0.092,0.15-0.23,0.276-0.426c0.103-0.817,0.057-1.714,0.057-2.611
			C337.217,507.282,337.217,454.718,337.217,402.142L337.217,402.142z M298.88,543.214c0,0.69,0,1.392-0.023,2.082
			c-0.012,0.196-0.104,0.391-0.196,0.736c-0.414,0.034-0.69,0.081-0.966,0.081c-13.043,0-26.087,0-39.049,0
			c-0.184-0.161-0.276-0.242-0.426-0.368c-0.023-0.38-0.069-0.794-0.057-1.208c0.012-42.799,0.023-85.587,0.034-128.386
			c0-0.069,0.035-0.138,0.058-0.196c0.023-0.058,0.069-0.115,0.127-0.219c0.15-0.046,0.334-0.161,0.529-0.173
			c0.483-0.035,0.966-0.023,1.461-0.023c12.077,0,24.143,0,36.22,0c0.552,0,1.104,0.023,1.656,0.046
			c0.276,0.012,0.529,0.265,0.564,0.518c0.023,0.207,0.057,0.414,0.069,0.621c0.012,0.621,0.012,1.242,0.012,1.875
			C298.88,460.124,298.88,501.669,298.88,543.214L298.88,543.214z M294.452,422.547c0-0.621,0-1.242-0.034-1.875
			c-0.012-0.184-0.138-0.368-0.207-0.518c-0.184-0.058-0.311-0.138-0.437-0.138c-10.133-0.011-20.255-0.023-30.377-0.023
			c-0.207,0-0.403,0.069-0.644,0.115c-0.081,0.184-0.219,0.357-0.23,0.552c-0.046,0.552-0.046,1.104-0.046,1.656
			c0,38.969,0,77.949,0,116.918c0,0.552,0,1.104,0.035,1.656c0.012,0.184,0.15,0.368,0.219,0.541
			c0.725,0.207,1.415,0.127,2.105,0.127c9.087,0.011,18.173,0,27.26,0c0.552,0,1.116,0,1.668-0.035
			c0.184-0.012,0.368-0.138,0.587-0.219c0.173-0.759,0.092-1.53,0.092-2.277C294.452,500.208,294.452,461.378,294.452,422.547
			L294.452,422.547z"
          />
        </g>
      </g>
      <path
        d="M399.603,500.476h1.461c14.216,0,28.445-0.011,42.661,0.012c1.392,0,2.749-0.115,4.152-0.564
	c1.231-0.805,2.358-1.84,3.508-2.83c6.729-5.809,13.434-11.606,20.152-17.414c0.886-0.771,1.771-1.564,2.622-2.369
	c0.679-0.644,1.058-1.461,1.116-2.392c0.046-0.828,0.081-1.668,0.104-2.484v-75.729c0-0.759-0.023-1.53-0.058-2.289
	c-0.046-1.104-0.552-1.99-1.288-2.772c-0.288-0.299-0.61-0.564-0.92-0.84c-0.932-0.828-1.875-1.645-2.806-2.473
	c-2.968-2.611-5.924-5.233-8.891-7.844c-2.289-2.013-4.578-4.037-6.867-6.05c-1.622-1.415-3.278-2.795-4.865-4.233
	c-0.874-0.794-1.886-1.139-3.014-1.265c-0.483-0.046-0.966-0.069-1.449-0.069h-4.785c-19.496,0-38.98,0-58.476-0.011
	c-1.392-0.012-2.772,0.046-4.279,0.644c-0.598,0.495-1.357,1.104-2.082,1.737c-2.922,2.553-5.843,5.107-8.765,7.672
	c-3.911,3.428-7.81,6.867-11.721,10.294c-0.771,0.679-1.541,1.392-2.335,2.059c-0.874,0.736-1.426,1.622-1.645,2.784
	c-0.173,0.886-0.299,1.76-0.299,2.668c0,9.926,0.012,19.841-0.011,29.756c0,0.989,0.138,1.932,0.368,2.875
	c0.368,1.541,1.334,2.542,2.841,3.014c0.863,0.276,1.76,0.345,2.668,0.345c11.997-0.011,24.005-0.011,36.001,0
	c0.909,0,1.794-0.115,2.657-0.38c1.346-0.426,2.277-1.311,2.692-2.657c0.184-0.587,0.242-1.219,0.299-1.84
	c0.046-0.552,0.012-1.116,0.012-1.668c0.011-2.991,0-5.97,0.011-8.949c0-0.345-0.046-0.702,0.253-1.047
	c0.127-0.046,0.311-0.15,0.506-0.161c0.495-0.023,0.978-0.023,1.461-0.023h25.385c0.552,0,1.104,0,1.656,0.046
	c0.184,0.011,0.357,0.161,0.506,0.242c0.172,2.001,0.219,19.22,0.104,35.196c0,0.414-0.035,0.828-0.069,1.242
	c0,0.046-0.081,0.104-0.127,0.15c-0.115,0.057-0.23,0.172-0.357,0.172c-0.69,0.034-1.392,0.046-2.082,0.046
	c-14.286,0-28.583,0.011-42.868-0.012c-1.61-0.011-3.186,0.115-4.888,0.656c-0.621,0.495-1.403,1.07-2.128,1.714
	c-7.614,6.671-15.217,13.354-22.82,20.036c-0.207,0.184-0.426,0.357-0.621,0.552c-0.564,0.541-0.932,1.196-1.093,1.967
	c-0.23,1.093-0.391,2.162-0.391,3.29c0.011,32.39,0.011,64.768,0,97.158c0,0.978,0.104,1.921,0.334,2.876
	c0.403,1.737,1.495,2.76,3.163,3.221c1.15,0.322,2.323,0.311,3.508,0.311h110.5c0.495,0,0.978,0.034,1.461-0.012
	c0.828-0.081,1.668-0.127,2.461-0.357c1.656-0.472,2.784-1.541,3.014-3.324c0.115-0.817,0.115-1.656,0.115-2.484v-51.391
	c0-0.495,0-0.978-0.011-1.461c-0.012-0.414-0.023-0.828-0.092-1.242c-0.276-1.656-1.254-2.726-2.829-3.267
	c-1.001-0.334-2.013-0.449-3.071-0.449c-11.594,0.012-23.177,0.012-34.759,0c-1.116-0.011-2.208,0.138-3.278,0.449
	c-1.392,0.403-2.243,1.357-2.634,2.691c-0.334,1.127-0.495,2.289-0.472,3.485c0.057,2.979,0.023,5.97,0.011,8.949
	c0,0.322,0.092,0.69-0.357,1.081c-0.46,0.104-1.081,0.058-1.691,0.058h-26.225c-0.414,0-0.828,0-1.242-0.012
	c-0.288,0-0.541-0.092-0.748-0.483c-0.012-0.564-0.034-1.254-0.034-1.944v-32.873C397.935,500.487,397.935,500.487,399.603,500.476z
	"
      />
      <g id="Shape_1_copy_1_" enable-background="new    ">
        <g id="Shape_1_copy">
          <path
            d="M448.648,287.018c-1.978,3.382-3.6,6.936-5.394,10.409c-3.37,6.545-6.913,12.986-10.881,19.197
			c-1.898,2.968-3.876,5.901-6.096,8.638c-1.737,2.151-3.554,4.221-5.659,6.027c-0.989,0.851-2.047,1.599-3.175,2.266
			c-3.129,1.829-6.499,3.083-9.972,4.072c-3.255,0.932-6.579,1.449-9.949,1.702c-0.15,0.012-0.311,0.023-0.46,0.023
			c-2.772,0.104-5.521-0.184-8.212-0.828c-1.645-0.391-3.198-1.047-4.589-2.047c-2.001-1.426-3.083-3.347-3.301-5.786
			c-0.196-2.208,1.507-4.704,3.347-5.268c0.357-0.104,0.759-0.138,1.127-0.115c0.495,0.035,0.863,0.345,1.024,0.805
			c0.127,0.357,0.207,0.736,0.23,1.116c0.046,0.61,0.012,1.231,0.046,1.84c0.069,1.507,0.817,2.622,2.116,3.336
			c0.529,0.288,1.127,0.495,1.714,0.656c1.116,0.288,2.254,0.437,3.416,0.426c2.162-0.023,4.279-0.265,6.361-0.874
			c3.152-0.909,5.601-2.749,7.281-5.601c1.346-2.277,2.116-4.75,2.565-7.338c0.184-1.058,0.276-2.128,0.391-3.198
			c0.207-2.001-0.046-3.991-0.449-5.947c-1.38-6.717-3.646-13.135-6.97-19.151c-0.253-0.46-0.437-1.001-0.955-1.265
			c-0.368,0.173-0.518,0.529-0.679,0.863c-1.242,2.461-2.473,4.934-3.715,7.396c-3.531,6.982-7.327,13.814-11.56,20.393
			c-2.036,3.163-4.21,6.223-6.706,9.041c-1.07,1.208-2.208,2.346-3.462,3.359c-2.243,1.829-4.808,2.979-7.637,3.554
			c-1.139,0.23-2.289,0.253-3.439,0.138c-1.334-0.138-2.519-0.656-3.531-1.541c-0.759-0.656-1.403-1.415-1.944-2.266
			c-0.736-1.185-1.311-2.438-1.679-3.761c-0.506-1.771-0.84-3.566-1.024-5.406c-0.322-3.301-0.23-6.591,0.069-9.869
			c0.127-1.369,0.345-2.737,0.598-4.095c1.242-6.867,2.864-13.641,4.854-20.324c0.437-1.472,0.943-2.91,1.415-4.371
			c0.115-0.345,0.334-0.69,0.184-1.116c-0.196-0.057-0.403-0.196-0.621-0.196c-1.909-0.035-3.807-0.46-5.763-0.242
			c-0.184,0.265-0.391,0.495-0.529,0.759c-0.518,1.024-1.035,2.047-1.53,3.094c-0.196,0.414-0.334,0.851-0.483,1.288
			c-0.161,0.426-0.265,0.874-0.46,1.3c-1.898,4.187-3.888,8.339-6.349,12.227c-1.518,2.404-3.198,4.681-5.061,6.832
			c-2.07,2.392-4.417,4.463-6.993,6.292c-2.277,1.622-4.785,2.737-7.511,3.313c-0.966,0.207-1.967,0.288-3.025,0.437
			c-0.23,0.253-0.506,0.518-0.736,0.817c-1.07,1.403-2.082,2.841-3.198,4.21c-2.542,3.106-5.429,5.832-8.834,7.982
			c-2.277,1.438-4.693,2.576-7.269,3.382c-3.06,0.943-6.177,1.162-9.328,0.782c-2.231-0.265-4.302-1.058-6.142-2.392
			c-1.691-1.219-3.129-2.703-4.382-4.371c-1.955-2.611-3.278-5.532-4.279-8.604c-0.702-2.185-1.15-4.44-1.518-6.717
			c-0.311-1.978-0.391-3.968-0.621-5.947c-0.046-0.368-0.012-0.771-0.391-1.012c-0.483,0.311-0.644,0.84-0.909,1.3
			c-2.116,3.738-4.336,7.407-6.683,11.007c-2.128,3.278-4.428,6.441-6.982,9.409c-1.507,1.748-3.152,3.347-4.969,4.773
			c-1.024,0.805-2.105,1.541-3.313,2.07c-1.794,0.782-3.635,1.185-5.601,0.725c-0.667-0.161-1.369-0.23-2.013-0.437
			c-1.967-0.61-3.543-1.783-4.808-3.393c-1.047-1.346-1.783-2.864-2.266-4.486c-0.529-1.771-0.863-3.577-0.978-5.417
			c-0.23-3.991-0.196-7.971,0.357-11.939c0.081-0.598,0.196-1.208,0.023-1.829c-0.127,0.034-0.322,0.034-0.368,0.115
			c-0.334,0.518-0.644,1.047-0.943,1.576c-2.185,3.876-4.474,7.683-6.97,11.364c-1.633,2.415-3.416,4.716-5.348,6.901
			c-1.633,1.852-3.416,3.52-5.429,4.934c-1.323,0.932-2.726,1.714-4.302,2.116c-0.299,0.081-0.587,0.196-0.886,0.23
			c-2.312,0.219-4.589,0.173-6.809-0.621c-2.933-1.047-4.911-3.06-5.981-5.958c-0.564-1.518-0.92-3.083-1.012-4.704
			c-0.184-3.152,0.023-6.269,0.667-9.374c0.863-4.129,2.047-8.166,3.244-12.215c1.587-5.371,3.577-10.593,5.555-15.827
			c1.576-4.152,3.106-8.327,4.647-12.491c0.587-1.587,1.047-3.209,1.357-4.865c0.069-0.368,0.069-0.771,0.023-1.139
			c-0.058-0.483-0.322-0.851-0.92-1.035c-0.943-0.058-1.875,0.311-2.691,0.874c-0.886,0.598-1.725,1.277-2.519,1.99
			c-1.714,1.541-3.244,3.267-4.693,5.049c-1.84,2.266-3.554,4.624-5.199,7.028c-3.244,4.75-6.315,9.604-9.225,14.573
			c-1.081,1.852-2.197,3.692-3.324,5.521c-0.851,1.38-1.553,2.818-2.082,4.336c-1.576,4.497-2.668,9.11-3.359,13.825
			c-0.368,2.519-0.529,5.026-0.483,7.557c0.035,2.001,0.311,3.968,0.828,5.901c0.104,0.368,0.184,0.748,0.23,1.127
			c0.115,0.805-0.253,1.369-1.012,1.553c-0.368,0.092-0.759,0.207-1.127,0.196c-1.3-0.046-2.599-0.173-3.853-0.575
			c-2.358-0.759-4.175-2.22-5.509-4.29c-0.84-1.3-1.449-2.714-1.783-4.221c-0.725-3.301-1.15-6.648-0.782-10.041
			c0.242-2.208,0.345-4.428,0.702-6.625c0.15-0.897,0.253-1.806,0.368-2.588c-0.115-0.265-0.265-0.288-0.38-0.115
			c-0.426,0.633-0.84,1.277-1.231,1.932c-1.771,2.956-3.508,5.935-5.325,8.868c-2.185,3.531-4.762,6.763-7.695,9.708
			c-2.565,2.565-5.44,4.704-8.627,6.453c-3.267,1.806-6.763,2.853-10.49,3.094c-1.53,0.104-3.06,0.15-4.589,0.035
			c-5.36-0.403-9.386-3.014-12.376-7.373c-1.093-1.587-1.875-3.336-2.53-5.141c-0.621-1.737-1.024-3.531-1.346-5.337
			c-0.437-2.415-0.552-4.865-0.621-7.327c-0.23-8.005,1.369-15.7,3.957-23.211c2.082-6.05,4.877-11.744,8.569-16.977
			c2.093-2.956,4.474-5.647,7.212-8.017c1.461-1.254,3.025-2.346,4.693-3.313c2.68-1.576,5.578-2.553,8.661-2.864
			c4.911-0.495,9.236,0.92,12.951,4.187c2.53,2.231,4.279,4.992,5.44,8.132c0.863,2.323,1.277,4.739,1.403,7.189
			c0.115,2.174-0.357,4.267-1.035,6.315c-0.656,1.978-1.622,3.807-2.818,5.498c-2.369,3.347-5.245,6.2-8.535,8.627
			c-4.29,3.163-8.972,5.567-13.998,7.327c-1.587,0.564-3.186,1.093-4.831,1.472c-0.518,0.115-1.024,0.299-1.472,0.437
			c-0.449,0.345-0.541,0.828-0.61,1.265c-0.518,3.175-1.058,6.361-0.955,9.593c0.058,1.84,0.219,3.669,0.759,5.452
			c0.15,0.518,0.334,1.024,0.541,1.507c1.668,3.888,5.889,5.084,8.707,4.44c1.978-0.449,3.796-1.185,5.452-2.335
			c1.967-1.357,3.761-2.91,5.383-4.658c2.139-2.312,4.072-4.796,5.774-7.442c3.359-5.222,6.591-10.524,9.34-16.103
			c1.254-2.542,2.576-5.049,3.876-7.568c0.391-0.748,0.702-1.53,0.909-2.358c1.024-4.164,2.116-8.316,3.014-12.514
			c0.449-2.105,0.794-4.221,1.081-6.338c0.184-1.369,0.173-2.749-0.069-4.118c-0.15-0.828-0.253-1.668-0.357-2.507
			c-0.035-0.299-0.023-0.61,0-0.92c0.058-0.851,0.518-1.415,1.3-1.737c0.207-0.092,0.437-0.15,0.656-0.219
			c0.15-0.046,0.299-0.057,0.449-0.081c1.852-0.242,3.669-0.127,5.463,0.391c2.91,0.84,4.934,2.68,6.119,5.463
			c0.449,1.07,0.771,2.174,0.851,3.324c0.184,2.53,0.127,5.049-0.265,7.568c-0.391,2.588-1.024,5.107-1.668,7.626
			c-0.207,0.817-0.437,1.622-0.644,2.438c-0.035,0.138,0.035,0.288,0.058,0.449c0.115-0.046,0.276-0.069,0.334-0.15
			c0.368-0.495,0.713-0.989,1.047-1.507c2.691-4.106,5.371-8.212,8.063-12.307c1.691-2.565,3.543-5.003,5.544-7.338
			c2.162-2.507,4.566-4.75,7.338-6.591c1.748-1.162,3.635-1.99,5.693-2.346c1.679-0.299,3.347-0.311,5.026,0.092
			c3.163,0.759,5.026,2.806,5.786,5.889c0.564,2.254,0.552,4.555,0.299,6.855c-0.472,4.198-1.438,8.304-2.68,12.342
			c-1.196,3.876-2.496,7.729-3.865,11.548c-1.99,5.555-3.807,11.168-5.452,16.827c-0.598,2.059-1.081,4.152-1.426,6.269
			c-0.38,2.358-0.322,4.739,0.196,7.085c0.115,0.529,0.322,1.024,0.61,1.472c0.449,0.69,1.277,0.943,2.001,0.644
			c0.874-0.357,1.61-0.897,2.254-1.564c0.897-0.943,1.783-1.898,2.611-2.898c2.496-3.025,4.658-6.28,6.694-9.616
			c3.957-6.476,7.614-13.124,11.226-19.795c1.104-2.036,1.944-4.141,2.599-6.361c1.449-4.923,2.991-9.823,4.52-14.723
			c0.437-1.403,0.771-2.795,0.805-4.267c0.012-0.759,0.161-1.53,0.357-2.266c0.196-0.713,0.736-1.162,1.472-1.3
			c0.92-0.161,1.84-0.127,2.726,0.184c1.449,0.518,2.864,1.116,4.164,1.932c0.518,0.322,1.012,0.69,1.484,1.081
			c1.288,1.104,1.955,2.553,2.059,4.221c0.046,0.84,0,1.691-0.138,2.519c-0.253,1.587-0.633,3.152-1.104,4.693
			c-1.829,6.016-3.462,12.077-4.888,18.196c-1.127,4.854-2.105,9.731-2.91,14.654c-0.483,2.956-0.748,5.924-0.644,8.937
			c0.046,1.311,0.127,2.611,0.506,3.865c0.173,0.587,0.391,1.162,0.702,1.691c0.552,0.978,1.748,1.369,2.795,0.955
			c0.495-0.196,0.989-0.449,1.392-0.782c0.713-0.575,1.426-1.162,2.047-1.84c0.978-1.081,1.909-2.197,2.795-3.359
			c2.427-3.175,4.497-6.579,6.533-10.007c2.853-4.808,5.521-9.731,8.166-14.665c0.782-1.461,1.173-2.956,1.369-4.578
			c0.345-2.887,0.874-5.751,1.702-8.558c0.368-1.231,1.001-2.208,1.955-3.037c1.599-1.392,3.451-2.254,5.498-2.749
			c2.53-0.621,5.015-0.437,7.476,0.391c0.506,0.173,1.012,0.322,1.553,0.495c0.15-0.184,0.345-0.345,0.403-0.541
			c0.115-0.368,0.161-0.748,0.207-1.127c0.357-3.439,1.012-6.821,1.921-10.156c0.759-2.749,1.829-5.36,3.255-7.833
			c1.254-2.139,2.829-4.003,4.739-5.578c2.116-1.737,4.497-2.876,7.2-3.359c2.749-0.495,5.475-0.541,8.212,0
			c2.634,0.529,4.888,1.737,6.878,3.497c2.105,1.863,3.6,4.152,4.727,6.717c1.116,2.542,1.725,5.233,2.174,7.959
			c0.552,3.347,0.61,6.717,0.46,10.087c-0.081,1.909-0.288,3.819-0.564,5.717c-1.001,6.855-2.772,13.503-5.348,19.933
			c-0.886,2.208-1.898,4.348-2.864,6.522c-0.219,0.483-0.46,0.966-0.667,1.392c0.207,0.414,0.506,0.403,0.794,0.334
			c1.955-0.483,3.761-1.3,5.429-2.415c1.461-0.989,2.795-2.151,4.026-3.416c2.864-2.933,5.084-6.326,7.016-9.915
			c0.104-0.207,0.242-0.414,0.265-0.633c0.115-1.104,0.702-2.013,1.185-2.967c1.576-3.152,3.175-6.303,4.75-9.443
			c0.207-0.414,0.391-0.828,0.598-1.288c-0.219-0.138-0.391-0.299-0.575-0.357c-0.437-0.138-0.897-0.219-1.346-0.322
			c-2.968-0.667-5.325-2.277-7.166-4.658c-0.61-0.794-1.139-1.645-1.541-2.553c-1.426-3.232-1.978-6.579-1.357-10.099
			c0.817-4.555,2.737-8.592,5.647-12.169c1.265-1.553,2.772-2.852,4.463-3.945c2.611-1.691,5.475-2.335,8.557-1.99
			c0.759,0.081,1.518,0.276,2.22,0.552c1.484,0.587,2.542,1.668,3.186,3.152c0.495,1.139,0.748,2.346,0.84,3.566
			c0.104,1.369,0.081,2.749-0.127,4.129c-0.483,3.198-1.3,6.303-2.289,9.374c-0.725,2.266-1.553,4.486-2.346,6.729
			c-0.23,0.656-0.472,1.3-0.69,1.955c-0.138,0.403,0.081,0.759,0.495,0.863c1.047,0.265,2.093,0.483,3.175,0.495
			c1.53,0.023,3.06,0.069,4.601,0.127c1.38,0.046,2.76,0.127,4.129,0.196c0.311,0.012,0.61,0.046,0.909,0.092
			c2.208,0.345,3.508,1.794,3.692,4.049c0.15,1.863-0.196,3.669-0.598,5.463c-0.437,1.944-0.909,3.876-1.403,5.809
			c-1.783,6.982-3.313,14.009-4.727,21.072c-0.357,1.806-0.679,3.623-0.909,5.44c-0.265,2.059-0.334,4.129,0.288,6.154
			c0.184,0.587,0.414,1.15,0.69,1.691c0.219,0.403,0.564,0.725,1.093,0.828c0.748-0.138,1.369-0.598,1.978-1.058
			c0.978-0.748,1.863-1.599,2.691-2.507c1.495-1.645,2.875-3.393,4.152-5.21c3.439-4.9,6.591-9.972,9.57-15.16
			c0.115-0.196,0.219-0.403,0.334-0.598c4.06-7.143,4.888-8.857,7.879-16.333c0.115-0.288,0.184-0.575,0.242-0.748
			c-0.012-0.725-0.437-1.116-0.736-1.541c-2.484-3.554-4.29-7.419-5.509-11.571c-0.69-2.358-1.07-4.773-1.311-7.223
			c-0.276-2.945-0.012-5.82,0.621-8.673c0.633-2.876,1.748-5.544,3.623-7.856c1.162-1.438,2.519-2.68,4.014-3.761
			c1.518-1.104,3.244-1.737,5.072-2.07c2.289-0.426,4.578-0.449,6.821,0.265c2.277,0.713,3.83,2.197,4.555,4.486
			c0.184,0.575,0.265,1.196,0.299,1.806c0.104,2.013-0.219,3.968-0.805,5.889c-0.759,2.496-1.691,4.923-2.657,7.338
			c-1.081,2.703-2.197,5.394-3.278,8.097c-0.506,1.277-1.07,2.542-1.346,3.876c0.288,0.851,0.84,1.507,1.3,2.208
			c3.405,5.187,6.648,10.49,9.604,15.953c2.335,4.325,4.486,8.719,6.165,13.342c1.024,2.807,1.921,5.659,2.197,8.661
			c0.023,0.288,0.046,0.288,0.46,0.702c0.449-0.322,0.69-0.817,0.978-1.254c1.898-2.876,3.646-5.855,5.383-8.834
			c2.887-4.969,5.624-10.03,8.155-15.194c0.575-1.173,1.139-2.335,1.714-3.508c0.069-0.138,0.127-0.276,0.207-0.414
			c0.955-1.737,2.853-1.875,4.118-0.276c0.138,0.173,0.219,0.403,0.345,0.633C448.74,286.581,448.763,286.834,448.648,287.018
			L448.648,287.018z M138.254,301.683c1.081-0.253,2.128-0.46,3.152-0.736c5.153-1.415,9.834-3.796,14.09-7.016
			c2.266-1.714,4.302-3.681,6.027-5.958c2.68-3.52,3.83-7.453,3.037-11.859c-0.288-1.587-0.874-3.083-1.702-4.474
			c-0.357-0.598-0.782-1.127-1.357-1.541c-0.506-0.368-1.058-0.575-1.702-0.61c-0.943-0.034-1.829,0.184-2.645,0.633
			c-1.081,0.598-2.024,1.369-2.922,2.208c-1.909,1.794-3.497,3.853-4.98,5.993c-4.44,6.384-7.879,13.285-10.616,20.543
			c-0.265,0.713-0.483,1.449-0.667,2.185C137.921,301.212,138.128,301.43,138.254,301.683L138.254,301.683z M300.26,299.256
			c-0.69-2.185-1.208-4.417-1.484-6.752c-0.15,0.138-0.288,0.207-0.334,0.311c-0.276,0.644-0.334,1.334-0.391,2.013
			c-0.069,0.84-0.104,1.679-0.207,2.519c-0.506,3.888-0.426,7.787-0.196,11.675c0.069,1.139,0.219,2.289,0.414,3.416
			c0.276,1.587,0.656,3.14,1.265,4.635c0.552,1.357,1.254,2.611,2.22,3.727c1.449,1.691,3.313,2.438,5.486,2.369
			c2.082-0.058,4.083-0.541,5.924-1.576c1.967-1.093,3.635-2.53,5.118-4.198c0.127-0.15,0.127-0.414,0.196-0.69
			c-0.886-0.345-1.737-0.667-2.588-1.001C307.932,312.679,302.779,307.193,300.26,299.256L300.26,299.256z M330.764,291.24
			c1.587-6.453,2.231-12.974,1.76-19.611c-0.104-1.53-0.345-3.048-0.644-4.543c-0.368-1.806-0.909-3.566-1.714-5.233
			c-0.736-1.53-1.656-2.933-2.887-4.118c-2.093-2.024-4.578-3.002-7.488-2.933c-1.369,0.035-2.565,0.472-3.566,1.369
			c-0.621,0.564-1.185,1.208-1.702,1.863c-0.759,0.978-1.3,2.082-1.737,3.232c-0.61,1.576-1.127,3.175-1.472,4.831
			c-0.449,2.174-0.805,4.371-1.07,6.579c-0.414,3.347-0.667,6.706-0.713,10.087c-0.046,3.071,0.207,6.131,0.495,9.179
			c0.276,2.829,0.828,5.613,1.771,8.293c0.851,2.404,2.001,4.635,3.589,6.648c1.53,1.921,3.462,3.232,5.797,3.957
			c0.426,0.138,0.897,0.161,1.438,0.265c0.805-0.817,1.173-1.668,1.599-2.473C327.129,303.098,329.269,297.29,330.764,291.24
			L330.764,291.24z M360.773,259.597c0.161-0.828,0.265-1.656,0.242-2.496c-0.012-0.23-0.057-0.449-0.081-0.679
			c-0.046-0.311-0.426-0.541-0.69-0.403c-0.334,0.173-0.713,0.311-0.978,0.564c-0.61,0.575-1.196,1.173-1.725,1.829
			c-1.633,2.047-2.841,4.336-3.853,6.729c-0.621,1.484-0.978,3.048-1.231,4.635c-0.161,1.024,0.057,1.978,0.403,2.91
			c0.184,0.506,0.529,0.932,0.989,1.231c0.449,0.288,0.909,0.552,1.714,0.575c0.173-0.311,0.345-0.552,0.472-0.828
			c0.552-1.254,1.139-2.507,1.622-3.784C358.921,266.522,360.083,263.129,360.773,259.597L360.773,259.597z M409.242,260.081
			c0.702-1.852,1.277-3.761,1.714-5.705c0.184-0.828,0.299-1.656,0.196-2.496c-0.046-0.391-0.161-0.736-0.38-1.07
			c-0.449-0.69-1.334-0.932-1.99-0.472c-0.368,0.253-0.725,0.575-1.012,0.909c-0.748,0.874-1.277,1.898-1.737,2.956
			c-1.127,2.611-1.817,5.348-2.139,8.178c-0.242,2.139-0.161,4.279,0.414,6.372c0.138,0.506,0.23,1.035,0.656,1.403
			c0.368-0.196,0.518-0.552,0.656-0.909C406.838,266.188,408.069,263.14,409.242,260.081L409.242,260.081z M120.99,258.229
			c0,0.84,0,1.691,0,2.53c-0.011,3.738-0.932,7.281-2.277,10.731c-1.3,3.313-3.129,6.315-5.279,9.121
			c-4.164,5.463-9.294,9.731-15.528,12.664c-2.933,1.38-5.993,2.335-9.202,2.784c-4.969,0.679-9.604-0.207-13.814-3.048
			c-1.737-1.173-3.209-2.611-4.451-4.29c-1.426-1.921-2.473-4.049-3.29-6.292c-0.184-0.506-0.288-1.035-0.403-1.553
			c-0.207-0.863-0.023-1.679,0.368-2.45c0.276-0.564,0.725-0.978,1.323-1.208c0.391-0.15,0.759-0.138,1.093,0.092
			c0.311,0.219,0.61,0.46,0.863,0.748c0.725,0.805,1.242,1.748,1.679,2.737c0.495,1.127,1.058,2.208,1.725,3.244
			c1.104,1.702,2.496,3.094,4.233,4.141c3.025,1.817,6.165,2.128,9.478,0.886c1.449-0.541,2.76-1.323,3.98-2.254
			c2.22-1.702,3.991-3.819,5.406-6.2c2.507-4.198,4.141-8.707,4.681-13.584c0.138-1.219,0.276-2.438,0.368-3.658
			c0.242-3.474-0.345-6.878-1.104-10.248c-2.128-9.432-6.292-17.897-12.261-25.465c-2.668-3.382-5.717-6.407-9.19-8.972
			c-2.162-1.599-4.474-2.945-6.936-4.049c-3.267-1.461-6.66-2.231-10.248-2.197c-2.622,0.023-5.199,0.219-7.729,0.92
			c-2.461,0.69-4.739,1.725-6.855,3.14c-3.692,2.461-6.694,5.601-9.087,9.317c-2.128,3.301-3.75,6.855-5.003,10.57
			c-1.047,3.129-1.794,6.338-2.358,9.581c-0.621,3.554-0.92,7.131-1.116,10.743c-0.138,2.599-0.311,5.21-0.219,7.81
			c0.173,4.831,0.667,9.627,2.197,14.263c0.69,2.116,1.541,4.175,2.507,6.165c1.783,3.646,4.371,6.637,7.407,9.282
			c2.795,2.427,5.889,4.405,9.156,6.119c2.036,1.07,4.129,2.024,6.28,2.841c4.44,1.679,8.88,3.359,13.331,5.015
			c3.382,1.254,6.706,2.611,9.903,4.29c3.761,1.967,7.189,4.394,10.248,7.338c3.968,3.819,6.556,8.431,7.913,13.768
			c1.001,3.911,1.196,7.879,0.817,11.87c-0.276,2.91-1.012,5.728-2.082,8.454c-2.208,5.636-5.394,10.651-9.535,15.056
			c-5.153,5.498-11.214,9.731-18.012,12.928c-2.922,1.38-5.993,2.335-9.133,3.048c-4.348,0.989-8.776,1.518-13.227,1.403
			c-12.181-0.334-23.395-3.692-33.356-10.846c-4.037-2.898-7.419-6.407-10.007-10.662c-1.564-2.565-2.795-5.279-3.508-8.212
			c-0.759-3.163-1.001-6.349-0.437-9.581c0.518-2.956,1.219-5.855,2.369-8.627c0.587-1.415,1.288-2.783,2.174-4.037
			c0.529-0.759,1.093-1.484,1.829-2.047c0.874-0.679,1.829-1.104,2.968-1.047c0.449,0.023,0.863,0.426,0.92,0.874
			c0.069,0.541,0.012,1.081-0.219,1.576c-0.253,0.552-0.529,1.104-0.84,1.633c-0.92,1.599-1.185,3.347-0.828,5.118
			c0.966,4.612,2.438,9.029,5.406,12.802c2.346,2.991,5.164,5.44,8.465,7.338c3.554,2.036,7.384,3.359,11.387,4.164
			c4.382,0.874,8.811,1.15,13.273,0.886c6.303-0.38,12.445-1.645,18.438-3.612c0.368-0.115,0.725-0.265,1.07-0.414
			c5.74-2.427,11.191-5.36,16.195-9.098c2.162-1.61,4.152-3.405,5.855-5.498c0.874-1.07,1.645-2.22,2.312-3.428
			c0.989-1.783,1.461-3.692,1.38-5.74c0-0.15-0.023-0.311-0.023-0.46c0.035-2.806-1.185-5.015-3.244-6.821
			c-0.989-0.874-2.082-1.564-3.232-2.185c-3.117-1.691-6.43-2.876-9.903-3.566c-4.21-0.84-8.419-1.645-12.641-2.415
			c-4.371-0.794-8.765-1.472-13.124-2.3c-5.268-1.001-10.536-2.013-15.654-3.612c-3.589-1.127-7.108-2.415-10.467-4.095
			c-3.451-1.714-6.625-3.842-9.501-6.418c-4.405-3.945-7.315-8.811-8.788-14.539c-0.288-1.116-0.529-2.243-0.794-3.359
			c-0.506-2.174-0.736-4.382-0.943-6.591c-0.058-0.69-0.058-1.38-0.012-2.07c0.207-2.829,0.391-5.659,0.909-8.454
			c1.438-7.787,3.658-15.355,6.844-22.601c5.118-11.629,12.422-21.67,21.934-30.066c4.451-3.922,9.34-7.212,14.688-9.777
			c3.738-1.794,7.649-3.129,11.732-3.922c1.208-0.23,2.381-0.587,3.577-0.84c2.945-0.621,5.924-0.725,8.926-0.633
			c5.532,0.173,10.72,1.679,15.7,4.003c5.038,2.358,9.581,5.463,13.607,9.294c8.247,7.833,14.009,17.173,17.115,28.134
			C120.426,249.062,121.07,253.593,120.99,258.229L120.99,258.229z M268.871,255.008c-1.714,1.771-3.842,2.714-6.453,2.714
			c-2.507,0.069-4.727-1.852-5.348-4.382c-0.506-2.059-0.426-4.083,0.173-6.108c0.414-1.403,1.024-2.714,1.898-3.888
			c1.001-1.323,2.254-2.266,3.922-2.588c0.46-0.092,0.909-0.069,1.357,0.035c2.001,0.437,3.405,1.691,4.428,3.37
			c0.955,1.576,1.599,3.313,1.829,5.141C270.942,251.454,270.39,253.432,268.871,255.008L268.871,255.008z"
          />
        </g>
      </g>
      <polyline
        stroke={primary}
        stroke-width="5"
        stroke-miterlimit="10"
        points="521.5,434.499 487.378,435.226 487.378,404.198 
	520.712,372.857 568.378,372.857 568.378,538.966 594.75,538.966 595,580.023 493.75,580.023 494,539.25 520.045,539.25 
	520.045,434.286 "
      />
    </svg>
  );
}

Artwrk15.props = {
  primary: string,
};

Artwrk15.defaultProps = {
  primary: "#000",
};
