import React from "react";
import { string } from "prop-types";

export default function Artwrk1(props) {
  const { primary, secondary } = props;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill={primary}
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="Shape_1_copy_10_1_" enable-background="new    ">
        <g id="Shape_1_copy_10">
          <g>
            <path
              d="M589.371,132.992c0.165-8.051,0.044-16.116,0.044-24.183c0-1.272-0.09-2.545-0.179-3.803
				c-0.031-0.525-0.09-1.098-0.316-1.544c-0.735-1.495-1.44-3.039-2.609-4.248c-2.145-2.228-4.2-4.534-6.525-6.554
				c-1.319-1.129-2.505-2.434-3.72-3.691c-0.345-0.366-0.63-0.827-0.87-1.272c-0.51-0.986-0.135-2.228,0.749-2.768
				c0.705-0.429,1.469-0.637,2.28-0.731c1.785-0.19,3.585-0.382,5.37-0.652c1.08-0.159,2.16-0.398,3.225-0.7
				c1.335-0.382,1.635-0.843,1.665-2.243c0.015-0.748,0-1.479,0-2.228c0-7.748,0-15.48,0-23.227c0-15.591,0-31.182,0-46.757
				c0-0.843-0.015-1.703-0.075-2.545c-0.031-0.429-0.285-0.78-0.614-1.05c-0.66-0.509-1.41-0.78-2.22-0.86
				c-1.109-0.112-2.205-0.016-3.285,0.175c-4.829,0.86-9.66,1.734-14.49,2.577c-7.2,1.256-14.399,2.53-21.599,3.739
				c-4.244,0.717-8.505,1.336-12.749,2.02c-3.66,0.588-7.305,1.193-10.964,1.781c-3.254,0.525-6.525,1.066-9.78,1.559
				c-3.855,0.588-7.71,1.177-11.58,1.687c-3.961,0.509-7.95,0.907-11.925,1.385c-4.77,0.572-9.525,1.177-14.295,1.75
				c-2.28,0.27-4.575,0.492-6.855,0.748c-5.37,0.605-10.725,1.225-16.095,1.83c-3.48,0.398-6.961,0.78-10.44,1.193
				c-2.461,0.302-4.575,1.352-6.36,3.229c-2.64,2.753-5.34,5.425-8.055,8.082c-2.79,2.737-5.609,5.425-8.415,8.13
				c-0.3,0.286-0.614,0.557-0.961,0.764c-0.435,0.255-0.899,0.462-1.365,0.621c-1.02,0.349-2.07-0.27-2.355-1.352
				c-0.3-1.129-0.375-2.291-0.375-3.468c0.015-2.434,0.031-4.883-0.015-7.318c-0.015-1.162-0.12-2.322-0.3-3.484
				c-0.21-1.321-1.425-2.688-2.969-2.657c-1.2,0.032-2.399,0.063-3.6,0.159c-1.8,0.143-3.585,0.366-5.37,0.478
				c-3.195,0.206-6.389,0.335-9.585,0.509c-0.899,0.047-1.8,0.063-2.684,0.19c-3.179,0.492-6.375,0.462-9.57,0.588
				c-4.59,0.19-9.179,0.462-13.77,0.7c-1.995,0.112-3.99,0.318-5.985,0.366c-5.49,0.127-10.995,0.19-16.5,0.286
				c-1.095,0.016-2.205,0.032-3.3,0.127c-5.88,0.462-11.775,0.717-17.669,0.795c-5.399,0.08-10.8,0.08-16.199,0.127
				c-4.394,0.032-8.79,0.096-13.2,0.08c-7.095-0.032-14.189-0.159-21.3-0.175c-5.894-0.016-11.79-0.445-17.684-0.541
				c-0.495-0.016-1.005-0.047-1.5-0.112c-2.49-0.318-4.995-0.286-7.485-0.286c-1.5,0-3,0.016-4.5-0.016
				c-4.889-0.112-9.795-0.175-14.684-0.605c-1.59-0.143-3.195-0.175-4.785-0.239c-0.705-0.032-1.38,0.112-2.039,0.429
				c-0.899,0.429-1.425,1.162-1.5,2.195c-0.06,0.731-0.044,1.479-0.044,2.228c0,3.707,0.015,7.43,0,11.137
				c0,0.843-0.106,1.687-0.165,2.53c-1.034,0.127-1.725-0.302-2.085-1.368c-0.45-1.289-0.945-2.561-1.44-3.834
				c-1.171-3.039-2.324-6.078-3.525-9.1c-0.391-0.97-0.886-1.909-1.335-2.847c-0.525-1.098-1.38-1.814-2.43-2.243
				c-1.41-0.572-2.835-1.003-4.365-0.97c-2.595,0.032-5.205-0.016-7.8-0.047c-1.29-0.016-2.58-0.112-3.87-0.366
				c-1.65-0.335-3.36-0.382-5.025-0.764c-0.48-0.112-0.99-0.047-1.5-0.047c-1.095-0.016-2.205-0.08-3.3-0.016
				c-3.015,0.206-5.955-0.572-8.94-0.795c-1.455-0.557-2.955-0.429-4.455-0.445c-1.305-0.016-2.595-0.016-3.899-0.047
				c-0.69-0.016-1.425-0.016-2.085-0.223c-1.171-0.366-2.37-0.382-3.555-0.429c-0.601-0.032-1.215-0.016-1.785-0.19
				c-1.171-0.349-2.37-0.366-3.57-0.382c-1.305-0.016-2.595-0.016-3.855-0.445c-0.464-0.159-0.99-0.175-1.485-0.19
				c-1.305-0.047-2.609,0-3.899-0.112c-1.394-0.112-2.775-0.349-4.155-0.541c-0.601-0.08-1.215-0.08-1.77-0.255
				c-0.975-0.318-1.964-0.366-2.969-0.382c-2.101-0.047-4.2,0.08-6.24-0.572c-0.179-0.063-0.391-0.032-0.601-0.047
				c-2.386-0.206-4.725-0.827-7.14-0.7c-0.195,0.016-0.405,0-0.601-0.08c-1.816-0.684-3.78-0.335-5.595-0.986
				c-2.595,0-5.16-0.572-7.725-0.764c-2.49-0.19-4.935-0.843-7.44-0.764c-0.195,0-0.405-0.047-0.585-0.112
				c-1.155-0.445-2.355-0.414-3.555-0.445c-1.095-0.032-2.176-0.159-3.27-0.398c-1.754-0.382-3.539-0.637-5.324-0.811
				c-1.095-0.112-2.176-0.286-3.254-0.492c-1.86-0.349-3.689-0.939-5.625-0.843c-0.3,0.016-0.601-0.032-0.886-0.112
				c-2.324-0.605-4.74-0.7-7.08-1.177c-1.665-0.335-3.345-0.541-5.025-0.795c-2.565-0.398-5.085-1.097-7.695-1.162
				c-0.391-0.016-0.78-0.127-1.184-0.206c-2.16-0.429-4.275-1.066-6.495-1.034c-0.195,0-0.405-0.016-0.601-0.063
				c-2.43-0.588-4.875-1.129-7.365-1.289c-2.894-0.795-5.925-0.907-8.819-1.718c-3.179-0.255-6.27-1.289-9.464-1.448
				c-0.3-0.016-0.585-0.112-0.886-0.19c-1.935-0.525-3.855-1.082-5.894-1.034c-0.3,0.016-0.601-0.063-0.886-0.127
				c-2.145-0.462-4.319-0.811-6.435-1.415c-1.891-0.541-3.3-0.047-4.515,1.495C3.407,5.233,3.047,5.934,3.047,6.841
				c0,0.954-0.015,1.909-0.015,2.863c0,26.615,0,53.216,0,79.832c0,0.525,0,1.066,0,1.591c-0.015,2.896,0.886,5.361,2.88,7.397
				c2.88,2.943,5.684,5.966,8.52,8.957c0.645,0.668,1.35,1.241,2.07,1.814c0.795,0.621,1.485,1.415,2.176,2.163
				c0.36,0.398,0.525,0.891,0.375,1.464c-0.15,0.572-0.69,1.003-1.35,1.066c-0.405,0.032-0.795,0.063-1.2,0.063
				c-1.695,0.032-3.404,0.047-5.1,0.063c-0.601,0.016-1.215-0.063-1.8,0.08c-1.469,0.349-2.955,0.492-4.44,0.652
				c-0.57,0.063-1.14,0.366-1.679,0.637c-0.57,0.286-0.915,0.811-1.005,1.479c-0.06,0.525-0.09,1.05-0.106,1.591
				c-0.015,1.385-0.015,2.753-0.015,4.136c0,23.211,0,46.44,0,69.65c0,0.954,0.031,1.909,0.075,2.863
				c0.015,0.461,0.3,0.764,0.676,0.954c1.8,0.827,3.676,0.97,5.58,0.731c1.29-0.159,2.565-0.398,3.855-0.588
				c7.41-1.113,14.834-2.243,22.244-3.357c0.99-0.143,1.98-0.302,2.969-0.461c3.66-0.541,7.319-1.082,10.979-1.623
				c3.855-0.557,7.725-1.097,11.58-1.638c3.87-0.525,7.725-1.034,11.595-1.559c4.065-0.557,8.114-1.162,12.179-1.654
				c4.964-0.621,9.93-1.177,14.894-1.718c3.179-0.349,6.329-0.923,9.539-0.954c0.3,0,0.601-0.08,0.899-0.096
				c3.375-0.335,6.765-0.684,10.14-1.019c0.795-0.08,1.59-0.159,2.386-0.255c2.985-0.318,5.955-0.621,8.94-0.939
				c1.2-0.127,2.386-0.206,3.585-0.335c1.695-0.159,3.375-0.349,5.07-0.492c2.684-0.223,5.385-0.335,8.07-0.668
				c2.58-0.335,5.174-0.525,7.77-0.652c0.899-0.047,1.8-0.127,2.684-0.206c4.679-0.414,9.345-1.019,14.039-1.019
				c0.99,0,1.995-0.127,2.985-0.206c1.5-0.127,2.985-0.318,4.469-0.414c3.285-0.19,6.585-0.335,9.885-0.509
				c1.29-0.063,2.595-0.08,3.885-0.255c2.386-0.318,4.77-0.414,7.17-0.541c1.394-0.08,2.79-0.175,4.184-0.27
				c0.899-0.063,1.8-0.159,2.79-0.588c0.345-0.461,0.78-1.019,1.215-1.591l0,0c0.179-0.239,0.495-0.461,0.525-0.731
				c0.106-0.731,0.12-1.479,0.12-2.212c0.015-4.136,0-8.257,0-12.393c0-0.637,0-1.272,0.015-1.909
				c0.015-0.97,0.316-1.607,0.72-1.528c0.555,0.096,0.81,0.541,0.99,1.05c0.21,0.588,0.435,1.193,0.676,1.766
				c0.886,2.132,1.8,4.248,2.655,6.396c0.824,2.036,1.59,4.121,2.386,6.157c0.27,0.684,0.601,1.336,0.915,2.005
				c0.614,1.305,1.635,2.1,2.894,2.594c2.22,0.874,4.515,0.907,6.795,0.557c1.5-0.239,2.969-0.318,4.469-0.318
				c6.09-0.047,12.179-0.096,18.285-0.143c0.899-0.016,1.816-0.016,2.684-0.206c1.095-0.239,2.176-0.318,3.27-0.335
				c1.905-0.032,3.795-0.063,5.7-0.063c18.674-0.016,37.365-0.016,56.039-0.016c4.8,0,9.585,0.016,14.384,0.047
				c1.394,0.016,2.79-0.016,4.17,0.382c0.66,0.19,1.38,0.175,2.085,0.19c6.195,0.063,12.389,0.127,18.585,0.159
				c0.601,0,1.184-0.112,1.785-0.175c3.705,0.047,7.394,0.063,11.099,0.127c1.005,0.016,1.995,0.127,3,0.19
				c2.295,0.159,4.59,0.349,6.885,0.461c4.29,0.19,8.595,0.335,12.884,0.492c1.005,0.032,2.01,0.016,3,0.159
				c3.78,0.557,7.575,0.541,11.37,0.731c2.79,0.143,5.595,0.239,8.385,0.557c2.79,0.318,5.58,0.461,8.37,0.572
				c1.394,0.063,2.79,0.143,4.184,0.255c3.99,0.335,7.964,0.811,11.954,0.954c1.29,0.047,2.58,0.27,3.885,0.366
				c2.985,0.255,5.969,0.509,8.969,0.717c0.886,0.063,1.8-0.032,2.684-0.143c0.87-0.112,1.41-0.717,1.77-1.544
				c0.3-0.7,0.345-1.432,0.345-2.179c0-1.797,0-3.611,0-5.41c0-0.414,0.06-0.843,0.12-1.256c0.045-0.349,0.48-0.731,0.855-0.764
				c0.645-0.08,1.184,0.127,1.575,0.684c0.735,1.034,1.184,2.195,1.62,3.388c0.525,1.479,1.08,2.959,1.59,4.471
				c0.78,2.308,2.295,3.66,4.575,4.072c1.679,0.302,3.345,0.668,5.039,0.748c1.305,0.063,2.595,0.143,3.885,0.286
				c4.964,0.557,9.93,1.129,14.909,1.703c2.385,0.27,4.77,0.478,7.155,0.764c4.365,0.541,8.73,1.113,13.094,1.687
				c3.87,0.509,7.74,1.003,11.609,1.528c4.259,0.572,8.52,1.162,12.78,1.766c4.65,0.652,9.315,1.289,13.964,1.973
				c5.55,0.827,11.085,1.687,16.619,2.545c4.845,0.748,9.689,1.528,14.534,2.243c1.184,0.175,2.385,0.175,3.585,0.223
				c0.81,0.032,1.575-0.239,2.31-0.605c0.795-0.382,1.29-0.986,1.319-1.94c0.031-0.843,0.031-1.703,0.031-2.545
				c0-4.979,0-9.975,0-14.954c0-1.066,0-2.116,0.075-3.182c0.225-2.753,0.195-5.504,0.195-8.257c0-2.338,0.06-4.661,0.106-7.001
				c0.044-2.434,0.225-4.883,0.135-7.318C589.145,145.067,589.251,139.038,589.371,132.992z M268.889,45.317
				c-0.015-1.399,0.21-2.737,0.99-4.072c0.285-0.08,0.645-0.255,1.02-0.286c0.795-0.063,1.59-0.047,2.399-0.047
				c11.085,0,22.184,0,33.269,0c0.899,0,1.8,0,2.7,0.063c1.08,0.08,2.039,1.129,2.16,2.291c0.031,0.318,0.06,0.637,0.06,0.954
				c0,4.55-0.015,9.117,0,13.665c0,1.097-0.195,2.116-0.795,3.006c-0.21,0.335-0.555,0.572-0.824,0.827
				c-2.265-0.605-4.41-0.748-6.45,0.557c-0.63,1.703-0.93,3.437-0.93,5.265c0.031,19.823,0.015,39.631,0.015,59.453
				c0,0.731,0.044,1.479,0.106,2.212c0.044,0.572,0.645,1.225,1.184,1.272c0.795,0.08,1.59,0.112,2.385,0.143
				c1.394,0.032,2.79,0.032,4.2,0.08c0.464,0.016,0.974,0.525,1.02,1.003c0.044,0.414,0.09,0.843,0.09,1.256
				c0,5.091,0,10.183-0.015,15.256c0,0.414-0.044,0.86-0.165,1.242c-0.09,0.27-0.329,0.525-0.57,0.668
				c-0.254,0.143-0.57,0.175-0.87,0.19c-0.705,0.032-1.394,0.032-2.1,0.032c-11.684,0-23.384,0.016-35.07,0.016
				c-0.705,0-1.394,0-2.101-0.047c-0.974-0.063-1.606-0.78-1.665-1.877c-0.015-0.318-0.031-0.637-0.031-0.954
				c0-4.869-0.015-9.752,0-14.621c0-0.525,0.09-1.05,0.195-1.559c0.031-0.175,0.241-0.382,0.405-0.429
				c0.375-0.127,0.78-0.206,1.171-0.223c1.305-0.032,2.595-0.016,3.899-0.032c0.495-0.016,1.005-0.032,1.485-0.159
				c0.51-0.127,0.945-0.492,1.05-1.034c0.345-1.638,0.765-3.262,0.765-4.995c-0.044-19.187-0.031-38.373-0.015-57.544
				c0-1.511-0.165-2.959-0.69-4.358c-0.45-1.193-1.275-1.877-2.52-1.893c-1.095-0.016-2.205-0.016-3.3-0.032
				c-0.495-0.016-1.005,0.016-1.65-0.492c-0.63-1.289-0.855-2.61-0.84-4.025C268.92,52.524,268.904,48.911,268.889,45.317z
				 M78.62,162.504c-0.044,0.7-0.464,1.146-1.034,1.432c-0.179,0.096-0.36,0.223-0.555,0.239c-2.189,0.112-4.29,1.082-6.51,0.843
				c-1.44-0.159-2.79,0.286-4.125,0.684c-1.469,0.429-2.925,0.637-4.44,0.572c-0.405-0.016-0.795,0.016-1.2,0
				c-1.109-0.047-2.189,0.143-3.225,0.572c-1.321,0.557-2.684,0.78-4.109,0.684c-1.71-0.112-3.389,0.096-4.995,0.811
				c-0.945,0.429-1.935,0.445-2.94,0.461c-1.095,0.016-2.205-0.032-3.285,0.127c-1.275,0.19-2.534,0.557-3.795,0.843
				c-0.391,0.096-0.78,0.239-1.171,0.239c-1.5,0.032-2.985,0.127-4.44,0.588c-0.645,0.206-1.394,0.143-2.085,0.112
				c-1.425-0.063-2.805,0.096-4.109,0.684c-1.14,0.494-2.31,0.668-3.525,0.588c-1.635-0.112-3.179,0.206-4.695,0.827
				c-1.455,0.605-2.94,0.525-4.44,0.302c-0.525-0.08-0.915-0.445-1.109-0.97c-0.539-1.385-0.81-2.8-0.81-4.328
				c0.044-5.504,0.015-11.025,0.015-16.545c0-6.777,0.031-13.571-0.015-20.348c-0.015-1.94,0.285-3.77,0.855-5.441
				c0.405-0.445,0.78-0.557,1.184-0.557c1.606-0.016,3.195-0.032,4.8-0.047c1.005,0,1.995-0.016,2.969-0.349
				c0.555-0.19,1.171-0.206,1.77-0.286c0.601-0.063,1.23,0.016,1.785-0.19c1.259-0.462,2.565-0.414,3.855-0.414
				c1.695,0,3.404,0,5.1,0.016c0.391,0,0.795,0.063,1.184,0.175c0.51,0.159,0.899,0.557,0.974,1.098
				c0.12,0.827,0.165,1.687,0.165,2.53c0.015,3.388,0,6.777,0.015,10.183c0,0.748,0.015,1.479,0.075,2.228
				c0.09,1.019,1.005,2.02,1.964,2.1c1.8,0.175,3.585,0.255,5.324-0.492c1.321-0.572,2.7-0.731,4.109-0.684
				c1.005,0.032,1.995,0.032,3-0.047c1.635-0.127,2.73-1.336,2.85-3.102c0.044-0.525,0.031-1.066,0.031-1.591
				c0-4.883,0-9.752,0-14.637c0-1.066-0.06-2.1-0.345-3.135c-0.405-1.479-1.08-2.625-2.265-3.611
				c-2.039-1.702-3.795-3.77-5.894-5.394c-2.101-1.623-3.749-3.803-5.969-5.282c-0.585-0.382-1.02-1.003-1.531-1.511
				c-0.435-0.429-0.81-0.97-1.321-1.289c-1.56-1.003-2.759-2.434-4.05-3.754c-0.72-0.731-1.425-1.464-2.28-2.036
				c-0.585-0.382-1.08-0.939-1.575-1.464c-1.14-1.177-2.265-2.386-3.42-3.58c-0.93-0.954-1.8-1.989-2.955-2.688
				c-0.345-0.206-0.63-0.525-0.915-0.827c-2.205-2.308-4.394-4.63-6.585-6.952c-0.135-0.143-0.285-0.302-0.405-0.462
				c-0.795-1.003-1.29-2.132-1.29-3.468c0-0.843-0.015-1.703-0.015-2.545c0-17.596,0-35.191,0-52.802c0-1.162,0.06-2.322,0.12-3.484
				c0.031-0.478,0.555-1.019,1.005-1.019c2.189-0.032,4.38-0.349,6.539,0.366c0.735,0.255,1.575,0.206,2.37,0.206
				c1.215-0.016,2.386,0.127,3.48,0.764c0.72,0.429,1.515,0.509,2.324,0.509c0.601,0,1.2,0.016,1.8,0
				c0.915-0.016,1.8,0.08,2.609,0.557c0.99,0.588,2.07,0.764,3.179,0.684c1.425-0.112,2.775,0.19,4.109,0.731
				c0.945,0.382,1.92,0.572,2.925,0.541c1.2-0.032,2.399-0.032,3.539,0.478c0.27,0.112,0.585,0.159,0.886,0.159
				c2.114,0,4.14,0.557,6.179,1.082c0.886,0.223,1.785,0.239,2.684,0.206c1.41-0.047,2.79,0.127,4.109,0.684
				c1.036,0.445,2.114,0.637,3.225,0.588c0.495-0.016,1.005,0,1.5,0c0.81-0.016,1.606,0.063,2.324,0.492
				c1.08,0.652,2.265,0.78,3.48,0.764c0.899-0.016,1.8,0.032,2.715,0.047c1.125,0.668,1.995,1.177,2.925,1.718
				c0.254,2.514,0.824,4.948,0.81,7.526c-0.09,9.005-0.044,18.025-0.015,27.03c0,1.511-0.09,2.959-0.765,4.328
				c-0.044,0.096-0.12,0.175-0.179,0.255c-0.316,0.302-0.69,0.398-1.095,0.429c-2.114,0.19-4.2-0.032-6.21-0.717
				c-1.259-0.429-2.534-0.541-3.84-0.525c-2.295,0.016-4.604,0.016-6.899,0c-0.601,0-1.2-0.016-1.8-0.096
				c-0.915-0.127-1.891-1.162-1.964-2.132c-0.06-0.637-0.075-1.272-0.075-1.909c-0.015-2.116-0.031-4.248,0-6.364
				c0.015-1.511-0.15-2.959-0.84-4.311c-0.45-0.605-1.08-0.891-1.785-1.05c-0.3-0.063-0.601-0.063-0.886-0.175
				c-4.155-1.623-8.474-1.098-12.749-1.098c-0.48,0-0.99,0.509-1.036,0.986c-0.031,0.318-0.09,0.637-0.09,0.939
				c0,5.41,0.015,10.818,0.031,16.228c0,0.318,0.075,0.621,0.12,0.97c1.275,2.704,3.329,4.74,5.49,6.619
				c1.005,0.874,1.995,1.75,2.925,2.704c1.29,1.321,2.474,2.784,4.05,3.754c0.345,0.206,0.614,0.541,0.899,0.843
				c1.29,1.336,2.505,2.72,4.05,3.754c0.585,0.382,1.02,1.003,1.531,1.511c0.945,0.954,1.8,2.005,2.969,2.641
				c2.324,2.322,4.889,4.344,7.365,6.46c0.614,0.525,1.125,1.209,1.754,1.718c1.259,1.034,2.399,2.243,3.78,3.102
				c0.51,0.318,0.93,0.795,1.38,1.209c1.109,1.066,2.205,2.148,3.316,3.214c0.614,0.588,0.975,1.336,1.08,2.212
				c0.075,0.621,0.075,1.272,0.075,1.909c0,17.182,0,34.348,0,51.529C78.679,161.023,78.664,161.772,78.62,162.504z M88.85,97.642
				c-0.09,1.177-1.036,1.989-2.07,1.718c-0.87-0.223-1.8-0.398-2.534-0.891c-2.101-1.401-4.155-2.912-5.91-4.805
				c-0.27-0.302-0.525-0.637-0.735-1.003c-0.63-1.05-0.915-2.116-0.15-3.245c0.705-1.034,1.71-1.575,2.85-1.272
				c1.575,0.414,3.165,0.429,4.754,0.478c0.3,0.016,0.601,0.016,0.899,0.047c0.705,0.08,1.35,0.366,1.95,0.764
				c0.525,0.366,0.886,0.874,0.945,1.544c0.075,1.162,0.106,2.322,0.15,3.437C88.925,95.526,88.925,96.593,88.85,97.642z
				 M157.892,151.51c0,0.748,0,1.495-0.075,2.228c-0.09,0.86-0.375,1.638-1.095,2.148c-0.21,0.063-0.405,0.175-0.585,0.175
				c-2.025-0.063-3.974,0.349-5.894,0.954c-0.886,0.286-1.754,0.366-2.655,0.366c-1.2,0-2.399,0.063-3.601-0.016
				c-2.819-0.175-5.565,0.27-8.295,0.986c-0.795,0.206-1.575,0.286-2.37,0.286c-1.695,0-3.405,0.016-5.1,0.016
				c-1.005,0-1.98,0.032-2.955,0.366c-0.555,0.19-1.171,0.255-1.77,0.27c-1.394,0.032-2.775,0.047-4.155,0.492
				c-0.645,0.206-1.38,0.112-2.085,0.127c-1.2,0.016-2.386,0-3.555,0.445c-0.539,0.206-1.184,0.19-1.77,0.206
				c-1.394,0.032-2.805,0.047-4.2,0.016c-1.215-0.032-2.399,0.112-3.525,0.605c-1.515,0.668-3.106,0.748-4.71,0.557
				c-0.179-0.016-0.36-0.159-0.66-0.286c-0.63-0.986-0.975-2.179-0.975-3.484c0-0.954-0.015-1.909-0.015-2.863
				c0-40.076,0-80.15,0-120.21c0-1.591-0.106-3.182,0.15-4.677c0.48-0.86,1.184-1.256,2.01-1.321c0.69-0.047,1.44-0.112,2.07,0.112
				c1.365,0.492,2.759,0.429,4.155,0.445c0.405,0,0.795,0.032,1.2,0c1.425-0.08,2.79,0.159,4.109,0.7
				c1.036,0.429,2.085,0.731,3.21,0.27c0.179-0.08,0.42-0.127,0.585-0.063c1.62,0.605,3.389,0.112,5.025,0.78
				c0.45,0.19,0.975,0.19,1.469,0.239c0.3,0.032,0.614-0.032,0.899,0.032c2.055,0.492,4.14,0.668,6.24,0.621
				c0.195,0,0.405,0.016,0.585,0.08c1.74,0.717,3.539,0.509,5.34,0.541c1.5,0.032,3-0.127,4.44,0.492
				c1.515-0.016,2.94,0.621,4.425,0.717c1.394,0.096,2.79,0.032,4.2,0.047c0.495,0,1.005,0.016,1.5,0
				c1.005-0.032,1.98,0.159,2.94,0.492c1.23,0.429,2.49,0.717,3.749,1.082c0.69,1.399,1.095,2.784,1.095,4.168
				c0.015,4.248,0,8.48,0.015,12.728c0,1.225-0.464,2.259-0.945,3.309c-0.435,0.954-1.184,1.511-2.085,1.623
				c-1.891,0.223-3.78,0.286-5.67-0.08c-1.754-0.335-3.51-0.748-5.295-0.97c-1.29-0.159-2.595-0.063-3.899-0.063
				c-2.205,0-4.394-0.016-6.6,0c-1.606,0.016-3.195,0-4.725-0.605c-0.464-0.175-0.99-0.206-1.455-0.382
				c-1.14-0.445-2.16-0.127-3.135,0.492c-0.555,0.349-0.855,0.891-0.915,1.559c-0.044,0.414-0.06,0.843-0.06,1.272
				c0,5.823,0,11.661,0,17.484c0,0.429,0,0.843,0.031,1.272c0.075,0.874,0.375,1.654,0.899,2.308c0.241,0.302,0.63,0.588,0.99,0.637
				c0.886,0.127,1.785,0.127,2.684,0.143c1.2,0.016,2.386-0.016,3.555,0.414c0.555,0.19,1.184,0.175,1.785,0.19
				c1.095,0.032,2.205,0,3.3,0.032c0.601,0.016,1.23-0.032,1.785,0.175c1.365,0.492,2.759,0.445,4.155,0.445
				c3.405,0.016,6.795,0,10.2,0.016c0.69,0,1.394,0.063,2.085,0.127c0.329,0.032,0.78,0.492,0.795,0.843
				c0.044,0.731,0.12,1.479,0.12,2.212c0.015,4.344,0.015,8.686,0.015,13.046c0,0.525,0,1.066-0.015,1.591
				c-0.031,1.734-0.031,1.734-0.93,4.089c-0.3,0.096-0.66,0.302-1.034,0.318c-0.899,0.063-1.8,0.047-2.7,0.047
				c-7.695,0-15.389,0-23.085,0c-0.899,0-1.8,0.047-2.684,0.127c-0.539,0.047-1.155,0.684-1.246,1.241
				c-0.044,0.318-0.12,0.637-0.12,0.939c-0.015,8.368-0.015,16.753-0.015,25.12c0,1.097,0.316,2.083,0.93,2.959
				c0.254,0.349,0.585,0.605,0.99,0.668c0.585,0.08,1.2,0.127,1.785,0.127c3.3,0.016,6.6,0.016,9.899,0c1.5,0,3,0.096,4.455-0.478
				c0.45-0.175,0.99-0.143,1.485-0.159c1.29-0.047,2.595,0.032,3.855-0.478c0.45-0.175,0.99-0.143,1.485-0.143
				c2.505-0.016,4.995,0,7.5,0c0.195,0,0.405,0.016,0.601,0.032c1.171,0.08,1.754,0.731,1.829,2.052
				c0.031,0.637,0.044,1.272,0.044,1.909c0.015,1.162,0,2.338,0,3.5C157.892,144.717,157.892,148.122,157.892,151.51z
				 M205.936,101.237c-1.05-2.737-2.07-5.504-3.21-8.21c-0.391-0.954-1.08-1.766-1.65-2.641c-0.195-0.286-0.87-0.206-1.065,0.096
				c-0.36,0.525-0.63,1.082-0.676,1.734c-0.044,0.731-0.06,1.479-0.06,2.228c0,16.959,0.015,33.935,0.031,50.894
				c0,1.687-0.12,3.388,0.195,5.075c0.075,0.382-0.09,0.827-0.165,1.242c-0.135,0.811-0.66,1.321-1.35,1.544
				c-0.57,0.19-1.171,0.318-1.754,0.318c-1.995,0.032-3.99,0.08-5.955,0.509c-0.676,0.143-1.394,0.112-2.085,0.112
				c-1.8,0.016-3.601,0-5.399,0c-0.795,0-1.604-0.016-2.399,0.032c-0.69,0.047-1.38,0.175-2.085,0.27
				c-0.375-0.19-0.749-0.349-1.08-0.572c-0.81-0.541-1.29-1.321-1.305-2.355c-0.015-0.843-0.031-1.703-0.031-2.545
				c0-35.095,0-70.208,0-105.303c0-0.525,0.031-1.066,0-1.591c-0.075-1.193,0.21-2.308,0.585-3.421
				c0.36-1.097,0.855-1.528,1.995-1.544c2.205-0.032,4.394-0.032,6.6-0.032c0.3,0,0.614-0.016,0.886,0.096
				c1.65,0.652,3.36,0.445,5.039,0.492c0.705,0.016,1.44-0.08,2.085,0.143c1.754,0.621,3.555,0.478,5.34,0.525
				c0.69,0.016,1.38,0.096,2.085,0.143c1.321,1.973,2.055,4.152,2.894,6.284c0.345,0.874,0.69,1.766,1.05,2.641
				c0.195,0.492,0.525,0.954,0.601,1.448c0.36,2.465,1.65,4.534,2.461,6.809c1.816,5.155,3.81,10.246,5.746,15.352
				c0.63,1.671,1.44,3.262,2.025,4.963c1.02,2.975,2.189,5.902,3.316,8.845c0.676,1.766,1.394,3.517,2.101,5.265
				c0.585,1.464,1.29,2.896,1.71,4.407c0.57,2.083,1.845,3.739,2.805,5.584c0.044,0.096,0.135,0.143,0.21,0.223
				c0.241,0.239,0.539,0.27,0.824,0.143c0.165-0.08,0.329-0.223,0.435-0.398c0.106-0.175,0.179-0.398,0.179-0.588
				c0.031-0.637,0.031-1.272,0.031-1.909c0-16.863,0-33.727-0.015-50.575c0-1.909,0.135-3.819-0.225-5.712
				c-0.12-0.684,0.57-1.559,1.215-1.575c2.88-0.032,5.775-0.366,8.64,0.366c2.415,0.621,4.891,0.954,7.425,0.795
				c1.5-0.096,3-0.016,4.5,0c0.495,0,1.005-0.016,1.425,0.349c0.84,2.132,0.99,2.737,1.02,4.232c0.015,0.637,0,1.272,0,1.909
				c0,33.091,0,66.166,0,99.258c0,0.954-0.015,1.909-0.09,2.863c-0.044,0.557-0.36,1.003-0.84,1.225
				c-1.109,0.478-2.22,0.907-3.464,0.907c-6.404-0.032-12.795-0.016-19.199-0.032c-0.3,0-0.601-0.08-0.975-0.127
				c-0.81-0.717-1.425-1.638-1.829-2.753c-0.765-2.069-1.469-4.168-2.37-6.172c-1.171-2.577-1.875-5.361-3.09-7.891
				c-1.891-3.977-3.104-8.241-4.935-12.25c-0.961-2.083-1.65-4.311-2.49-6.46c-0.87-2.259-1.74-4.503-2.609-6.762
				C207.946,106.551,206.956,103.894,205.936,101.237z M403.901,148.058c0,1.385-0.031,2.753-0.075,4.136
				c-0.031,0.827-0.539,1.305-1.38,1.336c-0.495,0.032-1.005,0.032-1.5,0.032c-1.905,0-3.795,0-5.7,0
				c-1.305,0-2.58-0.047-3.855-0.445c-0.84-0.27-1.785-0.19-2.671-0.206c-4.005-0.016-7.995-0.016-12-0.016
				c-1.29,0-2.595,0.063-3.855-0.414c-0.555-0.206-1.184-0.143-1.785-0.206c-0.495-0.047-1.034,0-1.485-0.175
				c-1.35-0.541-2.759-0.461-4.155-0.461c-7.904,0-15.795,0-23.699-0.016c-1.5,0-2.969-0.016-4.455-0.429
				c-1.036-0.302-2.19-0.175-3.285-0.159c-1.125,0.016-2.176-0.286-3.195-0.764c-0.345-0.159-0.705-0.668-0.614-0.986
				c0.57-1.973,0.42-3.993,0.435-5.998c0.015-1.797,0.015-3.611,0-5.41c-0.015-1.591,0.106-3.182-0.435-4.726
				c-0.165-0.478-0.12-1.05-0.12-1.575c-0.015-0.843,0-1.703,0-2.545c0-27.364,0-54.711,0-82.076c0-0.525,0.015-1.066,0-1.591
				c-0.075-1.415,0.195-2.737,0.886-4.072c0.93-0.557,1.74-0.414,2.534-0.414c4.8-0.016,9.6-0.096,14.399,0.016
				c2.52,0.063,4.995-0.223,7.41-0.843c1.29-0.335,2.55-0.429,3.855-0.429c5.295-0.016,10.604-0.016,15.899-0.032
				c1.394,0,2.79,0.08,4.155-0.462c0.45-0.175,0.99-0.127,1.485-0.175c0.495-0.047,1.034,0.032,1.485-0.143
				c1.56-0.572,3.15-0.462,4.754-0.462c2.805-0.016,5.595-0.016,8.399-0.016c0.899,0,1.77-0.112,2.671-0.335
				c1.455-0.349,2.94-0.557,4.41-0.795c0.48-0.08,1.23,0.557,1.321,1.097c0.06,0.318,0.12,0.621,0.135,0.939
				c0.031,1.066,0.045,2.116,0.045,3.182C403.915,77.644,403.915,112.851,403.901,148.058z M495.068,160.133
				c-0.015,0.206-0.031,0.414-0.06,0.637c-0.031,0.366-0.435,0.843-0.749,0.891c-1.394,0.223-2.79,0.335-4.14-0.239
				c-1.8-0.78-3.676-0.939-5.58-0.874c-1.2,0.047-2.399,0.016-3.6-0.016c-0.601-0.016-1.23-0.032-1.77-0.255
				c-1.065-0.414-2.19-0.255-3.24-0.525c-0.974-0.255-1.935-0.478-2.94-0.494c-0.899,0-1.5-0.492-1.891-1.321
				c-0.495-1.034-1.08-2.036-1.215-3.229c-0.044-0.318-0.075-0.652-0.225-0.923c-0.899-1.575-0.99-3.421-1.679-5.075
				c-0.72-1.734-1.184-3.596-1.77-5.394c-0.539-1.703-1.02-3.437-1.65-5.108c-0.824-2.163-1.365-4.407-2.19-6.57
				c-0.824-2.163-1.38-4.423-2.13-6.619c-0.495-1.495-1.02-2.975-1.485-4.487c-0.464-1.495-1.05-2.975-1.515-4.471
				c-0.495-1.559-1.335-2.832-2.894-3.803c-1.575-0.143-3.06-0.175-4.56-0.016c-0.676,0.063-1.319,0.731-1.38,1.464
				c-0.075,0.954-0.106,1.909-0.106,2.847c0,10.708,0,21.414,0.015,32.121c0,0.748-0.031,1.479,0,2.228
				c0.106,2.036-0.165,4.009-0.915,5.966c-0.225,0.112-0.464,0.335-0.749,0.382c-0.795,0.127-1.59,0.127-2.37-0.159
				c-2.595-0.939-5.264-1.113-7.979-1.019c-1.695,0.063-3.405,0.016-5.1-0.016c-0.601-0.016-1.215-0.047-1.77-0.223
				c-1.71-0.557-3.391-1.242-5.085-1.082c-1.05-1.973-1.171-2.275-1.23-3.437c-0.031-0.843-0.031-1.703-0.031-2.545
				c0-29.783,0-59.58,0-89.362c0-1.272,0.031-2.545,0.015-3.819c-0.015-1.575,0.539-2.896,1.59-3.977
				c1.29-1.321,2.49-2.737,3.93-3.913c2.13-1.766,3.974-3.85,5.894-5.855c1.35-1.415,2.58-2.959,4.246-3.993
				c0.329-0.206,0.614-0.541,0.885-0.86c1.095-1.272,2.445-2.163,4.005-3.07c1.456-0.016,3.06-0.032,4.65-0.032
				c1.2,0,2.37-0.143,3.539-0.492c2.821-0.827,2.835-0.764,5.91-0.78c1.695-0.016,3.391,0.096,5.039-0.509
				c0.63-0.239,1.38-0.143,2.085-0.159c1.5-0.032,2.969-0.016,4.455-0.445c1.23-0.349,2.565-0.255,3.824-0.7
				c0.466-0.159,0.99-0.112,1.485-0.112c0.899-0.016,1.8-0.047,2.7,0c1.62,0.08,3.195-0.112,4.695-0.78
				c0.945-0.414,1.935-0.509,2.94-0.492c1.005,0.016,1.995,0,3-0.016c1.095,0,2.19,0,3.254-0.382c0.539-0.19,1.184-0.143,1.77-0.159
				c0.42,0,0.751,0.239,1.065,0.684c-0.254,3.182-0.135,6.46-0.15,9.721c-0.015,3.182,0,6.364,0,9.546c0,3.294,0,6.57,0,9.864
				c0,16.228,0.015,32.438,0.015,48.666c0,1.591-0.031,3.166,0.27,4.74c0.015,0.08-0.06,0.19-0.09,0.286
				c-0.3,0.318-0.69,0.414-1.095,0.414c-2.1,0.016-4.2,0.016-6.3,0.032c-1.095,0-2.205,0.032-3.3,0.016
				c-1.155-0.016-1.891,0.572-2.295,1.671c-0.075,0.19-0.135,0.398-0.254,0.764c0.585,1.846,0.765,3.993,1.829,5.839
				c0.106,0.175,0.225,0.382,0.24,0.572c0.135,1.734,1.109,3.166,1.544,4.805c0.435,1.623,1.005,3.214,1.544,4.82
				c0.93,2.784,1.891,5.568,2.79,8.368c0.915,2.8,1.77,5.632,2.684,8.432c0.525,1.591,1.065,3.182,1.575,4.789
				c0.464,1.495,1.034,2.975,1.515,4.471C494.889,158.256,495.143,159.163,495.068,160.133z M506.617,111.451
				c-1.485-0.112-2.37-0.891-2.775-2.418c-0.075-0.302-0.179-0.621-0.195-0.923c-0.106-1.893-0.15-3.803,0.106-5.696
				c0.15-1.066,0.555-2.036,1.741-2.61c1.125,0.159,2.055,0.492,2.865,1.162c1.41,1.162,2.865,2.259,4.275,3.452
				c1.171,0.986,2.28,2.036,3.405,3.07c0.614,0.557,1.14,1.177,1.456,1.973c0.285,0.7,0.075,1.528-0.57,1.909
				c-0.345,0.206-0.72,0.366-1.095,0.478c-0.285,0.08-0.601,0.127-0.885,0.08C512.182,111.579,509.392,111.659,506.617,111.451z
				 M578.976,171.937c-0.15,0.668-0.614,1.098-1.259,1.209c-1.2,0.206-2.386,0.223-3.555-0.239c-0.466-0.19-0.961-0.302-1.425-0.492
				c-0.765-0.318-1.544-0.462-2.355-0.398c-1.92,0.143-3.795-0.175-5.609-0.78c-1.171-0.382-2.34-0.509-3.539-0.492
				c-1.2,0-2.399,0.047-3.539-0.461c-0.27-0.127-0.585-0.143-0.885-0.159c-1.2-0.063-2.37-0.127-3.555-0.494
				c-1.14-0.349-2.37-0.223-3.525-0.637c-0.735-0.255-1.59-0.19-2.385-0.159c-1.41,0.032-2.805-0.096-4.109-0.684
				c-1.14-0.509-2.31-0.621-3.525-0.588c-0.495,0.016-1.005-0.032-1.5,0c-1.319,0.08-2.595-0.096-3.81-0.637
				c-1.23-0.525-2.505-0.637-3.824-0.637c-3.135,0-3.135,0.016-6.21-0.811c-0.974-0.27-1.935-0.494-2.955-0.429
				c-1.62,0.096-3.179-0.159-4.725-0.668c-1.05-0.335-2.114-0.588-3.15-0.874c-0.165-0.349-0.375-0.621-0.405-0.907
				c-0.075-0.843-0.075-1.687-0.075-2.545c0-11.137,0-22.273,0-33.394c0-0.843,0-1.703,0.06-2.545
				c0.044-0.652,0.27-1.241,0.676-1.734c0.179-0.223,0.464-0.398,0.72-0.509c0.27-0.112,0.585-0.127,0.885-0.127
				c1.606,0,3.195,0,4.8,0.016c0.404,0,0.824-0.016,1.184,0.127c1.65,0.652,3.345,0.492,5.039,0.509c1.5,0.016,3,0,4.5,0
				c0.899,0,1.785,0.096,2.671,0.318c0.961,0.239,1.98,0.255,2.969,0.366c0.495,0.063,0.974,0.525,1.005,1.003
				c0.06,0.843,0.106,1.687,0.106,2.53c0.015,3.07,0,6.157,0.015,9.227c0,0.843,0.044,1.687,0.075,2.561
				c0.466,0.7,0.915,1.385,1.35,2.036c0.345,0.127,0.614,0.302,0.899,0.318c1.005,0.047,1.995,0.096,3,0.047
				c1.425-0.047,2.805,0.127,4.109,0.7c1.14,0.492,2.324,0.588,3.525,0.572c1.335,0,2.565-0.398,3.72-1.019
				c0.165-0.08,0.24-0.318,0.36-0.492c0.031-0.206,0.135-0.429,0.09-0.605c-0.36-1.241-0.345-2.498-0.345-3.77
				c0-5.298,0.015-10.595,0-15.909c0-1.05,0.106-2.116-0.254-3.357c-0.99-1.368-2.22-2.768-3.676-3.882
				c-0.99-0.748-1.86-1.591-2.715-2.482c-0.855-0.874-1.679-1.814-2.73-2.465c-0.51-0.318-0.915-0.811-1.35-1.241
				c-0.645-0.668-1.275-1.352-2.085-1.797c-0.181-0.096-0.345-0.223-0.48-0.366c-1.575-1.623-3.225-3.149-4.964-4.581
				c-0.87-0.7-1.575-1.638-2.55-2.195c-0.179-0.096-0.329-0.239-0.464-0.398c-2.01-2.069-4.23-3.85-6.48-5.616
				c-1.319-1.607-3.06-2.704-4.575-4.089c-1.425-1.305-2.969-2.482-4.455-3.739c-0.705-0.588-1.35-1.256-2.025-1.893
				c-1.365-1.272-1.845-2.975-1.98-4.82c-0.06-0.843-0.015-1.703-0.015-2.545c0-13.252,0.015-26.505-0.015-39.757
				c0-2.753,0-5.504,0.961-8.4c0.915-0.525,1.785-1.034,2.79-1.623c0.961-0.016,2.07-0.047,3.165-0.047
				c0.915,0,1.8-0.112,2.609-0.588c1.005-0.588,2.085-0.731,3.195-0.668c1.725,0.112,3.391-0.159,4.995-0.843
				c0.66-0.286,1.335-0.478,2.039-0.414c2.43,0.206,4.754-0.462,7.095-0.954c0.795-0.159,1.56-0.335,2.37-0.335
				c0.405,0,0.824,0.032,1.184-0.112c1.44-0.541,2.925-0.621,4.44-0.572c0.93,0.032,1.785-0.27,2.625-0.605
				c1.23-0.492,2.49-0.731,3.81-0.652c1.215,0.08,2.385-0.127,3.464-0.78c0.63-0.382,1.319-0.509,2.026-0.462
				c1.816,0.096,3.585-0.159,5.28-0.907c0.749-0.335,1.544-0.382,2.355-0.366c1.005,0.016,1.995-0.032,2.956-0.429
				c0.36-0.143,0.78-0.206,1.171-0.206c1.695-0.016,3.404-0.016,5.101,0.016c0.824,0.016,1.56,0.874,1.41,1.703
				c-0.254,1.479-0.195,2.959-0.21,4.438c-0.015,2.228,0,4.454,0,6.682c0,9.339,0,18.661,0,28c0,0.954,0,1.909-0.044,2.863
				c-0.031,0.923-0.539,1.607-1.394,1.83c-0.57,0.143-1.171,0.239-1.77,0.255c-1.606,0.032-3.195,0.016-4.8,0.032
				c-1.005,0-1.98,0.063-2.969,0.349c-1.531,0.445-3.15,0.462-4.71,0.843c-0.855,0.206-1.785,0.127-2.671-0.143
				c-0.765-0.239-1.531-0.462-2.205-0.652c-0.63-0.572-0.899-1.241-1.036-1.989c-0.42-2.498-0.78-5.012-0.645-7.573
				c0.06-1.05-0.015-2.116-0.075-3.182c-0.044-0.684-0.42-1.177-0.945-1.528c-0.87-0.572-1.86-1.003-2.85-0.668
				c-2.16,0.731-4.35,0.588-6.539,0.541c-1.65-0.032-3.165,0.366-4.65,1.019c-0.45,0.206-0.899,0.478-1.29,0.795
				c-0.57,0.462-0.961,1.082-1.005,1.877c-0.044,0.637-0.044,1.272-0.044,1.909c0,3.929,0,7.843,0,11.772
				c0,0.525-0.015,1.066,0.015,1.591c0.09,1.464,0.676,2.72,1.785,3.517c1.26,0.891,2.28,2.052,3.405,3.086
				c1.125,1.034,2.34,1.957,3.539,2.927c0.24,0.19,0.555,0.286,0.749,0.509c1.77,2.02,4.005,3.5,5.85,5.425
				c0.72,0.748,1.425,1.479,2.295,2.036c0.51,0.318,0.93,0.795,1.365,1.225c0.45,0.429,0.824,0.954,1.335,1.289
				c1.394,0.891,2.474,2.148,3.614,3.309c1.155,1.177,2.205,2.451,3.614,3.325c0.51,0.318,0.885,0.843,1.335,1.272
				c0.36,0.366,0.69,0.811,1.109,1.066c1.41,0.86,2.474,2.132,3.614,3.325c1.785,1.861,3.539,3.739,5.31,5.616
				c0.345,0.382,0.66,0.795,0.899,1.082c0.765,1.703,0.961,3.341,0.961,5.075c-0.031,18.661-0.031,37.323,0,55.984
				C579.71,168.661,579.366,170.299,578.976,171.937z"
            />
          </g>
        </g>
      </g>
      <g id="s_3_">
        <g id="Shape_1_copy_26">
          <g>
            <path
              d="M575.541,163.188c0-6.682-0.015-13.348-0.015-20.029c0-9.434,0-18.869,0-28.286c0-1.066,0.031-2.132-0.09-3.182
				c-0.254-2.355-0.84-4.581-2.746-6.125c-3.42-3.723-7.184-7.048-10.8-10.548c-1.62-1.559-3.285-3.086-4.964-4.597
				c-2.355-2.132-4.725-4.248-7.095-6.348c-1.845-1.623-3.689-3.229-5.549-4.836c-1.935-1.671-3.899-3.309-5.834-4.979
				c-0.614-0.541-1.215-1.113-1.74-1.734c-1.246-1.464-1.995-3.149-1.95-5.186c0.015-0.637,0-1.272,0-1.909
				c0.015-4.661,0.015-9.323,0.031-13.984c0-0.621,0.106-1.272-0.345-1.893c-0.225,0.143-0.585,0.239-0.676,0.445
				c-0.72,1.781-0.945,3.66-0.945,5.584c0.015,5.091,0.015,10.166-0.015,15.256c-0.015,1.94,0.316,3.786,1.23,5.886
				c0.195,0.19,0.645,0.621,1.095,1.019c3.614,3.182,7.244,6.331,10.845,9.529c3.135,2.784,6.285,5.568,9.375,8.416
				c2.64,2.434,5.19,4.963,7.8,7.43c0.526,0.492,1.08,0.939,1.59,1.448c2.19,2.179,4.365,4.344,6.525,6.554
				c1.515,1.544,2.235,3.484,2.265,5.696c0.015,1.066,0.015,2.116,0.015,3.182c0,12.823,0,25.645,0,38.469
				c0,0.843,0.015,1.703,0,2.545c-0.015,1.415,0.36,2.72,0.93,3.977c0.09,0.206,0.466,0.286,0.69,0.414
				C575.676,164.667,575.541,163.919,575.541,163.188z M518.842,33.161c0.601,0.429,1.246,0.652,1.98,0.572
				c1.095-0.112,2.19-0.255,3.285-0.335c1.5-0.112,2.985-0.206,4.455-0.605c0.87-0.239,1.77-0.286,2.67-0.366
				c2.085-0.206,4.184-0.349,6.24-0.874c0.676-0.175,1.38-0.239,2.085-0.27c1.995-0.127,3.974-0.478,5.94-0.874
				c0.69-0.143,1.38-0.286,2.07-0.318c1.606-0.08,3.179-0.398,4.74-0.717c0.69-0.143,1.365-0.382,2.055-0.398
				c2.505-0.063,4.95-0.684,7.425-1.05c0.601-0.08,1.184-0.206,1.785-0.255c1.394-0.112,2.775-0.302,4.155-0.588
				c0.974-0.19,1.98-0.159,2.985-0.143c1.065,0.016,1.695,0.668,1.8,1.734c0.06,0.637,0.09,1.272,0.106,1.909
				c0.015,1.162,0,2.338,0,3.5c0,5.823-0.015,11.661,0,17.484c0,0.731,0.044,1.479,0.179,2.212c0.045,0.255,0.345,0.509,0.585,0.652
				c0.27,0.159,0.855-0.175,0.974-0.541c0.181-0.605,0.405-1.209,0.45-1.83c0.21-2.959,0.404-5.918,0.495-8.878
				c0.06-1.909-0.075-3.819-0.12-5.712c-0.031,0-0.06,0-0.09,0c0-0.525,0.015-1.066,0-1.591c-0.09-3.707-0.179-7.414-0.27-11.12
				c-0.015-0.86-0.15-1.687-0.539-2.451c-0.3-0.605-0.751-0.986-1.365-1.129c-0.391-0.096-0.795-0.175-1.184-0.175
				c-1.59,0.063-3.195,0.047-4.754,0.509c-0.87,0.255-1.77,0.335-2.655,0.478c-1.964,0.318-3.974,0.382-5.91,1.003
				c-0.464,0.143-0.99,0.127-1.485,0.19c-0.795,0.112-1.59,0.206-2.37,0.349c-2.07,0.382-4.095,0.939-6.225,0.939
				c-0.585,0-1.171,0.223-1.77,0.349c-1.86,0.382-3.72,0.811-5.64,0.811c-0.69,0-1.38,0.175-2.07,0.335
				c-1.86,0.429-3.72,0.795-5.625,0.843c-0.601,0.016-1.215,0.032-1.785,0.206c-2.039,0.637-4.14,0.717-6.225,0.939
				c-2.025,0.223-3.885,0.97-5.759,1.687c-0.27,0.112-0.51,0.335-0.749,0.525C517.777,30.919,517.853,32.446,518.842,33.161z
				 M553.417,147.231c-0.945-0.605-1.95-1.066-3.06-1.225c-1.08-0.159-2.16-0.429-3.254-0.572c-1.875-0.223-3.735-0.637-5.655-0.478
				c-1.08,0.096-2.19-0.143-3.285-0.239c-1.26-0.112-2.265-0.764-3.106-1.734c-0.42-0.478-0.705-1.034-0.765-1.687
				c-0.044-0.414-0.06-0.843-0.06-1.272c-0.06-3.182-0.12-6.348-0.195-9.529c-0.015-0.731,0.106-1.495-0.27-2.179
				c-0.15-0.143-0.27-0.335-0.435-0.414c-0.63-0.302-1.275-0.621-1.935-0.86c-1.964-0.7-8.91-1.575-10.964-1.415
				c-0.705,0.047-1.38,0.223-1.98,0.637c-1.44,0.939-1.394,2.005-0.21,3.388c0.135,0.159,0.316,0.27,0.48,0.366
				c1.171,0.637,2.385,1.098,3.705,1.162c1.095,0.047,2.19,0.08,3.285,0.175c1.606,0.127,3.165,0.462,4.635,1.177
				c0.795,0.382,1.275,1.019,1.319,1.957c0.031,0.748,0.031,1.479,0.031,2.228c0,2.116-0.031,4.232,0,6.364
				c0.015,1.05,0.075,2.116,0.241,3.166c0.195,1.336,0.974,2.308,2.114,2.896c0.45,0.239,0.93,0.398,1.41,0.541
				c1.829,0.541,3.705,0.86,5.625,0.843c2.505-0.016,4.98,0.27,7.44,0.843c1.74,0.398,3.375,0.063,4.98-0.605
				c0.179-0.08,0.345-0.206,0.51-0.335c0.466-0.318,0.69-1.113,0.51-1.703C554.316,148.058,553.956,147.565,553.417,147.231z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_25">
          <g>
            <path
              fill={secondary}
              d="M579.697,110.942c0-1.734-0.195-3.372-0.961-5.075c-0.241-0.286-0.555-0.7-0.899-1.082
				c-1.754-1.877-3.525-3.754-5.31-5.616c-1.14-1.193-2.205-2.465-3.614-3.325c-0.42-0.255-0.749-0.7-1.109-1.066
				c-0.435-0.429-0.824-0.97-1.335-1.272c-1.41-0.874-2.461-2.148-3.614-3.325c-1.155-1.177-2.22-2.434-3.614-3.309
				c-0.51-0.318-0.886-0.86-1.335-1.289c-0.45-0.429-0.87-0.907-1.365-1.225c-0.87-0.557-1.575-1.289-2.295-2.036
				c-1.86-1.926-4.08-3.405-5.85-5.425c-0.195-0.223-0.51-0.318-0.749-0.509c-1.184-0.97-2.399-1.893-3.539-2.927
				c-1.125-1.034-2.145-2.195-3.405-3.086c-1.125-0.795-1.71-2.052-1.785-3.517c-0.031-0.525-0.015-1.066-0.015-1.591
				c0-3.929,0-7.843,0-11.772c0-0.637,0-1.272,0.044-1.909c0.045-0.795,0.435-1.415,1.005-1.877c0.391-0.318,0.824-0.605,1.29-0.795
				c1.485-0.652,3.015-1.05,4.65-1.019c2.19,0.047,4.38,0.19,6.539-0.541c0.99-0.335,1.98,0.096,2.85,0.668
				c0.539,0.349,0.915,0.86,0.945,1.528c0.06,1.05,0.135,2.116,0.075,3.182c-0.15,2.561,0.225,5.059,0.645,7.573
				c0.12,0.748,0.405,1.415,1.036,1.989c0.676,0.206,1.44,0.414,2.205,0.652c0.885,0.27,1.815,0.349,2.67,0.143
				c1.56-0.382,3.179-0.398,4.71-0.843c0.99-0.286,1.964-0.349,2.969-0.349c1.606,0,3.195,0.016,4.8-0.032
				c0.585-0.016,1.2-0.112,1.77-0.255c0.855-0.223,1.365-0.907,1.394-1.83c0.031-0.954,0.044-1.909,0.044-2.863
				c0-9.339,0-18.661,0-28c0-2.228-0.015-4.454,0-6.682c0-1.479-0.044-2.959,0.21-4.438c0.15-0.843-0.585-1.687-1.41-1.703
				c-1.695-0.032-3.405-0.032-5.101-0.016c-0.391,0-0.81,0.063-1.171,0.206c-0.961,0.398-1.95,0.429-2.955,0.429
				c-0.81,0-1.606,0.032-2.355,0.366c-1.695,0.748-3.45,1.003-5.28,0.907c-0.705-0.032-1.41,0.096-2.025,0.462
				c-1.08,0.652-2.249,0.843-3.464,0.78c-1.319-0.08-2.58,0.159-3.81,0.652c-0.84,0.335-1.695,0.637-2.625,0.605
				c-1.5-0.047-2.985,0.032-4.44,0.572c-0.36,0.143-0.795,0.096-1.184,0.112c-0.81,0.016-1.575,0.175-2.37,0.335
				c-2.34,0.492-4.665,1.162-7.095,0.954c-0.705-0.063-1.394,0.127-2.039,0.414c-1.606,0.684-3.27,0.954-4.995,0.843
				c-1.109-0.063-2.19,0.08-3.195,0.668c-0.81,0.478-1.695,0.588-2.609,0.588c-1.095,0-2.19,0.032-3.165,0.047
				c-1.005,0.588-1.875,1.098-2.79,1.623c-0.961,2.896-0.961,5.647-0.961,8.4c0.015,13.252,0.015,26.505,0.015,39.757
				c0,0.843-0.044,1.703,0.015,2.545c0.135,1.846,0.601,3.531,1.98,4.82c0.676,0.637,1.319,1.289,2.025,1.893
				c1.469,1.256,3.015,2.418,4.455,3.739c1.515,1.385,3.24,2.482,4.575,4.089c2.235,1.766,4.469,3.564,6.479,5.616
				c0.15,0.143,0.3,0.286,0.464,0.398c0.99,0.557,1.695,1.479,2.55,2.195c1.741,1.432,3.391,2.959,4.964,4.581
				c0.135,0.143,0.316,0.27,0.48,0.366c0.81,0.462,1.44,1.146,2.085,1.797c0.435,0.445,0.84,0.939,1.35,1.241
				c1.05,0.652,1.875,1.575,2.73,2.465c0.855,0.874,1.74,1.734,2.715,2.482c1.456,1.113,2.684,2.514,3.676,3.882
				c0.36,1.241,0.254,2.308,0.254,3.357c0.015,5.298,0.015,10.595,0,15.909c0,1.272-0.015,2.545,0.345,3.77
				c0.044,0.175-0.044,0.414-0.09,0.605c-0.12,0.175-0.195,0.398-0.36,0.492c-1.171,0.637-2.399,1.019-3.72,1.019
				c-1.215,0-2.386-0.08-3.525-0.572c-1.319-0.572-2.684-0.748-4.109-0.7c-0.99,0.032-1.995,0-3-0.047
				c-0.285-0.016-0.555-0.19-0.899-0.318c-0.435-0.652-0.885-1.352-1.35-2.036c-0.031-0.874-0.075-1.718-0.075-2.561
				c-0.015-3.07,0-6.157-0.015-9.227c0-0.843-0.06-1.687-0.106-2.53c-0.031-0.492-0.525-0.954-1.005-1.003
				c-0.99-0.112-1.995-0.127-2.969-0.366c-0.886-0.223-1.77-0.318-2.671-0.318c-1.5,0-3,0.016-4.5,0
				c-1.695-0.016-3.404,0.143-5.039-0.509c-0.36-0.143-0.795-0.112-1.184-0.127c-1.606-0.016-3.195-0.032-4.8-0.016
				c-0.3,0-0.614,0.016-0.885,0.127c-0.27,0.096-0.539,0.286-0.72,0.509c-0.391,0.492-0.63,1.098-0.676,1.734
				c-0.044,0.843-0.06,1.703-0.06,2.545c0,11.137,0,22.273,0,33.394c0,0.843,0.015,1.703,0.075,2.545
				c0.031,0.286,0.24,0.557,0.404,0.907c1.036,0.286,2.114,0.541,3.15,0.874c1.544,0.494,3.106,0.764,4.725,0.668
				c1.005-0.063,1.964,0.175,2.955,0.429c3.06,0.827,3.075,0.811,6.21,0.811c1.319,0,2.595,0.112,3.824,0.637
				c1.229,0.525,2.505,0.7,3.81,0.637c0.495-0.032,1.005,0.016,1.5,0c1.215-0.032,2.386,0.08,3.525,0.588
				c1.305,0.588,2.7,0.731,4.109,0.684c0.795-0.016,1.65-0.096,2.386,0.159c1.17,0.414,2.385,0.302,3.525,0.637
				c1.184,0.349,2.355,0.429,3.555,0.494c0.3,0.016,0.614,0.047,0.885,0.159c1.14,0.509,2.355,0.461,3.539,0.461
				c1.2,0,2.37,0.112,3.539,0.492c1.816,0.605,3.689,0.939,5.609,0.78c0.81-0.063,1.59,0.08,2.355,0.398
				c0.464,0.19,0.961,0.302,1.425,0.492c1.171,0.461,2.355,0.445,3.555,0.239c0.63-0.112,1.095-0.541,1.259-1.209
				c0.375-1.638,0.735-3.262,0.735-4.995C579.681,148.265,579.681,129.604,579.697,110.942z M553.987,150.397
				c-0.165,0.112-0.329,0.255-0.51,0.335c-1.606,0.668-3.24,1.003-4.98,0.605c-2.445-0.557-4.92-0.86-7.44-0.843
				c-1.904,0.016-3.78-0.302-5.625-0.843c-0.48-0.143-0.961-0.302-1.41-0.541c-1.14-0.605-1.92-1.559-2.114-2.896
				c-0.15-1.034-0.225-2.1-0.24-3.166c-0.031-2.116,0-4.232,0-6.364c0-0.748,0-1.479-0.031-2.228
				c-0.044-0.939-0.526-1.575-1.319-1.957c-1.469-0.7-3.031-1.034-4.635-1.177c-1.095-0.096-2.19-0.127-3.285-0.175
				c-1.319-0.063-2.534-0.525-3.705-1.162c-0.179-0.096-0.345-0.223-0.48-0.366c-1.184-1.385-1.215-2.451,0.21-3.388
				c0.614-0.398,1.275-0.572,1.98-0.637c2.055-0.159,9,0.717,10.964,1.415c0.66,0.239,1.29,0.557,1.935,0.86
				c0.165,0.08,0.3,0.27,0.435,0.414c0.36,0.684,0.254,1.448,0.27,2.179c0.06,3.182,0.12,6.348,0.195,9.529
				c0.015,0.429,0.031,0.843,0.06,1.272c0.06,0.652,0.345,1.209,0.765,1.687c0.84,0.97,1.845,1.607,3.104,1.734
				c1.095,0.096,2.205,0.335,3.285,0.239c1.92-0.159,3.78,0.255,5.655,0.478c1.095,0.127,2.16,0.414,3.254,0.572
				c1.109,0.159,2.114,0.621,3.06,1.225c0.525,0.335,0.899,0.827,1.08,1.464C554.676,149.284,554.451,150.062,553.987,150.397z
				 M528.547,32.796c-1.469,0.398-2.956,0.492-4.455,0.605c-1.095,0.08-2.19,0.206-3.285,0.335c-0.735,0.08-1.38-0.143-1.98-0.572
				c-0.99-0.7-1.065-2.243-0.135-2.99c0.24-0.19,0.48-0.414,0.749-0.525c1.875-0.717,3.749-1.464,5.759-1.687
				c2.085-0.223,4.184-0.302,6.225-0.939c0.57-0.175,1.184-0.19,1.785-0.206c1.904-0.047,3.765-0.414,5.625-0.843
				c0.676-0.159,1.38-0.335,2.07-0.335c1.92,0.016,3.765-0.429,5.64-0.811c0.585-0.127,1.171-0.349,1.77-0.349
				c2.114,0.016,4.155-0.557,6.225-0.939c0.78-0.143,1.575-0.239,2.37-0.349c0.495-0.063,1.02-0.047,1.485-0.19
				c1.935-0.621,3.945-0.668,5.91-1.003c0.885-0.143,1.785-0.239,2.655-0.478c1.56-0.445,3.165-0.445,4.754-0.509
				c0.391-0.016,0.795,0.08,1.184,0.175c0.614,0.143,1.08,0.525,1.365,1.129c0.375,0.764,0.51,1.591,0.539,2.451
				c0.09,3.707,0.179,7.414,0.27,11.12c0.015,0.525,0,1.066,0,1.591c0.031,0,0.06,0,0.09,0c0.044,1.909,0.179,3.819,0.12,5.712
				c-0.09,2.959-0.3,5.918-0.495,8.878c-0.044,0.621-0.27,1.225-0.45,1.83c-0.106,0.366-0.69,0.7-0.974,0.541
				c-0.24-0.143-0.555-0.398-0.585-0.652c-0.12-0.717-0.181-1.464-0.181-2.212c-0.015-5.823,0-11.661,0-17.484
				c0-1.162,0.015-2.338,0-3.5c-0.015-0.637-0.031-1.272-0.106-1.909c-0.106-1.066-0.749-1.703-1.8-1.734
				c-0.99-0.016-2.01-0.063-2.985,0.143c-1.38,0.27-2.759,0.462-4.155,0.588c-0.601,0.047-1.184,0.159-1.785,0.255
				c-2.474,0.366-4.904,0.986-7.425,1.05c-0.69,0.016-1.38,0.255-2.055,0.398c-1.575,0.318-3.135,0.637-4.74,0.717
				c-0.69,0.032-1.394,0.175-2.07,0.318c-1.964,0.398-3.93,0.748-5.94,0.874c-0.69,0.047-1.41,0.112-2.085,0.27
				c-2.055,0.525-4.14,0.668-6.24,0.874C530.332,32.526,529.417,32.573,528.547,32.796z M575.166,165.4
				c-0.225-0.127-0.601-0.206-0.69-0.414c-0.555-1.256-0.93-2.561-0.93-3.977c0-0.843,0-1.703,0-2.545c0-12.823,0-25.645,0-38.469
				c0-1.066,0-2.116-0.015-3.182c-0.031-2.228-0.749-4.152-2.265-5.696c-2.16-2.195-4.35-4.375-6.525-6.554
				c-0.51-0.509-1.065-0.954-1.59-1.448c-2.595-2.482-5.16-4.995-7.8-7.43c-3.09-2.847-6.24-5.632-9.375-8.416
				c-3.601-3.182-7.23-6.348-10.845-9.529c-0.466-0.398-0.899-0.843-1.095-1.019c-0.915-2.1-1.23-3.962-1.23-5.886
				c0.015-5.091,0.015-10.166,0.015-15.256c0-1.926,0.225-3.803,0.945-5.584c0.09-0.223,0.45-0.302,0.676-0.445
				c0.466,0.621,0.345,1.272,0.345,1.893c0,4.661-0.015,9.323-0.031,13.984c0,0.637,0.015,1.272,0,1.909
				c-0.044,2.02,0.72,3.707,1.95,5.187c0.525,0.621,1.125,1.209,1.741,1.734c1.935,1.671,3.885,3.325,5.834,4.979
				c1.86,1.607,3.72,3.198,5.549,4.836c2.37,2.1,4.74,4.215,7.095,6.348c1.665,1.511,3.329,3.039,4.964,4.597
				c3.63,3.484,7.38,6.809,10.8,10.548c1.905,1.544,2.49,3.754,2.746,6.125c0.106,1.05,0.09,2.116,0.09,3.182
				c0,9.434,0,18.869,0,28.286c0,6.682,0.015,13.348,0.015,20.029C575.541,163.919,575.676,164.667,575.166,165.4z"
            />
          </g>
        </g>
      </g>
      <g id="r_1_">
        <g id="Shape_1_copy_24">
          <g>
            <path
              d="M468.699,58.442c0-0.637-0.031-1.272-0.075-1.909c-0.06-0.748-0.645-1.448-1.321-1.495
				c-1.59-0.143-3.165-0.223-4.754,0.223c-3.435,0.97-3.45,0.923-7.08,0.923c-1.305,0-2.595-0.016-3.899,0
				c-0.495,0-1.005,0-1.485,0.127c-1.29,0.349-1.754,0.97-1.785,2.179c0,0.206-0.015,0.429-0.015,0.637
				c0,9.323,0,18.646,0.015,27.969c0,0.525,0.106,1.034,0.15,1.575c0.3,0.19,0.525,0.414,0.795,0.492
				c0.375,0.112,0.795,0.127,1.184,0.127c5.399,0,10.785,0,16.184,0c0.391,0,0.795-0.08,1.184-0.127
				c0.316-0.047,0.765-0.509,0.78-0.86c0.06-0.731,0.12-1.479,0.12-2.212C468.713,76.864,468.713,67.653,468.699,58.442z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_23">
          <g>
            <path
              d="M467.034,92.662c-0.48-0.127-0.99-0.143-1.485-0.143c-3.899,0-7.8,0.016-11.7,0.063
				c-0.899,0.016-1.785,0.175-2.684,0.239c-1.29,0.096-2.595,0.175-3.885,0.206c-0.391,0.016-0.795-0.096-1.171-0.19
				c-0.705-0.175-1.275-0.827-1.319-1.544c-0.06-1.05-0.106-2.116-0.12-3.182c-0.015-5.091,0-10.181,0-15.272
				c0-3.707-0.015-7.414,0-11.137c0-1.289-0.12-2.545-0.48-3.77c-0.06-0.19-0.12-0.414-0.241-0.572
				c-0.12-0.143-0.329-0.27-0.48-0.255c-0.15,0.016-0.391,0.19-0.435,0.349c-0.42,1.638-0.57,3.325-0.555,5.028
				c0.015,7.095,0,14.206,0.015,21.303c0,4.025,0.015,8.051,0.031,12.091c0.015,2.052,0.27,2.322,2.31,2.322
				c4.904,0.016,9.795,0.016,14.7,0.016c2.101,0,4.2,0,6.3,0c0.705,0,1.41,0,2.19-0.492c0.435-0.621,1.065-1.305,1.23-2.386
				c-0.165-0.78-0.645-1.464-1.184-2.083C467.799,92.98,467.394,92.757,467.034,92.662z M444.655,118.292
				c0-1.256-0.031-2.53-0.3-3.739c-0.749-0.016-1.05,0.143-1.23,0.621c-0.555,1.495-0.66,3.07-0.66,4.661
				c0.015,4.852,0,9.721,0,14.572c0,3.596,0.015,7.191,0,10.771c0,1.177,0.106,2.322,0.555,3.405c0.075,0.19,0.195,0.382,0.36,0.492
				c0.135,0.096,0.45,0.127,0.51,0.047c0.24-0.318,0.539-0.684,0.585-1.05c0.12-1.05,0.179-2.1,0.179-3.149
				c0.015-5.918,0.015-11.837,0.015-17.754C444.669,124.211,444.669,121.251,444.655,118.292z M490.119,38.222
				c0-0.954,0.015-1.909-0.044-2.863c-0.09-1.846-1.38-2.753-2.821-2.371c-1.275,0.335-2.565,0.492-3.87,0.414
				c-2.114-0.112-4.184,0.27-6.24,0.7c-1.485,0.318-2.956,0.541-4.455,0.478c-1.71-0.063-3.391,0.127-5.055,0.525
				c-2.249,0.541-4.531,0.748-6.84,0.668c-1.5-0.047-2.985,0.127-4.469,0.445c-1.95,0.414-3.93,0.827-5.94,0.717
				c-1.935-0.112-3.78,0.223-5.595,0.843c-0.945-0.159-1.77,0.096-2.43,0.86c-0.405,0.462-0.795,0.939-1.171,1.432
				c-0.81,1.082-1.71,2.036-2.894,2.673c-0.525,0.286-1.005,0.684-1.44,1.098c-0.66,0.637-1.365,1.209-2.085,1.766
				c-1.365,1.066-2.534,2.355-3.585,3.77c-0.06,0.08-0.075,0.19-0.135,0.286c-0.135,0.255-0.06,0.795,0.135,0.86
				c0.179,0.047,0.375,0.096,0.539,0.047c0.69-0.206,1.215-0.7,1.71-1.225c1.275-1.336,2.49-2.72,3.81-4.009
				c1.394-1.352,2.865-2.594,4.8-3.102c1.635,0.652,3.254,0.764,4.95,0.127c0.63-0.239,1.38-0.175,2.07-0.255
				c0.42-0.047,0.749-0.255,1.036-0.572c2.295-0.127,4.59-0.19,6.87-0.572c3.06-0.509,6.15-0.811,9.254-0.811
				c0.69,0,1.394-0.127,2.085-0.239c2.865-0.478,5.73-0.923,8.64-0.923c1.2,0,2.386-0.19,3.57-0.414
				c1.38-0.239,2.775-0.335,4.17-0.414c0.48-0.032,1.005,0.08,1.469,0.223c0.824,0.255,1.41,0.843,1.515,1.781
				c0.09,0.843,0.106,1.687,0.106,2.53c0.031,3.388,0.045,6.777,0.045,10.183c0,15.162-0.015,30.322-0.015,45.468
				c0,1.162,0.015,2.338,0.06,3.5c0.031,0.86,0.285,1.671,0.765,2.371c0.165,0.223,0.466,0.349,0.676,0.492
				c0.466-0.223,0.676-0.605,0.72-1.019c0.075-0.954,0.12-1.909,0.12-2.847c0-6.682-0.015-13.363-0.015-20.029
				C490.119,66.634,490.119,52.428,490.119,38.222z M487.928,148.902c-1.184-3.595-2.37-7.207-3.57-10.802
				c-1.935-5.792-3.899-11.582-5.865-17.374c-0.945-2.784-1.905-5.568-2.925-8.575c-0.915-0.19-1.425-0.047-1.635,0.445
				c-0.195,0.478-0.375,0.986-0.42,1.511c-0.106,1.193,0.27,2.291,0.645,3.388c2.31,6.777,4.604,13.571,6.899,20.348
				c1.246,3.691,2.445,7.397,3.72,11.089c0.45,1.289,1.02,2.53,1.544,3.786c0.12,0.286,0.3,0.557,0.51,0.78
				c0.285,0.318,0.66,0.445,1.08,0.349c0.316-0.063,0.69-0.541,0.69-0.923c0-0.318,0.015-0.637,0-0.954
				C488.573,150.906,488.259,149.903,487.928,148.902z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_22">
          <g>
            <path
              fill={secondary}
              d="M494.588,157.349c-0.495-1.495-1.05-2.975-1.515-4.471c-0.495-1.607-1.05-3.182-1.575-4.789
				c-0.915-2.8-1.77-5.632-2.684-8.432c-0.915-2.8-1.875-5.568-2.79-8.368c-0.525-1.591-1.109-3.182-1.544-4.82
				c-0.435-1.623-1.394-3.07-1.544-4.805c-0.015-0.19-0.135-0.398-0.241-0.572c-1.065-1.846-1.246-3.977-1.829-5.839
				c0.12-0.366,0.179-0.572,0.254-0.764c0.405-1.113,1.14-1.687,2.295-1.671c1.095,0.016,2.205-0.016,3.3-0.016
				c2.1-0.016,4.2-0.016,6.3-0.032c0.405,0,0.795-0.096,1.095-0.414c0.031-0.096,0.106-0.206,0.09-0.286
				c-0.3-1.559-0.27-3.149-0.27-4.74c0-16.228,0-32.44-0.015-48.666c0-3.294,0-6.57,0-9.864c0-3.182-0.015-6.364,0-9.546
				c0.015-3.278-0.106-6.554,0.15-9.721c-0.316-0.462-0.645-0.7-1.065-0.684c-0.601,0-1.23-0.047-1.77,0.159
				c-1.08,0.382-2.16,0.382-3.254,0.382c-1.005,0-1.995,0.032-3,0.016c-1.005-0.016-1.995,0.08-2.94,0.492
				c-1.5,0.652-3.075,0.843-4.695,0.78c-0.899-0.047-1.8-0.016-2.7,0c-0.495,0.016-1.036-0.047-1.485,0.112
				c-1.246,0.445-2.58,0.349-3.824,0.7c-1.485,0.429-2.955,0.414-4.455,0.445c-0.705,0.016-1.44-0.08-2.085,0.159
				c-1.665,0.605-3.345,0.492-5.039,0.509c-3.075,0.016-3.09-0.047-5.91,0.78c-1.184,0.349-2.34,0.478-3.539,0.492
				c-1.59,0-3.195,0.016-4.65,0.032c-1.544,0.907-2.91,1.797-4.005,3.07c-0.27,0.318-0.539,0.652-0.886,0.86
				c-1.665,1.034-2.894,2.594-4.246,3.993c-1.92,2.005-3.765,4.105-5.894,5.855c-1.425,1.177-2.64,2.594-3.93,3.913
				c-1.065,1.082-1.606,2.402-1.59,3.977c0.015,1.272-0.015,2.545-0.015,3.819c0,29.783,0,59.58,0,89.363
				c0,0.843-0.015,1.703,0.031,2.545c0.044,1.162,0.165,1.464,1.23,3.437c1.695-0.159,3.375,0.525,5.085,1.082
				c0.555,0.175,1.184,0.206,1.77,0.223c1.695,0.032,3.404,0.08,5.1,0.016c2.715-0.096,5.385,0.08,7.979,1.019
				c0.78,0.286,1.575,0.286,2.37,0.159c0.27-0.047,0.525-0.255,0.749-0.382c0.749-1.957,1.02-3.929,0.915-5.966
				c-0.044-0.748,0-1.479,0-2.228c0-10.708-0.015-21.414-0.015-32.121c0-0.954,0.031-1.909,0.106-2.847
				c0.06-0.731,0.705-1.401,1.38-1.464c1.5-0.159,2.985-0.127,4.56,0.016c1.575,0.97,2.415,2.243,2.894,3.803
				c0.48,1.511,1.065,2.975,1.515,4.471c0.466,1.511,0.974,2.992,1.485,4.487c0.735,2.195,1.305,4.454,2.13,6.619
				c0.824,2.163,1.365,4.407,2.19,6.57c0.63,1.671,1.109,3.405,1.65,5.108c0.585,1.797,1.05,3.66,1.77,5.394
				c0.69,1.654,0.795,3.517,1.679,5.075c0.15,0.255,0.179,0.605,0.225,0.923c0.15,1.193,0.735,2.179,1.215,3.229
				c0.391,0.827,0.99,1.321,1.891,1.321c1.005,0,1.964,0.239,2.94,0.492c1.065,0.286,2.19,0.112,3.24,0.525
				c0.539,0.206,1.171,0.223,1.77,0.255c1.2,0.047,2.399,0.063,3.6,0.016c1.92-0.08,3.78,0.096,5.58,0.874
				c1.335,0.572,2.746,0.478,4.14,0.239c0.316-0.047,0.72-0.525,0.749-0.891c0.015-0.206,0.044-0.414,0.06-0.637
				C495.143,159.163,494.889,158.256,494.588,157.349z M444.655,144.908c0,1.05-0.06,2.116-0.179,3.149
				c-0.044,0.382-0.345,0.731-0.585,1.05c-0.06,0.08-0.375,0.047-0.51-0.047c-0.15-0.096-0.285-0.302-0.36-0.492
				c-0.466-1.082-0.555-2.228-0.555-3.405c0.015-3.596,0-7.191,0-10.771c0-4.852,0-9.721,0-14.572c0-1.591,0.106-3.166,0.66-4.661
				c0.179-0.462,0.466-0.637,1.23-0.621c0.285,1.225,0.3,2.482,0.3,3.739c0,2.959,0,5.918,0,8.878
				C444.669,133.072,444.669,138.991,444.655,144.908z M467.995,97.737c-0.78,0.492-1.485,0.492-2.19,0.492c-2.1,0-4.2,0-6.3,0
				c-4.905,0-9.795,0.016-14.7-0.016c-2.039,0-2.295-0.27-2.31-2.322c-0.031-4.025-0.031-8.051-0.031-12.091
				c0-7.095,0.015-14.206-0.015-21.303c0-1.703,0.15-3.388,0.555-5.028c0.045-0.159,0.27-0.335,0.435-0.349
				c0.15-0.016,0.375,0.112,0.48,0.255c0.12,0.143,0.179,0.366,0.24,0.572c0.375,1.225,0.495,2.482,0.48,3.77
				c-0.015,3.707,0,7.414,0,11.137c0,5.091,0,10.183,0,15.272c0,1.066,0.044,2.116,0.12,3.182c0.044,0.717,0.614,1.368,1.321,1.544
				c0.391,0.096,0.78,0.206,1.171,0.19c1.29-0.047,2.595-0.127,3.886-0.206c0.899-0.063,1.785-0.223,2.684-0.239
				c3.899-0.047,7.8-0.047,11.7-0.063c0.495,0,1.005,0.016,1.485,0.143c0.375,0.096,0.765,0.318,1.02,0.605
				c0.539,0.621,1.02,1.305,1.184,2.083C469.073,96.433,468.444,97.132,467.995,97.737z M448.285,59.125
				c0-0.206,0-0.429,0.015-0.637c0.031-1.209,0.51-1.83,1.785-2.179c0.48-0.127,0.99-0.127,1.485-0.127c1.305-0.016,2.595,0,3.899,0
				c3.63,0,3.645,0.047,7.08-0.923c1.59-0.445,3.165-0.366,4.754-0.223c0.676,0.063,1.275,0.764,1.321,1.495
				c0.044,0.637,0.075,1.272,0.075,1.909c0,9.211,0,18.438,0,27.651c0,0.731-0.075,1.479-0.12,2.212
				c-0.031,0.349-0.466,0.811-0.78,0.86c-0.391,0.047-0.795,0.127-1.184,0.127c-5.399,0.016-10.785,0.016-16.184,0
				c-0.391,0-0.81-0.016-1.184-0.127c-0.27-0.08-0.51-0.302-0.795-0.492c-0.06-0.525-0.15-1.05-0.15-1.575
				C448.285,77.771,448.285,68.448,448.285,59.125z M468.293,39.923c-0.69,0.112-1.394,0.255-2.085,0.239
				c-3.104,0-6.179,0.302-9.254,0.811c-2.28,0.382-4.575,0.445-6.87,0.572c-0.285,0.302-0.614,0.525-1.034,0.572
				c-0.69,0.063-1.425,0.016-2.07,0.255c-1.679,0.637-3.316,0.509-4.95-0.127c-1.92,0.509-3.405,1.75-4.8,3.102
				c-1.321,1.272-2.55,2.657-3.81,4.009c-0.495,0.525-1.005,1.034-1.71,1.225c-0.165,0.047-0.375,0-0.539-0.047
				c-0.195-0.063-0.27-0.605-0.135-0.86c0.044-0.096,0.075-0.206,0.135-0.286c1.05-1.415,2.22-2.704,3.585-3.77
				c0.72-0.557,1.425-1.129,2.085-1.766c0.435-0.414,0.915-0.827,1.44-1.098c1.184-0.637,2.085-1.575,2.894-2.673
				c0.375-0.492,0.765-0.97,1.171-1.432c0.66-0.764,1.485-1.019,2.43-0.86c1.816-0.621,3.66-0.97,5.595-0.843
				c2.01,0.127,3.99-0.302,5.94-0.717c1.485-0.318,2.969-0.478,4.469-0.445c2.31,0.08,4.59-0.127,6.84-0.668
				c1.665-0.398,3.345-0.588,5.055-0.525c1.5,0.063,2.969-0.159,4.455-0.478c2.055-0.429,4.125-0.811,6.24-0.7
				c1.29,0.063,2.595-0.096,3.87-0.414c1.44-0.382,2.73,0.525,2.821,2.371c0.045,0.954,0.045,1.909,0.045,2.863
				c0,14.206-0.015,28.414-0.015,42.621c0,6.682,0.015,13.363,0.015,20.029c0,0.954-0.045,1.909-0.12,2.847
				c-0.031,0.414-0.254,0.795-0.72,1.019c-0.21-0.143-0.51-0.255-0.676-0.492c-0.48-0.7-0.72-1.511-0.765-2.371
				c-0.044-1.162-0.06-2.322-0.06-3.5c0-15.162,0.015-30.322,0.015-45.468c0-3.388-0.031-6.777-0.045-10.183
				c0-0.843-0.015-1.703-0.106-2.53c-0.106-0.939-0.69-1.528-1.515-1.781c-0.466-0.143-0.99-0.255-1.469-0.223
				c-1.394,0.096-2.79,0.175-4.17,0.414c-1.184,0.206-2.37,0.414-3.57,0.414C474.008,39,471.159,39.462,468.293,39.923z
				 M488.619,152.926c0,0.382-0.375,0.86-0.69,0.923c-0.42,0.08-0.795-0.032-1.08-0.349c-0.21-0.223-0.391-0.492-0.51-0.78
				c-0.539-1.256-1.109-2.498-1.544-3.786c-1.275-3.691-2.474-7.397-3.72-11.089c-2.295-6.793-4.59-13.571-6.899-20.348
				c-0.375-1.113-0.749-2.212-0.645-3.388c0.045-0.509,0.225-1.019,0.42-1.511c0.195-0.509,0.72-0.637,1.635-0.445
				c1.02,2.99,1.98,5.775,2.925,8.575c1.964,5.792,3.915,11.582,5.865,17.374c1.2,3.595,2.37,7.207,3.57,10.802
				c0.329,1.003,0.645,2.005,0.69,3.086C488.633,152.29,488.633,152.609,488.619,152.926z"
            />
          </g>
        </g>
      </g>
      <g id="o_1_">
        <g id="Shape_1_copy_21">
          <g>
            <path
              d="M376.917,135.076c-0.24-0.159-0.48-0.429-0.735-0.462c-0.69-0.096-1.38-0.127-2.07-0.127
				c-4.455-0.032-8.894-0.047-13.35-0.08c-2.609,0-5.235,0.016-7.845,0.016c-0.031-0.175-0.075-0.335-0.12-0.509l0.165-0.19
				c-0.391-0.223-0.78-0.462-1.155-0.684c0-0.811,0-1.623,0-2.434c-0.031-0.954-0.09-1.909-0.09-2.863
				c-0.015-19.298-0.015-38.595-0.015-57.893c0-2.863,0.06-5.727,0.09-8.592c-0.075-0.08-0.135-0.159-0.21-0.223
				c-0.81-0.509-1.35-0.382-1.665,0.429c-0.106,0.286-0.12,0.621-0.165,0.923c-0.405,0.588-0.42,1.305-0.539,2.005
				c-0.329,0.398-0.495,0.874-0.495,1.401c-0.015,0.827-0.015,1.638-0.031,2.465c0,6.46,0.015,12.934,0.015,19.394
				c0,12.823,0,25.645,0,38.469c0,1.272-0.015,2.545-0.031,3.819c0.015,1.034,0.044,2.083,0.06,3.118
				c0.015,0.541,0.179,1.019,0.585,1.385c0.27,0.572,0.48,1.162,0.81,1.687c0.495,0.811,1.125,1.479,2.114,1.575
				c0.254,0.159,0.495,0.398,0.78,0.445c1.86,0.335,3.72,0.7,5.625,0.668c1.785-0.032,3.585,0.096,5.385,0.127
				c0.899,0.016,1.785-0.032,2.684-0.047c3.539,0.335,7.095,0.19,10.65,0.175c0.285,0,0.585-0.096,0.87-0.175
				c0.81-0.223,1.184-1.305,0.705-2.02C378.371,136.126,377.772,135.442,376.917,135.076z M353.472,137.574
				c-0.075-0.112-0.165-0.206-0.27-0.286C353.306,137.367,353.397,137.463,353.472,137.574c0.93,0.016,1.86,0.032,2.79,0.032
				C355.347,137.606,354.402,137.59,353.472,137.574z M375.687,135.172c0.225,0.462,0.57,0.764,0.945,1.019
				C376.257,135.935,375.912,135.633,375.687,135.172z M400.705,45.444c-0.06-0.843-0.21-1.671-0.405-2.498
				c-0.21-0.907-0.855-1.401-1.665-1.671c-0.78-0.255-1.575-0.223-2.37-0.096c-2.19,0.349-4.38,0.302-6.585,0.302
				c-2.49-0.016-4.995-0.032-7.485,0c-1.5,0.016-2.985,0.206-4.469,0.429c-3.27,0.462-6.539,0.827-9.855,0.78
				c-3.689-0.047-7.394-0.08-11.085-0.047c-1.891,0.016-3.795,0.112-5.684,0.366c-1.394,0.19-2.775,0.335-4.17,0.398
				c-1.891,0.096-3.795,0.159-5.684,0.239c-1.905,0.08-3.78,0.398-5.565,1.162c-0.27,0.112-0.51,0.318-0.765,0.478
				c-0.3,0.206-0.391,0.764-0.254,1.162c0.195,0.541,0.555,0.874,1.02,1.082c0.84,0.366,1.754,0.429,2.655,0.398
				c1.695-0.047,3.405-0.063,5.085-0.239c5.28-0.541,10.575-0.7,15.87-0.572c5.399,0.127,10.785-0.096,16.155-0.684
				c2.684-0.286,5.37-0.509,8.07-0.525c3.99-0.016,7.995,0.016,11.985,0.032c0.495,0,1.005,0.032,1.485,0.127
				c0.27,0.063,0.495,0.318,0.705,0.462c0.316,1.209,0.179,2.371,0.179,3.531c0.015,30.943,0,61.887,0,92.847
				c0,0.843,0,1.703,0,2.545c0,0.318,0.015,0.637,0.075,0.939c0.12,0.557,0.464,0.923,0.974,1.097
				c0.391,0.143,1.005-0.239,1.184-0.748c0.106-0.302,0.195-0.605,0.3-0.891c0.435-1.336,0.345-2.72,0.345-4.089
				c0-30.943,0-61.887,0-92.847C400.765,47.782,400.78,46.605,400.705,45.444z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_20">
          <g>
            <path
              d="M379.257,65.029c-0.031-0.525-0.106-1.05-0.195-1.575c-0.165-0.939-0.36-1.861-0.601-3.086
				c-1.455-0.731-2.805-1.256-4.365-0.572c-0.525,0.239-1.184,0.159-1.77,0.223c-0.495,0.047-1.034-0.016-1.485,0.159
				c-1.35,0.541-2.759,0.429-4.155,0.445c-1.905,0.016-3.795,0-5.7,0c-1.2,0-2.399,0-3.601,0.016c-0.391,0-0.795,0.08-1.184,0.127
				c-0.329,0.047-0.765,0.492-0.795,0.843c-0.06,0.843-0.12,1.687-0.12,2.53c-0.015,4.661,0,9.323,0,13.984
				c0,16.116,0,32.215,0,48.333c0,1.066,0,2.116,0.075,3.182c0.06,1.019,0.99,2.02,1.935,2.148c0.391,0.063,0.795,0.08,1.2,0.096
				c6,0,11.985,0.016,17.984,0c0.495,0,0.99-0.047,1.485-0.112c0.539-0.063,1.171-0.7,1.215-1.256
				c0.06-0.731,0.106-1.479,0.106-2.212c0-20.46,0-40.903,0-61.361C379.272,66.301,379.286,65.648,379.257,65.029z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_19">
          <g>
            <path
              fill={secondary}
              d="M403.871,39.271c-0.015-0.318-0.075-0.637-0.135-0.939c-0.09-0.541-0.84-1.177-1.321-1.097
				c-1.469,0.239-2.956,0.445-4.41,0.795c-0.886,0.223-1.77,0.335-2.671,0.335c-2.805,0-5.595,0-8.399,0.016
				c2.894-0.016-0.749-0.891-53.428,2.514c-0.78,0.047-1.606-0.143-2.534,0.414c-0.69,1.336-0.961,2.657-0.886,4.072
				c0.031,0.525,0,1.066,0,1.591c0,27.364,0,54.711,0,82.076c0,0.843-0.015,1.703,0,2.545c0.015,0.525-0.044,1.098,0.12,1.575
				c0.539,1.544,0.435,3.135,0.435,4.726c0.015,1.797,0.015,3.611,0,5.41c-0.015,2.005,0.135,4.025-0.435,5.998
				c-0.09,0.318,0.27,0.827,0.614,0.986c1.02,0.478,2.055,0.78,3.195,0.764c1.095-0.016,2.249-0.127,3.285,0.159
				c1.485,0.429,2.969,0.429,4.455,0.429c7.904,0.016,15.795,0.016,23.699,0.016c1.394,0,2.79-0.08,4.155,0.461
				c0.45,0.175,0.99,0.127,1.485,0.175c0.601,0.063,1.23,0,1.785,0.206c1.275,0.461,2.565,0.414,3.855,0.414
				c4.005,0,7.995,0,12,0.016c0.899,0,1.845-0.063,2.67,0.206c1.275,0.398,2.55,0.445,3.855,0.445c1.905,0.016,3.795,0,5.7,0
				c0.495,0,1.005-0.016,1.5-0.032c0.84-0.047,1.35-0.509,1.38-1.336c0.044-1.385,0.075-2.753,0.075-4.136
				c0-35.207,0-70.414,0-105.605C403.901,41.387,403.886,40.321,403.871,39.271z M378.192,138.911
				c-0.285,0.08-0.57,0.175-0.87,0.175c-3.555,0.016-7.095,0.159-10.65-0.175c-0.899,0.016-1.785,0.063-2.684,0.047
				c-1.8-0.032-3.585-0.143-5.385-0.127c-1.905,0.032-3.765-0.335-5.625-0.668c-0.27-0.047-0.525-0.302-0.78-0.445
				c-0.99-0.096-1.62-0.764-2.114-1.575c-0.329-0.525-0.539-1.129-0.81-1.687c-0.391-0.366-0.57-0.827-0.585-1.385
				c-0.031-1.034-0.044-2.083-0.06-3.118c0.015-1.272,0.031-2.545,0.031-3.819c0-12.823,0-25.645,0-38.469
				c0-6.46-0.015-12.934-0.015-19.394c0.015-0.827,0.015-1.638,0.031-2.465c0.015-0.525,0.165-1.003,0.495-1.401
				c0.106-0.684,0.12-1.399,0.539-2.005c0.044-0.302,0.06-0.637,0.165-0.923c0.3-0.811,0.84-0.939,1.665-0.429
				c0.09,0.047,0.15,0.143,0.21,0.223c-0.031,2.863-0.09,5.727-0.09,8.592c0,19.298,0,38.595,0.015,57.893
				c0,0.954,0.06,1.909,0.09,2.863c0,0.811,0,1.623,0,2.434c0.391,0.223,0.78,0.462,1.155,0.684l-0.165,0.19
				c0.045,0.159,0.09,0.335,0.12,0.509c2.609,0,5.235-0.016,7.845-0.016c4.455,0.032,8.894,0.047,13.35,0.08
				c0.69,0,1.38,0.032,2.07,0.127c0.254,0.032,0.495,0.302,0.735,0.462c0.855,0.366,1.456,1.05,1.98,1.83
				C379.376,137.606,379.001,138.703,378.192,138.911z M379.272,128.283c0,0.731-0.045,1.479-0.106,2.212
				c-0.044,0.557-0.676,1.193-1.215,1.256c-0.495,0.063-0.99,0.112-1.485,0.112c-6,0-11.985,0-17.984,0
				c-0.405,0-0.795-0.032-1.2-0.096c-0.945-0.143-1.875-1.129-1.935-2.148c-0.06-1.05-0.075-2.116-0.075-3.182
				c0-16.116,0-32.215,0-48.333c0-4.661,0-9.323,0-13.984c0-0.843,0.075-1.687,0.12-2.53c0.031-0.349,0.466-0.811,0.795-0.843
				c0.391-0.047,0.795-0.127,1.184-0.127c1.2-0.016,2.399-0.016,3.601-0.016c1.905,0,3.795,0.016,5.7,0
				c1.394-0.016,2.79,0.08,4.155-0.445c0.45-0.175,0.99-0.112,1.485-0.159c0.601-0.063,1.246,0,1.77-0.223
				c1.56-0.684,2.91-0.159,4.365,0.572c0.241,1.225,0.435,2.163,0.601,3.086c0.09,0.525,0.165,1.05,0.195,1.575
				c0.031,0.637,0.015,1.272,0.015,1.909C379.272,87.38,379.272,107.84,379.272,128.283z M400.765,48.944
				c0,30.943,0,61.886,0,92.847c0,1.368,0.09,2.768-0.345,4.089c-0.09,0.302-0.195,0.605-0.3,0.891
				c-0.179,0.509-0.78,0.891-1.184,0.748c-0.51-0.175-0.855-0.557-0.974-1.097c-0.075-0.302-0.075-0.637-0.075-0.939
				c-0.015-0.843,0-1.703,0-2.545c0-30.943,0-61.887,0-92.847c0-1.162,0.15-2.322-0.179-3.531c-0.21-0.143-0.45-0.398-0.705-0.462
				c-0.48-0.112-0.99-0.127-1.485-0.127c-3.99-0.016-7.995-0.047-11.985-0.032c-2.7,0.016-5.385,0.239-8.07,0.525
				c-5.37,0.588-10.754,0.811-16.155,0.684c-5.295-0.127-10.59,0.032-15.87,0.572c-1.695,0.175-3.391,0.19-5.085,0.239
				c-0.899,0.032-1.8-0.032-2.655-0.398c-0.464-0.206-0.84-0.557-1.02-1.082c-0.135-0.398-0.031-0.954,0.254-1.162
				c0.254-0.175,0.495-0.366,0.765-0.478c1.785-0.748,3.645-1.082,5.565-1.162c1.891-0.08,3.795-0.143,5.684-0.239
				c1.394-0.063,2.79-0.223,4.17-0.398c1.875-0.255,3.78-0.349,5.684-0.366c3.689-0.032,7.394,0,11.085,0.047
				c3.316,0.047,6.585-0.335,9.855-0.78c1.485-0.206,2.969-0.398,4.469-0.429c2.49-0.032,4.995-0.016,7.485,0
				c2.205,0.016,4.394,0.047,6.585-0.302c0.795-0.127,1.59-0.159,2.37,0.096c0.81,0.27,1.456,0.764,1.665,1.671
				c0.195,0.811,0.345,1.654,0.405,2.498C400.78,46.605,400.765,47.782,400.765,48.944z"
            />
          </g>
        </g>
      </g>
      <g id="i_1_">
        <g id="Shape_1_copy_18">
          <g>
            <path
              d="M307.003,133.454c-0.705-0.032-1.394-0.096-2.101-0.112c-1.2-0.016-2.399,0-3.6-0.016c-0.391,0-0.795-0.047-1.184-0.143
				c-0.405-0.096-0.735-0.398-0.84-0.827c-0.15-0.605-0.27-1.241-0.3-1.861c-0.06-1.703-0.06-3.388-0.06-5.091
				c0-19.935-0.015-39.884-0.015-59.819c0-1.066-0.015-2.116-0.045-3.182c-0.015-0.302-0.075-0.652-0.225-0.907
				c-0.15-0.255-0.435-0.429-0.69-0.572c-0.195-0.112-0.676,0.112-0.78,0.335c-0.21,0.478-0.405,0.97-0.601,1.448
				c0.031,0.954,0.106,1.909,0.106,2.863c0,22.909,0,45.819-0.015,68.711c0,0.843-0.044,1.687-0.075,2.545
				c0.329,0.382,0.51,0.923,1.275,1.256c0.42,0.016,1.109,0.08,1.8,0.096c1.394,0.016,2.805,0,4.2,0.016
				c0.285,0,0.601,0.08,0.87,0.19c0.165,0.063,0.345,0.239,0.42,0.414c0.12,0.286,0.21,0.605,0.21,0.907
				c0.031,1.479,0,2.975,0.044,4.454c0.015,0.414,0.106,0.843,0.3,1.193c0.12,0.223,0.464,0.445,0.72,0.445
				c0.254,0,0.601-0.223,0.72-0.462c0.179-0.349,0.285-0.795,0.3-1.209c0.045-3.292,0.075-6.57,0.075-9.864
				C307.498,134.01,307.184,133.756,307.003,133.454z M303.073,48.482c0.391,0.016,0.795-0.016,1.171,0.063
				c0.525,0.112,0.899,0.478,1.005,1.034c0.12,0.621,0.135,1.256,0.241,1.877c0.06,0.302,0.179,0.605,0.329,0.86
				c0.09,0.159,0.3,0.302,0.466,0.335c0.165,0.016,0.375-0.096,0.51-0.206c0.135-0.127,0.27-0.335,0.3-0.525
				c0.45-2.402,0.42-4.82,0.09-7.223c-0.06-0.398-0.645-0.939-1.08-0.97c-0.886-0.063-1.785-0.112-2.684-0.112
				c-8.85,0-17.715,0-26.565,0c-0.69,0-1.394,0-2.085,0.032c-0.3,0.016-0.585,0.112-0.87,0.19c-0.42,0.112-0.749,0.795-0.66,1.272
				c0.27,1.368,1.05,2.275,2.28,2.673c0.945,0.318,1.935,0.478,2.894,0.7c0.795-0.063,1.575-0.159,2.37-0.159
				c6.735-0.016,13.454,0,20.189,0C301.708,48.307,302.384,48.419,303.073,48.482z M277.529,133.517
				c-1.171-0.27-2.355-0.255-3.539-0.016c-0.329,0.063-0.765,0.525-0.795,0.86c-0.044,0.414-0.06,0.843-0.106,1.305
				c0.045,0.478,0.09,1.003,0.15,1.528c0.044,0.349,0.464,0.811,0.81,0.827c0.885,0.047,1.785,0.112,2.67,0.08
				c0.765-0.032,1.5-0.78,1.679-1.575c0.179-0.731,0.165-1.464-0.044-2.179C278.234,133.93,277.933,133.613,277.529,133.517z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_17">
          <g>
            <path
              fill={secondary}
              d="M311.399,131.72c-0.044-0.478-0.539-0.986-1.02-1.003c-1.394-0.032-2.79-0.047-4.2-0.08
				c-0.795-0.016-1.59-0.063-2.385-0.143c-0.539-0.047-1.14-0.7-1.184-1.272c-0.06-0.731-0.106-1.479-0.106-2.212
				c0-19.823,0-39.631-0.015-59.453c0-1.83,0.3-3.548,0.93-5.265c2.039-1.305,4.184-1.162,6.45-0.557
				c0.27-0.27,0.601-0.509,0.824-0.827c0.585-0.891,0.795-1.926,0.795-3.006c-0.015-4.55,0-9.117,0-13.665
				c0-0.318-0.031-0.637-0.06-0.954c-0.12-1.162-1.08-2.212-2.16-2.291c-0.899-0.063-1.8-0.063-2.7-0.063
				c-11.085,0-22.184,0-33.269,0c-0.795,0-1.606-0.016-2.399,0.047c-0.375,0.032-0.749,0.206-1.02,0.286
				c-0.78,1.336-0.99,2.657-0.99,4.072c0.031,3.596,0.031,7.207,0,10.802c-0.015,1.415,0.21,2.737,0.84,4.025
				c0.645,0.492,1.155,0.478,1.65,0.492c1.095,0.016,2.205,0.016,3.3,0.032c1.246,0.016,2.07,0.7,2.52,1.893
				c0.525,1.401,0.69,2.847,0.69,4.358c-0.015,19.187-0.031,38.373,0.015,57.544c0,1.734-0.42,3.341-0.765,4.995
				c-0.106,0.525-0.555,0.907-1.05,1.034c-0.48,0.112-0.99,0.143-1.485,0.159c-1.305,0.032-2.595,0-3.899,0.032
				c-0.391,0.016-0.795,0.096-1.171,0.223c-0.165,0.047-0.36,0.255-0.405,0.429c-0.106,0.509-0.195,1.034-0.195,1.559
				c-0.015,4.869-0.015,9.752,0,14.621c0,0.318,0.015,0.637,0.031,0.954c0.075,1.097,0.69,1.814,1.665,1.877
				c0.69,0.047,1.394,0.047,2.101,0.047c11.684,0,23.384,0,35.07-0.016c0.705,0,1.394,0,2.1-0.032c0.3-0.016,0.614-0.047,0.87-0.19
				c0.24-0.143,0.48-0.398,0.57-0.668c0.135-0.382,0.165-0.827,0.165-1.241c0.015-5.091,0.015-10.181,0.015-15.256
				C311.488,132.562,311.443,132.149,311.399,131.72z M278.399,136.524c-0.195,0.795-0.915,1.544-1.679,1.575
				c-0.886,0.032-1.77-0.032-2.671-0.08c-0.345-0.016-0.765-0.478-0.81-0.827c-0.06-0.525-0.106-1.05-0.15-1.528
				c0.031-0.462,0.06-0.874,0.106-1.305c0.031-0.335,0.45-0.795,0.795-0.86c1.184-0.239,2.37-0.27,3.539,0.016
				c0.42,0.096,0.705,0.414,0.824,0.827C278.565,135.06,278.565,135.792,278.399,136.524z M280.829,48.292
				c-0.795,0-1.575,0.112-2.37,0.159c-0.974-0.223-1.95-0.398-2.894-0.7c-1.23-0.398-2.01-1.305-2.28-2.673
				c-0.09-0.478,0.225-1.162,0.66-1.272c0.285-0.08,0.585-0.175,0.87-0.19c0.69-0.032,1.394-0.032,2.085-0.032
				c8.85,0,17.715,0,26.565,0c0.899,0,1.785,0.047,2.684,0.112c0.435,0.032,1.02,0.572,1.08,0.97c0.329,2.418,0.36,4.82-0.09,7.223
				c-0.031,0.19-0.165,0.398-0.3,0.525c-0.135,0.127-0.345,0.239-0.51,0.206c-0.165-0.016-0.375-0.175-0.466-0.335
				c-0.15-0.255-0.285-0.557-0.329-0.86c-0.106-0.621-0.135-1.256-0.241-1.877c-0.106-0.557-0.48-0.923-1.005-1.034
				c-0.375-0.08-0.78-0.047-1.171-0.063c-0.69-0.063-1.38-0.175-2.07-0.175C294.283,48.292,287.549,48.292,280.829,48.292z
				 M307.423,144.113c0,0.414-0.106,0.86-0.3,1.209c-0.12,0.239-0.466,0.462-0.72,0.462c-0.241,0-0.601-0.206-0.72-0.445
				c-0.179-0.349-0.285-0.795-0.3-1.193c-0.045-1.479-0.015-2.975-0.045-4.454c0-0.302-0.09-0.621-0.21-0.907
				c-0.075-0.175-0.254-0.349-0.42-0.414c-0.27-0.112-0.57-0.175-0.87-0.19c-1.394-0.016-2.805,0-4.2-0.016
				c-0.69-0.016-1.38-0.063-1.8-0.096c-0.765-0.335-0.945-0.874-1.275-1.256c0.031-0.843,0.075-1.687,0.075-2.545
				c0.015-22.909,0.015-45.819,0.015-68.711c0-0.954-0.06-1.909-0.106-2.863c0.195-0.478,0.391-0.97,0.601-1.448
				c0.106-0.223,0.585-0.445,0.78-0.335c0.254,0.159,0.539,0.335,0.69,0.572c0.15,0.255,0.21,0.605,0.225,0.907
				c0.044,1.066,0.044,2.116,0.044,3.182c0,19.935,0,39.884,0.015,59.819c0,1.703,0.015,3.388,0.06,5.091
				c0.015,0.621,0.15,1.256,0.3,1.861c0.106,0.429,0.435,0.731,0.84,0.827c0.391,0.096,0.795,0.127,1.184,0.143
				c1.2,0.016,2.399,0,3.601,0.016c0.69,0.016,1.394,0.063,2.1,0.112c0.195,0.286,0.51,0.541,0.51,0.795
				C307.498,137.543,307.469,140.835,307.423,144.113z"
            />
          </g>
        </g>
      </g>
      <g id="n_1_">
        <g id="Shape_1_copy_16">
          <g>
            <path
              d="M246.66,53.414c0-1.272,0.045-2.545,0-3.819c-0.031-0.954-0.09-1.909-0.254-2.847c-0.51-2.975-0.899-3.341-3.72-3.388
				c-1.905-0.032-3.78-0.112-5.67-0.414c-1.08-0.175-2.19-0.143-3.285-0.143c-0.51,0-0.961,0.206-1.38,0.525
				c-0.899,0.684-0.886,2.083,0.06,2.657c0.345,0.206,0.69,0.429,1.065,0.572c1.034,0.398,2.114,0.605,3.225,0.637
				c1.095,0.032,2.205,0.032,3.285,0.143c0.886,0.096,1.77,0.27,2.64,0.492c0.974,0.255,1.544,0.97,1.606,2.036
				c0.075,1.368,0.09,2.753,0.09,4.121c0,28.398,0,56.78,0,85.178c0,1.162,0.015,2.322,0.015,3.5
				c0.179,0.206,0.36,0.414,0.555,0.637c-0.195-0.223-0.375-0.429-0.555-0.637c0,0.731-0.015,1.464,0.015,2.179
				c0.015,0.414,0.375,0.795,0.735,0.874c0.45,0.112,1.125-0.206,1.246-0.605c0.12-0.398,0.285-0.795,0.3-1.193
				c0.045-1.256,0.031-2.498,0.031-3.754C246.66,111.26,246.66,82.337,246.66,53.414z M225.045,105.056
				c-0.405-0.318-0.81-0.637-1.259-0.843c-1.679-0.748-2.7-2.132-3.36-3.866c-0.27-0.684-0.539-1.368-0.81-2.052
				c-0.975-2.545-1.95-5.108-2.925-7.669c-3.689-9.721-7.394-19.457-11.085-29.178c-1.829-4.82-3.676-9.625-5.474-14.445
				c-0.45-1.193-1.034-2.291-1.665-3.372c-0.435-0.748-1.05-1.289-1.8-1.623c-0.539-0.255-1.125-0.429-1.71-0.572
				c-2.235-0.572-4.531-0.795-6.81-1.066c-2.73-0.335-5.355,0.112-7.77,1.464c-0.3,0.843,0.15,1.321,0.614,1.703
				c0.975,0.78,2.101,1.209,3.329,1.256c1.005,0.032,1.995,0,3,0.032c2.31,0.063,4.575,0.414,6.81,0.986
				c1.455,0.366,2.505,1.289,3.21,2.657c0.48,0.923,0.899,1.893,1.259,2.88c6.345,16.704,12.674,33.41,19.02,50.114
				c0.705,1.861,1.321,3.77,2.415,5.806c0.614,0.239,1.171,0.478,1.725,0.684c1.125,0.429,2.16,0.159,3.15-0.462
				C225.796,106.9,225.871,105.691,225.045,105.056z M195.632,92.583c-0.015-0.414-0.106-0.843-0.165-1.256
				c-0.06-0.366-0.48-0.748-0.84-0.795c-0.464-0.047-0.99,0.366-1.05,0.907c-0.09,0.731-0.165,1.464-0.179,2.212
				c-0.075,4.661-0.195,9.323-0.21,13.984c-0.031,11.868-0.031,23.721-0.031,35.589c0,0.429,0.015,0.843,0.044,1.272
				c0.044,0.764,0.345,1.432,0.765,2.036c0.106,0.143,0.316,0.206,0.63,0.382c0.899-1.718,1.184-3.372,1.2-5.075
				c0-0.637,0.015-1.272,0.015-1.909c0-13.984,0.015-27.953,0-41.937C195.827,96.178,195.706,94.381,195.632,92.583z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_15">
          <g>
            <path
              fill={secondary}
              d="M250.86,45.492c-0.044-1.495-0.195-2.1-1.02-4.232c-0.42-0.366-0.93-0.349-1.425-0.349
				c-1.5-0.016-3-0.096-4.5,0c-2.52,0.159-4.995-0.175-7.425-0.795c-2.88-0.748-5.759-0.414-8.64-0.366
				c-0.645,0.016-1.35,0.891-1.215,1.575c0.345,1.893,0.225,3.803,0.225,5.712c0.015,16.863,0.015,33.727,0.015,50.575
				c0,0.637,0,1.272-0.031,1.909c-0.015,0.206-0.075,0.429-0.179,0.588c-0.106,0.159-0.27,0.318-0.435,0.398
				c-0.285,0.127-0.585,0.08-0.824-0.143c-0.075-0.063-0.165-0.127-0.21-0.223c-0.961-1.846-2.22-3.5-2.805-5.584
				c-0.42-1.511-1.125-2.943-1.71-4.407c-0.69-1.75-1.41-3.5-2.101-5.265c-1.125-2.943-2.295-5.87-3.316-8.845
				c-0.585-1.703-1.38-3.294-2.025-4.963c-1.935-5.108-3.915-10.197-5.746-15.352c-0.795-2.275-2.101-4.344-2.461-6.809
				c-0.075-0.509-0.405-0.97-0.601-1.448c-0.36-0.874-0.705-1.75-1.05-2.641c-0.84-2.132-1.575-4.328-2.894-6.284
				c-0.705-0.047-1.394-0.127-2.085-0.143c-1.785-0.047-3.585,0.096-5.34-0.525c-0.645-0.223-1.38-0.127-2.085-0.143
				c-1.695-0.047-3.405,0.143-5.039-0.492c-0.27-0.112-0.601-0.096-0.886-0.096c-2.205,0-4.394-0.016-6.6,0.032
				c-1.155,0.016-1.635,0.445-1.995,1.544c-0.375,1.113-0.66,2.228-0.585,3.421c0.031,0.525,0,1.066,0,1.591
				c0,35.095,0,70.208,0,105.303c0,0.843,0.015,1.702,0.031,2.545c0.015,1.05,0.51,1.814,1.305,2.355
				c0.329,0.223,0.705,0.382,1.08,0.572c0.705-0.096,1.38-0.223,2.085-0.27c0.795-0.047,1.606-0.032,2.399-0.032
				c1.8,0,3.601,0.016,5.399,0c0.705,0,1.41,0.032,2.085-0.112c1.964-0.429,3.961-0.478,5.955-0.509
				c0.585-0.016,1.184-0.143,1.754-0.318c0.705-0.239,1.215-0.731,1.35-1.544c0.075-0.414,0.241-0.86,0.165-1.242
				c-0.316-1.687-0.195-3.388-0.195-5.075c-0.015-16.959-0.031-33.935-0.031-50.894c0-0.748,0.015-1.479,0.06-2.228
				c0.044-0.652,0.316-1.209,0.676-1.734c0.195-0.286,0.87-0.382,1.065-0.096c0.57,0.86,1.246,1.687,1.65,2.641
				c1.14,2.704,2.16,5.473,3.21,8.21c1.02,2.641,2.025,5.298,3.031,7.938c0.87,2.259,1.74,4.503,2.609,6.762
				c0.824,2.163,1.531,4.375,2.49,6.46c1.829,4.009,3.044,8.273,4.935,12.25c1.215,2.545,1.92,5.314,3.09,7.891
				c0.915,2.005,1.62,4.105,2.37,6.172c0.405,1.113,1.02,2.036,1.829,2.753c0.375,0.047,0.676,0.127,0.975,0.127
				c6.404,0.016,12.795,0,19.199,0.032c1.246,0,2.355-0.414,3.464-0.907c0.495-0.206,0.81-0.668,0.84-1.225
				c0.075-0.954,0.09-1.909,0.09-2.863c0-33.091,0-66.166,0-99.258C250.86,46.765,250.875,46.128,250.86,45.492z M195.842,139.928
				c0,0.637-0.015,1.272-0.015,1.909c-0.015,1.718-0.3,3.357-1.2,5.075c-0.316-0.19-0.539-0.239-0.63-0.382
				c-0.42-0.605-0.735-1.256-0.765-2.036c-0.015-0.429-0.044-0.843-0.044-1.272c0-11.868,0-23.721,0.031-35.589
				c0.015-4.661,0.12-9.323,0.21-13.984c0.015-0.731,0.09-1.479,0.179-2.212c0.06-0.541,0.585-0.954,1.05-0.907
				c0.36,0.032,0.78,0.429,0.84,0.795c0.06,0.414,0.15,0.827,0.165,1.256c0.075,1.797,0.195,3.596,0.195,5.394
				C195.842,111.961,195.842,125.945,195.842,139.928z M224.941,107.442c-0.99,0.621-2.025,0.907-3.15,0.462
				c-0.555-0.206-1.109-0.445-1.725-0.684c-1.095-2.02-1.71-3.946-2.415-5.806c-6.36-16.704-12.674-33.41-19.02-50.114
				c-0.375-0.986-0.795-1.957-1.259-2.88c-0.705-1.368-1.754-2.275-3.21-2.657c-2.235-0.572-4.515-0.923-6.81-0.986
				c-1.005-0.032-1.995,0-3-0.032c-1.23-0.032-2.355-0.478-3.329-1.256c-0.464-0.382-0.915-0.843-0.614-1.703
				c2.399-1.352,5.039-1.781,7.77-1.464c2.28,0.27,4.575,0.492,6.81,1.066c0.585,0.143,1.155,0.318,1.71,0.572
				c0.735,0.349,1.365,0.874,1.8,1.623c0.63,1.082,1.215,2.179,1.665,3.372c1.816,4.82,3.645,9.625,5.474,14.445
				c3.689,9.721,7.394,19.457,11.085,29.178c0.975,2.561,1.95,5.108,2.925,7.669c0.254,0.684,0.539,1.368,0.81,2.052
				c0.676,1.734,1.679,3.102,3.36,3.866c0.45,0.206,0.87,0.525,1.259,0.843C225.871,105.691,225.796,106.9,224.941,107.442z
				 M246.63,143.937c-0.015,0.398-0.165,0.811-0.3,1.193c-0.135,0.398-0.795,0.7-1.246,0.605c-0.36-0.08-0.705-0.462-0.735-0.874
				c-0.031-0.731-0.015-1.448-0.015-2.179c-0.015-1.162-0.015-2.322-0.015-3.5c0-28.398,0-56.78,0-85.178
				c0-1.368-0.031-2.753-0.09-4.121c-0.06-1.066-0.63-1.781-1.606-2.036c-0.87-0.223-1.754-0.398-2.64-0.492
				c-1.095-0.112-2.19-0.112-3.285-0.143c-1.109-0.032-2.189-0.223-3.225-0.637c-0.375-0.143-0.72-0.366-1.065-0.572
				c-0.93-0.588-0.961-1.973-0.06-2.657c0.42-0.318,0.87-0.541,1.38-0.525c1.095,0.016,2.205-0.016,3.285,0.143
				c1.891,0.302,3.765,0.366,5.67,0.414c2.821,0.063,3.21,0.429,3.72,3.388c0.165,0.939,0.225,1.893,0.254,2.847
				c0.045,1.272,0,2.545,0,3.819c0,28.923,0.015,57.846,0.015,86.769C246.66,141.423,246.674,142.681,246.63,143.937z"
            />
          </g>
        </g>
      </g>
      <g id="e_1_">
        <g id="Shape_1_copy_14">
          <g>
            <path
              d="M154.008,141.297c-0.06-0.509-0.254-1.034-0.495-1.479c-0.749-1.385-1.829-2.228-3.405-2.163
				c-0.495,0.016-0.99,0.08-1.485,0.159c-1.98,0.335-3.974,0.349-5.969,0.318c-2.595-0.032-5.189,0.063-7.785,0.112
				c-0.601,0.016-1.215,0.016-1.785,0.159c-2.055,0.557-4.155,0.541-6.24,0.795c-2.205,0.27-4.38-0.159-6.555-0.302
				c-0.705-0.047-1.275-0.717-1.35-1.479c-0.06-0.731-0.075-1.479-0.075-2.212c0-9.419,0-18.852,0-28.271
				c0-0.731-0.031-1.479-0.12-2.212c-0.031-0.286-0.225-0.588-0.57-0.637c-0.3-0.047-0.63-0.047-0.81,0.223
				c-0.225,0.335-0.495,0.7-0.555,1.082c-0.12,0.827-0.15,1.687-0.165,2.53c-0.015,4.869,0,9.736,0,14.621h-0.015
				c0,4.773,0.015,9.529-0.015,14.302c-0.015,1.607,0.21,3.166,0.435,4.74c0.165,1.177,0.915,1.877,1.95,2.163
				c0.57,0.159,1.184,0.19,1.77,0.19c1.59,0,3.195,0.016,4.785-0.08c2.49-0.159,4.995-0.255,7.455-0.637
				c2.58-0.398,5.16-0.541,7.754-0.557c2.101-0.016,4.2,0.016,6.285,0.032c0.795,0,1.606,0,2.399,0.016
				c0.3,0,0.601,0.063,0.886,0.112c0.345,0.047,0.795,0.478,0.824,0.811c0.044,0.525,0.09,1.05,0.106,1.575
				c0.031,1.05,0.044,2.116,0.09,3.166c0.031,0.588,0.824,0.986,1.394,0.748c0.391-0.175,0.69-0.445,0.824-0.86
				C154.277,145.943,154.293,143.619,154.008,141.297z M116.628,86.251c0.015,2.847,0.09,2.943,2.925,2.943
				c7.679-0.016,15.375-0.016,23.054-0.016c1.095,0,2.205,0.032,3.285,0.143c0.585,0.063,1.155,0.302,1.695,0.557
				c0.676,0.318,1.109,0.923,1.215,1.703c0.09,0.621,0.106,1.256,0.12,1.893c0.031,1.05,0,2.116,0.075,3.166
				c0.031,0.382,0.254,0.811,0.495,1.113c0.165,0.19,0.555,0.318,0.795,0.27c0.241-0.063,0.464-0.349,0.63-0.588
				c0.106-0.159,0.135-0.398,0.135-0.605c0.015-2.322,0.031-4.661,0.015-6.985c-0.015-1.066-0.285-2.083-0.645-3.086
				c-0.525-1.448-1.469-2.212-2.969-2.1c-0.3,0.016-0.601,0-0.899,0c-5.189,0-10.38-0.016-15.57-0.016
				c-2.399,0-4.785,0.032-7.184-0.032c-1.184-0.032-2.386-0.239-3.57-0.398c-0.391-0.047-0.78-0.19-1.14-0.748
				c-0.044-0.206-0.179-0.605-0.195-1.019c-0.06-1.479-0.12-2.959-0.12-4.438c-0.015-6.348,0-12.695,0-19.059
				c0-0.843,0-1.687-0.031-2.545c-0.031-0.764-0.316-1.432-0.795-2.005c-0.3-0.366-0.66-0.398-1.335,0.112c0,0.445,0,0.86,0,1.272
				c0,8.463,0.015,16.943,0.015,25.406C116.628,82.879,116.614,84.564,116.628,86.251z M122.104,39.445
				c2.955,0.492,5.925,0.923,8.94,0.86c0.99-0.016,1.995,0.159,2.985,0.302c2.865,0.429,5.73,0.907,8.64,0.843
				c0.705-0.016,1.394,0.047,2.101,0.096c1.606,0.096,3.165,0.509,4.695,0.986c0.961,0.302,1.469,1.066,1.575,2.116
				c0.06,0.637,0.06,1.272,0.06,1.909c0.015,1.162,0,2.338,0.015,3.5c0,0.318,0.09,0.621,0.135,0.939
				c0.06,0.318,0.585,0.652,0.975,0.541c0.27-0.08,0.51-0.286,0.824-0.478c0.135-2.975,0.12-5.935-0.044-8.894
				c-0.031-0.525-0.195-1.034-0.3-1.559c-0.24-1.146-0.93-1.877-1.98-2.163c-3.09-0.86-6.195-1.687-9.435-1.495
				c-2.01,0.127-4.005-0.112-5.955-0.572c-1.875-0.445-3.749-0.605-5.655-0.572c-1.71,0.032-3.391-0.112-5.07-0.445
				c-2.355-0.478-4.725-0.86-7.14-0.764c-1.394,0.063-2.775-0.19-4.14-0.588c-1.544-0.462-3.135-0.652-4.754-0.572
				c-0.795,0.032-1.606-0.016-2.399,0c-0.405,0-0.81,0-1.2,0.08c-0.899,0.175-1.695,0.588-2.324,1.336
				c-0.329,0.398-0.3,1.066,0.106,1.399c0.15,0.127,0.3,0.286,0.464,0.398c0.961,0.588,1.95,1.034,3.075,1.162
				c1.891,0.206,3.795,0.318,5.655,0.717c1.98,0.429,3.961,0.557,5.955,0.572C119.329,39.112,120.723,39.208,122.104,39.445z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_13">
          <g>
            <path
              fill={secondary}
              d="M157.892,141.329c0-1.162,0-2.338,0-3.5c0-0.637-0.015-1.272-0.044-1.909
				c-0.075-1.336-0.66-1.973-1.829-2.052c-0.195-0.016-0.405-0.032-0.601-0.032c-2.505,0-4.995-0.016-7.5,0
				c-0.495,0-1.036-0.047-1.485,0.143c-1.259,0.492-2.55,0.429-3.855,0.478c-0.495,0.016-1.036-0.016-1.485,0.159
				c-1.455,0.572-2.955,0.478-4.455,0.478c-3.3,0.016-6.6,0.016-9.899,0c-0.601,0-1.2-0.032-1.785-0.127
				c-0.405-0.063-0.735-0.318-0.99-0.668c-0.614-0.874-0.93-1.861-0.93-2.959c0.015-8.368,0.015-16.753,0.015-25.12
				c0-0.318,0.06-0.637,0.12-0.939c0.09-0.541,0.705-1.193,1.246-1.241c0.899-0.08,1.8-0.127,2.684-0.127
				c7.695-0.016,15.389,0,23.085,0c0.899,0,1.8,0.016,2.7-0.047c0.375-0.032,0.735-0.223,1.036-0.318
				c0.899-2.355,0.899-2.355,0.93-4.089c0.015-0.525,0.015-1.066,0.015-1.591c0-4.344,0-8.686-0.015-13.046
				c0-0.731-0.06-1.479-0.12-2.212c-0.031-0.349-0.464-0.811-0.795-0.843c-0.69-0.063-1.394-0.127-2.085-0.127
				c-3.404-0.016-6.795-0.016-10.2-0.016c-1.394,0-2.79,0.047-4.155-0.445c-0.555-0.19-1.184-0.159-1.785-0.175
				c-1.095-0.032-2.205,0-3.3-0.032c-0.601-0.016-1.23,0.016-1.785-0.19c-1.171-0.414-2.355-0.398-3.555-0.414
				c-0.899-0.016-1.8-0.016-2.684-0.143c-0.36-0.047-0.735-0.335-0.99-0.637c-0.525-0.652-0.824-1.448-0.899-2.308
				c-0.031-0.414-0.031-0.843-0.031-1.272c0-5.823,0-11.661,0-17.484c0-0.429,0.015-0.843,0.06-1.272
				c0.06-0.668,0.36-1.209,0.915-1.559c0.975-0.637,1.995-0.939,3.135-0.492c0.464,0.175,0.99,0.206,1.455,0.382
				c1.531,0.605,3.135,0.605,4.725,0.605c2.205-0.016,4.394-0.016,6.6,0c1.305,0,2.609-0.096,3.899,0.063
				c1.77,0.223,3.525,0.637,5.295,0.97c1.891,0.366,3.78,0.302,5.67,0.08c0.915-0.112,1.65-0.668,2.085-1.623
				c0.48-1.05,0.945-2.083,0.945-3.309c-0.015-4.248,0-8.48-0.015-12.728c0-1.399-0.405-2.768-1.095-4.168
				c-1.259-0.366-2.534-0.652-3.749-1.082c-0.961-0.335-1.935-0.525-2.94-0.492c-0.495,0.016-1.005,0-1.5,0
				c-1.394-0.016-2.805,0.047-4.2-0.047c-1.485-0.112-2.91-0.748-4.425-0.717c-1.44-0.621-2.955-0.462-4.44-0.492
				c-1.785-0.032-3.601,0.175-5.34-0.541c-0.179-0.08-0.391-0.096-0.585-0.08c-2.101,0.032-4.184-0.127-6.24-0.621
				c-0.285-0.063-0.601,0-0.899-0.032c-0.495-0.063-1.02-0.063-1.469-0.239c-1.62-0.668-3.391-0.175-5.025-0.78
				c-0.165-0.063-0.405,0-0.585,0.063c-1.109,0.462-2.16,0.159-3.21-0.27c-1.321-0.541-2.684-0.78-4.109-0.7
				c-0.405,0.016-0.795,0-1.2,0c-1.394-0.016-2.79,0.047-4.155-0.445c-0.63-0.223-1.38-0.159-2.07-0.112
				c-0.824,0.063-1.531,0.462-2.01,1.321c-0.241,1.511-0.15,3.102-0.15,4.677c0,40.076,0,80.15,0,120.21
				c0,0.954,0.015,1.909,0.015,2.863c0,1.305,0.345,2.482,0.975,3.484c0.3,0.127,0.48,0.27,0.66,0.286
				c1.606,0.19,3.195,0.096,4.71-0.557c1.125-0.494,2.31-0.637,3.525-0.605c1.394,0.032,2.805,0.016,4.2-0.016
				c0.601-0.016,1.23,0,1.77-0.206c1.171-0.445,2.355-0.414,3.555-0.445c0.705-0.016,1.44,0.08,2.085-0.127
				c1.365-0.445,2.746-0.461,4.155-0.494c0.601-0.016,1.215-0.063,1.77-0.27c0.975-0.349,1.964-0.366,2.955-0.366
				c1.695,0,3.405-0.016,5.101-0.016c0.795,0,1.575-0.096,2.37-0.286c2.715-0.7,5.474-1.162,8.295-0.986
				c1.2,0.08,2.399,0.016,3.601,0.016c0.899,0,1.785-0.08,2.655-0.366c1.92-0.605,3.87-1.019,5.894-0.954
				c0.195,0,0.375-0.112,0.585-0.175c0.72-0.492,1.005-1.289,1.095-2.148c0.075-0.731,0.075-1.479,0.075-2.228
				C157.892,148.122,157.892,144.717,157.892,141.329z M123.798,84.596c2.399,0.063,4.785,0.032,7.184,0.032
				c5.189,0,10.38,0.016,15.57,0.016c0.3,0,0.601,0.032,0.899,0c1.5-0.112,2.445,0.652,2.969,2.1
				c0.36,1.003,0.645,2.005,0.645,3.086c0.015,2.322,0,4.661-0.015,6.985c0,0.206-0.031,0.445-0.135,0.605
				c-0.165,0.239-0.391,0.541-0.63,0.588c-0.24,0.063-0.645-0.063-0.795-0.27c-0.254-0.302-0.464-0.717-0.495-1.113
				c-0.075-1.05-0.044-2.116-0.075-3.166c-0.015-0.637-0.015-1.272-0.12-1.893c-0.12-0.78-0.555-1.385-1.215-1.703
				c-0.539-0.255-1.125-0.492-1.695-0.557c-1.095-0.127-2.189-0.143-3.285-0.143c-7.679,0-15.375,0-23.054,0.016
				c-2.835,0-2.894-0.096-2.925-2.943c-0.015-1.687,0-3.388,0-5.075c0-8.463-0.015-16.943-0.015-25.406c0-0.414,0-0.827,0-1.272
				c0.676-0.509,1.036-0.462,1.335-0.112c0.48,0.572,0.765,1.241,0.795,2.005c0.044,0.843,0.031,1.687,0.031,2.545
				c0,6.348-0.015,12.695,0,19.059c0,1.479,0.06,2.959,0.12,4.438c0.015,0.414,0.15,0.811,0.195,1.019
				c0.36,0.572,0.749,0.7,1.14,0.748C121.428,84.357,122.614,84.564,123.798,84.596z M111.979,38.508
				c-1.86-0.398-3.765-0.509-5.655-0.717c-1.125-0.127-2.114-0.572-3.075-1.162c-0.165-0.112-0.316-0.255-0.464-0.398
				c-0.405-0.335-0.435-1.019-0.106-1.401c0.63-0.748,1.425-1.162,2.324-1.336c0.391-0.08,0.795-0.08,1.2-0.08
				c0.795-0.016,1.606,0.047,2.399,0c1.62-0.08,3.195,0.112,4.754,0.572c1.365,0.398,2.746,0.652,4.14,0.588
				c2.415-0.112,4.785,0.286,7.14,0.764c1.679,0.335,3.36,0.478,5.07,0.445c1.905-0.032,3.78,0.127,5.655,0.572
				c1.95,0.462,3.93,0.7,5.955,0.572c3.24-0.19,6.345,0.637,9.435,1.495c1.05,0.286,1.725,1.019,1.98,2.163
				c0.106,0.509,0.27,1.034,0.3,1.559c0.165,2.959,0.179,5.918,0.044,8.894c-0.316,0.19-0.555,0.398-0.824,0.478
				c-0.391,0.112-0.915-0.223-0.975-0.541c-0.06-0.302-0.135-0.621-0.135-0.939c-0.015-1.162,0-2.338-0.015-3.5
				c0-0.637,0-1.272-0.06-1.909c-0.106-1.034-0.614-1.814-1.575-2.116c-1.531-0.478-3.09-0.891-4.695-0.986
				c-0.705-0.047-1.394-0.112-2.101-0.096c-2.91,0.08-5.775-0.414-8.64-0.843c-0.99-0.143-1.98-0.318-2.985-0.302
				c-3.015,0.063-5.985-0.366-8.94-0.86c-1.394-0.239-2.775-0.335-4.184-0.349C115.938,39.064,113.959,38.937,111.979,38.508z
				 M153.588,148.202c-0.135,0.414-0.435,0.7-0.824,0.86c-0.57,0.255-1.365-0.143-1.394-0.748c-0.06-1.05-0.06-2.116-0.09-3.166
				c-0.015-0.525-0.06-1.05-0.106-1.575c-0.031-0.335-0.48-0.764-0.824-0.811c-0.3-0.047-0.585-0.096-0.886-0.112
				c-0.795-0.016-1.59-0.016-2.399-0.016c-2.101-0.016-4.2-0.047-6.285-0.032c-2.595,0.016-5.176,0.159-7.754,0.557
				c-2.461,0.382-4.964,0.478-7.455,0.637c-1.59,0.096-3.195,0.08-4.785,0.08c-0.601,0-1.215-0.016-1.77-0.19
				c-1.036-0.286-1.77-0.986-1.95-2.163c-0.225-1.575-0.45-3.135-0.435-4.74c0.031-4.773,0.015-9.529,0.015-14.302h0.015
				c0-4.869,0-9.736,0-14.621c0-0.843,0.044-1.703,0.165-2.53c0.06-0.382,0.329-0.748,0.555-1.082c0.195-0.27,0.51-0.27,0.81-0.223
				c0.345,0.063,0.539,0.349,0.57,0.637c0.09,0.731,0.12,1.479,0.12,2.212c0,9.419,0,18.852,0,28.271
				c0,0.731,0.015,1.479,0.075,2.212c0.06,0.764,0.645,1.432,1.35,1.479c2.189,0.159,4.35,0.572,6.555,0.302
				c2.085-0.255,4.184-0.239,6.24-0.795c0.57-0.159,1.184-0.143,1.785-0.159c2.595-0.047,5.189-0.143,7.785-0.112
				c1.995,0.032,3.99,0.032,5.969-0.318c0.495-0.08,0.99-0.143,1.485-0.159c1.575-0.063,2.655,0.78,3.405,2.163
				c0.241,0.445,0.435,0.97,0.495,1.479C154.293,143.619,154.277,145.943,153.588,148.202z"
            />
          </g>
        </g>
      </g>
      <g id="s_2_">
        <g id="Shape_1_copy_12">
          <g>
            <path
              d="M74.855,149.124c0.015-11.772,0.044-23.546,0.031-35.318c0-1.05-0.165-2.116-0.241-3.166
				c-0.15-1.893-0.93-3.452-2.176-4.757c-0.855-0.891-1.71-1.797-2.625-2.61c-4.065-3.596-8.174-7.144-12.254-10.722
				c-1.544-1.352-3.06-2.737-4.544-4.136c-2.684-2.53-5.355-5.091-8.025-7.652c-0.3-0.286-0.601-0.557-0.915-0.827
				c-2.79-2.418-5.295-5.171-7.86-7.828c-0.21-0.223-0.42-0.462-0.614-0.7c-0.87-1.082-1.38-2.338-1.469-3.754
				c-0.06-0.954-0.075-1.909-0.075-2.863c0-4.454,0-8.909-0.015-13.363c0-0.954-0.06-1.909-0.15-2.847
				c-0.031-0.302-0.21-0.588-0.329-0.874c-0.09-0.206-0.614-0.382-0.795-0.239c-0.225,0.19-0.539,0.398-0.614,0.652
				c-0.165,0.605-0.285,1.241-0.285,1.861c-0.031,2.863,0,5.727,0,8.592c0,3.5,0.015,7.001,0,10.5c0,1.607,0.21,3.166,0.614,4.709
				c0.3,1.146,0.81,2.163,1.62,3.006c0.285,0.302,0.555,0.605,0.855,0.891c2.746,2.641,5.461,5.282,8.235,7.891
				c2.534,2.402,5.114,4.74,7.679,7.111c1.964,1.814,3.915,3.627,5.894,5.41c1.679,1.511,3.391,2.959,5.101,4.438
				c2.249,1.94,4.5,3.866,6.72,5.839c0.84,0.748,1.635,1.575,2.386,2.418c1.14,1.289,1.77,2.8,1.695,4.614
				c-0.031,0.748,0,1.479,0,2.228c0,6.157,0,12.298,0,18.454l0,0c0,3.182,0,6.364,0,9.546c0,3.611,0,7.207,0.015,10.818
				c0,0.525,0.044,1.066,0.195,1.544c0.075,0.223,0.435,0.414,0.69,0.492c0.391,0.112,0.961-0.175,1.02-0.478
				c0.09-0.398,0.21-0.811,0.21-1.225C74.869,154.214,74.855,151.669,74.855,149.124z M17.796,25.033
				c0.72,0.605,1.544,1.019,2.43,1.209c2.249,0.492,4.5,0.986,6.78,1.305c1.98,0.27,3.961,0.492,5.925,0.923
				c0.69,0.143,1.38,0.27,2.07,0.302c1.606,0.08,3.179,0.398,4.74,0.7c0.69,0.143,1.365,0.382,2.055,0.398
				c2.415,0.063,4.754,0.652,7.125,0.986c1.08,0.159,2.189,0.255,3.27,0.366c0.69,0.08,1.41,0.032,2.085,0.223
				c1.754,0.478,3.539,0.605,5.34,0.795c1.095,0.112,2.205,0.206,3.27,0.462c1.56,0.382,3.15,0.572,4.74,0.717
				c0.795,0.08,1.575,0.255,2.34,0.478c1.095,0.318,1.635,1.177,1.71,2.338c0.044,0.731,0.031,1.479,0.031,2.228
				c0,5.616-0.015,11.232,0,16.847c0,0.843-0.135,1.703,0.21,2.498c0.945,0.684,0.945,0.684,1.77,0.016
				c0.165-2.163,0.254-21.828,0.106-26.06c-0.015-0.414-0.09-0.827-0.165-1.432C71,28.614,68.36,27.771,65.51,27.707
				c-1.71-0.047-3.389-0.159-5.055-0.605c-0.765-0.206-1.575-0.286-2.37-0.318c-1.71-0.08-3.375-0.398-5.039-0.764
				c-0.78-0.175-1.575-0.398-2.355-0.398c-2.01-0.032-3.961-0.478-5.925-0.874c-0.78-0.159-1.575-0.302-2.37-0.335
				c-1.394-0.08-2.775-0.27-4.14-0.605c-1.259-0.302-2.565-0.445-3.84-0.637c-0.495-0.08-1.005-0.047-1.485-0.206
				c-2.025-0.637-4.125-0.731-6.21-1.05c-0.601-0.096-1.2-0.143-1.77-0.335c-1.544-0.509-3.15-0.525-4.754-0.605
				c-0.585-0.032-1.2,0.08-1.77,0.206c-0.27,0.063-0.57,0.223-0.78,0.429C16.762,22.519,16.866,24.269,17.796,25.033z
				 M51.62,145.512c-1.23-0.478-2.49-0.717-3.824-0.414c-1.275,0.27-2.565,0.429-3.84,0.621c-0.886,0.127-1.785,0.27-2.684,0.27
				c-2.295,0.016-4.59-0.016-6.885-0.063c-0.795-0.016-1.321-0.557-1.335-1.401c-0.044-1.703-0.044-3.388-0.044-5.091
				c0-2.228,0.015-4.454,0-6.682c0-0.748-0.031-1.495-0.15-2.212c-0.165-1.082-1.034-2.577-2.67-2.482
				c-1.29,0.08-2.595,0.143-3.885,0.27c-1.184,0.127-2.37,0.318-3.555,0.492c-0.886,0.127-1.785,0.27-2.67,0.429
				c-1.005,0.175-1.905,0.621-2.746,1.225c-0.24,0.175-0.45,0.414-0.645,0.652c-0.285,0.318-0.316,1.129-0.06,1.495
				c0.676,1.003,1.544,1.687,2.715,1.781c1.29,0.112,2.595,0.159,3.885,0.19c1.095,0.032,2.205,0,3.3-0.016
				c0.915-0.016,1.785,0.206,2.64,0.525c0.945,0.349,1.469,1.082,1.56,2.116c0.075,0.954,0.12,1.909,0.135,2.863
				c0.015,2.545,0.015,5.091,0.015,7.636c0,0.637,0,1.272,0.044,1.909c0.106,1.272,1.455,2.545,2.67,2.545
				c0.405,0,0.81,0,1.184-0.096c1.485-0.349,2.969-0.335,4.469-0.382c1.2-0.047,2.415-0.08,3.585-0.302
				c2.37-0.461,4.725-0.843,7.14-0.923c1.365-0.047,2.52-0.605,3.495-1.591c0.63-0.652,0.87-1.415,0.254-2.418
				C53.091,146.149,52.371,145.8,51.62,145.512z"
            />
          </g>
        </g>
        <g id="Shape_1_copy_11">
          <g>
            <path
              fill={secondary}
              d="M78.679,108.763c0-0.637,0-1.272-0.075-1.909c-0.106-0.86-0.464-1.607-1.08-2.212
				c-1.109-1.066-2.205-2.148-3.316-3.214c-0.45-0.429-0.87-0.907-1.38-1.209c-1.394-0.86-2.52-2.069-3.78-3.102
				c-0.63-0.525-1.14-1.193-1.754-1.718c-2.49-2.116-5.055-4.136-7.365-6.46c-1.184-0.652-2.039-1.703-2.969-2.641
				c-0.51-0.509-0.945-1.129-1.531-1.511c-1.544-1.034-2.759-2.434-4.05-3.754c-0.285-0.302-0.555-0.637-0.899-0.843
				c-1.575-0.986-2.759-2.434-4.05-3.754c-0.93-0.954-1.92-1.83-2.925-2.704c-2.16-1.877-4.215-3.898-5.49-6.619
				c-0.044-0.335-0.12-0.652-0.12-0.97c-0.015-5.41-0.031-10.818-0.031-16.228c0-0.318,0.06-0.637,0.09-0.939
				c0.06-0.462,0.555-0.986,1.034-0.986c4.275,0,8.595-0.525,12.749,1.097c0.27,0.112,0.585,0.112,0.886,0.175
				c0.705,0.159,1.335,0.445,1.785,1.05c0.705,1.352,0.87,2.8,0.84,4.311c-0.031,2.116-0.015,4.248,0,6.364
				c0,0.637,0.015,1.272,0.075,1.909c0.09,0.97,1.05,2.005,1.964,2.132c0.601,0.08,1.2,0.096,1.8,0.096
				c2.295,0.016,4.604,0.016,6.899,0c1.305-0.016,2.58,0.096,3.84,0.525c2.01,0.684,4.095,0.891,6.21,0.717
				c0.405-0.032,0.78-0.127,1.095-0.429c0.06-0.08,0.12-0.159,0.179-0.255c0.676-1.352,0.765-2.816,0.765-4.328
				c-0.015-9.005-0.06-18.025,0.015-27.03c0.031-2.577-0.555-5.012-0.81-7.526c-0.93-0.557-1.8-1.066-2.925-1.718
				c-0.93-0.016-1.816-0.063-2.715-0.047c-1.215,0.016-2.386-0.112-3.48-0.764c-0.72-0.429-1.515-0.509-2.324-0.492
				c-0.495,0-1.005-0.016-1.5,0c-1.109,0.047-2.189-0.143-3.225-0.588c-1.321-0.572-2.7-0.731-4.109-0.684
				c-0.899,0.032-1.785,0.016-2.684-0.206c-2.025-0.525-4.065-1.082-6.179-1.082c-0.3,0-0.614-0.047-0.886-0.159
				c-1.14-0.492-2.34-0.509-3.539-0.478c-1.02,0.032-1.995-0.159-2.925-0.541c-1.321-0.541-2.67-0.827-4.109-0.731
				c-1.109,0.08-2.189-0.112-3.179-0.684c-0.81-0.478-1.695-0.572-2.609-0.557c-0.601,0.016-1.2,0-1.8,0
				c-0.81,0-1.606-0.08-2.324-0.509c-1.08-0.637-2.265-0.78-3.48-0.764c-0.795,0-1.635,0.047-2.37-0.206
				c-2.16-0.717-4.35-0.398-6.539-0.366c-0.464,0-0.99,0.541-1.005,1.019c-0.06,1.162-0.12,2.322-0.12,3.484
				c0,17.596,0,35.191,0,52.802c0,0.843,0.015,1.703,0.015,2.545c0,1.336,0.495,2.465,1.29,3.468
				c0.135,0.159,0.27,0.318,0.405,0.462c2.189,2.322,4.394,4.646,6.585,6.952c0.285,0.302,0.57,0.621,0.915,0.827
				c1.155,0.684,2.025,1.718,2.955,2.688c1.14,1.177,2.265,2.402,3.42,3.58c0.495,0.509,0.99,1.066,1.575,1.464
				c0.855,0.572,1.56,1.305,2.28,2.036c1.29,1.321,2.49,2.753,4.05,3.754c0.495,0.318,0.886,0.86,1.321,1.289
				c0.51,0.509,0.961,1.129,1.531,1.511c2.22,1.479,3.87,3.643,5.969,5.282c2.085,1.607,3.855,3.691,5.894,5.394
				c1.171,0.986,1.86,2.116,2.265,3.611c0.285,1.05,0.345,2.083,0.345,3.135c0,4.883,0,9.752,0,14.637c0,0.525,0,1.066-0.031,1.591
				c-0.135,1.766-1.215,2.975-2.85,3.102c-0.99,0.08-1.995,0.08-3,0.047c-1.425-0.047-2.79,0.127-4.109,0.684
				c-1.725,0.748-3.525,0.668-5.324,0.492c-0.961-0.096-1.875-1.097-1.964-2.1c-0.06-0.731-0.075-1.479-0.075-2.228
				c-0.015-3.388,0-6.777-0.015-10.183c0-0.843-0.044-1.703-0.165-2.53c-0.075-0.541-0.464-0.939-0.974-1.098
				c-0.375-0.112-0.78-0.175-1.184-0.175c-1.695-0.016-3.404-0.016-5.1-0.016c-1.29,0-2.595-0.047-3.855,0.414
				c-0.555,0.206-1.184,0.112-1.785,0.19c-0.601,0.063-1.215,0.08-1.77,0.286c-0.974,0.349-1.964,0.349-2.969,0.349
				c-1.606,0.016-3.195,0.032-4.8,0.047c-0.405,0-0.78,0.127-1.184,0.557c-0.57,1.671-0.87,3.5-0.855,5.441
				c0.06,6.777,0.015,13.571,0.015,20.348c0,5.52,0.031,11.025-0.015,16.545c-0.015,1.528,0.27,2.943,0.81,4.328
				c0.195,0.509,0.585,0.891,1.109,0.97c1.485,0.223,2.985,0.286,4.44-0.302c1.5-0.621,3.06-0.939,4.695-0.827
				c1.215,0.08,2.386-0.08,3.525-0.588c1.321-0.572,2.684-0.748,4.109-0.684c0.69,0.032,1.44,0.096,2.085-0.112
				c1.455-0.461,2.94-0.557,4.44-0.588c0.391,0,0.78-0.159,1.171-0.239c1.259-0.286,2.52-0.668,3.795-0.843
				c1.08-0.159,2.189-0.112,3.285-0.127c1.005-0.016,1.995-0.032,2.94-0.461c1.59-0.7,3.27-0.923,4.995-0.811
				c1.425,0.096,2.79-0.112,4.109-0.684c1.036-0.445,2.114-0.637,3.225-0.572c0.405,0.016,0.795-0.016,1.2,0
				c1.515,0.063,2.969-0.143,4.44-0.572c1.335-0.398,2.684-0.843,4.125-0.684c2.22,0.239,4.319-0.731,6.51-0.843
				c0.195-0.016,0.375-0.143,0.555-0.239c0.585-0.286,0.99-0.731,1.036-1.432c0.06-0.731,0.06-1.479,0.06-2.228
				C78.679,143.11,78.679,125.928,78.679,108.763z M26.99,27.547c-2.28-0.318-4.531-0.811-6.78-1.305
				c-0.886-0.19-1.695-0.605-2.43-1.209c-0.915-0.764-1.02-2.498-0.165-3.405c0.21-0.206,0.495-0.382,0.78-0.429
				c0.585-0.112,1.184-0.239,1.77-0.206c1.59,0.08,3.195,0.096,4.754,0.605c0.57,0.19,1.171,0.239,1.77,0.335
				c2.07,0.302,4.184,0.414,6.21,1.05c0.464,0.143,0.99,0.127,1.485,0.206c1.29,0.206,2.58,0.335,3.84,0.637
				c1.365,0.335,2.746,0.525,4.14,0.605c0.795,0.047,1.59,0.175,2.37,0.335c1.964,0.414,3.915,0.843,5.925,0.874
				c0.795,0.016,1.575,0.239,2.355,0.398c1.665,0.366,3.329,0.684,5.039,0.764c0.795,0.032,1.604,0.112,2.37,0.318
				c1.665,0.462,3.345,0.572,5.055,0.605c2.85,0.063,5.505,0.907,8.114,2.625c0.075,0.605,0.15,1.019,0.165,1.432
				c0.15,4.215,0.06,23.88-0.106,26.06c-0.81,0.668-0.81,0.668-1.77-0.016c-0.345-0.795-0.21-1.654-0.21-2.498
				c-0.015-5.616,0-11.232,0-16.847c0-0.748,0.015-1.479-0.031-2.228c-0.075-1.162-0.614-2.02-1.71-2.338
				c-0.765-0.223-1.56-0.414-2.34-0.478c-1.59-0.143-3.179-0.335-4.74-0.717c-1.065-0.27-2.176-0.349-3.27-0.462
				c-1.785-0.19-3.585-0.302-5.34-0.795c-0.676-0.19-1.394-0.143-2.085-0.223c-1.095-0.112-2.189-0.206-3.27-0.366
				c-2.37-0.349-4.71-0.923-7.125-0.986c-0.69-0.016-1.38-0.255-2.055-0.398c-1.575-0.318-3.135-0.637-4.74-0.7
				c-0.69-0.032-1.394-0.159-2.07-0.302C30.951,28.039,28.971,27.816,26.99,27.547z M53.451,148.837
				c-0.974,0.986-2.13,1.559-3.495,1.591c-2.399,0.08-4.77,0.461-7.14,0.923c-1.171,0.223-2.386,0.27-3.585,0.302
				c-1.5,0.047-3,0.032-4.469,0.382c-0.391,0.096-0.795,0.096-1.184,0.096c-1.215,0-2.565-1.272-2.67-2.545
				c-0.06-0.637-0.044-1.272-0.044-1.909c0-2.545,0-5.091-0.015-7.636c0-0.954-0.06-1.909-0.135-2.863
				c-0.09-1.05-0.614-1.766-1.56-2.116c-0.855-0.318-1.725-0.541-2.64-0.525c-1.095,0.016-2.205,0.047-3.3,0.016
				c-1.305-0.032-2.595-0.063-3.885-0.19c-1.171-0.096-2.039-0.78-2.715-1.781c-0.24-0.366-0.225-1.177,0.06-1.495
				c0.21-0.223,0.405-0.478,0.645-0.652c0.84-0.588,1.74-1.05,2.746-1.225c0.886-0.159,1.77-0.302,2.67-0.429
				c1.184-0.175,2.37-0.366,3.555-0.492c1.29-0.127,2.58-0.19,3.885-0.27c1.635-0.096,2.505,1.415,2.67,2.482
				c0.106,0.731,0.15,1.479,0.15,2.212c0.015,2.228,0,4.454,0,6.682c0,1.703,0.015,3.388,0.044,5.091
				c0.015,0.827,0.539,1.385,1.335,1.401c2.295,0.047,4.59,0.08,6.885,0.063c0.899,0,1.785-0.159,2.684-0.27
				c1.29-0.175,2.58-0.335,3.84-0.621c1.335-0.286,2.58-0.063,3.824,0.414c0.749,0.286,1.469,0.637,2.085,0.907
				C54.306,147.422,54.08,148.202,53.451,148.837z M74.855,156.761c0,0.414-0.12,0.827-0.21,1.225
				c-0.06,0.286-0.63,0.588-1.02,0.478c-0.27-0.08-0.614-0.27-0.69-0.492c-0.15-0.492-0.195-1.034-0.195-1.544
				c-0.015-3.611-0.015-7.207-0.015-10.818c0-3.182,0-6.364,0-9.546l0,0c0-6.157,0-12.298,0-18.454c0-0.748-0.031-1.479,0-2.228
				c0.075-1.814-0.57-3.325-1.695-4.614c-0.749-0.843-1.544-1.671-2.386-2.418c-2.22-1.973-4.485-3.898-6.72-5.839
				c-1.695-1.479-3.42-2.927-5.1-4.438c-1.98-1.781-3.945-3.595-5.894-5.41c-2.565-2.371-5.145-4.709-7.679-7.111
				c-2.759-2.61-5.49-5.251-8.235-7.891c-0.3-0.286-0.57-0.588-0.855-0.891c-0.81-0.827-1.321-1.861-1.62-3.006
				c-0.405-1.544-0.63-3.102-0.614-4.709c0.015-3.5,0-7.001,0-10.5c0-2.863-0.031-5.727,0-8.592c0-0.621,0.12-1.256,0.285-1.861
				c0.075-0.255,0.391-0.462,0.614-0.652c0.179-0.143,0.72,0.032,0.795,0.239c0.12,0.286,0.3,0.572,0.329,0.874
				c0.075,0.954,0.135,1.893,0.15,2.847c0.015,4.454,0,8.909,0.015,13.363c0,0.954,0.031,1.909,0.075,2.863
				c0.09,1.415,0.601,2.673,1.469,3.754c0.195,0.239,0.391,0.478,0.614,0.7c2.565,2.673,5.085,5.41,7.86,7.828
				c0.316,0.27,0.614,0.541,0.915,0.827c2.67,2.545,5.34,5.122,8.025,7.652c1.5,1.401,3.015,2.784,4.544,4.136
				c4.08,3.58,8.174,7.128,12.254,10.722c0.915,0.811,1.785,1.703,2.625,2.61c1.246,1.321,2.025,2.88,2.176,4.757
				c0.075,1.05,0.241,2.116,0.241,3.166c0,11.772-0.015,23.546-0.031,35.318C74.855,151.669,74.869,154.214,74.855,156.761z"
            />
          </g>
        </g>
      </g>
      <path
        stroke={primary}
        stroke-width="9"
        stroke-miterlimit="10"
        d="M366.995,281.833"
      />
      <path
        fill="#FCFCFC"
        stroke={primary}
        stroke-width="7"
        stroke-miterlimit="10"
        d="M557.5,306.5"
      />
      <text
        transform="matrix(1.2377 0 0 1 3.0757 698.8115)"
        fill="none"
        stroke={primary}
        stroke-width="9"
        stroke-miterlimit="10"
        font-family="'CollegeSemiCondensed'"
        font-size="555.0421"
      >
        21
      </text>
    </svg>
  );
}

Artwrk1.propTypes = {
  primary: string,
  secondary: string,
};

Artwrk1.defaultProps = {
  primary: "#000",
  secondary: "#fff",
};
