import React from "react";
import { string } from "prop-types";

export default function Artwrk8(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      fill={primary}
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="Shape_1_1_" enable-background="new    ">
        <g id="Shape_1">
          <path
            d="M290.909,433.403c0,3.499-0.327,6.987-1.213,10.393c-0.373,1.435-0.828,2.835-1.411,4.188
			c-2.706,6.299-7.337,10.498-13.811,12.668c-2.66,0.887-5.412,1.423-8.224,1.481c-1.866,0.035-3.733,0.07-5.599-0.035
			c-3.359-0.175-6.602-0.898-9.717-2.228c-5.074-2.17-8.795-5.751-11.245-10.685c-1.423-2.881-2.263-5.949-2.718-9.122
			c-0.362-2.461-0.478-4.946-0.478-7.442c0.023-9.565,0.012-19.13,0.012-28.695l0,0c0-9.798,0.012-19.597-0.012-29.395
			c0-3.044,0.303-6.054,0.922-9.028c0.49-2.368,1.26-4.643,2.356-6.812c2.59-5.167,6.602-8.784,11.968-10.86
			c3.068-1.19,6.252-1.843,9.553-1.936c1.785-0.047,3.581-0.082,5.366,0.023c3.359,0.198,6.614,0.887,9.728,2.205
			c5.086,2.146,8.83,5.716,11.303,10.627c1.155,2.298,1.936,4.748,2.438,7.267c0.56,2.835,0.805,5.692,0.817,8.574
			C290.932,394.209,290.932,413.806,290.909,433.403L290.909,433.403z M272.969,374.368c0-1.236-0.035-2.485-0.117-3.721
			c-0.105-1.4-0.467-2.753-1.073-4.036c-1.236-2.601-3.336-4.024-6.159-4.386c-1.318-0.163-2.636-0.175-3.954,0.012
			c-2.73,0.385-4.771,1.773-5.984,4.269c-0.548,1.12-0.91,2.31-1.038,3.558c-0.175,1.621-0.28,3.243-0.28,4.887
			c0.012,17.019,0.012,34.026,0.012,51.045c0,2.566,0.012,5.121,0,7.687c-0.012,1.481,0.14,2.951,0.303,4.409
			c0.128,1.166,0.478,2.275,0.98,3.336c1.178,2.531,3.196,3.931,5.926,4.351c1.306,0.198,2.625,0.198,3.954,0.047
			c3.138-0.373,5.319-1.995,6.497-4.934c0.502-1.236,0.817-2.52,0.875-3.838c0.058-1.236,0.093-2.485,0.093-3.721
			c0.012-9.787,0-19.573,0-29.36C272.969,394.104,272.98,384.236,272.969,374.368L272.969,374.368z M210.457,380.072
			c-0.56,5.307-1.96,10.358-4.211,15.199c-1.283,2.753-2.776,5.389-4.433,7.92c-2.858,4.363-6.112,8.434-9.565,12.341
			c-1.598,1.808-3.266,3.546-4.853,5.354c-1.901,2.158-3.768,4.339-5.622,6.544c-1.248,1.493-2.356,3.091-3.383,4.736
			c-0.735,1.19-1.388,2.426-1.936,3.721c-1.085,2.566-1.411,5.237-1.166,7.99c0.023,0.21,0.152,0.42,0.245,0.653
			c0.233,0.07,0.443,0.187,0.665,0.187c0.782,0.023,1.551,0.023,2.333,0.023c9.168,0,18.349,0,27.517,0
			c0.852,0,1.703,0.012,2.485,0.023c0.187,0.163,0.303,0.268,0.467,0.397c0.023,0.35,0.082,0.735,0.082,1.12
			c0,4.584-0.012,9.168-0.023,13.764c0,0.152-0.058,0.303-0.105,0.443c-0.023,0.07-0.07,0.14-0.117,0.245
			c-0.187,0.07-0.397,0.187-0.618,0.198c-0.7,0.035-1.4,0.047-2.1,0.047c-15.234,0-30.48,0-45.714,0c-0.7,0-1.4,0-2.1-0.047
			c-0.222-0.012-0.432-0.152-0.7-0.245c-0.047-0.385-0.128-0.758-0.128-1.143c-0.012-4.584-0.012-9.168,0.012-13.764
			c0.023-7.01,1.971-13.484,5.517-19.492c1.738-2.951,3.744-5.727,5.902-8.375c2.158-2.648,4.468-5.179,6.812-7.664
			c2.939-3.114,5.739-6.346,8.282-9.787c1.715-2.31,3.278-4.724,4.619-7.279c1.971-3.756,3.289-7.722,3.838-11.921
			c0.467-3.546,0.583-7.127,0.105-10.697c-0.187-1.388-0.525-2.753-1.12-4.024c-1.213-2.625-3.266-4.106-6.147-4.339
			c-1.085-0.093-2.181-0.105-3.254-0.012c-3.231,0.28-5.517,1.913-6.719,4.969c-0.513,1.306-0.828,2.671-0.91,4.083
			c-0.047,0.852-0.058,1.703-0.058,2.566c-0.012,3.266,0,6.532-0.012,9.798c0,0.537,0.105,1.085-0.21,1.656
			c-0.152,0.07-0.362,0.187-0.572,0.233c-0.222,0.047-0.467,0.035-0.7,0.035c-4.666,0-9.332,0-13.998,0
			c-0.315,0-0.618-0.035-0.852-0.047c-0.373-0.198-0.502-0.455-0.525-0.782c-0.012-0.082-0.012-0.152-0.012-0.233
			c0.012-4.584-0.14-9.18,0.105-13.753c0.187-3.511,0.898-6.929,2.193-10.207c2.823-7.185,8.037-11.665,15.456-13.613
			c2.415-0.63,4.864-1.015,7.372-1.003c1.318,0,2.648-0.023,3.966,0.035c3.126,0.14,6.159,0.735,9.098,1.843
			c5.167,1.948,9.04,5.366,11.606,10.253c1.236,2.356,2.076,4.853,2.601,7.454C210.842,370.297,210.971,375.161,210.457,380.072
			L210.457,380.072z M382.862,380.072c-0.56,5.307-1.96,10.358-4.211,15.199c-1.283,2.753-2.776,5.389-4.433,7.92
			c-2.858,4.363-6.112,8.434-9.565,12.341c-1.598,1.808-3.266,3.546-4.853,5.354c-1.901,2.158-3.768,4.339-5.611,6.544
			c-1.248,1.493-2.356,3.091-3.383,4.736c-0.735,1.19-1.388,2.426-1.936,3.721c-1.085,2.566-1.411,5.237-1.166,7.99
			c0.023,0.21,0.152,0.42,0.245,0.653c0.233,0.07,0.443,0.187,0.665,0.187c0.77,0.023,1.551,0.023,2.333,0.023
			c9.168,0,18.349,0,27.517,0c0.852,0,1.703,0.012,2.485,0.023c0.187,0.163,0.303,0.268,0.467,0.397
			c0.023,0.35,0.082,0.735,0.082,1.12c0,4.584-0.012,9.168-0.023,13.764c0,0.152-0.058,0.303-0.105,0.443
			c-0.023,0.07-0.07,0.14-0.117,0.245c-0.187,0.07-0.397,0.187-0.618,0.198c-0.7,0.035-1.4,0.047-2.1,0.047
			c-15.234,0-30.48,0-45.714,0c-0.7,0-1.4,0-2.1-0.047c-0.222-0.012-0.432-0.152-0.7-0.245c-0.047-0.385-0.128-0.758-0.128-1.143
			c-0.012-4.584-0.012-9.168,0.012-13.764c0.023-7.01,1.971-13.484,5.517-19.492c1.738-2.951,3.744-5.727,5.902-8.375
			c2.158-2.66,4.468-5.179,6.812-7.664c2.928-3.114,5.739-6.346,8.282-9.787c1.715-2.31,3.266-4.724,4.608-7.279
			c1.971-3.756,3.289-7.722,3.838-11.921c0.467-3.546,0.583-7.127,0.105-10.697c-0.187-1.388-0.525-2.753-1.12-4.024
			c-1.213-2.625-3.266-4.106-6.147-4.339c-1.085-0.093-2.181-0.105-3.254-0.012c-3.231,0.28-5.517,1.913-6.719,4.969
			c-0.513,1.306-0.828,2.671-0.91,4.083c-0.047,0.852-0.058,1.703-0.058,2.566c-0.012,3.266,0,6.532-0.012,9.798
			c0,0.537,0.105,1.085-0.21,1.656c-0.152,0.07-0.362,0.187-0.572,0.233c-0.222,0.047-0.467,0.035-0.7,0.035
			c-4.666,0-9.332,0-13.998,0c-0.303,0-0.618-0.035-0.852-0.047c-0.373-0.198-0.502-0.455-0.537-0.782
			c-0.012-0.082-0.012-0.152-0.012-0.233c0.012-4.584-0.14-9.18,0.105-13.753c0.187-3.511,0.898-6.929,2.193-10.207
			c2.823-7.185,8.037-11.665,15.456-13.613c2.415-0.63,4.864-1.015,7.372-1.003c1.318,0,2.648-0.023,3.966,0.035
			c3.126,0.14,6.159,0.735,9.098,1.843c5.167,1.948,9.028,5.366,11.606,10.253c1.236,2.356,2.076,4.853,2.601,7.454
			C383.247,370.297,383.375,375.161,382.862,380.072L382.862,380.072z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_2_1_" enable-background="new    ">
        <g id="Shape_1_copy_2">
          <path
            d="M366.481,278.869c0,0.852-0.058,1.715-0.198,2.543c-0.525,3.196-2.391,5.412-5.331,6.532
			c-5.319,2.03-9.962-1.201-11.268-5.307c-0.187-0.583-0.303-1.201-0.35-1.82c-0.07-0.852-0.058-1.703-0.058-2.555
			c0-2.718,0-5.436,0-8.154c0-2.87,0-5.751,0-8.62c0-0.618,0.012-1.248,0.023-1.866c0.035-4.223,4.083-8.107,8.375-8.049
			c2.951,0.035,5.249,1.166,7.045,3.441c0.828,1.05,1.4,2.24,1.551,3.593c0.105,0.852,0.198,1.703,0.21,2.543
			C366.493,267.053,366.493,272.967,366.481,278.869L366.481,278.869z M360.731,261.465c0-0.618-0.035-1.236-0.07-1.855
			c-0.035-0.56-0.303-1.038-0.653-1.458c-0.653-0.77-1.505-1.061-2.485-1.026c-1.551,0.058-2.811,1.225-2.939,2.765
			c-0.047,0.537-0.035,1.085-0.035,1.633c0,5.669,0,11.338,0,17.007c0,0.548-0.012,1.085,0.023,1.633
			c0.082,1.411,1.318,2.858,3.114,2.858c1.598,0,3.009-1.26,3.021-2.905c0.012-0.548,0.023-1.085,0.023-1.633
			c0-2.718,0-5.436,0-8.154C360.742,267.368,360.742,264.416,360.731,261.465L360.731,261.465z M298.85,287.816
			c-0.058,0.047-0.117,0.128-0.187,0.14c-1.388,0.245-2.788,0.082-4.176,0.105c-0.303,0-0.595-0.07-0.887-0.117
			c-0.607-1.365-0.513-2.8-0.852-4.141c-0.315-1.271-0.105-2.66-0.852-3.884c-1.446-0.303-2.928-0.105-4.386-0.14
			c-0.292-0.012-0.595,0.105-0.875,0.163c-0.548,1.376-0.537,2.8-0.852,4.153c-0.292,1.271-0.187,2.636-0.77,3.873
			c-1.703,0.175-3.336,0.128-4.957,0.035c-0.14-0.012-0.268-0.14-0.397-0.21c-0.012-0.152-0.058-0.303-0.035-0.443
			c1.971-11.641,3.943-23.283,5.914-34.924c0.268-0.128,0.467-0.303,0.677-0.303c2.1-0.023,4.199-0.023,6.299-0.012
			c0.14,0,0.28,0.117,0.455,0.187c0.093,0.198,0.245,0.397,0.28,0.618c1.925,11.49,3.838,22.991,5.739,34.492
			C298.99,287.524,298.896,287.676,298.85,287.816L298.85,287.816z M289.494,261.617c-0.257,0.537-0.408,0.723-0.443,0.945
			c-0.56,3.756-1.108,7.524-1.645,11.28c-0.023,0.128,0.07,0.28,0.128,0.478c0.187,0.058,0.397,0.198,0.618,0.198
			c0.922-0.023,1.855,0.21,2.753-0.152c0.07-0.023,0.128-0.093,0.303-0.233C290.778,270.12,290.066,266.073,289.494,261.617
			L289.494,261.617z M256.238,264.253c-1.563,0.175-3.114,0.128-4.654,0.035c-0.14-0.012-0.268-0.128-0.455-0.222
			c-0.047-0.175-0.163-0.385-0.163-0.607c-0.023-1.085-0.035-2.17-0.035-3.266c0-0.642-0.21-1.213-0.548-1.75
			c-1.143-1.785-4.094-1.866-5.261,0.21c-0.152,0.268-0.257,0.572-0.292,0.875c-0.07,0.537-0.093,1.085-0.093,1.621
			c-0.012,5.902,0,11.805,0,17.707c0,0.548,0.035,1.085,0.07,1.621c0.093,1.178,1.33,2.415,2.683,2.485
			c1.47,0.082,2.636-0.595,3.173-1.878c0.152-0.35,0.233-0.747,0.245-1.12c0.047-0.77,0.012-1.551,0.023-2.333
			c0.012-0.77,0.023-1.54,0.035-2.298c0.303-0.222,0.362-0.303,0.432-0.303c1.4-0.035,2.788-0.058,4.188-0.07
			c0.222,0,0.443,0.082,0.7,0.14c0.082,0.21,0.233,0.42,0.233,0.63c0.023,1.551,0.058,3.103,0.012,4.654
			c-0.117,4.689-4.258,8.084-8.317,8.119c-3.021,0.023-5.412-1.143-7.232-3.534c-1.027-1.341-1.505-2.881-1.645-4.538
			c-0.023-0.315-0.023-0.618-0.023-0.933c0-6.287,0-12.586-0.012-18.873c0-1.015,0.082-2.018,0.397-2.986
			c1.015-3.138,3.184-5.062,6.334-5.926c6.626-1.248,10.475,4.013,10.498,8.142c0.012,1.248,0,2.485-0.035,3.721
			C256.507,263.821,256.343,264.02,256.238,264.253L256.238,264.253z M311.074,266.178c0.665,0.653,1.376,1.26,2.088,1.855
			c1.178,1.015,2.345,2.041,3.359,3.231c1.481,1.738,2.683,3.628,3.231,5.867c0.77,3.126,0.21,6.007-1.738,8.597
			c-1.143,1.505-2.73,2.298-4.526,2.636c-1.61,0.303-3.231,0.28-4.853-0.163c-1.551-0.432-2.846-1.248-3.861-2.461
			c-1.715-2.041-2.473-4.433-2.555-7.08c0.28-0.152,0.478-0.327,0.677-0.338c1.236-0.105,2.485-0.187,3.721-0.257
			c0.14-0.012,0.292,0.082,0.502,0.14c0.082,0.163,0.222,0.35,0.28,0.56c0.198,0.665,0.303,1.376,0.56,2.018
			c0.222,0.572,0.56,1.108,0.933,1.598c1.155,1.528,3.359,1.376,4.526,0.187c0.618-0.63,0.991-1.365,1.096-2.263
			c0.187-1.621-0.163-3.103-1.155-4.386c-0.513-0.677-1.12-1.295-1.726-1.89c-1.341-1.295-2.706-2.566-4.059-3.849
			c-1.073-1.026-2.065-2.111-2.905-3.336c-2.135-3.103-2.496-6.497-1.341-10.008c0.828-2.508,2.59-4.246,5.191-4.911
			c2.228-0.572,4.479-0.513,6.602,0.478c2.87,1.353,4.211,3.768,4.573,6.824c0.012,0.07-0.035,0.152-0.035,0.222
			c-0.012,0.14-0.292,0.49-0.432,0.513c-1.143,0.21-2.286,0.432-3.441,0.618c-0.303,0.047-0.607,0.012-1.05,0.012
			c-0.268-0.793-0.455-1.376-0.665-1.948c-0.082-0.222-0.187-0.432-0.303-0.63c-0.408-0.7-0.98-1.166-1.773-1.423
			c-0.922-0.292-2.251,0.07-2.893,1.003c-0.35,0.525-0.618,1.085-0.7,1.703c-0.175,1.248-0.093,2.473,0.432,3.651
			C309.371,264.16,310.118,265.245,311.074,266.178L311.074,266.178z M330.974,263.845c0.595,1.015,1.318,1.925,2.193,2.706
			c0.863,0.782,1.738,1.551,2.625,2.298c1.318,1.108,2.461,2.368,3.464,3.756c1.248,1.726,2.181,3.604,2.426,5.727
			c0.35,2.998-0.408,5.704-2.485,7.955c-1.096,1.19-2.543,1.796-4.083,2.076c-1.61,0.292-3.231,0.28-4.841-0.187
			c-1.796-0.513-3.196-1.528-4.293-3.021c-0.793-1.085-1.353-2.286-1.668-3.569c-0.233-0.968-0.583-1.936-0.42-2.928
			c0.245-0.28,0.537-0.373,0.863-0.385c1.236-0.07,2.485-0.14,3.698-0.21c0.338,0.117,0.525,0.35,0.595,0.677
			c0.128,0.525,0.222,1.061,0.397,1.586c0.268,0.817,0.653,1.563,1.225,2.228c1.12,1.283,3.091,1.085,4.024,0.35
			c0.91-0.712,1.4-1.645,1.54-2.765c0.152-1.201-0.14-2.31-0.7-3.371c-0.443-0.84-1.026-1.551-1.703-2.205
			c-1.295-1.225-2.601-2.45-3.884-3.698c-0.828-0.817-1.668-1.633-2.426-2.508c-0.607-0.7-1.19-1.446-1.656-2.24
			c-1.26-2.135-1.75-4.456-1.411-6.94c0.14-1.085,0.338-2.146,0.84-3.138c1.388-2.706,3.569-4.246,6.637-4.398
			c1.318-0.07,2.625-0.035,3.919,0.385c2.03,0.665,3.523,1.936,4.514,3.791c0.548,1.026,0.921,2.135,1.038,3.313
			c0.023,0.21-0.093,0.432-0.128,0.618c-1.785,0.56-2.671,0.723-4.561,0.84c-0.315-0.152-0.432-0.432-0.537-0.723
			c-0.233-0.653-0.385-1.341-0.77-1.948c-0.49-0.758-1.155-1.26-2.041-1.4c-0.991-0.163-1.866,0.128-2.473,0.945
			c-0.782,1.05-1.026,2.263-0.875,3.534C330.123,262.002,330.449,262.958,330.974,263.845L330.974,263.845z M386.696,257.009
			c-0.268,0.42-0.56,0.525-0.863,0.525c-2.018,0.012-4.036,0.012-6.054,0.023c-0.852,0-1.703,0.023-2.473,0.023
			c-0.198,0.187-0.303,0.28-0.467,0.432c-0.023,0.338-0.082,0.723-0.082,1.108c-0.012,2.251-0.012,4.503,0,6.754
			c0,0.303,0.012,0.63,0.117,0.91c0.07,0.187,0.28,0.408,0.467,0.455c0.373,0.093,0.77,0.093,1.155,0.105
			c1.4,0.012,2.788,0,4.188,0.012c0.303,0,0.618,0.058,0.898,0.082c0.128,0.14,0.303,0.245,0.327,0.373
			c0.187,1.12,0.14,3.103-0.07,4.654c-0.175,0.082-0.373,0.233-0.583,0.245c-1.703,0.035-3.418,0.035-5.121,0.058
			c-0.303,0-0.607,0.058-0.898,0.152c-0.128,0.035-0.268,0.187-0.315,0.303c-0.082,0.292-0.152,0.607-0.163,0.91
			c-0.012,2.1-0.012,4.188-0.012,6.287s0,4.188,0,6.287c0,0.385,0.035,0.782-0.222,1.12c-1.178,0.327-1.796,0.35-4.864,0.163
			c-0.093-0.163-0.268-0.362-0.28-0.56c-0.058-0.537-0.058-1.085-0.058-1.621c0-10.475,0-20.961,0-31.436
			c0-0.618,0.023-1.236,0.047-1.82c0.14-0.152,0.245-0.268,0.35-0.385c1.936-0.14,12.808-0.14,14.651,0
			c0.105,0.117,0.21,0.222,0.338,0.373C386.766,254.058,386.778,255.598,386.696,257.009L386.696,257.009z M275.672,287.372
			c0,0.14-0.105,0.268-0.152,0.408c-0.222,0.28-0.548,0.268-0.852,0.268c-2.718,0.012-5.436,0.012-8.154,0.012
			c-1.4,0-2.799,0.012-4.188-0.012c-0.385,0-0.758-0.058-1.085-0.093c-0.128-0.198-0.257-0.327-0.28-0.467
			c-0.047-0.467-0.07-0.933-0.07-1.388c0-10.72,0-21.44,0-32.148c0-0.467,0.023-0.933,0.082-1.388
			c0.012-0.128,0.175-0.245,0.28-0.362c2.006-0.233,3.348-0.21,4.876,0.082c0.07,0.187,0.187,0.397,0.198,0.607
			c0.035,0.782,0.035,1.551,0.035,2.333c0,8.235,0,16.459,0,24.694c0,0.77-0.093,1.54,0.07,2.135
			c0.303,0.443,0.618,0.478,0.922,0.478c1.866,0.012,3.721,0.012,5.587,0.023c0.77,0,1.551,0.023,2.321,0.035
			c0.233,0.268,0.303,0.315,0.327,0.385c0.047,0.152,0.105,0.303,0.105,0.455C275.695,284.736,275.695,286.054,275.672,287.372
			L275.672,287.372z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_3_1_" enable-background="new    ">
        <g id="Shape_1_copy_3">
          <path
            d="M235.522,274.576c0.035,2.158-0.07,2.24-2.123,2.473c-0.852,0.093-1.703,0.105-2.555,0.105
			c-12.435,0-24.869-0.012-37.292-0.012c-1.248,0-2.485,0.047-3.721,0.07c-0.618,0.012-1.248,0.047-1.866,0.023
			c-4.736-0.198-9.483-0.035-14.219-0.07c-0.933-0.012-1.866-0.012-2.788-0.198c-0.595-0.128-0.98-0.478-1.131-1.038
			c-0.315-1.143-0.338-2.298,0.035-3.441c0.152-0.478,0.478-0.817,0.98-0.898c0.537-0.093,1.085-0.163,1.621-0.152
			c3.651,0.058,7.302-0.21,10.953,0c1.003,0.058,2.018,0.023,3.033,0.023c15.071,0,30.142-0.012,45.224-0.012
			c0.782,0,1.551,0,2.333,0.058c0.56,0.035,1.061,0.257,1.388,0.828C235.627,273.025,235.51,273.806,235.522,274.576
			L235.522,274.576z M454.142,274.786c-0.257,1.843-0.607,2.24-2.496,2.426c-0.688,0.07-1.4,0.035-2.1,0.035
			c-5.832,0-11.653-0.012-17.485,0c-1.4,0-2.8,0.082-4.188,0.105c-1.085,0.023-2.181,0.093-3.254,0
			c-1.47-0.117-2.951-0.117-4.421-0.117c-9.087,0-18.185,0-27.272,0c-0.618,0-1.248,0.012-1.866-0.023
			c-0.7-0.035-1.388-0.128-2.065-0.56c-0.898-1.178-0.922-2.531-0.478-3.931c0.222-0.723,0.747-1.201,1.505-1.295
			c0.77-0.093,1.551-0.093,2.321-0.105c7.617-0.012,15.234-0.012,22.851,0c4.818,0.012,9.635,0.035,14.453,0.058
			c0.152,0,0.315,0.012,0.467,0c4.818-0.233,9.635,0.07,14.453-0.023c2.1-0.035,4.199-0.047,6.287-0.07c0.537,0,1.096,0,1.621,0.07
			c0.653,0.093,1.166,0.467,1.388,1.108C454.107,273.223,454.258,273.993,454.142,274.786L454.142,274.786z"
          />
        </g>
      </g>
      <g id="Shape_1_copy_1_" enable-background="new    ">
        <g id="Shape_1_copy">
          <path
            d="M47.522,423.371c-0.198,1.458-0.303,2.939-0.408,4.409c-0.117,1.54-0.443,3.068-0.677,4.608
			c-0.758,5.179-2.275,10.148-4.188,15.012c-1.061,2.671-2.193,5.307-3.604,7.815c-2.146,3.791-4.561,7.407-7.442,10.685
			c-1.33,1.516-2.73,2.974-4.339,4.211c-1.843,1.423-3.674,2.858-5.646,4.106c-1.656,1.038-3.453,1.773-5.202,2.59
			c-0.688,0.315-1.493,0.443-2.251,0.56c-0.793,0.128-1.528-0.338-1.843-1.108c-0.187-0.432-0.373-0.863-0.478-1.306
			c-0.63-2.485-1.248-4.969-1.668-7.5c-0.268-1.61-0.525-3.219-0.747-4.841c-0.093-0.688-0.128-1.388-0.128-2.088
			c-0.012-2.333,0-4.666-0.012-6.999c0-1.015,0.082-2.018,0.28-3.009c0.257-1.376,0.42-2.765,0.665-4.141
			c0.595-3.289,1.458-6.521,2.578-9.67c0.467-1.318,1.003-2.613,1.703-3.826c0.933-1.621,1.831-3.243,2.788-4.841
			c1.365-2.275,2.998-4.339,4.899-6.194c0.222-0.222,0.443-0.432,0.653-0.665c2.146-2.403,4.748-4.246,7.477-5.891
			c1.12-0.677,2.333-1.236,3.546-1.761c3.219-1.388,6.579-2.345,10.043-2.905c0.782-0.128,1.54-0.128,2.31,0.058
			c0.653,0.163,1.096,0.537,1.353,1.166c0.443,1.096,0.618,2.251,0.677,3.418c0.047,0.933,0.012,1.866,0.012,2.531
			C47.895,419.813,47.755,421.586,47.522,423.371L47.522,423.371z M63.934,306.048c-0.397,0.933-0.793,1.855-1.225,2.765
			c-0.537,1.12-1.096,2.228-1.656,3.336c-1.621,3.184-3.313,6.322-5.482,9.18c-0.747,0.992-1.458,2.018-2.193,3.01
			c-1.108,1.493-2.298,2.928-3.569,4.293c-1.54,1.645-3.009,3.348-4.689,4.853c-1.913,1.703-3.698,3.546-5.786,5.051
			c-3.849,2.765-7.804,5.366-12.19,7.232c-3.219,1.376-6.532,2.473-9.997,3.056c-0.537,0.093-1.085,0.152-1.598,0.303
			c-1.89,0.548-3.826,0.63-5.774,0.607c-0.933-0.035-1.866-0.082-2.788-0.117c-0.957-0.047-1.831-0.338-2.66-0.782
			c-0.747-0.397-1.131-1.108-0.991-1.925c0.163-0.991,0.315-1.995,0.548-2.974c0.665-2.8,1.318-5.599,2.368-8.282
			c1.446-3.686,2.986-7.325,5.016-10.743c1.271-2.146,2.66-4.199,4.269-6.089c0.198-0.233,0.408-0.467,0.583-0.723
			c1.936-2.858,4.456-5.144,7.104-7.337c2.461-2.041,5.144-3.733,7.85-5.389c0.525-0.327,1.108-0.56,1.668-0.828
			c3.103-1.493,6.357-2.52,9.728-3.126c2.053-0.362,4.164-0.443,6.241-0.677c0.688-0.082,1.4,0.035,2.088,0.105
			c1.155,0.105,2.31,0.292,3.476,0.373c1.96,0.14,3.814,0.712,5.681,1.213c0.677,0.175,1.341,0.408,1.983,0.677
			c0.572,0.233,1.131,0.525,1.668,0.828c0.28,0.152,0.408,0.455,0.467,0.77C64.144,305.138,64.121,305.604,63.934,306.048
			L63.934,306.048z M90.203,258.829c-0.093,0.21-0.233,0.408-0.373,0.583c-1.656,2.146-3.336,4.281-5.226,6.252
			c-2.263,2.356-4.608,4.619-6.975,6.859c-1.575,1.493-3.266,2.87-5.062,4.094c-1.866,1.26-3.756,2.485-5.634,3.721
			c-0.07,0.047-0.128,0.082-0.198,0.128c-3.196,1.75-6.416,3.476-9.81,4.818c-1.085,0.432-2.181,0.828-3.266,1.236
			c-2.695,1.026-5.517,1.598-8.317,2.24c-0.607,0.14-1.236,0.14-1.855,0.21c-0.537,0.058-1.096,0.058-1.621,0.198
			c-1.68,0.432-3.383,0.455-5.226,0.42c-1.656,0.082-3.418-0.152-5.191-0.443c-1.913-0.315-3.826-0.63-5.704-1.166
			c-0.677-0.198-1.341-0.397-1.995-0.642c-0.432-0.163-0.863-0.385-1.236-0.642c-0.688-0.49-1.073-1.131-0.817-2.006
			c0.128-0.443,0.222-0.91,0.42-1.318c0.957-1.948,1.878-3.931,2.951-5.809c2.076-3.651,4.421-7.139,7.22-10.265
			c3.429-3.814,7.104-7.395,11.501-10.113c1.458-0.898,2.87-1.843,4.351-2.706c0.933-0.548,1.925-0.992,2.916-1.423
			c2.706-1.19,5.529-2.065,8.387-2.823c0.303-0.082,0.595-0.187,0.898-0.233c1.843-0.303,3.674-0.63,5.517-0.863
			c2.1-0.268,4.188-0.14,6.264,0.257c0.537,0.105,1.061,0.245,1.598,0.292c4.316,0.42,8.305,1.866,12.12,3.884
			c0.887,0.467,1.785,0.956,2.636,1.481c0.455,0.28,0.875,0.642,1.271,1.003C90.623,256.799,90.623,257.942,90.203,258.829
			L90.203,258.829z M557.339,564.129c-0.28,0.548-0.63,1.073-1.015,1.551c-0.77,0.968-1.633,1.878-2.403,2.846
			c-1.983,2.52-4.304,4.678-6.824,6.649c-1.341,1.05-2.66,2.146-4.001,3.208c-0.42,0.338-0.887,0.63-1.33,0.933
			c-1.096,0.735-2.216,1.411-3.324,2.146c-2.006,1.33-4.234,2.275-6.451,3.196c-2.87,1.201-5.832,2.135-8.853,2.87
			c-2.718,0.665-5.471,1.178-8.294,1.096c-1.003-0.035-2.018-0.047-3.033,0.012c-2.426,0.117-4.806-0.292-7.174-0.723
			c-0.84-0.152-1.656-0.432-2.473-0.677c-0.665-0.21-1.33-0.432-1.983-0.688c-1.096-0.432-2.216-0.723-3.394-0.782
			c-1.225-0.058-2.251-0.548-3.091-1.435c-0.7-0.735-1.505-1.33-2.321-1.936c-2.065-1.528-3.884-3.348-5.716-5.132
			c-0.443-0.432-0.852-0.922-1.166-1.446c-0.478-0.805-0.397-1.633,0.152-2.403c0.408-0.583,0.991-0.98,1.54-1.411
			c0.98-0.77,2.006-1.47,2.974-2.251c2.368-1.901,4.934-3.511,7.57-4.992c3.861-2.17,7.792-4.199,11.98-5.681
			c2.998-1.062,6.066-1.936,9.145-2.73c2.94-0.758,5.949-1.12,8.935-1.633c0.758-0.128,1.551-0.14,2.321-0.152
			c1.085-0.023,2.17-0.012,3.266-0.012l0,0c0.467,0,0.933,0.012,1.4,0c2.181-0.07,4.328,0.163,6.486,0.548
			c1.995,0.35,3.954,0.817,5.891,1.376c3.453,0.992,6.661,2.543,9.752,4.374c0.327,0.198,0.607,0.478,0.91,0.735
			C557.584,562.193,557.736,563.359,557.339,564.129L557.339,564.129z M608.139,445.581c-0.058,0.537-0.14,1.085-0.257,1.61
			c-0.922,4.176-2.006,8.305-3.709,12.236c-1.236,2.846-2.566,5.657-4.153,8.329c-0.84,1.4-1.761,2.741-2.741,4.048
			c-1.68,2.228-3.394,4.433-5.436,6.357c-1.015,0.957-2.006,1.936-3.021,2.905c-0.338,0.315-0.688,0.63-1.061,0.91
			c-3.231,2.438-6.591,4.654-10.312,6.276c-1.855,0.805-3.768,1.423-5.727,1.936c-1.586,0.42-3.173,0.747-4.806,0.828
			c-0.467,0.023-0.933,0.105-1.376,0.222c-1.143,0.28-2.31,0.327-3.476,0.373c-1.003-0.128-2.006-0.245-2.998-0.373
			c-0.537-0.07-1.073-0.187-1.61-0.257c-2.625-0.35-5.179-1.003-7.675-1.89c-0.432-0.152-0.852-0.385-1.225-0.653
			c-0.7-0.49-0.98-1.213-0.805-2.041c0.128-0.607,0.338-1.201,0.572-1.773c1.54-3.744,3.511-7.267,5.657-10.697
			c1.026-1.645,2.205-3.196,3.266-4.818c1.726-2.601,3.709-4.969,5.891-7.197c0.712-0.723,1.4-1.458,2.111-2.17
			c3.523-3.534,7.384-6.637,11.653-9.238c1.458-0.887,2.893-1.808,4.398-2.59c3.033-1.575,6.194-2.846,9.518-3.744
			c1.808-0.478,3.628-0.84,5.471-1.108c0.688-0.105,1.376-0.222,2.065-0.385c2.076-0.478,4.164-0.432,6.252-0.152
			c0.933,0.128,1.808,0.432,2.66,0.828c0.548,0.245,0.805,0.723,0.887,1.283C608.174,444.963,608.174,445.277,608.139,445.581
			L608.139,445.581z M126.597,219.321c-0.84,0.688-1.726,1.318-2.601,1.96c-0.817,0.595-1.645,1.166-2.461,1.761
			c-3.324,2.45-6.801,4.619-10.522,6.416c-1.26,0.607-2.473,1.306-3.768,1.843c-1.936,0.805-3.908,1.528-5.867,2.24
			c-1.388,0.502-2.776,1.015-4.199,1.388c-2.555,0.653-5.086,1.4-7.71,1.785c-0.84,0.128-1.691,0.28-2.508,0.49
			c-0.992,0.257-1.983,0.35-2.998,0.35c-1.551,0-3.103,0-4.666,0l0,0c-1.551,0-3.103-0.012-4.666,0
			c-0.933,0-1.855-0.082-2.776-0.292c-1.131-0.268-2.275-0.525-3.406-0.747c-2.065-0.408-4.036-1.108-6.019-1.785
			c-2.52-0.863-4.794-2.181-7.045-3.558c-0.478-0.292-0.852-0.665-1.131-1.143c-0.28-0.502-0.338-1.026-0.187-1.575
			c0.222-0.758,0.63-1.423,1.155-1.995c1.831-2.006,3.534-4.129,5.517-5.996c1.645-1.54,3.301-3.068,4.969-4.573
			c1.388-1.248,3.009-2.181,4.514-3.278c2.52-1.843,5.296-3.208,8.13-4.503c3.196-1.446,6.474-2.601,9.857-3.488
			c0.817-0.21,1.668-0.327,2.52-0.467c0.537-0.093,1.096-0.082,1.61-0.21c1.75-0.443,3.534-0.49,5.319-0.478
			c1.015,0.012,2.018,0,3.033,0c1.166,0,2.321,0.163,3.476,0.338c2.473,0.373,4.864,0.968,7.209,1.843
			c0.572,0.21,1.213,0.245,1.82,0.385c0.91,0.21,1.831,0.397,2.566,1.026c0.175,0.152,0.397,0.257,0.607,0.35
			c1.61,0.782,3.056,1.831,4.479,2.905c0.875,0.653,1.715,1.341,2.473,2.111c0.98,1.003,1.96,1.995,2.939,2.998
			c0.443,0.455,0.782,0.968,0.945,1.586C127.472,217.886,127.343,218.714,126.597,219.321L126.597,219.321z M582.313,286.824
			c-0.012,1.085-0.012,2.181,0,3.266c0.012,1.481-0.035,2.951-0.385,4.398c-0.105,0.455-0.14,0.922-0.198,1.388
			c-0.408,3.418-1.283,6.731-2.543,9.915c-0.747,1.866-1.715,3.651-2.683,5.424c-1.528,2.811-3.383,5.389-5.681,7.629
			c-0.56,0.537-1.096,1.096-1.645,1.645c-0.875,0.887-1.866,1.621-2.858,2.38c-2.625,2.006-5.587,3.336-8.574,4.666
			c-0.42,0.187-0.875,0.35-1.318,0.455c-0.373,0.093-0.77,0.105-1.155,0.105c-0.583,0-1.062-0.257-1.365-0.758
			c-0.198-0.327-0.397-0.677-0.525-1.038c-0.28-0.805-0.56-1.621-0.77-2.45c-0.747-3.021-1.481-6.042-1.96-9.11
			c-0.292-1.843-0.525-3.698-0.747-5.541c-0.105-0.852-0.128-1.703-0.14-2.555c-0.023-1.481,0-2.951,0-4.433c0-0.7,0.012-1.4,0-2.1
			c-0.058-2.578,0.175-5.109,0.572-7.664c0.338-2.228,0.653-4.456,1.131-6.661c0.502-2.275,1.05-4.549,1.866-6.742
			c1.96-5.261,4.293-10.347,7.874-14.733c2.263-2.776,4.713-5.377,7.675-7.43c0.572-0.397,1.178-0.747,1.785-1.096
			c0.817-0.478,1.761-0.175,2.345,0.408c0.222,0.222,0.42,0.467,0.583,0.723c0.245,0.397,0.455,0.805,0.677,1.225
			c1.82,3.359,3.324,6.847,4.654,10.417c0.513,1.376,0.91,2.811,1.283,4.234c0.408,1.575,0.758,3.173,1.096,4.771
			c0.175,0.84,0.268,1.68,0.397,2.531c0.07,0.537,0.07,1.096,0.198,1.621C582.302,283.406,582.325,285.11,582.313,286.824
			L582.313,286.824z M458.073,647.45c-0.467,0.28-0.98,0.478-1.458,0.723c-2.578,1.295-5.249,2.31-8.014,3.126
			c-2.391,0.7-4.759,1.423-7.22,1.878c-1.458,0.268-2.905,0.502-4.386,0.583c-0.852,0.047-1.691,0.222-2.531,0.327
			c-0.922,0.117-1.843,0.268-2.776,0.303c-1.318,0.058-2.648,0.023-3.966,0.012c-0.548-0.012-1.096-0.012-1.621-0.117
			c-2.66-0.56-5.389-0.7-8.049-1.33c-2.496-0.583-4.946-1.341-7.36-2.216c-2.275-0.817-4.293-2.158-6.381-3.371
			c-1.551-0.898-2.986-1.96-4.374-3.091c-1.621-1.33-3.184-2.73-4.409-4.468c-0.758-1.085-1.738-1.971-2.473-3.091
			c-2.275-3.476-3.861-7.255-5.051-11.221c-0.105-0.373-0.163-0.758-0.198-1.143c-0.07-0.688,0.245-1.201,0.898-1.47
			c0.28-0.117,0.583-0.222,0.875-0.292c0.828-0.198,1.656-0.432,2.496-0.572c1.843-0.303,3.651-0.735,5.517-0.863
			c0.537-0.035,1.085-0.093,1.621-0.187c2.228-0.408,4.468-0.595,6.73-0.537c0.852,0.023,1.715,0,2.566,0
			c0.618,0,1.248,0.012,1.866,0c2.496-0.058,4.969,0.093,7.419,0.56c1.295,0.245,2.613,0.327,3.919,0.537
			c3.231,0.502,6.357,1.47,9.53,2.228c0.455,0.105,0.887,0.292,1.33,0.443c4.129,1.411,8.037,3.324,11.793,5.529
			c2.076,1.225,4.001,2.683,5.821,4.258c0.712,0.607,1.388,1.237,2.088,1.855c0.117,0.105,0.245,0.198,0.338,0.315
			c1.936,2.228,3.978,4.374,5.471,6.94c0.35,0.595,0.642,1.248,0.887,1.89C459.309,645.887,458.889,646.972,458.073,647.45
			L458.073,647.45z M49.178,359.822c-0.782,4.129-1.738,8.212-3.149,12.166c-0.782,2.193-1.575,4.374-2.613,6.474
			c-0.887,1.808-1.621,3.698-2.613,5.459c-1.026,1.831-2.076,3.639-3.289,5.354c-0.945,1.33-1.808,2.718-2.788,4.024
			c-0.828,1.12-1.75,2.181-2.636,3.254c-0.537,0.653-1.096,1.306-1.645,1.96c-1.353,1.61-2.835,3.091-4.526,4.351
			c-0.805,0.607-1.621,1.201-2.38,1.866c-1.773,1.528-3.709,2.811-5.797,3.884c-1.516,0.793-3.009,1.598-4.584,2.298
			c-2.788,1.236-5.669,2.135-8.69,2.555c-0.77,0.105-1.528,0.268-2.31,0.35c-0.922,0.105-1.633-0.443-1.901-1.365
			c-0.198-0.677-0.28-1.365-0.28-2.076c0-1.551,0-3.103,0-4.818c0.07-1.551,0.14-3.254,0.245-4.969
			c0.128-2.1,0.56-4.153,0.991-6.206c0.618-2.893,1.4-5.739,2.391-8.527c0.712-1.971,1.505-3.919,2.473-5.786
			c2.146-4.153,4.608-8.095,7.78-11.571c1.995-2.193,4.281-4.059,6.637-5.832c2.508-1.878,5.261-3.301,8.13-4.503
			c2.368-0.991,4.818-1.738,7.384-2.065c0.537-0.07,1.061-0.21,1.598-0.303c2.695-0.467,5.412-0.502,8.13-0.222
			c0.782,0.082,1.528,0.257,2.251,0.583c0.863,0.397,1.318,1.073,1.33,2.018C49.353,358.737,49.283,359.285,49.178,359.822
			L49.178,359.822z M171.973,188.293c-0.828,0.723-1.843,1.143-2.846,1.551c-2.508,1.038-5.016,2.1-7.559,3.056
			c-1.446,0.548-2.905,1.085-4.433,1.4c-1.365,0.28-2.695,0.735-4.059,1.038c-2.275,0.513-4.584,0.817-6.871,1.283
			c-1.073,0.222-2.146,0.327-3.243,0.315c-1.481,0-2.939,0.117-4.409,0.373c-1.925,0.338-3.873,0.338-5.797,0.012
			c-1.306-0.222-2.613-0.362-3.943-0.373c-1.33,0-2.636-0.187-3.931-0.478c-0.303-0.07-0.607-0.163-0.91-0.198
			c-2.963-0.28-5.751-1.283-8.574-2.135c-1.108-0.338-2.17-0.852-3.231-1.33c-0.992-0.455-1.936-0.992-2.916-1.458
			c-3.488-1.656-6.427-4.048-9.203-6.672c-0.455-0.42-0.863-0.898-1.225-1.4c-0.56-0.77-0.537-1.598-0.093-2.415
			c0.187-0.338,0.42-0.653,0.7-0.922c0.385-0.373,0.817-0.723,1.26-1.038c3.208-2.333,6.579-4.409,9.997-6.416
			c0.863-0.513,1.82-0.863,2.73-1.295c3.464-1.61,7.104-2.718,10.743-3.838c1.108-0.338,2.251-0.607,3.406-0.747
			c1.085-0.128,2.158-0.303,3.219-0.537c0.992-0.222,1.995-0.303,3.009-0.315c2.181,0,4.351,0,6.532,0
			c1.166,0,2.333,0.093,3.464,0.408c0.373,0.105,0.758,0.21,1.143,0.257c2.333,0.28,4.549,0.968,6.754,1.715
			c5.156,1.738,9.717,4.444,13.648,8.224c2.426,2.333,4.433,4.981,6.194,7.827c0.28,0.455,0.502,0.956,0.735,1.446
			C172.708,186.601,172.614,187.476,171.973,188.293L171.973,188.293z M61.636,497.104c0,0.233,0,0.467-0.047,0.7
			c-0.443,2.205-0.56,4.468-1.085,6.661c-0.408,1.738-0.735,3.488-1.213,5.214c-0.455,1.656-0.945,3.289-1.586,4.876
			c-0.642,1.586-1.248,3.185-1.983,4.724c-2.683,5.611-6.416,10.44-11.116,14.499c-1.12,0.968-2.286,1.866-3.628,2.52
			c-0.793,0.385-1.505,0.408-2.345-0.373c-0.443-0.42-0.793-0.945-1.131-1.47c-0.257-0.385-0.432-0.817-0.642-1.237
			c-1.131-2.38-2.426-4.701-3.301-7.197c-0.467-1.318-0.933-2.636-1.376-3.954c-0.292-0.887-0.548-1.785-0.84-2.671
			c-0.292-0.887-0.525-1.785-0.653-2.718c-0.082-0.618-0.21-1.225-0.362-1.831c-0.28-1.131-0.478-2.275-0.548-3.453
			c-0.035-0.618-0.105-1.236-0.233-1.843c-0.315-1.458-0.42-2.916-0.408-4.398c0.012-0.933-0.012-1.866,0-2.8
			c0.012-0.618,0-1.248,0.082-1.866c0.268-1.925,0.537-3.849,0.875-5.751c0.245-1.376,0.537-2.753,0.922-4.094
			c0.56-1.936,1.213-3.849,2.03-5.704c1.131-2.566,2.415-5.051,4.071-7.314c1.505-2.076,3.009-4.164,4.981-5.821
			c3.394-2.858,6.975-5.436,11.233-6.94c0.443-0.152,0.863-0.35,1.295-0.513c0.292-0.105,0.583-0.21,0.887-0.268
			c1.05-0.233,2.076,0.28,2.461,1.295c0.385,1.015,0.735,2.053,1.015,3.103c0.898,3.383,1.68,6.777,2.065,10.265
			c0.023,0.152,0.023,0.315,0.058,0.467c0.56,2.135,0.548,4.304,0.56,6.486c0.012,1.4,0.082,2.8,0.117,3.931
			C61.753,492.286,61.695,494.701,61.636,497.104L61.636,497.104z M611.813,386.779c-0.338,1.995-0.595,4.001-0.607,6.019
			c0,0.537-0.082,1.085-0.175,1.621c-0.84,4.433-1.866,8.819-3.523,13.029c-1.283,3.254-2.776,6.404-4.689,9.343
			c-1.528,2.345-3.091,4.654-4.981,6.731c-1.936,2.135-4.036,4.083-6.427,5.704c-1.47,1.003-2.951,2.006-4.514,2.881
			c-1.901,1.061-3.908,1.878-5.961,2.601c-1.995,0.7-4.059,1.026-6.101,1.516c-0.817,0.198-1.691,0.21-2.543,0.245
			c-1.003,0.035-2.018,0.012-3.033,0.012c0,0.012,0,0.023,0,0.035c-1.47-0.035-2.951-0.058-4.433-0.117
			c-0.712-0.023-1.365-0.268-1.901-0.77c-0.35-0.327-0.583-0.712-0.653-1.201c-0.105-0.7-0.035-1.388,0.105-2.088
			c0.782-4.211,1.855-8.352,3.254-12.388c1.715-4.922,3.814-9.67,6.602-14.103c1.201-1.913,2.391-3.814,3.663-5.669
			c1.365-1.995,2.87-3.861,4.526-5.622c0.688-0.735,1.283-1.586,2.041-2.228c2.065-1.761,3.908-3.791,6.112-5.389
			c3.021-2.181,6.159-4.176,9.612-5.657c2.729-1.166,5.541-2.03,8.457-2.52c0.77-0.128,1.54-0.198,2.31-0.268
			c0.303-0.023,0.63-0.023,0.922,0.035c0.642,0.128,1.155,0.443,1.4,1.096c0.373,0.945,0.595,1.936,0.653,2.951
			C612.035,383.991,612.047,385.391,611.813,386.779L611.813,386.779z M589.44,508.675c-0.817,1.668-1.598,3.371-2.485,5.004
			c-1.551,2.87-3.313,5.622-5.319,8.2c-2.403,3.056-4.992,5.926-7.92,8.504c-1.808,1.598-3.674,3.103-5.669,4.444
			c-2.578,1.726-5.226,3.324-8.107,4.526c-3.173,1.318-6.439,2.251-9.798,2.951c-2.286,0.478-4.596,0.782-6.287,0.665
			c-5.949,0.047-10.988-1.143-15.817-3.219c-1.843-0.793-3.651-1.726-5.319-2.881c-0.513-0.362-0.98-0.77-1.295-1.318
			c-0.327-0.572-0.443-1.178-0.163-1.785c0.222-0.49,0.467-0.992,0.782-1.411c1.878-2.473,3.756-4.946,6.019-7.104
			c0.898-0.863,1.75-1.761,2.636-2.636c1.213-1.201,2.415-2.415,3.849-3.371c0.443-0.303,0.852-0.677,1.237-1.05
			c1.505-1.481,3.254-2.636,4.992-3.814c1.54-1.038,3.161-1.96,4.724-2.963c2.566-1.633,5.331-2.87,8.107-4.071
			c1.353-0.583,2.718-1.143,4.106-1.621c1.54-0.537,3.091-1.085,4.678-1.411c2.275-0.455,4.514-1.178,6.87-1.213
			c0.618-0.012,1.225-0.175,1.831-0.303c2.391-0.502,4.783-0.537,7.162,0.035c0.455,0.105,0.922,0.245,1.376,0.245
			c2.123,0,4.141,0.642,6.171,1.143c0.98,0.233,1.925,0.607,2.846,1.015c0.758,0.338,1.132,1.027,1.143,1.878
			C589.814,507.66,589.685,508.174,589.44,508.675L589.44,508.675z M396.705,669.59c-1.05,0.257-2.123,0.502-3.184,0.677
			c-1.761,0.292-3.534,0.548-5.296,0.782c-0.688,0.093-1.4,0.082-2.1,0.093c-1.47,0.012-2.951,0-4.433,0
			c-0.618,0-1.248-0.012-1.866,0c-5.354,0.163-10.498-0.98-15.584-2.426c-1.26-0.362-2.473-0.921-3.709-1.388
			c-2.835-1.085-5.599-2.321-8.13-4.013c-1.936-1.295-3.873-2.59-5.552-4.211c-0.618-0.595-1.318-1.096-1.925-1.68
			c-0.677-0.642-1.33-1.306-1.925-2.018c-2.765-3.289-5.097-6.847-6.672-10.86c-1.027-2.613-1.773-5.307-2.193-8.084
			c-0.245-1.621-0.187-3.254-0.105-4.888c0.023-0.455,0.163-0.922,0.338-1.341c0.245-0.63,0.747-1.038,1.423-1.026
			c1.085,0.012,2.181,0.023,3.254,0.198c1.843,0.292,3.698,0.467,5.564,0.537c0.922,0.035,1.855,0.175,2.776,0.292
			c0.922,0.117,1.843,0.28,2.765,0.42c0.385,0.058,0.77,0.093,1.155,0.175c3.931,0.91,7.862,1.866,11.676,3.196
			c2.415,0.84,4.748,1.913,7.069,2.986c1.691,0.782,3.289,1.738,4.934,2.613c1.925,1.026,3.779,2.158,5.494,3.499
			c2.87,2.251,5.786,4.456,8.235,7.185c1.715,1.901,3.231,3.978,4.654,6.112c1.866,2.799,3.219,5.832,4.304,9.005
			c0.257,0.747,0.408,1.493,0.42,2.286C398.104,668.645,397.626,669.368,396.705,669.59L396.705,669.59z M275.765,162.875
			c-0.688,0.093-1.4,0.012-2.1,0.012c-1.248,0.082-2.485-0.023-3.709-0.28c-0.758-0.163-1.54-0.28-2.31-0.327
			c-4.759-0.303-9.355-1.446-13.951-2.613c-3.476-0.875-6.836-2.065-10.09-3.569c-3.033-1.4-5.984-2.975-8.9-4.596
			c-0.817-0.443-1.563-1.015-2.31-1.563c-2.438-1.796-4.864-3.628-7.127-5.634c-0.688-0.618-1.318-1.318-1.925-2.018
			c-1.073-1.225-2.1-2.496-3.161-3.733c-0.968-1.12-1.773-2.356-2.52-3.639c-1.54-2.613-2.916-5.307-3.814-8.235
			c-0.233-0.758-0.362-1.505-0.338-2.286c0.023-0.677,0.35-1.19,0.933-1.516c0.338-0.187,0.7-0.373,1.061-0.443
			c1.831-0.373,3.628-0.887,5.506-0.933c0.618-0.012,1.236-0.152,1.831-0.292c1.061-0.268,2.146-0.373,3.231-0.373
			c2.485,0,4.981,0,7.465,0c1.015,0,2.018,0.082,2.998,0.362c0.373,0.105,0.758,0.21,1.143,0.233
			c1.785,0.152,3.534,0.49,5.284,0.887c2.275,0.513,4.514,1.12,6.707,1.96c2.765,1.05,5.447,2.275,8.014,3.733
			c3.126,1.761,5.984,3.884,8.515,6.462c2.018,2.053,3.966,4.164,5.611,6.544c0.443,0.642,0.875,1.271,1.19,1.995
			c0.408,0.922,0.875,1.82,1.353,2.706c0.922,1.726,1.528,3.569,2.065,5.436c0.268,0.898,0.49,1.808,0.7,2.706
			c0.128,0.525,0.268,1.061,0.303,1.598c0.105,1.551,0.187,3.103,0.467,4.643c0.012,0.07,0.012,0.152,0.023,0.233
			C278.04,161.907,277.002,162.712,275.765,162.875L275.765,162.875z M222.551,169.302c-0.63,0.303-1.295,0.502-1.995,0.618
			c-2.146,0.362-4.258,0.875-6.439,1.026c-0.303,0.023-0.618,0.082-0.922,0.152c-1.446,0.35-2.916,0.42-4.398,0.42
			c-1.866,0-3.733,0-5.587,0c-1.948,0-3.884,0-5.821,0c-1.318,0-2.636-0.07-3.931-0.385c-0.303-0.07-0.607-0.14-0.91-0.163
			c-2.8-0.233-5.517-0.91-8.247-1.516c-3.114-0.688-6.112-1.75-9.04-2.998c-0.642-0.28-1.295-0.525-1.925-0.817
			c-0.991-0.455-1.983-0.898-2.939-1.4c-2.974-1.54-5.739-3.394-8.329-5.517c-0.595-0.49-1.236-0.933-1.831-1.446
			c-0.583-0.513-1.166-1.026-1.668-1.621c-1.341-1.61-2.671-3.231-3.954-4.887c-0.665-0.863-1.19-1.808-1.773-2.73
			c-0.082-0.128-0.128-0.28-0.187-0.432c-0.502-1.33-0.093-2.473,1.143-3.173c0.268-0.152,0.548-0.303,0.828-0.42
			c2.59-1.05,5.121-2.205,7.792-3.068c3.779-1.213,7.652-2.041,11.583-2.578c0.455-0.058,0.933-0.058,1.388-0.105
			c1.085-0.105,2.17-0.187,3.243-0.327c2.251-0.292,4.491-0.362,6.719,0.105c0.525,0.117,1.073,0.198,1.61,0.21
			c2.426,0.047,4.771,0.572,7.127,1.073c2.905,0.618,5.669,1.633,8.399,2.753c1.586,0.653,2.986,1.645,4.491,2.461
			c0.478,0.257,0.922,0.572,1.376,0.863c2.1,1.341,4.036,2.881,5.739,4.724c2.006,2.17,4.001,4.339,5.412,6.964
			c1.388,2.601,2.8,5.202,3.628,8.049c0.222,0.747,0.385,1.505,0.502,2.263C223.776,168.218,223.309,168.941,222.551,169.302
			L222.551,169.302z M512.733,611.301c-0.397,0.688-0.921,1.248-1.563,1.715c-0.817,0.583-1.645,1.178-2.473,1.738
			c-2.835,1.913-5.669,3.814-8.749,5.331c-3.348,1.645-6.777,3.091-10.405,3.989c-0.525,0.128-1.05,0.292-1.563,0.455
			c-1.481,0.478-2.975,0.887-4.526,1.05c-0.233,0.023-0.467,0.058-0.688,0.117c-2.205,0.537-4.444,0.653-6.707,0.595
			c-0.933-0.023-1.866-0.035-2.8,0c-3.616,0.163-7.127-0.513-10.58-1.423c-5.004-1.33-9.612-3.534-13.706-6.731
			c-2.275-1.773-4.328-3.803-6.147-6.019c-1.283-1.551-2.263-3.336-3.266-5.086c-0.187-0.338-0.303-0.712-0.42-1.085
			c-0.233-0.735,0.047-1.598,0.688-2.088c0.245-0.187,0.502-0.362,0.77-0.513c1.365-0.735,2.776-1.4,4.199-1.995
			c1.645-0.7,3.301-1.376,4.969-2.006c1.306-0.49,2.636-0.933,3.978-1.306c3.441-0.945,6.917-1.761,10.463-2.298
			c2.776-0.42,5.541-0.712,8.352-0.665c1.166,0.012,2.333,0,3.499,0c0-0.023,0-0.047,0-0.07c2.251,0.047,4.514,0.023,6.754,0.152
			c2.403,0.14,4.794,0.397,7.115,1.085c1.785,0.525,3.604,0.933,5.389,1.493c3.056,0.968,5.902,2.345,8.69,3.896
			c1.773,0.992,3.394,2.181,4.899,3.534c0.863,0.77,1.761,1.528,2.601,2.321c0.443,0.42,0.863,0.898,1.213,1.4
			C513.247,609.668,513.212,610.496,512.733,611.301L512.733,611.301z M602.493,340.062c0,1.4-0.14,2.788-0.443,4.164
			c-0.385,1.75-0.688,3.511-1.085,5.249c-0.98,4.269-2.52,8.305-4.678,12.131c-1.96,3.476-4.293,6.661-7.104,9.483
			c-2.928,2.94-6.229,5.389-10.043,7.08c-0.782,0.35-1.563,0.677-2.333,1.038c-1.983,0.922-4.118,1.4-6.206,1.983
			c-1.563,0.443-3.231,0.455-4.864,0.268c-0.583-0.07-1.038-0.385-1.225-0.945c-0.163-0.513-0.315-1.05-0.362-1.575
			c-0.093-1.003-0.152-2.018-0.128-3.021c0.047-3.348,0.117-6.684,0.187-10.032c0-0.233,0-0.467,0.058-0.7
			c0.583-2.741,0.735-5.541,1.388-8.27c0.688-2.87,1.458-5.727,2.461-8.504c0.257-0.735,0.502-1.47,0.735-2.205
			c0.432-1.33,0.957-2.625,1.54-3.896c1.866-4.024,3.966-7.92,6.567-11.525c0.817-1.131,1.68-2.24,2.59-3.301
			c1.167-1.353,2.356-2.695,3.639-3.931c2.018-1.948,4.211-3.674,6.602-5.144c0.525-0.327,1.073-0.63,1.598-0.956
			c1.656-1.038,3.453-1.761,5.296-2.403c0.292-0.105,0.595-0.187,0.898-0.245c0.793-0.152,1.61,0.21,1.971,0.922
			c0.21,0.408,0.397,0.84,0.525,1.295c0.688,2.555,1.353,5.109,1.761,7.722c0.082,0.537,0.175,1.073,0.28,1.61
			c0.28,1.376,0.408,2.765,0.408,4.176c0,1.551,0,3.114,0,4.666C602.493,336.796,602.505,338.429,602.493,340.062L602.493,340.062z
			 M122.503,556.792c-0.152,0.758-0.653,1.225-1.388,1.4c-0.303,0.07-0.618,0.105-0.922,0.117c-0.385,0.023-0.782,0-1.166,0
			c-1.33-0.058-2.636-0.245-3.931-0.513c-0.233-0.047-0.455-0.105-0.688-0.14c-3.604-0.688-7.034-1.901-10.3-3.581
			c-1.446-0.747-2.893-1.493-4.316-2.298c-2.181-1.225-4.059-2.858-5.949-4.468c-0.35-0.303-0.688-0.63-1.061-0.91
			c-1.493-1.131-2.846-2.403-3.919-3.954c-0.222-0.315-0.49-0.595-0.77-0.875c-1.295-1.26-2.333-2.706-3.289-4.211
			c-1.493-2.356-3.009-4.701-4.211-7.232c-1.446-3.033-2.52-6.182-3.301-9.437c-0.175-0.758-0.315-1.516-0.513-2.275
			c-0.35-1.365-0.513-2.753-0.513-4.153c0-2.87,0-5.751,0-8.62c0-1.645,0.198-3.254,0.758-4.806c0.385-1.05,1.318-1.54,2.415-1.236
			c0.443,0.128,0.91,0.233,1.33,0.432c1.89,0.91,3.884,1.575,5.692,2.671c0.595,0.362,1.248,0.63,1.866,0.956
			c3.301,1.761,6.299,3.954,9.273,6.194c0.432,0.327,0.828,0.7,1.236,1.061c0.992,0.863,1.983,1.738,2.974,2.601
			c1.05,0.922,2.041,1.913,2.928,2.998c0.443,0.537,0.852,1.108,1.353,1.598c1.831,1.82,3.289,3.919,4.783,6.007
			c2.823,3.931,5.016,8.2,6.929,12.633c2.228,5.179,3.616,10.568,4.538,16.097c0.152,0.922,0.198,1.855,0.257,2.776
			C122.619,556.01,122.573,556.407,122.503,556.792L122.503,556.792z M535.585,284.281c-0.14,1.388-0.245,2.788-0.432,4.176
			c-0.14,0.992-0.408,1.971-0.63,2.963c-0.035,0.152-0.117,0.292-0.198,0.42c-0.362,0.56-0.852,0.922-1.54,0.852
			c-0.455-0.047-0.922-0.152-1.353-0.315c-2.765-1.038-5.424-2.31-8.025-3.709c-3.441-1.855-6.521-4.223-9.658-6.497
			c-0.058-0.047-0.128-0.093-0.187-0.14c-2.496-2.321-5.144-4.503-7.36-7.127c-1.551-1.843-3.231-3.569-4.584-5.576
			c-1.213-1.796-2.508-3.558-3.674-5.389c-1.33-2.111-2.391-4.363-3.383-6.637c-1.05-2.426-1.971-4.899-2.87-7.372
			c-0.42-1.166-0.653-2.403-0.992-3.593c-0.7-2.555-1.19-5.144-1.656-7.745c-0.14-0.758-0.152-1.551-0.128-2.321
			c0.047-1.621,0.677-2.321,2.24-2.368c1.085-0.035,2.181,0,3.254,0.175c3.313,0.525,6.532,1.376,9.682,2.531
			c1.971,0.723,3.814,1.726,5.657,2.741c0.478,0.257,0.945,0.537,1.411,0.805c1.691,0.956,3.289,2.041,4.794,3.278
			c2.053,1.68,3.954,3.511,5.832,5.366c2.45,2.415,4.456,5.179,6.276,8.084c2.87,4.584,4.934,9.507,6.217,14.744
			c0.478,1.96,0.91,3.931,0.945,5.972c0.012,0.77,0.152,1.551,0.222,2.321c0.093,0.922,0.175,1.855,0.257,2.671
			C535.678,283.197,535.643,283.745,535.585,284.281L535.585,284.281z M147.197,259.202c-0.012,0.467,0,0.933,0,1.4l0,0
			c0,0.933,0.012,1.866,0,2.8c-0.012,0.618,0,1.248-0.082,1.866c-0.607,5.261-1.621,10.417-3.628,15.374
			c-1.213,2.975-2.858,5.681-4.549,8.375c-0.338,0.548-0.793,0.956-1.388,1.19c-0.373,0.152-0.758,0.187-1.131,0.035
			c-0.362-0.14-0.712-0.303-1.061-0.478c-0.198-0.105-0.373-0.28-0.56-0.42c-3.324-2.485-6.194-5.401-8.609-8.784
			c-1.82-2.531-3.476-5.167-4.643-8.049c-1.388-3.464-2.52-7.022-2.695-10.802c-0.035-0.77-0.117-1.551-0.233-2.321
			c-0.222-1.4-0.198-2.788,0.023-4.176c0.093-0.618,0.21-1.236,0.222-1.843c0.023-2.368,0.595-4.619,1.271-6.847
			c0.747-2.45,1.621-4.853,2.835-7.127c1.563-2.963,3.359-5.751,5.541-8.317c1.108-1.306,2.17-2.636,3.511-3.709
			c0.42-0.338,0.84-0.7,1.283-1.003c0.91-0.607,2.228-0.583,3.056,0.14c1.715,1.493,3.231,3.149,3.896,5.401
			c0.513,1.715,1.225,3.359,2.018,4.958c1.936,3.943,3.021,8.142,3.978,12.4c0.105,0.455,0.117,0.922,0.175,1.388
			c0.058,0.537,0.07,1.096,0.187,1.621C147.15,254.536,147.243,256.858,147.197,259.202L147.197,259.202z M493.265,534.944
			c0,0.093,0.023,0.327,0,0.56c-0.257,2.555-0.525,5.097-1.12,7.594c-0.653,2.718-1.516,5.377-2.648,7.944
			c-0.945,2.135-2.123,4.129-3.394,6.077c-1.108,1.691-2.391,3.254-3.616,4.853c-0.42,0.548-0.921,1.061-1.411,1.551
			c-0.933,0.933-1.878,1.855-2.823,2.776c-0.163,0.163-0.35,0.303-0.548,0.432c-0.443,0.303-1.353,0.175-1.715-0.245
			c-0.198-0.233-0.42-0.467-0.572-0.735c-0.513-0.875-1.108-1.68-1.703-2.496c-0.677-0.933-1.236-1.971-1.773-2.998
			c-0.91-1.715-1.773-3.453-2.636-5.191c-1.551-3.149-2.66-6.451-3.418-9.88c-0.408-1.82-0.828-3.628-0.933-5.506
			c-0.035-0.537-0.163-1.073-0.292-1.598c-0.303-1.213-0.397-2.45-0.385-3.698c0.012-1.785,0.012-3.569,0-5.354
			c-0.012-1.248,0.117-2.473,0.397-3.698c0.21-0.91,0.327-1.831,0.42-2.753c0.093-0.933,0.222-1.843,0.467-2.753
			c0.303-1.12,0.525-2.275,0.852-3.383c1.236-4.199,3.068-8.13,5.319-11.863c0.443-0.723,0.968-1.411,1.528-2.053
			c0.653-0.758,1.656-0.887,2.543-0.385c0.338,0.187,0.642,0.432,0.957,0.665c3.488,2.531,6.346,5.669,8.889,9.145
			c1.995,2.718,3.511,5.681,4.759,8.784c1.155,2.881,2.076,5.856,2.321,8.994c0.047,0.618,0.175,1.225,0.303,1.843
			C493.23,532.623,493.312,533.708,493.265,534.944L493.265,534.944z M121.173,304.846c0,0.933,0,1.866,0,2.8h-0.012
			c0,0.852-0.012,1.715,0,2.566c0.035,2.03-0.233,4.001-0.642,5.996c-0.467,2.286-1.05,4.538-1.738,6.754
			c-0.257,0.828-0.653,1.575-1.201,2.251c-0.315,0.385-0.712,0.572-1.201,0.642c-0.642,0.082-1.236-0.035-1.808-0.327
			c-2.006-1.003-4.048-1.983-5.914-3.243c-1.551-1.038-3.103-2.065-4.549-3.243c-2.298-1.866-4.386-3.954-6.101-6.369
			c-0.945-1.33-1.843-2.683-2.718-4.059c-1.633-2.578-2.718-5.389-3.593-8.305c-0.828-2.765-1.19-5.622-1.633-8.445
			c-0.268-1.761-0.187-3.569,0.035-5.342c0.163-1.388,0.397-2.765,0.583-4.153c0.187-1.47,0.548-2.893,0.957-4.316
			c1.073-3.756,2.695-7.267,4.584-10.662c0.49-0.875,1.096-1.703,1.726-2.485c1.015-1.271,2.181-1.446,3.441-0.408
			c2.088,1.726,4.199,3.406,5.949,5.529c1.68,2.041,3.243,4.153,4.748,6.322c0.618,0.887,1.155,1.843,1.645,2.811
			c1.61,3.103,3.114,6.252,4.316,9.542c1.05,2.858,1.831,5.762,2.275,8.772c0.058,0.385,0.082,0.782,0.175,1.143
			C121.115,300.659,121.185,302.747,121.173,304.846L121.173,304.846z M554.166,381.028c-0.093,0.933-0.875,1.75-1.785,1.913
			c-0.152,0.035-0.303,0.07-0.455,0.07c-0.548,0.012-1.085,0-1.633,0c-1.33,0.035-2.648-0.058-3.943-0.362
			c-0.991-0.233-1.995-0.303-2.998-0.397c-0.77-0.082-1.551-0.163-2.298-0.338c-2.123-0.49-4.246-0.945-6.287-1.715
			c-1.085-0.42-2.193-0.793-3.266-1.225c-1.516-0.595-3.033-1.213-4.538-1.831c-0.502-0.21-1.038-0.373-1.493-0.642
			c-1.808-1.061-3.674-2.018-5.354-3.289c-0.373-0.28-0.77-0.525-1.143-0.793c-0.887-0.63-1.738-1.295-2.531-2.053
			c-1.295-1.236-2.648-2.403-3.908-3.663c-2.146-2.135-4.176-4.363-5.972-6.836c-1.936-2.66-3.464-5.517-4.853-8.48
			c-0.49-1.05-0.887-2.158-1.318-3.243c-0.082-0.21-0.117-0.455-0.152-0.677c-0.163-1.073,0.222-1.843,1.201-2.263
			c0.642-0.268,1.306-0.502,1.983-0.642c1.75-0.385,3.511-0.688,5.249-1.061c1.073-0.233,2.146-0.327,3.243-0.327
			c1.633,0.012,3.266,0.035,4.899-0.012c1.726-0.035,3.418,0.152,5.086,0.537c1.738,0.397,3.488,0.77,5.214,1.225
			c2.496,0.665,4.783,1.843,7.022,3.103c4.736,2.66,8.69,6.206,11.956,10.568c3.313,4.421,5.529,9.343,7.01,14.628
			c0.35,1.271,0.618,2.566,0.863,3.861C554.201,378.38,554.295,379.699,554.166,381.028L554.166,381.028z M552.697,428.865
			c-0.047,1.085-0.082,2.17-0.14,3.254c-0.012,0.303-0.07,0.618-0.14,0.922c-0.117,0.502-0.49,0.77-0.957,0.922
			c-0.455,0.14-0.922,0.117-1.388,0.023c-1.831-0.362-3.651-0.77-5.424-1.341c-2.216-0.712-4.386-1.528-6.486-2.59
			c-2.776-1.4-5.459-2.951-8.014-4.736c-1.4-0.98-2.765-2.006-3.966-3.231c-0.163-0.163-0.315-0.35-0.502-0.478
			c-2.321-1.738-4.164-3.943-6.077-6.089c-1.773-1.971-3.219-4.188-4.678-6.392c-1.155-1.75-2.158-3.593-3.033-5.506
			c-0.688-1.493-1.178-3.044-1.645-4.608c-1.26-4.246-2.356-8.539-3.383-12.843c-0.012-0.07,0.012-0.152,0.035-0.28
			c0.082-0.07,0.175-0.233,0.303-0.257c1.528-0.28,3.044-0.572,4.619-0.397c2.391,0.257,4.806,0.397,7.197,0.677
			c1.54,0.187,3.091,0.42,4.584,0.84c4.817,1.353,9.378,3.278,13.333,6.427c1.458,1.155,2.916,2.321,4.328,3.534
			c0.7,0.607,1.341,1.295,1.925,2.018c2.018,2.566,3.884,5.237,5.342,8.177c1.073,2.158,2.03,4.363,2.625,6.719
			c0.385,1.505,0.782,3.01,1.108,4.526c0.163,0.758,0.222,1.54,0.292,2.31C552.802,423.278,552.802,426.066,552.697,428.865
			L552.697,428.865z M521.505,502.458c0,0.956,0,1.586,0,2.205c0,0.385,0.023,0.782-0.047,1.166
			c-0.385,2.298-0.723,4.608-1.201,6.882c-0.362,1.75-0.933,3.453-1.563,5.121c-1.155,3.068-2.636,5.961-4.503,8.667
			c-0.362,0.513-0.747,1.003-1.271,1.341c-0.537,0.338-1.12,0.513-1.773,0.268c-0.513-0.187-1.015-0.385-1.435-0.758
			c-1.225-1.073-2.531-2.053-3.581-3.324c-0.642-0.77-1.353-1.505-2.053-2.228c-1.341-1.411-2.543-2.928-3.616-4.561
			c-0.688-1.038-1.376-2.065-2.065-3.103c-0.175-0.257-0.35-0.513-0.49-0.793c-0.922-1.878-1.878-3.756-2.741-5.669
			c-1.026-2.263-1.831-4.619-2.531-7.01c-0.373-1.271-0.688-2.555-1.026-3.826c-0.117-0.455-0.222-0.91-0.303-1.365
			c-0.245-1.376-0.49-2.753-0.7-4.141c-0.128-0.84-0.233-1.703-0.245-2.543c-0.035-2.718-0.012-5.447-0.012-8.165
			c0-0.467-0.012-0.945,0.07-1.4c0.723-3.896,1.4-7.804,3.033-11.466c0.035-0.07,0.047-0.152,0.082-0.222
			c0.502-1.12,1.516-1.61,2.671-1.201c0.945,0.338,1.901,0.712,2.776,1.19c2.986,1.656,5.914,3.441,8.55,5.634
			c2.52,2.088,4.759,4.468,6.602,7.185c0.828,1.225,1.656,2.45,2.45,3.686c0.875,1.388,1.586,2.858,2.135,4.398
			c0.875,2.496,1.726,5.004,2.041,7.64c0.023,0.233,0.082,0.455,0.14,0.688C521.424,498.76,521.61,500.766,521.505,502.458
			L521.505,502.458z M543.062,332.247c-1.108,0.338-2.24,0.677-3.383,0.887c-1.598,0.303-3.196,0.653-4.841,0.653
			c-0.618,0-1.248,0.082-1.855,0.222c-2.24,0.49-4.479,0.408-6.719,0.082c-0.922-0.14-1.855-0.268-2.776-0.28
			c-1.878-0.035-3.698-0.432-5.517-0.828c-2.123-0.467-4.246-0.968-6.299-1.68c-2.345-0.805-4.643-1.773-6.894-2.823
			c-3.394-1.575-6.591-3.464-9.565-5.727c-0.49-0.373-0.968-0.782-1.446-1.178c-0.653-0.548-1.306-1.096-1.96-1.645
			c-1.015-0.84-1.995-1.726-2.823-2.765c-0.77-0.968-1.586-1.913-2.403-2.846c-0.98-1.12-1.773-2.356-2.485-3.651
			c-0.175-0.327-0.268-0.723-0.315-1.108c-0.093-0.653,0.105-1.248,0.618-1.68c0.35-0.303,0.723-0.583,1.132-0.805
			c2.741-1.47,5.517-2.893,8.515-3.756c1.925-0.56,3.861-1.178,5.891-1.33c0.852-0.07,1.691-0.233,2.531-0.42
			c1.295-0.292,2.613-0.408,3.838-0.373c1.271-0.023,2.426,0.082,3.581,0.303c1.295,0.245,2.625,0.35,3.908,0.618
			c1.516,0.315,3.033,0.7,4.514,1.143c1.108,0.338,2.193,0.782,3.266,1.225c2.018,0.828,3.861,1.995,5.716,3.126
			c2.065,1.26,3.943,2.753,5.599,4.526c0.898,0.968,1.878,1.855,2.811,2.788c0.992,0.991,1.855,2.076,2.625,3.254
			c1.306,2.03,2.531,4.094,3.639,6.229c0.793,1.516,1.458,3.091,2.053,4.689c0.14,0.362,0.21,0.747,0.28,1.131
			C544.415,331.103,543.925,331.978,543.062,332.247L543.062,332.247z M183.334,223.695c0,1.166-0.07,2.321-0.362,3.464
			c-0.187,0.747-0.292,1.528-0.385,2.298c-0.467,3.873-1.551,7.605-2.835,11.268c-0.408,1.166-0.945,2.298-1.493,3.418
			c-0.723,1.47-1.505,2.893-2.251,4.339c-1.073,2.076-2.368,4.001-3.931,5.751c-0.723,0.805-1.306,1.738-1.96,2.601
			c-0.945,1.248-2.03,2.345-3.418,3.126c-0.198,0.117-0.408,0.222-0.618,0.315c-0.618,0.257-1.225,0.222-1.773-0.163
			c-0.315-0.222-0.63-0.478-0.863-0.77c-2.415-2.951-4.596-6.066-6.147-9.577c-0.467-1.061-1.003-2.1-1.551-3.126
			c-0.537-0.968-0.898-1.971-0.956-3.079c-0.035-0.77-0.152-1.551-0.268-2.31c-0.093-0.688-0.175-1.388-0.373-2.053
			c-0.292-0.98-0.42-1.983-0.432-2.986c-0.012-2.1-0.023-4.199,0-6.299c0.012-0.77,0.082-1.551,0.21-2.321
			c0.63-3.849,1.715-7.594,3.406-11.105c1.213-2.508,2.625-4.934,4.421-7.104c1.19-1.435,2.298-2.939,3.581-4.293
			c0.747-0.793,1.528-1.551,2.403-2.205c1.12-0.84,2.181-1.75,3.324-2.555c1.073-0.77,2.216-1.423,3.313-2.17
			c0.863-0.583,1.808-0.887,2.928-0.91c0.665,0.245,1.085,0.583,1.365,1.073c0.303,0.548,0.607,1.085,0.852,1.656
			c1.446,3.208,2.38,6.567,2.998,10.02c0.117,0.688,0.175,1.4,0.35,2.065c0.42,1.598,0.49,3.219,0.478,4.853
			C183.322,219.192,183.346,221.444,183.334,223.695L183.334,223.695z M106.755,357.804c0.012,0,0.012,0,0.023,0
			c-0.047,1.785-0.093,3.569-0.128,5.354c0,0.082,0,0.152-0.012,0.233c-0.14,1.691-0.852,2.135-2.473,2.006
			c-0.922-0.07-1.843-0.292-2.741-0.537c-1.575-0.42-3.149-0.863-4.701-1.376c-5.914-1.971-11.081-5.191-15.642-9.413
			c-1.318-1.225-2.368-2.671-3.429-4.106c-2.1-2.823-3.768-5.879-5.109-9.122c-1.015-2.45-1.633-5.016-2.076-7.629
			c-0.058-0.385-0.082-0.782-0.175-1.155c-0.653-2.601-0.56-5.237-0.502-7.885c0-0.315,0-0.63,0.058-0.933
			c0.548-2.741,0.595-5.564,1.423-8.259c0.385-1.26,0.735-2.543,1.143-3.791c0.163-0.513,0.432-1.003,0.723-1.458
			c0.537-0.852,1.47-1.201,2.426-0.887c0.735,0.245,1.47,0.525,2.146,0.887c3.301,1.75,6.416,3.803,9.273,6.217
			c0.408,0.35,0.828,0.712,1.201,1.096c1.68,1.726,3.336,3.464,5.027,5.179c0.77,0.782,1.458,1.61,2.065,2.52
			c0.782,1.155,1.633,2.275,2.403,3.429c3.709,5.541,6.287,11.595,7.885,18.057c0.747,3.021,1.26,6.089,1.19,9.215
			C106.744,356.241,106.755,357.022,106.755,357.804L106.755,357.804z M126.994,484.459c-0.315,0.222-0.63,0.467-0.968,0.642
			c-1.855,0.968-3.698,1.96-5.657,2.73c-1.96,0.758-3.954,1.423-5.984,1.901c-2.03,0.478-4.106,0.828-6.182,1.108
			c-1.061,0.152-2.17,0.023-3.254,0.023c0,0.012,0,0.023,0,0.047c-0.548,0-1.085,0.012-1.633,0c-0.467-0.012-0.945,0.012-1.4-0.07
			c-1.983-0.362-3.966-0.712-5.937-1.143c-1.131-0.245-2.251-0.607-3.359-0.968c-0.805-0.268-1.621-0.56-2.38-0.945
			c-2.916-1.458-5.797-2.975-8.422-4.934c-1.003-0.747-1.948-1.54-2.811-2.438c-0.922-0.945-1.773-1.96-2.741-2.846
			c-2.216-2.03-3.791-4.514-5.202-7.104c-1.085-1.971-2.018-4.024-3.009-6.042c-0.408-0.84-0.618-1.761-0.723-2.683
			c-0.058-0.49,0.128-0.91,0.525-1.236c0.49-0.397,1.026-0.688,1.645-0.852c2.636-0.677,5.249-1.388,8.002-1.388
			c1.166,0,2.321-0.152,3.488-0.198c0.852-0.035,1.703-0.035,2.555,0c1.318,0.047,2.636,0.163,3.954,0.21
			c1.166,0.035,2.321,0.105,3.464,0.397c0.91,0.222,1.831,0.362,2.741,0.548c2.66,0.548,5.272,1.283,7.815,2.251
			c1.96,0.735,3.919,1.481,5.797,2.415c3.348,1.656,6.591,3.476,9.495,5.832c1.621,1.318,3.254,2.66,4.724,4.141
			c2.181,2.205,4.141,4.619,5.809,7.244c0.257,0.397,0.408,0.828,0.478,1.295C127.927,483.258,127.705,483.958,126.994,484.459
			L126.994,484.459z M110.243,446.841c-0.607,0.35-1.248,0.607-1.948,0.747c-1.143,0.222-2.275,0.502-3.418,0.735
			c-0.688,0.14-1.376,0.28-2.065,0.327c-2.018,0.152-4.024,0.268-6.217,0.408c-2.006-0.14-4.188-0.222-6.346-0.467
			c-2.088-0.233-4.118-0.747-6.101-1.481c-0.583-0.222-1.166-0.408-1.761-0.618c-2.718-0.98-5.202-2.368-7.605-3.978
			c-2.485-1.656-4.666-3.604-6.684-5.762c-0.583-0.618-1.131-1.283-1.645-1.948c-1.236-1.586-2.426-3.219-3.464-4.958
			c-1.551-2.625-2.753-5.389-3.733-8.259c-0.735-2.135-1.166-4.351-1.645-6.544c-0.245-1.143-0.315-2.31-0.175-3.476
			c0.117-1.05,0.863-1.878,1.901-2.088c0.455-0.093,0.922-0.163,1.388-0.152c1.085,0.023,2.17,0.093,3.254,0.175
			c1.085,0.082,2.158,0.187,3.243,0.303c0.77,0.082,1.54,0.163,2.31,0.292c3.861,0.618,7.524,1.866,11.14,3.289
			c2.613,1.027,5.109,2.251,7.5,3.721c0.467,0.28,0.933,0.56,1.376,0.863c1.481,1.003,2.951,2.018,4.421,3.033
			c1.026,0.712,1.936,1.551,2.846,2.403c0.91,0.852,1.831,1.68,2.741,2.52c0.292,0.268,0.572,0.525,0.84,0.805
			c2.333,2.496,4.479,5.156,6.346,8.025c0.21,0.327,0.408,0.653,0.583,1.003c1.003,2.018,2.018,4.024,2.974,6.066
			c0.42,0.91,0.782,1.855,0.945,2.87C111.398,445.616,111.048,446.362,110.243,446.841L110.243,446.841z M277.83,175.368
			c-0.222,1.225-0.28,2.473-0.315,3.721c-0.023,0.933-0.163,1.855-0.397,2.765c-0.21,0.828-0.35,1.668-0.56,2.496
			c-0.338,1.353-0.642,2.73-1.096,4.048c-1.12,3.313-2.508,6.521-4.234,9.577c-1.26,2.24-2.625,4.409-4.129,6.486
			c-1.458,2.018-3.056,3.919-4.736,5.751c-2.38,2.601-5.191,4.678-7.979,6.789c-2.671,2.018-4.596,2.881-7.652,4.409
			c-1.96,0.98-3.989,1.738-6.077,2.38c-0.595,0.175-1.201,0.327-1.82,0.397c-0.758,0.093-1.376-0.245-1.773-0.898
			c-0.233-0.397-0.432-0.84-0.537-1.283c-0.315-1.365-0.595-2.741-0.828-4.118c-0.128-0.758-0.163-1.551-0.187-2.321
			c-0.035-1.085-0.012-2.181-0.012-3.266l0,0c0-0.782,0.012-1.551,0-2.333c-0.023-1.481,0.117-2.94,0.49-4.386
			c0.303-1.201,0.49-2.438,0.758-3.651c0.723-3.219,2.065-6.182,3.651-9.052c1.855-3.359,4.199-6.322,7.01-8.947
			c2.683-2.52,5.576-4.713,8.842-6.392c0.828-0.42,1.668-0.828,2.531-1.19c2.799-1.178,5.704-2.03,8.632-2.835
			c0.747-0.198,1.528-0.303,2.298-0.35c1.4-0.105,2.788-0.222,4.153-0.572c0.688-0.175,1.376-0.21,2.088-0.163
			c0.992,0.082,1.901,1.015,1.913,2.018C277.888,174.75,277.888,175.065,277.83,175.368L277.83,175.368z M541.312,471.99
			c-0.327,1.75-0.548,3.523-1.05,5.249c-0.362,1.271-0.712,2.543-1.143,3.791c-0.338,0.968-0.852,1.831-2.018,2.368
			c-1.341-0.117-2.45-0.665-3.558-1.225c-4.922-2.461-9.157-5.821-12.889-9.833c-0.315-0.338-0.618-0.7-0.957-1.015
			c-2.753-2.671-4.946-5.786-6.917-9.052c-2.088-3.464-3.849-7.092-5.167-10.93c-0.887-2.578-1.528-5.202-2.076-7.874
			c-0.548-2.695-0.735-5.377-0.688-8.095c0.023-1.633,0.012-3.266,0.035-4.899c0.012-0.863,0.105-1.703,0.467-2.496
			c0.28-0.63,0.758-0.968,1.435-1.015c1.26-0.082,2.485,0.128,3.686,0.408c2.73,0.63,5.366,1.54,7.944,2.625
			c0.852,0.362,1.645,0.875,2.473,1.295c2.998,1.505,5.727,3.429,8.27,5.599c1.96,1.668,3.593,3.639,5.097,5.727
			c1.913,2.66,3.441,5.541,4.713,8.55c1.026,2.438,1.645,5.016,2.111,7.617c0.688,3.849,0.653,3.849,0.653,8.095
			C541.743,468.607,541.615,470.298,541.312,471.99L541.312,471.99z M372.979,595.554c-1.353,3.348-3.313,6.299-5.506,9.145
			c-1.82,2.356-4.071,4.258-6.334,6.147c-3.499,2.928-7.559,4.852-11.851,6.311c-0.956,0.327-1.913,0.63-2.893,0.91
			c-1.493,0.408-2.986,0.828-4.549,0.957c-1.155,0.093-2.298,0.35-3.453,0.56c-1.003,0.175-2.006,0.233-3.021,0.175
			c-0.513-0.035-0.84-0.315-1.061-0.782c-0.303-0.653-0.327-1.341-0.362-2.041c-0.163-3.453,0.443-6.812,1.26-10.137
			c0.49-1.96,1.12-3.873,1.855-5.762c0.362-0.933,0.63-1.936,1.096-2.811c1.33-2.45,2.508-4.992,4.071-7.314
			c0.782-1.155,1.516-2.356,2.345-3.476c0.688-0.933,1.493-1.785,2.263-2.66c0.933-1.038,1.89-2.065,2.823-3.091
			c0.21-0.233,0.397-0.49,0.63-0.688c1.785-1.61,3.499-3.313,5.412-4.771c1.785-1.353,3.663-2.613,5.622-3.721
			c2.1-1.19,4.281-2.181,6.532-3.033c1.166-0.443,2.345-0.817,3.523-1.225c0.595-0.21,1.213-0.28,1.843-0.198
			c0.572,0.07,1.026,0.373,1.295,0.875c0.152,0.268,0.268,0.56,0.362,0.863s0.14,0.607,0.21,0.91
			c0.887,4.223,0.887,4.223,0.887,9.238c0,0.315-0.012,0.618,0,0.933C376.198,585.989,374.88,590.83,372.979,595.554
			L372.979,595.554z M457.758,558.647c0,0.233,0,0.467,0,0.7h-0.012c0,1.167,0,2.333,0,3.499c0.012,1.411-0.14,2.788-0.537,4.141
			c-0.35,1.19-0.583,2.415-0.968,3.593c-1.096,3.418-2.695,6.602-4.584,9.647c-1.236,1.995-2.683,3.803-4.351,5.436
			c-0.77,0.758-1.54,1.54-2.286,2.321c-2.438,2.543-5.296,4.549-8.352,6.264c-0.467,0.268-0.992,0.455-1.505,0.618
			c-0.735,0.245-1.61-0.093-2.041-0.747c-0.082-0.128-0.175-0.257-0.245-0.397c-1.026-1.925-1.726-3.989-2.555-5.996
			c-0.49-2.356-1.26-4.654-1.493-7.057c-0.047-0.467-0.07-0.933-0.175-1.388c-0.537-2.216-0.537-4.468-0.513-6.719
			c0.012-1.015-0.012-2.018,0.012-3.033c0.012-0.7-0.035-1.411,0.105-2.088c0.583-3.126,0.642-6.346,1.656-9.39
			c0.665-3.371,1.971-6.544,3.196-9.728c1.038-2.695,2.601-5.121,4.223-7.489c1.411-2.053,3.033-3.931,4.643-5.821
			c0.968-1.131,2.123-2.053,3.313-2.916c0.607-0.443,1.365-0.408,1.948,0.047c0.245,0.187,0.502,0.373,0.712,0.607
			c1.201,1.318,2.321,2.683,3.278,4.223c1.551,2.52,2.905,5.132,4.141,7.815c0.887,1.913,1.481,3.919,1.785,6.007
			c0.012,0.082,0,0.163,0.012,0.233C457.886,553.503,457.746,556.08,457.758,558.647L457.758,558.647z M103.816,405.279
			c-0.175,0.467-0.478,0.793-0.957,0.933c-0.292,0.082-0.607,0.152-0.91,0.187c-0.303,0.035-0.618,0.047-0.933,0.047
			c-1.4,0-2.8,0-4.106,0c-1.645-0.128-3.184-0.292-4.748-0.152c-0.443,0.035-0.922-0.105-1.365-0.233
			c-2.601-0.77-5.202-1.563-7.804-2.356c-0.292-0.093-0.595-0.187-0.875-0.327c-2.321-1.085-4.596-2.263-6.684-3.768
			c-1.715-1.225-3.278-2.601-4.876-3.954c-1.073-0.91-1.936-2.018-2.8-3.114c-1.493-1.89-2.881-3.849-3.943-6.031
			c-0.478-0.98-1.038-1.913-1.528-2.881c-0.98-1.948-1.633-4.024-2.17-6.136c-0.21-0.828-0.373-1.668-0.56-2.496
			c-0.513-2.286-0.84-4.584-0.782-6.929c0.047-2.018,0.035-4.036,0.035-6.054c0-0.782,0.105-1.551,0.292-2.31
			c0.222-0.863,0.968-1.435,1.89-1.446c0.233,0,0.467,0.023,0.7,0.047c1.236,0.14,2.45,0.362,3.639,0.758
			c1.108,0.373,2.24,0.642,3.336,1.026c3.978,1.4,7.664,3.371,11.186,5.681c2.146,1.411,4.211,2.916,6.066,4.701
			c0.385,0.373,0.84,0.688,1.236,1.061c0.572,0.513,1.201,1.003,1.691,1.598c1.33,1.621,2.8,3.114,3.978,4.853
			c0.875,1.283,1.866,2.473,2.8,3.709c0.233,0.303,0.478,0.618,0.665,0.956c1.155,2.111,2.333,4.211,3.441,6.346
			c0.618,1.166,1.166,2.368,1.68,3.581c0.373,0.852,0.735,1.726,0.933,2.625c0.56,2.578,1.05,5.179,1.551,7.769
			C104.037,403.74,104.096,404.521,103.816,405.279L103.816,405.279z M227.882,192.294c-0.257,1.225-0.35,2.461-0.35,3.709
			c0,1.015-0.128,2.006-0.362,2.998c-0.222,0.98-0.338,1.995-0.583,2.974c-0.665,2.718-1.341,5.436-2.391,8.037
			c-0.315,0.793-0.677,1.575-0.968,2.368c-0.91,2.415-2.31,4.573-3.534,6.812c-0.408,0.747-0.898,1.446-1.353,2.181
			c-1.668,2.73-3.709,5.167-5.832,7.547c-1.621,1.808-3.476,3.324-5.366,4.818c-1.656,1.306-3.476,2.345-5.237,3.476
			c-0.677,0.443-1.435,0.595-2.228,0.537c-0.478-0.035-0.922-0.21-1.225-0.595c-0.292-0.362-0.572-0.747-0.782-1.155
			c-0.898-1.808-1.691-3.674-2.286-5.611c-0.782-2.531-1.306-5.121-1.726-7.734c-0.128-0.758-0.14-1.551-0.152-2.321
			c-0.023-1.633,0.035-3.266-0.023-4.899c-0.058-1.96,0.163-3.884,0.618-5.774c0.502-2.111,1.05-4.223,1.855-6.252
			c1.528-3.873,3.663-7.395,6.346-10.533c2.986-3.499,6.346-6.602,10.37-8.923c1.96-1.131,3.943-2.158,6.054-2.963
			c1.446-0.548,2.881-1.166,4.374-1.598c0.373-0.105,0.747-0.233,1.12-0.315c0.91-0.187,1.703,0,2.263,0.84
			c0.735,1.796,1.096,3.686,1.085,5.657c-0.012,1.318,0.105,2.636,0.35,3.943C228.103,190.439,228.08,191.36,227.882,192.294
			L227.882,192.294z M417.934,579.258c-0.047,1.096-0.187,2.158-0.455,3.219c-0.572,2.263-1.166,4.503-2.053,6.672
			c-1.12,2.753-2.508,5.331-4.258,7.734c-0.828,1.131-1.668,2.24-2.508,3.371c-0.747,1.003-1.61,1.89-2.578,2.683
			c-1.435,1.19-2.881,2.368-4.374,3.476c-0.991,0.747-2.03,1.446-3.126,2.03c-2.951,1.563-5.926,3.091-9.133,4.071
			c-0.665,0.198-1.341,0.385-2.018,0.56c-0.467,0.117-0.922,0.047-1.376-0.093c-0.548-0.175-0.956-0.525-1.143-1.073
			c-0.502-1.551-0.922-3.138-0.957-4.783c-0.047-1.866-0.07-3.733-0.105-5.599c0.035,0,0.058,0,0.093,0
			c0-1.551-0.023-3.103,0.012-4.666c0.012-0.77,0.082-1.551,0.21-2.321c0.455-2.683,0.98-5.354,1.715-7.967
			c0.338-1.19,0.77-2.368,1.19-3.534c1.528-4.258,3.604-8.247,5.972-12.085c0.817-1.318,1.773-2.555,2.718-3.779
			c0.992-1.295,2.03-2.555,3.079-3.803c0.502-0.595,1.038-1.155,1.621-1.656c1.003-0.863,2.076-1.633,3.068-2.496
			c1.598-1.376,3.359-2.508,5.144-3.604c0.653-0.408,1.365-0.735,2.088-1.027c0.338-0.14,0.758-0.152,1.143-0.152
			c0.572,0.012,1.073,0.257,1.411,0.7c0.28,0.362,0.525,0.77,0.723,1.19c1.365,2.974,2.45,6.042,3.103,9.262
			c0.14,0.688,0.303,1.365,0.455,2.053c0.21,0.91,0.292,1.843,0.338,2.776C418.074,573.344,418.051,576.295,417.934,579.258
			L417.934,579.258z"
          />
        </g>
      </g>
      <text
        transform="matrix(1.0363 0 0 1 382.4453 460.5117)"
        font-family="'Shruti'"
        font-size="177.0632"
      >
        1
      </text>
    </svg>
  );
}

Artwrk8.props = {
  primary: string,
};

Artwrk8.defaultProps = {
  primary: "#000",
};
