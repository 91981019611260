import React from "react";
import { string } from "prop-types";

export default function Artwrk9(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="of_1_">
        <g id="Shape_1_copy_12">
          <path
            d="M386.62,98.129c-0.142,0.639-0.313,1.264-0.511,1.875c-2.003,6.264-3.991,12.528-6.009,18.778
			c-0.227,0.696-0.497,1.364-0.767,2.045c-0.284,0.724-0.838,1.193-1.548,1.449c-0.909,0.341-1.832,0.653-2.756,0.952
			c-1.037,0.341-2.074,0.313-3.111-0.014c-1.861-0.582-3.722-1.151-5.582-1.733c-1.321-0.412-2.642-0.824-3.949-1.222
			c-1.222-0.369-2.159-1.094-2.855-2.145c-0.54-0.81-1.08-1.619-1.605-2.443c-0.369-0.568-0.497-1.193-0.284-1.861
			c0.185-0.54,0.327-1.08,0.511-1.634c1.562-4.957,3.139-9.901,4.702-14.858c0.483-1.548,0.938-3.111,1.463-4.645
			c0.753-2.259,0.952-2.443,3.182-3.31c0.611-0.227,1.222-0.455,1.832-0.653c0.384-0.128,0.781-0.199,1.236-0.298
			c1.335,0.185,2.415,0.483,3.494,0.838c2.457,0.824,4.915,1.662,7.401,2.415c1.392,0.426,2.472,1.179,3.267,2.372
			c0.497,0.739,1.037,1.463,1.548,2.187C386.649,96.837,386.762,97.462,386.62,98.129L386.62,98.129z M377.558,97.107
			c-1.491-0.653-3.04-1.08-4.616-1.42c-0.085-0.014-0.156-0.014-0.241-0.014c-0.611-0.014-1.136,0.213-1.42,0.753
			c-0.298,0.568-0.554,1.165-0.767,1.776c-1.676,4.886-3.111,9.858-4.659,14.801c-0.071,0.227-0.099,0.483-0.114,0.71
			c-0.014,0.426,0.17,0.781,0.483,1.051c0.128,0.099,0.256,0.213,0.398,0.27c1.491,0.625,3.026,1.122,4.616,1.449
			c0.085,0.014,0.156,0.014,0.241,0.014c0.625,0.057,1.136-0.17,1.42-0.724c0.298-0.568,0.568-1.165,0.767-1.776
			c1.491-4.702,2.983-9.403,4.446-14.105c0.142-0.455,0.199-0.937,0.227-1.122C378.396,97.845,378.126,97.348,377.558,97.107
			L377.558,97.107z M423.325,114.18c-0.824,1.392-1.648,2.798-2.472,4.19c-0.298,0.497-0.724,0.881-1.392,0.938
			c-0.199-0.057-0.44-0.085-0.653-0.199c-1.335-0.753-2.685-1.506-4.006-2.315c-0.582-0.355-0.938-0.895-0.753-1.648
			c0.043-0.156,0.071-0.313,0.114-0.469c0.128-0.639-0.128-1.151-0.625-1.477c-0.824-0.526-1.676-0.994-2.543-1.435
			c-0.696-0.341-1.491-0.099-1.96,0.511c-0.923,1.236-1.747,2.543-2.301,3.991c-0.156,0.398-0.071,0.838,0.241,1.122
			c0.412,0.384,0.852,0.753,1.335,1.037c1.25,0.767,2.514,1.477,3.778,2.23c0.483,0.284,0.966,0.597,1.435,0.923
			c0.355,0.256,0.54,0.923,0.369,1.335c-0.554,1.278-1.236,2.472-2.131,3.523c-0.27,0.327-0.724,0.398-1.122,0.256
			c-0.384-0.128-0.753-0.298-1.108-0.497c-1.42-0.795-2.813-1.619-4.233-2.415c-0.426-0.241-0.866-0.426-1.321-0.611
			c-0.511-0.199-1.207-0.028-1.534,0.426c-0.994,1.378-1.96,2.784-2.557,4.503c0.241,0.625,0.497,1.293,0.724,1.875
			c-0.057,0.696-0.355,1.193-0.668,1.662c-0.54,0.81-1.065,1.634-1.634,2.415c-0.256,0.341-0.696,0.412-1.108,0.313
			c-0.227-0.057-0.469-0.156-0.682-0.27c-2.258-1.278-4.531-2.543-6.776-3.849c-1.307-0.781-1.406-1.25-0.696-2.571
			c0.384-0.71,0.781-1.42,1.193-2.116c0.384-0.668,0.938-1.094,1.747-1.151c0.313-0.028,0.625-0.114,0.838-0.156
			c0.753-0.469,1.151-1.08,1.52-1.705c0.838-1.392,1.662-2.784,2.472-4.205c1.619-2.812,3.21-5.639,4.815-8.466
			c0.398-0.71,0.81-1.406,0.909-2.386c-0.17-0.568-0.369-1.264-0.582-1.932c0.54-1.335,1.335-2.472,2.074-3.622
			c0.298-0.455,1.009-0.625,1.534-0.369c0.582,0.284,1.165,0.582,1.719,0.895c3.31,1.903,6.619,3.807,9.915,5.71
			c1.761,1.009,3.523,2.017,5.27,3.04C423.836,112.021,424.248,112.604,423.325,114.18L423.325,114.18z"
          />
        </g>
      </g>
      <g id="class_1_">
        <g id="Shape_1_copy_10">
          <path
            d="M266.735,117.618c-0.625,0.185-1.25,0.398-1.889,0.469c-1.69,0.185-3.366,0.511-5.028,0.824
			c-0.71,0.128-1.449,0.142-2.173,0.142c-0.611,0-1.094-0.298-1.321-0.895c-0.483-1.222-0.653-2.5-0.767-3.793
			c-0.028-0.298,0.142-0.625,0.313-0.895c0.213-0.341,0.526-0.611,0.753-0.952c0.341-0.511-0.043-1.449-0.653-1.577
			c-0.398-0.085-0.81-0.17-1.193-0.099c-1.193,0.185-2.386,0.426-3.58,0.653c-0.156,0.028-0.298,0.128-0.44,0.213
			c-0.54,0.313-0.724,1.293-0.298,1.705c0.398,0.384,0.852,0.739,1.335,1.136c0.554,1.321,0.696,2.77,0.753,4.233
			c0.028,0.682-0.398,1.193-1.108,1.406c-0.156,0.043-0.313,0.128-0.469,0.142c-2.656,0.327-5.241,1.037-7.926,1.151
			c-0.327,0.014-0.653,0.014-0.966-0.028c-0.327-0.057-0.639-0.213-0.739-0.554c-0.227-0.767-0.455-1.548-0.611-2.344
			c-0.426-2.017-0.554-2.06,1.037-3.636c0.057-0.057,0.085-0.128,0.227-0.369c0.057-0.298,0.199-0.767,0.227-1.25
			c0.156-1.932,0.44-3.864,0.398-5.824c-0.014-0.895,0.043-1.79,0.17-2.67c0.27-1.69,0.327-3.381,0.412-5.085
			c0.17-3.395,0.44-6.79,0.895-10.17c0.028-0.156,0.043-0.327,0.071-0.483c0.128-0.597,0.455-1.037,1.037-1.25
			c0.227-0.085,0.455-0.17,0.696-0.213c2.159-0.369,4.304-0.724,6.463-1.065c0.398-0.057,0.81-0.057,1.207-0.043
			c0.426,0.014,0.81,0.227,1.023,0.582c0.341,0.554,0.653,1.122,0.895,1.719c1.435,3.437,2.841,6.889,4.261,10.341
			c1.42,3.452,2.855,6.889,4.29,10.327c0.213,0.511,0.511,1.009,0.781,1.548c0.781,0.412,1.349,0.71,1.974,1.051
			c0.128,0.355,0.327,0.724,0.398,1.108c0.17,1.037,0.313,2.088,0.426,3.125C267.7,116.893,267.288,117.447,266.735,117.618
			L266.735,117.618z M253.127,103.286c-0.199-0.866-0.511-1.676-1.136-2.33c-0.099-0.099-0.27-0.114-0.412-0.17
			c-0.412,0.185-0.597,0.54-0.597,0.952c-0.014,0.994-0.526,2.031,0.128,2.969c0.54,0.142,0.994-0.043,1.42-0.241
			C252.956,104.28,253.226,103.712,253.127,103.286L253.127,103.286z M336.99,87.987c-0.114,1.207-0.256,2.415-0.384,3.636
			c-0.014,0.156-0.043,0.327-0.071,0.483c-0.185,0.994-0.881,1.506-1.932,1.42c-1.051-0.085-2.102-0.185-3.153-0.27
			c-0.739-0.057-1.435-0.227-2.06-0.653c-0.099-0.128-0.27-0.241-0.298-0.384c-0.298-1.378-1.222-1.776-2.514-1.79
			c-0.966,0-1.946-0.128-2.912-0.17c-0.625-0.028-1.108,0.341-1.264,0.938c-0.142,0.54-0.27,1.108-0.313,1.662
			c-0.071,0.881-0.114,1.776-0.114,2.67c0,0.81,0.511,1.435,1.293,1.534c1.364,0.199,2.727,0.341,4.105,0.483
			c0.81,0.085,1.619,0.099,2.429,0.142c0.838,0.043,1.605,0.313,2.244,0.838c1.009,0.824,1.974,1.676,2.94,2.543
			c0.497,0.455,0.724,1.065,0.71,1.747c-0.014,0.895,0.043,1.79-0.028,2.67c-0.199,2.344-0.44,4.687-0.682,7.017
			c-0.028,0.327-0.114,0.639-0.185,0.952c-0.099,0.398-0.298,0.753-0.625,1.023c-1.193,0.994-2.528,1.747-3.892,2.472
			c-0.653,0.341-1.406,0.384-2.145,0.327c-1.051-0.085-2.102-0.156-3.153-0.241c-1.69-0.128-3.395-0.313-5.099-0.355
			c-1.449-0.043-2.699-0.483-3.764-1.449c-0.781-0.71-1.577-1.406-2.329-2.131c-0.554-0.54-0.781-1.222-0.724-1.989
			c0.057-0.81,0.128-1.619,0.213-2.429c0.071-0.682,0.582-1.151,1.307-1.207c0.241-0.014,0.483-0.043,0.724-0.028
			c1.293,0.085,2.585,0.156,3.878,0.27c0.781,0.057,1.392,0.384,1.648,1.207c0.114,0.384,0.298,0.739,0.383,0.952
			c0.44,0.44,0.838,0.511,1.236,0.568c1.364,0.213,2.741,0.298,4.105,0.014c0.412-0.085,0.739-0.341,0.852-0.767
			c0.412-1.506,0.44-3.04,0.327-4.588c-0.057-0.767-0.611-1.335-1.406-1.435c-0.483-0.057-0.966-0.099-1.449-0.099
			c-1.875,0.043-3.707-0.341-5.554-0.597c-0.582-0.085-1.108-0.298-1.548-0.653c-1.009-0.824-2.003-1.662-2.955-2.543
			c-0.696-0.639-1.009-1.491-0.866-2.429c0.327-2.244,0.199-4.545,0.668-6.776c0.156-0.781,0.114-1.619,0.17-2.429
			c0.071-0.952,0.497-1.705,1.321-2.216c0.071-0.043,0.128-0.099,0.199-0.128c1.349-0.724,2.486-1.847,4.048-2.173
			c2.344-0.014,4.73-0.213,6.989,0.568c0.952,0.327,1.861,0.384,2.855,0.341c1.705-0.071,3.409,0,5.099,0.071
			c1.136,0.043,1.719,0.739,1.733,1.932C337.061,87.007,337.047,87.504,336.99,87.987L336.99,87.987z M300.285,112.874
			c-1.051,0.895-2.116,1.776-3.21,2.599c-0.668,0.497-1.463,0.653-2.301,0.668c-1.548,0.028-3.082,0.085-4.631,0.114
			c-1.222,0.028-2.429,0.014-3.636,0.213c-1.08,0.185-2.131-0.014-3.054-0.582c-1.179-0.724-2.301-1.52-3.423-2.33
			c-0.554-0.398-0.838-0.98-0.895-1.662c-0.057-0.81-0.142-1.619-0.185-2.429c-0.014-0.241,0.057-0.483,0.128-0.71
			c0.128-0.412,0.384-0.753,0.81-0.838c1.761-0.355,3.537-0.384,5.327-0.369c0.511,0.014,0.909,0.327,1.165,0.781
			c0.241,0.412,0.455,0.838,0.696,1.278c0.355,0.085,0.668,0.199,0.98,0.199c1.293,0.014,2.599,0.014,3.892-0.028
			c0.625-0.014,1.207-0.625,1.278-1.278c0.17-1.634-0.014-3.239-0.341-4.83c-0.071-0.327-0.341-0.554-0.682-0.625
			c-0.398-0.071-0.795-0.114-1.207-0.114c-1.548,0.014-3.082,0.043-4.631,0.057c-0.724,0.014-1.463,0.014-2.187,0
			c-0.497-0.014-0.98-0.142-1.378-0.426c-1.136-0.781-2.273-1.562-3.381-2.386c-0.483-0.355-0.724-0.866-0.795-1.463
			c-0.014-0.085-0.028-0.156-0.028-0.241c-0.099-2.031-0.199-4.048-0.298-6.08c-0.057-1.222-0.085-2.429-0.128-3.651
			c-0.028-0.952,0.312-1.747,1.051-2.358c0.81-0.668,1.619-1.364,2.443-2.017c0.781-0.625,1.676-0.966,2.685-1.023
			c1.463-0.085,2.912-0.185,4.375-0.241c0.398-0.014,0.81,0.057,1.207,0.142c0.554,0.114,1.108,0.27,1.676,0.412
			c2.258-0.724,2.258-0.724,4.517-0.724c0.966,0,1.946,0.043,2.912,0.099c0.44,0.028,0.909,0.511,0.98,0.966
			c0.028,0.156,0.057,0.327,0.071,0.483c0.17,1.861,0.185,3.736,0.114,5.597c-0.028,0.923-0.582,1.52-1.491,1.634
			c-1.69,0.199-3.395,0.284-5.099,0.057c-0.085-0.014-0.156-0.043-0.227-0.071c-0.568-0.27-0.753-0.824-1.009-1.335
			c-0.199-0.398-0.483-0.668-0.909-0.739c-1.534-0.213-3.068-0.227-4.588,0.156c-0.455,0.114-0.881,0.582-0.866,1.051
			c0.028,1.463,0.085,2.912,0.156,4.375c0.014,0.156,0.085,0.313,0.142,0.469c0.185,0.483,0.511,0.824,1.037,0.909
			c0.313,0.057,0.639,0.085,0.966,0.085c1.136-0.014,2.273-0.043,3.409-0.057c0.98-0.014,1.946-0.014,2.926-0.028
			c0.838,0,1.605,0.227,2.287,0.71c0.923,0.668,1.861,1.307,2.77,1.989c0.597,0.455,0.952,1.094,1.023,1.861
			c0.213,2.344,0.398,4.687,0.384,7.045c0,0.895,0,1.79,0,2.685C301.124,111.695,300.854,112.376,300.285,112.874L300.285,112.874z
			 M196.65,132.717c-0.213,0.526-0.497,1.023-0.739,1.534c-0.213,0.44-0.455,0.866-0.653,1.307c-0.44,1.009-1.179,1.719-2.159,2.216
			c-1.818,0.909-3.622,1.832-5.426,2.741c-1.165,0.582-2.315,1.165-3.48,1.747c-1.051,0.526-2.116,0.767-3.295,0.412
			c-0.852-0.256-1.733-0.412-2.599-0.611c-0.753-0.17-1.307-0.625-1.705-1.278c-0.298-0.483-0.568-0.98-0.824-1.491
			c-3.168-6.222-6.321-12.443-9.517-18.636c-0.653-1.264-0.781-2.429-0.014-3.679c0.426-0.696,0.767-1.435,1.136-2.145
			c0.384-0.739,0.938-1.335,1.69-1.705c2.472-1.222,4.815-2.699,7.628-3.48c0.909-0.895,2.06-1.648,3.324-2.202
			c0.895-0.384,1.747-0.866,2.628-1.25c0.639-0.284,1.278-0.028,1.662,0.597c0.128,0.199,0.256,0.412,0.369,0.625
			c0.724,1.449,1.449,2.898,2.173,4.361c0.114,0.213,0.185,0.455,0.27,0.682c0.199,0.568,0.028,1.207-0.497,1.491
			c-1.435,0.767-2.869,1.52-4.318,2.216c-0.526,0.256-1.165,0.213-1.591-0.227c-0.895-0.923-1.832-0.852-2.869-0.298
			c-1.065,0.582-2.159,1.108-3.224,1.705c-0.582,0.327-0.81,1.051-0.582,1.705c0.185,0.54,0.398,1.065,0.653,1.577
			c2.116,4.205,4.247,8.395,6.378,12.585c0.298,0.582,0.625,1.136,0.98,1.676c0.412,0.611,1.037,0.824,1.69,0.597
			c1.392-0.469,2.699-1.108,3.935-1.889c0.327-0.213,0.568-0.554,0.881-0.852c0-0.668,0-1.321,0-1.989
			c0.241-0.241,0.426-0.54,0.696-0.682c1.364-0.724,2.741-1.42,4.105-2.102c0.142-0.071,0.313-0.085,0.469-0.128
			c0.455-0.114,1.065,0.085,1.293,0.469c0.497,0.838,0.952,1.69,1.435,2.543c0.043,0.071,0.071,0.142,0.099,0.227
			C196.877,131.609,196.877,132.177,196.65,132.717L196.65,132.717z M229.32,124.535c-0.511,0.241-1.023,0.483-1.563,0.653
			c-2.003,0.653-4.02,1.278-6.023,1.918c-3.168,0.994-6.335,1.989-9.517,2.955c-0.696,0.213-1.406,0.384-2.116,0.54
			c-0.412,0.085-1.051-0.227-1.25-0.611c-0.597-1.151-1.065-2.358-1.278-3.651c-0.085-0.497,0.014-0.966,0.327-1.378
			c0.142-0.199,0.312-0.369,0.44-0.582c0.227-0.355,0.312-0.753,0.27-1.165c-0.043-0.398-0.099-0.81-0.227-1.193
			c-1.378-4.489-2.77-8.977-4.162-13.466c-0.099-0.312-0.213-0.611-0.284-0.923c-0.341-1.321-0.952-2.401-2.386-2.798
			c-0.27-0.071-0.526-0.398-0.668-0.668c-0.597-1.165-0.881-2.429-1.151-3.693c-0.099-0.469,0.213-1.037,0.625-1.207
			c0.455-0.185,0.895-0.369,1.364-0.526c1.932-0.611,3.864-1.207,5.81-1.79c0.611-0.185,1.264-0.284,1.903-0.384
			c0.455-0.071,1.009,0.241,1.179,0.682c0.327,0.824,0.554,1.69,0.938,2.5c0.511,1.108,0.355,2.031-0.54,2.841
			c-0.114,0.099-0.156,0.284-0.341,0.653c0.114,0.497,0.185,0.98,0.327,1.435c1.548,5.028,3.111,10.057,4.673,15.085
			c0.142,0.455,0.355,0.895,0.582,1.335c0.156,0.298,0.469,0.469,0.795,0.497c1.08,0.085,2.088-0.17,3.011-0.724
			c0.199-0.114,0.341-0.341,0.469-0.54c0.085-0.128,0.156-0.298,0.156-0.455c0-1.278,0.767-1.889,1.875-2.216
			c1.25-0.369,2.472-0.767,3.736-1.08c0.838-0.213,1.378,0.085,1.719,0.938c0.384,0.98,0.724,1.974,1.051,2.983
			c0.298,0.923,0.568,1.861,0.824,2.798C230.016,123.74,229.746,124.322,229.32,124.535L229.32,124.535z"
          />
        </g>
      </g>
      <g id="_x31__1_">
        <g id="Shape_1_copy_5">
          <path
            d="M265.286,412.605c8.977,0,17.94-0.028,26.918,0.057c1.974,0.014,4.062,0.114,5.866,0.781
			c1.165,0.44,2.258,1.847,2.77,3.082c0.554,1.321,0.554,2.926,0.554,4.403c0.014,18.935,0,37.869-0.057,56.804
			c0,1.463-0.412,2.926-0.682,4.645c-1.25,0.582-2.5,1.534-3.849,1.733c-2.457,0.341-4.957,0.312-7.457,0.312
			c-43.366,0.014-86.718,0.028-130.085-0.014c-3.452,0-6.889-0.341-10.44-0.526c-0.696-1.619-1.605-2.898-1.747-4.261
			c-0.298-2.969-0.256-5.966-0.27-8.949c-0.014-36.378-0.014-72.755-0.014-109.133c0-3.48,0-6.96,0-9.773
			c7.088-8.906,15.923-17.06,32.869-30.383c2.77-0.17,6.207-0.554,9.645-0.568c16.449-0.071,32.898-0.014,49.346-0.057
			c2.983-0.014,5.994-0.099,8.906-0.639c1.193-0.227,2.514-1.563,3.125-2.741c0.653-1.25,0.739-2.898,0.739-4.361
			c0.043-15.952,0.043-31.889,0.014-47.841c0-0.98-0.213-1.974-0.327-2.955c-0.199-1.619-2.287-3.92-3.807-4.148
			c-1.477-0.213-2.955-0.526-4.432-0.526c-11.96-0.028-23.92-0.014-35.881,0.014c-2.528,0.014-4.972,0.355-6.861,2.457
			c-3.168,6.179,0.966,13.849-3.651,20.298c-14.46,0.54-28.906,0.256-43.324,0.17c-1.435-0.014-2.855-0.511-4.119-0.753
			c-0.682-1.023-1.634-1.804-1.69-2.628c-0.881-13.409-0.753-26.832-0.071-40.525c9.943-10.526,21.023-19.744,31.804-28.636
			c11.619-1.065,75.341-1.207,90.099-0.241c10.27,7.486,23.182,18.821,31.435,27.628c0.227,1.264,0.724,2.727,0.724,4.176
			c0.043,32.898,0.057,65.781,0.014,98.679c0,1.463-0.455,2.926-0.781,4.929c-1.491,1.477-3.139,3.324-5,4.929
			c-8.636,7.5-17.329,14.943-26.165,22.543c-4.02,0.199-7.983,0.554-11.932,0.568c-15.454,0.071-30.909,0-46.349,0.057
			c-2.969,0.014-5.952,0.298-8.92,0.554c-1.634,0.142-3.693,2.258-4.034,3.977c-0.284,1.463-0.739,2.926-0.739,4.389
			c-0.057,15.454-0.043,30.895-0.028,46.349c0,2.528,0.455,4.957,2.656,7.045c1.151,0.256,2.585,0.838,4.034,0.838
			c13.452,0.071,26.917,0.071,40.369,0c1.435-0.014,2.955-0.384,4.29-0.938c0.81-0.341,1.705-1.264,1.918-2.102
			c0.497-1.903,0.824-3.92,0.81-5.881C251.408,412.321,252.416,412.605,265.286,412.605L265.286,412.605z"
          />
        </g>
      </g>
      <g id="seniors_1_">
        <g id="Shape_1_copy">
          <path
            d="M300.328,614.359c-0.014,0.696-0.27,1.264-0.895,1.619c-0.213,0.114-0.426,0.242-0.625,0.384
			c-0.44,0.298-0.653,0.739-0.682,1.236c-0.057,0.724-0.057,1.463-0.057,2.188c0,8.523,0,17.045-0.014,25.568
			c0,1.051-0.043,2.116-0.114,3.168c-0.057,0.767-0.412,1.406-1.364,1.435c-2.031,0.057-4.063,0.043-6.08,0.014
			c-0.781-0.014-1.406-0.412-1.747-1.136c-0.355-0.724-0.668-1.477-0.966-2.23c-1.378-3.466-2.727-6.946-4.105-10.412
			c-0.355-0.909-0.81-1.761-1.236-2.642c-0.114-0.256-0.142-0.242-0.838-0.355c-0.071,0.227-0.199,0.44-0.213,0.668
			c-0.128,1.889-0.043,6.406,0.128,7.756c0.213,0.384,0.582,0.554,0.938,0.739c0.639,0.327,0.909,0.881,0.966,1.577
			c0.114,1.619,0.128,3.239-0.114,4.858c-0.085,0.597-0.483,1.008-1.122,1.122c-0.398,0.071-0.81,0.099-1.207,0.099
			c-2.67,0-5.355-0.014-8.04-0.014c-0.241,0-0.483-0.014-0.724-0.043c-0.952-0.099-1.52-0.639-1.534-1.563
			c-0.028-1.378-0.014-2.756,0-4.134c0.014-0.781,0.312-1.406,1.009-1.832c0.142-0.085,0.27-0.17,0.412-0.27
			c0.455-0.284,0.611-0.739,0.668-1.236c0.043-0.398,0.043-0.81,0.043-1.222c0-7.06,0-14.119,0-21.179
			c0-0.568-0.085-1.122-0.114-1.491c-0.298-0.653-0.753-0.867-1.136-1.151c-0.483-0.355-0.781-0.852-0.795-1.449
			c-0.057-1.534-0.17-3.082,0.028-4.616c0.028-0.242,0.099-0.483,0.185-0.71c0.185-0.483,0.526-0.852,1.051-0.895
			c1.776-0.142,3.551-0.313,5.341-0.241c0.966,0.043,1.946,0.128,2.912,0.17c0.824,0.043,1.378,0.44,1.719,1.151
			c0.327,0.653,0.639,1.307,0.909,1.989c1.307,3.324,2.585,6.662,3.892,9.972c0.44,1.136,0.938,2.244,1.42,3.352
			c0.099,0.213,0.256,0.412,0.426,0.582c0.099,0.099,0.298,0.17,0.426,0.142c0.142-0.028,0.284-0.156,0.369-0.284
			s0.156-0.298,0.156-0.44c0-2.429-0.014-4.872-0.043-7.301c0-0.156-0.114-0.298-0.227-0.582c-0.227-0.156-0.526-0.426-0.881-0.611
			c-0.625-0.341-0.98-0.881-1.023-1.562c-0.114-1.776-0.199-3.565,0.27-5.327c0.17-0.611,0.554-0.994,1.165-1.094
			c0.483-0.085,0.966-0.156,1.449-0.156c2.429-0.014,4.872-0.043,7.301,0c2.273,0.043,2.685,0.497,2.713,2.741
			C300.357,611.916,300.357,613.138,300.328,614.359L300.328,614.359z M383.751,648.692c-0.014,0.554-0.54,1.08-1.065,1.179
			c-0.085,0.014-0.156,0.043-0.241,0.043c-2.031,0-4.048,0.043-6.08,0c-0.682-0.014-1.25-0.398-1.563-1.023
			c-0.327-0.653-0.639-1.307-0.909-1.989c-1.321-3.324-2.614-6.648-3.92-9.957c-0.327-0.824-0.668-1.662-1.08-2.443
			c-0.17-0.341-0.497-0.639-0.81-0.866c-0.483-0.355-1.236,0-1.321,0.582c-0.014,0.156-0.057,0.327-0.057,0.483
			c0,2.102,0,4.219,0.014,6.321c0,0.227,0.114,0.469,0.185,0.739c0.497,0.369,1.009,0.753,1.463,1.08
			c0.099,0.242,0.199,0.384,0.227,0.54c0.199,1.776,0.128,3.565,0.043,5.341c-0.028,0.554-0.526,1.065-1.08,1.151
			c-0.398,0.057-0.81,0.114-1.207,0.114c-2.67,0-5.355,0-8.026-0.014c-0.241,0-0.483-0.028-0.724-0.057
			c-0.696-0.085-1.25-0.582-1.293-1.25c-0.099-1.705-0.185-3.395,0.057-5.099c0.014-0.156,0.114-0.298,0.241-0.611
			c1.79-1.42,1.804-1.42,1.804-3.48c0.014-6.08,0.014-12.173,0-18.253c0-1.136-0.071-2.273-0.142-3.409
			c-0.028-0.398-0.17-0.781-0.256-1.165c-0.483-0.369-0.923-0.724-1.335-1.051c-0.099-0.17-0.213-0.313-0.241-0.455
			c-0.341-1.932-0.227-3.878-0.085-5.81c0.014-0.085,0.043-0.156,0.057-0.242c0.099-0.455,0.554-0.895,1.009-0.938
			c0.724-0.085,1.449-0.185,2.173-0.199c1.378-0.043,2.756-0.043,4.134-0.028c1.534,0.028,1.662,0.085,3.437,0.98
			c0.156-0.014,0.341,0.014,0.469-0.057c1.634-0.881,3.409-0.966,5.199-0.909c1.051,0.028,2.102,0.114,3.153,0.142
			c0.767,0.014,1.435,0.256,2.031,0.724c1.463,1.151,2.912,2.33,4.077,3.793c0.398,1.193,0.54,2.386,0.54,3.608
			c-0.014,2.429-0.028,4.872,0,7.301c0.014,1.463-0.142,2.912-0.426,4.304c-0.44,0.852-1.165,1.392-1.776,2.045
			c-0.781,0.824-1.605,1.605-2.372,2.443c-0.526,0.568-0.682,1.25-0.412,2.031c0.767,2.216,1.634,4.403,2.571,6.563
			c0.313,0.696,0.767,1.25,1.563,1.392c0.085,0.014,0.156,0.028,0.241,0.057c1.463,0.384,1.747,0.852,1.79,2.202
			C383.836,645.936,383.794,647.314,383.751,648.692L383.751,648.692z M373.623,616.59c-0.625-0.412-1.335-0.511-2.074-0.483
			c-1.051,0.043-2.102,0.099-3.139,0.156c-0.241,0.014-0.483,0.057-0.724,0.099c-0.455,0.085-0.881,0.526-0.966,0.966
			c-0.028,0.156-0.071,0.313-0.071,0.483c0,1.861,0,3.707,0.014,5.568c0,0.156,0.043,0.313,0.085,0.469
			c0.114,0.511,0.44,0.867,0.909,1.037c0.298,0.114,0.625,0.213,0.938,0.213c1.207,0.014,2.415-0.014,3.636-0.043
			c0.241,0,0.483-0.071,0.71-0.128c0.526-0.128,0.994-0.639,1.08-1.193c0.028-0.156,0.057-0.327,0.071-0.483
			c0.028-1.69,0.057-3.395,0.057-5.085C374.135,617.626,374.049,617.058,373.623,616.59L373.623,616.59z M350.115,643.138
			c0,2.145-0.043,2.614-1.861,4.233c-0.781,0.71-1.619,1.349-2.457,2.003c-0.526,0.412-1.136,0.625-1.804,0.625
			c-0.483,0-0.98,0.014-1.463,0.014c-3.168,0.014-6.335,0.028-9.503,0.028c-3.409,0-3.082-0.099-5.298-1.861
			c-0.185-0.156-0.398-0.298-0.568-0.455c-2.003-1.832-2.116-2.017-2.116-4.602c0.014-8.935-0.014-17.855,0.014-26.775
			c0.014-3.722,0.099-3.665,2.77-6.051c0.185-0.156,0.369-0.313,0.54-0.497c1.136-1.151,2.443-1.832,4.119-1.747
			c0.881,0.057,1.776-0.085,2.67-0.099c2.116-0.014,4.219-0.014,6.335,0c0.653,0,1.293,0.043,1.946,0.085
			c0.753,0.043,1.435,0.298,2.031,0.739c1.307,0.966,2.543,2.003,3.651,3.21c0.625,0.682,0.966,1.491,0.98,2.429
			c0,0.568,0.014,1.136,0.014,1.705c0,4.389,0,8.764,0,13.153C350.115,633.876,350.101,638.507,350.115,643.138L350.115,643.138z
			 M341.706,629.317c-0.014,0-0.028,0-0.043,0c0-3.153,0.014-6.321,0-9.474c0-0.724-0.014-1.463-0.114-2.173
			c-0.128-0.866-0.398-1.335-1.406-1.364c-1.705-0.043-3.395-0.028-5.099-0.028c-0.156,0-0.327,0.043-0.483,0.071
			c-0.469,0.071-0.938,0.483-0.98,0.952c-0.085,0.795-0.156,1.605-0.17,2.415c-0.043,3.324,0.156,6.634,0.071,9.957
			c-0.099,3.395,0.028,6.804,0.057,10.199c0,0.398,0.028,0.81,0.071,1.207c0.057,0.54,0.554,1.051,1.122,1.108
			c1.861,0.213,3.722,0.156,5.568,0.028c0.653-0.043,1.193-0.582,1.293-1.264c0.057-0.313,0.085-0.639,0.085-0.966
			C341.706,636.447,341.706,632.882,341.706,629.317L341.706,629.317z M264.49,617.215c-0.028,1.435-0.483,1.946-1.861,2.017
			s-2.756,0.057-4.133,0.057c-0.568,0-1.122-0.142-1.747-0.597c-0.284-0.753-0.54-1.42-0.767-2.017
			c-0.241-0.128-0.369-0.256-0.526-0.284c-1.449-0.284-2.912-0.213-4.361-0.043c-0.412,0.057-0.71,0.355-0.838,0.781
			c-0.043,0.156-0.099,0.313-0.114,0.469c-0.227,2.031-0.199,4.048,0.043,6.065c0.085,0.682,0.597,1.207,1.264,1.293
			c0.483,0.071,0.966,0.099,1.449,0.114c1.861,0.043,3.736,0.071,5.597,0.128c0.327,0.014,0.639,0.057,0.966,0.114
			c0.412,0.071,0.881,0.597,0.937,1.023c0.043,0.327,0.085,0.639,0.085,0.966c-0.014,1.548-0.028,3.082-0.071,4.616
			c-0.014,0.455-0.511,0.923-0.952,0.966c-0.653,0.057-1.293,0.071-1.946,0.085c-1.619,0.028-3.239,0.028-4.872,0.071
			c-0.483,0.014-0.966,0.071-1.449,0.156c-0.497,0.071-0.895,0.483-0.994,0.952c-0.028,0.156-0.085,0.313-0.099,0.483
			c-0.156,2.031-0.17,4.048,0,6.079c0.014,0.242,0.099,0.483,0.185,0.71c0.114,0.313,0.327,0.554,0.653,0.668
			c0.071,0.028,0.142,0.085,0.227,0.085c1.449,0.185,2.912,0.256,4.361-0.057c0.142-0.028,0.256-0.185,0.469-0.327
			c0.213-0.696,0.455-1.463,0.724-2.287c0.298-0.128,0.582-0.313,0.881-0.355c1.776-0.241,3.565-0.185,5.341-0.057
			c0.085,0,0.156,0.028,0.241,0.043c0.597,0.114,1.065,0.54,1.165,1.094c0.057,0.313,0.128,0.639,0.128,0.966
			c0.014,2.358,0.014,4.702,0.014,7.06c0,0.156-0.043,0.327-0.057,0.483c-0.071,0.54-0.568,1.037-1.136,1.108
			c-0.398,0.057-0.81,0.085-1.207,0.085c-1.222,0.014-2.443,0-3.651,0c-5.114,0-10.227,0.014-15.341,0.014
			c-0.653,0-1.307,0-1.946-0.057c-0.313-0.028-0.625-0.142-0.923-0.284s-0.497-0.398-0.611-0.724
			c-0.028-0.071-0.057-0.156-0.057-0.242c-0.028-1.534-0.085-3.082-0.085-4.616c0-0.696,0.327-1.25,0.938-1.605
			c0.142-0.085,0.284-0.156,0.412-0.256c0.185-0.156,0.355-0.327,0.597-0.54c0.071-0.426,0.241-0.909,0.213-1.378
			c-0.099-1.463,0.028-2.912,0.028-4.375c0.014-5.44,0-10.881,0-16.321c0-0.966,0.014-1.946-0.369-2.912
			c-0.241-0.199-0.483-0.412-0.724-0.611c-0.256-0.199-0.526-0.384-0.696-0.511c-0.128-0.241-0.256-0.369-0.27-0.526
			c-0.099-0.639-0.227-1.278-0.227-1.918c0-1.136,0.085-2.273,0.156-3.409c0.014-0.242,0.057-0.483,0.142-0.71
			c0.142-0.398,0.412-0.753,0.838-0.81c0.966-0.128,1.932-0.256,2.898-0.256c4.957-0.028,9.901-0.028,14.858,0
			c1.534,0,3.082,0.114,4.616,0.185c0.938,0.043,1.463,0.568,1.577,1.534c0.028,0.242,0.057,0.483,0.057,0.724
			C264.504,612.669,264.533,614.942,264.49,617.215L264.49,617.215z M233.567,614.686c-0.043,1.136-0.17,2.258-0.284,3.395
			c-0.043,0.483-0.44,0.909-0.909,1.023c-0.156,0.043-0.312,0.099-0.469,0.114c-1.705,0.128-3.409,0.199-5.099,0.028
			c-0.384-0.043-0.753-0.256-1.165-0.398c-0.298-0.767-0.582-1.52-0.838-2.188c-0.241-0.128-0.369-0.256-0.526-0.284
			c-1.776-0.284-3.551-0.199-5.327-0.043c-0.355,0.028-0.753,0.44-0.824,0.81c-0.071,0.398-0.142,0.795-0.156,1.207
			c-0.028,0.895-0.028,1.79-0.028,2.685c0.014,0.895,0.043,1.79,0.099,2.67c0.057,0.696,0.554,1.179,1.25,1.278
			c0.483,0.071,0.966,0.099,1.449,0.099c1.293,0.014,2.599,0,3.892,0c0.568,0,1.136,0.028,1.705,0.028
			c1.193,0,2.23,0.369,3.097,1.193c1.179,1.108,2.372,2.202,3.508,3.253c0.526,1.406,0.639,2.528,0.625,3.679
			c-0.014,3.253,0,6.492,0,9.744c0,0.327-0.014,0.653-0.028,0.98c-0.071,1.278-0.526,2.358-1.548,3.168
			c-0.895,0.71-1.747,1.449-2.642,2.159c-0.582,0.469-1.264,0.71-2.031,0.71c-0.483,0-0.98,0.014-1.463,0.014
			c-3.239,0-6.491,0-9.744,0.014c-0.923,0-1.79-0.142-2.528-0.724c-1.009-0.81-2.003-1.648-2.983-2.5
			c-0.639-0.554-0.994-1.293-1.08-2.131c-0.099-0.881-0.213-1.79-0.128-2.67c0.256-2.713,0.284-2.798,3.026-3.054
			c1.463-0.142,2.912,0.014,4.361,0.298c0.156,0.028,0.284,0.156,0.426,0.227c0.469,0.426,0.426,1.037,0.597,1.563
			c0.284,0.866,0.653,1.207,1.591,1.236c1.378,0.043,2.756,0.028,4.133-0.014c0.866-0.028,1.406-0.554,1.449-1.349
			c0.114-2.188,0.17-4.375-0.057-6.563c-0.071-0.597-0.511-1.051-1.108-1.122c-0.568-0.057-1.136-0.099-1.69-0.099
			c-1.548-0.014-3.082,0-4.631-0.014c-0.483,0-0.966-0.057-1.463-0.057c-0.937,0-1.733-0.313-2.429-0.938
			c-0.966-0.866-1.946-1.719-2.926-2.571c-0.639-0.554-1.009-1.278-1.122-2.102c-0.085-0.554-0.156-1.122-0.156-1.69
			c-0.014-3.565-0.014-7.145,0-10.71c0-0.412,0.014-0.81,0.085-1.207c0.114-0.739,0.398-1.42,0.952-1.932
			c1.08-0.98,2.173-1.96,3.281-2.912c0.696-0.597,1.52-0.966,2.443-0.98c1.79-0.028,3.565-0.043,5.355-0.014
			c0.653,0,1.278,0.199,1.875,0.469c0.611,0.27,1.264,0.327,1.889,0.028c0.98-0.483,2.031-0.54,3.082-0.54
			c1.293,0.014,2.599-0.099,3.878,0.213c0.511,0.128,0.852,0.455,0.994,0.966c0.057,0.227,0.099,0.469,0.142,0.71
			C233.667,611.448,233.624,613.067,233.567,614.686L233.567,614.686z M399.291,624.871c0.54,0.128,1.108,0.199,1.676,0.227
			c1.861,0.057,3.736,0.085,5.597,0.114c0.241,0,0.483,0.014,0.724,0.014c0.838,0.014,1.605,0.298,2.23,0.838
			c1.151,1.023,2.287,2.06,3.395,3.139c0.653,0.639,1.009,1.463,1.009,2.415c0,0.483,0.014,0.98,0.014,1.463c0,3.239,0,6.491,0,9.73
			c0,0.483-0.014,0.98,0,1.463c0,0.852-0.256,1.619-0.881,2.187c-1.08,0.98-2.173,1.946-3.295,2.883
			c-0.511,0.426-1.136,0.611-1.804,0.625c-0.327,0.014-0.653,0.014-0.98,0.014c-3.324,0-6.648,0-9.986,0
			c-0.327,0-0.653-0.014-0.98-0.014c-0.668-0.028-1.278-0.256-1.818-0.625c-1.335-0.938-2.514-2.06-3.58-3.281
			c-0.256-0.284-0.469-0.696-0.511-1.08c-0.227-1.619-0.142-3.239,0.156-4.83c0.085-0.426,0.369-0.724,0.781-0.852
			c0.313-0.099,0.625-0.185,0.952-0.185c1.378-0.028,2.756-0.043,4.134,0c0.469,0.014,0.938,0.185,1.577,0.327
			c0.241,0.71,0.44,1.236,0.597,1.776c0.185,0.611,0.568,0.994,1.193,1.051c1.619,0.142,3.239,0.156,4.858,0
			c0.526-0.057,0.895-0.398,1.023-0.909c0.114-0.384,0.199-0.795,0.199-1.193c-0.014-1.705-0.057-3.409-0.114-5.114
			c-0.057-1.449-0.483-1.875-1.918-1.903c-1.136-0.028-2.273,0-3.409-0.014c-1.222-0.014-2.429-0.071-3.651-0.099
			c-0.753-0.014-1.435-0.227-2.031-0.71c-0.938-0.767-1.861-1.563-2.798-2.33c-1.136-0.938-1.747-2.116-1.662-3.608
			c0.014-0.156,0-0.327,0-0.483c0-3.565,0.043-7.145-0.014-10.71c-0.028-1.577,0.384-2.855,1.619-3.864
			c1.009-0.824,1.974-1.69,2.983-2.5c0.511-0.412,1.122-0.625,1.79-0.71c1.861-0.227,3.722-0.213,5.582-0.071
			c0.653,0.057,1.278,0.199,1.875,0.483c0.369,0.17,0.767,0.256,1.193,0.384c1.548-0.852,3.224-0.994,4.943-0.866
			c0.895,0.057,1.776,0.071,2.656,0.185c0.611,0.071,0.98,0.54,1.151,1.122c0.085,0.313,0.142,0.639,0.142,0.952
			c0.014,2.358,0.014,4.702,0,7.06c0,0.242-0.057,0.483-0.114,0.71c-0.099,0.426-0.369,0.71-0.753,0.895
			c-0.227,0.099-0.455,0.199-0.696,0.227c-1.69,0.213-3.395,0.227-5.099,0.014c-0.156-0.014-0.312-0.071-0.469-0.114
			c-0.483-0.156-0.881-0.412-1.051-0.923c-0.17-0.54-0.241-1.122-0.739-1.619c-0.213-0.071-0.511-0.213-0.824-0.242
			c-1.136-0.099-2.259-0.17-3.395-0.213c-0.398-0.014-0.81,0.057-1.193,0.142c-0.753,0.156-1.193,0.597-1.222,1.307
			c-0.114,2.102-0.156,4.219,0.028,6.321C398.439,624.374,398.794,624.757,399.291,624.871L399.291,624.871z M318.766,643.109
			c0.071,0.412,0.17,0.795,0.185,1.193c0.057,0.895,0.114,1.776,0.099,2.67c0,0.639-0.085,1.293-0.17,1.932
			c-0.057,0.455-0.54,0.909-0.98,0.98c-0.327,0.057-0.639,0.099-0.966,0.099c-2.841,0-5.668,0-8.508,0
			c-0.327,0-0.653-0.043-0.966-0.114c-0.511-0.128-0.852-0.455-1.009-0.952c-0.071-0.227-0.142-0.469-0.142-0.71
			c-0.014-1.293-0.043-2.585-0.014-3.892c0.014-0.852,0.313-1.591,1.165-1.974c0.582-0.27,0.824-0.753,0.881-1.349
			c0.028-0.327,0.043-0.639,0.043-0.966c0-7.216,0-14.418-0.014-21.633c0-0.554-0.128-1.108-0.199-1.69
			c-0.27-0.227-0.313-0.298-0.384-0.341c-1.193-0.54-1.605-1.506-1.563-2.756c0.028-0.966-0.014-1.946,0.028-2.912
			c0.014-0.483,0.099-0.966,0.213-1.435c0.142-0.582,0.526-1.023,1.151-1.122c0.554-0.099,1.122-0.17,1.69-0.185
			c2.273-0.014,4.531-0.014,6.804,0c0.483,0,0.966,0.071,1.449,0.142c0.795,0.128,1.307,0.653,1.307,1.506
			c0.028,1.776,0,3.565-0.028,5.341c0,0.156-0.099,0.298-0.199,0.568c-0.412,0.327-0.909,0.724-1.42,1.122
			c-0.085,0.242-0.213,0.469-0.227,0.696c-0.043,0.724-0.057,1.463-0.057,2.188c0,6.477-0.014,12.969,0,19.446
			c0,0.881-0.114,1.79,0.227,2.756C317.643,642.143,318.197,642.612,318.766,643.109L318.766,643.109z"
          />
        </g>
      </g>
      <text
        transform="matrix(1.0375 0 0 1 314.2773 472.0791)"
        stroke={primary}
        stroke-width="20"
        stroke-miterlimit="10"
        font-family="'College'"
        font-size="375.0536"
      >
        1
      </text>
    </svg>
  );
}

Artwrk9.props = {
  primary: string,
};

Artwrk9.defaultProps = {
  primary: "#000",
};
