import React from "react";
import { string } from "prop-types";

export default function Artwrk4(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      fill={primary}
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="Shape_1_1_" enable-background="new    ">
        <g id="Shape_1">
          <g>
            <path
              d="M609.916,502.485c-2.36-0.29-4.402-1.338-6.554-1.959c-2.166-0.621-4.291-1.394-6.444-2.084
				c-2.153-0.69-4.305-1.38-6.458-2.056s-4.319-1.311-6.472-1.973s-4.305-1.352-6.472-2.001c-2.166-0.649-4.333-1.283-6.499-1.918
				s-4.319-1.311-6.513-1.835c-2.166-0.511-4.222-1.49-6.637-1.587c-0.069,0.524-0.166,0.938-0.166,1.338
				c0,17.373,0.014,34.759,0,52.132c0,4.623-0.773,9.162-1.959,13.633c-2.732,10.294-7.631,19.539-13.592,28.273
				c-6.541,9.604-14.461,17.994-22.713,26.107c-6.016,5.92-12.419,11.398-18.959,16.71c-7.562,6.127-15.372,11.922-23.403,17.428
				c-5.947,4.084-11.922,8.114-18.007,11.977c-5.933,3.781-12.019,7.313-18.021,10.984c-9.852,6.03-19.994,11.577-30.178,17.028
				c-13.868,7.41-27.915,14.475-42.141,21.181c-9.811,4.623-19.732,9.066-29.653,13.468c-8.183,3.629-16.434,7.065-24.658,10.584
				c-2.387,1.021-4.788,2.042-7.162,3.105c-0.869,0.386-1.656,0.345-2.498-0.014c-10.294-4.402-20.615-8.762-30.895-13.205
				c-16.834-7.272-33.379-15.123-49.758-23.347c-11.26-5.657-22.451-11.439-33.42-17.635c-7.106-4.015-14.213-8.031-21.264-12.17
				c-4.871-2.856-9.604-5.933-14.406-8.9c-8.886-5.478-17.497-11.356-25.955-17.455c-5.699-4.112-11.232-8.472-16.752-12.833
				c-9.825-7.769-19.111-16.145-27.584-25.39c-6.347-6.927-12.226-14.24-17.207-22.23c-4.526-7.258-8.114-14.972-10.321-23.265
				c-1.311-4.954-2.084-10.004-2.07-15.179c0.055-15.813,0.014-31.627,0.014-47.44c0-0.869,0.028-1.739-0.041-2.608
				c-0.028-0.4-0.221-0.787-0.4-1.325c-8.983,2.484-17.787,5.119-26.549,7.851c-8.679,2.705-17.29,5.602-26.038,8.472
				c-0.856-2.249-1.739-4.181-2.125-6.458c1.711-0.621,3.312-1.242,4.954-1.78c5.602-1.849,11.205-3.739,16.834-5.492
				c7.713-2.387,15.441-4.705,23.196-6.982c6.334-1.863,12.681-3.657,19.042-5.381c4.857-1.311,9.756-2.47,14.64-3.698
				c3.036-0.759,6.044-1.573,9.093-2.291c5.754-1.325,11.508-2.608,17.276-3.891c3.298-0.731,6.61-1.463,9.921-2.153
				c4.084-0.856,8.155-1.697,12.253-2.47c7.244-1.366,14.502-2.732,21.774-4.002c4.705-0.828,9.425-1.532,14.144-2.249
				c5.492-0.842,10.984-1.67,16.476-2.47c2.746-0.4,5.519-0.704,8.279-1.035c5.423-0.649,10.86-1.325,16.282-1.946
				c4.14-0.469,8.293-0.897,12.446-1.283c2.939-0.276,5.892-0.428,8.831-0.676c1.38-0.11,2.76-0.29,4.14-0.428
				c5.009-0.511,10.032-0.897,15.068-1.09c4.333-0.166,8.666-0.635,12.998-0.869c5.023-0.276,10.059-0.455,15.096-0.635
				c5.726-0.207,11.467-0.4,17.193-0.538c3.56-0.097,7.12-0.097,10.68-0.11c6.168-0.014,12.336-0.069,18.518,0
				c5.382,0.069,10.763,0.304,16.144,0.455c4.25,0.11,8.514,0.166,12.764,0.304c3.822,0.138,7.644,0.331,11.453,0.538
				c5.119,0.29,10.239,0.552,15.344,0.925c5.106,0.373,10.211,0.883,15.317,1.338c0.428,0.041,0.869,0.11,1.297,0.11
				c4.181-0.028,8.307,0.593,12.46,1.076c3.367,0.386,6.748,0.662,10.101,1.062c6.554,0.773,13.095,1.601,19.649,2.415
				c4.65,0.58,9.3,1.145,13.937,1.808c4.471,0.635,8.928,1.352,13.385,2.111c5.823,0.98,11.646,1.959,17.455,3.049
				c6.14,1.145,12.281,2.373,18.394,3.643c8.5,1.752,17,3.519,25.472,5.382c4.995,1.104,9.977,2.346,14.93,3.643
				c10.832,2.829,21.664,5.671,32.455,8.652c6.858,1.89,13.661,4.015,20.464,6.127c6.14,1.904,12.239,3.919,18.352,5.892
				c1.145,0.373,2.318,0.69,3.464,1.366C611.71,498.566,610.896,500.511,609.916,502.485L609.916,502.485z M537.004,481.455
				c-0.345-0.138-0.731-0.331-1.145-0.428c-3.546-0.869-7.079-1.725-10.639-2.553c-4.816-1.118-9.632-2.249-14.475-3.284
				c-5.947-1.27-11.894-2.498-17.856-3.657c-7.672-1.49-15.344-2.953-23.03-4.333c-4.871-0.883-9.769-1.628-14.654-2.415
				c-4.374-0.704-8.748-1.407-13.123-2.042c-3.353-0.483-6.72-0.883-10.073-1.325c-4.65-0.607-9.3-1.27-13.95-1.794
				c-6.306-0.704-12.612-1.366-18.918-1.918c-5.533-0.483-11.053-1.297-16.627-1.366c-0.869-0.014-1.725-0.152-2.594-0.221
				c-5.892-0.414-11.784-0.897-17.676-1.242c-5.644-0.317-11.287-0.511-16.931-0.745c-5.989-0.248-11.977-0.566-17.966-0.676
				c-7.12-0.138-14.254-0.041-21.374-0.11c-9.907-0.083-19.815,0.124-29.709,0.497c-8.334,0.317-16.669,0.759-25.003,1.145
				c-0.345,0.014-0.69,0.041-1.035,0.069c-8.224,0.635-16.462,1.242-24.686,1.89c-4.25,0.345-8.486,0.717-12.722,1.173
				c-5.878,0.621-11.729,1.311-17.593,2.001c-3.284,0.373-6.568,0.745-9.838,1.187c-5.671,0.787-11.343,1.614-17.014,2.456
				c-4.816,0.704-9.631,1.38-14.42,2.194c-6.679,1.132-13.33,2.373-19.994,3.615c-4.443,0.814-8.873,1.656-13.288,2.567
				c-3.739,0.759-7.465,1.628-11.191,2.442c-5.423,1.173-10.86,2.318-16.269,3.532c-4.153,0.938-8.279,1.959-12.405,2.981
				c-1.752,0.442-3.491,0.98-5.119,1.449c-0.524,1.2-0.359,2.249-0.359,3.284c-0.014,17.635,0,35.283-0.014,52.918
				c0,3.767,0.635,7.465,1.628,11.053c1.518,5.451,3.891,10.584,6.817,15.441c3.05,5.064,6.582,9.783,10.335,14.351
				c5.657,6.858,11.991,13.054,18.559,19.001c5.009,4.554,10.239,8.859,15.634,12.971c3.381,2.58,6.748,5.188,10.225,7.644
				c6.03,4.264,12.06,8.528,18.214,12.598c7.907,5.202,15.979,10.156,24.12,14.985c12.115,7.175,24.369,14.075,36.856,20.574
				c8.472,4.402,17.014,8.707,25.583,12.929c7.327,3.601,14.696,7.106,22.119,10.515c7.493,3.436,15.054,6.761,22.616,10.032
				c5.257,2.263,10.611,4.347,15.896,6.541c1.518,0.635,2.939,0.662,4.485,0.014c5.768-2.415,11.605-4.664,17.345-7.148
				c10.597-4.595,21.14-9.314,31.571-14.268c10.984-5.216,21.912-10.528,32.717-16.117c13.813-7.148,27.473-14.585,40.83-22.547
				c8.362-4.981,16.614-10.142,24.796-15.399c8.334-5.354,16.407-11.08,24.313-17.041c2.774-2.097,5.561-4.167,8.252-6.361
				c4.029-3.312,8.031-6.651,11.922-10.114c8.721-7.755,16.876-16.048,23.803-25.472c4.126-5.616,7.7-11.549,10.266-18.063
				c2.097-5.313,3.215-10.777,3.174-16.517c-0.083-10.17-0.028-20.339-0.028-30.495c0-7.824,0.014-15.634-0.014-23.458
				C537.363,483.401,537.584,482.421,537.004,481.455L537.004,481.455z M549.119,487.444c0-0.607-0.014-1.214-0.028-1.821
				c-0.014-0.814-0.469-1.27-1.228-1.476c-1.766-0.469-3.519-0.925-5.299-1.366c-0.372-0.097-0.786-0.083-1.325-0.124
				c0,2.249,0,4.319,0,6.389c0,15.813,0.014,31.627-0.014,47.44c-0.014,5.492-0.676,10.901-2.387,16.172
				c-2.304,7.093-5.864,13.536-10.032,19.649c-3.036,4.457-6.499,8.583-10.059,12.626c-4.774,5.423-9.921,10.446-15.248,15.317
				c-6.996,6.389-14.351,12.364-21.926,18.049c-10.004,7.507-20.312,14.599-30.923,21.25c-6.844,4.291-13.785,8.417-20.726,12.571
				c-11.549,6.927-23.403,13.316-35.325,19.567c-14.24,7.451-28.674,14.502-43.314,21.14c-7.907,3.588-15.882,7.01-23.817,10.556
				c-6.347,2.843-12.833,5.326-19.235,8.003c-1.49,0.621-2.801,0.649-4.222-0.055c-1.325-0.648-2.691-1.2-4.071-1.739
				c-13.316-5.285-26.411-11.094-39.45-17.041c-7.507-3.422-14.958-6.968-22.354-10.639c-9.093-4.526-18.131-9.149-27.101-13.909
				c-7.824-4.167-15.524-8.555-23.237-12.902c-11.646-6.582-23.03-13.606-34.193-21.002c-11.011-7.299-21.747-14.985-32.041-23.265
				c-7.658-6.154-14.999-12.667-21.871-19.705c-7.727-7.934-14.737-16.42-20.201-26.093c-2.097-3.712-3.933-7.548-5.23-11.618
				c-1.021-3.215-2.042-6.472-2.222-9.88c-0.11-2.249-0.207-4.512-0.221-6.775c-0.028-14.254-0.014-28.494-0.041-42.748
				c0-3.036-0.055-6.071-0.124-9.107c-0.014-0.386-0.193-0.759-0.304-1.2c-2.608,0.566-5.05,1.132-7.658,2.125
				c0,0.869,0,1.559,0,2.249c0,16.6,0.055,33.186-0.014,49.786c-0.041,8.321,1.794,16.2,5.119,23.748
				c2.318,5.257,5.078,10.252,8.307,15.027c4.733,6.996,10.073,13.509,15.882,19.622c8.334,8.776,17.386,16.752,26.852,24.272
				c8.983,7.148,18.283,13.84,27.791,20.256c11.094,7.479,22.519,14.447,34.069,21.195c12.074,7.051,24.396,13.674,36.815,20.105
				c8.942,4.636,18.007,9.052,27.101,13.371c10.666,5.05,21.429,9.949,32.192,14.806c4.981,2.249,10.059,4.278,15.11,6.403
				c3.767,1.587,7.548,3.105,11.287,4.733c1.214,0.538,2.277,0.51,3.477,0c4.885-2.056,9.811-3.988,14.682-6.071
				c6.071-2.594,12.115-5.244,18.132-7.962c8.707-3.919,17.4-7.893,26.066-11.894c3.629-1.67,7.175-3.505,10.777-5.23
				c14.102-6.761,27.929-14.047,41.589-21.664c12.446-6.941,24.713-14.157,36.705-21.871c9.797-6.306,19.387-12.888,28.729-19.843
				c6.968-5.188,13.743-10.639,20.284-16.352c2.815-2.456,5.478-5.078,8.183-7.658c6.513-6.265,12.805-12.736,18.297-19.967
				c5.809-7.644,10.956-15.661,14.295-24.713c2.235-6.071,3.712-12.281,3.698-18.808
				C549.078,522.024,549.119,504.734,549.119,487.444L549.119,487.444z M609.847,358.054c-6.872-2.084-13.564-4.457-20.353-6.513
				c-6.817-2.056-13.605-4.195-20.45-6.154c-6.83-1.973-13.702-3.822-20.588-5.63c-6.803-1.794-13.606-3.546-20.45-5.188
				c-6.927-1.67-13.868-3.229-20.836-4.733c-6.968-1.49-13.937-2.912-20.933-4.236c-6.996-1.325-14.02-2.484-21.029-3.739
				c-7.01-1.256-14.033-2.387-21.098-3.284s-14.102-1.987-21.167-2.843c-7.065-0.869-14.157-1.601-21.236-2.304
				c-6.996-0.704-14.006-1.366-21.015-1.904c-7.106-0.552-14.213-1.035-21.333-1.394s-14.254-0.4-21.36-0.814
				c-7.12-0.414-14.24-0.386-21.374-0.455c-7.12-0.055-14.254-0.055-21.374,0.055s-14.24,0.414-21.36,0.566
				s-14.24,0.455-21.347,0.952c-7.106,0.497-14.213,0.925-21.319,1.504c-7.01,0.58-14.006,1.311-21.002,2.015
				c-7.093,0.718-14.171,1.407-21.236,2.277c-7.065,0.883-14.102,1.918-21.14,3.008c-7.037,1.076-14.047,2.249-21.057,3.463
				c-7.01,1.228-14.019,2.498-21.002,3.877c-6.982,1.38-13.95,2.843-20.905,4.374c-6.955,1.532-13.895,3.105-20.808,4.802
				c-6.83,1.67-13.633,3.45-20.422,5.285c-6.872,1.849-13.73,3.767-20.56,5.768s-13.633,4.112-20.422,6.265
				c-6.775,2.153-13.509,4.416-20.381,6.665c-1.159-1.918-1.656-3.988-2.263-5.975c0.552-0.814,1.338-0.952,2.07-1.187
				c6.099-2.015,12.198-4.057,18.311-6.03c6.113-1.959,12.253-3.891,18.394-5.74c4.319-1.311,8.679-2.47,13.026-3.712
				c1.076-0.304,2.125-0.676,3.298-1.049c0.028-1.063,0.069-2.001,0.069-2.939c0-48.406,0-96.812,0-145.218
				c0-16.517,0-33.02,0.014-49.537c0-0.925-0.193-1.89,0.262-2.746c2.525-0.373,47.316-0.497,59.928-0.193
				c0.842,1.366,1.642,2.898,2.677,4.264c1.021,1.366,2.277,2.567,3.56,3.988c-0.469,0.11-0.856,0.262-1.228,0.262
				c-2.608,0.014-5.216,0-7.81,0c-15.206,0-30.426,0-45.632,0c-0.938,0-1.89,0.014-2.925,0.028c-0.11,0.662-0.248,1.159-0.262,1.656
				c-0.041,0.952-0.014,1.918-0.014,2.87c0,60.742,0,121.484,0,182.226c0,0.856,0,1.711,0,2.553c1.794,0.193,5.478-0.69,7.92-1.863
				c0.055-0.511,0.166-1.09,0.179-1.683c0.041-1.035,0.028-2.084,0.028-3.132c0-48.061,0-96.122,0-144.169
				c0-8.597,0-17.207,0.014-25.804c0-0.856-0.152-1.739,0.166-2.498c0.635-0.635,1.352-0.442,2.028-0.442
				c5.823,0,11.646,0.028,17.469,0.028c8.252,0,16.503-0.041,24.769-0.041c9.218,0.014,18.421,0.055,27.639,0.083
				c0.787,0,1.559-0.041,2.346-0.014c0.69,0.028,1.394,0.041,2.042,0.262c2.304,0.759,4.581,1.601,6.872,2.429
				c0.235,0.083,0.414,0.304,0.8,0.58c-0.386,0.179-0.649,0.428-0.911,0.414c-2.953-0.041-5.906-0.193-8.845-0.193
				c-8.086-0.028-16.158-0.014-24.244-0.014c-13.73,0-27.459-0.014-41.189,0c-1.642,0-3.284,0.069-5.037,0.097
				c-0.152,0.552-0.359,1.035-0.386,1.518c-0.069,0.952-0.041,1.904-0.041,2.87c0,55.015,0,110.017,0.014,165.032
				c0,1.2-0.207,2.415,0.262,3.753c0.414-0.028,0.828,0,1.214-0.097c4.485-1.063,8.942-2.18,13.426-3.215
				c6.941-1.601,13.881-3.174,20.836-4.678c4.498-0.98,9.011-1.835,13.523-2.718c6.734-1.311,13.454-2.636,20.201-3.864
				c4.692-0.855,9.425-1.559,14.13-2.291c5.147-0.8,10.294-1.601,15.455-2.318c4.816-0.676,9.632-1.269,14.461-1.877
				c6.899-0.856,13.799-1.711,20.698-2.525c1.89-0.221,3.808-0.29,5.713-0.455c6.844-0.58,13.675-1.159,20.519-1.752
				c3.201-0.276,6.403-0.566,9.604-0.828c1.297-0.11,2.594-0.193,3.905-0.248c4.167-0.179,8.334-0.317,12.502-0.511
				c5.202-0.248,10.404-0.607,15.606-0.828c3.643-0.152,7.299-0.166,10.942-0.221c16.862-0.262,33.724-0.166,50.586,0.207
				c5.036,0.11,10.059,0.511,15.096,0.759c3.56,0.179,7.12,0.304,10.68,0.497c3.118,0.166,6.237,0.373,9.369,0.593
				c5.368,0.386,10.749,0.773,16.117,1.214c3.726,0.304,7.438,0.704,11.149,1.09c7.686,0.8,15.386,1.587,23.058,2.47
				c4.747,0.552,9.466,1.256,14.199,1.918c6.361,0.897,12.722,1.766,19.084,2.746c3.864,0.593,7.7,1.352,11.549,2.015
				c4.278,0.731,8.569,1.421,12.833,2.18c3.505,0.635,6.996,1.325,10.473,2.028c6.72,1.366,13.44,2.718,20.146,4.167
				c5.34,1.159,10.653,2.47,15.979,3.712c2.194,0.511,4.402,1.035,6.596,1.518c0.58,0.124,1.187,0.166,1.932,0.248
				c0.097-0.566,0.221-1.049,0.248-1.532c0.041-0.869,0.014-1.739,0.014-2.608c0-54.836,0-109.672-0.014-164.494
				c0-1.104,0.193-2.235-0.428-3.588c-5.561,0-10.942,0-16.324,0c-19.732,0-39.464-0.014-59.196,0c-1.297,0-2.594,0.152-3.905,0.221
				c-0.414,0.028-0.828,0-1.187,0c-0.276-0.566,0-0.773,0.331-0.897c2.36-0.856,4.719-1.739,7.106-2.539
				c0.649-0.221,1.366-0.262,2.056-0.262c11.549-0.028,23.113-0.014,34.662-0.028c8.431-0.014,16.862-0.041,25.293-0.055
				c4.526,0,9.038,0.028,13.564,0.041c0.428,0,0.856,0.055,1.449,0.11c0.138,0.58,0.359,1.145,0.373,1.711
				c0.055,2.263,0.055,4.512,0.055,6.775c0,8.169,0,16.338,0,24.507c0,18.946,0,37.891,0,56.823c0,21.029,0,42.058,0,63.088
				c0,7.644-0.041,15.289-0.055,22.933c0,0.248,0.097,0.497,0.138,0.718c2.484,1.201,5.575,1.959,7.465,1.835
				c0.538-0.773,0.317-1.67,0.317-2.511c0.028-6.002,0.041-11.991,0.041-17.993c0-30.675,0-61.349,0-92.024
				c0-22.506-0.028-45.011-0.028-67.517c0-2.001,0.014-4.002,0-6.002c0-0.593-0.097-1.187-0.166-1.904
				c-0.58-0.11-1.063-0.235-1.559-0.29c-0.345-0.041-0.69-0.014-1.049-0.014c-14.861,0.014-29.722,0.028-44.597,0.041
				c-3.298,0-6.61-0.014-9.907-0.028c-0.4,0-0.8-0.097-1.407-0.179c0.566-1.297,1.766-1.656,2.387-2.525
				c0.704-0.993,1.463-1.946,2.111-2.967c0.593-0.938,1.09-1.946,1.601-2.884c20.229,0,40.292,0,60.355,0.014
				c0.152,0,0.304,0.152,0.649,0.331c0.345,2.36,0.11,4.871,0.152,7.355c0.041,2.608,0.014,5.216,0,7.824c0,2.525,0,5.037,0,7.562
				c0,2.608,0,5.216,0,7.824c0,2.525,0,5.037,0,7.562s0,5.037,0,7.562c0,2.608,0,5.216,0,7.824c0,2.525,0,5.037,0,7.562
				s0,5.037,0,7.562c0,2.608,0,5.216,0,7.824c0,2.525,0,5.037,0,7.562c0,2.608,0,5.216,0,7.824c0,2.525,0,5.037,0,7.562
				s0,5.037,0,7.562c0,2.608,0,5.216,0,7.824c0,2.525,0,5.037,0,7.562c0,2.608,0,5.216,0,7.824c0,2.525,0,5.036,0,7.562
				c0,2.525,0,5.036,0,7.562c0,2.608,0,5.216,0,7.824c0,2.525,0,5.037,0,7.562c0,2.525,0,5.036,0,7.562c0,2.608,0,5.216,0,7.824
				c0,2.525,0,5.037,0,7.562c0,2.608,0,5.216,0,7.824c0,2.511,0,5.023,0,7.644c2.967,1.214,6.03,1.849,8.997,2.732
				c3.077,0.911,6.182,1.766,9.259,2.663c2.994,0.869,6.002,1.766,8.983,2.677c2.994,0.911,5.961,1.863,8.942,2.801
				c3.063,0.966,6.14,1.904,9.176,2.925c2.939,0.993,5.933,1.835,8.886,3.077C611.696,354.024,610.938,355.998,609.847,358.054
				L609.847,358.054z"
            />
          </g>
        </g>
      </g>
      <g id="Shape_1_copy_4_1_" enable-background="new    ">
        <g id="Shape_1_copy_4">
          <g>
            <path
              d="M443.752,627.763c0.262,0.731-0.193,0.994-0.635,1.269c-2.732,1.697-5.437,3.422-8.183,5.092
				c-10.556,6.375-21.25,12.515-32.082,18.421c-14.778,8.086-29.791,15.744-45.011,22.961c-9.411,4.457-18.918,8.748-28.425,12.985
				c-7.134,3.174-14.378,6.127-21.54,9.231c-1.214,0.524-2.263,0.524-3.477,0c-5.175-2.249-10.377-4.416-15.565-6.637
				c-12.929-5.561-25.707-11.425-38.36-17.58c-15.234-7.41-30.343-15.054-45.108-23.334c-9.314-5.216-18.504-10.68-27.722-16.062
				c-2.553-1.49-5.037-3.077-7.548-4.636c-0.317-0.193-0.552-0.538-0.911-0.897c0.4-0.207,0.607-0.4,0.842-0.428
				c7.134-0.938,14.282-1.821,21.416-2.829c6.886-0.966,13.799-1.642,20.698-2.373c5.009-0.538,10.018-1.132,15.027-1.545
				c6.665-0.552,13.343-0.966,20.008-1.407c4.761-0.317,9.535-0.593,14.309-0.842c5.464-0.276,10.929-0.538,16.407-0.704
				c5.644-0.179,11.287-0.248,16.931-0.317c5.906-0.069,11.812-0.166,17.718-0.097c11.646,0.152,23.292,0.248,34.911,0.704
				c11.025,0.428,22.023,1.283,33.034,1.987c4.498,0.29,8.997,0.676,13.495,1.049c1.559,0.124,3.105,0.359,4.65,0.538
				c5.782,0.649,11.563,1.297,17.345,1.946c3.367,0.373,6.734,0.718,10.087,1.145c3.877,0.483,7.741,1.035,11.619,1.532
				c1.808,0.235,3.615,0.414,5.423,0.635C443.352,627.57,443.573,627.694,443.752,627.763L443.752,627.763z"
            />
          </g>
        </g>
      </g>
      <g id="Shape_1_copy_3_1_" enable-background="new    ">
        <g id="Shape_1_copy_3">
          <g>
            <path
              d="M301.982,564.744c-2.939,2.843-5.823,5.727-8.666,8.652c-0.842,0.869-1.739,1.269-2.967,1.256
				c-8.514-0.041-17.028-0.041-25.541,0c-1.214,0-2.125-0.345-2.981-1.228c-2.967-3.049-5.989-6.058-9.024-9.024
				c-0.814-0.8-1.118-1.642-1.104-2.774c0.041-6.168,0.014-12.336,0.014-18.504c0-12.681,0-25.362,0-38.057
				c0-0.607-0.014-1.214,0-1.821c0.041-2.718-0.317-2.125,1.821-4.278c2.139-2.153,4.291-4.305,6.458-6.444
				c1.049-1.035,2.139-2.028,3.201-3.049c1.476-0.055,2.939-0.138,4.416-0.138c7.12-0.014,14.254-0.014,21.374-0.014
				c0.952,0,1.904,0,3.036,0c3.56,3.588,6.982,7.037,10.432,10.473c0.593,0.593,0.938,1.228,0.897,2.07
				c-0.028,0.524,0,1.049,0,1.559c0,19.028,0,38.057,0,57.085c0,0.345-0.041,0.704,0,1.049
				C303.5,562.895,302.92,563.834,301.982,564.744L301.982,564.744z M285.989,553.015c0-14.061,0-28.108,0-42.169
				c0-0.524-0.028-1.049,0-1.559c0.041-0.828-0.166-1.532-0.8-2.097c-0.386-0.345-0.731-0.745-1.063-1.145
				c-0.649-0.787-1.449-1.118-2.498-1.09c-2.608,0.055-5.202,0.041-7.81,0.014c-0.924-0.014-1.711,0.179-2.318,0.911
				c-0.221,0.262-0.469,0.524-0.745,0.731c-1.38,1.035-1.725,2.387-1.711,4.084c0.083,7.106,0.028,14.226,0.028,21.347
				c0,3.643,0,7.286,0,10.929s0.069,7.286-0.028,10.929c-0.041,1.532,0.4,2.691,1.587,3.615c0.138,0.11,0.262,0.235,0.373,0.359
				c0.704,0.883,1.614,1.187,2.746,1.145c2.084-0.069,4.167-0.014,6.251-0.014c2.939,0,2.939,0,4.899-2.166
				c0.11-0.124,0.235-0.262,0.359-0.386c0.511-0.524,0.759-1.132,0.718-1.877C285.962,554.064,285.989,553.54,285.989,553.015
				L285.989,553.015z M218.5,528.15c-3.491,2.042-7.203,3.891-10.846,5.864c-3.588,1.946-7.189,3.85-10.791,5.782
				c-3.67,1.973-7.341,3.933-10.929,5.864c-0.414,2.47-0.373,11.136,0.041,13.081c0.221,0.069,0.455,0.221,0.704,0.221
				c4.512,0.014,9.038,0.014,13.55,0c0.235,0,0.483-0.138,0.883-0.276c0.469-2.511-0.055-5.119,0.331-7.769
				c1.394-0.469,2.787-0.221,4.14-0.248c1.476-0.041,2.953-0.014,4.429-0.014c1.394,0,2.774-0.028,4.167,0.014
				c1.366,0.028,2.76-0.166,4.25,0.221c0.373,2.594,0.138,5.188,0.179,7.769c0.041,2.608,0.014,5.216,0.014,7.81
				s-0.014,5.188-0.014,7.672c-1.049,0.731-2.028,0.483-2.953,0.483c-10.252,0.014-20.505,0.014-30.744,0.014
				c-4.609,0-9.204,0-13.813,0c-0.856,0-1.725,0.11-2.539-0.235c-0.11-0.317-0.276-0.552-0.276-0.8
				c-0.014-12.239-0.014-24.479-0.014-36.594c0.524-0.966,1.407-1.214,2.153-1.614c9.259-4.954,18.532-9.88,27.804-14.834
				c0.98-0.524,1.959-1.076,3.008-1.656c0.11-2.346,0.055-4.595,0.055-6.844c0.014-4.098,0-4.098-3.091-6.858
				c-0.069-0.055-0.138-0.097-0.386-0.248c-3.118,0-6.416-0.028-9.714,0.028c-0.469,0-0.98,0.29-1.394,0.552
				c-0.428,0.29-0.718,0.759-1.131,1.076c-1.076,0.814-1.407,1.877-1.311,3.201c0.097,1.463,0.028,2.939,0.028,4.526
				c-2.498,0.414-14.613,0.428-17.097,0.055c-0.055-0.345-0.193-0.745-0.193-1.159c-0.014-3.739,0.014-7.465-0.028-11.205
				c-0.014-0.938,0.304-1.642,0.966-2.291c3.477-3.394,6.927-6.817,10.57-10.432c0.524,0,1.311,0,2.084,0
				c8.072,0,16.158-0.014,24.231,0c2.856,0,2.166-0.276,4.278,1.821c2.774,2.746,5.519,5.533,8.307,8.279
				c0.787,0.773,1.228,1.559,1.214,2.718c-0.055,8.334-0.028,16.669-0.041,25.017C218.61,527.419,218.555,527.681,218.5,528.15
				L218.5,528.15z M387.727,528.15c-3.491,2.042-7.203,3.891-10.846,5.864c-3.588,1.946-7.189,3.85-10.791,5.782
				c-3.67,1.973-7.341,3.933-10.929,5.864c-0.414,2.47-0.373,11.136,0.041,13.081c0.221,0.069,0.455,0.221,0.704,0.221
				c4.512,0.014,9.038,0.014,13.55,0c0.235,0,0.483-0.138,0.883-0.276c0.469-2.511-0.055-5.119,0.331-7.769
				c1.394-0.469,2.787-0.221,4.14-0.248c1.476-0.041,2.953-0.014,4.429-0.014c1.394,0,2.787-0.028,4.167,0.014
				c1.366,0.028,2.76-0.166,4.264,0.221c0.373,2.594,0.138,5.188,0.179,7.769c0.041,2.608,0.014,5.216,0.014,7.81
				s-0.014,5.188-0.014,7.672c-1.049,0.731-2.028,0.483-2.953,0.483c-10.252,0.014-20.505,0.014-30.757,0.014
				c-4.609,0-9.204,0-13.813,0c-0.855,0-1.725,0.11-2.539-0.235c-0.11-0.317-0.276-0.552-0.276-0.8
				c-0.014-12.239-0.014-24.479-0.014-36.594c0.524-0.966,1.407-1.214,2.153-1.614c9.259-4.954,18.532-9.88,27.804-14.834
				c0.993-0.524,1.959-1.076,3.008-1.656c0.11-2.346,0.055-4.595,0.055-6.844c0.014-4.098,0-4.098-3.077-6.858
				c-0.069-0.055-0.138-0.097-0.386-0.248c-3.118,0-6.416-0.028-9.714,0.028c-0.469,0-0.98,0.29-1.394,0.552
				c-0.428,0.29-0.718,0.759-1.132,1.076c-1.076,0.814-1.394,1.877-1.311,3.201c0.097,1.463,0.028,2.939,0.028,4.526
				c-2.498,0.414-14.613,0.428-17.097,0.055c-0.055-0.345-0.193-0.745-0.193-1.159c-0.014-3.739,0.014-7.465-0.028-11.205
				c-0.014-0.938,0.304-1.642,0.966-2.291c3.477-3.394,6.927-6.817,10.57-10.432c0.524,0,1.311,0,2.084,0
				c8.072,0,16.158-0.014,24.231,0c2.856,0,2.166-0.276,4.278,1.821c2.774,2.746,5.52,5.533,8.307,8.279
				c0.787,0.773,1.228,1.559,1.214,2.718c-0.055,8.334-0.028,16.669-0.041,25.017C387.837,527.419,387.782,527.681,387.727,528.15
				L387.727,528.15z"
            />
          </g>
        </g>
      </g>
      <g id="Shape_1_copy_2_1_" enable-background="new    ">
        <g id="Shape_1_copy_2">
          <g>
            <path
              d="M482.706,134.142c-0.814,0.483-1.725,0.828-2.608,1.159c-7.079,2.649-14.144,5.299-21.236,7.92
				c-9.769,3.629-19.539,7.231-29.295,10.86c-4.471,1.67-8.914,3.408-13.399,5.092c-4.636,1.739-9.287,3.422-13.923,5.147
				c-3.739,1.394-7.465,2.815-11.218,4.209c-4.802,1.78-9.604,3.546-14.406,5.313c-2.925,1.09-5.851,2.18-8.79,3.27
				c-9.19,3.436-18.366,6.858-27.556,10.294c-4.719,1.752-9.438,3.505-14.157,5.257c-5.782,2.153-11.563,4.278-17.317,6.485
				c-1.904,0.731-3.67,0.676-5.561-0.028c-14.061-5.285-28.136-10.515-42.21-15.758c-2.608-0.966-5.244-1.835-7.851-2.801
				c-0.8-0.29-1.532-0.745-2.36-1.145c-0.014-8.955-0.166-17.8,0-26.728c1.559-0.593,2.994-1.173,4.457-1.683
				c10.087-3.519,20.174-7.024,30.261-10.515c8.362-2.898,16.724-5.795,25.114-8.652c1.338-0.455,2.594-0.994,3.588-2.028
				c2.001-2.097,2.912-4.498,1.932-7.369c-1.063-3.077-3.27-4.843-6.458-5.326c-1.476-0.221-2.925,0.069-4.347,0.58
				c-6.554,2.304-13.123,4.567-19.677,6.858c-6.968,2.429-13.937,4.857-20.905,7.272c-6.403,2.222-12.805,4.429-19.194,6.637
				c-3.118,1.076-6.237,2.166-9.342,3.27c-0.235,0.083-0.442,0.276-0.676,0.428c-0.511,0.883-0.304,1.863-0.304,2.787
				c-0.028,8.431,0,16.848-0.028,25.279c0,1.021,0.166,2.07-0.29,3.201c-0.524-0.097-1.035-0.124-1.49-0.29
				c-12.129-4.498-24.258-9.011-36.373-13.523c-8.21-3.063-16.42-6.154-24.631-9.218c-7.162-2.677-14.323-5.326-21.485-7.989
				c-6.513-2.415-13.026-4.83-19.525-7.258c-0.731-0.276-1.476-0.566-2.125-0.994c-2.925-1.89-2.912-5.947,0.014-7.838
				c0.58-0.373,1.228-0.662,1.877-0.897c7.644-2.843,15.317-5.644,22.961-8.5c7.479-2.787,14.944-5.657,22.423-8.459
				c5.933-2.222,11.894-4.388,17.828-6.596c7.893-2.939,15.772-5.906,23.665-8.831c5.782-2.153,11.563-4.264,17.345-6.416
				c10.57-3.946,21.14-7.92,31.709-11.853c7.396-2.76,14.806-5.492,22.202-8.238c4.636-1.725,9.287-3.422,13.895-5.216
				c1.877-0.731,3.643-0.787,5.547-0.055c6.886,2.649,13.799,5.216,20.712,7.796c4.968,1.849,9.935,3.657,14.903,5.506
				c4.636,1.725,9.245,3.532,13.895,5.202c4.002,1.421,7.893,3.174,12.005,4.278c1.325,0.359,2.525,1.118,3.808,1.587
				c10.57,3.946,21.167,7.851,31.737,11.798c8.942,3.339,17.869,6.748,26.811,10.087c8.541,3.188,17.097,6.32,25.652,9.494
				c5.036,1.877,10.059,3.795,15.096,5.671c2.36,0.883,4.733,1.725,7.093,2.608c0.731,0.276,1.49,0.524,2.153,0.925
				C485.714,128.002,485.77,132.279,482.706,134.142L482.706,134.142z M398.396,226.041c-2.001,2.76-4.416,5.119-7.134,7.189
				c-4.25,3.229-8.955,5.671-13.854,7.7c-8.045,3.339-16.42,5.616-24.948,7.3c-4.843,0.966-9.742,1.725-14.654,2.318
				c-4.567,0.538-9.149,0.883-13.73,1.242c-8.417,0.662-16.821,0.924-25.238,0.635c-5.892-0.193-11.798-0.58-17.676-1.007
				c-2.856-0.207-5.685-0.662-8.528-1.076c-3.781-0.566-7.548-1.159-11.301-1.849c-3.408-0.635-6.789-1.421-10.239-2.153
				c-0.166-0.607-0.304-0.842-0.304-1.09c-0.014-19.442-0.014-38.885-0.014-58.534c0.552,0.069,0.966,0.041,1.311,0.179
				c5.782,2.139,11.549,4.305,17.331,6.43c1.297,0.469,2.663,0.745,3.946,1.228c1.214,0.455,2.332,1.145,3.546,1.587
				c6.347,2.36,12.722,4.664,19.07,6.996c1.628,0.593,3.229,1.269,4.843,1.904c3.657,1.435,7.258,1.228,10.901-0.152
				c7.465-2.843,14.972-5.575,22.451-8.362c5.202-1.946,10.39-3.933,15.593-5.878c5.699-2.125,11.412-4.195,17.097-6.334
				c1.78-0.676,3.505-1.49,5.257-2.235c0.235-0.097,0.483-0.193,0.731-0.262c4.236-1.007,8.196-2.856,12.267-4.319
				c5.313-1.918,10.556-3.974,15.841-5.961c0.304-0.11,0.649-0.11,1.118-0.179c0.069,0.787,0.179,1.463,0.179,2.139
				c0.014,13.813,0.014,27.625,0,41.438C402.287,219.073,400.783,222.73,398.396,226.041L398.396,226.041z M311.685,126.346
				c-0.635,0.869-1.518,1.352-2.539,1.711c-6.968,2.401-13.923,4.857-20.891,7.3c-5.326,1.863-10.653,3.726-15.979,5.575
				c-6.071,2.111-12.143,4.195-18.214,6.292c-1.973,0.676-3.933,1.38-5.892,2.097c-0.469,0.179-0.911,0.442-1.49,0.731
				c-0.028,1.131-0.097,2.263-0.097,3.381c0,9.287,0.028,18.587,0.028,27.887c0,15.206-0.028,30.399-0.041,45.605
				c0,8.072-0.014,16.158-0.014,24.231c0,0.607,0.014,1.214,0.041,1.821c0.028,0.649,0.331,1.159,0.897,1.504
				c3.008,1.808,4.002,4.595,3.974,7.948c-0.028,4.954,0.028,9.907,0.041,14.847c0,0.497-0.11,1.007-0.179,1.587
				c-1.849,0.552-3.67,0.221-5.464,0.276c-1.904,0.055-3.822,0.028-5.74,0.014c-1.808-0.014-3.615-0.041-5.533-0.069
				c-0.152-0.649-0.359-1.132-0.359-1.614c-0.028-5.478-0.055-10.942,0-16.42c0.028-2.401,1.283-4.264,2.994-5.851
				c0.373-0.345,0.856-0.593,1.269-0.897c0.511-0.386,0.814-0.897,0.814-1.545c0.014-0.69,0.028-1.394,0.028-2.084
				c0-34.304,0-68.621,0-102.925c0-0.938,0.041-1.89,0.069-2.898c4.623-1.628,9.121-3.215,13.619-4.774
				c11.315-3.933,22.63-7.851,33.945-11.784c6.803-2.36,13.606-4.733,20.408-7.079c2.015-0.69,4.36,0.635,4.843,2.705
				C312.499,124.8,312.209,125.614,311.685,126.346L311.685,126.346z M233.584,240.502c-3.767-1.78-7.507-3.615-10.929-6.044
				c-2.843-2.028-5.533-4.209-7.782-6.886c-2.442-2.912-4.209-6.168-4.857-9.949c-0.248-1.449-0.331-2.939-0.331-4.402
				c-0.028-11.563-0.014-23.113,0-34.676c0-2.332,0-4.678,0-7.231c2.87,0.649,23.927,8.583,25.459,9.563c0,19.76,0,39.561,0,59.376
				C234.744,241.013,234.136,240.751,233.584,240.502L233.584,240.502z"
            />
          </g>
        </g>
      </g>
      <g id="Shape_1_copy_1_" enable-background="new    ">
        <g id="Shape_1_copy">
          <g>
            <path
              d="M249.343,417.843c0,0.317-0.166,0.649-0.262,1.007c-7.838,0.593-15.606,1.201-23.389,1.766
				c-2.746,0.193-5.506,0.29-8.321,0.428c-0.593-2.18-0.248-4.264-0.304-6.32c-0.055-2.001-0.028-4.002-0.014-5.989
				c0.014-2.07-0.055-4.14,0.055-6.265c0.828-0.207,1.476-0.359,2.084-0.511c0.083-0.235,0.235-0.414,0.193-0.538
				c-0.248-0.731-0.524-1.463-0.828-2.263c-2.47-0.469-4.843,0.331-7.244,0.359c-2.332,0.041-4.664,0.4-6.996,0.662
				c-2.401,0.276-4.802,0.621-7.313,0.952c-0.359,1.132-0.621,1.932-0.925,2.898c0.773,0.124,1.407,0.235,2.015,0.331
				c0.359,2.097,0.4,16.02,0.041,18.697c-0.497,0.124-1.049,0.304-1.614,0.373c-7.231,0.883-14.461,1.752-21.692,2.608
				c-2.318,0.276-4.65,0.538-6.982,0.787c-0.414,0.041-0.828-0.055-1.118-0.083c-0.179-0.193-0.248-0.248-0.29-0.331
				c-0.041-0.069-0.083-0.152-0.083-0.235c-0.014-5.726-0.028-11.453-0.041-17.193c0-0.166,0.11-0.317,0.193-0.524
				c0.856-0.662,1.946-0.566,2.953-0.731c1.132-0.179,1.932-0.718,2.346-1.794c0.29-0.731,0.58-1.449,0.856-2.18
				c3.284-8.583,6.582-17.166,9.852-25.762c2.042-5.354,4.029-10.708,6.03-16.076c0.124-0.317,0.193-0.649,0.262-0.924
				c-0.511-0.773-1.132-0.317-1.697-0.29c-0.966,0.055-1.352-0.235-1.352-1.09c0.028-5.726,0.055-11.453,0.097-17.179
				c0-0.069,0.069-0.152,0.207-0.414c1.228-0.483,2.705-0.58,4.14-0.731c4.14-0.455,8.293-0.842,12.433-1.269
				c2.249-0.221,4.485-0.469,6.734-0.676c4.057-0.386,8.114-0.773,12.184-1.145c2.332-0.207,4.664-0.414,6.996-0.593
				c0.497-0.041,1.007,0.041,1.49,0.055c0.124,0.345,0.276,0.593,0.276,0.828c0.028,5.561,0.041,11.108,0.028,16.669
				c0,0.331-0.138,0.662-0.235,1.09c-1.118,0.221-2.208,0.428-3.477,0.676c1.559,5.037,3.905,9.673,5.768,14.475
				c1.849,4.774,3.96,9.438,5.906,14.171c1.973,4.788,3.946,9.576,6.154,14.309c1.173,0.317,2.277,0.193,3.394,0.069
				c1.132-0.124,1.49,0.179,1.49,1.214C249.356,406.722,249.356,412.282,249.343,417.843L249.343,417.843z M207.601,372.225
				c-0.8,2.001-1.628,3.891-2.318,5.809c-0.676,1.904-1.752,3.684-1.973,5.823c3.091,0.248,5.947-0.166,8.983-0.883
				C211.327,379.897,208.609,373.688,207.601,372.225L207.601,372.225z M368.08,367.947c0.58,0.607,1.269,0.814,2.097,0.856
				c4.153,0.207,8.321,0.442,12.474,0.731c3.45,0.248,6.899,0.607,10.459,0.924c3.049,3.298,6.127,6.61,9.176,9.935
				c0.993,1.09,1.904,2.249,2.939,3.312c0.787,0.814,1.063,1.711,1.049,2.815c-0.028,7.037-0.028,14.075,0,21.112
				c0,1.035-0.29,1.821-1.076,2.511c-3.56,3.174-7.106,6.375-10.639,9.59c-0.952,0.869-1.918,1.283-3.284,1.159
				c-4.402-0.414-8.817-0.718-13.233-1.035c-2.332-0.179-4.678-0.331-7.01-0.483c-4.498-0.276-9.011-0.58-13.523-0.731
				c-1.27-0.041-2.139-0.483-2.939-1.338c-1.959-2.084-3.933-4.167-5.92-6.223c-1.683-1.752-3.394-3.477-5.119-5.188
				c-0.58-0.58-0.924-1.2-0.911-2.056c0.041-4.774,0.041-9.549,0.055-14.323c0-0.166,0.083-0.317,0.179-0.649
				c0.331-0.055,0.731-0.221,1.132-0.207c6.844,0.29,13.702,0.607,20.546,0.925c0.414,0.014,0.814,0.207,1.242,0.317
				c0.469,1.242,0.248,2.47,0.29,3.67c0.028,0.524-0.069,1.035-0.069,1.559c-0.055,3.118,1.918,5.216,5.037,5.368
				c2.691,0.124,5.381,0.235,8.072,0.345c1.683,0.069,3.505-1.214,3.836-2.815c0.179-0.828,0.138-1.711,0.152-2.58
				c0.014-0.773-0.166-1.573-0.055-2.332c0.179-1.159-0.276-2.001-0.98-2.829c-1.118-1.297-2.373-2.07-4.222-2.153
				c-5.202-0.221-10.39-0.649-15.592-0.952c-1.739-0.097-3.477-0.11-5.216-0.124c-0.745-0.014-1.339-0.276-1.835-0.814
				c-4.112-4.347-8.224-8.693-12.571-13.288c0-1.352,0-2.746,0-4.126c0-6.085,0.055-12.157-0.028-18.242
				c-0.028-1.614,0.442-2.787,1.642-3.864c3.532-3.215,6.996-6.499,10.459-9.797c0.759-0.718,1.559-1.049,2.58-0.966
				c0.262,0.014,0.524,0.014,0.787,0.028c8.569,0.552,17.152,1.118,25.721,1.67c2.429,0.166,4.843,0.442,7.272,0.469
				c1.256,0.014,2.153,0.428,2.967,1.297c2.249,2.415,4.485,4.843,6.83,7.175c1.725,1.711,2.994,3.781,4.719,5.464
				c0.442,0.428,0.704,0.98,0.704,1.628c-0.041,5.036-0.069,10.073-0.097,15.11c0,0.069-0.097,0.138-0.138,0.221
				c-0.055,0.069-0.097,0.138-0.166,0.193c-0.069,0.041-0.166,0.083-0.248,0.083c-0.345,0.014-0.69,0.055-1.035,0.014
				c-7.079-0.649-14.144-1.311-21.278-1.973c-0.11-0.566-0.262-0.966-0.276-1.38c-0.028-1.476-0.124-2.953,0.014-4.416
				c0.152-1.628-0.483-2.843-1.573-3.933c-0.759-0.745-1.518-1.242-2.663-1.394c-3.022-0.4-6.058-0.455-9.093-0.552
				c-1.78-0.055-3.491,1.601-3.56,3.408c-0.083,1.904-0.041,3.822-0.069,5.726c-0.014,0.745,0.235,1.366,0.731,1.877
				C367.225,367.106,367.666,367.52,368.08,367.947L368.08,367.947z M327.071,364.36c-2.056,0.304-3.933,0.124-5.823,0.055
				c-2.001-0.069-3.988-0.097-5.989-0.11c-1.904-0.014-3.808,0.069-5.713-0.028c-1.863-0.097-3.753,0.414-5.616,0
				c0-2.304-0.014-4.374,0-6.444c0.014-0.897-0.276-1.642-0.828-2.387c-1.021-1.366-2.208-2.015-3.974-1.932
				c-2.594,0.138-5.216,0.014-7.81,0.041c-2.484,0.028-4.347,1.918-4.402,4.416c-0.041,1.656-0.028,3.298-0.055,4.954
				c-0.014,0.745,0.29,1.297,0.759,1.863c1.173,1.449,2.567,2.084,4.498,2.042c6.596-0.152,13.205-0.097,19.801-0.207
				c1.311-0.028,2.346,0.248,3.27,1.2c1.863,1.932,3.795,3.808,5.699,5.713c1.601,1.601,3.174,3.215,4.802,4.788
				c0.993,0.966,1.463,2.015,1.435,3.463c-0.097,5.202-0.083,10.418-0.11,15.634c0,1.739-0.069,3.477,0.055,5.202
				c0.083,1.242-0.276,2.125-1.159,2.981c-3.188,3.077-6.292,6.223-9.452,9.328c-2.484,2.442-2.498,2.429-5.906,2.429
				c-5.823,0-11.632-0.041-17.455,0.014c-4.25,0.041-8.5,0.193-12.75,0.317c-4.029,0.11-3.422,0.331-6.168-2.346
				c-2.994-2.912-5.933-5.864-9.011-8.666c-1.283-1.173-1.711-2.346-1.642-4.084c0.166-3.988,0.041-7.976,0.028-11.977
				c0-0.428,0-0.856,0-1.407c0.455-0.221,0.676-0.428,0.911-0.442c3.215-0.11,6.416-0.193,9.632-0.304
				c4.14-0.138,8.279-0.276,12.529-0.414c0.58,2.622,0.166,5.133,0.29,7.631c0.469,0.511,0.911,1.049,1.407,1.518
				c0.842,0.814,1.766,1.256,3.022,1.2c2.608-0.11,5.216-0.014,7.824-0.041c2.732-0.028,4.747-2.001,4.788-4.678
				c0.014-1.297,0.041-2.608-0.014-3.905c-0.083-2.194-2.166-4.25-4.388-4.236c-4.512,0.055-9.024,0.179-13.536,0.29
				c-2.774,0.055-5.561,0.11-8.334,0.193c-0.773,0.028-1.297-0.331-1.821-0.828c-2.429-2.36-4.885-4.692-7.327-7.037
				c-1.683-1.628-3.367-3.257-4.954-4.802c0-7.934,0.014-15.648-0.014-23.375c0-1.021,0.29-1.835,0.993-2.567
				c3.698-3.795,7.382-7.589,11.039-11.412c0.8-0.828,1.711-1.049,2.801-1.049c3.822,0,7.644-0.028,11.467-0.083
				c1.904-0.028,3.808-0.166,5.726-0.262c0.607-0.028,1.214-0.055,1.821-0.055c4.319,0.055,8.652,0.138,12.971,0.193
				c0.524,0.014,1.049,0,1.559-0.097c1.435-0.276,2.525,0.262,3.505,1.256c2.746,2.774,5.519,5.533,8.265,8.307
				c0.731,0.745,1.366,1.601,2.166,2.249c1.007,0.8,1.228,1.78,1.214,2.967c-0.041,4.002-0.028,7.989-0.028,11.991
				C327.071,362.386,327.071,363.325,327.071,364.36L327.071,364.36z M523.178,405.907c0,6.43,0,12.847,0,19.277
				c0,0.952,0,1.904,0,2.87c-1.007,0.828-1.932,1.601-2.884,2.36c-2.705,2.166-5.451,4.291-8.127,6.499
				c-1.463,1.214-2.911,1.394-4.802,0.98c-5.92-1.297-11.908-2.318-17.869-3.464c-0.676-0.124-1.352-0.304-2.042-0.428
				c-2.898-0.511-5.795-1.118-8.721-1.463c-1.476-0.179-2.87-0.662-4.305-0.897c-0.828-0.138-1.463-0.538-1.946-1.187
				c-3.339-4.554-7.493-8.431-11.011-12.847c-0.869-1.09-1.311-2.084-1.145-3.532c0.207-1.794,0.083-3.643,0.069-5.45
				c-0.055-3.629-0.179-7.272-0.179-10.901c0-2.856,0.124-5.713,0.179-8.555c0.028-1.821,0.014-3.629,0.014-5.451
				c0-2.856,0.055-5.713-0.014-8.583c-0.055-2.939-0.152-5.892-0.014-8.831c0.083-1.642-0.069-3.298-0.166-4.94
				c-0.055-0.924,0.138-1.656,0.897-2.291c3.684-3.022,7.368-6.071,10.998-9.162c1.021-0.869,2.028-1.104,3.339-0.842
				c3.905,0.773,7.838,1.421,11.757,2.166c5.119,0.966,10.225,1.959,15.33,2.939c1.959,0.373,3.905,0.8,5.864,1.132
				c1.187,0.193,2.07,0.759,2.829,1.683c3.505,4.305,7.024,8.597,10.597,12.847c0.952,1.132,1.366,2.304,1.352,3.781
				C523.164,384.382,523.178,395.145,523.178,405.907L523.178,405.907z M500.341,392.316c-0.014-4.071-0.069-8.155-0.11-12.226
				c-0.014-1.214,0.083-2.429-0.028-3.643c-0.166-1.863-1.49-3.05-2.718-4.264c-0.166-0.166-0.469-0.193-0.731-0.235
				c-2.636-0.511-5.285-0.952-7.907-1.532c-2.139-0.469-3.919-0.083-5.52,2.111c0,1.449,0,3.008,0,4.567
				c0.041,9.632,0.069,19.249,0.11,28.881c0,1.476-0.014,2.953,0,4.416c0,0.428,0,0.938,0.221,1.256
				c1.09,1.573,2.208,3.077,4.333,3.422c2.815,0.455,5.616,1.035,8.431,1.545c0.414,0.069,0.883,0.124,1.27,0.014
				c1.366-0.386,2.553-2.015,2.608-3.505c0.028-0.607,0.014-1.214,0.014-1.821C500.355,404.969,500.369,398.649,500.341,392.316
				L500.341,392.316z M75.258,392.343c0,0.428-0.083,0.856-0.138,1.463c-7.506,1.89-14.806,3.726-22.244,5.588
				c-0.124-0.607-0.29-1.09-0.304-1.573c-0.028-2.166-0.11-4.347,0.014-6.513c0.097-1.683-0.455-2.856-1.89-3.67
				c-0.304-0.166-0.593-0.345-0.897-0.524c-3.132,0.855-6.223,1.67-9.314,2.553c-2.76,0.773-4.512,3.05-4.512,5.892
				c-0.014,11.287,0,22.575,0,33.876c0,0.869-0.055,1.739-0.014,2.608c0.069,1.463,1.808,2.843,3.256,2.608
				c0.262-0.041,0.511-0.11,0.759-0.179c2.594-0.69,5.188-1.449,7.81-2.028c2.291-0.511,3.436-2.222,4.512-4.002
				c0.29-0.469,0.235-1.173,0.235-1.766c0.028-1.821,0-3.643,0.014-5.478c0-0.773,0.055-1.545,0.083-2.263
				c2.166-1.007,19.843-5.464,22.299-5.602c0.083,0.483,0.235,0.966,0.235,1.463c0.041,5.216,0.041,10.418,0.097,15.634
				c0.014,0.855-0.331,1.504-0.828,2.125c-1.628,2.028-3.256,4.057-4.885,6.085c-2.07,2.567-4.153,5.119-6.168,7.727
				c-0.856,1.104-1.877,1.642-3.243,1.918c-2.539,0.511-5.064,1.187-7.562,1.877c-6.61,1.821-13.205,3.67-19.801,5.519
				c-1.67,0.469-3.353,0.869-4.981,1.463c-1.021,0.373-1.821,0.248-2.663-0.373c-2.58-1.918-5.175-3.808-7.769-5.699
				c-1.049-0.773-2.097-1.545-3.174-2.291c-0.635-0.428-0.938-0.993-0.938-1.739c0-0.607-0.014-1.214-0.014-1.821
				c0-16.848,0-33.696,0-50.545c0-0.69-0.028-1.394,0.041-2.084c0.069-0.787,0.373-1.463,0.869-2.125
				c3.643-4.747,7.272-9.521,10.846-14.337c0.731-0.98,1.601-1.532,2.718-1.849c2.663-0.759,5.326-1.559,8.003-2.304
				c2.415-0.676,4.857-1.297,7.286-1.959c5.106-1.38,10.197-2.787,15.303-4.167c3.781-1.021,3.795-1.007,7.051,1.38
				c1.256,0.924,2.456,1.932,3.698,2.884c0.69,0.524,1.338,1.132,2.097,1.532c0.911,0.497,1.642,1.187,2.456,1.794
				c1.366,1.049,1.642,2.18,1.614,3.795C75.134,382.27,75.244,387.307,75.258,392.343L75.258,392.343z M598.795,385.789
				c0.055,2.429,0.014,4.871,0.014,7.299c0,2.429-0.014,4.843-0.014,7.713c-6.306-1.877-12.184-3.629-18.187-5.409
				c-0.152-1.021-0.317-2.028-0.483-3.077c-1.228-0.773-2.622-1.007-3.919-1.407c-2.318-0.704-4.678-1.311-7.024-1.932
				c-0.731-0.193-1.49-0.276-2.222-0.414c-0.469,1.932-0.538,9.645-0.138,12.377c0.345,0.152,0.718,0.345,1.118,0.483
				c0.317,0.11,0.662,0.138,1.173,0.248c0.097-0.897,0.193-1.683,0.304-2.649c0.69,0.041,1.297-0.028,1.835,0.124
				c2.925,0.8,5.851,1.642,8.762,2.484c1.918,0.552,3.836,1.076,5.754,1.642c0.649,0.193,1.283,0.455,2.084,0.745
				c0.028,0.966,0.097,1.904,0.097,2.856c0.014,5.906,0,11.812,0,17.718c0,0.524,0,1.049,0,1.559
				c0.028,3.394,0.055,3.339-3.077,2.415c-4.071-1.214-8.183-2.318-12.267-3.477c-0.994-0.29-1.973-0.635-3.022-0.966
				c-0.083-0.58-0.166-1.076-0.207-1.587c-0.166-1.642-0.304-1.794-2.07-2.015c-0.152-0.014-0.317,0.097-0.483,0.152
				c-0.345,1.959-0.428,7.534-0.152,11.646c0.442,0.731,1.187,0.98,1.946,1.187c1.835,0.511,3.684,0.993,5.533,1.504
				c0.483,0.138,0.966,0.331,1.518,0.524c0.317,1.601,0.124,3.16,0.152,4.705c0.028,1.559,0.014,3.132,0.014,4.692
				s0.097,3.132-0.028,4.692c-0.11,1.532,0.331,3.091-0.193,4.567c-0.262,0.069-0.455,0.179-0.593,0.138
				c-11.908-3.146-23.803-6.306-35.904-9.507c-0.083-0.455-0.29-1.035-0.29-1.601c-0.014-5.381,0.014-10.763,0.028-16.158
				c0-0.248,0.11-0.497,0.179-0.731c1.449-0.718,2.677,0.842,4.236,0.221c0.58-0.828,0.511-2.001,0.511-3.077
				c0.041-12.778,0.028-25.541,0.014-38.319c0-0.855-0.207-1.711-0.331-2.567c-1.38-0.966-3.105-0.745-4.581-1.725
				c-0.055-5.947-0.138-11.977,0.055-18.021c0-0.124,0.221-0.248,0.442-0.497c0.345,0.069,0.759,0.11,1.159,0.221
				c15.924,3.919,31.751,8.196,47.495,12.75c3.491,1.007,6.955,2.125,10.501,3.201C599.029,380.959,598.74,383.388,598.795,385.789
				L598.795,385.789z M152.131,429.117c-0.911,0.317-1.877,0.58-2.925,0.704c-1.89,0.235-3.767,0.607-5.657,0.897
				c-2.056,0.317-4.153,0.455-6.182,0.911c-5.588,1.27-11.274,1.946-16.89,3.105c-6.623,1.366-13.274,2.58-19.884,4.071
				c-2.705,0.607-5.437,1.145-8.155,1.711c-0.317,0.069-0.649,0.014-0.952,0.014c-0.248-1.628-0.359-16.655-0.152-18.601
				c1.173-1.173,3.036-0.511,4.416-1.614c0.331-0.869,0.262-1.918,0.262-2.939c0-5.989-0.069-11.991-0.069-17.98
				c0-5.036,0.055-10.073,0.055-15.11c0-2.594-0.083-5.188-0.124-7.865c-1.601-0.386-2.967,0.552-4.429,0.386
				c-0.097-0.262-0.262-0.497-0.262-0.731c-0.014-5.892-0.014-11.784-0.014-17.787c3.532-1.269,7.162-1.67,10.68-2.456
				c3.56-0.8,7.134-1.504,10.708-2.222c3.56-0.718,7.134-1.38,10.556-2.042c0.828,0.662,0.635,1.394,0.649,2.056
				c0.014,4.167,0.014,8.334,0.014,12.515c0,1.297,0,2.58,0,3.85c-1.214,1.449-2.981,1.214-4.581,1.587
				c-0.11,0.359-0.248,0.676-0.276,1.007c-0.193,2.387-0.014,41.382,0.193,43.107c1.545,0.4,2.994-0.317,4.498-0.524
				c1.545-0.207,3.077-0.483,4.609-0.787c1.518-0.304,3.036-0.662,4.54-0.993c0.856-1.532,0.028-3.215,0.731-4.761
				c5.754-1.435,11.729-1.973,17.593-3.174c0.304-0.069,0.649,0.083,1.09,0.152c0.069,0.566,0.152,1.063,0.166,1.573
				C152.572,415.36,152.462,426.826,152.131,429.117L152.131,429.117z"
            />
          </g>
        </g>
      </g>
      <text
        transform="matrix(1.0601 0 0 1 403.8877 570.9834)"
        font-family="'TimesNewRomanPSMT'"
        font-size="117.7064"
      >
        1
      </text>
      <text
        transform="matrix(1.0601 0 0 1 403.8877 570.9834)"
        fill="none"
        stroke={primary}
        stroke-width="8"
        stroke-miterlimit="10"
        font-family="'TimesNewRomanPSMT'"
        font-size="117.7064"
      >
        1
      </text>
    </svg>
  );
}

Artwrk4.props = {
  primary: string,
};

Artwrk4.defaultProps = {
  primary: "#000",
};
