import React from "react";
import "./main.css";

import { connect } from "react-redux";

function Leftside(props) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="240 40 400 560"
        enableBackground="new 240 40 400 560"
        xmlSpace="preserve"
      >
        <path
          fill="url(#SVGID_1_)"
          d="M442.204,479.321l-92.997-8.896c0,0,5.284,40.479,5.284,47.961
	c7.725,18.494,49.659,23.67,87.713,23.67c38.052,0,81.783-5.915,89.513-24.409c0-7.482,0-47.222,0-47.222L442.204,479.321z"
        />

        <path
          fill="url(#SVGID_2_)"
          d="M531.623,476.769c-4.195,5.632-32.9,18.085-96.897,18.085c-63.031,0-81.279-14.851-85.545-19.268
	c0-0.311-0.014-0.761-0.014-0.761l92.89,9.775l89.733-9.267C531.792,475.333,531.623,476.499,531.623,476.769z"
        />
        <path
          id="body"
          style={{ fill: props.body }}
          d="M534.442,474.067c5.136-4.175,10.155-10.099,10.609-15.509
	c0.923-10.889-3.427-17.108-6.341-40.048c-0.232-1.833,1.841-6.059,1.077-9.158c-1.45-5.923-4.036-7.094-6.227-11.794
	c-0.877-1.892,2.741-4.731,2.741-10.423c0-3.484-4.064-10.519-6.345-20.837c0-36.812,2.818-48.081,3.859-86.288
	c3.136-11.811,7.922-22.902,7.922-35.244c0-14.062-9.141-53.959-11.227-65.617c-3.614-20.179-10.854-33.038-23.454-53.486
	c-7.404-12.016-24.618-17.781-43.709-17.781c-17.541,0-49.386,30.163-60.136,30.163c-1.323,0.025-1.559,0.832-1.736,1.225
	c-28.522,60.429-53.172,93.612-52.972,135.408c0.264,55.409-9.877,137.23-10.727,165.468c-0.511,17.005,6.58,29.936,11.464,35.032
	c17.163,15.104,53.022,18.968,90.667,18.968C477.556,494.145,521.41,487.138,534.442,474.067z"
        />
        <g>
          <path
            fill="url(#SVGID_3_)"
            d="M456.229,112.321l6.295,9.53l-0.464,1.775l2.055,1.801c0,0,2.941,4.782,5.091,6.959
		c7.018,7.11,24.054,40.817,23.282,66.73c-0.241,0.023-0.559,0.087-0.827,0.111c0.891-25.94-15.904-59.129-23.041-66.49
		c-2.305-2.377-5.364-7.26-5.364-7.26l-1.968-1.505l0.441-1.857l-6.363-9.612L456.229,112.321z"
          />

          <path
            fill="url(#SVGID_4_)"
            d="M471.61,132.386c-2.15-2.177-5.091-6.959-5.091-6.959l-2.055-1.801l0.464-1.775l-6.295-9.53
		l-0.514,0.108l6.223,9.422l-0.464,1.775l2.054,1.801c0,0,2.941,4.782,5.091,6.959c7.018,7.11,24.054,40.817,23.282,66.73
		c-0.071,0.007-0.159,0.02-0.241,0.033c0,0.025,0,0.051,0,0.078c0.268-0.024,0.586-0.089,0.827-0.111
		C495.665,173.203,478.629,139.496,471.61,132.386z"
          />
        </g>
        <path
          fill="#3A3A3A"
          d="M365.353,362.854l-0.145,0.211l-5.759,8.211c0,0,15.854,42.111,32.082,71.779l5.909-7.999
	C383.321,410.906,365.353,362.854,365.353,362.854z"
        />
        <path
          id="pockets"
          style={{ fill: props.pockets }}
          stroke="#0A0A0A"
          strokeMiterlimit="10"
          d="M365.294,363.717c0,0,17.736,47.626,31.654,71.242
	l-2.468,3.336c-14.65-25.353-31.704-70.98-31.704-70.98L365.294,363.717z M359.935,371.363l2.564-3.66
	c0,0,17.041,45.821,31.709,70.965l-2.614,3.533C375.758,412.943,359.935,371.363,359.935,371.363z"
        />
        <path
          fill="#020202"
          d="M365.75,357.444l-0.116,0.167l-9.279,13.109c0,0,20.672,52.094,34.777,77.746l9.402-12.817
	C386.191,411.032,365.75,357.444,365.75,357.444z M356.743,370.794l8.961-12.661c0,0,20.05,53.336,34.438,77.442l-8.961,12.208
	C377.318,422.523,356.743,370.794,356.743,370.794z"
        />

        <path
          fill="url(#SVGID_5_)"
          d="M434.97,556.091c0,0-1.073,1.701-1.359,2.136c-9.741,3.367-47.911-18.357-48.138-28.085
	c0.173-0.324,0.705-1.294,0.705-1.294S420.974,548.101,434.97,556.091z"
        />
        <path
          id="base_1_"
          style={{ fill: props.rib2 }}
          d="M519.183,482.825c8.366-3.679,12.44-6.056,12.44-6.056s0.093,31.975,0.093,39.459
	c-7.729,18.49-51.461,24.408-89.513,24.408c-38.054,0-79.988-5.18-87.713-23.669c-0.786-11.524-4.532-30.133-5.309-41.38
	c22.068,21.664,95.318,19.268,95.318,19.268S497.024,492.57,519.183,482.825z M398.861,144.976
	c5.236,2.367,48.702-19.075,63.636-24.008c14.934-4.932,34.909-3.157,40.92,0.198c1.359-2.565-2.58-8.45-3.877-11.64
	c-2.327-5.722-5.048-20.273-5.53-25.101c-0.254-2.549-0.416-5.603-4.023-6.565c-7.977-2.126-25.018-3.092-38.7,4.151
	C423.529,96.707,405.67,124.283,400.032,138C398.818,140.952,398.684,144.232,398.861,144.976z"
        />
        <path
          id="hand_cufs"
          style={{ fill: props.rib2 }}
          d="M405.261,493.263c0,0-16.539,29.969-19.375,36.07c0.368,8.884,40.079,31.838,48.724,27.26
	c3.827-5.339,22.323-33.137,22.323-33.137s-20.6-5.206-30.315-11.605C418.257,506.343,405.261,493.263,405.261,493.263z"
        />
        <path
          id="hand"
          style={{ fill: props.sleeves }}
          d="M534.992,229.701c3.952,18.251,2.095,55.829,4.209,97.329c0.691,13.583,0.205,14.851,0.245,17.433
	c0.055,3.766,1.777,9.153,1.123,15.163c-0.923,8.474-6.595,17.279-7.768,21.172c-2.573,8.584-5.527,31.683-7.368,36.733
	c-10.295,28.253-23.627,48.056-36.859,76.972c-0.941,2.061-4.791,1.28-5.877,3.384c-0.75,1.453,1.027,6.829-0.182,8.72
	c-3.827,6.002-24.872,16.083-24.872,16.083s-6.455,1.261-28.418-10.608c-18.327-9.9-23.663-19.72-23.663-19.72
	s2.554-22.708,7.413-33.504c3.25-7.211,8.423-9.174,10.423-15.145c2.836-8.488,16.3-48.27,17.064-50.831
	c0.936-3.117,3.409-5.283,4.404-8.593c0.382-1.28-0.618-4.705,0.255-7.625c0.936-3.114,4.595-3.169,4.873-5.435
	c0.359-2.934-3.282-2.89-2.936-5.059c0.445-2.845,4.309-1.551,4.582-3.815c0.2-1.618-2.482-3.19-2.886-5.9
	c-1.896-12.659-7.8-41.889-9.177-66.245c-0.45-8.01-1.345-28.575,0-41.89c3.491-34.521,20.943-79.902,49.452-79.902
	C515.596,168.417,529.951,206.428,534.992,229.701z"
        />

        <path
          fill="url(#SVGID_6_)"
          d="M429.583,512.304c-15.068-8.191-24-19.767-24-19.767l-0.323,0.726c0,0,7.809,11.227,23.736,19.943
	c15.827,8.658,27.936,10.25,27.936,10.25l0.523-0.736C457.456,522.72,445.365,520.888,429.583,512.304z"
        />
        <g>
          <path
            fill="url(#SVGID_7_)"
            d="M402.322,140.443c5.638-13.717,22.397-40.089,50.156-54.785c13.682-7.243,30.584-6.235,38.561-4.109
		c1.432,0.382,2.714,1.62,2.9,2.178c-0.037-0.893-1.223-2.297-3.05-2.783c-7.977-2.126-25.018-3.092-38.7,4.151
		c-27.759,14.695-44.638,40.876-50.277,54.593c-1.214,2.952-1.482,4.667-1.305,5.41c0.15,0,0.262-0.037,0.373-0.111
		C400.95,143.702,401.329,142.858,402.322,140.443z"
          />

          <path
            fill="url(#SVGID_8_)"
            d="M503.417,120.944c-6.011-3.354-25.986-5.129-40.92-0.198c-14.934,4.932-58.399,26.375-63.636,24.007
		c0,0.111-0.037,0.184,0,0.369c5.236,2.367,48.702-19.074,63.636-24.006c14.934-4.933,35.261-3.059,41.272,0.296
		C503.696,121.153,503.442,120.968,503.417,120.944z"
          />

          <path
            fill="url(#SVGID_9_)"
            d="M505.585,123.682c-6.22-3.354-26.757-5.106-42.211-0.174c-15.457,4.933-60.438,26.387-65.859,24.02
		c-0.073,0.073-0.109,0.198-0.073,0.332c5.42,2.368,50.475-19.05,65.931-23.981c15.454-4.933,36.356-3.084,42.577,0.27
		C505.876,123.891,505.61,123.705,505.585,123.682z"
          />
        </g>
        <path
          id="ribs"
          style={{ fill: props.rib1 }}
          stroke={props.rib1}
          strokeMiterlimit="10"
          d="M481.043,506.265c0.048-0.074,0.064-0.185,0.102-0.269
	c23.973-3.043,44.163-9.425,49.1-20.833v5.396c-5.839,12.349-29.797,19.045-56.036,21.694c0.002-0.002-6.618,4.691-14.243,8.195
	c32.054-1.568,63.52-7.832,70.279-22.344v5.351c-7.85,15.355-42.84,21.545-76.636,22.566c0.923-1.391,1.895-2.86,2.411-3.656
	 M544.861,471.561 M340.065,264.539 M387.555,149.527 M427.626,89.423 M497.35,105.142c-6.4-1.745-17.136-3.682-31.584,0.973
	c-16.089,5.183-33.638,19.73-43.579,30.631c-2.52,1.094-5.059,2.134-7.482,3.08c9.414-13.603,30.527-32.252,49.122-38.366
	c14.157-4.655,27.802-3.3,32.566-1.1c0.455-2.305-0.125-5.039-1.548-6.968c-8.145-2.909-25.059-3.292-38.272,2.974
	c-22.097,10.478-41.699,32.219-48.863,46.048c-2.513,0.873-4.598,1.526-5.986,1.877c6.459-14.117,25.65-38.864,52.697-52.14
	c13.648-6.699,31.195-6.888,39.341-3.591 M440.739,545.194c-9.261,4.251-49.068-18.736-50.163-28.035
	c-0.738,1.373-1.491,2.743-2.154,3.99c-11.439-2.385-21.384-5.608-28.314-10.236l-0.743-5.683
	c7.636,5.177,18.766,8.845,31.543,11.313c0.548-1.016,1.102-2.043,1.673-3.098c3.53,9.851,40.034,30.842,50.583,28.166
	C442.339,542.833,441.532,544.026,440.739,545.194z M446.659,536.426c-10.068,3.716-50.222-19.619-50.875-28.893
	c-0.425,0.785-0.907,1.676-1.336,2.467c-14.57-2.358-27.404-5.828-35.945-11.371l-0.748-5.726c9.27,6.024,23.386,9.965,39.27,12.342
	c0.182-0.34,0.348-0.64,0.789-1.452c3.316,9.906,40.145,31.193,51.336,28.921C448.344,533.917,447.509,535.16,446.659,536.426z"
        />
        <g className="left-design-parent">
          <rect
            className={`l-st12 design-place-outline out left-design ${
              props.leftdesign && "active-path"
            }`}
            height="130"
            id="XMLID_7_"
            width="80"
            x="450"
            y="220"
          ></rect>
          <rect
            className={`l-st13 design-place-outline in left-design ${
              props.leftdesign && "active-path"
            }`}
            height="124"
            id="XMLID_14_"
            width="74"
            x="453"
            y="223"
          ></rect>
          <svg height="139" width="73" x="453" y="220" viewBox="0 0 256 256">
            {props.leftImage}
          </svg>
          <text
            font="[object Object]"
            fill={props.leftTextColor}
            text="ghhgch"
            x="490"
            y="290"
            maxLength="3"
            fontSize={props.leftTextSize}
            textAnchor="middle"
            strokeWidth={props.leftTextStroke}
            stroke={props.leftTextStrokeColor}
            nostroke="true"
            style={{ direction: "ltr" }}
            fontFamily={props.leftTextFamily}
            height="108"
            width="107.2"
          >
            {props.leftText}
          </text>
        </g>
      </svg>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    body: state.shirtColor.body,
    sleeves: state.shirtColor.sleeves,
    rib1: state.shirtColor.rib1,
    pockets: state.shirtColor.pockets,
    rib2: state.shirtColor.rib2,
    // text, image
    leftdesign: state.leftView.chooseLeft,
    leftText: state.leftView.leftText,
    leftImage: state.leftView.leftImage,
    leftTextColor: state.leftView.leftTextColor,
    leftTextSize: state.leftView.leftTextSize,
    leftTextFamily: state.leftView.leftTextFamily,
    leftTextStroke: state.leftView.leftTextStroke,
    leftTextStrokeColor: state.leftView.leftTextStrokeColor,
  };
};

export default connect(mapStateToProps)(Leftside);
