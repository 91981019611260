import React from "react";
import { string } from "prop-types";

export default function Artwrk2(props) {
  const { primary } = props;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      fill={primary}
      viewBox="0 0 612 792"
      enable-background="new 0 0 612 792"
      xmlSpace="preserve"
    >
      <g id="Shape_1">
        <path
          d="M535.343,246.958c-4.556,5.012-8.998,10.023-13.441,15.035c-0.342,0.342-0.57,0.797-0.911,1.139
		c0.683,0.456,1.139,0.114,1.709,0c5.24-1.481,10.023-3.873,14.58-6.72c3.075-1.936,6.037-4.101,8.657-6.72
		c0.57-0.456,1.139-0.911,1.709-1.481c1.367,0.911,2.62,1.822,3.759,2.961c-0.228,0.342-0.342,0.57-0.57,0.683
		c-3.873,3.417-7.745,6.606-12.188,9.226c-5.012,2.961-10.479,5.012-16.06,6.492c-2.05,0.57-3.987,1.025-6.037,1.481
		c-1.139,0.228-1.936,0.683-2.734,1.595c-2.734,3.303-5.923,6.037-9.568,8.315c-5.012,3.189-10.479,5.24-16.516,5.581
		c-3.645,0.228-7.29-0.114-10.821-1.367c-1.595-0.57-3.075-1.253-4.442-2.278c-4.442-3.303-3.417-8.087-1.025-10.707
		c0.911-0.911,1.936-1.709,2.961-2.392c1.822-1.253,3.987-1.936,6.151-2.506c5.012-1.253,10.251-1.595,15.377-1.253
		c1.481,0.114,2.848,0.114,4.328,0c0.342,0,0.911-0.228,1.139-0.456c1.822-2.05,3.759-4.1,5.467-6.379
		c-0.683-0.342-1.025,0.114-1.481,0.228c-3.075,1.253-6.265,1.367-9.568,0.683c-5.809-1.253-7.973-5.695-8.201-9.682
		c0-0.797,0-1.595,0-2.62c-0.57,0.228-1.025,0.342-1.481,0.683c-2.05,1.253-4.101,2.278-6.379,3.189
		c-1.936,0.683-3.987,1.253-6.037,1.481c-3.417,0.228-6.606-0.342-9.568-2.05c-0.911-0.456-1.595-1.139-2.392-1.822
		c-2.05-1.936-2.734-4.328-2.05-7.062c0.569-2.392,1.481-4.67,2.734-6.72c1.481-2.392,3.189-4.67,5.012-6.72
		c1.367-1.595,2.848-3.075,4.101-4.67c0.683-0.911,1.367-1.822,1.936-2.848c0.57-1.139,0.114-1.936-1.025-2.278
		c-1.367-0.456-2.506-0.114-3.759,0.342c-1.822,0.797-3.417,2.05-4.898,3.417c-1.822,1.709-3.417,3.531-4.784,5.581
		c-3.759,5.24-7.404,10.593-11.162,15.832c-0.456,0.683-0.911,1.253-1.367,1.822c-1.595,0-13.327-2.392-14.807-2.961
		c0.342-1.025,1.025-1.709,1.481-2.62c0.57-0.911,1.139-1.709,1.595-2.506s1.139-1.595,1.595-2.848
		c-0.911,0.683-1.595,1.139-2.164,1.595c-2.506,1.936-5.353,3.417-8.315,4.556c-3.189,1.253-6.606,1.481-9.91,0.797
		c-0.57-0.114-1.139-0.342-1.709-0.456c-3.075-1.139-4.67-3.417-5.24-6.492c-0.228-1.481-0.114-2.848,0.114-4.328
		c0.114-0.456,0.228-1.025,0.342-1.367c-0.683-0.456-0.797,0.114-1.139,0.342c-2.848,2.62-5.809,4.784-9.34,6.492
		c-1.822,0.911-3.759,1.481-5.695,1.822c-2.734,0.456-5.24,0.114-7.859-0.683c-1.709-0.456-3.075-1.481-4.214-2.848
		c-1.481-1.822-1.936-3.987-1.709-6.265c0-0.797,0.228-1.595,0.342-2.62c-0.456,0.342-0.797,0.456-1.025,0.683
		c-1.936,1.822-3.987,3.645-6.379,5.126c-1.709,1.025-3.417,2.05-5.353,2.734c-2.848,1.025-5.695,1.139-8.657,0.683
		c-3.531-0.57-5.581-2.734-6.492-6.037c-0.228-0.797-0.342-1.709-0.57-2.62c-0.683,0-0.911,0.57-1.253,1.025
		c-2.164,2.392-4.784,4.101-7.745,5.24c-1.822,0.683-3.759,1.025-5.695,1.025c-5.923,0-9.226-4.442-10.137-8.087
		c-0.114-0.683-0.342-1.367-0.456-2.164c-0.683,0.228-1.025,0.797-1.481,1.253c-2.164,2.278-4.556,4.214-7.176,5.809
		c-2.164,1.253-4.556,2.05-7.062,2.392c-1.481,0.114-2.848,0.114-4.328-0.114c-3.417-0.57-5.467-2.62-6.606-5.695
		c-0.228-0.683-0.456-1.481-0.569-2.278c-0.683-0.114-0.797,0.456-1.025,0.797c-2.05,2.734-4.556,4.898-7.745,6.265
		c-2.392,1.025-4.784,1.481-7.29,1.253c-3.873-0.342-7.176-3.645-7.518-7.176c-0.114-1.481-0.114-2.961-0.228-4.442
		c-0.683-0.114-0.797,0.456-1.025,0.911c-1.595,2.278-3.417,4.442-5.581,6.265c-1.139,1.025-2.278,1.936-3.531,2.734
		c-3.189,2.05-6.606,2.961-10.479,2.62c-2.961-0.228-5.126-1.709-6.379-4.328c-0.456-1.025-0.797-2.05-1.367-3.303
		c-0.57,0.797-1.025,1.481-1.481,2.164c-2.62,3.645-5.923,6.151-10.365,7.29c-6.72,1.709-11.049-2.05-12.985-6.72
		c-0.342-0.683-0.57-1.481-0.911-2.392c-0.57,0.797-0.911,1.367-1.367,1.936c-1.822,2.734-3.873,5.126-6.379,7.29
		c-2.734,2.392-5.809,3.873-9.454,4.328c-4.328,0.57-7.404-1.025-9.34-4.898c-0.342-0.57-0.456-1.139-0.797-1.709
		c-0.683,0-0.797,0.57-1.025,1.025c-1.481,2.734-3.531,5.126-6.037,6.948c-1.936,1.367-4.101,2.278-6.492,2.62
		c-4.101,0.57-7.29-1.025-9.91-4.101c-1.367-1.709-2.164-3.759-2.62-5.809c-0.114-0.797-0.342-1.709-0.456-2.734
		c-0.456,0.228-0.797,0.342-1.139,0.57c-2.734,1.822-5.923,2.506-9.226,2.392c-3.531-0.114-8.429-2.278-9.682-7.518
		c-1.709-7.062-0.797-13.668,4.101-19.363c2.278-2.62,5.24-3.873,8.771-3.873c1.709,0,2.848,0.911,3.531,2.392
		c0.797,1.595,0.797,3.189,0.456,4.898c-0.342,1.822-1.139,3.417-2.164,4.898c-2.278,2.961-5.012,5.353-8.429,6.948
		c-0.228,0.114-0.456,0.228-0.683,0.342c-1.025,3.303,2.848,7.859,7.404,6.948c2.164-0.456,3.987-1.595,5.24-3.417
		c0.911-1.481,1.595-2.961,2.164-4.67c0.228-0.683,0.342-1.367,0.911-1.936c0.456-0.456,0.57-1.139,0.797-1.709
		c2.848-7.062,7.404-12.757,13.782-16.972c2.392-1.595,5.012-2.848,7.859-3.189c1.367-0.228,2.734-0.228,4.101-0.114
		c1.822,0.114,3.417,1.025,4.328,2.848c0.911-0.683,0.683-1.822,1.367-2.62c2.278-0.57,4.784-0.683,7.176-1.139
		c2.392-0.342,4.784-0.57,7.404-0.911c-0.114,0.911-0.228,1.595-0.456,2.278c-1.481,5.809-2.961,11.732-4.328,17.541
		c-0.797,3.075-1.595,6.151-2.164,9.226c-0.228,1.139-0.342,2.278-0.228,3.417c0.114,1.367,1.025,1.936,2.392,1.709
		c0.911-0.114,1.595-0.57,2.278-1.139c0.797-0.911,1.709-1.822,2.392-2.734c2.278-3.189,3.987-6.606,5.467-10.251
		c0.342-0.911,0.683-1.822,1.025-2.848c0.228-0.57,0.342-1.253,0.911-1.595c0.456-0.342,0.57-0.911,0.911-1.481
		c2.278-4.67,5.24-8.771,8.998-12.188c3.759-3.417,7.859-5.923,12.985-6.834c0.228,0,0.456-0.114,0.683-0.114
		c2.848-0.228,5.695-0.342,8.201,1.936c1.595-4.67,3.075-9.226,4.556-13.782c1.595-4.556,3.075-9.112,4.67-13.441
		c3.759-0.57,15.263-0.911,17.199-0.456c-0.228,0.683-0.456,1.367-0.683,2.05c-5.467,14.466-10.593,28.931-15.377,43.625
		c-1.025,3.075-1.936,6.151-2.848,9.226c-0.228,0.683-0.342,1.595-0.342,2.278c0,1.595,0.911,2.278,2.506,2.164
		c1.025-0.114,1.822-0.57,2.506-1.253c1.025-0.911,1.936-1.822,2.848-2.961c1.595-1.822,2.848-3.873,3.987-6.151
		c1.253-2.278,2.278-4.556,3.531-6.948c0.57,0,1.253,0,1.936,0c0.114-0.114,0.342-0.342,0.342-0.683
		c1.709-4.556,3.417-9.112,5.126-13.668c0.228-0.57,0.456-1.253,0.683-1.709c1.936-0.456,13.554-0.57,16.174-0.228
		c0.228,0.57-0.114,1.025-0.342,1.595c-3.189,8.429-6.492,16.858-9.682,25.286c-0.456,1.139-0.797,2.278-1.025,3.531
		c-0.114,0.456-0.114,1.025,0.114,1.481c0.228,0.797,0.683,1.253,1.595,1.253c0.911,0.114,1.822-0.114,2.62-0.683
		c1.595-1.025,2.848-2.278,3.645-3.873c0.57-1.025,1.025-2.05,1.481-3.075c3.075-7.745,6.151-15.491,9.226-23.122
		c0.342-0.797,0.683-1.595,1.025-2.278c1.595-0.228,13.668-0.114,16.174,0.228c0.456,0.797-0.114,1.367-0.456,1.936
		c-2.62,6.037-5.24,12.074-7.859,18.224c-1.139,2.734-2.278,5.467-3.303,8.201c-0.342,0.797-0.456,1.709-0.57,2.506
		c-0.114,1.367,0.797,2.164,2.164,2.05c0.683,0,1.481-0.228,2.05-0.57c1.025-0.683,2.05-1.367,2.961-2.164
		c3.303-3.189,5.923-7.062,7.973-11.162c3.759-7.176,9.454-12.415,16.63-16.06c2.734-1.367,5.695-2.278,8.771-2.734
		c1.936-0.228,3.873-0.228,5.809,0.342c2.278,0.57,4.214,1.709,4.784,4.442c0.911-0.683,1.139-1.709,1.709-2.392
		c1.936-0.228,12.415,0.797,14.693,1.481c0.114,0.683-0.342,1.139-0.683,1.709c-4.214,7.859-8.315,15.719-12.529,23.578
		c-0.456,0.911-0.911,1.822-1.253,2.734c-0.228,0.57-0.342,1.139-0.456,1.709c-0.114,1.253,0.57,2.05,1.822,2.164
		c1.025,0.114,2.05-0.114,2.848-0.797c1.367-1.025,2.848-2.05,4.101-3.189c3.189-2.961,5.923-6.379,8.315-10.137
		c0.228-0.342,0.456-0.57,0.57-0.911c0-0.114,0.114-0.114,0.342-0.114c0.456,0.114,1.025,0.228,1.595,0.456
		c0.114-0.114,0.456-0.342,0.57-0.57c2.164-3.873,4.328-7.745,6.492-11.618c0.797-1.481,1.595-2.848,2.392-4.328
		c0.228-0.342,0.342-0.797,0.57-1.253c-2.278-0.57-4.442-0.57-6.72-1.025c0.114-0.342,0.114-0.683,0.228-1.025
		c0.57-1.139,1.253-2.278,1.936-3.417c2.506,0.342,4.898,0.57,7.404,0.911c2.05-3.531,4.214-6.948,6.265-10.365
		c6.265-0.683,12.529-0.797,19.022-1.253c-0.228,0.456-0.228,0.911-0.456,1.253c-2.278,3.531-4.442,7.062-6.72,10.479
		c-0.456,0.683-0.797,1.367-1.253,2.164c1.253,0.57,2.392,0.57,3.645,0.797c1.139,0.228,2.392,0.456,3.645,0.683
		c-0.228,1.025-0.797,1.595-1.253,2.278s-0.911,1.253-1.367,1.936c-2.506-0.342-5.012-0.797-7.518-1.139
		c-0.456,0.683-0.911,1.367-1.253,2.05c-4.67,8.087-9.454,16.06-14.124,24.147c-0.683,1.139-1.253,2.278-1.822,3.417
		c-0.456,1.025-0.797,2.278,0,3.303s2.05,0.911,3.189,0.683s2.164-0.797,3.075-1.481c2.961-1.936,5.467-4.442,7.745-7.176
		c1.481-1.822,2.848-3.759,4.442-5.695c0.57,0.114,1.139,0.342,1.822,0.456c3.189-5.012,6.379-10.023,9.568-15.149
		c0.683,0.114,1.367,0,1.936,0.114c4.442,0.797,8.884,1.595,13.327,2.506c0.228,0,0.57,0.114,0.797,0.228
		c0,0.911-0.57,1.367-1.025,1.936c-4.1,6.265-8.201,12.529-12.188,18.68c-1.139,1.709-2.278,3.531-3.303,5.24
		c-0.342,0.57-0.683,1.139-0.797,1.709c-0.57,1.822,0.342,3.189,2.278,3.075c0.911-0.114,1.822-0.342,2.62-0.797
		c1.367-0.683,2.62-1.595,3.759-2.392c3.303-2.506,6.037-5.695,8.657-8.884c0.456-0.57,0.911-1.025,1.481-1.709
		c0.683,0.342,1.367,0.57,2.05,0.911c1.822-2.506,3.531-5.126,5.24-7.632c1.709-2.506,3.417-4.898,5.24-7.518
		c2.734,0.342,5.24,1.025,7.859,1.595s5.126,1.253,7.859,1.822c-0.228,1.025-0.911,1.595-1.367,2.278s-1.139,1.253-1.595,2.392
		c1.025-0.342,1.595-0.683,2.278-0.911c3.987-1.709,8.201-2.392,12.643-1.936c1.822,0.228,3.531,0.683,5.24,1.367
		c2.848,1.253,4.328,3.303,4.328,6.492c0,1.595-0.342,2.961-1.025,4.328c-0.797,1.595-1.709,3.189-2.961,4.442
		c-2.506,2.848-5.126,5.581-7.745,8.429c-1.253,1.367-2.506,2.734-3.645,4.214c-0.57,0.683-1.025,1.595-1.253,2.392
		c-0.342,1.025,0.114,1.936,1.139,2.392c0.911,0.456,1.822,0.342,2.734,0.114c1.025-0.342,2.05-0.683,2.961-1.253
		c3.189-1.709,6.037-3.873,8.657-6.379c0.456-0.342,0.797-0.797,1.139-1.253c3.531-4.556,7.973-7.745,13.099-10.251
		c3.531-1.709,7.176-2.848,11.049-3.303c3.417-0.456,6.72-0.342,10.023,0.797c0.57,0.228,1.253,0.456,1.822,0.683
		c2.278,1.025,3.987,2.506,3.987,5.467c1.139-0.456,1.595-1.367,2.392-1.822c1.936,0.456,11.276,3.645,14.124,4.898
		c-0.228,0.342-0.342,0.683-0.57,1.025C544.342,237.39,539.9,242.174,535.343,246.958L535.343,246.958z M495.933,268.941
		c-2.506,0.228-5.012,0.456-7.518,0.683c-2.164,0.228-4.214,1.025-6.037,2.164c-1.481,0.911-2.62,2.05-2.961,3.759
		c-0.342,1.595,0.342,2.848,1.822,3.417c1.253,0.456,2.734,0.57,3.987,0.114c1.139-0.342,2.164-0.797,3.189-1.367
		c2.278-1.253,4.1-3.075,5.809-5.012c0.911-1.025,1.709-2.05,2.62-3.189c0.114-0.114,0-0.228,0.114-0.57
		C496.389,269.055,496.161,268.941,495.933,268.941L495.933,268.941z M196.482,203.219c-0.228-1.025-0.911-1.481-1.936-1.367
		c-0.683,0.114-1.253,0.228-1.822,0.57c-0.683,0.456-1.367,1.025-1.936,1.709c-0.911,1.253-1.709,2.392-2.392,3.759
		c-2.961,5.581-4.328,11.618-4.784,17.883c0,0.683,0,1.481,0,2.164c0,0.342,0.114,0.683,0.114,1.025
		c0.456,2.164,2.278,2.848,3.987,1.481c0.456-0.342,1.025-0.911,1.367-1.367c0.797-1.253,1.709-2.506,2.278-3.873
		c0.683-1.595,1.253-3.303,1.709-5.012c0.797-2.734,1.481-5.353,2.05-8.087c0.456-1.822,0.911-3.759,1.253-5.695
		C196.71,205.384,196.824,204.358,196.482,203.219L196.482,203.219z M251.839,196.727c-0.228-1.253-1.025-1.822-2.392-1.595
		c-1.139,0.228-2.05,0.683-2.848,1.481c-1.139,1.025-2.164,2.392-2.848,3.759c-2.734,5.012-4.898,10.137-6.265,15.719
		c-0.342,1.253-0.456,2.506-0.683,3.873c0.228,0.797,0.342,1.822,0.57,2.62c0.456,1.025,1.481,1.481,2.506,1.025
		c0.57-0.228,1.139-0.57,1.595-0.911c0.683-0.57,1.253-1.253,1.709-1.936c1.595-1.936,2.62-4.214,3.417-6.606
		c1.481-4.101,2.848-8.087,4.214-12.188c0.342-1.025,0.683-2.164,0.911-3.303C252.067,197.98,252.067,197.296,251.839,196.727
		L251.839,196.727z M358.794,195.816c-1.139,0-2.164,0.57-3.189,1.253c-1.709,1.139-3.075,2.62-4.328,4.214
		c-3.759,4.67-6.606,9.91-8.429,15.605c-0.342,0.911-0.456,1.822-0.57,2.392c-0.228,2.848,1.481,3.987,3.759,2.848
		c0.911-0.456,1.709-1.139,2.392-1.822c2.05-1.822,3.531-4.101,4.898-6.492c1.025-1.936,2.164-3.873,3.189-5.923
		c1.253-2.392,2.506-4.67,3.645-7.062c0.456-0.797,0.683-1.709,0.911-2.62C361.3,196.613,360.389,195.816,358.794,195.816
		L358.794,195.816z M525.776,229.303c-0.57-0.114-1.253-0.114-1.936,0c-0.911,0.228-1.936,0.57-2.848,1.139
		c-1.367,0.797-2.848,1.709-4.101,2.734c-2.62,2.278-5.24,4.67-7.745,7.176c-1.709,1.709-3.303,3.645-4.556,5.695
		c-0.683,1.025-1.253,2.164-1.367,3.303c-0.114,1.025,0.228,1.709,1.139,2.05c0.342,0.114,0.797,0.228,1.025,0.342
		c1.253,0,2.278-0.456,3.303-0.911c2.848-1.367,5.126-3.417,7.29-5.695c2.278-2.392,4.442-4.67,6.606-7.062
		c1.139-1.253,2.278-2.392,3.303-3.645c0.57-0.683,1.025-1.367,1.367-2.164C528.054,230.67,527.598,229.645,525.776,229.303
		L525.776,229.303z M309.93,413.484c-0.342,11.162-2.164,22.097-4.556,32.918c-1.822,8.429-3.987,16.744-6.72,24.945
		c-5.126,15.377-11.732,30.07-21.072,43.397c-5.581,7.973-12.074,15.263-19.819,21.414c-6.606,5.24-13.782,9.454-21.869,12.074
		c-6.151,2.05-12.415,2.848-18.908,2.278c-9.796-0.797-18.338-4.328-25.286-11.39c-4.214-4.328-7.176-9.34-9.226-14.921
		c-1.936-5.126-3.075-10.479-3.531-15.946c-0.342-3.531-0.456-7.176-0.683-10.707c0.228-6.834,0.797-13.668,1.822-20.389
		c1.822-12.074,4.67-23.92,8.429-35.538c4.328-13.554,9.91-26.539,17.541-38.613c4.328-6.834,9.226-13.099,15.035-18.794
		c6.606-6.492,14.01-11.846,22.667-15.263c6.492-2.62,13.213-3.987,20.389-3.987c7.859,0,15.491,1.253,22.781,3.987
		c7.745,2.961,13.782,7.973,17.655,15.263c1.936,3.531,3.189,7.404,3.987,11.276C309.816,401.41,310.157,407.447,309.93,413.484
		L309.93,413.484z M280.087,385.008c-0.683-3.075-1.709-6.037-3.645-8.657c-1.709-2.392-3.987-3.987-6.948-4.328
		c-2.392-0.342-4.898-0.342-7.29,0.114c-4.214,0.797-7.973,2.848-11.276,5.467c-2.848,2.278-5.24,4.898-7.404,7.745
		c-3.303,4.328-6.151,8.998-8.543,13.896c-4.67,9.226-8.201,18.794-11.276,28.59c-6.151,19.933-10.935,40.094-14.01,60.71
		c-1.025,6.606-1.595,13.213-1.822,19.933c-0.228,4.898,0,9.91,0.683,14.807c0.456,3.531,1.367,6.948,2.848,10.137
		c0.797,1.709,1.822,3.303,3.075,4.67c3.189,3.303,7.062,4.328,11.39,3.189c2.392-0.57,4.328-1.822,6.265-3.303
		c3.303-2.506,6.151-5.581,8.657-8.884c4.214-5.581,7.745-11.504,10.935-17.769c5.24-10.137,9.454-20.73,13.099-31.551
		c5.809-17.313,10.479-34.854,13.668-52.851c1.595-9.112,2.734-18.338,2.848-28.02C281.454,394.576,281.226,389.792,280.087,385.008
		L280.087,385.008z M146.023,412.003c-1.139,9.568-4.328,18.338-8.771,26.767c-4.556,8.543-10.365,16.288-17.199,23.35
		c-8.884,9.34-19.022,17.199-29.615,24.603c-7.631,5.353-15.605,10.251-23.464,15.149c-6.379,3.987-12.643,7.859-18.794,12.188
		c-4.898,3.417-9.568,7.062-13.782,11.39c-3.189,3.303-6.037,6.948-7.973,11.162c-0.683,1.595-1.253,3.189-1.822,4.784
		c-0.114,0.228-0.114,0.57-0.114,1.139c0.797-0.456,1.367-0.797,1.936-1.139c7.631-4.898,15.605-9.112,24.034-12.415
		c5.581-2.278,11.39-4.101,17.313-5.012c6.037-0.911,11.846-0.57,17.655,1.139c6.265,1.822,11.846,4.898,17.085,8.884
		c1.367,1.025,2.62,2.164,3.987,3.303c0.797,0.57,1.595,1.253,2.506,1.709c2.392,1.367,4.784,1.253,7.176-0.342
		c1.595-1.025,2.961-2.506,3.987-4.101c1.595-2.278,2.734-4.898,3.759-7.518c0.456-1.367,0.911-2.734,1.367-4.101
		c0.114-0.456,0.228-1.025,0.456-1.481c0.683-1.481,1.709-1.822,3.189-1.139c0.456,0.228,0.797,0.57,1.253,0.911
		c0.911,0.683,1.253,1.481,1.139,2.62c-0.228,3.759-1.139,7.404-2.506,10.821c-3.189,7.859-8.087,14.352-15.263,19.022
		c-5.126,3.303-10.821,4.898-16.972,4.556c-5.126-0.228-9.796-1.822-14.238-4.328c-2.506-1.367-4.898-2.848-7.29-3.987
		c-4.784-2.392-9.91-4.214-15.149-5.24c-8.201-1.595-16.288-1.025-24.261,1.595c-3.417,1.025-6.606,2.392-9.682,3.987
		c-3.075,1.595-5.809,1.139-8.429-0.911c-1.025-0.797-1.822-1.822-2.734-2.734c-0.57-0.57-0.683-1.253-0.683-1.936
		c0.114-2.506,0.57-5.012,1.253-7.404c1.367-5.012,3.417-9.682,5.923-14.238c4.328-7.973,9.454-15.377,15.149-22.439
		c6.834-8.543,14.238-16.516,22.097-24.034c11.049-10.707,22.894-20.389,35.31-29.501c5.581-4.1,10.935-8.543,15.832-13.554
		c5.012-5.126,9.454-10.707,12.985-17.085c2.392-4.442,4.214-9.112,5.126-14.124c0.797-4.784,0.683-9.454-0.797-14.01
		c-2.05-6.265-6.151-10.821-11.846-13.896c-4.101-2.164-8.543-3.417-13.213-3.759c-7.404-0.57-14.58,0.342-21.528,3.075
		c-5.695,2.164-10.593,5.353-14.58,10.023c-5.809,6.834-8.429,14.693-7.973,23.692c0.228,4.898,1.367,9.454,3.303,14.01
		s4.556,8.771,7.518,12.757c0.456,0.57,1.025,1.253,1.481,1.822c4.556,6.265,3.987,14.352-1.595,19.477
		c-5.24,4.784-11.162,6.379-18.111,4.442c-1.253-0.342-2.164-1.025-2.848-2.05c-3.417-4.898-5.809-10.365-7.404-16.174
		c-2.278-8.429-2.848-17.085-1.822-25.742c0.797-7.632,2.848-14.921,6.151-21.869c7.29-15.149,18.794-25.173,34.854-30.184
		c4.898-1.481,10.023-2.392,15.149-2.734c3.987-0.342,7.973-0.342,12.074,0.114c8.087,0.797,15.719,3.075,22.553,7.745
		c8.201,5.695,13.441,13.441,15.946,23.008C146.593,398.563,146.821,405.283,146.023,412.003L146.023,412.003z M388.978,586.161
		c-0.911,1.481-1.822,2.961-2.734,4.328c-23.236,35.765-29.387,45.106-28.134,43.169c-3.759,5.809-7.518,11.732-11.39,17.541
		c-3.303,5.012-6.606,10.137-9.91,15.149c-3.303,5.012-6.492,10.023-9.682,15.035c-5.809,8.998-11.732,17.883-17.541,26.881
		c-3.987,6.151-7.973,12.415-12.074,18.566c-1.139,1.709-2.164,3.417-3.303,5.012c-0.228,0.342-0.57,0.57-0.911,1.025
		c-0.342-0.456-0.683-0.683-0.911-1.025c-3.987-6.037-7.859-12.188-11.846-18.224c-4.101-6.265-8.201-12.529-12.188-18.794
		c-3.303-5.126-6.72-10.365-10.023-15.491c-4.67-7.176-9.226-14.352-13.896-21.528c-5.353-8.315-10.821-16.63-16.174-24.831
		c-7.745-11.96-15.491-23.92-23.35-35.879c-4.214-6.379-8.429-12.871-12.529-19.25c-4.442-6.834-8.884-13.668-13.327-20.616
		c-5.126-7.859-10.137-15.605-15.263-23.464c-2.62-3.987-5.126-7.973-7.745-11.96c-4.556-7.062-9.112-14.01-13.782-21.072
		c-2.506-3.759-4.898-7.632-7.29-11.39c-2.734-4.214-5.467-8.429-8.201-12.643c-0.228-0.342-0.342-0.57-0.683-1.025
		c0.228-0.228,0.456-0.57,0.797-0.683c1.822-1.025,3.759-2.05,5.809-3.189c0.342,0.342,0.683,0.683,0.911,1.025
		c3.075,4.67,6.037,9.34,9.112,14.124c3.645,5.695,7.404,11.276,11.049,16.972c2.62,4.1,5.24,8.201,7.973,12.302
		c5.126,7.859,10.251,15.719,15.377,23.578c2.848,4.442,5.695,8.771,8.543,13.213c3.303,5.126,6.606,10.251,10.023,15.377
		c2.506,3.873,5.012,7.859,7.631,11.732c3.075,4.67,6.151,9.34,9.226,14.01c3.417,5.24,6.72,10.479,10.137,15.719
		c2.506,3.873,5.012,7.631,7.518,11.504c3.645,5.695,7.29,11.276,10.935,16.971c2.392,3.645,4.784,7.404,7.176,11.049
		c5.353,8.201,10.707,16.516,16.06,24.717c6.265,9.568,12.415,19.136,18.68,28.704c3.189,4.898,6.265,9.796,9.454,14.58
		c0.228,0.342,0.456,0.683,0.797,1.139c0.342-0.342,0.683-0.57,0.911-0.911c2.392-3.645,4.784-7.29,7.176-10.935
		c2.848-4.328,5.695-8.771,8.543-13.213c5.012-7.632,9.91-15.263,14.921-22.894c3.531-5.467,7.062-10.821,10.593-16.288
		c3.303-5.126,6.606-10.251,10.023-15.377c2.848-4.442,5.809-8.884,8.657-13.327c4.101-6.265,13.441-20.616,30.754-47.497
		c0.57-0.911,1.139-1.822,1.936-2.62c0.569,0.456,0.569,1.025,0.797,1.595c0.456,1.481,0.797,3.075,1.253,4.556
		c0.228,1.025,0.57,2.05,0.683,3.189C389.434,585.25,389.206,585.705,388.978,586.161L388.978,586.161z M146.479,206.409
		c-1.139,8.201-2.278,16.516-3.303,24.717c-0.683,5.012-1.139,10.137-1.709,15.149c-0.114,0.683-0.228,1.253-0.342,2.05
		c-2.392,0.911-5.012,1.367-7.404,2.05c-2.506,0.683-4.898,1.367-7.518,1.936c-0.228-2.05,0.342-3.873,0.342-5.695
		s0.456-3.531,0.342-5.353c-0.683,0-0.797,0.456-0.911,0.797c-1.709,2.734-3.645,5.353-5.923,7.631
		c-1.709,1.709-3.531,3.417-5.353,5.012c-0.911,0.797-1.481,1.595-1.595,2.848c-1.709,11.504-6.72,21.528-14.352,30.298
		c-2.506,2.848-5.24,5.24-8.429,7.29c-2.961,1.822-6.151,2.961-9.682,3.303c-2.05,0.114-3.987-0.228-5.809-1.139
		c-1.253-0.683-2.278-1.709-2.961-3.075c-1.253-2.506-1.595-5.012-1.025-7.745c0.456-2.62,1.481-4.898,2.961-7.176
		c2.05-3.303,4.784-6.151,7.631-8.771c3.531-3.303,7.29-6.151,11.276-8.998c1.025-0.683,1.936-1.481,2.961-2.164
		c0.57-0.456,0.797-0.911,0.797-1.595c0-1.595,0.114-3.075,0.114-4.67c-0.683,0.114-0.797,0.683-1.025,1.025
		c-3.075,4.101-7.176,6.834-12.074,8.087c-4.328,1.025-8.201,0-11.732-2.734c-2.62-2.164-4.442-4.784-5.695-7.973
		c-1.709-4.556-2.278-9.34-1.936-14.124c0.683-9.796,4.328-18.566,9.796-26.539c5.467-7.973,12.415-14.238,20.958-18.794
		c4.101-2.164,8.543-3.873,13.099-4.556c2.506-0.342,5.012-0.456,7.518,0.228c0.228,0.114,0.456,0.114,0.683,0.114
		c4.214,1.367,6.037,5.353,4.328,9.454c-0.57,1.367-1.367,2.506-2.392,3.531c-3.075,3.075-6.606,5.24-10.935,6.037
		c-1.139,0.228-2.278,0.342-3.759,0.114c0.683-0.797,1.139-1.253,1.595-1.709c1.139-1.253,1.936-2.62,2.278-4.328
		c0.228-0.911,0.228-2.05,0.114-2.961c-0.456-2.734-2.62-3.873-5.24-3.189c-1.595,0.456-2.848,1.253-3.987,2.392
		c-1.139,1.139-2.164,2.278-3.189,3.531c-1.936,2.392-3.189,5.126-4.556,7.745c-4.1,8.657-6.606,17.883-7.631,27.451
		c-0.342,3.075-0.456,6.151-0.228,9.226c0.114,1.709,0.57,3.303,1.253,4.784c0.228,0.456,0.57,1.025,0.911,1.481
		c0.911,1.025,1.936,1.367,3.303,0.911c1.367-0.456,2.392-1.253,3.189-2.392c1.139-1.481,1.936-3.189,2.506-5.012
		c1.595-4.442,2.848-8.884,3.417-13.554c0.57-4.214,1.139-8.429,1.709-12.529c0-0.228,0.114-0.456,0.228-0.57
		c1.595-0.911,15.377-4.784,17.997-5.126c0.342,1.595-0.228,3.189-0.228,4.898c0,1.595-0.228,3.303-0.342,4.898
		c-0.114,1.595-0.342,3.303-0.456,4.898c-0.114,1.709-0.228,3.417-0.342,5.126c-0.114,1.595-0.228,3.303-0.342,4.898
		c-0.114,1.595-0.228,3.303-0.228,4.898c-0.114,1.595-0.456,3.303-0.228,4.898c4.328-2.62,10.023-10.593,12.074-20.275
		c0.456-0.114,1.025-0.228,1.709-0.456c0.57-2.961,0.683-5.923,1.025-8.884s0.683-5.809,1.025-8.657
		c1.936-0.911,13.782-3.645,16.06-3.759C146.479,205.725,146.593,206.067,146.479,206.409L146.479,206.409z M94.539,266.208
		c-2.278,2.05-4.556,4.1-6.72,6.379c-2.164,2.164-3.873,4.556-5.467,7.176c-0.911,1.481-1.481,3.075-1.822,4.784
		c-0.342,1.936-0.228,3.873,0.797,5.695c0.911,1.709,2.506,2.392,4.328,1.822c1.481-0.456,2.734-1.253,3.645-2.62
		c0.911-1.253,1.595-2.506,2.278-3.873c2.392-5.24,3.417-10.821,3.873-16.402c0.114-1.025,0.114-2.164,0.228-3.531
		C94.995,265.866,94.767,265.98,94.539,266.208L94.539,266.208z M432.831,169.504c-0.911,0.911-1.822,1.822-2.734,2.62
		c-0.342-0.114-0.57-0.114-0.683-0.228c-4.328-3.303-8.657-6.606-13.099-9.91c-4.328-3.189-8.543-6.492-12.871-9.682
		c-2.62-2.05-5.24-3.987-7.859-6.037c-6.379-4.898-12.757-9.682-19.25-14.58c-6.265-4.784-12.415-9.454-18.68-14.238
		c-5.695-4.328-11.39-8.543-17.085-12.757c-4.556-3.417-8.998-6.834-13.554-10.251c-6.151-4.67-12.301-9.34-18.566-14.01
		c-4.214-3.189-8.429-6.492-12.643-9.682c-0.797-0.683-1.709-1.253-2.62-1.822c-1.139,0.456-1.822,1.139-2.62,1.709
		c-3.189,2.392-6.265,4.784-9.454,7.176c-2.506,1.822-4.898,3.759-7.404,5.581c-4.556,3.417-8.998,6.834-13.554,10.251
		c-2.848,2.164-5.695,4.328-8.543,6.606c-4.101,3.075-8.087,6.151-12.188,9.226c-4.556,3.417-8.998,6.834-13.554,10.251
		c-4.442,3.303-8.771,6.72-13.213,10.023c-6.72,5.126-13.327,10.137-20.047,15.263c-8.315,6.265-16.516,12.529-24.831,18.908
		c-4.101,3.075-8.315,6.151-12.415,9.34c-3.075,2.278-6.037,4.67-8.998,6.948c-1.709,1.367-3.417,2.62-5.126,3.873
		c-0.683,0.456-1.367,0.683-2.164,0.342c-2.506-0.797-5.126-1.367-7.745-1.481c-0.228,0-0.456-0.114-0.683-0.228
		c0-0.228,0-0.228,0-0.342s0-0.114,0.114-0.228c53.876-40.891,107.866-81.896,161.742-122.787c0.114-0.114,0.342-0.228,0.57-0.228
		c1.936,0.797,139.759,105.588,142.492,107.752C434.995,168.137,433.628,168.593,432.831,169.504L432.831,169.504z M42.03,332.271
		c5.24,8.087,10.479,16.06,15.719,24.147c-0.683,1.253-1.822,1.936-2.62,2.848c-0.911,0.911-1.709,1.822-2.62,2.734
		c-0.683,0-0.797-0.456-1.025-0.797c-2.62-3.987-5.126-7.973-7.745-11.846c-3.645-5.581-7.176-11.049-10.821-16.63
		c-5.012-7.745-10.023-15.377-15.035-23.122c-3.987-6.037-7.973-12.074-11.846-18.224c-1.936-2.961-3.873-6.037-5.809-8.998
		c-0.114-0.114-0.114-0.342-0.342-0.683c0.456-0.342,0.911-0.797,1.481-1.253c8.201-6.151,16.402-12.415,24.489-18.566
		c1.139-0.911,2.278-1.822,3.417-2.734c0.456-0.456,1.025-0.57,1.595-0.342c2.506,0.911,5.126,1.822,7.859,2.734
		c-0.114,0.228-0.228,0.57-0.57,0.797c-4.101,3.075-8.201,6.151-12.415,9.34c-2.506,1.822-4.898,3.759-7.404,5.581
		c-2.164,1.595-4.328,3.303-6.492,5.012c-0.456,0.342-0.911,0.683-1.481,1.253c5.353,8.201,10.479,16.288,15.719,24.375
		C31.437,315.983,36.791,324.07,42.03,332.271L42.03,332.271z M586.144,282.837c-2.734,4.214-5.467,8.429-8.201,12.643
		c-4.328,6.606-8.543,13.213-12.871,19.819c-2.961,4.556-6.037,9.112-8.998,13.668c-4.214,6.379-14.921,22.667-19.136,29.159
		c-1.595,2.392-3.075,4.784-4.784,7.404c-0.456-0.114-0.683-0.228-1.025-0.342c-1.936-1.025-3.987-1.936-6.037-2.961
		c1.709-3.075,3.645-5.923,5.581-8.771c1.822-2.848,10.251-15.491,12.074-18.338c1.822-2.848,3.759-5.809,5.581-8.657
		c1.823-2.848,3.759-5.581,5.581-8.429s3.645-5.809,5.581-8.657c1.822-2.848,3.759-5.581,5.581-8.429s3.759-5.809,5.581-8.657
		s3.873-5.467,5.467-8.657c-2.734-2.506-5.809-4.442-8.543-6.606c-2.848-2.278-5.695-4.328-8.657-6.606
		c0.342-0.228,0.456-0.342,0.57-0.342c2.506-0.114,5.012-0.456,7.404-1.025c0.456-0.114,1.025-0.114,1.481-0.342
		c0.683-0.114,1.253,0,1.822,0.456c0.911,0.797,1.936,1.481,2.961,2.164c3.531,2.734,7.062,5.353,10.593,8.087
		c0.911,0.683,1.936,1.481,2.961,2.278C586.486,282.04,586.372,282.496,586.144,282.837L586.144,282.837z M136.797,304.365
		c0.342,5.809-4.898,11.846-11.732,11.732c-6.72-0.114-11.846-4.898-11.96-11.846s6.037-11.96,12.188-11.732
		C131.672,292.633,137.253,298.442,136.797,304.365L136.797,304.365z M461.42,316.097c-5.809-0.114-11.39-4.67-11.504-11.732
		c-0.114-6.948,5.581-12.074,11.846-11.846c6.265-0.114,11.846,5.24,11.846,11.96C473.494,310.972,468.027,316.211,461.42,316.097
		L461.42,316.097z M364.603,307.668c0,1.139-0.228,2.164-0.683,3.075c-1.025,1.936-2.506,3.075-4.784,3.189
		c-3.873,0.228-6.492-2.164-6.606-6.037c0-2.164,0-4.442,0-6.72l0,0c0-2.164,0-4.442,0-6.72c0-1.595,0.57-2.848,1.709-4.1
		c1.709-1.822,3.873-2.278,6.151-1.595c2.392,0.683,3.645,2.62,4.101,5.012c0.114,0.569,0.114,1.139,0.114,1.709
		C364.603,299.809,364.489,303.796,364.603,307.668L364.603,307.668z M360.389,301.631L360.389,301.631c0-0.456,0-1.025,0-1.481
		c0-1.822,0-3.759,0-5.581c0-0.911-0.569-1.367-1.367-1.709c-0.797-0.228-1.367,0-1.936,0.569c-0.57,0.456-0.57,1.139-0.57,1.822
		c0,4.214,0,8.429,0,12.643c0,0.342,0,0.57,0.114,0.911c0.228,0.911,1.253,1.595,2.164,1.481s1.595-0.683,1.709-1.595
		c0-0.456,0-0.911,0-1.253C360.389,305.276,360.389,303.454,360.389,301.631L360.389,301.631z M259.471,313.705
		c-0.797-1.709-0.57-3.645-1.253-5.467c-0.911,0-1.822,0-2.62,0c-0.683,0-1.025,0.342-1.139,1.025
		c-0.114,0.797-0.228,1.709-0.342,2.506c-0.114,0.456-0.114,0.911-0.228,1.253c-0.114,0.342-0.456,0.683-0.683,0.683
		c-1.025,0.114-2.164,0.114-3.075,0c-0.114-0.342-0.228-0.456-0.228-0.57c1.253-7.859,2.62-15.605,3.987-23.464
		c0-0.228,0.228-0.342,0.342-0.57c1.595-0.228,3.075-0.114,4.784-0.114c0.114,0.456,0.342,0.911,0.456,1.367
		c0.797,4.214,1.481,8.429,2.164,12.643c0.57,3.075,1.025,6.151,1.481,9.34c0.114,0.342,0.114,0.683,0.114,1.253
		C261.749,313.933,260.61,313.933,259.471,313.705L259.471,313.705z M256.737,297.531c0-0.114-0.228-0.114-0.456-0.228
		c-0.228,2.164-0.456,4.442-0.683,6.606c0,0.114,0.114,0.228,0.228,0.456c0.57,0,1.025,0,1.936,0
		C257.42,301.859,257.079,299.695,256.737,297.531L256.737,297.531z M205.367,311.769c-1.481,2.05-3.531,2.62-5.923,2.278
		c-2.392-0.342-3.873-1.822-4.556-4.1c-0.342-0.911-0.342-1.936-0.342-2.961c-0.114-3.417-0.114-6.72-0.114-10.137
		c0-1.253,0.228-2.392,0.456-3.645c0.911-4.214,6.492-6.151,9.682-2.734c0.911,0.911,1.595,2.05,1.822,3.303
		c0.228,1.253,0.228,2.392,0,3.759c-1.481,0-2.506,0.114-3.759-0.114c-0.114-0.57-0.114-1.139-0.114-1.709
		c0-0.456,0-1.025-0.114-1.481c-0.228-0.911-1.253-1.595-2.164-1.481s-1.709,0.911-1.822,1.822c-0.114,0.911,0,1.822,0,2.848
		c0,3.189,0,6.265,0,9.454c0,0.456,0,1.025,0,1.481c0.114,1.025,0.797,1.709,1.822,1.822c0.797,0.114,1.822-0.57,2.05-1.595
		c0.114-0.456,0.114-0.911,0.114-1.253c0-0.797-0.114-1.595-0.114-2.392c1.253-0.342,2.506-0.114,3.759-0.114
		c0.683,0.911,0.342,1.822,0.456,2.734C206.848,309.149,206.278,310.516,205.367,311.769L205.367,311.769z M290.224,294.797
		c-0.797,0.114-1.709,0.342-2.62,0.114c-0.228-0.57-0.456-1.025-0.683-1.595c-0.342-0.683-0.911-1.139-1.709-1.025
		c-0.911,0.114-1.367,0.683-1.709,1.367c-0.456,1.139-0.114,2.164,0.342,3.075c0.57,1.025,1.367,1.822,2.164,2.62
		c1.139,1.025,2.164,2.05,3.189,3.189c0.797,0.797,1.253,1.822,1.709,2.961c0.911,2.392,0.342,4.67-1.139,6.72
		c-0.683,0.911-1.709,1.595-2.961,1.595c-0.683,0-1.481,0.114-2.164,0.114c-1.822,0-3.303-0.911-4.328-2.392
		c-0.797-1.253-1.253-2.62-1.481-3.987c0.911-1.025,2.164-0.569,3.303-0.797c0.342,0.797,0.57,1.481,1.025,2.164
		c0.342,0.683,0.797,1.367,1.709,1.481c1.481,0.114,2.734-1.139,2.392-2.734c-0.228-1.253-0.683-2.278-1.709-3.189
		c-0.911-0.797-1.822-1.595-2.734-2.506c-0.797-0.797-1.595-1.709-2.278-2.506c-1.709-2.164-1.709-4.556-1.025-6.948
		c0.683-2.164,2.734-3.645,5.012-3.645c0.797,0,1.595,0,2.392,0.228c2.392,0.683,3.417,2.392,3.873,4.67
		C291.136,294.342,290.794,294.683,290.224,294.797L290.224,294.797z M318.7,294.797c-0.683,0.114-1.367,0.228-2.278,0.342
		c-0.228-0.57-0.456-1.025-0.683-1.481c-0.569-1.025-1.253-1.481-2.278-1.253c-0.911,0.228-1.367,0.911-1.481,2.164
		c-0.114,1.139,0.228,2.164,0.797,3.075c0.683,1.025,1.595,1.822,2.62,2.62c1.709,1.367,2.961,3.075,3.987,5.012
		c0.797,1.595,0.797,3.417,0.228,5.012c-0.797,2.164-2.278,3.759-4.898,3.873c-3.189,0.114-5.012-0.683-6.492-4.1
		c-0.228-0.456-0.342-0.911-0.456-1.481c-0.114-0.342-0.114-0.683-0.114-0.911c0.57-0.797,1.253-0.57,1.822-0.683
		c1.595-0.228,1.595-0.228,2.05,1.253c0.114,0.456,0.342,0.911,0.683,1.367c0.911,1.253,2.62,1.139,3.417-0.114
		c0.57-0.911,0.797-1.936,0.228-2.848c-0.456-0.797-0.911-1.481-1.481-2.05c-1.481-1.481-2.961-2.961-4.328-4.442
		c-1.709-1.822-2.164-3.987-1.822-6.379c0.342-3.075,3.189-5.012,5.581-4.898c3.075,0.114,5.126,1.595,6.037,4.556
		C319.953,294.228,319.611,294.683,318.7,294.797L318.7,294.797z M392.168,292.747c-0.683,0.114-1.025,0.114-1.481,0.114
		c-1.481,0-2.848,0-4.328,0c-0.683,0-1.025,0.342-1.025,1.025c0,1.595,0,3.303,0,5.012c0.57,0.114,1.025,0.342,1.481,0.342
		c1.025,0.114,2.164,0,3.189,0.114c0.797,1.025,0.57,2.164,0.342,3.189c0,0.228-0.456,0.57-0.683,0.683
		c-0.57,0.114-1.139,0.114-1.709,0.114c-0.456,0-1.025-0.114-1.481-0.114c-0.683,0-1.025,0.342-1.025,1.025c0,2.392,0,4.898,0,7.29
		c0,0.683-0.114,1.253-0.114,1.936c-1.253,0.228-2.392,0.342-3.531,0c-0.456-1.253-0.342-2.392-0.342-3.645
		c0-2.392,0-4.67,0.114-7.062c0-4.328,0-8.657,0-12.871c0-0.342,0.228-0.683,0.342-1.025c0.342,0,0.57-0.114,0.911-0.114
		c2.734,0,5.467,0,8.201,0c1.025,0,1.253,0.228,1.367,1.253C392.168,291.039,392.168,291.722,392.168,292.747L392.168,292.747z
		 M233.273,313.591c-1.709,0.342-7.859,0.342-9.796,0c-0.114-0.342-0.228-0.797-0.228-1.139c0-7.29,0-14.58,0-21.983
		c0-0.456,0.114-0.797,0.114-1.253c0.228-0.114,0.456-0.228,0.683-0.342c3.075-0.114,3.189-0.342,3.189,2.848
		c0,5.353,0,10.593,0,15.946c0,0.683,0.114,1.253,0.114,2.164c2.164,0.114,3.987,0.114,5.809,0.114
		C233.615,311.313,233.615,312.452,233.273,313.591L233.273,313.591z M445.474,189.779c-2.506,1.936-5.353,2.05-8.087,0.797
		c-2.961-1.481-3.303-4.898-0.797-7.062c1.367-1.139,3.189-1.709,5.012-1.936c1.025,0.342,2.164,0.456,3.075,0.911
		C447.41,183.628,448.549,187.387,445.474,189.779L445.474,189.779z M398.774,394.69
		C398.774,394.576,398.774,394.576,398.774,394.69C398.774,394.576,398.774,394.576,398.774,394.69
		C398.774,394.576,398.774,394.69,398.774,394.69L398.774,394.69z M398.66,394.462v-0.114
		C398.66,394.462,398.66,394.462,398.66,394.462C398.66,394.576,398.66,394.576,398.66,394.462L398.66,394.462z M459.029,412.003
		c-1.139,9.568-4.328,18.338-8.771,26.767c-4.556,8.543-10.365,16.288-17.199,23.35c-8.884,9.34-19.022,17.199-29.615,24.603
		c-7.632,5.353-15.605,10.251-23.464,15.149c-6.379,3.987-12.643,7.859-18.794,12.188c-4.898,3.417-9.568,7.062-13.782,11.39
		c-3.189,3.303-6.037,6.948-7.973,11.162c-0.683,1.595-1.253,3.189-1.822,4.784c-0.114,0.228-0.114,0.57-0.114,1.139
		c0.797-0.456,1.367-0.797,1.936-1.139c7.632-4.898,15.605-9.112,24.034-12.415c5.581-2.278,11.39-4.101,17.313-5.012
		c6.037-0.911,11.846-0.57,17.655,1.139c6.265,1.822,11.96,4.898,17.085,8.884c1.367,1.025,2.62,2.164,3.987,3.303
		c0.797,0.57,1.595,1.253,2.506,1.709c2.392,1.367,4.784,1.253,7.176-0.342c1.709-1.025,2.961-2.506,3.987-4.101
		c1.595-2.278,2.848-4.898,3.759-7.518c0.456-1.367,0.911-2.734,1.253-4.101c0.114-0.456,0.228-1.025,0.456-1.481
		c0.57-1.481,1.709-1.822,3.189-1.139c0.456,0.228,0.797,0.57,1.253,0.911c0.911,0.683,1.253,1.481,1.139,2.62
		c-0.228,3.759-1.139,7.404-2.506,10.821c-3.189,7.859-8.087,14.352-15.263,19.022c-5.126,3.303-10.821,4.898-16.971,4.556
		c-5.126-0.228-9.796-1.822-14.238-4.328c-2.392-1.367-4.898-2.848-7.404-3.987c-4.784-2.392-9.91-4.214-15.149-5.24
		c-8.201-1.595-16.288-1.025-24.261,1.595c-3.417,1.025-6.606,2.392-9.682,3.987c-3.075,1.595-5.809,1.139-8.429-0.911
		c-1.025-0.797-1.822-1.822-2.734-2.734c-0.57-0.57-0.683-1.253-0.683-1.936c0.114-2.506,0.57-5.012,1.139-7.404
		c1.367-5.012,3.417-9.682,5.923-14.238c4.328-7.973,9.454-15.377,15.149-22.439c6.834-8.543,14.238-16.516,22.097-24.034
		c11.049-10.707,22.894-20.389,35.31-29.501c5.695-4.1,10.935-8.543,15.832-13.554c5.012-5.126,9.454-10.707,12.985-17.085
		c2.392-4.442,4.214-9.112,5.126-14.124c0.797-4.784,0.683-9.454-0.797-14.01c-2.05-6.265-6.151-10.821-11.846-13.896
		c-4.1-2.164-8.543-3.417-13.099-3.759c-7.404-0.57-14.58,0.342-21.528,3.075c-5.695,2.164-10.593,5.353-14.58,10.023
		c-5.809,6.834-8.429,14.693-7.973,23.692c0.228,4.898,1.367,9.454,3.303,14.01s4.556,8.771,7.518,12.757
		c0.456,0.57,1.025,1.253,1.481,1.822c4.556,6.265,3.987,14.352-1.595,19.477c-5.24,4.784-11.162,6.379-18.111,4.442
		c-1.253-0.342-2.164-1.025-2.848-2.05c-3.417-4.898-5.809-10.365-7.404-16.174c-2.278-8.429-2.848-17.085-1.822-25.742
		c0.797-7.632,2.848-14.921,6.151-21.869c7.29-15.149,18.794-25.173,34.854-30.184c5.012-1.481,10.023-2.392,15.263-2.734
		c3.987-0.342,7.973-0.342,12.074,0.114c8.087,0.797,15.719,3.075,22.553,7.745c8.201,5.695,13.441,13.441,15.946,23.008
		C459.598,398.563,459.826,405.283,459.029,412.003L459.029,412.003z"
        />
        <text
          transform="matrix(0.7607 0 0 1 489.6114 554.8896)"
          font-family="'ScriptMTBold'"
          font-size="266.8098"
        >
          1
        </text>
      </g>
    </svg>
  );
}

Artwrk2.propTypes = {
  primary: string,
};

Artwrk2.defaultProps = {
  primary: "#000",
};
