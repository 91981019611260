import React from "react";

import { connect } from "react-redux";

function Back(props) {
  return (
    <div>
      <svg
        version="1.1"
        id="backview"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="150 95 600 380"
        enableBackground="new 150 95 600 380"
        xmlSpace="preserve"
      >
        <path
          id="base"
          style={{ fill: props.rib2 }}
          d="M333.621,434.632c1.293,6.781,5.487,25.062,6.218,29.885c-2.194,5.993-36.247,13.134-41.506,8.015
	c-1.607-4.529-8.003-26.124-9.024-29.633c-1.028-3.506,14.468-6.722,21.48-9.205C317.808,431.207,332.632,429.431,333.621,434.632z
	 M446.324,161.765c-26.519,0-46.457-10.545-49.246-19.259c0.569-2.966,2.142-4.982,2.85-7.313c1.138-3.756,1.89-6.389,3.797-15.919
	c1.899-9.488,29.366-11.288,42.598-11.288s40.699,1.8,42.598,11.288c1.907,9.529,2.659,12.163,3.797,15.919
	c0.709,2.331,2.281,4.347,2.851,7.313C492.782,151.22,472.843,161.765,446.324,161.765z M559,434.632
	c-1.293,6.781-5.487,25.063-6.218,29.885c2.194,5.994,36.247,13.134,41.506,8.015c1.607-4.529,8.003-26.122,9.024-29.633
	c1.028-3.504-14.468-6.722-21.48-9.205C574.813,431.207,559.99,429.431,559,434.632z M446.442,412.224l-98.25-6.638
	c0,0,0,32.244,0,37.825c8.3,13.506,54.345,18.2,95.477,18.2c1.269,0,3.863,0.002,5.077,0.002
	c41.215-0.161,87.624-4.66,95.946-18.202c0-5.58,0-37.825,0-37.825L446.442,412.224z"
        />
        <path
          id="body"
          style={{ fill: props.body }}
          stroke=""
          strokemsiterlimit="10"
          d="M588.78,204.37c-6.46-16.12-9.03-26.58-20.15-37.09
	c-7.28-6.88-17.91-10-23.77-12.2c-3.08-1.15-4.47-1.79-5.3-2.02c-1.27-0.36-2.19-0.55-3.33-0.9c-1.04-0.32-1.95-1.07-2.9-1.37
	c-1.02-0.34-3.27-0.86-4.22-1.05c-9.17-1.79-18.49-3.96-31.44-7.4c-0.83,0-2.19-0.22-2.62,0.28c-8.36,1.69-19.54,4.96-48.29,5.16
	h-0.83c-28.74-0.2-39.93-3.47-48.29-5.16c-0.43-0.5-1.79-0.28-2.61-0.28c-12.95,3.44-22.28,5.61-31.44,7.4
	c-0.96,0.19-3.2,0.71-4.23,1.04c-0.94,0.31-1.85,1.06-2.9,1.38c-1.14,0.35-2.06,0.54-3.32,0.89c-0.84,0.24-2.23,0.88-5.31,2.03
	c-5.86,2.2-16.49,5.32-23.77,12.2c-11.12,10.51-13.69,20.97-20.15,37.09c-0.11,0.27-0.33,0.82-0.44,1.24
	c0.14,0.14,0.31,0.25,0.5,0.37c9.94,8.24,46.9,45.89,47.38,84.44c0,10.67-4.12,23.26-4.12,32.73c0,7.28,2.18,7.03,1.69,12.85
	c-0.72,8.73-3.14,13.09-3.14,20.37v0.16c0.2-0.99,0.38-1.96,0.53-2.9c-0.25,2.5-0.52,4.63-0.53,2.9
	c-1.96,9.85-6.07,21.91-6.07,29.54c0,10.69,5.34,17.7,8.49,23.52c10.25,7.24,40.88,15.2,95.72,15.34c0.81,0.01,1.62,0.01,2.43,0.01
	s1.62,0,2.42-0.01c54.84-0.14,85.47-8.1,95.72-15.34c3.15-5.82,8.49-12.82,8.49-23.52c0-7.62-4.1-19.66-6.06-29.5
	c-0.02,1.65-0.28-0.46-0.54-2.94c0.15,0.95,0.33,1.93,0.54,2.94v-0.2c0-7.28-2.43-11.64-3.15-20.37c-0.49-5.82,1.69-5.57,1.69-12.85
	c0-9.47-4.12-22.06-4.12-32.73c0.49-38.54,37.44-76.2,47.38-84.44c0.19-0.12,0.36-0.23,0.5-0.37
	C589.11,205.19,588.89,204.64,588.78,204.37z M346.74,349.95c-0.08,1.13-0.22,2.33-0.42,3.59C346.66,350.2,347,346.28,346.74,349.95
	z M546.37,353.54c-0.2-1.26-0.34-2.46-0.42-3.59C545.69,346.28,546.03,350.2,546.37,353.54z"
        />
        <path
          id="hand"
          style={{ fill: props.sleeves }}
          d="M573.486,172.502c0.11,0.331,29.875,48.97,46.448,118.414c3.446,9.129,3.366,10.109,3.956,11.866
	c0.852,2.564,3.27,5.851,4.101,10.107c1.169,6.008-1.024,13.334-1.024,16.265c0,6.46,2.873,22.932,2.635,26.809
	c-1.317,21.68-6.663,38.282-9.961,61.091c-0.234,1.626-3.187,1.973-3.514,3.661c-0.228,1.168,2.221,4.437,1.759,6.006
	c-1.466,4.984-14.503,16.702-14.503,16.702s-8.635,2.069-23.472-0.602c-15.368-2.766-20.775-8.188-20.775-8.188
	s-3.076-16.115-1.904-24.612c0.786-5.677,4.101-8.205,4.249-12.746c0.21-6.458,1.321-36.75,1.321-38.676
	c0-2.345,1.318-4.396,1.318-6.886c0-0.966-1.466-3.076-1.466-5.273c0-2.345,2.635-3.225,2.345-4.835
	c-0.379-2.087-3.004-1.221-3.225-2.783c-0.293-2.052,2.783-2.052,2.49-3.663c-0.207-1.154-2.49-1.611-3.37-3.37
	c-4.114-8.226-14.558-25.139-20.069-41.753C541.445,225.138,564.486,177.925,573.486,172.502z M324.06,167.28
	c-22.948,11.525-40.84,95.741-51.373,123.636c-3.446,9.129-3.366,10.109-3.956,11.866c-0.852,2.562-3.27,5.851-4.101,10.107
	c-1.169,6.008,1.024,13.334,1.024,16.264c0,6.462-2.873,22.932-2.635,26.81c1.318,21.68,6.663,38.282,9.961,61.089
	c0.234,1.628,3.187,1.975,3.515,3.663c0.228,1.168-2.221,4.435-1.759,6.006c1.466,4.982,14.503,16.702,14.503,16.702
	s8.634,2.069,23.472-0.602c15.369-2.768,20.775-8.188,20.775-8.188s3.076-16.115,1.904-24.613
	c-0.786-5.675-4.101-8.203-4.249-12.744c-0.211-6.458-1.321-36.75-1.321-38.676c0-2.345-1.318-4.396-1.318-6.886
	c0-0.966,1.466-3.078,1.466-5.275c0-2.343-2.635-3.223-2.345-4.834c0.379-2.088,3.004-1.223,3.225-2.783
	c0.293-2.052-2.783-2.052-2.49-3.663c0.207-1.154,2.49-1.611,3.37-3.37c4.115-8.226,14.558-25.141,20.069-41.753
	c3.011-9.072,2.648-38.565-5.019-71.565C341.79,201.281,337.669,179.252,324.06,167.28z"
        />
        <path
          id="ribs_1_"
          style={{ fill: props.rib1 }}
          stroke={props.rib1}
          strokeMiterlimit="10"
          d="M445.101,440.348L445.101,440.348c-51.957,0-90.265-5.841-96.91-16.823
	c0,0-1.149,3.968-0.184,8.554c6.559,13.088,51.656,17.921,98.12,17.921c46.466,0,101.189-6.583,98.917-21.981
	c-0.536-3.997-0.352-4.495-0.352-4.495C538.048,434.506,497.06,440.348,445.101,440.348z M574.592,460.845
	c-15.744-3.221-19.217-6.946-19.593-7.719c-0.402,0.7-1.035,3.349-0.895,4.104c0.483,0.862,3.586,4.497,19.328,7.719
	c16.218,3.318,21.608,1.476,22.33,1.169c0.763-0.593,1.468-3.021,1.317-4.197C596.567,462.232,590.808,464.163,574.592,460.845z
	 M578.5,450.25c-16.07-3.22-20.376-7.965-20.376-7.965s-1.317,2.051-2.067,6.99c0.469,0.707,3.928,4.497,19.998,7.719
	c16.554,3.318,21.84,1.904,22.415,1.542c0.705-3.673,2.755-7.448,2.755-7.448S595.057,453.568,578.5,450.25z M443.821,139.096
	c18.962,0,44.203,1.009,50.197,3.142c-0.363-1.347-1.435-4.758-2.018-5.655c-5.715-3.323-27.494-4.338-48.179-4.338
	c-20.683,0-39.383,2.38-44.773,5.123c-0.583,0.898-1.544,3.529-1.8,4.389C403.132,139.552,424.86,139.096,443.821,139.096z
	 M446.673,127.746c18.548,0,38.084,2.521,44.424,5.604c-0.692-1.025-1.154-6.066-1.643-7.447
	c-5.896-3.349-26.543-6.118-42.895-5.653l0,0c-16.352-0.466-41.31,3.25-43.89,7.026c-0.487,1.38-1.732,3.912-2.038,5.547
	C406.981,129.514,428.127,127.746,446.673,127.746z M318,462.75c15.691-3.126,19.065-6.805,19.525-7.494
	c-0.732-4.795-1.257-5.792-1.257-5.792s-4.128,4.668-19.817,7.795c-16.164,3.221-22.294,0.829-22.294,0.829
	s-0.484,2.373,1.395,5.809C296.117,464.248,301.837,465.971,318,462.75z M316.171,453.936c16.147-3.104,19.331-6.608,19.823-7.438
	c0.145-0.73-0.504-3.282-0.918-3.955c-0.385,0.745-3.949,4.334-20.094,7.439c-16.635,3.197-22.543,1.336-23.067,1.035
	c-0.154,1.133,0.568,3.473,1.351,4.045C294.006,455.359,299.535,457.134,316.171,453.936z M445.767,435.355
	c53.417,0,91.685-7.531,98.426-18.512c0,0,0.579-3.67-0.028-5.149c-4.425,6.729-33.924,18.248-98.399,18.248
	s-93.152-10.51-97.576-17.239c-0.605,1.48,0.308,4.464,0.308,4.464C355.241,428.148,392.35,435.355,445.767,435.355z"
        />
        <g className="back-center-parent" fontSize="40">
          <rect
            className={`b-st8 design-place-outline out ${
              props.centerActive && "active-path"
            } back-center`}
            height="100"
            width="175"
            x="360"
            y="242"
          ></rect>
          <rect
            className={`b-st9 design-place-outline in ${
              props.centerActive && "active-path"
            } back-center`}
            height="96"
            width="171"
            x="362"
            y="244"
          ></rect>
          <svg height="100" width="100%" x="146" y="245" viewBox="0 0 256 256">
            {props.backCenterImage}
          </svg>
        </g>

        <g className="back-top-parent">
          <rect
            className={`b-st8 design-place-outline out ${
              props.topActive && "active-path"
            } back-top`}
            height="58"
            width="190"
            x="353"
            y="180"
          ></rect>
          <rect
            className={`b-st9 design-place-outline in ${
              props.topActive && "active-path"
            } back-top`}
            height="54"
            width="186"
            x="355"
            y="182"
          ></rect>
          <text
            font="[object Object]"
            x="447"
            y="220"
            fill={props.backTopTextColor}
            fontSize={props.backTopTextSize}
            text=""
            textAnchor="middle"
            strokeWidth={props.backTopTextStroke}
            stroke={props.backTopTextStrokeColor}
            style={{ direction: "ltr" }}
            fontFamily={props.backTopTextFamily}
            height="56"
            width="248.5"
          >
            {props.backTopText}
          </text>
        </g>

        <g className="back-bottom-parent">
          <rect
            className={`b-st8 design-place-outline out ${
              props.bottomActive && "active-path"
            } back-bottom`}
            height="58"
            width="190"
            x="351"
            y="349"
          ></rect>
          <rect
            className={`b-st9 design-place-outline in ${
              props.bottomActive && "active-path"
            } back-bottom`}
            height="54"
            width="186"
            x="353"
            y="351"
          ></rect>
          <text
            font="[object Object]"
            x="447"
            y="390"
            fill={props.backBottomTextColor}
            fontSize={props.backBottomTextSize}
            text=""
            textAnchor="middle"
            strokeWidth={props.backBottomTextStroke}
            stroke={props.backBottomTextStrokeColor}
            style={{ direction: "ltr" }}
            fontFamily={props.backBottomTextFamily}
            height="56"
            width="248.5"
          >
            {props.backBottomText}
          </text>
        </g>
      </svg>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    body: state.shirtColor.body,
    sleeves: state.shirtColor.sleeves,
    rib1: state.shirtColor.rib1,
    rib2: state.shirtColor.rib2,
    // text, image
    topActive: state.backView.chooseTop,
    centerActive: state.backView.chooseCenter,
    bottomActive: state.backView.chooseBottom,
    //...
    backTopText: state.backView.backTopText,
    backTopTextColor: state.backView.backTopTextColor,
    backTopTextSize: state.backView.backTopTextSize,
    backTopTextFamily: state.backView.backTopTextFamily,
    backTopTextStroke: state.backView.backTopTextStroke,
    backTopTextStrokeColor: state.backView.backTopTextStrokeColor,
    //...
    backCenterImage: state.backView.backCenterImage,
    backBottomText: state.backView.backBottomText,
    backBottomTextColor: state.backView.backBottomTextColor,
    backBottomTextSize: state.backView.backBottomTextSize,
    backBottomTextFamily: state.backView.backBottomTextFamily,
    backBottomTextStroke: state.backView.backBottomTextStroke,
    backBottomTextStrokeColor: state.backView.backBottomTextStrokeColor,
  };
};

export default connect(mapStateToProps)(Back);
