import React from "react";
import { string } from "prop-types";

export default function Artwrk22(props) {
  const { primary } = props;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      fill={primary}
      width="100%"
      height="100%"
      viewBox="0 0 400 400"
      enable-background="new 0 0 400 400"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M123.723,31.422c-0.288-0.539-0.433-0.808-0.721-1.347c2.19-1.17,3.292-1.742,5.509-2.856
			c1.923,3.823,2.884,5.735,4.806,9.558c-2.122,1.067-3.176,1.612-5.272,2.732c-0.092-0.173-0.138-0.258-0.23-0.431
			c-1.034-1.936-2.259-2.53-3.664-1.761c-0.971,0.531-1.385,1.178-1.247,1.931c0.138,0.753,1.026,2.589,2.66,5.506
			c1.793,3.202,3.35,4.428,4.681,3.7c1.233-0.675,1.373-1.901,0.412-3.697c-0.218-0.408-0.326-0.612-0.544-1.02
			c-0.181-0.338-0.272-0.508-0.453-0.847c2.268-1.214,3.409-1.805,5.707-2.955c0.841,1.171,1.583,2.407,2.229,3.704
			c2.505,5.026,1.229,8.742-3.692,11.433c-3.35,1.832-5.845,2.377-7.574,1.533s-4.103-3.749-7.1-8.743
			c-2.903-4.837-4.423-8.303-4.523-10.407c-0.101-2.104,1.16-3.932,3.786-5.403C120.415,30.973,122.173,30.769,123.723,31.422z"
          />
          <path
            d="M155.296,31.032c1.792,4.609,2.686,6.913,4.476,11.522c-6.791,2.639-10.134,4.101-16.697,7.296
			c-1.251-2.572-1.877-3.858-3.128-6.43c0.817-0.397,1.227-0.594,2.047-0.984c-2.186-4.609-3.279-6.915-5.467-11.523
			c-0.866,0.411-1.299,0.618-2.161,1.038c-1.197-2.459-1.795-3.688-2.993-6.148c4.316-2.101,6.497-3.097,10.903-4.985
			c1.078,2.514,1.618,3.771,2.695,6.284c-0.932,0.399-1.397,0.601-2.326,1.01c2.059,4.668,3.088,7.002,5.145,11.671
			c1.636-0.722,2.456-1.074,4.105-1.763c-0.803-1.924-1.203-2.886-2.006-4.809C152.04,32.311,153.123,31.876,155.296,31.032z"
          />
          <path
            d="M178.963,28.656c0.765,2.756,1.148,4.136,1.912,6.892c-4.12,1.143-6.167,1.764-10.226,3.103
			c-0.896-2.716-1.344-4.076-2.24-6.792c0.899-0.296,1.35-0.442,2.251-0.729c-0.707-1.296-1.063-1.944-1.778-3.237
			c-1.907,0.614-2.857,0.932-4.75,1.587c0.192,1.467,0.29,2.199,0.491,3.664c0.969-0.338,1.454-0.504,2.427-0.832
			c0.913,2.711,1.37,4.067,2.282,6.778c-3.377,1.138-5.056,1.74-8.386,3.012c-1.02-2.672-1.531-4.008-2.551-6.68
			c0.785-0.3,1.178-0.447,1.965-0.74c-1.03-7.978-1.471-11.985-2.199-20.033c3.442-1.203,5.176-1.772,8.66-2.848
			c4.248,6.891,6.298,10.37,10.25,17.389C177.828,28.973,178.205,28.866,178.963,28.656z M167.425,25.482
			c-1.733-2.897-2.613-4.342-4.396-7.217c0.276,3.378,0.427,5.065,0.756,8.432C165.237,26.199,165.966,25.956,167.425,25.482z"
          />
          <path
            d="M192.722,5.304c0.718,3.701,1.078,5.552,1.797,9.252c-2.492,0.484-3.734,0.743-6.211,1.292
			c-0.102-0.267-0.154-0.4-0.256-0.667c-0.4-1.155-1.128-1.617-2.18-1.374c-1.281,0.295-1.812,0.901-1.596,1.803
			c0.246,1.029,1.562,1.649,3.912,1.93c3.711,0.442,6.122,1.158,7.248,1.997s1.921,2.542,2.402,5.096
			c0.892,4.736-0.658,7.451-4.609,8.308c-2.124,0.46-3.987,0.101-5.64-1.099c0.179,0.739,0.268,1.108,0.446,1.847
			c-2.147,0.519-3.219,0.792-5.353,1.364c-1.054-3.928-1.581-5.892-2.635-9.82c2.231-0.599,3.351-0.884,5.597-1.427
			c0.037,0.152,0.057,0.227,0.093,0.379c0.415,1.714,1.386,2.384,2.925,2.029c1.43-0.33,2.013-1.091,1.746-2.303
			c-0.276-1.25-1.311-2.023-3.12-2.287c-1.449-0.219-2.175-0.322-3.635-0.518c-3.646-0.483-5.9-2.148-6.707-5.124
			c-1.271-4.69,0.221-7.642,4.522-8.685c2.139-0.519,4.017-0.146,5.592,1.099c-0.162-0.735-0.244-1.102-0.405-1.836
			C189.075,6.029,190.288,5.777,192.722,5.304z"
          />
          <path
            d="M213.654,2.155c0.4,3.748,0.6,5.623,1.001,9.371c-2.534,0.272-3.798,0.425-6.32,0.763
			c-0.079-0.274-0.12-0.411-0.199-0.685c-0.301-1.185-0.99-1.707-2.063-1.554c-1.306,0.186-1.885,0.744-1.747,1.661
			c0.157,1.046,1.417,1.777,3.741,2.255c3.671,0.754,6.021,1.669,7.074,2.601s1.703,2.695,1.965,5.281
			c0.485,4.795-1.297,7.37-5.32,7.89c-2.162,0.279-3.995-0.236-5.544-1.572c0.115,0.751,0.172,1.128,0.287,1.879
			c-2.19,0.335-3.281,0.518-5.461,0.907c-0.717-4.003-1.075-6.006-1.792-10.01c2.28-0.408,3.422-0.597,5.713-0.948
			c0.023,0.155,0.035,0.231,0.059,0.386c0.268,1.743,1.182,2.491,2.75,2.267c1.458-0.208,2.106-0.917,1.943-2.146
			c-0.169-1.269-1.136-2.126-2.922-2.542c-1.43-0.34-2.149-0.506-3.59-0.824c-3.601-0.79-5.708-2.639-6.259-5.672
			c-0.867-4.782,0.877-7.597,5.263-8.272c2.181-0.336,4.023,0.195,5.49,1.569c-0.099-0.746-0.147-1.12-0.246-1.866
			C209.943,2.568,211.179,2.42,213.654,2.155z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M146.956,397.938c-2.103,0.628-3.153,0.947-5.248,1.601c-1.356-3.262-2.034-4.892-3.391-8.153
			c2.146-0.669,3.22-0.997,5.375-1.641c1.53,2.037,3.166,2.798,4.932,2.286c1.276-0.369,1.706-1.104,1.285-2.212
			c-0.22-0.578-0.629-0.994-1.221-1.248c-0.642-0.275-1.892-0.559-3.731-0.842c-2.325-0.358-4.11-0.754-5.31-1.146
			c-1.113-0.364-2.218-0.995-3.289-1.896c-1.063-0.895-1.879-1.996-2.427-3.312c-0.985-2.364-1.004-4.447-0.047-6.239
			c0.952-1.783,2.542-3.017,4.753-3.666s4.338-0.549,6.351,0.302c-0.238-0.636-0.358-0.954-0.597-1.59
			c2.291-0.644,3.438-0.956,5.737-1.572c1.05,2.94,1.575,4.409,2.626,7.35c-2.224,0.596-3.333,0.897-5.548,1.52
			c-1.434-1.831-3.028-2.498-4.759-1.997c-1.219,0.353-1.618,1.066-1.2,2.134c0.202,0.516,0.537,0.846,0.97,1.008
			c0.432,0.162,1.75,0.449,3.899,0.863c2.891,0.557,4.851,1.056,5.869,1.404c0.51,0.175,1.078,0.455,1.691,0.853
			s1.157,0.835,1.632,1.3c1.064,1.041,1.86,2.273,2.36,3.685c0.768,2.168,0.655,4.202-0.268,6.093
			c-0.931,1.908-2.49,3.186-4.715,3.818c-2.067,0.588-4.188,0.464-6.396-0.37C146.556,396.938,146.69,397.27,146.956,397.938z"
          />
          <path
            d="M186,388.139c-9.143,1.872-13.687,2.925-22.706,5.254c-0.854-2.48-1.28-3.722-2.135-6.202
			c0.748-0.193,1.121-0.287,1.869-0.478c-1.832-5.408-2.749-8.112-4.581-13.519c-0.792,0.201-1.188,0.303-1.979,0.507
			c-0.855-2.479-1.283-3.719-2.137-6.198c9.725-2.513,14.627-3.646,24.487-5.666c0.917,3.366,1.376,5.049,2.296,8.414
			c-2.646,0.542-3.967,0.824-6.604,1.402c-0.247-0.844-0.37-1.267-0.617-2.11c-2.296,0.504-3.442,0.762-5.731,1.293
			c0.498,1.606,0.745,2.409,1.243,4.016c1.97-0.457,2.956-0.681,4.931-1.117c0.629,2.131,0.944,3.197,1.573,5.328
			c-1.946,0.43-2.918,0.65-4.858,1.101c0.53,1.712,0.795,2.567,1.325,4.279c2.203-0.511,3.305-0.76,5.515-1.244
			c-0.247-0.844-0.37-1.265-0.617-2.108c2.567-0.563,3.853-0.839,6.43-1.366C184.624,383.09,185.083,384.771,186,388.139z"
          />
          <path
            d="M199.033,385.667c-3.96,0.692-5.937,1.061-9.877,1.839c-0.663-2.524-0.995-3.786-1.66-6.31
			c0.857-0.169,1.285-0.254,2.144-0.419c-1.414-5.501-2.124-8.25-3.537-13.75c-0.892,0.172-1.338,0.258-2.229,0.434
			c-0.665-2.522-0.997-3.783-1.659-6.307c4.897-0.968,7.354-1.42,12.276-2.266c5.041,4.894,7.501,7.371,12.282,12.38
			c-0.612-3.016-0.92-4.523-1.531-7.539c-0.832,0.127-1.247,0.191-2.079,0.321c-0.53-2.547-0.796-3.82-1.323-6.367
			c4.201-0.657,6.306-0.964,10.522-1.533c0.456,2.558,0.688,3.834,1.146,6.391c-0.894,0.121-1.343,0.184-2.236,0.309
			c1.533,8.256,2.308,12.382,3.839,20.641c-3.193,0.446-4.785,0.683-7.969,1.184c-5.415-5.793-8.218-8.653-14-14.289
			c0.896,3.729,1.345,5.593,2.24,9.322c0.868-0.156,1.302-0.23,2.171-0.383C198.146,381.861,198.443,383.129,199.033,385.667z"
          />
          <path
            d="M233.519,380.939c-5.569,0.553-8.351,0.872-13.895,1.591c-0.441-2.561-0.663-3.841-1.105-6.4
			c0.911-0.118,1.367-0.177,2.279-0.29c-0.928-5.578-1.394-8.365-2.318-13.942c-0.947,0.118-1.42,0.177-2.366,0.3
			c-0.44-2.56-0.661-3.84-1.1-6.399c5.966-0.772,8.954-1.112,14.947-1.708c0.337,2.571,0.509,3.856,0.848,6.428
			c-0.943,0.094-1.416,0.141-2.358,0.239c0.774,5.595,1.165,8.391,1.944,13.985c0.907-0.095,1.361-0.142,2.27-0.231
			C233.006,377.082,233.177,378.368,233.519,380.939z"
          />
          <path
            d="M234.917,367.14c-0.277-2.242-0.027-4.46,0.772-6.653c0.794-2.177,2.223-4.094,4.297-5.691
			c0.735-0.566,1.513-1.029,2.323-1.39c0.81-0.359,1.706-0.654,2.678-0.884c0.973-0.23,2.004-0.382,3.094-0.45
			c4.74-0.299,8.521,0.961,11.248,3.677c1.351,1.345,2.391,2.824,3.112,4.432c0.722,1.608,1.117,3.326,1.199,5.152
			c0.169,3.767-0.976,7.08-3.341,9.966c-2.366,2.886-5.628,4.433-9.868,4.7c-4.099,0.259-7.537-0.799-10.4-3.174
			C237.168,374.45,235.424,371.235,234.917,367.14z M245.953,366.243c0.28,2.995,0.76,5.003,1.377,6.008s1.465,1.498,2.564,1.429
			c1.088-0.068,1.864-0.627,2.321-1.678c0.462-1.062,0.575-3.124,0.346-6.174c-0.223-2.961-0.646-4.964-1.272-6.005
			c-0.616-1.025-1.513-1.508-2.685-1.434c-1.122,0.07-1.919,0.64-2.385,1.697C245.754,361.146,245.668,363.195,245.953,366.243z"
          />
          <path
            d="M281.011,378.639c-5.355-0.012-8.031,0.02-13.378,0.16c-0.094-2.586-0.142-3.879-0.233-6.465
			c0.814-0.021,1.222-0.031,2.036-0.049c-0.168-5.632-0.253-8.447-0.416-14.08c-0.847,0.019-1.271,0.028-2.116,0.051
			c-0.09-2.587-0.134-3.881-0.222-6.468c5.757-0.151,8.638-0.188,14.403-0.176c1.296,0.003,2.499,0.032,3.604,0.088
			s2.05,0.134,2.836,0.229s1.51,0.222,2.166,0.377c1.844,0.437,3.324,1.351,4.417,2.729c1.11,1.399,1.617,3.039,1.539,4.903
			c-0.059,1.398-0.46,2.658-1.196,3.772c-0.729,1.104-1.663,1.911-2.794,2.416c1.331,2.504,1.983,3.759,3.267,6.271
			c0.722,0.022,1.085,0.035,1.807,0.061c-0.121,2.586-0.182,3.877-0.303,6.463c-3.551-0.124-5.33-0.168-8.881-0.225
			c-2.054-4.447-3.113-6.667-5.301-11.092c-1.25-0.006-1.875-0.005-3.124-0.003c0.005,1.827,0.007,2.738,0.012,4.564
			c0.758-0.001,1.136,0.001,1.894,0.003C281.021,374.758,281.018,376.051,281.011,378.639z M279.096,357.537
			c0.005,2.061,0.009,3.092,0.014,5.151c0.444-0.001,0.666-0.002,1.11-0.002c1.456,0.001,2.447-0.042,2.964-0.126
			c0.553-0.09,1.03-0.355,1.42-0.767c0.376-0.396,0.588-0.945,0.598-1.645c0.01-0.7-0.18-1.26-0.556-1.679
			c-0.382-0.425-0.877-0.689-1.475-0.785c-0.574-0.092-1.627-0.149-3.153-0.149C279.649,357.536,279.464,357.536,279.096,357.537z"
          />
          <path
            d="M304.204,379.259c-2.218-0.114-3.327-0.164-5.546-0.252c0.185-3.462,0.277-5.194,0.461-8.657
			c2.273,0.09,3.408,0.142,5.681,0.258c0.516,2.389,1.693,3.628,3.551,3.738c1.342,0.079,2.061-0.456,2.16-1.619
			c0.052-0.606-0.144-1.126-0.578-1.556c-0.472-0.467-1.501-1.142-3.075-2.011c-1.989-1.098-3.463-2.053-4.396-2.811
			c-0.867-0.703-1.61-1.654-2.201-2.841c-0.587-1.178-0.854-2.463-0.782-3.86c0.131-2.511,1.028-4.443,2.698-5.784
			c1.662-1.334,3.671-1.952,5.999-1.823s4.252,0.928,5.74,2.379c0.059-0.666,0.087-1.001,0.146-1.667
			c2.402,0.161,3.602,0.247,6.002,0.438c-0.317,3.065-0.477,4.598-0.799,7.663c-2.322-0.184-3.485-0.27-5.81-0.426
			c-0.52-2.166-1.7-3.308-3.521-3.416c-1.281-0.076-1.964,0.45-2.047,1.575c-0.04,0.543,0.124,0.957,0.452,1.25
			c0.326,0.291,1.418,0.992,3.224,2.085c2.427,1.468,4.025,2.579,4.813,3.236c0.396,0.33,0.797,0.777,1.188,1.348
			c0.393,0.57,0.704,1.156,0.938,1.742c0.525,1.313,0.719,2.713,0.558,4.183c-0.247,2.258-1.252,4.098-2.942,5.541
			c-1.705,1.456-3.711,2.125-6.046,1.976c-2.17-0.139-4.071-0.955-5.743-2.455C304.278,378.198,304.253,378.553,304.204,379.259z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M239.595,24.484c0.367-3.401,0.552-5.101,0.919-8.501c0.411-3.808,1.52-6.26,3.402-7.291
			c1.883-1.03,5.545-1.192,10.791-0.021c5.204,1.162,8.423,2.87,9.689,4.639c1.267,1.769,1.208,4.529-0.071,8.248
			c-1.035,3.009-1.553,4.513-2.588,7.521c-0.292,0.779-0.438,1.168-0.729,1.948c-1.57,4.572-5.577,5.546-12.345,4.046
			C242.059,33.61,238.951,30.445,239.595,24.484z M254.93,23.883c0.323-1.248,0.483-1.873,0.807-3.121
			c0.095-0.459,0.145-0.689,0.238-1.148c0.106-0.413,0.159-0.618,0.266-1.031c0.364-1.41-0.63-2.439-3.018-2.974
			c-2.506-0.561-4.06,0.739-4.629,3.771c-0.25,1.329-0.375,1.993-0.624,3.321c-0.524,2.793,0.306,4.375,2.468,4.857
			C252.669,28.057,254.156,26.87,254.93,23.883z"
          />
          <path
            d="M295.235,15.926c-1.196,1.962-1.793,2.943-2.989,4.905c-0.984-0.782-1.853-1.381-2.586-1.802
			c-3.134-1.801-5.095-1.939-5.771-0.631c-0.144,0.277-0.188,0.7-0.143,1.271c2.118,1.106,3.161,1.688,5.227,2.901
			c-1.439,2.447-2.156,3.671-3.596,6.118c-2.237-1.315-3.372-1.939-5.676-3.12c-2.162,4.219-3.244,6.328-5.406,10.547
			c1.515,0.776,2.267,1.181,3.749,2.023c-1.473,2.595-2.213,3.894-3.687,6.489c-4.853-2.757-7.385-3.949-12.575-5.937
			c1.066-2.787,1.6-4.18,2.667-6.967c1.118,0.428,1.674,0.652,2.78,1.113c1.827-4.375,2.74-6.563,4.567-10.938
			c-1.236-0.516-1.856-0.766-3.107-1.245c1.015-2.651,1.521-3.977,2.537-6.628c1.33,0.509,1.992,0.774,3.307,1.323
			c-0.064-1.417,0.083-2.6,0.47-3.546c1.535-3.753,6.16-4.263,13.406-0.459C290.852,12.625,293.139,14.162,295.235,15.926z"
          />
        </g>
      </g>
      <polygon points="253.424,254.716 253.158,109.108 237.605,109.108 237.605,89.82 250.415,71.674 282.941,71.163 284.098,254.716 " />
      <path
        d="M251.781,346.905c-27.212-15.192-94.255-17.368-94.255-17.368c-16.122-2.473-44.7-8.884-44.7-8.884
	c-92.791-37.252-84.77-135.1-84.77-135.1c-12.8-64.345,69.305-137.556,69.305-137.556L75.369,59.123c0,0,1.753,1.599-7.509,12.65
	l-6.552,10.729l-11.21-19.337l-2.706,36.885l-4.242,7.673L25.216,88.221l8.041,38.317l-5.679,11.14L7.746,122.407l15.271,36.941
	l0.642,9.739L6.881,157.393l16.691,34.094l2.036,7.989l-25.4-8.567l26.3,26.87l1.978,11.328l-23.728-3.698l30.031,20.967
	l5.524,12.972l-26.534,0.513l37.783,17.271l3.901,5.151l-25.724,4.421l39.318,9.918l2.623,3.177l-23.182,9.929l39.96,1.767
	l4.865,2.842l-20.506,11.94l35.138-3.8l9.843,2.189l-15.612,13.615c0,0,34.439-10.428,46.495-7.795l-16.214,18.658
	c0,0,36.836-15.037,43.509-13.707c0,0,8.777-1.676,30.949,0.766c0,0-19.558,10.408-21.433,12.695c0,0-6.32,1.4-5.509,5.31
	l13.915,0.344c0,0,19.793-17.791,25.074-14.938c0,0,16.837,4.86,25.393,9.963l11.701-0.49c0,0,2.787-3.432,0.125-5.052
	c0,0-19.609-7.486-28.975-10.051l-7.965-2.483"
      />
      <path
        d="M193.027,351.067c12.568-19.238,77.283-33.125,77.283-33.125c82.685-26.443,106.007-108.877,106.007-108.877
	c31.786-92.286-68.87-171.869-68.87-171.869l7.478,18.275l10.361,2.723l5.842,5.833l-19.786,1.166l36.823,19.054h-22.438
	l33.239,17.887l5.402,8.942l-19.787-5.443l26.954,22.941l2.262,5.444l-21.115-8.556l24.697,27.609l0.884,5.443l-21.996-12.442
	c0,0,22.93,27.606,22.489,37.327l-17.088-13.608c0,0,11.491,28.092,12.129,36.551l-16.646-15.144c0,0,9.676,28.26,7.218,39.252
	l-12.127-18.274c0,0,2.714,27.613-0.441,38.495l-9.921-20.998c0,0-0.03,30.79-2.698,38.494l-3.585,5.056l-6.727-22.941
	c0,0-3.641,24.887-7.688,37.328l-8.514,6.224l-2.652-23.332l-17.529,34.219v1.944l-8.984,2.722L287.8,288l-24.528,28.387
	c0,0-38.687,8.165-37.338,9.721L193.027,351.067z"
      />
      <path
        d="M97.361,47.998c0,0-6.891,18.11-6.978,21.609l-16.326,5.82l-6,7.242c0,0,20.746-3.766,24.248,0.6l-21.25,5.31l-7.678,1.625
	l-4.327,4.82l5.736-1.933l6.268-4.512l21.25-5.31c0,0-15.443,11.465-28.872,16.271c0,0-10.234,2.081-13.088,8.233
	c0,0,18.068-3.702,22.806-0.992l-18.137,6.553l-9.377,3.14l-1.627,4.887l11.004-8.026l18.137-6.553c0,0-10.166,10.899-16.551,14.891
	c0,0-11.686,6.671-16.061,7.641c0,0-3.958,2.494-3.813,8.204c0,0,20.895-8.819,24.758-7.428L42.31,141.802l-7.891,3.954
	l-0.128,5.183l8.019-9.137l19.175-11.714c0,0-13.396,20.674-30.054,28.301c0,0-0.554,9.84-0.308,12.439
	c0,0,17.078-12.024,25.406-10.523l-16.181,12.501c0,0-10.824,2.909-9.649,9.421l9.649-9.421l16.181-12.501
	c0,0-15.352,22.2-21.96,28.76c0,0-3.959,2.495-2.86,6.411c0,0,0.558,1.569,2.986,0.074c0,0,5.623-8.938,16.189-12.824l-12.01,13.966
	c0,0-8.932,9.372-6.921,13.054c0,0,2.944-9.782,6.921-13.054l12.01-13.966c0,0-3.034,14.445-8.348,23.391
	c0,0-8.367,10.685-8.457,14.311l1.073,4.956c0,0,13.382-20.156,16.869-15.142l-9.081,15.334c0,0-7.371,7.078-4.812,12.845
	c0,0,2.333-9.275,4.812-12.845l9.081-15.334l-0.962,14.755c0,0-10.975,19.437-6.625,25.249l2,4.2c0,0,10.865-25.664,14.582-19.349
	l-6.708,15.133c0,0-7.657,7.072-3.599,13.135c0,0,1.572-5.72,2.115-11.504l8.192-16.764c0,0,0.396,9.085-0.333,13.476
	c-0.73,4.391-0.032,1.295-0.032,1.295l-3.808,19.614c0,0-0.664,4.65,3.161,6.561c0,0,3.213-10.034,5.074-12.84
	c0,0,5.167-14.653,7.268-2.932l-4.179,11.436c0,0-4.617,4.942-1.212,12.287l1.212-12.287l4.179-11.436c0,0,2.685,12.254,2.01,15.347
	c0,0-2.682,11.863,1.397,16.114c0,0,0.569,2.087,1.962-5.917c0,0,1.05-6.715,3.808-8.979c0,0,2.58-7.718,4.839-1.438l-3.02,12.891
	c0,0-4.955,6.102,0.336,10.64l-0.336-10.64l3.02-12.891c0,0,3.619,11.237,3.218,14.861c-0.397,3.619-0.026,1.035-0.026,1.035
	s1.167,13.772,3.232,14.342l4.719,3.488c0,0-0.126-7.523,0.282-11.402c0,0,2.236-5.39,2.554-6.681c0,0,2.302-7.979,4.848-1.693
	c0,0,0.182,4.152,3.994,7.618l-4.607,4.553c0,0-3.721,1.607-1.412,8.782c0,0,2.61,3.695,3.535,2.938c0,0-4.861-10.234-2.124-11.721
	l4.607-4.553l1.867,9.641c0,0,1.845,5.231,1.798,9.639c0,0,8.668,3.064,9.85,3.614c1.181,0.547-1.181-0.55-1.181-0.55
	s-1.091-13.252,2.003-18.878c0,0-0.17-7.006,4.498-1.445c0,0,4.326,6.849,5.475,8.691l-4.296,4.563c0,0-2.954-2.405-1.596,3.592
	c0,0-0.989,1.82,0.163,4.297l4.223,0.104l-2.791-7.993l4.296-4.563c0,0,3.773,6.658,5.375,12.727c1.601,6.067-0.035,1.41-0.035,1.41
	s2.56,5.769,11.612,3.138c0,0-5.85-6.108-4.781-12.564c0,0-0.765-4.946,3.553-3.542c0,0,11.083,5.2,12.538,7.312l-1.66,6.183
	c0,0-5.271-5.317-3.277-0.858c1.994,4.456,0.877,1.317,0.877,1.317l2.272,4.724l4.491,0.11l-4.363-5.293l1.66-6.183
	c0,0,8.38,4.354,12.127,11.709c0,0,15.674,2.722,20.764,1.811l14.998,0.638l15.954,3.086l26.186,13.505
	c0,0-60.574-16.537-67.89-14.771c0,0-71.031-3.251-85.634-18.229c0,0-39.298-15.192-54.708-55.574c0,0-21.356-24.629-16.73-82.48
	c0,0-11.802-32.391,37.374-94.446C64.193,81.405,93.766,48.427,97.361,47.998z"
      />
      <path
        d="M307.447,37.196l17.971,7.454c0,0,0,9.396,12.568,17.692c0,0,1.817-3.112,4.829-7.777c0,0,4.467-8.295,5.662-4.796
	l-3.272,9.981c0,0-3.264,5.137-2.129,10.369c0,0,2.391-6.481,2.129-10.369l3.272-9.981c0,0,3.011,15.424,0,25.534l5.713,7.258
	c0,0,5.09-7.518,7.479-10.629c0,0,2.7-4.666,3.583-0.258c0.884,4.407,0,2.333,0,2.333l-3.012,7.258c0,0-5.971,5.703-3.582,8.555
	c2.39,2.852,0.885,0,0.885,0s2.126-5.573,2.697-8.555l3.247-8.503c0,0,2.379,11.297,0.163,18.326c0,0-3.67,2.362,3.186,11.435
	l2.131,4.406l1.766-2.852c0,0,7.626-8.296,9.788-6.481l-5.27,9.333c0,0-5.091,4.926-3.323,9.074c0,0,1.193-10.629,2.699-10.37
	l5.894-8.037c0,0,2.926,7.518,0,12.184c0,0-9.788,13.999-2.881,20.739c0,0,11.375-9.332,13.452-6.22l-7.426,9.803
	c0,0-2.049,0.196-2.896,0.92c-1.282,1.094-2.718,2.903-1.622,5.088l11.944-15.811c0,0-3.095,12.198-10.571,22.292v9.592
	c0,0,10.883-10.37,14.207-8.296l-8.986,10.628c0,0-6.023,3.888-3.895,7.777l3.895-7.777l8.986-10.628
	c0,0-2.576,16.522-11.687,24.887l-0.622,8.878c0,0,13.501-6.288,15.581-3.694l-11.063,8.813c0,0-7.695,0.723-6.417,7.36l6.417-7.36
	L400,177.958c0,0-8.083,14.5-15.269,20.998c0,0-7.48,4.406-6.286,10.628c0,0,14.878-4.881,18.812-3.631
	c0.575,0.19,0.927,0.523,0.927,1.039l-15.663,8.034c0,0-7.659,1.036-7.971,5.187l7.971-5.187l15.663-8.034
	c0,0-12.261,15.034-25.451,20.479c0,0-3.117,6.793-5.662,8.037c0,0,7.792-2.077,8.985-1.557c0,0,11.948-2.333,13.764,1.557
	l-15.892,5.183c0,0-11.947,1.557-11.947,4.407c0,2.852,0.26,1.296,0.26,1.296l11.115-7.258l16.464-3.628
	c0,0-19.166,13.478-28.771,15.553c0,0-8.053,1.035-5.976,5.444c0,0,19.168-3.89,21.555,0l-17.084,4.666h-7.742l-3.011,3.628
	l10.753-3.628l17.084-4.666c0,0-18.852,13.479-32.045,15.293l-4.778,5.443c0,0,14.958,0,19.739,3.111l-17.971,2.334l-5.352-1.814
	l-2.39,4.667l7.741-2.853l17.971-2.334c0,0-14.338,10.889-33.242,11.666c0,0-5.351,5.186-8.985,5.962c0,0,11.997,3.113,16.516,6.479
	h-16.205l-6.284-2.851l-5.973,2.851h12.257h16.205c0,0-7.975,7.809-35.681,5.704c0,0-8.362,3.628-13.453,5.443
	c0,0,15.844,3.889,19.166,12.443l-19.788-3.889c0,0-8.984-7.518-12.259-4.666c0,0,1.507,1.297,7.168,2.074l24.879,6.48
	c0,0-28.151,3.369-38.333-5.443c0,0-46.445,10.393-45.279,11.81l7.204-9.293c0,0,101.835-10.823,132.708-85.468
	c0,0,29.378-30.624,16.154-100.065c0,0-21.556-59.102-38.638-69.471C337.68,70.12,309.837,37.455,307.447,37.196z"
      />
      <polygon
        points="323.417,168.86 331.403,162.279 321.578,161.402 320.803,171.301 317.316,174.572 315.982,156.73 337.106,157.575 
	338.563,170.734 338.192,173.828 317.316,174.572 "
      />
      <polygon
        points="87.645,193.621 96.106,199.756 95.979,191.204 84.543,191.667 80.399,189.049 100.722,185.84 102.157,204.104 
	87.229,206.846 83.62,206.884 80.399,189.049 "
      />
      <g>
        <path
          d="M218.458,220.121l-2.521,35.627l-106.166-0.304l0.132-34.717c3.468-3.309,9.19-8.489,17.167-15.541
		c19.488-17.41,33.678-31.758,42.569-43.044c8.89-11.284,13.354-21.643,13.39-31.076c0.026-6.898-1.714-12.602-5.223-17.111
		c-3.508-4.507-8.234-6.77-14.178-6.787c-13.825-0.04-21.885,11.335-24.184,34.124l-29.205-8.337
		c6.716-39.369,25.004-59.012,54.864-58.927c16.726,0.048,29.543,5.632,38.452,16.748c8.908,11.119,13.334,24.362,13.275,39.733
		c-0.061,15.897-4.799,30.075-14.211,42.537c-9.414,12.463-26.701,28.09-51.863,46.883L218.458,220.121z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

Artwrk22.props = {
  primary: string,
};

Artwrk22.defaultProps = {
  primary: "#000",
};
